(function ($) {
	'use strict';

	/**
	 * compare decorator implementation for the BasicTableRestyle module.
	 *
	 * @author  <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module.BasicTableRestyle
	 * @class SeparateHeader
	 * @extends T.Module
	 */
	T.Module.BasicTableRestyle.SeparateHeader = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Object} */
		$tr: [],


		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.$rowHeadline = this.$ctx.find('.js-separate');

			this.separateHeadline();

			this._parent.start(resolve);
		},

		separateHeadline() {
			const tableRow = this.$ctx.find('.mm-table tr');

			$.each(tableRow, (index, elem) => {
				this.tableCounter = $(elem).find('td').length;
			});

			this.$rowHeadline.prop('colspan', this.tableCounter - 1);

		}

	});
}(jQuery));
