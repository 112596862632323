(function ($) {
	'use strict';
	/**
	 * infoboxlist decorator implementation for the BasicToggle module.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module.BasicToggle
	 * @class Infoboxlist
	 * @extends T.Module
	 */
	T.Module.BasicToggle.Infoboxlist = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$toggleModuleContainer: null,

		start: function (resolve) {

			this.$ctx = $(this._ctx);
			const that = this,
				$toggleModuleContainer = $('.m-basic-toggle--infoboxlist');

			// disable click-events on init when mobile-view is shown
			if (!S.Utils.Helper.mq('tablet').matches) {

				$toggleModuleContainer.find('.mm-head').each(function (index, item) {
					$(item).off();
				});
			}

			this.initEvents();
			this.buildMobileToggle(that.$ctx);
			this.windowResize($toggleModuleContainer);

			this._parent.start(resolve);

		},

		/**
		 * init click events
		 *
		 */
		initEvents: function () {

			const that = this;

			// js-head
			this.$ctx.find('.js-head').on('click', function () {

				let status;

				if (that.allowClick === true || that.allowClickTrigger === true) {
					that.allowClick = false;

					const headController = $(this),
						eventRecalculateHeight = 'FdlCreditCardConfig.recalculateHeight';

					if (headController.hasClass('mm-opened')) {

						status = 'close';

						//emit immediately after click
						that._events.emit(eventRecalculateHeight, headController, status);

						headController.next('.js-content').slideUp(0, function () {

							headController.removeClass('mm-opened');

							if (headController.hasClass('is-opened')) {
								headController.removeClass('is-opened');
							}

							$(this).css('display', '');

							//Shoot event to basicanchornav on open / close to recalculate height
							that._events.emit('BasicAnchorNav.recalculateHeight', headController, status);

							setTimeout(() => {
								that.allowClick = true;
							}, 200);
						});

						that.replaceHead(headController, status);
					}

					else {

						status = 'open';

						//emit immediately after click
						that._events.emit(eventRecalculateHeight, headController, status);

						headController.addClass('mm-opened').next('.js-content').hide().slideDown(function () {

							that._events.emit('BasicAnchorNav.recalculateHeight', headController, status);

							setTimeout(() => {
								that.allowClick = true;
								that._events.emit('basicToggleOpen', that.$ctx);
							}, 200);
						});

						that.replaceHead(headController, status);
					}
				}
			});
		},

		/**
		 * build toggle button for mobile-view to expand the hidden content
		 *
		 */
		buildMobileToggle: function (module) {

			const that = this,
				toggleInfoboxList = '.m-basic-toggle--infoboxlist';

			module.parent().prepend('<div class="mobile-toggle">Mehr Infos</div>');

			module.prev('.mobile-toggle').on('click', function () {

				that._events.emit('FdlCreditCardConfig.calcStickyFooterPos');


				if ($(this).hasClass('mm-opened')) {
					$(this).removeClass('mm-opened').text('Mehr Infos');
					$(this).next(toggleInfoboxList).slideUp().removeClass('is-opened');
				}

				else {
					$(this).addClass('mm-opened').text('Weniger Infos');
					$(this).next(toggleInfoboxList).slideDown().addClass('is-opened');
				}

			});
		},

		/**
		 * hide mobile stuff on mq change
		 *
		 */
		windowResize: function ($toggleModuleContainer) {

			const that = this;

			// close opened boxes on mq change desktop <-> tablet
			S.Utils.Helper.mq('desktop').addListener(function (mq) { // eslint-disable-line
				$toggleModuleContainer.find('.mm-head').each(function (index, item) {
					if ($(item).hasClass('mm-opened')) {
						$(item).trigger('click');
					}
				});
			});

			// close opened boxes on mq change mobile <-> tablet
			S.Utils.Helper.mq('tablet').addListener(function (mq) {

				// tablet + desktop
				if (mq.matches) {
					// reset mobile-toggle-button and text
					$('.mobile-toggle').removeClass('mm-opened').text('Mehr Infos');

					// close opened content
					$toggleModuleContainer.find('.mm-head').each(function (index, item) {

						if ($(item).hasClass('mm-opened')) {
							$(item).trigger('click');
						}
					});

					//show accordion-toggles
					$toggleModuleContainer.show();

					//rebind click events
					that.initEvents();
				}

				else {

					// close opened content
					$toggleModuleContainer.find('.mm-head').each(function (index, item) {

						if ($(item).hasClass('mm-opened')) {
							$(item).trigger('click');
						}

						//unbind click events
						$(item).off();

						//remove scrollbars
						that._events.emit('FdlCreditCardConfig.recalculateHeight', $(item), 'close');

					});

					// hide accordion-toggles
					$toggleModuleContainer.hide();
				}
			});
		},

		/**
		 * change Headline temporarily, when box is active
		 *
		 * @param {Object} headController - toggle-head that was clicked
		 *
		 * @param {string} status - open or close state for content
		 */
		replaceHead: function (headController, status) {

			if (status === 'open') {

				headController.addClass('js-active').siblings('.js-head').hide();

				headController.data('headline', headController.html())
					.html('<p><strong>Zurück</strong></p>');
			}

			else if (status === 'close') {

				headController.removeClass('js-active').siblings('.js-head').show();

				headController.html(headController.data('headline'));
			}
		}

	});
}(jQuery));
