/**
 * EventTrackingWrapper implementation
 *
 * @author <marc.radziwill@merkle.com>
 * @class EventTrackingWrapper
 */

// eslint-disable-next-line no-undef
class EventTrackingWrapper extends BasisComponent {
	eventTrackingAttribute = "data-clickevent";

	eventTrackingAttributeSelector = `[${this.eventTrackingAttribute}]`;

	_connectedCallback() {
		const eventTrackingManual = document.getElementsByTagName('event-tracking-manual');
		const isMaualEventTracking = eventTrackingManual.length > 0;

		if (!isMaualEventTracking) {
			T.Utils.Tracking.hasAnalyticsConsent(this._handleConsent.bind(this));
		}
	}

	_handleConsent(consentStatus) {
		if (consentStatus) {
			this._bindTrackingEvents(jQuery(this));
		}
	}

	_bindTrackingEvents($context) {
		const eventKey = "event-click-bound";
		jQuery($context)
			.find(this.eventTrackingAttributeSelector)
			.each((index, element) => {
				const $element = jQuery(element);
				if (!$element.data(eventKey)) {
					$element.on("click", this.handleEventClick.bind(this));
					$element.data(eventKey, "true");
				}
			});
	}

	handleEventClick(evt) {
		const $element = jQuery(evt.currentTarget);
		const eventData = JSON.parse(
			$element.attr(this.eventTrackingAttribute)
		);

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: "click",
		});
	}
}
window.customElements.define("event-tracking-wrapper", EventTrackingWrapper);
