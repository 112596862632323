
(function ($) {
	'use strict';

	/**
	 * LayoutMainNavLeft module implementation.
	 *
	 * @author <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class LayoutMainNavLeft
	 * @extends T.Module
	 */
	T.Module.LayoutMainNavLeft = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$headerWrap: null,

		/** @type {jQuery} */
		$contentWrap: null,

		/**
		 * @type {jQuery} btn
		 */
		$btn: null,

		/** @type {jQuery} navLayer */
		$navLayer: null,

		/**
		 * @type {Object[]} linksLevel1
		 */
		linksLevel1: null,

		/**
		 * @type {Object[]} linksLevel2
		 */
		linksLevel2: null,

		/**
		 * #### Sticky Stuff ####
		 */

		/** @type {Boolean} triggers sticky stuff */
		hasStickyness: true,

		/** @type {Boolean} toggles sticky initialising delay */
		stickyIsInit: false,

		/** @type {Boolean} hasCookie - specifies if we should listen to cookie events */
		hasCookie: false,

		/** @type {Number} current threshold of sticky-nav (= where should it stick) */
		stickyCurrentThreshold: null,

		/** @type {Number} minimal threshold of sticky-nav (= where should it stick at least)*/
		stickyMinThreshold: 20,

		/** @type {Number} headerHeight */
		headerHeight: null,

		/** @type {Number} headerHeight */
		footerHeight: null,

		/** @type {Number} navHeight */
		navHeight: null,

		/** @type {Boolean} specifies if nav sticks at bottom end (e.g. fullwidth aside elem) */
		stickAtBottom: false,

		/** @type {Boolean} specifies if nav elem has css attr. "top: auto"
		 * used for edge cases in calcStickyElements()
		 * */
		isTopAuto: false,

		/** @type {jQuery} */
		$asideElem: null,

		/** @type {jQuery} */
		$footerElem: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			const that = this;

			this.$btn = this.$ctx.find('.mm-btn').eq(0);
			this.$navLayer = this.$ctx.find('.js-layer').eq(0);
			this.linksLevel1 = this.$ctx.find('> ul > li > a');
			this.linksLevel2 = this.$ctx.find('> ul > li > ul > li > a');

			this.$headerWrap = $('header.l-header').eq(0);
			this.$contentWrap = $('.l-main-content').eq(0);
			this.$asideElem = $('aside.ll-main-full').eq(0);
			this.$footerElem = $('footer.l-footer').eq(0);

			this.navHeight = this.$ctx.outerHeight();

			this.jsClusterpoint = '.js-clusterpoint';
			this.mainContent = '+ .l-main-content';
			this.minHeight = 'min-height';

			this._events.on('BasicKeywordfilter.letter', function () {
				if (S.Utils.Helper.mq('desktop').matches) {
					that.desktop();
				}
			});

			this._events.on('BasicTable.zoom', function () {
				if (S.Utils.Helper.mq('desktop').matches) {
					that.desktop();
				}
			});

			// init smartphone + tablet
			if (!S.Utils.Helper.mq('desktop').matches) {

				// open active toggle
				this.smartphoneTablet();

				// switch clusterpoints to bottom
				that.$ctx.find(this.jsClusterpoint).appendTo(that.$ctx.find('> ul'));

				//add classes to first and last item for styling purposes
				if (that.$ctx.find(this.jsClusterpoint).length) {

					if (that.$ctx.find(this.jsClusterpoint).length > 1) {

						that.$ctx.find(this.jsClusterpoint).eq(0).addClass('js-clusterpoint-first');
						that.$ctx.find(this.jsClusterpoint).eq(-1).addClass('js-clusterpoint-last');
					}

					else {
						that.$ctx.find(this.jsClusterpoint).addClass('js-clusterpoint-single');
					}
				}
			}

			else {
				// init desktop
				this.desktop();

				// switch clusterpoints to top
				that.$ctx.find(this.jsClusterpoint).prependTo(that.$ctx.find('> ul'));
			}

			if (Cookies.get('dataprotection') === undefined) {
				this.hasCookie = true;
			}

			// init sticky events and vars (debug solution until development is done!)
			if (this.$ctx.hasClass('is-not-sticky')) {
				this.hasStickyness = false;
			}

			// init listener click events
			this.addEventListener();

			resolve();
		},

		/**
		 * init eventlistener
		 *
		 */
		// eslint-disable-next-line
		addEventListener: function () { //NOSONAR complexity
			const that = this;

			// smartphone + tablet
			S.Utils.Helper.mq('desktop').addListener((mq) => {

				if (!mq.matches) {
					that.smartphoneTablet();

					if (that.hasStickyness) {
						that.toggleStickyness('remove');
					}

					// reset height
					that.$ctx.find(this.mainContent).css(this.minHeight, '0px');

					// switch clusterpoints to bottom
					that.$ctx.find(this.jsClusterpoint).appendTo(that.$ctx.find('> ul'));

					//add classes to first and last clusterpoint-item for styling purposes
					if (that.$ctx.find(this.jsClusterpoint).length) {

						if (that.$ctx.find(this.jsClusterpoint).length > 1) {

							that.$ctx.find(this.jsClusterpoint).eq(0).addClass('js-clusterpoint-first');
							that.$ctx.find(this.jsClusterpoint).eq(-1).addClass('js-clusterpoint-last');
						}

						else {
							that.$ctx.find(this.jsClusterpoint).addClass('js-clusterpoint-single');
						}
					}
				}

				else {
					// desktop
					that.desktop();

					if (that.hasStickyness) {
						that.toggleStickyness('readd');
					}

					// switch clusterpoints to top
					that.$ctx.find(this.jsClusterpoint).prependTo(that.$ctx.find('> ul'));

					// Clear classes for mobile styling
					that.$ctx.find(this.jsClusterpoint).removeClass('js-clusterpoint-first js-clusterpoint-last js-clusterpoint-single');
				}
			});

			// tablet + desktop
			S.Utils.Helper.mq('tablet').addListener(function (mq) {
				if (mq.matches) {

					that.$ctx.show();

					if (that.hasStickyness) {
						that.toggleStickyness('remove');
					}

					// hide darkness if nav is hidden
					if (that.$navLayer.is(':hidden')) {
						S.Utils.LayerBgDark.hideLayer();
					}
				}
			});

			// Global Close Event
			this._events.on('LayoutMainNavLeft.close', function () {
				that.smartphoneClose('default');
			});

			this._events.on('LayoutHeaderMetaNav.open', function () {

				// if nav is open
				if (that.$btn.next('.mm-content').is(':visible')) {
					that.smartphoneClose('headernav');
				}
			});

			// listen to pin-events of header-nav to animate sticky elem
			if (this.hasStickyness) {

				// layer is pinned -> slides down from top
				this._events.on('LayoutHeaderNav.pinned', () => {
					that.animStickyNav('pinned');
				});

				// layer is unpinned -> slides up to top
				this._events.on('LayoutHeaderNav.unpinned', () => {
					if (!that.$headerWrap.hasClass('stay-visible')) {
						that.animStickyNav('unpinned');
					}
				});

				// listen for cookie layer animations and close
				if (this.hasCookie) {

					let observerHeader;

					this._events.on('cookieLayerTop.close', () => {

						that.hasCookie = false;

						if (typeof observerHeader !== 'undefined') {
							// disconnect observer on cookie close to save performance
							observerHeader.disconnect();
						}

						S.Utils.delayed('stickyContentNav.cookieClosed', 400, () => {
							that.animStickyNav('pinned');
						});
					});

					// add a mutationObserver to get the exact cookie layer state via listening to class changes of l-header
					// use vanilla-js selectors and methods in the callback instead of jQuery to save performance!
					if (that.$headerWrap.length && typeof that.$headerWrap !== 'undefined') {
						observerHeader = new MutationObserver((mutations) => {
							mutations.forEach((mutation) => {
								S.Utils.delayed('stickyContentNav.checkCookieState', 50, () => {

									if (mutation.target.classList.contains('headroom--top')) {
										that.animStickyNav('pinned');
									}
								});
							});
						});

						// register elems to observe
						observerHeader.observe(that.$headerWrap[0], {
							attributeFilter: ["class"]
						});
					}
				}

				// depending on the scrolling state, the header-events wont fire on page load
				// => therefore, we check if stickyness was init and call it manually (pinningDelay + 100ms)
				S.Utils.delayed('stickyContentNav.WaitForPinning', 1100, () => {

					if (!that.stickyIsInit) {
						that.isStickyInit = true;
						that.animStickyNav('pinned');
					}

				});
			}
		},

		/**
		 * calc or store values for sticky nav logic, like header-nav and cookie layer
		 *
		 * @param mod - init / update
		 *
		 * @param headermod - pinned / unpinned
		 *
		 */
		// eslint-disable-next-line
		calcStickyElements: function (mod, headermod) { //NOSONAR Return, Complaxity

			const that = this;
			let calcVal,
				availableSpace;

			/** elem is (in desktop!) not on the same level as first child of l-main (top boundary has an offset).
			 * "stickyFixTop" is used to adjust top positions when we set elem to the same level as l-main until
			 * stickykit snaps again
			 */
			const stickyFixTop = 10;
			that.animToBottom = false;

			// .m-layout-header-nav is no "height calculable part" of header wrapper due to markup structure
			that.headerHeight = that.$headerWrap.outerHeight() + that.$headerWrap.find('.m-layout-header-nav > nav').outerHeight();
			that.footerHeight = that.$footerElem.outerHeight();

			that.navHeight = that.$ctx.outerHeight();
			const windowHeight = $(window).outerHeight();
			availableSpace = windowHeight - that.headerHeight - that.footerHeight;

			if (mod === 'init') {
				that.stickyCurrentThreshold = that.headerHeight + that.stickyMinThreshold;
				that.toggleStickyness(mod);
			}

			// update
			else {

				// check how far content is scrolled upwards
				let contentOffsetTop = 0;

				if (that.$contentWrap.children().length) {
					contentOffsetTop = that.$contentWrap.children().first().offset().top;
				}

				const windowOffsetTop = $(window).scrollTop(),
					contentDistanceToTop = contentOffsetTop - windowOffsetTop,
					navDistanceToTop = that.$ctx[0].getBoundingClientRect().top;
				let asideDistanceToTop = that.$footerElem[0].getBoundingClientRect().top;

				// if aside elem is present, the available space is defined by the space between header-bottom and aside-top
				if (that.$asideElem.length && typeof that.$asideElem !== 'undefined') {
					asideDistanceToTop = that.$asideElem[0].getBoundingClientRect().top;
					availableSpace = asideDistanceToTop - that.headerHeight;
				}

				// check if elem is top = auto
				that.isTopAuto = that.$ctx.prop('style').top === 'auto';

				// Caution: We check for the availableSpace, not windowHeight!
				const tooLargeMode = that.navHeight + that.stickyMinThreshold >= availableSpace;

				// pinned -> header slides down
				if (headermod === 'pinned') {

					// nav elem is behind/beyond header and not bottom sticking
					if (navDistanceToTop <= that.headerHeight && !that.stickAtBottom) {

						// nav elem is higher than distance between header and aside/footer elem (= doesn't fit in)
						// animate to aside/footer top
						if (tooLargeMode) {

							// aside elem is not part of the current viewport (= invisible)
							if (asideDistanceToTop > windowHeight) {

								if (navDistanceToTop > that.headerHeight) {

									that.$ctx.css({
										'position': 'fixed',
										'top': navDistanceToTop
									});

									calcVal = windowHeight - that.navHeight - that.stickyMinThreshold - stickyFixTop;
								}

								else {
									const contentBottomDistanceToTop = contentDistanceToTop + that.$contentWrap.outerHeight();

									that.$ctx.css({
										'position': 'fixed',
										'top': navDistanceToTop
									});

									calcVal = contentBottomDistanceToTop - that.navHeight - that.stickyMinThreshold;

								}
							}

							else {
								that.$ctx.css({
									'position': 'fixed',
									'top': navDistanceToTop
								});

								calcVal = asideDistanceToTop - that.navHeight;
							}


							// prevent to big calc vals by checking against the max anim value
							// => we are in pinned, elem is max. sticking at header-bottom,
							// every bigger value has to be false since it would push the nav below that
							// thresh, leaving a gap between header-bottom and nav-top

							if (calcVal > that.headerHeight + that.stickyMinThreshold) {
								calcVal = that.headerHeight + that.stickyMinThreshold;
							}
						}

						// build fixed state manually (= "freeze", before we disable the stickykit props)
						else {
							that.$ctx.css({
								'position': 'fixed',
								'top': navDistanceToTop
							});

							calcVal = that.headerHeight + that.stickyMinThreshold;
						}
					}

					else {
						calcVal = false;
					}

					// update threshold to header height;
					that.stickyCurrentThreshold = that.headerHeight + that.stickyMinThreshold;

				}

				// unpinned -> header slides up
				else {

					// nav sticky at aside
					if (that.stickAtBottom && !tooLargeMode) {

						// nav elem is behind/beyond min thresh
						if (navDistanceToTop < that.stickyMinThreshold) {
							calcVal = false;
						}

						// animate to min thresh (upwards!) and reset stickAtBottom manually, cause we wont reach
						// toggleStickyness() in every case.
						else {
							that.$ctx.css({
								'position': 'fixed',
								'top': navDistanceToTop
							});

							that.stickAtBottom = false;
							calcVal = that.stickyMinThreshold;
						}

					}

					// content elem is behind/beyond min thresh
					else if (contentDistanceToTop <= that.stickyMinThreshold) {

						// is sticking at bottom
						if (that.stickAtBottom) {

							if (navDistanceToTop > that.stickyMinThreshold) {
								that.$ctx.css({
									'position': 'fixed',
									'top': navDistanceToTop
								});

								that.stickAtBottom = false;
								calcVal = that.stickyMinThreshold;
							}

							else {
								calcVal = false;
							}

						}

						else {
							calcVal = that.stickyMinThreshold;

							// special case, where content is right at the thresh
							// add fix-value to align content and nav
							if (contentDistanceToTop === that.stickyMinThreshold) {
								calcVal += stickyFixTop;
							}
						}
					}

					// just anim upwards to content top
					else {
						if (that.stickAtBottom) {
							that.$ctx.css({
								'position': 'fixed',
								'top': navDistanceToTop
							});

							that.stickAtBottom = false;
						}

						calcVal = contentDistanceToTop + stickyFixTop;
					}

					// update threshold to min threshold cause there is no header;
					that.stickyCurrentThreshold = that.stickyMinThreshold;

				}

				return (calcVal);
			}
		},

		/**
		 * animate nav to slide up/down with headernav and call stickyness afterwards to update offset.top prop
		 *
		 * @param headermod - pinned / unpinned
		 */

		animStickyNav: function (headermod) {

			const that = this;
			let pinningDelay = 400;

			const animDuration = 400;

			if (S.Utils.Helper.mq('desktop').matches) {

				if (!that.stickyIsInit) {
					pinningDelay = 1000;
					that.stickyIsInit = true;
				}

				// stop running anim and set it to end pos of animation
				that.$ctx.stop(true, false);

				const oldThreshold = that.stickyCurrentThreshold;

				S.Utils.delayed('stickyContentNav.WaitForPinning', pinningDelay, () => {

					// calc (end-)position value for the animation if we need it
					const animVal = that.calcStickyElements('update', headermod);

					// animVal is "false" if no animation is needed
					if (typeof animVal === 'number') {

						// animate up-/downwards
						that.$ctx.animate({ top: Math.round(animVal) }, animDuration).promise().done(() => {

							if (oldThreshold !== that.stickyCurrentThreshold) {
								that.toggleStickyness('update');
							}
						});
					}

					// just update the threshold
					else if (oldThreshold !== that.stickyCurrentThreshold) {
						that.toggleStickyness('update');
					}


				});

			}
		},

		/**
		 * add sticky prop to nav-elem
		 *
		 * @param mod - init / update / remove
		 */
		toggleStickyness: function (mod) {

			const that = this,
				stickyKitDetach = 'sticky_kit:detach';

			if (S.Utils.Helper.mq('desktop').matches) {

				S.Utils.delayed('stickyContentNav.toggleStickynessDelay', 40, () => {

					if (mod !== 'remove') {

						/**
						 * For updating the offset property, we have to detach stickykit bc the framework doesn't
						 * allow threshold changes on the fly;
						 */
						if (mod !== 'init') {
							that.$ctx.trigger('sticky_kit:tick ').trigger(stickyKitDetach);
						}

						that.$ctx.stick_in_parent({
							offset_top: that.stickyCurrentThreshold,
							sticky_class: 'is-stuck',
							parent: '.ll-main-center',
							spacer: false
							// recalc_every: 1
						});

						// nav docked at aside elem
						that.$ctx.on('sticky_kit:bottom', () => {
							that.stickAtBottom = true;
						});

						// nav undocked from aside elem
						that.$ctx.on('sticky_kit:unbottom', () => {
							that.stickAtBottom = false;
						});
					}

					else {
						that.$ctx.removeClass('is-fixed').trigger(stickyKitDetach).attr('style', '');
					}
				});
			}

			// remove if desktop doesn't match
			else {
				that.$ctx.removeClass('is-fixed').trigger(stickyKitDetach).attr('style', '');
			}
		},

		/**
		 * init smartphone + tablet events
		 *
		 */
		smartphoneTablet: function () {
			const that = this;

			// click smartphone nav btn
			this.$btn.off('click').on('click', function () {
				let closeBtn = false;

				if (!$(this).hasClass('is-open')) {
					$(this).addClass('is-open').attr('style', '');
				}
				else {
					closeBtn = true;
				}

				$(this).next().slideToggle('fast', () => {
					if (closeBtn) {
						$(this).removeClass('is-open').attr('style', '');
					}
					if ($(this).hasClass('is-open')) {
						that.$ctx.css('z-index', 1500);
					}
					else {
						that.$ctx.css('z-index', 800);
					}
				});

				// layer bg dark
				const callback = function () {
					that.$btn.removeClass('is-open').next().slideUp('fast');
				};

				if (!that.$ctx.hasClass('has-no-darkness')) {
					S.Utils.LayerBgDark.init(that.$btn, callback);
				}
			});

			this.$btn.on('blur', function () {
				setTimeout(() => {
					if (!$(this).hasClass('is-open')) {
						that.$ctx.css('z-index', 800);
					}
				}, 200);
			});

			// click level 1
			this.linksLevel1.off().on('click', function (e) {
				// toggle
				that.toggle(this, e);
			});
		},

		/**
		 * close for smartphone
		 *
		 * @param mod - default / headernav
		 */
		smartphoneClose: function (mod) {

			const that = this;

			// hide dark bg if headernav is not opened -> we still need that
			if (mod !== 'headernav') {
				S.Utils.LayerBgDark.hideLayer();
			}

			that.$btn.removeClass('is-open').next().slideUp(0);
		},

		/**
		 * init desktop events
		 *
		 */
		desktop: function () {
			// check if left navi is higher than main area
			const firstContentHeight = this.$ctx.find(this.mainContent).outerHeight();
			const navHeight = this.$ctx.outerHeight();

			if (firstContentHeight < navHeight) {
				const div = navHeight - firstContentHeight;
				const newHeight = firstContentHeight + div + 80;
				this.$ctx.find(this.mainContent).css(this.minHeight, `${newHeight}px`);


				// if finecalculator remove min-height on l-main-content
				if ($('.l-main-content--searchresult').length) {
					this.$ctx.find(this.mainContent).css(this.minHeight, 0);
				}
			}
		},

		/**
		 * toggle navi items
		 *
		 * @param {jQuery} elem
		 */
		// eslint-disable-next-line
		toggle: function (elem, event) { //NOSONAR Return

			if ($(elem).next('ul').length) {
				event.preventDefault();

				// reset active
				this.$ctx.find('> ul > li > a.is-active, > ul > li > a.is-open').next().slideUp('fast', () => {
					this.$ctx.find('> ul > li > a.is-active, > ul > li > a.is-open').removeClass('is-active is-open');
				});

				// set active
				if (!$(elem).next().is(':visible')) {
					$(elem).next().slideToggle('fast', function () {
						$(elem).addClass('is-open');

						// check for this.hasStickyness was added in context of new-header-dev:
						// i thought its a valid selector to prevent the scroll logic if stickyness is disabled in general, no matter if the new header is set or not.
						// if this is causing bugs, just update the check by asking if 'm-basic-header' is there.

						if (!S.Utils.Helper.mq('tablet').matches && this.hasStickyness) {
							const scrollPos = $(elem).offset().top;
							$('html, body').animate({
								scrollTop: scrollPos
							}, 700);
						}
					});
				}
			}
			else {
				if ($(elem).hasClass('is-active')) {
					return false;
				}
			}
		},

		/**
		 * loaderanimation
		 *
		 * @param {jQuery} elem
		 */
		loader: function (elem) {
			//reset
			this.$ctx.find('.is-active').not($(elem).parents('ul').siblings()).removeClass('is-active');

			// set active and loader
			const loaderHtml = '<span class="mm-loader"></span>';
			$(elem).addClass('is-active');

			if ($(elem).next('ul').length) {
				$(elem).next().find('> li:first-child >').addClass('is-active').append(loaderHtml);
			} else {
				$(elem).append(loaderHtml);
			}

			window.location.href = $(elem).attr('href');
		},
	});


}(jQuery));
