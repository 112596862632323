'use strict';
(function ($) {
	/**
	 *
	 * @author  <marc.radziwill@namics.com>
	 * @namespace T.Module
	 * @class BasicBtn.Tet
	 * @extends T.Module
	 */
	T.Module.BasicBtn.Tet = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		sessionKey: 'compareProduct',

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.registerListeners();

			this._parent.start(resolve);
		},

		registerListeners: function () {
			this.$ctx.on('click', this.handleCompareSubmit.bind(this));
		},

		handleCompareSubmit: function (ev) {
			ev.preventDefault();
			this.callActionUrlWithCompareIds();
		},

		callActionUrlWithCompareIds: function () {
			const compareList = this.collectCompareIdsAsString(this.$ctx.data('mode'));
			if (compareList === "") { return; }
			const actionUrl = this.$ctx.attr('href') || '';
			window.location.href = T.Utils.Helper.updateUrlParameter({
				ids: compareList
			}, actionUrl);

		},

		collectCompareIdsAsString: function (mode) {
			const compareData = T.Utils.Store.get(this.sessionKey, 'session'),
				compareList = [];

			// eslint-disable-next-line no-eq-null
			if (compareData != null && compareData.length > 0) {
				compareData.forEach(function (element) {
					if (element.mode === mode) {
						compareList.push(element.id);
					}
				});
			}
			return compareList.join(',');
		}

	});
}(jQuery));
