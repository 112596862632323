'use strict';

(function ($) {
	/**
	 * PostalVerificationFinish module implementation.
	 *
	 * @author  <barbara.ascher@gks.adac.de>
	 * @namespace T.Module
	 * @class PostalVerificationFinish
	 * @extends T.Module
	 */
	T.Module.PostalVerificationFinish = T.createModule({
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this.$successfulVerification = this.$ctx.find('.js-successful-verification');
			this.$statusVerification = this.$ctx.find('.js-status-verification');
			this.$pendingVerification = this.$ctx.find('.js-pending-verification');
			this.$notVerified = this.$ctx.find('.js-not-verified');
			this.$failedVerification = this.$ctx.find('.js-failed-verification');
			this.$notYetSentVerification = this.$ctx.find('.js-not-yet-sent-verification');
			this.$overdueVerification = this.$ctx.find('.js-overdue-verification');
			this.$failedAuthorisationVerification = this.$ctx.find('.js-failed-authorisation-verification');
			this.$requestVerification = this.$ctx.find('.js-request-verification');
			this.$errorVerification = this.$ctx.find('.js-error');
			this.$formRow = this.$ctx.find('.js-row');

			this._readConfiguration();
			this._registerListeners();
			T.Utils.Auth.getResolvedIdentity(this._handleUserData.bind(this));

			resolve();
		},

		_readConfiguration: function readConfiguration() {
			this.confirmcodeApi = this.$ctx.data("confirmcode-api");
			this.accessmanagementApi = this.$ctx.data("accessmanagement-api");
		},

		_registerListeners: function _registerListeners() {
			this.$ctx.find('.js-submit').on('click', this._submitForm.bind(this));
		},

		_handleUserData: function _handleUserData(user) {
			if (user) {
				if (T.Utils.SelfServiceLogin.isVerifiedUser(user)) {
					this._showAndHideSuccess();
				} else {
					T.Utils.Auth.getBearerToken(this._checkVerficationStatus.bind(this));
				}
			} else {
				T.Utils.Auth.authorize();
			}
		},

		_checkVerficationStatus: function _checkVerficationStatus(token) {
			const url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.accessmanagementApi);
			const options = {
				url: url,
				type: 'GET',
				headers: {
					Authorization: `Bearer ${token}`
				}
			};

			T.Utils.Ajax.fragment(options, this._handleVerificationStatus.bind(this), this._handleVerificationStatusError.bind(this));
		},

		_handleVerificationStatus: function _handleVerificationStatus(data) {

			if (data.Data.some(value => value.Status === 3)) {
				this._showAndHideSuccess();
			} else {
				const verifiedState = T.Utils.SelfServiceLogin.getVerifiedStateResponse(data);

				if (verifiedState && verifiedState.Status) {
					switch (verifiedState.Status) {
						case 1:
							this._showAndHideNotYetSent();
							break;
						case 2:
							this._showAndHidePending();
							break;
						default:
							this._showAndHideError();
							break;
					}
				} else {
					this._showAndHideError();
				}
			}
		},

		_handleVerificationStatusError: function _handleVerificationStatusError(err) {
			if (err.status === 404 || err.status === 401) {
				this._showAndHideNotVerified();
			} else {
				this._showAndHideError();
			}
		},

		_submitForm: function _submitForm(ev) {
			ev.preventDefault();
			this._hideFieldError();

			const data = {
				"Code": this.$ctx.find('.js-confirm-code').val(),
				"Role": "Verified",
				"Method": 1
			};

			T.Utils.Auth.getBearerToken((token) => {
				const options = {
					type: 'POST',
					data: JSON.stringify(data),
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.confirmcodeApi),
					headers: {
						Authorization: `Bearer ${token}`,
					},
				};
				T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));

			});
		},

		_successCallback: function _successCallback() {
			T.Utils.Auth.bgTokenUpdate(true);
			this._showAndHideSuccess();
		},

		_errorCallback: function _errorCallback(err) {
			if (err && err.status === 400 && err.responseJSON && err.responseJSON.Messages) {
				if (err.responseJSON.Messages[0].Id === 161008) {
					this._showAndHideFailedVerification();
				} else if (err.responseJSON.Messages[0].Id === 161007 || err.responseJSON.Messages[0].Id === 161011) {
					this._showFieldError();
				} else if (err.responseJSON.Messages[0].Id === 161010) {
					this._showAndHideOverdue();
				} else {
					this._showAndHideError();
				}
			} else {
				this._showAndHideError();
			}
		},

		_showAndHideSuccess: function _showAndHideSuccess() {
			this.$successfulVerification.toggleClass("h-hidden", false);
			this.$statusVerification.toggleClass("h-hidden", false);

			this.$pendingVerification.toggleClass("h-hidden", true);
			this.$notVerified.toggleClass("h-hidden", true);
			this.$failedVerification.toggleClass("h-hidden", true);
			this.$notYetSentVerification.toggleClass("h-hidden", true);
			this.$overdueVerification.toggleClass("h-hidden", true);
			this.$failedAuthorisationVerification.toggleClass("h-hidden", true);
			this.$requestVerification.toggleClass("h-hidden", true);
			this.$errorVerification.toggleClass("h-hidden", true);
		},

		_showAndHideNotYetSent: function _showAndHideNotYetSent() {

			this.$statusVerification.toggleClass("h-hidden", false);
			this.$notYetSentVerification.toggleClass("h-hidden", false);

			this.$successfulVerification.toggleClass("h-hidden", true);
			this.$pendingVerification.toggleClass("h-hidden", true);
			this.$notVerified.toggleClass("h-hidden", true);
			this.$failedVerification.toggleClass("h-hidden", true);
			this.$overdueVerification.toggleClass("h-hidden", true);
			this.$failedAuthorisationVerification.toggleClass("h-hidden", true);
			this.$requestVerification.toggleClass("h-hidden", true);
			this.$errorVerification.toggleClass("h-hidden", true);
		},

		_showAndHideOverdue: function _showAndHideOverdue() {
			this.$statusVerification.toggleClass("h-hidden", false);
			this.$overdueVerification.toggleClass("h-hidden", false);

			this.$notYetSentVerification.toggleClass("h-hidden", true);
			this.$successfulVerification.toggleClass("h-hidden", true);
			this.$pendingVerification.toggleClass("h-hidden", true);
			this.$notVerified.toggleClass("h-hidden", true);
			this.$failedVerification.toggleClass("h-hidden", true);
			this.$failedAuthorisationVerification.toggleClass("h-hidden", true);
			this.$requestVerification.toggleClass("h-hidden", false);
			this.$errorVerification.toggleClass("h-hidden", true);
		},

		_showAndHidePending: function _showAndHidePending() {
			this.$pendingVerification.toggleClass("h-hidden", false);

			this.$statusVerification.toggleClass("h-hidden", true);
			this.$notYetSentVerification.toggleClass("h-hidden", true);
			this.$successfulVerification.toggleClass("h-hidden", true);
			this.$notVerified.toggleClass("h-hidden", true);
			this.$failedVerification.toggleClass("h-hidden", true);
			this.$overdueVerification.toggleClass("h-hidden", true);
			this.$failedAuthorisationVerification.toggleClass("h-hidden", true);
			this.$requestVerification.toggleClass("h-hidden", true);
			this.$errorVerification.toggleClass("h-hidden", true);
		},

		_showAndHideNotVerified: function _showAndHideNotVerified() {
			this.$statusVerification.toggleClass("h-hidden", false);
			this.$notVerified.toggleClass("h-hidden", false);

			this.$notYetSentVerification.toggleClass("h-hidden", true);
			this.$successfulVerification.toggleClass("h-hidden", true);
			this.$pendingVerification.toggleClass("h-hidden", true);
			this.$failedVerification.toggleClass("h-hidden", true);
			this.$overdueVerification.toggleClass("h-hidden", true);
			this.$failedAuthorisationVerification.toggleClass("h-hidden", true);
			this.$requestVerification.toggleClass("h-hidden", true);
			this.$errorVerification.toggleClass("h-hidden", true);
		},

		_showAndHideFailedVerification: function _showAndHideFailedVerification() {
			this.$statusVerification.toggleClass("h-hidden", false);
			this.$failedAuthorisationVerification.toggleClass("h-hidden", false);
			this.$failedVerification.toggleClass("h-hidden", false);
			this.$requestVerification.toggleClass("h-hidden", false);

			this.$notVerified.toggleClass("h-hidden", true);
			this.$notYetSentVerification.toggleClass("h-hidden", true);
			this.$successfulVerification.toggleClass("h-hidden", true);
			this.$pendingVerification.toggleClass("h-hidden", true);
			this.$overdueVerification.toggleClass("h-hidden", true);
			this.$errorVerification.toggleClass("h-hidden", true);
		},

		_showAndHideError: function _showAndHideError() {
			this.$statusVerification.toggleClass("h-hidden", false);
			this.$failedAuthorisationVerification.toggleClass("h-hidden", false);
			this.$errorVerification.toggleClass("h-hidden", false);

			this.$failedVerification.toggleClass("h-hidden", true);
			this.$requestVerification.toggleClass("h-hidden", true);
			this.$notVerified.toggleClass("h-hidden", true);
			this.$notYetSentVerification.toggleClass("h-hidden", true);
			this.$successfulVerification.toggleClass("h-hidden", true);
			this.$pendingVerification.toggleClass("h-hidden", true);
			this.$overdueVerification.toggleClass("h-hidden", true);

		},

		_showFieldError: function _showFieldError() {
			this.$formRow.addClass('is-error');
			this.$formRow.addClass('is-check');
		},

		_hideFieldError: function _hideFieldError() {
			this.$formRow.removeClass('is-error');
			this.$formRow.removeClass('is-check');
		}

	});
}(jQuery));