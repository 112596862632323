'use strict';
(function ($) {
	/**
	 * ContractAdvocateSearch module implementation.
	 *
	 * @author Markus Hiller <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class ContractAdvocateSearch
	 * @extends T.Module
	 */

	T.Module.ContractAdvocateSearch = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();

			this.$rowhtmlTemplate = $(this.$ctx.find('#rowhtml').html());
			this.$listhtmlTemplate = $(this.$ctx.find('#listhtml').html());

			this._readConfig();
			this._registerListeners();

			resolve();
		},

		_readConfig: function _readConfig() {
			this._apiVertragsanwaelte = this.$ctx.data("apivertragsanwaelte");

			if (!this._searchEvent) {
				this._searchEvent = "PoiPerimeterSearch";
			}
			this._errorEvent = this.$ctx.data('error-event');
			if (!this._errorEvent) {
				this._errorEvent = "PoiPerimeterSearchError";
			}
		},

		_registerListeners: function _registerListeners() {
			$(document).on(this._searchEvent, this._requestData.bind(this));
			$(document).on(this._errorEvent, this._handleSearchError.bind(this));
		},

		_requestData: function _requestData(event, searchData) {
			const position = T.Utils.Geo.mercatorToLonLat({ x: searchData.geoRectangle.Position.X, y: searchData.geoRectangle.Position.Y });
			T.Utils.View.startLoader();

			const parameter = {
				// fixed values
				channel: 16,
				outputclass: 0,
				mandant: 75,
				// variable values
				rows: 12,
				"position.latitude": position.y,
				"position.longitude": position.x,
				"position.perimeter": searchData.perimeter
			};
			const options = {
				type: 'GET',
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() },
				dataType: "json",
				data: parameter,
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._apiVertragsanwaelte)
			};

			T.Utils.Ajax.fragment(options, this._handleSearchResults.bind(this), this._handleSearchError.bind(this));
		},

		_handleSearchError: function _handleSearchError() {
			this.$ctx.find(".js-error").toggleClass("h-hidden", false);
			T.Utils.View.stopLoader();
		},

		_handleSearchResults: function _handleSearchResults(data) {
			this.$ctx.find(".js-resultlist").empty();
			this.$ctx.find(".js-error").toggleClass("h-hidden", true);
			this.$ctx.find(".js-noresults").toggleClass("h-hidden", true);
			const resultsHtml = [];

			if (!data.response || !data.response.docs || data.response.docs.length === 0) {
				this.$ctx.find(".js-noresults").toggleClass("h-hidden", false);
			}

			for (let i = 0; i < data.response.docs.length; i++) {
				const $resultHtml = this._buildResult(data.response.docs[i]);

				if (i % 3 === 0) {
					resultsHtml.push(this.$rowhtmlTemplate.clone());

					if (i === 0) {
						resultsHtml[0].addClass("l-main-cols--border-top");
					}
				}

				resultsHtml[resultsHtml.length - 1].append($resultHtml);
			}

			this.$ctx.find(".js-resultlist").append(resultsHtml);
			T.Utils.View.stopLoader();
		},

		_buildResult: function _buildResult(result) {
			const $html = this.$listhtmlTemplate.clone();
			$html.find(".js-contact").html(`<strong>${result.imxtools_contact.join(' ')}</strong><br>${result.imx_subtitle}<br><br>${result.street} ${result.imx_streetno}<br>${result.zipcode} ${result.city}`);
			$html.find(".js-phone").html(`<span>Telefon ${result.phone}</span>`);
			if (result.imxtools_homepage && result.imxtools_homepage.length > 0) {
				$html.find(".js-net").html(`<a href="${result.imxtools_homepage[0]}" target="_blank">${result.imxtools_homepage[0]}</a>`);
			}
			return $html;
		}
	});
}(jQuery));