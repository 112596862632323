(function ($) {
	'use strict';

	S.Lazy = S.Lazy || {};

	/**
	 * lazy loading for modules
	 * ########################
	 *
	 * Via Class:
	 * - is-lazy
	 *
	 * Via internal variable:
	 * - isLazy = true;
	 *
	 * Via initLazy: function():
	 * - S.Lazy.initLazy(<HTMLElement|HTMLCollection|jQuery>)
	 *
	 * Via _observe: function():
	 * - S.Lazy._observe(<element>, <callback>, <object>, <Boolean>)
	 *  - (optional) custom Callback and custom observer
	 *
	 *  lazy loading for images
	 *  #######################
	 *  Via Class:
	 *  - lazysizes
	 */
	S.Lazy = {
		// DELETE ME
		$clones: [],

		lazyloadClass: {
			lazysizes: 'lazyload', // taken over the default behavior
			optinClass: 'is-lazy',
			optoutClass: 'no-lazy',
			loadedClass: 'is-lazyloaded',
		},

		/**
		 * @var {Object}
		 */
		lazyloadOptions: {
			/**
			 * Element to observe
			 */
			root: null,

			/**
			 * Margin around the viewport
			 *
			 * @var {string} Accepts shorthand values like a normal margin (top right bottom left)
			 */
			margin: '200px',

			/**
			 * Indicate at what percentage of the module's visibility the lazyloading should be executed.
			 *
			 * The default is 0 (meaning as soon as even one pixel is visible). A value of 1.0 means that the threshold
			 * isn't considered passed until every pixel is visible.
			 *
			 * @var {Number} Range between 0 and 1
			 */
			threshold: 0,
		},

		/**
		 * @var {IntersectionObserver}
		 * @private
		 */
		intersectionObserver: null,

		/**
		 * Array of all initialized modules
		 *
		 * @var {Object[]}
		 */
		instances: [],

		/**
		 * IntersectionObserver state
		 *
		 * @var {Boolean}
		 */
		isSupported: true,

		/**
		 * Array of all supported HTML tags
		 *
		 * @var {string[]}
		 */
		elements: ['[data-t-id]'],

		/**
		 * Reference container
		 *
		 * @var intersection-stack
		 * */
		customElementDataWeakMap: new WeakMap(),

		/**
		 * Print lazy listener
		 *
		 * @var {Event}
		 * */
		printListener: null,

		/**
		 * State indicator for bootstrap.js
		 *
		 * @var {Boolean}
		 * */
		wasInitialized: false,

		/**
		 * all modules get initialized
		 * - if they have the "isLazy" state or "is-lazy" class the _lazyUpdate gets triggered when they come into view
		 * - if the "isLazy" state is false (not null or undefined)
		 */
		init() {
			/**
			 * indicator for bootstrap.js "addModules"
			 *
			 * This S.Lazy-logic gets initialized after all modules are initialized and terrificJS is done with its thing.
			 * Some Modules are adding modules already in the start() function and trigger the overall
			 * T.Utils.Helper.initAdditionalModules(<context>) function to add additional modules etc.
			 *
			 * At this point this function isn´t executed yet, because we need to be sure we have all modules in the _sandbox and
			 * therefore the lazyLoading logic for modules isn´t ready.
			 * We need to tell our S.Lazy.update() function-query in the bootstrap.js to wait until
			 * we are ready to update when an additional Module gets added.
			 *
			 * We only need this after all terrificJS is done, and so the additional Module which are added in start() are already in the _sandbox we are using
			 *
			 * state:
			 * wasInitialized = true;
			 */
			this.wasInitialized = true;

			// only for testing
			// this.testCSSSelector();
			// return;
			// this.testFunction();
			this._checkForIntersectionObserverSupport();

			// reference all modules from terrificJS sandbox
			const instances = document.applicationInstance?._sandbox?._application?._modules;

			// check if the observer is already initialized
			this._checkForObserver();

			// for testing purpose
			if (this.isSupported !== 'disabled') {
				// init lazy logic for modules
				this._lazyInstancesLogic(instances);
			}

			// deal with images
			this._handleInitialImages();

			// deal with iframes
			this._handleInitialIframes();
		},

		/**
		 * @param instances
		 * @private
		 */
		_lazyInstancesLogic(instances) {
			for (const instance in instances) {
				const currInstance = instances[instance],
					element = currInstance._ctx,
					noLazy = currInstance._ctx.classList?.contains(this.lazyloadClass.optoutClass);

				// if there is no support just trigger all lazy functions
				if (!this.isSupported) {
					this.triggerLazyUpdate(currInstance, null, null);

					continue;
				}

				// no-lazy (optoupClass) has a higher weighting so that this can be easily switched off when needed.
				if (!noLazy && (currInstance.isLazy || currInstance._ctx.classList?.contains(this.lazyloadClass.optinClass))) {
					// add "lazyload"-class
					this.addLazyClassesOnImg(element);

					// just add lazy class, for reference and CSS-selector
					currInstance._ctx.classList?.add(this.lazyloadClass.optinClass);

					this._customObserve(element, currInstance);
				}
				// if currInstance won't have a lazy option - just check for the _lazyUpdate function and trigger it
				// to assure that all works and gets executed
				else {
					if (typeof currInstance._lazyUpdate === 'function') {
						currInstance._lazyUpdate(element, this._createDataObj(element, currInstance));
						this._setInstanceToIsLoadedState(currInstance);
					}
				}
			}
		},

		/**
		 * update function when HTML gets added
		 */
		update(context) {
			// check if the observer is already initialized
			this._checkForObserver();

			// via addModules document listener (bootstrap.js)
			if (context) {
				const currInstance = S.Utils.Helper.getModuleInstance(context);

				if (currInstance) {
					// reset isLoaded state for lazy loading
					// (maybe the cloned module had alreayd isLoaded = true)
					currInstance.isLoaded = false;
				}

				this._handleUpdate(context, currInstance);

				// add "lazyload"-class
				this.addLazyClassesOnImg(context);

				// search for images and unveil or observe them
				this._handleImagesOnUpdate(context, true);

				// search for iframes and unveil or observe them
				this._handleIframesOnUpdate(context, true);
			}
			// is triggered through S.Globals.lazyloadertx
			// - which gets called when HTML gets added (from Backend/Namics)
			else {
				// check for lazysizes class and add to observer
				// reference all modules from terrificJS sandbox
				const instances = document.applicationInstance?._sandbox?._application?._modules;

				for (const instance in instances) {
					const currInstance = instances[instance],
						element = currInstance._ctx;

					this._handleUpdate(element, currInstance);
				}

				// add "lazyload"-class
				this.addLazyClassesOnImg(document.body);

				// search for images and unveil or observe them
				this._handleImagesOnUpdate(document.body);

				// search for iframes and unveil or observe them
				this._handleIframesOnUpdate(document.body);
			}
		},

		/**
		 * this gets triggered when a module gets removed via removeModule event
		 */
		remove() {
			// remove element from dataWeakmap if it´s in there
			// and remove the observer from it
		},

		/**
		 * @param context
		 * @param currInstance
		 * @private
		 */
		_handleUpdate(context, currInstance) {
			const module = context instanceof jQuery ? context.get(0) : context;

			if (this.isAlreadyObserved(module) || currInstance?.isLoaded || !currInstance) {
				return;
			}

			// for module that needs to be lazy loaded (observed)
			if (this.isSupported && (currInstance?.isLazy || module?.classList.contains(this.lazyloadClass.optinClass))) {
				this._customObserve(module, null);
			}
			else {
				// trigger lazyUpdate function, no lazy loading
				this.triggerLazyUpdate(currInstance, null, null);
			}
		},

		/**
		 * @param element
		 * @private
		 */
		_handleImagesOnUpdate(element) {
			// get all images with the lazysizes class
			const $context = element instanceof jQuery ? element : $(element),
				$images = $context.find(`img.${this.lazyloadClass.lazysizes}`);

			// and add them to the observer lazy loading cycle, which
			// gets revealed when they are in view
			for (const img of $images) {
				// if there is no support just unveil the images
				if (!this.isSupported) {
					this.unveilLazyImages(img);

					continue;
				}

				this._observeSingleImageWithLazyLoadClass(img);
			}
		},

		/**
		 * @param element
		 * @private
		 */
		_handleIframesOnUpdate(element) {
			// get all iframes with the lazysizes class
			const $context = element instanceof jQuery ? element : $(element),
				$iframes = $context.find(`iframe.${this.lazyloadClass.lazysizes}`);

			// and add them to the observer lazy loading cycle, which
			// gets revealed when they are in view
			for (const iframe of $iframes) {
				// if there is no support just unveil the images
				if (!this.isSupported) {
					this.unveilIframe(iframe);

					continue;
				}

				this._observeSingleIframeWithLazyLoadClass(iframe);
			}
		},

		/**
		 * check if intersection observer is support,
		 * if not reveal all images -> else check to observe them
		 * @private
		 */
		_handleInitialImages() {
			// this.getImgLazyParam() can be null (if no param is given) - so better to test agains false Boolean (not String)
			if (this.getImgLazyParam() === false || !this.isSupported) {
				this.unveilAllLazyImages();

				return;
			}

			// observe all other images
			this._observeAllImageWithLazyLoadClass();
		},

		/**
		 * check if intersection observer is support,
		 * if not reveal all iframes -> else check to observe them
		 * @private
		 */
		_handleInitialIframes() {
			// this.getImgLazyParam() can be null (if no param is given) - so better to test agains false Boolean (not String)
			if (!this.isSupported) {
				this.unveilAllLazyIframes();

				return;
			}

			// observe all other images
			this._observeAllIframesWithLazyLoadClass();
		},

		/**
		 * We need to catch the print event so that lazyloadable iframes will be shown
		 */
		unveilAllLazyIframes() {
			$(`iframe.${this.lazyloadClass.lazysizes}`).each((_, el) => {
				this.unveilIframe(el);
				this._unObserve(el, null, false, false);
			});
		},

		/**
		 * @param {HTMLElement|jQuery} iframe
		 */
		unveilIframe(iframe) {
			const isJquery = iframe instanceof jQuery,
				$iframe = isJquery ? iframe : $(iframe);

			if (
				!$iframe.hasClass(this.lazyloadClass.optoutClass) &&
				$iframe.hasClass(this.lazyloadClass.lazysizes) &&
				$iframe.data('src') !== null
			) {
				$iframe.attr('src', $iframe.attr('data-src'))
					.removeAttr('data-src');

				this.handleUnveilClasses($iframe);
			}
		},

		/**
		 * after the module lazy loading logic, find all img with the old lazysizes class
		 * and observe them to be unveiled if they are in view
		 * @private
		 */
		_observeAllIframesWithLazyLoadClass() {
			$(`iframe.${this.lazyloadClass.lazysizes}[data-src]`).each((_, element) => {
				this._observeSingleIframeWithLazyLoadClass(element);
			});
		},

		/**
		 * @param {HTMLElement|jQuery} iframe
		 * @private
		 */
		_observeSingleIframeWithLazyLoadClass(iframe) {
			const element = iframe instanceof jQuery ? iframe.get(0) : iframe;

			this._customObserve(element, null);
		},

		/**
		 * after the module lazy loading logic, find all img with the old lazysizes class
		 * and observe them to be unveiled if they are in view
		 * @private
		 */
		_observeAllImageWithLazyLoadClass() {
			$(`img.${this.lazyloadClass.lazysizes}[data-src]`).each((_, element) => {
				this._observeSingleImageWithLazyLoadClass(element);
			});
		},

		/**
		 * @param {HTMLElement|jQuery} img
		 * @private
		 */
		_observeSingleImageWithLazyLoadClass(img) {
			const element = img instanceof jQuery ? img.get(0) : img;

			this._customObserve(element, null);
		},

		/**
		 * trigger _lazyUpdate function when its not supported
		 *
		 * @param {Object} currInstance
		 * @param {HTMLElement} originalElement :: this is used for S.Lazy._observe (if u need to observe an additional element)
		 * @param {Function} cb                 :: if u need a custom callBack - gets triggered when element gets in view instead of _lazyUpdate()
		 *                                         S.Lazy._observe(... , cb.bind(this), ..., ...)
		 */
		triggerLazyUpdate(currInstance, originalElement, cb) {
			const element = originalElement instanceof Element ? originalElement : currInstance._ctx;

			if (currInstance) {
				// if we added a custom callback in S.Lazy._observe
				if (cb) {
					cb(element, this._createDataObj(element, currInstance));
				}
				else if (typeof currInstance._lazyUpdate === 'function') {
					currInstance._lazyUpdate(element, this._createDataObj(element, currInstance));
				}

				this._setInstanceToIsLoadedState(currInstance);
			}
		},

		/**
		 * sets the internal object variable (state) to is loaded
		 * @param currInstance
		 * @private
		 */
		_setInstanceToIsLoadedState(currInstance) {
			if (typeof currInstance === 'object') {
				currInstance.isLoaded = true;
			}
		},

		/**
		 * @param cb
		 * @param opt
		 * @return {IntersectionObserver}
		 */
		createNewObserver(cb, opt) {
			// set default options
			const callback = cb || this._lazy.bind(this),
				options = { ...this.lazyloadOptions, ...opt };

			return new IntersectionObserver(callback, options);
		},

		/**
		 * check if specific module is in lazyObserver weakMap
		 * @param {jQuery|HTMLCollection|Number} entry :: can be an element or the module id
		 * @param weakMap
		 */
		isLazy(entry, weakMap) {
			return this.isAlreadyObserved(entry, weakMap);
		},

		/**
		 * check if specific module is in lazyObserver weakMap
		 * @param {jQuery|HTMLCollection|Number} entry :: can be an element or the module id
		 * @param weakMap
		 */
		isAlreadyObserved(entry, weakMap) {
			const map = weakMap || this.customElementDataWeakMap,
				isJQuery = entry instanceof jQuery,
				// reference all modules from terrificJS sandbox
				sandbox = document.applicationInstance?._sandbox;

			// just in case, entry is a jQuery-Collection
			let module = isJQuery ? entry.get(0) : entry;

			if (typeof module === 'number') {
				const instance = sandbox?.getModuleById(entry);

				module = instance?._ctx;
			}

			return map?.get(module);
		},

		/**
		 * Create data object for a given context (used for weakMap context - just for info sugar)
		 *
		 * @param {string|HTMLElement|Node|jQuery} context
		 * @param {Object} instance                             :: {this} - module where this was called from
		 * @param {Function} callback                           :: callback when u need a separate call for a function in _lazy
		 * @param {WeakMap} customWeakMap                       :: custom weakMap if needed in S.Lazy._observe
		 * @return {{instance: *, $target: (*|jQuery|HTMLElement), tId: *, $nearestModule: (*), target: *}}
		 * @private
		 */
		_createDataObj(context, instance = null, callback = null, customWeakMap = null) {
			const el = context instanceof jQuery ? context.get(0) : context,
				dataTId = el.getAttribute('data-t-id') || null;

			return {
				tId: dataTId,
				$nearestModule: S.Utils.Helper.getTheNearestModuleInstance(el, true),
				$target: $(el),
				target: el,
				instance: instance || S.Utils.Helper.getModuleInstance(el),
				callback: callback,
				customWeakMap: customWeakMap,
			};
		},

		/**
		 * for IE or browser which doesn't support intersectionObserver by now
		 * @private
		 */
		_checkForIntersectionObserverSupport() {
			const testCase = this.getLazyParam();

			this.isSupported = testCase !== null ? testCase : 'IntersectionObserver' in window;

			return this.isSupported;
		},

		/**
		 * for testing purpose - lazyModule parameter
		 * @returns {null}
		 */
		getLazyParam() {
			const neededParam = 'lazy',
				params = S.Utils?.GetUrlParameter?.getUrlParams(neededParam);

			// for testing page to toggle
			let testParam = null;

			for (const param in params) {
				const arrVal = params[param],
					keyLazy = arrVal[neededParam];

				if (keyLazy) {
					testParam = S.Utils.Helper._checkForBooleanValueFromAString(keyLazy);
				}
			}

			return testParam;
		},

		/**
		 * for testing purpose - lazyImages parameter
		 * @returns {null}
		 */
		getImgLazyParam() {
			const neededParam = 'lazyImg',
				params = S.Utils?.GetUrlParameter?.getUrlParams(neededParam);

			// for testing page to toggle
			let testParam = null;

			for (const param in params) {
				const arrVal = params[param],
					keyLazy = arrVal[neededParam];

				if (keyLazy) {
					testParam = S.Utils.Helper._checkForBooleanValueFromAString(keyLazy);
				}
			}

			return testParam;
		},

		/**
		 * default observer logic
		 *
		 * @param {HTMLElement|HTMLCollection|jQuery} element
		 * @param {Object} instance :: {this} - where this was called from
		 * @private
		 */
		_customObserve(element, instance) {
			// check if this element is already observed
			if (!this.isAlreadyObserved(element, false)) {
				// create object with the data reference
				const object = this._createDataObj(element, instance);

				// set reference
				this.customElementDataWeakMap.set(element, object);

				// init original observer for this element
				this.intersectionObserver.observe(element);
			}
		},

		/**
		 * @param {HTMLElement} element
		 * @private
		 */
		_removeLazyClass(element) {
			// just remove is lazy class (no need for differentiation)
			element.classList.remove(this.lazyloadClass.optinClass);
		},

		/**
		 * Usage from outside:
		 * S.Lazy._observe(<element>, <callback>, <object>, <Boolean>)
		 *
		 * @param {HTMLElement|HTMLCollection|jQuery} element   :: one or multiple elements
		 * @param {Function} cb                                 :: if u need a custom callBack - gets triggered when element gets in view instead of _lazyUpdate()
		 *                                                         S.Lazy._observe(... , cb.bind(this), ..., ...)
		 *
		 * @param {WeakMap} customWeakMap                       :: custom dataWeakMap {Boolean} true - create new weakMap (data container) || Object - hand over a custom weakMap and work with it || default
		 * @param {Object} cObserver                            :: custom observer if u have already created an custom observer from outside and have to add more elements to observe to the same observer
		 *
		 * @private
		 */
		_observe(element, cb, customWeakMap, cObserver) {
			const entries = element,
				dataWeakMap = customWeakMap || this.customElementDataWeakMap,
				observer = cObserver || this._checkForObserver();

			// if its unsupported, triggere CB when given or handle the element
			if (!this.isSupported) {
				this._handleUnsupportedObserver(entries, cb, element, dataWeakMap);

				return null;
			}

			for (const entry of entries) {
				// create object with the data reference
				const el = entry instanceof jQuery ? entry.get(0) : entry,
					object = this._createDataObj(el, false, cb, dataWeakMap);

				dataWeakMap.set(el, object);

				observer.observe(el);
			}

			return { observer: observer, weakMap: dataWeakMap };
		},

		/**
		 *
		 * @param {Array[HTMLElement|HTMLCollection|jQuery]}entries
		 * @param {Function} cb
		 * @param {HTMLElement} element
		 * @param {WeakMap} dataWeakMap
		 * @private
		 */
		_handleUnsupportedObserver(entries, cb, element, dataWeakMap) {
			// on default/notSupport call the cb with a custom return
			if (cb) {
				cb([
					{
						target: element,
						dataWeakMap: dataWeakMap,
						notSupported: !this.isSupported
					}
				]);

				return;
			}

			for (const entry of entries) {
				// create object with the data reference
				const el = entry instanceof jQuery ? entry.get(0) : entry,
					id = el.getAttribute('data-t-id'),
					instance = id ? S.Utils.Helper.getModuleInstance(id) : S.Utils.Helper.getTheNearestModuleInstance(el);

				// call _lazyUpdate in instances
				this.triggerLazyUpdate(instance, cb);
			}
		},

		/**
		 * check if a observer exist - if not create one
		 */
		_checkForObserver() {
			// check if observer exists
			if (!this.intersectionObserver && this.isSupported) {
				this.intersectionObserver = this.createNewObserver();
			}

			return this.intersectionObserver;
		},

		/**
		 * @param {HTMLElement} element
		 * @param {IntersectionObserver} cObserver :: custom observer
		 * @param {Object[WeakMap]} cWeakMap :: custom weak map
		 * @private
		 */
		_unObserve(element, cObserver, cWeakMap) {
			const observer = cObserver || this._checkForObserver();

			this._deleteDataWeakMapEntry(element, cWeakMap);

			this._removeLazyClass(element);

			observer?.unobserve(element);
		},

		/**
		 * default callback for IntersectionObserver
		 * @param entries
		 * @private
		 */
		_lazy(entries) {
			entries.forEach((entry) => {
				// for now, we are only interested in the intersection part, no threshold/margin etc.
				if (entry.isIntersecting) {
					const target = entry.target,
						customData = this.customElementDataWeakMap.get(target),
						weakmap = customData.customWeakMap || this.customElementDataWeakMap;

					// for images/iframes
					if ($(target).hasClass(this.lazyloadClass.lazysizes)) {
						// for iframes
						if (target?.tagName?.toLowerCase() === 'iframe') {
							this.unveilIframe(target);
						}
						// for images
						else {
							this.unveilLazyImages(target);
						}
					}
					// for modules
					else {
						this._emitUpdate(target, customData);
					}

					this._unObserve(target, null, weakmap);
				}
			});
		},

		/**
		 * @param context
		 * @param map
		 * @private
		 */
		_deleteDataWeakMapEntry(context, map) {
			const weakMap = map || this.customElementDataWeakMap,
				entry = context instanceof Element ? context : context?.target;
			weakMap.delete(entry);
		},

		/**
		 * Notifies instance that target element has to be loaded
		 *
		 * @param {HTMLElement} target
		 * @param {Object[WeakMap]} customData
		 * @private
		 */
		_emitUpdate(target, customData) {
			const instanceToCall = customData?.instance,
				callback = customData?.callback;

			// if we have added a callback to the element - just call the callback not (_lazyUpdate)
			if (typeof callback === 'function') {
				callback(target, customData);

				return;
			}

			if (instanceToCall) {
				if (typeof instanceToCall._lazyUpdate === 'function') {
					instanceToCall._lazyUpdate(target, customData);
					this._setInstanceToIsLoadedState(instanceToCall);
				}
			}
			else if (customData?.tId || customData?.$nearestModule) {
				// nearestModule is a fallback, if don`t needed delete this here and in the createObj Function
				const id = customData?.tId || customData?.$nearestModule?.data('t-id');

				const module = id ? document.applicationInstance?._sandbox?.getModuleById(id) : null;

				if (module && typeof module._lazyUpdate === 'function') {
					module._lazyUpdate(target, customData);
					this._setInstanceToIsLoadedState(module);
				}
			}
		},

		/**
		 * for all images
		 * ###############
		 * swiper handles this by itself and the lazy class need to be added before the initialization of the swiper.
		 * This whole functions and logic gets initialized after terrificJS is finished.
		 *
		 * swiper usage ::
		 * - module start() function if slides are already rendered
		 * - if they get added dynamically, handle this before they get added into the swiper cycle (rendered)
		 *
		 * S.Utils.SwiperHelper.addLazyClassToImg(slides, true);
		 *
		 * @param {HTMLElement|jQuery} context
		 */
		addLazyClassesOnImg(context) {
			const $ctx = context instanceof jQuery ? context : $(context),
				// only apply the lazysizes class if the images are not loaded already (via loadedClass) or have the optout-Class
				$images = $ctx.find(`img[data-src]:not(.${S.Lazy.lazyloadClass.loadedClass}):not(.${this.lazyloadClass.optoutClass})`);

			for (const img of $images) {
				const $img = $(img);

				if (!$img.closest('.swiper').length) {
					$img.addClass(this.lazyloadClass.lazysizes);
				}
			}
		},

		/**
		 * We need to catch the print event so that lazyloadable images will be shown
		 */
		unveilAllLazyImages() {
			$(`img.${this.lazyloadClass.lazysizes}`).each((_, el) => {
				this.unveilLazyImages(el);
				this._unObserve(el, null, false, false);
			});
		},

		/**
		 * differentiation between picture, img and img collection for separat handling to unveil
		 * @param {HTMLElement|HTMLCollection|jQuery} el
		 */
		unveilLazyImages(el) {
			const $el = $(el),
				$parent = $el.parent();

			// not for swiper, just for safeness
			// if ($el.closest('.swiper').length)
			// {
			//     return;
			// }

			if ($parent.is('picture')) {
				this.unveilPictureImg($parent);
			}
			else if ($el.is('img')) {
				this.unveilImg($el);
			}
			else {
				const $images = $el.find('img');

				for (const img of $images) {
					this.unveilImg(img);
				}
			}
		},

		/**
		 * @param $picture
		 */
		unveilPictureImg($picture) {
			const $source = $picture.find('source'),
				$img = $picture.find('img');

			$source.each((_, node) => {
				const $node = $(node);

				if ($node.data('srcset') !== null) {
					$node.attr('srcset', $node.attr('data-srcset'));
					$node.removeAttr('data-srcset');
				}
			});

			this.unveilImg($img);
		},

		/**
		 * @param {HTMLElement|jQuery} img
		 */
		unveilImg(img) {
			const isJquery = img instanceof jQuery,
				$img = isJquery ? img : $(img);

			if (
				!$img.hasClass(this.lazyloadClass.optoutClass) &&
				$img.hasClass(this.lazyloadClass.lazysizes) &&
				$img.data('src') !== null
			) {
				$img.attr('src', $img.attr('data-src'))
					.removeAttr('data-src');

				this.handleUnveilClasses($img);
			}
		},

		/**
		 * kept old logic with mm-ratio-container and is-lazyloaded class
		 * @param {HTMLElement|jQuery} context
		 */
		handleUnveilClasses(context) {
			const isJquery = context instanceof jQuery,
				$ctx = isJquery ? context : $(context);

			// for iframes
			if ($ctx.is('iframe')) {
				$ctx.each((_, iframe) => {
					const $iframe = $(iframe);

					$iframe.removeClass(this.lazyloadClass.lazysizes)
						.addClass(this.lazyloadClass.loadedClass); // remove spinner
				});
			}
			// for images
			else {
				$ctx.each((_, img) => {
					const $img = $(img);

					$img.removeClass(this.lazyloadClass.lazysizes)
						.addClass(this.lazyloadClass.loadedClass)
						.closest('div.mm-ratio-container')
						.addClass(this.lazyloadClass.loadedClass); // remove spinner
				});
			}
		},

		/**
		 * passive resize listener
		 * - kept the old logic from S.Globals.lazySizes.init()
		 */
		unveilResizerEvent() {
			window.addEventListener('resize', () => {
				S.Utils.delayed('lazylaod', 500, () => {
					$('figure.h-lazyloader img').each((index, item) => {
						if ($(item).height() > 0) {
							$(item).closest('span').css('height', 'auto');
						}
					});
				});

			}, { passive: true });
		},

		/**
		 * if user want to print stuff, unveil all
		 */
		lazyPrintEvent() {
			const exceptionForMyAdac = document.getElementsByClassName('l-outer--my-adac');

			// exception for myAdacDashboardBoxes, this triggers to open the boxes
			// but the boxes need to trigger it by themselves to trigger an event for the backend to GET the data
			if (exceptionForMyAdac.length) {
				return;
			}

			// prevent double print listener, no need
			// - for example, basicbtn-printpage.js and basicsocialshare.js
			if (!this.printListener) {
				this.printListener = window.matchMedia('print');

				window.onbeforeprint = this.emitLazyPrint.bind(this);

				this.printListener.addListener((mql) => {
					if (mql.matches) {
						mql.preventDefault();
					}
				});
			}
		},

		/**
		 * all necessary printing logic which should happen "before"
		 * the actual printing
		 */
		emitLazyPrint() {
			// reference all modules from terrificJS sandbox
			const instances = document.applicationInstance?._sandbox?._application?._modules;

			for (const instance in instances) {
				const currInstance = instances[instance],
					element = currInstance._ctx;

				if (currInstance.isLazy || currInstance._ctx.classList?.contains(this.lazyloadClass.optinClass)) {
					const customData = this.customElementDataWeakMap.get(element);

					this._unObserve(element, null, false);

					this._emitUpdate(element, customData);
				}
			}

			// check for visible slides and load the lazy images via (swiper.lazy.loadInSlide(<index>))
			S.Utils.SwiperHelper.reveilVisibleSwiperImagesForPrint();

			// show all images
			this.unveilAllLazyImages();

			// show all images
			this.unveilAllLazyIframes();
		},

		/**
		 * only for testing stuff
		 */
		testFunction() {
			$('body').append('<div class="mm-tester" style="z-index: 10000; display: block; height: 100px; width: 60px; background: black; color: white; text-align: center; padding: 20px 0; position: fixed; top: 100px; left: 10px;">klick me to update</div>');

			const $tester = $('body').find('.mm-tester');

			$tester.on('click', () => {
				S.Globals.lazyloadertx.init();
			});

			this.addTestAppendForUpdate();
		},

		testCSSSelector() {

			$('img[src="#"][width]:not([width=""])').each((index, el) => {
				const $el = $(el);

				if ($el.closest('.mm-nav-list').length) {
					$el.closest('li').css('border', '4px dotted red');

					return;
				}

				$el.closest('div').css('border', '4px dotted red');
			});
		},

		/**
		 * only for testing stuff
		 */
		addTestAppendForUpdate() {
			$('body').append('<div class="mm-add-tester" style="z-index: 10000; display: block; height: 60px; width: 60px; background: deeppink; color: white; text-align: center; padding: 5px 0; position: fixed; top: 200px; left: 10px;">add video</div>');
			$('body').append('<div class="mm-add-tester-image" style="z-index: 10000; display: block; height: 60px; width: 60px; background: lightblue; color: white' +
				'; text-align: center; padding: 5px 0; position: fixed; top: 260px; left: 10px;">add images</div>');
			$('body').append('<div class="mm-add-tester-print" style="z-index: 10000; display: block; height: 60px; width: 60px; background: mediumvioletred; color: white; text-align: center; padding: 5px 0; position: fixed; top: 320px; left: 10px;">print</div>');

			const $testerVideo = $('body').find('.mm-add-tester');
			const $testerImage = $('body').find('.mm-add-tester-image');
			const $testerPrint = $('body').find('.mm-add-tester-print');

			$testerVideo.on('click', () => {
				this.cloneAndAppendVideoForTesting();
			});

			$testerImage.on('click', () => {
				this.cloneAndAppendImagesForTesting();
			});

			$testerPrint.on('click', () => {
				S.Lazy.lazyPrintEvent();
			});
		},

		/**
		 * for testing purpose
		 * ##################
		 *
		 * e.g.: /pages-basic-components-molecules-basicimage
		 */
		cloneAndAppendVideoForTesting() {
			const sandbox = document.applicationInstance?._sandbox,
				instances = sandbox._application?._modules;

			this.$clones = [];

			for (const instance in instances) {
				const ctx = instances[instance]._ctx,
					$ctx = $(ctx);

				if ($ctx?.hasClass('m-basic-movie')) {
					// youtube to add
					this.$clones.push(instances[instance].$ctx.clone());
				}
			}

			for (const clone in this.$clones) {
				if (this.$clones[clone]?.hasClass('m-basic-movie')) {
					const $cloneEl = this.$clones[clone];

					this.cloneVideo($cloneEl.data('t-id'), $cloneEl, sandbox);
				}
			}
		},

		cloneVideo(id, clone, sandbox) {
			// youtube to add
			const module = clone ? sandbox?.getModuleById(clone.data('t-id')) : sandbox?.getModuleById(id);
			const $clone = clone || module.$ctx.clone();

			module.$ctx.parent().append($clone);

			const cloudinaryContainer = '.mm-cloudinary-container';
			let $cloudMovie = false;

			if ($clone.find(cloudinaryContainer).length) {
				$cloudMovie = $clone.find(cloudinaryContainer);
			}
			else if ($clone.hasClass(cloudinaryContainer)) {
				$cloudMovie = $clone;
			}

			// update ids for cloudinary
			if ($cloudMovie) {
				$cloudMovie.find('video').removeAttr('id');
				$cloudMovie.attr('id', `${$cloudMovie.attr('id')}_${'test'}`);
			}

			$clone.css('border', '4px dotted green');

			$clone.addClass('clone-added');
			$clone.addClass('is-lazy');
			$clone.removeAttr('data-t-id');
			T.Utils.Helper.initAdditionalModules($clone);

			return $clone;
		},

		/**
		 * for testing purpose
		 * ##################
		 *
		 * e.g.: /pages-basic-components-molecules-basicimage
		 */
		cloneAndAppendImagesForTesting() {
			this.$clones = [];

			// images has no js - so no module in the sandbox
			$('.m-basic-image').each((_, el) => {
				this.$clones.push($(el).clone());
			});

			for (const clone in this.$clones) {
				const $cloneEl = this.$clones[clone];

				if ($cloneEl.hasClass('m-basic-image')) {
					this.cloneImage($cloneEl?.data('t-id'), $cloneEl);
				}
			}
		},

		cloneImage(id, clone) {
			// image to add
			const $clone = clone;

			$('#l_main_content').append($clone);

			$clone.find('img').css('border', '4px dotted green');

			$clone.addClass('clone-added');
			$clone.addClass('is-lazy');
			$clone.removeAttr('data-t-id');
			T.Utils.Helper.initAdditionalModules($clone);

			return $clone;
		},

		/**
		 * handles the native loading=lazy attribute logic, what we need for img and
		 * videos and modules to keep running the same way as they intended
		 */
		nativeLazy() {
			jQuery('img[loading="lazy"]').on('load', (e) => {

				const $img = jQuery(e.target);

				$img.removeClass('lazyload').addClass('is-lazyloaded');
			});
		}
	};

})(jQuery);

// on ready functions
jQuery(document).ready(() => {
	S.Lazy.unveilResizerEvent();
	S.Lazy.lazyPrintEvent();
});
