(function ($) {
	'use strict';
	/**
	 * Award decorator implementation for the basickeywordfilter component.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class Lexicon
	 * @extends T.Module
	 */
	T.Module.BasicKeywordfilter.Mobilelayer = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.isVisible = 'is-visible';

			this._parent.start(resolve);
		},

		/**
		 * default cb function from observer when lazy loading
		 * is triggered or none lazy loading is needed/given
		 *
		 * @param target :: which initialized the lazy call
		 * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
		 * @private
		 */
		_lazyUpdate() {
			// init open layer click
			this.initOpenLayerClick();

			// init close layewr click
			this.initCloseLayerClick();

			// init reset filter click
			this.initResetFilterClick();
		},

		initOpenLayerClick: function () {
			this.$ctx.find('.js-mobile-layer-open').on('click', (e) => {
				$(e.currentTarget).parent().next().addClass(this.isVisible);
			});
		},

		initCloseLayerClick: function () {
			this.$ctx.find('.js-mobile-layer-close').on('click', (e) => {
				$(e.currentTarget).closest('.js-mobile-layer').removeClass(this.isVisible);
			});
		},

		initResetFilterClick: function () {
			this.$ctx.find('.js-filter-statusbar-reset').on('click', (e) => // eslint-disable-line
			{
				// set event for reset checkboxes
				this._events.emit('BasicKeywordfilter.resetCategories');

				// remove filter items
				this.$ctx.find('.mm-filterstatusbar').removeClass(this.isVisible).find('.mm-filer-item').remove();
			});
		}

	});
}(jQuery));
