(function ($) {
	'use strict';
	/**
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class AdacMaps.Sticky
	 * @extends T.Module
	 */
	T.Module.AdacMaps.Sticky = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$header: null,

		/** @type {jQuery} */
		$mainWrap: null,

		/** @type {Number} */
		actualStickyHeaderOffset: -1,

		/** @type {Object} */
		$listWrapper: null,

		/** @type {Object} */
		$mapSwitch: null,

		/** @type {Object} */
		$mapWrapper: null,

		/** @type {Object} */
		$mapHeadline: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$header = $('body > .l-outer > .m-basic-header').eq(0);

			this.$mainWrap = this.$ctx.find('.mm-maps-mgl-wrapper').eq(0);
			this.$mapHeadline = this.$ctx.find('.mm-head-map').eq(0);
			this.$resultHeadline = this.$ctx.find('.mm-result-headline').eq(0);
			this.$mapSwitch = this.$ctx.find('.mm-switch-map').eq(0);
			this.$mapWrapper = this.$mainWrap.find('.mm-map-wrapper').eq(0);
			this.$listWrapper = this.$mainWrap.find('.mm-result-list-wrap').eq(0);

			this.addEvents();
			this.checkRefreshHeader();

			this._parent.start(resolve);
		},

		/**
		 * adds scroll events to react to user scroll pos
		 */
		addEvents: function () {

			const that = this;

			let lastScrollVal = $(window).scrollTop();

			// window resize
			$(window).on('resize.adacMapsStickyResize', () => {
				S.Utils.delayed('adacmaps.initScrollBarOnResize', 40, () => {
					that.checkRefreshHeader('force');
				});
			});

			const callStickyCalc = () => {

				S.Utils.delayed(`adacmapssticky-${that.$ctx.data('t-id')}.preventScrollOverflow`, 40, () => {
					that.checkRefreshHeader('default');

					// check scroll direction
					const currentScrollVal = $(window).scrollTop();

					// if page is scrolled upwards
					if (currentScrollVal > lastScrollVal) {
						that.removeStickyHeadline();
					}

					else {
						that.setStickyHeadline();
					}

					lastScrollVal = currentScrollVal;
				});
			};

			document.removeEventListener('scroll', callStickyCalc, false);
			window.addEventListener('scroll', callStickyCalc, { passive: true });

			// listen to header events and recalc stickyness
			that._events.on('basicHeader.isStuck', () => {
				that.checkRefreshHeader('default');
			});

			that._events.on('basicHeader.isNotStuck', () => {
				that.checkRefreshHeader('default');
			});

			that._events.on('basicHeader.reducedHeightChanged', () => {
				that.checkRefreshHeader('default');
			});

			// add listeners to recheck sticky state on header anim events
			that._events.on('AdacMaps.viewSwitchToMap', () => {
				that.scrollMapIntoView();
			});
		},

		/**
		 * get height values from the header elems to set the sticky offset
		 *
		 * @param mod {String} optional - 'force' overrides the logic if offset === that.actualStickyHeaderOffset to init sticky no matter what.
		 */
		checkRefreshHeader: function (mod) {
			const that = this;
			let headerHeight = 0;

			if (that.$header.hasClass('is-stuck') || S.Utils.Helper.mq('desktop-l').matches) {
				headerHeight += that.$header.outerHeight();
			}

			if (that.actualStickyHeaderOffset !== headerHeight || mod === 'force') {
				that.initStickyMap(headerHeight);
				that.actualStickyHeaderOffset = headerHeight;
			}
		},

		/**
		 * initStickyMap
		 *
		 * @param {Number} offsetTop
		 */
		initStickyMap: function (offsetTop) {

			const that = this;

			setTimeout(() => {

				// store current scroll pos
				const lastScrollVal = $(window).scrollTop();

				that._events.emit('stickyDetachEvent.preventHeaderEvent');

				// detach and clear
				that.$mapWrapper.trigger("sticky_kit:detach").removeAttr('style');
				that.$mapSwitch.trigger("sticky_kit:detach").removeAttr('style');
				that.$mapHeadline.removeClass('is-fixed').removeAttr('style');
				that.$listWrapper.removeAttr('style');
				that.$mainWrap.removeAttr('style');

				// reassign last scroll pos to prevent scroll events
				// Hint: On detach(), the sticky elem is reinserted into the doc. flow.
				// That will cause a short scroll event, made by the browser, bc the doc is larger
				// than before and the browser tries to stay at the last (visible!) pos. That triggers
				// the header elem to pin
				// => freeze the current scroll pos
				// => Hint: delay name on purpose cause we use this trick in e.g. basic-anchor.js as well
				// => prevents double scroll call cause only the last delayed func is executed
				S.Utils.delayed('freezeWindowAtCurrentScrollPos', 5, () => {
					$(window).scrollTop(lastScrollVal);
				});

				// Tablet+
				if (S.Utils.Helper.mq('tablet').matches) {

					const mapBounds = that.$mapWrapper[0].getBoundingClientRect(),
						offsetRight = $(window).width() - mapBounds.width - mapBounds.left,
						offsetHead = that.$mapHeadline.length ? that.$mapHeadline.outerHeight() : that.$resultHeadline.outerHeight(),
						offsetMapTop = offsetTop + offsetHead,
						listWrapperWidth = that.$listWrapper.outerWidth();

					// due to markup structure the offset val is not correct (add 1px)
					if (offsetTop !== 0) {
						offsetTop += 1;
					}

					that.$mapWrapper.stick_in_parent({
						sticky_class: 'is-stuck',
						offset_top: offsetMapTop,
						parent: that.$ctx

					}).on("sticky_kit:stick sticky_kit:unbottom", () => {
						that.$mapWrapper.css('right', offsetRight);
						that.$listWrapper.css('width', listWrapperWidth);
						// check if headline should stick
						that.setStickyHeadline();

					}).on("sticky_kit:unstick", () => {
						that.$mapWrapper.removeAttr('style');
						that.$mapHeadline.removeClass('is-fixed').removeAttr('style');
						that.$listWrapper.removeAttr('style');
						that.$mainWrap.removeAttr('style');

					}).on("sticky_kit:bottom", () => {
						that.$mapWrapper.css('right', '0');
						that.$mainWrap.removeAttr('style');
					});
				}

				// Mobile
				else {

					that.$mapSwitch.stick_in_parent({
						sticky_class: 'is-stuck',
						offset_top: offsetTop,
						parent: that.$ctx
					}).on("sticky_kit:stick sticky_kit:unbottom", () => {
						that.$mapHeadline.addClass('is-fixed').css('top', offsetTop);
					}).on("sticky_kit:bottom sticky_kit:unstick", () => {
						that.$mapHeadline.removeClass('is-fixed').removeAttr('style');
					});
				}
			}, 40);
		},

		/**
		 * handle special case where headline should not stick but stickykit has already 'sticked'
		 *
		 */
		setStickyHeadline() {

			const that = this;

			S.Utils.delayed('adacmapssticky.setStickyHeadlineDelay', 200, () => {

				if (!that.$mapHeadline.hasClass('is-fixed') && S.Utils.Helper.mq('tablet').matches) {

					const mainWrapBounds = that.$mainWrap[0].getBoundingClientRect(),
						bottomVal = mainWrapBounds.height + mainWrapBounds.top,
						headHeight = that.$mapHeadline.outerHeight();

					if (bottomVal > that.actualStickyHeaderOffset && bottomVal > headHeight && mainWrapBounds.top < that.actualStickyHeaderOffset) {
						that.$mapHeadline.addClass('is-fixed').css('top', that.actualStickyHeaderOffset);
						that.$mainWrap.css('padding-top', headHeight);
					}
				}
			});

		},

		/**
		 * handle special case where headline should still stick but stickykit has already 'unsticked'
		 *
		 */
		removeStickyHeadline() {

			const that = this;

			S.Utils.delayed('adacmapssticky.removeStickyHeadlineDelay', 40, () => {
				if (that.$mapHeadline.hasClass('is-fixed') && S.Utils.Helper.mq('tablet').matches) {

					const mainWrapBounds = that.$mainWrap[0].getBoundingClientRect();

					if (mainWrapBounds.top < 0 && mainWrapBounds.height + mainWrapBounds.top < that.$mapHeadline.outerHeight()) {

						// store current scroll pos
						const lastScrollVal = $(window).scrollTop();

						that.$mapHeadline.removeClass('is-fixed').removeAttr('style');
						that.$mainWrap.removeAttr('style');

						S.Utils.delayed('freezeWindowAtCurrentScrollPos', 5, () => {
							$(window).scrollTop(lastScrollVal + that.$mapHeadline.outerHeight());
						});

					}
				}
			});
		},

		/**
		 * scroll map into view after switch was triggered
		 *
		 */
		scrollMapIntoView() {

			let offsetThresh = 0;

			S.Utils.delayed('adacMapsSticky.scrollIntoView', 200, () => {

				const mapBounds = this.$mapWrapper[0].getBoundingClientRect(),
					currentScrollVal = $(window).scrollTop();

				if (!this.$header.hasClass('headroom--unpinned')) {
					offsetThresh += this.$header.height();
				}

				if (mapBounds.top < offsetThresh) {
					$(window).scrollTop(currentScrollVal - offsetThresh - this.$mapSwitch.outerHeight() - Math.abs(mapBounds.top));

					// remove fixed styles (will be resetted if necessary by the next call)
					this.$mapHeadline.removeClass('is-fixed').removeAttr('style');
					this.checkRefreshHeader('default');
				}
			});
		},

		/**
		 * scroll to the highlighted li if there is one
		 *
		 */
		scrollToHighlight() {

			if (this.$listWrapper.find('li.is-highlight').length) {

				const $target = this.$listWrapper.find('li.is-highlight').eq(0),
					targetBounds = $target[0].getBoundingClientRect(),
					currentScrollVal = $(window).scrollTop();

				// we need to scroll down
				if (targetBounds.top < this.actualStickyHeaderOffset) {

					// buffer is used to save distance to top header instead of calculating the real value
					// => If you are motivated enough, see basictable.js and blame LazyLars
					const distanceToView = Math.abs(targetBounds.top) + this.actualStickyHeaderOffset + this.$mapHeadline.outerHeight(),
						bufferVal = 200,
						newScrollVal = currentScrollVal - distanceToView - bufferVal;

					$(window).scrollTop(newScrollVal);
				}

				// we need to scroll up
				else {
					const distanceToView = Math.abs(targetBounds.top) - this.actualStickyHeaderOffset - this.$mapHeadline.outerHeight(),
						newScrollVal = currentScrollVal + distanceToView;

					$(window).scrollTop(newScrollVal);

				}
			}
		}
	});
}(jQuery));
