(function ($)
{
    'use strict';
    /**
     * LayoutFooterDark module implementation.
     *
     * @author <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class LayoutHeaderNav
     * @extends T.Module
     */
    T.Module.LayoutFooterDark = T.createModule({

        /** @type {jQuery} $module */
        $module: null,

        /** @type {jQuery} $footerHeadlines */
        $footerHeadlines: null,

        /** @type {jQuery} $footerLinkLists */
        $footerLinkLists: null,

        /** @type {jQuery} $footerLinkWrapper */
        $footerLinkWrapper: null,

		$footerListClass: '.mm-footer-list',

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start(resolve)
        {
            this.$module = $(this._ctx);

            this.$footerHeadlines = this.$module.find('.mm-footer-list-headline');
            this.$footerLinkLists = this.$module.find(this.$footerListClass);
            this.$footerLinkWrapper = this.$module.find('.mm-footer-link-wrapper, .mm-footer-advertising');

            this.resize();
            this.initFooterHeadlineEvents();
            this.initFooterToggleMobile();

            resolve();
        },

		initFooterToggleMobile() {
			if (S.Utils.Helper.mq('desktop').matches)
			{
				//desktop
				this.$footerLinkLists.find('a').attr('tabindex', '0');
				this.$footerHeadlines.attr('tabindex', '-1');
				this.$footerHeadlines.off('keyup');
			}
			else
			{
				//mobile
				this.$footerLinkLists.find('a').attr('tabindex', '-1');
				this.$footerHeadlines.attr('tabindex', '0');
				this.$footerHeadlines.on('keyup', (headline) => {
					if(headline.which === 13)
					{
						const $clickedHeadline = $(headline.currentTarget),
							$clickedList = $clickedHeadline.closest(this.$footerListClass);

						if ($clickedList.hasClass('is-active'))
						{
							this.setMaxHeightForAccordion($clickedList, false);
							$clickedHeadline.next().find('a').attr('tabindex', '-1');
						}
						else
						{
							this.$footerLinkLists.find('a').attr('tabindex', '-1');
							this.cleanAllLinkLists().then(() =>
							{
								this.setMaxHeightForAccordion($clickedList, true);
								$clickedHeadline.next().find('a').attr('tabindex', '0');
							});
						}
					}
				});
			}
		},

        /**
         * init click events for all headline - and only trigger if its not on desktop
         */
        initFooterHeadlineEvents()
        {
            this.$footerHeadlines.on('click', (e) =>
            {
                if (!S.Utils.Helper.mq('desktop').matches)
                {
                    const $clickedHeadline = $(e.currentTarget),
                        $clickedList = $clickedHeadline.closest(this.$footerListClass);

                    if ($clickedList.hasClass('is-active'))
                    {
                        this.setMaxHeightForAccordion($clickedList, false);
                    }
                    else
                    {
                        this.cleanAllLinkLists().then(() =>
                        {
                            this.setMaxHeightForAccordion($clickedList, true);
                        });
                    }
                }

            });
        },

        /**
         *
         * @param {jQuery} $clickedList
         * @param {Boolean} fadeOut / true - fadeout | false - fadein
         */
        setMaxHeightForAccordion($clickedList, fadeOut)
        {
            const $footerLinkWrapper = $clickedList.find('.mm-footer-link-wrapper, .mm-footer-advertising');

            if (fadeOut)
            {
                $footerLinkWrapper.css({maxHeight: $footerLinkWrapper.prop('scrollHeight')});
                $clickedList.addClass('is-active');

                return;
            }

            $footerLinkWrapper.removeAttr('style');
            $clickedList.removeClass('is-active');
        },

        /**
         * removes max-height / style and resets all to normal
         */
        cleanAllLinkLists()
        {
            this.$footerLinkLists.removeClass('is-active');
            this.$footerLinkWrapper.removeAttr('style');

            return Promise.resolve();
        },

        /**
         * resize
         */
        resize: function ()
        {
            let oldWidth = $(window).width();

            // resizing
            $(window).resize(() =>
            {
                S.Utils.delayed(`layoutFooterDark-${  this.$module.data('tId')}`, 100, () =>
                {
                    // if you dont check for this, mobile devices will trigger if you swipe over the end of the site
                    if ($(window).width() !== oldWidth)
                    {
                        this.cleanAllLinkLists();

                        oldWidth = $(window).width();
                    }
					this.initFooterToggleMobile();
                });
            });
        },

    });
}(jQuery));
