'use strict';
(function ($) {
	/**
	 * PoiPerimeterSearchForm module implementation.
	 *
	 * @author Markus Hiller <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class PoiPerimeterSearchForm
	 * @extends T.Module
	 */
	T.Module.PoiPerimeterSearchForm = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		typeaheadSelection: null,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this.$queryInput = this.$ctx.find('input[name="plz_city"]');
			this._readConfig();
			this._registerListeners();
			resolve();
		},

		_readConfig: function _readConfig() {
			this.apigeocode = this.$ctx.data("apigeo");
			this.apireversegeocoding = this.$ctx.data("apireversegeocoding");
			this.formatRequest = this.$ctx.data("format-request");
			T.Utils.View.setDataSafe(this.$queryInput, "searchurl", T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apigeocode));
			T.Utils.View.setDataSafe(this.$queryInput, "subscription-key", T.Utils.Application.getApiMSubscriptionKey());
			this._searchEvent = this.$ctx.data('search-event');
			if (!this._searchEvent) {
				this._searchEvent = "PoiPerimeterSearch";
			}
			this._errorEvent = this.$ctx.data('error-event');
			if (!this._errorEvent) {
				this._errorEvent = "PoiPerimeterSearchError";
			}
		},

		_registerListeners: function _registerListeners() {
			this.$ctx.find('.js-submit').on('click', this._submitForm.bind(this));
			this.$ctx.find('form js-poiperimetersearch').submit(this._submitForm.bind(this));
			this.$queryInput.on('automcompleteSelect', this._handleSelection.bind(this));
			this.$queryInput.on('change', this._handleInputChange.bind(this));
			this.$queryInput.keypress((e) => { //NOSONAR
				if (13 === e.keyCode) {
					if (this._autocompletePending) {
						delete this._autocompletePending;
						return false;
					}
					this._submitForm(e);
				}
			});
			this.$ctx.find('.js-localizeme').on('click', this._getClientGeoLocation.bind(this));
		},
		_handleInputChange: function _handleInputChange() {
			this.inputChanged = true;
		},

		_handleSelection: function _handleSelection(term, item, e, origEvt) {
			if (origEvt && 'keydown' === origEvt.type && 13 === origEvt.keyCode) {
				this._autocompletePending = true;
			}
			delete this.inputChanged;
			const $elem = $(e),
				elemDataAttrs = $elem.data();
			this.typeaheadSelection = {
				Bezeichnung: elemDataAttrs.jsVal,
				Position1X: elemDataAttrs.jsPoint1.X,
				Position1Y: elemDataAttrs.jsPoint1.Y
			};
		},

		_submitForm: function _submitForm(ev) {
			ev.preventDefault();
			this._showAutolocationError(false);
			if (this.$queryInput.val() !== '') {
				if (this.inputChanged) {
					delete this.inputChanged;
					this._requestMapsResult();
				} else {
					this._triggerSearchEvent();
				}
			}
		},

		_triggerSearchEvent: function _triggerSearchEvent() {
			const perimeter = parseInt(this.$ctx.find(".js-perimeter").find(":selected").val());

			const searchData = {
				perimeter: perimeter,
				geoRectangle: this._getRequestData(perimeter)
			};
			if (this.typeaheadSelection.UserPosition) {
				searchData.userPosition = this.typeaheadSelection.UserPosition;
			}
			$(document).trigger(this._searchEvent, searchData);
		},

		_requestMapsResult: function _requestMapsResult() {
			const searchTerm = this.$queryInput.val();

			const url = T.Utils.Helper.updateUrlParameter({
				suchbegriff: searchTerm
			}, T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apigeocode));

			const options = {
				type: 'GET',
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() },
				url: url
			};
			T.Utils.Ajax.fragment(options, this._handleMapsSuccess.bind(this), this._errorCallback.bind(this));
		},

		_handleMapsSuccess: function _handleMapsSuccess(data) {
			this._doSearch(data);
		},

		_doSearch: function _doSearch(data, fromGeolocation) {
			if (data && data.Data && data.Data.length > 0) {
				this.typeaheadSelection = {
					Bezeichnung: data.Data[0].Kurztext,
					Position1X: data.Data[0].Punkt1.X,
					Position1Y: data.Data[0].Punkt1.Y
				};
				if (fromGeolocation) {
					this.typeaheadSelection.UserPosition = {
						X: data.Data[0].Punkt1.X,
						Y: data.Data[0].Punkt1.Y,
						Bezeichnung: data.Data[0].Kurztext || `${data.Data[0].Punkt1.X} ${data.Data[0].Punkt1.Y}`,
						Ort: data.Data[0].Stadt,
						PLZ: data.Data[0].Postleitzahl,
						Strasse: data.Data[0].StrasseNummer
					};
				}
				this._triggerSearchEvent();
			}
		},

		_getRequestData: function _getRequestData(perimeter) {
			const diagonal = parseInt(Math.sqrt((perimeter * perimeter) + (perimeter * perimeter)));
			let requestObj = {};

			if (this.typeaheadSelection) {
				requestObj = {
					Rectangle: {
						WestNord: {
							X: this.typeaheadSelection.Position1X - diagonal * 1000,
							Y: this.typeaheadSelection.Position1Y + diagonal * 1000
						},
						OstSued: {
							X: this.typeaheadSelection.Position1X + diagonal * 1000,
							Y: this.typeaheadSelection.Position1Y - diagonal * 1000
						}
					},
					Position: {
						X: this.typeaheadSelection.Position1X,
						Y: this.typeaheadSelection.Position1Y
					}
				};
			}
			return requestObj;
		},

		_errorCallback: function _errorCallback(err) {
			$(document).trigger(this._errorEvent, err);
		},

		_getClientGeoLocation: function _getClientGeoLocation() {
			this._showAutolocationError(false);
			if (this.typeaheadSelection) {
				delete this.typeaheadSelection.UserPosition;
			}
			const error = (() => {
				this._showAutolocationError(true);
			});
			if (!navigator.geolocation) {
				console.warn("This browser doesn't support geolocation");
				error();
				return;
			}
			const success = ((position) => {
				const p = T.Utils.Geo.latlonToMercator({ x: position.coords.longitude, y: position.coords.latitude });

				p.x = Math.round(p.x);
				p.y = Math.round(p.y);

				const renderFunc = ((foundAddress) => {
					this.$queryInput.val(foundAddress.Kurztext);
					this._doSearch({ Data: [foundAddress] }, true);
				});

				T.Utils.Map.getGeolocationFromApiByCoords(p, renderFunc, error, this.apireversegeocoding);
			});
			navigator.geolocation.getCurrentPosition(success, error);
		},

		_showAutolocationError: function _showAutolocationError(show) {
			this.$ctx.find('.js-auto-location-err').toggleClass('h-hidden', !show);
		}

	});
}(jQuery));
