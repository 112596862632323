(function ($) {
	'use strict';
	/**
	 * BasicLayerSurvey module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicLayerSurvey
	 * @extends T.Module
	 */
	T.Module.BasicLayerSurvey = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Number} */
		timeout: 5000,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			if (this.$ctx.data('timeout') !== 'undefined') {
				this.timeout = this.$ctx.data('timeout');
			}

			if (Cookies.get('layersurvey') === undefined) {

				this.$ctx.find('.a-basic-btn').add(this.$ctx.find('.mm-close')).click(() => {
					// create cookie by value 'true' for 30 days
					Cookies.set('layersurvey', 'true', { path: '/' });

					this.$ctx.hide();
				});
				this.showLayer();
			}

			resolve();
		},

		showLayer: function () {
			setTimeout(() => {
				this.$ctx.css({ "display": "block" });

				// make this focusable and keep the tabFocus inside this container
				S.Globals.TabFocus.setFocusToContext(this.$ctx);

				$('body').on('click.surveylayer', (el) => {
					if ($(el.target).closest('.mm-content').length < 1) {
						this.$ctx.hide();
						$('body').off('click.surveylayer');
					}
				});
			}, this.timeout);
		}
	});
}(jQuery));