/* eslint-disable sonarjs/cognitive-complexity */
(function ($) //NOSONAR
{
	'use strict';
	/**
	 * RufWeatherChart module implementation.
	 *
	 * @author t.grigoriadis <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class RufWeatherChart
	 * @extends T.Module
	 */
	T.Module.RufWeatherChart = T.createModule(
		{

			/** @var jQuery $module */
			$module: null,

			/** @var jQuery weatherChart */
			weatherChart: null,

			/** @var String weatherType */
			weatherType: '',

			/** @var javascript chartContainer */
			chartContainer: null,

			/** @var jQuery $chartContainer */
			$chartContainer: null,

			/** @var String url */
			url: null,

			/** @var inline json object */
			json: null,

			/** @var Numbers|Array|String Chartoptions defaults */
			neededRightGap: 5,
			neededLeftGap: 40,
			neededTopGap: 0,

			/** @var Boolean hideSnowValues */
			hideSnowValues: true,

			/** @var Array renderSeriesArray */
			renderSeriesArray: [],

			/** @var Object timeArray */
			timeArray: {
				categoryDate: [],
				tooltipTime: [],
				tooltipTimeDate: [],
				categoryHours: [],
			},

			/** @var jQuery $weatherIcons */
			$weatherIcons: null,

			/** @var jQuery chartBackground */
			$chartBackground: null,

			/** @var Number chartBackgroundWidth */
			chartBackgroundWidth: 0,

			/** @var Array tooltipDate */
			tooltipDate: [],

			/** @var Array neededSymbols */
			neededSymbols: [],

			/** @var String rain */
			rainAmount: [],

			/** @var String snow */
			snow: [],

			/** @var String wind */
			wind: [],

			/** @var String rainPercantage */
			rainPercantage: [],

			/** @var String temperatureCold */
			temperatureCold: [],

			/** @var String temperatureColdRange */
			temperatureColdRange: [],

			/** @var String temperatureWarm */
			temperatureWarm: [],

			/** @var String temperatureWarmRange */
			temperatureWarmRange: [],

			/** @var Number maxAmountOfData */
			maxAmountOfData: 0,

			/** @var Object mouseEvent */
			mouseEvent: null,

			/** @var Arrays|Object chartOptions */
			chartSeries: [],
			chartxAxis: [],
			chartyAxis: [],

			/** @var Arrays|Object mobileDisplaySequenceForecast */
			mobileDisplaySequenceForecast: [0, 2, 6, 8],
			mobileDisplaySequenceCurrentWeather: [0, 4, 8, 11],

			/** @var Arrays|Object needed categorie/series objects to create the chart content */
			mainCategorObject: null,
			rainPercantageObject: null,
			rainAmountObject: null,
			snowObject: null,
			celciusRange: null,
			temperatureWarmObject: null,
			temperatureColdObject: null,
			temperatureColdRangeObject: null,
			temperatureWarmRangeObject: null,

			start(resolve) { //NOSONAR
				this.$module = $(this._ctx);

				this.weatherType = this.$module.data('weatherType');
				this.url = this.$module.data('url');
				this.json = this.$module.data('json');

				// get variables
				this.$errorContainer = this.$module.find('.mm-error-wrapper');
				this.$chartContainer = this.$module.find('.mm-chart-box');
				this.chartContainer = this.$chartContainer.get(0);

				/**
				 * thanks to terrifc, you have to override the variables for each multiple instance
				 * if you dont do that, the variables are already filled with old values from the previous instance \o/
				 * terrifc uses the same object/variables instead of CLONING!!
				 */
				this.chartSeries = [];
				this.chartxAxis = [];
				this.chartyAxis = [];
				this.neededRightGap = 5;
				this.neededLeftGap = 50;
				this.neededTopGap = 0;
				this.renderSeriesArray = [];
				this.timeArray = {
					categoryDate: [],
					tooltipTime: [],
					tooltipTimeDate: [],
					categoryHours: []
				};
				this.$weatherIcons = null;
				this.$chartBackground = null;
				this.chartBackgroundWidth = 0;
				this.tooltipDate = [];
				this.neededSymbols = [];
				this.rainAmount = [];
				this.snow = [];
				this.wind = [];
				this.rainPercantage = [];
				this.temperatureCold = [];
				this.temperatureColdRange = [];
				this.temperatureWarm = [];
				this.temperatureWarmRange = [];
				this.maxAmountOfData = 0;
				this.mouseEvent = null;

				this.mainCategorObject = [
					{
						// xAxis 0
						type: 'category',
						categories: [],

						// responsible for the vertical grid lines
						gridLineWidth: 1,
						gridLineColor: 'rgba(128, 128, 128, 0.6)',
						className: 'mm-label-wrapper mm-label-wrapper--main',
						lineWidth: 0,
						opposite: true,
						labels: {
							useHTML: true,
							autoRotation: 0,
							formatter: (e) => {
								// if on mobile the last one is shown - for example if there is an odd number
								// the steps will be executed and than the last one will be added
								return `<span class="mm-main-label">${e.axis.categories[e.pos]}</span>`;
							},
							align: 'center',
						},
					},
					{
						// xAxis 0
						name: 'dateWithIcon',
						data: [],
					},
				];

				this.rainPercantageObject = [
					{
						// xAxis 1
						type: 'category',
						categories: [],
						offset: 5,
						title: {
							useHTML: true,
							text: '<span class="m-icon m-icon--umbrella"><img src="/assets/img/svg-sprite/weather-sprite.svg#umbrella" alt="umbrella"></span>',
							align: 'high',
							style: {
								position: 'relative',
								width: 20,
								height: 'auto',
							},
							x: 25,
							y: -18,
						},
						className: 'mm-label-wrapper mm-label-wrapper--rain-percantage',
						// line next to the xAxis - date
						lineWidth: 0,
						labels: {
							useHTML: true,
							autoRotation: 0,
							formatter: (e) => {
								// if the value is null or undefined
								if (e.axis.categories[e.pos] === null || e.axis.categories[e.pos] === undefined) {
									return `<div class="mm-sub-label">0 %</div>`;
								}

								return `<div class="mm-sub-label">${e.axis.categories[e.pos]}%</div>`;
							},
							align: 'center',
						},
					},
					{
						// xAxis 1
						name: 'Regenwahrscheinlichkeit',
						data: [],
						xAxis: 1,
						lineWidth: 0,
						minorGridLineWidth: 0,
						lineColor: 'transparent',
						minorTickLength: 0,
						marker: {
							states: {
								hover: {
									enabled: false,
								},
							},
						},
						tickLength: 0,
					},
				];

				this.rainAmountObject = [
					{
						// xAxis 2
						type: 'category',
						categories: [],
						offset: 22,

						// line above the labels
						lineWidth: 0,
						className: 'mm-label-wrapper mm-label-wrapper--rain-amount',
						labels: {
							useHTML: true,
							autoRotation: 0,
							formatter: (e) => {
								// if the value is null or undefined
								if (e.axis.categories[e.pos] === null || e.axis.categories[e.pos] === undefined) {
									return `<div class="mm-sub-label">0 mm</div>`;
								}

								return `<div class="mm-sub-label">${e.axis.categories[e.pos]} mm</div>`;
							},
							align: 'center',
						},
					},
					{
						//     // xAxis 2
						name: 'Regen',
						data: [],
						xAxis: 2,
					},
				];

				this.snowObject = [
					{
						// xAxis 4
						type: 'category',
						categories: [],
						offset: 50,

						title: {
							useHTML: true,
							text: '<span class="m-icon m-icon--snow"><img src="/assets/img/svg-sprite/weather-sprite.svg#snowflake" alt="snowflake"></span>',
							align: 'high',
							style: {
								position: 'relative',
								width: 20,
								height: 'auto',
							},
							x: 25,
							y: -22,
						},
						className: 'mm-label-wrapper mm-label-wrapper--snow-amount',
						// line above the labels
						lineWidth: 0,
						labels: {
							useHTML: true,
							autoRotation: 0,
							formatter: (e) => {
								// // if the value is null, only return null without suffix
								if (e.axis.categories[e.pos] === null || e.axis.categories[e.pos] === 0) {
									return `<div class="mm-sub-label">0 cm</div>`;
								}
								else {
									this.hideSnowValues = false;
									return `<div class="mm-sub-label">${e.axis.categories[e.pos]} cm</div>`;
								}
							},
							align: 'center',
						},
					},
					{
						name: 'Schnee',
						data: [],
						xAxis: 3,
					},
				];
				this.celciusRange = [
					{
						// yAxis 0
						title: {
							text: null,
						},
						// type: 'category',
						// categories: [500, 300, 200, 100, 50, 40, 30, 20, 0],

						// line above the labels
						lineWidth: 0,

						// tick interval
						tickInterval: 4,
						labels: {
							format: '{value}°C',
							reserveSpace: true,
							style: {
								fontSize: 'inherit',
								color: '#333',
							}
						},
					},
					{
						data: [],
						yAxis: 0,
						minPadding: 0,
					},
				];

				this.temperatureWarmObject = [
					{
						// yAxis 1
						title: {
							text: null,
						},

						// line above the labels
						lineWidth: 0,
					},
					{
						name: 'Temperatur',
						data: [],
						color: '#cc3333',
						zIndex: 2,
						marker: {
							enabled: false,
							symbol: 'circle',
						},

						// later for chart labels above the line
						dataLabels: {
							enabled: false,
							format: '{y}°',
							y: -5,
						},
						tooltip: {
							valueSuffix: '° C',
						},
					},
				];

				this.temperatureColdObject = [
					{
						// yAxis 2
						title: {
							text: null,
						},

						// line above the labels
						lineWidth: 0,
					},
					{
						name: 'Temperature-cold',
						data: [],
						type: 'line',
						zIndex: 2,
						color: '#33a3dc',

						// later for chart labels above the line
						dataLabels: {
							enabled: false,
							format: '{y}°',
							y: -10,
						},
						tooltip: {
							valueSuffix: '° C',
						},
					},
				];

				this.temperatureColdRangeObject = [
					{
						name: 'Range-Cold',
						data: [],
						type: 'arearange',
						lineWidth: 0,
						linkedTo: ':previous',
						color: '#deeff8',
						fillOpacity: 0.6,
						zIndex: 1,
						marker: {
							enabled: false,
						},
					},
				];

				this.temperatureWarmRangeObject = [
					{
						name: 'Range-warm',
						type: 'arearange',
						data: [],
						linkedTo: ':previous',
						color: '#f5dede',
						zIndex: 1,
						fillOpacity: 0.6,
						marker: {
							enabled: false,
						},
					},
				];

				if (typeof this.json !== 'undefined') {
					// get jsonData
					this.getJson();
				}

				resolve();
			},

			/**
			 * create the chartOptions
			 */
			buildChartObjects() {
				// maybe async/await
				this.processingYAxisData();
			},

			/**
			 * error handling // do error stuff in here
			 */
			error() {
				this.$errorContainer.html('<i class="fa fa-frown-o"></i> Failed loading data, please try again later');
			},

			/**
			 * get json and init chart
			 */
			getJson() {
				this.handleJson(this.json);
			},

			/**
			 * initialize the chart and get the right options
			 *
			 * @param {Object} json
			 */
			initChart() {
				// eslint-disable-next-line no-undef
				this.weatherChart = new Highcharts.Chart(this.setOptions(this), (chart) => {
					// after setting the options draw the icons/symbols
					this.onChartLoad(chart);
				});
			},

			/**
			 * handles json
			 * @param {Object} json
			 * @returns {*}
			 */
			handleJson(json) {
				this.handleData(json);
			},

			/**
			 * handle jsonDAta
			 * @param {Object} json
			 * @returns {boolean}
			 */
			handleData(json) {
				// if there is no data return false
				if (!json.data) {
					return false;
				}

				if (this.weatherType === 'currentWeather') {
					this.handleCurrentWeather(json);
				}
				else {
					this.handleForcastWeather(json);
				}

				return true;
			},

			/**
			 * handles the daily current weather forecast
			 *
			 * if data are null: every xAxis has a formatter which leave this field blank on the chart
			 * if data are 0 -> 0 + 'suffix'
			 *
			 * the rest handles this.tooltipFormatter() function -> tooltips
			 * @param {Object} json
			 */
			handleCurrentWeather(json) {
				const currentTime = moment().startOf('hour');

				let counter = 0;

				// set the max amount of data to show
				this.maxAmountOfData = 12;

				for (const data of json.data) {
					// get timestamp of data object
					// compare with current client time
					const dataTime = moment(data.timestamp).startOf('hour'),
						diffTime = dataTime.diff(currentTime, 'hours');

					// select the next 12 entrys, starting +1h from client time
					if (diffTime >= 1 && diffTime <= this.maxAmountOfData) {

						const currentData = data.data;

						// only take the first 12 datasets
						if (counter === this.maxAmountOfData) {
							break;
						}

						// get time / date
						this.transformDate(data.timestamp);

						let symbolName = currentData.weather_symbol;

						if (currentData.daynight === 'night') {
							symbolName = `${currentData.weather_symbol}-night`;
						}

						// push all necassery data in array
						this.neededSymbols.push(symbolName);
						this.wind.push(currentData.wind_speed);
						this.temperatureWarm.push(Math.round(parseInt(currentData.temperature)));
						this.rainPercantage.push(currentData.precipitation1h);
						this.rainAmount.push(currentData.multipop);
						this.snow.push(currentData.snow1h);

						counter++;
					}
				}

				// create array/object for highcharts
				this.buildChartObjects();
			},

			/**
			 * handles the 9 day forecaast
			 */
			handleForcastWeather(json) {
				// set the max amount of data to show
				this.maxAmountOfData = 9;

				let counter = 0;

				for (const data in json.data) {
					// only take the first 9 datasets
					if (counter === this.maxAmountOfData) {
						break;
					}

					// save data
					const currentData = json.data[data];

					// get time date
					this.transformDate(data);

					// remove the stringParts sym_/.svg to fit into the neededSymbol function
					// in the weatherForecast
					const optimizedSymbolString = currentData.r_sym.replace(/sym_|.svg/gi, '');

					// push all necassery data in array
					this.neededSymbols.push(optimizedSymbolString);
					this.temperatureWarm.push(this.roundNumbers(currentData.eps_h));
					this.temperatureWarmRange.push([this.roundNumbers(currentData.eps_hl), this.roundNumbers(currentData.eps_hh)]);
					this.temperatureCold.push(this.roundNumbers(currentData.eps_l));
					this.temperatureColdRange.push([this.roundNumbers(currentData.eps_ll), this.roundNumbers(currentData.eps_lh)]);

					counter++;
				}

				// create array/object for highcharts
				this.buildChartObjects();
			},

			/**
			 * function to round and parse the values from the json
			 * they have comma and 1-2 decimal
			 * @param neededValue
			 * @returns {*}
			 */
			roundNumbers(neededValue) {
				return Math.round(parseInt(neededValue));
			},

			/**
			 *
			 * @param input
			 * @returns {boolean|*}
			 */
			getHighestValueOfArray(input) {
				return Math.max.apply(null, input);
			},

			/**
			 *
			 * @param input
			 * @returns {boolean|*}
			 */
			getLowestValueOfArray(input) {
				return Math.min.apply(null, input);
			},

			/**
			 * calculates the min/max Celcius in a 4 sequence
			 * @returns {Array} celciusMinMaxArray
			 * @param weatherType
			 * @returns {*}
			 */
			calculateCelciusRange(weatherType = false) {
				let arrayToCalculateWith = this.temperatureWarm;
				const celciusMinMaxArray = [];

				if (weatherType) {
					arrayToCalculateWith = [...this.temperatureWarm, ...this.temperatureCold];
				}

				const lowestGivenValue = this.getLowestValueOfArray(arrayToCalculateWith),
					highestGivenValue = this.getHighestValueOfArray(arrayToCalculateWith),
					moduleNumberLowest = lowestGivenValue % 4,
					moduleNumberHighest = highestGivenValue % 4,
					lowestValue = lowestGivenValue - moduleNumberLowest,
					highestValue = (highestGivenValue - moduleNumberHighest) + 4;

				celciusMinMaxArray.push(lowestValue);

				// start with the lowestValue in 4 sequence
				let currentNumber = lowestValue;

				// while currentNummber isnt the same as the highest value, just count 4 up (4 sequence)
				while ((currentNumber + 4) < highestValue) {
					currentNumber += 4;

					// push all numbers in a 4 sequence in array
					celciusMinMaxArray.push(currentNumber);
				}

				// push at last the highest value in array
				celciusMinMaxArray.push(highestValue);

				return celciusMinMaxArray;
			},

			/**
			 * processing
			 */
			processingYAxisData() {
				// main category
				if (this.weatherType === 'currentWeather') {
					// deactivated: highcharts calcs the y-axis values them selves (this was imho never working)
					// => if needed, try solving this in the formatter where celcius-icons are added
					// this.celciusRange[0].categories = this.calculateCelciusRange();

					// put the wright categories in and fill the seriesData up with null | reasons look at fillArraysWithNull desc.
					this.celciusRange[1].data = this.fillArraysWithNull();

					// put the wright data in
					this.temperatureWarmObject[1].data = this.temperatureWarm;

					// build the array
					this.chartyAxis.push(this.celciusRange[0], this.temperatureWarmObject[0]);
					// add to series array
					this.chartSeries.push(
						this.celciusRange[1],
						this.temperatureWarmObject[1]
					);

					// push the correct series in, for the icon rendering
					this.renderSeriesArray.push(1);
				}
				else if (this.weatherType === 'forecast') {
					// deactivated: highcharts calcs the y-axis values them selves (this was imho never working)
					// => if needed, try solving this in the formatter where celcius-icons are added
					// this.celciusRange[0].categories = this.calculateCelciusRange();

					// put the wright categories in and fill the seriesData up with null | reasons look at fillArraysWithNull desc.
					this.celciusRange[1].data = this.fillArraysWithNull();

					// put the wright data in
					this.temperatureWarmObject[1].data = this.temperatureWarm;
					//enable dataLabels for this
					this.temperatureWarmObject[1].dataLabels.enabled = true;
					// put the wright data in
					this.temperatureColdObject[1].data = this.temperatureCold;
					//enable dataLabels for this
					this.temperatureColdObject[1].dataLabels.enabled = true;

					// for ranges, there is no yAxis
					this.temperatureWarmRangeObject[0].data = this.temperatureWarmRange;
					this.temperatureColdRangeObject[0].data = this.temperatureColdRange;

					// build the array
					this.chartyAxis.push(this.celciusRange[0], this.temperatureWarmObject[0], this.temperatureColdObject[0]);

					// add to series array
					this.chartSeries.push(
						this.celciusRange[1],
						this.temperatureWarmObject[1],
						this.temperatureWarmRangeObject[0],
						this.temperatureColdObject[1],
						this.temperatureColdRangeObject[0]
					);
				}

				this.processingXAxisData();
			},

			/**
			 * processing
			 */
			processingXAxisData() {
				// main category
				if (this.weatherType === 'currentWeather') {
					// put the wright categories in and fill the seriesData up with null | reasons look at fillArraysWithNull desc.
					this.mainCategorObject[0].categories = this.timeArray.categoryHours;
					this.mainCategorObject[1].data = this.fillArraysWithNull();

					// put the wright categories in and fill the seriesData up with null | reasons look at fillArraysWithNull desc.
					this.rainPercantageObject[0].categories = this.rainPercantage;
					this.rainPercantageObject[1].data = this.fillArraysWithNull();

					// put the wright categories in and fill the seriesData up with null | reasons look at fillArraysWithNull desc.
					this.rainAmountObject[0].categories = this.rainAmount;
					this.rainAmountObject[1].data = this.fillArraysWithNull();

					// put the wright categories in and fill the seriesData up with null | reasons look at fillArraysWithNull desc.
					this.snowObject[0].categories = this.snow;
					this.snowObject[1].data = this.fillArraysWithNull();

					// build the array
					this.chartxAxis.push(this.mainCategorObject[0], this.rainPercantageObject[0], this.rainAmountObject[0], this.snowObject[0]);
					this.chartSeries.push(
						this.mainCategorObject[1],
						this.rainPercantageObject[1],
						this.rainAmountObject[1],
						this.snowObject[1]
					);

					if (!this.checkArrayForNullValues(this.rainPercantage) && !this.checkArrayForNullValues(this.rainAmount) && !this.checkArrayForNullValues(this.snow)) {
						this.neededRightGap = 25;
					}

					this.neededTopGap = 30;
				}
				else if (this.weatherType === 'forecast') {
					// put the wright categories in and fill the seriesData up with null | reasons look at fillArraysWithNull desc.
					this.mainCategorObject[0].categories = this.timeArray.categoryDate;
					this.mainCategorObject[1].data = this.fillArraysWithNull();

					// build the array
					this.chartxAxis.push(
						this.mainCategorObject[0]
					);

					// add to series array
					this.chartSeries.push(
						this.mainCategorObject[1]
					);

					this.renderSeriesArray.push(5);

					// set
					this.neededTopGap = 80;
				}

				// init chart after all data are stored
				this.initChart(this.json);
			},

			/**
			 * this is necassery, to fill the data array for each category series
			 * if there were data in the array, the highcharstPlugin tries to create a chart of it and wont just display the catgeories
			 * @returns {*}
			 */
			fillArraysWithNull() {
				// eslint-disable-next-line no-unused-vars
				return [...Array(this.maxAmountOfData)].map((_, i) => null); //NOSONAR
			},

			checkArrayForNullValues(arrayToCheck) {
				return arrayToCheck.join().replace(/,/g, '').length === 0;
			},
			/**
			 * render function for weather symbols
			 * @param {Object} chart
			 * @param {Array} seriesNumber
			 */
			renderIcon(chart, seriesNumber = false) //NOSONAR
			{
				const neededSeries = seriesNumber ? seriesNumber : this.renderSeriesArray,
					$neededLabelWrapper = this.$module.find('.highcharts-xaxis-labels.mm-label-wrapper--main:not(g)'),
					pathToSVG = '/assets/img/svg-sprite/weather-sprite.svg#',
					weatherIcon = 'mm-weather-icon';

				let counter = 0;

				for (const series in neededSeries) {
					$.each(chart.series[neededSeries[series]].data, (i, point) => {
						// if plotY is undefined
						// for top catgeroy symbol (opposite: true) there is no plotY, it´s undefined
						if (point.plotY === undefined) {
							// main categories label top - mobile rendering
							if (!S.Utils.Helper.mq('tablet').matches) {
								const neededLabel = $neededLabelWrapper.find('span:not(.mm-main-label)').eq(counter),
									textSpanPosition = neededLabel.find('.mm-main-label');

								if (this.mobileDisplaySequenceForecast.includes(i) && counter !== 4) {
									// label min-width: 70px -> center it -> divided by 2
									chart.renderer
										.image(
											pathToSVG +
											this.neededSymbols[i],
											// explanation:
											//  wrapper postion from left (50px) +
											//      | label position from left +
											//      | inner text span positon left from neededLabel +
											//      | inner text span width /2 to center  -
											//      | icon width 35 px /2 to center
											50 + neededLabel.position().left + textSpanPosition.position().left + (textSpanPosition.width() / 2) - 26,
											chart.plotTop - 70,
											55,
											55
										)
										.attr({
											zIndex: 5,
											class: weatherIcon,
										})
										.add();

									// on mobile there has to be only 4 categories
									counter++;
								}
							}
							// main categories label top - tablet/desktop rendering
							else {
								const neededLabel = $neededLabelWrapper.find('span:not(.mm-main-label)').eq(i),
									textSpanPosition = neededLabel.find('.mm-main-label');
								chart.renderer
									.image(
										pathToSVG +
										this.neededSymbols[i],
										// explanation:
										//  wrapper postion from left +
										//      | label position from left +
										//      | inner text span positon left from neededLabel +
										//      | inner text span width /2 to center  -
										//      | icon width 35 px /2 to center
										50 + neededLabel.position().left + textSpanPosition.position().left + (textSpanPosition.width() / 2) - 26,
										chart.plotTop - 85,
										60,
										60
									)
									.attr({
										zIndex: 5,
										class: weatherIcon,
									})
									.add();
							}
						}
						else {
							// regular categories at bottom snow/rain/rainpercantage - mobile
							if (!S.Utils.Helper.mq('tablet').matches) {
								if (Number(i) % 2 === 0) {
									const iconPosX = point.plotX + chart.plotLeft - 30;
									let iconPosY = point.plotY + chart.plotTop - 60;

									// icon is overlapping x axis legend at chart.top => pos it beneath graph
									if (iconPosY < chart.plotTop) {
										iconPosY = iconPosY + 60 + 10;
									}

									// draw the icons
									chart.renderer
										.image(
											pathToSVG +
											this.neededSymbols[i],
											iconPosX,
											iconPosY,
											55,
											55
										)
										.attr(
											{
												zIndex: 5,
												class: weatherIcon,
											}
										)
										.add();
								}
							}
							// regular categories at bottom snow/rain/rainpercantage - tablet/desktop
							else {
								const iconPosX = point.plotX + chart.plotLeft - 30;
								let iconPosY = point.plotY - chart.plotTop;

								// icon is overlapping x axis legend at chart.top => pos it beneath graph
								if (iconPosY < chart.plotTop) {
									iconPosY = iconPosY + 60 + 5;
								}

								// draw the icons
								chart.renderer
									.image(
										pathToSVG +
										this.neededSymbols[i],
										iconPosX,
										iconPosY,
										60,
										60
									)
									.attr(
										{
											zIndex: 5,
											class: weatherIcon,
										}
									)
									.add();
							}
						}
					});
				}

				// all drawn icons
				this.$weatherIcons = this.$module.find('.mm-weather-icon');
			},

			/**
			 * save needed variables for charts after chartInit
			 */
			saveNeededVariables() {
				// plot/chart background
				this.$chartBackground = this.$module.find('.highcharts-plot-background');
			},

			/**
			 * save needed values for charts after chartInit
			 */
			saveNeededValues() {
				if (this.$chartBackground === null) {

					this.saveNeededVariables();
				}

				this.chartBackgroundWidth = this.$chartBackground.width();
			},

			/**
			 * Build and return the Highcharts options structure
			 * @returns {options}
			 */
			setOptions(thisContext) //NOSONAR
			{
				const that = thisContext;

				const options = {
					chart: {
						renderTo: this.chartContainer,

						// spacingTop: 10,
						// spacingBottom: 100,
						// marginBottom: 100,
						marginRight: this.neededRightGap,
						marginLeft: this.neededLeftGap,
						marginTop: this.neededTopGap,

						plotBorderWidth: 1,

						events: {
							load: (e) => {
								const chart = e.target;

								this.$chartContainer.bind(`mousemove.chart-${this.$module.data('tId')}`, (e) => {
									S.Utils.delayed(`mouseEvent.chart-${this.$module.data('tId')}`, 5, () => {
										this.mouseEvent = chart.pointer.normalize(e);
									});
								});
							},
							redraw: (e) => {
								// if there are images, remove them and redraw them on resize
								if (this.$weatherIcons !== null) {
									this.$weatherIcons.remove();
									this.renderIcon(e.target);
								}
							},
						},
					},

					title: {
						text: '',
					},

					credits: {
						enabled: false,
					},

					// xAxis
					xAxis: [],

					// yAxis
					yAxis: [],

					legend: {
						enabled: false,
					},

					// custom default options for all series and type´s
					plotOptions: {
						series: {
							marker: {
								symbol: 'circle',
								marker: {
									enabled: false,
								},
							},
							lineWidth: 3,
							dataLabels: {
								style: {
									color: '#333',
									fontSize: 'inherit',
									textOutline: 0,
									fontWeight: 'normal',
								}
							},
							labels: {
								style: {
									color: '#333',
									fontSize: 'inherit',
									textOutline: 0,
									fontWeight: 'normal',
								}
							}
						},
						line: {
							marker: {
								radius: 4,
								enabled: false,
								enabledThreshold: 5,
							},
						},
						arearange: {
							enableMouseTracking: false,
						},
					},

					tooltip: {
						shared: true,
						useHTML: true,

						// hover on xAxis - color
						crosshairs: {
							color: 'rgba(0, 0, 0, 0.05)',
						},
						formatter: function () {
							return that.tooltipFormatter(this);
						},
						positioner: (labelWidth) => {
							//if the user hovers on reload on the chart, the event isnt declared yet, so just return a default "x" value
							if (this.mouseEvent !== null) {
								this.highChartInstance = this.$chartContainer.highcharts();
								const leftHalf = this.mouseEvent.chartX < (this.highChartInstance.plotWidth / 2);

								// for mobile
								if (!S.Utils.Helper.mq('tablet').matches) {
									return {
										x: leftHalf ? this.highChartInstance.plotWidth - labelWidth + 40 : this.highChartInstance.plotLeft + 0,
										y: 0
									};
								}

								// for tablet/desktop
								return {
									x: leftHalf ? this.highChartInstance.plotWidth - labelWidth + 10 : this.highChartInstance.plotLeft + 10,
									y: 20
								};
							}

							// if the user hovers on reload on the chart, the event isnt declared yet, so just return a default "x" value
							return {
								x: 40,
							};
						},
					},

					series: [],
				};

				// set options
				options.xAxis = this.chartxAxis;
				options.yAxis = this.chartyAxis;
				options.series = this.chartSeries;

				// if weatherType - currentWeather each of the four xAxis need a formatter on mobile
				if (this.weatherType === 'currentWeather') {
					options.responsive = {
						rules: [
							{
								condition: {
									// maxWidth: 768 - 40,
									// maxWidth: 728,
									maxWidth: 720,
								},
								chartOptions: {
									xAxis: [
										{
											labels: {
												useHTML: true,
												steps: 0,
												autoRotation: 0,
												formatter: (e) => {
													return this.mobileDisplaySequenceCurrentWeather.includes(e.pos) ? `<span class="mm-main-label">${e.value}</span>` : null;
												},
												overflow: "allow",
											}
										},
										{
											labels: {
												useHTML: true,
												steps: 0,
												autoRotation: 0,
												formatter: (e) => {
													return this.mobileDisplaySequenceCurrentWeather.includes(e.pos) ? `<div class="mm-sub-label">${e.value}%</div>` : null;
												},
												overflow: "allow",
											}
										},
										{
											labels: {
												useHTML: true,
												steps: 0,
												autoRotation: 0,
												formatter: (e) => {
													return this.mobileDisplaySequenceCurrentWeather.includes(e.pos) ? `<div class="mm-sub-label">${e.value} mm</div>` : null;
												},
												overflow: "allow",
											}
										},
										{
											labels: {
												useHTML: true,
												steps: 0,
												autoRotation: 0,
												formatter: (e) => {
													return this.mobileDisplaySequenceCurrentWeather.includes(e.pos) ? `<div class="mm-sub-label">${e.value} mm</div>` : null;
												},
												overflow: "allow",
											}
										},
									],
								},
							},
						],
					};
				}
				else {
					options.responsive = {
						rules: [
							{
								condition: {
									maxWidth: 692,
								},
								chartOptions: {
									xAxis: [
										{
											labels: {
												useHTML: true,
												steps: 0,
												autoRotation: 0,
												formatter: (e) => {
													return this.mobileDisplaySequenceForecast.includes(e.pos) ? `<span class="mm-main-label">${e.value}</span>` : null;
												},
												overflow: "allow",
											}
										},
									]
								}
							}
						]
					};
				}

				return options;
			},

			/**
			 * this function will be called, after the Chartoptions are loaded
			 */
			onChartLoad(chart) {
				// render icons
				this.renderIcon(chart);

				// save needed variables
				this.saveNeededVariables();

				// set needed values
				this.saveNeededValues();

				// init resize function
				this.resize();

				// hide snow amount if all values are 0 || null
				if (this.hideSnowValues === true) {
					this.$module.find('.mm-label-wrapper--snow-amount').addClass('is-hidden');
				}
			},

			/**
			 * timestamp function for later
			 * @param jsonDate
			 * @returns {Object} convertedTime
			 */
			transformDate(jsonDate) {
				const neededLanguage = navigator.language,
					date = new Date(jsonDate);

				const optionsTime = { weekday: 'short', month: 'numeric', day: 'numeric' },
					optionsTooltipDate = { weekday: 'long', day: 'numeric', month: 'long' },
					optionsTooltipTimeDate = {
						weekday: 'long',
						day: 'numeric',
						month: 'long',
						hour: '2-digit',
						minute: '2-digit'
					},
					optionsCategoryHours = { hour: '2-digit', minute: '2-digit' };

				const categoryDate = new Intl.DateTimeFormat(neededLanguage, optionsTime).format(date),
					tooltipTime = new Intl.DateTimeFormat(neededLanguage, optionsTooltipDate).format(date),
					tooltipTimeDate = new Intl.DateTimeFormat(neededLanguage, optionsTooltipTimeDate).format(date),
					categoryHours = new Intl.DateTimeFormat(neededLanguage, optionsCategoryHours).format(date);

				// push all necassery data in the timeArray
				this.timeArray.categoryDate.push(categoryDate);
				this.timeArray.tooltipTime.push(tooltipTime);
				this.timeArray.tooltipTimeDate.push(tooltipTimeDate);
				this.timeArray.categoryHours.push(categoryHours);
			},

			/**
			 * creates dynamically the HTML for the individual charts
			 * @param {Object} currentChart
			 * @returns {string} HTML for the tooltip container
			 */
			tooltipFormatter(currentChart) //NOSONAR
			{
				const //get the chart object
					chart = currentChart.points[0].series.chart,
					//get the categories array
					categories = chart.xAxis[0].categories,
					htmlClassTooltip = '<li class="mm-tooltip">';
				let neededIndex = 0;
				let tooltipHTML;

				//compute the neededIndex of corr y value in each data arrays
				while (currentChart.x !== categories[neededIndex]) {
					neededIndex++;
				}

				// switch for different date display in tooltip
				if (this.weatherType === 'currentWeather') {
					tooltipHTML = `<div class="mm-tooltip-wrapper"><span class="mm-headline">${this.timeArray.tooltipTimeDate[neededIndex]}</span><span class="mm-weather-icon"><img src="/assets/img/svg-sprite/weather-sprite.svg#${this.neededSymbols[neededIndex]} " alt="${this.neededSymbols[neededIndex]}-icon"></span><ul>`;
				}
				else {
					tooltipHTML = `<div class="mm-tooltip-wrapper"><span class="mm-headline">${this.timeArray.tooltipTime[neededIndex]}</span><span class="mm-weather-icon"><img src="/assets/img/svg-sprite/weather-sprite.svg#${this.neededSymbols[neededIndex]} " alt="${this.neededSymbols[neededIndex]}-icon"></span><ul>`;
				}

				// HTML Markup creation -
				// loop through series array
				$.each(chart.series, (i, series) => {
					const currentData = series.data[neededIndex];

					switch (series.name) {
						case 'Schnee':

							if (this.hideSnowValues === false) {
								const neededSnowValue = currentData.category !== null ? `${currentData.category} cm` : '0 cm';

								// do snow stuff here
								tooltipHTML += `<li class="mm-tooltip mm-tooltip--snow">${series.name}: ${

									//use neededIndex to get the y value
									neededSnowValue}</li>`;
							}
							break;
						case 'Regen':
							// eslint-disable-next-line no-case-declarations
							const neededRainValue = currentData.category !== null ? `${currentData.category} mm` : '0 mm'; //NOSONAR

							// do rain stuff here
							tooltipHTML += `${htmlClassTooltip + series.name}: ${

								//use neededIndex to get the y value
								neededRainValue}</li>`;
							break;
						case 'Regenwahrscheinlichkeit':
							// eslint-disable-next-line no-case-declarations
							const neededRainPercantageValue = currentData.category !== null ? `${currentData.category} %` : '0 %'; //NOSONAR

							// do rain percentage stuff here
							tooltipHTML += `${htmlClassTooltip + series.name}: ${

								//use neededIndex to get the y value
								neededRainPercantageValue}</li>`;
							break;
						case 'dateWithIcon':
							break;
						case 'Temperatur':
							// eslint-disable-next-line no-case-declarations
							const neededTemperatureValue = currentData.y !== null ? `${currentData.y}° C` : '-'; //NOSONAR

							// if its currentWeather - SeriesName
							if (this.weatherType === 'currentWeather') {
								// do temperature stuff here
								tooltipHTML += `${htmlClassTooltip + series.name}: ${

									//use neededIndex to get the y value
									neededTemperatureValue}</li>`;
							}
							// if its forecast - Höchsttemperatur
							else {
								// do temperature stuff here
								tooltipHTML += `<li class="mm-tooltip">Höchsttemperatur: ${

									//use neededIndex to get the y value
									neededTemperatureValue}</li>`;
							}
							break;
						case 'Range-warm':
							// eslint-disable-next-line no-case-declarations
							const neededTemperatureRangeLowValue = currentData.low !== null ? `${currentData.low}° C` : '-',
								neededTemperatureRangeHighValue = currentData.high !== null ? `${currentData.high}° C` : '-'; //NOSONAR

							// do temperature range stuff here
							tooltipHTML += `<li class="mm-tooltip mm-sub-tooltip">(Bandbreite ${

								//use neededIndex to get the y value
								neededTemperatureRangeHighValue} bis ${neededTemperatureRangeLowValue})</li>`;
							break;
						case 'Temperature-cold':
							// eslint-disable-next-line no-case-declarations
							const neededTemperatureColdValue = currentData.y !== null ? `${currentData.y}° C` : '-'; //NOSONAR

							// do temperature cold stuff here
							tooltipHTML += `<li class="mm-tooltip">Tiefsttemperatur: ${

								//use neededIndex to get the y value
								neededTemperatureColdValue}</li>`;
							break;
						case 'Range-Cold':

							// eslint-disable-next-line no-case-declarations
							const neededTemperatureRangeColdLowValue = currentData.low !== null ? `${currentData.low}° C` : '-',
								neededTemperatureRangeColdHighValue = currentData.high !== null ? `${currentData.high}° C` : '-'; //NOSONAR

							// do temperature range stuff here
							tooltipHTML += `<li class="mm-tooltip mm-sub-tooltip">(Bandbreite ${

								//use neededIndex to get the y value
								neededTemperatureRangeColdLowValue} bis ${neededTemperatureRangeColdHighValue})</li>`;
							break;
						default:
							break;
					}
				});

				// only for currentWeather show wind-speed
				// this should be only display in the tooltip
				if (this.weatherType === 'currentWeather') {

					const neededWindValue = this.wind[neededIndex] !== null ? `${this.wind[neededIndex]} km/h` : '-';

					// do snow stuff here
					tooltipHTML += `<li class="mm-tooltip"> Windböen: ${

						//use neededIndex to get the y value
						neededWindValue}</li>`;
				}

				tooltipHTML += '</ul></div>';

				return tooltipHTML;
			},

			/**
			 * resize funciton
			 */
			resize() {
				let oldWidth = $(window).width();

				// resizing
				$(window).resize(() => {
					S.Utils.delayed(`resizeFunction-${this.$module.data('tId')}`, 100, () => {
						// if you dont check for this, mobile devices will trigger if you swipe over the end of the site
						if ($(window).width() !== oldWidth) {
							// save values for chartModule
							this.saveNeededValues();

							oldWidth = $(window).width();
						}
					});
				});
			},
		});
}(jQuery));
