(function($) {
	'use strict';
	/**
	 * BasicToggleContainer module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicToggleContainer
	 * @extends T.Module
	 */
	T.Module.BasicToggleContainer = T.createModule({
		start: function(resolve) {
			this.$ctx = $( this._ctx );

			// classes as strings
			this.toggleHeadline = '.mm-toggle-headline';
			this.toggleContentClass = '.mm-toggle-content';
			this.toggleContainer = '.mm-toggle-container';
			this.isCurrent = 'is-current';

			this.$toggleBtn = this.$ctx.find(this.toggleHeadline);
			this.$toggleContent = this.$ctx.find(this.toggleContentClass);
			this.$deleteBoxtrigger = this.$ctx.find('.mm-close');
			this.$deleteBtn = $('.js-delete-elem');
			this.$modHeight = this.$ctx.height();

			this.addEventListener();
			this.initTabToggleId();

			resolve();
		},

		/**
		 * adds listeners, e.g. listen to global clone events etc.
		 */
		addEventListener()
		{
			let deleteId = 0;

			// click events

			// toggle content
			this.$toggleBtn.off('click.basicToggleContainer.open').on('click.basicToggleContainer.open', (e) =>
			{
				if ( $(e.target).hasClass('mm-toggle-headline')) {
					this.toggleContent( $(e.target).closest(this.toggleContainer).find(this.toggleContentClass) );
				}
			});

			// delete
			this.$deleteBoxtrigger.off('click.basicToggleContainer.delete').on('click.basicToggleContainer.delete', (e) => {

				// store deleteId
				if ($(e.target).parents('mm-toggle-container')) {
					deleteId = $(e.target).closest(this.toggleContainer).data('js-delete-id');
				}

				// dunno what that is
				$('.mm-delete-index').text(deleteId);

				// call func to delete on layer click
				this.$deleteBtn.off('click').on('click', () => {
					this.deleteToggleElem(deleteId, e.target);
				});
			});

			// fires in S.Utils.Clone.js after reinit is done
			$(document).on('utilsClone.reinit.finished', (e, $elem) => {

				// find last toggle elem and open it
				if ( $($elem).data('t-id') === this.$ctx.data('t-id') ) {

					S.Utils.delayed(`basicToggleContainer_${this.$ctx.data('t-id')}.cloneDelay`, 10, () => {
						this.$ctx.find(' > .mm-formular > .mm-toggle-container').each((index, $wrapper) => {

							// close all others
							if (!$($wrapper).is(':last-child')) {
								$($wrapper).find('> .is-current').removeClass(this.isCurrent);
							} else {
								this.toggleContent($($wrapper).find('> .mm-toggle-content'));
							}
						});
					});
				}
			});
		},

		initTabToggleId()
		{
			const that = this;
			let countToggles = 1;

			that.$ctx.find('.mm-toggle-container .mm-toggle-headline').each( (index, toggleEl) => {
				$(toggleEl).closest(this.toggleContainer).attr('data-js-delete-id', countToggles);

				if ( $(toggleEl).find('.mm-t-index').length ) {
					$(toggleEl).find('.mm-t-index').text(countToggles);
				}
				countToggles++;
			});
		},

		/**
		 * opens / closes content
		 *
		 * @param content {Object} - what needs to be toggled;
		 */
		toggleContent(content)
		{
			const $content = $(content),
				isCurrent = '.is-current';

			if(!$content.hasClass(this.isCurrent))
			{
				$content.slideDown('slow', function() {
					$('html, body').animate({scrollTop:$content.position().top});
				}).addClass(this.isCurrent);

				$content.prev(this.toggleHeadline).addClass(this.isCurrent);
			}
			else
			{
				$content.filter(isCurrent).slideUp('slow',() =>
				{
					$content.filter(isCurrent).removeClass(this.isCurrent);
				});

				$content.prev(this.toggleHeadline).filter(isCurrent).removeClass(this.isCurrent);
			}
		},

		/**
		 * delete toggle elem
		 *
		 * @param deleteId {String}
		 *
		 * @param $trigger {Object} - what triggered ^^
		 */
		deleteToggleElem(deleteId, $trigger)
		{
			this.initTabToggleId();

			// find prev to open
			const $prevSibling = $($trigger).closest(this.toggleContainer).prev();

			if ( $prevSibling.hasClass('mm-toggle-container') )
			{
				$prevSibling.find(this.toggleHeadline).addClass(this.isCurrent);

				if (S.Utils.Helper.mq('mobile').matches) {
					$prevSibling.find(this.toggleContentClass).slideDown(400).addClass(this.isCurrent);
				}
				else
				{
					$prevSibling.find(this.toggleContentClass).fadeIn(400).addClass(this.isCurrent);
				}
			}

			this._events.emit('BasicToggleTabBox.update');

			if ( $.magnificPopup.instance.isOpen )
			{
				$.magnificPopup.close();
			}

			this.$ctx.find(`[data-js-delete-id="${ deleteId  }"]`).remove();

			this.modifyCountings();
		},

		/**
		 * modifies counts like in headlines (e.g. "Route n") and data-attr. like clone counts
		 */
		modifyCountings()
		{

		}
	});
}(jQuery));
