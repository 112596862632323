'use strict';
(function ($) {
	/**
	 * SecondhandVehiclesList module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>
	 * @namespace T.Module
	 * @class SecondhandVehiclesList
	 * @extends T.Module
	 */
	T.Module.SecondhandVehiclesList = T.createModule({
		SESSIONKEY_BRANDID: "Application.SecondhandVehiclesList.BrandId",

		dataJsId: 'data-js-id',

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();

			this._readConfig();
			this._getBrands();
			resolve();
		},
		_errorApilNotAvailableCallback: function _errorApilNotAvailableCallback() {
			T.Utils.View.stopLoader();
			this._showError(true);
		},
		_getBrands: function _getBrands() {
			this._showError(false);
			T.Utils.View.startLoader();
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.config.apiBrands);
			const options = {
				type: "GET",
				url: apiUrl
			};
			T.Utils.Ajax.fragment(options,
				this._handleGetBrandSuccess.bind(this),
				this._errorApilNotAvailableCallback.bind(this));
		},
		_getBrandHtmlId: function _getBrandHtmlId(brand) {
			return `vehicleBrand${(brand || {}).Id}`;
		},
		_getVehicles: function _getVehicles(brandId) {
			this._showError(false);
			T.Utils.View.startLoader();

			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.config.apiVehicles);
			const options = {
				type: "GET",
				data: {
					markeId: brandId
				},
				url: apiUrl
			};
			T.Utils.Ajax.fragment(options,
				this._handleGetVehiclesSuccess.bind(this),
				this._errorApilNotAvailableCallback.bind(this));
		},
		_handleGetBrandSuccess: function _handleGetBrandSuccess(data) {
			if (data && data.Success && data.Data &&
				data.Data.Marken && data.Data.Marken.Facets && data.Data.Marken.Facets.length > 0) {
				this.brands = data.Data.Marken.Facets;
				this._loadBrands();
				this._loadStoredSearch();
			}
			T.Utils.View.stopLoader();
		},
		_handleGetVehiclesSuccess: function _handleGetVehiclesSuccess(data) {
			if (data && data.Success && data.Data && data.Data.Informationen &&
				data.Data.Informationen.Facets && data.Data.Informationen.Facets.length > 0) {
				this.vehicles = data.Data.Informationen.Facets;
				this._loadVehicles();
			}
			else {
				this.$ctx.find(".js-no-vehicle").toggleClass("h-hidden", false);
			}
			T.Utils.View.stopLoader();
		},
		_loadBrands: function _loadBrands() {
			if (!this.brands || this.brands.length === 0) {
				return;
			}

			const rowsCount = this.config.maxRows || 6;
			const columnCount = Math.ceil(this.brands.length / rowsCount);
			for (let index = 0; index < columnCount; index++) {
				const $column = this.$columnTemplate.clone();
				const subItems = this._buildBrandColumn(index, columnCount, rowsCount);
				this._loadColumnBrands($column, subItems);
				$column.append(this.$swiperLazyPreloaderTemplate.clone());
				this.$brands.append($column);
			}

			this.$vehiclesbrands.attr('data-t-name', this.$vehiclesbrands.data('tx-name'));
			this.$vehiclesbrands.removeAttr('data-tx-name');
			T.Utils.Helper.initAdditionalModules(this.$vehiclesbrands);

		},
		_loadStoredSearch: function _loadStoredSearch() {
			const storedBrandId = T.Utils.Store.get(this.SESSIONKEY_BRANDID, T.Utils.Store.SESSION);
			if (storedBrandId) {
				this.$vehicles.toggleClass("h-none", false);
				this._loadVehiclesByBrandId(storedBrandId);
			}

		},
		_loadVehicles: function _loadVehicles() {
			if (!this.vehicles || !this.vehicles.length) {
				return;
			}

			this.vehicles.forEach((vehicle) => {
				const name = (this.currentBrand && 0 !== vehicle.Modell.toLowerCase().indexOf(`${this.currentBrand.Name.toLowerCase()} `)
					? `${this.currentBrand.Name} ` : "") + vehicle.Modell;

				const $item = this.$vehicleTemplate.clone();
				const detailUrl = T.Utils.Helper.appendURIPath(
					this.config.detailPageUrl,
					this.config.detailPageUrlSfx.interpolate({
						id: vehicle.InfoId,
						name: T.Utils.Helper.createSeoUrl(name)
					}));
				$item.find(".js-vehicle-link").attr("href", detailUrl);

				const $image = $item.find(".js-result-image");
				const src = this.config.baseVehicleMediaUrl.interpolate({
					Media: vehicle.Bild
				});
				$image.find("picture source").attr("data-srcset", src);
				$image.find("picture source").attr("srcset", src);

				const $img = $image.find("img");
				$img.find("img").attr("src", src);
				$img.attr("data-src", src);
				$img.attr("alt", name);

				const $name = $item.find(".js-vehicle-name");
				$name.text(name);

				const $pdf = $item.find(".js-vehicle-pdf");
				const pdfUrl = this.config.baseVehicleMediaUrl.interpolate({
					Media: vehicle.Pdf
				});
				$pdf.attr('href', pdfUrl);

				this.$vehicles.append($item);
			});

			T.Utils.Helper.parseDynamicModules(this.$vehicles);
			// Init lazyloader for new adding images
			S.Globals.lazyloadertx.init();
		},
		_loadVehiclesByBrandId: function _loadVehiclesByBrandId(brandId) {
			this.$ctx.find(".js-no-vehicle").toggleClass("h-hidden", true);
			this.$vehicles.empty();
			for (let index = 0; index < this.brands.length; index++) {
				if (this._getBrandHtmlId(this.brands[index]) === brandId) {
					this.currentBrand = this.brands[index];
					break;
				}
			}
			if (this.currentBrand) {
				this.$vehicles.attr("data-js-content-id", this._getBrandHtmlId(this.currentBrand));
				this._getVehicles(this.currentBrand.Id);
			}
		},
		_buildBrandColumn: function _buildBrandColumn(colIndex, colCount, rowCount) {
			let rows = 0;
			return this.brands.filter((brand, index) => {
				const isBrand = index % colCount === colIndex && rows <= rowCount;
				if (isBrand) {
					rows++;
				}
				return isBrand;
			});
		},
		_loadColumnBrands: function _loadColumnBrands($column, items) {
			for (let index = 0; index < items.length; index++) {
				const $item = this.$brandTemplate.clone();
				$item.attr(this.dataJsId, this._getBrandHtmlId(items[index]));
				$item.on('click', this._onBrandClicked.bind(this));

				const $image = $item.find(".js-img");
				const src = this.config.baseBrandImageUrl.interpolate({
					Bild: items[index].Bild
				});
				$image.attr("src", src);
				$image.attr("data-src", src);
				$image.attr("alt", items[index].Name);
				$image.attr("title", items[index].Name);
				$image.attr(this.dataJsId, this._getBrandHtmlId(items[index]));

				$column.append($item);
			}
		},
		_onBrandClicked: function _onBrandClicked(e) {
			const currentBrandId = $(e.target).attr(this.dataJsId);
			this._loadVehiclesByBrandId(currentBrandId);
			T.Utils.Store.set(this.SESSIONKEY_BRANDID, currentBrandId, T.Utils.Store.SESSION);

		},
		_readConfig: function _readConfig() {
			this.$swiperLazyPreloaderTemplate = $(this.$ctx.find("#js-swiper-lazy-preloader").html());
			this.$columnTemplate = $(this.$ctx.find("#js-column-template").html());
			this.$brandTemplate = $(this.$ctx.find("#js-brand-template").html());
			this.$brands = this.$ctx.find(".js-brands");
			this.$vehiclesbrands = this.$ctx.find(".js-vehiclesbrands");
			this.$vehicles = this.$ctx.find(".js-brandingresult");
			this.$vehicleTemplate = $(this.$ctx.find("#js-vehicle-template").html());
			this.$vehiclesResults = this.$ctx.find(".js-vehicles-results");
			this.config = this.$ctx.data('config');

		},
		_showError: function _showError(show) {
			this.$ctx.find(".ll-error-msg").toggleClass('h-hidden', !show);
		},

	});
}(jQuery));