(function($) {
	'use strict';
	/**
	 * BasicTableToggle module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicTableToggle
	 * @extends T.Module
	 */
	T.Module.BasicTableToggle = T.createModule({

		/** @type {Object} */
		activefilters: null,

		/** @type {String} */
		filterGroupId: null,

		/** @type {Array} */
		$filterGroup: [],
		toggleContentClass: '.mm-toggle-content',
		activeClass: '.is-active',
		msgCheckboxClass: '.js-msg-checkbox',
		toggleRowClass: '.js-toggle-row',
		filterLayerClass: '.mm-filter-layer',
		toggleTableClass: '.js-basic-toggle-table-wrap',

		start: function(resolve) {
			const that = this;

			this.$ctx = $(this._ctx);

			if (!this.$ctx.closest('.m-basic-info-layer').length && !this.$ctx.closest('.ll-lightbox-inner').length) {
				this.$ctx.find('table').wrap('<div class="js-main-border" />');
			}

			this.$toggleBtn = this.$ctx.find('.js-toggle-btn');
			this.$toggleRow = this.$ctx.find(this.toggleRowClass);
			this.$FilterIconBtn = this.$ctx.find('.mm-customize-view');
			this.$FilterTextBtn = this.$ctx.find('.mm-customize-text');
			this.$filter = this.$ctx.find(this.filterLayerClass);
			this.$table = this.$ctx.find('.mm-table');
			this.$tableToggleWrap = this.$table.closest(this.toggleTableClass);

			this.filterGroupId = this.$ctx.data('js-filtergroup-id');

			this.activefilters = [];

			// collect each tableToggle elem with same filter id to sync filter states
			if ( typeof this.filterGroupId !== 'undefined' && this.filterGroupId.length ) {
				this.$filterGroup = [];
				this.$filterGroup.push(document.querySelectorAll(`.m-basic-table-toggle[data-js-filtergroup-id="${this.filterGroupId}"]`));
			}

			this.initToggle();

			this.setWidthOfToggleContent();

			this.showFilter();

			if (this.$ctx.data('sticky') !== false)
			{
				this.initStickyFnc();
			}

			this.initFiltering();

			this.resizeModule();

			this._events.on('BasicTableToggle.syncFilter', (context, activeFilters) => {

				if(context !== that.$ctx){
					that.activefilters = activeFilters;
					that.filterTables();
					that.resetInfoMessage();

				}
			});

			this.shortenTable();
			resolve();
		},

		initToggle() {
			this.$ctx.find(this.toggleRowClass).each((index, element) => {
				this.manageTabIndexForLinks($(element), -1);
			});

			this.$toggleBtn.on('click', (el) => {
				const clickedElement = $(el.currentTarget);

				const content = clickedElement.next(this.toggleRowClass);
				const $toggleContent = content.find(this.toggleContentClass);

				if(!content.hasClass('is-active')) {
					const currentHeight = $toggleContent.css('height', '100%').height();

					$toggleContent.css('height', '0');

					content.addClass('is-active').find(this.toggleContentClass)
						.animate({
							height: currentHeight
						}, () => {
							$toggleContent.css('height', '100%');
						});
					clickedElement.addClass('is-active').attr('aria-expanded', true);
					this.manageTabIndexForLinks(content, 0);
				}
				else {
					content.filter(this.activeClass).find(this.toggleContentClass)
						.animate({
							height: "0"
						});
					content.filter(this.activeClass).removeClass('is-active');
					clickedElement.filter(this.activeClass).removeClass('is-active').attr('aria-expanded', false);
					this.manageTabIndexForLinks(content, -1);
				}
			});

			this.$toggleBtn.on('keyup', (el) => {
				if(el.which === 13) {
					$(el.currentTarget).trigger('click');
				}
			});
		},

		manageTabIndexForLinks: function(elem, index) {
			elem.find('a, button').attr('tabindex', index);
		},

		setWidthOfToggleContent() {
			const that = this;
			S.Utils.Helper.mq( 'mobile' ).addListener(() => {
				if (S.Utils.Helper.mq('mobile').matches) {
					this.$ctx.find(that.toggleContentClass).closest('td').prop('colspan', '4');
					this.$ctx.find('.js-placeholder').remove();
				}
				else
				{
					this.$ctx.find(that.toggleContentClass).closest('td').prop('colspan', '1');
					this.$ctx.find(that.toggleRowClass).append($('<td class="js-placeholder"></td><td class="js-placeholder"></td><td class="js-placeholder"></td>'));
				}
			});
		},

		showFilter()
		{
			const that = this;

			this.$FilterIconBtn.on('click', function (e)
				{
					e.stopPropagation();
					if(that.$filter.hasClass('is-active')){
						that.$filter.removeClass('is-active');
					}else {
						that.$filter.addClass('is-active');
						that.closeFilter();
					}
				}
			);

			this.$FilterTextBtn.on('click', function (e)
				{
					e.stopPropagation();
					if(that.$filter.hasClass('is-active')){
						that.$filter.removeClass('is-active');
					}else {
						that.$filter.addClass('is-active');
						that.closeFilter();
					}
				}
			);
		},

		closeFilter()
		{
			$('.l-main').on('click.closeFilter', (e) => {
				const $target = $(e.target);

				if ($target.closest('div').hasClass('mm-filter-layer-close') || !$target.closest(this.$filter.find('.mm-layer-header, .mm-layer-content')).length && this.$filter.hasClass("is-active")) {
					this.$filter.removeClass('is-active');
					this.resetFilter(this.$filter);

					$('.l-main').off('click.closeFilter');
				}

				if ($target.hasClass('js-filtering') && this.$filter.hasClass("is-active")) {
					this.$filter.removeClass('is-active');
				}
			});
		},

		initFiltering()
		{
			this.$filter.find('.mm-layer-content ul input').on('click', (e) => {
				this.inputCombinations(e);
				this.handleDisabled(e);
			});

			this.$filter.find('.js-filtering').on('click', (e) => {
				e.preventDefault();

				this.filterTables();
				this.syncFilter();
			});
		},

		syncFilter()
		{
			const that = this;
			const filterData = this.$filter.find('.mm-layer-content');

			that.activefilters = [];

			filterData.find('ul input').each( (i, el) => {

				let inputName = $(el).attr('id');
				const divider = inputName.lastIndexOf('_');

				inputName = inputName.substring(0, divider);

				if($(el).is(':checked')) {
					that.activefilters.push(inputName);
				}

				$.each(this.$filterGroup, (index, elem) => {
					$(elem).find(that.filterLayerClass).each((index2, elem2) => {
						$(elem2).find(`[id^="${inputName}"]`).prop('checked', $(el).is(':checked'));
					});
				});
			});

			this._events.emit('BasicTableToggle.syncFilter', this.$ctx, that.activefilters);
		},

		resetFilter ($el)
		{
			const that = this;

			if(that.activefilters.length) {
				$el.find('ul input').prop('checked', false);
				$.each(that.activefilters, (i, e) => {
					$el.find(`[id^="${e}"]`).prop('checked', true);
					this.$filter.find('.mm-error-msg').removeClass('is-active');
				});
			} else {
				$el.find('ul input[type="checkbox"]').prop('checked', true);
				$el.find('ul input[type="radio"]').first().prop('checked', true);
				this.$filter.find('.mm-error-msg').removeClass('is-active');
			}
		},

		inputCombinations(e)
		{
			const $target = $(e.target),
				defaultInputs = this.$filter.find('ul input[type="checkbox"]').closest('div').not('.js-combination').find('input'),
				combineElementId = $target.data('combination'),
				combineElement = $target.closest(this.filterLayerClass).find(`#${  combineElementId}`);

			if($target.attr('type') === 'radio'){

				if($target.closest('.js-combination').length) {

					// show info message about auto enabled categories
					$.each(this.$filterGroup, (index, elem) => {
						$(elem).find('.js-msg-vehicle').addClass('is-active');
						$(elem).find(this.msgCheckboxClass).removeClass('is-active');
					});

					if (!combineElement.prop('checked')) {
						combineElement.trigger('click');
					}

					defaultInputs.each((i, el) => {
						if ($(el).prop('checked')) {
							$(el).trigger('click');
						}
					});
				} else {

					// hide info message about auto enabled categories
					$.each(this.$filterGroup, (index, elem) => {
						$(elem).find('.js-msg-vehicle').removeClass('is-active');
					});

					defaultInputs.each((i, el) => {
						if (!$(el).prop('checked')) {
							$(el).trigger('click');
						}
					});
				}

			}
		},

		/**
		 * resets info message about least amount of active cbx in all grouped filters (except the one, that fired => see call() )
		 */
		resetInfoMessage()
		{
			this.$ctx.find(this.msgCheckboxClass).removeClass('is-active');
		},

		filterTables()
		{
			const that = this,
			$target = this.$filter.find('.mm-layer-content ul input');

			$target.each((i, el) => {

				const uncheckedInputsData = $(el).data('filter-product'),
					hideTableHead = this.$ctx.find(`th[data-filter-product="${uncheckedInputsData}"]`),
					hideTableHeadIndex = hideTableHead.index();

				if ($(el).attr('type') === 'checkbox') {
					if (!$(el).prop('checked')) {

						hideTableHead.addClass('is-hidden');

						if ( that.$filterGroup.length ) {
							$.each(this.$filterGroup, (index, groupElem) => {
								$(groupElem).find('tr').each((i, elem) => {
									$(elem).find('td').eq(hideTableHeadIndex).addClass('is-hidden');
								});
							});
						}

					} else {

						hideTableHead.removeClass('is-hidden');

						if ( that.$filterGroup.length ) {

							$.each(this.$filterGroup, (index, groupElem) => {
								$(groupElem).find('tr').each((i, elem) => {
									$(elem).find('td').eq(hideTableHeadIndex).removeClass('is-hidden');
								});
							});
						}
					}
				}
			});
		},

		handleDisabled(e)
		{
			const $target = $(e.target);

			if($target.attr('type') === 'checkbox'){
				if($target.closest('.mm-filter-form').find('.js-combination input[type="radio"]').is(':checked')){
					e.preventDefault();
					return;
				}

				if(!$target.closest('.mm-list-input').siblings().find('input').is(':checked'))
				{
					e.preventDefault();
					this.$filter.find(this.msgCheckboxClass).addClass('is-active');
				} else {
					this.$filter.find(this.msgCheckboxClass).removeClass('is-active');
				}
			}

		},

		initStickyFnc()
		{
			this.checkRefreshHeader();

			// add listeners to recheck sticky state on header anim events
			this._events.on( 'basicHeader.isStuck', () => {
				this.checkRefreshHeader();
			});

			// add listeners to recheck sticky state on header anim events
			this._events.on( 'basicHeader.isNotStuck', () => {
				this.checkRefreshHeader();
			});
		},

		/**
		 * get height values from the header elems to set the sticky offset
		 */
		checkRefreshHeader: function ()
		{
			const anchorIndicatorFix = 16,
				that = this;

			let offset = 0;
			const $header = $('body > .l-outer > .m-basic-header');

			if ( $header.hasClass('is-stuck') ) {
				offset = $header.outerHeight();
			}

			if (that.hasAnchor && S.Utils.Helper.mq('tablet').matches)
			{
				const anchorHeight = that.$ctx.closest('.m-basic-anchornav').find('.mm-anchorlist').outerHeight() + anchorIndicatorFix;
				offset += anchorHeight;
			}

			if (this.actualStickyHeaderOffset !== offset)
			{
				this.initStickyHeader(offset);
				this.actualStickyHeaderOffset = offset;
			}
		},

		/**
		 * initStickyHeader
		 *
		 * @param {Number} offsetTop
		 */
		initStickyHeader: function (offsetTop) {

			const that = this;

			S.Utils.delayed(`basicTableToggle-${this.$ctx.data('t-id')}.stickyDelay`, 200, () => {

				const stickyAllowed = !that.$tableToggleWrap.find('.is-clone').length;

				if (that.$table.find('> thead').length && stickyAllowed ) {

					if (!that.$clonedTableHeader) {
						that.$clonedTableHeader = that.$table.clone(true);
						that.$clonedTableHeader
							.addClass('is-cloned-header')
							//.attr('aria-hidden', true)
							.insertBefore(that.$table)
							.wrap('<div class="js-basic-table-header-clone-wrap" />');

						// is not visible and has no tab elements
						that.$table.find('> thead').attr('aria-hidden', true);
						that.$clonedTableHeader.find('tbody, thead ~ tr').attr('aria-hidden', true);

						// clean tabindex
						that.$table.find('th [tabindex]').removeAttr('tabindex');
						that.$table.find('th a, th button').attr('tabindex', -1);
						that.$clonedTableHeader.find('tr[tabindex], td [tabindex]').removeAttr('tabindex');
						that.$clonedTableHeader.find('td a, td button').attr('tabindex', -1);
					}

					// store current scroll pos
					const lastScrollVal = $(window).scrollTop();

					that._events.emit('stickyDetachEvent.preventHeaderEvent');

					// detach
					that.$clonedTableHeader.parent().trigger("sticky_kit:detach");

					S.Utils.delayed('freezeWindowAtCurrentScrollPos', 5, () => {
						$(window).scrollTop(lastScrollVal);
					});

					that.$clonedTableHeader.parent().show().stick_in_parent({
						sticky_class: 'is-sticky-header',
						offset_top: offsetTop,
						parent: that.$clonedTableHeader.closest(that.toggleTableClass)
					}).on("sticky_kit:stick sticky_kit:unbottom", function () {
						// if in zoom layer => set unset zoom icon sticky too
						that.$clonedTableHeader.closest(that.toggleTableClass).siblings('.js-table-zoom').addClass('is-sticky');
						that.$filter.addClass('is-sticky');
						that.$filter.css({'width': that.$clonedTableHeader.width(), 'top': that.$clonedTableHeader.parent().css('top')});

					}).on("sticky_kit:unstick sticky_kit:bottom", function () {
						that.$clonedTableHeader.closest(that.toggleTableClass).siblings('.js-table-zoom').removeClass('is-sticky');
						that.$filter.removeClass('is-sticky');
						that.$filter.css({'width': '100%', 'top': 'auto'});
					});

					that.updateStickyHeader();
				}
			});
		},

		/**
		 * updateStickyHeader
		 *
		 */
		updateStickyHeader: function ()
		{
			const that = this;

			if (that.$clonedTableHeader && that.$table.find('> thead').length && !that.$table.closest(that.toggleTableClass).find('.is-clone').length) {

				S.Utils.delayed(`basicTableToggle-${this.$ctx.data('t-id')}.updateHeader`, 50, () => {

					const trHeight = that.$table.find('thead').eq(0).outerHeight();

					// set width + height
					that.$clonedTableHeader.parent().css({
						width: that.$table.outerWidth(),
						height: `${trHeight + 1}px`
					});

					that.$table.css({
						left: 'auto',
						marginTop: `${-trHeight - 1}px`
					});

					that.$clonedTableHeader.parent().css({
						left: 'auto'
					});

					that.$clonedTableHeader.parent().show();
				});
			}
		},

		resizeModule: function ()
		{
			$(window).resize(() => {

				S.Utils.delayed('basicTableToggle.resizeBuffer', 40, () => {
					this.checkRefreshHeader();
				});
			});
		},

		shortenTable: function ()
		{
			const that = this;
			const count = parseInt(this.$ctx.data('table-short-count')) * 2;
			const btn = this.$ctx.find('.js-less, .js-more');
			S.Utils.Helper.shortenTable(that.$ctx, count);

			btn.on('click', () => {
				this.updateStickyHeader();
			});

		},
	});
}(jQuery));
