'use strict';
(function () {

	T.Utils = T.Utils || {};

	T.Utils.Format = {

		normalizeNumber: function (text) {
			return text.replace(/\D/g, '');
		},

		normalizeSpace: function normalizeSpace(text) {
			return text ? text.trim().replace(/\s+/g, " ") : '';
		},

		getBlobFromDataUri: function getBlobFromDataUri(dataUri, mimetype = 'application/pdf') {
			const byteCharacters = window.atob(dataUri);

			const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));

			const byteArray = new Uint8Array(byteNumbers);
			return new Blob([byteArray], { type: mimetype });
		},

		viewDateToIsoDate: function viewDateToIsoDate(viewDate) {
			if (!viewDate) {
				return '';
			}

			try {
				const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
				return new Date(viewDate.replace(pattern, '$3-$2-$1')).toISOString();
			} catch (e) {
				return '';
			}
		}

	};
})();