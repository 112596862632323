(function ($) {
	'use strict';
	/**
	 * Backend decorator implementation for the LayoutHeaderMetanav component.
	 *
	 * @author  <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class Backend
	 * @extends T.Module.LayoutHeaderMetanav
	 */
	T.Module.LayoutHeaderMetanav.Backend = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/**
		* Initialize.
		*
		* @param {function} resolve
		*/
		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.$logoutForm = this.$ctx.find('#logout');
			//if user back from external page Login or LandingPage
			if (!T.Utils.Auth.state) {
				T.Utils.Auth.state = $('input[name=__RequestVerificationToken]').val();
			}

			this._parent.start(resolve);
		},

		/**
		 * Initializes login setup
		 *
		 * @private
		 */
		_loginHandler: async function () {
			const self = this,
				pathIndex = window.location.href.indexOf('?');
			this.basePath = window.location.href.substr(0, pathIndex) || window.location.href;

			this.$loggedoutContent.find('a.js-login').on('click', (ev) => {
				ev.preventDefault();
				T.Utils.Auth.authorize();
			});
			this.$btnLogin.on('click', (ev) => {
				ev.preventDefault();
				T.Utils.Auth.authorize();
			});
			this.$btnRegister.attr(
				'href',
				await T.Utils.Auth.makeRegisterURL()
			);

			this.$logoutForm.attr(
				'action',
				await T.Utils.Auth.makeLogoutURL()
			);

			this.$btnLogout.on('click', async function (e) {
				e.preventDefault();
				self.$ctx.find('form#logout').submit();
				await T.Utils.Auth.logout();

			});
			T.Utils.Auth.checkStatus();
		},

		_handleIdentityLoaded: function () {
			const self = this;
			const identity = T.Utils.Auth.getLocalIdentity();
			if (identity && identity.Firma) {
				self.$ctx.find('.js-login span').text(identity.Firma);
			} else if (identity) {
				self.$ctx.find('.js-login span').text(`${identity.Vorname} ${identity.Nachname}`);
			}

			self.$ctx.addClass('o-layout-header-metanav--loggedin');
			self.$ctx.parents('.l-outer').addClass('l-outer--loggedin');

			// init events
			if (!S.Utils.Helper.mq('tablet').matches) {
				// smartphone
				self.eventsSmartphone();
			} else {
				// tablet/desktop
				self.eventsTabletDesktop();
			}
		},

	});
}(jQuery));