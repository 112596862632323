/* eslint-disable sonarjs/cognitive-complexity */
(function ($) {
	'use strict';
	/**
	 * BasicKeywordfilter module implementation.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicKeywordfilter
	 * @extends T.Module
	 */
	T.Module.BasicKeywordfilter = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$searchbtn: null,

		/** @type {jQuery} */
		$searchfield: null,

		/** @type {jQuery} */
		$searchselect: null,

		/** @type {jQuery} */
		$toggleControler: null,

		/** @type {jQuery} */
		$filterContent: null,

		/** @type {jQuery} */
		$filterDependGroup: null,

		/** @type {jQuery} */
		$toggleControlerLabelDefault: null,

		/** @type {jQuery} */
		$toggleControlerLabelActive: null,

		/** @type {Object} */
		filter: {},

		/** @type {Object} */
		filterGroups: [],

		/** @type {Boolean} */
		doublesliderActivated: false,

		/** @type {Number} */
		doublesliderMin: 0,

		/** @type {Boolean} */
		doublesliderMax: false,

		/** @type {jQuery} */
		limitZone: null,

		/** @type {jQuery} */
		filterSelector: null,

		/** @type {Boolean} */
		groupFilter: false,

		/** @type {Boolean} */
		noFilter: false,

		/** @type {Boolean} */
		isLoaded: false,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) { //NOSONAR
			this.$ctx = $(this._ctx);
			this.$searchbtn = this.$ctx.find('.mm-searchbtn').eq(0);
			this.$searchfield = this.$ctx.find('.mm-searchbar input').eq(0);
			this.$toggleControler = this.$ctx.find('.js-toggle-controler').eq(0);
			this.$toggleControlerLabelDefault = this.$toggleControler.attr('data-title-labeldefault');
			this.$toggleControlerLabelActive = this.$toggleControler.attr('data-title-labelactive');
			this.$filterContent = this.$ctx.find('.mm-filter').eq(0);
			this.limitZone = this.$ctx.data('limit-zone');

			this.toggleControlerGeneral = '.js-toggle-controler';
			this.layerCategory = '.mm-layer .mm-category';
			this.searchbar = '.mm-searchbar';
			this.filterExtraInputChecked = '.is-filter-extra input:checked';
			this.separatorInputChecked = '.mm-separator input:checked';
			this.filterExtra = '.is-filter-extra';
			this.dataFilterDoubleslider = '[data-filter-doubleslider]';
			this.eventBasicKeywordfilterSearch = 'BasicKeywordfilter.search';
			this.eventBasicKeywordfilterReset = 'BasicKeywordfilter.reset';
			this.eventBasicKeywordfilterFilterLayerTrue = 'BasicKeywordfilter.filterLayerTrue';
			this.dataFilter = '[data-filter]';
			this.dataFilterAttr = 'data-filter';
			this.filterGroup = 'filter-group';
			this.jsFilterMismatched = 'js-filter-mismatched';
			this.jsFilterMismatchedDoubleslider = 'js-filter-mismatched-doubleslider';
			this.filterIgnoreBySearch = 'filter-ignorebysearch';
			this.searchResult = 'has-search-result';
			this.dataFilterContent = '[data-filter="';

			const that = this;

			if ($('[data-filter-group]').length) {
				this.groupFilter = true;
			}

			if (this.$ctx.data('backend')) {
				this.noFilter = true;
			}

			if (typeof this.limitZone === "undefined") {
				this.filterSelector = $(".js-basickeywordfilter-result");
			} else {
				this.filterSelector = this.$ctx.closest(this.limitZone);
				$(this.filterSelector).wrapInner("<div class='js-basickeywordfilter-result'></div>");
				this.filterSelector = `${this.limitZone} .js-basickeywordfilter-result`;
			}

			// if a select field is used as main filter 'input' and no inputfield was found
			if (!this.$searchfield.length && this.$ctx.find('.mm-searchbar > .a-basic-input-select > select').eq(0).length) {
				this.$searchselect = this.$ctx.find('.mm-searchbar > .a-basic-input-select > select').eq(0);
			}

			this.initEvents();

			//*** get event reset categories
			this._events.on('BasicKeywordfilter.resetCategories', () => {
				that.resetCategories();
			});

			//*** get global event double slider
			this._events.on('BasicCalculatorSliderDouble.change', (sliderVals) => {
				if ($(this.dataFilterDoubleslider).length) {
					that.filterItemsByDoubleSlider(parseInt(sliderVals[0], 10), parseInt(sliderVals[1], 10));
					sessionStorage.setItem('basickeywordfilter_doubleslider_min', parseInt(sliderVals[0], 10));
					sessionStorage.setItem('basickeywordfilter_doubleslider_max', parseInt(sliderVals[1], 10));
				}
			});

			// for dynamic content change by namics
			this._events.on("BasicKeywordfilter.content.change", () => {
				that.filterItemsFilterLayer();
				that.filterContent('filterlayer');
			});

			// check doubleslider in sessionStorage
			if (sessionStorage.getItem('basickeywordfilter_doubleslider_min') && $(this.dataFilterDoubleslider).length) {
				that.filterItemsByDoubleSlider(sessionStorage.getItem('basickeywordfilter_doubleslider_min'), sessionStorage.getItem('basickeywordfilter_doubleslider_max'));
			}

			// check searchtext in sessionStorage
			if (sessionStorage.getItem(`basickeywordfilter_searchtext_${location.pathname}`) && this.$searchfield.length) {
				this.$searchfield.val(sessionStorage.getItem(`basickeywordfilter_searchtext_${location.pathname}`));

				if (!that.noFilter) {
					that._events.emit(this.eventBasicKeywordfilterSearch, that.$searchfield.val());

					setTimeout(() => {
						that.$searchfield.trigger('keyup');
					}, 200);

					// filter content
					that.filterContentBySearch(that.$searchfield.val());
				}
			}

			// check if filterlayer was open
			if (this.$toggleControler.length && sessionStorage.getItem(`basickeywordfilter_togglecontroler_${location.pathname}`)) {
				this.$toggleControler.trigger('click');
			}

			// initial collection of filter groups by data-filter-group
			this.initFilterGroups();

			// check active layer filter in sessionStorage
			$(this.dataFilter).each((index, item) => {
				// radio || checkbox
				if ($(item).find('> input[checked]').prop('checked')) {
					this.setCheckFilter($(item).find('> input[checked]'));
				}
				if (sessionStorage.getItem(`basickeywordfilter_${$(item).attr(this.dataFilterAttr)}_${location.pathname}`)) {
					$(item).find('input:not([checked])').prop('checked', true);
					this.setCheckFilter($(item).find('input:not([checked])'), 'initstart');

					// select
					if ($(item).find('select').length) {
						$(item).find('select').val(sessionStorage.getItem(`basickeywordfilter_${$(item).attr(this.dataFilterAttr)}`));
						$(item).find('select').trigger('change');
					}
				}
				if ((this.$ctx.find(this.dataFilter).length - 1) === index) {
					// filter items
					this.filterItemsFilterLayer();

					// filter content
					this.filterContent('filterlayer');
				}
			});

			// if a back to top btn is present:
			// check if a sibling table is available
			// if it is, scroll to it once clicked
			// else scroll to closest l-main-content
			if (this.$ctx.find('.js-backtotop-link').length) {
				const $backToTopLink = this.$ctx.find('.js-backtotop-link .a-basic-link--top');
				let routeTo = this.$ctx.closest('.l-main-content');

				if (this.$ctx.siblings('.js-basic-table-wrap').length) {
					routeTo = this.$ctx.siblings('.js-basic-table-wrap');
				}

				$backToTopLink.on('click', (e) => {
					e.preventDefault();
					$('html, body').animate({ scrollTop: `${routeTo.offset().top}px` });
				});
			}

			if (this.$filterContent.find('> .mm-filter-col-wrapper')) {
				this.initParentChildToggle();
			}

			// check if filter should be open onInit if at least one input is selected
			if (this.$ctx.data('js-open-init') && this.$filterContent.find('input:checked').length && !this.$toggleControler.hasClass('is-active')) {
				this.$toggleControler.trigger('click');
			}

			// init statusbar reset clicks
			this.initStatusbarResetClick();

			// set global event filter finishedInit
			this._events.emit('BasicKeywordfilter.finishedInit');

			resolve();
		},

		/**
		 * initEvents
		 *
		 */
		initEvents: function () { //NOSONAR
			const that = this,
				$dataFilterInput = $('[data-filter] > input'),
				optionSelected = 'option:selected';

			// searchbtn click
			this.$searchbtn.on('click', (e) => {
				e.currentTarget.blur();

				// set global event search
				that._events.emit(this.eventBasicKeywordfilterSearch, that.$searchfield.val());
			});

			// initial clearing searchfield
			this.$searchfield.val('');

			// searchfield keyup
			this.$searchfield.on('keyup', (e) => {
				that.$count = that.$searchfield.val().length;

				if (e.which === 13 && that.$count > 2) {
					// set global event search
					that._events.emit(this.eventBasicKeywordfilterSearch, that.$searchfield.val());

					that.$searchfield.blur();
				}

				if (that.$count > 2) {
					if (!that.noFilter) {
						// set global event keyup
						that._events.emit('BasicKeywordfilter.searchKeyup', that.$searchfield.val());

						// filter content
						that.filterContentBySearch(that.$searchfield.val());

						// save searchtext into sessionStorage
						sessionStorage.setItem(`basickeywordfilter_searchtext_${location.pathname}`, that.$searchfield.val());
					}
				}
				else {
					// global reset
					that._events.emit(this.eventBasicKeywordfilterReset);

					// remove searchtext into sessionStorage
					sessionStorage.removeItem(`basickeywordfilter_searchtext_${location.pathname}`, that.$searchfield.val());
				}
			});

			// searchfield blur
			this.$searchfield.on('blur focusout change', () => {
				that.$count = that.$searchfield.val().length;

				if (that.$count < 3) {

					// global reset
					//that._events.emit(this.eventBasicKeywordfilterReset);

					// reset controler button
					that.$toggleControler.removeClass('is-active')
						.text(that.$toggleControlerLabelDefault);

					// toggle
					if ($('.m-basic-toggle').length) {
						// set global event toggle close all
						that._events.emit('BasicKeywordfilter.toggleCloseAll');
					}

					// :: if filter layer
					if (that.$filterContent.length) {
						// hide filter layer
						that.$filterContent.stop().slideUp('fast');
					}

					// remove searchtext into sessionStorage
					sessionStorage.removeItem(`basickeywordfilter_searchtext_${location.pathname}`, that.$searchfield.val());
				}
				else {
					// save searchtext into sessionStorage
					sessionStorage.setItem(`basickeywordfilter_searchtext_${location.pathname}`, that.$searchfield.val());
				}
			});

			// toggle controller click
			this.$toggleControler.on('click', (e) => {
				e.preventDefault();

				// :: not active
				if (!$(e.currentTarget).hasClass('is-active')) {
					// set state
					$(e.currentTarget).addClass('is-active');

					// update controller text and title
					$(e.currentTarget).text(that.$toggleControlerLabelActive)
						.attr('title', that.$toggleControlerLabelActive);

					// :: toggle
					that._events.emit('BasicKeywordfilter.toggleOpenAll');
					that._events.emit('basicBtnToggle.toggleOpenAll');

					// :: filter layer
					if (that.$filterContent.length) {
						$(e.currentTarget).addClass('js-focus');

						// show filter layer
						if (that.$filterContent.hasClass('h-hidden')) {
							that.$filterContent.css('display', 'none').removeClass('h-hidden');
						}

						$(e.currentTarget).parent().parent().parent().find('.a-basic-btn--hidden').hide();

						that.$filterContent.stop().slideDown('fast');

					}

					// save state in session storage
					sessionStorage.setItem(`basickeywordfilter_togglecontroler_${location.pathname}`, 'active');

				}
				else {
					// :: active

					// update controller text and title
					$(e.currentTarget).text(that.$toggleControlerLabelDefault)
						.attr('title', that.$toggleControlerLabelDefault);

					// set state
					$(e.currentTarget).removeClass('is-active');

					// set global event toggle close all
					that._events.emit('BasicKeywordfilter.toggleCloseAll');
					that._events.emit('basicBtnToggle.toggleCloseAll');


					// :: filter layer
					if (that.$filterContent.length) {
						// hide filter layer
						that.$filterContent.stop().slideUp('fast', function () {
							// update controller text and title
							that.$toggleControler.text(that.$toggleControlerLabelDefault)
								.attr('title', that.$toggleControlerLabelDefault);

							// set state
							that.$toggleControler.removeClass('is-active');

							$(this).parent().parent().parent().find('.a-basic-btn--hidden').show();
						});
					}

					// save state in session storage
					sessionStorage.removeItem(`basickeywordfilter_togglecontroler_${location.pathname}`);
				}
			});

			// toggle controller blur
			this.$toggleControler.on('blur', function () {
				$(this).removeClass('js-focus');
			});

			// check initial checked layer filter (overwrite F5 state)
			$('[data-filter] > input[checked]').prop('checked', true);

			// click on layer filter
			$dataFilterInput.on('click', (e) => {
				that.setCheckFilter(e.currentTarget);
			});

			// click on global data-filter elements
			setTimeout(() => {
				$dataFilterInput.off().on('click', (e) => {
					that.setCheckFilter(e.currentTarget);
				});
			}, 100);

			// check initial in html checked layer filter (overwrite F5 state)
			$('[data-filter] > input[checked]').attr('checked', false).prop('checked', false).trigger('click');

			/**
			 * checks for preassignments and filter-values
			 * no-filter (usually 1st option -> 'Bitte wählen') can be set if value-attr is omitted or set as 'all'
			 */
			if (typeof that.$searchselect !== 'undefined' && that.$searchselect !== null) {

				// change layer filter select
				that.$searchselect.on('change', (e) => {
					const filterCategory = $(e.target).attr('name'),
						filterId = $(e.target).find(optionSelected).attr('value');

					// option is selected and has value-attr => filter depends on value-attr
					if ($(e.target).find('option').is(':selected') && filterId !== '' && filterId !== 'all') {
						// set global filter event
						that._events.emit(this.eventBasicKeywordfilterFilterLayerTrue, filterId);

						// add filterCategory and filterId
						that.filter[filterCategory] = filterId;

						// save layer filter into sessionStorage
						sessionStorage.setItem(`basickeywordfilter_${filterId}_${location.pathname}`, true);

						// filter items
						that.filterItemsFilterLayer(filterId);

						// filter content
						that.filterContent('filterlayer');
					}

					// option is selected but does not have a value-attr => reset filter
					else {

						// filter content
						that.filterContent('select');

						// remove filterCategory and filterId
						delete that.filter[filterCategory];

						// remove layer filter into sessionStorage
						sessionStorage.removeItem(`basickeywordfilter_${filterId}_${location.pathname}`);
					}
				});

				// filter items if selectfield is preassigned
				if (that.$searchselect.find('option').is(':selected') &&
					that.$searchselect.find(optionSelected).attr('value') !== '' &&
					that.$searchselect.find(optionSelected).attr('value') !== 'all') {
					const filterCategory = that.$searchselect.attr('name'),
						filterId = that.$searchselect.find(optionSelected).attr('value');

					// set global filter event
					that._events.emit(this.eventBasicKeywordfilterFilterLayerTrue, filterId);

					// add filterCategory and filterId
					that.filter[filterCategory] = filterId;

					// save layer filter into sessionStorage
					sessionStorage.setItem(`basickeywordfilter_${filterId}_${location.pathname}`, true);

					// filter items
					that.filterItemsFilterLayer(filterId);

					// filter content
					that.filterContent('filterlayer');
				}
			}

			// change layer filter select
			this.$filterContent.find('select').on('change', (e) => {
				const filterCategory = $(e.target).attr('name'),
					filterId = $(e.target).find(optionSelected).attr('value');

				if (!$(e.target).find('option').eq(0).is(':selected')) {
					// set global filter event
					that._events.emit(this.eventBasicKeywordfilterFilterLayerTrue, filterId);

					// add filterCategory and filterId
					that.filter[filterCategory] = filterId;

					// save layer filter into sessionStorage
					sessionStorage.setItem(`basickeywordfilter_${filterId}_${location.pathname}`, true);
				}
				else {
					// set global filter event
					that._events.emit('BasicKeywordfilter.filterLayerFalse', filterId);

					// remove filterCategory and filterId
					delete that.filter[filterCategory];

					// remove layer filter into sessionStorage
					sessionStorage.removeItem(`basickeywordfilter_${filterId}_${location.pathname}`);
				}

				// filter items
				that.filterItemsFilterLayer(filterId);

				// filter content
				that.filterContent('filterlayer');
			});
		},

		/**
		 * initFilterGroups
		 */
		initFilterGroups: function () {
			$('[data-filter-group] > input:checked').parent().each((index, item) => {
				this.addFilterGroup($(item).data(this.filterGroup));
			});
		},

		/**
		 * addFilterGroup
		 */
		addFilterGroup: function (filterGroupID) {
			if (!this.filterGroups.includes(filterGroupID)) {
				this.filterGroups.push(filterGroupID);
			}
		},

		/**
		 * removeFilterGroup
		 */
		removeFilterGroup: function (filterGroupID) {
			if (!$(`[data-filter-group="${filterGroupID}"] > input:checked`).length) {
				const index = this.filterGroups.indexOf(filterGroupID);

				if (index > -1) {
					this.filterGroups.splice(index, 1);
				}


			}
		},

		/**
		 * filterItemsByGroup
		 */
		filterItemsByGroup: function (filterStringArray) {
			let countMatchesPerGroup = 0;

			// all groups
			$.each(this.filterGroups, (keyGroup, valueGroup) => {
				let countMatchesPerResult = 0;

				// all check filter in the group
				$(`[data-filter-group="${valueGroup}"] > input:checked`).parent().each((indexFilterBox, itemFilterBox) => {
					// all result filterIDs
					$.each(filterStringArray, (keyResult, valueResultFilter) => {
						if ($(itemFilterBox).data('filter').toString() === valueResultFilter.toString()) {
							countMatchesPerResult++;
						}
					});
				});

				if (countMatchesPerResult > 0) {
					countMatchesPerGroup++;
				}
				else {
					return false;
				}

				return true;
			});

			return countMatchesPerGroup === this.filterGroups.length;
		},

		/**
		 * filterItemsFilterLayer
		 */
		filterItemsFilterLayer: function () { //NOSONAR
			if (!this.noFilter) {
				const that = this,
					jsFilterMatched = 'js-filter-matched';
				let filterCount = 0;

				// count active filter
				$.each(that.filter, function () {
					filterCount++;
				});

				// extra filter
				let extraFilter = false;

				if (that.$filterContent.find('.mm-separator').length || that.$ctx.find(this.filterExtra).length) {
					if (that.$filterContent.find(this.separatorInputChecked).length) {
						extraFilter = that.$filterContent.find(this.separatorInputChecked).eq(0).closest(this.dataFilter).data('filter');
					}
					else if (that.$ctx.find(this.filterExtraInputChecked).length) {
						extraFilter = this.$ctx.find(this.filterExtraInputChecked).eq(0).closest(this.dataFilter).data('filter');
					}
				}

				// filter items
				$(this.filterSelector).find(this.dataFilter).each((index, item) => {
					const filterString = $(item).data('filter').filter;

					// reset
					$(item).removeClass('js-filter-matched js-filter-mismatched');

					if (filterCount > 0) {
						// filter by filter layer
						$.each(that.filter, (index, value) => {
							if (value !== extraFilter) {
								const filterStringArray = filterString.split(',');

								let showEntry = false;

								for (let i = 0; i < filterStringArray.length; i++) {
									let filterString = filterStringArray[i];

									if (filterString.indexOf('::') > -1) {
										filterString = filterString.split('::')[0];
									}

									if (filterString === value.toString()) {
										// now check if there are group filter settings
										if ($('[data-filter-group]').length) {
											if (this.filterItemsByGroup(filterStringArray)) {
												showEntry = true;
												// we got a match, stop looping ... it won't get any better
												break;
											}
										}
										else {
											showEntry = true;

											// we got a match, stop looping ... it won't get any better
											break;
										}
									}
								}

								if (showEntry) {
									$(item).addClass(jsFilterMatched).removeClass(this.jsFilterMismatched);

									if (!$(item).hasClass(this.jsFilterMismatchedDoubleslider)) {
										$(item).show();
										//alert('show filter')
									}
								}

								else if (!$(item).hasClass(jsFilterMatched)) {
									$(item).addClass(this.jsFilterMismatched);

									// check if there are active filter groups
									if (this.filterGroups.length) {
										// && modus => ignore h-search inside result if filter from grups are not matching
										$(item).hide();
									}
									else {
										// normal || modus without filter-groups
										if ($(item).find('.h-search').length === 0 || typeof $(item).data(this.filterIgnoreBySearch) !== 'undefined') {
											$(item).hide();
										}
									}
								}
							}
						});
					}
					else {
						// no filter :: last active filter unchecked
						// and if no search value => show all items

						if ($(item).find('.h-search').length === 0 && !$(item).hasClass(this.jsFilterMismatchedDoubleslider)) {
							$(item).show();
						}
					}
				});

				if (extraFilter !== false) {
					$(this.filterSelector).find(this.dataFilter).each((index, item) => {
						const filterString = $(item).data('filter').filter;

						if (filterString.indexOf(extraFilter) === -1) {
							$(item).hide().addClass(this.jsFilterMismatched).removeClass(jsFilterMatched);
						}
						else if (filterCount === 1) {
							// if only extraFilter Checkbox is checked
							$(item).show().addClass(jsFilterMatched).removeClass(this.jsFilterMismatched);
						}
					});
				}

				if (this.$searchfield.length && this.$searchfield.val().length > 2) {
					this.filterContentBySearch(this.$searchfield.val());
				}

				// filter again if doubleslider exists
				if (this.doublesliderActivated === true) {
					this.filterItemsByDoubleSlider(this.doublesliderMin, this.doublesliderMax);
				}

				// for lazyloading images
				$(document).trigger('scroll');
			}
		},

		filterItemsByDoubleSlider: function (min, max) {
			const that = this;

			this.doublesliderActivated = true;

			this.doublesliderMin = min;

			this.doublesliderMax = max;

			$(this.filterSelector).find('[data-filter-doubleslider]').each((index, item) => {
				const filterNum = parseInt($(item).data('filter-doubleslider'), 10);

				if (filterNum >= min && filterNum <= max) {
					$(item).addClass('js-filter-matched-doubleslider').removeClass(this.jsFilterMismatchedDoubleslider);

					if (!$(item).hasClass(this.jsFilterMismatched)) {
						if (this.$ctx.find(this.searchbar).data(this.filterGroup)) {
							if (this.$ctx.find('.mm-searchbar input').val().length >= 3) {
								if ($(item).find('.h-search').length) {
									$(item).show();
								}
							}
							else {
								$(item).show();
							}
						}
						else {
							$(item).show();
							//alert('show slider')
						}

					}
				}
				else {
					$(item).removeClass('js-filter-matched-doubleslider').addClass(this.jsFilterMismatchedDoubleslider);

					if (this.$ctx.find(this.searchbar).data(this.filterGroup)) {
						$(item).hide();
					}
					// eslint-disable-next-line sonarjs/no-duplicated-branches
					else if ($(item).find('.h-search').length === 0) { //NOSONAR
						$(item).hide();
					}
				}
			});

			this.filterContent('doubleslider');

			that._events.emit('BasicKeywordfilter.filterDoubleSlider');
		},

		/**
		 * filterContent
		 *
		 * @param {String} mod 'search' || 'filterlayer' || 'doubleslider' || 'select'
		 */
		filterContent: function (mod) { //NOSONAR
			const that = this,
				dataFilterIgnore = '[data-filter-ignore]',
				$basicKeywordfilterResultsFalse = $('.js-basickeywordfilter-results-false');

			// reset
			$(this.filterSelector).show();

			// show/hide filter result areas
			$(this.filterSelector).each((index, item) => {
				// filter doubleslider
				if (mod === 'doubleslider') {
					if (!$(item).find('[data-filter-doubleslider]:visible').length) {
						$(item).hide();
					}
					else {
						$(item).show();
					}
				}

				// filterlayer
				if (mod === 'filterlayer') {
					if (!$(item).find('[data-filter]:visible').length) {
						//don't hide for new Dashboard because it is initially toggled
						if (that.$ctx.closest('.m-my-adac-dashboard-box--block').length === 0) {
							$(item).hide();
						}
					}
					else {
						$(item).show();

						// if items have multiple sort methods linke sort by index, sort by group like FREUNDE WERBEN
						if ($(item).find('[data-index]').length) {

							let count = 0;
							const items = $(item).find(this.dataFilter),
								itemsArr = [],
								itemContainer = $(item);

							$.each(items, function (index) {
								itemsArr.push(items[index]);
							});

							$.each(that.filter, function () {
								count++;
							});

							let sortByGroup = false;
							let extraFilter = false;

							if (that.$filterContent.find(this.separatorInputChecked).length) {
								extraFilter = that.$filterContent.find('.mm-separator [data-filter]').eq(0).data('filter');
							}
							else if (that.$ctx.find(this.filterExtraInputChecked).length) {
								extraFilter = that.$ctx.find(this.filterExtra).eq(0).data('filter');
							}

							if (count === 1 && extraFilter === false) {
								sortByGroup = true;
							}

							if (count === 2 && extraFilter !== false) {
								sortByGroup = true;
							}

							// sort
							if (sortByGroup) {
								// sort by groupindex
								itemsArr.sort((a, b) => {
									return that.getGroupSortIndex($(a).attr(this.dataFilterAttr)) - that.getGroupSortIndex($(b).attr(this.dataFilterAttr));
								});
							}
							else {
								itemsArr.sort(function (a, b) {
									return $(a).attr('data-index') - $(b).attr('data-index');
								});
							}

							// refill
							$.each(itemsArr, function (index) {
								itemContainer.append(itemsArr[index]);
							});

						}
					}
				}

				// search
				if (mod === 'search') {
					if (!$(item).find('.js-search-conditions-matched').length && !$(item).hasClass('js-search-conditions-matched') && !$(item).find('[data-filter]:visible:not([data-filter-ignorebysearch])').length && !$(item).find('.h-search').not($('.m-basic-text > h2 .h-search, .m-basic-text > h3 .h-search, .js-filter-mismatched')).length) {
						$(item).hide();
					}
					else if ($(item).find('.h-search:not(.js-filter-mismatched):not(.js-search-conditions-mismatched)').not($('h2 .h-search, h3 .h-search')).length && !$(item).find('[data-filter-ignorebysearch]').length) {
						$(item).show();
					}
				}

				// select reset
				if (mod === 'select') {
					$(item).find(this.dataFilter).removeClass('js-filter-matched js-filter-mismatched').show();
				}
			});


			if (!$('.js-basickeywordfilter-result [data-filter]:visible, .js-basickeywordfilter-result .h-search:visible, .js-search-conditions-matched').not($(dataFilterIgnore)).length) {
				const dataIgnoreContainer = $(dataFilterIgnore).eq(0).closest('.js-basickeywordfilter-result');

				if (dataIgnoreContainer.length) {
					dataIgnoreContainer.hide();

					// show data-filter-ignore boxes
					$(dataFilterIgnore).show();

					if (dataIgnoreContainer.closest('[data-ignore-sort-random="true"]').length) {
						// sort random
						const slideArray = new Array();
						$(dataFilterIgnore).each((index, item) => {
							slideArray.push($(item));
							$(item).remove();
						});

						slideArray.sort(function () {
							return .5 - Math.random(); //NOSONAR
						});

						$.each(slideArray, (index, item) => {
							$(item).appendTo(dataIgnoreContainer);
						});
					}

					dataIgnoreContainer.show();
				}

				//don't show for new Dashboard because it is initially toggled
				if (that.$ctx.closest('.m-my-adac-dashboard-box--block').length === 0) {
					$basicKeywordfilterResultsFalse.show();
				}

				// hide controller in my adac dashboard templates (used as 'show-more' / 'show-less')
				if (that.$ctx.hasClass('m-basic-keywordfilter--my-adac')) {
					$(this.toggleControlerGeneral).hide();
				}

				if ($(dataFilterIgnore).length) {
					$('.js-basickeywordfilter-results-false .mm-recommendations').show();
				}
				else {
					$('.js-basickeywordfilter-results-false .mm-recommendations').hide();
				}
			}
			else {
				$basicKeywordfilterResultsFalse.hide();
				$(this.toggleControlerGeneral).show();
			}

			// for lazyloading images
			$(document).trigger('scroll');

			// emit change (usually used as onComplete())
			that._events.emit('BasicKeywordfilter.filterChange');
		},

		/**
		 * getGroupSortIndex
		 *
		 * @param: {String} filterstring
		 */
		getGroupSortIndex: function (filterstring) {
			const that = this;

			let groupIndexArr = [],
				groupIndexName = '',
				groupIndex = '';

			if (filterstring.indexOf(',') > -1) {
				const data = $.parseJSON(filterstring),
					dataFilterArr = data.filter.split(',');

				$.each(dataFilterArr, function (index, value) {
					groupIndexArr = value.split('::');
					groupIndexName = groupIndexArr[0];

					if (that.filter[groupIndexName] !== undefined) {
						groupIndex = groupIndexArr[1];
					}
				});
			}
			else {
				groupIndexArr = filterstring.split('::');
				groupIndexName = groupIndexArr[0];
				groupIndex = groupIndexArr[1].replace('"', '').replace('}', '').replace(' ', '');
			}

			return groupIndex;
		},

		resetBasicKeywordfilter: function () {
			const that = this;

			// show/hide items
			$(this.filterSelector).find(this.dataFilter).each((index, item) => {
				// now check if there are group filter settings
				if (this.$ctx.find(this.searchbar).data(this.filterGroup)) {
					const filterStringArray = $(item).data('filter').filter.split(',');

					if (!$(item).hasClass(this.jsFilterMismatched) && this.filterItemsByGroup(filterStringArray) && !$(item).hasClass(this.jsFilterMismatchedDoubleslider)) {
						$(item).show();
					}
					else {
						$(item).hide();
					}
				}
				else {
					if (!$(item).hasClass(this.jsFilterMismatched) && !$(item).hasClass(this.jsFilterMismatchedDoubleslider)) {
						$(item).show();
					}
					else {
						$(item).hide();
					}
				}
			});

			$('.js-search-conditions-matched, .js-search-conditions-mismatched').removeClass('js-search-conditions-matched js-search-conditions-mismatched');

			//if ($(this.filterSelector).find(this.dataFilter).length)
			//{
			// filter content
			//that.filterContent('search');
			//}
			//else
			//{
			// show init state
			$(this.filterSelector).show();

			that._events.emit('BasicKeywordfilter.afterRefreshFinished');
			//}

			$('.h-search').parent().addClass(this.searchResult).unhighlight({ className: 'h-search' });
			$('.has-search-result').each((index, item) => {
				$(item).removeClass(this.searchResult);
				$(item).html($(item).html());
			});
		},

		/**
		 * filterContentBySearch
		 *
		 * @param: {String} searchValue
		 */
		filterContentBySearch: function (searchValue) { //NOSONAR
			// trim spaces before and after search words, replace multiple spaces between with one and transform string to array
			let searchValueArray = searchValue.trim().replace(/\s\s+/g, ' ').split(' ');

			// filter doublicates
			searchValueArray = searchValueArray.filter((element, index) => {
				return searchValueArray.indexOf(element) === index;
			});

			//delete all array entries with length < 3
			searchValueArray = searchValueArray.filter((element) => {
				return element.length > 2;
			});

			// reset event
			this._events.on(this.eventBasicKeywordfilterReset, () => {
				$('.js-basickeywordfilter-results-false').hide();
				$(this.toggleControlerGeneral).show();
				this.resetBasicKeywordfilter();
			});

			$(document).on(this.eventBasicKeywordfilterReset, () => {
				this.resetBasicKeywordfilter();
			});

			$('.h-search').parent().addClass(this.searchResult).unhighlight({ className: 'h-search' });

			$('.has-search-result').each((index, item) => {
				$(item).removeClass(this.searchResult);
				$(item).html($(item).html());
			});

			// set highlight
			for (let i = 0; i < searchValueArray.length; i++) {
				// eslint-disable-next-line sonarjs/no-collapsible-if
				if (searchValueArray.length) {
					if (searchValueArray[i].length > 2) {
						$('.js-basickeywordfilter-result, .m-basic-toggle, .m-basic-newsbox').highlight(searchValueArray[i], {
							className: 'h-search'
						});
					}
				}
			}

			// reset for some areas
			$('th, .mm-subheading, .js-basickeywordfilter-result-ignore').unhighlight({ className: 'h-search' });

			// show/hide items
			$(this.filterSelector).find(this.dataFilter).each((index, item) => {
				// data-search-mod="AND" => all search words must match
				if (typeof this.$ctx.data('search-mod') !== 'undefined' && this.$ctx.data('search-mod') === 'AND') {
					const searchValueArrayLowerCase = searchValueArray.map(element => {
						return element.toLowerCase();
					});

					$(item).find('.h-search').each((index, itemHighlighted) => {
						if (this.checkAvailability(searchValueArrayLowerCase, $(itemHighlighted).text().toLowerCase())) {
							searchValueArrayLowerCase.splice(searchValueArrayLowerCase.lastIndexOf($(itemHighlighted).text().toLowerCase()), 1);
						}
					});

					if (searchValueArrayLowerCase.length === 0) {
						$(item).show().find('.h-search').removeClass(this.jsFilterMismatched);
					}
					else {
						$(item).hide().find('.h-search').addClass(this.jsFilterMismatched);
					}
				}
				// normal modus without group filter
				// eslint-disable-next-line eqeqeq
				else if ($(item).find('.h-search').length && typeof $(item).data(this.filterIgnoreBySearch) == 'undefined') { //NOSONAR
					// now check if there are group filter settings
					if (this.$ctx.find(this.searchbar).data(this.filterGroup)) {
						const filterStringArray = $(item).data('filter').filter.split(',');

						if (this.filterItemsByGroup(filterStringArray) && !$(item).hasClass(this.jsFilterMismatchedDoubleslider)) {
							$(item).show();
							//alert('show search')
						}
					}
					else {
						$(item).show();
					}
				}
				else {
					// eslint-disable-next-line eqeqeq
					if (typeof $(item).data(this.filterIgnoreBySearch) == 'undefined') { //NOSONAR
						$(item).hide();
					}
				}
			});

			// show/hide items by data-search-conditions
			$('.js-basickeywordfilter-result [data-search-conditions]').each((index, item) => {
				// reset
				$(item).removeClass('js-search-conditions-matched js-search-conditions-mismatched');

				if ($(item).data('search-conditions') !== '') {
					const dataSearchConditionArray = $(item).data('search-conditions').trim().split(','),
						jsSearchConditionsMatched = 'js-search-conditions-matched';

					for (let i = 0; i < searchValueArray.length; i++) {
						if (searchValueArray[i] !== '' && searchValueArray[i].length > 2) {
							for (let x = 0; x < dataSearchConditionArray.length; x++) {
								if (!$(item).hasClass(jsSearchConditionsMatched)) {

									if (dataSearchConditionArray[x].trim().toLowerCase().indexOf(searchValueArray[i].trim().toLowerCase()) > -1) {
										$(item).addClass(jsSearchConditionsMatched).show();
									}
									else if (!$(item).find('.h-search').length) {
										$(item).addClass('js-search-conditions-mismatched').hide();
									}
								}
							}
						}
					}
				}
			});

			this._events.emit('BasicKeywordfilter.filterContentBySearchFinished');

			// filter content
			this.filterContent('search');
		},

		/**
		 * adds events for <<wrapped>> inputs, where a checkbox works like a main input
		 * and toggles all child inputs.
		 *
		 */
		initParentChildToggle: function () { //NOSONAR
			const that = this;

			// check if there are dependent inputs
			that.$filterContent.find(' > .mm-filter-col-wrapper > .mm-filter-col').each((index, item) => {

				if ($(item).find('> .mm-child').length) {

					const parentElem = $(item).find(' > li:not(.mm-child)').eq(0),
						parentInput = $(parentElem).find(' input[type="checkbox"] ').eq(0),
						childInputs = $(parentElem).siblings('li.mm-child').find(' input[type="checkbox"] '),
						childElemCount = $(childInputs).length;
					let childCheckCount = 0;

					// get initial checkcount
					$(childInputs).each((index, item) => {
						if ($(item).prop('checked')) {
							childCheckCount++;
						}
					});

					if (childCheckCount !== 0) {
						$(parentInput).prop('checked', true);
					}
					else {
						$(parentInput).prop('checked', false);
					}

					// save counts in data-attr.
					$(parentInput).data('childCheckCount', childCheckCount);
					$(parentInput).data('childElemCount', childElemCount);

					// events for parent input
					$(parentInput).on('change', () => {

						// toggle all children (check / uncheck)
						if ($(parentInput).prop('checked')) {

							$(childInputs).each((index, item) => {
								if (!$(item).prop('checked')) {
									$(item).trigger('click').blur();
								}
							});

							// add new checkCount to data => all checked, summary count works
							$(parentInput).data('childCheckCount', childElemCount);
						}

						else {

							$(childInputs).each((index, item) => {
								if ($(item).prop('checked')) {
									$(item).trigger('click').blur();
								}
							});

							// add new checkCount to data => nothing checked, 0 works
							$(parentInput).data('childCheckCount', 0);
						}

					});


					// events for child inputs
					$(childInputs).each((index, item) => {

						$(item).on('change', () => {

							let currentCheckCount = Number($(parentInput).data('childCheckCount'));

							if ($(item).prop('checked')) {
								currentCheckCount++;
							}

							else {
								currentCheckCount--;
							}

							if (currentCheckCount > 0) {
								$(parentInput).prop('checked', true);
							}

							else {
								$(parentInput).prop('checked', false);
							}

							$(parentInput).data('childCheckCount', currentCheckCount);
						});

					});
				}

			});
		},

		checkAvailability: function (arr, val) {
			return arr.some((arrVal) => val === arrVal);
		},

		setCheckFilter: function setCheckFilter(elm, waitForAllFilters) { //NOSONAR

			const that = this,
				filterCategory = $(elm).attr('name'),
				filterId = $(elm).closest('div').data('filter'),
				dataFilterInputNotChecked = '[data-filter] > input:not(:checked)',
				dataFilterInputChecked = '[data-filter] > input:checked',
				category = '.mm-category',
				$jsFilterStatusbar = $('.js-filter-statusbar');

			if ($(elm).is(':checked')) {

				// check group filter
				if ($(elm).closest(this.dataFilter).data(this.filterGroup)) {
					this.addFilterGroup($(elm).closest(this.dataFilter).data(this.filterGroup));
				}

				// set global filter event
				that._events.emit(this.eventBasicKeywordfilterFilterLayerTrue, filterId);
				// if radio, remove all others from the radio group
				if ($(elm).attr('type') === 'radio') {

					const radioName = $(elm).attr('name');

					$(`input[name="${radioName}"]`).each(function (index, item) {
						// remove filterCategory and filterId
						delete that.filter[$(item).closest('div').data('filter')];
					});
				}
				if ($(elm).attr('name') === 'all') {
					if (waitForAllFilters !== 'initstart') {
						that.$filterContent.find(dataFilterInputNotChecked).trigger('click');

						$(elm).closest('.mm-layer').find(dataFilterInputNotChecked).each((index, item) => {
							$(item).prop('checked', true);
							this.setCheckFilter($(item), 'allCb');


							if (($(elm).closest('.mm-layer').find(dataFilterInputNotChecked).length - 1) === index) {
								// filter items
								this.filterItemsFilterLayer();

								// filter content
								this.filterContent('filterlayer');
							}
						});
					}
					$(elm).prop('checked', true);
				}
				else if ($(elm).attr('name') !== 'all' && $(elm).attr('name').indexOf('all') !== -1 && this.$ctx.find(this.layerCategory).length) {
					if (waitForAllFilters !== 'initstart') {
						$(elm).closest(category).find(dataFilterInputNotChecked).each((index, item) => {
							$(item).prop('checked', true);
							this.setCheckFilter($(item), 'allCb');


							if (($(elm).closest(category).find(dataFilterInputNotChecked).length - 1) === index) {
								// filter items
								this.filterItemsFilterLayer();

								// filter content
								this.filterContent('filterlayer');
							}
						});
					}
					$(elm).prop('checked', true);
				}
				else {
					// add filterCategory and filterId
					that.filter[filterCategory] = filterId;

					// reset second special filter if exists
					if ($(elm).closest('.mm-separator').length) {
						that.$filterContent.find(this.separatorInputChecked).not($(elm)).trigger('click');
					}
					if ($(elm).closest(this.dataFilter).siblings(this.filterExtra).length) {
						that.$ctx.find(this.filterExtraInputChecked).not($(elm)).trigger('click');
					}
				}
				// save layer filter into sessionStorage
				sessionStorage.setItem(`basickeywordfilter_${filterId}_${location.pathname}`, true);

				// add filter to statusbar
				if ($jsFilterStatusbar.length && $(elm).closest(this.dataFilter).data('filter').indexOf('all') === -1 && !$(`.js-filter-statusbar [data-filter-id="${filterId}"]`).length) {
					const filterId = $(elm).closest(this.dataFilter).data('filter');

					const filterHTML = `<div class="mm-filter-item" data-filter-id="${filterId}"><span>${$(elm).siblings('div').find('label').html()}</span><div class="mm-filter-delete js-filter-delete"></div></div>`;

					$(filterHTML).appendTo($('.js-filter-statusbar-filterbox'));

					$(`.mm-filter-item[data-filter-id="${filterId}"] .js-filter-delete`).on('click', () => {
						$(`${this.dataFilterContent + filterId}"] input`).trigger('click');
					});

					$jsFilterStatusbar.addClass('is-visible');
				}
			}
			else {
				// check group filter
				if ($(elm).closest(this.dataFilter).data(this.filterGroup)) {
					this.removeFilterGroup($(elm).closest(this.dataFilter).data(this.filterGroup));
				}

				// set global filter event
				that._events.emit('BasicKeywordfilter.filterLayerFalse', filterId);
				// remove filterCategory and filterId
				delete that.filter[filterCategory];
				// remove layer filter into sessionStorage
				sessionStorage.removeItem(`basickeywordfilter_${filterId}_${location.pathname}`);

				if ($(elm).attr('name') === 'all') {
					this.$filterContent.find(dataFilterInputChecked).not($(elm)).trigger('click');
					$(elm).closest('.mm-layer').find(dataFilterInputChecked).not($(elm)).trigger('click');
				}
				else if ($(elm).attr('name').indexOf('all') !== -1 && this.$ctx.find(this.layerCategory).length) {
					$(elm).closest(category).find(dataFilterInputChecked).not($(elm)).each((index, item) => {
						$(item).prop('checked', false);
						this.setCheckFilter($(item), 'allCb');


						if (($(elm).closest(category).find(dataFilterInputChecked).not($(elm)).length - 1) === index) {
							// filter items
							this.filterItemsFilterLayer();

							// filter content
							this.filterContent('filterlayer');
						}
					});
				}
				else {
					// uncheck/clean all-of-category-filter
					if (this.$ctx.find(this.layerCategory).length) {
						const firstGroupFilter = $(elm).closest(category).find('[data-filter] > input').eq(0);

						if (firstGroupFilter.is(':checked')) {
							firstGroupFilter.prop('checked', false);
							sessionStorage.removeItem(`basickeywordfilter_${firstGroupFilter.closest(this.dataFilter).data('filter')}_${location.pathname}`);
						}
					}
					else {
						// uncheck/clean the all-filter
						this.$filterContent.find('[data-filter] > input[name="all"]:checked').prop('checked', false);
						$(elm).closest('.mm-layer').find('[data-filter] > input[name="all"]:checked').prop('checked', false);
						sessionStorage.removeItem(`basickeywordfilter_all_${location.pathname}`);
					}
				}

				// remove filter from statusbar
				if ($jsFilterStatusbar.length) {
					$(`.mm-filter-item[data-filter-id="${$(elm).closest(this.dataFilter).data('filter')}"]`).remove();

					if (!$('.js-filter-statusbar .mm-filter-item').length) {
						$jsFilterStatusbar.removeClass('is-visible');
					}
				}
			}

			if (waitForAllFilters !== 'initstart' && waitForAllFilters !== 'allCb') {
				// filter items
				that.filterItemsFilterLayer(filterId);

				// filter content
				that.filterContent('filterlayer');
			}
		},

		resetCategories: function () {
			let cnt = 0;
			const allFilter = Object.keys(this.filter).length;

			$.each(this.filter, (index, value) => {
				if (this.$ctx.find(`${this.dataFilterContent + value}"] > input`).length > 0) {
					this.$ctx.find(`${this.dataFilterContent + value}"] > input`).prop('checked', false);
					this.setCheckFilter(this.$ctx.find(`${this.dataFilterContent + value}"] > input`), 'allCb');
					if ((allFilter - 1) === cnt) {
						// filter items
						this.filterItemsFilterLayer();

						// filter content
						this.filterContent('filterlayer');
					}
					cnt++;
				}
			});
		},

		initStatusbarResetClick: function () {
			setTimeout(() => {
				if ($('.js-filter-statusbar').length) {
					$('.js-filter-statusbar .js-filter-delete').on('click', (item) => {
						$(item.currentTarget).trigger('click');
					});
				}
			}, 200);
		}
	});
}(jQuery));