(function ($) {
	'use strict';

	/**
	 * BasicInputText module implementation.
	 *
	 * @author  <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputText
	 * @extends T.Module
	 */
	T.Module.BasicInputText.Search = T.createDecorator({

		/** @type {jQuery} */
		$module: null,

		/** @type {String} */
		dataType: null,

		/** @type {String} XMLHttpRequest  */
		xHr: null,

		/** @type {String} autoCompleteInstance  */
		autocompleteInstance: null,

		/** @type {Number} dataResultObj */
		dataResultObj: 0,

		/** @type {Array} dataResultArray */
		dataResultArray: [],

		/** @type {jQuery} $resultWrapper */
		$resultWrapper: null,

		/** @type {jQuery} $showMore */
		$showMoreButton: null,

		/** @type {jQuery} $showMore */
		$searchButton: null,

		/** @type {jQuery} $buttonTemplate */
		$buttonTemplate: null,

		/** @type {jQuery} $linkTemplate */
		$linkTemplate: null,

		/** @type {jQuery} naviSearch */
		naviSearch: null,

		/**
		 * Initialize.
		 */
		start(resolve) {
			this.$module = $(this._ctx);
			this.moduleId = this.$module.data('tId');
			this.dataType = this.$module.data('jsonformat');
			this.$resultWrapper = this.$module.closest('.mm-search-wrapper').find('.mm-search-result');
			this.$searchButton = this.$resultWrapper.find('.mm-searchbtn');

			this.naviSearch = this.$module.closest('header').length;

			// templates
			// for search 2.0 - adding commercial etc.
			this.$buttonTemplate = $('.js-button-template');
			this.$linkTemplate = $('.js-link-template');

			this.resize();
			this.validate();

			resolve();
		},

		/**
		 *
		 */
		validate() {
			this.autocompleteInstance = this.$module.devbridgeAutocomplete({
				serviceUrl: '/dev/api/search/search-terms.json',
				datatype: 'json',
				minChars: 1,
				width: '100%',
				maxHeight: '100%',
				noSuggestionNotice: 'Keine Einträge gefunden',
				appendTo: this.$resultWrapper,
				triggerSelectOnValidInput: false,
				showNoSuggestionNotice: true,
				// cache: false,
				delay: 50,
				transformResult: (response) => {
					const resultObj = JSON.parse(response);

					// return the give JSON or return the empty response
					return {
						suggestions: $.map(resultObj.results, (dataItem) => {
							if (typeof response === 'undefined' || response === null || response.length === 0) {
								return response;
							}

							return { value: dataItem.value, data: dataItem.data };
						}),
					};
				},
				beforeRender: ($container, suggestions) => {
					if (suggestions.length !== 0) {
						$container.addClass('is-shown');

						const contentWrapperHTML = '<div class="mm-content-wrapper"><div class="mm-content mm-content-left"></div><div class="mm-content mm-content-right"></div></div>';

						// empty the container for individual markup, add left and right wrapper
						$container.empty().append(contentWrapperHTML);

						// add the button
						$container.append(this.$buttonTemplate);

						// get show more button
						this.$showMoreButton = $container.find('.mm-show-more-button');

						// set the event on the button to trigger the submit on click
						this.handleShowMoreClick();

						for (const suggestion of suggestions) {
							const html = this.createHtmlOutput(suggestion, suggestions.indexOf(suggestion));
							$container.find('.mm-content-left').append(html);
						}
					}
				},
			})  // to get/save the instance
				.data('autocomplete');
		},

		/**
		 * reformat the highlighted value if it is in the search suggestion
		 * @param {suggestion} suggestion single
		 * @return {*}
		 */
		formatResult(suggestion) {
			const currentValue = this.autocompleteInstance.currentValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // eslint-disable-line

			const re = new RegExp(`(${currentValue.split(' ').join('|')})`, 'gi'),
				title = suggestion.replace(re, '<strong>$1</strong>');

			return title;
		},

		/**
		 * handles the person-template and fill it
		 * @param {Object} suggestion
		 * @param {number} index -> suggestion index
		 * @return {*|jQuery|*|*|*|*}
		 */
		handleLinkTemplating(suggestion, index) {
			const $linkHtml = $(this.$linkTemplate.html());

			$linkHtml.attr('data-index', index);
			$linkHtml.find('.mm-link').attr({ href: suggestion.data.link }).html(this.formatResult(suggestion.value));

			return $linkHtml.html();
		},

		/**
		 * Create different html output based on category
		 * @param {array} suggestion
		 * @param {Number} suggestionIndex
		 * @return {string}html
		 */
		createHtmlOutput(suggestion, suggestionIndex) {
			return this.handleLinkTemplating(suggestion, suggestionIndex);
		},

		/**
		 * handle show more click to trigger form submit
		 */
		handleShowMoreClick() {
			this.$showMoreButton.on('click', (e) => {
				e.preventDefault();
			});
		},

		/**
		 * reisze function
		 */
		resize() {
			let oldWidth = $(window).width();

			$(window).on('resize', () => {
				S.Utils.delayed(`search-box-${this.id}`, 200, () => {
					// if you dont check for this, mobile devices will trigger if you swipe over the end of the site
					if ($(window).width() !== oldWidth) {
						oldWidth = $(window).width();
					}
				});
			});
		},

	});
}(jQuery));
