'use strict';
(function ($) {
	/**
	 * InsuranceVoucherRedeem module implementation.
	 *
	 * @author  <stefan.pirkl@gks.adac.de>
	 * @namespace T.Module
	 * @class InsuranceVoucherRedeem
	 * @extends T.Module
	 */
	T.Module.InsuranceVoucherRedeem = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		/** @type {jQuery} */
		$formDiv: null,
		/** @type {jQuery} */
		$successDiv: null,
		/** @type {jQuery} */
		$voucherInput: null,
		/** @type {jQuery} */
		$submit: null,
		/** @type {jQuery} */
		$errorMandatory: null,
		/** @type {jQuery} */
		$errorNotVald: null,
		/** @type {jQuery} */
		$errorSpent: null,
		_voucherCodeSessionKey: "voucherCode",
		_voucherCode: undefined,
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this._readConfiguration();
			this._initControls();
			this._addEventListener($);
			this._setupValidation();
			resolve();
		},
		_readConfiguration: function _readConfiguration() {
			this._config = this.$ctx.data('config') || {};
			this._apiCheckVoucher = this._config.apiCheckVoucher;
			this._api = T.Utils.Application.getApi();
		},
		_initControls() {
			this.$voucherForm = this.$ctx.find('form');
			this.$formDiv = this.$ctx.find('.js-form').eq(0);
			this.$successDiv = this.$ctx.find('.js-success').eq(0);
			this.$voucherInput = this.$ctx.find('#vouchercode').eq(0);
			this.$submit = this.$ctx.find('.js-submit-vouchercode').eq(0);
			this.$errorMandatory = this.$ctx.find('.js-error-mandatory').eq(0);
			this.$errorNotVald = this.$ctx.find('.js-error-notvalid').eq(0);
			this.$errorSpent = this.$ctx.find('.js-error-spent').eq(0);
		},
		_addEventListener() {
			this.$voucherInput.on('change', this._validateInput.bind(this));
			this.$submit.on('click', this._sendRequest.bind(this));
		},
		_sendRequest() {
			if (this.$voucherForm.valid()) {
				this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
				const api = T.Utils.Helper.appendURIPath(this._api, this._apiCheckVoucher);
				this._resetVoucherCodeErrors();
				if (this._validateInput()) {
					const options = {
						type: 'GET',
						url: api,
						data: { Gutscheincode: this._voucherCode },
						xhrFields: {
							withCredentials: true
						}
					};
					T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
				}
			}
		},
		_validateInput() {
			this._resetVoucherCodeErrors();
			this._voucherCode = this.$voucherInput.val();
			if (!this._voucherCode.length) {
				this.$errorMandatory.toggleClass('h-hidden', false);
				return false;
			}
			this.$errorMandatory.toggleClass('h-hidden', true);
			return true;
		},
		_setupValidation: function _setupValidation() {
			const validationConfig = {
				rules: {
					confirmsubmit: {
						required: true
					}
				},
				messages: this._config.validationMessages || {}
			};
			T.Utils.Validation.init(this.$ctx, [this.$voucherForm], validationConfig);
		},
		_successCallback: function _successCallback() {
			T.Utils.Store.set(this._voucherCodeSessionKey, this._voucherCode, T.Utils.Store.SESSION);
			//hide form page, show success page
			this.$formDiv.toggleClass("h-hidden", true);
			this.$successDiv.toggleClass("h-hidden", false);
			window.scrollTo(0, this.scrollPosition);
		},
		_errorCallback: function _errorCallback(err) {
			//show error message based on APIL response
			if (err.responseJSON && err.responseJSON.Messages) {
				const messages = err.responseJSON.Messages[0];
				if (messages.Id) {
					switch (messages.Id) {
						case 121069:
							this.$errorNotVald.toggleClass("h-hidden", false);
							break;
						case 121070:
							this.$errorSpent.toggleClass("h-hidden", false);
							break;
					}
				}
			}
		},
		_resetVoucherCodeErrors() {
			this.$errorMandatory.toggleClass("h-hidden", true);
			this.$errorNotVald.toggleClass("h-hidden", true);
			this.$errorSpent.toggleClass("h-hidden", true);
		}
	});
}(jQuery));