(function ($)
{
    'use strict';
    /**
     * LayoutMainStage module implementation.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class LayoutMainStage
     * @extends T.Module
     */
    T.Module.LayoutMainStage = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {Object[]} */
        $swiperSlides: null,

        /** @type {jQuery} */
        $swiperBtnLeft: null,

        /** @type {jQuery} */
        $swiperBtnRight: null,

        /** @type {Number} */
        imgSrcWidth: 0,

        /** @type {jQuery} */
        imgElement: null,

        /** @type {Boolean} */
        isLazy: false,

        /** @type {Boolean} */
        isLoaded: false,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$swiperContainer = this.$ctx.find('.swiper').eq(0);
            this.$swiperSlides = this.$swiperContainer.find('.swiper-slide');
            this.$swiperBtnLeft = this.$swiperContainer.find(' .swiper-button-prev');
            this.$swiperBtnRight = this.$swiperContainer.find(' .swiper-button-next');
            this.ariaLabelAttribute = 'aria-label';
            this.swiperBtnLeftAriaLabelText = this.$swiperBtnLeft.attr(this.ariaLabelAttribute);
            this.swiperBtnRightAriaLabelText  = this.$swiperBtnRight.attr(this.ariaLabelAttribute);

            resolve();
        },

        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate()
        {
            // init swipe
            this.initSwiper();

            // smartphone
            S.Utils.Helper.mq('tablet').addListener(() =>
            {
                this.checkStopper();
            });

            // mgl stage always contains 3 radio buttons
            if (this.$ctx.find('input[type="radio"]').length === 3)
            {
                this._initMglRadioButtonListener();
            }

            // some stages have a fixed height in desktop
            // => we need some js-logic to cut off the left side via css without setting hc values for the images
            if (this.$ctx.hasClass('o-layout-main-stage--align-right') && this.$ctx.find('.oo-img.is-fixed-height').length)
            {
                this.imgElement = this.$ctx.find('.oo-img.is-fixed-height').eq(0);

                // call offset logic after load
                this.imgElement.on('load', () =>
                {
                    this.imgSrcWidth = this.imgElement[0].naturalWidth;
                });
            }
        },

        _initMglRadioButtonListener: function ()
        {
            this.$ctx.find('.js-radio-link').on('click', (e) =>
            {
                e.preventDefault();

                window.location.href = this.$ctx.find('input[type="radio"]:checked').attr('data-target-url');
            });
        },

        /**
         * init swipe
         */
        initSwiper: function ()
        {
            const speedSmartphone = parseInt(this.$ctx.attr('data-speed')),
                speedDesktop = parseInt(this.$ctx.attr('data-speed-d')),
                simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
                autoplay = this.$ctx.data('mode') === 'edit' ? false : { delay: 7000, disableOnInteraction: true}, // if sitecore edit mode
                keyboardControl = this.$ctx.data('mode') !== 'edit';// if sitecore edit mode

            // init swiper conatiner
            new Swiper(this.$swiperContainer[0], {
                slidesPerView: 1,
                speed: speedSmartphone,
                simulateTouch: simulateTouch,
                autoplay: autoplay,
                keyboard: {
                    enabled: keyboardControl,
                },
                watchSlidesProgress: true,
                preloadImages: false,
                observer: true,
                navigation: {
                    prevEl: this.$swiperBtnLeft.get(0),
                    nextEl: this.$swiperBtnRight.get(0),
                },
                lazy: {
                    checkInView: true,
                    loadPrevNextAmount: 1,
                    loadPrevNext: true,
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        speed: speedSmartphone,
                    },
                    1024: {
                        speed: speedDesktop,
                    }
                },
                on: {
                    init: () =>
                    {
                        // remove is-init
                        this.$swiperSlides.removeClass('is-init');

                        // check if there is a stopper in the slide => set special styling class
                        this.checkStopper();
                    },

                    slideChangeTransitionStart: () =>
                    {
                        // check if there is a stopper in the slide => set special styling class
                        this.checkStopper(true);
                    },
                    reachEnd: (swiper) =>
                    {
                        // jump to slide 1 if autoplay is
                        if (swiper.autoplay?.running)
                        {
                            setTimeout(() =>
                            {
                                swiper.setProgress(0, 0);
                                swiper.updateProgress();
                                swiper.autoplay.start();
                            }, 7000);
                        }
                    },
                    /**
                     * @params {swiper, slideEl, imageEl}
                     *
                     * @param {Object} swiper
                     * @param {HTMLElement} slideEl
                     */
                    lazyImageLoad: (swiper, slideEl) =>
                    {
                        const $slide = $(slideEl);

                        // kept the same old logic from loadVisibleImages() Function
                        $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                        $slide.find('.js-init').removeClass('js-init');
                    },
                    lazyImageReady: (swiper, slideEl) =>
                    {
                        const $slide = $(slideEl);

                        S.Lazy.handleUnveilClasses($slide.find('img'));
                    }
                },

            });

            // bugfix: set aria-labels here again, because swiper sometimes overwrites it
            this.$swiperBtnLeft.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
            this.$swiperBtnRight.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);
        },

        /**
         * check if slide has stopper inside => set special styling :: smartphone only
         */
        checkStopper: function (slide)
        {
            const time = slide ? 300 : 0;
			const classHasStopper = 'has-stopper';

            // smartphone
            if (!S.Utils.Helper.mq('tablet').matches)
            {
                setTimeout(() =>
                {
                    if (this.$ctx.find('.swiper-slide-active .a-basic-stopper').length)
                    {
                        this.$swiperBtnRight.addClass(classHasStopper);
                    }
                    else
                    {
                        this.$swiperBtnRight.removeClass(classHasStopper);
                    }
                }, time);
            }
            else
            {
                this.$swiperBtnRight.removeClass(classHasStopper);
            }
        },
    });
}(jQuery));
