'use strict';
(function ($) {
	/**
	 * FormFileUploadConfirmation module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>
	 * @namespace T.Module
	 * @class FormFileUploadConfirmation
	 * @extends T.Module
	 */
	T.Module.FormFileUploadConfirmation = T.createModule({
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this._readConfig();
			this._confirmation();
			resolve();
		},
		_errorApiTokenNotAvailableCallback: function _errorApiTokenNotAvailableCallback(data) {
			if (data && !data.Success && !data.Data) {
				this.$ctx.find(".js-warning").toggleClass("h-hidden", false);
			}
			else {
				const $error = this.$ctx.find(".js-error");
				$error.toggleClass("h-hidden", false);
			}
		},
		_confirmation: function _confirmation() {
			const token = T.Utils.Helper.getUrlParameter("token");
			if (!token) {
				return;
			}

			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiUrl);
			const options = {
				type: "POST",
				data: { "Token": token },
				url: apiUrl,
				contentType: "application/x-www-form-urlencoded;charset=utf-8"
			};

			T.Utils.Ajax.fragment(options,
				this._handleConfirmationSuccess.bind(this),
				this._errorApiTokenNotAvailableCallback.bind(this));

		},
		_handleConfirmationSuccess: function _handleConfirmationSuccess(data) {
			if (data && data.Success && data.Data) {
				this.$ctx.find(".js-success").toggleClass("h-hidden", false);
			}

		},
		_readConfig: function _readConfig() {
			this._config = this.$ctx.data("config");
		}
	});
}(jQuery));