(function ($) {
	'use strict';
	/**
	 * Award decorator implementation for the basicbanderole component.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class Award
	 * @extends T.Module
	 */
	T.Module.BasicBanderole.Sedcard = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$swiperContainer: null,

		/** @type {jQuery} */
		$swiperSlidesWrapper: null,

		/** @type {Object[]} */
		$swiperSlides: null,

		/** @type {jQuery} */
		$swiperBtnRight: null,
		$swiperBtnLeft: null,

		/** @type {Object} unknown?? usecase */
		globalSwiper: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start(resolve) {
			this.$ctx = $(this._ctx);
			this.$swiperContainer = this.$ctx.find('.swiper');
			this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper');
			this.$swiperSlides = this.$swiperSlidesWrapper.find('.swiper-slide');
			this.$swiperBtnLeft = this.$ctx.find('.swiper-button-prev');
			this.$swiperBtnRight = this.$ctx.find('.swiper-button-next');

			// remove lazyload class from picture/img and replace them with swiper-lazy class
			S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides);

			resolve();
		},

		/**
		 * init swipe
		 *
		 * lazyLoading:
		 * this decorator inherits the "isLazy = true" from the parent. When this module gets in view
		 * the _lazyUpdate function is triggered and executed. The _lazyUpdate function also inherits from his parent (this decorator).
		 * So if you need to overwrite the initialisation on init/comesIntoView - write a separat _lazyUpdate function here
		 *
		 * If you dont want this module to be lazy loaded and immediately called, just set "isLazy = false" or at the beginning of this object as variable declaration "isLazy: fasle"
		 * in this decorator. If the "is-lazy" class is given remove this also.
		 *
		 * This will overwrite the parent isLazy variable.
		 */
		initSwiper() {
			// if sitecore edit mode
			const simulateTouch = this.$ctx.data('mode') !== 'edit',
				keyboardControl = this.$ctx.data('mode') !== 'edit',
				lazy = {
					checkInView: true,
					loadPrevNextAmount: 2,
					loadPrevNext: true,
					loadOnTransitionStart: true,
				};

			// init swiper container - for testing this way
			let swiperOptions = {
				spaceBetween: 30,
				slidesPerView: 'auto',
				slidesPerGroupAuto: true,
				simulateTouch: simulateTouch,
				watchSlidesProgress: true,
				preloadImages: false,
				a11y: false,
				speed: 1500,
				keyboard: {
					enabled: keyboardControl,
				},
				navigation: {
					prevEl: this.$swiperBtnLeft[0],
					nextEl: this.$swiperBtnRight[0],
				},
				on: {
					/**
					 * @param {Object} swiper
					 * @param {HTMLElement} slideEl
					 * @param {HTMLElement} imageEl :: if needed
					 */
					lazyImageLoad: (swiper, slideEl) => {
						const $slide = $(slideEl);

						// i don't know the logic behind "is-init" and why in this special case for this swiper
						// the class should not be removed (SCSS for slides: margin-left: 15px;)

						// kept the same old logic from loadVisibleImages() Function
						$slide.find('.js-init').removeClass('js-init');
					},
				}
			};

			// for develop testing purpose only - disable/enable lazy loading via param
			if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
				swiperOptions = { ...swiperOptions, lazy };
			}

			const mySwiper = new Swiper(this.$swiperContainer[0], swiperOptions);

			if (typeof this._sandbox.swiperInstance === 'undefined') {
				this._sandbox.swiperInstance = {};
			}
			this._sandbox.swiperInstance[this.$ctx.data('t-id')] = mySwiper;
		},
	});
}(jQuery));
