(function ($) {
	'use strict';

	/**
	 * BasicInputSelect module implementation.
	 *
	 * @author  <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputSelect
	 * @extends T.Module
	 */
	T.Module.BasicInputSelect.Dependence = T.createDecorator({

		/** @type {Object} */
		$validateSelects: null,

		/** @type {Object} */
		$resetBtn: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.$validateSelects = this.$ctx.closest('form').find('.js-select-validate');

			this.$resetBtn = this.$ctx.closest('form').find('.js-reset');

			this.setDisable();

			this.$validateSelects.on('change', () => {
				this.setDisable();
			});

			this.resetContent();

			this._parent.start(resolve);
		},

		// set input disabled if brand value is unset
		setDisable() {
			const disabledSelects = this.$validateSelects.filter(':not(.js-activator)');

			if (this.$validateSelects.hasClass('js-activator') && this.$validateSelects.val() === '0') {

				if (disabledSelects.val() >= '1') {
					disabledSelects.val('0', true).trigger('change').attr('disabled', true);
				}
				else {
					disabledSelects.attr('disabled', true);
				}
			}
			else {
				disabledSelects.attr('disabled', false);
			}
		},

		// reset Content by trigger Btn
		resetContent() {
			this.$resetBtn.on('click', () => {
				if (this.$validateSelects.hasClass('js-activator') && this.$validateSelects.val() >= '0') {
					this.$validateSelects.val('0').trigger('change');
				}
			});
		}

	});
}(jQuery));
