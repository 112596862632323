'use strict';
/* eslint-disable sonarjs/cognitive-complexity */
(function ($) { //NOSONAR

	T.Utils = T.Utils || {};

	T.Utils.Map = {
		// Constants from ajaxmaps2.adac.de, we need them to convert from X,Y coordinates to Lon,Lat
		MERC_2_GEO_X_HELPER: 18000 / (6371 * Math.PI),
		MERC_2_GEO_Y_HELPER: 36000000 / Math.PI,
		SMART_OFFSET: 20015087,
		SMART_UNIT: 4.809543,
		GEO_2_MERC_X_HELPER: 63.71 * (Math.PI / 180),
		GEO_2_MERC_Y_HELPER: Math.PI * (0.5 / 18000000),
		fixStickyMap: function fixStickyMap() { //NOSONAR
			com.ptvag.webcomponent.util.DomUtils.getAbsoluteY = function (element) {
				let y = 0;
				// Subtract the scroll position
				let testElem = element;

				while (testElem && testElem !== document.body) {
					if (testElem.scrollTop) {
						y -= testElem.scrollTop;
					}

					// eslint-disable-next-line sonarjs/no-collapsible-if
					if (com.ptvag.webcomponent.util.EventUtils.NEED_TO_ADD_SCROLL) {
						if (getComputedStyle(testElem, null).getPropertyValue("position") === "fixed") {
							y = com.ptvag.webcomponent.util.DomUtils.getScrollY(window);
							break;
						}
					}
					testElem = testElem.parentNode;
				}

				// Add the element position
				testElem = element;

				while (testElem) {
					let offsetTop;
					if (testElem.nodeName === "TR" && testElem.offsetHeight === 0) {
						// Safari fix
						offsetTop = testElem.offsetTop;
						const trs = testElem.parentNode.getElementsByTagName("tr");
						const trCount = trs.length;
						let tr;

						for (let i = 0; i < trCount; ++i) {
							tr = trs[i];
							if (tr === testElem) {
								break;
							}

							offsetTop += com.ptvag.webcomponent.util.DomUtils.getAbsoluteHeight(tr);
						}
					} else {
						offsetTop = testElem.offsetTop;
					}
					y += offsetTop;

					// Add IE's clientTop of all offsetParents

					if (testElem !== element && testElem.clientTop &&
						testElem.nodeName !== "TABLE") {
						y += testElem.clientTop;
					}
					testElem = testElem.offsetParent;
				}

				if (!element.offsetParent && element.y) {
					y += element.y;
				}

				return y;

			};
		},
		getGeolocationFromApiByCoords: function getGeolocationFromApiByCoords(point, successCallback, errorCallback, apireversegeocoding) {

			if ("function" !== typeof successCallback) {
				successCallback = function () { };
				console.warn("successCallback is undefined, are you sure you don't want the results of getGeolocationFromApiByCoords()?");
			}
			if ("function" !== typeof errorCallback) {
				errorCallback = function () { };
			}
			let foundAddress;
			T.Utils.Ajax.fragment({
				method: 'GET',
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() },
				url: encodeURI(`${T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), apireversegeocoding)}/?X=${point.x}&Y=${point.y}`),
			}, function (data) {

				for (let i = 0; data.Data && i < data.Data.length && !foundAddress; i++) {
					if (data.Data[i].Kurztext && data.Data[i].Punkt1 && undefined !== data.Data[i].Punkt1.X && undefined !== data.Data[i].Punkt1.Y && (!data.Data[i].Punkt2 || data.Data[i].Punkt2.X === 0 && data.Data[i].Punkt2.Y === 0)) {
						foundAddress = data.Data[i];
					}
				}
				successCallback(foundAddress);
			}, function (err) {
				console.warn(err);
				errorCallback();
			});
		},

		/**
		 * scroll to Container by click on element with data-scrollto="myclassname"
		 * points in Mercator
		 */
		renderStaticMap: function renderStaticMap(id, mercatorPoints, radius, mapWidth, mapHeight, baseUrl) {
			const url = this.makeStaticMapURL(mercatorPoints, radius, mapWidth, mapHeight, baseUrl);
			$(`#${id}`).prepend($('<img>', { id: 'adacbasic-map-static', src: url }));
			return url;
		},

		/**
		 *
		 * @param {Mercator} location
		 * @param {boolean} setPoint
		 * @param {number} zoom
		 * @param {boolean} debug default = false
		 * @return {AMAMap}
		 */
		// eslint-disable-next-line no-unused-vars
		renderMap: function renderMap(location, setPoint = false, zoom = 10, debug = false) { //NOSONAR
			if (!AMAMAPS) {
				T.Utils.Logger.log("AMAMAPS not initialized");
				return {};
			}
			const smartUnit = AMAMAPS.AMAMercator_2_SmartUnits(location);
			const init = "MapContainer" !== AMAMAPS.MapDivName;
			if (init) {
				AMAMAPS.AMAinit(smartUnit.x, smartUnit.y, zoom, null, "MapContainer");
			}
			const map = AMAMAPS.AMAgetAJAXMapReference();
			if (init && debug) {
				// debug remove in production mode
				map.getLayer("position").setEnabled(true);
				map.getLayer("debug").setEnabled(true);
			}

			map.setCenter(smartUnit);
			map.setZoom(zoom);
			T.Utils.Map.fixStickyMap();
			return map;
		},

		resetMapContainer: function () {
			if (AMAMAPS) {
				AMAMAPS.MapDivName = null;
			}
		},

		clearVectorLayer: function clearVectorLayer(map) {
			if (map) {
				const vectorLayer = map.getLayer("vector");
				vectorLayer.removeAllElements();
			}
		},

		manipulateLayer: function manipulateLayer(map, layer, func, params) {
			if (map) {
				const l = map.getLayer(layer);
				if (l && 'function' === typeof l[func]) {
					l[func].apply(l, params);
				}
			}
		},

		/**
		 *
		 * @param {AMAMap} map
		 * @param {Mercator} location
		 * @param {string} tooltipText can be html
		 * @param {HTMLImageElement} iconHtml
		 * @param {Function} onClickHandler
		 */
		createPoint: function (map, location, tooltipText, iconHtml, onClickHandler) {
			const poi = this.createPointEx(map, location, tooltipText, iconHtml, onClickHandler);
			return poi.icon;
		},

		createPointEx: function createPointEx(map, mercator, tooltipText, iconHtml, onClickHandler) {
			const smartUnit = T.Utils.Geo.mercator2SmartUnit(mercator);
			const vectorLayer = map.getLayer("vector");
			const vector = com.ptvag.webcomponent.map.vector;

			const result = {
				icon: null,
				clickArea: null,
				tooltip: null,
			};
			result.icon = this.getIcon(iconHtml);
			result.icon.set(smartUnit);
			vectorLayer.addElement(result.icon);

			if (onClickHandler) {
				result.clickArea = new vector.ClickArea();
				result.clickArea.set({ x: smartUnit.x, y: smartUnit.y });
				result.clickArea.setHandler(onClickHandler);
				result.clickArea.setTolerance(100);
				vectorLayer.addElement(result.clickArea);
			}

			if (tooltipText) {
				result.tooltip = new vector.Tooltip();
				result.tooltip.set({ x: smartUnit.x, y: smartUnit.y, tolerance: 34 });
				result.tooltip.setText(tooltipText);
				vectorLayer.addElement(result.tooltip);
			}

			return result;
		},

		removePoint: function removePoint(map, element) {
			if (element && element.getId) {
				const vectorLayer = map.getLayer("vector");
				vectorLayer.removeElement(element.getId());
			}
		},

		removePointEx: function removePointEx(map, poi) {
			if (poi) {
				for (const key in poi) {
					this.removePoint(map, poi[key]);
				}
			}
		},

		getZoom: function getZoom(map) {
			return map.getZoom();
		},

		setZoom: function setZoom(map, zoom) {
			map.setZoom(zoom);
		},

		getCenter: function getCenter(map) {
			return map.getCenter();
		},

		setCenter: function setCenter(map, mercator) {
			const smartUnit = T.Utils.Geo.mercator2SmartUnit(mercator);
			map.setCenter(smartUnit);
		},

		getIcon: function getIcon(iconHtml) {
			const vector = com.ptvag.webcomponent.map.vector;
			const VectorLayerClass = com.ptvag.webcomponent.map.layer.VectorLayer;

			const img = iconHtml.clone();
			img.attr("id", null);
			img.uniqueId();
			const html = new vector.HTML();
			html.setAlignment(img.data("mapsalign") || (VectorLayerClass.ALIGN_MID_VERT | VectorLayerClass.ALIGN_MID_HORIZ));
			html.setHtml($('<div>').append(img).html());
			return html;
		},

		updateIcon: function updateIcon(vectorHtml, iconHtml) {
			const VectorLayerClass = com.ptvag.webcomponent.map.layer.VectorLayer;
			const title = $(vectorHtml._valueHtml).find("img").attr("title");
			const img = iconHtml.clone();
			img.attr('title', title);
			vectorHtml.setAlignment(img.data("mapsalign") || (VectorLayerClass.ALIGN_MID_VERT | VectorLayerClass.ALIGN_MID_HORIZ));
			vectorHtml.setHtml($('<div>').append(img).html());
		},

		makeStaticMapURL: function makeStaticMapURL(mercatorPoints, radius, mapWidth, mapHeight, baseUrl = "https://maps.adac.de/Pages/Minimap/MiniMap.aspx") {
			const north = mercatorPoints.y + radius;
			const south = mercatorPoints.y - radius;
			const west = mercatorPoints.x - radius;
			const east = mercatorPoints.x + radius;
			const pinIconName = "icon-pin-cardamage.png";
			const cordParams = `?North=${north}&South=${south}&West=${west}&East=${east}`;
			return `${baseUrl + cordParams}&MapWidth=${mapWidth}&MapHeight=${mapHeight}&PinX=${mercatorPoints.x}&PinY=${mercatorPoints.y}&PinIcon=${pinIconName}&do=add`;
		},

		// Points in geodecimal
		showLocationInfo: function showLocationInfo(point, id) {
			if ($(`#${id}`).find(".js-location-info-coord")) {
				$(`#${id}`).find(".js-location-info-coord").text(this.generateLocationInfo(point));
				$(`#${id}`).toggleClass("h-hidden");
			}
		},

		generateLocationInfo: function generateLocationInfo(point) {
			return `${this.getTypedDMS(this.degToDMS(point.y), 0)} ${this.getTypedDMS(this.degToDMS(point.x), 1)}`;
		},

		degToDMS: function degToDMS(deg) {
			const isNeg = 0 > deg;
			deg = Math.abs(deg / 100000.0);
			let d = Math.floor(deg);
			const minfloat = (deg - d) * 60;
			let m = Math.floor(minfloat);
			const secfloat = (minfloat - m) * 60;
			let s = Math.round(secfloat);
			if (s === 60) {
				m++;
				s = 0;
			}
			if (m === 60) {
				d++;
				m = 0;
			}
			if (isNeg) {
				d *= -1;
			}
			return (`${d}° ${m}' ${s}''`);
		},

		getTypedDMS: function getTypedDMS(dms, dir) {
			const dmsmap = [
				['N', 'S'],
				['O', 'W']
			];
			const isNeg = 0 === dms.indexOf("-");
			if (isNeg) {
				dms = dms.substring(1);
			}
			return `${dmsmap[dir][isNeg ? 1 : 0]}: ${dms}`;
		},
		getCenterFromGeoDecimalRect: function getCenterFromGeoDecimalRect(point1, point2) {
			return {
				x: (point1.x + point2.x) / 2,
				y: (point1.y + point2.y) / 2
			};
		},


		mercatorToGedecimal: function mercatorToGedecimal(mercPoint) {
			return com.ptvag.webcomponent.map.CoordUtil.mercator2GeoDecimal(mercPoint);
		},
		geoDecimalToMercator: function geoDecimalToMercator(geoPoint) {
			const smartUnits = com.ptvag.webcomponent.map.CoordUtil.geoDecimal2SmartUnit({
				x: Number(geoPoint.x),
				y: Number(geoPoint.y)
			});
			const result = AMAMAPS.smartUnit2Mercator(smartUnits);
			result.x = Math.round(result.x);
			result.y = Math.round(result.y);
			return result;
		},
		getZoomForPermiter: function getZoomForPermiter(permiter) {
			switch (permiter) {
				case 5:
					return 8;
				case 10:
					return 9;
				case 20:
					return 10;
				case 30:
					return 10;
				case 40:
					return 11;
				case 50:
					return 11;
				default:
					return 3;
			}
		},
		createRouteUrl: function createRouteUrl(baseMapUrl, destination, start, vehicle) {
			// default.aspx - maps-alt
			const oldMaps = baseMapUrl.toLowerCase().endsWith('/default.aspx');
			const params = {};
			let pt = { x: destination.x, y: destination.y };
			if (oldMaps) {
				if (!T.Utils.Geo.isMercatorPoint(pt)) {
					pt = T.Utils.Geo.latlonToMercator(pt);
				}
				params.preset = true;
				params.id = 101;
				params.dest = `${destination.postCode} ${destination.location} ${destination.street}|||${destination.postCode}|${destination.location}|${destination.street}|||${pt.x}|${pt.y}||`;
				if (start) {
					pt = { x: start.x, y: start.y };
					if (!T.Utils.Geo.isMercatorPoint(pt)) {
						pt = T.Utils.Geo.latlonToMercator(pt);
					}
					params.start = `${start.name}|||${start.postCode}|${start.location}|${start.street}|||${pt.x}|${pt.y}||`;
				}
			} else {
				if (T.Utils.Geo.isMercatorPoint(pt)) {
					pt = T.Utils.Geo.mercatorToLonLat(pt);
				}
				params.destination = `${pt.y}_${pt.x}_1`;
				if (start) {
					pt = { x: start.x, y: start.y };
					if (T.Utils.Geo.isMercatorPoint(pt)) {
						pt = T.Utils.Geo.mercatorToLonLat(pt);
					}
					params.places = `${pt.y}_${pt.x}_2,${params.destination}`;
				}
				if (vehicle) {
					params['vehicle-type'] = vehicle;
				}
			}
			return T.Utils.Helper.updateUrlParameter(params, baseMapUrl);
		}
	};
})(jQuery);