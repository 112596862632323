'use strict';
(function ($) {
	/**
	 * EnsureUserAuth module implementation.
	 * On content pages a condition can be set and / or checked.
	 * This mechanism can be used to ensure that a page has been visited before another page is shown.
	 *
	 * @author  <hauke.lueneburg@namics.com>
	 * @namespace T.Module
	 * @class EnsureUserAuth
	 * @extends T.Module
	 */
	T.Module.EnsureUserAuth = T.createModule({

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		async start(resolve) {
			this.$ctx = $(this._ctx);

			const redirecturl = this.$ctx.data('urlafterlogout');

			const isUserAuthenticated = await this._isUserAuthenticated();
			if (!isUserAuthenticated && redirecturl) {
				this._doRedirect(redirecturl);
			}

			resolve();
		},

		_isUserAuthenticated: async function () {
			return T.Utils.Auth.isLoggedIn();
		},

		_doRedirect: function (redirecturl) {
			window.location.href = redirecturl;
		}

	});
}(jQuery));

