(function($) {
	'use strict';
	/**
	 * VehicleTestCriterion module implementation.
	 *
	 * @author Niclas Bork <n.bork@edelweiss72.de>
	 * @namespace T.Module
	 * @class VehicleTestCriterion
	 * @extends T.Module
	 */
	T.Module.VehicleTestCriterion = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $nav: null,

        /** @type {Object[]} */
        $navList: null,

        /** @type {Object[]} */
        $navBtn: null,

        /** @type {jQuery} */
        $content: null,

        /** @type {Object[]} */
        $tabcontents: null,

		start: function (resolve) {
            this.$ctx = $(this._ctx);

            this.$nav = this.$ctx.find('.m-nav').eq(0);
            this.$navList = this.$nav.find('> li');
            this.$navBtn = this.$nav.find('a');
            this.$tabcontentWrap = this.$ctx.find('.m-tabcontent');
            this.$tabcontents = this.$ctx.find('.m-tabcontent-inside');

            // set nav btn events
            this.setNavBtnEvents();

            // set nav btn height based on data attribute
            this.setNavBtnHeight();

            resolve();
		},
        /**
         * setNavBtnEvents
         *
         */
        setNavBtnEvents: function () {
            const that = this;

            this.$navBtn.on('click touch', function (e) {
                e.preventDefault();

                const activeContentId = that.$ctx.find('li.is-active > a').attr('href').substring(1),
                    newTab = $(this),
                    newId = newTab.attr('href').substring(1);

                if (newId !== activeContentId){

                    // reset active class
                    that.$navList.removeClass('is-active');

                    // set active
                    newTab.closest('li').addClass('is-active');

                    // hide actual content
                    that.$ctx.find(`#${  activeContentId}`).hide();

                    // show new content
                    that.$ctx.find(`#${  newId}`).removeClass('h-visuallyhidden').show();
                }

                return false;
            });
        },
        /**
         * setNavBtnHeight
         */
        setNavBtnHeight: function () {
            const data = this.$ctx.find('.m-nav .m-nav-btn');

            data.each(function () {
                const dataheight = parseFloat($(this).data('criterion'));
                const dataBtn = $(this).find('.a-basic-btn');
                const triangle = $(this).find('.m-triangle');

                // calculation: modulheight / 100% * data-criterion
                $(dataBtn).css('height', `${305 / 100 * dataheight}px`);

                if ($(window).width() > 768) {

                    $(triangle).css({
                        'border-top-width': `${dataheight / 2 + dataheight}px`,
                        'border-bottom-width': `${dataheight / 2 + dataheight}px`,
                    });
                }
            });
        }
	});
}(jQuery));
