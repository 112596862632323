(function($) {
    'use strict';
    /**
     * FdlCalculator module implementation.
     *
     * @author  <s.krause@edelweiss72.de>
     * @namespace T.Module
     * @class FdlCalculator
     * @extends T.Module
     */
    T.Module.FdlCalculator = T.createModule({
        _amount: 10000,
        _period: 60,
        _currentrate: 210.00,
        _calcData: null,
        _calcUnit: null,
        _interest: 0,
        $component: null,
        initial: true,

        start: function(resolve) {
            const that = this;
            this.$component = $(this._ctx);

			this.fdlCalcCreditReplacement = 'm-fdl-calculator--creditreplacement';
			this.jsDiffMonthEm = '.js-difference-month em';
			this.fdlCalculator = '.m-fdl-calculator';

            if (this.$component.find('.mm-calc').length) {
                that._initCalculator();
            }

            resolve();
        },

        _initCalculator: function() {
            const that = this,
                temp = {};

            this.$component = $(this._ctx);

            this._calcData = this.$component.data('init');
            this._calcUnit = this.$component.data('unit');

            const deferred = new $.Deferred(); //NOSONAR kommt durch Plugin
            let promise = deferred.promise();

            $.each(that._calcData, function(item, key) {
                (function() {
                    promise = promise.then(function() {
                        return temp[item] = parseFloat(key); //NOSONAR
                    });
                }());
            });

            promise.done(function() {
                that._calcData.lifespaninterest = temp;
                that._calcInit(that);
            });

            deferred.resolve();
        },

        _calcInit: function(that) {
            const jsResult = '.js-result';
            const inputTextInput = '.a-basic-input-text > input';

            // Add 07.05.2019 Carsten Meiselbach
            that._amount = parseInt(this.$component.find(jsResult).eq(0).val(), 10);
            that._period = parseInt(this.$component.find(jsResult).eq(1).val(), 10);
            that._interest = that._calcGetInterest(that, that._period);
            that._calcRenderResult(this.$component, that._calcMR(that, that._period, that._interest, that._amount));
            // End 07.05.2019 Carsten Meiselbach

            // add 18.07.2018 Mahria Ibrahim
            let selectedValues = T.Utils.Store.get('selectedValues', 'session');
            if (selectedValues === null || selectedValues === undefined) {
                selectedValues = {};
                selectedValues['period'] = parseInt(this.$component.find(jsResult).eq(1).val(), 10);
                selectedValues['amount'] = parseInt(this.$component.find(jsResult).eq(0).val(), 10);
                T.Utils.Store.set('selectedValues', selectedValues, 'session');
            }
            // End add 18.07.2018 Mahria Ibrahim

            // handling amount-slider change event
            that._events.on('FdlCalculator.changeAmount', ($ctx) =>
			{
                const $result = $ctx.find(jsResult);
                that._amount = parseInt($result.val(), 10);

                // fill amount value from storage, only oin page load
                if (that.initial === true) {
                    const sliderOptions = T.Utils.Store.get('BasicCalculatorSliderAmount', 'session');
                    if (sliderOptions) {
                        const storageValue = `${parseInt(sliderOptions.start, 10)} ${that._calcUnit}`;
                        $ctx.find(jsResult).eq(0).val(storageValue);
                    }
                    that.initial = false;
                }

                that._amount = parseInt($result.val(), 10);

                that._calcRenderResult($ctx, that._calcMR(that, that._period, that._interest, that._amount));
                // add 18.07.2018 Mahria Ibrahim
                selectedValues['amount'] = that._amount;
                T.Utils.Store.set('selectedValues', selectedValues, 'session');
                // End add 18.07.2018 Mahria Ibrahim

                // calculate and render difference
                if (that.$component.hasClass(this.fdlCalcCreditReplacement)) {
                    that._calcCreditdifference($ctx, that._period, that._amount, that._currentrate, that._calcMR(that, that._period, that._interest, that._amount), that);
                }
            });

            // handling period-slider change event
            that._events.on('FdlCalculator.changePeriod', ($ctx) =>
			{
                const $result = $ctx.find(jsResult);
                that._period = parseInt($result.val(), 10);

                // Check if there is matching data-value for the actual slider value
				// eslint-disable-next-line eqeqeq
				//NOSONAR
                if (typeof that._calcData.lifespaninterest[that._period.toString()] != 'undefined') {
                    // request interest for given period
                    that._interest = that._calcGetInterest(that, that._period);

                    // calculate and render result
                    that._calcRenderResult($ctx, that._calcMR(that, that._period, that._interest, that._amount));
                    // add 18.07.2018 Mahria Ibrahim
                    selectedValues['period'] = that._period;
                    T.Utils.Store.set('selectedValues', selectedValues, 'session');
                    // End add 18.07.2018 Mahria Ibrahim

                    // calculate and render difference
                    if (that.$component.hasClass(this.fdlCalcCreditReplacement)) {
                        that._calcCreditdifference($ctx, that._period, that._amount, that._currentrate, that._calcMR(that, that._period, that._interest, that._amount), that);
                    }
                }
            });

            // handling currentrate input change event
            this.$component.find(inputTextInput).on('blur', (e) =>
			{
                let input = that._stringToNum($(e.currentTarget).val());
                const initData = that.$component.find('.a-basic-input-text').data('init'),
                    maxValue = Number(initData["maxValue"]);

                if (input > maxValue) {
                    $(this).val(that._numToString(maxValue));
                    input = maxValue;
                } else {
                    $(this).val(that._numToString(input));
                }

                that._currentrate = input;
                that._interest = that._calcGetInterest(that, that._period);

                // calculate and render difference
                that._calcCreditdifference($(this), that._period, that._amount, that._currentrate, that._calcMR(that, that._period, that._interest, that._amount), that);

                const text = that.$component.find(this.jsDiffMonthEm).text();
                const textToInt = parseInt(text, 10);
                that._renderDifferenceContainer(textToInt);
            });

            this.$component.find(inputTextInput).on('focus', function() {
                $(this).val('');
            });
            this.$component.find(inputTextInput).on('blur', function() {
                const initData = that.$component.find('.a-basic-input-text').data('init'),
                    initValue = Number(initData["initValue"]);

                if ($(this).val() === '') {
                    $(this).val(that._numToString(initValue));
                }
            });
            this.$component.find(inputTextInput).on('keyup', function(e) {
                const input = $(this);
                let text = input.val();

                if (e.which === 13) {
                    if (S.Utils.Helper.isTouch) {
                        input.trigger('blur');
                    }
                } else {
                    // filter all letters
                    text = text.replace(/[^0-9.,€ ]/g, "");
                    input.val(text);
                }
            });

            that._events.on('FdlCalculator.setAmount', ($ctx) => // eslint-disable-line
			{
                const text = that.$component.find(this.jsDiffMonthEm).text();
                const textToInt = parseInt(text, 10);
                that._renderDifferenceContainer(textToInt);
            });
            that._events.on('FdlCalculator.setPeriod', ($ctx) => // eslint-disable-line
			{
                const text = that.$component.find(this.jsDiffMonthEm).text();
                const textToInt = parseInt(text, 10);
                that._renderDifferenceContainer(textToInt);
            });

            that.$component.find('.a-basic-calculator-slider input').bind('mousedown', ($ctx) => // eslint-disable-line
			{
                const text = that.$component.find(this.jsDiffMonthEm).text();
                const textToInt = parseInt(text, 10);
                that._renderDifferenceContainer(textToInt);
            });

            that.$component.find(this.jsDiffMonthEm).bind("DOMSubtreeModified", function() {
                const $result = parseInt($(this).text(), 10);
                if (isNaN($result)) {
                    return;
                }
                // ONLY USE IF TEXT SHOULD BE DISPLAYED INSTANTLY
				// eslint-disable-next-line
                return; //NOSONAR
            });


        },

        _numToString: function(num) {
            const that = this;
            let calcUnit = '';

            if (that._calcUnit) {
                calcUnit = ` ${  that._calcUnit}`;
            }

            num = Number(num);

            return $.number(num, 2, ',', '.') + calcUnit;
        },

        _stringToNum: function(string) {
            let result = string,
                num = '';

            if (result.indexOf(',') > -1) {
                result = result.replace(/\./g, '');
                const arrayComma = result.split(',');
                const cent = arrayComma.slice(-1).pop();
                for (let i = 0; i < (arrayComma.length - 1); i++) {
                    num += arrayComma[i];
                }


                num = `${num}.${cent}`;
            } else if (result.indexOf('.') > -1) {
                const arrayStop = result.split('.');
                const cent = arrayStop.slice(-1).pop();

                for (let i = 0; i < (arrayStop.length - 1); i++) {
                    num += arrayStop[i];
                }
                num = `${num}.${cent}`;
            }
            if (num !== '') {
                result = num;
            }

            result = Number(result);

            if (isNaN(result)) {
                result = 0;
            }

            return result;
        },

        _calcCreditdifference: function(sliderObj, period, amount, currentrate, result, that) {
            const difference = that._calcCD(result, currentrate);
            const periodicDifference = that._calcPD(difference, period);

            sliderObj.closest(this.fdlCalculator).find(this.jsDiffMonthEm).html(that._numToString(difference));
            sliderObj.closest(this.fdlCalculator).find('.js-difference-year em').html(that._numToString(periodicDifference));
        },

        _renderDifferenceContainer: function($result) {
            const difference = $result,
                btn = this.$component.find('.a-basic-btn').eq(0),
                equals = this.$component.find('.js-equals').eq(0),
                negativ = this.$component.find('.js-negativ').eq(0);

            // Hide Positiv and Negativ Infotexts
            this.$component.find('.js-equals, .js-negativ, .js-positiv').hide();
            if (difference < 0) {
                negativ.show();
                btn.hide();
            } else if (difference === 0) {
                equals.show();
                btn.show();
            } else {
                btn.show();
                return;
            }
        },

        _calcGetInterest: function(that, period) {
            return that._calcData.lifespaninterest[period.toString()];
        },

        _calcRenderResult: function(sliderObj, result) {
            if (this.$component.hasClass(this.fdlCalcCreditReplacement)) {
                result = this._numToString(result);
            } else {
                result = `${Math.ceil(result)} ${this._calcUnit}`;
            }

            // Add 07.05.2019 Carsten Meiselbach
            if(sliderObj.parents(this.fdlCalculator).length > 0) {
                sliderObj.parents(this.fdlCalculator).eq(0).find('.mm-amount').eq(0).html(result);
            } else {
                sliderObj.find('.mm-amount').eq(0).html(result);
            }
            // End 07.05.2019 Carsten Meiselbach
        },

        _calcCD: function(result, currentrate) {
            return currentrate - result;
        },

        _calcPD: function(difference, period) {
            return difference * period;
        },

        _calcMR: function(that, m, EZ, GK) {
            let G1, z, z1, z2;

            EZ = EZ / 100;
            const Diff = 0.0000000001;
            z1 = 0;
            z2 = (GK / m) * 10;
            const G2 = GK;

            do {
                z = (z1 + z2) / 2;
                G1 = 0;
                for (let i = 1; i <= m; i++) {
                    G1 = G1 + (z / (Math.pow((1 + EZ), (i / 12))));
                }
                if (G1 > G2) { z2 = z; }
                if (G1 < G2) { z1 = z; }
            } while (that._calcCirca(G1, G2, Diff) !== true);

            return that._calcRound(z);
        },

        _calcCirca: function(val1, val2, limit) {
            return Math.abs(val1 - val2) < Math.abs(limit);
        },

        _calcRound: function(val) {
            return Math.round(val * 100) / 100;
        }
    });
}(jQuery));
