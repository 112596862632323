/**
 * EventTrackingGenericMyAdacDashboard implementation
 *
 * @author <marc.radziwill@merkle.com>
 * @class EventTrackingGenericMyAdacDashboard
*/

// eslint-disable-next-line no-undef
class EventTrackingGenericMyAdacDashboard extends EventTrackingGeneric {


	_connectedCallback() {
		T.Utils.Store.set(this._sessionKeyEventCategory, null, T.Utils.Store.SESSION);
		super._connectedCallback();
	}

	_getEventActionByElement($element) {
		if (this._isInsideTile($element)) {
			const headline = this._getHeadline($element);
			if (headline) {
				return `${headline} | click`;
			}
		}
		return super._getEventActionByElement($element);
	}

	_getEventCategory($element, action) {
		if (this._isInsideTile($element)) {
			const result = `${this._getPageHeadline($element)} | ${this._getSectionHeadline($element)}`;
			T.Utils.Store.set(this._sessionKeyEventCategory, result, T.Utils.Store.SESSION);
			return result;
		}
		return super._getEventCategory($element, action);
	}

	_getPageHeadline($element) {
		return T.Utils.Format.normalizeSpace($element.closest(".l-main-content").find("h1").text());
	}

	_getSectionHeadline($element) {
		return T.Utils.Format.normalizeSpace($element.closest(".mm-dashbox-theme").find("h2").text());
	}

	_getHeadline($element) {
		const $box = $element.closest(".mm-box");
		if ($box.length) {
			return T.Utils.Format.normalizeSpace($box.hasClass("js-vehicle-tile") ? "Mein Fahrzeug" : $box.find("h3:not(.h-hidden)").text());
		}
		return '';
	}

	_isInsideTile(element) {
		return !!element.parents('[data-t-name="MeinAdacDashboard"]').length;
	}

}
window.customElements.define('event-tracking-generic-myadac-dashboad', EventTrackingGenericMyAdacDashboard);