'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) { //NOSONAR
	/**
	 * KameleoonConsent module implementation.
	 *
	 * @author  <marc.radziwill@merkle.com>
	 * @namespace T.Module
	 * @class KameleoonConsent
	 * @extends T.Module
	 */
	T.Module.KameleoonConsent = T.createModule({

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		_kameleoonConsentKey: 'Kameleoon SAS',

		start(resolve) {

			T.Utils.Helper.isConsentMatch(this._kameleoonConsentKey, (consentStatus) => {
				this.consentStatus = consentStatus;
				this.setKameleoonConsent();
			});
			kameleoonQueue.push(this.setKameleoonConsent.bind(this));
			resolve();
		},

		setKameleoonConsent: function setKameleoonConsent() {
			if (Kameleoon) {
				Kameleoon.API.Core.runWhenConditionTrue(
					() => () => this.consentStatus,
					() => {
						if (this.consentStatus) {
							Kameleoon.API.Core.enableLegalConsent('AB_TESTING');
							Kameleoon.API.Core.disableLegalConsent('PERSONALIZATION');
						} else if (T.Utils.Helper.userChoiceExists()) {
							if (this.consentStatus === false) {
								Kameleoon.API.Core.disableLegalConsent('AB_TESTING');
							}
							Kameleoon.API.Core.disableLegalConsent('PERSONALIZATION');
						}
					});
			}
		},
	});
}(jQuery));

