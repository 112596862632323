(function ($) {
    'use strict';
    /**
     * FdlCreditcardconfig module implementation.
     *
     * @author Lars Meyer <l.meyer@edelweiss72.de>
     * @namespace T.Module
     * @class FdlCreditcardconfig
     * @extends T.Module
     */
    T.Module.FdlCreditcardconfig = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $pkgBox: null,

        /** @type {jQuery} */
        $pkgContainer: null,

        /** @type {jQuery} */
        $boxArrows: null,

        /** @type {Object} */
        $scrollPaneApi: null,

        /** @type {Object} */
        $tippLayerTrigger: null,

        /** @type {Number} */
        pkgBoxHeight: null,

        /** @type {Number} */
        txtContentHeight: null,

        /** @type {Object} */
        $checkboxes: null,

        /** @type {Number} */
        $checkCount: null,

        /** @type {Object} */
        $specialboxWrapper: null,

        /** @type {Object} */
        $specialbox: null,

        /** @type {Object} */
        $specialboxPrice: null,

        /** @type {Object} */
        $stickyFooter: null,

        /** @type {Object} */
        $stickyFooterMonth: null,

        /** @type {Object} */
        $stickyFooterYearFee: null,

        /** @type {Boolean} */
        sideNav: false,

        start: function (resolve) {

            const that = this;

            this.$ctx = $(this._ctx);

            // toggle stuff
            this.pkgBoxHeight = 0;
            this.txtContentHeight = 0;
            this.$pkgContainer = this.$ctx.find('.oo-packagecontainer');
            this.$pkgBox = this.$ctx.find('.oo-packagebox');
            this.$boxArrows = this.$ctx.find('.oo-special-arrows');
            // cbx stuff
            this.$checkboxes = this.$ctx.find('.oo-togglecontainer .oo-checkbox > .a-basic-input-checkbox > input');
            this.$specialboxWrapper = this.$ctx.find('.oo-specialbox');
            this.$specialbox = this.$ctx.find('.oo-specialbox .a-basic-input-checkbox > input ');
            this.$specialboxPrice = this.$specialbox.parents('.oo-input').next('.oo-pricing-box').find('.oo-price-value');
            this.$checkCount = 0;
            // layer stuff
            this.$tippLayerTrigger = this.$ctx.find('article.oo-platinum-tipp');
            // footer stuff
            this.$stickyFooter = this.$ctx.find('.oo-fdl-stickyfooter');
            this.$stickyFooterMonth = this.$stickyFooter.find('.oo-stickyfooter-price > .oo-monthly > .oo-monthly-val');
            this.$stickyFooterYearFee = this.$stickyFooter.find('.oo-stickyfooter-text > p > .oo-annual-fee');

            if (that.$tippLayerTrigger.length) {
                this.setLayerData();
            }

            // check if side nav is present
            this.sideNav = document.querySelector('body > .l-outer--nav') !== null;

            this.calcFooterPos();
            this.setArrows();
            this.windowResize();
            this.calcHeights();
            this.setScrollBar();
            this.cbxEvents();

            resolve();
        },

        /**
         * init or destroy custom scrollbar
         *
         */
        setScrollBar: function () {

            const that = this;

            //listen to click-event
            this._events.on('FdlCreditCardConfig.recalculateHeight', function ($clickedElement, status) {

                //get corresponding content element
                const $scrollContent = $clickedElement.next();

                // init scrollbar
                if (status === 'open') {
                    $scrollContent.css('height', that.txtContentHeight);
                    $scrollContent.jScrollPane({
                        //settings
                        // showArrows is true -> currently used to define h-spacing (see styles)!
                        showArrows: true
                    });

                    //prevent arrow-btn events
                    $scrollContent.find('.jspArrow').off();

                    //set width to container width
                    $scrollContent.css('width', '100%').find('.jspContainer, .jspPane').css('width', '100%');
                }

                // destroy scrollbar
                else {

                    that.$scrollPaneApi = $scrollContent.data('jsp');

                    if (typeof that.$scrollPaneApi !== 'undefined') {
                        that.$scrollPaneApi.destroy();
                    }

                    $clickedElement.next().css('height', '');
                }
            });
        },

        /**
         * calculate height of wrappers and content-elements
         *
         */
        calcHeights: function () {

            const that = this;
            let toggleListMaxHeight = 0,
                headItemHeight = 71;
            const bufferHeight = 35,
                contentOffset = 62;

            S.Utils.delayed('calcPkgBoxHeight', 40, () => {

                // get initial height of the longest (=largest) packagebox
                that.$pkgBox.each(function (index, element) {

                    // get item-px-height
                    const elementListHeight = $(element).find('.m-basic-toggle--infoboxlist').outerHeight(true);
                    // store largest height-value
                    if (toggleListMaxHeight < elementListHeight) {
                        toggleListMaxHeight = elementListHeight;
                    }

                    // determine height of largest head area
                    const elementHeadItem1 = $(element).find('.oo-price-old').outerHeight(),
                        elementHeadItem2 = $(element).find('.oo-input').outerHeight(),
                        elementHeadItemHeight = elementHeadItem1 + elementHeadItem2;

                    // store largest height-value of head-items
                    if (headItemHeight < elementHeadItemHeight) {
                        headItemHeight = elementHeadItemHeight;
                    }
                });

                // for desktop
                if (S.Utils.Helper.mq('desktop').matches) {

                    // set wrapper height
                    that.pkgBoxHeight = toggleListMaxHeight + headItemHeight + bufferHeight;
                    that.$pkgBox.css('height', that.pkgBoxHeight);

                    // set text-wrapper height
                    that.txtContentHeight = toggleListMaxHeight - contentOffset;
                }

                // for tablet
                else if (S.Utils.Helper.mq('tablet').matches) {

                    //reset values
                    that.$pkgBox.css('height', '');

                    // set wrapper height
                    that.pkgBoxHeight = toggleListMaxHeight + headItemHeight + bufferHeight;
                    that.$pkgBox.css('height', that.pkgBoxHeight);

                    // set text-wrapper height
                    that.txtContentHeight = toggleListMaxHeight - contentOffset;
                }

                // for mobile
                else {
                    // reset height value
                    that.$pkgBox.css('height', '');
                }

            });
        },

        /**
         * add event listeners for mq's
         * call height-calculation on change
         *
         */
        windowResize: function () {

            const that = this;

            // desktop
            S.Utils.Helper.mq('desktop').addListener(function () {

                that.calcHeights();
                that.calcStickyLogic();
            });

            // tablet
            S.Utils.Helper.mq('tablet').addListener(function () {

                that.calcHeights();
                that.setArrows('tablet');
                that.calcStickyLogic();
            });

            // call calcHeight on window-resize to style boxes properly in tablet-view
            window.addEventListener('resize', () => {
                S.Utils.delayed('scrollpaneCalculation', 200, function () {
                    if (S.Globals.MediaQuery.mqRightNow === 'tablet') {

                        that.calcHeights();
                        that.calcStickyLogic();

                        // recalc scrollbar height on resize
                        $('.jspScrollable').each(function () {
                            that._events.emit('FdlCreditCardConfig.recalculateHeight', $(this).prev(), 'close');
                        });
                    }
                });
            });
        },

        /**
         * set info-layer data via prices from other elemens
         *
         */
        setLayerData: function () {

            const that = this;
            let boxValSum = 0;

            // get platinum price as number from specialBox
            const pricePlatinum = that.convertToNumber(that.$specialboxPrice.text());

            // get prices of each cbx
            that.$pkgBox.each(function (index, element) {

                // get box price as number
                const boxVal = that.convertToNumber($(element).find('.oo-price').text());

                // count to sum of all prices
                boxValSum = boxValSum + boxVal;
            });

            // get rid of float decimal madness:
            // convert to string with two decimal chars and reconvert to number
            const priceBoxSum = parseFloat(boxValSum.toFixed(2));

            // calc diff-value and make it a string
            const priceDifference = that.convertToString(priceBoxSum - pricePlatinum);


            // build special stuff for layers
            that.$tippLayerTrigger.each((index, item) => {

                if ($(item).next('div.tipplayer-container').length) {

                    const layerContainer = $(item).next('div.tipplayer-container');

                    // insert prices as strings in each layer
                    $(layerContainer).find('.diff-price').append(priceDifference).css('font-weight', 'bold');
                    $(layerContainer).find('.platinum-price').append(that.$specialboxPrice.text()).css('font-weight', 'bold');
                }
            });


        },

        /**
         * show info-layer
         *
         * @param {String} mode
         */
        showLayer: function (mode) {

            const that = this;

            const layerBtn = $(`article.oo-platinum-tipp.${mode}`).children('a');
            $(layerBtn).trigger('click');

            // special clickevents for layers
            const layerContainer = $('.tipplayer-container.ll-lightbox-inner'),
                closeBtn = $(layerContainer).find('.mfp-close');

            if (layerContainer.hasClass('TwoBoxes')) {

                $(layerContainer).find('.pkg-tipp-layer-btncontainer > .ll-main-cols-m').each(function (index, button) {

                    // 1st Button is prime selection
                    if ($(button).is(':first-child')) {

                        // edit elems, influenced by prime selection
                        $(button).on('click', function () {

                            that.$checkboxes.prop('checked', false);
                            that.$specialbox.prop('checked', true);
                            that.$checkCount = 0;
                            that.calcFooter($(that.$specialbox), 0);

                            // calc offset for automated scrolling
                            const platinumOffset = that.$specialbox.offset().top;
                            let platinumHeightBuffer = that.$specialboxWrapper.outerHeight();

                            // increase heightBuffer if specialbox is on top
                            if (that.$specialboxWrapper.hasClass('oo-specialbox--posTop')) {
                                platinumHeightBuffer = platinumHeightBuffer * 2;
                            }

                            const offsetSum = platinumOffset - platinumHeightBuffer;

                            setTimeout(() => {
                                $('html, body').animate({ scrollTop: offsetSum }, 800);
                                $(closeBtn).trigger('click');
                                that.$checkboxes.blur();
                            }, 50);
                        });
                    }

                    else {
                        $(button).on('click', function () {
                            $(closeBtn).trigger('click');
                        });
                    }
                });
            }

            else {
                $(layerContainer).find('.pkg-tipp-layer-btncontainer > .ll-main-cols-m').each(function (index, button) {
                    $(button).on('click', function () {
                        $(closeBtn).trigger('click');
                    });
                });
            }
        },

        /**
         * handle checkbox events and call according functions
         */
        cbxEvents: function () {

            const that = this;

            that.$checkCount = 0;

            // cbx in toggle container
            that.$checkboxes.on('click', function () {

                //reset specialbox
                if (that.$specialbox.prop('checked')) {
                    that.$specialbox.prop('checked', false);
                    // reset values
                    that.$stickyFooterMonth.text('0,00 €');
                }

                if ($(this).is(':checked')) {

                    that.$checkCount = that.$checkCount + 1;

                    // trigger layer if 2nd cbx is checked
                    if (that.$checkCount === 2) {
                        that.showLayer('TwoBoxes');
                    }

                    // activate specialbox and uncheck other cbx
                    if (that.$checkCount === 3) {

                        that.showLayer('ThreeBoxes');

                        setTimeout(function () {
                            that.$checkboxes.prop('checked', false);
                            that.$checkCount = 0;
                            that.$specialbox.prop('checked', true);

                            that.calcFooter(that.$specialbox, that.$checkCount);

                        }, 400);
                    }
                }

                else {
                    that.$checkCount = that.$checkCount - 1;
                }

                that.calcFooter(this, that.$checkCount);
            });

            // cbx in specialbox
            that.$specialbox.on('click', function () {
                // uncheck other cbx
                that.$checkboxes.prop('checked', false);
                that.$checkCount = 0;

                that.calcFooter(this, that.$checkCount);
            });
        },

        /**
         * calc prices shown in footer
         *
         * @param {Object} cbx - clicked checkbox
         *
         * @param {Number} checkCount
         */
        calcFooter: function (cbx, checkCount) {

            const that = this;

            // no cbx is checked
            if (checkCount === 0) {

                //check if specialbox is checked
                if (that.$specialbox.prop('checked')) {
                    // set month val
                    that.$stickyFooterMonth.text(that.$specialboxPrice.text());
                }

                else {
                    // reset footer values
                    that.$stickyFooterMonth.text('0,00 €');
                }
            }

            else {
                // get new value from cbx
                const cbxValue = that.convertToNumber($(cbx).parents('.oo-checkbox').next().find('.oo-price').text());

                // get current footer value as number
                let monthVal = that.convertToNumber(that.$stickyFooterMonth.text());

                if ($(cbx).prop('checked')) {
                    // add cbxValue value
                    monthVal = monthVal + cbxValue;
                }

                else {
                    // subtract cbxValue value
                    monthVal = monthVal - cbxValue;
                }

                // convert and inject
                that.$stickyFooterMonth.text(that.convertToString(monthVal));
            }
        },


        /**
         *
         */
        calcFooterPos: function () {

            const that = this;

            if (this.sideNav) {
                this.$stickyFooter.addClass('is-sidenav');
            }

            const callFunc = () => {
                that.calcStickyLogic();
            };

            document.addEventListener('scroll', callFunc, { passive: true });

            //listen to click-event
            this._events.on('FdlCreditCardConfig.calcStickyFooterPos', () => {
                S.Utils.delayed('stickyFooterMobile', 400, () => {
                    this.calcStickyLogic();
                });

            });

            this.calcStickyLogic();
        },

        /**
         *
         */
        calcStickyLogic() {
            const that = this;

            let position = $(document).scrollTop(),
                scrollDirection = '';

            S.Utils.delayed('stickyFooter', 40, () => {

                // get current scroll direction
                const scroll = $(document).scrollTop();

                if (scroll > position) {
                    scrollDirection = 'down';
                }
                else {
                    scrollDirection = 'up';
                }

                // get stickyfooter height
                const footerHeight = that.$stickyFooter.outerHeight();

                if (($(document).scrollTop() + $(window).height()) >= that.$ctx.offset().top) {

                    if (($(document).scrollTop() + $(window).height()) > (that.$ctx.offset().top + that.$ctx.outerHeight())) {
                        that.$stickyFooter.removeClass('is-sticky');
                        // eslint-disable-next-line
                        that.$ctx.css('padding-bottom', '0'); //NOSONAR css attribute
                    }

                    else {

                        if (!that.$stickyFooter.hasClass('is-sticky')) {
                            if (scrollDirection === 'down') {
                                that.$stickyFooter.css('bottom', `-${footerHeight}px`)
                                    .addClass('is-sticky')
                                    .animate({ bottom: '0' }, 500);
                            }

                            else if (scrollDirection === 'up') {
                                that.$stickyFooter.css('bottom', '0')
                                    .addClass('is-sticky');
                            }


                            that.$ctx.css('padding-bottom', `${footerHeight}px`);
                        }
                    }
                }

                else {
                    if (that.$stickyFooter.hasClass('is-sticky')) {
                        that.$stickyFooter.animate({ bottom: `-${footerHeight}px` }, 500, () => {
                            that.$stickyFooter.removeClass('is-sticky');
                        });
                        that.$ctx.css('padding-bottom', `${footerHeight}px`);
                    }
                }

                position = scroll;
            });
        },

        /**
         * convert string to (float) number for further calculation
         *
         * @param {String} price
         */
        convertToNumber: function (price) {

            return parseFloat(price
                // remove dots
                .replace(/\./g, '')
                // replace commas with dots
                .replace(/,/g, '.')
                // remove every char excepts numbers and dots
                .replace(/[^\d\.]/g, '')// eslint-disable-line
            );
        },

        /**
         * convert number to string for text injections
         *
         * @param {Number} value
         */
        convertToString: function (value) {

            return (
                // round number on 2nd decimal-char, convert it to string and add currency-char
                `${(value).toFixed(2)} €`)
                // replace dots with commas
                .replace(/\./g, ',');
        },


        /**
         * show/hide specialarrows if mobile is displayed
         *
         */

        setArrows: function () {

            const that = this;

            if (S.Globals.MediaQuery.mqRightNow !== 'smartphone') {
                that.$boxArrows.show();
            }

            else if (S.Globals.MediaQuery.mqRightNow === 'smartphone') {

                // calc for zero based index (see below)
                const arrowCount = that.$boxArrows.length - 1;

                that.$boxArrows.each(function (index, item) {

                    // arrows pointing down
                    if ($(item).hasClass('oo-special-arrows--posBottom')) {

                        if (index !== arrowCount) {
                            $(item).hide().removeClass('mobileArrow');
                        }
                        else {
                            $(item).addClass('mobileArrow').show();
                        }
                    }

                    // arrows pointing up
                    else if ($(item).hasClass('oo-special-arrows--posTop')) {

                        if (index === 0) {
                            $(item).addClass('mobileArrow').show();
                        }
                        else {
                            $(item).hide().removeClass('mobileArrow');
                        }
                    }
                });
            }
        }

    });
})(jQuery);