(function ( $ )
{
	class DsresultComponent extends HTMLElement {
		constructor() {
			super();
			this.$ctx = null;
			this.$filterMobileTrigger = null;
			this.$tablerows = null;
			this.$mobileOpenClass = 'is-mobile-open';
			this.$filterModuleclass = '.m-ds-filter';
		}

		connectedCallback() {
			setTimeout(() => { // needed to delay the connect callback, else the dom inside is not accessible
				this.$ctx = $(this);
				this.$filterMobileTrigger = this.$ctx.find('.js-trigger');
				this.$tablerows = this.$ctx.find('.js-table-row');
				this.initMobileFilter();
				this.initRowRadios();
				this.resize();
			});
		}

		//custom functions start here!
		initMobileFilter()
		{
			//count active filters -> probably needs to be done in filter.js
			this.$filterMobileTrigger.on('click', () => {
				const filterWrapper = $(`[data-source="${this.$ctx.data('target')}"]`);
				filterWrapper.fadeIn();
				filterWrapper.addClass(this.$mobileOpenClass);
				filterWrapper.find('.js-close').off().on('click', (closeBtn) => {
					$(closeBtn.currentTarget).closest(this.$filterModuleclass).fadeOut();
					$(closeBtn.currentTarget).removeClass(this.$mobileOpenClass);
				});
			});
		}

		initRowRadios()
		{
			if(this.$tablerows.length > 0) {
				this.$tablerows.find('input:checked').closest('tr').addClass('is-active');

				this.$tablerows.on('click', (row)=> {
					this.$tablerows.removeClass('is-active');
					$(row.currentTarget).addClass('is-active');
					$(row.currentTarget).find('input').prop('checked', true).trigger('change');
				});

				this.$tablerows.find('input').on('focus', (row)=> {
					this.$tablerows.removeClass('is-focus');
					$(row.currentTarget).closest('tr').addClass('is-focus');
				});
			}
		}

		resize()
		{
			$(window).on('resize', () =>
			{
				S.Utils.delayed('resize-ds-filter', 200, () =>
				{
					if (S.Utils.Helper.mq('tablet').matches)
					{
						$(this.$filterModuleclass).show().removeClass(this.$mobileOpenClass);
						$(this.$filterModuleclass).find('.js-close').off();
					}
					else
					{
						const filterWrapper = $(`[data-source="${this.$ctx.data('target')}"]`);
						filterWrapper.find('.js-close').off().on('click', (closeBtn) => {
							$(closeBtn.currentTarget).closest(this.$filterModuleclass).fadeOut();
							$(closeBtn.currentTarget).removeClass(this.$mobileOpenClass);
						});
					}
				});
			});
		}
	}

	customElements.define( 'dsresult-component', DsresultComponent);

}( jQuery ));
