(function ( $ )
{
    'use strict';
    /**
     * BasicSocialshare module implementation.
     *
     * @author  <oe.guenay@edelweiss72.de>
     * @namespace T.Module
     * @class BasicSocialshare
     * @extends T.Module
     */
    T.Module.BasicSocialshare = T.createModule( {
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $printBtn: null,

        /** @type {jQuery} */
        $mailBtn: null,

        start: function ( resolve )
        {
            this.$ctx = $( this._ctx );
            this.$printBtn = this.$ctx.find( '.m-btn-print' );
            this.$mailBtn = this.$ctx.find( '.m-btn-email' );

            /**
             * Adds click function for the mail icon
             */
            this.addEvents();

            resolve();
        },

        addEvents: function ()
        {

            // Start print dialog on print icon click
            this.$printBtn.on( 'click', function ()
            {
                S.Lazy.lazyPrintEvent();

                setTimeout( () =>
                {
                    window.print();
                }, 20 );
            } );

            // E-Mail Icon click
            this.$mailBtn.on( 'click', function ()
            {

                //Toggle the box with the id of contentId
                $( '#socialshare_form' ).stop().slideToggle( 'fast' );
            } );
        }
    } );
}( jQuery ));
