(function($) {
	'use strict';
	/**
	 * MyAdacMembershipContent module implementation.
	 *
	 * @author Tobias <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class MyAdacPromotionBox
	 * @extends T.Module
	 */
	T.Module.MyAdacMembershipContent = T.createModule({

        /** @type {jQuery} this.module ID */
        $module: null,

        start(resolve)
		{
			this.$module = $(this._ctx);

			resolve();
		}
	});
}(jQuery));
