(function ($) {
    'use strict';
    /**
     * VesCalulatorShort module implementation.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class VesCalculatorShort
     * @extends T.Module
     */
    T.Module.VesCalculatorShort = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {String} */
        membership: 'ADACMitglied',

        /** @type {String} */
        singleRate: 'true',

        /** @type {String} */
        type: 'AKS',

        /** @type {Number} */
        age: 18,

        /** @type {String} */
        produktKey: '',

        /** @type {String} */
        isInit: true,

        start: function (resolve) {
            this.$ctx = $(this._ctx);

            this.calcProposal = '.js-calculator-proposal';

            // check rate :: single || family
            this.checkRate();

            // check membership :: Kunde || ADACMitglied || ADACPlusMitglied
            this.checkMembership();

            // check type :: 18 || 67
            this.checkAge();

            // calculate
            this.calculate();

            // dummy
            this.dummy();

            this.isInit = false;


            resolve();
        },

        /**
         * DUMMY
         *
         */
        dummy: function () {
            // init proposal btns
            this.$ctx.find(this.calcProposal).off().on('click', function (e) {
                e.preventDefault();
            });
        },

        /**
         * check rate :: single || family
         *
         */
        checkRate: function () {
            const that = this;

            // init change
            this.$ctx.find('input[name="rate"], input[name="rate_not_member"]').on('change', function () {

                if ($(this).val() === 'single') {
                    that.singleRate = 'true';
                } else {
                    that.singleRate = 'false';
                }

                if (that.isInit === false) {
                    // calculate
                    that.calculate();
                }
            });
        },

        /**
         * check membership :: ADACMitglied || Kunde
         *
         */
        checkMembership: function () {
            const that = this,
                inputMembershipChecked = 'input[name="membership"]:checked';

            // init change
            this.$ctx.find('input[name="membership"]').on('click', function () {
                that.membership = that.$ctx.find(inputMembershipChecked).val();

                // check rating if member
                if (that.$ctx.find(inputMembershipChecked).val() === 'ADACMitglied') {
                    if (that.$ctx.find('input[name="rate"]:checked').val() === 'single') {
                        that.singleRate = 'true';
                    } else {
                        that.singleRate = 'false';
                    }
                }

                // check rating if not member
                if (that.$ctx.find(inputMembershipChecked).val() === 'Kunde') {
                    if (that.$ctx.find('input[name="rate_not_member"]:checked').val() === 'single') {
                        that.singleRate = 'true';
                    } else {
                        that.singleRate = 'false';
                    }
                }

                if (that.isInit === false) {
                    // calculate
                    that.calculate();
                }
            });

            // init trigger
            that.$ctx.find(inputMembershipChecked).trigger('click');
        },

        /**
         * check age :: 18 || 67
         *
         */
        checkAge: function () {
            const that = this;

            // init change
            this.$ctx.find('input[name="age"]').on('click', function () {
                that.age = that.$ctx.find('input[name="age"]:checked').val();

                if (that.isInit === false) {
                    // calculate
                    that.calculate();
                }
            });

            // init trigger
            that.$ctx.find('input[name="age"]:checked').trigger('click');
        },

        /**
         * calculate
         *
         */
        calculate: function () {
            const that = this;
            let result = '';

            $.getJSON(that.$ctx.data('json'), function (data) {
                let count = 0; // count result for testing
                const dummyArray = []; // for testing

                $.each(data.AKS, function (index, item) {
                    if (item.Mitglied === that.membership
                        && item.Typ === that.type
                        && parseInt(item.MinimalAlter) <= that.age
                        && parseInt(item.MaximalAlter) >= that.age
                        && item.Einzeltarif === that.singleRate) {
                        count++; // dummy
                        dummyArray.push(item.ProduktKey); // dummy

                        that.produktKey = item.ProduktKey;
                        result = item.Beitrag;
                    }
                });

                let restultsring = '';
                for (let x = 0; x < dummyArray.length; x++) {
                    restultsring += `${dummyArray[x]}, `;
                }

                $('.m-basic-toggle .js-content').append(`<br><strong>Kriterien: </strong>Mitgliedschaft: ${that.membership}, Tarifart: ${that.type}, Alter: ${that.age}, Einzeltarif: ${that.singleRate}`);
                $('.m-basic-toggle .js-content').append(`<br>${count} Ergebnisse(${restultsring}) => Tarif: ${$.number(result, 2, ',')}`);

                // update sticky box
                that.$ctx.find('.mm-amount-value').html($.number(result, 2, ','));
            }).done(() => {
                that.$ctx.find('.mm-error').hide();
                that.$ctx.find(this.calcProposal).prop('disabled', false).removeClass('is-disabled');
                that.$ctx.find('.mm-value-container').show();
            }).fail(() => {
                that.$ctx.find('.mm-error').show();
                that.$ctx.find(this.calcProposal).prop('disabled', true).addClass('is-disabled');
                that.$ctx.find('.mm-value-container').hide();
            });
        }

    });
}(jQuery));