'use strict';
(function ($) {
	/**
	 * InsuranceAboalarm module implementation.
	 *
	 * @author  <marc.radziwill@merkle.com>
	 * @namespace T.Module
	 * @class InsuranceAboalarm
	 * @extends T.Module
	 */
	T.Module.InsuranceAboalarm = T.createModule({
		/** @type {string} */
		_ctaClass: '.js-aboalarm',
		/** @type {jQuery} */
		$ctx: null,
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this._readConfiguration();
			this._addEventListener();
			resolve();
		},
		_readConfiguration: function _readConfiguration() {
			this._config = this.$ctx.data('config') || {};
			this._api = T.Utils.Application.getApi();
		},
		_addEventListener() {
			this.$ctx.find(this._ctaClass).on('click', this._sendRequest.bind(this));
		},
		_sendRequest(e) {
			e.preventDefault();
			const api = T.Utils.Helper.appendURIPath(this._api, this._config.aboAlarmApi);
			const options = {
				type: 'GET',
				url: api,
				data: {
					model: {
						tarif: this._config.tarif,
						token: this._config.token
					},
				},
				xhrFields: {
					withCredentials: true
				}
			};
			T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
		},
		_successCallback: function _successCallback(data) {
			this.$ctx.find('.js-success').removeClass('h-hidden');
			this.$ctx.find(this._ctaClass).addClass('h-hidden');
			this.$ctx.find('.js-aboalarm-voucher').text(data.Data.VoucherCode);
		},
		_errorCallback: function _errorCallback() {
			this.$ctx.find('.js-error').removeClass('h-hidden');
			this.$ctx.find(this._ctaClass).addClass('h-hidden');
		},
	});
}(jQuery));