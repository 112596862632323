(function($) {
	'use strict';
	/**
	 * BasicStickyServiceContact module implementation.
	 *
	 * @author m.haeusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicStickyServiceContact
	 * @extends T.Module
	 */
	T.Module.BasicHelp = T.createModule({

		/** @type {number} */
		resizeTimeout: 0,

		start: function(resolve) {
			this.$ctx = $(this._ctx);
			this.$trigger = this.$ctx.find('.mm-trigger');
			this.$detailsContent = this.$ctx.find('.mm-content-layer');
			this.$triggerClose = this.$detailsContent.find('.mm-close');
			this.$tabBtn = this.$ctx.find('.mm-tab');
			this.$contentWrapper = this.$ctx.find('.mm-content-wrapper');
			this.$contentWrapperClose = this.$ctx.find('.mm-content-wrapper .mm-close-content');
			this.scrollPaneWasInit = false;

			this.initTrigger();
			this.initCheckFormFooterSticky();
			this.initScrollPane();

			resolve();
		},

		initCheckFormFooterSticky()
		{
			if($('.m-basic-form-footer-sticky').length)
			{
				this.$ctx.addClass('has-basic-form-footer-sticky');
			}
		},

		initTrigger()
		{
			const isVisible = 'is-visible',
				eventClickHelp = 'click.help';

			this.$trigger.on('click', () => {
				this.$ctx.addClass('is-open');
				this.$detailsContent.addClass(isVisible);
				$('body').on(eventClickHelp, (el) => {
					if($(el.target).closest('.m-basic-help').length < 1) {
						this.$triggerClose.trigger('click');
						$('body').off(eventClickHelp);
					}
				});
			});

			this.$triggerClose.on('click',() => {
				$('body').off(eventClickHelp);
				this.$detailsContent.removeClass(isVisible);
				this.$contentWrapper.find('> div').removeClass(isVisible);
				this.$ctx.removeClass('is-open');
			});
		},

		/** init scrollpane  */
		initScrollPane: function(){
			if (this.$ctx.closest('.m-basic-sticky-service-contact-restyle').length)
			{
				const scrollpaneContainer = this.$ctx.find('.mm-content');
				scrollpaneContainer.find('.mm-scrollpane').jScrollPane(
					{
						showArrows: false,
						autoReinitialise: true,
						height: 600
					});
			}
		},

	});
}(jQuery));
