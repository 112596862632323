/* eslint-disable sonarjs/cognitive-complexity */
"use strict";
(function () { //NOSONAR

	T.Utils = T.Utils || {};
	T.Utils.Perf = {
		_marks: [],
		_measures: [],
		_navigationStart: Date.now ? Date.now() : +new Date(),

		_now: function _now() {
			const hasPerformanceNow = window.performance && typeof window.performance.now === 'function';
			const hasDateNow = typeof Date.now === 'function';

			if (hasPerformanceNow) {
				return window.performance.now();
			} else if (hasDateNow) {
				return Date.now() - this._navigationStart;
			} else {
				return +new Date() - this._navigationStart;
			}
		},

		getMark: function getMark(name) {
			return this.getM(name, this.getMarks());
		},

		getM: function getM(name, marks) {
			if (marks) {
				const markToCheck = marks.filter(e => e.name === name);
				if (markToCheck && markToCheck.length) {
					return markToCheck[0];
				}
				if (marks.length && marks[name]) {
					for (let n = marks.length - 1; n >= 0; n--) {
						const mark = marks[name];

						if (mark && name === mark.name) {
							return mark;
						}
					}

				}
			}
			return undefined;
		},

		mark: function mark(name) {
			if (window.performance) {
				if (window.performance.mark) {
					return window.performance.mark(name);
				}
				if (window.performance.webkitMark) {
					return window.performance.webkitMark(name);
				}
			}
			this._marks.push({
				name: name,
				entryType: "mark",
				startTime: this._now(),
				duration: 0,
			});
			return undefined;
		},

		getMarks: function getMarks() {
			if (window.performance) {
				if (window.performance.getEntriesByType) {
					return window.performance.getEntriesByType("mark");
				}

				if (window.performance.webkitGetEntriesByType) {
					return window.performance.webkitGetEntriesByType("mark");
				}
			}

			return this._marks;
		},
		setMeasure: function setMeasure(name, start, end) {
			if (window.performance) {
				if (window.performance.measure) {
					return window.performance.measure(name, start, end);
				}

				if (window.performance.webkitMeasure) {
					return window.performance.webkitMeasure(name, start, end);
				}
			}

			this._measures.push({
				name: name,
				entryType: "measure",
				start: start,
				end: end,
			});
			return undefined;
		},
		getMeasures: function getMeasures() {
			if (window.performance) {
				if (window.performance.getEntriesByType) {
					return window.performance.getEntriesByType("measure");
				}

				if (window.performance.webkitGetEntriesByType) {
					return window.performance.webkitGetEntriesByType("measure");
				}
			}

			return this._measures;
		},
		clearCustomMarks: function clearCustomMarks(name) {
			if (window.performance && window.performance.clearMarks) {
				return window.performance.clearMarks(name);
			}

			if (name) {
				for (let idx = this._marks.length - 1; idx >= 0; idx--) {
					name === this._marks[idx].name && this._marks.splice(idx, 1);
				}
			} else {
				this._marks = [];
			}
			return undefined;
		},
		clearCustomMeasures: function clearCustomMeasures(name) {
			if (window.performance && window.performance.clearMeasures) {
				return window.performance.clearMeasures(name);
			}

			if (name) {
				for (let idx = this._measures.length - 1; idx >= 0; idx--) {
					name === this._measures[idx].name &&
						this._measures.splice(idx, 1);
				}
			} else {
				this._measures = [];
			}
			return undefined;
		},
	};
})();
