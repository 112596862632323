'use strict';
(function ($) {
	/**
	 * Smart Connect Compatibility Check module implementation.
	 *
	 * @author Krystian Sadowski <krystian.sadowski@mtc.berlin>
	 * @namespace T.Module
	 * @class SmartConnectCompatibilityCheck
	 * @extends T.Module
	 */
	T.Module.SmartConnectCompatibilityCheck = T.createModule({
		_brandSelect: "#select_model",
		_modelSeriesSelect: "#select_modelseries",
		_engineSelect: "#select_motor",
		_yearSelect: "#select_year",
		_smartConnectVehicleKey: "smartConnectVehicle",

		start: function start(resolve) {
			this._initApplication();
			this._readConfiguration();
			this._registerListeners();
			this._setupValidation();
			this._requestBrands();
			resolve();
		},
		_initApplication: function _initApplication() {
			this.$ctx = $(this._ctx);
			this.$compatibiliyForm = this.$ctx.find('form');
			this.$compatibleHeader = this.$ctx.find('.js-compatible-without-cable');
			this.$comaptibleWithCableHeader = this.$ctx.find('.js-compatible-with-cable');
			this.$compatibleSectionWrapper = this.$ctx.find('.js-vehicle-is-compatible');
			this.$notCompatibleSectionWrapper = this.$ctx.find('.js-vehicle-is-not-compatible');
			this.$unknownCompatibleSectionWrapper = this.$ctx.find('.js-vehicle-is-unknown');
			this.$brandSelect = this.$ctx.find(this._brandSelect);
			this.$modelSeriesSelect = this.$ctx.find(this._modelSeriesSelect);
			this.$engineSelect = this.$ctx.find(this._engineSelect);
			this.$yearSelect = this.$ctx.find(this._yearSelect);
			this.$resetBtn = this.$ctx.find('.js-vehicle-reset');
			T.Utils.Application.init();
		},
		_readConfiguration: function _readConfiguration() {
			this._apiM = T.Utils.Application.getApiM();
			this._config = typeof this.$ctx.data('config') === 'object' ? this.$ctx.data('config') : JSON.parse(this.$ctx.data('config'));
			this.vehicleMappingApi = this._config.apiMapping;
			this.apiCompability = this._config.apiCompability;
			this.apiFitment = this._config.apiFitment;
		},
		_registerListeners: function _registerListeners() {
			this.$ctx.find('.js-form').submit(this._submitSerach.bind(this));
			this.$ctx.find('.js-submit').on('click', this._submitSerach.bind(this));
			this.$ctx.find('.js-join-btn').on('click', this._persistVehicleData.bind(this));

			this.$brandSelect.on('change', this._requestModelSeries.bind(this));
			this.$modelSeriesSelect.on('change', this._requestEngine.bind(this));
			this.$engineSelect.on('change', this._requestYear.bind(this));
			this.$yearSelect.on('change', this._onYearChange.bind(this));
			this.$resetBtn.on('click', this._resetVehicleData.bind(this));
		},
		_setupValidation: function _setupValidation() {
			const validationConfig = {
				rules: {
					select_model: {
						requiredsel: true
					},
					select_modelseries: {
						requiredsel: true
					},
					select_motor: {
						requiredsel: true
					},
					select_year: {
						requiredsel: true
					}
				},
				messages: this._config.validationMessages || {}
			};
			T.Utils.Validation.init(this.$ctx, [this.$compatibiliyForm], validationConfig);
			$.validator.addMethod('requiredsel', function (value) {
				return (value !== "0");
			});
		},
		_createVehicleDataRequestOptions: function _createVehicleDataRequestOptions(urlParams) {
			let url = T.Utils.Helper.appendURIPath(this._apiM, this.vehicleMappingApi);
			if (urlParams) {
				url = T.Utils.Helper.updateUrlParameter(urlParams, url);
			}

			return {
				headers: {
					'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey(),
				},
				dataType: "json",
				url: url
			};
		},
		_requestBrands: function _requestBrands() {
			T.Utils.View.startLoader();
			this._showError(false);
			this._resetCompatibilitySection();
			T.Utils.Ajax.fragment(this._createVehicleDataRequestOptions(), this._handleBrandApiSuccessResponse.bind(this), this._handleVehicleDataError.bind(this));
		},
		_requestModelSeries: function _requestModelSeries() {
			T.Utils.View.startLoader();
			this._showError(false);
			this._resetCompatibilitySection();
			const urlParams = { make: this._getSelectedValue(this._brandSelect) };
			T.Utils.Ajax.fragment(this._createVehicleDataRequestOptions(urlParams), this._handleModelSeriesApiSuccessResponse.bind(this), this._handleVehicleDataError.bind(this));
		},
		_requestEngine: function _requestEngine() {
			T.Utils.View.startLoader();
			this._showError(false);
			this._resetCompatibilitySection();

			const urlParams = {
				make: this._getSelectedValue(this._brandSelect),
				series: this._getSelectedValue(this._modelSeriesSelect)
			};

			T.Utils.Ajax.fragment(this._createVehicleDataRequestOptions(urlParams), this._handleEngineApiSuccessResponse.bind(this), this._handleVehicleDataError.bind(this));
		},
		_requestYear: function _requestYear() {
			T.Utils.View.startLoader();
			this._showError(false);
			this._resetCompatibilitySection();

			const urlParams = {
				make: this._getSelectedValue(this._brandSelect),
				series: this._getSelectedValue(this._modelSeriesSelect),
				model: this._getSelectedValue(this._engineSelect)
			};

			T.Utils.Ajax.fragment(this._createVehicleDataRequestOptions(urlParams), this._handleYearApiSuccessResponse.bind(this), this._handleVehicleDataError.bind(this));
		},
		_onYearChange: function _onYearChange() {
			this._resetCompatibilitySection();
		},
		_resetVehicleData: function _resetVehicleData(ev) {
			ev.preventDefault();
			T.Utils.View.startLoader();

			this.$brandSelect.val('0');
			this.$brandSelect.removeClass('is-error');
			this.$brandSelect.closest('.js-row.is-check').removeClass('is-error is-check');

			this._clearDropdown(this._modelSeriesSelect);
			this._clearDropdown(this._engineSelect);
			this._clearDropdown(this._yearSelect);

			this._resetCompatibilitySection();
			T.Utils.View.stopLoader();
		},
		_resetCompatibilitySection: function _resetCompatibilitySection() {
			this.$resetBtn.toggleClass('h-hidden', true);

			this.$notCompatibleSectionWrapper.toggleClass('h-hidden', true);
			this.$compatibleSectionWrapper.toggleClass('h-hidden', true);
			this.$unknownCompatibleSectionWrapper.toggleClass('h-hidden', true);
			this.$compatibleHeader.toggleClass('h-hidden', true);
			this.$comaptibleWithCableHeader.toggleClass('h-hidden', true);
		},
		_handleBrandApiSuccessResponse: function _handleBrandApiSuccessResponse(data) {
			if (data && data.makeList) {
				this._clearDropdown(this._modelSeriesSelect);
				this._clearDropdown(this._engineSelect);
				this._clearDropdown(this._yearSelect);
				this._fillDropdown(this._brandSelect, data.makeList);
			}
			T.Utils.View.stopLoader();
		},
		_handleModelSeriesApiSuccessResponse: function _handleModelSeriesApiSuccessResponse(data) {
			if (data && data.seriesList) {
				this._clearDropdown(this._modelSeriesSelect);
				this._clearDropdown(this._engineSelect);
				this._clearDropdown(this._yearSelect);

				this._fillDropdown(this._modelSeriesSelect, data.seriesList);
			}
			T.Utils.View.stopLoader();
		},
		_handleEngineApiSuccessResponse: function _handleEngineApiSuccessResponse(data) {
			if (data && data.modelList) {
				this._clearDropdown(this._engineSelect);
				this._clearDropdown(this._yearSelect);
				this._fillDropdown(this._engineSelect, data.modelList);
			}
			T.Utils.View.stopLoader();
		},
		_handleYearApiSuccessResponse: function _handleYearApiSuccessResponse(data) {
			if (data && data.buildYearList) {
				this._clearDropdown(this._yearSelect);
				this._fillDropdown(this._yearSelect, data.buildYearList);
			}
			T.Utils.View.stopLoader();
		},
		_clearDropdown: function _clearDropdown(selector) {
			this.$ctx.find(`${selector} option[value!="0"]`).remove();
			const $select = this.$ctx.find(selector);
			$select.prop("disabled", true);
			$select.removeClass('is-error');
			$select.closest('.js-row.is-check').removeClass('is-error is-check');
		},
		_fillDropdown: function _fillDropdown(selector, items) {
			const $elm = this.$ctx.find(selector);

			items.forEach((item) => {
				$elm.append($("<option/>").attr('value', item).text(item));
			});

			$elm.prop("disabled", false);
		},
		_getSelectedValue: function _getSelectedValue(selector) {
			return this.$ctx.find(selector).val();
		},
		_submitSerach: function _submitSerach(ev) {
			ev.preventDefault();
			if (this.$compatibiliyForm.valid() && this._checkAllFilterEnabled()) {
				this._resetCompatibilitySection();
				this._requestCompatibility();
			}
		},

		_persistVehicleData: function _persistVehicleData() {
			const vehicleData = this._getVehicleSelection();
			sessionStorage.setItem(this._smartConnectVehicleKey, JSON.stringify(vehicleData));
		},
		// Addional check because of the validator is only checking enabled fields
		_checkAllFilterEnabled: function _checkAllFilterEnabled() {
			return (this.$brandSelect.is(':enabled') &&
				this.$modelSeriesSelect.is(':enabled') &&
				this.$engineSelect.is(':enabled') &&
				this.$yearSelect.is(':enabled'));
		},
		_getVehicleSelection: function _getVehicleSelection() {
			const make = this._getSelectedValue(this._brandSelect);
			const series = this._getSelectedValue(this._modelSeriesSelect);
			const engine = this._getSelectedValue(this._engineSelect);
			const year = this._getSelectedValue(this._yearSelect);
			if (make && series && engine && year) {
				return {
					make: make,
					series: series,
					model: engine,
					year: year
				};
			}
			return undefined;
		},
		_requestCompatibility: function _requestCompatibility() {
			this._showError(false);
			const urlParams = this._getVehicleSelection();
			if (urlParams) {
				T.Utils.View.startLoader();

				let url = T.Utils.Helper.appendURIPath(this._apiM, this.apiCompability);

				url = `${url}?make=${this._getSelectedValue(this._brandSelect)}&series=${this._getSelectedValue(this._modelSeriesSelect)}&model=${this._getSelectedValue(this._engineSelect)}&buildYear=${this._getSelectedValue(this._yearSelect)}`;

				const options = {
					headers: {
						'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey(),
					},
					dataType: "json",
					url: url
				};

				T.Utils.Ajax.fragment(options, this._handleCompatibilityApiSuccess.bind(this), this._handleVehicleDataError.bind(this));
			}
		},

		_handleCompatibilityApiSuccess: function _handleCompatibilityApiSuccess(data) {
			if (data && data.results[0]) {
				if (data.results[0].compatibility && data.results[0].compatibility.toLowerCase() === 'compatible') {
					this._requestFitment();
				}
				else if (data.results[0].compatibility && data.results[0].compatibility.toLowerCase() === 'not_compatible') {
					this._handleNonCompatibleVehicle();
				}
				else {
					this.handleUnknownVehicle();
				}
			}
		},
		_handleNonCompatibleVehicle: function _handleNonCompatibleVehicle() {
			this.$notCompatibleSectionWrapper.toggleClass('h-hidden', false);
			this.$resetBtn.toggleClass('h-hidden', false);

			if (sessionStorage.getItem(this._smartConnectVehicleKey)) {
				sessionStorage.removeItem(this._smartConnectVehicleKey);
			}
			T.Utils.View.stopLoader();
		},
		handleUnknownVehicle: function handleUnknownVehicle() {
			this.$unknownCompatibleSectionWrapper.toggleClass('h-hidden', false);
			this.$resetBtn.toggleClass('h-hidden', false);

			if (sessionStorage.getItem(this._smartConnectVehicleKey)) {
				sessionStorage.removeItem(this._smartConnectVehicleKey);
			}
			T.Utils.View.stopLoader();
		},
		_requestFitment: function _requestFitment() {
			this._showError(false);
			const urlParams = this._getVehicleSelection();
			if (urlParams) {

				let url = T.Utils.Helper.appendURIPath(this._apiM, this.apiFitment);
				url = `${url}?make=${this._getSelectedValue(this._brandSelect)}&series=${this._getSelectedValue(this._modelSeriesSelect)}&model=${this._getSelectedValue(this._engineSelect)}&buildYear=${this._getSelectedValue(this._yearSelect)}`;

				const options = {
					headers: {
						'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey(),
					},
					dataType: "json",
					url: url
				};

				T.Utils.Ajax.fragment(options, this._handleFitmentApiSuccess.bind(this), this._handleVehicleDataError.bind(this));
			}
		},
		_handleFitmentApiSuccess: function _handleFitmentApiSuccess(data) {
			if (data && data.results[0]) {

				this._displayCompatibilityHeader(data.results[0].installationLevel.toLowerCase());
				this.$compatibleSectionWrapper.toggleClass('h-hidden', false);
				T.Utils.View.stopLoader();
			}
		},
		_displayCompatibilityHeader: function _displayCompatibilityHeader(installationLevel) {
			if (installationLevel === 'lvl1' || installationLevel === 'lvl2') {
				this.$compatibleHeader.toggleClass('h-hidden', false);
				this.$comaptibleWithCableHeader.toggleClass('h-hidden', true);
			}

			if (installationLevel === 'lvl3' || installationLevel === 'lvl4') {
				this.$compatibleHeader.toggleClass('h-hidden', true);
				this.$comaptibleWithCableHeader.toggleClass('h-hidden', false);
			}
		},

		_handleVehicleDataError: function _handleVehicleDataError(error) {
			T.Utils.View.stopLoader();
			console.error(error);
			this._showError(true);
		},
		_showError: function _showError(show) {
			this.$ctx.find(".js-search-error").toggleClass('h-hidden', !show);
		}
	});
}(jQuery));