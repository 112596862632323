(function($) {
    'use strict';

    /**
     * VesRsSticky module implementation.
     *
     * @author Niclas Bork <n.bork@edelweiss72.de>
     * @namespace T.Module
     * @class VesRsStickyBar
     * @extends T.Module
     */
    T.Module.VesRsStickyBar = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $bodyElem: null,

        /** @type {jQuery} */
        $cookieLayer: null,

        /** @type {jQuery} */
        $menuBtns: null,

        /** @type {jQuery} */
        $closeLayerBtns: null,

        /** @type {jQuery} */
        $contentLayerWraps: null,

        /** @type {jQuery} */
        $contentLayers: null,

        /** @type {number} */
        barHeight: 0,

        /** @type {number} */
        stickyElemHeight: 0,

        /** @type {boolean} */
        layerIsOpen: false,

        /** @type {boolean} */
        isTouch: false,

        start: function(resolve) {

            this.$ctx = $(this._ctx);

            this.$menuBtns = this.$ctx.find('.mm-sticky-module .mm-nav-btn');
            this.$contentLayerWraps = this.$ctx.find('.mm-layer');
            this.$contentLayers = this.$ctx.find('.mm-layer > .mm-layer-wrap');
            this.$closeLayerBtns = this.$contentLayerWraps.find('.mm-close-icon');
            this.$bodyElem = $('body').eq(0);

            this.$cookieLayer = $('header.l-header').find('.m-basic-cookie').eq(0);

            this.barHeight = this.$ctx.innerHeight();

            this.isNoScroll = 'is-noscroll';

            // collect infos about devices to change height calc
            if ( $('body > .l-outer').eq(0).hasClass('is-touch') ) {
                this.isTouch = true;
            }

            this.addLayerEvents();
            this.initSticky();
            this.calcDimensions('init');
            this.resize();

            resolve();
        },

        /**
         * adds open/close events to btns and toggles scroll ability
         */
        addLayerEvents: function () {
            const that = this;
            let lastScrollY,
                cookieHidden;

            // open layers
            this.$menuBtns.on('click.openLayer', (e) =>
			{
                const clickedIndex = $(e.currentTarget).index();

                // open content layer / hide all others
                that.$contentLayerWraps.each( (index, item) => {

                    if (clickedIndex === index) {
                        $(item).addClass('is-open');
                        that.layerIsOpen = true;
                    }

                    else {
                        $(item).removeClass('is-open');
                    }
                });

                // remove highlight from each btn, except from the target
                that.$menuBtns.removeClass('is-active');
				$(e.currentTarget).addClass('is-active');

                // disable scrolling in mobile while layer is open
                if (!S.Utils.Helper.mq('tablet').matches) {

                    // check if cookie is still there, hide it if true
                    if ( that.$cookieLayer.hasClass('is-sliding-down') && !cookieHidden ) {
                        that.$cookieLayer.addClass('is-hidden');
                        cookieHidden = true;
                    }

                    // if not already done (2nd Btn-Click), store current scroll pos
                    if ( !that.$bodyElem.hasClass(this.isNoScroll) ) {
                        lastScrollY = $(document).scrollTop();
                        that.$bodyElem.addClass(this.isNoScroll);
                    }
                }

                that.calcDimensions();

            });

            // close layers
            this.$closeLayerBtns.on('click.closeLayer', (e) => {
                e.preventDefault();
                this.$contentLayerWraps.removeClass('is-open').css('height','');
                this.$menuBtns.removeClass('is-active');
                that.layerIsOpen = false;

                // enable scrolling in mobile when layer is closed
                if (!S.Utils.Helper.mq('tablet').matches) {

                    that.$bodyElem.removeClass(this.isNoScroll);

                    // scroll back to last pos and reset
                    $(document).scrollTop( lastScrollY);
                    lastScrollY = 0;

                    if (cookieHidden) {
                        // short delay until header is unpinned
                        S.Utils.delayed('vesrssticky.waitForUnpin', 200, () => {
                            that.$cookieLayer.removeClass('is-hidden');
                            cookieHidden = false;
                        });
                    }
                }
            });
        },

        /**
         * init sticky
         *
         * listens to the scroll event and adds classes to handle states where sticky footer is displayed as well
         *
         */
        initSticky: function () {

            const mainCenter = '.ll-main-center',
				mainCenterVesrsgap = 'll-main-center--vesrsgap';

			$(mainCenter).last().addClass(mainCenterVesrsgap);

            const callStickyLogic = () => {
                if(this.$ctx.offset().top + this.$ctx.height() >= $('.l-footer').offset().top - 10) {
                    this.$ctx.addClass('is-stuck-bottom');
                    this.$ctx.removeClass('is-stuck-unbottom');
					$(mainCenter).last().removeClass(mainCenterVesrsgap);
                }

                if($(document).scrollTop() + window.innerHeight < $('.l-footer').offset().top) {
                    this.$ctx.addClass('is-stuck-unbottom');
                    this.$ctx.removeClass('is-stuck-bottom');
					$(mainCenter).last().addClass(mainCenterVesrsgap);
                }
            };

            document.addEventListener('scroll',  callStickyLogic,  { passive: true });

        },

        /**
         * for mobile: calc max layer height to respect sticky-header and cookie layer
         * for tablet+: reset height to (css-)default
         *
         * @param mod - init / update
         */
        calcDimensions: function (mod) {

            const that = this;

            // update elem height
            that.barHeight = that.$ctx.innerHeight();

            // add offset to each layer-wrapper
            that.$contentLayerWraps.css('bottom', this.barHeight);

            // prevent calc on init bc header is not finished with anim (slide-down) => we dont need that anyway onInit
            if (!S.Utils.Helper.mq('tablet').matches && mod !== 'init') {

                if ( !that.$bodyElem.hasClass(this.isNoScroll) && that.layerIsOpen ) {
                    that.$bodyElem.addClass(this.isNoScroll);
                }

                const headerHeight = $('header.l-header > .ll-header-center').eq(0).outerHeight();
                that.stickyElemHeight = headerHeight + that.barHeight;

                if (that.isTouch) {
                    // calc browser url-bar height by comparing bodyHeight (100vh s. above) with windowHeight
                    that.stickyElemHeight = that.$bodyElem.innerHeight() - $(window).innerHeight() + that.barHeight;
                }

                that.$contentLayerWraps.css({'height': `calc(100vh - ${  that.stickyElemHeight  }px)`});
            }

            else {
                // reset height value
                that.$contentLayerWraps.css({'height': ''});
                that.$bodyElem.removeClass(this.isNoScroll);
            }
        },

        /**
         * recalculate heights on resize
         */
        resize: function () {
            $(window).on('resize', () => {
                S.Utils.delayed('vesrssticky', 40, () => {
                    this.calcDimensions('update');
                });
            });
        },

    });
}(jQuery));
