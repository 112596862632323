'use strict';
(function ($) {
	/**
	 * FormFileUpload module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>
	 * @namespace T.Module
	 * @class FormFileUpload
	 * @extends T.Module
	 */
	T.Module.FormFileUpload = T.createModule({
		loaderId: 'FormFileUpload',
		uploadStartTime: undefined,

		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this._readConfig();
			this._addEventListeners();
			this._getTokenIfNotLoggedIn();
			if (this._config.required) {
				const emitter = new T.EventEmitter(this._sandbox);
				emitter.on('t.sync', () => {
					$(document).ready(() => {
						this._setupValidation();
					});
				});
			}
			resolve();
		},
		_addEventListeners: function _addEventListeners() {
			this._events.on("BasicUpload.onFileSelected", () => {
				T.Utils.View.startLoader(this.loaderId, 60000);
				if (!this._processId) {
					this._getProcessId();
				}
				else {
					this._uploadFiles();
				}
			});

			this._events.on("BasicUpload.onFileDeleted", () => {
				this._updateFileIds();
				if (this._config.required) {
					this._setupValidation();
					this.$ctx.closest("form").find('[name*="Upload"]').trigger('focusout');
				}
			});
		},
		_setupValidation: function _setupValidation() {
			const validationOptions = {
				rules: {
					Uploads: {
						formsupload: this._config.required,
					},
				},
				messages: this._config.validationMessages || {}
			};
			const allItems = this.$ctx.find("input");

			for (let index = 0; index < allItems.length; index++) {
				const item = $(allItems[index]);
				const validationName = item.data("validation");
				if (!validationName) {
					continue;
				}
				if (validationOptions.rules && typeof validationOptions.rules[validationName] !== 'undefined') {
					const nameItems = this.$ctx.find(`[data-validation="${validationName}"]`);
					validationOptions.rules[validationName].messages = validationOptions.messages[validationName];
					for (let subIndex = 0; subIndex < nameItems.length; subIndex++) {
						const nameItem = $(nameItems[subIndex]);
						if ($.data(nameItem[0].form, "validator")) {
							nameItem.rules('add', validationOptions.rules[validationName]);
						}
					}
				}
			}
		},
		_checkFileExtension: function _checkFileExtension(file) {
			const allowedExtensions = this._config.allowedExtensions.split(",").map((ext) => {
				return ext.toLowerCase().trim();
			});
			let extension = file.File.name.split('.').pop();

			if (!extension) {
				return false;
			}

			extension = extension.toLowerCase();
			return allowedExtensions.indexOf(extension) > -1;
		},
		_checkFile: function _checkFile(file) {
			if (!this._checkFileExtension(file)) {
				this._addErrorMessages([this._config.errorFileFormat]);
				return false;
			}

			if (this._getFileCount() >= this._config.maxFileCount) {
				this._addErrorMessages([this._config.errorMaxFileCount]);
				return false;
			}

			const fileSizeInKB = file.File.size / 1024;
			if (fileSizeInKB > 4096) {
				this._addErrorMessages([this._config.errorMaxFileSize]);
				return false;
			}
			return true;
		},
		_errorApiProcessIdNotAvailableCallback: function __errorApiProcessIdNotAvailableCallback() {
			this._addErrorMessages([this._config.errorTokenApil]);
		},
		_errorApiTokenNotAvailableCallback: function _errorApiTokenNotAvailableCallback() {
			this._addErrorMessages([this._config.errorTokenApil]);
		},
		_errorFileUploadApilNotAvailableCallback: function _errorFileUploadApilNotAvailableCallback(file, data) {
			const messages = [];
			T.Utils.Logger.log(`Upload Error after ${moment().diff(this.uploadStartTime)}`, data.Messages);

			this._removeLastSelectedFile(file.Element);
			messages.push(this._config.errorApil);
			if (data && data.Messages && data.Messages.length > 0) {
				$.each(data.Messages, function (index, value) {
					messages.push(value);
				});
			}
			this._addErrorMessages(messages);
			T.Utils.View.stopLoader(this.loaderId);
		},
		_fileUpload: function _fileUpload(fileObject, data) {
			let apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiUrl);
			apiUrl = T.Utils.Helper.updateUrlParameter({ purpose: this._purpose, processId: this._processId }, apiUrl);
			const options = {
				type: "POST",
				timeout: 60000,
				data: data,
				url: apiUrl,
				cache: false,
				contentType: false,
				processData: false
			};

			this.uploadStartTime = moment();
			T.Utils.Ajax.fragment(options,
				this._handleFileUploadSuccess.bind(this, fileObject),
				this._errorFileUploadApilNotAvailableCallback.bind(this, fileObject));
		},
		_fileUploadByToken: function _fileUploadByToken(fileObject, data) {
			let apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiUrlUploadByToken);
			apiUrl = T.Utils.Helper.updateUrlParameter({ token: this._token }, apiUrl);
			const options = {
				type: "POST",
				timeout: 60000,
				data: data,
				url: apiUrl,
				cache: false,
				contentType: false,
				processData: false
			};

			this.uploadStartTime = moment();
			T.Utils.Ajax.fragment(options,
				this._handleFileUploadSuccess.bind(this, fileObject),
				this._errorFileUploadApilNotAvailableCallback.bind(this, fileObject));
		},
		_getFileCount: function _getFileCount() {
			return this.$ctx.find(".js-file-stored").length;
		},
		_getProcessId: function _getProcessId() {
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiUrlProcessId);
			const options = {
				type: "GET",
				data: { "FormId": this._config.formId },
				url: apiUrl
			};
			T.Utils.Ajax.fragment(options,
				this._handleGetProcessIdSuccess.bind(this),
				this._errorApiProcessIdNotAvailableCallback.bind(this));

		},
		_getTokenIfNotLoggedIn: function _getTokenIfNotLoggedIn() {
			T.Utils.Auth.getResolvedIdentity(async () => {
				const isLoggedIn = await T.Utils.Auth.isLoggedIn();
				if (!isLoggedIn) {
					const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiUrlToken);
					const options = {
						type: "POST",
						data: { "FormId": this._config.formId },
						url: apiUrl,
						contentType: "application/x-www-form-urlencoded;charset=utf-8"
					};

					T.Utils.Ajax.fragment(options,
						this._handleGetTokenSuccess.bind(this),
						this._errorApiTokenNotAvailableCallback.bind(this));
				}
			});

		},
		_handleFileUploadSuccess: function _handleFileUploadSuccess(file, data) {
			T.Utils.Logger.log(`Upload Success after ${moment().diff(this.uploadStartTime)}`);

			file.Element.addClass("js-file-stored");
			file.Element.data("file-id", data.Data);
			this._updateFileIds();
			T.Utils.View.stopLoader(this.loaderId);
			if (this._config.required) {
				this.$ctx.find('[name*="Upload"]').trigger('focusout');
			}
		},
		_handleGetProcessIdSuccess: function _handleGetProcessIdSuccess(data) {
			if (data && data.Success && data.Data) {
				this._processId = data.Data;
				if (this.$processId) {
					this.$processId.val(data.Data);
				}
				this._uploadFiles();
			}
		},
		_handleGetTokenSuccess: function _handleGetTokenSuccess(data) {
			const $token = this.$ctx.find(".js-sc-file-upload-token");
			if ($token && data.Data && data.Data.Token) {
				this._token = data.Data.Token;
				$token.val(this._token);
			}

			if (this.$processId && data.Data && data.Data.ProcessId) {
				this._processId = data.Data.ProcessId;
				this.$processId.val(this._processId);
			}
		},
		_readConfig: function _readConfig() {
			this._config = this.$ctx.data("config");
			this._config.required = this._config.required === "true" ? true : false;

			if (!this._config.purpose && !this._config.processId) {
				const $form = this.$ctx.closest(".js-sc-form");
				if ($form) {
					this._purpose = $form.data("sc-field-name");
					this.$processId = $form.find(".js-sc-process-id");
					if (this.$processId) {
						this._processId = this.$processId.val();
					}
				}
			}

			// If purpose and processId have been set over the config, overwrite the values before so that the component
			// can be used outside of Sitecore Forms
			if (this._config.purpose) {
				this._purpose = this._config.purpose;
			}
			if (this._config.processId) {
				this._processId = this._config.processId;
			}
		},
		_removeLastSelectedFile: function _removeLastSelectedFile($file) {
			const $basicUpload = $file.closest(".is-file-only");
			if ($basicUpload) {
				$basicUpload.remove();
			}
		},
		_showError: function _showError(visible) {
			const $elm = this.$ctx.find(".js-upload-error");
			$elm.toggleClass("h-hidden", !visible);
		},
		_addErrorMessages: function _addErrorMessages(messages) {
			const $div = $("<div/>");
			$.each(messages, function (index, value) {
				$div.append($("<p/>").text(value));
			});

			const $elm = this.$ctx.find(".js-upload-error .js-upload-error-content");
			$elm.empty();
			$elm.append($div);
			this._showError(true);
		},
		_updateFileIds: function _updateFileIds() {
			const fileElements = this.$ctx.find(".js-file-stored");
			let fileIds = "";
			fileElements.each(function () {
				if (fileIds.length) {
					fileIds += ",";
				}
				fileIds += $(this).data("file-id");
			});

			this.$ctx.find('input[data-FormFileUpload="1"]').val(fileIds);
		},
		_uploadFiles: function _uploadFiles() {
			this._showError(false);
			const elements = $(this.$ctx.find(".is-file-only .js-input"));
			const that = this;
			$.each(elements, function (index, value) {
				const $element = $(value);

				if ($element.hasClass("js-file-stored")) {
					return;
				}
				const file = $element.prop('files')[0];
				const fileObject = {
					Element: $element,
					File: file
				};
				if (file) {
					if (that._checkFile(fileObject)) {
						const data = new FormData();
						data.append("File", file);

						T.Utils.Auth.getResolvedIdentity(() => {
							if (!T.Utils.Auth.isLoggedIn()) {
								that._fileUploadByToken(fileObject, data);
							}
							else {
								that._fileUpload(fileObject, data);
							}
						});
					}
					else {
						that._removeLastSelectedFile($element);
						T.Utils.View.stopLoader(this.loaderId);
					}
				}
			});
		}
	});
}(jQuery));