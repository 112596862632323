'use strict';
(function ($) {

	/**
	 * AdacMapsDisplayPoi module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>
	 * @namespace T.Module
	 * @class AdacMapsDisplayPoi
	 * @extends T.Module
	 */
	T.Module.AdacMapsDisplayPoi = T.createModule({
		defaultZoom: 3,
		start: function start(resolve) {
			this.$ctx = $(this._ctx);

			this._readConfig();

			let rendered = false;
			try {
				const point = this._getConfig("Point");
				if (point) {
					const loc = { x: point.X, y: point.Y };
					this.map = T.Utils.Map.renderMap(loc, false, this._getConfig("Zoom", this.defaultZoom));
					if (AMAMAPS.AMAgetAJAXMapReference()) {
						T.Utils.Map.createPoint(this.map, loc, point.Tooltip || null, this.$iconActivePoi);
						rendered = true;
						this._adjustControls();
					}
				}
			} catch (e) {
				console.error(e);
			}
			if (!rendered) {
				this.$ctx.toggleClass('h-hidden', true);
			}
			resolve();
		},

		_readConfig: function _readConfig() {
			this.config = this.$ctx.data('config') || {};
			this.$iconActivePoi = this.$ctx.find("#mapsIconActivePoi");
		},

		_getConfig: function _getConfig(key, defaultVal) {
			const result = this.config[key];
			return undefined === result ? defaultVal : result;
		},

		_adjustControls: function _adjustControls() {

			let hideControls = this._getConfig("HideControls");
			if (hideControls && !Array.isArray(hideControls)) {
				hideControls = ["toolbar", "zoomslider", "adac_mapsettings"];
			}

			if (hideControls) {
				T.Utils.Map.manipulateLayer(this.map, "scale", "setAreaOpacity", [0.6]);
				T.Utils.Map.manipulateLayer(this.map, "copyright", "setAreaOpacity", [0.6]);
				hideControls.forEach(element => {
					T.Utils.Map.manipulateLayer(this.map, element, "setEnabled", [false]);
				});
			}
		}
	});
}(jQuery));