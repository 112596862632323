(function ($) {
    'use strict';
    /**
     * LayoutHeaderMetanav module implementation.
     *
     * @author <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class LayoutHeaderMetanav
     * @extends T.Module
     */
    T.Module.LayoutHeaderMetanav = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $btnSearch: null,

        /** @type {jQuery} */
        $btnMenu: null,

        /** @type {jQuery} */
        $btnLogin: null,

        /** @type {jQuery} */
        $content: null,

        /** @type {jQuery} */
        $loggedinContent: null,

        /** @type {jQuery} */
        $loggedoutContent: null,

        /** @type {jQuery} */
        $header: null,

        /** @type {jQuery} */
        $darknessLayer: null,

        /** @type {jQuery} */
        $basicSearchModule: null,

        /** @type {jQuery} */
        $basicSearchModuleInput: null,

        $classFormSearch: 'form[role="search"]',
        $classBrs: 'o-layout-header-metanav--brs',
        $classLogin: 'o-layout-header-metanav--loggedin',
        $classNavLeft: '.m-layout-main-nav-left',
        $classNavOpen: 'has-navi-opened',
        $classRedcNavOpen: 'is-redc-open-nav',
        $classCloseIcon: 'js-close-icon',
        $classMainHeader: '.o-layout-main-header',
        $classSubmitSearch: '.js-submit-search',
        $classOoLoggedIn: '.oo-loggedin',
        $emitNavClose: 'LayoutHeaderNav.close',

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start(resolve) {
            this.$ctx = $(this._ctx);

            // eslint-disable-next-line
            this.api = this.api || $('meta[data-key="backend"]').attr('data-value'); // NOSONAR data-attr don't do constants
            this.client = this.client || $('meta[data-key="client"]').attr('data-value');
            this.redirectUri = this.redirectUri || $('meta[data-key="redirect_uri"]').attr('data-value');

            this.$btnSearch = this.$ctx.find('.js-search').eq(0);
            this.$btnMenu = this.$ctx.find('.js-menu').eq(0);
            this.$btnLogin = this.$ctx.find('.js-login').eq(0);
            this.$btnLogout = this.$ctx.find('.js-logout').eq(0);
            this.$btnRegister = this.$ctx.find('.js-register-member').eq(0);
            this.$loggedinContent = this.$ctx.find(this.$classOoLoggedIn).eq(0);
            this.$loggedoutContent = this.$ctx.find('.oo-loggedout').eq(0);
            this.$content = this.$ctx.find('.oo-content').eq(0);

            this.$header = this.$ctx.closest('.l-header');
            this.$basicSearchModule = this.$header.find('.m-basic-search');
            this.$basicSearchModuleInput = this.$basicSearchModule.find(this.$classSubmitSearch);
            this.$searchbar = this.$basicSearchModule.find('.mm-searchbar');
            // add additional class to avoid display: none!important on desktop for a-layout-darkness--search
            this.$darknessLayer = $('.a-layout-darkness').addClass('a-layout-darkness--search');
            this.$layoutHeaderNav = $('.m-layout-header-nav');

            this._registerListeners();
            this._loginHandler();

            this.resize();

            // init events
            if (!S.Utils.Helper.mq('tablet').matches) {
                // smartphone
                this.eventsSmartphone();
            }
            else {
                // tablet/desktop
                this.eventsTabletDesktop();
            }

            // check for reduced header
            if (this.$ctx.hasClass(this.$classBrs)) {
                // add class to l-main for top spacing
                $('.l-main').eq(0).addClass('js-reduced-header');
            }

            this.setClickMenu();

            /**
             * event for the searchBtn
             */
            this.$btnSearch.off().on('click', (e) => {
                e.preventDefault();

                const btnSearch = $(e.currentTarget).eq(0);

                if (!S.Utils.Helper.mq('tablet').matches) {
                    // for mobile: check if the menu was open
                    this.checkMenuStatus();

                    if (this.$searchbar.is(':hidden')) {
                        // addClass for open
                        this.$basicSearchModule.addClass('is-open');

                        // fade out the menu
                        this.searchBarFadeIn();
                    }
                    else {
                        // addClass for open
                        this.$basicSearchModule.removeClass('is-open');

                        // fade in the menu
                        this.searchBarFadeOut();
                    }
                }
                else {
                    if (this.$ctx.hasClass(this.$classBrs)) {

                        this.setSearchPos('open');

                        this.$searchbar.find('button').css('z-index', '5');

                        btnSearch.hide();

                        this.$basicSearchModule.addClass('is-open');

                        // show up
                        this.$searchbar.show();

                        // jump into the input field
                        S.Utils.delayed(`layoutMetaNav-btnSearch${this.$ctx.data('tId')}`, 300, () => {
                            // jump into the input field
                            this.$basicSearchModuleInput.focus();
                        });
                    }
                }

                // close left content navi
                if (!S.Utils.Helper.mq('tablet').matches && $(this.$classNavLeft).length && $('.m-layout-main-nav-left .js-layer').is(':visible')) {
                    $('.m-layout-main-nav-left .mm-btn').trigger('click');
                }

                // reset
                this.reset(btnSearch);
            });

            /**
             * event for darkLayer
             */
            this.$darknessLayer.on('click', () => {
                this.$darknessLayer.fadeOut();

                this.$basicSearchModuleInput.blur();
            });

            resolve();
        },

        /**
         * resize handling
         */
        resize() {
            let oldWidth = $(window).width();

            $(window).on('resize', () => {
                S.Utils.delayed(`layoutheadermetanav-search-${this.$ctx.data('tId')}`, 50, () => {
                    // if you dont check for this, mobile devices will trigger if you swipe over the end of the site
                    if ($(window).width() !== oldWidth) {

                        this.setSearchPos('open');

                        // // Tablet/Desktop:: init events and
                        if (S.Utils.Helper.mq('tablet').matches) {
                            // // hide the input field
                            this.$basicSearchModule.removeClass('is-open');

                            // header-navi
                            this.$layoutHeaderNav.find('.js-layer').attr('style', '');

                            // // hide navi content bar
                            this.hideNaviContent();

                            // show up
                            this.$searchbar.show();

                            // if its an BRS Header.
                            if (this.$ctx.hasClass(this.$classBrs)) {
                                // hide navi menu
                                this.$layoutHeaderNav.find('.js-layer').hide();

                                // // hide inputSearch bar
                                this.searchBarFadeOut();

                                // show the search icon again
                                this.$btnSearch.show();
                            }
                            else {
                                // show up
                                this.$searchbar.css({ opacity: 1 });
                            }
                        }

                        // sets new oldWidth
                        oldWidth = $(window).width();
                    }
                });
            });
        },

        /**
         * searchBarFadeOut
         */
        searchBarFadeIn() {
            const searchbarHeight = this.$searchbar.outerHeight();

            this.$content.css('height', `${this.$content.outerHeight()}px`);
            this.$content.animate({
                height: `${searchbarHeight}px`
            }, 'fast', () => {
                this.$searchbar.fadeIn('fast');
            });

            // show up
            this.$searchbar.css({ opacity: 1 });
            this.$searchbar.focus();
        },

        /**
         * searchBarFadeIn
         */
        searchBarFadeOut() {
            this.$searchbar.fadeOut('fast', () => {
                this.hideNaviContent();
            });
        },

        /**
         * hideNaviContent
         */
        hideNaviContent() {
            this.$content.animate({
                height: '0px'
            }, 'fast');
        },

        /**
         * reset :: close all layers
         *
         * @param {jQuery} elem
         */
        reset(elem) {
            // search
            if (!$(elem).hasClass('js-search') && !S.Utils.Helper.mq('tablet').matches) {
                this.$searchbar.fadeOut();
            }

            // login/logout
            if (!$(elem).hasClass('js-login')) {
                this.$ctx.find(this.$classOoLoggedIn).hide();
                this.$ctx.find('.oo-loggedout').hide();
            }

            // navi
            if ($(elem).hasClass('js-menu')) {
                this.$content.animate({
                    height: '0px'
                }, 'fast');
            }
            else {
                if (!S.Utils.Helper.mq('tablet').matches) {
                    this._events.emit(this.$emitNavClose);
                    $('.o-basic-form').find('.oo-aside').hide();
                    $('.o-basic-form').find('.oo-aside-open').show();
                }
                S.Utils.LayerBgDark.instantHideLayer();
            }
        },

        /**
         *
         */
        setClickMenu() {
            const LayoutHeaderNavLayer = this.$layoutHeaderNav.find('.js-layer');

            // click :: smartphone btn menu
            this.$btnMenu.off().on('click', (e) => {
                e.preventDefault();

                // reset layers
                this.reset($(e.currentTarget));

                // show layoutMainNavLeft
                const closeBtn = this.$layoutHeaderNav.find('.mm-close');

                closeBtn.on('click', () => {
                    $(this.$classNavLeft).removeClass('is-hidden');
                });

                // toggle navi
                if (LayoutHeaderNavLayer.is(':visible')) {
                    // duplicate this.$btnLogin.off().on('click', function (event)
                    // so if you change something, you have to check both

                    // close navi
                    this._events.emit('LayoutHeaderMetaNav.close');

                    if (!S.Utils.Helper.mq('tablet').matches) {
                        LayoutHeaderNavLayer.find('.mm-close').hide();
                    }
                    else {
                        $(this.$classMainHeader).removeClass(this.$classRedcNavOpen);
                    }

                    if (this.$btnMenu.hasClass(this.$classCloseIcon)) {
                        this.$btnMenu.removeClass(this.$classCloseIcon);
                        this.$btnMenu.find('img').attr('src', '/assets/img/icons/icon-menu.svg');
                    }

                    this.$ctx.removeClass(this.$classNavOpen);

                    S.Utils.LayerBgDark.hideLayer();

                    // show layoutMainNavLeft
                    $(this.$classNavLeft).removeClass('is-hidden');

                    // close the menu if it open and the closeFunction sets the default position
                    this._events.emit(this.$emitNavClose);

                }
                else {
                    // open navi
                    if (!S.Utils.Helper.mq('tablet').matches) {
                        LayoutHeaderNavLayer.find('.mm-close').show();

                        this.$content.css('height', '0');
                    }

                    this.$btnMenu.addClass(this.$classCloseIcon).find('img').attr('src', '/assets/img/icons/icon-close.svg');

                    if (S.Utils.Helper.mq('tablet').matches) {
                        LayoutHeaderNavLayer.css('display', 'flex').hide().slideDown('fast');
                        LayoutHeaderNavLayer.find('.mm-close').show();
                        this.$ctx.addClass(this.$classNavOpen);

                        $(this.$classMainHeader).addClass(this.$classRedcNavOpen);
                    }
                    else {
                        LayoutHeaderNavLayer.slideDown('fast');
                    }

                    // reset style attributes from tablet/desktop actions
                    LayoutHeaderNavLayer.find('ul').attr('style', '');

                    // layer bg dark
                    const callback = () => {
                        this._events.emit(this.$emitNavClose);
                    };

                    if (!S.Utils.Helper.mq('tablet').matches) {
                        S.Utils.LayerBgDark.init(this.$btnMenu, callback);
                        // open navi
                        this._events.emit('LayoutHeaderMetaNav.open');
                    }

                    this._events.emit('LayoutHeaderNav.open');
                }
            });
        },

        /**
         * sets the headerInputField position to fit next to the login-name
         * @param {String} mod
         */
        setSearchPos(mod) {
            if (this.$ctx.hasClass(this.$classLogin) || mod === 'open' || this.$searchbar.hasClass('is-open')) {
                const $searchIcon = $('.js-search').closest('li'),
                    searchIconWidth = $searchIcon.outerWidth(),
                    searchIconPosition = $searchIcon.position(),
                    iconListWidth = $searchIcon.closest('.oo-list').outerWidth(true),
                    iconListPadding = parseInt($searchIcon.closest('.oo-list').css('padding-left')),
                    contentWidth = $('.oo-content').outerWidth();

                let neededPosition = (contentWidth - searchIconPosition.left) - searchIconWidth;

                // if its not mobile and the brs-navi is disabled
                if (S.Utils.Helper.mq('tablet').matches && !this.$ctx.hasClass(this.$classBrs)) {
                    neededPosition = iconListWidth - iconListPadding;

                    // in CSS the opacity for the not BRS-Header is set to 0
                    // after the calculation, it will show up
                    this.$searchbar.css({ opacity: 1 });
                }

                // if its not mobile and the brs-navi is enabled
                else if (S.Utils.Helper.mq('tablet').matches && this.$ctx.hasClass(this.$classBrs) && mod === 'open') {
                    this.$searchbar.addClass('is-open');
                }

                // sets the right position for the input field in the header
                this.$searchbar.css('right', `${neededPosition}px`);
            }
        },

        /**
         * mobile:  if the user clicks on the searchIcon/loginIcon
         *          check if the menu was clicked - trigger the events and change the icon
         */
        checkMenuStatus() {
            // console.log('Line: 482:', 'checkMenuStatus in layoutheadermetanav');
            // for mobile: check if the menu was open, and change the X-icon to burger-icon
            // remove classes and trigger events - duplicate from this.$btnMenu.on().off()
            // so if you change something, you have to check both
            if (this.$btnMenu.hasClass(this.$classCloseIcon)) {
                this._events.emit('LayoutHeaderMetaNav.close');

                if (!S.Utils.Helper.mq('tablet').matches) {
                    this.$layoutHeaderNav.find('.js-layer .mm-close').hide();
                }
                else {
                    $(this.$classMainHeader).removeClass(this.$classRedcNavOpen);
                }

                if (this.$btnMenu.hasClass(this.$classCloseIcon)) {
                    this.$btnMenu.removeClass(this.$classCloseIcon);
                    this.$layoutHeaderNav.find('.js-layer .mm-close').hide();
                    this.$btnMenu.find('img').attr('src', '/assets/img/icons/icon-menu.svg');
                }

                this.$ctx.removeClass(this.$classNavOpen);

                S.Utils.LayerBgDark.hideLayer();

                // show layoutMainNavLeft
                $(this.$classNavLeft).removeClass('is-hidden');

                this._events.emit(this.$emitNavClose);
            }
        },

        /**
         * eventsSmartphone :: init smartphone click events
         */
        eventsSmartphone: function () {
            const that = this;
            let loginContent = this.$loggedoutContent;

            // reset from tablet/desktop settings
            this.$content.show();
            this.$ctx.find(this.$classOoLoggedIn).attr('style', '');

            if (this.$ctx.hasClass(this.$classLogin)) {
                loginContent = this.$loggedinContent;
            }

            // click :: smartphone btn login
            this.$btnLogin.off().on('click', function (event) {
                event.preventDefault();

                // toggle content
                if (loginContent.is(':hidden')) {
                    that.checkMenuStatus();

                    loginContent.show();
                    const loginContentHeight = loginContent.outerHeight();
                    loginContent.hide();
                    that.$content.css('height', `${that.$content.outerHeight()}px`);
                    that.$content.animate({
                        height: `${loginContentHeight}px`
                    }, 'fast', function () {
                        loginContent.fadeIn('fast');
                    });
                }
                else {
                    loginContent.fadeOut('fast', function () {
                        that.$content.animate({
                            height: '0px'
                        }, 'fast');
                    });
                }

                // reset
                that.reset(this);
            });

            // disable mousout event set in tablet/desktop mode
            this.$loggedinContent.off();

            // disable mousout event set in tablet/desktop mode
            this.$ctx.off();


            this.setClickMenu();
        },

        /**
         * eventsTabletDesktop :: init tablet/desktop click events
         */
        eventsTabletDesktop() {
            const that = this;

            const loginContent = this.$loggedinContent;

            this.$ctx.find(this.$classOoLoggedIn).attr('style', '');

            loginContent.hide();

            this.$content.attr('style', '');

            if (!this.$ctx.hasClass(this.$classBrs)) {
                // show searchBar
                this.setSearchPos('open');
            }

            if (this.$ctx.hasClass(this.$classLogin)) {
                this.$btnLogin.off().on('click', (e) => {
                    e.preventDefault();
                });

                this.$btnLogin.on('mouseover', () => {
                    if (S.Utils.Helper.mq('tablet').matches) {
                        loginContent.show();
                        that.$ctx.addClass('has-loggedin-layer-visible');

                        const $loginButton = that.$ctx.find('.oo-login'),
                            loginWidth = $loginButton.outerWidth(true),
                            $loginButtonLeftPosition = $loginButton.position().left,
                            $headerWidth = this.$ctx.width(),
                            neededLeftPosition = $headerWidth - $loginButtonLeftPosition;

                        loginContent.css({ width: `${loginWidth}px`, right: neededLeftPosition - loginWidth });
                    }
                });

                loginContent.on('mouseover', () => {
                    loginContent.show();
                });

                $('.l-main').on('mouseover', () => {
                    if (S.Utils.Helper.mq('tablet').matches) {
                        loginContent.hide();
                        $('.o-layout-header-metanav.has-loggedin-layer-visible').removeClass('has-loggedin-layer-visible');
                    }
                });
            }
            else {
                // reset
                this.$btnLogin.off();
                this.$content.attr('style', '');
            }
        },

        /**
         * Initializes login setup
         * @private
         */
        _loginHandler: function () {
            const self = this,
                pathIndex = window.location.href.indexOf('?'),
                scopeTitle = 'Rollen Mitgliedsnummer Daten Profil',
                stateIdentifier = 'my - nonce';
            this.basePath = window.location.href.substr(0, pathIndex) || window.location.href;


            this.$loggedoutContent.find('a.js-login').attr(
                'href',
                T.Utils.Helper.updateUrlParameter(
                    {
                        'client_id': this.client,
                        'redirect_uri': `${this.redirectUri}?processUrl=${window.location.href.replace(/\/\s*$/, "")}`,
                        'state': stateIdentifier,
                        'scope': scopeTitle,
                        'response_type': 'token'
                    },
                    this.api + this.$ctx.attr('data-authorize')
                )
            );
            this.$btnLogin.attr(
                'href',
                T.Utils.Helper.updateUrlParameter(
                    {
                        'client_id': this.client,
                        'redirect_uri': `${this.redirectUri}?processUrl=${window.location.href.replace(/\/\s*$/, "")}`,
                        'state': stateIdentifier,
                        'scope': scopeTitle,
                        'response_type': 'token'
                    },
                    this.api + this.$ctx.attr('data-authorize')
                )
            );
            this.$btnRegister.attr(
                'href',
                T.Utils.Helper.updateUrlParameter(
                    {
                        'client_id': this.client,
                        'origin': window.location.href,
                        'redirect_uri': `${this.redirectUri}?processUrl=${window.location.href.replace(/\/\s*$/, "")}`,
                        'state': stateIdentifier,
                        'scope': scopeTitle,
                        'response_type': 'token'
                    },
                    this.api + this.$ctx.attr('data-register')
                )
            );

            this.$btnLogout.on('click', function (e) {
                e.preventDefault();
                self.$ctx.find('form#logout')
                    .attr('action', `${self.api + self.$ctx.attr('data-logout')}?returnUrl=${self.basePath}`)
                    .submit();
                T.Utils.Auth.logout();

            });
            T.Utils.Auth.checkStatus();
        },

        _registerListeners: function () {
            const $ctx = this.$ctx;

            if ($ctx.find(this.$classSubmitSearch).length) {
                $ctx.find(this.$classFormSearch).bind('submit', this._handleSearchSubmit.bind(this));
            }
            $(document).on('identityloaded', this._handleIdentityLoaded.bind(this));
        },

        _handleIdentityLoaded: function () {
            const self = this;

            self.$ctx.find('.js-login span').text(`${T.Utils.Auth.getLocalIdentity().Vorname} ${T.Utils.Auth.getLocalIdentity().Nachname}`);

            self.$ctx.addClass(this.$classLogin);
            self.$ctx.parents('.l-outer').addClass('l-outer--loggedin');

            // init events
            if (!S.Utils.Helper.mq('tablet').matches) {
                // smartphone
                self.eventsSmartphone();
            }
            else {
                // tablet/desktop
                self.eventsTabletDesktop();
            }
        },

        _handleSearchSubmit: function (e) {
            e.preventDefault();
            const $ctx = this.$ctx;
            $ctx.find(this.$classFormSearch).serializeArray();
            const data = {};
            $ctx.find(this.$classFormSearch).find('input').each(function (i, field) {
                data[field.name] = field.value;
            });
            window.location.href = $ctx.find(this.$classSubmitSearch).data('searchurl') + encodeURIComponent(data.search);
        },
    });
})(jQuery);