'use strict';
(function ($) {
	/**
	 * Radio decorator for the BasicBoxRouting module
	 *
	 * @author  <dmitri.zoubkov@namics.cpom>
	 * @namespace T.Module
	 * @class BasicBoxRouting
	 * @extends T.Module
	 */
	T.Module.BasicBoxRouting.Radio = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		routeContainer: '.a-basic-input-radio',
		routeElement: 'input[type=radio]',
		routeState: ':checked',

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this._parent.start(resolve);
		}
	});
}(jQuery));
