'use strict';
(function ($) {
	/**
	 * HavaristCarDamageProofV2 module implementation.
	 *
	 * @author  <marc.radziwill@merkle.com>
	 * @namespace T.Module
	 * @class HavaristCarDamageProofV2
	 * @extends T.Module
	 */
	T.Module.HavaristCarDamageProofV2 = T.createModule({
		start: function start(resolve) {
			T.Utils.Application.init();

			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');

			this._setStorage();
			this._readConfiguration();
			this._initValidation();
			this._addListeners();
			this._setDataToView();
			resolve();
		},

		_setStorage: function _setStorage() {
			T.Utils.Store.set('PdfCarDemageProof', null, "session");
			T.Utils.Store.set('PdfName', null, "session");
		},
		/**
		* Read configuration
		*/
		_readConfiguration: function _readConfiguration() {
			this._apim = T.Utils.Application.getApiM();
			this._apimSubscriptionKey = T.Utils.Application.getApiMSubscriptionKey();
			this._apiCarDamageProof = this.$ctx.data("api-cardamageproof");

		},
		_initValidation: function () {
			const validationOptions = {
				messages: this.$form.data('validation-messages') ? JSON.parse(this.$form.data('validation-messages')) : {},
				rules: {
					'number-plate': {
						required: true,
						pattern: "^[a-zA-ZÖöÜüÄä]+$",
						maxlength: 3
					},
					'number-plate-2': {
						required: true,
						pattern: "^[a-zA-Z\\d]+$"
					},
					'cardamage_ex_attribute': {
						required: true,
						pattern: "[^- ]*$"
					},
					'cardamage_id': {
						required: true,
						maxlength: 4,
						minlength: 4,
						digits: true
					}
				}
			};
			T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
		},
		/**
		 * Add events
		 */
		_addListeners: function _addListeners() {
			this.$ctx.find(".js-submit").click(this._handleSubmit.bind(this));
			this.$ctx.find('input[data-t-name="BasicInputText"]').change(this._setDataToSession.bind(this));
		},
		_handleSubmit: function _handleSubmit(e) {
			e.preventDefault();

			if (this.$form.valid()) {
				T.Utils.View.startLoader();
				const url = this._buildUrl();
				const options = {
					url: url,
					headers: { "Ocp-Apim-Subscription-Key": this._apimSubscriptionKey }
				};

				T.Utils.Ajax.fragment(options, this._handleSuccessCallback.bind(this), this._handleErrorCallback.bind(this));
			}
		},


		_buildUrl: function _buildUrl() {
			const url = T.Utils.Helper.appendURIPath(this._apim, this._apiCarDamageProof);
			const nationalAttribute = this.$ctx.find('[name="attribute"]:checked');

			let kennzeichen = '';

			if (nationalAttribute.val() === 'nationalattribute') {
				kennzeichen = `${this.$ctx.find('[name="number-plate"]').val()}-${this.$ctx.find('[name="number-plate-2"]').val()}`;
			}
			else {
				kennzeichen = this.$ctx.find('[name="cardamage_ex_attribute"]').val();
			}

			const replacements = {
				licenseplate: kennzeichen,
				id: this.$ctx.find('[name="cardamage_id"]').val()
			};

			return url.interpolate(replacements);
		},


		_handleErrorCallback: function _handleErrorCallback() {
			this.$ctx.find(".js-error").removeClass("h-hidden");
			T.Utils.View.stopLoader();
		},

		_handleSuccessCallback: function _handleSuccessCallback(data) {
			this.$ctx.find("#content_demand").removeClass("h-hidden");
			this.$ctx.find(".js-cardemageproof-form").addClass("h-hidden");
			const pdfLink = this.$ctx.find("#link_demand .a-basic-link--pdf");
			const pdfSize = pdfLink.find("span.a-basic-icon-pdf");

			if (data && data.length > 0 && data[0].DocumentData) {
				const options = {
					url: data[0].DocumentData.Url,
					headers: { "Ocp-Apim-Subscription-Key": this._apimSubscriptionKey },
					xhrFields: {
						responseType: 'arraybuffer'
					}
				};

				T.Utils.Ajax.fragment(options, pdfData => {
					const file = new Blob([pdfData], { type: 'application/pdf' });
					file.name = data[0].DocumentData.Name;

					pdfLink.attr("aria-label", file.name);
					pdfLink.prepend(document.createTextNode(`${file.name}, `));
					pdfSize.append(document.createTextNode(`${Math.ceil(file.size / 1024)} kB`));
					pdfLink.on('click', () => {
						T.Utils.Helper.saveFileBlob(file, file.name);
					});
					T.Utils.View.stopLoader();
				}, () => {
					T.Utils.View.stopLoader();
				});
			}
		},

		_setDataToSession: function _setDataToSession(elem) {
			let inputModel = T.Utils.Store.get('DemageProblemInputModel', 'session');
			if (inputModel === null || inputModel === undefined) {
				inputModel = {};
			}
			inputModel[elem.currentTarget.name] = elem.currentTarget.value;
			T.Utils.Store.set('DemageProblemInputModel', inputModel, 'session');
		},
		_setDataToView() {
			const inputModel = T.Utils.Store.get('DemageProblemInputModel', 'session');
			if (inputModel) {
				if (inputModel["number-plate"]) {
					this.$ctx.find("#number-plate").val(inputModel["number-plate"]);
				}
				if (inputModel["number-plate-2"]) {
					this.$ctx.find("#number-plate-2").val(inputModel["number-plate-2"]);
				}
				if (inputModel["cardamage_ex_attribute"]) {
					this.$ctx.find("#cardamage_ex_attribute").val(inputModel["cardamage_ex_attribute"]);
				}
				if (inputModel["cardamage_id"]) {
					this.$ctx.find("#cardamage_id").val(inputModel["cardamage_id"]);
				}
			}
		}
	});
})(jQuery);