'use strict';

(function ($) {
	/**
	 * Membership billing data
	 *
	 * @author Hauke Lueneburg <hauke.lueneburg@namics.com>
	 * @namespace T.Module
	 * @class MyAdacMembershipContent-BillingDetails
	 * @extends T.Module.MyAdacMembershipContent
	 */
	T.Module.MyAdacMembershipContent.BillingDetails = T.createDecorator({

		$rechnungsdaten: null,
		$rechnungsdatenLeer: null,

		/**
		 * start function
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.$rechnungsdaten = this.$ctx.find('.js-rechnungsdaten');
			this.$rechnungsdatenLeer = this.$ctx.find('.js-rechnungsdaten-leer');
			this._parent.start(resolve);
			T.Utils.Application.init();
			this.readConfiguration();
			this.addEventListeners();
			const emitter = new T.EventEmitter(this._sandbox);
			emitter.on('t.sync', () => {
				this.onIdentityChanged();
			});
		},

		readConfiguration: function readConfiguration() {
			this._apiurl = this.$ctx.data("apiurl");
			this._apiListemglrechnungen = this.$ctx.data("api-listemglrechnungen");
			this._apiLesemglrechnung = this.$ctx.data("api-lesemglrechnung");
			this._backend = this.$ctx.data("backend") || T.Utils.Application.getApi();
		},

		addEventListeners: function addEventListeners() {
			$(document).on("identityloaded", this.onIdentityChanged.bind(this));
			$(document).on("identitylogout", this.onIdentityChanged.bind(this));
		},

		startLoader: function startLoader() {
			T.Utils.View.startLoader();
		},

		stopLoader: function stopLoader() {
			T.Utils.View.stopLoader();
			this._events.emit('MyAdacDashboardList.content-changed', this.$ctx);
		},

		onIdentityChanged: function onIdentityChanged() {
			if (this.needsUpdating()) {
				this.updateContent();
			}
		},

		needsUpdating: function needsUpdating() {
			return !this._dataLoaded && !this._dataLoading;
		},

		updateContent: function updateContent() {
			this._dataLoading = true;
			this.startLoader();

			T.Utils.Auth.getResolvedIdentity(this.handleUserCallback.bind(this));
		},

		handleUserCallback: function handleUserCallback(user) {
			this._user = user;
			this.getUserBillingDetails();
		},

		getUserBillingDetails: function getUserBillingDetails() {
			T.Utils.Auth.getBearerToken((token) => {
				this._dataLoading = true;

				const req = {
					url: T.Utils.Helper.appendURIPath(this._backend, this._apiListemglrechnungen),
					type: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				};
				T.Utils.Ajax.fragment(req, (data) => {
					if (data && data.Success && data.Data.TotalCount > 0) {
						this.$rechnungsdaten.addClass('h-hidden');
						this.$ctx.find(".js-pdf-list").removeClass('h-hidden');
						$.each(data.Data.Documents, function (index, ele) {
							$(".js-pdf").append(`<a data-t-name="BasicBtn" title="${ele.DocumentName}" class="a-basic-link--my-adac h-space-s js-pdf-link" name="${ele.Id}" href="#">${ele.DocumentName}</a>`);
						});
						$(".js-pdf-link").on("click", this._generatePdf.bind(this));
						this._dataLoading = false;
						this._dataLoaded = true;
						this.stopLoader();
					} else {
						this.getMglBilling(token);
					}
				}, this.requestFailed.bind(this));
			});
		},

		requestFailed: function requestFailed(err) {
			if (err.responseJSON && err.responseJSON.Success && err.responseJSON.Data === null) {
				const that = this;
				T.Utils.Auth.getBearerToken(function (token) {
					that._dataLoading = true;
					that.getMglBilling(token);
				});
			}
			else {
				this.$rechnungsdaten.addClass('h-hidden');
				this.$ctx.find(".js-interface-error").removeClass('h-hidden');
				this._dataLoading = false;
				this._dataLoaded = true;
				this.stopLoader();
			}
		},
		_generatePdf: function _generatePdf(e) {
			T.Utils.Auth.getBearerToken((token) => {
				const opt = {
					url: T.Utils.Helper.appendURIPath(this._backend, this._apiLesemglrechnung + e.currentTarget.name),
					type: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				};
				T.Utils.Ajax.fragment(opt, (data) => {
					if (data && data.Success) {
						const byteCharacters = atob(data.Data);
						const byteNumbers = [];

						for (let i = 0; i < byteCharacters.length; i++) {
							byteNumbers[i] = byteCharacters.charCodeAt(i);
						}

						const byteArray = new Uint8Array(byteNumbers);
						const file = new Blob([byteArray], {
							type: 'application/pdf'
						});
						file.name = e.currentTarget.textContent;
						T.Utils.Helper.saveFileBlob(file);
					}
				});
			});
		},
		getMglBilling: function getMglBilling(token) {
			const options = {
				url: T.Utils.Helper.appendURIPath(this._backend, this._apiurl),
				type: 'GET',
				headers: {
					Authorization: `Bearer ${token}`
				}
			};
			T.Utils.Ajax.fragment(options, this.successCallback.bind(this), this.errorCallback.bind(this));
		},
		successCallback: function successCallback(jqxhr) {
			if (this._user) {
				this._user.UserData.Rechnungsdaten = jqxhr.Data;
			}
			this.updateView(this._user);
		},

		errorCallback: function errorCallback() {
			this.updateView(this._user);
		},

		hasBillingData: function hasBillingData(user) {
			return user && user.Success && user.UserData && user.UserData.Rechnungsdaten;
		},

		updateView: function updateView(user) {

			delete this._user;
			if (this.hasBillingData(user)) {
				const mitgliedschaft = user.UserData.Mitgliedschaft;
				const show = mitgliedschaft && mitgliedschaft.TarifInformation;
				const keineRechnungsdaten = (mitgliedschaft.TarifInformation.TarifCode === "PAR" || mitgliedschaft.TarifInformation.TarifCode === "F1U" || mitgliedschaft.TarifInformation.TarifCode === "F1");
				if (show && keineRechnungsdaten) {
					this.$rechnungsdatenLeer.removeClass('h-hidden');
					this.$rechnungsdaten.addClass('h-hidden');
				}
				else {
					const data = user.UserData.Rechnungsdaten;

					this.$ctx.find('.js-AbrechnungsZeitraumVon').text(moment(data.AbrechnungsZeitraumVon).format("L"));
					this.$ctx.find('.js-AbrechnungsZeitraumBis').text(moment(data.AbrechnungsZeitraumBis).format("L"));

					this.setAmount('.js-Jahresbeitrag', data.Jahresbeitrag, data.AbhaengigesMitglied);
					this.setAmount('.js-Aufnahmegebuehr', data.Aufnahmegebuehr, data.AbhaengigesMitglied);
					this.setAmount('.js-Mahngebuehren', data.Mahngebuehren, data.AbhaengigesMitglied);

					this.setBalance(data);
					this.setPaymentByDebit(data);

					this.$ctx.find('.js-membership-active-text').toggleClass('h-hidden', data.MitgliedschaftAktiv);
					this.$ctx.find('.js-related-member-text').toggleClass('h-hidden', !data.AbhaengigesMitglied);
					this.$ctx.find('.js-balance-text').toggleClass('h-hidden', this.hideBalanceText(data));
					this.$ctx.find('.js-respite-active-text').toggleClass('h-hidden', !data.ZahlungsaufschubAktiv);
					this.$ctx.find('.js-Lastschrifteinzug').toggleClass('h-hidden', data.Lastschrifteinzug);

					this.$rechnungsdatenLeer.addClass('h-hidden');
					this.$rechnungsdaten.removeClass('h-hidden');
				}
			}

			this._dataLoading = false;
			this._dataLoaded = true;
			this.stopLoader();
		},

		setBalance: function setBalance(data) {
			const $balance = this.$ctx.find(".js-balance");
			$balance.empty();
			if (data.Saldo && data.Saldo < 0) {
				$balance.append(parseFloat(data.Saldo, 10).toFixed(2).replace('.', ','));
			} else {
				$balance.parent().toggleClass('h-hidden', true);
			}
		},

		setPaymentByDebit: function setPaymentByDebit(data) {
			const paymentbydebitElem = this.$ctx.find('.js-paymentbydebit-text');
			if (data.Lastschrifteinzug && !data.AbhaengigesMitglied) {
				let paymentByDebitText = $(paymentbydebitElem).text();
				paymentByDebitText = paymentByDebitText.replace('{IBAN}', data.IbanGeschwaerzt);
				$(paymentbydebitElem).text(paymentByDebitText);

				$(paymentbydebitElem).toggleClass('h-hidden', !data.Lastschrifteinzug);
			}
		},

		hideBalanceText: function hideBalanceText(data) {
			if (data.AbhaengigesMitglied) {
				return true;
			}
			return data.Saldo < 0;
		},

		setAmount: function setAmount(selector, value, abhaengigesMitglied) {
			this.$ctx.find(selector).prepend(parseFloat(value, 10).toFixed(2).replace('.', ','));
			this.showListItems([selector], (value > 0 && !abhaengigesMitglied));
		},

		showListItems: function showListItems(selectors, show, parents) {
			parents = parents ? parents : '.mm-list-wrapper';
			for (let i = 0; i < selectors.length; i++) {
				this.$ctx.find(selectors[i]).parents(parents).toggleClass('h-hidden', !show);
			}
		}
	});
}(jQuery));