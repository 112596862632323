'use strict';
(function ($) {
	/**
	 * Question decorator for the BasicContactForm module
	 *
	 * @author  <tobias.knaus@namics.com>
	 * @namespace T.Module
	 * @class BasicContactForm
	 * @extends T.Module
	 */
	T.Module.BasicContactForm.Question = T.createDecorator({
		/** @type {jQuery} */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this._parent.start(resolve);
			this.$cancelBtn = this.$ctx.find('.js-form-cancel');
			this.$cancelBtn.on('click', this._cancelSubmit.bind(this));
		},

		_setupValidation: function () {
			let validationmessages = this.$contactForm.data('validation-messages');

			if (!validationmessages) {
				validationmessages = {};
			}
			const rulesAndMessages = {
				rules: {
					'MembershipNumber': {
						maxlength: 9,
						digits: true
					},
					'gender': {
						required: true
					},
					'Firstname': {
						required: true,
						maxlength: 100
					},
					'Lastname': {
						required: true,
						maxlength: 100
					},
					'Email': {
						required: true,
						email: true,
						maxlength: 100
					},
					'Message': {
						required: true,
						maxlength: 7000
					}
				},
				messages: validationmessages
			};
			T.Utils.Validation.init(this.$ctx, [this.$contactForm], rulesAndMessages);
		},

		_cancelSubmit: function () {
			const lastVisitedPage = JSON.parse(sessionStorage.getItem("lastVisitedPage"));

			if (lastVisitedPage) {
				window.location.href = lastVisitedPage.href;
			} else {
				history.back();
			}
		},

		_successCallback: function () {
			this.$contactForm.addClass('h-hidden');
			this.$errorText.addClass('h-hidden');
			this.$successText.removeClass('h-hidden');
			this.$forwardBtn = this.$ctx.find('.js-form-forward');
			this.$forwardBtn.on('click', this._cancelSubmit.bind(this));
			$(window).scrollTop(0);
		},

		_errorCallback: function _errorCallback() {
			this.$contactForm.addClass('h-hidden');
			this.$successText.addClass('h-hidden');
			this.$errorText.removeClass('h-hidden');
			this.$forwardBtn = this.$ctx.find('.js-form-forward');
			this.$forwardBtn.on('click', this._cancelSubmit.bind(this));
			$(window).scrollTop(0);
		}
	});
}(jQuery));