(function ($)
{
    'use strict';
    /**
     * BasicMemberPuzzle module implementation.
     *
     * @author Örsan Günay <oe.guenay@edelweiss72.de>
     * @namespace T.Module
     * @class BasicMemberPuzzle
     * @extends T.Module
     */
    T.Module.BasicMemberPuzzle = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {Number} */
        ctxTID: null,

        /** @type {jQuery} */
        $swipeNextBtn: null,

        /** @type {jQuery} */
        $swipePrevBtn: null,

        /** @type {jQuery} */
        $closeLayerBtn: null,

        /** @type {jQuery} */
        $memberContainer: null,

        /** @type {jQuery} */
        $contentContainer: null,

        /** @type {jQuery} */
        $keywordComponent: null,

		/** @type {jQuery} */
		$swiperSlidePuzzle: null,

        start(resolve)
        {
            this.$ctx = $(this._ctx);
            this.ctxTID = this.$ctx.data('t-id');
			this.$swipePrevBtnSmall = this.$ctx.find('.js-swipe-prev');
			this.$swipeNextBtnSmall = this.$ctx.find('.js-swipe-next');
			this.$swipePrevBtn = this.$ctx.find('.swiper-button-prev');
            this.$swipeNextBtn = this.$ctx.find('.swiper-button-next');
            this.$closeLayerBtn = this.$ctx.find('.js-close-layer');
            this.$memberContainer = this.$ctx.find('.mm-member-gallery-wrapper');
            this.$contentContainer = this.$ctx.find('.js-member-content-full');
            this.$keywordComponent = jQuery('.m-basic-keywordfilter').find('input');
			this.$swiperSlidePuzzle = this.$ctx.find('.swiper-slide.js-puzzle-content');
            this.swiperSlide = '.swiper-slide';
            this.isInactive = 'is-inactive';
            this.clickSwipeTo = 'click.swipeTo';
            this.ariaLabelAttribute = 'aria-label';
            this.swiperBtnLeftAriaLabelText = this.$swipePrevBtn.attr(this.ariaLabelAttribute);
            this.swiperBtnRightAriaLabelText  = this.$swipeNextBtn.attr(this.ariaLabelAttribute);

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            // only for the second (big) swiper
            S.Utils.SwiperHelper.addLazyClassToImg(this.$contentContainer.find(this.swiperSlide), true);

            resolve();
        },

        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * Only if u call "this._parent.start(resolve);" in the start
         * ##########################################################
         * :: "views/pages/lazy/examples/lazy.html" - part "decorator and parent-lazy loading"
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate()
        {
            this.setGalleryEventOnMembers();
        },

        setGalleryEventOnMembers()
        {
			this.$swiperSlidePuzzle.on('keyup', (event) =>
			{
				if(event.key === 'Enter') {
					$(event.currentTarget).trigger('click');
				}
			});

			this.$swiperSlidePuzzle.on('click.initSwiper', (e) =>
            {
                this.initializeSwiper($(e.target).closest(this.swiperSlide));
				this.$swiperSlidePuzzle.off('click.initSwiper');
            });
        },

        initializeSwiper(clickedMember)
        {
            let firstTime = false;

            $('html, body').animate({scrollTop: this.$ctx.offset().top}, 300, () =>
            {
                if (firstTime)
                {
                    return;
                }

                firstTime = true;

                this.$memberContainer.fadeOut(500, () =>
                {
                    this.$ctx.find('.swiper-button.is-disabled').removeClass('is-disabled');
                    this.$ctx.find('.swiper.is-inactive').removeClass(this.isInactive);
                    this.$contentContainer.filter('.is-inactive').removeClass(this.isInactive);

                    this.$memberContainer.find('.js-members').addClass(`swiperId${this.ctxTID}`);
                    this.$contentContainer.addClass(`swiperId${this.ctxTID}`);

                    this.$contentContainer.show();
                    this.$memberContainer.show();

					this.$ctx.find('.mm-member-content-wrapper .js-close-layer').focus();
                    const members = new Swiper(`.js-members.swiperId${this.ctxTID}`, {
                        slidesPerView: 'auto',
                        speed: 1000,
                        spaceBetween: 10,
                        watchSlidesProgress: true,
                        slideToClickedSlide: true,
                        slidesOffsetBefore: S.Utils.Helper.mq('tablet').matches ? 50 : 40,
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
						keyboard: {
							enabled: true,
						},
                        breakpoints: {
                            // when window width is >= 320px
                            320: {
                                slidesOffsetBefore: 40
                            },
                            768: {
                                speed: 600,
                                slidesOffsetBefore: 50,
                            }
                        }
                    });

                    const memberContentLayer = new Swiper(`.js-member-content-full.swiper.swiperId${this.ctxTID}`, {
                        speed: 1000,
                        spaceBetween: 10,
                        slidesPerView: 1,
                        watchSlidesProgress: true,
						keyboard: {
							enabled: true,
						},
                        lazy: {
                            checkInView: true,
                            loadPrevNextAmount: 2,
                            loadPrevNext: true,
                        },
                        on: {
                            /**
                             * @params {swiper, slideEl, imageEl}
                             *
                             * @param {Object} swiper
                             * @param {HTMLElement} slideEl
                             */
                            lazyImageLoad: (swiper, slideEl) =>
                            {
                                const $slide = $(slideEl);

                                // kept the same old logic from loadVisibleImages() Function
                                $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                                $slide.find('.js-init').removeClass('js-init');
                            },
                            lazyImageReady: (swiper, slideEl) =>
                            {
                                const $slide = $(slideEl);

                                S.Lazy.handleUnveilClasses($slide.find('img'));
                            }
                        }
                    });

                    // bugfix: set aria-labels here again, because swiper sometimes overwrites it
                    this.$swipePrevBtn.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
                    this.$swipeNextBtn.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);

                    this.$contentContainer.hide();

                    this.$memberContainer.hide().fadeIn(500, () =>
                    {
                        this.$contentContainer.slideDown('slow', () =>
                        {
                            members.slideTo(clickedMember.index());
                            memberContentLayer.slideTo(clickedMember.siblings('.js-puzzle-content').add(clickedMember).index(clickedMember));
                        });

                        this.setEventsWhenSwiperIsActive(members, memberContentLayer);
                    });
                });
            });
        },

        setEventsWhenSwiperIsActive(memberSwiper, layerSwiper)
        {
            // Members Buttons
			this.$swiperSlidePuzzle.off(this.clickSwipeTo).on(this.clickSwipeTo, (e) =>
            {
                const clickedMember = $(e.target).closest(this.swiperSlide);
                const jumpTo = clickedMember.siblings('.js-puzzle-content').add(clickedMember).index(clickedMember);
                layerSwiper.slideTo(jumpTo);
            });

            this.$swipePrevBtn.off('click').on('click', () =>
            {
                layerSwiper.slidePrev();
                memberSwiper.slideTo(this.$swiperSlidePuzzle.eq(layerSwiper.activeIndex).index());
            });

            this.$swipeNextBtn.off('click').on('click', () =>
            {
                layerSwiper.slideNext();
                memberSwiper.slideTo(this.$swiperSlidePuzzle.eq(layerSwiper.activeIndex).index());
            });

			// trigger swiper skipp-arrows
			this.$swipePrevBtnSmall.off('click').on('click', () => {
				layerSwiper.slidePrev();
				memberSwiper.slideTo(this.$swiperSlidePuzzle.eq(layerSwiper.activeIndex).index());
			});
			this.$swipeNextBtnSmall.off('click').on('click', () => {
				layerSwiper.slideNext();
				memberSwiper.slideTo(this.$swiperSlidePuzzle.eq(layerSwiper.activeIndex).index());
			});

            // Close Button
            this.$closeLayerBtn.off('click').on('click', () =>
            {
                this.resetGallery(memberSwiper, layerSwiper);
            });

            // sync member slides with current layer slide index
            layerSwiper.on('transitionEnd', () =>
            {
                memberSwiper.slideTo(this.$swiperSlidePuzzle.eq(layerSwiper.activeIndex).index());
            });
        },

        resetGallery(memberSwiper, layerSwiper)
        {
            let firstTime = false;

            this.$memberContainer.add(this.$contentContainer).fadeOut(500, () =>
            {
                if (firstTime)
                {
                    return;
                }
                firstTime = true;

                this.$ctx.find('.swiper-button').addClass('is-disabled');
                this.$ctx.find('.js-members').addClass(this.isInactive);
                this.$contentContainer.addClass(this.isInactive);
				this.$swiperSlidePuzzle.off(this.clickSwipeTo);

                memberSwiper.destroy(true, true);
                layerSwiper.destroy(true, true);

                if (this.$keywordComponent.val())
                {
                    this.$keywordComponent.trigger('keyup');
                }

                this.$memberContainer.fadeIn(500, () =>
                {
                    this.setGalleryEventOnMembers();
                });
            });
        }
    });
}(jQuery));
