(function ($) {
	'use strict';

	/**
	 * break decorator implementation for the BasicTable module.
	 *
	 * @author  <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module.BasicRating
	 * @class Clickable
	 * @extends T.Module
	 */
	T.Module.BasicRating.Clickable = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		start: function (resolve) {
			const that = this;

			this.$ctx = $(this._ctx);
			that.$stars = this.$ctx.find('.mm-star');
			that.$stars.addClass('is-clickable');

			this.addStar();


			this._parent.start(resolve);
		},

		addStar() {
			this.$stars.on('click', (e) => {
				const currentStar = $(e.target),
					minStarPos = $(e.target).index(),
					starFull = 'mm-star--full';

				currentStar.addClass(starFull);
				this.$ctx.find(`.mm-star:lt(${minStarPos})`).addClass(starFull);
				this.$ctx.find(`.mm-star:gt(${minStarPos})`).removeClass(starFull);
			});
		}

	});
}(jQuery));
