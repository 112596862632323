'use strict';
(function ($) {
	/**
	 * Dahag module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class Dahag
	 * @extends T.Module
	 */
	T.Module.DAHAG = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		_user: null,

		sessionKey: "selector.dahag",

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			const that = this;
			this.$ctx = $(this._ctx);
			that.getUserData(that._showContents.bind(that));

			this.$ctx.find('.js-submit-form').on('click', this._submitOnClick.bind(this));
			this.$ctx.find('.js-login').on('click', this._authorizeOnClick.bind(this));

			resolve();
		},
		_authorizeOnClick(event) {
			event.preventDefault();
			T.Utils.Auth.authorize();
		},
		_submitOnClick(event) {
			event.preventDefault();
			const that = this;
			const privacyCheck = this.$ctx.find('input[name="privacy_dahag"]');
			if (!privacyCheck[0].checked) {
				privacyCheck.parent().parent().addClass('is-error');
			}
			else {
				privacyCheck.parent().parent().removeClass('is-error');
				const _form = that.$ctx.find('form'),
					apitoken = _form.attr('data-api-token'),
					nextUrl = _form.attr('data-next-url'),
					api = that.api || $('meta[data-key="backend"]').attr('data-value');
				T.Utils.Auth.getBearerToken((token) => {
					T.Utils.Ajax.fragment({
						url: api + apitoken,
						type: 'GET',
						headers: {
							Authorization: `Bearer ${token}`,
						},
					},
						(data) => {
							if (data && data.Data) {
								window.open(nextUrl + encodeURIComponent(data.Data));
							}
						},
						(jqxhr) => {
							const errorGeneralerror = _form.find('[data-div-error="ErrorGeneralerror"]');
							if (jqxhr.responseJSON) {
								this._handleError(jqxhr, errorGeneralerror);
							} else {
								_form.addClass('h-hidden');
							}
						});
				});
			}
		},

		_handleError(jqxhr, errorGeneralerror) {
			$.each(jqxhr.responseJSON.Messages, function () {
				// 120007 Kunde hat keine Berechtigung
				//120008 Kunde hat keine Rechtsschutzvertrag
				//120009 Versicherungsnehmer hat Altvertrag.
				if (this.Id !== 120007 && this.Id !== 120008 && this.Id === 120009) {
					errorGeneralerror.removeClass('h-hidden');
					errorGeneralerror.show();
				}
			});
		},
		/**
		 * Displa Content
		 *
		 * @param { function } user data: from session storage
		 */
		_showContents(user) {
			const _form = this.$ctx.find('form'),
				formularDiv = _form.find('[data-div-name="FormularDIV"]'),
				notLoggedDIV = _form.find('[data-div-name="NotLoggedDIV"]'),
				notAuthDIV = _form.find('[data-div-name="NotAuthDIV"]'),
				errorGeneralerror = _form.find('[data-div-error="ErrorGeneralerror"]');
			if (user) {
				if (user.UserData.IstMitglied || (user.Roles && jQuery.inArray('Kunde', user.Roles) > -1)) {

					const pruefezugangAPI = _form.attr('data-api-pruefezugang'),
						api = this.api || $('meta[data-key="backend"]').attr('data-value');
					T.Utils.Auth.getBearerToken((token) => {
						T.Utils.Ajax.fragment({
							url: api + pruefezugangAPI,
							type: 'GET',
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
							() => { // on success
								notLoggedDIV.addClass('h-hidden');
								notAuthDIV.addClass('h-hidden');
								formularDiv.removeClass('h-hidden');
								formularDiv.show();
							},
							(jqxhr) => {
								notLoggedDIV.addClass('h-hidden');
								formularDiv.addClass('h-hidden');
								notAuthDIV.removeClass('h-hidden');
								notAuthDIV.show();
								if (jqxhr.responseJSON) {
									this._handleError(jqxhr, errorGeneralerror);
								} else {
									_form.addClass('h-hidden');
								}

							});
					});

				} else {
					notLoggedDIV.addClass('h-hidden');
					formularDiv.addClass('h-hidden');
					notAuthDIV.removeClass('h-hidden');
					notAuthDIV.show();
					errorGeneralerror.removeClass('h-hidden');
					errorGeneralerror.show();
				}
			} else {
				notLoggedDIV.removeClass('h-hidden');
				notLoggedDIV.show();
				formularDiv.addClass('h-hidden');
				notAuthDIV.addClass('h-hidden');
			}
		},

		/**
		 * Gets the user data from the application identity
		 *
		 * @param {function} name callback: the callback to call showContent-Methode
		 */
		getUserData(callback) {
			T.Utils.Auth.getResolvedIdentity(callback);
		}
	});
}(jQuery));