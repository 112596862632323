(function($) {
	'use strict';
	/**
	 * MyAdacInfoBox module implementation.
	 *
	 * @author Tobias <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class MyAdacInfoBox
	 * @extends T.Module
	 */
	T.Module.MyAdacInfoBox = T.createModule({

        /** @type {jQuery} */
        $module: null,

        /** @type {Boolean}*/
        firstInit: true,

        start(resolve)
		{
            this.$module = $(this._ctx);

            this._events.on('MyAdacDashboardList.content-changed', () =>
            {
                if(this.$module.hasClass('m-my-adac-info-box--stopper-outside') && this.firstInit) {
                    this.shortenTheFirstContent();
                    this.setStopperHeight();
                }
            });

			resolve();
		},

        /**
		 * shorten the first mm-content element, that´s not an mm-breaker
		 *
		 * so the content don´t collide with the .a-basic-stopper--my-adac-top-wrapper
		 * on the side
         */
        shortenTheFirstContent() {
			const $neededWrapper = this.$module.closest('.mm-content-wrapper'),
				$neededFirstContent = $neededWrapper.find('.mm-content:not(.mm-breaker)').eq(0);

			$neededFirstContent.css({maxWidth: '85%'});
            this.firstInit = false;
		},

        /**
		 * set the height from top the box, after calculating the size of the
		 * box-headline (maybe it has more than one line)
		 * after setting the height, add the class
		 * the rest is triggered in basicstopper-my-adac.scss
         */
		setStopperHeight() {
			const $neededBoxHeadlineHeight = this.$module.closest('.mm-box').find('.mm-box-headline').outerHeight(),
				$stopperToSetHeight = this.$module.find('.a-basic-stopper--my-adac');

			$stopperToSetHeight.css({top: `+=${$neededBoxHeadlineHeight}px`});
            $stopperToSetHeight.addClass('is-shown');
		}
	});
}(jQuery));
