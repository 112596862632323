(function($) {
	'use strict';
	/**
	 * BasicStickyServiceContact module implementation.
	 *
	 * @author m.haeusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicStickyServiceContact
	 * @extends T.Module
	 */
	T.Module.BasicStickyServiceContact = T.createModule({

		/** @type {number} */
		resizeTimeout: 0,

		start: function(resolve) {
			this.$ctx = $(this._ctx);
			this.$trigger = this.$ctx.find('.mm-trigger');
			this.$detailsContent = this.$ctx.find('.mm-layer-details');
			this.$triggerClose = this.$detailsContent.find('.mm-trigger-close');
			this.$tabBtn = this.$ctx.find('.mm-tab-headline');
			this.$tabContainer = this.$ctx.find('.mm-inner-content');

			this.initToggleOpen();
			this.initTab();
			this.resizeModule();

			resolve();
		},

		initToggleOpen()
		{
			const that = this;
			this.$trigger.on('click', function() {
				that.$detailsContent.show();
				that.setScrollBar('init');
			});

			this.$triggerClose.on('click', function() {
				that.$detailsContent.hide();
			});

			$('.l-main').bind('click', (e) =>
			{
				const $target = $(e.target);
				if(!$target.closest(this.$ctx).length && this.$ctx.is(":visible")) {
					this.$detailsContent.hide();
				}
			});
		},

		initTab()
		{
			const that = this;

			this.$tabBtn.on('click', function() {
				const position = $(this).index();
				const content = that.$tabContainer.eq(position);

				that.$tabBtn.removeClass('is-active');
				$(this).addClass('is-active');

				if(!content.hasClass('is-active'))
				{
					that.$tabContainer.filter('.is-active').css('display', '').fadeOut(250).removeClass('is-active');
					content.fadeIn(400).addClass('is-active');
					that.setScrollBar('init');
				}
			});
		},

		/**
		 * init custom scrollbar for dropDown
		 *
		 * @param mode
		 *
		 */
		setScrollBar: function (mode) {

			this.$tabContainerActive = this.$ctx.find('.mm-inner-content.is-active');

			const that = this,
				heightBuffer = 20;

			if (mode === 'init') {

				that.$tabContainerActive.each( function (index, item) {


					const containerHeight = $(item).outerHeight(),
						listHeight = $(item).find('.mm-scroll-content').outerHeight();

					if ( !$(item).hasClass('jspScrollable') ) {

						// check if item is bigger than container ( + buffer )
						if (listHeight >= ( containerHeight + heightBuffer ) ) {
							$(item).jScrollPane({showArrows: true});

							// prevent arrow-btn events
							that.$ctx.find('.jspArrow').off();

							that.$scrollPaneApi = $(item).data('jsp');
						}
					}

					// reinit for width calculation
					else {
						that.$scrollPaneApi.reinitialise();
					}
				});
			}
		},
		//setPosition()
		//{
		//	const header = $('.l-header').height();
		//	const nav = $('.l-header .m-layout-header-nav-refresh > nav').outerHeight();
		//	const deskDistance = 115;
		//	const tabDistance = 20;
		//	let gesHeight;
		//
		//	if (S.Utils.Helper.mq('desktop').matches) {
		//		gesHeight = header + nav + deskDistance;
		//		this.$ctx.css({'top': gesHeight + 'px'});
		//	}
		//	else if (S.Utils.Helper.mq('tablet').matches){
		//		gesHeight = header + nav + tabDistance;
		//		this.$ctx.css({'top': gesHeight + 'px'});
		//	}else {
		//		this.$ctx.css({'top': 'inherit'});
		//	}
		//},

		resizeModule()
		{
			$(window).on('resize', () => {
				clearTimeout(this.resizeTimeout);
				this.resizeTimeout = setTimeout(()=> {
					this.setScrollBar('init');
				}, 10);

			});
		}

	});
}(jQuery));
