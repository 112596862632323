(function ($) {
	'use strict';

	/**
	 * BasicInputText module implementation.
	 *
	 * @author  <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputText
	 * @extends T.Module
	 */
	T.Module.BasicInputText.SearchOld = T.createDecorator({
		// T.Module.BasicInputText.Search = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {String} */
		dataType: null,

		/** @type {String} XMLHttpRequest  */
		xHr: null,

		/**
		 * Initialize.
		 */
		start(resolve) {
			this.$ctx = $(this._ctx);
			this.dataType = this.$ctx.data('jsonformat');

			// check the json/data type
			switch (this.dataType) {
				case "inline":
					// do stuff
					break;
				case "json":
					// do stuff
					break;
				case "url":
					// do stuff
					break;
			}

			// validate on modification
			this.$ctx.on('change keyup', (e) => {
				this.validate(e.which);
			});

			// validation site
			// https://goodies.pixabay.com/jquery/auto-complete/demo.html

			resolve();
		},

		validate() {

			this.$ctx.autoComplete({
				minChars: 1,
				cache: false,
				delay: 50,
				offsetLeft: 500,
				menuClass: 'mm-autocomplete-wrapper',
				dataType: 'jsonp',
				source: (term, response) => {
					try {
						this.xhr.abort();
					} catch (e) {
						throw new Error('finally error; basicinputtext-search(old).js');
					}

					this.xhr = $.getJSON('/dev/api/search/search-terms.json', { q: term, format: "json" },
						(data) => {
							// hier nur die ergebnissse übergeben
							return response(data);
						});
				},
				renderItems: (item) => {
					const $text = '<p>itemLabel.replace(re, "<strong>$1</strong>")</p>';

					return $('<div>')
						.addClass('autocomplete-suggestion')
						.data('val', item)
						.append($text);
				}
			});
		},

		/**
		 *
		 */
		resize() {

		}

	});
}(jQuery));
