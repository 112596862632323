(function ($)
{
    'use strict';
    /**
     * MyAdacDashboardBoxLayer module implementation.
     *
     * @author Tobias <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class MyAdacPromotionBox
     * @extends T.Module
     */
    T.Module.MyAdacDashboardBoxLayer = T.createModule({

        /** @type {jQuery} */
        $module: null,

        /** @type {jQuery} */
        $close: null,

        /** @type {jQuery} */
        $closeLink: null,

        /** @type {jQuery} */
        $closeButton: null,

        /** @type {jQuery} overlayer*/
        overlayer: null,

		/** @type {jQuery} */
		$themeOption: null,

		/** @type {jQuery} */
		$headlineLayer: null,

		/** @type {jQuery} */
		$headline: null,

		// eslint-disable-next-line
        start(resolve) //NOSONAR complexity
        {
            this.$module = $(this._ctx);
            this.overlayer = this.$module.find('.mm-overlay');
            this.$close = this.$module.find('.mm-close');
            this.$closeLink = this.$module.find('.a-basic-link--my-adac-back');
            this.$closeButton = this.$module.find('.mm-close-button');
            this.$themeOption = this.$module.closest('.mm-theme-option');
			this.$headlineLayer = this.$module.closest('.m-my-adac-dashboard-box-layer').attr('data-t-headline');
			this.$headline = this.$module.closest('.m-my-adac-dashboard-box-layer').hasClass('m-my-adac-dashboard-box-layer--headline');

			const optionsShow = 'Optionen anzeigen';

            this._events.on('MyAdacDashboardBoxLayer.open-layer-by-link',  (clickedElement) => {
                if(clickedElement.is(this.$module.prev('.a-basic-link').find('.aa-info-icon'))){

                    this.$module.removeClass('is-hidden');
                }
            });


            this._events.on('MyAdacDashboardBoxLayer.open-layer-info-icon', (e) =>
            {
				if ($(e).hasClass('open-right'))
				{
					$(e).find('.is-hidden').eq(1).removeClass('is-hidden');
				}
				else if ($(e).hasClass('open-left')) {
					$(e).find('.is-hidden:first-child').removeClass('is-hidden');
				}
				else if (e.is(this.$module.closest('.mm-list-wrapper')))
                {
                    this.$module.removeClass('is-hidden');

                }
            });
            this._events.on('MyAdacDashboardBoxLayer.open-layer', (e) =>
            {
                if (e.is(this.$module.closest('.mm-content')))
                {
                    this.$module.removeClass('is-hidden');
                }
            });

            this._events.on('MyAdacDashboardBoxLayer.open-layer-in-toggle', (e) =>
            {
                if (e.is(this.$module.closest('.mm-my-adac-toggle')))
                {
                    this.$module.removeClass('is-hidden');
                }
            });

            this._events.on('MyAdacDashboardBoxLayer.open-layer-theme-options', (e) =>
            {
                if (e.is(this.$themeOption))
                {
                    this.$module.removeClass('is-hidden');
					if(this.$headline) {
						$(e).addClass('is-open').find('.mm-text').html(`${this.$headlineLayer  } verbergen`);
					}
					else {
						$(e).addClass('is-open').find('.mm-text').html('Optionen verbergen');
					}
                }
            });

            this._events.on('MyAdacDashboardBoxLayer.open-layer-theme-options-close', (e) =>
            {
                if (e.is(this.$themeOption))
                {
                    this.$module.addClass('is-hidden');

                    if(this.$headline) {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(this.$headlineLayer);
					}
                    else {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(optionsShow);
					}
                }
            });

            // clicking on the overlayer - to check if someone is clicking outside of the layer
            this.overlayer.on('click.outsideOptionLayer', () =>
            {
                this.$module.addClass('is-hidden');

                if (this.$themeOption.length)
                {
					if(this.$headline) {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(this.$headlineLayer);
					}
					else {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(optionsShow);
					}
                }
            });

            this.$close.on('click', () =>
            {
                this.$module.addClass('is-hidden');

                if (this.$themeOption.length)
                {
					if(this.$headline) {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(this.$headlineLayer);
					}
					else {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(optionsShow);
					}
                }
            });

            this.$closeLink.on('click', (e) =>
            {
                e.preventDefault();

                this.$module.addClass('is-hidden');

                if (this.$themeOption.length)
                {
					if(this.$headline) {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(this.$headlineLayer);
					}
					else {
						this.$themeOption.removeClass('is-open').find('.mm-text').html(optionsShow);
					}
                }
            });

            resolve();
        }
    });
}(jQuery));
