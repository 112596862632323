'use strict';
/* eslint-disable complexity */
/* eslint-disable camelcase */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
(function ($) {
	T.Utils = T.Utils || {};

	T.Utils.Autocomplete = {
		config: {},
		mapJsonResponse: function mapJsonResponse(mode, data) { //NOSONAR
			switch (mode) {
				case 'onsitelocation':
					if (data.Data && data.Data.length > 0) {
						return data.Data.reduce(function (filtered, item) {
							if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y) {
								filtered.push([T.Utils.Helper.htmlToText(item.Kurztext), item.Punkt1, item.Punkt2, T.Utils.Helper.htmlToText(item.LandKennzeichen)]);

							}
							return filtered;
						}, []);
					}
				// fall through
				case 'onsitelocationde':
					if (data.Data && data.Data.length > 0) {
						return data.Data.reduce(function (filtered, item) {
							if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y && 'D' === item.LandKennzeichen) {
								filtered.push([T.Utils.Helper.htmlToText(item.Kurztext), item.Punkt1, item.Punkt2, T.Utils.Helper.htmlToText(item.LandKennzeichen)]);
							}
							return filtered;
						}, []);
					}
				// fall through
				case 'defaultgeotypeahead':
					if (data.Data && data.Data.length > 0) {
						return data.Data.reduce(function (filtered, item) {
							if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y) {
								filtered.push([T.Utils.Helper.htmlToText(item.AufbereiteterName), item.Punkt1, item.Punkt2, T.Utils.Helper.htmlToText(item.LandKennzeichen)]);
							}
							return filtered;
						}, []);
					}
				// fall through
				case 'tourmaillocation':
					if (data.Data && data.Data.length > 0) {
						return data.Data.reduce(function (filtered, item) {
							if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y) {
								filtered.push([T.Utils.Helper.htmlToText(item.AufbereiteterName), item.Punkt1, item.Punkt2, T.Utils.Helper.htmlToText(item.LandKennzeichenIso2)]);
							}
							return filtered;
						}, []);
					}
				// fall through
				case 'mglmodularsystem':
				case 'mglcancellation':
					if (data && data.content.length > 0) {
						const resultStrings = [];
						const uniqueItems = [];
						data.content.forEach((item) => {
							const field = this.getFieldNameAndText(item);
							const uniqueKey = `${field['zip']}-${field['city']}`;
							if (resultStrings.indexOf(uniqueKey) === -1) {
								uniqueItems.push(field);
								resultStrings.push(uniqueKey);
							}
						});
						return uniqueItems;
					}
					return [];
				// fall through
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'insurance':
				case 'advertisingconsent':
					if (data && data.content.length > 0) {
						return data.content.reduce((filtered, item) => {
							filtered.push(this.getFieldNameAndText(item));
							return filtered;
						}, []);
					}
				// fall through
				case 'maplocation':
					if (data.Data && data.Data.length > 0) {
						return data.Data.reduce((filtered, item) => {
							if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y) {
								filtered.push([T.Utils.Helper.htmlToText(item.Kurztext), item.Punkt1, T.Utils.Helper.htmlToText(item.LandKennzeichen)]);
							}
							return filtered;
						}, []);
					}
				// fall through
				case 'testingservice':
					if (data.Data && data.Data.length > 0) {
						return data.Data.reduce((filtered, item) => {
							if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y && 'D' === item.LandKennzeichen) {
								filtered.push([T.Utils.Helper.htmlToText(item.Kurztext), item.Punkt1, T.Utils.Helper.htmlToText(item.LandKennzeichen), T.Utils.Helper.htmlToText(item.Postleitzahl)]);
							}
							return filtered;
						}, []);
					}
				// fall through
				case 'vehiclekeycarbrands':
					if (data.Data && data.Data.Marken) {
						return data.Data.Marken.map((item) => {
							return [T.Utils.Helper.htmlToText(item.Name).replace(/"/g, '\''), T.Utils.Helper.htmlToText(item.Id), T.Utils.Helper.htmlToText(item.Key)];
						}, []);
					}
				// fall through
				case 'vehiclekeycarseries':
					if (data.Data && data.Data.Baureihen) {
						return data.Data.Baureihen.map((item) => {
							return [T.Utils.Helper.htmlToText(item.Name).replace(/"/g, '\''), T.Utils.Helper.htmlToText(item.Id), T.Utils.Helper.htmlToText(item.Key)];
						}, []);
					}
				// fall through
				case 'carbrands':
					if (data.Data && data.Data.Marken) {
						return data.Data.Marken.map((item) => {
							return [T.Utils.Helper.htmlToText(item.Name).replace(/"/g, '\''), T.Utils.Helper.htmlToText(item.Id)];
						}, []);
					}
				// fall through
				case 'carseries':
					if (data.Data && data.Data.Baureihen) {
						return data.Data.Baureihen.map((item) => {
							return [T.Utils.Helper.htmlToText(item.Name).replace(/"/g, '\''), T.Utils.Helper.htmlToText(item.Id)];
						}, []);
					}
				// fall through
				case 'carmodels':
					if (data.Data && data.Data.Modelle) {
						return data.Data.Modelle.map((item) => {
							let displayValue = '';
							if (item.ModellLabel && item.ModellLabel.length > 0) {
								displayValue = [T.Utils.Helper.htmlToText(item.ModellLabel).replace(/"/g, '\''), T.Utils.Helper.htmlToText(item.Id)];
							} else {
								displayValue = [T.Utils.Helper.htmlToText(item.Name).replace(/"/g, '\''), T.Utils.Helper.htmlToText(item.Id)];
							}
							return displayValue;
						}, []);
					}
				// fall through
				case 'rufdestination':
					if (data.Data && "length" in data.Data) {
						return data.Data.map((item) => {
							return [T.Utils.Helper.htmlToText(item.displayname), T.Utils.Helper.htmlToText(item.id), "", T.Utils.Helper.htmlToText(item.url)];
						}, []);
					}
				// fall through
				case 'toursetsearch':
					if (data.Data && data.Data.length) {
						return data.Data.map((item) => {
							const result = [item.AufbereiteterName, item.Id, item.GeoArt, item.Name, item.LandKennzeichen, item.LandKennzeichenIso2, item.HatToursetFamily];
							if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y) {
								result.push(item.Punkt1.X);
								result.push(item.Punkt1.Y);
							}
							return T.Utils.Helper.unprotectJson(result);
						}, []);
					}
				// fall through
				case 'toursetfilter':
					if (data.Data && data.Data.length) {
						return data.Data.reduce((filtered, item) => {
							if (item.HatTourset) {
								const result = [item.AufbereiteterName, item.Id, item.GeoArt, item.Name, item.LandKennzeichen, item.LandKennzeichenIso2, item.HatToursetFamily];
								if (item.Punkt1 && item.Punkt1.X && item.Punkt1.Y) {
									result.push(item.Punkt1.X);
									result.push(item.Punkt1.Y);
								}
								filtered.push(T.Utils.Helper.unprotectJson(result));
							}
							return filtered;
						}, []);
					}
				// fall through
				case 'ibsfilter':
					if (data.features && data.features.length) {
						return data.features.reduce((filtered, item) => {
							const countries = item.context.reduce((codearr, ctx) => {
								if (ctx.country_code) {
									codearr.push(ctx.country_code);
								}
								return codearr;
							}, []);
							const result = [item.place_name, item.id, (item.place_type || ["-"])[0], item.text, countries.length ? `KEY_COUNTRY_${countries[0]}` : '', ''];
							filtered.push(T.Utils.Helper.unprotectJson(result));
							return filtered;
						}, []);
					}
				// fall through
				default:
					console.warn(`Unknown response mode '${mode}'`);
					return data;

			}
		},
		createSuggestElements: function createSuggestElements(mode, regexp, item) {
			const matchedStringHtml = "<span class='aa-matched-string'>$1</span>";

			if ('carbrands' === mode || 'carseries' === mode || 'carmodels' === mode) {
				return `<div class="autocomplete-suggestion" data-js-val="${item[0]}" data-js-id='${item[1]}'>${item[0].replace(
					regexp, matchedStringHtml
				)}</div>`;
			}
			else if ('vehiclekeycarbrands' === mode || 'vehiclekeycarseries' === mode) {
				return `<div class="autocomplete-suggestion" data-js-val="${item[0]}" data-keyid='${item[1]}' data-tetid='${item[2]}'>${item[0].replace(
					regexp, matchedStringHtml
				)}</div>`;
			}
			else if ('mglcancellation' === mode || 'insurance' === mode || 'mglmodularsystem' === mode || 'advertisingconsent' === mode) {
				const inpputSuggestionLong = "js-suggestion--long";
				$(".js-suggestion-zip").first().addClass(inpputSuggestionLong);
				$(".js-suggestion-city").first().addClass(inpputSuggestionLong);
				const thisElement = $('.a-basic-input-text--autocomplete.js-focus').find('input')[0];
				if (thisElement && thisElement.id === 'streetandno') {
					$(".js-suggestion-streetandno").first().addClass(inpputSuggestionLong);
					const matchedStreet = `${item['streetandno']}, ${item['zip']}-${item['city']}`;
					return `<div class="autocomplete-suggestion" data-js-val="${item[thisElement.id]} " data-js-id="${thisElement.id}">${matchedStreet.replace(
						regexp, matchedStringHtml
					)}</div>`;
				}
				else if (thisElement) {
					const matchedValue = `${item['zip']}-${item['city']}`;
					return `<div class="autocomplete-suggestion" data-js-val="${item[thisElement.id]}" data-js-id="${thisElement.id}">${matchedValue.replace(
						regexp, matchedStringHtml
					)}</div>`;
				}
			}
			else if ('defaultgeotypeahead' === mode || 'tourmaillocation' === mode || 'onsitelocation' === mode || 'onsitelocationde' === mode) {
				return `<div class="autocomplete-suggestion" data-js-val="${item[0]}" data-js-point1='${JSON.stringify(item[1])}' data-js-id='${JSON.stringify(item[0])}' data-js-point2='${JSON.stringify(item[2])}' data-js-laenderkuerzel='${item[3]}'>${item[0].replace(regexp, matchedStringHtml)}</div>`;
			} else if ('testingservice' === mode) {
				const suggestion = `${item[0]}`;
				return `<div class="autocomplete-suggestion" data-js-val="${suggestion}" data-js-point1='${JSON.stringify(item[1])}' data-js-id='${JSON.stringify(item[0])}' data-js-laenderkuerzel='${item[2]}'>${suggestion.replace(regexp, matchedStringHtml)}</div>`;
			} else if ('maplocation' === mode) {
				return `<div class="autocomplete-suggestion" data-js-val="${item[0]}" data-js-id='${JSON.stringify(item[1])}' data-js-laenderkuerzel='${item[2]}'>${item[0].replace(
					regexp, matchedStringHtml
				)}</div>`;
			} else if ('rufdestination' === mode) {
				let add = item[2] || '';
				if (add) {
					add = ` (${add})`;
				}
				return `<div class="autocomplete-suggestion autocomplete-suggestion--${mode}" data-js-val="${item[0]}" data-js-id="${item[1]}" data-js-link="${item[3]}"><span class="aa-resultlist">${item[0].replace(
					regexp, matchedStringHtml
				)}${add}</span></div>`;
			} else if ('toursetsearch' === mode || 'toursetfilter' === mode || 'ibsfilter' === mode) {
				const $result = $('<div class="autocomplete-suggestion"></div>');
				$result.attr('data-js-val', item[0]);
				$result.attr('data-js-id', item[1]);
				$result.attr('data-js-type', item[2]);
				$result.attr('data-js-name', item[3]);
				$result.attr('data-js-country', item[4]);
				$result.attr('data-js-countryiso2', item[5]);
				if (item[6]) {
					$result.attr('data-js-family', true);
				}
				if (6 < item.length) {
					$result.attr('data-js-point1', item[7]);
					$result.attr('data-js-point2', item[8]);
				}
				$result.html(item[0].replace(regexp, matchedStringHtml));
				return $('<div></div>').append($result).html();
			}
		},

		handleJsonError: function handleJsonError(ctx, err) {
			/* istanbul ignore next */
			$(ctx).trigger("mapsError", [err]);
		},

		handleSelectedItem: function handleSelectedItem(e, ctx, mode, term, item) {
			/* istanbul ignore next */
			$(ctx).trigger("automcompleteSelect", [term, item, e]);
		},

		formatUrl: function formatUrl(mode, searchUrl, term) {
			switch (mode) {
				case 'vehiclekeycarseries':
				case 'vehiclekeycarbrands':
					return T.Utils.Helper.updateUrlParameter(
						{
							searchname: term,
							Status: 1,
							Repository: 1
						},
						searchUrl
					);
				case 'carbrands':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'carseries':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'carmodels':
					return T.Utils.Helper.updateUrlParameter({
						searchname: term
					}, searchUrl);
				case 'mglmodularsystem':
				case 'mglcancellation':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'insurance':
				case 'advertisingconsent':
					return T.Utils.Helper.updateUrlParameter({
						in_zip: $('.js-autocomplete-zip').val(),
						in_city: $('.js-autocomplete-city').val(),
						in_street: $('.js-autocomplete-street').val(),
					}, searchUrl);
				case 'onsitelocation':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'onsitelocationde':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'defaultgeotypeahead':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'tourmaillocation':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'testingservice':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'toursetsearch':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'toursetfilter':
				// eslint-disable-next-line sonarjs/no-duplicated-branches
				case 'maplocation':
					return T.Utils.Helper.updateUrlParameter({
						suchbegriff: term
					}, searchUrl);
				case 'rufdestination':
					return searchUrl;
				case 'ibsfilter':
					return T.Utils.Helper.updateUrlParameter({
						query: term
					}, searchUrl);
				default:
					console.warn(`Unknown response mode '${mode}'`);
					return encodeURI(searchUrl);
			}

		},
		getAjaxConfig: function getAjaxConfig($ctx) {
			this.globalSearchConfig = $ctx.data('config');

			if (this.globalSearchConfig) {
				const query = T.Utils.Helper.getUrlParameter("q");
				if (query && query.length) {
					$ctx.find(".mm-submit-search").val(query);
				}
				const result = {
					serviceUrl: this.globalSearchConfig.apiSuggestions,
					dataType: 'json',
					isAjax: true,
					triggerSelectOnValidInput: false,
					type: 'POST',
					mode: 'normal',
					params: {
						count: 8
					},
					ajaxSettings: {
						contentType: "application/json",
						crossDomain: true,
						beforeSend: function (jqXHR, setting) {
							setting.data = JSON.stringify({
								count: 8,
								query: T.Utils.Helper.getUrlParameter("query", `?${setting.data}`),
								source_id_pattern: "popularsearches",
								// eslint-disable-next-line camelcase
								property_constraint: {
									unparsed: "url:www.adac.de"
								}
							});
						}
					}
				};
				if (this.globalSearchConfig.apiSubscription) {
					result.ajaxSettings.headers = { "Ocp-Apim-Subscription-Key": this.globalSearchConfig.apiSubscription };
				}
				return result;
			} else {
				return {};
			}
		},

		setSearchValue: function setSearchValue($ctx) {
			const query = T.Utils.Helper.getUrlParameter("q");
			if (query && query.length) {
				$ctx.find(".mm-submit-search").val(query);
			}
		},

		transformResult: function transformResult(data, isAjax) {
			const resultObj = {
				searchResults: {
					results: []
				}
			};
			if (isAjax) {
				if (data.results && data.results.length > 0) {
					for (let i = 0; i < data.results.length; i++) {
						const searchItem = {
							value: data.results[i].value,
							data: {
								resultId: '',
								link: ''
							}
						};
						resultObj.searchResults.results.push(searchItem);
					}
				}
				return resultObj;
			}

			return $.isPlainObject(data) ? data : JSON.parse(data);
		},
		normalSearchValue: function normalSearchValue(searchValue) {
			if (searchValue && searchValue.length) {
				this.checkForResults(searchValue);
			}
		},
		selectSearchValue: function selectSearchValue(searchValue) {
			T.Utils.Helper.isConsentMatch("Campaigns", (consentCheck) => {
				let resultsUrl = this.globalSearchConfig.resultPageUrl;
				const campaignIds = T.Utils.Helper.getCampaignUrlParams();
				const telljaIds = T.Utils.Helper.getTelljaIdUrlParams();

				if (campaignIds.length && !consentCheck) {
					resultsUrl = T.Utils.Helper.getUrlWithIdParam(resultsUrl, campaignIds, telljaIds);
				}

				window.location.href = T.Utils.Helper.updateUrlParameter({ q: searchValue }, resultsUrl);
			});
		},
		checkForResults: function checkForResults(q) {
			const count = 1;
			const queryString = q;

			const searchWithPaging = {
				max_page_count: 1,
				query: {
					unparsed: queryString
				},
				query_context_user_query: queryString,
				content_sample_length: 1,
				count: count,
				estimated_count: count,
				alternatives_query_spelling_max_estimated_count: 50,
				alternatives: false,
				show_query_spelling_alternatives: false,
				exclude_available_properties_and_facets: true,
				result_pages: {
					page_size: count
				},
				properties: [{
					name: "title",
					formats: ["HTML"]
				}]
			};
			this._requestData(searchWithPaging);
		},
		_requestData: function _requestData(searchData) {
			if (!this.globalSearchConfig) {
				console.warn("No global search configuration found");
				return;
			}
			const options = {
				type: 'POST',
				url: this.globalSearchConfig.apiSearch,
				data: JSON.stringify(searchData)
			};
			if (this.globalSearchConfig.apiSubscription) {
				options.headers = { "Ocp-Apim-Subscription-Key": this.globalSearchConfig.apiSubscription };
			}
			T.Utils.Ajax.fragment(options, this._handleSearchResults.bind(this), () => { });
		},
		_handleSearchResults: function (data) {
			const campaignIds = T.Utils.Helper.getCampaignUrlParams();
			const telljaIds = T.Utils.Helper.getTelljaIdUrlParams();
			let resultsUrl = this.globalSearchConfig.resultPageUrl,
				noResultsUrl = this.globalSearchConfig.noResultPage,
				searchUrl;

			T.Utils.Helper.isConsentMatch("Campaigns", (consentStatus) => {
				if (campaignIds.length && !consentStatus) {
					resultsUrl = T.Utils.Helper.getUrlWithIdParam(resultsUrl, campaignIds, telljaIds);
					noResultsUrl = T.Utils.Helper.getUrlWithIdParam(noResultsUrl, campaignIds, telljaIds);
				}

				if (data && data.resultset && data.resultset.results && data.resultset.results.length > 0) {
					searchUrl = T.Utils.Helper.updateUrlParameter({ q: data.search_request.query.unparsed }, resultsUrl);
					window.location.href = searchUrl;
				} else {
					searchUrl = T.Utils.Helper.updateUrlParameter({ q: data.search_request.query.unparsed }, noResultsUrl);
					window.location.href = searchUrl;
				}
			});
		},
		/**
		 * Finds best matched item from last autocomplete dropdown displayed.
		 * @param {JQuery} autocomplete - autocomplete field for best match to look up
		 */
		getLastBestMatchSuggestion: function getLastBestMatchSuggestion(autocomplete, dropdownMustShow) {
			if (autocomplete.val()) {
				let $elm = autocomplete.parent().find(".ll-noresult");
				if (!$elm.is(":visible")) {
					$elm = $(`.js-suggestion-${autocomplete.attr("id")}`);
					if (!dropdownMustShow || $elm.is(":visible")) {
						return $elm.find(".autocomplete-suggestion").first();
					}
				}
			}
			return null;
		},
		getFieldNameAndText: function getFieldNameAndText(data) {
			const textAndField = [];
			const order = ["street", "zip", "city"];
			for (let j = 0; j < order.length; j++) {
				let name = order[j];
				name = /^out_/.test(name) ? name : `out_${name}`;
				if (data[name] && data[name].length > 0) {
					order[j] = (order[j] === 'street') ? 'streetandno' : order[j];
					textAndField[order[j]] = data[name];
				}
			}
			return textAndField;
		}
	};
})(jQuery);