(function ($)
{
    'use strict';
    /**
     * BasicCompareTeaser module implementation.
     *
     * @author  <>
     * @namespace T.Module
     * @class BasicCompareTeaser
     * @extends T.Module
     */
    T.Module.BasicCompareTeaser = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {jQuery} */
        $swiperBtnLeft: null,

        /** @type {jQuery} */
        $swiperBtnRight: null,

        /** @type {jQuery} */
        $swiperPagination: null,

        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$swiperContainer = this.$ctx.find('.swiper');
            this.$swiperBtnLeft = this.$ctx.find('.swiper-button-prev');
            this.$swiperBtnRight = this.$ctx.find('.swiper-button-next');
            this.$swiperPagination = this.$ctx.find('.swiper-pagination');
            this.ariaLabelAttribute = 'aria-label';
            this.swiperBtnLeftAriaLabelText = this.$swiperBtnLeft.attr(this.ariaLabelAttribute);
            this.swiperBtnRightAriaLabelText  = this.$swiperBtnRight.attr(this.ariaLabelAttribute);

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperContainer.find('.swiper-slide'), true);

            resolve();
        },


        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate(target, dataObj)// eslint-disable-line
        {
            // init swipe
            this.initSwipe();
        },

        /**
         * init swipe
         */
        initSwipe: function ()
        {
            const simulateTouch = true,
                keyboardControl = true;

            if (this.$swiperContainer.find('.swiper-wrapper').children().length > 3)
            {
                this.$swiperContainer.addClass('is-multiple');
            }

            // init swiper container
            const mySwiper = new Swiper(this.$swiperContainer[0], {
                spaceBetween: 0,
                slidesPerView: 'auto',
                simulateTouch: simulateTouch,
                speed: 1500,
                preloadImages: false,
                watchSlidesProgress: true,
                keyboard: {
                    enabled: keyboardControl,
                },
                navigation: {
                    prevEl: this.$swiperBtnLeft[0],
                    nextEl: this.$swiperBtnRight[0],
                },
                lazy: {
                    checkInView: true,
                    loadPrevNextAmount: 2,
                    loadPrevNext: true,
                    loadOnTransitionStart: true,
                },
				on: {
					init: (swiper) =>
					{
						S.Utils.SwiperHelper.moveFocusedLinkIntoView(swiper, this.$ctx);
					}
				}
            });

            // bugfix: set aria-labels here again, because swiper sometimes overwrites it
            this.$swiperBtnLeft.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
            this.$swiperBtnRight.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);

            // dont know what this does, kept the old logic
            if (typeof this._sandbox.swiperInstance === 'undefined')
            {
                this._sandbox.swiperInstance = {};
            }

            this._sandbox.swiperInstance[$(this._ctx).data('t-id')] = mySwiper;
        },
    });
}(jQuery));
