'use strict';
(function () {
	/**
	 * Award decorator implementation for the vescalculatorshort component.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class Rrv
	 * @extends T.Module
	 */
	T.Module.VesCalculatorShort.RrvApi = T.createDecorator({
		/** @type {jQuery} */
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			// Uncomment this to make calculation in git-prototype consistent for first view after pageload
			// this.insurance=1000;

			this._parent.start(resolve);
		},

		dummy: function () {

		},


	});
}());
