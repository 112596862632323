(function ($) {
	'use strict';
	/**
	 * BasicRatingEkomi module implementation.
	 *
	 * @author Stefan Vogt <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicRating
	 * @extends T.Module
	 */
	T.Module.BasicRating.Ekomi = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {String} */
		json: '',

		/** @type {String} */
		api: '',

		/** @type {Number} */
		thisRating: 0.00,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.api = this.api || $('meta[data-key="backend"]').attr('data-value');

			if (typeof this.api !== 'undefined') {
				this.json = this.api + this.$ctx.data('apigateway');
			}
			else {
				this.json = this.$ctx.data('apigateway');
			}

			this.getData();

			this._parent.start(resolve);
		},


		/*
		 * getData
		 */
		getData() {
			const that = this,
				sealBronze = this.$ctx.data('seal-bronze'),
				sealSilber = this.$ctx.data('seal-silber'),
				sealGold = this.$ctx.data('seal-gold');
			let sealimg = '';

			$.getJSON(that.json, function (data) {
				if (data.Data['DurchschnittBewertungen'] >= 4.8 && data.Data['AnzahlBewertungen'] >= 50) {
					sealimg = sealGold;
				} else if (data.Data['DurchschnittBewertungen'] >= 4.4 && data.Data['DurchschnittBewertungen'] <= 4.7 && data.Data['AnzahlBewertungen'] >= 50) {
					sealimg = sealSilber;
				} else if (data.Data['DurchschnittBewertungen'] >= 4.0 && data.Data['DurchschnittBewertungen'] <= 4.3 && data.Data['AnzahlBewertungen'] >= 50) {
					sealimg = sealBronze;
				} else {
					sealimg = 'https://www.ekomi.de/widgets/generate/de/keinsiegel.png';
				}
				that.$ctx.find('.mm-ekomi-logo img').attr('src', sealimg);

				// recommendation
				that.$ctx.find('.mm-quota > .mm-recommendation').first().html(data.Data['DurchschnittBewertungen']);
				that.thisRating = Number(data.Data['DurchschnittBewertungen']);

				// reviews
				that.$ctx.find('.mm-reviews > span').html(data.Data['AnzahlBewertungen']);

				// set stars
				that.setEkomiStars();

			}).done(function () {
				// remove loader
				that.$ctx.addClass('is-loaded');

			}).fail(function () {
				// remove loader
				that.$ctx.addClass('is-loaded');

				// hide valid box
				that.$ctx.find('.mm-valid').addClass('h-none');

				// show fail box
				that.$ctx.find('.mm-fail').removeClass('h-none');
			});
		},

		/*
		 * setEkomiStars
		 */
		setEkomiStars() {
			const that = this,
				stars = this.$ctx.find('.mm-star-container .mm-star--ekomi');

			stars.each(function () {
				if (that.thisRating >= 1) {
					$(this).addClass('mm-star--ekomi-full');
				} else if (that.thisRating > 0) {
					$(this).addClass(`mm-star--ekomi-${that.thisRating.toFixed(2).substring(2)}`);
				}
				that.thisRating -= 1;
			});
		},
	});
}(jQuery));
