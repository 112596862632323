(function ($) {
    'use strict';

    /**
     * sticky decorator implementation for the BasicStatusNav module.
     *
     * @author  <w.eberts@edelweiss72.de>
     * @namespace T.Module.BasicStatusNav
     * @class Sticky
     * @extends T.Module
     */
    T.Module.BasicStatusNav.Sticky = T.createDecorator({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {Number} */
        stickyPos: 0,

        start: function (resolve) {
            this.$ctx = $(this._ctx);

            if (S.Utils.Helper.mq('desktop').matches) {
                this.setSticky(106);
                this.stickyPos = 106;
            } else {
                this.setSticky(0);
            }

            $(window).on('resize', () => {
                S.Utils.delayed('checkMqStatusNav', 200, () => {
                    if (S.Utils.Helper.mq('desktop').matches) {
                        if (this.stickyPos !== 106) {
                            this.$ctx.trigger('sticky_kit:detach');
                            this.setSticky(106);
                            this.stickyPos = 106;
                        }
                    }
                    else {
                        if (this.stickyPos !== 0) {
                            this.$ctx.trigger('sticky_kit:detach');
                            this.setSticky(0);
                            this.stickyPos = 0;
                        }
                    }
                });
            });

            this._parent.start(resolve);
        },

        setSticky: function (offset) {
            this.$ctx.stick_in_parent({
                sticky_class: 'is-stuck',
                offset_top: offset
            });
        }
    });
}(jQuery));
