(function ($)
{
    'use strict';

    /**
     * BasicInputTextarea module implementation.
     *
     * @author  <>
     * @namespace T.Module
     * @class BasicInputTextarea
     * @extends T.Module
     */
    T.Module.BasicInputTextarea = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} $neededStickyFooter*/
        $neededStickyFooter: null,

        /** @type {jQuery} $descwrapper */
		$descwrapper: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$input = this.$ctx;
            this.$neededStickyFooter = $('.m-basic-form-footer-sticky');
            this.$descwrapper = this.$ctx.next('.ll-description');
			this.$descwrapperNewForm = this.$ctx.parent().find('.ll-description');
			this.formGeneral = '.l-form-general';

            const that = this;

			// validate on modification
			this.$ctx.off('change.basicInputTextarea keyup.basicInputTextarea').on('change.basicInputTextarea keyup.basicInputTextarea', (e) => {
				that.validate(e.which);
			});

            this.$input.on('focus', function(){
                // check if there is sticky footer first
                if (that.$neededStickyFooter.length) {
					that.handleInputOnClickForStickyElements(true, that.$neededStickyFooter);
				}

                $(this).addClass('js-focus');
            });
            this.$input.on('focusout', function(){

                // check if there is sticky footer first
                if (that.$neededStickyFooter.length) {
					that.handleInputOnClickForStickyElements(false, that.$neededStickyFooter);
				}

                $(this).removeClass('js-focus');
            });

            this.countTextareaCharacters();


            resolve();
        },


		validate: function ()
		{
			const value = this.$ctx.val(),
				clearBtn = '.js-clear-button';

			if (value !== '' && !this.$ctx.closest('.a-basic-input-textarea').hasClass('js-clear-disabled')) {
				this.$ctx.addClass('js-clear a-basic-input-textarea--clear');

				if (!this.$ctx.siblings(clearBtn).length){
					if(this.$ctx.closest(this.formGeneral).length > 0) {
						this.$ctx.after('<div class="js-clear-button" tabindex="0"><span></span></div>');
					}
					else {
						this.$ctx.after('<div class="js-clear-button"><span></span></div>');
					}

					this.$ctx.siblings(clearBtn).on('click touch', (event) => {
						event.preventDefault();
						this.clearTextarea(true);
					});

					this.$ctx.siblings(clearBtn).on('keydown', (event) => {
						event.preventDefault();

						// on ENTER
						if (event.which === 13) {
							this.clearTextarea(true);
						}
					});
				}
			}
			else {
				this.$ctx.removeClass('js-clear a-basic-input-textarea--clear');

				if(!$(this.formGeneral)) {
					this.$ctx.siblings(clearBtn).remove();
				}
			}
		},

		/**
		 * clearTextarea
		 *
		 * clear textarea
		 * @param {Boolean} focus
		 */
		clearTextarea(focus)
		{
			this.$ctx.removeClass('js-clear a-basic-input-textarea--clear is-hovered').val('').trigger('change');
			this.$ctx.closest('.ll-row').removeClass('is-valid');

			// emit reset
			if (this.$ctx.closest('.m-basic-keywordfilter').length)
			{
				this._events.emit('BasicKeywordfilter.reset');
			}

			if (focus) {
				this.$ctx.focus();
			}
		},


        /**
         * handles the hiding process for the sticky navi with content
         * @param {Boolean} hide -> true | show -> false
         * @param {jQuery} $neededStickyFooter
         *                      - its better to do the query in each inputModule and deliver it to the HideStickContent,
         *                        because u need to check for a given sticky module to call this function - to prevent unecassery function calls
         *                      - so u got the element here already, than just transfer it to the HideStickContent
         */
		// eslint-disable-next-line
        handleInputOnClickForStickyElements(hide = false, $neededStickyFooter) //NOSONAR Parameter Order
        {

            if (hide) {
                S.Utils.HideStickyContent.handleStickyContent(this.$ctx, $neededStickyFooter);

                return;
            }

            S.Utils.HideStickyContent.showStickyFooter($neededStickyFooter);
        },

        /**
         * counts the characters in a Textarea and displays
         * the maximum amount left
         */
        countTextareaCharacters: function(){
			const textareaCount = '.js-textarea-count';

			if(typeof this.$descwrapper !== 'undefined' && this.$descwrapper.length || typeof this.$descwrapperNewForm !== 'undefined' && this.$descwrapperNewForm.length && this.$descwrapper.children(textareaCount).length || this.$descwrapperNewForm.children(textareaCount).length) {
				const descriptionCounter = this.$descwrapper.children(textareaCount);
				const descriptionCounterNewForm = this.$descwrapperNewForm.children(textareaCount);
				(descriptionCounter, descriptionCounterNewForm).each( (index, item) => {
                        // textMax defined by initial string in span.js-textarea-count (e.g. "125")
                        const textMax = $(item).html(),
							textarea = this.$ctx;

						textarea.on('focus', () =>
						{
							if($(item).closest(this.formGeneral).length > 0 && $(textarea).val().length === 0) {
								$(item).html(`0 / ${  textMax}`);
							}
						});

                        // when a button is pressed in a textarea
                        textarea.on('keydown', () =>
						{
                            setTimeout(() =>
							{
                                const value = $(textarea).val(),
                                    textLength = value.length;

								if($(item).closest(this.formGeneral).length > 0)
								{
									if (textLength > textMax)
									{
										const dif = textLength - textMax; // NOSONAR
										$(textarea).val(value.substring(0, value.length - dif));
										$(item).html(`${textMax} / ${textMax}`);
									}
									else
									{
										$(item).html(`${textLength  } / ${  textMax}`);
									}
								}
								else
								{
									if (textLength > textMax)
									{
										const dif = textLength - textMax; // NOSONAR
										$(textarea).val(value.substring(0, value.length - dif));
										$(item).html(0);
									}
									else
									{
										$(item).html(textMax - value.length); // NOSONAR
									}
								}
                            }, 0);

                        });
                    });
            }
        },
    });
}(jQuery));
