/// <reference path="../../../../../assets/typings/terrific-ext.d.ts" />
/// <reference path="../../../../../assets/js/utils/T.Utils.Helper.js" />

'use strict';
(function ($) {
	/**
	 * Conditional Back Link
	 *
	 * @author Dmitri Zoubkov <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class BasicLink-ConditionalBack
	 * @extends T.Module.BasicLink
	 */
	T.Module.BasicLink.ConditionalBack = T.createDecorator({
		/**
		 * start function
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this._parent.start(resolve);
			this.addEventListeners();
		},

		addEventListeners: function addEventListeners() {
			const referrer = this.getReferrer();
			if (referrer) {
				this.$ctx.attr('href', referrer);
				this.$ctx.on('click', this.onClick.bind(this));
			}
		},

		onClick: function onClick(e) {
			e.preventDefault();
			window.location.href = this.$ctx.attr('href');
			return false;
		},

		getReferrer: function getReferrer() {
			const href = this.$ctx.length ? this.$ctx[0].href : "";
			if (href && document.referrer && document.referrer !== href && document.referrer !== window.location.href) {
				const pfx = T.Utils.Helper.commonPrefix([href, document.referrer]);
				if (pfx && 8 < pfx.length) {
					return document.referrer;
				}
			}
			return "";
		}
	});
}(jQuery));