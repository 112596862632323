(function ($) {
	'use strict';
	/**
	 * BasicTop.Conditional module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class BasicTop.Conditional
	 * @extends T.Module.BasicTop
	 */

	T.Module.BasicTop.Conditional = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,
		/**
		 * @param resolve {Function}
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			if (this.isRestricted()) {
				// do something?
			} else {
				this._parent.start(resolve);
			}
		},

		isRestricted: function () {
			// eslint-disable-next-line eqeqeq
			return "true" == this.$ctx.data("restricted");  //NOSONAR
		}
	});
}(jQuery));
