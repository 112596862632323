'use strict';
(function ($) {
	/**
	 * SelectboxRouting module implementation.
	 *
	 * @author  <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class SelectboxRouting
	 * @extends T.Module
	 * @deprecated - use BasicBoxRouting.Select instead
	 */
	T.Module.SelectboxRouting = T.createModule({

		$ctx: null,

		start(resolve) {
			this.$ctx = $(this._ctx);
			const that = this;

			// interaction boxes
			this.$boxFormSelectButton = this.$ctx.find('.a-basic-input-select').parent().find(".a-basic-btn");
			this.$boxFormSelectButton.on('click', function (e) {
				e.preventDefault();
				that.boxOpenSelection($(e.currentTarget));
			});

			resolve();
		},

		/**
		 * Opens the value of the currently selected option from the selection the provided button belongs to.
		 * If data-target of the option is set to "_blank", the link value is opened in a new window / tab.
		 */
		boxOpenSelection: function ($button) {
			const $selectedOption = $button.parent().find('option:selected');
			const url = $selectedOption.attr('value');
			const target = $selectedOption.data('target');
			if (url && target) {
				window.open(url, target);
			} else if (url) {
				window.open(url, "_self");
			}
		}
	});
}(jQuery));
