'use strict';
(function ($) {
	/**
	 * EnsureUserAuth module implementation.
	 * On content pages a condition can be set and / or checked.
	 * This mechanism can be used to ensure that a page has been visited before another page is shown.
	 *
	 * @author  <marc.radziwill@emea.merkleinc.com>
	 * @namespace T.Module
	 * @class BasicSearchInput
	 * @extends T.Module
	 */
	T.Module.BasicSearchInput = T.createModule({

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start(resolve) {
			this.$ctx = $(this._ctx);

			this.message = this.$ctx.data('invalidmsg');
			this.$ctx.on('invalid', this.handleInvalidEvent.bind(this));
			resolve();
		},

		handleInvalidEvent(event) {
			event.target.setCustomValidity(this.message);
		}

	});
}(jQuery));

