'use strict';
(function ($) {
	/**
	 * CarInsuranceTelljaCampaignFinish module implementation.
	 *
	 * @author  <stefan.pirkl@gks.adac.de>
	 * @namespace T.Module
	 * @class CarInsuranceTelljaCampaign
	 * @extends T.Module
	 */
	T.Module.CarInsuranceTelljaCampaignFinish = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			resolve();
			this._callFinishApi();
		},
		_callFinishApi() {
			const api = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.$ctx.data('api-finish'));
			const hergang = T.Utils.Helper.getUrlParameter(this.$ctx.data('finish-param'));
			if (hergang) {
				const options = {
					type: 'POST',
					url: api,
					data: { "Id": hergang },
					xhrFields: {
						withCredentials: true
					},
					contentType: "application/x-www-form-urlencoded;charset=utf-8"
				};
				T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
			}
		},
		_successCallback: function _successCallback(data) {
			if (data.Data && data.Data.ProcessId && data.Data.TippgeberId) {
				this._generatePixel(data.Data.ProcessId, data.Data.TippgeberId);
			}
		},
		_errorCallback: function _errorCallback() {
			//don't show an error
		},
		_generatePixel(processId, tippgeberId) {
			let imgUrl = this.$ctx.data('campaign-url');
			imgUrl = imgUrl.replace('{orderid}', encodeURIComponent(processId));
			imgUrl = imgUrl.replace('{telljaid}', encodeURIComponent(tippgeberId));
			$('<img />').attr({
				'src': imgUrl,
			}).appendTo(this.$ctx);
		}
	});
}(jQuery));
