(function ($)
{
    'use strict';
    /**
     * BasicTeaserWrapper module implementation.
     *
     * @author Tobias <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class BasicTeaserWrapper
     * @extends T.Module
     */
    T.Module.BasicTeaserWrapper = T.createModule({

        /** @type {jQuery} */
        $module: null,

        /** @type {jQuery} $teaserWrapper */
        $teaserWrapper: null,

        /** @type {jQuery} $teaserInfoWrapper */
        $teaserInfoWrapper: null,

        /** @type {Number} moduleId */
        moduleId: 0,

        /** @type {Number} heighestValue */
        heighestValue: 0,

        /** @type {jQuery} $teaserBoxes */
        $teaserBoxes: null,

        /** @type {jQuery} $teaserBoxesContent */
        $teaserBoxesContent: null,

        /** @type {jQuery} $teaserInfoButtons */
        $teaserInfoButtons: null,

        /** @type {jQuery} $contentToHide */
        $contentToHide: null,

        start(resolve)
        {
            this.$module = $(this._ctx);
            this.moduleId = this.$module.data('tId');

            // get needed variables
            this.$teaserWrapper = this.$module.find('.mm-teaser-wrapper');
            this.$teaserBoxes = this.$teaserWrapper.find('.mm-teaser-box');
            this.$teaserBoxesContent = this.$teaserBoxes.find('.mm-teaser-content');
            this.$teaserInfoWrapper = this.$teaserBoxes.find('.mm-teaser-info-wrapper');
            this.$teaserInfoButtons = this.$teaserInfoWrapper.find('.mm-info-button');

            // initial scroll to top - to prevent if the user reloads to be in the middle of the content
            this.calculateButtonHeight();

            // init resize
            this.resize();

            // Deactivated by LM [AC-405]
            // // if hideMode is activated - hide the specific content
            // if (this.$teaserWrapper.hasClass('mm-hide-content'))
            // {
            //     this.getContentPosition();
            //
            //     // init scrollevent
            //     this.initScrollEvent();
            // }

            resolve();
        },

        initScrollEvent()
        {
            $(window).scroll(() =>
            {
                const scrollPos = $(document).scrollTop();

                // get the postions
                this.getContentPosition(scrollPos);
            });
        },

        /**
         * calculates the heighest $teaserInfoWrapper and
         * sets it to all to be on line with each other
         */
        calculateButtonHeight()
        {
            // only for product-teaser
            if (this.$teaserInfoButtons.length)
            {
                if (!S.Utils.Helper.mq('mobile').matches) {

                    if (this.$module.hasClass('m-basic-teaser-wrapper--product')) {

                        // reset all
                        this.$teaserInfoButtons.removeAttr('style');
                        this.heighestValue = 0;

                        // check each wrapper if its the heighest
                        this.$teaserInfoButtons.each((index, element) =>
                        {
                            if (this.heighestValue < element.offsetHeight)
                            {
                                this.heighestValue = element.offsetHeight;
                            }
                        });
                    }

                    // sets the heighest value
                    this.$teaserInfoButtons.removeClass('is-hidden').css('min-height', this.heighestValue);

                    return;
                }

                // reset all
                this.$teaserInfoButtons.removeClass('is-hidden').removeAttr('style');
            }
        },

        /**
         * go through all teaserBox and compare the offset top position to the scrollPostion
         * if its higher than open yourself (only mobile
         */
        getContentPosition(scrollPosition = false)
        {
            const currentScrollPosition = scrollPosition ? scrollPosition : $(document).scrollTop();

            this.$teaserBoxes.each((index, element) =>
            {
                const $teaserContent = $(element).find('.mm-teaser-content');

                if (!$teaserContent.hasClass('is-shown') && currentScrollPosition >= $(element).offset().top)
                {
                    this.scrollToPosition(element, $teaserContent);
                }
            });
        },

        /**
         * scroll to function, with optional parameter
         * @param {Object} element
         * @param {jQuery} $teaserContent
         */
        scrollToPosition(element, $teaserContent)
        {
            // slide to
            $('html, body').stop().animate({scrollTop: ($(element).offset().top)}, 0, 'linear');

            // add slide down class
            $teaserContent.addClass('is-shown');
        },

        /**
         * reisze function
         */
        resize()
        {
            let oldWidth = $(window).width();

            $(window).on('resize', () =>
            {
                S.Utils.delayed(`basic-teaser-wrapper-${  this.moduleId}`, 200, () =>
                {
                    const newWindowWidth = $(window).width();
                    // if you dont check for this, mobile devices will trigger if you swipe over the end of the site
                    if (newWindowWidth !== oldWidth)
                    {
                        // if hideMode is activated - hide the specific content
                        if (this.$teaserWrapper.hasClass('mm-hide-content'))
                        {
                            this.getContentPosition();
                        }

                        this.calculateButtonHeight();

                        // only remove the height on mobile, so the buttons align at the bottom of the box
                        if (newWindowWidth > 768)
                        {
                            this.calculateButtonHeight();
                        }
                        else
                        {
                            this.$teaserInfoButtons.removeAttr('style');
                        }

                        oldWidth = newWindowWidth;
                    }
                });
            });
        },
    });
}(jQuery));
