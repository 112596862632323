/// <reference path="../../../../assets/typings/terrific-ext.d.ts" />

'use strict';
(function ($) {
	/**
	 * BasicMemberSingup module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @memberof T.Module
	 * @class BasicMemberSingup
	 * @extends T.Module
	 */
	T.Module.BasicMemberSingup = T.createModule({
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this._readConfig();
			this._registerListeners();
			resolve();
		},
		_readConfig: function _readConfig() {
			this.apiRetrieveregisterjwt = this.$ctx.data('api-retrieveregisterjwt');
			this.evtSuccess = this.$ctx.data('evt-success') || "BasicMemberSingup.Success";
			this.evtError = this.$ctx.data('evt-error') || "BasicMemberSingup.Error";
			this.externalBind = this.$ctx.data('bind-external');
		},
		_registerListeners: function _registerListeners() {
			this._getForm().on('submit', this._sumbit.bind(this));
			this.$ctx.find('.js-register-submit').on('click', this._sumbit.bind(this));
			if (this.externalBind) {
				$(this.externalBind).on('click', this._sumbit.bind(this));
			}
		},
		_sumbit: function _sumbit(e) {
			if (e) {
				e.preventDefault();
			}
			this._showError(false);
			const $form = this._getForm();
			if ($form.valid()) {
				T.Utils.View.startLoader();
				const req = {
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.apiRetrieveregisterjwt),
					data: $form.serialize()
				};
				T.Utils.Ajax.fragment(req, this._handleJwtTokenSucces.bind(this), this._handleError.bind(this));
			}
		},
		_handleJwtTokenSucces: async function _handleJwtTokenSucces(data) {
			T.Utils.View.stopLoader();
			$(document).trigger(this.evtSuccess);
			if (data && data.Data) {
				const authUrl = await T.Utils.Auth.makeRegisterURL({ id_token_hint: data.Data });
				if (this.externalBind) {
					const $elm = $(this.externalBind);
					if ($elm.attr('href')) {
						$elm.attr('href', authUrl);
					}
				}
				window.location.href = authUrl;
			}
		},
		_handleError: function _handleError(jqxhr) {
			T.Utils.View.stopLoader();
			T.Utils.Logger.log(`Error ${jqxhr.statusText} requesting JWT Token from ${this.apiRetrieveregisterjwt}?${this._getForm().serialize()}`);
			this._showError(true);
			if (jqxhr.responseJSON && jqxhr.responseJSON.Messages) {
				$(document).trigger(this.evtError, jqxhr.responseJSON.Messages);
			} else {
				$(document).trigger(this.evtError);
			}
		},
		_showError: function _showError(show) {
			this.$ctx.find('.js-register-error').toggleClass('h-hidden', !show);
		},
		_getForm: function _getForm() {
			return this.$ctx.find(".js-register-form form");
		}
	});
}(jQuery));