/**
 * AccordionTracking component
 *
 * @author  <riccardo.jung@merkle.com>
 * @class AccordionTracking
*/

// eslint-disable-next-line no-undef
class AccordionTracking extends BasisComponent {

	_connectedCallback() {
		T.Utils.Application.init();
		this.$ctx = jQuery(this);

		this.readConfig();
		if (this.eventAction && this.eventCategory && this.eventLabel) {
			this.registerListeners();
		}
	}

	_disconnectedCallback() {
		jQuery(document).off('BasicToggleOpenTracking', this.handleTrackingEvent.bind(this));
	}

	readConfig() {
		this.config = this.$ctx.data('config') || {};
		this.eventAction = this.config.EventAction;
		this.eventCategory = this.config.EventCategory;
		this.eventLabel = this.config.EventLabel;
		this.product = this.config.Product;
		this.funnelStep = this.config.FunnelStep;
		this.funnelStepContent = this.config.FunnelStepContent;
	}

	registerListeners() {
		jQuery(document).on('BasicToggleOpenTracking', this.handleTrackingEvent.bind(this));
	}

	handleTrackingEvent(e, obj) {
		if (obj.closest('accordion-tracking') === this) {
			this.pushTrackingData(obj);
		}
	}

	pushTrackingData(obj) {
		const accordionText = jQuery(obj).find('strong').text() || '';

		const eventData = {
			eventCategory: this.eventCategory,
			eventAction: this.eventAction,
			eventLabel: accordionText.trim()
		};

		if (this.product) {
			eventData.product = this.product;
		}
		if (this.funnelStep) {
			eventData.funnelStep = this.funnelStep;
		}
		if (this.funnelStepContent) {
			eventData.funnelStepInhalt = this.funnelStepContent;
		}

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});

	}
}
window.customElements.define('accordion-tracking', AccordionTracking);