'use strict';
(function ($) {
	/**
	 * EkomiReviewLinkButton module implementation.
	 *
	 * @author Ascher Barbara <barbara.ascher@gks.adac.de>
	 * @namespace T.Module
	 * @class EkomiReviewLinkButton
	 * @extends T.Module
	 */
	T.Module.EkomiReviewLinkButton = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.api = this.$ctx.data('api-url');
			T.Utils.Application.init();
			this._addEkomiListener();
			resolve();
		},

		_addEkomiListener: function _addEkomiListener() {
			this.$ctx.find('.js-ekomi-button').on('click', this._getEkomiLink.bind(this));
		},

		_getEkomiLink: function _getEkomiLink(e) {
			e.preventDefault();
			if (this.ekomiLink) {
				window.open(this.ekomiLink);
			} else {
				const url = T.Utils.Helper.appendURIPath(window.location.origin, this.api);
				const options = {
					url: url,
					xhrFields: {
						withCredentials: true
					},
					type: 'GET'
				};
				T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
			}
		},

		_successCallback: function _successCallback(jqxhr) {
			if (jqxhr.Data && jqxhr.Data.Link) {
				this.ekomiLink = jqxhr.Data.Link;
				this.$ctx.find('#ekomierror').addClass('h-hidden');
				window.open(jqxhr.Data.Link);
			}
		},

		_errorCallback: function _errorCallback() {
			this.$ctx.find('#ekomierror').removeClass('h-hidden');
		},

	});
}(jQuery));