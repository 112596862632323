'use strict';
(function ($) {
	/**
	 * BasicRichText module implementation.
	 *
	 * @author Hauke Lüneburg <hauke.lueneburg@emea.merkleinc.com>
	 * @namespace T.Module
	 * @class BasicRichText
	 * @extends T.Module
	 */
	T.Module.BasicRichText = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();

			this._transformText();

			resolve();
		},

		_transformText: function _transformText() {
			this.$ctx.html(this.$ctx.text().replaceAll('\r\n', '<br />'));
		}
	});
}(jQuery));
