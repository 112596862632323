(function ($)
{
	'use strict';

	/**
	 * BasicBigLink module implementation.
	 *
	 * @author  <b.kowatsch@mgo360.de>
	 * @namespace T.Module
	 * @class BasicBigLink
	 * @extends T.Module
	 */
	T.Module.BasicBigLink = T.createModule({

		/** @type {jQuery} */
		$ctx: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve)
		{
			this.$ctx = $(this._ctx);

			resolve();
		}

	} );
}(jQuery));
