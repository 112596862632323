/**
 * InsuranceCooperationEmail component
 *
 * @author  <riccardo.jung@merkle.com>
 * @class InsuranceCooperationEmail
*/

// eslint-disable-next-line no-undef
class InsuranceCooperationEmail extends BasisComponent {

	_connectedCallback() {
		T.Utils.Application.init();
		this.$ctx = jQuery(this);

		this.readConfig();
		this.registerListeners();
		this.initValidation();
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.$ctx.find('.js-submit').off('click', this.submitForm.bind(this));
		}
	}

	readConfig() {
		this.$form = this.$ctx.find('form');
		this.config = this.$ctx.data('config') || {};
		this.insuranceApplicationLink = this.config.linkInsuranceApplication;
		this.validationMessages = this.config.validationMessages ?? {};

		this.$form = this.$ctx.find('form');
		this.emailSessionKey = "voucherMail";
	}

	registerListeners() {
		this.$ctx.find('.js-submit').on('click', this.submitForm.bind(this));
	}

	initValidation() {
		const validationOptions = {
			messages: this.validationMessages,
			rules: {
				"email": {
					required: true,
					email: true
				},
				"confirmsubmit": {
					required: true
				}
			}
		};

		T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
	}

	submitForm(event) {
		event.preventDefault();
		if (this.$form.valid() && T.Utils.Helper.isNullUndefinedOrEmpty(this.insuranceApplicationLink)) {
			this.setEmailToStore();
			T.Utils.Helper.routeToUrl(this.insuranceApplicationLink);
		}
	}

	setEmailToStore() {
		const email = this.$ctx.find('#email').val();
		T.Utils.Store.set(this.emailSessionKey, email, T.Utils.Store.SESSION);
	}
}
window.customElements.define('insurance-cooperation-email', InsuranceCooperationEmail);