'use strict';
(function ($) {
	/**
	 * WhatsappModifier module implementation.
	 *
	 * @author  <stefan.aufderheide@adac.de>
	 * @namespace T.Module
	 * @class WhatsAppModifier
	 * @extends T.Module
	 */
	T.Module.WhatsAppModifier = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/**
		* start.
		*/
		start: function () {
			this.$ctx = $(this._ctx);
			const isAppleMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
			const isAndroid = /Android/i.test(navigator.userAgent);
			if (!isAppleMobile && !isAndroid) {
				const $anchor = this.$ctx.find('.shariff-button.whatsapp').find('a');
				if ($anchor.length > 0) {
					let href = $anchor.attr('href');
					if (href) {
						href = href.replace('whatsapp://send?text=', 'https://wa.me/?text=');
					}
					$anchor.attr('href', href);
				}
			}
		},

	});
}(jQuery));
