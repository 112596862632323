'use strict';
(function ($) {
	/**
	 * PoiFuelPriceSearch module implementation.
	 *
	 * @author Igor Iric <igor.iric@namics.com>
	 * @namespace T.Module
	 * @class PoiFuelPriceSearch
	 * @extends T.Module
	 */
	T.Module.PoiFuelPriceSearch = T.createModule({
		STORED_SEARCH_KEY: "PoiFuelPriceSearch.storedSearch",
		SESSIONKEY_FUELTYPE: "Application.GasStationHotlist.FuelType",
		/** @type {jQuery} */
		$ctx: null,
		_initialLoad: false,
		currentPageIndex: 0,
		loadedItems: [],
		typeaheadSelection: null,
		perimeter: null,
		perimeterChange: false,
		fuelTypeChange: false,
		apimMapStyles: '',
		_fuelTypes: [],
		_hotlist: [],
		_isOnHotlistClass: ".js-is-on-hotlist",
		_addToHotlistClass: ".js-add-to-hotlist",
		_$iconActive: null,
		_$iconInActive: null,
		_pinActiveClass: "mm-pin-yellow",
		_mapResultCache: [],
		_bounds: [],
		_popupConfig: { offset: 10, maxWidth: "320px", anchor: '' },
		_min_coords: {
			lat: 0,
			lng: 0
		},
		_max_coords: {
			lat: 0,
			lng: 0
		},
		validationOptions: {
			rules: {
				PostleitzahlOrt: {
					required: true,
					minlength: 3
				}
			},
			messages: {
				PostleitzahlOrt: {
					minlength: "Bitte geben Sie mindestens {0} Ziffern / Buchstaben ein."
				}
			}
		},
		_isLoggedIn: false,

		start: async function start(resolve) {
			this.$ctx = $(this._ctx);

			this.$switchMapClass = this.$ctx.find('.js-switch[data-switch="map"]');
			this.$submitClass = this.$ctx.find('.js-submit');
			this.$selectedFueltype = this.$ctx.find('.js-selectedfueltype');
			this.$perimeterClass = this.$ctx.find('.js-perimeter');
			this.$fuelpriceResults = this.$ctx.find('.js-fuelpriceresults');
			this.$searchResultslist = this.$ctx.find('.js-searchresultslist');

			T.Utils.Application.init();
			this._readConfig();
			this._registerListeners();
			const isLoggedIn = await T.Utils.Auth.isLoggedIn();

			if (isLoggedIn) {
				T.Utils.Auth.getResolvedIdentity(this.handleUserData.bind(this));
			} else {
				this._tryReloadSearch();
			}

			this._changeButtonLabelToRefreshIfTriggered();
			resolve();
		},

		handleUserData: function handleUserData(user) {
			if (user && user.UserData) {
				this._requestHotlist(this._requestHotlistSuccess.bind(this), this._requestHotlistError.bind(this));
			} else {
				this._tryReloadSearch();
			}
		},

		_requestHotlist: function _requestHotlist(successCallback, errorCallback) {
			T.Utils.View.startLoader();
			T.Utils.Auth.getBearerToken((token) => {
				const requests = [];
				this._fuelTypes.forEach(element => {
					const def = $.Deferred();
					requests.push(def);

					const req = {
						type: "GET",
						url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiHotlist),
						headers: {
							Authorization: `Bearer ${token}`,
							"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey()
						},
						data: {
							spritsorte: element
						}
					};
					T.Utils.Ajax.fragment(req, (data) => {
						if (data.Success && data.Data) {
							$.map(data.Data, (value) => {
								this._hotlist.push(value.ID);
							});
						}

						def.resolve();
					}, (jqxhr) => def.reject(jqxhr));

				});
				$.when.apply($, requests).then(successCallback, errorCallback);
			});
		},

		_requestHotlistSuccess: function _requestHotlistSuccess() {
			this._tryReloadSearch();
			T.Utils.View.stopLoader();
		},
		_requestHotlistError: function _requestHotlistError() {
			this._tryReloadSearch();
			T.Utils.View.stopLoader();
		},

		_onRemoveFromHotlist: function _onRemoveFromHotlist(id, event) {
			event.preventDefault();
			T.Utils.View.startLoader();
			T.Utils.Auth.getBearerToken((token) => {
				const req = {
					type: "POST",
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiHotlistDelete),
					dataType: "json",
					headers: {
						Authorization: `Bearer ${token}`,
						"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey()
					},
					data: JSON.stringify({ ID: id })
				};

				T.Utils.Ajax.fragment(req, () => {
					$(event.currentTarget).parent().find(this._isOnHotlistClass).toggleClass("h-hidden", true);
					$(event.currentTarget).parent().find(this._addToHotlistClass).toggleClass("h-hidden", false);
					T.Utils.View.stopLoader();
				}, () => {
					this.$ctx.find(".js-error").toggleClass("h-hidden", false);
					T.Utils.View.stopLoader();
				});
			});
		},

		_onAddToHotlist: async function _onAddToHotlist(id, event) {
			event.preventDefault();
			const isLoggedIn = await T.Utils.Auth.isLoggedIn();
			if (isLoggedIn) {
				this._addToHotlistRequest(id, event);
			} else {
				this._showLoginLayer();
			}
		},

		_addToHotlistRequest: function _addToHotlistRequest(id, event) {
			T.Utils.View.startLoader();
			T.Utils.Auth.getBearerToken((token) => {
				const req = {
					type: "POST",
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiHotlistAdd),
					headers: {
						Authorization: `Bearer ${token}`,
						"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey()
					},
					data: JSON.stringify({ 'ID': id })
				};

				T.Utils.Ajax.fragment(req, () => {
					$(event.currentTarget).parent().find(this._isOnHotlistClass).toggleClass("h-hidden", false);
					$(event.currentTarget).parent().find(this._addToHotlistClass).toggleClass("h-hidden", true);
					T.Utils.View.stopLoader();
				}, () => {
					this.$ctx.find(".js-error").toggleClass("h-hidden", false);
					T.Utils.View.stopLoader();
				});
			});
		},

		_showLoginLayer: function _showLoginLayer() {
			const $dialogLogin = $('#dialog_login');
			$.magnificPopup.open({
				mainClass: 'l-lightbox',
				items: {
					src: $dialogLogin,
					type: 'inline'
				},
				callbacks: {
					beforeOpen: function () {
						// setup classes
						$dialogLogin.addClass("ll-lightbox-inner");
						this.st.mainClass = "mfp-zoom-in l-lightbox";
					}
				}
			});
		},

		_tryReloadSearch: function _tryReloadSearch() {
			if (!this._loadStoredSearchIfAny()) {
				this._getClientGeoLocation(true);
			}
		},

		_loadCorrectEntry: function _loadCorrectEntry(item) {
			if (item.Preis > 0) {
				this.loadedItems.push(item);
			}
		},
		_readConfig: function _readConfig() {
			this.itemsPerPage = this.$ctx.data("itemsperpage");
			this.mapsBasicUrl = this.$ctx.data("mapsbaseurl");
			this.detailsBasicUrl = this.$ctx.data("baseurldetails");
			this.urlSuffix = this.$ctx.data("urlsuffix");
			this._$iconActive = this.$ctx.find("#mapsIconActivePoi");
			this._$iconInActive = this.$ctx.find("#mapsIconInActivePoi");
			this._$iconYourPosition = this.$ctx.find("#mapsIconYourPosition");

			this.$queryInput = this.$ctx.find('.js-submit-search');
			this.apigeo = this.$queryInput.data("searchurl");
			T.Utils.View.setDataSafe(this.$queryInput, "searchurl", T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apigeo));
			T.Utils.View.setDataSafe(this.$queryInput, "subscription-key", T.Utils.Application.getApiMSubscriptionKey());
			this.apimPoiAzureUrl = this.$ctx.data('apimpoiazure');
			this.apireversegeocoding = this.$ctx.data("apireversegeocoding");
			this.operatorbenefitidassignment = this.$ctx.data('operatorbenefitidassignment');
			this.apiMembershipBenefitUrl = this.$ctx.data('apimembershipbenefiturl');
			this.apiHotlist = this.$ctx.data('apihotlist');
			this.apiHotlistAdd = this.$ctx.data('apihotlistadditem');
			this.apiHotlistDelete = this.$ctx.data('apihotlistdeleteitem');
			this.apimMapStyles = this.$ctx.data('apimmapboxstyle');

			this.$form = this.$ctx.find('form.js-poifuelpricesearch');
			this.validationOptions.messages = $.extend({}, this.validationOptions.messages, this.$ctx.data('validationoption'));
			T.Utils.Validation.init(this.$ctx, this.$form, this.validationOptions);

			this.$ctx.find('input[name="fuel-type"]').each((index, element) => {
				this._fuelTypes.push(element.value);
			});
		},

		_registerListeners: async function _registerListeners() {
			this.$submitClass.on('click', this._submitForm.bind(this));
			this.$form.submit(this._submitForm.bind(this));
			this.$selectedFueltype.on('click', this._handleFuelTypeChange.bind(this));
			this.$ctx.find('.js-results-filter').on('change', this._filterChanged.bind(this));
			this.$perimeterClass.on('change', this._handlePerimeterChange.bind(this));
			this.$queryInput.on('automcompleteSelect', this._handleSelection.bind(this));
			this.$ctx.find('.js-more').on('click', this._loadItemsPaging.bind(this));
			this.$ctx.find('.js-switch').on("click", this._toggleMaps.bind(this));
			$(document).on(`basicInputTextAutocomplete.suggestionShown_${this.$queryInput.data('t-id')}`, () => {
				this._setTypeaheadMatch(T.Utils.Autocomplete.getLastBestMatchSuggestion(this.$queryInput, true));
			});
			this.$queryInput.keypress((e) => { //NOSONAR
				if (13 === e.keyCode) {
					if (this._autocompletePending) {
						delete this._autocompletePending;
						return false;
					}
					this._submitForm(e);
				} else {
					delete this.typeaheadSelection;
				}
			});
			this.$ctx.find('.js-localizeme').on('click', this._getClientGeoLocation.bind(this, false));
			this.$ctx.find(".js-login").on('click', (ev) => {
				ev.preventDefault();
				T.Utils.Auth.authorize();
			}).toggleClass("h-hidden", false);

			this.$ctx.find(".js-register").attr("href", await T.Utils.Auth.makeRegisterURL());
		},

		_handlePerimeterChange: function _handlePerimeterChange() {
			this.perimeterChange = true;
			this.currentPageIndex = 0;
			this._changeButtonLabelToRefreshIfTriggered();
		},

		_handleFuelTypeChange: function _handleFuelTypeChange() {
			this.$selectedFueltype.attr("checked");
			this.fuelTypeChange = true;
			this.currentPageIndex = 0;
			this._changeButtonLabelToRefreshIfTriggered();
		},

		_handleSelection: function _handleSelection(term, item, e, origEvt) {
			if (origEvt && 'keydown' === origEvt.type && 13 === origEvt.keyCode) {
				this._autocompletePending = true;
			}
			this._setTypeaheadMatch($(e));
			this._changeButtonLabelToRefreshIfTriggered();
		},

		_setTypeaheadMatch: function _setTypeaheadMatch(elem) {
			const data = elem && elem.data ? elem.data() : undefined;
			this.typeaheadSelection = data && data.jsPoint1 ? {
				Bezeichnung: data.jsVal,
				Position1X: data.jsPoint1.X,
				Position1Y: data.jsPoint1.Y,
			} : undefined;
		},

		_loadStoredSearchIfAny: function _loadStoredSearchIfAny() {
			const storedSearch = T.Utils.Store.get(this.STORED_SEARCH_KEY, T.Utils.Store.SESSION);

			if (storedSearch && storedSearch.searchData) {
				if (undefined !== storedSearch.searchData.perimeter) {
					this.perimeter = storedSearch.searchData.perimeter;
					this.$perimeterClass.val(`${this.perimeter}`);
				}
				this.typeaheadSelection = storedSearch.typeaheadSelection || {};
				if (this.typeaheadSelection.Bezeichnung) {
					this.$queryInput.val(this.typeaheadSelection.Bezeichnung);
				}
				if (storedSearch.fuelType) {
					//replacement of whitespace - needed for Fueltype Super_E10
					this._selectFuelTypeAccordingToSessionValues(storedSearch.fuelType);
				}

				this._startApimSearch(storedSearch.searchData);
				return true;
			} else {
				const storedFuelType = T.Utils.Store.get(this.SESSIONKEY_FUELTYPE, T.Utils.Store.Session);
				if (storedFuelType) {
					this._selectFuelTypeAccordingToSessionValues(storedFuelType);
				}
				else {
					this.$ctx.find(".js-selectedfueltype input:radio").first().prop("checked", true);
				}
			}
			return false;
		},
		_storeSearch: function _storeSearch(searchData) {
			T.Utils.Store.set(this.STORED_SEARCH_KEY, {
				searchData: searchData,
				typeaheadSelection: this.typeaheadSelection,
				fuelType: this.$ctx.find('input[name="fuel-type"]:checked').val()
			}, T.Utils.Store.SESSION);
		},

		_submitForm: function _submitForm(ev) {
			ev.preventDefault();
			this.currentPageIndex = 0;
			this._mapResultCache = [];
			this.currentItem = null;
			if (this.$form.valid()) {
				const findSuccess = (data) => {
					if (data && data.length) {
						const $elm = $(T.Utils.Autocomplete.createSuggestElements(this.$queryInput.data('jsonformat'), /.*/, data[0]));
						this._setTypeaheadMatch($elm);
					}
					if (this.typeaheadSelection) {
						this._onChangeTrigger();
					} else {
						this._errorNoResults();
					}
				};
				if (this.typeaheadSelection) {
					this._onChangeTrigger();
				} else {
					this._findLocation(this.$queryInput.val(), findSuccess, this._errorNoResults.bind(this));
				}
			}
		},

		_findLocation: function _searchLocation(term, successCallback, errorCallback) {
			if (!errorCallback) {
				errorCallback = function () { };
			}
			if (!term || 3 > term.length) {
				errorCallback();
			}
			const req = {
				suchbegriff: term
			};

			T.Utils.Ajax.fragment({
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() },
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apigeo),
				data: req
			}, (data) => {
				const filtered = T.Utils.Autocomplete.mapJsonResponse(this.$queryInput.data('jsonformat'), data);
				successCallback(filtered);
			}, errorCallback);
		},

		_onChangeTrigger: function _onChangeTrigger() {
			this._showAutolocationError(false);
			this.$ctx.find(".js-noresult").toggleClass("h-hidden", true);
			this.$ctx.find(".js-error").toggleClass("h-hidden", true);
			this.$ctx.find(".js-more").toggleClass("h-hidden", false);
			this.$ctx.find(".js-fueltypeerror").toggleClass("h-hidden", true);
			this.$ctx.find(".js-eingabeerror").toggleClass("h-hidden", true);
			this.$fuelpriceResults.empty();
			if (this.typeaheadSelection.Bezeichnung) {
				this.$queryInput.val(this.typeaheadSelection.Bezeichnung);
			}
			this.loadedItems = [];
			this.currentPageIndex = 0;
			this._triggerSearchEvent();
		},

		_triggerSearchEvent: function _triggerSearchEvent() {
			const perimeter = parseInt(this.$perimeterClass.find(":selected").val());
			this.perimeter = perimeter;
			const searchData = {
				perimeter: perimeter,
				geoRectangle: this._getRequestData(perimeter)
			};
			if (this.typeaheadSelection.UserPosition) {
				searchData.userPosition = this.typeaheadSelection.UserPosition;
			}
			this._storeSearch(searchData);
			this._startApimSearch(searchData);
		},

		_startApimSearch: function _startApimSearch(searchData) {
			T.Utils.View.startLoader();

			const fuelType = this.$selectedFueltype.find(":checked").val();
			if (!fuelType) {
				this.$ctx.find(".js-fueltypeerror").toggleClass("h-hidden", false);
			} else {
				if (!this.sortBy) {
					this.sortBy = "Preis";
				}

				const param = {
					lat: searchData.geoRectangle.Position.Y,
					lon: searchData.geoRectangle.Position.X,
					sort: fuelType,
					radius: searchData.perimeter,
					pageSize: this.itemsPerPage,
					pageNumber: this.currentPageIndex + 1,
					sortBy: this.sortBy,
					sortDirection: "asc"
				};
				const apimFuelUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apimPoiAzureUrl);
				const url = T.Utils.Helper.appendURIPath(apimFuelUrl, this.urlSuffix.interpolate(
					param
				));
				const options = {
					type: 'GET',
					url: url,
					headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() },
				};
				T.Utils.Ajax.fragment(options, this._handleListSuccess.bind(this), this._errorApimNotAvailableCallback.bind(this));
			}
		},

		_handleListSuccess: function _handleListSuccess(data) {
			T.Utils.View.stopLoader();
			this.$ctx.find(".js-total-count").text(data.Data.TotalCount);
			if (data && data.Success && data.Data && data.Data.Tankstellen && data.Data.Tankstellen.length > 0) {
				if (data.Data.Tankstellen.length < this.itemsPerPage) {
					this._hideMore();
				}

				data.Data.Tankstellen.filter(this._loadCorrectEntry.bind(this));

				if (this.loadedItems.length > 0) {
					T.Utils.Auth.getResolvedIdentity(async () => {
						this._isLoggedIn = await T.Utils.Auth.isLoggedIn();
						this._showData();
					});

				} else {
					this._errorNoResults();
				}
			} else {
				if (data && data.Success && data.Data.Tankstellen.length === 0 && this.currentPageIndex > 0) {
					this._hideMore();
				} else {
					this._errorNoResults();
				}
			}
		},

		_showData: async function _showData() {
			this._hideTypeaheadDropdown();
			this.$fuelpriceResults.empty();
			this.$searchResultslist.toggleClass("h-hidden", false);
			this.$searchResultslist.attr("data-t-name", "AdacMaps");
			T.Utils.Helper.initAdditionalModules(this.$ctx);

			await this._gasStationResults(this.loadedItems, 0);
		},

		_getItemMapLocation: function _getItemMapLocation(item) {
			return item && item.x && item.y
				? this._mercatorToLocation(item)
				: null;
		},

		_setBounds: function _setBounds() {
			const lng = this._mapResultCache.map((item) => {
				return this._mercatorToLocation(item.pos)[0];
			});
			const lat = this._mapResultCache.map((item) => {
				return this._mercatorToLocation(item.pos)[1];
			});

			this._min_coords = {
				lat: Math.min.apply(null, lat),
				lng: Math.min.apply(null, lng)
			};
			this._max_coords = {
				lat: Math.max.apply(null, lat),
				lng: Math.max.apply(null, lng)
			};
			this._bounds = [[this._min_coords.lng, this._min_coords.lat], [this._max_coords.lng, this._max_coords.lat]];
		},

		_setCurrentItemFromResultList: function _setCurrentItemFromResultList() {
			if (this.currentActivePoi && this.currentItem) {
				const currentListItemId = this.currentActivePoi.attr('data-id');
				if (currentListItemId && this.currentItem.id !== currentListItemId) {
					const newCurrentItem = this._mapResultCache.filter((item) => {
						return item.id === currentListItemId;
					});
					if (newCurrentItem && newCurrentItem.length > 0) {
						this.currentItem = newCurrentItem[0];
					}
				}
			}
		},

		_showMap: function _showMap() {
			if (!this._mapResultCache.length) {
				return;
			}
			this._setBounds();

			if (!T.Utils.Helper.isSmartphone() || this.$switchMapClass.hasClass("is-active")) {

				this._createMapIfNeeded(this._bounds, () => {
					T.Utils.Mapbox.removeAllPoints(this.map);
					this._setCurrentItemFromResultList();
					this._addInactiveLocationsToMap();
					if (!this.currentItem) {
						this.currentItem = this._mapResultCache[0];
					}
					const loc = this._getItemMapLocation(this.currentItem.pos);

					this.currentItem.mapsReference = T.Utils.Mapbox.createPoint(
						this.map,
						loc,
						this._getCurrentTooltip() || null,
						this._$iconActive,
						null,
						this._popupConfig
					);
					T.Utils.Mapbox.fitBounds(this.map, this._bounds, {
						padding: { top: 25, bottom: 25, left: 25, right: 25 }
					});
				});
			} else {
				this.$switchMapClass.one('click', this._showMap.bind(this));
			}
			const $results = this.$ctx.find("#poifuelpricesearch-results");
			if ($results.length && !this.currentPageIndex) {
				S.Utils.ScrollIntoView.scroll($results[0]);
			}
		},

		_createMapItemContent: async function _createMapItemContent(fragment, gasStations, gasStationCounter, $item) {
			const isFirstResult = gasStationCounter === 0;
			const gasStation = gasStations[gasStationCounter];
			const mapItemHtmlEntry = '<br/><span/>';

			fragment.append($(mapItemHtmlEntry).text(`${gasStation.Plz} ${gasStation.Ort},${gasStation.Strasse}`));
			fragment.append($(mapItemHtmlEntry).text(gasStation.Sorte));
			fragment.append($(mapItemHtmlEntry).text(gasStation.Preis));
			fragment.append($(mapItemHtmlEntry).html($item.find(".js-provider").html()));
			fragment.append($(mapItemHtmlEntry).text(moment(`${gasStation.Datum} ${gasStation.TimeSort}`, "DD.MM.YYYY HH:mm:ss").format('DD.MM.YYYY; HH:mm')));

			const result = {
				id: `${gasStation.PoiId}`,
				html: $item,
				pos: {
					x: gasStation.x,
					y: gasStation.y
				},
				distance: gasStation.Distance,
				tooltip: fragment.html(),
				icon: isFirstResult ? this._$iconActive : this._$iconInActive
			};
			this._mapResultCache.push(result);

			this.$fuelpriceResults.append($item);

			gasStationCounter++;
			if (gasStationCounter < gasStations.length) {
				await this._gasStationResults(gasStations, gasStationCounter);
			} else {
				this._showMap();
			}
		},

		_gasStationResults: async function _gasStationResults(gasStations, gasStationCounter) {
			const isFirstResult = gasStationCounter === 0;
			const gasStation = gasStations[gasStationCounter];
			const $template = $(this.$ctx.find("#js-itemprice-template").html());
			const $item = $template.clone();
			const provider = gasStation.Provider.toLowerCase();
			$item.on("click", this._handleResultClick.bind(this, $item));

			$item.attr('data-id', gasStation.PoiId.toString());

			$item.find(".js-companyname").text(gasStation.Name);
			$item.find(".js-companyaddress").text(`${gasStation.Plz} ${gasStation.Ort},${gasStation.Strasse}`);
			$item.find('.js-map-link').attr('href', this._getRouteUrl(gasStation));
			$item.find(".js-details-link").attr('href', this._getDetailsUrl(gasStation));

			const isLoggedIn = await T.Utils.Auth.isLoggedIn();
			if (isLoggedIn && this._hotlist && this._hotlist.indexOf(gasStation.PoiId) > -1) {
				$item.find(this._isOnHotlistClass).toggleClass("h-hidden", false);
			} else {
				$item.find(this._addToHotlistClass).toggleClass("h-hidden", false);
			}

			this._addHotlistEvents($item, gasStation.PoiId);

			$item.find(".js-digits").text(gasStation.Preis);
			$item.find(".js-date").text(moment(`${gasStation.Datum} ${gasStation.TimeSort}`, "DD.MM.YYYY HH:mm:ss").format('DD.MM.YYYY; HH:mm'));
			$item.find(".js-distance").text(`${$.number(gasStation.Distance, 1, ',', '.')} km`);

			if (provider === "gibgas") {
				$item.find(".js-gibgas").toggleClass("h-hidden", false);
			} else if (provider === "mtsk") {
				$item.find(".js-mtsk").toggleClass("h-hidden", false);
			}

			if (isFirstResult) {
				$item.find(".js-icon").addClass(this._pinActiveClass);
				this.currentActivePoi = $item;
			}

			const fragment = $("<div/>");
			fragment.append($("<strong/>").text(`${gasStation.Name} `));

			this._showGasStationResult(fragment, gasStations, gasStationCounter, $item);
		},

		_addHotlistEvents: function _addHotlistEvents($item, id) {
			$item.find(this._isOnHotlistClass).on("click", this._onRemoveFromHotlist.bind(this, id));
			$item.find(this._addToHotlistClass).on("click", this._onAddToHotlist.bind(this, id));
		},

		_showGasStationResult: async function _showGasStationResult(fragment, gasStations, gasStationCounter, $item) {
			if (this._isLoggedIn) {
				const gasStation = gasStations[gasStationCounter];
				const customerBenefits = Object.keys(this.operatorbenefitidassignment);

				let hasCustomerBenefit = false;
				let customerBenefitId = '';

				customerBenefits.forEach((elem) => {
					if (gasStation.Name.toLowerCase().includes(elem.toLowerCase()) || (gasStation.Betreiber && gasStation.Betreiber.toLowerCase().includes(elem.toLowerCase()))) {
						hasCustomerBenefit = true;
						customerBenefitId = this.operatorbenefitidassignment[elem];
					}
				});

				if (hasCustomerBenefit) {
					$item.attr('data-benefit-id', customerBenefitId);
					const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.apiMembershipBenefitUrl);
					const options = {
						type: "GET",
						data: {
							angebotId: customerBenefitId
						},
						url: apiUrl
					};
					T.Utils.Ajax.fragment(options, async (dataBenefit) => {
						if (dataBenefit && dataBenefit.Data && $item.has(`[data-benefit-id="${dataBenefit.Data.AngebotId}"]`)) {
							$item.find('.js-mgl-benefit').toggleClass('h-hidden', false);
							fragment.append(this.$ctx.find(".js-mgl-benefit-small").clone());
						}
						await this._createMapItemContent(fragment, gasStations, gasStationCounter, $item);
					}, async () => {
						await this._createMapItemContent(fragment, gasStations, gasStationCounter, $item);
					});
				} else {
					await this._createMapItemContent(fragment, gasStations, gasStationCounter, $item);
				}
			} else {
				await this._createMapItemContent(fragment, gasStations, gasStationCounter, $item);
			}
		},

		_filterChanged: function _filterChanged() {
			const filter = parseInt(this.$ctx.find(".js-results-filter").find(":selected").val());
			if (filter === 1) {
				this.sortBy = "Name";
			} else if (filter === 2) {
				this.sortBy = "Distance";
			} else {
				this.sortBy = "Preis";
			}
			this._onChangeTrigger();
		},
		_loadItems: function _loadItems() {
			if (!this._initialLoad && this.loadedItems.length) {
				this._loadItemsPaging();
			}
			this.currentPageIndex = 0;
			this._initialLoad = false;
		},

		_loadItemsPaging: function _loadItemsPaging() {
			this.currentPageIndex += 1;
			this._triggerSearchEvent();
		},

		_hideMore: function _hideMore() {
			this.$ctx.find(".js-more").toggleClass("h-hidden", true);
		},

		_hideTypeaheadDropdown: function _hideTypeaheadDropdown() {
			setTimeout(() => {
				// force hide dropdown
				$(`.js-suggestion-${this.$queryInput.attr('id')}`).css('display', 'none');
			}, 100);
		},

		_getRequestData: function _getRequestData(perimeter) {
			this._initialLoad = true;
			const mercPoint = { x: this.typeaheadSelection.Position1X, y: this.typeaheadSelection.Position1Y };
			const coords = T.Utils.Geo.mercatorToLonLat(mercPoint);
			const diagonal = parseInt(Math.sqrt((perimeter * perimeter) + (perimeter * perimeter)));
			return {
				Rectangle: {
					WestNord: {
						X: coords.x - diagonal / 1000,
						Y: coords.y + diagonal / 1000
					},
					OstSued: {
						X: coords.x + diagonal / 1000,
						Y: coords.y - diagonal / 1000
					}
				},
				Position: {
					X: coords.x,
					Y: coords.y
				}
			};
		},

		_getCurrentTooltip: function _getCurrentTooltip() {
			return this.currentItem.tooltip;
		},

		_getDetailsUrl: function (item) {
			const seoUrl = T.Utils.Helper.appendURIPath(this.detailsBasicUrl,
				T.Utils.Helper.createSeoUrl(`${item.Plz}-${item.Ort}-${item.Name}`));
			return T.Utils.Helper.appendURIPath(seoUrl, item.PoiId.toString());
		},
		_getRouteUrl: function (item) {
			const destination = {
				postCode: item.Plz,
				location: item.Ort,
				street: item.Strasse,
				x: item.x,
				y: item.y
			};
			const start = this.typeaheadSelection && this.typeaheadSelection.UserPosition ? {
				name: this.typeaheadSelection.UserPosition.Bezeichnung,
				postCode: this.typeaheadSelection.UserPosition.PLZ,
				location: this.typeaheadSelection.UserPosition.Ort,
				street: this.typeaheadSelection.UserPosition.Strasse,
				x: this.typeaheadSelection.UserPosition.X,
				y: this.typeaheadSelection.UserPosition.Y
			} : undefined;
			return T.Utils.Map.createRouteUrl(this.mapsBasicUrl, destination, start);
		},
		_getClientGeoLocation: function _getClientGeoLocation(skipSearch) {
			this._showAutolocationError(false);
			if (this.typeaheadSelection) {
				delete this.typeaheadSelection.UserPosition;
			}
			const error = (() => {
				this._showAutolocationError(true);
			});
			if (!navigator.geolocation) {
				console.warn("This browser doesn't support geolocation");
				error();
				return;
			}

			const success = ((position) => {
				const p = T.Utils.Geo.latlonToMercator({ x: position.coords.longitude, y: position.coords.latitude });

				p.x = Math.round(p.x);
				p.y = Math.round(p.y);

				const renderFunc = ((foundAddress) => {
					this.$queryInput.val(foundAddress.Kurztext);
					if (this.$form[0] && this.$form[0].validation) {
						this.$form[0].validation.element(this.$queryInput);
						this.$queryInput.focus();
					}
					this._doSearch({ Data: [foundAddress] }, true, skipSearch);
				});

				T.Utils.Map.getGeolocationFromApiByCoords(p, renderFunc, error, this.apireversegeocoding);
			});
			navigator.geolocation.getCurrentPosition(success, error);
		},

		_doSearch: function _doSearch(data, fromGeolocation, skipExecution) {
			if (data && data.Data && data.Data.length > 0) {
				this.typeaheadSelection = {
					Bezeichnung: data.Data[0].Kurztext,
					Position1X: data.Data[0].Punkt1.X,
					Position1Y: data.Data[0].Punkt1.Y
				};
				if (fromGeolocation) {
					this.typeaheadSelection.UserPosition = {
						X: data.Data[0].Punkt1.X,
						Y: data.Data[0].Punkt1.Y,
						Bezeichnung: data.Data[0].Kurztext || (`${data.Data[0].Punkt1.X} ${data.Data[0].Punkt1.Y}`),
						Ort: data.Data[0].Stadt,
						PLZ: data.Data[0].Postleitzahl,
						Strasse: data.Data[0].StrasseNummer
					};
				}
				if (!skipExecution) {
					this._onChangeTrigger();
				}
			}
		},

		_toggleMaps: function _toggleMaps(e) {
			e.preventDefault();
			const $currentClickedElement = $(e.currentTarget);
			const $wrapper = $currentClickedElement.closest(".m-adac-maps");
			let $showElement = $wrapper.find('.mm-result-list-wrap'),
				$hideElement = $wrapper.find('.mm-map-wrapper');

			$currentClickedElement.addClass('is-active');
			$currentClickedElement.siblings('.js-switch').removeClass("is-active");

			if ($currentClickedElement.data("switch") === "map") {
				$showElement = $wrapper.find('.mm-map-wrapper');
				$hideElement = $wrapper.find('.mm-result-list-wrap');
			}

			$showElement.addClass("is-active");
			$hideElement.removeClass("is-active");
		},

		_hanldeMapLoad: function _hanldeMapLoad(whenReady) {
			delete this._loadingMap;
			this._adjustMapControls();
			if ("function" === typeof whenReady) {
				whenReady(true);
			}
		},

		_adjustMapControls: function _adjustMapControls() {
			T.Utils.Mapbox.showNavigationControl(this.map);
		},

		_mercatorToLocation: function _mercatorToLocation(point) {
			return T.Utils.Mapbox.coordToLocation(
				T.Utils.Geo.mercatorToLonLat({ x: point.x, y: point.y })
			);
		},

		_createMapIfNeeded: function _createMapIfNeeded(center, whenReady) {
			if (!this.map) {
				this._loadingMap = true;
				this.map = T.Utils.Mapbox.renderMap(
					this.apimMapStyles,
					center,
					{
						apimKey: T.Utils.Application.getApiMSubscriptionKey(),
					}
				);
				if (this.map) {
					this.map.on(
						"load",
						this._hanldeMapLoad.bind(this, whenReady)
					);
				}
			} else if ("function" === typeof whenReady) {
				if (this._loadingMap) {
					// when filter is set on page load and wants to select a location, map is not yet ready
					const mapsCheck = setInterval(() => {
						if (!this._loadingMap) {
							clearInterval(mapsCheck);
							whenReady();
						}
					}, 300);
				} else {
					whenReady();
				}
			}
		},

		_onItemClicked: function _onItemClicked(result) {
			this.currentItem = null;
			for (let index = 0; index < this._mapResultCache.length; index++) {
				if (this._mapResultCache[index].mapsReference === result.mapsReference) {
					this.currentItem = this._mapResultCache[index];
					break;
				}
			}

			this.currentActivePoi.find(".js-icon").removeClass(this._pinActiveClass);
			this.currentActivePoi = this.$ctx.find(`.js-fuelpriceresults li[data-id="${this.currentItem.id}"]`);
			this.currentActivePoi.find(".js-icon").addClass(this._pinActiveClass);
			this._updateMapMarkes();
		},

		_updateMapMarkes: function _updateMapMarkes() {
			if (this.currentItem) {
				T.Utils.Mapbox.removeAllPoints(this.map);

				this._addInactiveLocationsToMap();
				const loc = this._getItemMapLocation(
					this.currentItem.pos
				);
				this.currentItem.mapsReference = T.Utils.Mapbox.createPoint(
					this.map,
					loc,
					this._getCurrentTooltip() || null,
					this._$iconActive,
					null,
					this._popupConfig
				);
				T.Utils.Mapbox.fitBounds(this.map, this._bounds, {
					padding: { top: 25, bottom: 25, left: 25, right: 25 }
				});
			}
		},

		_addInactiveLocationsToMap: function _addInactiveLocationsToMap() {
			const idx = this._mapResultCache.indexOf(this.currentItem);
			const tempResults = this._mapResultCache.filter((item, index) => {
				return index !== idx;
			});

			tempResults.forEach((result) => {
				const loc = this._getItemMapLocation(result.pos);
				result.mapsReference = T.Utils.Mapbox.createPoint(
					this.map,
					loc,
					result.tooltip || null,
					this._$iconInActive,
					null,
					this._popupConfig
				);
				const element = result.mapsReference.getElement();
				$(element).on('click', this._onItemClicked.bind(this, result));
			});
		},

		_handleResultClick: function _handleResultClick(element) {
			if (this.map) {
				const result = this._mapResultCache.filter(item => {
					return item.id === element.attr('data-id');
				});
				if (result && result.length > 0) {
					this.currentItem = result[0];
					this.currentActivePoi.find(".js-icon").removeClass(this._pinActiveClass);
					element.find(".js-icon").addClass(this._pinActiveClass);
					this.currentActivePoi = element;
					if (this.userPorsitionMapsRef) {
						this.userPorsitionMapsRef.setTopLevel(true);
					}
				}
				this._updateMapMarkes();
			}
		},

		_showAutolocationError: function _showAutolocationError(show) {
			this.$ctx.find('.js-auto-location-err').toggleClass('h-hidden', !show);
		},

		_errorApimNotAvailableCallback: function _errorApimNotAvailableCallback() {
			T.Utils.View.stopLoader();
			this._hideMore();
			this.$ctx.find(".js-error").toggleClass("h-hidden", false);
		},
		_errorNoResults: function _errorNoResults() {
			T.Utils.View.stopLoader();
			this._hideTypeaheadDropdown();
			this._hideMore();
			this.$ctx.find(".js-noresult").toggleClass("h-hidden", false);
			this.$searchResultslist.toggleClass("h-hidden", true);
		},
		_changeButtonLabelToRefreshIfTriggered: function () {
			const storedSearch = T.Utils.Store.get(this.STORED_SEARCH_KEY, T.Utils.Store.SESSION);

			if (storedSearch) {
				const refresh = this.$submitClass.data("refresh");
				this.$submitClass.text(refresh);
			}
		},
		_selectFuelTypeAccordingToSessionValues: function _selectFuelTypeAccordingToSessionValues(value) {

			//replacement of whitespace - needed for Fueltype Super_E10
			const transformedFuelType = value.replace(/\s/g, "_");
			this.$ctx.find(`input[name="fuel-type"][value="${transformedFuelType}"]`).prop("checked", true);
		}
	});
}(jQuery));