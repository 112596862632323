'use strict';
(function ($) {
	/**
	 * MapboxSearchPoiResults.MembershipBenefitsNearBy module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>
	 * @namespace T.Module
	 * @class MapboxSearchPoiResults.MembershipBenefitsNearBy
	 * @extends T.Module
	 */
	T.Module.MapboxSearchPoiResults.MembershipBenefitsNearBy = T.createDecorator({
		_initialLoad: false,

		start: function start(resolve) {
			this._parent.start(resolve);
			this.$ctx = $(this._ctx);
			this.$mapsMglWrapper = this.$ctx.find(".mm-maps-mgl-wrapper");
			this._registerListeners();
			this._initGeoData();
			this._initOffer();
		},
		_initGeoData: function _initGeoData() {
			this._initialLoad = true;
			this.selectedLocation = {};
			this.selectedLocation.jsId = {};
			this.selectedLocation.jsId.X = this.coordinateX;
			this.selectedLocation.jsId.Y = this.coordinateY;
			if (this.coordinateX && this.coordinateY) {
				const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.config.apigeocoord);
				const options = {
					type: "GET",
					headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() },
					data: { x: this.coordinateX, y: this.coordinateY, listOfAngebotId: this.offerId },
					url: apiUrl
				};
				T.Utils.Ajax.fragment(options,
					this._handleGetGeoCoordSuccess.bind(this),
					this._errorApiGeoCoordNotAvailableCallback.bind(this));
			}
		},
		_initOffer: function _initOffer() {
			if (!this.offerId) {
				return;
			}
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.config.apiMembershipBenefitOffer);
			const options = {
				type: "GET",
				data: { angebotId: this.offerId },
				url: apiUrl
			};
			T.Utils.Ajax.fragment(options,
				this._handleGetOfferSuccess.bind(this),
				this._errorApilNotAvailableCallback.bind(this));
		},
		_errorApiGeoCoordNotAvailableCallback: function _errorApiGeoCoordNotAvailableCallback() {
			this._showGeoError();
		},
		_errorApilNotAvailableCallback: function _errorApilNotAvailableCallback(data) {
			if (data && data.responseJSON && data.responseJSON.Success) {
				T.Utils.View.stopLoader();
				this._showNoResult();
				this._hideList();
			}
			else {
				T.Utils.View.stopLoader();
				this._showApilError();
				this._hideList();
			}
		},

		_getData: function _getData() {
			if (this.offerId || (this.coordinateX && this.coordinateY && this.perimeter && this.offerId)) {
				this.getData();
			}
			else {
				this._hideList();
			}
		},
		_getConfigEndpoint: function _getConfigEndpoint() {
			return this.config.apiMembershipBenefit;
		},
		_getConfigRequestParams: function _getConfigRequestParams() {
			// Test data: id=1057&x=1281623&y=6122711&perimeter=50
			let data = {};
			if (this.coordinateX && this.coordinateY && this.perimeter) {
				const diagonal = parseInt(Math.sqrt((this.perimeter * this.perimeter) + (this.perimeter * this.perimeter)));
				const parsedX = parseInt(this.coordinateX);
				const parsedY = parseInt(this.coordinateY);
				data = {
					Rectangle: {
						WestNord: {
							X: parsedX - diagonal * 1000,
							Y: parsedY + diagonal * 1000
						},
						OstSued: {
							X: parsedX + diagonal * 1000,
							Y: parsedY - diagonal * 1000
						}
					},
					Position: {
						X: this.coordinateX,
						Y: this.coordinateY
					}
				};
			}

			// OfferId has 1:n in the result. There are many offers with one OfferId
			data.listOfAngebotId = this.offerId;

			return data;
		},
		_getItemTooltip: function _getItemTooltip(item) {
			return item ? item.NiederlassungName || item.Angebotsname : "";
		},
		_getClientGeoLocation: function _getClientGeoLocation() {
			this._hideClientGeoError();
			if (!navigator.geolocation) {
				console.warn("This browser doesn't support geolocation");
				this._showClientGeoError();
				return;
			}
			const success = ((position) => {
				const smart = com.ptvag.webcomponent.map.CoordUtil.geoDecimal2SmartUnit({
					x: position.coords.longitude * 100000,
					y: position.coords.latitude * 100000
				});
				const p = AMAMAPS.AMASmartUnits_2_Mercator(smart);
				p.x = Math.round(p.x);
				p.y = Math.round(p.y);

				const renderFunc = ((result) => {
					this.$locationInput.val(result.Kurztext);
					this.selectedLocation.jsId.X = result.Punkt1.X;
					this.selectedLocation.jsId.Y = result.Punkt1.Y;
				});

				T.Utils.Map.getGeolocationFromApiByCoords(p, renderFunc, error, this.config.apigeocoord);
			});

			const error = (() => {
				this._showClientGeoError();
			});
			navigator.geolocation.getCurrentPosition(success, error);
		},
		_getItemLocation: function _getItemLocation(item) {
			return item.Ort;
		},
		_getItemName: function _getItemName(item) {
			return item.NiederlassungName || item.Angebotsname;
		},
		_getItemPostCode: function _getItemPostCode(item) {
			return item.PLZ;
		},
		_getItemStreet: function _getItemStreet(item) {
			return item.Strasse;
		},
		_handleGetGeoCoordSuccess: function _handleGetGeoCoordSuccess(data) {
			if (data && data.Data && data.Data.length > 0) {
				const text = data.Data[0].Kurztext;
				this.selectedLocation.jsVal = text;
				this.$locationInput.val(text);
			}
		},
		_handleGetDataSuccessOnCompleted: function _handleGetDataSuccessOnCompleted() {
			if (this.items === null || this.items.length === 0) {
				this._showNoResult();
			}
		},
		_handleGetOfferSuccess: function _handleGetOfferSuccess(data) {
			if (data && data.Success && data.Data) {
				this.$ctx.find(".js-offer-name").html(data.Data.Angebotsname);
			}
		},
		_handleSelection: function _handleSelection(term, item, e) {
			const $elem = $(e);
			this.selectedLocation = $elem.data();
		},
		_hideApilError: function _hideApilError() {
			this.$ctx.find(".js-error").toggleClass("h-hidden", true);
		},
		_hideClientGeoError: function _hideClientGeoError() {
			this.$ctx.find(".js-client-geo-error").toggleClass("h-hidden", true);
		},
		_hideGeoError: function _hideGeoError() {
			this.$ctx.find(".js-postcode-error").toggleClass("h-hidden", true);
		},
		_hideMore: function _hideMore() {
			this.$ctx.find(".js-more").toggleClass("h-hidden", true);
		},
		_hideNoResult: function _hideNoResult() {
			this.$ctx.find(".js-noresult").toggleClass("h-hidden", true);
		},
		_hideList: function _hideList() {
			this.$mapsMglWrapper.toggleClass("h-hidden", true);
		},
		_isDistanceVisible: function _isDistanceVisible(item) {
			return !!item.EntfernungInKm;
		},

		_loadItems: function _loadItems() {
			const itemsPerPage = parseInt(this.config.itemsPerPage);
			if (this._initialLoad) {
				if (this.items.length === 0) {
					this.$ctx.find(".m-adac-maps--mgldiscountmaps").toggleClass("h-hidden", true);
				}
				if (this.items.length > 0 && this.items.length <= itemsPerPage) {
					this._hideMore();
				}
				if (this.items.length > 0 && this.items.length <= 8) {
					this._showAddress();
					this._parent._loadItemsPaging(0, itemsPerPage);
				} else if (this.items.length > 8) {
					this._showSearchWithPLZ();
				}
			} else {
				if (this.items.length) {
					this._showList();
					this._parent._loadItemsPaging(0, itemsPerPage);
				}
			}

			this.currentPageIndex = 0;
			this._initialLoad = false;
		},

		_loadItemsPaging: function _loadItemsPaging() {
			this.currentPageIndex += 1;
			const itemsPerPage = parseInt(this.config.itemsPerPage);
			const startIndex = this.currentPageIndex * itemsPerPage;
			const endIndex = startIndex + itemsPerPage;
			this._parent._loadItemsPaging(startIndex, endIndex);
			if (endIndex >= this.items.length) {
				this._hideMore();
			}
		},
		_readConfig: function _readConfig() {
			this._parent._readConfig();
			this.perimeter = T.Utils.Helper.getUrlParameter("perimeter");
			this.coordinateX = T.Utils.Helper.getUrlParameter("x");
			this.coordinateY = T.Utils.Helper.getUrlParameter("y");
			this.offerId = T.Utils.Helper.getUrlParameter("id");
			if (!this.offerId && this.config.idPosInSeoUrl) {
				this.offerId = T.Utils.Helper.getUrlPathPart(this.config.idPosInSeoUrl);
			}

			this.$locationInput = this.$ctx.find('input[id="mbdp_postcode"]');
			const $perimeter = this.$ctx.find(".js-perimeter");
			if (this.perimeter) {
				$perimeter.val(this.perimeter);
			}

			if (this.$locationInput) {

				T.Utils.View.setDataSafe(this.$locationInput, "searchurl", T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.config.apigeoname));
				T.Utils.View.setDataSafe(this.$locationInput, "subscription-key", T.Utils.Application.getApiMSubscriptionKey());
			}

		},
		_registerListeners: function _registerListeners() {
			this.$locationInput.on('automcompleteSelect', this._handleSelection.bind(this));
			this.$locationInput.keypress((e) => { //NOSONAR
				if (13 === e.keyCode) {
					return false;
				}
			});
			this.$ctx.find('.js-submit').on('click', this._submitForm.bind(this));
			this.$ctx.find('.js-localizeme').on('click', this._getClientGeoLocation.bind(this));
			this.$ctx.find('.js-more').on('click', this._loadItemsPaging.bind(this));
		},
		_showGeoError: function _showGeoError() {
			this.$ctx.find(".js-postcode-error").toggleClass("h-hidden", false);
		},
		_showClientGeoError: function _showClientGeoError() {
			this.$ctx.find(".js-client-geo-error").toggleClass("h-hidden", false);
		},
		_showNoResult: function _showNoResult() {
			if (this._initialLoad) {
				this.$ctx.find(".m-adac-maps--mgldiscountmaps").toggleClass("h-hidden", true);
			} else {
				this.$ctx.find(".js-noresult").toggleClass("h-hidden", false);
			}
		},
		_showApilError: function _showApilError() {
			this.$ctx.find(".js-error").toggleClass("h-hidden", false);
		},
		_showList: function _showList() {
			this.$mapsMglWrapper.toggleClass("h-hidden", false);
		},
		_showAddress: function _showAddress() {
			this.$mapsMglWrapper.toggleClass("h-hidden", false);
			this.$ctx.find(".m-basic-radius-search").toggleClass("h-hidden", true);
		},
		_showSearchWithPLZ: function _showSearchWithPLZ() {
			this.$mapsMglWrapper.toggleClass("h-hidden", true);
			this.$ctx.find(".m-basic-radius-search").toggleClass("h-hidden", false);
		},
		_submitForm: function _submitForm(ev) {
			ev.preventDefault();

			if (this.$locationInput.val() === "" || !this.selectedLocation || !this.selectedLocation.jsId) {
				this._showGeoError();
				return;
			}

			this._showList();
			this._showAddress();
			this._showSearchWithPLZ();
			this._hideApilError();
			this._hideGeoError();
			this._hideNoResult();
			this._hideClientGeoError();

			this.perimeter = parseInt(this.$ctx.find(".js-perimeter").find(":selected").val());
			this.coordinateX = this.selectedLocation.jsId.X;
			this.coordinateY = this.selectedLocation.jsId.Y;
			$(".mm-result-list").empty();
			this._getData();
		}
	});
}(jQuery));