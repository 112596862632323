(function($) {
	'use strict';
	/**
	 * VehiclesResults module implementation.
	 *
	 * @author Lars Meyer <l.meyer@mgo360.de>
	 * @namespace T.Module
	 * @class VehiclesResults
	 * @extends T.Module
	 */
	T.Module.VehiclesResults = T.createModule({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$contentWrappers: null,

		start: function(resolve) {

			this.$ctx = $(this._ctx);
			this.$contentWrappers = this.$ctx.find('.js-brandingresult');

			this.addEventListeners();

			resolve();
		},

		/**
		 * listen to events
		 */
		addEventListeners()
		{
			// listen to clicks of vehiclebrands to open content
			this._events.on('vehicleBrands.tileClick', (contentId) => {
				this.scrollToContent(contentId);
			});
		},

		/**
		 * scroll document to position
		 *
		 * @param contentId {String} - data-id of the content-wrapper we are looking for
		 */
		scrollToContent(contentId)
		{
			// hide others
			this.$contentWrappers.addClass('h-none');

			const contentElem = this.$contentWrappers.filter(`[data-js-content-id="${  contentId  }"]`);

			// show and scroll to pos
			if ( typeof $(contentElem) !== 'undefined' && $(contentElem).length) {
				$(contentElem).removeClass('h-none');
				$('body, html').animate({scrollTop: $(contentElem).offset().top}, 500);
			}
		}
	});
}(jQuery));
