(function ($)
{
    'use strict';
    /**
     * MyAdacDashboardBoxBanner module implementation.
     *
     * @author Tobias <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class MyAdacDashboardBox
     * @extends T.Module
     */
    T.Module.MyAdacDashboardBoxBanner = T.createModule({

        /** @type {jQuery} */
        $module: null,

        start: function (resolve)
        {
            this.$module = $(this._ctx);

            resolve();
        },
    });
}(jQuery));
