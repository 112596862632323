/**
 * Created by s.vogt on 17.05.2017.
 */
(function ($)
{
    'use strict';

    /**
     * BasicFormOverview module implementation.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class BasicFormOverview
     * @extends T.Module
     */
    T.Module.BasicFormOverview = T.createModule({
        /** @type {jQuery} */
        $ctx: null,


        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);


            if (sessionStorage.getItem('productlist'))
            {
                // get title, image + copayment
                const items = JSON.parse(sessionStorage.getItem('productlist')),
					jsSessionBonusCopayment = '.js-session-bonus-copayment';

                let productTitle = '',
                    productImage = '',
                    productCopayment = '';

                for (let i = 0; i < items.length; i++) {
                    if (items[i]['url'] === sessionStorage.getItem('productActive')) {
                        productTitle = items[i]['title'];
                        productImage = items[i]['image'];
                        productCopayment = items[i]['copayment'];
                    }
                }

                // set title
                if ($('.js-session-bonus-title').length)
                {
                    $('.js-session-bonus-title').html(productTitle);
                }
                // set image
				const imgSessionSelector = '.js-session-bonus-image';
                if ($(imgSessionSelector).length && productImage && productImage.length > 0)
                {
                    $(imgSessionSelector).attr('src', productImage);
                }
				else {
					$(imgSessionSelector).closest('.mm-aside').hide();
				}

                // set image
                if ($(jsSessionBonusCopayment).length)
                {
                    if (productCopayment !== '')
                    {
                        $(jsSessionBonusCopayment).html(productCopayment);
                    }
                    else
                    {
                        $(jsSessionBonusCopayment).hide();
                    }
                }
            }

            resolve();
        },
    });
}(jQuery));

