(function($) {
    'use strict';
    /**
     * BasicToggle module implementation.
     *
     * @author  <l.meyer@edelweiss72.de>
     * @namespace T.Module
     * @class BasicToggle
     * @extends T.Module
     */
    T.Module.BasicToggle = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {Boolean} */
        allowClick: true,

        /** @type {Boolean} */
        allowClickTrigger: false,

		classNoToggleBoth: 'm-basic-toggle--no-toggle-both',
		selectorContent: '.js-content',
		ariaExpandedAttribute: 'aria-expanded',

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function(resolve) {
            this.$ctx = $(this._ctx);
            const that = this;

            //*** get global event search
            //this._events.on('BasicKeywordfilter.search', this.highlight.bind(this.searchValue));
            this._events.on('BasicKeywordfilter.search', function(){
                that.toggleAfterSearch();
            });

            //*** get global event search keyup
            //this._events.on('BasicKeywordfilter.searchKeyup', this.highlight.bind(this.searchValue));
            this._events.on('BasicKeywordfilter.searchKeyup', function(){
                that.toggleAfterSearch();
            });

            //*** get global event search reset
            this._events.on('BasicKeywordfilter.reset', function(){
                that.toggleAfterSearch();
            });

            //*** get global event toggle open all
            this._events.on('BasicKeywordfilter.toggleOpenAll', function(){
                // reset highlight
                that.$ctx.unhighlight({className: 'h-search'});

                // open
                that.$ctx.find('.js-head').each(function(index, item){
                    if (!$(item).hasClass('mm-opened')) {
                        $(item).trigger('click');
                    }
                });
            });

            //*** get global event toggle close all
            this._events.on('BasicKeywordfilter.toggleCloseAll', function(){
                // reset highlight
                that.$ctx.unhighlight({className: 'h-search'});

                // close
                that.$ctx.find('.js-head').each(function(index, item){
                    if ($(item).hasClass('mm-opened')) {
                        $(item).trigger('click');
                    }
                });
            });

            //*** get global event toggle open all
            this._events.on('myAdacDashboardShowCase.toggleOpenAll', function(){
                // reset highlight
                that.$ctx.unhighlight({className: 'h-search'});

                // open
                that.$ctx.find('.js-head').each(function(index, item){
                    if (!$(item).hasClass('mm-opened')) {
                        $(item).trigger('click');
                    }
                });
            });

            //*** get global event toggle close all
            this._events.on('myAdacDashboardShowCase.toggleCloseAll', function(){
                // reset highlight
                that.$ctx.unhighlight({className: 'h-search'});

                // close
                that.$ctx.find('.js-head').each(function(index, item){
                    if ($(item).hasClass('mm-opened')) {
                        $(item).trigger('click');
                    }
                });
            });

            // init events
			this.initEvents();
			this.basicToggleDirectory();
			this.resize();

            resolve();
        },

        /**
         * init click events
         *
         */
        initEvents: function(){

            const that = this;
			const openedClass = 'mm-opened';

			this.$ctx.find('.js-head').each((index, element) => {
					if($(element).hasClass(openedClass)) {
						$(element).attr(this.ariaExpandedAttribute, true);
						that.manageTabIndexForLinks($(element).next(), 0);
					}
					else {
						that.manageTabIndexForLinks($(element).next(), -1);
					}
			});

            // js-head
            this.$ctx.find('.js-head').on('click', function(){
                if ((that.allowClick === true || that.allowClickTrigger === true) && !that.$ctx.hasClass(that.classNoToggleBoth))
                {
					if((that.$ctx.hasClass('m-basic-toggle--no-toggle-desktop') && S.Globals.MediaQuery.mqRightNow === 'desktop') || (that.$ctx.hasClass('m-basic-toggle--no-toggle-mobile') && S.Globals.MediaQuery.mqRightNow === 'smartphone')) {
						return;
					}


                    // ***
                    // that.allowClick = false;
                    // ----
                    // removed by EW72 (LM)
                    // reason: caused bug for open-/close-all call();
                    // intention: was implemented to prevent "double-clicks" or additional clicks while anim is still running
                    // ----
                    // comment: if still necessary, find way to add that property to .js-head itself, rather than to the component
                    // or use a different approach like on()/off()  etc.
                    // ***

                    const headController = $(this);

                    if($(this).hasClass('mm-opened')) {

                        $(this).next(that.selectorContent).slideUp(function(){
							that.manageTabIndexForLinks($(this), -1);
                            $(this).prev().attr(that.ariaExpandedAttribute, false).removeClass('mm-opened');
                            if($(this).prev().hasClass('is-opened')) {
                                $(this).prev().removeClass('is-opened');
                            }
                            $(this).css('display', '');

                            //Shoot event to basicanchornav on open / close to recalculate height
                            that._events.emit('BasicAnchorNav.recalculateHeight', headController, 'close');
                        });
                    }

                    else {

                        $(this).attr(that.ariaExpandedAttribute, true).addClass('mm-opened').next(that.selectorContent).hide().slideDown(function(){
                            that._events.emit('BasicAnchorNav.recalculateHeight', headController, 'open');
							that.manageTabIndexForLinks($(this), 0);

                            setTimeout(() => {
                                that._events.emit('basicToggleOpen', that.$ctx);
                                $(document).trigger('BasicToggleOpenTracking', headController);
                            }, 200);
                        });
                    }

                    // accordion
                    if($(this).parent().hasClass('m-basic-toggle--accordion')) {
                        if($(this).hasClass('mm-opened')) {
                            let actHeight = 0;
                            $(this).prevAll('.mm-head.mm-opened').each(function(){
                                actHeight += $(this).next(that.selectorContent).outerHeight() + 15;
                            });
                            $('html').animate({
                                scrollTop: $(this).offset().top - actHeight - 70
                            }, 500);
                        }
                        $(this).siblings('.mm-head').attr(that.ariaExpandedAttribute, false).removeClass('mm-opened').next(that.selectorContent).slideUp();
                    }
                }
            });
        },

		basicToggleDirectory: function() {
			const openingClasses = 'mm-opened is-opened';
			const that = this;

			setTimeout(() => {
				$(document).find('.m-basic-toggle.m-basic-toggle--directory .js-head').each(function(index, elem) {
					if(S.Utils.Helper.mq('desktop').matches)
					{
						// desktop initially opened
						if($(elem).hasClass('is-opened-desktop'))
						{
							$(elem).addClass(openingClasses);
							that.manageTabIndexForLinks($(elem).next(), 0);
						}
						// desktop no Toggle
						else if($(elem).parent().hasClass('m-basic-toggle--no-toggle-desktop') || $(elem).parent().hasClass(this.classNoToggleBoth)) // eslint-disable-next-line
						{ //NOSONAR
							$(elem).addClass(openingClasses);
							that.manageTabIndexForLinks($(elem).next(), 0);
						}
						// desktop initially closed
						else {
							$(elem).removeClass(openingClasses);
							that.manageTabIndexForLinks($(elem).next(), -1);
						}
					}
					else
					{
						// mobile initially opened
						if($(elem).hasClass('is-opened-mobile'))
						{
							$(elem).addClass(openingClasses);
							that.manageTabIndexForLinks($(elem).next(), 0);
						}
						// mobile no Toggle
						else if($(elem).parent().hasClass('m-basic-toggle--no-toggle-mobile') || $(elem).parent().hasClass(this.classNoToggleBoth)) // eslint-disable-next-line
						{ //NOSONAR
							$(elem).addClass(openingClasses);
							that.manageTabIndexForLinks($(elem).next(), 0);
						}
						// mobile initially closed
						else {
							$(elem).removeClass(openingClasses);
							that.manageTabIndexForLinks($(elem).next(), -1);
						}
					}
				});
			}, 100);
		},

		manageTabIndexForLinks: function(elem, index) {
			elem.find('a, button').attr('tabindex', index);
		},

		resize: function() {
			let cachedWidth = $(window).width();

			$(window).on('resize', () =>
				S.Utils.delayed('basictoggle.resizeEvent', 500, () => {
						const newWidth = $(window).width();
						if(newWidth !== cachedWidth) {
							this.basicToggleDirectory();
							cachedWidth = newWidth;
						}
				}),
			);
		},

        /**
         * toggle navi items
         *
         * @param {string} searchValue
         */
        highlight: function(searchValue){
			return searchValue.split(' ');
        },

        /**
         * toggle after search if results are inside of toggle content
         *
         */
        toggleAfterSearch: function(){

            // only if toggle is not inside of a js-basickeywordfilter-result-container
            if (!this.$ctx.closest('.js-basickeywordfilter-result').length){

                setTimeout(() => {
                    this.allowClickTrigger = true;

                    this.$ctx.find('.js-head').each(function(index, item){
                        if ($(item).find('.h-search').length || $(item).next().find('.h-search').length){
                            if (!$(item).hasClass('mm-opened')) {
                                $(item).trigger('click');
                            }
                        }else{
                            if ($(item).hasClass('mm-opened')) {
                                $(item).trigger('click');
                            }
                        }
                    });

                    this.allowClickTrigger = false;
                }, 300);
            }
        }
    });
}(jQuery));
