'use strict';
(function ($) {
	/**
	 * Insurance termination module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class InsuranceTermination
	 * @extends T.Module
	 */
	T.Module.InsuranceTermination = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		$vesToken: null,
		$form: null,
		sessionKey: "selector.InsuranceTermination",
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');
			T.Utils.Application.init();
			this.$vesToken = this._getVesTokenFromUrl('data');
			this.returnUrl = this.$ctx.data('return-url');
			this._getUser(this._addUserdatatoView.bind(this));
			this._getInsuranceData();
			this._initValidation();
			this.$ctx.find('.js-submit-form').on('click', this._checkTokenAndSendInsuranceTermination.bind(this));
			resolve();
		},
		_addUserdatatoView: function _addUserdatatoView(user) {
			if (user.UserData.Firma) {
				this.$ctx.find('.js-anrede-firma').removeClass('h-hidden');
				this.$ctx.find('.js-firma-label').removeClass('h-hidden');
				this.$ctx.find('.js-firma-user').removeClass('h-hidden');
				this.$ctx.find('.js-name').text(user.UserData.Firma);
				this.$ctx.find('.js-vorname').text(user.UserData.Beguenstigter);
				this.$ctx.find('.js-geburtsdatum').addClass('h-hidden');
			}
			else {
				if (user.UserData.Anrede) {
					user.UserData.Anrede === 1 ? this.$ctx.find('.js-anrede-herr').removeClass('h-hidden') : this.$ctx.find('.js-anrede-frau').removeClass('h-hidden');
				}
				if (user.UserData.Nachname) {
					this.$ctx.find('.js-name').text(user.UserData.Nachname);
					this.$ctx.find('.js-name-label').removeClass('h-hidden');
				}
				if (user.UserData.Vorname) {
					this.$ctx.find('.js-vorname').text(user.UserData.Vorname);
					this.$ctx.find('.js-vorname-label').removeClass('h-hidden');
				}
				if (user.UserData.Geburtsdatum) {
					this.$ctx.find('.js-geb').text(moment(user.UserData.Geburtsdatum).format("L"));
					this.$ctx.find('.js-geburtsdatum').removeClass('h-hidden');

				}
			}
		},
		_initValidation: function () {
			const validationOptions = {
				messages: this.$form.data('validationoption') ? this.$form.data('validationoption') : {},
				rules: {
					'reason': {
						required: true
					},
					'sonstiges': {
						required: true
					}
				}
			};
			T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
		},
		_getUser: function getUser(callback) {
			const that = this;
			T.Utils.View.startLoader();
			T.Utils.Auth.getResolvedIdentity(function (user) {
				if (!user) {
					T.Utils.View.stopLoader();
					T.Utils.Helper.routeToUrl(window.location.origin + that.returnUrl);
				} else {
					if (user.UserData) {
						callback(user);
					} else {
						T.Utils.View.stopLoader();
						T.Utils.Helper.routeToUrl(window.location.origin + that.returnUrl);
					}
				}
			});
		},
		_getInsuranceData: function () {
			const that = this;
			const insuranceData = T.Utils.Store.get('InsuranceData', 'session');
			if (insuranceData && that.$vesToken) {
				const insurance = insuranceData.find((i) => {
					return i.VesToken === that.$vesToken;
				});

				if (insurance) {
					that._addInsuranceDataToView(insurance);
					that._hideAndShowTerminationReasonByInsurance(insurance.VertragsArt);
				} else {
					T.Utils.Helper.routeToUrl(window.location.origin + that.returnUrl);
				}

				T.Utils.View.stopLoader();
			}
			else {
				T.Utils.View.stopLoader();
				T.Utils.Helper.routeToUrl(window.location.origin + that.returnUrl);
			}
		},
		_addInsuranceDataToView: function _addInsuranceDataToView(data) {
			if (data) {
				if (data.Sicht1.Bezeichnung) {
					this.$ctx.find('.js-produkt').text(data.Sicht1.Bezeichnung);
					this.$ctx.find('.js-product-header').text(data.Sicht1.Bezeichnung);
				}
				if (data.Sicht0.Nachname && data.Sicht0.Vorname) {
					let personData = `${data.Sicht0.Vorname} ${data.Sicht0.Nachname}`;
					if (data.Sicht0.Geburtsdatum && data.Sicht0.Geburtsdatum.length > 0) {
						personData += `, ${moment(data.Sicht0.Geburtsdatum).format("L")}`;
					}
					this.$ctx.find('.js-person').text(personData);
					this.$ctx.find('.js-person-div').removeClass('h-hidden');
				}
				if (data.Sicht1.Beginn) {
					this.$ctx.find('.js-vesbeginn').text(moment(data.Sicht1.Beginn).format("L"));
				}
				if (data.Sicht1.Vertragstyp) {
					this.$ctx.find('.js-vesart-dev').removeClass('h-hidden');
					this.$ctx.find('.js-vesart').text(data.Sicht1.Vertragstyp);
				}
				if (data.Sicht1.Zahlart) {
					const $zahlart = this.$ctx.find('.js-zahlart');
					$zahlart.removeClass('h-hidden');
					$zahlart.text(`${$zahlart.text()} ${data.Sicht1.Zahlart}`);
				}
				if (data.Sicht1.Betrag) {
					this.$ctx.find('.js-beitrag').text($.number(data.Sicht1.Betrag, 2, ',', '.'));
				}
				if (data.Sicht1.Zahlweise) {
					const $zahlweise = this.$ctx.find('.js-zahlweise');
					$zahlweise.removeClass('h-hidden');
					if (data.Sicht1.Zahlweise === "jährlich") {
						$zahlweise.text(`${$zahlweise.text()} Jahr`);
					} else {
						$zahlweise.text(`${$zahlweise.text()} Monat`);
					}
				}
				if (data.Sicht1.Ende && data.Sicht1.Ende.length > 0) {
					this.$ctx.find('.js-faellig-div').removeClass('h-hidden');
					this.$ctx.find('.js-faellig').text(moment(data.Sicht1.Ende).format("L"));
				}
				if (data.Sicht0.Kennzeichen && data.Sicht0.Kennzeichen.length > 0) {
					this.$ctx.find('.js-kennzeichen-div').removeClass('h-hidden');
					this.$ctx.find('.js-kennzeichen').text(data.Sicht0.Kennzeichen);
				}
			}
		},

		_hideAndShowTerminationReasonByInsurance: function _hideAndShowTerminationReasonByInsurance(vertragsArt) {
			const self = this;
			this.$ctx.find('[name="reason"]').each(function (index, elem) {
				const insuranceIds = $(elem).data('terminationforinsurance');

				if (insuranceIds !== undefined) {
					if (self._isVertragsArtInHtmlELement(insuranceIds.toString(), vertragsArt)) {
						$(elem).parent().toggleClass('h-hidden', false);
					} else if (insuranceIds === '') {
						$(elem).parent().toggleClass('h-hidden', false);
					} else {
						$(elem).parent().toggleClass('h-hidden', true);
					}
				}
			});
			this.$ctx.find('.js-termination-reasons').toggleClass('h-hidden', false);
		},

		_isVertragsArtInHtmlELement: function _isVertragsArtInHtmlELement(insuranceIds, vertragsArt) {
			const splittedInsuranceIds = insuranceIds.split(',');

			if (splittedInsuranceIds.length > 1) {
				if ($.inArray(vertragsArt.toString(), splittedInsuranceIds) > -1) {
					return true;
				}
			} else if (insuranceIds.length === 1 && insuranceIds === vertragsArt.toString()) {
				return true;
			}
			return false;
		},

		_getVesTokenFromQueryString: function _getVesTokenFromQueryString() {
			const queryParameter = new URLSearchParams(window.location.search);
			if (queryParameter.has('vesToken')) {
				this.$vesToken = encodeURI(queryParameter.get('vesToken'));
			}
		},
		_checkTokenAndSendInsuranceTermination: function _checkTokenAndSendInsuranceTermination() {
			T.Utils.Auth.getBearerToken(this._sendInsuranceTermination.bind(this));
		},

		_sendInsuranceTermination: function _sendInsuranceTermination(token) {

			if (token) {
				const that = this;
				T.Utils.View.startLoader();
				const terminationApi = this.$ctx.data('termination-api');
				const checkedRadio = this.$form.find("input[name=reason]").filter(":checked");
				const divContainer = checkedRadio.nextAll('div:first');
				const hasinputSonstiges = divContainer.find("input[name=sonstiges]");
				let inputSonstiges = true;
				if (hasinputSonstiges && hasinputSonstiges.length > 0) {
					inputSonstiges = hasinputSonstiges.valid();
					const firstRadio = this.$form.find("input.is-first-radio[name=reason]");
					if (firstRadio) {
						firstRadio.removeClass('is-first-radio');
					}
				}
				if (inputSonstiges === false) {
					T.Utils.View.stopLoader();
				}
				else if (terminationApi && this.$form.valid()) {
					let freetextField = '';
					if (hasinputSonstiges && hasinputSonstiges.length > 0) {
						freetextField = hasinputSonstiges.val();
					}
					const options = {
						url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), terminationApi),
						type: 'POST',
						cache: false,
						headers: {
							Authorization: `Bearer ${token}`,
						},
						data: JSON.stringify({
							'VesToken': this.$vesToken,
							'GrundKuerzel': checkedRadio.val(),
							'Grund': checkedRadio.data("reasontext"),
							'Freitext': freetextField
						})
					};
					T.Utils.Ajax.fragment(options,
						function (data) {
							const _data = data.Data;
							if (_data) {
								that.$ctx.find('.js-confirmation').removeClass('h-hidden');
								that.$ctx.find('.js-form').addClass('h-hidden');
								that.$ctx.find('.js-vorgangsnummer').text(_data.Vorgangsnummer);
								that.$ctx.find('.js-timestamp').text(moment(_data.Timestamp).format("L"));
							}
							T.Utils.View.stopLoader();
						},
						function () {
							T.Utils.View.stopLoader();
							T.Utils.Helper.routeToUrl(window.location.origin + that.$ctx.data('cancel-url'));
						});
				}
				else {
					T.Utils.View.stopLoader();
				}
			}
		},

		_getVesTokenFromUrl(name) {
			name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); //NOSONAR
			const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
			const results = regex.exec(location.search);
			return results === null ? '' : decodeURIComponent(results[1]);
		}
	});
}(jQuery));
