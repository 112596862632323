(function($) {
	'use strict';
	/**
	 * MyAdacFormularTemplates module implementation.
	 *
	 * @author Tobias <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class MyAdacFormularTemplates
	 * @extends T.Module
	 */
	T.Module.MyAdacFormularTemplate = T.createModule({

		/** @type {Object} */
		$vehicleTypes: null,

		/** @type {Boolean} */
		prevType: false,

		/** @type {Object} */
		$vehicleDetail: null,


		start(resolve) {
			this.$ctx = $( this._ctx );
			this.notJsBrand = ':not(.js-brand)';

			if(this.$ctx.hasClass('js-vehicle-validate')) {

				// vehicle types
				this.$vehicleTypes = this.$ctx.find('.js-vehicle-type');

				// vehicle input Details
				this.$vehicleDetail = this.$ctx.find('.js-vehicle-details');

				this.$vehicleTypes.on('change', (e) => {
					this.changeType(e);
				});

				this.setDisable();

				this.$vehicleDetail.on('change', () => {
					this.setDisable();
				});
			}


			resolve();
		},


		// set input disabled if brand value is unset
		setDisable()
		{

			if (this.$vehicleDetail.hasClass('js-brand') && !this.$vehicleDetail.val() && $('.is-special-type').is(':checked') === false) {
				this.$vehicleDetail.filter(this.notJsBrand)
					.removeClass('js-clear a-basic-input-text--clear')
					.val('').attr('disabled', true);
			}
			else
			{
				this.$vehicleDetail.filter(this.notJsBrand).attr('disabled', false);
			}
		},


		//check if Radio Types are changed and if specialTyp is involved
		changeType(e)
		{
			const $clickedElement = $(e.target);

			if(this.prevType === true)
			{
				this.setDisable();
			}
			else if($clickedElement.hasClass('is-special-type'))
			{
				this.$vehicleDetail.filter(this.notJsBrand).attr('disabled', false);
			}
			else
			{
				this.$vehicleDetail.removeClass('js-clear a-basic-input-text--clear').val('');
				this.setDisable();
			}

			this.prevType = $clickedElement.hasClass('is-special-type');
		},

	});
}(jQuery));
