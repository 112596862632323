(function ($) {
	'use strict';
	/**
	 * BasicListFilter module implementation.
	 *
	 * @author Lars Meyer <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicList
	 * @extends T.Module
	 */
	T.Module.BasicList.Filter = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/**
		* @param {string} resolve
		*/

		start: function (resolve) {
			const that = this;
			this.$ctx = $(this._ctx);

			this.listFilter = 'm-basic-list--filter';
			this.tabcontentInside = '.mm-tabcontent-inside';
			this.filterDesc = '.filter-desc';

			if (sessionStorage.getItem('basickeywordfilter_doubleslider_min')) {
				this.writeBudget('tab-1-3', sessionStorage.getItem('basickeywordfilter_doubleslider_min'), sessionStorage.getItem('basickeywordfilter_doubleslider_max'));
			}

			// find tab-wrapper
			const tabcontentWrap = document.getElementsByClassName('mm-tabcontent');
			const tabcontents = $(tabcontentWrap).find('> .mm-tabcontent-inside');

			// call events for each item
			for (let i = 0; i < tabcontents.length; i++) {
				const tab = tabcontents[i];
				that.initEvents(tab);
			}

			this._parent.start(resolve);
		},

		/**
		 * initEvents
		 * @param {Object} tab
		 */
		initEvents: function (tab) {
			const that = this;

			const checkboxInputs = $(tab).find('input[type=checkbox]'),
				txtInputs = $(tab).find('input[type=text]');

			// Checkbox inputs
			if (checkboxInputs.length) {

				$(checkboxInputs).each(function () {
					that.checkFilter($(this), checkboxInputs);

					this.addEventListener('change', () => {
						that.checkFilter($(this), checkboxInputs);
					});
				});

			}

			// TF Input (budget-slider)
			if (txtInputs.length) {

				const tabCtx = txtInputs.closest(this.tabcontentInside).attr('id');

				$(txtInputs).each(function () {

					this.addEventListener('change', () => {

						const inputText1 = $(txtInputs[0]).val(),
							inputText2 = $(txtInputs[1]).val();

						that.writeBudget(tabCtx, inputText1, inputText2);
					});
				});

				that._events.on('BasicCalculatorSliderDouble.change', function (sliderVals) {
					that.writeBudget(tabCtx, sliderVals[0], sliderVals[1]);
				});
			}
		},

		checkFilter: function (filterElem, checkboxInputs) {

			// get data from cbx and dependent label
			const tabCtx = filterElem.closest(this.tabcontentInside).attr('id'),
				boxID = filterElem.attr('id'),
				boxOrder = parseInt(filterElem.attr('value')),
				inputtext = filterElem.parent().find(`div > label[for="${boxID}"]`).text();

			// cbx checked
			if (filterElem.prop('checked')) {
				this.writeFilterList(tabCtx, boxID, boxOrder, inputtext, checkboxInputs);
			}

			// cbx unchecked
			else if (!$(this).prop('checked')) {
				this.deleteFilterList(tabCtx, boxID, boxOrder, checkboxInputs);
			}
		},

		/**
		 * write inputtext from cbx into span elements in filter-list
		 * @params {String} tabCtx | get tab-context to handle the text injection
		 * @params {String} boxID
		 * @params {number} boxOrder | get info about the value(order)
		 * @params {String} inputtext | what will be injected
		 * @params {Object} checkboxInputs | amount of cbx in tab
		 *
		 */
		writeFilterList: function (tabCtx, boxID, boxOrder, inputtext, checkboxInputs) {
			const that = this;

			// find filter-list
			const listWrap = document.getElementsByClassName(this.listFilter),
				listItem = $(listWrap).find(`li.${tabCtx}`);

			// define item that will be injected
			const newItem = $(`<span data-cbx-name=${boxID} data-cbx-order=${boxOrder} class="filter-data"> ${inputtext}</span>`);

			// check if text was already injected
			if (listItem.hasClass('is-active')) {

				// build arrays for injection order
				const currentOrder = [];
				const currentItems = listItem.find('.filter-desc > span.filter-data');

				// push order-value as number to order-array
				for (let i = 0; i < currentItems.length; i++) {
					currentOrder.push(parseInt($(currentItems[i]).attr('data-cbx-order')));
				}

				// save min and max order-value in vars for better maintainability
				const maxOrder = Math.max.apply(null, currentOrder);
				const minOrder = Math.min.apply(null, currentOrder);

				// new Item has biggest order-val -> write to end of list
				if (boxOrder > maxOrder) {
					$(newItem).appendTo(listItem.find(this.filterDesc));
				}

				// new Item has smallest order-val -> write to start of list
				else if (boxOrder < minOrder) {
					$(newItem).insertAfter(listItem.find('.legend'));
				}

				// new Item has order-val in between -> find correct position
				else {
					for (let i = 0; i < currentItems.length; i++) {

						//find closest item with bigger order -> inject before it
						if ($(currentItems[i]).attr('data-cbx-order') > boxOrder) {
							$(newItem).insertBefore(currentItems[i]);

							// kill that loop on success;
							break;
						}
					}
				}

				that.addCommas(listItem);
			}

			// init state
			else {
				listItem.addClass('is-active');
				listItem.find(this.filterDesc).append(newItem);
			}

			//remove btn - unbind previous listeners and add click-event again
			const removeBtn = listItem.find('.remove-filter-btn');
			$(removeBtn).off();
			$(removeBtn).click(function () {
				that.clearFiltering(tabCtx, listItem, checkboxInputs);
			});


			//show filters again
			const showTabBtn = listItem.find(this.filterDesc);
			$(showTabBtn).off();
			$(showTabBtn).click(function () {
				that.scrollToTab(tabCtx);
			});
		},


		/**
		 * write inputtexts from budget-slider into span elements in filter-list
		 * @params {String} tabCtx | get tab-context to handle the text injection
		 * @params {String} inputText1 | what will be injected
		 * @params {String} inputText2 | what will be injected
		 */
		writeBudget: function (tabCtx, inputText1, inputText2) {

			const that = this;

			// find filter-list
			const listWrap = document.getElementsByClassName(this.listFilter),
				listItem = $(listWrap).find(`.${tabCtx}`);

			listItem.addClass('is-active');

			listItem.find('.filter-data').remove();
			$(`<span class="filter-data"> von ${inputText1} bis ${inputText2}</span>`).insertAfter(listItem.find('.legend'));

			//remove btn
			const removeBtn = listItem.find('.remove-filter-btn');
			$(removeBtn).off();
			$(removeBtn).click(function () {
				// move that slider back to init
				that._events.emit('BasicCalculatorSliderDouble.reset');

				const myArray = [];

				myArray[0] = $('#slider-input1').val();
				myArray[1] = $('#slider-input2').val();
				that._events.emit('BasicCalculatorSliderDouble.change', myArray);

				//remove filter-data
				listItem.find('.filter-data').remove();
				listItem.removeClass('is-active');

				// remove from sessionStorage
				sessionStorage.removeItem('basickeywordfilter_doubleslider_min');
				sessionStorage.removeItem('basickeywordfilter_doubleslider_max');

				$('.js-filter-matched-doubleslider').removeClass('js-filter-matched-doubleslider');
			});

			//show filters again
			const showTabBtn = listItem.find(this.filterDesc);
			$(showTabBtn).off();
			$(showTabBtn).click(function () {
				that.scrollToTab(tabCtx);
			});
		},

		/**
		 * delete inputtext from filter-list
		 * @params {String} tabCtx
		 * @params {String} boxID
		 * @params {number} boxOrder | get info about the value size for replacement
		 * @params {Object} checkboxInputs | amount of cbx in tab
		 *
		 */
		deleteFilterList: function (tabCtx, boxID, boxOrder, checkboxInputs) {
			const that = this;

			// find filter-list
			const listWrap = document.getElementsByClassName(this.listFilter),
				listItem = $(listWrap).find(`.${tabCtx}`);

			// check if other cbx are checked, store them in array
			const checkedBoxes = [];

			for (let i = 0; i < checkboxInputs.length; i++) {
				if (checkboxInputs[i].checked) {
					checkedBoxes.push(checkboxInputs[i]);
				}
			}

			// cbx all unchecked
			if (checkedBoxes.length === 0) {
				that.clearFiltering(tabCtx, listItem, checkboxInputs);
			}

			else {
				// remove (de)clicked input
				listItem.find(`span[data-cbx-order=${boxOrder}]`).remove();

				that.addCommas(listItem);
			}
		},

		/**
		 * insert <<,>> between items for visual separation
		 * @params {Object} listItem
		 */
		addCommas: function (listItem) {

			// update current items
			const currentItems = listItem.find('.filter-desc > span.filter-data');
			listItem.find('.comma-separator').remove();

			for (let i = 1; i < currentItems.length; i++) {
				$('<span class="comma-separator">,</span>').insertAfter(currentItems[i - 1]);
			}
		},

		/**
		 * set filter back to init
		 * @params {String} tabCtx | get tab-context to handle clearing
		 * @params {Object} listItem
		 * @params {Object} checkboxInputs | amount of cbx in tab
		 */
		clearFiltering: function (tabCtx, listItem, checkboxInputs) {

			// List Items
			listItem.find('.filter-data, .comma-separator').remove();
			listItem.removeClass('is-active');

			// Tab Items
			for (let i = 0; i < checkboxInputs.length; i++) {
				if (checkboxInputs[i].checked) {
					$(checkboxInputs[i]).trigger('click').blur();
				}
			}
		},

		/**
		 * scroll window back to tab-inputs
		 * @params {String} tabCtx | get tab-context to handle clearing
		 */
		scrollToTab: function (tabCtx) {

			// get the tab-wrapper
			const tabComponent = document.querySelector('.m-ves-tab');

			// find dependent inputs
			let tabInputs;

			if (tabCtx === 'tab-1-3') {

				tabInputs = tabComponent.querySelectorAll('input[type=text]');
			}

			else {
				tabInputs = tabComponent.querySelector(`.mm-tabcontent-inside#${tabCtx} input`);
			}

			// get input wrapper
			const tabInputWrapper = $(tabInputs).parents(this.tabcontentInside);

			// display tabcontent if not already done previously by user-click
			tabInputWrapper.removeClass('h-visuallyhidden');

			// get index of input wrapper to select dependent nav-item
			const tabIndex = $(tabInputWrapper).index();

			// set back tab-highlighting
			$(tabComponent).find('li.is-active').removeClass('is-active');

			// highlight active tab
			const tabActive = $(tabComponent).find(`li:eq(${tabIndex})`);
			$(tabActive).addClass('is-active');

			const tabs = $(tabComponent).find('.mm-tabcontent > .mm-tabcontent-inside');

			for (let i = 0; i < tabs.length; i++) {

				if ($(tabs[i]).index() !== tabIndex) {

					$(tabs[i]).hide();
				}

				else {
					$(tabs[i]).show();
				}
			}

			$('body,html').animate({
				scrollTop: $(tabComponent).offset().top
			}, 850);
		}

	});
}(jQuery));
