// EW72 Namespace

// eslint-disable-next-line
window.S = window.S || {}; //NOSONAR


(function ($) {
	'use strict';

	S.Globals = S.Globals || {};

	S.Globals.TabVisible = {
		isInViewport(element) {
			// avoid scrolling when entering the website via alt + tab
			if (element[0] === $('body')[0]) {
				return;
			}

			if ($(element).closest('.m-basic-header').length === 0) {
				const viewportTop = $(window).scrollTop() + 60;
				const viewportHeight = viewportTop + ($(window).height() - 210); // viewport height minus sticky elements top and bottom
				const elementTop = element.offset().top;
				const elementHeight = elementTop + element.height();

				if (elementHeight > viewportHeight || elementTop < viewportTop) {
					let newTop = $('html, body').scrollTop();

					if (elementHeight > viewportHeight) {
						newTop = newTop + element.height() + 120; // bottom  sticky area
					}
					else {
						newTop = newTop - element.height() - 80; // top sticky area
					}

					$('html, body').animate({
						scrollTop: newTop
					}, 'slow');
				}
			}
		}
	};

	S.Globals.TabFocus = {

		/** @var{HTMLElement} */
		lastFocusableEl: null,

		/** @var{Array} */
		additionalElToFocus: [],
		additionalEl: [],
		additionalElLast: [],

		/** @var{HTMLElement} */
		focusedElBeforePopupOpen: null,

		/** @var{HTMLElement} */
		isLastFocusableEl: null,

		/** @var{HTMLElement} */
		isAdditionalFocusableEl: null,

		/** @var{HTMLElement} */
		endReached: true,

		/** @var{HTMLElement} */
		userLeftThePage: false,

		init() {
			const $body = $('body'),
				$doc = $(document),
				$iframe = $('iframe');

			this.isKeyboardFocus = 'is-keyboard-focus';

			// keydown provides "previous" focused element
			$doc.on('keydown.keyboardFocus', (event) => {
				// we only care about the tab key
				if (event.key !== 'Tab' && event.key !== 'Enter') {
					return;
				}

				$body.addClass(this.isKeyboardFocus);

				// check for last element
				this._handleTabKeyDown(event.shiftKey, event);

				// scroll top after tabbing through browser navi to open full header
				setTimeout(() => {
					if ($('a:focus-visible').parent().hasClass('mm-topics-list')) {
						$(window).scrollTop(0);
					}
				}, 50);
			});

			// keydown provides "previous" focused element
			$doc.on('keyup.keyboardFocus', (event) => {
				// we only care about the tab key
				if (event.key !== 'Tab' && event.key !== 'Enter') {
					return;
				}

				$body.addClass(this.isKeyboardFocus);

				// override the delay to only trigger focus scroll when user is finished tabbing, otherwise it gets broken
				// issue: if you don't do this and tab quickly in inputs out of viewport, this gets triggered several times and in the end its broken
				S.Utils.delayed('tab-is-in-viewport', 500, () => {
					S.Globals.TabVisible.isInViewport($(event.target));
				});

				// check for last element
				this._handleTabKeyUp(event);

				// trigger nearest modul hasFocus Function
				this.handleTabFocusForModules(event);
			});

			this.clickTouchstart($doc, $body);

			/**
			 * when everything is ready
			 * (javascript is executed and all dynamically via JS added elements are applied and rendered)
			 */
			$doc.ready(() => {
				const allFocusableEl = this.getKeyboardFocusableElements();
				this.lastFocusableEl = allFocusableEl[allFocusableEl.length - 1];

				// we use window.onblur (userLeftThePage) as our flag to check if the user comes back from browsers tab, X, URL e.g.
				// - this is necessary because if he navigates back via tab+shift the last additional element (only if there are additional el in the array)
				//   needs to be focused and our main logic is handled via keydown event.
				//   But if the user navigates back via tab+shift the event won't get triggered
				//   because the key wasn't pressed (triggered) inside the window (object).
				window.onblur = () => {
					/**
					 * prevent setting the userLeftThePage flag when user enters an iframe
					 *
					 * info:
					 * ####
					 * If you focus in an iframe, you leave the actual window (page) and an onBlur Event is triggered. It focuses in the
					 * iframe document (basically an iframe could contain a new page etc.). Normally you do not
					 * have access to the iframe or its behaviour. Therefore we deactivate the onBlur flag here to ensure that everything works
					 * normally - S.Globals.TabFocus.addAdditionalElToLastFocusable / S.Globals.TabFocus.addPopup etc.
					 * Only the last element of Shift-Tab (after the user has left the window object) is not the first to be focussed again.
					 *
					 * firefox issue:
					 * #############
					 * in firefox the html (body) of the iframe gets focused first, that's the reason for the user
					 * to tab multiple times before he gets to the input field (if there is one)
					 */
					if ($iframe.length) {
						return;
					}

					this.userLeftThePage = true;
				};

				// and maybe the user clicks back inside the page and want to tab further. That's how we recognize that the user
				// is already in the page when he presses the tab-key
				window.onclick = () => {
					this.userLeftThePage = false;
				};
			});
		},

		/**
		 * add elements to additional el array for focus at the end
		 * @param {jQuery|HTMLCollection} elements
		 * @param {Boolean} isLast
		 */
		addAdditionalElToLastFocusable(elements, isLast) {
			const $elements = elements instanceof jQuery ? elements : $(elements);

			$elements.each((index, element) => {
				this.setTabindex(element, '-1', true);

				if (isLast) {
					this.additionalElLast.push(element);
				}
				else {
					this.additionalEl.push(element);
				}
			});
		},

		/**
		 * gets triggered from "keyDown" event
		 * - event.target is the previous once
		 *
		 * @param shiftKey
		 * @param event
		 */
		_handleTabKeyDown(shiftKey = false, event) //NOSONAR
		{
			this.additionalElToFocus = this.additionalEl.concat(this.additionalElLast);

			// if there is no next additionalElToFocus to focus to that is visible
			// - just do nothing and let the browser do the next focus
			if (!this._visibleAdditionalElToFocusTo().length) {
				return;
			}

			// we have to get this every time, because since initial loading maybe
			// some additionalEl changed visibility (visibility:hidden or display: none)
			const visibleAdditionalEl = this._visibleAdditionalElToFocusTo();

			// is the previous el was last focusableEl
			this.endReached = this.compareTargets(document.activeElement, visibleAdditionalEl[visibleAdditionalEl.length - 1]);

			// this.compareTargets(event.target, this.lastFocusableEl) - is the previous el the last "intended" focusableEl or
			// this._activeElIsInAdditionalElToFocusArr() - is the previous el in the additionalFocusableArr or
			// (document.activeElement === document.body && shiftKey) - the user pressed shift + tab on init (without clicking etc.) or gets back in to the window (page)
			if (this.compareTargets(event.target, this.lastFocusableEl) || this._activeElIsInAdditionalElToFocusArr() || (document.activeElement === document.body && shiftKey)) {
				if (shiftKey) {
					this._handleTabShiftBackwardsLogic(event);

					return;
				}

				this._handleTabForwardLogic(event);
			}
		},

		/**
		 * gets triggered from "keyUp" event
		 * - event.target is the current once
		 * @param event
		 */
		_handleTabKeyUp(event) {
			this.additionalElToFocus = this.additionalEl.concat(this.additionalElLast);

			// user left the browserWindow via tab and tabs through the browsers url, closeBtn etc.
			if (this.userLeftThePage && this._visibleAdditionalElToFocusTo().length) {
				// if there is no next additionalElToFocus to focus to
				// - just do nothing and let the browser do the next focus
				this._handleTabShiftBackwardsLogic(event);
			}

			// just revoke the variable state
			this.userLeftThePage = false;
		},

		/**
		 * @param {Event} event
		 */
		_handleTabShiftBackwardsLogic(event) {
			if (this.userLeftThePage) {
				// we have to get this every time, because since initial loading maybe
				// some additionalEl changed visibility (visibility:hidden or display: none)
				const visibleAdditionalEl = this._visibleAdditionalElToFocusTo();

				if (visibleAdditionalEl.length) {
					visibleAdditionalEl[visibleAdditionalEl.length - 1].focus();

					event.preventDefault();
				}

				return;
			}

			// check if the current active element is the additionalElArr
			// - if not we don't have to do anything
			if (this._activeElIsInAdditionalElToFocusArr()) {
				this._focusNextNeededEl(event);
			}
		},

		/**
		 * @param event
		 * @private
		 */
		_handleTabForwardLogic(event) {
			// the real last element is reached via tab (keydown)
			if (this.endReached) {
				this.lastFocusableEl.focus();

				return;
			}

			this._focusNextNeededEl(event);
		},

		/**
		 * @param event
		 * @private
		 */
		_focusNextNeededEl(event) {
			const visibleAdditionalElements = this._visibleAdditionalElToFocusTo();

			let nextElement = visibleAdditionalElements[0];

			// this means, nextElement/visibleAdditionalElements[0] is the current visible (maybe the only)
			// - therefor we need focus the last "intended" el on the page (if shiftKey is pressed)
			if (visibleAdditionalElements[0] === document.activeElement && event.shiftKey) {
				this.lastFocusableEl.focus();

				// if so, prevent the normal flow/event
				// - instead focus our custom el (only for keyDown)
				event.preventDefault();

				return;
			}

			for (const [index, el] of this.additionalElToFocus.entries()) {
				const nextIndex = index + 1,
					prevIndex = index === 0 ? null : index - 1,
					indexDirection = event.shiftKey ? prevIndex : nextIndex;

				// if the current el is the activeElement we all necessary information
				// - just check the shift key and count up/down
				if (document.activeElement === el) {
					const index = indexDirection !== null ? indexDirection : 0;

					nextElement = this.additionalElToFocus[index];

					break;
				}
			}

			// prevent the normal flow/event - instead focus our custom el
			event.preventDefault();

			nextElement.focus();
		},

		/**
		 * @returns {*|jQuery}
		 */
		_activeElIsInAdditionalElToFocusArr() {
			return $(document.activeElement).is(this.additionalElToFocus);
		},

		/**
		 * check for element visibility to focus
		 * @returns {null|*}
		 * @private
		 */
		_visibleAdditionalElToFocusTo() {
			return this.additionalElToFocus.filter((el, index) => //NOSONAR
			{
				if ($(el).css('display') !== 'none' && $(el).css('visibility') !== 'hidden') {
					return this.additionalElToFocus[index];
				}
			});
		},

		/**
		 * triggers the nearest (or if the tab-focused el is a module) module-instance _hasFocus() function
		 */
		handleTabFocusForModules(event) {
			const $target = event.target,
				currInstance = S.Utils.Helper.getTheNearestModuleInstance(event.target);

			if (currInstance && typeof currInstance._hasFocus === 'function') {
				currInstance._hasFocus($target, event);
			}
		},

		/**
		 * this checks, if the element has a tabindex - so its focusable and adds
		 * the enter/esc events with element and instance reference
		 * @param element
		 * @param instance
		 * @param value
		 */
		addPopup(element, instance, value = 0) {
			const $elements = element instanceof jQuery ? element : $(element);

			$elements.each((index, el) => {
				const $ctx = $(el);

				// check for focus ability and tabindex
				this.setTabindex($ctx, value);

				// set enter/esc event
				this.setPopupEvents($ctx, instance);

			});
		},

		/**
		 *
		 * @param element
		 */
		addElToLastFocusableEl(element) {
			const $element = element instanceof jQuery ? element : $(element);

			this.additionalElToFocus.push($element);
		},

		/**
		 * for a non keyboard focusable element like a div or span,
		 * this needs to have at least and tabIndex of "-1" or higher to be considered to be focusable
		 * (for details of keyboard focusable elements :: function getKeyboardFocusableElements())
		 */
		setTabindex(ctx, value = 0, forceToApply) //NOSONAR
		{
			const $ctx = ctx instanceof jQuery ? ctx : $(ctx);

			if ($ctx.length && (!this.isFocusable($ctx) || forceToApply)) {
				$ctx.attr('tabindex', value);
			}
		},

		/**
		 * Be aware:
		 * ########
		 * check for preventDefault() or stopPropagation() functions inside the original click event for opening
		 * the layer etc. They will prevent this as well, this is only a namespace separation, not an extra event
		 *
		 * @param {jQuery} $ctx
		 * @param {Object} instance
		 */
		setPopupEvents($ctx, instance) {
			// this delivers the actual focused el
			// - the keydown the old one (or none on init)
			$ctx.off('keyup.popupCtx').on('keyup.popupCtx', (event) => {
				const $target = $(event.target);

				// trigger nearest module instance
				if (event.key === 'Enter') {
					this.triggerFocusFunctions($target, instance, 'enter', event, null);
				}
			});
		},

		/**
		 * In the respective instance the _handleFocusEnter function is triggered when the user presses Enter.
		 * - The respective module or the parent module is addressed here.
		 *
		 * @param $target
		 * @param {Object} instance
		 * @param {String} key
		 * @param {Event} event
		 * @param {Function} closeCallbackFunction :: this gets executed on "Enter/ESC", when the closeBtn isn´t a button or the user presses "ESC"
		 */
		triggerFocusFunctions($target, instance, key, event, closeCallbackFunction) {
			// if the handed over param is a function, just execute it
			if (typeof closeCallbackFunction === 'function') {
				closeCallbackFunction();
			}

			// take the handed over instance, or get the closest module instance
			const currInstance = instance || S.Utils.Helper.getTheNearestModuleInstance($target);

			switch (key) {
				case 'escape':
					if (currInstance && typeof currInstance._handleFocusEsc === 'function') {
						currInstance._handleFocusEsc($target, event);
					}
					break;
				case 'enter':
					if (currInstance && typeof currInstance._handleFocusEnter === 'function') {
						currInstance._handleFocusEnter($target, event);
					}
					break;
			}
		},

		/**
		 * Gets keyboard-focusable elements within a specified element
		 * @param {HTMLElement} [ctx=document] element
		 * @returns {Array}
		 */
		getKeyboardFocusableElements: function (ctx = document) {
			const element = ctx instanceof jQuery ? ctx.get(0) : ctx,
				allTabableEl = element.querySelectorAll(
					'a[href], button, input, textarea, select, summary,[tabindex]:not([tabindex="-1"])'
				);

			return [].filter.call(allTabableEl, (el) => {
				return !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden');
			}
			);
		},

		/**
		 * check if an el is focusable
		 * @param el
		 * @returns {boolean|*}
		 */
		isFocusable(el) {
			const $el = el instanceof jQuery ? el : $(el);

			if (!$el.attr('disabled') && !$el.attr('aria-hidden')) {
				// in this case, we don't care about "tabindex: -1". This is focusable via .focus() function as well
				return $el.is('a[href], button, input, textarea, select, summary,[tabindex]');
			}

			return false;
		},

		/**
		 * set focus to container, so the user starts the next tab in the focused container
		 *
		 * Usage from outside:
		 * S.Globals.setFocusToContext(<jQuery|HTMLCollection>, <instance>, <jQuery|HTMLCollection>, <callBack>)
		 *
		 * @param {HTMLElement|jQuery} container
		 * @param {Object} instance
		 * @param {jQuery|HTMLCollection} closeBtn :: additional button/el thats normally not tab-able (like an img with an X and an close Event-Logic in JS)   :: experimental
		 * @param {Function} closeCallbackFunction :: this gets executed on "Enter/ESC", when the closeBtn isn´t a button or the user presses "ESC"
		 */
		setFocusToContext(container, instance, closeBtn, closeCallbackFunction) {
			const ctx = container instanceof jQuery ? container.get(0) : container[0],
				focusableEls = this.getKeyboardFocusableElements(ctx),
				firstFocusableEl = focusableEls[0];

			S.Utils.delayed(`set-focus-to-context`, 250, () => {
				if (firstFocusableEl) {
					// check for focus ability and tabindex
					this.setTabindex(container);

					// save el before the focus happened
					this.focusedElBeforePopupOpen = document.activeElement;

					// set tab index if needed (for focusing)
					this.setTabindex(ctx);

					// focus the container
					ctx.focus();

					// only let the user tab inside a given context
					this.trapFocus(ctx, instance, closeBtn, closeCallbackFunction);
				}
			});
		},

		/**
		 * keeps tab focus inside a container / element
		 *
		 * Usage from outside:
		 * S.Globals.trapFocus(<element>)
		 *
		 * @param {HTMLElement|jQuery} element
		 * @param {Object} instance
		 * @param {jQuery|HTMLCollection} closeBtn :: additional button/el thats normally not tab-able (like an img with an X and an close Event-Logic in JS)
		 * @param {Function} closeCallbackFunction :: this gets executed on "Enter/ESC", when the closeBtn isn´t a button or the user presses "ESC"
		 */
		trapFocus(element, instance, closeBtn, closeCallbackFunction) {
			// just wait a tick so all stuff can be rendered and executed
			// - for example: magnific-popup X button gets added a little bit later
			S.Utils.delayed('trap-focus', 10, () => {
				const $ctx = element instanceof jQuery ? element : $(element),
					$closeBtn = this.prepareCloseButton(closeBtn),
					focusableEls = this.getKeyboardFocusableElements($ctx),
					firstFocusableEl = focusableEls[0],
					lastFocusableEl = focusableEls[focusableEls.length - 1];

				// only for event namespace we converted this into jquery, for easy solution (no additional Vanilla-JS function etc.)
				$ctx.off('keydown.trapFocus').on('keydown.trapFocus', (event) => {
					const isTabPressed = (event.key === 'Tab' || event.keyCode === 9);

					// ESC support - close on ESC key
					if (event.key === 'ESC' || event.key === 'Escape' || event.keyCode === 27) {
						this.triggerFocusFunctions($ctx, instance, 'escape', event, closeCallbackFunction);

						this.focusLastElBeforePopupOpen();
					}

					// only go further when tab is pressed
					if (!isTabPressed) {
						return;
					}

					// shift + tab
					if (event.shiftKey) {
						if (document.activeElement === firstFocusableEl) {
							lastFocusableEl.focus();
							event.preventDefault();
						}
					}
					// tab
					else {
						if (document.activeElement === lastFocusableEl) {
							firstFocusableEl.focus();
							event.preventDefault();
						}
					}

					if (closeBtn) {
						this.handleCloseButtonLogic($ctx, $closeBtn, closeCallbackFunction);
					}
				});
			});
		},

		/**
		 *
		 * @param closeBtn
		 * @returns {{length}|jQuery|*|jQuery|HTMLElement|boolean}
		 */
		prepareCloseButton(closeBtn) {
			const $closeBtn = closeBtn instanceof jQuery ? closeBtn : $(closeBtn);

			// check for focus ability and tabindex
			this.setTabindex($closeBtn);

			return $closeBtn.length ? $closeBtn : false;
		},

		/**
		 * if there is a custom el for closing a layer (like span, div, img as X) and this needs to be focused as well
		 * and should trigger a custom closeCallback or just trigger a click
		 * @param $ctx
		 * @param $closeBtn
		 * @param closeCallbackFunction
		 */
		handleCloseButtonLogic($ctx, $closeBtn, closeCallbackFunction) {
			if (!$closeBtn.is(':button')) {
				/**
				 * if we hand over a special close button
				 * for none focusable el, like X icon as img, pressing enter won´t trigger click (like button does)
				 * for maybe handed over callback function to get triggered
				 */
				$ctx.off('keyup.trapFocus').on('keyup.trapFocus', (e) => {
					if ($closeBtn.is($(e.target)) && e.key === 'Enter') {
						// only trigger when it´s the target
						$closeBtn.trigger('click');

						// if there was a cb handed over
						if (typeof closeCallbackFunction === 'function') {
							closeCallbackFunction();
						}
					}
				});
			}
		},

		/**
		 * focus the last element before the popup was focused
		 */
		focusLastElBeforePopupOpen() {
			S.Utils.delayed('focus-last-tab-focused-el', 200, () => {
				this.focusedElBeforePopupOpen.focus();
			});
		},

		/**
		 * @param target :: since keydown is the previous target - we can compare against the old focused el
		 * @param compareTarget :: el to compare against
		 */
		compareTargets(target, compareTarget) {
			/**
			 * target === this.lastFocusableEl and $(target).is($(this.lastFocusableEl))
			 *
			 * ...is basically the same, instead Object.is() treads +0, -0, and "NaN" differently and more accurate.
			 */
			return target === compareTarget;
		},

		/**
		 * get current focused element
		 * - can be called from outside
		 *
		 * Function call:
		 * S.Globals.TabFocus.getCurrentFocusedElement()
		 *
		 * @returns {*|jQuery|HTMLElement}
		 */
		getCurrentFocusedElement() {
			return $(':focus').get(0);
		},

		/**
		 * check current focused element
		 * @returns {{$focusedEl: (*|jQuery|HTMLElement), hasFocus: (*|jQuery)}}
		 */
		checkIfElementFocused(ctx) {
			// Get the focused element:
			const $ctx = ctx instanceof jQuery ? ctx : $(ctx);

			return $ctx.is(':focus');
		},

		clickTouchstart($doc, $body) {
			$doc.on('click.keyboardFocus touchstart.keyboardFocus', () => {
				if ($body.hasClass(this.isKeyboardFocus)) {
					$body.removeClass(this.isKeyboardFocus);
				}
			});
		},
	};

	S.Globals.MediaQuery = {

		/*
		 * String: 'desktop', 'tablet' or 'smartphone'
		 */
		mqRightNow: '',

		init() {
			S.Utils.Helper.mq('desktop').addListener(this.checkMediaQuery.bind(this));
			S.Utils.Helper.mq('tablet').addListener(this.checkMediaQuery.bind(this));
			this.checkMediaQuery();
		},

		checkMediaQuery() {
			if (S.Utils.Helper.mq('desktop').matches) {
				this.mqRightNow = 'desktop';
			}
			else if (S.Utils.Helper.mq('tablet').matches) {
				this.mqRightNow = 'tablet';
			}
			else {
				this.mqRightNow = 'smartphone';
			}
		},
	};

	S.Globals.lazyloadertx = {
		init() {
			S.Lazy.update();
		},
		loadImagesForPrint() {
			S.Lazy.unveilAllLazyImages();
		},
		setEventPrint() {
			S.Lazy.lazyPrintEvent();
		},
	};

	S.Globals.checkMobileOS = {

		init() {
			if ($('.js-apple').length || $('.js-android').length) {
				if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
					$('.js-android').hide();
				}

				if (/Android/i.test(navigator.userAgent)) {
					$('.js-apple').hide();
				}
			}
		}
	};

	S.Globals.CalculateOffset = {
		/**
		 * calculates the actual top offset from a given element to a given parent.
		 * constraint: parent has to be positioned (in css position relative or absolute)
		 * @param element
		 * @param parent
		 */
		topToParent(element, parent) {
			let currentElement = element;
			let top = 0;

			if (parent.length > 0) {
				while (!currentElement.is(parent)) {
					if (currentElement.css('position') !== 'static') {
						top = top + currentElement.position().top;
					}
					currentElement = currentElement.parent();
				}
			}

			return top;
		}
	};

})(jQuery);

jQuery(document).ready(function () {
	S.Globals.TabFocus.init();
	S.Globals.MediaQuery.init();
	S.Globals.checkMobileOS.init();
});
