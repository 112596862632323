(function ($) {
	'use strict';

	/**
	 * BasicInputSelect module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputSelect
	 * @extends T.Module
	 */
	T.Module.BasicInputSelect.Newdesign = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {Object} */
		$scrollPaneApi: null,

		/** @type {Object} */
		$hiddenSelect: null,

		/** @type {Object} */
		$dropDown: null,

		/** @type {Object} */
		$dropDownList: null,

		/** @type {Object} */
		$toggleBtn: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$dropDownContainer = this.$ctx.find('.aa-options');
			this.$hiddenSelect = this.$ctx.find('> select');
			this.$dropDownList = this.$ctx.find('.aa-options-list');
			this.$toggleBtn = this.$ctx.find('.aa-btn-selection');
			this.$validateSelects = this.$ctx.closest('.l-form-general').find('.js-select-validate');

			const that = this;

			// set tabIndex and enter event
			S.Globals.TabFocus.addPopup(that.$toggleBtn, this);
			S.Globals.TabFocus.addPopup(that.$dropDownList.find('li'), this);

			if (that.$dropDownContainer.length && that.$toggleBtn.length) {

				that.$toggleBtn.on('click', () => {
					if (!that.$toggleBtn.parent().hasClass('is-locked')) {
						that.toggleDropDown();
					}
				});

				that.$dropDownList.find('li').on('keydown', (item) => {
					if ($(item.currentTarget).index() === (that.$dropDownList.find('li').length - 1) && that.$dropDownContainer.hasClass('is-opened') && item.which === 9) {
						that.toggleDropDown();
					}
				});

				// close dropDown if something else is clicked
				$(window).on('click', (e) => {

					if (that.$dropDownContainer.hasClass('is-opened') &&
						!that.$ctx.is(e.target) &&
						!that.$ctx.find('*').is(e.target)) {

						that.$toggleBtn.removeClass('is-opened');

						// hide dropdown
						that.$dropDownContainer.stop().slideUp(() => {
							that.$dropDownContainer.css('height', '').removeClass('is-opened');
						});
					}
				});
			}

			this.setDisable();

			this.$validateSelects.on('click', () => {
				this.setDisable();
			});

			that.syncDropDown();

			this._parent.start(resolve);
		},

		/**
		 * This is triggered when the user press Enter on the element which where handed over via "S.Globals.TabFocus.addPopup(<element>, <instance>);"
		 * in the (maybe start()) function.
		 *
		 * @param {jQuery} $target - currently tab focused el
		 */
		// eslint-disable-next-line
		_handleFocusEnter($target) //NOSONAR Complexity
		{
			// only for info icons
			if ($target.is(this.$toggleBtn)) {
				S.Utils.delayed('enter-focus-select', 100, () => {
					if (!this.$toggleBtn.parent().hasClass('is-locked')) {
						this.toggleDropDown();

						this.$dropDownContainer.on('keydown', (e) => {
							if (e.which === 40 || e.which === 38 || e.which === 9) {
								e.preventDefault();
							}
						});

						this.$dropDownContainer.on('keyup', (e) => {
							if (e.which === 27) {
								this.toggleDropDown();
								this.$toggleBtn.focus();
							}
							else if (e.which === 40) {
								if (this.$dropDownList.find('li:focus').index() === (this.$dropDownList.find('li').length - 1)) {
									this.toggleDropDown();
									this.$toggleBtn.focus();
								}
								else {
									this.$dropDownList.find('li:focus').next().focus();
								}
							}
							else if (e.which === 38) {
								if (this.$dropDownList.find('li:focus').index() === 0) {
									this.toggleDropDown();
									this.$toggleBtn.focus();
								}
								else {
									this.$dropDownList.find('li:focus').prev().focus();
								}
							}
						});
					}
				});
			}
			else if ($target.closest('.aa-options-list').length > 0) {
				$target.trigger('click');
			}
		},

		/**
		 * sync selection with hidden drop-down for easier validation
		 *
		 */
		syncDropDown: function () {
			const that = this;
			const dataValue = 'data-value';


			this.$dropDownList.find('> li').each((index, item) => {
				// set selected item as default-selection on init
				if ($(item).hasClass('is-active')) {
					that.$hiddenSelect.val($(item).attr(dataValue));
					that.$hiddenSelect.trigger('change');
					that.showSelection(item);
				}

				if (this.$hiddenSelect.children().eq(index).prop('disabled')) {
					$(item).addClass('is-disabled');
				}

				$(item).on('click', function () {

					if (!$(item).hasClass('is-active') && !$(item).hasClass('is-disabled')) {

						$(item).addClass('is-active').siblings().removeClass('is-active');

						// set dropdown-value to selected value from li
						that.$hiddenSelect.val($(item).attr(dataValue));
						that.$hiddenSelect.trigger('change');

						that.showSelection(item);

						// close dropdown
						that.toggleDropDown();
					}
				});

				// if option:selected is maintained dynamically add class is-active & run function showSelection
				this.$ctx.on('change', () => {
					this.$dropDownList.find('> li').each((index, item) => {
						if (that.$hiddenSelect.find('option:selected').val() === $(item).attr(dataValue)) {
							$(item).addClass('is-active');
							that.showSelection(item);
						}
					});
				});
			});
		},

		// set input disabled if brand value is unset
		setDisable() {
			const disabledSelects = this.$validateSelects.filter(':not(.js-activator)');

			// if Activators' value is not choosen yet, then lock upcoming dropdown via class is-locked
			if (this.$validateSelects.hasClass('js-activator') && !this.$validateSelects.find('> li').hasClass('is-active')) {
				disabledSelects.parent().parent().addClass('is-locked');
			}
			else {
				disabledSelects.parent().parent().removeClass('is-locked');
			}
		},


		/**
		 * copy selected content to toggle-button to show current selection
		 *
		 * @param (item)
		 */
		showSelection: function (item) {

			const that = this,
				itemText = $(item).text();

			that.$toggleBtn.text(itemText).removeClass('has-content');
		},

		/**
		 * show/hide dropdown and init/destroy scrollbar
		 *
		 */
		toggleDropDown: function () {

			const that = this;

			if (that.$dropDownContainer.hasClass('is-opened')) {

				that.$toggleBtn.removeClass('is-opened');

				// hide dropdown
				that.$dropDownContainer.stop().slideUp(300, () => {
					that.$dropDownContainer.css('height', '').removeClass('is-opened');
				});

				that.$dropDownContainer.off('keyup');
				that.$dropDownContainer.off('keydown');
			}

			else {
				this.checkScrollPosition();
				that.$toggleBtn.addClass('is-opened');

				// show dropdown
				that.$dropDownContainer.stop().addClass('is-opened').slideDown(() => {
					that.$dropDownContainer.css('height', '');
				});

				that.$dropDownContainer.promise().done(() => {
					that.setScrollBar('init');
				});

				setTimeout(function () {
					that.$dropDownList.find('li').first().focus();
				}, 500);

			}
		},

		/**
		 * init custom scrollbar for dropDown
		 *
		 * @param mode
		 *
		 */
		setScrollBar: function (mode) {

			const that = this,
				heightBuffer = 20;

			if (mode === 'init') {

				that.$dropDownContainer.each(function (index, item) {

					const containerHeight = $(item).outerHeight(),
						listHeight = that.$dropDownList.outerHeight();

					if (!$(item).hasClass('jspScrollable')) {

						// check if item is bigger than container ( + buffer )
						if (listHeight >= (containerHeight + heightBuffer)) {
							$(item).jScrollPane({ showArrows: true });

							// prevent arrow-btn events
							that.$ctx.find('.jspArrow').off();

							that.$scrollPaneApi = $(item).data('jsp');
						}
					}

					// reinit for width calculation
					else {
						that.$scrollPaneApi.reinitialise();
					}
				});
			}
		}

	});
}(jQuery));
