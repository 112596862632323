/* eslint-disable eqeqeq */
(function ($) {
	'use strict';

	S.Calculator = S.Calculator || {};

	S.Calculator.AksLongTerm = {

		/** @type {jQuery} */
		$form: null,

		/** @type {String} */
		toggleContent: '',

		/** @type {String} */
		calculatorAksLongTerm: '',

		/** @type {String} */
		membership: '',

		/** @type {Number} */
		age: 18,

		/** @type {String} */
		dateOfBeginning: '',

		/** @type {String} */
		term: 2,

		/** @type {Date} */
		currentDate: new Date(),

		/** @type {Object} */
		urlvars: [],

		/** @type {Boolean} */
		loggedin: false,

		/** @type {String} */
		produktKey: '',

		/**
		 * init form actions
		 *
		 */
		init: function () {
			this.calculatorAksLongTerm = '#calculator_aks_long_term';
			this.$form = $(this.calculatorAksLongTerm).eq(0);

			// classes/events/attr as strings
			this.toggleContent = '.m-basic-toggle .js-content';
			this.dateOfBirthInsurant = '#date_of_birth_insurant';
			this.dateNotValidString = '<br>Fehler: Datum nicht valide';
			this.insuranceStart = '#insurancestart';
			this.jsCalculatorProposal = '.js-calculator-proposal';
			this.isDisabled = 'is-disabled';
			this.selectTerm = '#select_term';
			this.jsResultTermReset = '.js-result-term-reset';
			this.jsResultValid = '.js-result-valid';

			// get url
			const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			let hash;

			for (let i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				this.urlvars.push(hash[0]);
				this.urlvars[hash[0]] = hash[1];
			}

			// ####### begin: Dummy ######
			$(this.toggleContent).append(`<br>Eingeloggt: ${this.loggedin}`);

			// simulate login
			if (typeof this.urlvars['loggedin'] != 'undefined') { //NOSONAR
				this.loggedin = true;
			}
			// simulate ADACMitglied
			if (typeof this.urlvars['ADACMitglied'] != 'undefined') { //NOSONAR
				this.membership = 'ADACMitglied';
			}
			// simulate ADACPlusMitglied
			if (typeof this.urlvars['ADACPlusMitglied'] != 'undefined') { //NOSONAR
				this.membership = 'ADACPlusMitglied';
			}
			// ####### end: Dummy ######

			if (this.$form.length > 0) {
				// check date of birth
				this.checkDateOfBirth();

				// check date of beginning
				this.checkDateOfBeginning();

				setTimeout(() => {
					if ($(this.dateOfBirthInsurant).val() !== '') {
						// get age
						this.getAge();
					}
				}, 200);

				// check term
				this.checkTerm();

				// check membership :: Kunde || ADACMitglied || ADACPlusMitglied
				this.checkMembership();
			}

			// calculate
			this.calculate();

			// init proposal btns
		},

		/**
		 * check date of birth
		 *
		 * @param {jQuery} elem
		 * @param {Boolean} valid
		 */
		setChecked: function (elem, valid) {
			if (valid) {
				elem.closest('.ll-row').addClass('is-check is-valid').removeClass('is-error');
			}
			else {
				elem.closest('.ll-row').addClass('is-check is-error').removeClass('is-valid');
			}
		},

		tiggerCheckDateOfBirth: function () {
			if (this.$form.length > 0) {
				const elem = this.$form.find('#date_of_birth'),
					that = this,
					valArray = $(elem).val().split('.');
				let dateFormat = valArray[0];

				if (valArray.length >= 2) {
					dateFormat = `${valArray[2]}-${valArray[1]}-${valArray[0]}`;
				}
				const date = new Date(dateFormat),
					day = that.currentDate.getDate(),
					year = that.currentDate.getFullYear();

				let month = that.currentDate.getMonth() + 1;

				if (day < 10) {
					month = `0${day}`;
				}
				if (month < 10) {
					month = `0${month}`;
				}

				const maxDate = new Date(`${year - 120}-${month}-${day}`);

				if ($(elem).val() === '') {
					$(this.toggleContent).append('<br>Fehler: Geburt leer');
				}
				else {
					// if date is valid
					if (!isNaN(date.getTime())) {
						const dif = that.currentDate.getFullYear() - date.getFullYear();
						if (date < maxDate) {
							// check row
							that.setChecked($(elem), false);
							$(this.toggleContent).append('<br>Fehler: Alter außerhalb zul. Bereich');
						}
						else if (this.currentDate < date) {
							$(this.toggleContent).append('<br>Fehler: Geburt in der Zukunft');
							that.setChecked($(elem), false);
						}
						else {
							// check row
							that.setChecked($(elem), true);

							// update age
							that.age = dif;

							// get age
							that.getAge();

							// update term selct
							that.updateTermSelect();
						}
					}
					else {
						// check row
						that.setChecked($(elem), false);
						$(this.toggleContent).append(this.dateNotValidString);
					}
				}
				that.checkTermForValidity();
			}
		},

		/**
		 * check date of birth
		 *
		 */
		checkDateOfBirth: function () {
			const that = this;

			// init blur
			this.$form.find(this.dateOfBirthInsurant).on('change', (e) => {
				const valArray = $(e.currentTarget).val().split('.');
				let dateFormat = valArray[0];

				if (valArray.length >= 2) {
					dateFormat = `${valArray[2]}-${valArray[1]}-${valArray[0]}`;
				}

				const date = new Date(dateFormat),
					day = that.currentDate.getDate(),
					year = that.currentDate.getFullYear();
				let month = that.currentDate.getMonth() + 1;

				if (day < 10) {
					month = `0${day}`;
				}
				if (month < 10) {
					month = `0${month}`;
				}

				const maxDate = new Date(`${year - 120}-${month}-${day}`);

				if ($(e.currentTarget).val() === '') {
					$(this.toggleContent).append('<br>Fehler: Geburt leer');
					that.setChecked($(e.currentTarget), false);
					that.age = 18;
				}
				else {
					// if date is valid
					if (!isNaN(date.getTime())) {
						const dif = that.currentDate.getFullYear() - date.getFullYear();
						if (date < maxDate) {
							// check row
							that.setChecked($(e.currentTarget), false);
							$(this.toggleContent).append('<br>Fehler: Alter außerhalb zul. Bereich');
						}
						else if (this.currentDate < date) {
							$(this.toggleContent).append('<br>Fehler: Geburt in der Zukunft');
							that.setChecked($(e.currentTarget), false);
						}
						else {
							// check row
							that.setChecked($(e.currentTarget), true);

							// update age
							that.age = dif;

							// get age
							that.getAge();

							// update term selct
							that.updateTermSelect();
						}
					}
					else {
						// check row
						that.setChecked($(e.currentTarget), false);
						$(this.toggleContent).append(this.dateNotValidString);
					}
				}
				that.checkTermForValidity();
			});
		},

		/**
		 * check date of beginning
		 *
		 */
		checkDateOfBeginning: function () {
			const that = this;
			const $insuranceStart = $(this.insuranceStart);

			const dateFormatMax = moment().add(1, 'y').format('YYYY-MM-DD');
			const maxDate = new Date(dateFormatMax);

			setTimeout(() => {
				that.dateOfBeginning = $insuranceStart.val();
				that.calculate();
			}, 200);

			// init change
			$(this.insuranceStart).on('change', (e) => {
				const valArray = $(e.currentTarget).val().split('.');
				let dateFormat = valArray[0];
				const jsResultValidRate = '.js-result-valid, .ll-rate';

				if (valArray.length >= 2) {
					dateFormat = `${valArray[2]}-${valArray[1]}-${valArray[0]}`;
				}
				const date = new Date(dateFormat);

				if ($(e.currentTarget).val() === '') {
					$(this.toggleContent).append('<br>Fehler: Begin leer');
					that.setChecked($(e.currentTarget), false);
				}
				else {
					// if date is valid
					if (!isNaN(date.getTime())) {
						if (date <= that.currentDate) {
							$(this.toggleContent).append('<br>Fehler: Begin Vergangenheit/Heute');
							that.setChecked($(e.currentTarget), false);
							$(this.jsCalculatorProposal).prop('disabled', true).addClass(this.isDisabled);
							$(jsResultValidRate).hide();
						}
						else if (date > maxDate) {
							$(this.toggleContent).append('<br>Fehler: Begin zu weit in der Zukunft');
							that.setChecked($(e.currentTarget), false);
							$(this.jsCalculatorProposal).prop('disabled', true).addClass(this.isDisabled);
							$(jsResultValidRate).hide();
						}
						else {
							// check row
							that.setChecked($(e.currentTarget), true);
							$(this.jsCalculatorProposal).prop('disabled', false).removeClass(this.isDisabled);
							$(jsResultValidRate).show();
						}
					}
					else {
						// check row
						that.setChecked($(e.currentTarget), false);
						$(this.toggleContent).append(this.dateNotValidString);
					}
				}

				that.dateOfBeginning = $(this.insuranceStart).val();

				// get age
				that.getAge();

				// update term selct
				that.updateTermSelect();
			});
		},

		/**
		 * get age
		 *
		 */
		getAge() {
			this.age = S.Utils.Form.getAge($(this.dateOfBirthInsurant).val(), this.dateOfBeginning);

			// calculate
			this.calculate();
		},

		checkTermForValidity() {
			const termVal = this.$form.find(this.selectTerm).val(),
				isItANumber = !isNaN(termVal);

			let overlappingAllowedVals = parseInt(this.$form.attr('data-over-76'), 10);

			if (this.age >= 66 && this.age <= 75) {
				overlappingAllowedVals = parseInt(this.$form.attr('data-under-76'), 10);
			}
			else if (this.age < 66) {
				overlappingAllowedVals = parseInt(this.$form.attr('data-under-66'), 10);
			}

			//When the page loading and LAUFZEIT undefined, Always set LAUFZEIT to 2
			// Or When updateTermSelect && LAUFZEIT option field < overlappingAllowedVals
			if (!isItANumber && this.term < overlappingAllowedVals) {
				this.term = 1;
			}
			else if (!isItANumber || termVal > overlappingAllowedVals) {
				// reset term duration on birthday change
				this.$form.find(this.selectTerm).val(this.$form.find(this.selectTerm).find('option').eq(0).val());
				this.term = 2;
				this.$form.find(this.jsResultTermReset).removeClass('h-none');
				this.$form.find(this.jsResultValid).addClass('h-none').hide();
				this.$form.find(this.jsCalculatorProposal).hide();
			}
			else {
				this.$form.find(this.jsResultTermReset).addClass('h-none');
				this.$form.find(this.jsResultValid).removeClass('h-none').show();
				this.$form.find(this.jsCalculatorProposal).show();
			}
		},

		/**
		 * check term
		 *
		 */
		checkTerm: function () {
			const that = this;

			// initial get only if selected option is a number
			if ($(this.selectTerm).val().match(/^\d+$/)) {
				that.term = $(this.selectTerm).val();
			}

			$(this.selectTerm).on('change', (e) => {
				that.checkTermForValidity();

				if (isNaN($(e.currentTarget).val())) {
					that.term = 1;
				}
				else {
					that.term = $(this.selectTerm).val();
				}

				that.calculate();
			});
		},

		/**
		 * update term select
		 *
		 */
		updateTermSelect: function () {
			const selectField = $(this.selectTerm),
				start = selectField.find('option').length;

			let max = 0;

			if (this.age < 66) {
				max = $(this.calculatorAksLongTerm).attr('data-under-66');
			}
			else if (this.age >= 66 && this.age <= 75) {
				max = $(this.calculatorAksLongTerm).attr('data-under-76');
			}
			else if (this.age >= 76) {
				max = $(this.calculatorAksLongTerm).attr('data-over-76');
			}

			$(this.selectTerm).find('option').show();

			const populate = () => {
				let indexOfSelectedItem;

				$.each(selectField.find('option'), (index, option) => {
					if ($(option).prop('selected')) {
						indexOfSelectedItem = index + 1;
					}

					if (index !== 0) {
						$(option).remove();
					}
				});

				if (indexOfSelectedItem) {
					selectField.find('option').eq(0).removeAttr('selected');
				}

				for (let i = 2; i <= max; i++) {
					$(`<option value="${i}" ${i === indexOfSelectedItem ? 'selected' : ''}>${i}</option>`).appendTo(selectField);
				}
			};

			if (start - 1 !== parseInt(max, 10)) {
				populate();
			}
		},

		/**
		 * check membership :: Kunde || ADACMitglied || ADACPlusMitglied
		 *
		 */
		checkMembership: function () {
			const that = this,
				membershipPriceStandardAndPlus = '#membership_price_standard, #membership_price_plus',
				inputRadioGetRateChecked = 'input[name="radio_get_rate"]:checked',
				membershipPriceStandard = '#membership_price_standard',
				membershipPricePlus = '#membership_price_plus',
				radioMembership2 = '#radio_membership_2',
				radioMembership3 = '#radio_membership_3';

			// initial setting
			this.membership = this.$form.find('input[name="membership"]:checked').val();

			if (that.membership === 'ADACMitglied' || that.membership === 'ADACPlusMitglied') {
				$(membershipPriceStandardAndPlus).hide();

				// hide invalid text
				$('#type_text_invalid').hide();
			}

			// init change
			this.$form.find('input[name="membership"]').on('change', function () {
				that.membership = that.$form.find('input[name="membership"]:checked').val();

				// hide extra membership price
				if (that.membership === 'ADACMitglied' || that.membership === 'ADACPlusMitglied') {
					$(membershipPriceStandardAndPlus).hide();
					that.membership = 'ADACMitglied';
				}
				else {
					if (that.$form.find(inputRadioGetRateChecked).val() === '1') {
						$(membershipPriceStandard).show();
						$(membershipPricePlus).hide();

						that.membership = 'ADACMitglied';
					}
					else if (that.$form.find(inputRadioGetRateChecked).val() === '2') {
						$(membershipPricePlus).show();
						$(membershipPriceStandard).hide();

						that.membership = 'ADACMitglied';
					}
					else {
						that.membership = 'Kunde';
					}
				}

				// calculate
				that.calculate();
			});

			// extra init change not member :: width membership || width membershipPlus || without membership
			this.$form.find('input[name="radio_get_rate"]').on('change', () => {
				if (that.$form.find(inputRadioGetRateChecked).val() === '3') {
					// hide extra membership price
					$(membershipPriceStandardAndPlus).hide();

					that.membership = 'Kunde';
				}
				else {
					// reset membership to calculate
					if (that.$form.find(inputRadioGetRateChecked).val() === '1') {

						// hide/show extra membership price
						$(membershipPriceStandard).show();
						$(membershipPricePlus).hide();

						that.membership = 'ADACMitglied';
					}
					else if (that.$form.find(inputRadioGetRateChecked).val() === '2') {

						// hide/show extra membership price
						$(membershipPriceStandard).hide();
						$(membershipPricePlus).show();

						that.membership = 'ADACMitglied';
					}
				}

				// calculate
				that.calculate();
			});

			// if logged in
			if (this.loggedin) {
				if (this.membership === 'Kunde') {
					// if not member
					$(radioMembership2).prop('disabled', true);
					$(radioMembership3).prop('disabled', true);

					if (that.$form.find('input[name="radio_type"]:checked').val() === 'EXKLUSIV'
						|| that.$form.find('input[name="radio_type"]:checked').val() === 'PREMIUM') {
						// reset membership to calculate
						that.membership = 'ADACMitglied';
					}
				}
				else {
					// hide extra membershiop prices
					$(membershipPricePlus).hide();
					$(membershipPriceStandard).hide();

					if (this.membership === 'ADACMitglied') {
						// if member
						$('#radio_membership_1').prop('disabled', true);
						$(radioMembership3).prop('disabled', true);
						$(radioMembership2).trigger('click').blur();
					}
					else if (this.membership === 'ADACPlusMitglied') {
						// if member plus
						$('#radio_membership_1').prop('disabled', true);
						$(radioMembership2).prop('disabled', true);
						$(radioMembership3).trigger('click').blur();
					}
				}
			}
		},

		/**
		 * calculate
		 *
		 */
		calculate: function () {
			const that = this;
			let result = '';

			if (!$(this.insuranceStart).val().length) {
				that.checkDateOfBeginning();
				return;
			}

			$.getJSON($(this.calculatorAksLongTerm).data('json'), (data) => {
				let count = 0; // count result for testing
				const dummyArray = []; // for testing


				$.each(data.AKS, function (index, item) {
					if (item.Mitglied === that.membership
						&& parseInt(item.MinimalAlter) <= that.age
						&& parseInt(item.MaximalAlter) >= that.age
						&& item.Laufzeit === that.term) {
						count++; // dummy
						dummyArray.push(item.ProduktKey); // dummy

						that.produktKey = item.ProduktKey;
						result = item.Beitrag;

						// if result value is for example 13.9, add 0
						if (result.match(/\.\d+$/g)) {
							result = `${result}0`;
						}
					}
				});

				let restultsring = '';
				for (let x = 0; x < dummyArray.length; x++) {
					restultsring += `${dummyArray[x]}, `;
				}

				$(this.toggleContent).append(`<br><strong>Kriterien: </strong>Mitgliedschaft: ${that.membership}, Versicherungsdauer: ${that.term}, Alter: ${that.age}, Reisebeginn: ${that.dateOfBeginning}`);
				$(this.toggleContent).append(`<br>${count} Ergebnisse(${restultsring}) => Tarif: ${$.number(result, 2, ',')}`);

				// update sticky box
				$('.ll-rate-value').html(result.replace('.', ','));
			}).done(() => {
				if ($(this.insuranceStart).val() === '') {
					$(this.jsCalculatorProposal).prop('disabled', true).addClass(this.isDisabled);
					$(this.jsResultValid).hide();
				}
				else {
					$('.js-result-error').hide();
					$(this.jsCalculatorProposal).prop('disabled', false).removeClass(this.isDisabled);
					if (that.$form.find(this.jsResultTermReset).is(':hidden')) {
						$(this.jsResultValid).show();
					}
				}
			}).fail(() => {
				$('.js-result-error').show();
				$(this.jsCalculatorProposal).prop('disabled', true).addClass(this.isDisabled);
				$(this.jsResultValid).hide();
			});
		}
	};

	$(document).ready(() => {
		if ($('#calculator_aks_long_term').length) {
			S.Calculator.AksLongTerm.init();
		}
	});

})(jQuery);
