(function ($) {
	'use strict';
	/**
	 * Swipeable decorator for the vestab module
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class Tab
	 * @extends T.Module
	 */
	T.Module.VesTab.Swipeable = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$swiperContainer: null,

		/** @type {jQuery} */
		$swiperSlidesWrapper: null,

		/** @type {Object[]} */
		$swiperSlides: null,

		/** @type {jQuery} */
		$swiperBtnLeft: null,

		/** @type {jQuery} */
		$swiperBtnRight: null,

		/**
		 * Initialize
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$swiperContainer = this.$ctx.find('.swiper');
			this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper').eq(0);
			this.$swiperSlides = this.$ctx.find('.swiper-slide');
			this.$swiperBtnLeft = this.$swiperContainer.find('.swiper-button-prev');
			this.$swiperBtnRight = this.$swiperContainer.find('.swiper-button-next');

			// remove lazyload class from picture/img and replace them with swiper-lazy class
			// just in case there are images
			S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides, true);

			this.initSwiper();

			this._parent.start(resolve);
		},

		/**
		 * init swiper
		 */
		initSwiper: function () {
			const simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				keyboardControl = this.$ctx.data('mode') !== 'edit'; // if sitecore edit mode

			// if the component is not set up in default structure (with swiper containers etc.),
			// e.g. because handlebar usage was not possible, we can build the necessary structure manually
			// by wrapping the elements and adding swiper-classes
			if (!this.$swiperContainer.length && !this.$ctx.children().eq(0).hasClass('mm-tabcontent')) {
				// containers
				// find first direct child of ctx, make it a swiper-wrapper and wrap it as a swiper
				this.$ctx.children().eq(0).addClass('swiper-wrapper').wrap('<div class="swiper"></div>');
				this.$swiperContainer = this.$ctx.children().closest('.swiper');
				this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper').eq(0);

				// slides
				// find direct li-children of slides-wrapper and make each of them a swiper-slide
				this.$swiperSlidesWrapper.children('li').each().addClass('swiper-slide');
				this.$swiperSlides = this.$ctx.find('.swiper-slide');

				// buttons
				// append them to the container
				this.$swiperContainer.append("<div class='swiper-button-prev'></div><div class='swiper-button-next'></div>");
				this.$swiperBtnLeft = this.$swiperContainer.find('.swiper-button-prev');
				this.$swiperBtnRight = this.$swiperContainer.find('.swiper-button-next');
			}

			// init swiper container
			const mySwiper = new Swiper(this.$swiperContainer[0], {
				slidesPerView: 'auto',
				simulateTouch: simulateTouch,
				watchSlidesProgress: true,
				preloadImages: false,
				keyboard: {
					enabled: keyboardControl,
				},
				navigation: {
					prevEl: this.$swiperBtnLeft[0],
					nextEl: this.$swiperBtnRight[0],
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						speed: 500,
						slidesPerGroupAuto: false,
						preventInteractionOnTransition: false,
					},
					769: {
						slidesPerGroupAuto: true,
						preventInteractionOnTransition: true,
					},
					1024: {
						speed: 1500,
						slidesPerGroupAuto: true,
						preventInteractionOnTransition: true,
					}
				},
				lazy: {
					checkInView: true,
					loadPrevNextAmount: 2,
					loadPrevNext: true,
				},
				on: {
					init: (swiper) => {
						// enables/disables the swiper
						// - In the old logic, the slides were not centred
						S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper);
					},
					resize: (swiper) => {
						S.Utils.delayed('vestab-swipeable-windowResize', 40, () => {
							// enables/disables the swiper
							// - In the old logic, the slides were not centred
							S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper);
						});
					}
				},
			});

			if (typeof this._sandbox.swiperInstance === 'undefined') {
				this._sandbox.swiperInstance = {};
			}
			this._sandbox.swiperInstance[this.$ctx.data('t-id')] = mySwiper;

			// set width of swiper-wrapper
			let slideWidth = 0;

			this.$swiperSlidesWrapper
				.children('li')
				.each((index, elem) => {
					slideWidth += $(elem).outerWidth();
				})
				.promise()
				.done(() => {
					this.$swiperSlidesWrapper.css('max-width', slideWidth);
				});
		},

	});
}(jQuery));
