(function ( $ )
{
    'use strict';
    /**
     * MglSelector module implementation.
     *
     * @author  <t.kruse@edelweiss72.de>
     * @namespace T.Module
     * @class MglSelector
     * @extends T.Module
     */
    T.Module.MglSelector = T.createModule( {
        start: function ( resolve )
        {

			// classes/attr as strings
			this.selectorSophisticated = '.oo-selector--sophisticated';
			this.selectorSophisticatedInputChecked = '.oo-selector--sophisticated input:checked';
			this.isSophisticatedDiv = '.js-sophisticated > div';
			this.listPriceSpan = '.oo-list-price span';
			this.divDataSelectValue = 'div[data-select-value]';
			this.liActiveDivDataDescSetInputChecked = 'li.is-active div[data-description-set] input:checked';

            const $ctx = $( this._ctx ),
                $sophisticatedContent = $ctx.find('.js-sophisticated'),
                $radioBtnsRegular = $sophisticatedContent.find('.js-radio-1'),
                $radioBtnsYoung = $sophisticatedContent.find('.js-radio-3'),
                $radioBtnsPlus = $sophisticatedContent.find('.js-radio-2'),
				jsMglSelectorActive = 'js-mgl-selector-active';

            $ctx
                .find(`.oo-content > div[data-section="${$ctx.find('.oo-selector input[type="radio"]:checked').attr('id')}"]` )
                .addClass(jsMglSelectorActive)
                .siblings( '.js-mgl-selector-active' )
                .removeClass(jsMglSelectorActive);
            $ctx.find( '.oo-selector input[type="radio"]' ).on( 'change', function ()
            {
                $ctx
                    .find(`.oo-content > div[data-section="${jQuery(this).attr('id')}"]` )
                    .addClass(jsMglSelectorActive)
                    .siblings( '.js-mgl-selector-active' )
                    .removeClass(jsMglSelectorActive);

            } );
            resolve();

            // if usual membership content (sophisticated) changes
            $radioBtnsRegular.on('change', (e) =>
            {
                if(!$(e.target).is('select'))
                {
                    $(e.target).closest('li').toggleClass('is-active');
                }

                this.resetSelect($(e.target).closest('li'), $ctx);
                this.setPriceInitial($ctx);
                this.setHeadlineInitial($ctx);
                this.setPriceHeadlineInitial($ctx);
                this.setDescriptionBoxInitial($ctx);
                this.setFormFooterInitial($ctx);

                if($radioBtnsPlus.length > 0)
                {
                    this.syncBetweenCategories($(e.target), $radioBtnsPlus);
                }
            });

            // if plus membership content (sophisticated) changes
            $radioBtnsPlus.on('change', (e) =>
            {
                if(!$(e.target).is('select'))
                {
                    $(e.target).closest('li').toggleClass('is-active');
                }

                this.resetSelect($(e.target).closest('li'), $ctx);
                this.setPriceInitial($ctx);
                this.setHeadlineInitial($ctx);
                this.setPriceHeadlineInitial($ctx);
                this.setDescriptionBoxInitial($ctx);
                this.setFormFooterInitial($ctx);

                if($radioBtnsPlus.length > 0)
                {
                    this.syncBetweenCategories($(e.target), $radioBtnsRegular);
                }
            });

            // If Young-Generation Content (sophisticated) changes
            $radioBtnsYoung.on('change', (e) =>
            {
                // set active class on parent li
                $radioBtnsYoung.find('li').removeClass('is-active');

                $(e.target).closest('li').addClass('is-active');
                this.setPriceInitial($ctx);
                this.setHeadlineInitial($ctx);
                this.setDescriptionBoxInitial($ctx, $(e.target).closest('[data-description-set]').data('descriptionSet'));
                this.setPriceHeadlineInitial($ctx);
                this.setFormFooterInitial($ctx);
            });

            this.setPriceInitial($ctx);
            this.setHeadlineInitial($ctx);
            this.setPriceHeadlineInitial($ctx);
            this.setDescriptionBoxInitial($ctx);
            this.setFormFooterInitial($ctx);

            S.Utils.Form.init();

            $(this.selectorSophisticated).on('change', () =>
            {
                this.resetSelect($ctx.find('li select').closest('li'), $ctx);
                this.setPriceInitial($ctx);
                this.setHeadlineInitial($ctx);
                this.setPriceHeadlineInitial($ctx);
                this.setDescriptionBoxInitial($ctx);
                this.setFormFooterInitial($ctx);
            });

            // if a different Radio Button needs to be active
            // on module load, a URL parameter setActiveRadio may
            // be transmitted like &setActiveRadio=2,
            // so that the third radio button will be actived
            if(location.search.match(/setActiveRadio/))
            {
                const setActiveRadio = location.search.match(/setActiveRadio=([0-3])/)[1];

                $ctx.find('.oo-selector--sophisticated label').eq(setActiveRadio).trigger('click');
            }
        },

        setPriceInitial(module)
        {
            if(module.find(this.selectorSophisticated).length > 0)
            {
                const activeIndex = module.find(this.selectorSophisticatedInputChecked).closest('label').index(),
                    maxPrice = module.find(this.isSophisticatedDiv).eq(activeIndex).data('maxPrice'),
                    maxPriceReduced = module.find(this.isSophisticatedDiv).eq(activeIndex).data('maxPriceReduced'),
					spanJsMobilePrice = 'span.js-mobile-price';

                let addedPrice = 0;

                module.find(this.isSophisticatedDiv).eq(activeIndex).find('li.is-active').each((item, obj) =>
                {

                    if($(obj).find('select').length > 0)
                    {
                        if($(obj).find('select').val() === '1')
                        {
                            $(obj).find(this.listPriceSpan).text(parseInt($(obj).find(this.divDataSelectValue).data('selectValue'), 10));
                            $(obj).find(spanJsMobilePrice).text(parseInt($(obj).find(this.divDataSelectValue).data('selectValue'), 10));

                        }
                        else
                        {
                            if(item > 1)
                            {
                                $(obj).find(this.listPriceSpan).text(parseInt($(obj).find(this.divDataSelectValue).data('selectValue'), 10));
                                $(obj).find(spanJsMobilePrice).text(parseInt($(obj).find(this.divDataSelectValue).data('selectValue'), 10));
                            }
                            else
                            {
                                $(obj).find(this.listPriceSpan).text(parseInt($(obj).find(this.divDataSelectValue).data('selectValue'), 10) * 2);
                                $(obj).find(spanJsMobilePrice).text(parseInt($(obj).find(this.divDataSelectValue).data('selectValue'), 10) * 2);

                            }
                        }
                    }

                    addedPrice += parseInt($(obj).find(this.listPriceSpan).text(), 10);
                });

                let reducedPrice = module.find(this.isSophisticatedDiv).eq(activeIndex).find('.js-contribution-reduced-total-price').data('reduced');

                if(addedPrice - reducedPrice >= maxPriceReduced)
                {
                    reducedPrice = maxPriceReduced;
                }
                else
                {
                    reducedPrice = addedPrice - reducedPrice;
                }

                if(addedPrice >= maxPrice)
                {
                    addedPrice = maxPrice;
                }

                // set Price
                module.find(this.isSophisticatedDiv).eq(activeIndex).find('.js-contribution-total-price').text(addedPrice);
                module.find(this.isSophisticatedDiv).eq(activeIndex).find('.js-contribution-reduced-total-price').text(reducedPrice);
            }
        },

        addAllPrices()
        {

        },

        setHeadlineInitial(module)
        {
            if(module.find(this.selectorSophisticated).length > 0)
            {
                const activeIndex = module.find(this.selectorSophisticatedInputChecked).closest('label').index();

                module.find(this.isSophisticatedDiv).eq(activeIndex).find('li.is-active div[data-description-headline]').each((index, obj) =>
                {
                    module.find(this.isSophisticatedDiv).eq(activeIndex).find('.js-description-headline').text($(obj).data('descriptionHeadline'));
                });
            }
        },

        setPriceHeadlineInitial(module)
        {
            if(module.find(this.selectorSophisticated).length > 0)
            {
                const activeIndex = module.find(this.selectorSophisticatedInputChecked).closest('label').index();

                if(module.find(this.isSophisticatedDiv).eq(activeIndex).find(this.liActiveDivDataDescSetInputChecked).length > 0)
                {
                    module.find(this.isSophisticatedDiv).eq(activeIndex).find(this.liActiveDivDataDescSetInputChecked).each((index, obj) =>
                    {
                        module.find(this.isSophisticatedDiv).eq(activeIndex).find('.js-price-headline').text($(obj).closest('div[data-price-headline]').data('priceHeadline'));
                    });
                }
                else
                {
                    module.find(this.isSophisticatedDiv).eq(activeIndex).find('li.is-active div[data-price-headline]').each((index, obj) =>
                    {
                        module.find(this.isSophisticatedDiv).eq(activeIndex).find('.js-price-headline').text($(obj).data('priceHeadline'));
                    });
                }
            }
        },

        setDescriptionBoxInitial(module, target)
        {
            if(module.find(this.selectorSophisticated).length > 0)
            {
                const activeIndex = module.find(this.selectorSophisticatedInputChecked).closest('label').index();

                let dataDescriptionSetItem =  module.find(this.isSophisticatedDiv).eq(activeIndex).find('li.is-active div[data-description-set]').data('descriptionSet');

                if(target)
                {
                    dataDescriptionSetItem = target;
                }

                module.find(this.isSophisticatedDiv).eq(activeIndex).find('.oo-description-wrapper[data-description-set]').each((index, obj) =>
                {
                    $(obj).removeClass('is-active');
                    $(obj).parent().find(`.oo-description-wrapper[data-description-set=${dataDescriptionSetItem}]`).addClass('is-active');
                });
            }
        },

        setFormFooterInitial(module)
        {
            // Change the formfooter set
            if(module.find(this.selectorSophisticated).length > 0)
            {
                const activeIndex = module.find(this.selectorSophisticatedInputChecked).closest('label').index(),
					visuallyHidden = 'h-visuallyhidden';

                if(module.find(this.isSophisticatedDiv).eq(activeIndex).find(this.liActiveDivDataDescSetInputChecked).length > 0)
                {
                    module.find(this.isSophisticatedDiv).eq(activeIndex).find(this.liActiveDivDataDescSetInputChecked).each((index, obj) =>
                    {
                        $('.js-young.is-active').removeClass('is-active').addClass(visuallyHidden);
                        $(`.js-young-${  $(obj).closest('div[data-description-set]').data('descriptionSet')}`).removeClass(visuallyHidden).addClass('is-active');
                    });
                }
                else
                {
                    module.find(this.isSophisticatedDiv).eq(activeIndex).find('li.is-active div[data-description-set]').each((index, obj) =>
                    {
                        const $pdfListToDisplay = module.find(`.js-young-${$(obj).data('descriptionSet')}`);

                        module.find('.js-young.is-active').removeClass('is-active').addClass(visuallyHidden);
                        $pdfListToDisplay.removeClass(visuallyHidden).addClass('is-active');
                    });
                }
            }
        },

        syncBetweenCategories(eventTarget, contentTarget)
        {
            if ( contentTarget.length > 0 )
            {
                if ( eventTarget.is( 'select' ) )
                {
                    contentTarget.find( 'select' ).val( eventTarget.val() ).prop( 'selected', true );
                }

                const indexOfClickedElement = eventTarget.closest('li').index();

                if(eventTarget.is('input:checked'))
                {
                    contentTarget.find('li').eq(indexOfClickedElement).addClass('is-active').find('input').prop('checked', true);
                }
                else if(eventTarget.is('input') && !eventTarget.is(':checked'))
                {
                    contentTarget.find('li').eq(indexOfClickedElement).removeClass('is-active').find('input').prop('checked', false);
                }
            }
        },

        resetSelect(eventTarget, component)
        {
            if(!eventTarget.hasClass('is-active') && eventTarget.find('select').length > 0)
            {
				eventTarget.find('select option[value="1"]').prop('selected', true);
				eventTarget.find('select').val('1');

				eventTarget.each((_, eachSelectItem)=>
				{
					$(eachSelectItem).find(this.listPriceSpan).text(parseInt($(eachSelectItem).find(this.divDataSelectValue).data('selectValue'), 10));
				});

				this.setPriceInitial(component);
            }
        }

    } );
}( jQuery ));
