'use strict';
(function () {

	T.Utils = T.Utils || {};

	/**
	 * Utilities to handle ADAC membership options in components
	 * @author Dmitri Zoubkov <dmitri.zoubkov@namics.com>
	 */
	T.Utils.Membership = {

		/**
		 * Show elements in given context having either data-usekey="GroupAny" or data-usekey="Group<GroupId>",
		 * where GroupId matches UserData.Mitgliedschaft.GruppenId. Also all elements whith mismatching groups are hidden.
		 * @param {JQuery} $ctx
		 * @param {User} user
		 * @returns String - current user GroupId
		 */
		showElementsByUserGroup: function showElementsByUserGroup($ctx, user) {
			$ctx.find('[data-usekey^="Group"]').toggleClass('h-hidden', true);
			const groupId = user && user.UserData && user.UserData.Mitgliedschaft ? user.UserData.Mitgliedschaft.GruppenId : null;
			if (groupId) {
				const $elements = $ctx.find(`[data-usekey="Group${groupId}"]`);
				$elements.toggleClass('h-hidden', false);
				$ctx.find('[data-usekey="GroupAny"]').toggleClass('h-hidden', !$elements.length);
			}
			return groupId;
		},

		getUserTarifInformation: function getUserTarifInformation(user, propName) {
			if (user && user.UserData && user.UserData.Mitgliedschaft && user.UserData.Mitgliedschaft.TarifInformation) {
				return user.UserData.Mitgliedschaft.TarifInformation[propName];
			}
			return undefined;
		},

		getTaxInformationForUser: function getTaxInformationForUser(api, user, successCallback, errorCallback) {
			if (user && user.UserData && user.UserData.Mitgliedschaft) {
				this.getTaxInformation(api,
					{
						"Rate": user.UserData.Mitgliedschaft.Mitgliedschaftstarif,
						"TMM": user.UserData.Mitgliedschaft.TarifInformation.TarifmerkmalMitgliedschaft,
						"JUVField": user.UserData.Mitgliedschaft.AnzahlJungeVolljaehrige,
						"PARField": user.UserData.Mitgliedschaft.HatPartner
					},
					successCallback,
					errorCallback);
			} else {
				errorCallback();
			}
		},

		getTaxInformation: function getTaxInformation(api, values, successCallback, errorCallback) {
			const options = {
				url: api,
				type: 'POST',
				data: JSON.stringify({
					"Rate": values.Rate,
					"TMM": values.TMM,
					"JUVField": values.JUVField,
					"PARField": values.PARField
				})
			};
			T.Utils.Ajax.multifragment(`true:${options.type}:${api}`, options, successCallback, errorCallback);
		}

	};
})();