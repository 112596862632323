/// <reference path="../../../../../assets/typings/terrific-ext.d.ts" />
'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) {
	/**
	 * MyAdac Tracking
	 *
	 * @author Markus Hiller <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class GoogleTagEventPackage-MyAdac
	 * @extends T.Module.GoogleTagEventPackage
	 */
	T.Module.GoogleTagEventPackage.MyAdac = T.createDecorator({

		_sessionKeyEventCategory: "gtm_myadac_eventcategory",

		start: function start(resolve) {
			this._myAdacCategory = this._getMyAdacCategory();
			this._parent.start(resolve);
		},

		_getEventActionByElement: function _getEventActionByElement($element) {
			if (this._isInsideMain($element)) {
				const headline = this._getPageHeadline($element);
				if (headline) {
					return `${headline} | click`;
				}
			}
			return this._parent._getEventActionByElement($element);
		},

		_getPageHeadline: function _getPageHeadline($element) {
			return T.Utils.Format.normalizeSpace($element.closest(".l-main-content").find("h1").text());
		},

		_getEventCategory: function _getEventCategory(element, action) {
			if (this._myAdacCategory && this._isInsideMain(element)) {
				return this._myAdacCategory;
			}
			return this._parent._getEventCategory(element, action);
		},

		_isInsideMain: function _isInsideMain(element) {
			return !!this.$main.has(element).length;
		},

		_getMyAdacCategory: function _getMyAdacCategory() {
			let result = T.Utils.Store.get(`${this._sessionKeyEventCategory}:${window.location.pathname}`, T.Utils.Store.SESSION);
			if (!result) {
				result = T.Utils.Store.get(this._sessionKeyEventCategory, T.Utils.Store.SESSION);
				T.Utils.Store.set(`${window.location.pathname}:${this._sessionKeyEventCategory}`, result, T.Utils.Store.SESSION);
			}
			T.Utils.Store.set(this._sessionKeyEventCategory, null, T.Utils.Store.SESSION);
			return result;
		}
	});

}(jQuery));
