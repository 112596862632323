(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.SectionPrinter = {

		$printButton: {},

		$printTrigger: {},

		$printTarget: {},

		printId: '',

		classPrintVisible: 'is-print-visible',

		/**
		 * on start initialize all
		 * dont put events in a function that you are calling more than once - stacking events
		 */
		start: function () {
			S.Utils.SectionPrinter.addPrintEventListener();

			// register listener to automatically clean up after print (or on abort)
			$(window).on("afterprint", () => {
				this.cleanUpPrint();
			});

			// catch event from the module
			$(document).on('printSection.preparePrintModuleFinished', () => {
				this.startPrinting();
			});
		},

		addPrintEventListener: function () {

			// click stuff
			this.$printButton = $('.print-section-button');
			this.$printButton.each((index, item) => {

				$(item).on('click.initSectionPrinting', (e) => {
					e.preventDefault();

					this.printId = $(item).data('print-section-id');
					this.$printTrigger = $(item);

					if (this.printId.length) {
						this.preparePrint($(e));
					}

					else {
						console.warn('NO PRINT ID SET');
					}
				});
			});
		},

		/**
		 * add classes to other elems
		 * @param {jQuery} $clickedButton
		 */
		preparePrint: function ($clickedButton) {

			// store current print target
			this.$printTarget = this.$printTrigger.parent().closest(`[data-print-section-id=${this.printId}]`);

			// add helper classes to body and each! child of the print target
			this.$printTarget.addClass('is-print-visible is-print-section').find('*:not(.js-print-ignore)').addClass(this.classPrintVisible);
			$('body').addClass('is-print-hidden');
			//hide the cookie
			$('.m-basic-cookie-refresh').hide();
			// show header on printing,
			$('header').addClass(this.classPrintVisible);
			// footer is for printing not importatnt
			$('footer').hide();

			/**
			 * Hint: See S.Global.js - there is a call for lazyloaded images which fires if we trigger window.print():
			 *
			 *
			 * => Option 1: By default, call the api to load the data for each onPrint()-case (MAJOR IMPACT FOR THE WHOLE SITE!)
			 * => Option 2: Write a data-attr. which could be checked in global.js to trigger the data call for specific
			 *              print events ( e.g.data-getDataOnPrint=true ) -> (would be my 1st choice)
			 * => Option 3: Keep this function here or more better in the module itself (e.g. via click-triggering)
			 *              -> probably the easiest solution
			 *
			 */
			$(document).trigger('printSection.preparePrintModule', $clickedButton);
		},

		/**
		 * start the actual print event
		 */
		startPrinting: function () {
			window.print();
		},

		/**
		 * clean up the classes after printing
		 */
		cleanUpPrint: function () {

			// remove classes on body
			$('body').removeClass('is-print-hidden');
			// show the cookie, if it wasnt clicked already
			$('header').removeClass(this.classPrintVisible).find('.m-basic-cookie-refresh').show();
			// show the footer after the printing process
			$('footer').show();

			if (this.$printTarget !== null && typeof this.$printTarget !== 'undefined') {
				this.$printTarget.removeClass('is-print-visible is-print-section').find('*').removeClass(this.classPrintVisible);
			}

			$(document).trigger('printSection.cleanUpPrintModule');
		},
	};

	/**
	 * looks for a printing button on the site if the site is loaded
	 */
	$(document).ready(function () {

		if ($('.print-section-button').length) {
			S.Utils.SectionPrinter.start();
		}
	});

})(jQuery);
