'use strict';
(function ($) {

	/*istanbul ignore next*/
	T.Utils = T.Utils || {};

	T.Utils.CookiesDisabledHelper = {

		/**
		 * Returns if cookies are disabled in browser. Uses a test cookie set by CMS pipeline on page request (CookiesDisabledCheckProcessor)
		 */
		_checkCookiesDisabled: function () {
			return Cookies.get('CookiesEnabled') === undefined;
		},

		/**
		 * Displays cookies disabled message to the user. The necessary <div> is part of the application container view.
		 */
		_displayCookiesDisabledMessage: function () {
			$('div[data-t-name="CookiesDisabledMessage"').removeClass("h-hidden");
		}

	};
})(jQuery);