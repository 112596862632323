/* eslint-disable */
(function ($) //NOSONAR
{
	'use strict';
	/**
	 * BasicGallery module implementation.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicGallery
	 * @extends T.Module
	 */
	T.Module.BasicGallery = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Number} */
		$ctxTID: null,

		/** @type {jQuery} */
		$layer: null,

		/** @type {Object} */
		swiperOrig: null,

		/** @type {Object} */
		swiperPrev: null,

		/** @type {Object} */
		swiperBig: null,

		/** @type {Object} */
		swiperThumbs: null,

		/** @type {jQuery} */
		$subtitleContainerPrev: null,

		/** @type {jQuery} */
		$activeSlideContainer: null,

		/** @type {Object[]} */
		swiper: [],

		/** @type {jQuery} */
		$thumbnailBox: null,

		/** @type {jQuery} */
		$captionBox: null,

		/** @type {Array} */
		urlvars: [],

		/** @type {Number} */
		index: 0,

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$ctxTID = this.$ctx.data('t-id');
			this.$layer = this.$ctx.find('.m-basic-gallery--layer').eq(0);
			this.$thumbnailBox = this.$ctx.find('.js-thumbnail-box').length ? this.$ctx.find('.js-thumbnail-box') : null;
			this.$captionBox = this.$ctx.find('.js-caption-box').length ? this.$ctx.find('.js-caption-box') : null;
			this.$subtitleContainerPrev = this.$layer.find('.mm-subtitle-prev').eq(0);
			this.$subtitleContainerBig = this.$layer.find('.mm-subtitle-big-text').eq(0);
			this.$activeSlideContainer = this.$ctx.find('.js-active-slide').eq(0);

			this.swiperSlide = '.swiper-slide';
			this.swiper = ['orig', 'big', 'prev'];
			this.basicMovie = '.m-basic-movie';
			this.cloudinaryContainer = '.mm-cloudinary-container';
			this.youtubeContainer = '.mm-youtube-container';
			this.swiperPreloader = '.swiper-lazy-preloader';
			this.has3dVideo = 'has-immersive-3d-video';
			this.swiperNoSwiping = 'swiper-no-swiping';
			this.mmMovieContainer = '.mm-movie-container';

			// remove lazyload class from picture/img and replace them with swiper-lazy class
			S.Utils.SwiperHelper.addLazyClassToImg(this.$ctx.find(this.swiperSlide), true);

			// check for url parameter to open a slide
			this.checkForUrlParmToOpenSlideIfNeeded();

			resolve();
		},

		/**
		 * default cb function from observer when lazy loading
		 * is triggered or none lazy loading is needed/given
		 *
		 * @param target :: which initialized the lazy call
		 * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
		 * @private
		 */
		_lazyUpdate(target) {
			// add more logic/separation if needed
			const $target = $(target);

			// if triggered target is this .m-basic-gallery module
			// dont do anything when Swiper is not defined
			if ($target.is(this.$ctx) && typeof Swiper !== 'undefined') {
				// if a thumbnail swiper is given
				if (this.$thumbnailBox) {
					this.swiper.push('thumbs');
				}

				// createClones
				this.createClones(this.swiper);

				// init swipers
				this.initOrigSwiper();
				this.initBigSwiper();

				// set swiper slide count
				this.setSwiperSlideCount();

				this.resize();

				// open slide from url if needed
				this.openUrlSlide();

				// for develop testing purpose only - disable/enable lazy loading via param
				if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
					// init all visible movies
					for (const mod of this.swiper) {
						// just for safety, update current classes and internal progress after we added and initialized all swiper
						this.swiper[mod].instance.update();

						// only for movies - images gets handled by swiper
						this.initMovies(mod);
					}
				}
			}
		},

		/**
		 * clone original swiper and append them into the layer container
		 *
		 * @param {Array[String]} modArr
		 */
		createClones: function (modArr) {
			for (const mod of modArr) {
				const modifier = mod === 'orig' ? '' : `--${mod}`;

				switch (mod) {
					case 'orig':
						this.updateMovieClassesOnSlides(this.$ctx.find(this.swiperSlide));

						break;
					case 'big':
						// clone big layer swiper
						this.cloneOrigSwiper(this.$layer.find('.mm-big'), mod, modifier);

						break;
					case 'prev':
						// clone prev layer swiper
						this.cloneOrigSwiper(this.$layer.find('.mm-prev'), mod, modifier);

						break;
					case 'thumbs':
						// clone thumbnails swiper
						this.cloneOrigSwiper(this.$thumbnailBox, mod, modifier);

						break;
				}

				// create swiper object-variables
				this.swiper[`${mod}`] = [];
				this.swiper[`${mod}`]['$container'] = this.$ctx.find(`.swiper${modifier}`);
				this.swiper[`${mod}`]['$container'].attr('data-type', `${mod}`);
				this.swiper[`${mod}`]['$wrapper'] = this.$ctx.find(`.swiper-wrapper${modifier}`);
				this.swiper[`${mod}`]['$slides'] = this.$ctx.find(`.swiper-slide${modifier}`);
				this.swiper[`${mod}`]['$prev'] = this.$ctx.find(`.swiper-button-prev${modifier}`);
				this.swiper[`${mod}`]['$next'] = this.$ctx.find(`.swiper-button-next${modifier}`);

				// set preview image boxes
				if (mod !== 'big') {
					this.setPreviewImageBoxes(mod);

					S.Utils.Helper.mq('tablet').addListener(() => {
						this.setPreviewImageBoxes(mod);
					});
				}
			}
		},

		/**
		 * in this module all slider are loaded and initialized with the "same" functionality.
		 * To prevent videos from playing, a movie-overlay gets added for prev/thumbs slider
		 *
		 * @param {Object[InstanceType]} swiper
		 * @param {jQuery|HTMLElement|HTMLCollection} slide
		 * @param {String} mod
		 */
		appendOverlayImagesForThumbnailVideos(swiper, slide, mod) {
			const validSwiper = ['prev', 'thumbs'];

			if (validSwiper.includes(mod)) {
				const slideMovieContent = slide ? [slide] : swiper.slides.filter(slide => slide.querySelectorAll(this.basicMovie).length);

				// movie slides :: youtube || ew72 player
				for (const slide of slideMovieContent) {
					// video overlay for not playing inside the prev swiper :: only tablet/desktop
					$(slide).prepend(`<div class="js-movie-overlay"></div>`);
				}
			}
		},

		/**
		 * @param $container
		 * @param mod
		 * @param modifier
		 */
		cloneOrigSwiper($container, mod, modifier) {
			const $clone = this.swiper.orig.$container.clone(true).prependTo($container),
				$wrapper = $clone.find('.swiper-wrapper'),
				$slides = $clone.find('.swiper-slide'),
				$prevBtn = $clone.find('.swiper-button-prev'),
				$nextBtn = $clone.find('.swiper-button-next');

			// add modifier classes for identification
			$clone.addClass(`swiper${modifier}`);
			$wrapper.addClass(`swiper-wrapper${modifier}`);
			$slides.addClass(`swiper-slide${modifier}`);
			$prevBtn.addClass(`swiper-button-prev${modifier}`);
			$nextBtn.addClass(`swiper-button-next${modifier}`);

			for (const slide of $slides) {
				const $slide = $(slide),
					$basicMovie = $slide.find(this.basicMovie),
					$cloudMovie = $slide.find(this.cloudinaryContainer);

				// update ids for cloudinary
				for (const movie of $cloudMovie) {
					$(movie).removeAttr('id');
				}

				// reinit cloned basic movie modules for correct separation
				if ($basicMovie.length) {
					// append overlay for needed videos
					this.appendOverlayImagesForThumbnailVideos(false, slide, mod);

					$basicMovie.removeAttr('data-t-id');
					T.Utils.Helper.initAdditionalModules($basicMovie);
				}
			}
		},

		/**
		 * init original swiper
		 */
		initOrigSwiper() {
			this.initThumbSwiper();

			const mod = 'orig',
				mobileSpaceBetween = 5,
				desktopSpaceBetween = 10,
				simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				keyboardControl = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				lazy = {
					checkInView: true,
					loadPrevNextAmount: 5,
					loadPrevNext: true,
				};

			let index = 0,
				swiperOptions = {
					slidesPerView: 'auto',
					simulateTouch: simulateTouch,
					watchSlidesProgress: true,
					preloadImages: false,
					slideToClickedSlide: true,
					observer: true,
					keyboard: {
						enabled: keyboardControl,
					},
					navigation: {
						prevEl: this.swiper.orig.$prev[0],
						nextEl: this.swiper.orig.$next[0],
					},
					thumbs: {
						swiper: this.swiperThumbs
					},
					breakpoints: {
						// when window width is >= 320px
						320: {
							speed: 300,
							spaceBetween: mobileSpaceBetween,
							preventInteractionOnTransition: false,
						},
						1024: {
							speed: 600,
							spaceBetween: desktopSpaceBetween,
							preventInteractionOnTransition: true,
						}
					},
					on: {
						init: (swiper) => {
							this.swiper.orig.instance = swiper;
						},
						slideChangeTransitionStart: () => {
							// pause movies
							this.pauseMovies(mod);
						},
						transitionEnd: (swiper) => {
							// set slide url
							this.setSlideUrl(swiper.activeIndex + 1);

							// for develop testing purpose only - disable/enable lazy loading via param
							if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
								// init visible movies :: ew72 player and youtube
								this.initMovies(mod, S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper));
							}

							if (this.$captionBox !== null) {
								this.setCurrentSubtitle(swiper, swiper.activeIndex);
							}
						},
						/**
						 * custom event from basicmovie.js
						 * (for now only for 3d movies)
						 *
						 * @param swiper
						 * @param $container
						 * @param type
						 */
						movieIsPlaying: (swiper, { $container, type }) => //NOSONAR
						{
							// disable simulateTouch if 3d movie
							if (type === '3d') {
								this.set3DSlideMovieSettings(swiper);
							}
						},
						/**
						 * custom event from basicmovie.js
						 * @param swiper
						 * @param $container
						 * @param type
						 */
						cloudinaryMovie: (swiper, { $container, event }) => {
							this.handleCloudinaryMovieInteraction({ $container, event });
						},
						// this triggers only when the container gets resized
						resize: () => {
							// the index is an extra indicator to not overwrite the delay
							S.Utils.delayed(`resize-orig-swiper-${this.$ctxTID}-${index}`, 1000, () => {
								this.pauseAllMovies();
							});

							index++;
						},
						/**
						 * @params {swiper, slideEl, imageEl}
						 *
						 * @param {Object} swiper
						 * @param {HTMLElement} slideEl
						 */
						lazyImageLoad: (swiper, slideEl) => {
							const $slide = $(slideEl);

							// kept the same old logic from loadVisibleImages() Function
							$slide.removeClass('is-init').find(this.swiperPreloader).fadeOut();
							$slide.find('.js-init').removeClass('js-init');
						},
						lazyImageReady: (swiper, slideEl) => {
							const $slide = $(slideEl);

							S.Lazy.handleUnveilClasses($slide.find('img'));
						}
					}
				};

			// set first subtitle
			this.setCurrentSubtitle(this.swiper.orig.$slides[0]);

			// for develop testing purpose only - disable/enable lazy loading via param
			if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
				swiperOptions = { ...swiperOptions, lazy };
			}

			this.swiperOrig = new Swiper(this.swiper.orig.$container[0], swiperOptions);

			this.setPaddingTopRatioForYTMovies();
		},

		setPaddingTopRatioForYTMovies: function () {
			this.swiper.orig.$slides.find(this.youtubeContainer).each((_, slide) => {
				// protect video from overflow
				const $slide = $(slide),
					ratioWidth = $slide.data('height') / $slide.data('width');

				$slide.css('padding-bottom', `${ratioWidth * 100}%`);
			});

			this.swiperOrig.update();
		},

		/**
		 * @param {Number} index
		 */
		setActiveSlideNumberInLayer: function (index) {
			this.$activeSlideContainer.html(index);
		},

		/**
		 * sets slide count to layer
		 */
		setSwiperSlideCount() {
			const count = this.swiperOrig?.slides.length;

			this.$ctx.find('.js-swiper-slides').text(count);
		},

		/**
		 * init thumbnail swiper
		 */
		initThumbSwiper: function () {
			if (this.$thumbnailBox !== null) {
				const mobileSpaceBetween = 5,
					desktopSpaceBetween = 10,
					simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
					keyboardControl = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
					lazy = {
						checkInView: true,
						loadPrevNextAmount: 2,
						loadPrevNext: true,
					};

				let swiperOptions = {
					slidesPerView: 'auto',
					slidesPerGroupAuto: true,
					simulateTouch: simulateTouch,
					watchSlidesProgress: true,
					preloadImages: false,
					keyboard: {
						enabled: keyboardControl,
					},
					navigation: {
						prevEl: this.swiper.thumbs.$prev[0],
						nextEl: this.swiper.thumbs.$next[0],
					},
					breakpoints: {
						// when window width is >= 320px
						320: {
							speed: 300,
							spaceBetween: mobileSpaceBetween,
						},
						1024: {
							speed: 600,
							spaceBetween: desktopSpaceBetween,
						}
					},
					on: {
						init: (swiper) => {
							this.swiper.thumbs.instance = swiper;
						},
						transitionEnd: (swiper) => {
							// for develop testing purpose only - disable/enable lazy loading via param
							if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
								// init visible movies :: ew72 player and youtube
								this.initMovieCloudinary(S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper), 'thumbs');
							}
						},
						/**
						 * @param {Object} swiper
						 * @param {HTMLElement} slideEl
						 */
						lazyImageLoad: (swiper, slideEl) => {
							const $slide = $(slideEl);

							// kept the same old logic from loadVisibleImages() Function
							$slide.removeClass('is-init').find(this.swiperPreloader).fadeOut();
							$slide.find('.js-init').removeClass('js-init');
						},
						lazyImageReady: (swiper, slideEl) => {

							const $slide = $(slideEl);

							S.Lazy.handleUnveilClasses($slide.find('img'));
						}
					}
				};

				// for develop testing purpose only - disable/enable lazy loading via param
				if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
					swiperOptions = { ...swiperOptions, lazy };
				}

				this.swiperThumbs = new Swiper(this.swiper.thumbs.$container[0], swiperOptions);
			}
		},

		/**
		 * init layer big swiper
		 */
		initBigSwiper: function () {
			// init the thumbnails for the big swiper first - to use it here
			this.initPrevSwiper();

			const mod = 'big',
				mobileSpaceBetween = 0,
				desktopSpaceBetween = 0,
				simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				keyboardControl = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				lazy = {
					checkInView: true,
					loadPrevNextAmount: 2,
					loadPrevNext: true,
					loadOnTransitionStart: true,
				};

			let swiperOptions = {
				spaceBetween: this.spaceBetween,
				slidesPerView: 1,
				simulateTouch: simulateTouch,
				watchSlidesProgress: true,
				preloadImages: false,
				observer: true,
				keyboard: {
					enabled: keyboardControl,
				},
				navigation: {
					prevEl: this.swiper.big.$prev[0],
					nextEl: this.swiper.big.$next[0],
				},
				thumbs: {
					swiper: this.swiperPrev
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						speed: 300,
						spaceBetween: mobileSpaceBetween,
						preventInteractionOnTransition: false,
					},
					1024: {
						speed: 600,
						spaceBetween: desktopSpaceBetween,
						preventInteractionOnTransition: true,
					}
				},
				on: {
					init: (swiper) => {
						this.swiper.big.instance = swiper;
					},
					/**
					 * custom event from basicmovie.js
					 * (for now only for 3d movies)
					 *
					 * @param swiper
					 * @param $container
					 * @param type
					 */
					movieIsPlaying: (swiper, { $container, type }) => //NOSONAR
					{
						// disable simulateTouch if 3d movie
						if (type === '3d') {
							this.set3DSlideMovieSettings(swiper);
						}
					},
					/**
					 * custom event from basicmovie.js
					 *
					 * @param swiper
					 * @param $container
					 * @param type
					 */
					cloudinaryMovie: (swiper, { $container, event }) => {
						this.handleCloudinaryMovieInteraction({ $container, event });
					},
					transitionStart: (swiper) => {
						// on mobile, the prev slider handles this
						if (!S.Utils.Helper.mq('mobile').matches) {
							// set active slide number to status view in layer
							this.setActiveSlideNumberInLayer(swiper.activeIndex + 1);
						}
					},
					transitionEnd: (swiper) => {
						// set slide url
						this.setSlideUrl(swiper.activeIndex + 1);

						// for develop testing purpose only - disable/enable lazy loading via param
						if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
							// init visible movies :: ew72 player and youtube
							this.initMovies(mod, S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper));
						}

						// pause movies
						this.pauseMovies(mod);

						if (this.$captionBox !== null) {
							this.setCurrentSubtitle(swiper, swiper.activeIndex);
						}
					},
					/**
					 * @params {swiper, slideEl, imageEl}
					 *
					 * @param {Object} swiper
					 * @param {HTMLElement} slideEl
					 */
					lazyImageLoad: (swiper, slideEl) => {
						const $slide = $(slideEl);

						// kept the same old logic from loadVisibleImages() Function
						$slide.removeClass('is-init').find(this.swiperPreloader).fadeOut();
						$slide.find('.js-init').removeClass('js-init');
					},
					lazyImageReady: (swiper, slideEl) => {

						const $slide = $(slideEl);

						S.Lazy.handleUnveilClasses($slide.find('img'));
					}
				}
			};

			// init layer opening by click on the orig slides
			this.initLayerOpening();

			// for develop testing purpose only - disable/enable lazy loading via param
			if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
				swiperOptions = { ...swiperOptions, lazy };
			}

			this.swiperBig = new Swiper(this.swiper.big.$container[0], swiperOptions);

			this.handleBigController();
		},

		/**
		 * init prev(iew) layer swiper
		 */
		initPrevSwiper: function () {
			const mod = 'prev',
				mobileSpaceBetween = 5,
				desktopSpaceBetween = 10,
				simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				keyboardControl = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				lazy = {
					checkInView: true,
					loadPrevNextAmount: 2,
					loadPrevNext: true,
				};

			let swiperOptions = {
				spaceBetween: this.spaceBetween,
				slidesPerView: 'auto',
				simulateTouch: simulateTouch,
				watchSlidesProgress: true,
				preloadImages: false,
				keyboard: {
					enabled: keyboardControl,
				},
				navigation: {
					prevEl: this.swiper.prev.$prev[0],
					nextEl: this.swiper.prev.$next[0],
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						speed: 300,
						spaceBetween: mobileSpaceBetween,
						slidesPerGroupAuto: false,
					},
					768: {
						slidesPerGroupAuto: true,
						speed: 600,
						spaceBetween: desktopSpaceBetween,
					},
					1024: {
						slidesPerGroupAuto: true,
						speed: 600,
						spaceBetween: desktopSpaceBetween,
					}
				},
				on: {
					init: (swiper) => {
						this.swiper.prev.instance = swiper;
					},
					transitionStart: (swiper) => {
						// Exception for smartphone
						// - swiperPrev is the main layer swiper
						if (S.Utils.Helper.mq('mobile').matches) {
							// init movie
							this.pauseMovies(mod);

							// set subtitle
							this.setCurrentSubtitle(swiper, swiper.activeIndex);

							// just sync big swiper
							this.swiperOrig.slideTo(swiper.activeIndex);

							const neededIndex = swiper.isEnd ? swiper.slides.length : swiper.activeIndex + 1;

							// set active slide number to status view in layer
							this.setActiveSlideNumberInLayer(neededIndex);
						}
					},
					transitionEnd: (swiper) => {
						// Exception for smartphone
						// - swiperPrev is the main layer swiper
						if (S.Utils.Helper.mq('mobile').matches) {
							// init movie
							this.pauseMovies(mod);

							this.setCurrentSubtitle(swiper, swiper.snapIndex);
						}

						// init movie
						this.initMovies(mod, S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper));
					},
					reachEnd: (swiper) => {
						if (S.Utils.Helper.mq('mobile').matches) {
							// set active slide number to status view in layer
							this.setActiveSlideNumberInLayer(swiper.slides.length);
						}
					},
					resize: () => {
						S.Utils.delayed(`prev-swiper-img-calculation-${this.$ctxTID}`, 500, () => {
							// set preview image boxes
							this.setPreviewImageBoxes(mod);
						});
					},
					/**
					 * @params {swiper, slideEl, imageEl}
					 *
					 * @param {Object} swiper
					 * @param {HTMLElement} slideEl
					 */
					lazyImageLoad: (swiper, slideEl) => {
						const $slide = $(slideEl);

						// kept the same old logic from loadVisibleImages() Function
						$slide.removeClass('is-init').find(this.swiperPreloader).fadeOut();
						$slide.find('.js-init').removeClass('js-init');
					},
					lazyImageReady: (swiper, slideEl) => {

						const $slide = $(slideEl);

						S.Lazy.handleUnveilClasses($slide.find('img'));
					}
				}
			};

			// for develop testing purpose only - disable/enable lazy loading via param
			if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false')) {
				swiperOptions = { ...swiperOptions, lazy };
			}

			this.swiperPrev = new Swiper(this.swiper.prev.$container[0], swiperOptions);
		},

		/**
		 * @param {Object[InstanceType]} swiper
		 */
		set3DSlideMovieSettings(swiper) {
			// get active slide
			const slide = swiper.slides[swiper.activeIndex];

			// disable simulateTouch if 3d movie
			if (S.Utils.Helper.mq('desktop').matches) {
				this.add3DSlideMovieSettings(false, slide);

			}
			else {
				this.remove3DSlideMovieSettings(false, slide);
			}
		},

		/**
		 * @param {Object[InstanceType]} swiper
		 * @param {jQuery|HTMLElement|HTMLCollection} specificSlide
		 */
		add3DSlideMovieSettings(swiper, specificSlide) {
			const movies3D = specificSlide ? [specificSlide] : swiper.slides.filter((slide) => {
				return slide.classList.contains(this.has3dVideo);
			});

			for (const slide of movies3D) {
				slide?.classList.add(this.swiperNoSwiping);
				slide.style.cursor = 'pointer';
			}
		},

		/**
		 * @param {Object[InstanceType]} swiper
		 * @param {jQuery|HTMLElement|HTMLCollection} specificSlide
		 */
		remove3DSlideMovieSettings(swiper, specificSlide) {
			const movies3D = specificSlide ? [specificSlide] : swiper.slides.filter((slide) => {
				return slide.classList.contains(this.has3dVideo);
			});

			for (const slide of movies3D) {
				slide?.classList.remove(this.swiperNoSwiping);
				slide.style.cursor = 'initial';
			}
		},

		/**
		 * initLayerOpening via magnific popup for orig and thumbnail slides
		 * @param {String[CSS]} effectClass
		 * @param {String[Class]} selector
		 */
		initLayerOpening: function (effectClass, selector) {
			const idSelector = `#${this.$ctx.find('.mm-layer-wrapper').eq(0).attr('id')}`,
				effectAttr = effectClass || 'mfp-zoom-in',
				delegateSelector = selector || '.swiper-slide[data-effect]';

			// this needs to be added only once, not every time a popup is open or each slide
			$(idSelector).addClass('ll-lightbox-inner ll-lightbox-inner--gallery mfp-with-anim');

			// orig swiper layer opening
			this.swiperOrig.slides.each((slide) => {
				const $slide = $(slide);

				// on the orig swiper the movies should be played
				// - don´t open popup on these slides
				if (!$slide.find(this.basicMovie).length) {
					// need to be set for parseHTML in magnific popup to know which id should be opened
					// and to differentiate the delegate swiper-slide class selector with data-effect
					$slide.attr('href', idSelector).attr('data-effect', effectAttr);
				}
			});

			// thumbnail swiper layer opening
			if (this.$thumbnailBox !== null) {
				this.swiperThumbs.slides.each((slide) => {
					const $slide = $(slide);

					// need to be set for parseHTML in magnific popup to know which id should be opened
					// and to differenciat the delegate swiper-slide class selector with data-effect
					$slide.attr('href', idSelector).attr('data-effect', effectAttr);
				});

				// set magnific popup options for thumbs swiper
				this.setMagnificPopup(this.swiperThumbs.$wrapperEl, delegateSelector, effectAttr);
			}

			// set magnific popup options for orig swiper
			this.setMagnificPopup(this.swiperOrig.$wrapperEl, delegateSelector, effectAttr);
		},

		/**
		 * custom event throughout the swiper (events support)
		 * - this gets initial triggered from basicMovie inside cloudinary if statement
		 * @param $container :: cloudinary container
		 * @param status :: events: play, pause etc.
		 * @param type
		 */
		handleCloudinaryMovieInteraction({ $container, event }) {
			switch (event) {
				case 'play':
					this.addNoSwipingClass($container.find('.vjs-control-bar'));
					break;
				case 'pause':
					// do stuff for pause if needed
					break;
				default:
					return;
			}
		},

		/**
		 * init movies
		 *
		 * @param {String} mod
		 * @param {HTMLElement|HTMLCollection|jQuery} paramVisbSlides
		 */
		initMovies: function (mod, paramVisbSlides = false) {
			const $slides = this.swiper[mod]['$slides'],
				$paramVisbSlides = paramVisbSlides ? (paramVisbSlides instanceof jQuery ? paramVisbSlides : $(paramVisbSlides)) : null,
				$visibleSlides = $paramVisbSlides || S.Utils.SwiperHelper.getVisbSlides($slides);

			for (const slide of $visibleSlides) {
				const $slide = $(slide),
					movieWasPlayed = $slide.find('.is-init').length,
					$movieCont = $slide.find(this.mmMovieContainer),
					$YTCont = $slide.find(this.youtubeContainer),
					$cloudCont = $slide.find(this.cloudinaryContainer);

				if ($movieCont.length && !movieWasPlayed && mod !== 'thumbs') {
					this.initMovieImmersive($slide);
				}

				if ($YTCont.length && !movieWasPlayed && mod !== 'thumbs') {
					this.initMovieYoutube($slide);
				}

				if ($cloudCont.length) {
					this.initMovieCloudinary($slide);
				}
			}
		},

		/**
		 * handle movie initialisation Immersive/Cloudinary-Functions
		 * - each container/element that gets found in the handed over element is searched for
		 *   the closest basicMovie module, and initialize the movie
		 *
		 *   Youtube movies gets handeled by the basicmovie (decorator: youtube) module
		 *
		 * @param  {jQuery|HTMLElement|HTMLCollection} el
		 */
		initMovieImmersive(el) {
			const $el = el instanceof jQuery ? el : $(el),
				$movieContainer = $el.hasClass('mm-movie-container') ? $el : $el.find(this.mmMovieContainer);

			for (const movie of $movieContainer) {
				const $movie = $(movie),
					basicMovieInstance = S.Utils.Helper.getModuleInstance($movie.closest(this.basicMovie));

				// just for safety
				if (typeof basicMovieInstance.initMovie === 'function') {
					basicMovieInstance.initMovie($movie);
				}
			}
		},

		initMovieCloudinary(el) {
			const $el = el instanceof jQuery ? el : $(el),
				$cloudinaryContainer = $el.hasClass('mm-cloudinary-container') ? $el : $el.find(this.cloudinaryContainer);

			for (const movie of $cloudinaryContainer) {
				const $movie = $(movie),
					basicMovieInstance = S.Utils.Helper.getModuleInstance($movie.closest(this.basicMovie));

				// just for safety
				if (typeof basicMovieInstance.initCloudinary === 'function') {
					basicMovieInstance.initCloudinary($movie);
				}
			}
		},

		initMovieYoutube(el) {
			const $el = el instanceof jQuery ? el : $(el),
				$youtubeContainer = $el.hasClass('mm-youtube-container') ? $el : $el.find(this.youtubeContainer);

			for (const movie of $youtubeContainer) {
				const $movie = $(movie),
					basicMovieInstance = S.Utils.Helper.getModuleInstance($movie.closest(this.basicMovie));

				// just for safety
				if (typeof basicMovieInstance.startVideo === 'function') {
					basicMovieInstance.startVideo(basicMovieInstance.$ctx.data('src'));
				}
			}
		},

		/**
		 * pause all movie on this site
		 */
		pauseAllMoviesOnPage: function () {
			// pause all videos on this page
			// pause videos in all slides
			this.pauseImmersiveVideo(document.body);
			this.pauseCloudinaryVideo(document.body);
			this.pauseYoutubeVideos(document.body);
		},

		/**
		 * pauseAllMovies in this context
		 */
		pauseAllMovies: function () {
			// pause/stop immersive player (for 3d movies etc.)
			this.pauseImmersiveVideo(this.$ctx);

			// There could be a Cloudinary player embedded on the page as a module without swiper
			// - find all and pause them if needed
			this.pauseCloudinaryVideo(this.$ctx);

			// pause all youtube videos
			this.pauseYoutubeVideos(this.$ctx);
		},

		/**
		 * pauseMovies in specific swiper or elements
		 * @param {String} mod
		 */
		pauseMovies(mod) {
			const $slides = this.swiper[mod]['$slides'];

			// pause videos in all slides
			this.pauseImmersiveVideo($slides);
			this.pauseCloudinaryVideo($slides);
			this.pauseYoutubeVideos($slides);
		},

		/**
		 * without the YT API you cant pause the video in the YT-Cont iframe
		 * - instead "reinit" the src
		 * @param {jQuery|HTMLElement|HTMLCollection} element
		 */
		pauseYoutubeVideos(element) {
			const $el = element instanceof jQuery ? element : $(element),
				$YTCont = $el.find(this.youtubeContainer),
				// User clicked on video for the first time - was-started
				// On slide(next/prev) videos get paused and poster is shown - is-paused
				$YTvideo = $YTCont.find('iframe.was-started:not(.is-paused)');

			for (const iframe of $YTvideo) {
				S.Utils.delayed(`pause-youtube-movie-${this.$ctxTID}-${this.index}`, 100, () => {
					/**
					 * because the Magnific Popup moves the slides into a layer and takes it away, we cannot reliably access the jQuery.data('t-id').
					 * It has cached/saved the old data attribute (t-id) and does not seem to
					 * make a new reference to the moved element. A more reliable approach is .attr()
					 *
					 * basicMovieTID = basicMovieEl.data('t-id'),
					 */
					const $iframe = $(iframe),
						$basicMovieEl = $iframe.closest(this.basicMovie),
						basicMovieTID = $basicMovieEl.attr('data-t-id'),
						basicMovieInstance = S.Utils.Helper.getModuleInstance(parseInt(basicMovieTID, 10)),
						src = iframe.getAttribute('src').split('?autoplay=1')[0];

					this.index += 1;

					basicMovieInstance.resetVideoSrc(false, src);
				});
			}
		},

		/**
		 * pause/stop immersive player (for 3d movies etc.)
		 *
		 * if no param is handed over, it will stop all immersiveVideos inside this Module.BasicGallery ($ctx is the fallback)
		 * @param {jQuery|HTMLElement|HTMLCollection} element
		 */
		pauseImmersiveVideo(element = this.$ctx) {
			const $el = element instanceof jQuery ? element : $(element);

			$el.find('.mm-movie-container.is-playing').each((_, player) => {
				$(player).immersive('pause');
			});
		},

		/**
		 * pause cloudinary videos via browser video-api pause
		 * @param {HTMLElement} element
		 */
		pauseCloudinaryVideo(element) {
			const $el = element instanceof jQuery ? element : $(element),
				$cloudinaryPlayers = $el.find('.mm-cloudinary-player');

			/**
			 * if there are cloudinary players, pause them via browser video-api
			 */
			for (const player of $cloudinaryPlayers) {
				const cloudPlayer = player.getElementsByTagName('video');

				for (const video of cloudPlayer) {
					/**
					 * if you need to remove the no-swiping class on .vjs-control-bar - uncomment the next lines
					 * - this gets triggered when user moves to the next slide, leave the layer
					 * @type {*|jQuery}
					 */
					// const $controlBar = $(cloudPlayer).find('.vjs-control-bar');
					//
					// // let the user slide (cloudinary isn´t an iframe like YT or an Canvas like Immersive)
					// // - swiper tracks the slide (like on sound or movie) and prevent that or try to slide to the next slide
					// this.removeNoSwipingClass($controlBar);

					// only pause if needed
					if (!video.paused) {
						video.pause();
					}
				}
			}
		},

		/**
		 * @param el :: to add/remove the "swiper-no-swiping" class from
		 */
		addNoSwipingClass(el) {
			const context = el instanceof jQuery ? el.get(0) : el;

			context?.classList.add(this.swiperNoSwiping);
		},

		removeNoSwipingClass(el) {
			const context = el instanceof jQuery ? el.get(0) : el;

			context?.classList.remove(this.swiperNoSwiping);
		},

		/**
		 * set subtitle for orig slider which
		 * will be set in the big swiper too
		 *
		 * @param {Object[InstanceType]|HTMLElement|jQuery} el swiper instance|slide
		 * @param {Integer} slideIndex
		 */
		setCurrentSubtitle: function (el, slideIndex) {
			// in case el is null or undefined - get out (for example, if no slides are maintained)
			if (!el) {
				return;
			}

			// u can set a
			const context = el instanceof jQuery ? el.get(0) : el,
				$neededSlide = context instanceof Element ? $(context) : $(context.slides[slideIndex]);

			let subtitleText = '&nbsp;';

			// from img
			if ($neededSlide.find('.js-img').length) {
				const $img = $neededSlide.find('.js-img');

				subtitleText = $img.attr('title') || subtitleText;
			}
			// from ew72 movie
			else if ($neededSlide.find(this.mmMovieContainer).length) {
				const $movieContainer = $neededSlide.find(this.mmMovieContainer);

				subtitleText = $movieContainer.data('title') || subtitleText;
			}
			// from youtube
			else if ($neededSlide.find(this.youtubeContainer).length) {
				const $YTContainer = $neededSlide.find(this.youtubeContainer);

				subtitleText = $YTContainer.data('title') || subtitleText;
			}

			this.$subtitleContainerPrev.html(subtitleText);
			this.$subtitleContainerBig.html(subtitleText);

			if (this.$captionBox !== null) {
				this.$captionBox.html(subtitleText);
			}
		},

		/**
		 * set (preview) image boxes for slide width
		 *
		 * @param {String} mod
		 */
		setPreviewImageBoxes: function (mod) {
			let origWidth = 0,
				origHeight = 0;

			// 120 - magic value, i have taken over the old value
			//
			// otherwise the poster is too large and the film is rendered larger and overflows the container
			const magicValue = (mod === 'thumbs' || mod === 'prev') ? 0 : 120;

			this.swiper[mod]['$slides'].each((_, slide) => {
				const $slide = $(slide);

				if ($slide.find('.mm-img').length) {
					const $img = $slide.find('.mm-img');

					origWidth = $img.data('width');
					origHeight = $img.data('height');
				}
				if ($slide.find('.mm-youtube-link-container').length) {
					const $img = $slide.find('.mm-youtube-link-container img');

					origWidth = $img.attr('width');
					origHeight = $img.attr('height');
				}
				else if ($slide.find(this.mmMovieContainer).length) {
					const $movieContainer = $slide.find(this.mmMovieContainer);

					// 120 - magic value, i have taken over the old value
					//
					// otherwise the poster is too large and the film is rendered larger and overflows the container
					origWidth = parseInt($movieContainer.data('width')) - magicValue;
					origHeight = parseInt($movieContainer.data('height'));
				}

				if (origWidth && !$slide.hasClass('has-youtube-video')) {
					const previewHeight = this.swiper[mod]['$wrapper'].outerHeight(),
						ratio = origWidth / origHeight,
						previewWidth = previewHeight * ratio;

					$slide.css({ width: `${previewWidth}px` });
				}
			});

			this.swiper[mod]?.instance?.update();
		},

		/**+
		 * init magnific popup for each slide
		 * @param {jQuery} element
		 * @param {String[CSS]} delegateSelector
		 * @param {String[Class]} effectAttr
		 */
		setMagnificPopup(element, delegateSelector, effectAttr) {
			const $el = element instanceof jQuery ? element : $(element);

			// add a none delegate initialization version if it's needed with a dependencies on delegateSelector
			$el.magnificPopup({
				delegate: delegateSelector, // child items selector, by clicking on it popup will open
				fixedContentPos: true,
				type: 'inline',
				mainClass: `${effectAttr} l-lightbox l-lightbox--gallery`,
				removalDelay: 300,
				callbacks: {
					beforeOpen: () => {
						// Because "slideOnClickedSlide" is active, the clicked 3D (immersive) movie is focused and it is the document.activeElement.
						// If you now click on another slide and the layer opens, the focus changes to magnificsPopup (.mfp-wrapper)
						// and the previous focused el is still on this (3D) slide. Also through the synchronisation of the Big and Orig-Swiper,
						// the Orig-Swiper slides along when the Big-Swiper (layer) changes - but as soon as the layer closes,
						// the focus on (.mfp-wrapper) is gone, because the element doesn't exist anymore. So the "old/previous" focused (document.activeElement)
						// is the 3D-Slide and it jumps back to it even though it is not the Active-Slide.
						//
						// This wont happen when the user clicks on a button (now the button would be the "old/previous" focused el)
						// only when the video gets triggered to load
						document.activeElement.blur();

						// just pause all movies on page
						this.pauseAllMoviesOnPage();
					},
					open: () => {
						// give the popup a few ms to get opened (for swiper´s internal calculation etc.)
						S.Utils.delayed(`magnific-popup-delay-${this.$ctxTID}`, 200, () => {
							const slideToIndex = S.Utils.Helper.mq('mobile').matches ? this.swiperOrig.clickedIndex : this.swiperOrig.activeIndex,
								// just for safety - when url slide is given, on the first trigger click (when img is clicked)
								// swiper wont see this as click and therefore there is no clickedIndex
								index = slideToIndex || this.swiperOrig.activeIndex;

							// set active slide number to status view in layer
							this.setActiveSlideNumberInLayer(index + 1);

							// maybe find a better solution :(
							// i think the problem for the controller issues between big & orig and orig & big - is the hidden big layer and its
							// missing HTML-Wrapper (from magnific) which gets removed every time. So sometimes the controller wont work.
							// To have a consistence solution, just slideTo and ignore the controller
							this.swiperBig.slideTo(index, 0);
							this.swiperPrev.slideTo(index, 600);

							// update/set big/prev slider
							this.swiperPrev.update(); // update should be used when the swiper is hidden/shown
							this.swiperBig.update(); // update should be used when the swiper is hidden/shown

							this.initMovies('prev', false);
						});
					},
					beforeClose: () => {
						if (S.Utils.Helper.mq('mobile').matches) {
							// pause all movies in prev swiper
							this.pauseMovies('prev');
						}
						else {
							// pause all movies in big swiper
							this.pauseMovies('big');
						}
					},
				}
			});
		},

		/**
		 * just for better overview and selectors and identification
		 * @param {jQuery|HTMLCollection} slides
		 */
		updateMovieClassesOnSlides(slides) {
			const $slides = slides instanceof jQuery ? slides : $(slides);

			// big slider img and YT movie
			$slides.each((_, slide) => {
				const $slide = $(slide),
					$movieCont = $slide.find(this.mmMovieContainer),
					$YTCont = $slide.find(this.youtubeContainer),
					$YTLinkCont = $slide.find('.mm-youtube-link-container'),
					$cloudCont = $slide.find(this.cloudinaryContainer);

				if ($YTCont.length) {
					// youtube
					$slide.addClass('has-youtube-video');
				}
				else if ($YTLinkCont.length) {
					// youtube
					$slide.addClass('has-youtube-link-video');
				}
				else if ($movieCont.length) {
					// 3D/immersive player
					const classToAdd = $movieCont.data('mode') === '3d' ? this.has3dVideo : 'has-immersive-video';

					$slide.addClass(classToAdd);
				}
				else if ($cloudCont.length) {
					// cloudinary/VJS-Player
					$slide.addClass('has-cloudinary-video');
				}
			});
		},

		/**
		 * set slide url to social share services
		 *
		 * @param {Number} slideIndex
		 */
		setSlideUrl: function (slideIndex) {
			const protocol = `${window.location.protocol}//`, // => e.g. https://
				host = window.location.host, // e.g. adac.de
				pathname = window.location.pathname, // e.g. /page/subpage
				galleryId = this.$ctx.data('id'),
				dataUrl = this.$layer.find('.m-basic-socialshare > .shariff').data('url'),
				newUrlPart = `${protocol + host + pathname}?gallery=${galleryId}xxx-xxxslide=${slideIndex}`;

			// update social share service urls
			this.$layer.find('.shariff-button > a').each(function (index, item) {
				const socialHref = $(item).attr('href'),
					socialSplitArray = socialHref.split('?'),
					socialBaseUrl = socialSplitArray[0];

				let socialExtension = socialSplitArray[1].replace(encodeURIComponent(dataUrl), '');
				socialExtension = socialExtension.replace(protocol, '');
				socialExtension = socialExtension.replace(host, '');
				socialExtension = socialExtension.replace(pathname, '');

				const newUrl = `${socialBaseUrl}?${socialExtension}${newUrlPart}`;

				$(item).attr('href', newUrl);
			});
		},

		/**
		 * open url slide
		 *
		 * for testing - url example:
		 * ...?gallery=936DA01F-9ABD-4D9D-80C7-02AF85C8220943xxx-xxxslide=25
		 *
		 * Param splitting:
		 * xxx-xxx - was original used in this code (and in the setSlideUrl),
		 *           but on the testpages the parameter is not splitted by xxx-xxx (just regular "&slide=..." )
		 */
		checkForUrlParmToOpenSlideIfNeeded: function () {
			const galleryID = this.$ctx.data('id'),
				hashes = window.location.href.replace('%3D', '=').slice(window.location.href.indexOf('?') + 1).split('xxx-xxx');

			for (const entry of hashes) {
				const hash = entry.split('=');
				this.urlvars[hash[0]] = S.Utils.Helper.checkForNumberFromString(hash[1]) ? parseInt(hash[1], 10) : hash[1];
			}

			// check if gallery id is in url and if this gallery has the data-id of the url and if slide is given
			if (this.urlvars?.gallery === galleryID && this.urlvars?.slide) {
				// set this module to get initialised as soon as possible
				this.isLazy = false;
			}
		},

		openUrlSlide: function () {
			const galleryID = this.$ctx.data('id');

			// check if gallery id is in url and if this gallery has the data-id of the url and if slide is given
			if (this.urlvars?.gallery === galleryID && this.urlvars?.slide) {
				// just for safety
				const slidePos = this.urlvars.slide > this.swiper.orig.$slides.length ? this.swiper.orig.$slides.length : this.urlvars.slide,
					$slide = this.swiper.orig.$slides.eq(slidePos);

				// maybe optimize this when its lazy loaded - or should be lazy loading
				// for now this orig slider gets loaded to, although it isnt in the view (but for now its ok)
				if ($slide.find('.js-img').length) {
					this.swiperOrig.slideTo(slidePos, 100);

					S.Utils.delayed(`magnific-popup-delay-from-url-${this.$ctxTID}`, 200, () => {
						$slide.trigger('click');
					});
				}
				else {
					// video content
					const idSelector = `#${this.$ctx.find('.mm-layer-wrapper').eq(0).attr('id')}`,
						$layer = $(idSelector),
						effectAttr = 'mfp-zoom-in';

					$.magnificPopup.open({
						items: {
							src: $layer
						},
						fixedContentPos: true,
						type: 'inline',
						mainClass: `${effectAttr} l-lightbox l-lightbox--gallery`,
						removalDelay: 300,
						callbacks: {
							open: () => {
								// give the popup a few ms to get opened (for swiper´s internal calculation etc.)
								S.Utils.delayed(`magnific-popup-delay-from-url-${this.$ctxTID}`, 100, () => {
									// layer swiper slide to pos
									this.swiperBig.slideTo(slidePos, 100);

									// update/set big/prev slider
									this.swiperPrev.update(); // update should be used when the swiper is hidden/shown
									this.swiperBig.update(); // update should be used when the swiper is hidden/shown

									this.initMovies('big');
									this.initMovies('prev');
								});
							},
							beforeClose: () => {
								if (S.Utils.Helper.mq('mobile').matches) {
									// pause all movies in prev swiper
									this.pauseMovies('prev');
								}
								else {
									// pause all movies in big swiper
									this.pauseMovies('big');
								}
							},
						}
					});
				}

				// set slide url
				this.setSlideUrl(slidePos);
			}
		},

		/**
		 * set swiper controller which swiper
		 * instances should be controlled by the other swiper
		 *
		 * Reference:
		 * ##########
		 * https://swiperjs.com/swiper-api#param-controller-control
		 */
		handleBigController() {
			// Only for this specific case - swiper big is hidden on mobile:
			// this must be handled in the MQ listener/resize (and not as a fixed initial setting) - otherwise,
			// when resizing the browser window from tablet to mobile and back, maximum-call-stack
			// problems occur on the part of the swiper events and the connection
			// between swiperBig thumbnail settings/events gets lost.
			if (S.Utils.Helper.mq('mobile').matches) {
				this.removeBigController();

				return;
			}

			this.addBigController();
		},

		addBigController() {
			// set controller for "big/orig" swiper
			if (!this.swiperBig.controller.control) {
				this.swiperBig.controller.control = this.swiperOrig;
				this.swiperBig.thumbs.swiper = this.swiperPrev;
				this.swiperBig.thumbs.init();
				this.swiperBig.thumbs.update();

				this.swiperBig.update();
			}
		},

		removeBigController() {
			if (this.swiperBig.controller.control) {
				this.swiperBig.controller.control = null;
				this.swiperBig.thumbs.swiper = null;

				this.swiperBig.update();
			}
		},

		/**
		 * add resize listener here
		 */
		resizeListener() {
			// disable simulateTouch if 3d movie
			S.Utils.Helper.mq('desktop').addListener((mq) => {
				if (mq.matches) {
					this.add3DSlideMovieSettings(this.swiperOrig);
				}
				else {
					this.remove3DSlideMovieSettings(this.swiperOrig);
				}
			});

			S.Utils.Helper.mq('mobile').addListener((mq) => {
				if (!mq.matches) {
					const index = this.swiperOrig.isEnd ? this.swiperOrig.slides.length : this.swiperOrig.activeIndex;

					// most problems come on resize from mobile to tablet and the controller
					// and the correct index. On mobile we need to take the snapIndex and the rest we take the normal
					// So we just close the layer and slide all swipers to 0 - quick and dirty solution
					this.swiperBig?.update();

					// the index is an extra indicator to not overwrite the delay
					S.Utils.delayed(`just-the-next-tick-${this.$ctxTID}-${index}`, 10, () => {
						this.swiperBig?.slideTo(index, 0);
					});

					this.handleBigController();
				}
				else {
					this.swiperPrev?.slideTo(this.swiperBig.activeIndex);
					this.handleBigController();
				}
			});
		},

		/**
		 * resize
		 */
		resize: function () {
			// init listener
			this.resizeListener();
		},
	});

}(jQuery));
