(function ($) {
    'use strict';
    /**
     * VehiclesBrands module implementation.
     *
     * @author Lars Meyer <l.meyer@mgo360.de>
     * @namespace T.Module
     * @class VehiclesBrands
     * @extends T.Module
     */
    T.Module.VehiclesBrands = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {jQuery} */
        $swiperSlidesWrapper: null,

        /** @type {Object[]} */
        $swiperSlides: null,

        /** @type {jQuery} */
        $swiperBtnLeft: null,

        /** @type {jQuery} */
        $swiperBtnRight: null,

        /** @type {Number} */
        $spaceBetween: 0,

        /**
         * Initialize.
         *
         * @param {function} resolve
         *
         * @param {Object} decoratorData
         */
        start: function ( resolve, decoratorData )
        {
            this.$ctx = $(this._ctx);
            this.$swiperContainer = this.$ctx.find('.swiper').eq(0);
            this.$swiperSlidesWrapper = this.$ctx.find('.swiper-wrapper').eq(0);
            this.$swiperSlides = this.$ctx.find('.swiper-slide');
            this.$swiperSlideImage = this.$ctx.find('.swiper-slide > .mm-img');
            this.$swiperBtnLeft = this.$ctx.find('.swiper-button-prev');
            this.$swiperBtnRight = this.$ctx.find('.swiper-button-next');
            this.$spaceBetween = decoratorData.spaceBetween || 0;

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides, true);

            resolve();
        },

        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate(target, dataObj)// eslint-disable-line
        {
            // init swipe
            this.initSwipe();
        },

        /**
         * init swipe
         */
        initSwipe: function ()
        {
            // init swiper conatiner
            new Swiper(this.$swiperContainer[0], {
                speed: 1500,
                spaceBetween: 20,
                grabCursor: true,
                preloadImages: false,
                slidesPerView: 'auto',
                keyboard: {
                    enabled: true,
                },
                watchSlidesProgress: true,
                navigation: {
                    prevEl: this.$swiperBtnLeft[0],
                    nextEl: this.$swiperBtnRight[0],
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        speed: 500,
                    },
                    1024: {
                        speed: 1500,
                    }
                },
                lazy: {
                    checkInView: true,
                    loadPrevNextAmount: 1,
                    loadPrevNext: true,
                    loadOnTransitionStart: true,
                },
                on: {
                    init: () =>
                    {
                        this.addEvents();
                    },
                    /**
                     * @param {Object} swiper
                     * @param {HTMLElement} slideEl
                     * @param {HTMLElement} imageEl :: if needed
                     */
                    lazyImageLoad: (swiper, slideEl) =>
                    {
                        const $slide = $(slideEl);

                        // kept the same old logic from loadVisibleImages() Function
                        $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                        $slide.find('.js-init').removeClass('js-init');
                    },
                },
            });
        },

        /**
         * adds event like 'click' to open related contents
         */
        addEvents()
        {
            // emit event on tile click
            this.$swiperSlideImage.each( (index, elem) => {
                $(elem).on( 'click.vehicleBrands', () =>
                {
                    if ( $(elem).data('js-id').length ) {
                        this._events.emit('vehicleBrands.tileClick',  $(elem).data('js-id'));
                    }
                });
            });
        }
    });
}(jQuery));
