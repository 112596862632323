(function($) {

	'use strict';

	/**
	 * MyAdacList module implementation.
	 *
	 * @author b.kowatsch <b.kowatsch@mgo360.de>
	 * @namespace T.Module
	 * @class MyAdacList
	 * @extends T.Module
	 */

	T.Module.MyAdacList = T.createModule({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {string} */
		$showInit: '',

		/** @type {string} */
		$toggleBtn: '',

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function(resolve) {

			this.$ctx = $(this._ctx);

			if(typeof this.$ctx.data('show-max') !== 'undefined')
			{
				this.$showInit = parseInt(this.$ctx.data('show-max'));
				this.$toggleBtn = this.$ctx.find('.js-show-all-toggle-btn');
				this.initToggleShowMore();
			}

			resolve();
		},

		/**
		 * show only the Elements wanted in data-show-max and hide rest
		 */
		initToggleShowMore: function ()
		{
			this.$ctx.find('> .mm-my-adac-toggle-wrapper >.mm-my-adac-toggle').each((index, item) => {
				if(index >= this.$showInit) {
					$(item).addClass('is-initially-hidden');
				}
			});

			this.$toggleBtn.on('click', () => {
				this.$ctx.toggleClass('is-show-all');
			});
		}
	});
}(jQuery));
