(function($) { // eslint-disable-line
	'use strict';
	/**
	 * MyAdacVehicleImage module implementation.
	 *
	 * @author Tobias <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class MyAdacVehicleImage
	 * @extends T.Module
	 */
	T.Module.MyAdacImage = T.createModule({
		start: function(resolve) {

			resolve();
		}
	});
}(jQuery));
