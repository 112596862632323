(function($) {
	'use strict';
	/**
	 * BasicPieChart module implementation.
	 *
	 * @author  <>
	 * @namespace T.Module
	 * @class BasicPieChart
	 * @extends T.Module
	 */
	T.Module.BasicPieChart = T.createModule({
		/** @type {jQuery} */
		$ctx: null,


		start: function(resolve) {
			this.$ctx = $(this._ctx);

			this.initPie();
			resolve();
		},

		initPie: function ()
		{
			this.$ctx.find('.mm-pie').each(function() {
				const percent = parseInt($(this).data('percent'));
				const chart = $(this).find('.mm-chart svg');

				if (percent > 0) {
					const cnt = percent;

					for(let i = 0; i <= cnt; i++) {
						const child = 100 - i;
						chart.find('path').eq(child).addClass('is-filled');
					}
				}
			});
		}
	});
}(jQuery));
