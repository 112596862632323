(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.LMainCols = {
		/**
		 * l-main-cols sv 2016-01-21
		 *
		 *
		 */

		/**
		* @type {String} layer
		*/
		$actualContentId: '',

		$mainColsLineClass: '.l-main-cols--line',
		$sliderContentClass: '.ll-main-cols-slidercontent',

		/**
		 * init
		 *
		 */
		init: function () {
			const that = this;

			// tablet mode
			$(".l-main-cols").each(function (index, item) {
				if ($(item).find('> .ll-main-cols-s--tablet, > .ll-main-cols-m--tablet, > .ll-main-cols-l--tablet').length) {
					$(item).addClass('l-main-cols--tablet');
				}
			});

			$(this.$mainColsLineClass).each(function (index, item) {
				const sliderContentArea = $(item).find('> .ll-main-cols-m + .ll-main-cols-m'),
					defaultContentArea = $(item).closest(that.$mainColsLineClass).find('> .ll-main-cols-m--first'),
					defaultContentHeight = defaultContentArea.outerHeight();
				that.addEvents(sliderContentArea, defaultContentArea, defaultContentHeight, item);
			});
		},

		/**
		 * addEvents
		 *
		 * @type {jQuery} sliderContentArea
		 * @type {jQuery} defaultContentArea
		 * @type {jQuery} defaultContentHeight
		 */
		addEvents: function (sliderContentArea, defaultContentArea, defaultContentHeight, elem) { // eslint-disable-line
			const that = this;

			// open content
			$(".js-l-main-cols--line-open").each(function (index, item) {
				$(item).on('click', function (event) {
					event.preventDefault();

					const contentId = $(item).attr('href');

					if (contentId !== that.$actualContentId) {

						if ($(contentId).length) {

							// get contentHeight
							$(contentId).show();
							const contentHeight = $(contentId).outerHeight();
							$(contentId).hide();

							// set contentHeight
							if (contentHeight > defaultContentArea.closest(that.$mainColsLineClass).outerHeight()) {
								defaultContentArea.closest(that.$mainColsLineClass).css({
									'height': defaultContentArea.closest(that.$mainColsLineClass).outerHeight()
								}).animate({
									'height': contentHeight
								}, function () {
									that.slideIn(contentId, sliderContentArea, defaultContentArea, defaultContentHeight);
								});
							} else {
								$(contentId).siblings(this.$sliderContentClass).slideUp();
								defaultContentArea.closest(that.$mainColsLineClass).css({
									'height': defaultContentArea.closest(that.$mainColsLineClass).outerHeight()
								}).animate({
									'height': defaultContentArea.outerHeight()
								}, function () {
									that.slideIn(contentId, sliderContentArea, defaultContentArea, defaultContentHeight);
								});
							}
						}

						that.$actualContentId = contentId;
					}

					return false;
				});
			});

			// close content
			$(".js-l-main-cols--line-close").each(function (index, item) {
				$(item).on('click', function (event) {
					event.preventDefault();

					const content = $(item).closest(this.$sliderContentClass);

					if ($(content).length) {
						that.slideOut(content, sliderContentArea, defaultContentArea, defaultContentHeight);
					}

					return false;
				});
			});

		},

		/**
		 * slideIn
		 *
		 * @type {String} contentId
		 * @type {jQuery} sliderContentArea
		 * @type {jQuery} defaultContentArea
		 * @type {jQuery} defaultContentHeight
		 */
		slideIn: function (contentId, sliderContentArea, defaultContentArea, defaultContentHeight) { // eslint-disable-line

			const that = this;
			// reset
			$(contentId).siblings(this.$sliderContentClass).hide();

			if (!S.Utils.Helper.mq('tablet').matches) {
				defaultContentArea.css({ 'opacity': 0 });
			}
			// slide in
			sliderContentArea.css({
				'border-color': '#d7d7d7'
			}).animate({
				'left': 0,
			}, function () {
				defaultContentArea.attr('style', '').closest(that.$mainColsLineClass).attr('style', '').addClass('l-main-cols--line-secondary');
			});

			// show content
			$(contentId).fadeIn(function () {

			});

		},

		/**
		 * slideOut
		 *
		 * @type {jQuery} content
		 * @type {jQuery} sliderContentArea
		 */
		slideOut: function (content, sliderContentArea, defaultContentArea, defaultContentHeight) { // eslint-disable-line

			const that = this;

			// hide content
			$(content).fadeOut();

			// slide out
			sliderContentArea.css({
				'border-color': '#ffffff'
			}).animate({
				'left': '100%',
			}, function () {

			});

			defaultContentArea.closest(that.$mainColsLineClass).css({
				'height': defaultContentArea.closest(that.$mainColsLineClass).outerHeight()
			}).animate({
				'height': defaultContentArea.outerHeight()
			}, function () {
				defaultContentArea.closest(that.$mainColsLineClass).attr('style', '').removeClass('l-main-cols--line-secondary');
			});

			this.$actualContentId = '';
		}

	};


	$(document).ready(function () {
		S.Utils.LMainCols.init();
	});

})(jQuery);
