(function ($) {
	'use strict';
	/**
	 * Printpage for the basicBtn module
	 *
	 * @author  s.krause@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicBtn
	 * @extends T.Module
	 */
	T.Module.BasicBtn.Printpage = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,
		$print: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			// click events
			this.clickevents();

			this._parent.start(resolve);
		},
		clickevents: function () {
			this.$ctx.click(function (e) {
				e.stopPropagation();
				e.preventDefault();

				S.Lazy.lazyPrintEvent();

				setTimeout(() => {
					window.print();
				}, 20);
			});
		}

	});
}(jQuery));
