/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
(function ($) {
	'use strict';
	/**
	 * BasicCalculatorContentBox module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicCalculatorContentBox
	 * @extends T.Module
	 */
	T.Module.BasicCalculatorContentBox = T.createModule({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} $allCalculatorContentBoxes */
		$allCalculatorContentBoxes: null,

		/** @type {jQuery} */
		$defaultCheckedInputs: null,

		/** @type {String} */
		resetConditionString: null,

		/** @type {jQuery} */
		$tileContainer: null,

		/** @type {jQuery} */
		$inputBox: null,

		/** @type {jQuery} */
		$inputField: null,

		/** @type {jQuery} */
		$selectField: null,

		/** @type {jQuery} */
		$inputRadioContentRow: null,

		/** @type {jQuery} */
		$inputDropDown: null,

		/** @type {jQuery} */
		$trafficBoxDummyBtn: null,

		/** @type {Object} */
		$btnCloseAll: null,

		/** @type {Object} */
		$stickyTarif: null,

		/** @type {Object} */
		$submitButton: null,

		/** @type {Object} $inputsToAdd */
		$inputsToAdd: null,

		/** @type {Object} $inputSwitchButton */
		$inputSwitchButton: null,

		/** @type {Object} $inputsToAddRows */
		$inputsToAddRows: null,

		/** @type {Object} $inputAddButton */
		$inputAddButton: null,

		/** @type {Object} $inputsToDeleteIcon */
		$inputsToDeleteIcon: null,

		/** @type {Object} $inputProgressNaviDepends */
		$inputProgressNaviDepends: null,

		/** @type {Number} counter */
		amountCounter: null,

		/** @type {Number} counter
		 * counts clones up but not down(!) to set a unique id-value for the cloned inputs
		 * */
		cloneCounter: 0,

		/** @type {Number} */
		initDelay: 500,

		/** @type {Object} $toggleStringsInputs */
		$toggleStringsInputs: null,

		/** @type {Object} $toggleStringsTargets */
		$toggleStringsTargets: null,

		/** @type {Object} $toggleStringsTargets */
		$toggleStringsPrefixes: null,

		/** @type {Object} $copyValueTriggers */
		$copyValueTriggers: null,

		/** @type {Object} $copyValueSources */
		$copyValueSources: null,

		/** @type {Object} $copyValueTargets */
		$copyValueTargets: null,

		/** @type {Object} $tileContent */
		$tileContent: null,

		start: function (resolve) {

			const that = this;

			this.$ctx = $(this._ctx);
			this.calcContentBox = '.m-basic-calculator-content-box';
			this.$allCalculatorContentBoxes = $(document.querySelectorAll(this.calcContentBox));
			this.$defaultCheckedInputs = this.$ctx.find('input:checked');
			this.resetConditionString = '';
			this.$inputsToAdd = this.$ctx.find('input.mm-hidden-input');
			this.$inputsToAddRows = this.$inputsToAdd.closest('.ll-row');
			this.$inputAddButton = this.$ctx.find('.a-basic-btn--add-intermediate-goal');
			this.$inputSwitchButton = this.$ctx.find('.ll-switch-icon');
			this.$inputsToDeleteIcon = this.$inputsToAddRows.find('.ll-delete');

			this.$inputProgressNaviDepends = this.$ctx.find('input[data-navi-progress-dependence]');

			this.$toggleStringsInputs = this.$ctx.find('input[data-js-toggle-strings]');
			this.$toggleStringsTargets = $(document.querySelectorAll('*[data-js-toggle-strings-target]'));
			this.$toggleStringsPrefixes = $(document.querySelectorAll('*[data-js-toggle-strings-prefix]'));

			this.$copyValueTriggers = this.$ctx.find('input[data-js-copy-val-trigger]');
			this.$copyValueSources = $(document.querySelectorAll('*[data-js-copy-val-src]'));
			this.$copyValueTargets = $(document.querySelectorAll('*[data-js-copy-val-target]'));

			this.amountCounter = 1;

			this.$tileContainer = this.$ctx.find('.mm-tile-container');
			this.$tileContent = this.$ctx.find('.mm-tile-content');
			this.$inputBox = this.$ctx.find('.mm-tile > input, .mm-form-container input');
			this.$inputField = this.$ctx.find('.l-form input').not('input[type=checkbox], input[type=radio]');
			this.$inputRadioContentRow = this.$ctx.find('.mm-tile-row-content input[type=radio], .ll-row-content-wrapper input[type=radio]');
			this.$inputDropDown = this.$ctx.find('.l-form select, .mm-form-container select');
			this.$btnCloseAll = $('.ll-main-center');

			this.$stickyTarif = $('.m-ves-rs-sticky');
			this.$submitButton = that.$stickyTarif.find('button[type="submit"]');

			this.dateFormat = 'DD-MM-YYYY';
			this.dataId = '[data-t-id]';
			this.hiddenClasses = 'hidden h-hidden';
			this.colData = '.ll-col-data';
			this.rowLast = 'll-row--last';
			this.dataJsCondition = 'data-js-condition';

			this.editValidationElems();
			this.fillTileContainer();
			this.addEventListeners('init');
			this.getInitialContext();
			this.oddElements();
			this.toggleContentStrings();

			if (this.$copyValueTriggers.length) {
				this.copyValues();
			}

			/**
			 * if this module has no "h-background" class the yellow background
			 * gets added to the layer and the mm-tile-content - which leads to the content
			 * being covered with the label background. There is no other good solution -
			 * due to the CSS structure and preventing overwriting existing dependency styles
			 *
			 * Addtional clause: do this only if label has no text (only blanks) otherwise it
			 * breaks other cases
			 */

			if (this.$tileContent?.length) {
				let contentInsteadOfLabel = true;
				this.$ctx.find('label').each(function () {
					if ($(this).text().replace(/ /g, '').length > 1) {
						contentInsteadOfLabel = false;
					}
				});

				if (contentInsteadOfLabel) {
					this.$ctx.addClass('m-basic-calculator-content-box--tile-content');
				}
			}

			resolve();
		},

		/**
		 * count tile elements and if the result is odd set helper class
		 *
		 */
		oddElements: function () {
			const that = this;

			if (!that.$inputBox.closest(this.calcContentBox).parent().hasClass('ll-col-data')) {

				const tileInputs = that.$inputBox.filter(function (index, elem) {
					return $(elem).parent('.mm-tile').length;
				});

				if ($(tileInputs).length % 2 !== 0 && !that.$tileContainer.hasClass('mm-tile-container--no-odd')) {
					that.$tileContainer.addClass('is-odd');
				}
			}
		},

		/**
		 * fill tilecontainer with empty tiles to maintain grid-layout
		 *
		 */
		fillTileContainer: function () {
			const that = this;

			// iterate through each container
			that.$tileContainer.each(function (index, element) {

				// check if there are already empty tiles
				if (!$(element).find('.mm-tile-empty').length) {
					// get tiles and length
					const tiles = $(element).find('.mm-tile');
					const tileSize = $(tiles).length;

					if (tileSize > 2) {
						const emptyTiles = [];
						for (let i = 0; i < tileSize; i++) {
							emptyTiles.push($('<div>', { class: 'mm-tile mm-tile-empty' }));
						}

						$(element).append(emptyTiles);
					}
				}
			});
		},

		/**
		 * position validation-icons next to calendar or info-layer icons
		 * limit error-msg width
		 */
		editValidationElems: function () {
			return true;
		},

		/**
		 * add events to special items
		 *
		 * @param mod - prevent unnecessary rebinding of events
		 */
		addEventListeners: function (mod) //NOSONAR
		{

			const that = this;

			if (mod === 'init') {
				const tileInputs = that.$inputBox.filter(function (index, elem) {
					return $(elem).parent('.mm-tile').length;
				});

				$(tileInputs).attr('tabindex', '-1');

				// add events to Box-Items
				that.$inputBox.each((index, element) => {
					// prevent tab-nav
					$(element).on('change', () => {
						that.getInputContext(element);
					});
				});

				// add events to form-items
				that.$inputField.each((index, element) => {

					let eventmod = 'change';

					if ($('.l-outer').hasClass('is-touch')) {
						eventmod = 'blur';
					}
					// prevent tab-nav
					$(element).on(eventmod, () => {
						that.getFieldContext(element);
					});
				});

				that.$ctx.find('.js-clone-remove').on('click', function () {
					that.removeClonedRow(this);
				});

				// add events to select items
				that.$inputDropDown.each((index, element) => {
					$(element).on('change', () => {
						that.getFieldContext(element);
					});
				});

				// for all contentRow radio buttons or components
				this.$inputRadioContentRow.each((_, element) => {
					$(element).on('change', () => {
						this.getInputContext(element);
					});
				});

				// only init it dependencies for the naviProgressBar are given
				if (this.$inputProgressNaviDepends.length) {
					// find all progressNav elements on this side
					const $progressNavis = this.$ctx.closest('.l-main-content').find('.m-basic-progress-nav');

					// set init state for progressNavi
					this.initProgressNaviState($progressNavis);

					// for all inputs, that have a dependency on the naviProgressBar
					this.$inputProgressNaviDepends.on('click', (e) => {
						// get needed dependece string
						const neededDependence = $(e.target).data('naviProgressDependence');

						// hide both
						$progressNavis.addClass('is-hidden');

						// find the right naviElement with the fitting dependece
						$progressNavis.each((_, element) => {

							if ($(element).data('jsDependence') === neededDependence) { $(element).removeClass('is-hidden'); }
						});

					});
				}

				// check if a button for addInputs is there and if there are inputs to add with needed class
				if (this.$inputSwitchButton.length) {
					this.$inputSwitchButton.on('click.inputSwitch', (e) => {
						e.preventDefault();

						// switch startDestination ipnut value with endDestination input value
						this.switchDestinations($(e.target));
					});
				}

				// check if a button for addInputs is there and if there are inputs to add with needed class
				if (this.$inputAddButton.length && this.$inputsToAdd.length) {
					// check in which interval
					const intervalToAdd = typeof this.$inputAddButton.data('intervalToAdd') !== 'undefined' ? this.$inputAddButton.data('intervalToAdd') : 1,
						maxAmount = typeof this.$inputAddButton.data('maxAmount') !== 'undefined' ? this.$inputAddButton.data('maxAmount') : this.$inputsToAdd.length;


					this.$inputAddButton.on('click.addInput', (e) => {
						e.preventDefault();

						// counter = element counter which has to be shown max. | like, only show overall 3 etc. | can be set via dataAttribute or on default 1
						// maxAmount, can be set via dataAttribute or on default its just the length of inputs in this module which has a class 'hidden' (ll-row)
						if (this.amountCounter <= maxAmount) {
							// get all rows which are hidden
							const $visibleRows = this.$inputsToAddRows.filter((_, element) => $(element).hasClass('hidden'));

							let intervalCounter = 1;

							// intervalToAdd = how many hidden elements should be displayed at once
							// intervalCounter = counts the number of shown elements in the while loop
							// for each click, this checks if the max amount of elements to show is reached (for this round/click)
							while (intervalToAdd >= intervalCounter) {
								// if amountCounter reaches the point to transcends the maxAmount
								// 		- happens only if the intervalToAdd is more than items left ($visibleRows)
								if (this.amountCounter > maxAmount) {
									return;
								}

								$visibleRows.eq(intervalCounter - 1).removeClass('hidden');

								//
								if (this.amountCounter === maxAmount) {
									// hide button if the right amount is reached
									this.$inputAddButton.addClass('is-hidden-animate').removeClass('is-shown-animate');
								}

								// add counter and intervalCounter
								intervalCounter++;
								this.amountCounter++;
							}
						}
					});
				}

				// check, if there is an delete button and on the the delete/trash icon next to input field
				// clear the input value
				if (this.$inputsToDeleteIcon.length) {
					// delete addedInput on trash-icon
					this.$inputsToDeleteIcon.on('click.deleteAddedInput', (e) => {
						this.amountCounter--;
						const $neededRow = $(e.target).closest('.ll-row'),
							$neededClearButton = $neededRow.find('.a-basic-input-text > .js-clear-button');

						// hide row
						$neededRow.addClass('hidden');
						// and empty value by triggering the X clear button
						if ($neededClearButton.length) { $neededClearButton.trigger('click'); }

						// show button again
						this.$inputAddButton.removeClass('is-hidden-animate').addClass('is-shown-animate');
					});
				}

				// sofortiger Schutz
				that.$ctx.find('.a-basic-input-checkbox.js-freeze-begin > input').on('click.freezeInsuBegin', (e) => {
					const inputElem = that.$inputField.filter('[data-js-condition=insurancestart]');

					// disable inputElem
					if ($(e.currentTarget).prop('checked')) {
						const serverTime = $(inputElem).data('initcare');

						// server time is set
						if (typeof serverTime !== 'undefined' && moment(serverTime, this.dateFormat).isValid()) {
							// set date to earliest day available (not client time!)
							$(inputElem).val(moment(serverTime, this.dateFormat).format('DD.MM.YYYY')).prop('disabled', true);
							$(inputElem).closest('.ll-row').removeClass('is-error');
							if ($(inputElem).prop('required')) {
								$(inputElem).closest('.ll-row').addClass('is-valid');
							}
						}
					}

					// enable inputElem
					else {
						$(inputElem).prop('disabled', false);
					}
				});

				$(document).on(`resetModule.${this.$ctx.data('t-id')}`, () => {
					this.$ctx.find(this.dataId).each((index, elem) => {
						// get all comps within this comp and call reset for them
						this._events.emit(`compReset.${$(elem).data('t-id')}`);
					});
				});
			}

			else if (mod === 'closeAll') {

				S.Utils.delayed('closeAllBtnDelay', 200, () => {

					that.$btnCloseAll.on('click', (e) => {

						const item = document.querySelector('.mm-infolayer-content.visible');

						// if clicked outside
						if (!$(item).is(e.target) && !$(item).find('*').is(e.target)) {
							// close layers
							that.closeAllLayers();
						}
					});
				});
			}
		},

		/**
		 * on clicking the switch button, next to "add interim goal" switches the start/end input value
		 * @param {jQuery} $currentTarget
		 */
		switchDestinations($currentTarget) {
			const $neededInputField = $currentTarget.closest(this.calcContentBox).find('.a-basic-input-text:visible > input');

			let inputValueArr = [];

			$neededInputField.each((_, element) => {
				inputValueArr.push(element.value);
			});

			// reverse the array
			inputValueArr = inputValueArr.reverse();

			$neededInputField.each((index, element) => {
				element.value = inputValueArr[index];
			});
		},

		/**
		 * checks if the fitting progressNavi is given, else it shows the first progressNavi
		 */
		initProgressNaviState($progressNavis) {
			const $getActiveInput = $(this.$inputProgressNaviDepends.filter((_, element) => element.checked));

			if ($getActiveInput.length) {
				const neededDepenceOnInit = $getActiveInput.data('naviProgressDependence');

				// find the right naviElement with the fitting dependece
				$progressNavis.each((_, element) => {

					if ($(element).data('jsDependence') === neededDepenceOnInit) { $(element).removeClass('is-hidden'); }
				});

				return;
			}

			$progressNavis.eq(0).removeClass('is-hidden');
		},

		/**
		 * find input-elements and -states on init
		 */
		getInitialContext: function () {
			const that = this;

			// get all input elements from page
			const boxInputs = this.$ctx.find('.mm-tile > input'),
				formInputs = this.$ctx.find('.l-form input, .m-basic-calculator-content-box .mm-form-container input');

			// loop through them and call infolayer on detection
			$(boxInputs).each((index, element) => {
				const condition = $(element).attr('data-js-layer-condition');
				if ($(element).is(':checked')) {
					that.getInputContext(element, 'init');
					if (condition) {
						this._events.emit('BasicInputLayer.inputChange', condition, true);
					}
				}
			});

			// loop through and call additional logic
			$(formInputs).each((index, element) => {
				that.getFieldContext(element);
			});

			if (that.$stickyTarif.length) {
				that.modifyStickyTarif('show');
			}

			that.$inputDropDown.each((index, element) => {
				that.getFieldContext(element);
			});
		},

		/**c
		 * find input-elements and -states and call layers or show/hide other elements
		 *
		 * @param element
		 *
		 * @param info - optional string (e.g "init")
		 */
		getInputContext: function (element, info) { //NOSONAR
			const that = this,
				$clickedElement = $(element),
				checkedState = $clickedElement.is(':checked'),
				context = $clickedElement.data('jsConditionContext'),
				inputType = $clickedElement[0].type,
				dataHiddenContent = 'data-js-hidden-content',
				hiddenContent = 'js-hidden-content',
				jsCondition = 'js-condition',
				eventShowElem = 'BasicCalculatorContentBox.showElem';

			// resetCondition values:
			//		- 0: hide condition (subCondition) not whole context
			// 		- 1: hide the whole context | note the differentiation in handleInputReset
			//		- 2: hide the clicked context for mutliContext | note the differentiation in handleInputReset
			let resetContext = $clickedElement.data('jsResetCondition');

			// iterate through all inputs and emit layer-conditions to get current info-layer context
			if (info !== 'init') {
				that.$inputBox.each((index, box) => {
					const condition = $(box).attr('data-js-layer-condition');
					if (condition) {
						that._events.emit('BasicInputLayer.inputChange', condition, $(box).is(':checked'));
					}
				});
			}

			// member inputs are rbtns => they fire only if checked
			if (context === 'membership') {

				const condition = $(element).attr(this.dataJsCondition),
					memberWrappers = document.querySelectorAll(`[data-js-condition-context=${context}]`);

				$.each(memberWrappers, (index, wrapper) => {
					let wrapperCondition = $(wrapper).attr(dataHiddenContent);

					if (typeof wrapperCondition !== 'undefined') {
						if (wrapperCondition.indexOf(",") > 0) {
							wrapperCondition = JSON.parse(wrapperCondition);
						}

						// data attr is an array
						if (typeof wrapperCondition !== 'string') {

							let matchFound = false;

							for (let i = 0; i < wrapperCondition.length; i++) {

								if (wrapperCondition[i] === condition) {
									$(wrapper).removeClass('h-hidden');
									matchFound = true;
								}
							}

							if (!matchFound) {
								$(wrapper).addClass('h-hidden');
							}
						}

						else {
							if (wrapperCondition === condition) {
								$(wrapper).removeClass('h-hidden');
							}

							else {
								$(wrapper).addClass('h-hidden');
							}
						}
					}
				});

				// show / hide additional memberfee in stickyfooter
				let footerMod = 'hide';

				if (condition === 'getMember') {
					footerMod = 'show';
				}

				S.Utils.delayed('BasicCalculatorContentBox.initDelayTillFooterIsLoaded', that.initDelay, () => {
					// reset delay
					that.initDelay = 10;
					that._events.emit('BasicCalculatorContentBox.memberShipChange', footerMod);
				});
			}

			// toggle pricing if participant is changed
			else if (context === 'insu-participant') {

				const condition = $(element).attr(this.dataJsCondition),
					conditionWrappers = document.querySelectorAll(`span[data-js-condition-context=${context}]`);

				$.each(conditionWrappers, (index, wrapper) => {

					if ($(wrapper).data(hiddenContent) === condition) {
						$(wrapper).removeClass('h-hidden');
					}

					else {
						$(wrapper).addClass('h-hidden');
					}
				});
			}

			// special for become member (ignores the default context logic)
			else if (context === 'becomemember') {

				const condition = $(element).attr(this.dataJsCondition),
					$stickyFooter = $('.js-form-footer-sticky').eq(0);

				$stickyFooter.find('.mm-header-label').each((index, elem) => {

					if ($(elem)[0].hasAttribute(dataHiddenContent)) {

						if ($(elem).attr(dataHiddenContent) === condition) {
							$(elem).removeClass('h-hidden');
						}

						else {
							$(elem).addClass('h-hidden');
						}
					}
				});
			}

			//  if it has a context - search for the context. if the context length is 0 or false, dont do stuff
			if (context !== undefined && $clickedElement.is(':visible')) {
				// get all needed variables and elements
				const condition = $clickedElement.data('jsCondition');

				// only trigger if the resetCondition is true on an element and if the user clicks
				if (resetContext) {
					// if there is a string, it takes the string and just resets the given data-js-hidden-content="STRING"
					//
					// resetContext: 0 (if string is given)
					if (typeof resetContext === 'string') {
						this.resetConditionString = resetContext;
						resetContext = 0;
					}

					// for hiding given/specific data-js-hidden-content
					if (resetContext === 0 && checkedState) {
						this.handleInputReset(context, condition, resetContext);

						return;
					}
					// for multi-options - check if the user clicks on the same element again
					// - user clicks on the element (with data resetCondition)
					// 			-> just ignore the input reset, no need and open the hidden-content
					// 			-> the change event is triggered after its getting checked | so the box is checked
					// - after the user clicks again
					// 			-> the change event is triggered after its getting checked | so the box is unchecked
					// eslint-disable-next-line sonarjs/no-duplicated-branches
					else if (resetContext === 2 && !checkedState) { //NOSONAR
						this.handleInputReset(context, condition, resetContext);

						return;
					}
					// for all others, just reset the inputs
					//
					// resetContext: 1
					// eslint-disable-next-line sonarjs/no-duplicated-branches
					else if (resetContext !== 0 && resetContext !== 2) { //NOSONAR
						this.handleInputReset(context, condition, resetContext);

						return;
					}
				}

				const $conditionElems = $(document.querySelectorAll(`:not(input)[data-js-condition-context=${context}]`));

				// cbx
				if (inputType === 'checkbox') {
					const correspondingBoxCond = [];
					let singleCbx = false;

					// used for unchecked boxes to find siblings which might opened the target
					const $correspondingBoxes = $(document.querySelectorAll(`input:checked[type=checkbox][data-js-condition-context=${context}]`));

					if (checkedState) {
						// this cbx is counted too!
						if ($correspondingBoxes.length > 1) {

							// store the condition of each corresponding box in the array to check against later
							$correspondingBoxes.each((index, corrBox) => {
								correspondingBoxCond.push($(corrBox).data(jsCondition));
							});
						}

						// cbx is the only cbx in its context => we pretend its a simple show/hide toggle
						else {
							singleCbx = true;
						}
					}

					else {

						if ($correspondingBoxes.length) {

							// store the condition of each corresponding box in the array to check against later
							$correspondingBoxes.each((index, corrBox) => {
								correspondingBoxCond.push($(corrBox).data(jsCondition));
							});
						}

						// cbx is the only cbx in its context => we pretend its a simple show/hide toggle
						else {
							// eslint-disable-next-line no-unused-vars
							singleCbx = true; //NOSONAR
						}
					}


					$conditionElems.each((index, elem) => {

						const elemCondition = $(elem).data(hiddenContent);
						const activeSubElements = $(elem).find('input:checked[data-js-condition]');

						if (typeof elemCondition !== 'undefined' && elemCondition.length) {

							if (elemCondition === condition) {

								activeSubElements.each((index, subEl) => {
									this.checkSubelementsForChecks(subEl, checkedState);
									// const chilEl = $('*[data-js-hidden-content="'+$(subEl).data(jsCondition)+'"]');
									// if ( checkedState ) {
									//     const that = this;
									//     setTimeout(function(){
									//         that.getInputContext($(subEl)[0]);
									//     }, 100);
									// }
									// else {
									//     chilEl.addClass('h-hidden');
									// }
								});
							}

							// condition is a string
							if (typeof elemCondition === 'string') {

								// if checked => show condition contents, hide all others in context
								// has been separated wit if(singleCbx) but both clauses did the same
								// elem has condition
								if (elemCondition === condition) {
									// show if checked
									if (checkedState) {
										$(elem).removeClass(this.hiddenClasses);
									}

									// hide if not
									else {
										$(elem).addClass('h-hidden');
									}

								}
							}

							// condition is an array
							else {

								let match = false;

								// iterate through each of the target conditions
								$.each(elemCondition, (elemCondIndex, elemCondVal) => {//NOSONAR
									// eslint-disable-next-line consistent-return

									// console.log('elemCondVal', elemCondVal);

									// trigger is not checked
									if (!checkedState) {

										// search for correspondingBoxConditions which might have opened the target elem previously
										$.each(correspondingBoxCond, (arrInd, condVal) => {

											// console.log('condVal', condVal);

											// we found a correspondingBox which has opened the target
											if (condVal === elemCondVal) {

												// console.log('match');

												// store match in var and break out of this each loop (cause we don't need to know, if more than one is matching)
												match = true;
												$(elem).removeClass(this.hiddenClasses);

												this._events.emit(eventShowElem, elem);
												return false;
											}

										});

										// if no match is found, hide the elem
										if (!match) {
											$(elem).addClass('h-hidden');

											this._events.emit('BasicCalculatorContentBox.hideElem', elem);
										}

										// if a match is found, break out of elemCondition each loop, cause we know now, that theres nothing to close until the corresponding boxes change there states (resulting in calling this again)...
										else {
											// console.log('stop loop');
											return false;
										}

									}

									// if cbx is checked, show the matching content regardless of the other boxes
									// HINT: In case you'll have to implement some kind of "if more than one box is checked, open smth" logic, see the logic above and create a data-attr. (e.g. "data-js-condition-min-checkcount") which would be checked here!
									else if (elemCondVal === condition) {
										$(elem).removeClass(this.hiddenClasses);

										this._events.emit(eventShowElem, elem);
									}
								});
							}
						}
					});
				}

				// radiobtn
				else {
					$conditionElems.each((index, elem) => {
						if (typeof $(elem).data(hiddenContent) !== 'undefined' && $(elem).data(hiddenContent).length) {

							// possible active radio Buttons in triggered Element
							const activeSubElements = $(elem).find('input:checked');
							// condition is a string
							if (typeof $(elem).data(hiddenContent) === 'string') {

								if ($(elem).data(hiddenContent) === condition) {
									this.toggleVisibility('show', elem);

									//call this again for the active radiobutton
									if (activeSubElements.length > 0) {
										this.getInputContext(activeSubElements[0]);
									}
								}

								else {
									this.toggleVisibility('hide', elem);
									// hide element which will be triggered again through SubElement
									this.toggleVisibility('hide', $(`*[data-js-hidden-content="${activeSubElements.data(jsCondition)}"]`)[0]);
								}
							}

							// condition is an array
							else {

								let matchFound = false;

								$.each($(elem).data(hiddenContent), (condIndex, cond) => {//NOSONAR
									// eslint-disable-next-line consistent-return

									if (cond === condition) {//NOSONAR
										// eslint-disable-next-line consistent-return

										matchFound = true;

										return false;
									}

									else {
										matchFound = false;
									}
								});

								if (matchFound) {
									this.toggleVisibility('show', elem);

									//call this again for the active radiobutton
									if (activeSubElements.length > 0) {
										this.getInputContext(activeSubElements[0]);
									}
								}

								else {
									this.toggleVisibility('hide', elem);
								}
							}
						}
					});
				}
			}
		},

		checkSubelementsForChecks: function (subEl, checkedState) {
			const chilEl = $(`*[data-js-hidden-content="${$(subEl).data('js-condition')}"]`);
			const activeSubElements = $(chilEl).find('input:checked[data-js-condition]');

			if (checkedState) {
				const that = this;
				setTimeout(() => {
					that.getInputContext($(subEl)[0]);
				}, 50);
			}
			else {
				chilEl.addClass('h-hidden');
			}

			activeSubElements.each((index, subEl2) => {
				this.checkSubelementsForChecks(subEl2, checkedState);
			});
		},
		/**
		 * toggleVisibility - show/hide contents
		 *
		 * @param mod {String} - show || hide
		 *
		 * @param elem {Object}
		 */
		toggleVisibility(mod, elem) {
			if (mod === 'show' && !$(elem).is(':visible')) {
				$(elem).removeClass(this.hiddenClasses);
				this._events.emit('BasicCalculatorContentBox.showElem', elem);
			}

			else if (mod === 'hide' && $(elem).is(':visible')) {
				$(elem).addClass('h-hidden');
				this._events.emit('BasicCalculatorContentBox.hideElem', elem);
				this.resetHiddenElems(elem);
			}
		},

		/**
		 * resetHiddenElems - clear hidden spans and inputs if parent is hidden and hide elems
		 *
		 * @param compWrapper {Object} - parent thats hidden;
		 */
		resetHiddenElems(compWrapper) {
			$(compWrapper).find('input[type=radio]').each((inputIndex, item) => {

				if (typeof $(item).data('jsConditionContext') !== 'undefined') {

					const context = $(item).data('jsConditionContext'),
						spanColl = [];
					let defaultFound = false;

					// find all related span.elems within the comp
					this.$allCalculatorContentBoxes.find(`span[data-js-condition-context="${context}"]`).each(
						(index, elem) => {
							if ($(elem).data('jsHiddenContent') === 'is-default') {
								if (!$(elem).is(':visible')) {
									defaultFound = true;
									$(elem).removeClass('h-hidden');
								}
							}

							else {
								spanColl.push(elem);
							}
						}
					);

					if (defaultFound) {
						$(spanColl).addClass('h-hidden');

						// uncheck this input
						$(item).prop('checked', false);
					}

					// find related wrappers
					this.$allCalculatorContentBoxes.filter(`:not(.h-hidden)[data-js-condition-context="${context}"]`).each((index, elem) => {
						this.toggleVisibility('hide', elem);
					});
				}
			});

		},

		/**
		 * handles the reset for the inputs
		 *        - needed if u need to hide/show multiple dimensions in m-basic-calculator
		 *        - different dependencies etc.
		 *        - for multiContext | means if you can click more than one option and more options can be shown between them
		 * @param {String} context
		 * @param {String} condition
		 * @param {Number} resetVariant
		 */
		handleInputReset: function (context, condition, resetVariant) {
			// console.log('handleInputReset', context, condition, resetVariant);

			let $contentWrapper;

			// console.log('handleInputReset',context,condition,resetVariant );

			switch (resetVariant) {
				case 0:
					// for only hide given/clicked condition
					$contentWrapper = this.$allCalculatorContentBoxes.find(`[data-js-condition-context=${context}][data-js-hidden-content=${this.resetConditionString}]`);
					break;
				case 1:
					// for normal - get all hidden-contents in the same context but not the triggering elements
					//				- all hidden-contents: the reason for this, you need all content to hide in a specific context. You dont have to look for different contents
					$contentWrapper = this.$allCalculatorContentBoxes.find(`[data-js-condition-context=${context}][data-js-hidden-content]:not([data-js-condition=${condition}])`);
					break;
				case 2:
					// for mutliContext - get all hidden-contents with specific condition in the same context but not the triggering elements
					//				- specific hidden-contents: the reason for this, you need the clicked content to specify the right content to hide and reset
					$contentWrapper = $(document).find(`[data-js-condition-context=${context}][data-js-hidden-content=${condition}]:not([data-js-condition=${condition}])`);
					break;
				default:

					break;
			}

			// u cant us "for (let element of $contentWrapper) es6" - babel polyfill version isnt working correctly for IE11 with "for loops"
			$contentWrapper.each((_, element) => {
				// get jquery elemeent
				let $currentElement = $(element);

				// if condition-context is not on an input
				// mm-tile-container - ll-row ... etc.
				if ($currentElement.is('input')) {
					const isTextOrNumber = $currentElement.attr('type') === 'text' || $currentElement.attr('type') === 'number';

					// check if the current element is a textField/text empty the value and hide it - else uncheck it
					//
					// else explanation:
					// initial all checked inputFields were saved in a collection. When the reset happens, all checked inputs were set to false. But if the $currentElement is in the collection, set it to the init state
					isTextOrNumber ? $currentElement.addClass('h-hidden').val('') : (this.$defaultCheckedInputs.is($currentElement) ? $currentElement.addClass('h-hidden').prop('checked', true) : $currentElement.addClass('h-hidden').prop('checked', false));
				}
				// if it is an select field
				else if ($currentElement.is('select')) {
					// hide the needed container
					$currentElement.addClass('h-hidden').prop('selectedIndex', 0);
				}
				// if condition-context is not on an input
				// its propably an  mm-tile-container - ll-row ... etc.
				else {
					// console.log('$currentElement', $currentElement)
					// hide the needed container
					$currentElement.addClass('h-hidden');
					$currentElement = $currentElement.find('input');

					// if there is an select field in the $currentElement/wrapper just reset it
					// 		- maybe | at the top add a defaultSelectField if needed and make the same query from $defaultCheckedInputs
					if ($currentElement.find('select').length) { $currentElement.prop('selectedIndex', 0); }

					// go through every input field
					// u cant us "for (let input of $currentElement) es6" - babel polyfill version isnt working correctly for IE11 with "for loops"
					$currentElement.each((_, element) => {
						const $currentInput = $(element);

						const isTextOrNumber = $currentInput.attr('type') === 'text' || $currentInput.attr('type') === 'number';

						// check if the current element is a textField/text - empty the value and hide it - else uncheck it
						//
						// else explanation: inputFields all checked inputs were saved in a collection. When the reset happens, all checked inputs were set to false. But if the $currentInput is in the collection, set it to the init state
						isTextOrNumber ? $currentInput.val('') : (this.$defaultCheckedInputs.is($currentInput) ? $currentInput.addClass('h-hidden').prop('checked', true) : $currentInput.addClass('h-hidden').prop('checked', false));
					});
				}
			});
		},

		/**
		 * calc values of text-inputs to do stuff
		 **/
		getFieldContext: function (element) { //NOSONAR
			const that = this;

			// store necessary objects from other contexts
			const $insuranceTerm = $('#insuranceTerm'),
				$ageInputs = $('input[data-js-condition="age"]'),
				$currentElement = $(element),
				elementAttr = $currentElement.data('jsCondition'),
				hiddenContent = 'js-hidden-content',
				isVisible = 'is-visible',
				optionSelected = 'option:selected';

			// calc age to show info layer
			if (elementAttr === 'age') {
				const age = that.calcAge($currentElement.val());

				if (typeof age === 'number' && age !== 0 && !isNaN(age)) {

					$currentElement.closest(this.colData).find('.ll-generations-msg').removeClass(isVisible);

					if (age < 18) {
						that.toggleSpecialLayers('error', element, 'under18');
						$currentElement.closest(this.colData).find('.ll-generations-msg[data-generation="child"]').addClass(isVisible);
					}
					else if (age >= 120) {
						that.toggleSpecialLayers('error', element, 'over120');
						$currentElement.val('');
					}
					else if (age >= 66 && age < 76) {
						$currentElement.closest(this.colData).find('.ll-generations-msg[data-generation="adult66"]').addClass(isVisible);

						if ($insuranceTerm.find(optionSelected).data('js-value') === 'special-age' && $insuranceTerm.find(optionSelected).val() > 12) {
							that.toggleSpecialLayers('error', element, 'over66');
							$insuranceTerm.find('option[data-js-value="age-fallback66"]').prop('selected', true);
						}
					}
					else if (age >= 76) {
						$currentElement.closest(this.colData).find('.ll-generations-msg[data-generation="adult76"]').addClass(isVisible);

						if ($insuranceTerm.find(optionSelected).val() > 6) {
							that.toggleSpecialLayers('error', element, 'over76');
							$insuranceTerm.find('option[data-js-value="age-fallback76"]').prop('selected', true);
						}
					}
					else {
						$currentElement.closest(this.colData).find('.ll-generations-msg[data-generation="adult"]').addClass(isVisible);
					}
				}

				else {
					$currentElement.closest(this.colData).find('.ll-generations-msg.is-visible').removeClass(isVisible);
				}
			}

			// check if duration is valid (-> 24 month is invalid for people over 66 years and 76 years)
			if (elementAttr === 'insuranceduration') {

				const jsValue = $currentElement.find(optionSelected).data('js-value');

				if (jsValue === 'special-age' || jsValue === 'age-fallback66') {

					let above66 = false,
						above76 = false;

					// calc age
					$ageInputs.each((index, ageElem) => {//NOSONAR
						// eslint-disable-next-line consistent-return
						const age = $(ageElem).val();
						if (age === '') {
							return;
						}

						if (that.calcAge($(ageElem).val()) > 75) {
							above76 = true;
							return false;
						}
						else if (that.calcAge($(ageElem).val()) > 65) {
							above66 = true;
						}
					});

					if (above76) {
						that.toggleSpecialLayers('error', element, 'over76');
						$currentElement.find('option[data-js-value="age-fallback76"]').prop('selected', true);
					}

					else if (above66 && $currentElement.find(optionSelected).val() > 12) {
						that.toggleSpecialLayers('error', element, 'over66');
						$currentElement.find('option[data-js-value="age-fallback66"]').prop('selected', true);
					}
				}

			}

			// toggle insurance before / on
			if (elementAttr === 'insurancemembercount') {
				that.toggleInsuranceMemberCount(parseInt($currentElement.val()));
			}

			// selects
			if ($currentElement.is('select')) {

				const optionCondition = $currentElement.find(optionSelected).data('jsCondition'),
					optionContext = $currentElement.find(optionSelected).data('jsConditionContext');

				if (typeof optionContext !== 'undefined' && optionContext.length && typeof optionCondition !== 'undefined' && optionCondition.length) {

					// console.log('optionContext', optionContext);
					// console.log('optionCondition', optionCondition);

					const $conditionElems = $(document.querySelectorAll(`:not(input)[data-js-condition-context=${optionContext}]`));

					$conditionElems.each((index, elem) => {

						if (typeof $(elem).data(hiddenContent) !== 'undefined' && $(elem).data(hiddenContent).length) {

							// condition is a string
							if (typeof $(elem).data(hiddenContent) === 'string') {

								if ($(elem).data(hiddenContent) === optionCondition) {
									// 'hidden' is removed, cause there might be older forms which still use this class
									$(elem).removeClass(this.hiddenClasses);
								}

								else {
									$(elem).addClass('h-hidden');
								}
							}

							// condition is an array
							else {

								$.each($(elem).data(hiddenContent), (condIndex, cond) => {//NOSONAR
									// eslint-disable-next-line consistent-return


									if (cond === optionCondition) {
										$(elem).removeClass(this.hiddenClasses);

										// condition is found in elem condition array => break the loop (for this elem!) cause we have a match
										// => and continue with the next elem
										return false;
									}

									else {
										$(elem).addClass('h-hidden');
									}
								});
							}
						}
					});
				}
			}
		},

		/**
		 * add / remove additional rows to set birthdays for all specified members in select
		 *
		 * @param count {Number} - amount of needed rows
		 */
		toggleInsuranceMemberCount: function (count) {

			const that = this,
				birthdayRows = [];
			let $sourceRow,
				currentCount = 0;

			// get info about current row amount
			that.$ctx.find('.l-form input').not('input[type=checkbox], input[type=radio]').each((index, inputElem) => {

				if ($(inputElem).attr('data-js-insurancemembercount')) {
					birthdayRows.push($(inputElem).closest('.ll-row'));
					currentCount++;
				}

			});

			// remove rows
			if (currentCount > count) {

				const countToRemove = currentCount - count;

				for (let i = 1; i <= countToRemove; i++) {

					// store dom element
					const rowToRemove = $(birthdayRows).eq(-1).get(0);

					// clean array
					if ($(birthdayRows).length > 1) {
						birthdayRows.splice(-1, 1);
					}

					// clean dom
					$(rowToRemove).remove();
				}

				const compWrapper = $(birthdayRows).eq(0).get(0).closest(this.calcContentBox);

				// clear data-t-id and reinit module
				$(compWrapper).removeAttr('data-t-id').find(this.dataId).each((index, item) => {
					$(item).removeAttr('data-t-id');
				});

				$(document).trigger('addModules', $(compWrapper));
			}

			// add rows
			else if (currentCount < count) {
				const countOfNeed = count - currentCount,
					sourceRowClass = 'js-source-row';

				$sourceRow = $(birthdayRows).eq(0).get(0);
				$sourceRow.removeClass(this.rowLast).addClass(sourceRowClass);

				// currentCount includes the sourcerow, therefore no "<="
				for (let i = 0; i < countOfNeed; i++) {
					// const $clonedRow = $sourceRow.clone(true, true);
					const $clonedRow = $sourceRow.clone(false);

					currentCount++;

					this.cloneCounter++;

					// update ids, names etc.
					$clonedRow.removeClass(sourceRowClass).find('label').each(function () {
						$(this).attr('for', `${$(this).attr('for')}-${that.cloneCounter}`);
						$(this).find('.js-clone-remove').removeClass('hidden');
					});

					$clonedRow.find('input, select').each(function () {
						$(this).removeClass('js-clear a-basic-input-text--clear hasDatepicker');
						$(this).attr('name', `${$(this).attr('name')}-${that.cloneCounter}`);
						$(this).attr('id', `${$(this).attr('id')}-${that.cloneCounter}`);
						$(this).siblings('.js-clear-button').remove();
						$(this).removeAttr('data-value');
						$(this).removeAttr('value');
						$(this).removeAttr('disabled');

						// add be helper class to the input
						if ($(this).is('input')) {
							$(this).val('').addClass('js-clone-input');
						}
					});

					// update date config in cloned inputs
					const $dateInput = $clonedRow.find('.a-basic-input-text--calendar[data-init]'),
						endValue = $dateInput.find('> .js-clone-input').data('js-clone-date-end');

					if (typeof endValue !== 'undefined' && endValue.length) {
						$dateInput.attr('data-init', `{"calendarversion": "standard", "begin": "-120y", "end":"${endValue}"}`);
					}

					const $cloneLabel = $clonedRow.find('label');

					// update clone label
					if ($cloneLabel.data('jsCloneLabel')) {

						if ($cloneLabel.find('span.js-label-text').length) {
							$cloneLabel.find('span.js-label-text').text($cloneLabel.data('jsCloneLabel'));
						}

						else {
							$cloneLabel.text($cloneLabel.data('jsCloneLabel'));
						}
					}

					// add count to label
					$cloneLabel.find('> span.js-member-count').removeClass('h-hidden').text(currentCount);

					// console.log($clonedRow)
					$clonedRow.find('.ll-generations-msg').removeClass('is-visible');

					$clonedRow.find('.js-has-listener').addBack().removeClass('js-has-listener');
					$clonedRow.find('.is-check').addBack().removeClass('is-error is-valid');

					// append clone
					$clonedRow.appendTo($sourceRow.closest('.ll-fieldset'));

					// update array
					birthdayRows.push($clonedRow);
				}

				// clear data-t-id and reinit module
				$sourceRow.closest(this.calcContentBox).removeAttr('data-t-id').find(this.dataId).each((index, item) => {
					if (!$(item).closest('.ll-row').hasClass(sourceRowClass)) {
						$(item).removeAttr('data-t-id');
					}
				});

				$(document).trigger('addModules', $sourceRow.closest(this.calcContentBox));
			}

			// set row classes like --last
			$(birthdayRows).eq(-1).get(0).addClass(this.rowLast);
		},

		/**
		 * remove cloned row on click
		 *
		 * @param binBtn {Object} - Bin that fired
		 */
		removeClonedRow: function (binBtn) {

			const clickedElement = $(binBtn);
			const removeRow = clickedElement.closest('.ll-row'),
				nextRows = removeRow.nextAll(),
				selectBox = clickedElement.closest('.ll-fieldset').find('select');
			let counter = parseInt(removeRow.find('.js-member-count').text());

			nextRows.each((i, el) => {
				$(el).find('.js-member-count').text(counter);
				counter++;
			});

			if (removeRow.hasClass(this.rowLast)) {
				removeRow.prev().addClass(this.rowLast);
			}

			clickedElement.closest('.ll-row').remove();

			if (selectBox.attr(this.dataJsCondition) === 'insurancemembercount') {
				const newValue = parseInt(this.$ctx.find('.ll-row.ll-row--last .js-member-count').text());

				selectBox.val(newValue);
			}

		},

		/**
		 *
		 * @param value {String} - base for calc
		 *
		 * @returns {number}
		 *
		 */
		calcAge: function (value) {

			const $vacationBegin = $('input#vacationstart').eq(0);
			let today;

			if ($($vacationBegin).val()) {
				// get today date
				today = moment($($vacationBegin).val(), this.dateFormat);
			}

			else {
				today = moment();
			}

			// get birthday-value and format it
			const birthDate = moment(value, this.dateFormat);
			const month = today.month() - birthDate.month();

			// calc age and month
			let age = today.year() - birthDate.year();

			// prevent false values if birthday is < 1 month in the future
			if (month < 0 || (month === 0 && today.date() < birthDate.date())) {
				age--;
			}

			return (age);
		},

		/**
		 *
		 * returns time delta between today and the time value given as param (future || past)
		 *
		 * @param value {String} - time base for calc
		 * @param mod {String} - defines 'past' or 'future'
		 * @param unit {String} - defines measurement ('days','months', 'years' ... (see docs for all props))
		 *
		 * @returns {number} - timeDelta between both values depending on unit.param
		 *
		 */
		calcTime: function (value, mod, unit) {

			const today = moment();

			// get futureDate-value and format it
			const compareDate = moment(value, this.dateFormat);

			// calc age and month
			let timeFrame = compareDate.diff(today, unit);

			if (mod !== 'future') {
				timeFrame = Math.abs(timeFrame);
			}

			return (timeFrame);
		},

		/**
		 *
		 * reset insurance begin to default-value
		 *
		 * CAUTION: is not called -> will be done via BE
		 *
		 */
		resetInsuranceDate: function (element) {

			// disabled -> will be done via BE
			$(element).val(moment().add(1, 'd').format('DD.MM.YYYY'));
			$(element).attr('value', moment().add(1, 'd').format('YYYY-MM-DD'));

			if ($(element).closest('.l-outer').hasClass('is-touch')) { //NOSONAR
				// android
				if (!$(element).closest('.l-outer').hasClass('is-ios')) {
					$(element).val(moment().add(1, 'd').format('YYYY-MM-DD'));
				}
			}
		},

		/**
		 * change/show/hide elements if corresponding input changed
		 */
		editElements: function () {
			return false;
		},

		/**
		 * show alert and error layers
		 *
		 * @param mod - type of layer (alert / error)
		 *
		 * @param element - what needs to be layered
		 *
		 * @param condition - (optional) necessary if element has more than one special layer
		 */
		toggleSpecialLayers: function (mod, element, condition) {

			const that = this;

			if (!condition) {
				condition = 'default';
			}

			// close other layers
			const allClosed = that.closeAllLayers();

			if (allClosed) {

				// find layer
				let layerContainer = $(element).siblings(`.m-basic-info-layer--${mod}`).children(`.mm-infolayer-content[data-js-layer-condition="${condition}"]`);

				if (!layerContainer.length) {
					layerContainer = $(element).parent().siblings(`.m-basic-info-layer--${mod}`).children(`.mm-infolayer-content[data-js-layer-condition="${condition}"]`);
				}

				// show layer (as hidden) and calc offset
				$(layerContainer).addClass('hidden');

				let layerPos = 'default';

				// special setting for tablet+ to check if layer should be switched to pos-right
				layerPos = that.calcLayerOffset(layerContainer);

				$(layerContainer).addClass(`visible pos-${layerPos}`).removeClass('hidden').find('.mm-infolayer-close').on('click', () => {
					$(layerContainer).removeClass(`visible pos-${layerPos}`).parents(this.colData).css('z-index', '');

					// special setting for insurance termination
					if ($(element).is('#beenfiredYes')) {
						that.editElements('toggleInsuranceTermination', 'reset');
					}

				});

				if ($(layerContainer).parents(this.colData).length) {
					$(layerContainer).parents(this.colData).css('z-index', '99');
				}

				// add listener to main-container
				that.addEventListeners('closeAll');

				// auto close layer after 7s
				// S.Utils.delayed('autoCloseLayers', 7000, () => {
				//     that.closeAllLayers();
				// });
			}
		},

		/**
		 * close all alert and error layers
		 *
		 */
		closeAllLayers: function () {

			const that = this;

			that.$btnCloseAll.off();

			// find visible layers and close them
			const visiblelayers = document.querySelectorAll('.mm-infolayer-content.visible');

			if (visiblelayers.length) {

				const activeInfoIcons = document.querySelectorAll('.mm-infolayer-icon.active');

				$(visiblelayers).removeClass('visible pos-right pos-default');
				$(activeInfoIcons).removeClass('active');

				if ($(visiblelayers).parents(this.colData).length) {
					$(visiblelayers).parents(this.colData).css('z-index', '');
				}

				// special setting for insurance termination
				if ($(visiblelayers).parent().siblings('.a-basic-input-radio > input#beenfiredYes')) {
					that.editElements('toggleInsuranceTermination', 'reset');

				}
			}

			return (true);

		},

		/**
		 * calc distance between right edge of layer and right edge of viewport-window
		 *
		 * @param layer
		 */
		calcLayerOffset: function (layer) {

			if (layer.length) {

				const windowWidth = $(window).innerWidth();
				const domRect = layer[0].getBoundingClientRect();

				const spaceRight = windowWidth - domRect.left - domRect.width;

				if (spaceRight < 10) {
					return ('right');
				}
				return ('default');
			}

			return undefined;
		},

		/**
		 * change text strings in the document on input change
		 * e.g. insert input values into text elements
		 */
		toggleContentStrings() { //NOSONAR
			this.$toggleStringsInputs.each((index, elem) => {

				const inputConditon = $(elem).data('js-toggle-strings'),
					toggleStrActivationCount = 'data-js-toggle-strings-activation-count';

				// store target elems for this elem
				const targets = this.$toggleStringsTargets.filter(`[data-js-toggle-strings-target=${inputConditon}]`);

				const prefixes = [];

				this.$toggleStringsPrefixes.each((index, prefElem) => {

					const prefCondition = $(prefElem).data('js-toggle-strings-prefix');

					// prefCondition is a string
					if (typeof prefCondition === 'string') {

						if (prefCondition === inputConditon) {
							prefixes.push(prefElem);
						}
					}

					// prefCondition is an array
					else {

						$.each(prefCondition, (prefConditionIndex, prefConditionVal) => {//NOSONAR
							// eslint-disable-next-line consistent-return
							if (prefConditionVal === inputConditon) {
								prefixes.push(prefElem);
								return false;
							}
						});
					}
				});

				// ask for input type
				if ($(elem).data('t-name') === 'BasicInputText') {

					$(elem).on('change', () => {

						// set value
						if ($(elem).val().length) {
							targets.text(` ${$(elem).val()}`);

							// show prefixes if there are some
							if ($(prefixes).length) {
								$(prefixes).removeClass('h-hidden');

								// increase activation count
								$(prefixes).each((index, prefElem) => {

									let newActivationCount = $(prefElem).attr(toggleStrActivationCount);

									newActivationCount++;

									$(prefElem).attr(toggleStrActivationCount, newActivationCount);
								});
							}

						}

						// remove value
						else {
							targets.text('');

							if ($(prefixes).length) {

								// decrease activation count
								$(prefixes).each((index, prefElem) => {

									let newActivationCount = $(prefElem).attr(toggleStrActivationCount);

									newActivationCount--;

									$(prefElem).attr(toggleStrActivationCount, newActivationCount);

									// if no other input has triggered the prefix ( activation count = 0), hide it
									// e.g. think of two inputs for prename & lastname: When a lastname is removed again, we might still want the prefix to be shown
									if (newActivationCount < 1) {
										$(prefElem).addClass('h-hidden');
									}
								});
							}

						}
					});
				}
			});
		},

		/**
		 * copies values from a matching source to a matching target if the trigger fires
		 * e.g. write names from one input into another to save time for the user
		 */
		copyValues() {
			const that = this;

			this.$copyValueTriggers.on('change', function () {

				if ($(this).is(':checked')) {

					const matchingId = $(this).data('js-copy-val-trigger'),
						sources = that.$copyValueSources.filter(`[data-js-copy-val-src=${matchingId}]`),
						targets = that.$copyValueTargets.filter(`[data-js-copy-val-target=${matchingId}]`);

					let valToInsert = '';

					$(sources).each((index, elem) => {
						if ($(elem).val().length) {
							valToInsert = `${valToInsert + $(elem).val()} `;
						}
					});

					if (valToInsert.length) {
						$(targets).each((index, elem) => {
							$(elem).val(valToInsert);
							$(elem).trigger('change');
						});
					}
				}
			});
		}
	});
}(jQuery));
