(function ($)
{
    'use strict';
    /**
     * BasicTeaserAcross module implementation.
     *
     * @author  <l.meyer@edelweiss72.de>
     * @namespace T.Module
     * @class BasicTeaserAcross
     * @extends T.Module
     */
    T.Module.BasicTeaserAcross = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $imageToLoad: null,

        /** @type {jQuery} */
        $mainWrap: null,

        /** @type {jQuery} */
        $footerWrap: null,

        /** @type {jQuery} */
        $filterList: null,

        /** @type {jQuery} */
        $headerWrap: null,

        /** @type {jQuery} */
        $contentWrap: null,

        /** @type {Boolean} */
        isLazy: false,

		selectorScrollWrap: '.mm-scroll-wrap',
		selectorArticleContentFilter: '.mm-article-content--filter',
		selectorArticleContent:'.mm-article-filter',
		selectorBanderole: '.o-basic-banderole',

        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$imageToLoad = this.$ctx.find('.js-img');
            this.$contentWrap = this.$ctx.find('.mm-article-content');

            this.$mainWrap = this.$ctx.find(this.selectorArticleContent).eq(0);

            if (this.$mainWrap.length)
            {
                this.$footerWrap = this.$mainWrap.find('.mm-footer--filter');
                this.$filterList = this.$mainWrap.find(this.selectorScrollWrap);
                this.$headerWrap = this.$mainWrap.find(this.selectorArticleContentFilter);
                this.setScrollbar('init');
            }

            // listeners to modify search-container and scrollbar
            this._events.on('BasicKeywordfilter.searchKeyup', () =>
            {
                this.setScrollbar('search');
            });

            this._events.on('BasicKeywordfilter.reset', () =>
            {
                this.setScrollbar('refresh');
            });

            this.setHeight();

            if (!this.$ctx.parents(this.selectorBanderole).length)
            {
                this.isLazy = true;
            }

            resolve();
        },
        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj
         * @private
         */
		// eslint-disable-next-line
        _lazyUpdate(target, dataObj)
        {
            // as it might not be good to adapt the HTML markup
            // for lazy loading of the images,
            // a separate solution must be found that calls the old
            // logic (function) - when this element comes into the user's view.
            if (!this.$ctx.parents(this.selectorBanderole).length)
            {
                this.loadImages();
            }
        },

        /**
         * get slider with heighest Height and set height
         * @type {jQuery}
         */
        setHeight: function ()
        {
            let biggestheight = 0;

            this.$contentWrap.closest(this.selectorBanderole).find('article:not(mm-article-filters) .mm-article-content').each((index, item) =>
            {
                const itemHeight = item.getBoundingClientRect().height;

                if (biggestheight < itemHeight)
                {
                    biggestheight = itemHeight;
                }

            });

            this.$contentWrap.closest(this.selectorBanderole).find('.mm-article-content').css({'height': biggestheight});
        },

        /**
         * load images
         * @type {jQuery}
         */
        loadImages: function ()
        {
            // Load image from data-src into src
            this.$imageToLoad.attr('src', this.$imageToLoad.attr('data-src'))
                .removeAttr('data-src')
                .addClass('is-lazyloaded');

            this.$ctx.find('.swiper-lazy-preloader').fadeOut();
        },

        /**
         * add custom scrollbar to filter-list
         *
         * @param mode
         */
        setScrollbar: function (mode)
        {

            const that = this,
                bufferHeight = 10;

            if (mode === 'init')
            {

                const listHeight = that.$filterList.outerHeight(),
                    containerHeight = that.$mainWrap.outerHeight(),
                    headerHeight = that.$headerWrap.outerHeight(),
                    availableSpace = containerHeight - headerHeight - bufferHeight;

                // check if item is too big
                if (listHeight >= availableSpace)
                {

                    // wrapper needs fixed height, when done -> add scrollbar
                    that.$filterList.css('height', availableSpace).promise().done(() =>
                        {
                            that.$filterList.jScrollPane({showArrows: false});
                        }
                    );
                }

                this.resize();
            }

            if (mode === 'refresh')
            {

                $(that.selectorScrollWrap).each((index, item) =>
                {

                    const mainWrapper = $(item).parents(that.selectorArticleContent),
                        headWrapper = $(mainWrapper).find(that.selectorArticleContentFilter).eq(0),
                        availableSpace = $(mainWrapper).outerHeight() - $(headWrapper).outerHeight() - bufferHeight;

                    // scrollbar is already there -> update it
                    if ($(item).hasClass('jspScrollable'))
                    {
                        $(item).css('height', availableSpace).promise().done(() =>
                        {
                            $(item).data('jsp').reinitialise();
                        });
                    }

                    // determine if scrollbar is necessary
                    else
                    {
                        // check if item is bigger than container and head (+buffer)
                        if ($(item).outerHeight() >= availableSpace)
                        {
                            $(item).css('height', availableSpace).promise().done(() =>
                            {
                                $(item).jScrollPane({showArrows: false});
                            });
                        }
                    }
                });
            }

            if (mode === 'search' && this.$ctx.find('.mm-link.js-basickeywordfilter-result').length)
            {
                const mainWrapper = this.$ctx.find(this.selectorArticleContent),
                    headWrapper = this.$ctx.find(this.selectorArticleContentFilter).eq(0),
                    scrollWrapper = this.$ctx.find(this.selectorScrollWrap).eq(0),
                    availableSpace = $(mainWrapper).outerHeight() - $(headWrapper).outerHeight() - bufferHeight;

                // scrollbar is already there -> update it
                if ($(scrollWrapper).hasClass('jspScrollable'))
                {
                    S.Utils.delayed(`banderoleScrollbarSearch-${this.$ctx.data('t-id')}`, 50, () =>
                    {
                        $(scrollWrapper).css('height', availableSpace).promise().done(() =>
                        {
                            $(scrollWrapper).data('jsp').reinitialise();
                        });
                    });
                }
            }
        },

        /**
         * resize
         */
        resize: function ()
        {

            // build new scrollbar if desktop is reached
            S.Utils.Helper.mq('tablet').addListener(() =>
            {
                S.Utils.delayed(`banderoleScrollbarResize-${this.$ctx.data('t-id')}`, 50, () =>
                {
                    this.setScrollbar('refresh');
                });
            });
        }
    });
}(jQuery));
