(function($) {
	'use strict';
	/**
	 * BasicRate module implementation.
	 *
	 * @author n.wewior <n.wewior@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicRate
	 * @extends T.Module
	 */
	T.Module.BasicRate = T.createModule({

		$ctx: null,
		$starItems: null,

		start: function(resolve) {
			this.$ctx = $(this._ctx);
			this.$starItems = this.$ctx.find('.mm-item');
			this.$input = this.$ctx.find('input');

			this.initRate();

			resolve();
		},

		initRate()
		{
			this.$starItems.on('mouseenter', (star) => {
				for (let i = 0; i <= $(star.currentTarget).index(); i++) {
					this.$starItems.eq(i).addClass('is-hover');
				}
			});

			this.$starItems.on('mouseleave', (star) => {
				for (let i = 0; i <= $(star.currentTarget).index(); i++) {
					this.$starItems.eq(i).removeClass('is-hover');
				}
			});

			this.$starItems.on('click', (star) => {
				this.$input.val(($(star.currentTarget).index() + 1));
				this.$starItems.each(function() {
					if($(this).index() <= $(star.currentTarget).index()) {
						$(this).addClass('is-filled');
					}
					else {
						$(this).removeClass('is-filled');
					}
				});
			});
		}

	});
}(jQuery));
