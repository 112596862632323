(function ($) {
	'use strict';
	/**
	 * product decorator for the basictext module
	 *
	 * @author  <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicText
	 * @extends T.Module
	 */
	T.Module.BasicText.Product = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/**
		 * Initialize
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {

			this.$ctx = $(this._ctx);

			// check if active product exists
			if (sessionStorage.getItem('productActive')) {
				// set product image
				this.$ctx.find('img').eq(0).attr('src', sessionStorage.getItem('productActiveImage'));
				this.$ctx.find('picture').eq(0).find('source').attr('data-srcset', sessionStorage.getItem('productActiveImage'));
				this.$ctx.find('picture').eq(0).find('source').attr('srcset', sessionStorage.getItem('productActiveImage'));

				// set product title
				this.$ctx.find('.mm-prod-name').eq(0).text(sessionStorage.getItem('productActiveTitle'));
			}

			this._parent.start(resolve);
		}

	});
}(jQuery));
