'use strict';
(function ($) {
	/**
	 * HavaristCarDamageProof module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class HavaristCarDamageProof
	 * @extends T.Module
	 */
	T.Module.HavaristCarDamageProof = T.createModule({
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');
			T.Utils.Store.set('PdfCarDemageProof', null, "session");
			T.Utils.Store.set('PdfName', null, "session");
			this._readConfiguration();
			this._initValidation();
			this._addListeners();
			this._setDataToView();
			resolve();
		},
		/**
		* Read configuration
		*/
		_readConfiguration: function _readConfiguration() {
			this.apiCarDamageProof = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.$ctx.data("api-cardamageproof"));
		},
		_initValidation: function () {
			const validationOptions = {
				messages: this.$form.data('validation-messages') ? JSON.parse(this.$form.data('validation-messages')) : {},
				rules: {
					'number-plate': {
						required: true,
						pattern: "^[a-zA-ZÖöÜüÄä]+$",
						maxlength: 3
					},
					'number-plate-2': {
						required: true,
						pattern: "^[a-zA-Z\\d]+$"
					},
					'cardamage_ex_attribute': {
						required: true,
						pattern: "[^- ]*$"
					},
					'cardamage_id': {
						required: true,
						maxlength: 4,
						minlength: 4,
						digits: true
					},

				}
			};
			T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
		},
		/**
		 * Add events
		 */
		_addListeners: function _addListeners() {
			this.$ctx.find(".js-submit").click(this._handleSubmit.bind(this));
			this.$ctx.find('input[data-t-name="BasicInputText"]').change(this._setDataToSession.bind(this));
		},
		_handleSubmit: function _handleSubmit() {
			const self = this;
			if (self.$form.valid()) {
				T.Utils.View.startLoader();
				this._handleCarDamageProof(successCallback, errorCallback);
				T.Utils.View.stopLoader();
			}

			function successCallback(data) {
				const _data = data.Data;
				if (_data) {
					self.$ctx.find("#content_demand").removeClass("h-hidden");
					self.$ctx.find(".js-cardemageproof-form").addClass("h-hidden");
					const pdfLink = self.$ctx.find("#link_demand .a-basic-link--pdf");
					const pdfSize = pdfLink.find("span.a-basic-icon-pdf");
					if (pdfLink.length > 0) {
						let padding;
						if (_data.Beleg.endsWith("==")) {
							padding = 2;
						} else if (_data.Beleg.endsWith("=")) {
							padding = 1;
						} else {
							padding = 0;
						}
						const base64StringLength = _data.Beleg.length;
						const inBytes = (base64StringLength / 4) * 3 - padding;
						const kbytes = Math.ceil(inBytes / 10000);
						pdfLink.text(`${_data.Name}, `);
						pdfSize.append(` ${kbytes} kB`);
						pdfLink[0].appendChild(pdfSize[0]);

						// Internet explorer workaround for dataUri's
						if (navigator.msSaveBlob) {
							pdfLink.on('click', /* istanbul ignore next: not waitable */(e) => {
								e.preventDefault();
								window.navigator.msSaveBlob?.(T.Utils.Format.getBlobFromDataUri(_data.Beleg), _data.Name);
							});
						} else {
							// Safari doesn't support 'download' attributes, so he's going for the dataUri link
							pdfLink[0].href = `data:application/pdf;base64,${_data.Beleg}`;
							pdfLink[0].download = _data.Name;
						}
					}
				}
			}

			function errorCallback() {
				self.$ctx.find(".js-error").removeClass("h-hidden");
				T.Utils.View.stopLoader();
			}

		},
		/**
		 * trigger CarDamageProof request
		 */
		_handleCarDamageProof: function _handleCarDamageProof(successCallback, errorCallback) {
			const self = this;
			const nationalAttribute = self.$ctx.find('#nationalattribute');
			let kennzeichen = '';
			if (nationalAttribute[0].checked) {
				kennzeichen = `${self.$ctx.find('#number-plate').val()}-${self.$ctx.find('#number-plate-2').val()}`;
			}
			else {
				kennzeichen = self.$ctx.find('#cardamage_ex_attribute').val();
			}
			const options = {
				method: 'GET',
				xhrFields: { withCredentials: true },
				url: `${self.apiCarDamageProof}/${kennzeichen}/${self.$ctx.find('#cardamage_id').val()}`
			};
			T.Utils.Ajax.fragment(options, successCallback, errorCallback);
		},

		_setDataToSession: function _setDataToSession(elem) {
			let inputModel = T.Utils.Store.get('DemageProblemInputModel', 'session');
			if (inputModel === null || inputModel === undefined) {
				inputModel = {};
			}
			inputModel[elem.currentTarget.name] = elem.currentTarget.value;
			T.Utils.Store.set('DemageProblemInputModel', inputModel, 'session');
		},
		_setDataToView() {
			const inputModel = T.Utils.Store.get('DemageProblemInputModel', 'session');
			if (inputModel) {
				if (inputModel["number-plate"]) {
					this.$ctx.find("#number-plate").val(inputModel["number-plate"]);
				}
				if (inputModel["number-plate-2"]) {
					this.$ctx.find("#number-plate-2").val(inputModel["number-plate-2"]);
				}
				if (inputModel["cardamage_ex_attribute"]) {
					this.$ctx.find("#cardamage_ex_attribute").val(inputModel["cardamage_ex_attribute"]);
				}
				if (inputModel["cardamage_id"]) {
					this.$ctx.find("#cardamage_id").val(inputModel["cardamage_id"]);
				}
			}
		}
	});
})(jQuery);