(function ($) {
	'use strict';

	S.Calculator = S.Calculator || {};

	S.Calculator.Mgl = {

		/** @type {jQuery} */
		$form: null,

		/** @type {jQuery} */
		$childClone: null,

		/** @type {jQuery} */
		$childToDelete: null,

		/** @type {Number} */
		childCount: null,

		/** @type {Number} */
		childCountLength: null,

		/** @type {Number} */
		childCountMax: 15,

		modAddMgl: false,

		app: null,

		/**
		 * init form actions
		 *
		 */
		init() {
			this.$form = $('#calculator_mgl').eq(0);

			// classes/events/attr/data as strings
			this.dataCloneChild = '[data-clone="child"]';
			this.dataCloneTemplateChild = '[data-clone-template="child"]';
			this.clickSetCloneChildEvent = 'click.setCloneChildEvent';
			this.jsChildCloneWrapper = '.js-child-clone-wrapper';
			this.divDataCloneChild = 'div[data-clone="child"]';
			this.h3Link = 'h3 .a-basic-link';

			// update child count length
			this.childCountLength = this.$form.find(this.dataCloneChild).length;

			if (this.$form.find(this.dataCloneTemplateChild).length) {
				this.$childClone = this.$form.find(this.dataCloneTemplateChild).eq(0).prop('outerHTML').replace('data-clone-template', 'data-clone');
			}

			if (this.childCountLength === 0) {
				this.cloneChild();
			}

			// set event for clone child
			this.setCloneChildEvent();

			this.resetAttributesAndIds();

			this.checkAgeOfChildren();

			// set event for delete child
			this.setDeleteChildEvent();

			// remove removal-link of first child - it should only appear when the child is too old
			this.$form.find('[data-clone="child"] h3 .a-basic-link').eq(0).hide();
		},

		addMgl() {
			this.modAddMgl = true;

			this.$form = $('#calculator_add_mgl').eq(0);

			// update child count length
			this.childCountLength = this.$form.find(this.dataCloneChild).length;

			if (this.$form.find(this.dataCloneTemplateChild).length) {
				this.$childClone = this.$form.find(this.dataCloneTemplateChild).eq(0).prop('outerHTML').replace('data-clone-template', 'data-clone');
			}

			if (this.childCountLength === 0) {
				this.cloneChild();
			}

			// set event for clone child
			this.setCloneChildEvent();

			this.resetAttributesAndIds();

			this.checkAgeOfChildren();

			// set event for delete child
			this.setDeleteChildEvent();

			// remove removal-link of first child - it should only appear when the child is too old
			this.$form.find('[data-clone="child"] h3 .a-basic-link').eq(0).hide();

			$('.l-form input[type="checkbox"]').on('click', function () {
				$('#text_sticky_default').hide();
				$('#text_sticky_change').show();
			});
		},

		/**
		 * set clone child event
		 *
		 * @param {jQuery} inputElem
		 */
		setCloneChildEvent() {
			const inputElements = this.$form.find('div[data-clone="child"] .js-clone-child input');

			inputElements.each((index, inputElem) => {
				// checkbox add child
				$(inputElem).off(this.clickSetCloneChildEvent).on(this.clickSetCloneChildEvent, (e) => {
					if ($(e.target).is(':checked')) {
						this.cloneChild();

						setTimeout(function () {
							$(inputElem).blur();
						}, 10);
					}
					else {
						if ($(e.target).closest(this.dataCloneChild).next().length) {
							this.$childToDelete = $(e.target).closest(this.dataCloneChild).next();
						}
						else {
							this.$childToDelete = this.$form.find(this.jsChildCloneWrapper).closest('.ll-fieldset').eq(0);
						}

						$(e.target).prop('checked', true).blur();

						// layer delete child
						$.magnificPopup.open({
							mainClass: 'l-lightbox',
							items: {
								src: $('#layer_delete_child'),
								type: 'inline'
							}
						});
					}

					// Namics needs this
					$(document).trigger('cloneChildEvent');
				});
			});
		},

		/**
		 * set delete child event
		 *
		 * @param {jQuery} inputElem
		 */
		setDeleteChildEvent() {
			const deleteChildButton = this.$form.find('.js-delete-child').eq(0),
				statusNav = '.m-basic-status-nav';

			// checkbox add child
			deleteChildButton.off('click.setDeleteChildEvent').on('click.setDeleteChildEvent', (e) => {
				e.preventDefault();

				if (!this.$childToDelete.next(this.dataCloneChild).length) {
					this.$childToDelete.prev().find('.js-clone-child input').prop('checked', false);
				}

				if (this.childCountLength === 1) {
					this.$childToDelete = this.$form.find(this.jsChildCloneWrapper).closest('.ll-fieldset').eq(0);
					if ($(statusNav)) {
						const dataStepToDelete = this.$childToDelete.attr('data-step'),
							$basicStatusNav = $(statusNav);

						$basicStatusNav.find('.mm-step').eq(parseInt(dataStepToDelete, 10) - 1).remove();

						$(document).trigger('removeModules', $(statusNav).eq(0));
						$(document).trigger('addModules', $(statusNav).eq(0));
					}
				}

				this.$childToDelete.remove();

				if ($.magnificPopup.instance.isOpen) {
					$.magnificPopup.close();
				}

				this.countChildren();

				this.resetAttributesAndIds();
			});
		},

		setRemovalButtonEvents() {
			const inputElements = this.$form.find('div[data-clone="child"] h3 .a-basic-link');

			inputElements.each((index, inputElem) => {
				// checkbox add child
				$(inputElem).off('click.removalLink').on('click.removalLink', (e) => {
					e.preventDefault();
					$(e.target).closest(this.divDataCloneChild).find('.js-clone-child input').trigger(this.clickSetCloneChildEvent);
				});
			});
		},

		/**
		 * clone child
		 *
		 */
		cloneChild() {
			const activeChildCloneWrapper = this.$form.find(this.jsChildCloneWrapper);

			activeChildCloneWrapper.append(this.$childClone);

			this.childCountLength++;

			this.countChildren();

			this.resetAttributesAndIds();

			this.checkAgeOfChildren();

			S.Utils.Form.init();

			this.setCloneChildEvent();
		},

		checkAgeOfChildren() {
			const $ageInputFields = this.$form.find('div[data-clone="child"] input[id^="child_birthday"]');

			const checkForErrorsAndDisableButton = () => {
				$ageInputFields.each((index, birthdayItem) => {
					if ($(birthdayItem).hasClass('is-error') && index === 0) {
						$(birthdayItem).closest(this.divDataCloneChild).find(this.h3Link).show();
					}
				});
			};

			$ageInputFields.on('change.checkForAge', (e) => {
				const ageOfCurrentInput = S.Utils.Form.getAge($(e.target).val());

				if (ageOfCurrentInput >= 18 && ageOfCurrentInput <= 23) {
					$(e.target).removeClass('is-error').closest('.ll-row').removeClass('is-error');
				}
				else {
					$(e.target).addClass('is-error').closest('.ll-row').addClass('is-error');
				}

				checkForErrorsAndDisableButton();
			});
		},

		resetAttributesAndIds() {
			const clones = this.$form.find('div.js-child-clone-wrapper div[data-clone="child"]'),
				clickLinkRemoveChildEvent = 'click.basicLinkRemoveChild';

			// update name/id/for attributes
			clones.each((index, clone) => {
				$(clone).find('input').each((_, item) => {
					$(item).attr('name', `${$(item).attr('name').replace(/_\d+$/, '')}_${index}`);
					$(item).attr('id', `${$(item).attr('id').replace(/_\d+$/, '')}_${index}`);
					$(document).trigger('addModules', $(item));
				});
				$(clone).find('label[for]').each((_, item) => {
					$(item).attr('for', `${$(item).attr('for').replace(/_\d+$/, '')}_${index}`);
				});
				$(clone).find('[data-depend]').each((_, item) => {
					$(item).attr('data-depend', `${$(item).attr('data-depend').replace(/_\d+$/, '')}_${index}`);
				});
				$(clone).find('h3 span').html(index + 1);

				$(clone).find(this.h3Link).off(clickLinkRemoveChildEvent).on(clickLinkRemoveChildEvent, (e) => {
					e.preventDefault();
					$(e.target).closest(this.divDataCloneChild).prev(this.divDataCloneChild).find('.js-clone-child input:checked').last().trigger('click');
				});

				if (index === 0) {
					$(clone).find(this.h3Link).eq(index).off(clickLinkRemoveChildEvent).on(clickLinkRemoveChildEvent, (e) => {
						e.preventDefault();
						$.magnificPopup.open({
							mainClass: 'l-lightbox',
							items: {
								src: $('#layer_delete_child'),
								type: 'inline'
							}
						});
					});

					this.$childToDelete = $(clone);
				}
			});
		},

		/**
		 * count children
		 *
		 */
		countChildren() {
			this.childCountLength = this.$form.find(this.dataCloneChild).length;

			if (this.childCountLength === this.childCountMax) {
				this.$form.find(this.dataCloneChild).last().find('.js-clone-child').hide();
			}
		}

	};

	$(document).ready(function () {
		if ($('#calculator_mgl').length) {
			S.Calculator.Mgl.init();
		}

		if ($('#calculator_add_mgl').length) {
			S.Calculator.Mgl.addMgl();
		}
	});

})(jQuery);
