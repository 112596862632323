(function ($)// eslint-disable-line
{
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.GetUrlParameter = {

		/**
		 * Usage/examples
		 *
		 * checkIfParameterExists(neededParam)
		 *      - checks for an parameter if its exists
		 *
		 * checkForUrlParameter(neededParam, neededParamValue = false)
		 *      - checks for an parameter and if the parameter has a value
		 *      - or compare specific value if the parameter neededParamValue is filled
		 */

		/** @type {Array} sParameterName*/
		sParameterName: null,

		/** @type {String} sPageURL*/
		sPageURL: null,

		/** @type {String|Number} paramValue*/
		paramValue: null,

		/** @type {String} paramType*/
		paramType: null,

		/** @type {String} paramType*/
		neededParam: null,

		/**
		 * check for an given parameter - only to check if its there
		 * @param {String} neededParam
		 * @returns {*|boolean|boolean} true or false
		 */
		checkIfParameterExists(neededParam) {
			/**
			 * the neededParam in the given URL needs a value, if not it will give u false back
			 *      - there would be nothing to check
			 */
			this.sPageURL = window.location.search.substring(1);
			this.sURLVariables = this.sPageURL.split('&');
			this.neededParam = neededParam;

			// go through all parameters
			for (let i = 0; i < this.sURLVariables.length; i++) {
				this.sParameterName = this.sURLVariables[i].split('=');

				// check if the parameter at postion [0] exists
				if (this.sParameterName[0] === this.neededParam) {
					// and then return true
					return true;
				}
			}

			// return false
			return false;
		},

		/**
		 * check for an given parameter value and
		 * @param {String} neededParam
		 * @param {String|Number} neededParamValue  - if not it´s 'undefined'
		 * @returns {*|boolean|boolean} true or false
		 */
		checkForUrlParameter(neededParam, neededParamValue = false) {
			/**
			 * the neededParam in the given URL needs a value, if not it will give u false back
			 *      - there would be nothing to check
			 */
			this.sPageURL = window.location.search.substring(1);
			this.sURLVariables = this.sPageURL.split('&');
			this.paramValue = !neededParamValue ? undefined : neededParamValue;
			this.paramType = typeof this.paramValue;
			this.neededParam = neededParam;

			// check which type it is
			// compare for number
			if (this.paramType === 'number') {
				return this.checkForNumberCompare();
			}
			// compare for all others
			return this.checkForStringCompare();
		},

		/**
		 * compare two numbers
		 * @returns {boolean}
		 */
		checkForNumberCompare() {
			// go through all parameters
			for (let i = 0; i < this.sURLVariables.length; i++) {
				this.sParameterName = this.sURLVariables[i].split('=');

				// check if the parameter at postion [0] is available
				if (this.sParameterName[0] === this.neededParam) {
					// if its undefined, no value to check
					if (this.paramValue === undefined) {
						// just check if a parameter is given
						return this.sParameterName[1] !== this.paramValue;
					}

					// put this to a number
					const paramNameNumber = parseInt(this.sParameterName[1], 10);

					// the check for an value, if it isnt true return false ? return true
					return paramNameNumber === this.paramValue;
				}
			}

			// if the parameter at postion [0] isnt available or isnt the same as needed - return false
			return false;
		},

		/**
		 * compare two strings
		 * @returns {boolean}
		 */
		checkForStringCompare() {
			// go through all parameters
			for (let i = 0; i < this.sURLVariables.length; i++) {
				this.sParameterName = this.sURLVariables[i].split('=');
				// check if the parameter at postion [0] is available

				// check if the parameter at postion [0] is available
				if (this.sParameterName[0] === this.neededParam) {
					// if its undefined, no value to check
					if (this.paramValue === undefined) {
						// just check if a parameter is given
						return this.sParameterName[1] !== this.paramValue;
					}
					// the check for an value, if it isnt true return false ? return true
					return this.sParameterName[1] === this.paramValue;
				}
			}

			// if the parameter at postion [0] isnt available or isnt the same as needed - return false
			return false;
		},

		/**
		 * get multiple URL params
		 * @param {String|Object} paramToReturn
		 * @return {Array[Object]}
		 */
		getUrlParams(paramToReturn) {
			const neededParams = typeof paramToReturn === 'object' ? paramToReturn : [paramToReturn];

			return this.getValues(neededParams);
		},

		/**
		 * @param {Array[String]} paramToSearchFor
		 * @return {Array[Object]}
		 */
		getValues(paramToSearchFor) {
			const sPageURL = window.location.search.substring(1),
				URLparams = sPageURL.split('&'),
				arr = [];

			// go through all parameters
			for (const param of URLparams) {
				const split = param.split('='),
					key = split[0],
					value = split[1];

				for (const nParam of paramToSearchFor) {
					const isNumber = S.Utils.Helper.checkForNumberFromString(value),
						obj = {};

					if (nParam === key) {
						obj[key] = isNumber ? parseInt(value, 10) : value;

						arr.push(obj);
					}
				}
			}

			return arr;
		},
	};
})(jQuery);
