(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.Controller = {

		$classDataScreen: '[data-screen]',
		$imageType: "image/jpeg",

		/**
		 * init form actions
		 *
		 */
		init: function () {
			// scroll to Container by click on element with data-scrollto="myclassname"
			this.scrollTo();

			// print
			this.print();

		},

		/**
		 * scroll to Container by click on element with data-scrollto="myclassname"
		 */
		scrollTo: function () {
			$('[data-scrollto]').each(function (index, item) {
				const classToScroll = $(item).data('scrollto');

				$(item).bind('click', function () {
					$('html, body').animate({
						scrollTop: $(`.${classToScroll}`).offset().top
					}, 1000);
				});
			});
		},

		/**
		 * print
		 */
		print: function () {
			$('[data-print]').each(function (index, item) {
				const printArea = $(item).data('print');

				$(item).bind('click', function (e) {
					e.preventDefault();

					if (printArea === 'all') {
						window.print();
					}
				});
			});
		},

		screen: function () {
			// eslint-disable-next-line
			if ($(this.$classDataScreen).length) {
				if (window.HTMLCanvasElement) {
					// eslint-disable-next-line
					html2canvas(document.querySelector(this.$classDataScreen)).then(function (canvas) {
						const imageCode = canvas.toDataURL(this.$imageType);

						$('body').append(`<img src="${imageCode}" alt="">`);
						// do something with that stuff
					});
				}
			}
		},

		/**
		 * screenshot: HTMLCanvasElement:
		 * @param (imageType) - image type jpeg or png or ....
		 * @param (callback) -
		 */
		screenshot: function screenshot(imageType, callback) {
			let imageCode = null;
			// eslint-disable-next-line
			if ($(this.$classDataScreen).length) {
				if (window.HTMLCanvasElement) {
					// eslint-disable-next-line
					html2canvas(document.querySelector(this.$classDataScreen), { allowTaint: true }).then(function (canvas) {
						// store dimensions for better maintainability
						if (!imageType) {
							imageType = this.$imageType;
						}

						const iOSPixelLimit = 5000000,
							canvasPixelSum = canvas.width * canvas.height;

						try {

							// determine if product of canvasWidth and canvasHeight is > iOS pixel limit && data is not empty
							if ((canvasPixelSum > iOSPixelLimit) && (canvas.toDataURL(this.$imageType) === 'data:,')) {
								// eslint-disable-next-line
								console.log('Canvas is > 5 Mio Px! | ', canvasPixelSum); //NOSONAR
								// eslint-disable-next-line
								console.log('Data is empty!');//NOSONAR
							} else {
								imageCode = canvas.toDataURL(imageType, 0.1);
							}
						} catch (err) {
							// if something went wrong (especially for mobile device madness)
							// eslint-disable-next-line
							console.log("err!");//NOSONAR
						}
						callback(imageCode);
					});
				}
			}
			// if HTMLCanvasElement not supported or empty return null object
			return imageCode;
		}

	};

	$(document).ready(function () {
		S.Utils.Controller.init();
	});

})(jQuery);
