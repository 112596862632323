/// <reference path="../../../../assets/typings/terrific-ext.d.ts" />

'use strict';
(function ($) {
	/**
	 * FuelPricesInternational module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class FuelPricesInternational
	 * @extends T.Module
	 */
	T.Module.FuelPricesInternational = T.createModule({
		validationOptions: {
			rules: {
				Country: {
					required: true
				}
			}
		},
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this._readConfiguration();
			this._addListeners();
			resolve();
		},
		_readConfiguration: function _readConfiguration() {
			this._apikraftstoffinfonachland = this.$ctx.data('apikraftstoffinfonachland');
			this.$itemTemplate = $(this.$ctx.find(".js-fuelprices-rowtpl").html());
			this.$hintTemplate = $(this.$ctx.find(".js-fuelprices-hinttpl").html());

			this.$form = this.$ctx.find('form.js-fuelprices-filter');
			this.validationOptions.messages = $.extend({}, this.validationOptions.messages, this.$ctx.data('validationoption'));
			T.Utils.Validation.init(this.$ctx, this.$form, this.validationOptions);
		},
		_addListeners: function _addListeners() {
			this.$ctx.find('form').on('submit', this._handleSubmit.bind(this));
			this.$ctx.find('.js-submit-search').on('click', this._handleSubmit.bind(this));
		},
		_handleSubmit: function _handleSubmit(e) {
			if (e) {
				e.preventDefault();
			}
			if (this.$form.valid()) {
				this._requestFuelPrices(this._handleFuelPricesSuccess.bind(this), this._handleFuelPricesError.bind(this));
			}
		},
		_requestFuelPrices: function _requestFuelPrices(success, error) {
			T.Utils.View.startLoader();
			this._showError(false);
			const url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._apikraftstoffinfonachland);
			const req = {
				type: "GET",
				url: url,
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() },
				data: {
					country: 0,
					countryiso2: this.$ctx.find('[name="Country"]').val()
				}
			};
			T.Utils.Ajax.fragment(req, success, error);
		},
		_handleFuelPricesSuccess: function _handleFuelPricesSuccess(data) {
			let hasResults = false;
			let hintCount = 0;
			const $prices = this.$ctx.find('table.js-fuelprices-table tbody');
			$prices.empty();
			const $hints = this.$ctx.find('.js-fuelprices-hints');
			$hints.empty();

			try {
				if (data && data.Data) {
					if (data.Data.KraftstoffPreis) {
						hasResults = !!data.Data.KraftstoffPreis.length;
						data.Data.KraftstoffPreis.forEach(element => {
							this._createResultRow($prices, element);
						});
					}
					if (!hasResults) {
						this._createResultRow($prices, {});
					}

					if (data.Data.ListOfBlocks) {
						data.Data.ListOfBlocks.forEach(block => {
							const list = block.ListOfContets || block.ListOfContents || [];
							list.forEach(element => {
								hintCount += this._createResultHint($hints, element);
							});
						});
					}
				}
				this._showError(false);
			} catch (e) {
				this._showError(true, hasResults);
				T.Utils.Logger.log("Error rendering results", e);
			} finally {
				$hints.toggleClass("h-hidden", 0 === hintCount);
				this.$ctx.find('.js-fuelprices-resultdesc').toggleClass('h-hidden', !hasResults);
				T.Utils.View.stopLoader();
			}
		},
		_handleFuelPricesError: function _handleFuelPricesError() {
			this._showError(true);
			T.Utils.View.stopLoader();
		},
		_createResultRow: function _createResultRow(container, fuelprice) {
			const $row = this.$itemTemplate.clone();
			$row.find('.js-fueltype-de').html(fuelprice.KraftstoffTyp || "-");
			$row.find('.js-fueltype-nat').html(fuelprice.KraftstoffTypCountry || "-");
			$row.find('.js-fuelprice-nat').text(this._formatPrice(fuelprice.KraftstoffPreisCountry));
			$row.find('.js-fuelprice-eur').text(this._formatPrice(fuelprice.KraftstoffPreis));
			container.append($row);
		},
		_createResultHint: function _createResultHint(container, content) {
			if (content && content.Text) {
				const $hint = this.$hintTemplate.clone();
				$hint.find(".js-fuelprices-hint").html(`* ${content.Text}`);
				container.append($hint);
				return 1;
			}
			return 0;
		},
		_formatPrice: function _formatPrice(price) {
			if (!price) {
				return "0,00";
			}
			return price.replace(/\s+.*$/, ""); //NOSONAR
		},
		_showError: function _showError(show, keepResults) {
			this.$ctx.find('.js-fuelprices-error').toggleClass('h-hidden', !show);
			this.$ctx.find('.js-fuelprices-result').toggleClass('h-hidden', show && !keepResults);
		}
	});
})(jQuery);