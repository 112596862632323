(function ($) {
	'use strict';
	/**
	 * Award decorator implementation for the vescalculatorshort component.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class Rrv
	 * @extends T.Module
	 */
	T.Module.VesCalculatorShort.Rrv = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {String} */
		membership: 'ADACMitglied',

		/** @type {Number} */
		age: 18,

		/** @type {String} */
		singleRate: 'true',

		/** @type {String} */
		insurance: '2000',

		/** @type {String} */
		deductible: 'true',

		/** @type {String} */
		produktKey: '',

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.api = this.api || $('meta[data-key="backend"]').attr('data-value');
			this.calculator = this.$ctx.data('calculator');

			this.calcProposal = '.js-calculator-proposal';

			// check insurance
			this.checkInsurance();

			// check deductible
			this.checkDeductible();


			this._parent.start(resolve);
		},


		/**
		 * DUMMY
		 */
		dummy: function () {
			// init proposal btns
			this.$ctx.find(this.calcProposal).off().on('click', function (e) {
				e.preventDefault();
			});
		},


		/**
		 * check membership :: ADACMitglied || Kunde
		 *
		 */
		checkMembership: function () {
			const that = this;

			// init change
			this.$ctx.find('input[name="membership"]').on('change', function () {
				that.membership = that.$ctx.find('input[name="membership"]:checked').val();

				// calculate
				that.calculate();
			});
		},


		/**
		 * check rate :: single || family
		 *
		 */
		checkRate: function () {
			const that = this,
				selectInsuranceOption = 'select[name="insurance"] > option';
			let options = '';

			// init change
			this.$ctx.find('input[name="rate"]').on('change', function () {
				if (that.$ctx.find('input[name="rate"]:checked').val() === 'single') {
					that.singleRate = 'true';

					// reset HTML of option '1.000'
					$(options).insertBefore(that.$ctx.find(selectInsuranceOption).eq(0));
				} else {
					that.singleRate = 'false';

					// save options HTML
					options = that.$ctx.find(selectInsuranceOption).eq(0).prop('outerHTML');

					// hide option '1.000'
					that.$ctx.find(selectInsuranceOption).eq(0).remove();

					// if family insurance >= 2000
					if (parseInt(that.insurance) < 2000) {
						that.insurance = '2000';

						// reset dropdown
						that.$ctx.find('select[name="insurance"]').val(that.$ctx.find(selectInsuranceOption).eq(0).attr('value'));
					}
				}

				// calculate
				that.calculate();
			});
		},


		/**
		 * check insurance :: 1500 || 3000 || 4000 || 5000 || 7500 || 10000
		 *
		 */
		checkInsurance: function () {
			const that = this;

			// init change
			this.$ctx.find('select[name="insurance"]').on('change', function () {
				const value = $(this).val().replace(/\D/g, '');

				that.insurance = value;

				// calculate
				that.calculate();
			});
		},


		/**
		 * check deductible :: true || false
		 *
		 */
		checkDeductible: function () {
			const that = this;

			// init change
			this.$ctx.find('input[name="deductible"]').on('change', function () {
				that.deductible = that.$ctx.find('input[name="deductible"]:checked').val();

				// calculate
				that.calculate();
			});
		},

		/**
		 * calculate Edelweiss
		 */
		calculate_EW: function () {
			const that = this;
			let result = '';

			$.getJSON("/dev/api/calculator/rrvbep.json", function (data) {
				let count = 0; // count result for testing
				const dummyArray = []; // for testing

				$.each(data.RRVBEP, function (index, item) {
					if (item.Mitglied === that.membership
						&& item.Typ === 'BASIS'
						&& item.RateBeschreibung === 'jaehrlich'
						&& parseInt(item.MinimalAlter) <= that.age
						&& parseInt(item.MaximalAlter) >= that.age
						&& item.Reisepreis === that.insurance
						&& item.Einzeltarif === that.singleRate
						&& item.Selbstbehalt === that.deductible) {
						count++; // dummy
						dummyArray.push(item.ProduktKey); // dummy

						that.produktKey = item.ProduktKey;
						result = item.Beitrag;
					}
				});

				let restultsring = '';
				for (let x = 0; x < dummyArray.length; x++) {
					restultsring += `${dummyArray[x]}, `;
				}

				$('.m-basic-toggle .js-content').append(`<br><strong>Kriterien: </strong>Mitgliedschaft: ${that.membership}, Alter: ${that.age}, Einzeltarif: ${that.singleRate}, Versicherungssumme: ${that.insurance}, Selbstbeteiligung: ${that.deductible}`);
				$('.m-basic-toggle .js-content').append(`<br>${count} Ergebnisse(${restultsring}) => Tarif: ${$.number(result, 2, ',')}`);

				// update sticky box
				that.$ctx.find('.mm-amount-value').html($.number(result, 2, ','));
			});
		},

		/**
		 * calculate
		 *
		 */
		calculate: function () {
			const that = this,
				membershipCheck = (that.membership === 'ADACMitglied'),
				familyCheck = (that.singleRate === 'false');
			let result = '';

			const data = {
				IstMitglied: membershipCheck,
				Familientarif: familyCheck,
				Reisepreis: that.insurance,
				Selbstbeteiligung: that.deductible
			};

			T.Utils.Store.set('vesCalculatorShort--rrv', data, 'session');

			T.Utils.Ajax.fragment(
				{
					url: this.api + this.calculator,
					type: 'POST',
					data: data,
					contentType: "application/x-www-form-urlencoded;charset=utf-8"
				},
				(response) => {
					result = response.Data.Beitrag;

					// update sticky box
					that.$ctx.find('.mm-amount-value').html($.number(result, 2).replace('.', ','));

					that.$ctx.find('.mm-error').hide();
					that.$ctx.find(this.calcProposal).prop('disabled', false).removeClass('is-disabled');
					that.$ctx.find('.mm-value-container').show();
				},
				(err) => // eslint-disable-line
				{
					that.$ctx.find('.mm-error').show();
					that.$ctx.find(this.calcProposal).prop('disabled', true).addClass('is-disabled');
					that.$ctx.find('.mm-value-container').hide();

					that.$ctx.find('.a-basic-btn').on('click', function (e) {
						e.preventDefault();
					});

				});

		}

	});
}(jQuery));
