/**
 * EventTrackingGenericMyAdac implementation
 *
 * @author <marc.radziwill@merkle.com>
 * @class EventTrackingGenericMyAdac
*/

// eslint-disable-next-line no-undef
class EventTrackingGenericMyAdac extends EventTrackingGeneric {


	_connectedCallback() {
		this._myAdacCategory = this._getMyAdacCategory();
		super._connectedCallback();
	}

	_getEventActionByElement($element) {
		if (this._isInsideMain($element)) {
			const headline = this._getPageHeadline($element);
			if (headline) {
				return `${headline} | click`;
			}
		}
		return super._getEventActionByElement($element);
	}

	_getPageHeadline($element) {
		return T.Utils.Format.normalizeSpace($element.closest(".l-main-content").find("h1").text());
	}

	_getEventCategory(element, action) {
		if (this._myAdacCategory && this._isInsideMain(element)) {
			return this._myAdacCategory;
		}
		return super._getEventCategory(element, action);
	}

	_isInsideMain(element) {
		return !!this.$main.has(element).length;
	}

	_getMyAdacCategory() {
		let result = T.Utils.Store.get(`${this._sessionKeyEventCategory}:${window.location.pathname}`, T.Utils.Store.SESSION);
		if (!result) {
			result = T.Utils.Store.get(this._sessionKeyEventCategory, T.Utils.Store.SESSION);
			T.Utils.Store.set(`${window.location.pathname}:${this._sessionKeyEventCategory}`, result, T.Utils.Store.SESSION);
		}
		T.Utils.Store.set(this._sessionKeyEventCategory, null, T.Utils.Store.SESSION);
		return result;
	}
}
window.customElements.define('event-tracking-generic-myadac', EventTrackingGenericMyAdac);