'use strict';
(function ($) {
	/**
	 * MotorcycleStructuredSearch module implementation.
	 *
	 * @author Alex Kröll <alexander.kroell@namics.com>
	 * @namespace T.Module
	 * @class MotorcycleStructuredSearch
	 * @extends T.Module
	 */
	T.Module.MotorcycleStructuredSearch = T.createModule({
		_brandFld: 'select[name="select_brand"]',
		_motorcyclesTypesBoxes: 'input[name="motorcycle-types"]',
		_motorcyclesGenreBoxes: 'input[name="motorcycles-genre"]',

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.$motorcycleGenres = this.$ctx.find('.js-motorcycle-genres');
			this.$componentContainer = this.$ctx.find('#js-component-container');
			this.$globalErrorContainer = this.$ctx.find('.js-motorcyclesstructuredsearch-error');
			this.$genreContainer = this.$ctx.find('.js-genre-container');
			this.$filtersForm = this.$ctx.find('.js-filters-form');
			this.$resultContainer = this.$ctx.find('.js-result-container');
			this.$motorcycleTypeTemplate = $(this.$ctx.find('#js-motorcycle-type-template').html());
			this.$motorcycleGenreTemplate = $(this.$ctx.find('#js-motorcycle-genre-template').html());
			this.$rowTemplate = $(this.$ctx.find('#js-row-template').html());

			T.Utils.Application.init();

			this._readConfig();
			this._addListeners();
			this._requestMotorcycleTypes();



			resolve();
		},
		_readConfig: function _readConfig() {
			this.config = this.$ctx.data('config');
		},
		_addListeners: function _addListeners() {

			this.$ctx.find('.js-filters-form').submit(this._submitSearch.bind(this, false));
			this.$ctx.find('.js-submit-search').on('click', this._submitSearch.bind(this, false));
			this.$ctx.find('.js-show-all').on('click', this._submitSearch.bind(this, true));
			this.$ctx.find(this._brandFld).on('change', this._clearResultList.bind(this));
		},

		_loadGenreContainer: function _loadGenreContainer() {

			const noTypesAreChecked = this._noneIsChecked(this._motorcyclesTypesBoxes);
			const noGenresAreChecked = this._noneIsChecked(this._motorcyclesGenreBoxes);

			if (noTypesAreChecked) {

				this.$motorcycleGenres.empty();
				this.$filtersForm.toggleClass('h-hidden', true);
				this.$genreContainer.toggleClass('h-hidden', true);

			}
			else {
				if (!noGenresAreChecked) {
					this._requestBrand();
				}
				this.$genreContainer.toggleClass('h-hidden', false);
				this._requestGenres();
			}

			this.$resultContainer.toggleClass('h-hidden', true);
		},

		_loadFilterContainer: function _loadFilterContainer() {
			this._clearDropdown(this._brandFld);
			this.$filtersForm.toggleClass('h-hidden', this._noneIsChecked(this._motorcyclesGenreBoxes));
			this.$resultContainer.toggleClass('h-hidden', this._noneIsChecked(this._motorcyclesGenreBoxes));
			this._requestBrand();
			this._events.on('BasicKeywordfilter.filterDoubleSlider', () => {
				this._clearResultList();
			});


		},
		_clearResultList: function _clearResultList() {
			this.$resultContainer.toggleClass('h-hidden', true);
			this.$resultContainer.find('.m-counter').empty();
			this.$resultContainer.find('tbody').empty();

		},
		_clearFilters: function _clearFilters() {
			this.$ctx.find(".mm-tile-container--checkbox input:checkbox").prop('checked', false);
			this._loadGenreContainer();
		},
		_requestMotorcycleTypes: function _requestMotorcycleTypes() {
			T.Utils.View.startLoader();
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.config.apiMotorcycleDataType);

			const request = {
				type: "GET",
				url: apiUrl,
			};
			T.Utils.Ajax.fragment(request, this._handleSuccessRequestMotorcycleTypes.bind(this), this._handleErrorRequestResults.bind(this));

		},

		_handleSuccessRequestMotorcycleTypes: function _handleSuccessRequestMotorcycleTypes(data) {

			if (data && data.Data && data.Data.Fahrzeugarten) {

				const srcCollection = data.Data.Fahrzeugarten;
				const iconConfig = this.config.imagesMotorcycleType;
				for (let i = 0; i < iconConfig.length; i++) {

					const addClass = this._determineTileClass(i, srcCollection.length);
					const $clone = this._initializeCheckboxClone(this.$motorcycleTypeTemplate.clone(), iconConfig[i].type, addClass);
					let icon = null;

					srcCollection.forEach((element) => {
						if (element === iconConfig[i].type) {
							icon = iconConfig[i].url;
						}
					});
					$clone.find('img').attr('src', icon);

					this.$ctx.find('.js-motorcycle-types').append($clone);
					T.Utils.Helper.initAdditionalModules($clone);

				}
			}

			this.$ctx.find(this._motorcyclesTypesBoxes).on('click', this._clearResultList.bind(this));
			this.$ctx.find(this._motorcyclesTypesBoxes).on('click', this._loadGenreContainer.bind(this));

			//load all results for all types received from apil
			this._submitSearch(true);
			T.Utils.View.stopLoader();
		},


		_requestGenres: function _requestGenres() {
			T.Utils.View.startLoader();
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.config.apiMotorcycleDataCategory);
			const checkedTypes = this._getAllCheckedInputs(this._motorcyclesTypesBoxes);
			const payload = {
				fahrzeugarten: []
			};
			const request = {
				type: "GET",
				url: apiUrl,
				data: payload
			};

			checkedTypes.forEach((element) => {
				payload.fahrzeugarten.push(element);

			});
			T.Utils.Ajax.fragment(request, this._handleSuccessRequestGenres.bind(this), this._handleErrorRequestResults.bind(this));

		},

		_handleSuccessRequestGenres: function _handleSuccessRequestGenres(data) {

			if (data && data.Data && data.Data.Motorradgattungen) {

				const previouslyChecked = this._getAllCheckedInputs(this._motorcyclesGenreBoxes);
				this.$motorcycleGenres.empty();
				const srcCollection = data.Data.Motorradgattungen;

				for (let i = 0; i < srcCollection.length; i++) {

					const addClass = this._determineTileClass(i, srcCollection.length);
					const $clone = this._initializeCheckboxClone(this.$motorcycleGenreTemplate.clone(), srcCollection[i], addClass);

					const $input = $clone.find('input');
					const inputValue = $input.val();
					if (previouslyChecked.indexOf(inputValue) > -1) {
						$input.prop('checked', true);
					}
					this.$motorcycleGenres.append($clone);
					T.Utils.Helper.initAdditionalModules($clone);
				}
			}

			this.$ctx.find(this._motorcyclesGenreBoxes).on('click', this._loadFilterContainer.bind(this));
			this.$ctx.find(this._motorcyclesGenreBoxes).on('click', this._clearResultList.bind(this));


			T.Utils.View.stopLoader();
		},
		_requestBrand: function _requestBrand() {
			T.Utils.View.startLoader();
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.config.apiMotorcycleDataBrand);
			const checkedTypes = this._getAllCheckedInputs(this._motorcyclesTypesBoxes);
			const checkedGenres = this._getAllCheckedInputs(this._motorcyclesGenreBoxes);

			const payload = {
				fahrzeugarten: checkedTypes,
				gattungen: checkedGenres

			};
			const request = {
				type: "GET",
				url: apiUrl,
				data: payload
			};


			T.Utils.Ajax.fragment(request, this._handleSuccessRequestBrands.bind(this), this._handleErrorRequestResults.bind(this));
		},

		_handleSuccessRequestBrands: function _handleSuccessRequestBrands(data) {

			this._clearDropdown(this._brandFld);
			const $element = this.$ctx.find(this._brandFld);

			if (data && data.Data && data.Data.Marken) {

				data.Data.Marken.forEach(val => {
					$element.append($('<option/>').attr('value', val).text(val));
				});
			}
			T.Utils.View.stopLoader();
		},

		_requestResults: function _requestResults(all) {
			T.Utils.View.startLoader();
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.config.apiMotorcycleDataModel);
			const brand = this._getDropdownVal(this._brandFld);
			const checkedTypes = this._getAllCheckedInputs(this._motorcyclesTypesBoxes);
			const checkedGenres = this._getAllCheckedInputs(this._motorcyclesGenreBoxes);

			const sliderVals = this._getSliderValues();
			let payload = null;

			if (!all) {
				payload = {
					leistungMin: sliderVals.enginePowerMin,
					leistungMax: sliderVals.enginePowerMax,
					sitzhoeheMin: sliderVals.seatHeightMin,
					sitzhoeheMax: sliderVals.seatHeightMax,
					hubraumMin: sliderVals.cylinderCapMin,
					hubraumMax: sliderVals.cylinderCapMax,
					preisMin: sliderVals.priceRangeMin,
					preisMax: sliderVals.priceRangeMax,
					fahrzeugarten: checkedTypes,
					gattungen: checkedGenres

				};
				if (brand !== "") {
					payload.marken = brand;
				}
			}


			const request = {
				type: "GET",
				url: apiUrl,
				data: payload
			};


			T.Utils.Ajax.fragment(request, this._handleSuccessRequestResults.bind(this), this._handleErrorRequestResults.bind(this));

		},

		_handleSuccessRequestResults: function _handleSuccessRequestResults(data) {

			this._clearResultList();
			this._resetBasicKeywordFilter();
			if (data && data.Data && data.Data.Modelle && data.Data.Anzahl) {

				if (data.Data.Anzahl > 0) {
					this.$resultContainer.find('.js-noresult').toggleClass('h-hidden', true);

					const srcCollection = data.Data.Modelle;

					srcCollection.forEach((item) => {
						const $clone = this.$rowTemplate.clone();
						const $input = $clone.find('input');
						const mopedName = `${item.Marke} ${item.Modelbezeichnung}`;
						$input.attr('data-compare-product-name', mopedName);
						$input.attr('data-compare-product-id', item.Id);
						$input.attr('data-compare-product-img', item.Bild);

						$input.toggleClass('h-hidden', true);

						const detailsPageUrl = this.config.detailsPageUrl.interpolate({
							id: item.Id,
							name: T.Utils.Helper.createSeoUrl(mopedName)
						});

						const tableRowName = '.js-tablerow-name';

						$clone.find(tableRowName).find('a').attr('href', detailsPageUrl);
						$clone.find('.js-mobile-link').find('a').attr('href', detailsPageUrl);
						// image is going to be found at (need for comparison basket)https://www.adac.de/_ext/itr/tests/motorrad/

						$clone.find(tableRowName).find('a').attr('aria-label', mopedName);
						$clone.find(tableRowName).find('a').append(mopedName);
						$clone.find(tableRowName).find('div').append(mopedName);
						$clone.find('.js-tablerow-genre').append(item.Gattung);
						$clone.find('.js-tablerow-engine-power').append(item.Leistung);
						$clone.find('.js-tablerow-cylinder-cap').append(item.Hubraum);
						$clone.find('.js-tablerow-price').append(`${item.Preis},–`);
						$clone.attr('data-filter', '');

						this.$resultContainer.find('tbody').append($clone);
					});

				}
				else {
					this.$resultContainer.find('.js-noresult').toggleClass('h-hidden', false);
				}
				this.$resultContainer.find('.m-counter').html(`(${data.Data.Anzahl})`);
			}
			this.$resultContainer.toggleClass('h-hidden', false);
			T.Utils.Helper.initAdditionalModules(this.$ctx);

			T.Utils.View.stopLoader();
		},


		_getAllCheckedInputs: function _getAllCheckedInputs(inputs) {

			const resultList = [];
			const $items = this.$ctx.find(`${inputs}:checked`);
			for (let i = 0; i < $items.length; i++) {
				resultList.push($items[i].value);
			}
			return resultList;
		},

		_initBasicKeywordFilter: function _initBasicKeywordFilter() {

			const basickeywordfilter = this.$resultContainer.find('.m-basic-keywordfilter');

			if (basickeywordfilter.data('tx-name')) {
				basickeywordfilter.attr('data-t-name', basickeywordfilter.data('tx-name'));
				basickeywordfilter.removeAttr('data-tx-name');
			}

			T.Utils.Helper.initAdditionalModules(basickeywordfilter);
		},
		_resetBasicKeywordFilter: function _resetBasicKeywordFilter() {


			const basickeywordfilter = this.$resultContainer.find('.m-basic-keywordfilter');
			T.Utils.Helper.removeModules(basickeywordfilter);

			this._initBasicKeywordFilter();

		},

		_noneIsChecked: function _noneIsChecked(inputs) {

			return !(this.$ctx.find(inputs).is(':checked'));
		},

		_initializeCheckboxClone: function _initializeCheckboxClone($clone, label, addClass) {

			$clone.find('input').attr('id', label);
			$clone.find('input').val(label);
			$clone.find('label').attr('for', label);
			$clone.find('.mm-tile-desc-head').append(label);
			$clone.addClass(addClass);

			return $clone;
		},
		_clearDropdown: function _clearDropdown(selector) {
			const $elm = this.$ctx.find(`${selector} option[value!=""]`);
			$elm.remove();
		},

		_getDropdownVal: function _getDropdownVal(selector) {
			const result = this.$ctx.find(selector).val();
			if ("0" !== `${result}`) {
				return result;
			}
			return null;
		},

		_getSliderValues: function _getSliderValues() {


			const $enginePowerSlider = this.$filtersForm.find('.js-engine-power-slider');
			const $cylinderCapSlider = this.$filtersForm.find('.js-cylinder-cap-slider');
			const $priceRangeSlider = this.$filtersForm.find('.js-price-range-slider');
			const $seatHeightSlider = this.$filtersForm.find('.js-seat-height-slider');


			const values = {
				enginePowerMin: this._getSliderVal('enginepower', 1),
				cylinderCapMin: this._getSliderVal('cylindercap', 1),
				priceRangeMin: this._getSliderVal('pricerange', 1),
				seatHeightMin: this._getSliderVal('seatheight', 1),
			};

			const enginePowerMax = this._getSliderVal('enginepower', 2);
			if (enginePowerMax !== $enginePowerSlider.data('init').max) {
				values.enginePowerMax = enginePowerMax;
			}

			const cylinderCapMax = this._getSliderVal('cylindercap', 2);
			if (cylinderCapMax !== $cylinderCapSlider.data('init').max) {
				values.cylinderCapMax = cylinderCapMax;
			}

			const priceRangeMax = this._getSliderVal('pricerange', 2);
			if (priceRangeMax !== $priceRangeSlider.data('init').max) {
				values.priceRangeMax = priceRangeMax;
			}

			const seatHeightMax = this._getSliderVal('seatheight', 2);
			if (seatHeightMax !== $seatHeightSlider.data('init').max) {
				values.seatHeightMax = seatHeightMax;
			}

			return values;

		},
		_getSliderVal: function _getSliderVal(sliderName, index) {

			return this.$ctx.find(`#${sliderName}-slider-input${index}`).val();
		},
		_getSliderMinMax: function _getSliderMinMax(sliderName) {

			const $slider = this.$filtersForm.find(`.js-${sliderName}-slider`);

			const vals = [];
			const min = $slider.data('init').min;
			const max = $slider.data('init').max;
			vals.push(min);
			vals.push(max);

			return vals;

		},

		_handleErrorRequestResults: function _handleErrorRequestResults() {
			this._showError();
		},

		_showError: function _showError() {
			this.$globalErrorContainer.toggleClass('h-hidden', false);
			this.$componentContainer.toggleClass('h-hidden', true);
			T.Utils.View.stopLoader();
		},
		_determineTileClass: function _determineTileClass(index, length) {

			if (index === 0) {
				return 'is-first';
			}
			if (index === length - 1) {
				return 'is-last';
			}
			else {
				return null;
			}
		},
		_submitSearch: function _submitSearch(searchAll, evt) {
			if (evt) {
				evt.preventDefault();
			}
			if (this.$ctx.find('form').valid()) {

				this.$resultContainer.toggleClass('h-hidden', false);
				this._requestResults(searchAll);


			}
			if (searchAll) {
				this._clearFilters();
			}
			return false;

		}
	});
}(jQuery));