(function ($) {
    'use strict';
    /**
     * Award decorator implementation for the basickeywordfilter component.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class Lexicon
     * @extends T.Module
     */
    T.Module.BasicKeywordfilter.Lexicon = T.createDecorator({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $swiper: null,

        /** @type {jQuery} */
        $letterboxsticky: null,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {jQuery} */
        $swiperSlidesWrapper: null,

        /** @type {jQuery} */
        $swiperBtnLeft: null,

        /** @type {jQuery} */
        $swiperBtnRight: null,

        /** @type {Object[]} */
        $swiperSlides: null,

        /** @type {Number} */
        $swiperSlidesVisible: 0,

        /** @type {jQuery} */
        $resultWrapper: null,

        /** @type {jQuery} */
        $result: null,

        /** @type {Object[]} */
        $categories: null,

        /** @type {Object[]} */
        $categoryOffsets: [],

        /** @type {Number} */
        $activeLetter: 0,

        /** @type {Boolean} */
        $isResizing: 0,

        /** @type {Boolean} */
        isLoaded: false,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve) {
            this.$ctx = $(this._ctx);
            this.$letterboxsticky = this.$ctx.find('.mm-lexicon-letterbox-sticky').eq(0);
            this.$swiperSlidesWrapper = this.$ctx.find('.swiper-wrapper').eq(0);
            this.$swiperContainer = this.$ctx.find('.swiper').eq(0);
            this.$swiperSlides = this.$ctx.find('.swiper-slide');
            this.$categories = this.$ctx.find('.mm-lexicon-category');
            this.$resultWrapper = this.$ctx.find('.mm-lexicon-result-wrap').eq(0);
            this.$result = this.$resultWrapper.find('> .mm-lexicon-result').eq(0);
            this.$swiperBtnLeft = this.$ctx.find('.mm-lexicon-prev');
            this.$swiperBtnRight = this.$ctx.find('.mm-lexicon-next');
            this.ariaLabelAttribute = 'aria-label';
            this.swiperBtnLeftAriaLabelText = this.$swiperBtnLeft.attr(this.ariaLabelAttribute);
            this.swiperBtnRightAriaLabelText = this.$swiperBtnRight.attr(this.ariaLabelAttribute);

            this.result = '.mm-result';
            this.noResult = '.mm-noresult';

            //*** get global event search
            this._events.on('BasicKeywordfilter.searchKeyup', this.highlight.bind(this.searchValue));
            this._events.on('BasicKeywordfilter.filterContentBySearchFinished', () => {
                this.filterAfterSearch();
            });

            //*** get global event search
            this._events.on('BasicKeywordfilter.reset', () => {
                this.reset();
            });

            //*** get global event search
            this._events.on('BasicKeywordfilter.checkKeywordContentLinks', () => {
                this.checkKeywordContentLinks();
            });

            // inti swipe
            this.initSwipe();

            // set category offsets
            this.setCategoryOffsets();

            // init letter event
            this.initLetterEvent();

            const callStickyLogic = () => {
                this.checkRefreshHeader();
            };

            window.addEventListener('scroll', callStickyLogic, { passive: true });

            this.checkKeywordContentLinks();

            this._parent.start(resolve);
        },

        checkRefreshHeader: function () {

            const header = $('.m-basic-header'),
                headerHeight = header.height();

            if (this.actualStickyHeaderOffset !== headerHeight) {
                this.$letterboxsticky.css('max-height', `calc(100vh - ${headerHeight}px`);
                this.$swiperContainer.css('max-height', `calc(100vh - ${headerHeight}px`);
                this.setSticky(headerHeight);

                this.actualStickyHeaderOffset = headerHeight;
            }
        },

        setSticky: function (offsetTop) {
            this._events.emit('stickyDetachEvent.preventHeaderEvent');

            this.$letterboxsticky.trigger('sticky_kit:detach');

            this.$letterboxsticky.stick_in_parent(
                {
                    offset_top: offsetTop,
                    parent: this.$resultWrapper,
                    sticky_class: 'is-sticky'
                }
            ).on('sticky_kit:stick', () => {
                this.$swiper.update();
            });
        },

        /**
         * checkKeywordContentLinks
         *
         */
        checkKeywordContentLinks: function () {
            $('.js-keywordfilter-content').off().on('click', (e) => {
                e.preventDefault();

                // global reset
                this._events.emit('BasicKeywordfilter.reset');

                this.$searchfield.val($(e.target).html());

                jQuery('html, body').animate({
                    scrollTop: this.$ctx.offset().top - 30
                }, 250);

                let anchor = null;

                this.$ctx.find('h3').each((index, item) => {

                    if ($(item).html() === $(e.target).html()) {
                        anchor = $(item);
                    }
                });

                if (anchor) {
                    const scrollPos = -(anchor.offset().top - this.$ctx.find('.mm-lexicon-result').offset().top);

                    this.$result.animate({
                        marginTop: scrollPos
                    }, 1000);
                }

                const activeCategoryIndex = anchor.closest('.mm-lexicon-category').index();

                // set active letter
                this.$swiperSlides.removeClass('is-active').eq(activeCategoryIndex).addClass('is-active');
                this.$activeLetter = activeCategoryIndex;
            });
        },

        /**
         * init letterEvent
         *
         */
        initLetterEvent: function () {
            this.$swiperSlides.each((index, slide) => {
                const $slide = $(slide);

                $slide.on('keyup', (event) => {
                    if (event.key === 'Enter') {
                        $(event.currentTarget).trigger('click');
                    }
                });

                $slide.on('click', () => {
                    // reset old active
                    $slide.siblings('.is-active').removeClass('is-active');

                    // set new active
                    $slide.addClass('is-active');
                    $slide.find('.mm-lexicon-letter').focus();

                    this.$activeLetter = index;

                    // scroll result
                    this.$result.animate(
                        {
                            marginTop: `-${this.$categoryOffsets[index]}px`,
                        },
                        {
                            duration: 1000,
                            complete: () => {
                                // update height :: css animation
                                if (!S.Utils.Helper.mq('tablet').matches) {
                                    // smartphone
                                    this.$letterboxsticky.css('height', 'auto');
                                }
                                else {
                                    // tablet + desktop
                                    this.$letterboxsticky.css('height', `${this.$resultWrapper.outerHeight()}px`);
                                }
                            }
                        })
                        .on('transitionend', () => {
                            this.$swiper.update();

                            S.Utils.delayed(`basickeywordfilter-animation-delay-${this.$ctx.data('t-id')}`, 500, () => {
                                this.$swiper.slideTo(this.$swiper.activeIndex, 500);
                            });
                        });

                    // scroll window
                    $('html, body').animate({
                        scrollTop: this.$ctx.offset().top
                    },
                        {
                            duration: 1000,
                            complete: () => {
                                this._events.emit('basicKeywordfilter.letter', this.$ctx);
                            }
                        });
                });
            });
        },

        /**
         * setCategoryOffsets [A][B][C] etc.
         *
         */
        setCategoryOffsets: function () {
            this.$categories.each((index, category) => {
                const $category = $(category);

                if ($category.is(':visible')) {
                    this.$categoryOffsets[index] = $category.position().top;
                }
            });
        },

        /**
         * reset
         *
         */
        reset: function () {
            // reset result pos
            this.$result.css('margin-top', '0px');

            // reset hidden categories + articles
            this.$result.find(':hidden').show().removeClass('is-hidden');

            // reset hidden letters
            this.$swiperSlidesWrapper.find(':hidden').show();

            // reset active letter
            this.$swiperSlides.removeClass('is-active').eq(0).addClass('is-active');
            this.$activeLetter = 0;

            // hide search info
            this.$ctx.find(this.result).hide();
            this.$ctx.find(this.noResult).hide();

            // update category offsets
            this.setCategoryOffsets();

            const heightValue = S.Utils.Helper.mq('tablet').matches ? `${this.$resultWrapper.outerHeight()}px` : 'auto';

            // update height :: css animation
            this.$letterboxsticky.css('height', heightValue);

            // slide to first letter
            this.$swiper.slideTo(0);

            // update swiper
            this.$swiper.update();
        },

        /**
         * filter category items
         *
         * @param {string} searchValue
         */
        highlight: function (searchValue) // eslint-disable-line
        {
            // reset result pos
            $('.m-basic-keywordfilter .mm-lexicon-result').css('margin-top', '0px');

            // reset hidden categories + articles
            $('.m-basic-keywordfilter .mm-lexicon-result :hidden').show().removeClass('is-hidden');

            // reset hidden letters
            $('.m-basic-keywordfilter').find('.swiper-wrapper :hidden').show();
        },

        /**
         * filter categories + articles
         *
         */
        filterAfterSearch: function () {
            let countHighlightCategory = 0;

            if (this.$ctx.find('.mm-searchbar input').eq(0).val().length > 2) {
                // filter categories + articles
                this.$categories.each((index, item) => {
                    const $item = $(item);

                    let countHighlightArticle = 0;
                    $item.children('.m-basic-newsbox').each((index, newsBox) => {
                        if (!$(newsBox).find('.h-search').length) {
                            $(newsBox).hide().addClass('is-hidden');
                        }
                        else {
                            countHighlightArticle++;
                        }
                    });

                    if (countHighlightArticle === 0) {
                        // hide category
                        $item.hide();

                        // hide letter in swiper
                        this.$swiperSlidesWrapper.children().eq(index).hide();
                    }
                    else {
                        countHighlightCategory++;

                        // set active letter
                        if (countHighlightCategory === 1) {
                            this.$swiperSlidesWrapper.find('.is-active').removeClass('is-active');
                            this.$swiperSlidesWrapper.children().eq(index).addClass('is-active');
                            this.$activeLetter = index;
                        }
                    }
                });

                // set search result info text
                if (countHighlightCategory === 0) {
                    this.$ctx.find(this.noResult).show();
                    this.$ctx.find(this.result).hide();
                }
                else {
                    this.$ctx.find('.js-num').html(this.$ctx.find('.h-search').length);
                    this.$ctx.find(this.noResult).hide();
                    this.$ctx.find(this.result).show();
                }
            }

            // update swiper
            this.$swiper.update();

            // update category offsets
            this.setCategoryOffsets();
        },

        /**
         * init swipe
         */
        initSwipe: function () {
            // init swiper conatiner
            this.$swiper = new Swiper(this.$swiperContainer[0], {
                spaceBetween: 0,
                slidesPerView: 'auto',
                direction: 'vertical',
                slidesPerGroupAuto: true,
                watchSlidesProgress: true,
                preventInteractionOnTransition: true,
                observer: true,
                preloadImages: false,
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    prevEl: this.$swiperBtnLeft[0],
                    nextEl: this.$swiperBtnRight[0],
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        speed: 300,
                    },
                    1024: {
                        speed: 700,
                    }
                },
                on: {
                    update: (swiper) => // eslint-disable-line
                    {
                        // update category offsets
                        this.setCategoryOffsets();

                        // update height :: css animation
                        if (!S.Utils.Helper.mq('tablet').matches) {
                            // smartphone
                            this.$letterboxsticky.css('height', 'auto');
                        }
                        else {
                            // tablet + desktop
                            this.$letterboxsticky.css('height', `${this.$resultWrapper.outerHeight()}px`);
                        }
                    },
                }
            });

            // bugfix: set aria-labels here again, because swiper sometimes overwrites it
            this.$swiperBtnLeft.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
            this.$swiperBtnRight.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);
        },
    });
}(jQuery));
