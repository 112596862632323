(function ($) {
	'use strict';
	/**
	 * Chatbot decorator for the basicBtn module
	 *
	 * @author  <b.kowatsch@mgo360.de>
	 * @namespace T.Module
	 * @class BasicBtn
	 * @extends T.Module
	 */
	T.Module.BasicBtn.Chatbot = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$iframeChatbot: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			// click events
			this.clickevents();
			this.$iframeChatbot = $('.eb7-snippet--iframe');

			this._parent.start(resolve);
		},

		clickevents: function () {
			this.$ctx.on('click', () => {
				this.$iframeChatbot.attr('src', this.$iframeChatbot.attr('data-chatbot-url'));
				this.$ctx.closest('.m-basic-text').addClass('h-hidden');
				this.$iframeChatbot.closest('.ll-main-shadowbox').removeClass('h-hidden');
			});
		}

	});
}(jQuery));
