
(function ($) {
	class DsfilterComponent extends BasisComponent {

		clickSelectors = 'input[type="radio"], input[type="checkbox"], input[data-location], select';

		constructor() {
			super();
			this.$ctx = null;
			this.$addFilterToggle = null;
			this.$addFilterButton = null;
			this.$deleteFilterButton = null;
			this.$correspondingResult = null;
			this.from = null;
			this.to = null;
			this.daterange = null;
			this.daterangeSettings = null;
		}

		_connectedCallback() {
			this.init();
		}

		init() {
			this._isConnected = true;
			this.$ctx = $(this);
			this.$addFilterToggle = this.$ctx.find('.js-additional-toggle');
			this.$addFilterButton = this.$ctx.find('.js-filter-btn');
			this.$deleteFilterButton = this.$ctx.find('.js-delete-filter');
			this.$correspondingResult = $(`[data-target="${this.$ctx.data('source')}"]`);
			this.daterange = this.$ctx.find('daterange-component');
			this.daterangeSettings = this.daterange.data('init');
			this.from = this.daterange.find('[data-timerange-start]');
			this.to = this.daterange.find('[data-timerange-end]');
			this.manageAdditionalFilter();
			this.filterAction();
			this.deleteAllFilter();
			this.countActiveFilter();
		}

		removeEvents() {
			if (this._isConnected) {
				this.$addFilterToggle.off('click');
				this.$addFilterToggle.off('click');
				this.$deleteFilterButton.off('click');
				this.$ctx.find(this.clickSelectors).off('click');
			}
		}

		_disconnectedCallback() {
			this.removeEvents();
		}

		//custom functions start here!
		manageAdditionalFilter() {
			this.$addFilterToggle.on('click', (head) => {
				$(head.currentTarget).toggleClass('is-open');
				$(head.currentTarget).next().slideToggle();
			});
		}

		filterAction(close = false) {
			this.$ctx.find(this.clickSelectors).on('change', () => {
				this.countActiveFilter(false);
			});

			this.$addFilterButton.on('click', () => {
				this.countActiveFilter();
				if (close) {
					this.$ctx.find('.js-close').trigger('click');
				}
			});
		}

		deleteAllFilter(close = false) {
			this.$deleteFilterButton.on('click', () => {
				const selectedOptions = this.$ctx.find('option:selected:not([data-default])');
				const location = this.$ctx.find('[data-location]');

				selectedOptions.prop('selected', false);
				selectedOptions.closest('select').find('option[data-default]').prop('selected', true);

				if (location.data('default')) {
					location.val(location.data('default'));
				}
				else {
					location.val('');
				}
				this.$ctx.find('input:checked').prop('checked', false);

				this.from.datepicker("setDate", this.daterangeSettings.defaultFrom);
				this.to.datepicker("setDate", this.daterangeSettings.defaultEnd);
				this.daterange.removeClass('is-tainted');

				this.countActiveFilter();
				if (close) {
					this.$ctx.find('.js-close').trigger('click');
				}
			});
		}

		countActiveFilter(updateFilterCount = true) {
			const checkedInputs = this.$ctx.find('input:checked');
			const location = this.$ctx.find('[data-location]');
			let count = 3;
			let countDefault = 2;

			if (!this.daterange.hasClass('is-tainted')) {
				countDefault += 1;
			}

			if (location.length > 0 && location.val().length > 1) {
				count += 1;

				if (parseInt(location.val()) === location.data('default')) {
					countDefault += 1;
				}
			}

			count = count + checkedInputs.length;

			if (count > countDefault) {
				this.$deleteFilterButton.show();
			}
			else {
				this.$deleteFilterButton.hide();
			}

			if (updateFilterCount) {
				this.$correspondingResult.find('.js-filter-count').text(count);
			}
		}
	}

	customElements.define('dsfilter-component', DsfilterComponent);

}(jQuery));
