(function ($) {
	'use strict';
	/**
	 * BasicMovie module implementation.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicMovie
	 * @extends T.Module
	 */
	T.Module.BasicMovie.Youtube = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$posterWrapper: null,

		/** @type {jQuery} */
		$poster: null,

		/** @type {jQuery} */
		$button: null,

		/** @type {jQuery} */
		$iframe: null,

		/** @type {Boolean} */
		isLazy: true,

		/** @type {Boolean} */
		isLoaded: false,

		config: {
			// see also css transition time picture and button
			fadeoutspeed: 700,
		},

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$ctxID = this.$ctx.data('t-id');
			this.isInSwiper = this.$ctx.closest('.swiper').length;
			this.$posterWrapper = this.$ctx.find('.js-picture');
			this.$poster = this.$posterWrapper.find('img');
			this.$button = this.$ctx.find('.js-button');
			this.$iframe = this.$ctx.find('iframe');
			this.defaultIframeSrc = this.$iframe.attr('src');

			// let the swiper handle the lazyloading
			if (this.isInSwiper) {
				this.$poster.removeClass(S.Lazy.lazyloadClass.lazysizes);
			}

			this._parent.start(resolve);
		},

		/**
		 * default cb function from observer when lazy loading
		 * is triggered or none lazy loading is needed/given
		 *
		 * @param target :: which initialized the lazy call
		 * @param {{instance, $target, tId, $nearestModule}} dataObj
		 * @private
		 */
		_lazyUpdate: function (target, dataObj)// eslint-disable-line
		{
			// let the swiper handle the lazyloading
			if (!this.isInSwiper) {
				// start video
				this.startVideo(this.$ctx.data('src'));

				this.isLoaded = true;
			}
		},

		/**
		 * set the correct src for iframe to show youtube video
		 * - on first init/click, the indicator is "was-started"
		 * @param {Number} videoId
		 */
		setVideoSrc(videoId) {
			const defaultSrc = `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`;

			this.$iframe.addClass('was-started').removeClass('is-paused').attr('src', defaultSrc);
		},

		/**
		 * reset the iframe youtube-src to stop the video from playing
		 * - to indicate which movie should be reset when the user has clicked after the iframe src was set (you cant track an click inside an iframe)
		 *   This should fix the problem to reset all iframe that have the class "was-started" on every swiper slide change
		 * @param {Number} videoId
		 * @param {String} src
		 */
		resetVideoSrc(videoId, src) {
			const defaultSrc = `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`,
				videoSrc = src || defaultSrc;

			// set default src to prevent YT from playing
			this.$iframe.attr('src', this.defaultIframeSrc || '#');

			this.$iframe.addClass('is-paused').attr('data-src', videoSrc);

			// show preview poster
			this.$posterWrapper.removeClass('is-fade-out is-z-index');
			this.$button.removeClass('is-fade-out is-z-index');
		},

		startVideo(videoId) {
			// ew72 player
			if (!this.$ctx.data('src')) {
				return;
			}

			this.$ctx.find('.js-button').on('click', () => {
				this.setVideoSrc(videoId);

				setTimeout(() => {
					this.$posterWrapper.addClass('is-fade-out');
					this.$button.addClass('is-fade-out');
				}, 75);

				setTimeout(() => {
					this.$posterWrapper.addClass('is-z-index');
					this.$button.addClass('is-z-index');
				}, this.config.fadeoutspeed);
			});

			this.$posterWrapper.on('click', () => {
				this.$button.click();
			});

			this.$posterWrapper.on('mouseover', () => {
				this.$button.addClass('is-hover');
			});

			this.$posterWrapper.on('mouseout', () => {
				this.$button.removeClass('is-hover');
			});
		},

	});
}(jQuery));
