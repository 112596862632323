/// <reference path="../../../../assets/typings/terrific-ext.d.ts" />

'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) {
	const DATA_AUTHLINKSET = 'authlinkset';
	const SEL_LOGINLINKS = 'a.js-authlink-login';
	const SEL_SIGNONLINKS = 'a.js-authlink-signon';
	const SEL_EMAILCHANGELINKS = 'a.js-authlink-emailchange';
	const SEL_PWDCHANGELINKS = 'a.js-authlink-pwdchange';
	const SEL_LOGOUTLINKS = 'a.js-authlink-logout';

	/**
	 * PageContext module implementation.
	 *
	 * @author  <dmitri.zoubkov@mtc.berlin>
	 * @memberof T.Module
	 * @class PageContext
	 * @extends T.Module
	 */
	T.Module.PageContext = T.createModule({
		_campaignsCookie: "campaigns",
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.parseAuthLinks();
			this._initEvents();
			this.appendCampaignUrlParam();
			T.Utils.Helper.updateUrlsWithDeviceParam();
			this.setCampaignToCookie();
			resolve();
		},

		_initEvents: function _initEvents() {
			$(document).on('PageContext.parseAuthLinks', this.parseAuthLinks.bind(this));
			$(document).on("identityloaded", this.onIdentityChanged.bind(this));
			$(document).on("identitylogout", this.onIdentityChanged.bind(this));
		},

		parseAuthLinks: async function parseAuthLinks(evt, ctx) {
			if (T.Utils.Auth.pendingUnload) {
				return;
			}
			const $ctx = ctx ? $(ctx) : this.$ctx;
			const $loginlinks = $ctx.find(SEL_LOGINLINKS);
			const $signonlinks = $ctx.find(SEL_SIGNONLINKS);
			const $emailchangelinks = $ctx.find(SEL_EMAILCHANGELINKS);
			const $pwdchangelinks = $ctx.find(SEL_PWDCHANGELINKS);
			const $logoutlinks = $ctx.find(SEL_LOGOUTLINKS);
			const isLoggedIn = await T.Utils.Auth.isLoggedIn();

			if (isLoggedIn) {
				$loginlinks.toggleClass('h-hidden', true);
				$signonlinks.toggleClass('h-hidden', true);

				$emailchangelinks.attr('href', await T.Utils.Auth.makeChangeEmailURL()).toggleClass('h-hidden', false);
				$pwdchangelinks.attr('href', await T.Utils.Auth.makeChangePwdURL()).toggleClass('h-hidden', false);

				$logoutlinks.each(async (index, elem) => {
					const $elem = $(elem);
					if (!$elem.data(DATA_AUTHLINKSET)) {
						let redirectUri = $elem.attr('href');
						if (redirectUri && 0 !== redirectUri.indexOf("#")) {
							redirectUri = new URL(redirectUri, window.location.href).toString();
						} else {
							redirectUri = null;
						}
						const url = await T.Utils.Auth.makeLogoutURL(redirectUri ? { redirect_uri: redirectUri } : undefined);
						$elem.attr('href', url)
							.on('click', async (evt) => {
								evt.preventDefault();
								await T.Utils.Auth.logoutFinally(redirectUri);
							})
							.toggleClass('h-hidden', false);
						T.Utils.View.setDataSafe($elem, DATA_AUTHLINKSET, 1);
					}
				});
			} else {
				$logoutlinks.toggleClass('h-hidden', true);
				$emailchangelinks.toggleClass('h-hidden', true);
				$pwdchangelinks.toggleClass('h-hidden', true);

				$loginlinks.each((index, elem) => {
					const $elem = $(elem);
					if (!$elem.data(DATA_AUTHLINKSET)) {
						const origHref = $elem.attr('href');
						$elem.on('click', (ev) => {
							ev.preventDefault();
							const authOptions = {};

							if (origHref && 0 !== origHref.indexOf("#")) {
								authOptions.processUrl = origHref;
							}
							T.Utils.Auth.authorize(authOptions);
						}).toggleClass("h-hidden", false);
						T.Utils.View.setDataSafe($elem, DATA_AUTHLINKSET, 1);
					}
				});

				$signonlinks.attr('href', await T.Utils.Auth.makeRegisterURL()).toggleClass('h-hidden', false);
			}
		},

		onIdentityChanged: function onIdentityChanged() {
			this.parseAuthLinks();
		},

		appendCampaignUrlParam: function appendCampaignUrlParam() {
			const campaignIds = T.Utils.Helper.getCampaignUrlParams();
			const telljaIds = T.Utils.Helper.getTelljaIdUrlParams();
			if (campaignIds.length || telljaIds.length) {
				$("a[href!=''][href]").on("click", (e) => {
					let targetLink;
					if (e.target && e.target.tagName === "A") {
						targetLink = e.target;
					} else if (e.target && e.target.closest("a")) {
						targetLink = e.target.closest("a");
					}

					if (targetLink) {
						const hrefLink = new URL(targetLink.getAttribute("href"), window.location.href).toString();

						if (hrefLink &&
							!T.Utils.Helper.isMediaUrl(hrefLink) &&
							!T.Utils.Helper.isExternalUrl(hrefLink)) {
							const newUrl = T.Utils.Helper.getUrlWithIdParam(hrefLink, campaignIds, telljaIds);
							if (newUrl !== hrefLink) {
								targetLink.setAttribute("href", newUrl);
							}
						}
					}
				});
			}
		},

		setCampaignToCookie() {
			const campaignIds = T.Utils.Helper.getCampaignUrlParams();
			T.Utils.Helper.isConsentMatch("Campaigns", (status) => {
				if (campaignIds.length && status) {
					const storedCampaigns = T.Utils.Store.get(this._campaignsCookie, T.Utils.Store.COOKIE);

					let result = storedCampaigns;
					campaignIds.forEach((campaignId) => {
						const currentlyCampaign = `${campaignId},${(new Date().toLocaleDateString())}`;
						if (storedCampaigns && !storedCampaigns.includes(campaignId)) {
							result = `${currentlyCampaign};${result}`;
						} else if (!storedCampaigns) {
							result = currentlyCampaign;
						}
					});

					const telljaID = T.Utils.Helper.getUrlParameter("telljaid");
					if (telljaID) {
						result += `,telljaid=${telljaID}`;
					}

					T.Utils.Store.set(this._campaignsCookie, result, T.Utils.Store.COOKIE);
				}
			});
		}
	});
})(jQuery);