(function($) {
	'use strict';
	/**
	 * AdacCardamage module implementation.
	 *
	 * @author Lars Meyer <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class AdacCardamage
	 * @extends T.Module
	 */
	T.Module.AdacCardamage = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $tableElement: null,

        /** @type {jQuery} */
        $tablecontainer: null,

        /** @type {Object} */
        $scrollPaneApi: null,

        /** @type {Object} */
        $oldMsgTrigger: null,


		start: function(resolve) {

            this.$ctx = $(this._ctx);

            this.$tablecontainer = this.$ctx.find('.mm-item > .mm-infotable');
            this.$tableElement = this.$ctx.find('.mm-item > .mm-infotable > table');
            this.$oldMsgTrigger = this.$ctx.find('.mm-linklist > a');

            if ( this.$tablecontainer.length ) {

                if ( S.Globals.MediaQuery.mqRightNow === 'desktop') {
                    this.setVertBar('init');
                }

                this.resize();
            }

			resolve();
		},

        /**
         * init custom scrollbar for large table and show table
         *
         * scrollbar is displayed as vertical scrollbar for the whole table -> scroll in y-direction
         *
         * @param mode
         *
         */
        setVertBar: function (mode) {

            const that = this,
                heightBuffer = 19;

            if (mode === 'init') {

                that.$tablecontainer.each( function (index, item) {

                    const containerHeight = $(item).outerHeight(),
                        tableHeight = that.$tableElement.outerHeight();

                    // check if item is bigger than container ( + buffer )
                    if (tableHeight >= ( containerHeight + heightBuffer ) ) {
                        $(item).jScrollPane({showArrows: false});
                    }
                });
            }

            else if (mode === 'destroy') {

                $('.mm-infotable').each(function (index, item) {
                    if ( $(item).hasClass('jspScrollable') || $(item).find('.jspPane').length ) {
                        $(item).data('jsp').destroy();
                    }
                });
            }

            else if (mode === 'refresh') {

                // check if custom scrollbar exists for each td
                $('.mm-infotable').each(function (index, item) {

                    let containerHeight = $(item).outerHeight();
                    let tableHeight = $(item).find('table').outerHeight();

                    // no custom scrollbar found: build it if necessary:
                    if ( !$(item).hasClass('jspScrollable') && !$(item).find('.jspPane').length ) {

                        // check if item is bigger than container ( + buffer )
                        if (tableHeight >= ( containerHeight + heightBuffer ) ) {
                            $(item).jScrollPane({showArrows: false});
                        }
                    }

                    // custom scrollbar already exists
                    else {

                        // reInit (to get current size, offset etc.)
                        $(item).data('jsp').reinitialise();

                        containerHeight = $(item).outerHeight();
                        tableHeight = $(item).find('table').outerHeight();

                        // check if item is bigger than container ( + buffer )
                        if ( tableHeight < ( containerHeight + heightBuffer ) ) {
                            $(item).data('jsp').destroy();
                        }
                    }
                });
            }
        },

        /**
         * resize
         */
        resize: function() {

            const that = this;

            // build new scrollbar if desktop is reached
            S.Utils.Helper.mq( 'desktop' ).addListener( function () {
                S.Utils.delayed('tablecalcDesktop', 50, function () {
                    if ( S.Globals.MediaQuery.mqRightNow === 'desktop') {
                        that.setVertBar('refresh');
                    }
                    else {
                        that.setVertBar('destroy');
                    }
                });
            });
        }

	});
}(jQuery));
