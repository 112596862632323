(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.Pager = {


		/**
		 * init product [Freunde werben Detailseite]
		 *
		 */
		initProduct: function () {

			if (sessionStorage.getItem('productlist')) {
				// save detail page url
				sessionStorage.setItem('productDetailPage', window.location);
				const items = JSON.parse(sessionStorage.getItem('productlist'));

				let next = '',
					prev = '';

				if (items.length > 1) {
					// get prev and next url
					for (let i = 0; i < items.length; i++) {
						if (items[i]['url'] === sessionStorage.getItem('productActive')) {
							// set next
							if (items[i + 1]) {
								next = items[i + 1]['url'];
							}
							else {
								next = items[0]['url'];
							}

							// set prev
							if (items[i - 1]) {
								prev = items[i - 1]['url'];
							}
							else {
								prev = items[items.length - 1]['url'];
							}

							sessionStorage.setItem('productActiveTitle', items[i]['title']);
							sessionStorage.setItem('productActiveImage', items[i]['image']);
							sessionStorage.setItem('productActiveOrderNumber', items[i]['ordernumber']);
							sessionStorage.setItem('productActiveModel', items[i]['model']);

							if (typeof items[i]['copayment'] !== 'undefined') {
								sessionStorage.setItem('productActiveCopayment', items[i]['copayment']);
							}

							else {
								sessionStorage.removeItem('productActiveCopayment');
							}
						}
					}

					// set productOverviewUrl to back link
					$('.a-basic-link--back').attr('href', sessionStorage.getItem('productOverviewUrl'));

					// set urls to links and init click
					$('.js-pager-product--prev').attr('href', prev).on('click', function () {
						sessionStorage.setItem('productActive', prev);
					});

					$('.js-pager-product--next').attr('href', next).on('click', function () {
						sessionStorage.setItem('productActive', next);
					});
				}
				else {
					$('.js-pager-product--prev, .js-pager-product--next').hide();
				}
			}
		},

		/**
		 * init ordernumber radio switch
		 *
		 */
		initOrderNumber: function () {

			const $dataModel = $(document.querySelectorAll('[data-model]'));

			// add click event
			$dataModel.on('click', (e) => {
				sessionStorage.setItem('productActiveModel', $(e.currentTarget).data('model'));
				sessionStorage.setItem('productActiveOrderNumber', $(e.currentTarget).data('ordernumber'));

				if (typeof $(e.currentTarget).data('copayment') !== 'undefined') {
					sessionStorage.setItem('productActiveCopayment', $(e.currentTarget).data('copayment'));
				}

				else {
					sessionStorage.removeItem('productActiveCopayment');
				}
			});

			this.refreshDataValues($dataModel);
		},

		/**
		 * refresh session storage values
		 *
		 * @param $dataModel {jQuery} - input element(s) with product data attr.
		 */
		refreshDataValues: function ($dataModel) {

			const $checkedDataModel = $dataModel.filter('input:not([type=hidden]):checked');

			// init for rbtn
			if ($checkedDataModel.length) {

				sessionStorage.setItem('productActiveModel', $checkedDataModel.data('model'));
				sessionStorage.setItem('productActiveOrderNumber', $checkedDataModel.data('ordernumber'));

				if (typeof $checkedDataModel.data('copayment') !== 'undefined') {
					sessionStorage.setItem('productActiveCopayment', $checkedDataModel.data('copayment'));
				}

				else {
					sessionStorage.removeItem('productActiveCopayment');
				}
			}

			else if ($($dataModel).length === 1) {
				sessionStorage.setItem('productActiveModel', $dataModel.data('model'));
				sessionStorage.setItem('productActiveOrderNumber', $dataModel.data('ordernumber'));

				if (typeof $dataModel.data('copayment') !== 'undefined') {
					sessionStorage.setItem('productActiveCopayment', $dataModel.data('copayment'));
				}

				else {
					sessionStorage.removeItem('productActiveCopayment');
				}
			}
		}
	};

	$(document).ready(function () {
		if ($('.js-pager-product').length) {
			S.Utils.Pager.initProduct();
			S.Utils.Pager.initOrderNumber();
		}
	});

})(jQuery);
