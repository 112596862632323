'use strict';
(function ($) {
	/**
	 * Select decorator for the BasicBoxRouting module
	 *
	 * @author  <dmitri.zoubkov@namics.cpom>
	 * @namespace T.Module
	 * @class BasicBoxRouting
	 * @extends T.Module
	 */
	T.Module.BasicBoxRouting = {};
	T.Module.BasicBoxRouting.Select = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		routeContainer: '.a-basic-input-select',
		routeElement: 'option',
		routeState: ':selected',

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this._parent.start(resolve);
		}
	});
}(jQuery));
