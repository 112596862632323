(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.SwiperHelper = {

		/**
		 * lock swipe to prev and next
		 * @param {Object} swiper
		 */
		lockSwiper(swiper) {
			swiper.allowSlideNext = swiper.allowSlidePrev = false;
			swiper.params.allowSlideNext = swiper.params.allowSlidePrev = false;

			swiper.update();
		},

		/**
		 * unlock swipe to prev and next
		 * @param {Object} swiper
		 */
		unlockSwiper(swiper) {
			swiper.allowSlideNext = swiper.allowSlidePrev = true;
			swiper.params.allowSlideNext = swiper.params.allowSlidePrev = true;

			swiper.update();
		},

		/**
		 * Return visible slides
		 *
		 * Filter function - different filter functions is used internally for different input (params changes):
		 * ################
		 *
		 * Input jQuery
		 * :: function filter(index, element)
		 *
		 * Input HTMLCollection|HTMLElement
		 * :: function filter(element, index)
		 *
		 * @param {Array|Object} slides
		 * @return {*[]}
		 */
		getVisbSlides(slides) {
			const isjQuery = slides instanceof jQuery;

			return slides.filter((a, b) => {
				const slide = isjQuery ? b : a;

				return slide?.classList.contains('swiper-slide-visible');
			});
		},

		/**
		 *
		 * @param swiper
		 * @return {T[]}
		 */
		filterVisbSlidesFromIndexes(swiper) {
			return swiper.slides.filter((slide, index) => {

				// {Array[Number]} swiper.visibleSlidesIndexes
				return swiper.visibleSlidesIndexes.includes(index);
			});
		},

		/**
		 * add "swiper-lazy" class to .js-img (images) with data-src
		 *
		 * so we don´t have to change every template, or add unnecessary classes for modules/elements
		 * that are not used in a swiper.
		 *
		 * @param {jQuery|Object|HTMLCollection} elements
		 * @param {Boolean} removeIsInitClass // remove unnecessary is-init classes on elements/slides that don´t need a lazy loading
		 */
		addLazyClassToImg(elements, removeIsInitClass = false) {
			const isjQuery = elements instanceof jQuery,
				collection = isjQuery ? elements.get() : elements;

			for (const element of collection) {
				const $element = $(element),
					$img = $element.find('img'),
					// jsImg = $element.find('.js-img'),
					// jsImgBig = $element.find('.js-img-big'),
					movie = $element.find('.m-basic-movie img'),
					// img = [...jsImg, ...jsImgBig],
					imgArr = [...movie, ...$img];

				for (const img of $img) {
					this.checkForDataSrcAndAddSwiperLazyClass(img);
				}

				// remove unnecessary is-init classes on elements that don´t need a lazy loading (non image)
				if (!imgArr.length || removeIsInitClass) {
					element.classList.remove('is-init');
				}
			}
		},

		/**
		 * append swiper preloader to specific html element
		 * @param el
		 * @return {*} jQuery or HTMLElement (depends on input)
		 */
		appendSwiperPreloader(el) {
			const isJquery = el instanceof jQuery,
				$context = isJquery ? el : $(el);

			$context.append('<div class="swiper-lazy-preloader"></div>');

			const $preloader = $context.find('.swiper-lazy-preloader');

			return isJquery ? $preloader : $preloader.get(0);
		},

		/**
		 * switches the swiper option to wait for the transition end
		 * - after the transition the user can interact with the swiper again (prev/next etc.)
		 * @param swiper
		 * @param enable
		 */
		swiperPreventInteractionOnTransitionSwitch(swiper, enable = false) {
			swiper.params.preventInteractionOnTransition = swiper.preventInteractionOnTransition = enable;
		},

		/**
		 * @param {Element} img
		 */
		checkForDataSrcAndAddSwiperLazyClass(img) {
			const dataSrc = img.getAttribute('data-src');

			if (dataSrc !== null) {
				img.classList.add('swiper-lazy');
				img.classList.remove(S.Lazy.lazyloadClass.lazysizes);
			}
		},

		/**
		 * set/remove center class for children that listens to it
		 * @param swiper
		 * @param $ctx
		 * @param noClass
		 */
		checkIfSlidesFitAndLockSwiper(swiper, $ctx, noClass) {
			if (this.slidesFitInContainer(swiper)) {
				swiper.enable();
				this.moveFocusedLinkIntoView(swiper, $ctx);

				if ($ctx?.hasClass('is-center')) {
					// remove center class for .oo-headline
					$ctx.removeClass('is-center');
				}
			}
			else {
				swiper.disable();

				if (!noClass) {
					// set center class for .oo-headline
					$ctx?.addClass('is-center');
				}
			}
		},

		moveFocusedLinkIntoView(swiper, $ctx) {
			const links = $ctx.find('.swiper-slide a, .swiper-slide button');
			links.on('focus', (link) => {
				const focusedSlide = $(link.currentTarget).closest('.swiper-slide');
				if($('body').hasClass('is-keyboard-focus') && !focusedSlide.hasClass('swiper-slide-fully-visible')) {
					swiper.slideTo(focusedSlide.index());
				}
			});
		},

		/**
		 * can also be called from everywhere
		 * - to check if all slides fit in the container so no swiping is necessary
		 * @param {Object} swiper
		 * @return {boolean}
		 */
		slidesFitInContainer(swiper) {
			let slideWidth = 0;

			for (const slideInd in swiper.slides) {
				const spaceBetween = (swiper.slides.length - 1) === slideInd ? 0 : swiper.params.spaceBetween;

				slideWidth += swiper.slidesSizesGrid[slideInd] + spaceBetween;
			}

			return swiper.size < slideWidth;
		},

		/**
		 * for printing :: unveils the images in visible slides via swiper.lazy.loadInSlide()
		 */
		reveilVisibleSwiperImagesForPrint() {
			$('.swiper').each((_, swiperEl) => {
				const swiper = swiperEl?.swiper;

				if (swiper) {
					this.forceLazyLoadingOnVisbSlides(swiper);
				}
			});
		},

		/**
		 * forces the swiper to trigger intern lazy loading (swiper-lazy class)
		 * @param {Object} swiper
		 */
		forceLazyLoadingOnVisbSlides(swiper) {
			const slides = swiper.slides;

			slides.each((slide, index) => {
				if (slide.classList.contains('swiper-slide-visible')) {
					swiper.lazy.loadInSlide(index);
				}
			});
		},
	};
})(jQuery);
