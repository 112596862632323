(function ($) {
	'use strict';

	/**
	 * break decorator implementation for the BasicTable module.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module.BasicTable
	 * @class Break
	 * @extends T.Module
	 */
	T.Module.BasicTable.Weather = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$thead: null,

		/** @type {Object} */
		$th: [],

		/** @type {jQuery} */
		$tbody: null,


		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$thead = this.$ctx.find('thead').eq(0);
			this.$th = this.$ctx.find('> thead > tr > th');
			this.$tbody = this.$ctx.find('tbody').eq(0);

			const that = this;


			if (!S.Utils.Helper.mq('tablet').matches) {
				// rebuild table
				this.rebuildTable('phone');
			}
			S.Utils.Helper.mq('tablet').addListener(function (mq) {
				if (!mq.matches) {
					// rebuild table
					that.rebuildTable('phone');
				} else {
					// rebuild table
					that.rebuildTable('tablet');
				}
			});


			this._parent.start(resolve);
		},


		/**
		 * rebuildTable
		 *
		 * @param {String} mod
		 */
		rebuildTable: function (mod) {
			const that = this;

			// reset html
			this.$ctx.hide();

			if (mod === 'phone') {
				const table = $('<table class="m-basic-table m-basic-table--ruf-weather js-weather-clone" />');
				setTimeout(function () {
					table.insertAfter(that.$ctx);
				}, 500);

				that.$tbody.find('> tr').eq(3).children().each(function (indexTd, itemTd) { // eslint-disable-line
					const row = $('<tr />');
					row.appendTo(table);

					that.$tbody.find('> tr').each(function (indexTr, itemTr) {

						if (indexTd === 0 && indexTr === 1) {
							$('<td />').appendTo(row); // case rowspan problem
						}
						else {
							let index = indexTd;

							if (indexTr === 1 && indexTd > 0) {
								index = indexTd - 1;

							}

							const cell = $(itemTr).children().eq(index).clone(true).attr('rowspan', '');

							cell.find('[data-smartphone-text]').text(cell.find('[data-smartphone-text]').data('smartphone-text'));

							row.append(cell);
						}
					});
				});

				// add classes to cloned table
				// get space class
				// eslint-disable-next-line
				let separatorClass = this.$ctx.attr('class').match(/h-space-[s,m,l]+/); //NOSONAR

				if (separatorClass) {
					// eslint-disable-next-line
					separatorClass = this.$ctx.attr('class').match(/h-space-[s,m,l]+/)[0]; //NOSONAR
				}
				else {
					separatorClass = '';
				}

				table.addClass(`m-basic-table--white ${separatorClass}`);

			} else {

				// show origi table
				this.$ctx.show();

				// remove smartphone table
				this.$ctx.next('.js-weather-clone').remove();
				this.$ctx.closest('.js-basic-table-wrap').next('.js-weather-clone').remove();
			}
		}
	});
}(jQuery));
