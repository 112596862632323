(function ($)
{
    'use strict';
    /**
     * BasicBanderole module implementation.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class BasicBanderole
     * @extends T.Module
     */
    T.Module.BasicBanderole = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {Number} */
        $ctxTID: null,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {jQuery} */
        $swiperSlidesWrapper: null,

        /** @type {Object[]} */
        $swiperSlides: null,

        /** @type {Number} */
        spaceBetween: 0,

        /** @type {jQuery} */
        $swiperBtnRight: null,
        $swiperBtnLeft: null,

        /** @type {Object} unknown?? usecase */
        globalSwiper: null,

        /** @type {Boolean} */
        isLazy: true,

        /** @type {Boolean} */
        isLoaded: false,

        /**
         * Initialize.
         *
         * @param {function} resolve
         *
         * @param {Object} decoratorData
         */
        start(resolve, decoratorData)
        {
            this.$ctx = $(this._ctx);
            this.$ctxTID = this.$ctx.data('t-id');
            this.$swiperContainer = this.$ctx.find('.swiper');
            this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper');
            this.$swiperSlides = this.$swiperSlidesWrapper.find('.swiper-slide');
            this.spaceBetween = decoratorData.spaceBetween || 0;

            this.$swiperBtnLeft = this.$ctx.find('.swiper-button-prev');
            this.$swiperBtnRight = this.$ctx.find('.swiper-button-next');

            // check sort
            if (this.$ctx.data('random') === 1000)
            {
                this.sortRandom();
            }

            this.equalizeTeaserAcrossHeader();

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides, true);

            resolve();
        },

        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate(target, dataObj)// eslint-disable-line
        {
            // init swipe
            this.initSwiper();

        },

        /**
         * init swipe options
         */
        initSwiper()
        {
            // if sitecore edit mode
            const simulateTouch = this.$ctx.data('mode') !== 'edit',
                keyboardControl = this.$ctx.data('mode') !== 'edit',
                mglDisount = this.$ctx.hasClass('o-basic-banderole--mgldiscount'),
                spaceBetween = mglDisount ? 30 : this.spaceBetween,
                lazy = {
                    checkInView: true,
                    loadPrevNextAmount: 2,
                    loadPrevNext: true,
                    loadOnTransitionStart: true,
                };

            // init swiper container - for testing this way
            let swiperOptions = {
                spaceBetween: spaceBetween,
                slidesPerView: 'auto',
                slidesPerGroupAuto: true,
                simulateTouch: simulateTouch,
                watchSlidesProgress: true,
                preloadImages: false,
				a11y: false,
                centerInsufficientSlides: true,
                keyboard: {
                    enabled: keyboardControl,
                },
                navigation: {
                    prevEl: this.$swiperBtnLeft[0],
                    nextEl: this.$swiperBtnRight[0],
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        speed: 500,
                    },
                    1024: {
                        speed: 1500,
                    }
                },
                freeMode: {
                    enable: true,
                    momentumRatio: 0.5,
                    momentumVelocityRatio: 0.7,
                },
                on: {
                    init: (swiper) =>
                    {
                        // enables/disables the swiper
                        // - In the old logic, the slides were "centred"
                        S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                    },
                    resize: (swiper) =>
                    {
                        S.Utils.delayed(`basicbanderole-check-${this.$ctxTID}`, 100, () =>
                        {
                            // enables/disables the swiper
                            // - In the old logic, the slides were "centred"
                            S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                        });
                    },
                    /**
                     * @param {Object} swiper
                     * @param {HTMLElement} slideEl
                     * @param {HTMLElement} imageEl :: if needed
                     */
                    lazyImageLoad: (swiper, slideEl) =>
                    {
                        const $slide = $(slideEl);

                        // kept the same old logic from loadVisibleImages() Function
                        $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                        $slide.find('.js-init').removeClass('js-init');
                    },
                }
            };

            // for develop testing purpose only - disable/enable lazy loading via param
            if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false'))
            {
                swiperOptions = {...swiperOptions, lazy};
            }

            const mySwiper = new Swiper(this.$swiperContainer[0], swiperOptions);

            if (typeof this._sandbox.swiperInstance === 'undefined')
            {
                this._sandbox.swiperInstance = {};
            }
            this._sandbox.swiperInstance[this.$ctx.data('t-id')] = mySwiper;
        },

        /**
         * sort slide boxes random
         */
        sortRandom()
        {
            const slideArray = [];

            this.$swiperSlides.each((index, item) =>
            {
                slideArray.push($(item));
                $(item).remove();
            });

            slideArray.sort(function ()
            {
                return .5 - Math.random();
            });

            $.each(slideArray, (index, item) =>
            {
                $(item).appendTo(this.$swiperSlidesWrapper);
            });
        },

        /**
         * find the highest header value of the TeaserAcross--rating and apply it to all of them
         */
        equalizeTeaserAcrossHeader()
        {
            const $teaser = this.$ctx.find('.m-basic-teaser-across--rating header');

            if ($teaser.length)
            {
                const heights = [];

                $teaser.each((_, el) =>
                {
                    heights.push($(el).outerHeight());
                });

                $teaser.css('height', `${Math.max(...heights)}px`);
            }
        }

    });
}(jQuery));
