(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.LayerBgDark = {
		/**
		 * darkness layer sv 2015-11-19
		 *
		 *
		 */

		darknessLayer: null,
		dataClick: 'clicked-element',
		classLayoutDarkness: 'a-layout-darkness--loader',

		/**
		 * init
		 * @param {jQuery} clickedElem
		 * @callback function fired by click on the darkness layer
		 *
		 */
		init: function (clickedElem, callback) {
			// set layer
			this.darknessLayer = $('.a-layout-darkness');

			// reset modifier
			this.darknessLayer.removeClass(this.classLayoutDarkness);

			if (clickedElem) {
				this.callback(clickedElem, callback);
			}

		},

		showLayer: function () {
			this.init();
			this.darknessLayer.show();
		},

		hideLayer: function () {
			this.init();

			if (!this.darknessLayer.hasClass(this.classLayoutDarkness) && $('.js-aside.is-open').length === 0) {
				this.darknessLayer.stop().fadeOut();
			}
		},

		instantHideLayer: function () {
			this.init();

			if (!this.darknessLayer.hasClass(this.classLayoutDarkness) && $('.js-aside.is-open').length === 0) {
				this.darknessLayer.hide();
			}
		},

		/**
		 * callback
		 * @param {jQuery} clickedElem
		 * @callback function fired by click on the darkness layer
		 *
		 */
		callback: function (clickedElem, callback) {
			// check if clickedElem already has a clicked element data and if it is the same as the last time
			if (this.darknessLayer.data(this.dataClick) === undefined) {
				// set new data and new callback
				this.set(clickedElem, callback);
			} else {
				if (this.darknessLayer.data(this.dataClick) === clickedElem) {
					// toggle dakness layer
					this.darknessLayer.stop().fadeToggle();
				} else {
					// set new data and new callback
					this.set(clickedElem, callback);
				}
			}
		},

		/**
		 * addEventListener
		 * @callback function fired by click on the darkness layer
		 *
		 */
		addEventListener: function (callback) {
			// click on dark layer
			this.darknessLayer.off().on('click', function () {
				// fade out darkness layer
				$(this).stop().fadeOut();

				// fire the callback function given by param callback
				if (callback) {
					callback();
				}

				$(document).trigger('darknessLayerClosed');
			});
		},

		/**
		 * set
		 * @param {jQuery} clickedElem
		 * @callback function fired by click on the darkness layer
		 *
		 */
		set: function (clickedElem, callback) {
			// set new clicked-element
			this.darknessLayer.data(this.dataClick, clickedElem);

			// fadeIn dakness layer
			this.darknessLayer.stop().fadeIn();

			// set click event
			this.addEventListener(callback);
		},

		showLoader: function () {
			this.init();
			this.darknessLayer.addClass(this.classLayoutDarkness).stop().fadeIn('fast');
		},

		/**
		 * showLoaderText
		 * @param {string} text
		 * @param {number} time
		 */
		showLoaderText: function (text, time) {
			this.showLoader();
			this.darknessLayer.addClass('a-layout-darkness--layer');

			let textHint = 'Der Inhalt wird geladen, bitte warten Sie noch einen Moment.',
				timeout = 1000;

			if (text) {
				textHint = text;
			}

			if (time) {
				timeout = time * 1000;
			}

			this.darknessLayer.append($('<div class="aa-layer"></div>'));

			setTimeout(() => {
				this.darknessLayer.find('.aa-layer').append($(`<span class="aa-text h-h2">${textHint}</span>`));
			}, timeout);
		},

		hideLoader: function () {
			this.darknessLayer.stop().fadeOut('fast');
			this.darknessLayer.removeClass('a-layout-darkness--layer').find('.aa-layer').remove();
		}
	};
})(jQuery);
