(function ($)
    {
        'use strict';
        /**
         * MyAdacDashboardListShowcase module implementation.
         *
         * @author Tobias <t.grigoriadis@edelweiss72.de>
         * @namespace T.Module
         * @class MyAdacDashboardList
         * @extends T.Module
         */
        T.Module.MyAdacDashboardListShowcase = T.createModule({

            /** @type {jQuery} this.module ID */
            $module: null,

            /** @type {Number} this.module ID */
            moduleId: null,

            /** @type {jQuery}*/
            $listWrapper: null,

            /** @type {jQuery}*/
            $leftListPointWrapper: null,

            /** @type {jQuery}*/
            $rightListPointWrapper: null,

            /** @type {number}*/
            neededLeftListWidth: 0,

            /** @type {number}*/
            neededRightListWidth: 0,

            /** @type {number}*/
            givenLeftListWidth: 0,

            /** @type {number}*/
            givenRightListWidth: 0,

            /** @type {Boolean}*/
            firstInit: true,

            /** @type {jQuery}*/
            infoIcon: null,

            /** @type {jQuery}*/
            ibanVal: null,

            start(resolve)
            {
                this.$module = $(this._ctx);

				const listWrapperClass = '.mm-list-wrapper';

                this.moduleId = this.$module.data('tId');
                this.$listWrapper = this.$module.find(listWrapperClass);
                this.$leftListPointWrapper = this.$listWrapper.find('.mm-left-list-wrapper');
                this.$leftListPoints = this.$leftListPointWrapper.find('.mm-list-point-headline');
                this.$rightListPointWrapper = this.$listWrapper.find('.mm-right-list-wrapper');
                this.$rightListPoints = this.$rightListPointWrapper.find('.mm-list-point-text');
                this.givenLeftListWidth = this.$leftListPointWrapper.width();
                this.givenRightListWidth = this.$rightListPointWrapper.width();
                this.infoIcon = this.$module.find('.mm-info-icon') || null;
                this.infoIconLabel = this.infoIcon.closest('.mm-left-list-wrapper, .mm-right-list-wrapper') || null;
                this.ibanVal = this.$rightListPoints.filter('.js-iban');

                // check if an "i"-icon is in the list and make the label and icon clickable
                if (this.infoIcon.length && this.infoIcon !== null)
                {
                    // for clicking a button with the class - mm-layer
                    // which contains a layer -> trigger the event
                    this.infoIconLabel.on('click', (e) =>
                    {
						const amount = $(e.currentTarget).closest(listWrapperClass).find('.m-my-adac-dashboard-box-layer').length;

						$(e.currentTarget).closest(listWrapperClass).removeClass('open-left open-right');

                        if($(e.currentTarget).hasClass('mm-left-list-wrapper') && amount > 1) {
							$(e.currentTarget).closest(listWrapperClass).addClass('open-left');
						}
                        else if($(e.currentTarget).hasClass('mm-right-list-wrapper') && amount > 1) {
							$(e.currentTarget).closest(listWrapperClass).addClass('open-right');
						}

						e.stopPropagation();

                        this._events.emit('MyAdacDashboardBoxLayer.open-layer-info-icon', $(e.currentTarget).closest(listWrapperClass));
                    });
                }


                this._events.on('MyAdacDashboardList.content-changed', (e) =>
                {
                    if (this.ibanVal.length)
                    {
                        this.formatIban();
                    }

                    if (e.is(this.$module.closest('.mm-box')) && this.firstInit)
                    {
                        this.init();
                        this.firstInit = false;
                    }
                });

                resolve();
            },

            /**
             * init only on trigger
             */
            init()
            {
                this.checkLeftListWrapper();
            },

            /**
             * check before calculating and initializing if it necessary for this list to be calculated
             * or if the words fits in the 40%/60% split
             * CHECK LEFT FIRST, if this fits the split check the right sight
             */
            checkLeftListWrapper()
            {

                let checkTheRightListToo = true;

                // eslint-disable-next-line
                this.$leftListPoints.each((index, element) => //NOSONAR
                {
                    if (this.givenLeftListWidth <= ($(element).outerWidth(true)))
                    {
                        this.setHighestWidthToAllListpoints();
                        checkTheRightListToo = false;

                        return false;
                    }
                });

                if (checkTheRightListToo)
                {
                    this.checkRightListWrapper();
                }
            },

            /**
             * check before calculating and initializing if it necessary for this list to be calculated
             * or if the words fits in the 40%/60% split
             * CHECK RIGHT SIGHT, if this fits the split all is fine
             */
            checkRightListWrapper()
            {
                // eslint-disable-next-line
                this.$rightListPoints.each((index, element) => //NOSONAR
                {

                    if (this.givenRightListWidth <= ($(element).outerWidth(true)))
                    {
                        this.setHighestWidthToAllListpoints();

                        return false;
                    }
                });

                this.$listWrapper.removeClass('is-hidden');
            },

            /**
             * checks if the someone puts something in the searchfield and pushed Browser-reload
             * on Browser-reload the searching-js saves the search word in sessionStorage
             * @return {boolean}
             */
            getSessionMatch()
            {
                for (let i = 0; i < sessionStorage.length; i++)
                {
                    const key = sessionStorage.key(i);

                    const keyStorage = key.split('/')[0];

                    if (keyStorage === 'basickeywordfilter_searchtext_')
                    {
                        return true;
                    }
                }

                return false;
            },

            /**
             * get the width of longest content
             * @param {jQuery} $elementCollection Object
             * @return {number} width number
             */
            getContentWidth($elementCollection)
            {
                const contentWidths = [];

                $elementCollection.each((index, element) =>
                {
                    $(element).css({width: 'initial'});
                    contentWidths.push($(element).outerWidth());
                });

                return Math.max.apply(null, contentWidths);
            },


            /**
             * sets the width of longest content
             * @param {jQuery} $elementCollection Object
             * @param {number} neededWidth number
             */
            setContentWidth($elementCollection, neededWidth)
            {

                // $elementCollection.css({width: '100%', maxWidth: neededWidth})
                $elementCollection.children().each((value, element) =>
                {
                    $(element).css({width: '100%', maxWidth: neededWidth});
                });
            },

            /**
             * get the width of longest content
             * set it first to the left list-wrapper and then for each element in the right
             */
            setHighestWidthToAllListpoints()
            {
                // new requirement: width are fixed
                this.$listWrapper.removeClass('is-hidden');
            },

            /**
             * format iban val by adding blank spaces every n char
             */
            formatIban()
            {
                // remove whitespaces from string and split it in array every 4th char
                if(this.ibanVal.text().length > 0) {
                    const formatArray = this.ibanVal.text().replace(/ /g,'').match(/.{1,4}/g);
                    this.ibanVal.text(formatArray.join(' '));
                }
            },
        });
    }(jQuery));
