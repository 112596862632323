(function ($)
{
    'use strict';
    /**
     * MglAdvisor module implementation.
     *
     * @author Nicole Wewior <n.wewior@mgo360.de>
     * @namespace T.Module
     * @class RufRating
     * @extends T.Module
     */
    T.Module.MglAdvisor = T.createModule({

        /** @type {jQuery} */
        $ctx: null,
        $btnStart: null,
        $wrapperIntro: null,
        $wrapperAdvisor: null,
        $answers: null,
        $result: null,
        $questionWrapper: null,
        $prio: null,
        $flow: null,

        start: function (resolve)
        {
            this.$ctx = $(this._ctx);

            this.questionWrapperClass = '.oo-question-wrapper';

            this.$btnStart = this.$ctx.find('.js-start');
            this.$wrapperIntro = this.$ctx.find('.js-intro');
            this.$wrapperAdvisor = this.$ctx.find('.js-advisor');
            this.$answers = this.$ctx.find('.js-answer');
            this.$result = this.$ctx.find('.oo-result-wrapper');
            this.$questionWrapper = this.$ctx.find(this.questionWrapperClass);
            this.$prio = this.$ctx.data('priority').split(',');

            this.initStart();
            this.manageAnswers();

            resolve();
        },

        /**
         * initEvents
         *
         * inits start advisor
         */
        initStart: function ()
        {
            this.$wrapperAdvisor.find(this.questionWrapperClass).children().first().removeClass('is-hidden');

            this.$btnStart.on('click', () =>
            {
                this.$wrapperIntro.addClass('is-hidden');
                this.$wrapperAdvisor.removeClass('is-hidden');
                $('html, body').scrollTop(0);
            });
        },

        /**
         * initEvents
         *
         * click through answers
         */
        manageAnswers: function ()
        {
            this.$answers.on('click', (answer) =>
            {
                const questionsClass = '.oo-questions',
					$currentAnswer = $(answer.currentTarget),
					$answerWrapper = $currentAnswer.closest('.oo-selection'), // wrapper for possible answers to current question
                    $currentQuestion = $currentAnswer.closest(questionsClass),
					$currentOtherQuestions = $currentQuestion.nextAll(), // all other given questions
                    $fullAnswer = $currentAnswer.closest(questionsClass).find('.oo-answer-wrapper');
                let $nextQuestion = $currentAnswer.closest(questionsClass).next();

				// first question defines the flow (all other questions do not have a defined data('flow') so only change
				// this.flow if its not 'undefined'. If this.$flow is not set on answer the value is not null but undefined
				if(typeof $currentAnswer.data('flow') !== 'undefined' && $currentAnswer.data('flow').length > 1)
				{
					// save current flow flag: is-senior / is-junior / is-new
					this.$flow = $currentAnswer.data('flow');
                    $nextQuestion.closest(this.questionWrapperClass).removeClass('is-done');
                    $currentOtherQuestions.addClass('is-hidden');
                    $currentOtherQuestions.find('.oo-answer').addClass('is-hidden');
                    $currentOtherQuestions.find('.oo-answer-item').removeClass('is-active');
                    $currentAnswer.closest(this.questionWrapperClass).find('.oo-questions--end .oo-text .oo-thanks').addClass('is-hidden');
                }

				if(this.$flow.length > 1) {
					$nextQuestion = $currentAnswer.closest(questionsClass).nextAll(`.${this.$flow}, .oo-questions--end`).first();
				}

                $answerWrapper.children().removeClass('is-active');
                $currentAnswer.addClass('is-active');
                this.calcResult();

                /**
                 * answer container shows clicked result in "oo-answer" like - "e.g. Ich bin über 23 Jahre." (grey container)
                 *
                 * class "is-dechosen" :: display: none
                 * class "is-hidden" :: opacity: 0; max-height: 0; margin-bottom:0 !important; padding: 0;
                 */
                if ($fullAnswer.length)
                {
                    setTimeout(() =>
                    {
                        // search for not hidden answers container and set them to "not-chosen"
                        $fullAnswer.children().filter(':not(.is-hidden)').addClass('is-dechosen');
                        // hide the visibility of all current answer container
                        $fullAnswer.children().addClass('is-hidden');
                        // show selected answer immediately
                        $fullAnswer.children().eq($currentAnswer.index()).removeClass('is-hidden');

                        setTimeout(() =>
                        {
                            // show all answer container back again,
                            // and the only visible one is the clicked one
                            $fullAnswer.children().removeClass('is-dechosen');
                        }, 701);

                    }, 300);
                }

                setTimeout(() =>
                {
                    $nextQuestion.removeClass('is-hidden is-dechosen');

                    // handles the end of the chat conversation
                    if ($nextQuestion.hasClass('oo-questions--end'))
                    {
                        // mark it as "is-done"
                        $nextQuestion.closest(this.questionWrapperClass).addClass('is-done');

                        // check for flow flags like :: is-senior / is-junior / is-new
                        if (this.$flow?.length > 1)
                        {
                            // show correct thanks text/container with the flow dependency
                            $nextQuestion.find(`.oo-text .oo-thanks.${this.$flow}`).removeClass('is-hidden');
                        }
                        else
                        {
                            // show default thanks text/container
                            $nextQuestion.find('.oo-text .oo-thanks').removeClass('is-hidden');
                        }
                    }

                    let scrollPos, searchedEl;

                    // if all questions are answered
                    if ($nextQuestion.closest(this.questionWrapperClass).hasClass('is-done'))
                    {
                        // find last question/thanks text and scroll to it
                        searchedEl = this.$questionWrapper.children().last();
                        scrollPos = searchedEl?.offset().top - searchedEl?.outerHeight();
                    }
                    // if there is an answer container (clicked user answer shown in grey container below)
                    else if ($fullAnswer.length)
                    {
                        if (!S.Utils.Helper.mq('desktop').matches)
                        {
                            // scroll to chosen answer container (shows clicked user answer)
                            scrollPos = $fullAnswer.offset().top - 10;
                        }
                        else
                        {
                            // just scroll to the first hidden ones on mobile
                            searchedEl = this.$questionWrapper.find('>.is-hidden').first();
                            scrollPos = searchedEl?.offset().top - $(window).height();
                        }
                    }

                    // just for safety:
                    $('html, body').stop().animate({
                        'scrollTop': scrollPos || this._getDefaultScrollPos($nextQuestion),
                    }, 800, 'swing');

                }, 1200);
            });
        },

        /**
         * default scroll pos
         * @param $nextQuestion
         * @returns {number}
         * @private
         */
        _getDefaultScrollPos($nextQuestion)
        {
            // if there is a nextQuestion, scroll to it minus the answer container
            if ($nextQuestion?.length)
            {
                return ($nextQuestion[0].getBoundingClientRect().top - $nextQuestion.outerHeight()) + window.scrollY;
            }

            return this.$questionWrapper.children().last();
        },

        /**
         * initEvents
         *
         * calculates which result has to be shown
         */
        calcResult: function ()
        {
            const that = this;
            const chosenResult = [];

            that.$result.find('> div').addClass('is-hidden');

            setTimeout(function ()
            {
                if (that.$questionWrapper.hasClass('is-done'))
                {
                    that.$answers.each(function ()
                    {
                        if ($(this).hasClass('is-active'))
                        {
                            chosenResult.push($(this).data('value').toString());
                        }
                    });

					/**
					 * go through the prioritylist given via data attribute on module and compare with values at given answers (chosenResult)
					 * first entry from prioritylist which is found represent the highest chosen priority and should therefor be shown
					 */
                    for (let i = 0; i < that.$prio.length; i++)
                    {
                        if (chosenResult.indexOf(that.$prio[i]) > -1)
                        {
                            that.$result.find(`> div[data-priority="${that.$prio[i]}"]`).removeClass('is-hidden');
                            break;
                        }
                    }
                }
            }, 1800);
        }
    });
}(jQuery));
