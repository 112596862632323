'use strict';
(function ($) {
	/**
	 * RedirectToAllianz module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class RedirectToAllianz
	 * @extends T.Module
	 */
	T.Module.RedirectToAllianz = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		sessionKey: "selector.RedirectToAllianz",
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			T.Utils.Auth.getResolvedIdentity(this._sendRequest.bind(this));
			resolve();
		},

		_sendRequest(user) {
			const mode = this.$ctx.data('mode');
			const maintenance = (this.$ctx.data('maintenance') === 'True');
			if (maintenance) {
				this._doRedirect();
			} else {
				const api = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.$ctx.data('api-token'));
				let params = {};
				if (mode === 'auto') {
					const preAssignedValue = this._preAssignValue();

					const bidParameter = T.Utils.Helper.getUrlParameter('bid') || T.Utils.Helper.getUrlParameter('BID');
					const lidParameter = T.Utils.Helper.getUrlParameter('lid') || T.Utils.Helper.getUrlParameter('LID');
					let IdArt = 'Keine';
					let Id = '';
					if (bidParameter) {
						IdArt = 'VorschlagAntrag';
						Id = bidParameter;
					} else if (lidParameter) {
						IdArt = 'Lead';
						Id = lidParameter;
					}
					params = {
						vorbelegung: preAssignedValue,
						IdArt: IdArt,
						Id: Id
					};
				}
				const options = {
					type: 'GET',
					url: api,
					data: params
				};

				if (user && user.UserData) {
					T.Utils.Auth.getBearerToken((token) => {
						options.headers = {
							Authorization: `Bearer ${token}`
						};
						T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
					});
				} else {
					T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
				}
			}
		},
		_successCallback: function _successCallback(data) {
			if (data.Data && data.Data.Token) {
				this.$ctx.find('#jwt').val(data.Data.Token);
				this._doRedirect();
			}
		},
		_errorCallback: function _errorCallback() {
			this.$ctx.find("#errortext").removeClass("h-hidden");
		},
		_doRedirect: function _doRedirect() {
			const $form = this.$ctx.find("form")[0];
			if ($form) {
				$form.submit();
			}
		},
		_preAssignValue: function _preAssignValue() {
			const urlparam = this.$ctx.data('preassignurlparam');
			let preAssignedValue = this.$ctx.data('preassignvalue');
			if (urlparam && T.Utils.Helper.getUrlParameter(urlparam)) {
				preAssignedValue = T.Utils.Helper.getUrlParameter(urlparam);
			}
			return preAssignedValue;
		}
	});
}(jQuery));
