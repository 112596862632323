'use strict';
(function ($) {
	/**
	 * Membership Wallet Advertising Consent
	 *
	 * @author Carsten Meiselbach <carsten.meiselbach@emea.merkleinc.com>
	 * @namespace T.Module
	 * @class MyAdacMembershipContent-WalletAdConsent
	 * @extends T.Module.MyAdacMembershipContent
	 */
	T.Module.MyAdacMembershipContent.WalletAdConsent = T.createDecorator({
		$chkEinwilligung: null,
		$btnSubmit: null,

		/**
		 * start function
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this._parent.start(resolve);
			T.Utils.Application.init();
			this.$chkEinwilligung = this.$ctx.find('#Einwilligung');
			this.$btnSubmit = this.$ctx.find('.js-submit');
			this.readConfiguration();
			this.addEventListeners();
			T.Utils.View.startLoader();
			this.passId = T.Utils.Helper.getUrlParameter("passid", window.location.href.toLowerCase());
			if (this.passId === undefined || this.passId === null || this.passId === "") {
				T.Utils.Auth.getResolvedIdentity(this.handleUserData.bind(this));
			} else {
				T.Utils.View.stopLoader();
			}
			resolve();
		},

		readConfiguration: function readConfiguration() {
			this._apiurl = this.$ctx.data("apiurl");
			this._apimYourpassproxy = this.$ctx.data("apimyourpassproxy");
			this._backend = this.$ctx.data("backend") || T.Utils.Application.getApi();
		},

		addEventListeners: function addEventListeners() {
			this.$btnSubmit.on('click', this.submitConsent.bind(this));
		},

		handleUserData: function handleUserData(user) {
			this._user = user;
			this.getWalletConsentData();
		},

		getWalletConsentData: function getWalletConsentData() {
			T.Utils.Auth.getBearerToken((token) => {
				const options = {
					url: T.Utils.Helper.appendURIPath(this._backend, this._apiurl),
					type: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				};

				T.Utils.Ajax.fragment(options, this.handleWalletReqSuccess.bind(this), this.handleApilError.bind(this));
			});
		},

		handleWalletReqSuccess: function handleWalletReqSuccess(data) {
			if (data && data.Data && data.Data.advertisingConsent) {
				this.$chkEinwilligung.prop('checked', true);
			} else {
				this.$chkEinwilligung.prop('checked', false);
			}

			T.Utils.View.stopLoader();
		},

		submitConsent: function submitConsent() {
			T.Utils.View.startLoader();
			if (this.passId === undefined || this.passId === null || this.passId === "") {
				this.submitConsentWithBearerToken();
			} else {
				this.submitConsentWithPassId();
			}
		},

		submitConsentWithBearerToken: function submitConsentWithBearerToken() {
			T.Utils.Auth.getBearerToken((token) => {
				const options = {
					url: T.Utils.Helper.appendURIPath(this._backend, this._apiurl),
					type: 'POST',
					headers: {
						Authorization: `Bearer ${token}`
					},
					data: JSON.stringify({
						"Werbeeinwilligung": this.$chkEinwilligung.is(":checked") ? true : false
					})
				};

				T.Utils.Ajax.fragment(options, this.handlSubmitConsentSuccess.bind(this), this.handleApilError.bind(this));
			});
		},

		submitConsentWithPassId: function submitConsentWithPassId() {
			const options = {
				url: T.Utils.Helper.appendURIPath(this._backend, this._apiurl),
				type: 'POST',
				data: JSON.stringify({
					"Werbeeinwilligung": this.$chkEinwilligung.is(":checked") ? true : false,
					"AdacPassId": this.passId
				})
			};

			T.Utils.Ajax.fragment(options, this.routeToApim.bind(this, this.passId), this.handleApilError.bind(this));
		},

		handlSubmitConsentSuccess: function handlSubmitConsentSuccess(res) {
			if (res.Data && res.Data.adacpassid) {
				this.routeToApim(res.Data.adacpassid);
			} else {
				T.Utils.View.stopLoader();
			}
		},

		routeToApim: function routeToApim(passId) {
			const apimUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._apimYourpassproxy);
			T.Utils.Helper.routeToUrl(T.Utils.Helper.appendURIPath(apimUrl, passId));
		},

		handleApilError: function handleApilError() {
			this.$ctx.find(".js-interface-error").removeClass('h-hidden');
			T.Utils.View.stopLoader();
		},
	});
}(jQuery));
