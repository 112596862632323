'use strict';
(function ($) {
	/**
	 * MoinAiTrigger bubble decorator implementation.
	 *
	 * @author  <krystian.sadowski@namics.com>
	 * @namespace T.Module
	 * @class MoinAiTrigger
	 * @extends T.Module
	 */
	T.Module.MoinAiTrigger.Bubble = T.createDecorator({
		swapContent() {
			const check = setInterval(() => {
				const $botBtn = $($.find('.moin-ai-button')).contents().find('.knw_widget_button');
				if ($botBtn && $botBtn.length > 0) {
					const $botbtnParent = $botBtn.closest("#moinai-container");
					if ($botbtnParent && $botbtnParent.length > 0) {
						$botbtnParent.attr("tabindex", 0);
						$botbtnParent.on('keydown', (e) => {
							if (e.key === 'Enter') {
								$botBtn.trigger('click');
							}
						});
					}
					$botBtn.trigger('click');
					this.$ctx.addClass('h-hidden');
					clearInterval(check);
				}
			}, 100);
		}
	});
}(jQuery));