(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.FormAside = {

		asideMode: {},
		asideOpenClass: '.js-aside-open',
		lformGeneralClass: '.l-form, .l-form-general',
		bodyOuterHeaderClass: 'body > .l-outer > .m-basic-header',
		stickyDetachClass: 'sticky_kit:detach',

		/**
		 * init form actions
		 *
		 */
		init() {
			// initial check if aside is present.
			if ($('.js-aside').length) {
				if ($(this.lformGeneralClass).find('.l-main-cols--calculator').length > 0) {
					this.asideMode.pageType = 'calculator';
				}

				if ($('.l-form:not(.l-form--cardamage-map), .l-form-general:not(.l-form-general--cardamage-map)').find('.ll-aside-inline-on-init').length > 0) {
					this.asideMode.animationOnInit = true;
					this.asideMode.animationFinished = false;
				}

				if ($('.l-form--cardamage-map, .l-form-general--cardamage-map').find('.ll-aside-inline-on-init').length) {
					this.asideMode.inlineInTablet = true;
				}

				this.setAside($(this.lformGeneralClass));

				$(this.lformGeneralClass).each((index, item) => {
					// init listener click events
					this._addEventListener($(item));
				});

				if (!S.Utils.Helper.mq('desktop').matches) {
					this._smartphoneTablet($(this.lformGeneralClass));
				}

				S.Utils.Helper.mq('tablet').addListener(() => {
					this.setAside($(this.lformGeneralClass));
				});
				S.Utils.Helper.mq('desktop').addListener(() => {
					this.setAside($(this.lformGeneralClass));
				});
			}
		},

		/**
		 * Set the Aside Bar Sticky
		 *
		 * @param {jQuery} item
		 */
		setAside(item) {
			const aside = $(item).find('.js-aside');

			let makeSticky = false;

			// general reset
			aside.trigger(this.stickyDetachClass);

			if ($('.m-basic-status-nav').length) {
				aside.addClass('has-status-nav');
			}

			if (S.Globals.MediaQuery.mqRightNow !== 'smartphone' || this.asideMode.pageType !== 'calculator' || this.asideMode.animationOnInit === true) {
				makeSticky = true;
			}

			if (S.Globals.MediaQuery.mqRightNow !== 'desktop' && this.asideMode.inlineInTablet === true) {
				makeSticky = false;
			}

			if (makeSticky) {
				this.setSticky(aside);
			}
			else {
				this.setMobileBarSticky($(item).find('.ll-aside-bar--mobile'));
			}
		},

		/**
		 * set elem sticky
		 *
		 * @param $mobileBar {Object} - mobile sticky bar
		 */
		setMobileBarSticky($mobileBar) {
			let offsetTop;

			// new header stuff
			if ($(this.bodyOuterHeaderClass).length) {
				offsetTop = $(this.bodyOuterHeaderClass).eq(0).outerHeight();
			}

			else {
				offsetTop = 0;
			}

			$mobileBar.stick_in_parent({
				sticky_class: 'is-stuck',
				offset_top: offsetTop,
				parent: this.lformGeneralClass,
				spacer: false
			});
		},

		/**
		 * set elem sticky
		 *
		 * @param asideElem {Object} - sticky elem
		 */
		setSticky(asideElem) {

			// general reset
			asideElem.addClass('sticky-init');

			let $header,
				headerHeight,
				headerNew = false;

			// new header stuff
			if ($(this.bodyOuterHeaderClass).length) {
				$header = $(this.bodyOuterHeaderClass).eq(0);
				headerHeight = $($header).outerHeight();
				headerNew = true;
			}

			else {
				$header = $('.l-header');
				headerHeight = $header.height();

				if (S.Utils.Helper.mq('tablet').matches) {
					headerHeight += 39;

					if (S.Utils.Helper.mq('desktop').matches) {
						headerHeight += 8;
					}
				}
			}

			asideElem.stick_in_parent({
				offset_top: headerHeight,
				sticky_class: 'is-stuck',
				parent: this.lformGeneralClass
			})
				.on("sticky_kit:stick", () => {
					if (S.Globals.MediaQuery.mqRightNow !== 'desktop' && this.asideMode.animationOnInit === true && this.asideMode.animationFinished === false) {
						asideElem.show().prev().css('top', '0').hide();

						setTimeout(function () {
							asideElem.addClass('is-animating-right');
						}, 200);

						setTimeout(() => {
							asideElem.prev().fadeIn().animate(
								{
									top: '33.5%'
								}, 1000, () => {
									asideElem
										//.attr('style', '')
										//.hide()
										.hide()
										.removeClass('is-animating-right')
										.trigger('sticky_kit:recalc')
										.removeClass('ll-aside-inline-on-init');
								});
						}, 1000);

						this.asideMode.animationFinished = true;
					}

					if (headerNew) {
						S.Utils.delayed('FormAside.WaitForStickyHeaderAnim', 400, () => {
							this.recheckHeader($header, headerHeight, asideElem);
						});
					}
				})
				.on('sticky_kit:bottom', () => {
					asideElem.addClass('is-bottom');
				})
				.on('sticky_kit:unbottom', () => {
					asideElem.removeClass('is-bottom');
				});
		},

		/**
		 * rechecks sticky offset in case header has changed
		 *
		 * @param $header {Object} - .m-basic-header
		 *
		 * @param currentStickyHeight {Number} - current offset of sticky elem
		 *
		 * @param asideElem {Object} - sticky elem
		 */
		recheckHeader($header, currentStickyHeight, asideElem) {
			if ($header.outerHeight() !== currentStickyHeight) {
				$(asideElem).trigger(this.stickyDetachClass);
				this.setSticky(asideElem);
			}
		},

		_addEventListener: function (formContainer) {
			const that = this;

			S.Utils.Helper.mq('tablet').addListener(function (mq) {
				if (!mq.matches) {
					// smartphone + tablet
					that._smartphoneTablet(formContainer);
				}
			});

			S.Utils.Helper.mq('desktop').addListener(function (mq) {
				if (!mq.matches) {
					// smartphone + tablet
					that._smartphoneTablet(formContainer);
				}
				else {
					// desktop
					that._desktop(formContainer);
				}
			});
		},

		_smartphoneTablet: function (formContainer) {
			const that = this;

			// show membership contribution layer
			formContainer.find(this.asideOpenClass).off('click').on('click', function () {
				// layer bg dark
				const callback = function () {
					// fade layer, show button
					formContainer.find(this.asideOpenClass).fadeOut(() => {
						formContainer.find(this.asideOpenClass).removeClass('is-open');
					});
					formContainer.find(this.asideOpenClass).fadeIn();
				};
				S.Utils.LayerBgDark.init($(this).find(this.asideOpenClass), callback);
				$(this).fadeOut();
				$(this).siblings('.js-aside').addClass('is-open').trigger(this.stickyDetachClass).fadeIn();
			});

			if (that.asideMode.pageType === 'calculator') {
				formContainer.find('.js-aside').show();
				formContainer.find(this.asideOpenClass).hide();

				if (this.asideMode.animationOnInit === true) {
					if (this.asideMode.animationFinished === false) {
						formContainer.find('.js-aside').show();
						formContainer.find(this.asideOpenClass).hide();
					}
					else if (formContainer.find('.js-aside.is-open').length === 0) {
						formContainer.find('.js-aside').hide();
						formContainer.find(this.asideOpenClass).show();
					}
				}
			}
			else if (formContainer.find('.js-aside.is-open').length === 0) {
				if (this.asideMode.inlineInTablet) {
					formContainer.find('.js-aside').trigger(this.stickyDetachClass);
				}

				else {
					formContainer.find(this.asideOpenClass).show();
					formContainer.find('.js-aside').hide();
				}
			}
		},

		_desktop: function (formContainer) {
			S.Utils.LayerBgDark.instantHideLayer();
			formContainer.find(this.asideOpenClass).off('click');
			formContainer.find('.js-aside').show().removeClass('is-open');
			formContainer.find('.js-aside-open').hide();
		}
	};

	$(document).ready(function () {
		S.Utils.FormAside.init();
	});

})(jQuery);
