'use strict';
(function ($) {
	/**
	 * TestingServiceResults module implementation.
	 *
	 * @author Marc Radziwill <marc.radziwill@namics.com>
	 * @namespace T.Module
	 * @class TestingServiceResults
	 * @extends T.Module
	 */
	T.Module.TestingServiceResults = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		_mapResultCache: [],

		perimeter: 10,
		_popupConfig: { offset: 10, maxWidth: "320px", anchor: '', closeOnMove: false },

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();

			this._readConfig();
			this._registerListeners();

			this.$switchMapClass = this.$ctx.find('.js-switch[data-switch="map"]');
			this.$mapWrapperClass = this.$ctx.find('.js-maps-wrapper');

			this.$ctx.find(`.js-map-radius option[value="${this.perimeter}"]`).prop('selected', true);

			resolve();
		},

		_readConfig: function _readConfig() {
			this._labels = this.$ctx.data("labels") || {};
			this._$iconActive = this.$ctx.find('#mapsIconActivePoi');
			this._config = this.$ctx.data('config');

			if (!this._searchEvent) {
				this._searchEvent = "PoiPerimeterSearch";
			}
			this._errorEvent = this.$ctx.data('error-event');
			if (!this._errorEvent) {
				this._errorEvent = "PoiPerimeterSearchError";
			}
		},

		_registerListeners: function _registerListeners() {
			$(document).on(this._searchEvent, this.requestData.bind(this));
			$(document).on(this._errorEvent, this._handleSearchError.bind(this));
			this.$ctx.find('.js-map-radius').change(this._submitPerimeterChange.bind(this));
		},

		_readPerimeter: function _readPerimeter() {
			const perimeter = this.$ctx.find('.js-map-radius option:selected').val();
			this.perimeter = perimeter ? parseInt(perimeter, 10) : 10;
		},

		requestData: function requestData(event, searchData) {
			this._mapResultCache = [];

			this.searchData = searchData;

			const position = T.Utils.Geo.mercatorToLonLat({ x: searchData.geoRectangle.Position.X, y: searchData.geoRectangle.Position.Y });

			this._readPerimeter();

			T.Utils.View.startLoader();

			const options = {
				type: 'GET',
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() },
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), `${this._config.apiTestingServices}/${position.y}/${position.x}?UmkreisKm=${this.perimeter}&PageSize=${100}`)
			};

			T.Utils.Ajax.fragment(options, this._handleSearchResults.bind(this), this._handleSearchError.bind(this));
		},

		_submitPerimeterChange: function _submitPerimeterChange(event) {
			this._readPerimeter();
			this.requestData(event, this.searchData);
		},

		_handleSearchError: function _handleSearchError(event, data) {
			this._showMapWrapper(true);
			if (data.responseJSON && data.responseJSON.Success && data.responseJSON.Data.length === 0) {
				this._setResultCount(0);
				this._showNoResultHint(true);
			} else {
				this._showNoBackendHint(true);
				this._showMapWrapper(false);
			}
			T.Utils.View.stopLoader();
		},

		_handleSearchResults: function _handleSearchResults(data) {
			this._showNoResultHint(false);
			this._showNoBackendHint(false);
			this._showResultWrapper(true);
			this._showMapWrapper(true);

			this.$ctx.find('ul.mm-result-list').empty();
			this.$ctx.find('.mm-result-list-wrap > div').toggleClass('h-hidden', true);
			this.$ctx.find('.mm-icon').toggleClass('h-hidden', true);

			let items;
			if (data.Success && data.Data.TotalCount > 0) {
				items = data.Data.Pruefstellen.filter(function (item) {
					if (item.Art === '2') {
						if (item.DatumVon || item.DatumBis) {
							return true;
						}
					} else {
						return true;
					}

					return false;
				});
			}

			if (data.Success && items && items.length > 0) {
				this._setResultCount(items.length);
				this.$listItemTemplate = $(this.$ctx.find('#listitem').html());
				items.forEach((item) => {
					if (item.Aktiv) {
						this._createNewListItem(item);
					}
				});

				if (!T.Utils.Helper.isSmartphone() || this.$switchMapClass.hasClass("is-active")) {
					this._renderMap();
				} else {
					this.$switchMapClass.one('click', this._renderMap.bind(this));
				}
			} else {
				this._setResultCount(0);
				this._showNoResultHint(true);
				this._mapResultCache.push({
					html: this.$ctx,
					pos: {
						x: this.searchData.geoRectangle.Position.X, y: this.searchData.geoRectangle.Position.Y
					},
					tooltip: '',
					icon: this._$iconActive
				});
				this._renderMap();
			}
			T.Utils.View.stopLoader();
			T.Utils.Helper.initAdditionalModules(this.$ctx);
		},

		_createNewListItem: function _createNewListItem(item) {

			const $listItemClone = this.$listItemTemplate.clone();
			const { pinClass, pinId, listClass, headlineClass, testType } = this.getTypeInfo(item);
			const $pin = $listItemClone.find('.js-pin');
			const $icon = this.$ctx.find(`#${pinId}`);

			$pin.addClass(pinClass);
			$listItemClone.find('.js-testtype').text(testType);

			const addressContent = $('<div/>').html(item.Anschrift);
			const aTags = addressContent.find('a');

			for (const tag of aTags) {
				const $tag = $(tag);
				const href = $tag.attr('href');
				if (href && !href.includes('mailto')) {
					$tag.attr('target', '_blank');
				}
			}

			$listItemClone.find('.js-address').html(addressContent.html());

			$listItemClone.find('.js-location').text(item.Standort);
			$listItemClone.find('.js-distance').text(`${parseFloat(item.Distance.toString().replace(/\s/g, '').replace('.', ','))} ${this._getLabel('km')}`);

			if (item.Art === '2') {
				$listItemClone.find('.js-timesname-test').toggleClass('h-hidden', false);
				$listItemClone.find('.js-datefrom').toggleClass('h-hidden', false);
				$listItemClone.find('.js-dateuntil').toggleClass('h-hidden', false);

				if (item.DatumVon) {
					$listItemClone.find('.js-date-wrapper').toggleClass('h-hidden', false);
					$listItemClone.find('.js-datefrom').text(item.DatumVon);
				}
				if (item.DatumBis) {
					$listItemClone.find('.js-date-wrapper').toggleClass('h-hidden', false);
					$listItemClone.find('.js-dateuntil').text(` - ${item.DatumBis}`);
				}
			} else if (item.Pruefzeiten) {
				$listItemClone.find('.js-testtimes-text').toggleClass('h-hidden', false);
				$listItemClone.find('.js-timesname-open').toggleClass('h-hidden', false);

			}

			if (item.Pruefzeiten) {
				$listItemClone.find('.js-testtimes-wrapper').toggleClass('h-hidden', false);
				$listItemClone.find('.js-testtimes').text(item.Pruefzeiten);
			}

			if (item.TerminverinbarungNotwendig) {
				$listItemClone.find('.js-appointment-wrapper').toggleClass('h-hidden', false);
				const appointmentText = item.TerminverinbarungNotwendig === '1' ? this._getLabel('Yes') : this._getLabel('No');
				$listItemClone.find('.js-appointment').text(appointmentText);
			}

			if (item.KostenpflichtigePruefungen) {
				$listItemClone.find('.js-paidservices-wrapper').toggleClass('h-hidden', false);
				$listItemClone.find('.js-paidservices').html(item.KostenpflichtigePruefungen);
			}

			if (item.KostenfreiePruefungen) {
				$listItemClone.find('.js-freeservices-wrapper').toggleClass('h-hidden', false);
				$listItemClone.find('.js-freeservices').html(item.KostenfreiePruefungen);

			}

			if (item.WeitereInformationen) {
				$listItemClone.find('.js-is-info').toggleClass('h-hidden', false);
				$listItemClone.find('.js-info').text(item.WeitereInformationen);
			}


			$listItemClone.find(".js-calcroute").attr('href',
				this._getRouteUrl(item.Plz, item.Ort, item.Strasse, item.x, item.y)
			);

			$listItemClone.attr('data-id', item.PoiId);
			$listItemClone.on('click', this._handleListItemClick.bind(this, $listItemClone));

			$(this.$ctx.find(headlineClass)).toggleClass('h-hidden', false);
			this.$ctx.find(listClass)[0].append($listItemClone[0]);

			this._mapResultCache.push({
				id: item.PoiId,
				html: $listItemClone,
				pos: { x: item.x, y: item.y },
				tooltip: addressContent.html(),
				icon: $icon
			});
		},

		_handleListItemClick: function _handleListItemClick($listItem) {
			if (!this.map) {
				this._renderMap();
			}
			this.currentActivePoi = $listItem;
			this.currentItem = null;
			const result = this._mapResultCache.filter(item => {
				return item.id === parseInt(this.currentActivePoi.attr('data-id'), 10);
			});
			if (result && result.length > 0) {
				this.currentItem = result[0];
				T.Utils.Mapbox.setZoom(this.map, this._getZoomForPermiter(1));
				this._centerMap();
			}
		},

		getTypeInfo: function getTypeInfo(item) {
			let pinClass,
				pinId,
				listClass,
				testType,
				headlineClass;

			switch (item.Art) {
				case "1":
					pinClass = 'mm-pin-orange';
					pinId = 'mapsIconOrange';
					listClass = '.mm-result-list-testing-services';
					testType = 'Prüfzentrum';
					headlineClass = '.js-result-list-testing-services-wrapper';
					break;
				case "2":
					pinClass = 'mm-pin-lightblue';
					pinId = 'mapsIconBlue';
					listClass = '.mm-result-list-mobile-testing-services';
					testType = 'Mobile Prüfdienste';
					headlineClass = '.js-result-list-mobile-testing-servicesices-wrapper';
					break;
				case "3":
					pinClass = 'mm-pin-yellow';
					pinId = 'mapsIconYellow';
					listClass = '.mm-result-list-contractexperts';
					testType = 'Vertragssachverständige';
					headlineClass = '.js-result-list-contractexperts-wrapper';
					break;
				case "4":
					pinClass = 'mm-pin-green';
					pinId = 'mapsIconGreen';
					listClass = '.mm-result-list-cooperationpartner';
					testType = 'Kooperationspartner';
					headlineClass = '.js-result-list-cooperationpartner-wrapper';
					break;
				default:
					pinClass = '';
					pinId = '';
					listClass = '';
					testType = '';
					headlineClass = '';
			}

			return { pinClass, pinId, listClass, headlineClass, testType };

		},

		_createMapIfNeeded: function _createMapIfNeeded(center, whenReady) {
			if (!this.map) {
				this._loadingMap = true;
				this.map = T.Utils.Mapbox.renderMap(this._getConfigMapStyle(), center, {
					apimKey: T.Utils.Application.getApiMSubscriptionKey()
				}, this.perimeter ? this._getZoomForPermiter(this.perimeter) : this._getConfigMapZoom());
				if (this.map) {
					this.map.on("load", this._hanldeMapLoad.bind(this, whenReady));
				}
			} else if ('function' === typeof whenReady) {
				if (this._loadingMap) {
					// when filter is set on page load and wants to select a location, map is not yet ready
					const mapsCheck = setInterval(() => {
						if (!this._loadingMap) {
							clearInterval(mapsCheck);
							whenReady();
						}
					}, 300);
				}
				else {
					whenReady();
				}
			}
		},
		_hanldeMapLoad: function _hanldeMapLoad(whenReady) {
			delete this._loadingMap;
			this._adjustMapControls();
			if ('function' === typeof whenReady) {
				whenReady(true);
			}
		},
		_adjustMapControls: function _adjustMapControls() {
			T.Utils.Mapbox.showNavigationControl(this.map);
		},

		_getConfigMapStyle: function _getConfigMapStyle() {
			return this._config.mapStyle;
		},

		_getConfigMapZoom: function _getConfigMapZoom() {
			return this._config.mapZoomFactor;
		},

		_mercatorToLocation: function _mercatorToLocation(point) {
			return T.Utils.Geo.coordToLocation(T.Utils.Geo.mercatorToLonLat(point));
		},

		_getCurrentTooltip: function _getCurrentTooltip(item) {
			return T.Utils.Helper.htmlToText(item.tooltip);
		},

		_setBounds: function _setBounds() {
			const lng = this._mapResultCache.map((item) => {
				return this._mercatorToLocation(item.pos)[0];
			});
			const lat = this._mapResultCache.map((item) => {
				return this._mercatorToLocation(item.pos)[1];
			});

			this._min_coords = {
				lat: Math.min.apply(null, lat),
				lng: Math.min.apply(null, lng)
			};
			this._max_coords = {
				lat: Math.max.apply(null, lat),
				lng: Math.max.apply(null, lng)
			};
			this._bounds = [[this._min_coords.lng, this._min_coords.lat], [this._max_coords.lng, this._max_coords.lat]];
		},

		_renderMap: function _renderMap() {
			const loc = this._mercatorToLocation(this._mapResultCache[0].pos);
			this._setBounds();
			this._createMapIfNeeded(loc, (initialLoad) => {
				if (!initialLoad) {
					T.Utils.Mapbox.removeAllPoints(this.map);
				}
				this._mapResultCache.forEach((result) => {
					result.mapsReference = T.Utils.Mapbox.createPoint(this.map, this._mercatorToLocation(result.pos), this._getCurrentTooltip(result) || null, result.icon, this._handleResultClick.bind(this, result.html), this._popupConfig);
				});
				if (this._mapResultCache && this._mapResultCache.length > 1) {
					T.Utils.Mapbox.fitBounds(this.map, this._bounds, {
						padding: { top: 25, bottom: 40, left: 25, right: 50 }
					});
				} else {
					this.currentItem = this._mapResultCache[0];
					T.Utils.Mapbox.setZoom(this.map, this._getZoomForPermiter(1));
					this._centerMap();
				}
			});
		},

		_handleResultClick: function _handleResultClick(element) {
			this.currentActivePoi = element;
			this.currentItem = null;
			const result = this._mapResultCache.filter(item => {
				return item.id === parseInt(this.currentActivePoi.attr('data-id'), 10);
			});
			if (result && result.length > 0 && this.map && (!T.Utils.Helper.isSmartphone() || this.$switchMapClass.hasClass("is-active"))) {
				this.currentItem = result[0];
				this._centerMap();
				this._scrollToListItem();
			}

		},

		_scrollToListItem: function _scrollToListItem() {
			$('html, body').animate({
				scrollTop: this.$ctx.find(`li[data-id="${this.currentItem.id}"]`).offset().top - 150
			}, 800);
		},

		_centerMap: function _centerMap(elem) {
			const loc = this._mercatorToLocation(
				elem ? elem.pos : this.currentItem.pos
			);
			T.Utils.Mapbox.setCenter(this.map, loc, !T.Utils.Helper.isSmartphone() && !T.Utils.Adaptive.isSlowNetwork());
		},

		_getZoomForPermiter: function _getZoomForPermiter(permiter) {
			switch (permiter) {
				case 1:
					return 14;
				case 5:
					return 12;
				case 10:
					return 10;
				case 20:
					return 8;
				case 50:
					return 6;
				default:
					return this._getConfigMapZoom();
			}
		},

		_showResultWrapper: function _showResultWrapper(state) {
			this.$ctx.find('.js-testingresults').removeAttr('data-t-id');
			this.$ctx.find('.mm-map-image').toggleClass('h-hidden', !state);
		},

		_showMapWrapper: function _showMapWrapper(state) {
			this.$ctx.find('.js-testingresults').toggleClass('h-visuallyhidden', !state);
			this.$mapWrapperClass.toggleClass("h-hidden", !state);
		},

		_showNoBackendHint: function _showNoBackendHint(state) {
			this.$ctx.find(".js-nobackend").toggleClass("h-hidden", !state);
			this.$mapWrapperClass.toggleClass("h-hidden", true);
			this.$ctx.find(".js-noresult").toggleClass("h-hidden", true);
		},

		_showNoResultHint: function _showNoResultHint(state) {
			this.$ctx.find(".js-noresult").toggleClass("h-hidden", !state);
			this.$ctx.find(".js-withresult").toggleClass("h-hidden", state);
			this.$ctx.find(".js-nobackend").toggleClass("h-hidden", true);
			this.$mapWrapperClass.toggleClass("h-hidden", false);
		},

		_setResultCount: function _setResultCount(count) {
			this.$ctx.find(".js-result-count").text(`(${count})`);
		},

		_getRouteUrl: function _getRouteUrl(plz, city, street, east, north) {
			const destination = {
				postCode: plz,
				location: city,
				street: street,
				x: east,
				y: north
			};
			const start = this.userPosition ? {
				name: this.userPosition.Bezeichnung,
				postCode: this.userPosition.PLZ,
				location: this.userPosition.Ort,
				street: this.userPosition.Strasse,
				x: this.userPosition.X,
				y: this.userPosition.Y
			} : undefined;
			return T.Utils.Map.createRouteUrl(this._config.adacMapsBaseUrl, destination, start);
		},


		_getLabel: function _getLabel(key, scope) {
			const dscope = scope ? (this._labels || {})[scope] : this._labels;
			return (dscope || {})[key];
		}

	});
}(jQuery));
