/// <reference path="../../../../../assets/typings/terrific-ext.d.ts" />
/// <reference path="../../../../../assets/js/utils/T.Utils.Membership.js" />

'use strict';
(function ($) {
	/**
	 * Membership Contract Insights
	 *
	 * @author Dmitri Zoubkov <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class MyAdacMembershipContent-ContractInsight
	 * @extends T.Module.MyAdacMembershipContent
	 */
	T.Module.MyAdacMembershipContent.ContractInsight = T.createDecorator({
		_partnerContributionCodes: ["PAR", "F1U", "F1", "JUV", "KIN"],
		/**
		 * start function
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this._parent.start(resolve);
			T.Utils.Application.init();
			this.readConfiguration();
			this.addEventListeners();
			const emitter = new T.EventEmitter(this._sandbox);
			emitter.on('t.sync', () => {
				if (this.needsUpdating()) {
					this.updateContent();
				}
			});
		},

		readConfiguration: function readConfiguration() {
			this._labels = this.$ctx.data("labels") || {};
			this._apifamily = this.$ctx.data("apiurl");
			this._backend = this.$ctx.data("backend") || T.Utils.Application.getApi();
			this._apiidentity = this.$ctx.data("identity");
		},

		addEventListeners: function addEventListeners() {
			$(document).on("identityloaded", this.onIdentityChanged.bind(this));
			$(document).on("identitylogout", this.onIdentityChanged.bind(this));
		},

		startLoader: function startLoader() {
			T.Utils.View.startLoader();
		},

		stopLoader: function stopLoader() {
			T.Utils.View.stopLoader();
			this._events.emit('MyAdacDashboardList.content-changed', this.$ctx);
		},

		onIdentityChanged: function onIdentityChanged() {
			if (this.needsUpdating()) {
				this.updateContent();
			}
		},

		needsUpdating: function needsUpdating() {
			return !this._dataLoaded && !this._dataLoading;
		},

		updateContent: function updateContent() {
			this._dataLoading = true;
			this.startLoader();
			if (this._apiidentity) {
				// pure lokal mock without any APIL
				T.Utils.Ajax.fragment({
					url: T.Utils.Helper.appendURIPath(this._backend, this._apiidentity),
					type: 'GET'
				}, this.handleUserCallback.bind(this), this.errorCallback.bind(this));
			} else {
				T.Utils.Auth.getResolvedIdentity(this.handleUserCallback.bind(this));
			}
		},

		handleUserCallback: function handleUserCallback(user) {
			this._user = user;
			this.getUserFamilyData();
			this.getUserBillingDetails();
			T.Utils.Membership.getTaxInformationForUser(`/${this.$ctx.data('api-taxinformation')}`, user, this._taxInformationSuccessCallback.bind(this), () => { });
		},
		getUserBillingDetails: function getUserBillingDetails() {
			T.Utils.Auth.getBearerToken((token) => {
				const req = {
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.$ctx.data("api-listemglrechnungen")),
					type: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				};
				T.Utils.Ajax.fragment(req, (data) => {
					if (data && data.Success) {
						if (data.Data.TotalCount > 0) {
							$(".js-billing-pdf > div").addClass("h-hidden");
							$(".js-billing-pdficon").removeClass("h-hidden");
						}
						if (data.Data.TotalCount === 1) {
							$(".js-billing-pdf").attr({ "name": data.Data.Documents[0].Id, "href": "javascript:void(0);" });
							$(".js-billing-pdf").on("click", this._generatePdf.bind(this));
						}
					}
					this.stopLoader();
				}, this.requestFailed.bind(this));
			});
		},
		requestFailed: function requestFailed() {
			this.stopLoader();
		},
		_generatePdf: function _generatePdf(e) {
			T.Utils.Auth.getBearerToken((token) => {
				const opt = {
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.$ctx.data("api-lesemglrechnung") + e.currentTarget.name),
					type: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				};
				T.Utils.Ajax.fragment(opt, (data) => {
					if (data && data.Success) {
						const byteCharacters = atob(data.Data);
						const byteNumbers = Array.from(byteCharacters.length);

						for (let i = 0; i < byteCharacters.length; i++) {
							byteNumbers[i] = byteCharacters.charCodeAt(i);
						}

						const byteArray = new Uint8Array(byteNumbers);
						const file = new Blob([byteArray], {
							type: 'application/pdf'
						});
						file.name = e.currentTarget.textContent;
						T.Utils.Helper.saveFileBlob(file);
					}
				});
			});
		},
		_taxInformationSuccessCallback: function _taxInformationSuccessCallback(result) {
			if (result.success && result.data) {
				this.$ctx.find(".js-tax-information-text").text(result.data);
				this.$ctx.find(".js-tax-information").toggleClass("h-hidden", false);
			}
		},

		getUserFamilyData: function getUserFamilyData() {
			T.Utils.Auth.getBearerToken((token) => {
				this._dataLoading = true;

				const options = {
					url: T.Utils.Helper.appendURIPath(this._backend, this._apifamily),
					type: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				};
				T.Utils.Ajax.multifragment(`true:${options.type}:${this._apifamily}`, options, this.successCallback.bind(this), this.errorCallback.bind(this));

			});
		},

		successCallback: function successCallback(jqxhr) {
			if (this._user) {
				this._user.UserData.Mitgliedschaft = jqxhr.Data.Mitgliedschaft;
				this._user.UserData.PartnerObj = jqxhr.Data.Partner;
				this._user.UserData.KinderList = jqxhr.Data.Kinder;
				this._user.UserData.JungeErwachsene = jqxhr.Data.JungeErwachsene;

			}
			this.updateView(this._user);
		},

		errorCallback: function errorCallback() {
			this.updateView(this._user);
		},

		updateView: function updateView(user) {
			delete this._user;
			if (user && user.Success) {
				const company = user.UserData.Firma;
				if (company) {
					this.$ctx.find(".js-firma").html(company);
					this.$ctx.find(".js-person").html(user.UserData.Beguenstigter);
				} else {

					this.$ctx.find(".js-salutation").text(this.getLabel(user.UserData.Anrede, 'salutation'));
					this.$ctx.find(".js-name").text(`${user.UserData.Vorname} ${user.UserData.Nachname}`);
				}
				this.showListItems(['.js-firma', '.js-person'], company);
				this.showListItems(['.js-salutation', '.js-name'], !company);

				this.$ctx.find(".js-mglno").text(user.MglNo || '');

				const membership = user.UserData.Mitgliedschaft;
				let show = membership && membership.TarifInformation;
				if (show) {
					const rateName = this.getLabel(membership.TarifInformation.TarifCodeId, 'ratenamemappings') || membership.TarifInformation.Beschreibung;
					this.$ctx.find(".js-rate").text(`ADAC ${rateName}`);
					if (this._partnerContributionCodes.includes(membership.TarifInformation.TarifCode)) {
						this.$ctx.find(".js-information-text").removeClass('h-hidden');
						const priceContainer = this.$ctx.find(".js-price");
						if (priceContainer.length) {
							priceContainer.addClass("h-hidden");
							priceContainer.closest(".js-price-wrapper").addClass("h-hidden");
						}
					} else {
						this.$ctx.find(".js-billing").removeClass('h-hidden');
					}
					this.$ctx.find(".js-price").text(`${$.number(membership.TarifInformation.Beitrag, 2)} ${this.getLabel('currency')}`);
				}
				this.showListItems(['.js-rate', '.js-price'], show);

				show = membership && membership.Beginn;
				if (show) {
					this.$ctx.find('.js-membership-begin').text(moment(membership.Beginn).format('L'));
				}
				this.showListItems(['.js-membership-begin'], show);

				this.generareProtectedList(user.UserData);

				const isPremium = this.userIsPremium(user);
				if (isPremium) {
					this.$ctx.find(".js-premium").removeClass('h-hidden');
				}

				T.Utils.Membership.showElementsByUserGroup(this.$ctx, user);
			}

			this._dataLoading = false;
			this._dataLoaded = true;
			this.stopLoader();
		},

		generareProtectedList: function generareProtectedList(userData) {
			if (!this._protectedTpl) {
				this._protectedTpl = this.$ctx.find('.js-protected-tpl');
				this._protectedContainer = this._protectedTpl.parent();
				this._protectedTpl = this._protectedTpl.clone();
				this._protectedTpl.removeClass('js-protected-tpl h-hidden');
				this._protectedTpl.addClass('js-protected');
			}
			this.$ctx.find('.js-protected, .js-protected-tpl').remove();

			let hasAny = false;
			if (userData.PartnerObj) {
				this.makeProtectedEntry(userData.PartnerObj, 'Partner');
				hasAny = true;
			}
			if (userData.KinderList && userData.KinderList.length) {
				for (let i = 0; i < userData.KinderList.length; i++) {
					this.makeProtectedEntry(userData.KinderList[i], 'Kind');
				}
				hasAny = true;
			}
			if (userData.JungeErwachsene && userData.JungeErwachsene.length) {
				for (let i = 0; i < userData.JungeErwachsene.length; i++) {
					this.makeProtectedEntry(userData.JungeErwachsene[i], 'JungeErwachsener');
				}
				hasAny = true;
			}
			this.showListItems(['.js-protected'], hasAny);
		},

		makeProtectedEntry: function makeProtectedEntry(entry, type) {
			const $elm = this._protectedTpl.clone();
			$elm.text(`${entry.Vorname} ${entry.Nachname} ${this.getLabel(type, 'protectedpersons')}`);
			this._protectedContainer.append($elm);
			return $elm;
		},

		userIsPremium: function userIsPremium(user) {
			return this.getMembershipProperty(user, 'PremiumMitglied', false);
		},

		getLabel: function getLabel(key, scope) {
			const map = scope ? this._labels[scope] : this._labels;
			return (map || {})[key];
		},

		showListItemIfNeeded: function showListItemIfNeeded(valueElm, parents) {
			parents = parents ? parents : '.mm-list-wrapper';

			valueElm.parents(parents).toggleClass('h-hidden', !valueElm.text());
		},

		showListItems: function showListItems(selectors, show, parents) {
			parents = parents ? parents : '.mm-list-wrapper';
			for (let i = 0; i < selectors.length; i++) {
				this.$ctx.find(selectors[i]).parents(parents).toggleClass('h-hidden', !show);
			}
		},

		getMembershipProperty: function getMembershipProperty(user, propName, defaultVal) {
			if (user && user.UserData && user.UserData.Mitgliedschaft) {
				return user.UserData.Mitgliedschaft[propName];
			}
			return defaultVal;
		}

	});
}(jQuery));