(function ($) {
	'use strict';

	/**
	 * BasicInputRadio module implementation.
	 *
	 * @author  <w.eberts@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputRadio
	 * @extends T.Module
	 */
	T.Module.BasicInputRadio.Setbyurl = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$input: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$input = this.$ctx.children('input').eq(0);

			this._parent.start(resolve);

			this.checkForUrlParameter();
		},

		checkForUrlParameter() {
			if (location.search.match(/setRadio/)) {
				const setRadio = location.search.match(/setRadio=(\d)/)[1];

				S.Utils.delayed('setUrl', 200, () => {
					this.$ctx.parent().find('.a-basic-input-radio[data-t-decorator=setbyurl]').eq(setRadio).find('input').trigger('click');
				});
			}
		}

	});
}(jQuery));
