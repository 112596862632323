'use strict';
(function ($) {
	/**
	 * MetaNavigation module implementation.
	 *
	 * @author Igor Iric <igor.iric@namics.com>
	 * @namespace T.Module
	 * @class MetaNavigation
	 * @extends T.Module
	 */
	T.Module.MetaNavigation = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		$loginLink: null,
		registerMemberClass: '.js-register-member',
		dataValue: 'data-value',

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.$loginLink = this.$ctx.find('.js-login-link');
			if (!T.Utils.Auth.state) {
				T.Utils.Auth.state = $('input[name=__RequestVerificationToken]').val();
			}
			this._readConfig();
			this._registerListeners();
			this._loginHandler();
			resolve();
		},


		_readConfig: function _readConfig() {
			this.$logoutForm = this.$ctx.find('#logout');
			this.$btnLogin = this.$loginLink;
			this.$btnLogout = this.$ctx.find('.js-logout-btn');
			this.$btnRegister = this.$ctx.find(this.registerMemberClass);
		},


		_loginHandler: function _loginHandler() {
			const pathIndex = window.location.href.indexOf('?');
			this.basePath = window.location.href.substr(0, pathIndex) || window.location.href;

			if (T.Utils.Helper.isExperienceEditor() || T.Utils.Helper.isPreviewMode()) {
				this.$btnLogin.on('click', (e) => {
					e.preventDefault();
					// eslint-disable-next-line no-alert
					alert("Login wird im Edit- oder Preview-Modus nicht unterstützt, rufen Sie die Seite mit sc_mode=normal auf und loggen Sie sich dort ein");
					return false;
				});
				return;
			}
			this.$btnLogin.on('click', (ev) => {
				ev.preventDefault();
				T.Utils.Auth.authorize();
			});
			T.Utils.Auth.getResolvedIdentity(this._handleIdentityLoaded.bind(this));

		},



		_registerListeners: function _registerListeners() {
			$(document).on('identityloaded', this._handleIdentityLoaded.bind(this));
			$(document).on("identitylogout", this._handleIdentityLoaded.bind(this));
		},

		_handleIdentityLoaded: async function _handleIdentityLoaded() {
			this.$btnRegister.attr(
				'href',
				await T.Utils.Auth.makeRegisterURL()
			);
			this.$logoutForm.attr(
				'action',
				await T.Utils.Auth.makeLogoutURL()
			);
			this.$btnLogout.on('click', async (e) => {
				e.preventDefault();
				await T.Utils.Auth.logout();
				this.$ctx.find('form#logout').submit();

			});
			this._adjustInterface(T.Utils.Auth.getLocalIdentity());
		},

		_adjustInterface: async function _adjustInterface(identity) {
			if (identity) {
				if (identity.Firma) {
					this.$ctx.find('.js-user-name').html(identity.Firma);
				} else {
					this.$ctx.find('.js-user-name').html(`${identity.Vorname} ${identity.Nachname}`);
				}
			}

			const loginStatus = await T.Utils.Auth.isLoggedIn();
			this.$ctx.find('.js-unsigned-links').toggleClass("h-hidden", !!loginStatus);
			this.$ctx.find(this.registerMemberClass).toggleClass("h-hidden", !!loginStatus);
			this.$loginLink.toggleClass("h-hidden", !!loginStatus);
			this.$ctx.find('.js-loggedin-icon').toggleClass("h-hidden", !loginStatus);
			this.$ctx.find('.js-loggedout-icon').toggleClass("h-hidden", !!loginStatus);

			this.$ctx.find('.js-signedin-greet').toggleClass("h-hidden", !loginStatus);
			this.$ctx.find('.js-signedin-links').toggleClass("h-hidden", !loginStatus);
			this.$ctx.find('.js-signedin-logout').toggleClass("h-hidden", !loginStatus);

		}
	});
}(jQuery));
