(function ($)
{
    'use strict';

    /**
     * break decorator implementation for the BasicTable module.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module.BasicTable
     * @class Break
     * @extends T.Module
     */
    T.Module.BasicTable.Break = T.createDecorator({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $thead: null,

        /** @type {Object} */
        $th: [],

        /** @type {jQuery} */
        $tbody: null,

		classToggleController: 'js-toggle-controller',
		classToggleContent: '.mm-toggle-row-content',


        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$thead = this.$ctx.find('thead').eq(0);
            this.$th = this.$ctx.find('> thead > tr > th');
            this.$tbody = this.$ctx.find('tbody').eq(0);

            const that = this;

            // set classes
            this.setClasses();

            if (!S.Utils.Helper.mq('tablet').matches){
                // rebuild table
                this.rebuildTable('phone');

                // check break row number
                this.checkBreakRowNumber();

                // init toggle
                if (this.$ctx.find('th[data-toggle]').length){
                    this.initToggle();
                }
            }
            S.Utils.Helper.mq('tablet').addListener(function (mq) {
                if (!mq.matches) {
                    // rebuild table
                    that.rebuildTable('phone');

                    // check break row number
                    that.checkBreakRowNumber();

                    // init toggle
                    if (that.$ctx.find('th[data-toggle]').length){
                        that.initToggle();
                    }
                }else{
                    // rebuild table
                    that.rebuildTable('tablet');
                }
            });

            this._events.on('BasicKeywordfilter.filterLayerTrue', function(){
                that.updateAfterFilterBreak();
            });

            this._events.on('BasicKeywordfilter.filterLayerFalse', function(){
                that.updateAfterFilterBreak();
            });

            this._events.on('BasicKeywordfilter.filterDoubleSlider', function(){
                that.updateAfterFilterBreak();
            });

            this._events.on('BasicKeywordfilter.filterContentBySearchFinished', function(){
                that.updateAfterFilterBreak();
            });

            this._events.on('BasicKeywordfilter.reset', function(){
                that.updateAfterFilterBreak();
            });

			/* for example done by Namics after change search params and click update btn => table body renews */
			this._events.on('BasicTable.ChangedContent', function ()
			{
				that.updateAfterFilterBreak();
			});


            this._parent.start(resolve);
        },

        updateAfterFilterBreak: function (){
            const that = this,
				tableWrap = '.js-basic-table-wrap';

            if (that.$ctx.closest(tableWrap).length && that.$ctx.closest('.js-basickeywordfilter-result').length && !S.Utils.Helper.mq('tablet').matches)
            {
                that.$ctx.closest(tableWrap).addClass('is-loading');

                setTimeout(() => {
                    that.rebuildTable('phone');
                    that.$ctx.closest(tableWrap).removeClass('is-loading');
                    that.initToggle();
                }, 300);
            }
        },

        /**
         * rebuildTable
         *
         * @param {String} mod
         */
		// eslint-disable-next-line
        rebuildTable: function (mod){ //NOSONAR too long
            const that = this,
				lightbox = '.js-lightbox';

            // reset html
            this.$ctx.find('.mm-toggle-row').remove();
            this.$ctx.find('.is-active').removeClass('is-active');

            if (mod === 'phone'){
                if (this.$ctx.hasClass('m-basic-table--ruf-weather'))
                {
                    this.$th.each(function(indexHead, itemHead){
                        if (!$(itemHead).data('toggle')) {
                            $(itemHead).hide();
                        }

                        that.$tbody.children().each(function(indexBody, itemBody){
                            $(itemBody).children().eq(indexHead).hide();
                        });
                    });

                    // build html
                    this.$th.each(function(indexHead, itemHead){
                        if (!$(itemHead).data('toggle')) {
                            const toggleRow = $('<tr class="js-weather" />');
                            toggleRow.appendTo(that.$tbody);

                            const toggleRowTd = $('<td class="js-toggle-controller" />');
                            toggleRow.append(toggleRowTd);
                            toggleRowTd.html(that.$th.eq(indexHead).text());

                            const mainRow = $('<tr class="mm-toggle-row" />');
                            mainRow.insertAfter(toggleRow);

                            const mainRowTd = $('<td />');
                            mainRow.append(mainRowTd);

                            const mainRowDiv = $('<div class="mm-toggle-row-content cf" />');
                            mainRowTd.append(mainRowDiv);

                            const mainRowTable = $('<table class="m-basic-table js-table" />');
                            mainRowDiv.append(mainRowTable);

                            const mainRowTableBody = $('<tbody />');
                            mainRowTable.append(mainRowTableBody);

                            that.$tbody.find('> tr:not(.js-weather, .mm-toggle-row, .js-filter-mismatched)').each(function(indexBody, itemBody) {
                                const row = $('<tr />');
                                mainRowTableBody.append(row);

                                const cellHead = $(itemBody).children().eq(0).clone(true).show();
                                row.append(cellHead);

                                const cellContent = $(itemBody).children().eq(indexHead).clone(true).show();
                                row.append(cellContent);
                            });

                            // add close btn
                            mainRowDiv.append('<div class="mm-toggle-close cf"><button class="a-basic-link a-basic-link--right">Schlie&szlig;en</button></div>');
                            const toggleClose = mainRowDiv.find('.mm-toggle-close > button').eq(0);

                            toggleClose.off().on('click', function(){
                                if (toggleRowTd.hasClass('is-active')){
                                    toggleRowTd.trigger('click');
                                }
                            });
                        }
                    });

                    setTimeout(() =>
                    {
                        this.$ctx.addClass('m-basic-table--white');
                    }, 300);
                }
                else
                {
                    // hide th/td
                    this.$th.each(function(indexHead, itemHead){
                        if (!$(itemHead).data('toggle')){
                            $(itemHead).hide();

                            that.$tbody.children().each(function(indexBody, itemBody){
                                $(itemBody).children().eq(indexHead).hide();
                            });
                        }
                    });

                    // build html
                    this.$tbody.children(':visible').each(function(indexBody, itemBody){
                        if (!$(itemBody).hasClass('mm-subheading'))
                        {
                            const mainRow = $('<tr class="mm-toggle-row" />');
                                mainRow.insertAfter($(itemBody));

							const mainRowTd = $('<td />');
                                mainRow.append(mainRowTd);

							const mainRowDiv = $('<div class="mm-toggle-row-content cf" />');
                                mainRowTd.append(mainRowDiv);

							const mainRowTable = $('<table class="m-basic-table js-table" />');
                                mainRowDiv.append(mainRowTable);

							const mainRowTableBody = $('<tbody />');
                                mainRowTable.append(mainRowTableBody);

                            // clone lightbox layer for smartphone
                            if (!S.Utils.Helper.mq('tablet').matches && $(itemBody).find(lightbox).length){
                                    const lightboxClone = $(itemBody).find(lightbox).clone(true);
                                    lightboxClone.html(`Erläuterungen zu „${  lightboxClone.text()  }“ `);
                                    lightboxClone.appendTo(mainRowDiv);
                            }

                            // check break type
                            if (that.$ctx.data('breaktype')){

                                // vertical setting
                                if (that.$ctx.data('breaktype') === 'vertical'){
                                    for (let i = 0; i < 2; i++){

                                        const row = $('<tr />');
                                        mainRowTableBody.append(row);

                                        that.$th.each(function(indexHead, itemHead){
                                            if (!$(itemHead).data('toggle') && $(itemHead).data('hide') !== 'phone'){
                                                if (i === 0){
                                                    // head
                                                    row.append($(itemHead).clone(true).show());
                                                }else{
                                                    // content
                                                    row.append($(itemBody).children().eq(indexHead).clone(true).show());
                                                }
                                            }
											// eslint-disable-next-line
											else if($(itemHead).data('toggle') == true){ //NOSONAR
                                                const toggleController = $(itemBody).children().eq(indexHead);
                                                toggleController.addClass(that.classToggleController);

                                                // set/reset js-lightbox link
                                                if (!S.Utils.Helper.mq('tablet').matches){
                                                    // smartphone
                                                    toggleController.find(lightbox).off();
                                                }

                                                S.Utils.Helper.mq('tablet').addListener(function (mq) {
                                                    if (!mq.matches) {
                                                        // smartphone
                                                        toggleController.find(lightbox).off();
                                                    }else{
                                                        // tablet + desktop
                                                        const idSelector = toggleController.find(lightbox).attr('href');
                                                        toggleController.find(lightbox).magnificPopup({
                                                            fixedContentPos: true,
                                                            callbacks: {
                                                                beforeOpen: function() {
                                                                     // setup classes
                                                                     $(idSelector).addClass("ll-lightbox-inner mfp-with-anim");
                                                                     this.st.mainClass = `${this.st.el.attr('data-effect')} l-lightbox`;
                                                                }
                                                            },
                                                            items: {
                                                                src: $(idSelector),
                                                                type: 'inline'
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                }
                            }else{

                                // horizontal
                                that.$th.each(function(indexHead, itemHead){
                                    if (!$(itemHead).data('toggle') && $(itemHead).data('hide') !== 'phone'){
                                        const row = $('<tr />');
                                        mainRowTableBody.append(row);

                                        const cellHead = $(itemHead).clone(true).show();
                                        row.append(cellHead);

                                        const cellContent = $(itemBody).children().eq(indexHead).clone(true).show();
                                        row.append(cellContent);

                                    }
									// eslint-disable-next-line
									else if($(itemHead).data('toggle') == true){ //NOSONAR
                                        const toggleController = $(itemBody).children().eq(indexHead);
                                        toggleController.addClass(that.classToggleController);

                                        // set/reset js-lightbox link
                                        if (!S.Utils.Helper.mq('tablet').matches){
                                            // smartphone
                                            toggleController.find(lightbox).off();
                                        }

                                        S.Utils.Helper.mq('tablet').addListener(function (mq) {
                                            if (!mq.matches) {
                                                // smartphone
                                                toggleController.find(lightbox).off();
                                            }else{
                                                // tablet + desktop
                                                const idSelector = toggleController.find(lightbox).attr('href');
                                                toggleController.find(lightbox).magnificPopup({
                                                    fixedContentPos: true,
                                                    callbacks: {
                                                        beforeOpen: function() {
                                                             // setup classes
                                                             $(idSelector).addClass("ll-lightbox-inner mfp-with-anim");
                                                             this.st.mainClass = `${this.st.el.attr('data-effect')  } l-lightbox`;
                                                        }
                                                    },
                                                    items: {
                                                        src: $(idSelector),
                                                        type: 'inline'
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }

                            // toggle controller
                            const toggleController = $(itemBody).find('.js-toggle-controller').eq(0);

                            // if toggletext, clone and add to toggle content
                            if ($(itemBody).find('.js-toggletext-content').length){
                                $(itemBody).next().find(that.classToggleContent).prepend($(itemBody).find('.js-toggletext-content').eq(0).clone());
                            }

                            // add close btn
                            $(itemBody).next().find(that.classToggleContent).append('<div class="mm-toggle-close cf"><button class="a-basic-link a-basic-link--right">Schlie&szlig;en</button></div>');
                            const toggleClose = $(itemBody).next().find('.mm-toggle-close > button').eq(0);

                            if(that.$ctx.hasClass('m-basic-table--detail')){
                                const href = $(itemBody).data('layer-href');
                                $(itemBody).next().find('.mm-toggle-close').append(`<a class="a-basic-link a-basic-link--left js-lightbox" href="#${ href }">Details</a>`);
                                S.Utils.Lightbox.init("js-lightbox", $(itemBody).next());
                            }

                            toggleClose.off().on('click', function(){
                                if (toggleController.hasClass('is-active')){
                                    toggleController.trigger('click');
                                }
                            });

                            // check if colspans have to set
                            $(itemBody).next().find('.mm-colspan-full').each(function(index, item){
                                $(item).attr('colspan', '2');
                                $(item).prev().addClass('mm-colspan-full-sibling');
                            });
                        }
                    });
                }

				that._events.emit('basicTable.rebuildedPhone');

            }else{

                // show th/td
                this.$th.each(function(indexHead, itemHead){
					if(!$(itemHead).hasClass('h-none'))
					{
						$(itemHead).css('display', 'table-cell');
					}

                        that.$tbody.children().each(function(indexBody, itemBody){
                            // to remove the toggle class, and it´s backgroundstyle.
                            $(itemBody).children().removeClass(that.classToggleController);
                            $(itemBody).children().eq(indexHead).css('display', 'table-cell');
                        });
                    //}
                });

                // reset mobile style of tbody elems
                this.$tbody.find('td.js-toggle-controller').each( (index, itemTD) => {
                    $(itemTD).removeClass(that.classToggleController);
                });

                // remove special weatcher generatet tr
                this.$tbody.find('tr.js-weather').remove();

                // reset --white mode
                this.checkRowNumber();
            }
        },


        /**
         * checkBreakRowNumber
         *
         */
        checkBreakRowNumber: function (){
            if (this.$ctx.find('.mm-toggle-row').first().find('tr').length > 3){
                this.$ctx.find('.mm-toggle-row tr').removeClass('js-white');
            }else{
                this.$ctx.find('.mm-toggle-row tr').addClass('js-white');
            }
        },


        /**
         * setClasses
         *
         */
		// eslint-disable-next-line
        setClasses: function (){ //NOSONAR complexity
            const that = this;

            // check all data-hide set in thead > th
            this.$ctx.find('> thead > tr > th').each(function(index, item){
                const theadIndex = index;

                // data hide
                if ($(item).data('hide')){
                    const dataHide = $(item).data('hide');
                    let setClass = '';
                    if (dataHide.toLowerCase().indexOf("all") >= 0){
                        setClass = 'mm-hide-all';
                    }else{
                        if (dataHide.toLowerCase().indexOf("phone") >= 0){
                            setClass = 'mm-hide-phone';
                        }
                        if (dataHide.toLowerCase().indexOf("tablet") >= 0){
                            setClass += ' mm-hide-tablet';
                        }
                        if (dataHide.toLowerCase().indexOf("desktop") >= 0){
                            setClass += ' mm-hide-desktop';
                        }
                    }
                    $(item).addClass(setClass);
                    that.$tbody.find('> tr').each(function(index, item){
                        $(item).children().eq(theadIndex).addClass(setClass);
                    });
                }

                // data show only content
                if ($(item).data('show-only-content')){
                    const dataShowOnlyContent = $(item).data('show-only-content');
                    let setClass = '';
                    if (dataShowOnlyContent.toLowerCase().indexOf("all") >= 0){
                        setClass = 'mm-hide-all';
                    }else{
                        if (dataShowOnlyContent.toLowerCase().indexOf("phone") >= 0){
                            setClass = 'mm-hide-tablet';
                        }
                        if (dataShowOnlyContent.toLowerCase().indexOf("tablet") >= 0){
                            setClass += ' mm-hide-phone mm-hide-desktop';
                        }
                        if (dataShowOnlyContent.toLowerCase().indexOf("desktop") >= 0){
                            setClass += ' mm-hide-phone mm-hide-tablet';
                        }
                    }

                    $(item).addClass(setClass);
                }

                // data content full
                if ($(item).data('content-full')){
                    const dataContentFull = $(item).data('content-full');
                    let setFullClass = 'mm-colspan-full';
                    let setFullSiblingClass = 'mm-colspan-full-sibling';

                    if (dataContentFull.toLowerCase().indexOf("all") >= 0){
                        setFullClass += ' js-colspan-full-all';
                        setFullSiblingClass += ' js-colspan-full-sibling-all';
                    }else{
                        if (dataContentFull.toLowerCase().indexOf("phone") >= 0){
                            setFullClass += ' js-colspan-full-phone';
                            // for phone no sibling class here, will set by toggling
                            setFullSiblingClass = '';
                        }
                        if (dataContentFull.toLowerCase().indexOf("tablet") >= 0){
                            setFullClass += ' js-colspan-full-tablet';
                            setFullSiblingClass += ' js-colspan-full-sibling-tablet';
                        }
                        if (dataContentFull.toLowerCase().indexOf("desktop") >= 0){
                            setFullClass += ' js-colspan-full-desktop';
                            setFullSiblingClass += ' js-colspan-full-sibling-desktop';
                        }
                    }

                    that.$tbody.find('> tr').each(function(index, item){
                        const colspan = $(item).children().length + 1;

                        $(item).children().each(function(index, item){
                            if (index === theadIndex){
                                $(item).addClass(setFullClass).attr('colspan', colspan);
                            }else{
                                $(item).addClass(setFullSiblingClass);
                            }
                        });
                    });
                }
            });
        },


        /**
         * initToggle
         *
         */
        initToggle: function (){
            const that = this;

            that.$ctx.find('> tbody > tr > td.js-toggle-controller').each(function(index, item){
                $(item).off().on('click', function(){
                    const toggleController = $(this);
                    toggleController.parent().toggleClass('is-active');
                    toggleController.toggleClass('is-active').closest('tr').next().find(that.classToggleContent).slideToggle(function(){
                        // scroll to toggle head
                        if (toggleController.hasClass('is-active')){
                            $('html, body').animate({
                                scrollTop: toggleController.offset().top
                            }, 500);
                        }
                    });
                });

                // a-tags in toggle controler to return false
                $(item).find('a').on('click', (e) => {
                    if(!S.Utils.Helper.mq('tablet').matches)
                    {
                        e.preventDefault();
                    }
                });
            });
        }

    });
}(jQuery));
