'use strict';
(function ($) {
	/**
	 * VehicleDefectSearch module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class VehicleDefectSearch
	 * @extends T.Module
	 */
	T.Module.VehicleDefectSearch = T.createModule({
		_brandFld: 'select[name="Marken"]',
		_modelFld: 'select[name="Modelle"]',
		_otherCriteria: {
			Motorarten: ["apiDefectsForumEngineTypes", "Motorart"],
			Baujahre: ["apiDefectsForumYears", "Baujahr"],
			Baugruppen: ["apiDefectsForumAssemblies", "Baugruppe"],
			Bauteile: ["apiDefectsForumComponents", "Bauteil"]
		},
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this._readConfiguration();
			this._addListeners();
			this._setupValidation();
			this._requestBrands();
			resolve();
		},
		_readConfiguration: function _readConfiguration() {
			this._config = this.$ctx.data("config");
			this._$resultsTemplate = $(this.$ctx.find("#vehicledefect-results-template").html());
		},
		_addListeners: function _addListeners() {
			this.$ctx.find(".js-form").submit(this.submitSearch.bind(this));
			this.$ctx.find(".js-submit-search").on('click', this.submitSearch.bind(this));
			this.$ctx.find(this._brandFld).on('change', this._requestModels.bind(this));
			this.$ctx.find(this._modelFld).on('change', this._requestOtherCriteria.bind(this));
		},
		_setupValidation: function _setupValidation() {
			const valconf = {
				rules: {
					Marken: {
						required: true,
						requiredsel: true
					},
					Modelle: {
						required: true,
						requiredsel: true
					}
				},
				messages: this._config.validationMessages || {}
			};
			T.Utils.Validation.init(this.$ctx, [this.$ctx.find("form")], valconf);
			$.validator.addMethod('requiredsel', function (value) {
				return ("0" !== value);
			});
		},
		_requestBrands: function _requestBrands() {
			this._showError(false);
			this._clearSearchResuls();
			this._clearCriteria(true);
			this._clearDropdown(this._brandFld);
			T.Utils.View.startLoader();
			const url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiDefectsForumBrands);
			const request = {
				method: 'GET',
				url: url
			};
			T.Utils.Ajax.fragment(request, this._handleBrandsSuccess.bind(this), this._handleSearchError.bind(this));

		},
		_handleBrandsSuccess: function _handleBrandsSuccess(data) {
			if (data && data.Data && data.Data.Marken) {
				this._fillSimpleDropdown(this._brandFld, data.Data.Marken);
			}
			T.Utils.View.stopLoader();
		},
		_requestModels: function _requestModels() {
			this._showError(false);
			this._clearSearchResuls();
			this._clearCriteria(true);
			const brand = this._getDropdownVal(this._brandFld);
			if (brand) {
				T.Utils.View.startLoader();
				const url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiDefectsForumModels);
				const request = {
					method: 'GET',
					url: url,
					data: {
						Marke: brand
					}
				};
				T.Utils.Ajax.fragment(request, this._handleModelsSuccess.bind(this), this._handleSearchError.bind(this));
			}
		},
		_handleModelsSuccess: function _handleModelsSuccess(data) {
			if (data && data.Data && data.Data.Modelle) {
				this._fillSimpleDropdown(this._modelFld, data.Data.Modelle);
			}
			T.Utils.View.stopLoader();
		},
		_requestOtherCriteria: function _requestOtherCriteria() {
			this._showError(false);
			this._clearSearchResuls();
			this._clearCriteria();
			const brand = this._getDropdownVal(this._brandFld);
			const model = this._getDropdownVal(this._modelFld);
			if (brand && model) {
				T.Utils.View.startLoader();
				const request = {
					method: 'GET',
					data: {
						Marke: brand,
						Modell: model
					}
				};
				let err;
				let reqCount = 0;
				const checkComplete = () => {
					reqCount--;
					if (0 >= reqCount) {
						if (err) {
							this._handleSearchError(err);
						} else {
							T.Utils.View.stopLoader();
						}
					}
				};
				Object.keys(this._otherCriteria).forEach((key) => {
					reqCount++;
					request.url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config[this._otherCriteria[key][0]]);
					T.Utils.Ajax.fragment(request, (data) => {
						checkComplete();
						if (data && data.Data && data.Data[key]) {
							this._fillSimpleDropdown(this._makeDropdownSelector(key), data.Data[key], "Baujahre" === key ? this._config.maxYears : undefined);
						}
					}, (error) => {
						err = error;
						checkComplete();
					});
				});
			}
		},
		_requestDefectData: function _requestDefectData() {
			this._showError(false);
			const brand = this._getDropdownVal(this._brandFld);
			const model = this._getDropdownVal(this._modelFld);
			if (brand && model) {
				this._clearSearchResuls();
				T.Utils.View.startLoader();
				const payload = {
					Marke: brand,
					Modell: model
				};
				for (const key in this._otherCriteria) {
					const val = this._getDropdownVal(this._makeDropdownSelector(key));
					if (val) {
						payload[this._otherCriteria[key][1]] = val;
					}
				}
				const request = {
					method: 'GET',
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiDefectsForumDeficienciesList),
					data: payload
				};
				T.Utils.Ajax.fragment(request, this._handleDefectDataSuccess.bind(this), this._handleSearchError.bind(this));
			}
		},
		_handleDefectDataSuccess: function _handleDefectDataSuccess(data) {
			if (data && data.Data && data.Data.Maengel && data.Data.Anzahl) {

				const $head = this.$ctx.find(".js-search-head.default");
				$head.find(".js-search-results-count").text(data.Data.Anzahl);
				$head.find(".js-search-results-title").text(`${data.Data.Maengel[0].Marke} ${data.Data.Maengel[0].Modell}`);
				$head.find(".js-search-results-plural").toggleClass("h-hidden", 1 === data.Data.Anzahl);
				$head.find(".js-search-results-singular").toggleClass("h-hidden", 1 < data.Data.Anzahl);
				$head.toggleClass("h-hidden", false);

				const $container = this.$ctx.find(".js-search-results");
				data.Data.Maengel.forEach((item) => {
					const $result = $("<div/>").append(this._$resultsTemplate.clone());
					$result.find(".js-head p").text(`${item.Bauteil} (${item.Marke} ${item.Modell}, ${item.Baujahr}, ${item.Motorart})`);

					for (const key in item) {
						let value = item[key];
						if ("boolean" === typeof (value)) {
							value = value ? this._config.labels.yes : this._config.labels.no;
						} else if ("number" !== typeof (value)) {
							value = value ? T.Utils.Helper.htmlToText(value) : "-";
						}
						$result.find(`.js-result-${key}`).text(value);
					}
					$container.append($result.children());
				});
				$container.toggleClass("h-hidden", false);
				T.Utils.Helper.parseDynamicModules($container);
			} else {
				this._showNoResults(true);
			}
			T.Utils.View.stopLoader();
		},
		_handleSearchError: function _handleSearchError(error) {
			T.Utils.View.stopLoader();
			if (error && 404 === error.status) {
				this._showNoResults(true);
			} else {
				console.error(error);
				this._showError(true);
			}
		},
		_clearCriteria: function _clearCriteria(inclModels) {
			if (inclModels) {
				this._clearDropdown(this._modelFld);
			}
			Object.keys(this._otherCriteria).forEach((key) => {
				this._clearDropdown(this._makeDropdownSelector(key));
			});
		},
		_clearSearchResuls: function _clearSearchResuls() {
			this.$ctx.find(".js-search-head").toggleClass('h-hidden', true);
			const $elm = this.$ctx.find(".js-search-results");
			$elm.toggleClass('h-hidden', true);
			$elm.removeAttr('data-t-name');
			$elm.removeAttr('data-t-id');
			$elm.empty();
		},
		_fillSimpleDropdown: function _fillSimpleDropdown(selector, items, maxItems) {
			const $elm = this.$ctx.find(selector);
			let count = 0;
			items.every((item) => {
				// sometimes API returns "0" in the list
				if ("0" === `${item}`) {
					return true;
				}
				$elm.append($("<option/>").attr('value', item).text(item));
				count++;
				return !maxItems || (count <= maxItems);
			});
			$elm.prop("disabled", false);
			const $values = $elm.find('option[value!="0"]');
			if (1 === $values.length) {
				$elm.val($values.prop('value'));
				$elm.trigger("change");
			}
		},
		_clearDropdown: function _clearDropdown(selector) {
			this.$ctx.find(`${selector} option[value!="0"]`).remove();
			this.$ctx.find(selector).prop("disabled", true);
		},
		_getDropdownVal: function _getDropdownVal(selector) {
			const result = this.$ctx.find(selector).val();
			if ("0" !== `${result}`) {
				return result;
			}
			return null;
		},
		_makeDropdownSelector: function _makeDropdownSelector(name) {
			return `select[name="${name}"]`;
		},
		_showNoResults: function _showNoResults(show) {
			this.$ctx.find(".js-search-head.none").toggleClass("h-hidden", !show);
		},
		_showError: function _showError(show) {
			this.$ctx.find(".js-search-error").toggleClass('h-hidden', !show);
		},
		submitSearch: function submitSearch(evt) {
			if (evt) {
				evt.preventDefault();
			}
			if (this.$ctx.find("form").valid()) {
				this._requestDefectData();
			}
			return false;
		}
	});
})(jQuery);