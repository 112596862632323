(function ($)
{
    'use strict';
    /**
     * BasicCalculatorTiles module implementation.
     *
     * @author Nicole Wewior <n.wewior@mgo360.de>
     * @namespace T.Module
     * @class BasicCalculatorTiles
     * @extends T.Module
     */
    T.Module.BasicCalculatorTiles = T.createModule({
        $ctx: null,
		$prevVisible: null,
		$prev: null,
		$nextVisible: null,
		query: '.m-basic-calculator-tiles:not(.h-transparent):visible',
		$hiddenContent: null,
		$hiddenTrigger: null,
		$tileButtons: null,
		$disabletriggeruncheck: null,
		$disabletriggercheck: null,
		$disabledLayer: null,
		$uncheckLayer: null,
		$checkLayer: null,
		$enableElements: null,
		$disableElements: null,
		$groupUncheck: null,
		$sumLayer: null,

        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$nextVisible = this.$ctx.nextAll(this.query).first();
            this.$prevVisible = this.$ctx.prevAll(this.query).first();
            this.$prev = this.$ctx.prev('.m-basic-calculator-tiles');
			this.$hiddenContent = $('*[data-js-hidden-content]');
			this.$hiddenTrigger = this.$ctx.find('[data-js-condition-context]');
			this.$userDynamicInputField = this.$ctx.find('[data-js-condition-input-target-selector]');
			this.$tileButtons = this.$ctx.find('input[type="radio"]:not([data-js-condition-context])');
			this.$disabletriggeruncheck = this.$ctx.find('input[data-disables-on-uncheck]');
			this.$disabletriggercheck = this.$ctx.find('input[data-disables-on-check]');
			this.$disabledLayer = this.$ctx.find('input[data-disabled-layer]');
			this.$uncheckLayer = this.$ctx.find('[data-layer-on-uncheck]');
			this.$checkLayer = this.$ctx.find('[data-layer-on-check]');
			this.$sumLayer = this.$ctx.find('[data-on-sum-layer]');
			this.$enableElements = this.$ctx.find('input[data-enables-element]');
			this.$disableElements = this.$ctx.find('input[data-disables-element]');
			this.$groupUncheck = this.$ctx.find('input[data-trigger-group-uncheck]');
			this.$inputField = this.$ctx.find('.l-form-general input').not('input[type=checkbox], input[type=radio]');
			this.inputName = 'input[name="';
			this.infoLayerClose = '.mm-infolayer-close';

			this.initBackgroundStriping();
			this.initEventlisteners();
			this.initHiddenContent();
			this.initInlineLayer();
			this.initDisableTrigger();
			this.initConditionedLayer();
			this.initConditionedElements();
			this.initHardcodedConstrains();
			this.initUserInputDynamic();
			this.resizeModule();

			if(this.$ctx.hasClass('m-basic-calculator-tiles--image')) {
				this.initEvenHeight();

			}
			resolve();
        },

		/**
		 * add Eventlistener to Label to check button via enter key or manage additional stuff via click
		 */
		initEventlisteners()
		{
			const labels = this.$ctx.find('.mm-tile');

			labels.on('keyup', (label) => {
				if(label.which === 13 && ($(label.target).closest('.mm-tile-main').length > 0 || $(label.target).closest('.m-basic-calculator-tiles--classic').length > 0))
				{
					$(label.currentTarget).find('input').trigger('click.labelClicked');
				}
			});

			labels.find('input').on('change', (input) => {
				if ($(input.currentTarget).attr('type') === 'radio')
				{
					//since deselect a radiobutton does not fire change event we need a class to find the previously selected
					this.toggleContent(this.$ctx.find('input.is-selected').closest('.mm-tile'));
					this.$ctx.find('input.is-selected').removeClass('is-selected');
					$(input.currentTarget).addClass('is-selected');

					if($(input.currentTarget).attr('tabindex') === "-1") {
						this.$ctx.find(`${this.inputName+$(input.currentTarget).attr('name')}"]`).each((index, elem) =>{
							$(elem).attr('tabindex', "0");
						});
					}
				}

				this.hideLayerContents();
				this.toggleContent($(input.currentTarget).closest('.mm-tile'));
			});

			this.$groupUncheck.on('change', (input) => {
				const toBeUncheckedElements = $(input.currentTarget).data('trigger-group-uncheck');
				const toBeCheckedElements = $(input.currentTarget).data('trigger-group-check');

				if(Array.isArray(toBeUncheckedElements)) {
					for(let i = 0; i < toBeUncheckedElements.length; i++) {
						$(`#${  toBeUncheckedElements[i]}`).prop('checked', false);
					}
				}
				else {
					$(`#${toBeUncheckedElements}`).prop('checked', false);
				}

				if(typeof toBeCheckedElements !== 'undefined' && toBeCheckedElements.length > 0) {
					if(Array.isArray(toBeCheckedElements)) {
						for(let i = 0; i < toBeUncheckedElements.length; i++) {
							$(`#${  toBeCheckedElements[i]}`).prop('checked', true);

						}
					}
					else {
						$(`#${toBeCheckedElements}`).prop('checked', true);
					}
				}
			});

			this.$sumLayer.on('change', (input) => {
				this.checkSumLayerConstrains($(input.currentTarget));
			});

			this.$sumLayer.each((index, elem) => {
				this.checkSumLayerConstrains($(elem));
			});
		},

		checkSumLayerConstrains(input) {
			const layerID = input.data('on-sum-layer');
			const completeSumGroup = $(`[data-on-sum-layer = ${layerID}]`);
			const belongingLayer = $(`[data-layer-on-sum-id = ${layerID}]`);
			let maxValue = 0;
			let currentValue = 0;

			if(belongingLayer.length > 0 && typeof belongingLayer.data('layer-on-sum-max') !== 'undefined') {
				maxValue = parseInt(belongingLayer.data('layer-on-sum-max'));
			}

			completeSumGroup.each((index, elem) => {
				currentValue = currentValue + parseInt($(elem).val());
			});

			if(currentValue > maxValue) {
				this.manageLayerContents(belongingLayer);
			}
		},

		initInlineLayer() {
			const layers = this.$ctx.find('.is-no-trigger');

			layers.each((index, elem) => {
				this.toggleInlineLayer(elem);
			});
		},

		toggleInlineLayer(layer) {
			const pos = this.calcLayerOffset(layer);
			const content = $(layer).find('.mm-infolayer-content');
			const visiblePos = 'visible pos-';

			content.addClass(visiblePos + pos);
			content.find(this.infoLayerClose).off().on('click', () =>
			{
				content.removeClass(visiblePos + pos);
			});
		},

		initEvenHeight() {
			const label = this.$ctx.find('.mm-tile label');
			let minHeight = 0;

			if (S.Utils.Helper.mq('tablet').matches)
			{
				label.each((index, label) => {
					if($(label).outerHeight() > minHeight) {
						minHeight = $(label).outerHeight();
					}
				});
				label.css('height', `${minHeight  }px`);
			}
			else {
				label.css('height', 'auto');
			}
		},

		/**
		 * calc distance between right edge of layer and right edge of viewport-window
		 *
		 * @param layer
		 */
		calcLayerOffset: function (layer)
		{
			const windowWidth = $(window).innerWidth();
			const domRect = layer.getBoundingClientRect();

			const spaceRight = windowWidth - domRect.left - domRect.width;

			if (spaceRight < 10)
			{
				return ('right');
			}

			else
			{
				return ('default');
			}
		},

		initDisableTrigger() {

			const disablesOnUncheck = 'disables-on-uncheck',
				disablesOnCheck = 'disables-on-check';

			//on uncheck this item disable others
			//on uncheck only works for checkboxes
			this.$disabletriggeruncheck.each((index, elem) => {
				this.manageDisableInputs($(elem).data(disablesOnUncheck), !$(elem).prop('checked'));

				$(elem).on('change', (el)=> {
					if(!$(el.currentTarget).prop('checked')) {
						this.manageDisableInputs($(el.currentTarget).data(disablesOnUncheck), true);
					}
					else if ($(`input[data-disables-on-uncheck="${$(el.currentTarget).data(disablesOnUncheck)}"]:checked`).length === 0 &&
						$(`input[data-disables-on-check="${$(el.currentTarget).data(disablesOnUncheck)}"]:not(:checked)`).length === 0
					)
					{
						this.manageDisableInputs($(el.currentTarget).data(disablesOnUncheck), false);
					}
				});
			});

			this.$disabletriggercheck.each((index, elem) => {
				this.manageDisableInputs($(elem).data(disablesOnCheck), $(elem).prop('checked'));

				$(elem).on('change', (el)=> {
					if($(el.currentTarget).prop('checked')) {
						this.manageDisableInputs($(el.currentTarget).data(disablesOnCheck), true);
					}
					else if ($(`input[data-disables-on-check="${$(el.currentTarget).data(disablesOnCheck)}"]:checked`).length === 0 &&
						$(`input[data-disables-on-uncheck="${$(el.currentTarget).data(disablesOnCheck)}"]:not(:checked)`).length === 0
					)
					{
						this.manageDisableInputs($(el.currentTarget).data(disablesOnCheck), false);
					}
				});
			});
		},

		initConditionedLayer() {
			this.$disabledLayer.closest('.mm-tile').find('label').on('click', (tile) => {
				const disabledLayerContent = $(`[data-disabled-layer-for="${$(tile.currentTarget).closest('.mm-tile').find('input').data('disabled-layer')}"]`);
				if($(tile.currentTarget).closest('.mm-tile').find('input').prop('disabled')) {
					this.manageLayerContents(disabledLayerContent);
				}
				else {
					this.hideLayerContents(disabledLayerContent);
					disabledLayerContent.hide();
				}
			});

			// works only for Checkboxes because RB do not fire unckeck
			this.$uncheckLayer.each((index, elem) => {
				const inputID = $(elem).data('layer-on-uncheck');
				const addCond = $(elem).data('hardcode-cond');
				$(`#${inputID}`).on('change', (el) => {
					if(!$(el.currentTarget).prop('checked')) {
						if(typeof addCond === 'undefined') {
							this.manageLayerContents($(elem));
						}
						else {
							//special hardcoded constrains here. Use carefully, scarcely(!) and wisely!

							// Rechtsschutz Tile 'Beruf' on uncheck only if age is over 60;
							if(addCond === 'birthday') {
								const compareYear = new Date().getFullYear();
								const enteredYear = parseInt($('#birthday').val().split('.')[2]);

								if((compareYear - enteredYear) >= 60) {
									this.manageLayerContents($(elem));
								}
								else {
									this.hideLayerContents($(elem));
								}
							}
						}
					}
					else {
						this.hideLayerContents($(elem));
					}
				});
			});

			this.$checkLayer.each((index, elem) => {
				const inputID = $(elem).data('layer-on-check');
				$(`#${  inputID}`).on('change', () => {
					this.hideLayerContents();
					this.manageLayerContents($(elem));

				});
			});
		},

		initConditionedElements() {

			this.$enableElements.each((index, elem) => {
				this.manageConditiondElements($(`[data-enable-trigger="${$(elem).data('enables-element')}"]`),$(elem).prop('checked'), 'is-active');
			});

			this.$disableElements.each((index, elem) => {
				this.manageConditiondElements($(`[data-disable-trigger="${$(elem).data('disables-element')}"]`),!$(elem).prop('checked'), 'is-disabled');
			});

			this.$enableElements.on('change', (el) => {
				this.manageConditiondElements($(`[data-enable-trigger="${$(el.currentTarget).data('enables-element')}"]`), $(el.currentTarget).prop('checked'), 'is-active');
			});

			this.$disableElements.on('change', (el) => {
				this.manageConditiondElements($(`[data-disable-trigger="${$(el.currentTarget).data('disables-element')}"]`), !$(el.currentTarget).prop('checked'),'is-disabled');
			});
		},

		manageConditiondElements(el,type, manageClass) {
			if(type) {
				el.addClass(manageClass);
			}
			else {
				el.removeClass(manageClass);
			}
		},

		manageLayerContents(disabledLayerContent) {
			let chosenCntent = false;

			//only one option to choose from
			if(disabledLayerContent.length === 1) {
				chosenCntent = disabledLayerContent.first();
			}
			//multiple options - check conditions
			else {
				disabledLayerContent.each((index, elem) => {
					const conditions = $(elem).data('disabled-layer-cond');
					if(typeof conditions === 'undefined' && !chosenCntent) {
						chosenCntent = $(elem);
					}
					else if(typeof conditions !== 'undefined') {
						let boolCont = true;

						for(let i = 0; i < conditions.length; i++) {
							if(!$(`#${conditions[i]}`).prop('checked')) {
								boolCont = false;
							}
						}

						if(boolCont) {
							chosenCntent = $(elem);
						}
					}
				});
			}

			this.hideLayerContents();
			const pos = this.calcLayerOffset(chosenCntent[0]);
			chosenCntent.find('.mm-infolayer-content').addClass(`visible pos-${pos}`);
			chosenCntent.show();
			chosenCntent.find('.mm-anchor-layer-content').focus();

			setTimeout(() => {
				$('body').on('click.closeLayerOnOutClick', (e) => {
					if($(e.target).closest('.m-basic-info-layer').length === 0) {
						chosenCntent.find(this.infoLayerClose).trigger('click');
						$('body').off('click.closeLayerOnOutClick');
					}
				});
			}, 50);
		},

		hideLayerContents(layer=false) {
			if(!layer) {
				$('.is-no-trigger').find(this.infoLayerClose).trigger('click');
			}
			else {
				layer.find(this.infoLayerClose).trigger('click');
			}
		},

		manageDisableInputs(elements, mod) {
			if(Array.isArray(elements)) {
				for(let i = 0; i < elements.length; i++) {
					$(`#${elements[i]}`).prop('disabled', mod);

				}
			}
			else {
				$(`#${elements}`).prop('disabled', mod);
			}
		},

		initHiddenContent()
		{
			const that = this,
				jsConditionTrigger = 'js-condition-trigger-default';

			this.initialHiddenCheck(jsConditionTrigger);
			this.$hiddenTrigger.on('input', (input) => {
				const currentInput = $(input.currentTarget);
				const direction = currentInput.data('js-condition-direction');

				if(currentInput.attr('type') === 'checkbox')
				{
					const defaultContent = currentInput.data(jsConditionTrigger);
					that.manageCheckboxContent(input, currentInput, defaultContent, direction);

				}
				else if(currentInput.attr('type') === 'radio')
				{
					that.manageRadioContent(input, currentInput);
				}
				else if ($(input.target).is('select')) {
					const currentOption = $(input.target).find('option:selected');

					currentOption.closest('select').children().each((index, el) =>{
						if(currentOption[0] !== $(el)[0]) {
							that.manageHiddenContent($(el), 'hide');
						}
					});

					that.manageHiddenContent(currentOption, 'show');
				}
			});
		},

		manageCheckboxContent(input, currentInput, defaultContent, direction)
		{
			const that = this;
			if(typeof direction !== "undefined" && direction === 'reversed') {
				if(currentInput.prop('checked'))
				{
					that.manageHiddenContent(currentInput, 'hide');
				}
				else
				{
					that.manageHiddenContent(currentInput, 'show');
				}
			}
			else {
				if(currentInput.prop('checked'))
				{
					that.manageHiddenContent(currentInput, 'show');
				}
				else
				{
					that.manageHiddenContent(currentInput, 'hide');
				}
			}

			if(typeof defaultContent !== 'undefined') {
				const defCont = $(`[data-js-hidden-content-default="${defaultContent}"]`);

				if(currentInput.prop('checked'))
				{
					defCont.addClass('h-hidden');
					defCont.find('input[data-js-condition-context]').each(function () {
						that.manageHiddenContent($(this), 'hidechild');
					});
				}
				else
				{
					defCont.removeClass('h-hidden');
					defCont.find('input[data-js-condition-context], option[data-js-condition-context]').each(function () {
						if($(this).prop('checked')) {
							$(this).trigger('input').trigger('change');
						}
					});
				}
			}
		},

		manageRadioContent(input, currentInput)
		{
			const that = this;

			that.$ctx.find(`${this.inputName+$(input.currentTarget).attr('name')}"]`).each(function()
			{
				if(input.currentTarget !== this) {
					that.manageHiddenContent($(this), 'hide');
				}
			});

			if(currentInput.prop('checked'))
			{
				that.manageHiddenContent($(input.currentTarget), 'show');
			}
			else
			{
				that.manageHiddenContent($(input.currentTarget), 'hide');
			}
		},

		initialHiddenCheck(jsConditionTrigger)
		{
			const that = this;

			this.$hiddenTrigger.each((index, element) =>
			{
				const direction =  $(element).data('js-condition-direction');
				if($(element).prop('checked') || $(element).prop('selected')) {
					if(typeof direction !== "undefined" && direction === 'reversed') {
						that.manageHiddenContent($(element), 'hide');
					}
					else {
						that.manageHiddenContent($(element), 'show');
					}

					that.$ctx.find(`${this.inputName+$(element).attr('name')}"]`).each((index, elem) =>{
						$(elem).attr('tabindex', "0");
					});
				}

				if(!$(element).prop('checked') && (typeof direction !== "undefined" && direction === 'reversed'))
				{
					that.manageHiddenContent($(element), 'show');
				}

				if(!$(element).prop('checked') && typeof $(element).data(jsConditionTrigger) !== "undefined") {
					$(`[data-js-hidden-content-default="${$(element).data(jsConditionTrigger)}"]`).removeClass('h-hidden');
				}
			});
		},

		// eslint-disable-next-line
		manageHiddenContent(trigger, mod)//NOSONAR complexity
		{
			const that = this;
			const hiddenContent = trigger.data('js-condition');
			const regex = new RegExp(`\\b\\w*${hiddenContent}\\w*\\b`);

			this.$hiddenContent.each(function () {

				let isAndConnected = false;
				let allContent = $(this).data('js-hidden-content');
				const dataJsConditionContext = 'input[data-js-condition-context]';
				const jsCurrentTriggerContent = 'js-current-trigger-content';

				if(typeof $(this).data('js-and-connection') !== 'undefined') {
					isAndConnected = true;
				}

				if(typeof allContent !== 'string') {
					allContent = allContent.join(',');
				}
				if(regex.test(allContent)) {
					let currentTriggerContent = $(this).data(jsCurrentTriggerContent);

					if(typeof currentTriggerContent === "undefined") {
						currentTriggerContent = '';
					}

					if(mod === 'show') {
						let showElement = true;

						if(!regex.test(currentTriggerContent)) {
							$(this).data(jsCurrentTriggerContent, `${currentTriggerContent} ${hiddenContent}`);
						}

						if(isAndConnected)
						{
							const currentSelection = $(this).data(jsCurrentTriggerContent).split(' ').sort();
							const neededSelection = allContent.split(',').sort().join(' ');

							if(currentSelection.indexOf("") > -1) {
								currentSelection.splice(currentSelection.indexOf(""), 1);
							}

							if(currentSelection.join(' ') !== neededSelection)
							{
								showElement = false;
							}
						}

						if(showElement)
						{
							$(this).removeClass('h-hidden');
							that._events.emit('BasicCalculatorContentBox.showElem', $(this));

							//hide default content
							// eslint-disable-next-line
							$(`*:not(input):not(option)[data-js-condition-context = ${$(this).data('js-condition-context')}][data-js-hidden-content = "default"]`).addClass('h-hidden'); //NOSONAR attribut

							//reset former conditional content
							$(this).find('input[data-js-condition-context], option[data-js-condition-context]').each(function () {
								$(this).trigger('change');
							});
						}
					}
					else if(mod === 'hide') {
						currentTriggerContent = currentTriggerContent.split(' ');
						let isAndConnected = false;

						if(typeof $(this).data('js-and-connection') !== 'undefined') {
							isAndConnected = true;
						}

						if($.inArray("", currentTriggerContent) > -1)
						{
							currentTriggerContent.splice( $.inArray("", currentTriggerContent), 1 );
						}
						if($.inArray(hiddenContent, currentTriggerContent) > -1)
						{
							currentTriggerContent.splice( $.inArray(hiddenContent, currentTriggerContent), 1 );
						}


						if(currentTriggerContent.length === 0 || isAndConnected)
						{
							$(this).addClass('h-hidden');
							$(this).find(dataJsConditionContext).each(function () {
								that.manageHiddenContent($(this), 'hidechild');
							});

							//show default if nothing else is shown
							if($(`*:not(.h-hidden):not(input):not(option)[data-js-condition-context = ${$(this).data('js-condition-context')}]`).length === 0)
							{
								$(`*[data-js-condition-context = ${$(this).data('js-condition-context')}][data-js-hidden-content = "default"]`).removeClass('h-hidden');
							}
						}

						currentTriggerContent = currentTriggerContent.join(' ');
						$(this).data(jsCurrentTriggerContent, currentTriggerContent);

						that._events.emit('BasicCalculatorContentBox.hideElem', $(this));
					}
					else if(mod === 'hidechild') {
						// will hide all subsidiary content but leaves selection untouched
						$(this).addClass('h-hidden');
						$(this).find(dataJsConditionContext).each(function () {
							that.manageHiddenContent($(this), 'hidechild');
						});
					}
				}
			});
		},

		initUserInputDynamic()
		{
			this.$userDynamicInputField.each((index, element) => {
				this.manageUserInputDynamic($(element));
			});
			this.$userDynamicInputField.on('blur', (field) => {
				this.manageUserInputDynamic($(field.currentTarget));
			});
		},

		manageUserInputDynamic(field)
		{
			// eslint-disable-next-line
			$(`[data-js-condition-input-target = ${field.data('js-condition-input-target-selector')}]`).text(field.val()); //NOSONAR attribute

			if(field.val().length > 0)
			{
				$(`*[data-js-overidden-by=${field.data('js-condition-input-target-selector')}]`).addClass('is-forced-hidden');
			}
			else
			{
				$(`*[data-js-overidden-by=${field.data('js-condition-input-target-selector')}]`).removeClass('is-forced-hidden');
			}
		},

		/**
		 * toggle basic Toggle if present when tile activated
		 */
		toggleContent($tile)
		{
			const $tileToggle = $tile.find('.m-basic-toggle');
			const $input = $tile.find('input');

			if($tileToggle.length > 0) { // eslint-disable-line
				if(($tileToggle.find('.js-head').hasClass('mm-opened') && !$input.prop('checked')) || (!$tileToggle.find('.js-head').hasClass('mm-opened') && $input.prop('checked')))
				{
					$tileToggle.find('.js-head').trigger('click');
				}
			}
		},

		/**
		 * initially handle alternating backgrounds and listen to $ctx if changed visibility/background and trigger
		 * change in following visible m-basic-calculator-tiles
		 */
		initBackgroundStriping()
		{
			this.stripeBG(this.$prevVisible);

			const observer = new MutationObserver(() => {
				this.$prevVisible = this.$ctx.prevAll(this.query).first();
				this.$nextVisible = this.$ctx.nextAll(this.query).first();
				this.stripeBG(this.$prevVisible);

				if(this.$ctx.is(":visible"))
				{
					this.stripeBG(this.$ctx, this.$nextVisible);
				}
				else
				{
					this.stripeBG(this.$prevVisible, this.$nextVisible);
				}
			});

			this.$ctx.each(function() {
				observer.observe(this, {attributes: true});
			});
		},

		/**
		 * decide if h-background is added or removed to given element based on previous visible element
		 */
		stripeBG(element, target = this.$ctx)
		{
			const backgroundClass = 'h-background';
			if(((element.length > 0 && !element.hasClass(backgroundClass)) || target.hasClass('is-bg')))
			{
				target.addClass(backgroundClass);
			}
			else
			{
				target.removeClass(backgroundClass);
			}
		},

		/**
		 * special hardcoded constrains here. Use carefully, scarcely(!) and wisely!
		 */
		initHardcodedConstrains() {
			const that = this,
				privateBox = '#privatBox',
				headlineDateCondition = '.mm-headline .ll-date-condition';

			// sofortiger Schutz AKS
			that.$ctx.find('.a-basic-input-checkbox.js-freeze-begin > input').on('click.freezeInsuBegin', function ()
			{
				const inputElem = that.$inputField.filter('[data-js-condition=insurancestart]');
				// disable inputElem
				if ($(this).prop('checked')) {
					const serverTime = $(inputElem).data('initcare');
					// server time is set
					if ( typeof serverTime !== 'undefined' && moment(serverTime, 'DD-MM-YYYY').isValid() ) {
						// set date to earliest day available (not client time!)
						$(inputElem).val( moment(serverTime, 'DD-MM-YYYY').format('DD.MM.YYYY') ).prop('disabled', true);
						$(inputElem).closest('.ll-row').removeClass('is-error');
						if($(inputElem).prop('required')) {
							$(inputElem).closest('.ll-row').addClass('is-valid');
						}
					}
				}
				// enable inputElem
				else {
					$(inputElem).prop('disabled', false);
				}
			});

			//Tile 'Beruf' in Rechtsschutz
			$('#berufBox').on('change', () => {
				if($('#berufBox').prop('checked')) {
					$(privateBox).next().find(headlineDateCondition).children().addClass('h-hidden');
					$(privateBox).next().find(headlineDateCondition).children().eq(0).removeClass('h-hidden');
				}
				else {
					//reset headline to fitting content
					$('#birthday').trigger('change');
					//fallback
					if($(privateBox).next().find(headlineDateCondition).find('div:not(.h-hidden)').length === 0) {
						$(privateBox).next().find(headlineDateCondition).children().eq(0).removeClass('h-hidden');
					}
				}
			});
		},
		resizeModule()
		{
			$(window).on('resize', () =>
			{
				S.Utils.delayed('basicBasicTiles.resizeDelay', 40, () =>
				{
					if(this.$ctx.hasClass('m-basic-calculator-tiles--image')) {
						this.initEvenHeight();
					}
				});
			});
		},
	});
}(jQuery));
