// eslint-disable-next-line no-unused-vars, no-redeclare
class BasisComponent extends HTMLElement {  //NOSONAR

	constructor() {
		super();
	}

	connectedCallback() {
		setTimeout(() => {
			this._isConnected = true;
			this._connectedCallback();
		});
	}

	_connectedCallback() {
		// implement in derived components
	}

	disconnectedCallback() {
		if (this._isConnected) {
			this._disconnectedCallback();
		}
	}

	_disconnectedCallback() {
		// implement in derived components
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (this._isConnected) {
			this._attributeChangedCallback(name, oldValue, newValue);
		}
	}

	// eslint-disable-next-line no-unused-vars
	_attributeChangedCallback(name, oldValue, newValue) {//NOSONAR
		// implement in derived components
	}

	setAttr(name, value) {
		this.setAttribute(name, value);
	}

	getAttr(name) {
		return this.getAttribute(name);
	}

	addClass(className) {
		this.classList.add(className);
	}

	removeClass(className) {
		this.classList.remove(className);
	}

	on(eventName, callback) {
		this.addEventListener(eventName, callback);
	}

	off(eventName, callback) {
		this.removeEventListener(eventName, callback);
	}

	emitEvent(eventName, detail = {}) {
		this.dispatchEvent(new CustomEvent(eventName, { detail }));
	}

	isExperienceEditor() {
		return document.body.classList.contains('is-page-editor');
	}

	logInheritance() {
		// eslint-disable-next-line no-console
		console.log(`Element of type ${this.constructor.name} has inherited from BasisComponent`); //NOSONAR
	}
}

if (typeof module !== "undefined" && module.exports) {
	module.exports = BasisComponent;
}