(function ($)
{
	class DaterangeComponent extends HTMLElement {
		constructor() {
			super();
			this.$ctx = null;
			this.$button = null;
			this.settings = null;
			this.datepickerClass = null;
			this.inRangeClass = null;
			this.from = null;
			this.to = null;
			this.dateFormat = 'dd.mm.yy';
		}

		connectedCallback() {
			setTimeout(() => { // needed to delay the connect callback, else the dom inside is not accessible
				this.$ctx = $(this);
				this.$button = this.$ctx.find('.js-date');
				this.datepickerClass = '.ui-datepicker';
				this.inRangeClass = 'datepicker-is-in-range';
				this.settings = this.$ctx.data('init');

				this.initDatePickers();
			});
		}

		//custom functions start here!
		initDatePickers() {
			const that = this;

			const options = {
				showOn: "none",
				changeMonth: true,
				changeYear: true,
				dateFormat : this.dateFormat,
				prevText: 'Zurück', prevStatus: '',
				prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: '',
				nextText: 'Vor', nextStatus: '',
				nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
				currentText: 'Heute', currentStatus: '',
				todayText: 'Heute', todayStatus: '',
				clearText: '-', clearStatus: '',
				closeText: 'Schließen', closeStatus: '',
				monthNames: ['Januar','Februar','März','April','Mai','Juni',
					'Juli','August','September','Oktober','November','Dezember'],
				monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
					'Jul','Aug','Sep','Okt','Nov','Dez'],
				dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
				dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
				dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
				yearRange: `1900:${this.settings.range}`,
				minDate: this.settings.begin,
				maxDate: this.settings.end,
				defaultDate: +7,
				// firstDay: firstDay,
				"showMonthAfterYear": false,
			};

			this.from = $("[data-timerange-start]")
					.datepicker(options)
					.on( "change", function() {
						that.to.datepicker("option", "minDate", that.getDate( this ));
						that.to.datepicker( "setDate", that.getDate( this ) );

						S.Utils.delayed('showPickerInMobile', 250, () => {
							that.to.datepicker('show');
							that.initDateControls();
						});
					});
			this.to = $("[data-timerange-end]")
					.datepicker(options)
					.on( "change", function() {
						that.$ctx.addClass('is-tainted');
						$(document).trigger('BasicDateRange.datechanged');
					});

			this.from.datepicker( "setDate", this.settings.defaultFrom);
			this.to.datepicker( "setDate", this.settings.defaultEnd);

			this.$button.on('click', () => {
				$(this.datepickerClass).addClass('is-new-form');
				this.from.datepicker('show');
			});
		}

		initDateControls()
		{
			const dateElements = $(this.datepickerClass).find('.ui-state-default');
			const allBtn = $(this.datepickerClass).find('.ui-datepicker-next, .ui-datepicker-prev, .ui-datepicker-year, .ui-datepicker-month');

			dateElements.off().on('mouseover', (dateItem) => {
				dateElements.removeClass(this.inRangeClass);
				if(!$(dateItem.currentTarget).parent().hasClass('ui-state-disabled'))
				{
					$(dateItem.currentTarget).addClass(this.inRangeClass);
					this.markRange($(dateItem.currentTarget));
				}
			});

			allBtn.on('click change', (btn)=> {
				this.initDateControls();

				if($(btn.currentTarget).hasClass('ui-datepicker-prev')) {
					$(this.datepickerClass).find('td:not(.ui-state-disabled) .ui-state-default').addClass(this.inRangeClass);
				}
			});
		}

		markRange(item, count = 0)
		{
			const itemParent = item.parent();
			let prevElement = itemParent.prev().children().first();

			if(!item.hasClass('ui-state-active')) {
				if(itemParent.is(':first-child') && itemParent.parent().prev().length > 0 && !itemParent.parent().prev().children().last().hasClass('ui-state-disabled')) {
					prevElement = itemParent.parent().prev().children().last().children().first();
				}

				prevElement.addClass(this.inRangeClass);
				count ++;

				if(count <= 31) {
					this.markRange(prevElement, count);
				}
			}
		}

		getDate(element)
		{
			let date;

			try {
				date = $.datepicker.parseDate( this.dateFormat, element.value );
			} catch( error ) {
				date = null;
			}

			return date;
		}
	}

	customElements.define( 'daterange-component', DaterangeComponent);
}(jQuery));
