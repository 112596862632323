(function($) {
	'use strict';
	/**
	 * HasPartner decorator implementation for the BasicClusterBox component.
	 *
	 * @author  <timo.mueller@namics.com>
	 * @namespace T.Module
	 * @class Rrv
	 * @extends T.Module
	 */
	T.Module.BasicClusterBox.HasPartner = T.createDecorator({
		/** @type {jQuery} */
        $ctx: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
		start: function(resolve) {
			const that = this;
			this.$ctx = $(this._ctx);
			this.has_partner_content= this.$ctx.find('.has-partner');
			this.no_partner_content = this.$ctx.find('.no-partner');
			this.has_partner_content.hide();
			this.getUserData(this._displayContent.bind(that));
			this._parent.start(resolve);
		},

		_displayContent(user) {
			// eslint-disable-next-line
            if (user && user.UserData && user.UserData.Mitgliedschaft.HatPartner === true) { //NOSONAR
				this.has_partner_content.show();
				this.no_partner_content.hide();
            } else {
				this.has_partner_content.hide();
				this.no_partner_content.show();

            }
		},

		/**
		 * Gets the user data from the application identity. Currently this call may result in several redirects to get a token for reading the user data.
		 *
		 * @param {function} name callback: the callback to call with optional user data after processing finished.
		 */
		getUserData(callback) {
            T.Utils.Auth.getResolvedIdentity(callback);
		}




	});
}(jQuery));
