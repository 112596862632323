(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.ScrollIntoView = {

		$header: null,

		/**
		 * init call
		 *
		 */
		init: function () {
			this.$header = $('body > .l-outer > .m-basic-header').eq(0);
		},

		/**
		 * scroll target elem to viewport top boundary with respect of header height
		 * => check for header is-stuck or other animations currently not implemented
		 * target {Element} - target elem that will be scrolled to
		 */
		scroll(target) {
			if (typeof $(target) !== 'undefined') {
				const distanceToTop = $(target)[0].getBoundingClientRect().top,
					headerHeight = this.$header.outerHeight(),
					currentScrollPos = $(window).scrollTop();

				$(window).scrollTop(distanceToTop - headerHeight + currentScrollPos);
			}
		},

	};

	$(document).ready(function () {
		S.Utils.ScrollIntoView.init();
	});

})(jQuery);
