'use strict';
(function ($) {
	/**
	 * CarInsuranceTelljaCampaignInit module implementation.
	 *
	 * @author  <stefan.pirkl@gks.adac.de>
	 * @namespace T.Module
	 * @class CarInsuranceTelljaCampaign
	 * @extends T.Module
	 */
	T.Module.CarInsuranceTelljaCampaignInit = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		/** @type {jQuery} */
		$formDiv: null,
		/** @type {jQuery} */
		$submit: null,
		/** @type {jQuery} */
		$errorId: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this._initControls();
			this._addEventListener($);
			resolve();
		},
		_initControls() {
			this.$formDiv = this.$ctx.find('.js-form').eq(0);
			this.$submit = this.$ctx.find('.js-submit-carinsurance').eq(0);
			this.$errorId = this.$ctx.find('.js-error-missing-id').eq(0);
		},
		_addEventListener() {
			this.$submit.on('click', this._sendRequest.bind(this));
		},
		_sendRequest() {
			const api = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.$ctx.data('api-token'));
			const telljaId = T.Utils.Helper.getUrlParameter(this.$ctx.data('id'));
			if (telljaId) {
				this._resetError();
				const options = {
					type: 'POST',
					url: api,
					data: { "Id": telljaId },
					xhrFields: {
						withCredentials: true
					},
					contentType: "application/x-www-form-urlencoded;charset=utf-8"
				};
				T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
			} else {
				this._showError();
			}
		},
		_successCallback: function _successCallback(data) {
			if (data.Data) {
				this.$ctx.find('#jwt').val(data.Data);
				this._doRedirect();
			}
		},
		_errorCallback: function _errorCallback() {
			//there's only one error currently (missing ID)
			this._showError();
		},
		_doRedirect: function _doRedirect() {
			const $form = this.$ctx.find("form")[0];
			if ($form) {
				$form.submit();
			}
		},
		_showError() {
			this.$errorId.toggleClass("h-hidden", false);
		},
		_resetError() {
			this.$errorId.toggleClass("h-hidden", true);
		}
	});
}(jQuery));
