(function ( $ )
{
	class BorderwrapperComponent extends HTMLElement {
		constructor() {
			super();
			this.$ctx = null;
			this.$toggleHeads = null;
			this.classToggleOpen = 'is-open';
		}

		connectedCallback() {
			setTimeout(() => { // needed to delay the connect callback, else the dom inside is not accessible
				this.$ctx = $(this);
				this.$toggleHeads = $(this).find('.js-trigger-head');
				this.initToggler();
			});
		}

		//custom functions start here!
		initToggler()
		{
			this.$toggleHeads.on('click', (toggle) => {
				const toggleElement = $(toggle.currentTarget);
				toggleElement.next().slideToggle();
				toggleElement.toggleClass(this.classToggleOpen);
			});
		}
	}

	customElements.define( 'borderwrapper-component', BorderwrapperComponent);

}( jQuery ));
