/// <reference path="../../../../../assets/typings/terrific-ext.d.ts" />

'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) {
	/**
	 * MyAdacDashboard Tracking
	 *
	 * @author Markus Hiller <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class GoogleTagEventPackage-MyAdacDashboard
	 * @extends T.Module.GoogleTagEventPackage
	 */
	T.Module.GoogleTagEventPackage.MyAdacDashboard = T.createDecorator({

		_sessionKeyEventCategory: "gtm_myadac_eventcategory",

		start: function start(resolve) {
			T.Utils.Store.set(this._sessionKeyEventCategory, null, T.Utils.Store.SESSION);
			this._parent.start(resolve);
		},

		_getEventActionByElement: function _getEventActionByElement($element) {
			if (this._isInsideTile($element)) {
				const headline = this._getHeadline($element);
				if (headline) {
					return `${headline} | click`;
				}
			}
			return this._parent._getEventActionByElement($element);
		},

		_getEventCategory: function _getEventCategory($element, action) {
			if (this._isInsideTile($element)) {
				const result = `${this._getPageHeadline($element)} | ${this._getSectionHeadline($element)}`;
				T.Utils.Store.set(this._sessionKeyEventCategory, result, T.Utils.Store.SESSION);
				return result;
			}
			return this._parent._getEventCategory($element, action);
		},

		_getPageHeadline: function _getPageHeadline($element) {
			return T.Utils.Format.normalizeSpace($element.closest(".l-main-content").find("h1").text());
		},

		_getSectionHeadline: function _getSectionHeadline($element) {
			return T.Utils.Format.normalizeSpace($element.closest(".mm-dashbox-theme").find("h2").text());
		},

		_getHeadline: function _getHeadline($element) {
			const $box = $element.closest(".mm-box");
			if ($box.length) {
				return T.Utils.Format.normalizeSpace($box.hasClass("js-vehicle-tile") ? "Mein Fahrzeug" : $box.find("h3:not(.h-hidden)").text());
			}
			return '';
		},

		_isInsideTile: function _isInsideTile(element) {
			return !!element.parents('[data-t-name="MeinAdacDashboard"]').length;
		}

	});

}(jQuery));
