(function ($)
{
	'use strict';
	/**
	 * BasicClusterBox module implementation.
	 *
	 * @author  <timo.mueller@namics.com>
	 * @namespace T.Module
	 * @class BasicClusterBox
	 * @extends T.Module
	 */
	T.Module.BasicClusterBox = T.createModule({

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
		start: function(resolve) {
			this.$ctx = $(this._ctx);

			resolve();
		}

	});
}(jQuery));

