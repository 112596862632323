(function ( $ )
{
    'use strict';
    /**
     * BasicAnchornav module implementation.
     *
     * @author  <l.meyer@mgo360.de>
     * @namespace T.Module
     * @class BasicAnchornav
     * @extends T.Module
     */
    T.Module.BasicAnchornav = T.createModule( {

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $header: null,

        /** @type {jQuery} */
        $legendWrap: null,

        /** @type {jQuery} */
        $stickyAnchors: null,

        /** @type {jQuery} */
        $contentAnchors: null,

        /** @type {jQuery} */
        $indicator: null,

        /** @type {Number} */
        headerHeight: 0,

        /** @type {Number} */
        headerStuckHeight: 64,

        /** @type {Number} */
        stickyThreshold: 0,

        /** @type {String} */
        ctxId: '',

        /** @type {Number} */
        indicatorWidth: null,

        /** @type {Number} */
        lastScrollVal: null,

        /** @type {Boolean} */
        isNoToggle: false,

        focusClass:'is-focused',

        start: function ( resolve )
        {
            this.$ctx = $(this._ctx);

            this.$header = $('body > .l-outer > .m-basic-header').eq(0);
            this.$legendWrap = $( document.querySelector('.m-basic-legend') );
            this.$stickyAnchors = this.$ctx.find( '> .mm-anchorlist' ).eq(0);
            this.$indicator = this.$stickyAnchors.find( ' > ul > .mm-indicator' ).eq(0);
            this.$contentAnchors = this.$ctx.find(' > .mm-contentwrapper > .mm-content > .mm-sticky-anchor > .mm-anchorpoint');

            this.listAnchor = 'li.mm-anchor';
			this.mainContent = '.l-main-content';
			this.legendOpen = 'js-legend-open';

            this.ctxId = this.$ctx.data('t-id');
            this.indicatorWidth =  this.$indicator.outerWidth();

            if(this.$ctx.hasClass('m-basic-anchornav--notoggle'))
            {
                this.isNoToggle = true;
            }

            this.addEventListeners();
            this.initControls();
            this.setSticky('init');
            this.setIndicatorPosition();
            this.windowResize();

            resolve();
        },

        /**
         * add event listeners
         */
        addEventListeners()
        {
            const that = this;

            const callScrollLogic = () => {
                S.Utils.delayed(`basicAnchorNav-${this.ctxId}.preventScrollOverflow`, 40, () => {
                    this.setIndicatorPosition();
                });
            };

            // add click event for the anchor links
            this.$ctx.find( '.mm-anchorlist li a' ).on( 'click', function (e) {
                e.preventDefault();

                // scroll to position
                if ( S.Utils.Helper.mq('tablet').matches || that.isNoToggle ) {
                    that.scrollToAnchor(this);
                }

                // open flyout content
                else {
                    that.openMobileContent(this);
                }
            });

            // close flyout
            this.$contentAnchors.on( 'click', function (e) {

                e.preventDefault();

                if (!S.Utils.Helper.mq('tablet').matches && that.$ctxMode !== 'notoggle') {
                    that.closeMobileContent();
                }

            });

            // add scroll event
            window.removeEventListener('scroll',  callScrollLogic,  false);
            window.addEventListener('scroll',  callScrollLogic,  { passive: true });

            // listen to header events and recalc stickyness
            this._events.on('basicHeader.isStuck', () => {
                this.setSticky('reinit');
            });

            this._events.on('basicHeader.isNotStuck', () => {
                this.setSticky('reinit');
            });

            this._events.on('basicHeader.reducedHeightChanged', () => {
                this.setSticky('reinit');
                this.setIndicatorPosition();
            });

            // listen to legend events
            this._events.on('BasicLegend.close', () => {
                this.setSticky('reinit');
            });
        },

        initControls()
        {
            const anchorTabList = this.$ctx.find('.js-anchor-tab-list');
            const anchorTabListLinks = anchorTabList.find('a');
            anchorTabListLinks.first().addClass(this.focusClass);

            this.$ctx.on('keyup', (event) =>
            {
                // make sure is-keyboard-focus is set when using arrow keys 39 ArrowRight 37 ArrowLeft
                $('body').addClass('is-keyboard-focus');
                const currentLink = anchorTabListLinks.filter('.is-focused');

                if(event.key === 'ArrowRight' && currentLink.parent().next().length > 0 && !currentLink.parent().next().hasClass('mm-indicator')) {
                    currentLink.parent().next().find('a').focus();
                    anchorTabListLinks.removeClass(this.focusClass);
                    currentLink.parent().next().find('a').addClass(this.focusClass);
                }
                if(event.key === 'ArrowLeft' && currentLink.parent().prev().length > 0) {
                    currentLink.parent().prev().find('a').focus();
                    anchorTabListLinks.removeClass(this.focusClass);
                    currentLink.parent().prev().find('a').addClass(this.focusClass);
                }

                if(event.key === 'Enter') {
                    $(currentLink.attr('href')).focus();
                }
            });
        },

        /**
         * sets anchor sticky
         *
         * @param mod {String} - 'init' || 'reinit'
         */
        setSticky(mod)
        {
            if (mod !== 'init') {

                this._events.emit('stickyDetachEvent.preventHeaderEvent');

                this.$stickyAnchors.trigger('sticky_kit:detach');
            }

            if ( S.Utils.Helper.mq( 'tablet' ).matches || this.isNoToggle ) {

                // update headerHeight (for the whole module!)
                this.headerHeight = this.$header.outerHeight();

                if ( !this.$header.hasClass('is-stuck') ) {

					if  ( S.Utils.Helper.mq( 'desktop-l' ).matches ) {
						// set threshold to sticky header height in desktop-l
						// => otherwise, it would stick with a wrong value on first scroll
						this.stickyThreshold = this.headerStuckHeight;
					}

					else {
						this.stickyThreshold = 0;
					}
				}

                else {
					// refresh thresh value
					this.stickyThreshold = this.headerHeight;
				}

                // check for the legend
                if (this.$ctx.closest(this.mainContent).hasClass(this.legendOpen) && this.$legendWrap.length) {
                    this.stickyThreshold += this.$legendWrap.outerHeight();
                }

                this.stickyThreshold = Math.round(this.stickyThreshold);

                this.$stickyAnchors.stick_in_parent({
                    sticky_class: 'is-stuck',
                    offset_top: this.stickyThreshold,
                });
            }
        },

        /**
         * scroll to the content anchor after link was clicked
         *
         * @param linkElem {Object} - link that was clicked
         */
        scrollToAnchor(linkElem)
        {
            const scrollBuffer = 100;

            // calc: target offset top - header height - buffer
            let scrollPos = $($(linkElem).attr('href')).offset().top - this.$stickyAnchors.outerHeight() - scrollBuffer;

            // check for the legend
            if (this.$ctx.closest(this.mainContent).hasClass(this.legendOpen) && this.$legendWrap.length) {
                scrollPos -= this.$legendWrap.outerHeight();
            }

            $('html, body').animate({scrollTop: scrollPos}, 400);
        },

        /**
         * open anchor content as fly out layer
         *
         * @param linkElem {Object} - link that was clicked
         */
        openMobileContent(linkElem)
        {
            const that = this,
                contentId = $(linkElem).attr( 'href' );

            if ( $(contentId).length ) {

                // store current scroll position
                this.lastScrollVal = $( window ).scrollTop();

                // animate fly out
                $( contentId ).eq(0).addClass('is-open').off('transitionend').on('transitionend.openContent', function () {
                    // show closing button
                    $(this).find('> .mm-sticky-anchor').eq(0).addClass('is-fixed').fadeIn();

                    that._events.emit('BasicAnchornav.openLayer', this.$ctx);

                    $('body').addClass('is-noscroll');
                });
            }
        },

        /**
         * closes mobileContent
         *
         */
        closeMobileContent()
        {
            const contentElem = this.$ctx.find(' > .mm-contentwrapper > .mm-content.is-open').eq(0);

            // anim content to left
            $(contentElem).css('left','100vw');

            // remove close btn completely
            $(contentElem).find('> .mm-sticky-anchor.is-fixed').eq(0).removeClass('is-fixed').removeAttr('style');

            // clean up after anim is done
            $(contentElem).off('transitionend').on('transitionend.closeContent', () => {
                $(contentElem).removeClass('is-open').removeAttr('style');
            });

            // reset old scroll state
            $('body').removeClass('is-noscroll');
            $(document).scrollTop( this.lastScrollVal );
        },

        /**
         * updated indicator position to display it under the active anchor point
         */
        setIndicatorPosition()
        {
            const currentScrollPos = $( window ).scrollTop(),
                // value to prevent firing too soon
                // => technically unnecessary but it looks better if we increase the threshold a little bit
                scrollBuffer = 120;

            let scrollThreshold = currentScrollPos + this.headerHeight + this.$stickyAnchors.outerHeight() + scrollBuffer,
                highestIndex = 0;

            // check for the legend
            if (this.$ctx.closest(this.mainContent).hasClass(this.legendOpen) && this.$legendWrap.length) {
                scrollThreshold += this.$legendWrap.outerHeight();
            }

            // get required li index by asking for the y-position of the corresponding content
            this.$contentAnchors.each( (index, elem) =>
            {
                if ( scrollThreshold >= $( elem ).offset().top )
                {
                    highestIndex = index;
                }
            });

            const targetAnchor = this.$stickyAnchors.find(this.listAnchor).eq( highestIndex ),
                posLeftValShown = Math.round( $(targetAnchor).outerWidth() / 2 + $(targetAnchor).position().left - this.indicatorWidth / 2 );


			// find hovered anchor and calculate its left position
			this.$stickyAnchors.find(this.listAnchor).on('mouseenter', ()  => {
				const hoveredAnchor = this.$stickyAnchors.find('li.mm-anchor:hover'),
					posLeftValHovered =  Math.round( hoveredAnchor.outerWidth() / 2 + hoveredAnchor.position().left - this.indicatorWidth / 2 );
					document.documentElement.style.setProperty('--indicatorHoveredLeft', `${posLeftValHovered   }px`);
			});

            // switch through each anchor
			this.$stickyAnchors.find(this.listAnchor).each((index, element) => {
				$(element).removeClass('is-shown');

				// check which anchor equals the position of the indicator --> add class 'is-shown' to the shown anchor
				if(Math.round($(element).outerWidth() / 2 + $(element).position().left - this.indicatorWidth / 2) === posLeftValShown && ((!S.Utils.Helper.mq( 'mobile' ).matches) || this.$ctx.hasClass('m-basic-anchornav--notoggle'))) {
					$(element).addClass('is-shown');
                    this.$stickyAnchors.find('a').removeClass(this.focusClass);
					$(element).find('a').addClass(this.focusClass);
				}
			});

            setTimeout(() => {
                this.$indicator.css('left', posLeftValShown);
            }, 150);
        },

        /**
         * resize
         */
        windowResize()
        {
            // add resize event
            S.Utils.Helper.mq( 'tablet' ).addListener(  ( mq ) =>
            {
                this.setSticky('reinit');

                if ( mq.matches )
                {
                   this.closeMobileContent();
                }
            });
        }

    } );
}( jQuery ));
