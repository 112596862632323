/**
 * MglAdvisor
 *
 * @author  <markus.hiller@mtc.berlin>
 * @class MglAdvisor
*/

// eslint-disable-next-line no-undef
class MglAdvisor extends BasisComponent {

	questions;
	questionTemplate;
	answerTemplate;
	_sessionKey = 'MglAdvisor';

	_isActiveClass = "is-active";
	_isHiddenClass = "is-hidden";
	_questionSelector = ".oo-questions";

	_connectedCallback() {
		this.questions = JSON.parse(this.dataset["questions"]);
		this.questionTemplate = this.querySelector("#question-template").content;
		this.answerTemplate = this.querySelector("#answer-template").content;
		this.hintTemplate = this.querySelector("#hint-template").content;
		const saveObj = T.Utils.Store.get(this._sessionKey, T.Utils.Store.SESSION);
		if (saveObj === null || saveObj.length === 0) {
			this._renderFirstQuestion();
		} else {
			this._restore(saveObj);
		}
	}

	_renderFirstQuestion() {
		this._renderQuestion(this.questions[0], true, true);

		this.querySelector(".js-start").addEventListener('click', () => {
			this.querySelector(".js-intro").classList.add('is-hidden');
			this.querySelector(".js-advisor").classList.remove('is-hidden');
			jQuery('html, body').scrollTop(0);
		});
	}

	_renderQuestion(question, skipAnimation = false, skipScroll = false) {
		if (this.querySelector(`div[data-question="${question.Id}"]`) === null) {
			const clone = this.questionTemplate.cloneNode(true);
			clone.firstElementChild.setAttribute("data-question", `${question.Id}`);
			this.querySelector(".js-questions").appendChild(clone);

			this.querySelector(`div[data-question="${question.Id}"] .js-question-text`).innerHTML = question.QuestionText;
			if (question.InfolayerText) {
				this.querySelector(`div[data-question="${question.Id}"] .js-infolayer-text`).innerHTML = question.InfolayerText;
				this.querySelector(`div[data-question="${question.Id}"] .js-info-icon`).classList.remove("h-hidden");
				T.Utils.Helper.initAdditionalModules(this);
			}
			const answerContainer = this.querySelector(`div[data-question="${question.Id}"] .js-answers`);
			question.Answers.forEach(this._renderAnswer.bind(this, answerContainer));

			setTimeout(() => {
				this.querySelectorAll(`div[data-question="${question.Id}"] .js-answer`).forEach(e => e.addEventListener("click", this._onAnswerSelected.bind(this)));
				this.querySelector(`div[data-question="${question.Id}"]`).classList.remove(this._isHiddenClass);

				if (!skipScroll) {
					const scrollPos = answerContainer.offsetTop - 10;

					jQuery('html, body').stop().animate({
						'scrollTop': scrollPos,
					}, 800, 'swing');
				}

			}, skipAnimation ? 0 : 1200);
		}
	}

	_renderAnswer(container, answer, index) {
		const clone = this.answerTemplate.cloneNode(true);
		clone.firstElementChild.setAttribute("data-flow", answer.FollowupQuestion);
		clone.firstElementChild.setAttribute("data-value", index);
		clone.firstElementChild.setAttribute("data-rate", answer.RelatedRate);
		clone.firstElementChild.dataset["hint"] = answer.TipText;
		clone.firstElementChild.innerHTML = answer.AnswerText;
		container.appendChild(clone);
	}

	_renderHint(text) {
		const clone = this.hintTemplate.cloneNode(true);
		clone.firstElementChild.querySelector(".js-hint-text").innerHTML = text;
		this.querySelector(".js-results").appendChild(clone);
	}

	_onAnswerSelected(e) {
		e.currentTarget.closest(this._questionSelector).querySelectorAll(".js-answer").forEach(a => a.classList.remove(this._isActiveClass));

		const nextQuestionId = e.currentTarget.dataset["flow"];
		if (nextQuestionId === "00000000-0000-0000-0000-000000000000") {
			e.currentTarget.classList.add(this._isActiveClass);
			this._showResult();
		} else {
			const nextQuestion = this._getNextQuestion(nextQuestionId);
			this._removeOldQuestions(e.currentTarget);
			this._renderQuestion(nextQuestion);
			e.currentTarget.classList.add(this._isActiveClass);
		}

		const saveObj = [];
		this.querySelectorAll(".js-answer.is-active").forEach(e => {
			const questionId = e.closest(this._questionSelector).dataset["question"];
			saveObj.push(`${questionId}|${e.dataset["flow"]}|${e.dataset["value"]}`);
		});
		T.Utils.Store.set(this._sessionKey, saveObj, T.Utils.Store.SESSION);
	}

	_removeOldQuestions(answerElement) {
		const q = answerElement.closest(this._questionSelector);
		while (q.nextSibling) {
			q.nextSibling.remove();
		}
		q.querySelectorAll(".js-answer").forEach(a => a.classList.remove(this._isActiveClass));
		this.querySelectorAll(".js-result").forEach(e => e.classList.add(this._isHiddenClass));
	}

	_restore(saveObj) {
		this.querySelector(".js-intro").classList.add(this._isHiddenClass);
		this._renderFirstQuestion();
		saveObj.forEach(s => {
			const save = s.split("|");
			this._selectAnswer(save[0], save[1], save[2]);
			if (save[1] !== "00000000-0000-0000-0000-000000000000") {
				this._renderQuestion(this._getNextQuestion(save[1]), true);
			} else {
				this._showResult(true);
			}
		});

		this.querySelector(".js-advisor").classList.remove(this._isHiddenClass);
	}

	_getNextQuestion(questionId) {
		return this.questions.find(q => {
			return q.Id === questionId;
		});
	}

	_selectAnswer(questionId, answerId, value) {
		const answerBtn = document.querySelector(`div[data-question="${questionId}"] button[data-flow="${answerId}"][data-value="${value}"]`);
		answerBtn.classList.add(this._isActiveClass);
	}

	_showResult(skipAnimation = false) {
		this.querySelectorAll(".js-result").forEach(e => e.classList.add(this._isHiddenClass));
		this.querySelectorAll(".m-basic-hint").forEach(e => e.remove());

		let highestRate = 0;
		this.querySelectorAll(".js-answer.is-active").forEach(e => {
			if (e.dataset["rate"] !== undefined) {
				highestRate = Number(e.dataset["rate"]) > highestRate ? Number(e.dataset["rate"]) : highestRate;
			}
			if (e.dataset["hint"]) {
				this._renderHint(e.dataset["hint"]);
			}
		});
		setTimeout(() => {
			this.querySelector(`div[data-result="${highestRate}"]`).classList.remove(this._isHiddenClass);
			this.querySelectorAll(".m-basic-hint").forEach(e => e.classList.remove(this._isHiddenClass));
		}, skipAnimation ? 0 : 700);

	}

}
window.customElements.define('mgl-advisor', MglAdvisor);