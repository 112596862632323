(function ($) {
	'use strict';

	S.Calculator = S.Calculator || {};

	S.Calculator.USMy = {

		/** @type {jQuery} */
		$form: null,

		/** @type {jQuery} */
		$childClone: null,

		/** @type {jQuery} */
		$childToDelete: null,

		/** @type {Number} */
		childCountLength: null,

		/** @type {Number} */
		childCountMax: 9,

		app: null,

		/**
		 * init form actions
		 *
		 */
		init() {
			this.$form = $('#calculator_us').eq(0);

			// classes/events/data as strings
			this.dataCloneVn = '[data-clone="vn"]';
			this.divDataCloneVn = 'div[data-clone="vn"]';
			this.jsCloneVnInput = '.js-clone-vn input';
			this.clickSetCloneChildEvent = 'click.setCloneChildEvent';

			// update child count length
			this.childCountLength = this.$form.find(this.dataCloneVn).length;

			this.$childClone = this.$form.find('[data-clone-template="vn"]').eq(0).prop('outerHTML').replace('data-clone-template', 'data-clone');

			// set event for clone child
			this.setCloneChildEvent();

			this.resetAttributesAndIds();


			// set event for delete child
			this.setDeleteChildEvent();

		},

		/**
		 * set clone child event
		 *
		 * @param {jQuery} inputElem
		 */
		setCloneChildEvent() {
			const inputElements = this.$form.find(this.jsCloneVnInput);


			inputElements.each((index, inputElem) => {
				// checkbox add child
				$(inputElem).off(this.clickSetCloneChildEvent).on(this.clickSetCloneChildEvent, (e) => {
					if ($(e.target).is(':checked')) {
						this.cloneChild();

						setTimeout(function () {
							$(inputElem).blur();
						}, 10);
					} else {
						this.$childToDelete = $(e.target).closest(this.divDataCloneVn).next(this.divDataCloneVn);
						if ($(e.target).closest(this.dataCloneVn).next().length) {
							this.$childToDelete = $(e.target).closest(this.dataCloneVn).next();
						} else {
							this.$childToDelete = this.$form.find(this.dataCloneVn).eq(0);
						}


						$(e.target).prop('checked', true).blur();

						// layer delete child
						$.magnificPopup.open({
							mainClass: 'l-lightbox',
							items: {
								src: $('#layer_delete_vn'),
								type: 'inline'
							}
						});
					}

					// Namics needs this
					$(document).trigger('cloneChildEvent');
				});
			});
		},

		/**
		 * set delete child event
		 *
		 * @param {jQuery} inputElem
		 */
		/**
		 * set delete child event
		 *
		 * @param {jQuery} inputElem
		 */
		setDeleteChildEvent() {
			const deleteChildButton = this.$form.find('.js-delete-vn').eq(0);

			// checkbox add child
			deleteChildButton.off('click.setDeleteChildEvent').on('click.setDeleteChildEvent', (e) => {
				e.preventDefault();

				this.$childToDelete.remove();

				if (!this.$form.find(this.dataCloneVn).length) {
					this.$form.find(this.jsCloneVnInput).eq(0).prop('checked', false);
				}

				if ($.magnificPopup.instance.isOpen) {
					$.magnificPopup.close();
				}

				this.countChildren();

				this.resetAttributesAndIds();

				if (this.$form.find(this.divDataCloneVn).last().find('input[id^=vn_add]:checked').length) {
					this.$form.find(this.divDataCloneVn).last().find('input[id^=vn_add]:checked').prop('checked', false);
				}

				// Namics needs this handler, for which is guaranteed that the childs are really deleted at call-time
				$(document).trigger('deleteChildEvent');

			});
		},

		setRemovalButtonEvents() {
			const inputElements = this.$form.find('div[data-clone="vn"] h3 .a-basic-link');

			inputElements.each((index, inputElem) => {
				// checkbox add child
				$(inputElem).off('click.removalLink').on('click.removalLink', (e) => {
					e.preventDefault();
					$(e.target).closest(this.divDataCloneVn).find(this.jsCloneVnInput).trigger(this.clickSetCloneChildEvent);
				});
			});
		},

		/**
		 * clone child
		 *
		 */
		cloneChild() {
			this.$form.find('.js-vn-clone-wrapper').append(this.$childClone);

			this.childCountLength++;

			this.countChildren();

			this.resetAttributesAndIds();

			S.Utils.Form.init();

			this.setCloneChildEvent();
			S.Utils.Lightbox.init();
		},


		resetAttributesAndIds() {
			const clones = this.$form.find('div.js-vn-clone-wrapper div[data-clone="vn"]'),
				clickBasicLinkRemoveChild = 'click.basicLinkRemoveChild';

			// update name/id/for attributes
			clones.each((index, clone) => {
				$(clone).find('input').each((_, item) => {
					$(item).attr('name', `${$(item).attr('name').replace(/-\d+$/, '')}-${parseInt(index + 1, 10)}`);
					$(item).attr('id', `${$(item).attr('id').replace(/-\d+$/, '')}-${parseInt(index + 1, 10)}`);

					// Needed. Terrific doesn't initialize modules when data-t-id is present.
					if ($(item).is('[type="radio"]')) {
						$(item).parent().removeAttr('data-t-id');

						// will reinitialize the input fields.
						$(document).trigger('addModules', $(item).parent());
					}
					$(item).removeAttr('data-t-id');

					// will reinitialize the input fields.
					$(document).trigger('addModules', $(item));
				});
				$(clone).find('label[for]').each((_, item) => {
					$(item).attr('for', `${$(item).attr('for').replace(/-\d+$/, '')}-${parseInt(index + 1, 10)}`);
				});
				$(clone).find('[data-depend]').each((_, item) => {
					$(item).attr('data-depend', `${$(item).attr('data-depend').replace(/-\d+$/, '')}-${parseInt(index + 1, 10)}`);
				});
				$(clone).find('h3 span').html(index + 2);
				$(clone).find('div.js-birthday-helptext span').each((_, item) => {
					$(item).attr('id', `${$(item).attr('id').replace(/-\d+$/, '')}-${parseInt(index + 1, 10)}`);
				});

				$(clone).find('h3 .a-basic-link').off(clickBasicLinkRemoveChild).on(clickBasicLinkRemoveChild, (e) => {
					e.preventDefault();
					$(e.target).closest(this.divDataCloneVn).prev(this.divDataCloneVn).find('.js-clone-vn input:checked').trigger('click');
				});



				if (index === 0) {
					$(clone).find('h3 .a-basic-link').eq(index).off(clickBasicLinkRemoveChild).on(clickBasicLinkRemoveChild, (e) => {
						e.preventDefault();
						$.magnificPopup.open({
							mainClass: 'l-lightbox',
							items: {
								src: $('#layer_delete_vn'),
								type: 'inline'
							}
						});
					});

					this.$childToDelete = $(clone);
				}
			});

		},


		/**
		 * count children
		 *
		 */
		countChildren() {
			this.childCountLength = this.$form.find(this.dataCloneVn).length;

			// check if max
			if (this.childCountLength === this.childCountMax) {
				this.$form.find(this.dataCloneVn).last().find('.js-clone-vn').hide();
			}
		},

		/**
		 * check date of birth
		 *
		 * @param {jQuery} elem
		 * @param {Boolean} valid
		 */
		setChecked: function (elem, valid) {
			if (valid) {
				elem.closest('.ll-row').addClass('is-check is-valid').removeClass('is-error');
			} else {
				elem.closest('.ll-row').addClass('is-check is-error').removeClass('is-valid');
			}
		},

	};

	$(document).ready(function () { });

})(jQuery);
