(function ($)
{
    'use strict';

    /**
     * compare decorator implementation for the BasicTable module.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module.BasicTable
     * @class CompareMgl
     * @extends T.Module
     */
    T.Module.BasicTable.CompareMgl = T.createDecorator({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {Object} */
        $tr: [],


        start: function (resolve)
        {
            this.$ctx = $(this._ctx);

            this.$tr = this.$ctx.find('tbody > tr');

            // init
            this.init();



            this._parent.start(resolve);
        },

        /**
         * init
         *
         */
        init: function (){

            if (!S.Utils.Helper.mq('tablet').matches){

                // switchMode
                this.switchMode();
            }

            S.Utils.Helper.mq('tablet').addListener( (mq) =>
            {
                if (!mq.matches) {

                    // switchMode
                    this.switchMode();
                }
            });
        },

        /**
         * switchMode
         */
        switchMode: function (){

            this.$tr.each( (index, item) =>
            {
                if (!this.$ctx.data('phoneswitch')){
                    const cloneContent = $(item).find('> td').first().html();

                    $(`<tr class="h-smartphone-only"><td colspan="7">${  cloneContent  }</td></tr>`).insertBefore($(item));
                }
            });

            // global event
            this._events.emit( 'BasicKeywordfilter.checkKeywordContentLinks' );

            // set state
            this.$ctx.data('phoneswitch', true);
        }
    });
}(jQuery));
