 (function ( $ )
{
    'use strict';
    /**
     * BasicBtn module implementation.
     *
     * @author  <oe.guenay@edelweiss72.de>
     * @namespace T.Module
     * @class BasicBtn
     * @extends T.Module
     */
    T.Module.BasicBtn = T.createModule( {

        /** @type {jQuery} */
        $ctx: null,

		/** @type {String} */
		shownAnimate: '',

        start: function ( resolve )
        {
            this.$ctx = $( this._ctx );
            this.shownAnimate = 'is-shown-animate';

            if ( this.$ctx.hasClass(this.shownAnimate) || this.$ctx.hasClass('is-hidden-animate')  ) {

                const fadeEventName = `BasicBtn.fadeAnimate-${  this.$ctx.data('t-id')  }`;

                // call fade animation by listening to global event
                this._events.off(fadeEventName).on(fadeEventName,  (mod) => {
                    this.fadeAnimate(mod, this.$ctx);
                });

                $(document).on(fadeEventName, (e, mod) => {
                    this.fadeAnimate(mod, this.$ctx);
                });
            }

            if ( this.$ctx.hasClass( 'js-edit-plain' ) || this.$ctx.hasClass( 'js-edit-plain--abort-edit' ) || this.$ctx.hasClass( 'js-edit-plain--save-edit' ) )
            {
                this.checkForPlainInputFields();
            }

			if (this.$ctx.hasClass( 'js-print' ))
			{
				this.$ctx.on('click', () =>
				{
					window.print();
				});
			}

            // for mgl cluster example page
            if(this.$ctx.hasClass('js-cluster-selector'))
            {
                this.$ctx.siblings('.a-basic-input-radio').find('input').on('change', (e)=>
                {
					// eslint-disable-next-line
                    this.$ctx.attr('href', this.$ctx.attr('href').replace(/setActiveRadio=[0-2]/, 'setActiveRadio=' + $(e.target).parent().index('.a-basic-input-radio')));
                });
            }

            resolve();
        },

		// eslint-disable-next-line
        checkForPlainInputFields() //NOSONAR complexity
        {
            const savedValueForAbort = $( '.a-basic-input-text--plain > input' );
			const inputTextPlain = '.a-basic-input-text--plain';
			const inputTextClear = 'a-basic-input-text--clear';
			const plainSaveEdit = '.js-edit-plain--save-edit';
			const plainAbortEdit = '.js-edit-plain--abort-edit';
			const plainDisable = '.js-edit-plain--disable';
			const isDisabled = 'is-disabled';
            const that = this;

            let parentFieldsetEditable;

            const switchVisibilityForFakeContentSpan = (hideIfSet, fieldset) =>
            {
                fieldset.find('.js-plain-content').each((item, obj) =>
                {
                    if(hideIfSet)
                    {
                        $(obj).hide();
                    }
                    else
                    {
                        $(obj).show();
                    }
                });

                that._events.emit('BasicInputText.eventSentFromButton', parentFieldsetEditable);
            };

            this.$ctx.on( 'click', ( e ) =>
            {
                parentFieldsetEditable = $( e.target ).closest('.js-fieldset-edit');

                if ( $( e.target ).hasClass( 'js-edit-plain' ) )
                {
                    e.preventDefault();

                    that._events.emit('BasicInputText.eventSentFromButton', parentFieldsetEditable);

                    switchVisibilityForFakeContentSpan(true, parentFieldsetEditable);

                    if (  parentFieldsetEditable.find(inputTextPlain).length > 0 )
                    {

                        const $editableContent =  parentFieldsetEditable.find(inputTextPlain);

                        $editableContent.each( ( index, object ) =>
                        {
                            $( object ).removeClass(isDisabled).find( 'input' ).prop( 'disabled', false );
                            $( e.target ).css( 'display', 'none' );
							$( object ).closest('.ll-row').removeClass('ll-row--data-only');
                        } );

                        if ( parentFieldsetEditable.find(plainSaveEdit).length > 0 )
                        {
                            const $editableSaveBtn =  parentFieldsetEditable.find(plainSaveEdit);

                            $editableSaveBtn.each( ( index, object ) =>
                            {
                                $( object ).css( 'display', 'table' );
                            } );

                        }

                        if ( parentFieldsetEditable.find(plainAbortEdit).length > 0 )
                        {
                            const $editableAbortBtn =  parentFieldsetEditable.find(plainAbortEdit);

                            $editableAbortBtn.each( ( index, object ) =>
                            {
                                $( object ).css( 'display', 'table' );
                            } );

                        }
                    }

                    $(plainDisable).addClass(isDisabled);
                }

                if ( $( e.target ).hasClass( 'js-edit-plain--abort-edit' ) )
                {
                    $( e.target ).css( 'display', 'none' );
                    $( '.js-edit-plain' ).css( 'display', 'table' );
                    $(plainSaveEdit).css( 'display', 'none' );

                    if ($(inputTextPlain).length > 0 )
                    {
                        $(inputTextPlain).each( ( index, object ) =>
                        {

                            if ( $( object ).find('input').attr( 'data-save' ) )
                            {
                                $( object ).addClass(isDisabled).find( 'input' ).prop( 'disabled', true ).val( $( savedValueForAbort[index] ).attr( 'data-save' ) ).removeClass(inputTextClear);
                            }
                            else
                            {
                                $( object ).addClass(isDisabled).find( 'input' ).prop( 'disabled', true ).val( $( savedValueForAbort[index] ).attr( 'value' ) ).removeClass(inputTextClear);
                            }

                        } );
                    }

                    $(plainDisable).removeClass(isDisabled);
                    switchVisibilityForFakeContentSpan(false, parentFieldsetEditable);
                }

                if ( $( e.target ).hasClass( 'js-edit-plain--save-edit' ) )
                {
                    e.preventDefault();

                    $( e.target ).css( 'display', 'none' );
                    $( '.js-edit-plain' ).css( 'display', 'table' );
                    $(plainAbortEdit).css( 'display', 'none' );

                    $(inputTextPlain).each( ( index, object ) =>
                    {
                        $( object ).addClass(isDisabled).find( 'input' ).prop( 'disabled', true ).removeClass(inputTextClear);

                        if ( typeof $( object ).attr( 'data-save' ) === 'undefined' && $( object ).find( 'input' ).attr( 'value' ) !== $( object ).find( 'input' ).val())
                        {
							$(object).find('input').attr('data-save', $(object).find('input').val());
                        }

                    } );

                    $(plainDisable).removeClass(isDisabled);
                    switchVisibilityForFakeContentSpan(false, parentFieldsetEditable);
                }
            } );
        },

        /**
         * starts fade-in/-out of elem
         *
         * @param {String} mod - 'show' || 'hide'
         * @param {Object} elem
         */
        fadeAnimate (mod, elem)
        {
            if ( mod === 'hide' ) {

                $(elem).addClass('is-hidden-animate').removeClass(this.shownAnimate);
            }

            else if ( mod === 'show' ) {

                $(elem).removeClass('h-hidden');

                setTimeout( () => {
                    $(elem).addClass(this.shownAnimate);
                }, 50);
            }
        },
    } );
}( jQuery ));
