(function ($) {
	'use strict';
	/* eslint-disable no-empty */
	/**
	 * BasicInputText module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputText
	 * @extends T.Module
	 */
	T.Module.BasicInputText.Autocomplete = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {Array} */
		receivedData: null,

		/** @type {string} */
		suggestionID: '',

		/** @type {jQuery} */
		$noResultWrapper: null,

		/** @type {Boolean} */
		selectOnEnter: false,

		/** @type {Boolean} */
		suggestWasOpen: false,

		/** @type {Boolean} */
		itemWasSelected: false,

		/** @type {Number} */
		charCount: 3,

		/** @type {Number} */
		focusDelay: 0,

		/** @type {jQuery} */
		$mainStageParent: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			const that = this;

			this.$ctx = $(this._ctx);
			this.noResultOpen = 'noresult-open';
			this.noResultClose = 'noresult-close';

			// validate on modification
			this.$ctx.on('change keyup', function (e) {
				that.validate(e.which);
			});

			// get custom no result option
			this.$noResultWrapper = this.$ctx.siblings('.ll-noresult');

			// get main stage elem if there is one
			if (this.$ctx.parents('.o-layout-main-stage--search').length) {
				this.$mainStageParent = this.$ctx.parents('.o-layout-main-stage--search').eq(0);
			}

			// get custom enter-select option
			if (typeof this.$ctx.data('selectonenter') !== 'undefined' && this.$ctx.data('selectonenter')) {
				this.selectOnEnter = true;
			}

			// long autocomplete layer
			if (this.$ctx.data('mod') === 'long') {
				setTimeout(() => {
					const id = this.$ctx.attr('id');
					const autocompleteSuggesetions = $(`.autocomplete-suggestions.js-suggestion-${id}`);
					if (autocompleteSuggesetions.length) {
						autocompleteSuggesetions.addClass('js-suggestion--long').css('min-width', this.$ctx.outerWidth());

						$(window).on('resize', () => {
							S.Utils.delayed(`basicInputText.autocomplete_${that.$ctx.data('t-id')}`, 100, () => {
								autocompleteSuggesetions.css('min-width', this.$ctx.outerWidth());
							});
						});
					}
				}, 500);
			}

			// trigger/listener for the BE to know, when a new suggestion list is called
			// $(document).on('basicInputTextAutocomplete.suggestionShown_' + this.$ctx.data('t-id'), () => {
			//     console.log('dropdown is shown');
			// });

			this.importData();

			this._parent.start(resolve);
		},

		validate: function () {
			const that = this,
				value = this.$ctx.val(),
				clearBtn = '.js-clear-button';

			if (value !== '' && !this.$ctx.closest('.a-basic-input-text').hasClass('js-clear-disabled')) {
				this.$ctx.addClass('js-clear a-basic-input-text--clear');

				if (!this.$ctx.siblings(clearBtn).length) {

					if (this.$ctx.closest('.l-form-general').length > 0) {
						this.$ctx.after('<div tabindex="0" class="js-clear-button" aria-label="Schaltfläche Eingabe löschen" role="button"><span></span></div>');
					}
					else {
						this.$ctx.after('<div class="js-clear-button"></div>');
					}

					// clear event logic
					this.$ctx.siblings(clearBtn).on('click touch', () => {

						that.$ctx
							.removeClass('js-clear a-basic-input-text--clear is-hovered')
							.val('')
							.trigger('change');

						// delay to prevent suggestion box from flashing while trigger.change() is still running and we call focus
						// => currently used if minChar === '0' otherwise delay.time is 0ms (see default declaration)
						S.Utils.delayed(`basicInputTextAutocomplete_${that.$ctx.data('t-id')}.focusInputAfterClearing`, that.focusDelay, () => {
							that.$ctx.focus();
						});

						// emit reset
						if (that.$ctx.closest('.m-basic-keywordfilter').length) {
							that._events.emit('BasicKeywordfilter.reset');
						}
					});

					this.$ctx.siblings(clearBtn).on('keydown', (event) => {
						// on ENTER
						if (event.which === 13) {
							$(event.currentTarget).trigger('click');
						}
					});
				}
			}

			else {
				this.$ctx.removeClass('js-clear a-basic-input-text--clear');
				this.$ctx.siblings(clearBtn).remove();
			}
		},

		/**
		 * import json data, either via url or data-attr. or as server-request
		 *
		 * and specifies json-format
		 *
		 **/
		// eslint-disable-next-line
		importData: function () { //NOSONAR returen

			const that = this,
				pathToUrl = this.$ctx.data('searchurl'),
				pathToData = this.$ctx.data('searchdata'),
				localrequest = this.$ctx.data('localrequest'),
				jsonformat = this.$ctx.data('jsonformat');

			let autoCompMod = 'default';

			// import JSON Data from Server/URL
			if (typeof pathToUrl !== 'undefined') {

				// load complete dataset
				if (localrequest) {

					$.getJSON(pathToUrl, (result) => {
						$.each(result, () => {
							// get each entry as array with two keys (text and identifier) and push it to globalArray
							that.receivedData = T.Utils.Autocomplete.mapJsonResponse(jsonformat, result);
						});
					});
				}

				else {
					autoCompMod = 'serverrequest';
				}

			}

			// get JSON Data from data-attr.
			else if (typeof pathToData !== 'undefined') {
				that.receivedData = pathToData.suggestions.map((item) => {
					return [item.key, item.value];
				});
			}

			else {
				console.warn("AUTOCOMPLETE DATA NOT FOUND!");
				return false;
			}

			that.initAutocomplete(autoCompMod);
		},

		/**
		 * init autocomplete if data exists
		 *
		 * @param mod {String} - special case for large data-collections ( default | serverrequest )
		 *
		 **/
		// eslint-disable-next-line
		initAutocomplete: function (mod) { //NOSONAR COmplexity

			const that = this;

			let caching = true,
				xhr;

			// remove suggestion wrapper that was added in an prev call (e.g. if this needs to be reinitialised, we have to clean up)
			$('body').find(`> .js-suggestion-${that.$ctx.attr('id')}`).remove();

			// add input id to suggestion wrapper (as a class!) to get a unique selector for further usage
			// if suggestboxmodifier is defined, add value to class as a modifier to enable styling via css
			if (typeof that.$ctx.data('suggestboxmodifier') !== 'undefined') {
				that.suggestionID = `autocomplete-suggestions--${that.$ctx.data('suggestboxmodifier')} js-suggestion-${that.$ctx.attr('id')}`;
			}

			else {
				that.suggestionID = `js-suggestion-${that.$ctx.attr('id')}`;
			}

			// get custom min chars
			if (typeof that.$ctx.data('charcount') === 'number') {
				that.charCount = that.$ctx.data('charcount');

				if (that.charCount === 0) {
					that.focusDelay = 200;
				}
			}

			// get custom no cache option
			if (typeof that.$ctx.data('nocache') !== 'undefined' && that.$ctx.data('nocache')) {
				caching = false;
			}

			// set no cache option if no result is set
			if (this.$noResultWrapper.length) {
				caching = false;
			}

			that.$ctx.autoComplete({
				minChars: that.charCount,
				menuClass: that.suggestionID,
				cache: caching,
				source: (term, response) => {

					let matches = [];
					const lowerCaseTerm = term.toLowerCase();

					if (mod === 'serverrequest') {

						// add search term to request url
						const jsonformat = this.$ctx.data('jsonformat');
						const requestURL = T.Utils.Autocomplete.formatUrl(jsonformat, this.$ctx.data('searchurl'), term);

						$(document.querySelector(`body > .autocomplete-suggestions.${that.suggestionID}`)).hide();

						// checks for currently running ajax calls and aborts them, if a new request is triggered
						// only the most current request is executed
						if (xhr && typeof (xhr) !== 'undefined') {
							try { xhr.abort(); } catch (e) { }
						}

						xhr = $.ajax({
							beforeSend: function (request) {
								if (typeof that.$ctx.data('subscription-key') !== 'undefined') {
									request.setRequestHeader('Ocp-Apim-Subscription-Key', that.$ctx.data('subscription-key'));
								}
							},
							dataType: "json",
							url: requestURL,
							data: { q: term },
							success: (data) => {
								matches = T.Utils.Autocomplete.mapJsonResponse(jsonformat, data);

								// trigger no result view
								if (!matches.length && that.$noResultWrapper.length) {
									that.addCustomElements(this.noResultOpen, that.$ctx.attr('id'), undefined);
								}

								// close no result if match was found
								else if (matches.length && that.$noResultWrapper.length) {
									that.addCustomElements(this.noResultClose, that.$ctx.attr('id'), undefined);
								}

								response(matches);
							}
						}).fail((error) => {
							// trigger no result view in any error case(!)
							if (that.$noResultWrapper.length) {
								that.addCustomElements(this.noResultOpen, that.$ctx.attr('id'), undefined);
							}

							T.Utils.Autocomplete.handleJsonError(that.$ctx, error);
						});
					}

					else {

						if (that.receivedData.length) {
							for (let i = 0; i < that.receivedData.length; i++) {
								if (~that.receivedData[i][0].toLowerCase().indexOf(lowerCaseTerm)) {
									matches.push(that.receivedData[i]);
								}
							}
						}

						else if (that.receivedData.suggestions.length) {
							for (let i = 0; i < that.receivedData.suggestions.length; i++) {
								if (~that.receivedData.suggestions[i].key.toLowerCase().indexOf(lowerCaseTerm)) {
									matches.push(that.receivedData.suggestions[i]);
								}
							}
						}

						// trigger no result view
						if (!matches.length && that.$noResultWrapper.length) {
							that.addCustomElements(this.noResultOpen, that.$ctx.attr('id'), undefined);
						}

						// close no result if match was found
						else if (matches.length && that.$noResultWrapper.length) {
							that.addCustomElements(this.noResultClose, that.$ctx.attr('id'), undefined);
						}

						response(matches);
					}
				},
				renderItem: (item, search) => {

					search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');// eslint-disable-line
					const re = new RegExp(`(${search.split(' ').join('|')})`, "gi");
					let suggestMod = 'default';

					// if more than one value is shown (for each matched result!)
					if (typeof this.$ctx.data('jsonformat') !== 'undefined') {
						suggestMod = this.$ctx.data('jsonformat');
					}

					// if suggestbutton is set
					if (typeof this.$ctx.data('suggestbuttontext') !== 'undefined') {

						S.Utils.delayed('addCustomElements', 10, () => {
							this.addCustomElements('button', that.$ctx.attr('id'), suggestMod);
						});
					}

					return that.createSuggestElements(suggestMod, re, item);
				},

				onSelect: (e, term, item) => {

					that.itemWasSelected = true;

					if (!e.target.classList.contains('a-basic-btn') && !e.target.classList.contains('has-button')) {
						T.Utils.Autocomplete.handleSelectedItem(e, this.$ctx, this.$ctx.data('jsonformat'), term, item);
						// add selected string to input field and item-id as data-attr. for further usage
						this.$ctx.data('selectedVal', item.data('js-id')).val(item.data('js-val'));

						// trigger change for other js.snippets
						// Hint: In case of minChar = 0, the 'change' listener won't fire always onSelect().
						// The clear button is not rendered either so we can ask for it to know if change was triggered
						if (!this.$ctx.hasClass('js-clear')) {
							this.$ctx.trigger('change');
						}
					}

					else {
						if (e.target.classList.contains('a-basic-btn')) {
							window.location = `${$(e.target).attr('href')}`;
						}

						S.Utils.delayed('blurInputOnButton', 30, () => {
							this.$ctx.eq(0).blur();
						});
					}
				}
			});

			this.addEvents();
		},

		/**
		 * create suggested list element
		 *
		 * @param mod {String} - default or other for special purposes
		 *
		 * @param re {Object} - regex
		 *
		 * @param item {Object} - matching object
		 *
		 **/
		createSuggestElements: function (mod, re, item) {

			const that = this;

			if (typeof item[0] === 'undefined') {
				item[0] = item.key;
			}

			S.Utils.delayed('basicInputTextAutocomplete.suggestionShownBuffer', 5, () => {
				$(document).trigger(`basicInputTextAutocomplete.suggestionShown_${that.$ctx.data('t-id')}`);
			});

			// reset selection-var
			that.itemWasSelected = false;

			let newElem = T.Utils.Autocomplete.createSuggestElements(mod, re, item);
			const dataJsId = '" data-js-id="';
			const autocompleteClass = 'autocomplete-suggestion autocomplete-suggestion--';
			const matchedStringSpan = '<span class="aa-matched-string">$1</span>';

			if (newElem) {
				return newElem;
			}

			// Stuff if T.Utils.Autocomplete.createSuggestElements() didnt return a newElem
			if (mod === 'puzzlegallery') {

				const toStringVar = item[0].toString();
				const splitpos = toStringVar.indexOf(":");
				const name = toStringVar.substring(0, splitpos);
				const occupation = toStringVar.substring(splitpos + 2);

				newElem = `<div class="${autocompleteClass}${mod}" data-js-val="${name}${dataJsId}${item[1]}"><img src="${item[2]}" alt=""><span class="aa-resultlist"><strong>${name.replace(re, matchedStringSpan)}</strong></span><p>${occupation}</p></div>`;
			}

			else if (mod === 'rufdestination') {

				const rawKey = item[0].toString(),
					splitpos = rawKey.indexOf(":");

				let name = item[0],
					additional = '&nbsp;';

				if (splitpos > 0) {
					name = rawKey.substring(0, splitpos);
					additional = additional + rawKey.substring(splitpos + 2);
				}

				newElem = `<div class="${autocompleteClass}${mod}" data-js-val="${name}${dataJsId}${item[1]}"><img src="${item[2]}" alt=""><span class="aa-resultlist">${name.replace(re, matchedStringSpan)}${additional}</span></div>`;
			}

			else {
				newElem = `<div class="autocomplete-suggestion" data-js-val="${item[0]}${dataJsId}${item[1]}">${item[0].replace(re, matchedStringSpan)}</div>`;
			}

			return newElem;

		},

		/**
		 * adds events like blur on keydown enter or click events to select a button
		 * or hide the no result elem
		 *
		 */
		// eslint-disable-next-line
		addEvents: function () { //NOSONAR Complexity

			const that = this;
			let $suggestWrapper = null;

			$('.autocomplete-suggestions').each((index, item) => {
				if ($(item).hasClass(that.suggestionID)) {
					$suggestWrapper = $(item);
				}
			});

			// get a valid info about the autocomplete state
			// suggestWasOpen is set to true if the box was closed not less than 200ms ago
			that.$ctx.on('blur.autocomplete', () => {

				if (!that.$noResultWrapper.is(':visible') && !that.itemWasSelected) {
					that.suggestWasOpen = true;

					S.Utils.delayed('autocomplete.getRealOpeningState', 200, () => {
						that.suggestWasOpen = false;
					});
				}

				else if (that.$noResultWrapper.is(':visible')) {
					that.addCustomElements(this.noResultClose, that.$ctx.attr('id'), undefined);
				}
			});


			that.$ctx.on('keydown', (e) => {

				// enter press
				if (e.which === 13) {
					if (that.selectOnEnter && $suggestWrapper.is(':visible')) {
						e.preventDefault();
						if (!$suggestWrapper.find('> .selected').length) {
							that.selectEntry($suggestWrapper, e);
						}
					}

					else {
						$suggestWrapper.hide();
						if (that.$noResultWrapper.length) {
							that.addCustomElements(this.noResultClose, that.$ctx.attr('id'), undefined);
						}
					}
				}

			});


			if (that.$noResultWrapper.length) {

				// hide no result message if charcount limit is reached again (user deleted chars)
				that.$ctx.on('input', () => {
					if (that.$ctx.val().length < that.charCount && that.$noResultWrapper.is(':visible')) {
						that.addCustomElements(this.noResultClose, that.$ctx.attr('id'), undefined);
					}
				});

				if (that.$noResultWrapper.find('a')) {
					that.$noResultWrapper.find('a').on('mousedown', (e) => {
						const targetRef = $(e.target).attr('href');
						that.addCustomElements(this.noResultClose, that.$ctx.attr('id'), undefined);

						S.Utils.delayed('noresultLinkClick', 100, () => {
							window.location = `${targetRef}`;
						});
					});
				}
			}

			that.$ctx.parents('form').on('submit', (e) => {
				if (that.$ctx.val().length && !that.$noResultWrapper.is(':visible') && that.itemWasSelected === false) {
					that.selectEntry($suggestWrapper, e);
				}
			});

			this.resize($suggestWrapper);


		},

		/**
		 *
		 * add custom elements like buttons to suggestbox
		 *
		 * @param mod {String} - noresult-open | noresult-close | button
		 *
		 * @param elemID - id of input to select proper box
		 *
		 * @param suggestMod {String} - used to add a class to the added elements
		 */
		addCustomElements: function (mod, elemID, suggestMod) {

			const that = this,
				$suggestWrapper = $(`.js-suggestion-${elemID}`),
				noResult = 'is-noresult';

			if (mod !== this.noResultClose) {
				S.Utils.delayed('basicInputTextAutocomplete.suggestionShownBuffer', 5, () => {
					$(document).trigger(`basicInputTextAutocomplete.suggestionShown_${that.$ctx.data('t-id')}`);
				});

			}

			if (mod === this.noResultOpen) {
				that.$ctx.parent().addClass(noResult);

				if (that.$mainStageParent !== null && !S.Utils.Helper.mq('tablet').matches) {
					that.$mainStageParent.addClass(noResult);
				}

				that.$noResultWrapper.show();

			}

			if (mod === this.noResultClose) {
				that.$noResultWrapper.hide();
				that.$ctx.parent().removeClass(noResult);

				if (that.$mainStageParent !== null) {
					that.$mainStageParent.removeClass(noResult);
				}
			}

			// Button Stuff
			if (mod === 'button') {
				const buttonText = that.$ctx.data('suggestbuttontext');
				let buttonLink = '#';

				if (typeof that.$ctx.data('suggestbuttonhref') !== 'undefined') {
					buttonLink = that.$ctx.data('suggestbuttonhref');
				}

				const buttonElem = `<div class="autocomplete-suggestion autocomplete-suggestion--${suggestMod} has-button"><a class="a-basic-btn h-space-s" data-t-name="BasicBtn" href="${buttonLink}">${buttonText}</a></div>`;
				$suggestWrapper.addClass('has-button').append(buttonElem);
			}
		},

		/**
		 *
		 * select 1st entry on different (key-)events
		 *
		 * @param $suggestWrapper - autocomplete parent wrapper
		 *
		 * @param e - fired event
		 *
		 */
		selectEntry: function ($suggestWrapper, e) {

			const that = this;

			const $firstEntry = $suggestWrapper.find('> .autocomplete-suggestion').eq(0);

			T.Utils.Autocomplete.handleSelectedItem(e, that.$ctx, that.$ctx.data('jsonformat'), that.$ctx.val(), $firstEntry);

			// add selected string to input field and item-id as data-attr. for further usage
			that.$ctx.data('selectedVal', $firstEntry.data('js-id')).val($firstEntry.data('js-val'));

			S.Utils.delayed('selectOnEnterHide', 10, () => {
				$suggestWrapper.hide();
			});

		},


		/**
		 *
		 * @param $suggestWrapper - autocomplete parent wrapper
		 */
		resize: function ($suggestWrapper) {
			$(window).on('resize', () => {

				S.Utils.Helper.mq('desktop').addListener(() => {
					this.resizeAction($suggestWrapper);
				});

				S.Utils.Helper.mq('tablet').addListener(() => {
					this.resizeAction($suggestWrapper);
				});
			});
		},

		/**
		 *
		 * @param $suggestWrapper - autocomplete parent wrapper
		 */
		resizeAction($suggestWrapper) {
			const that = this;

			S.Utils.delayed('hideSuggestionsOnResize', 50, () => {
				$suggestWrapper.hide();
				if (that.$noResultWrapper.length) {
					that.addCustomElements(this.noResultClose, that.$ctx.attr('id'), undefined);
				}
			});
		}

	});
}(jQuery));
