(function ($) {
    'use strict';
    /**
     * BasicCalculatorSlider module implementation.
     *
     * @author  <f.schrottenloher@edelweiss72.de>
     * @namespace T.Module
     * @class BasicCalculatorSlider
     * @extends T.Module
     */
    T.Module.BasicCalculatorSlider = T.createModule({
        start: function (resolve) {
            const $ctx = $(this._ctx),
                initData = $ctx.data('init');

            this._setupSlider($ctx, initData);
            resolve();
        },

        /**
         * Slider init function
         *
         * @param $ctx current context
         * @param initData data to initialize the slider
         */

        _setupSlider: function ($ctx, initData) {

            const sliderOptions = T.Utils.Store.get(`BasicCalculatorSlider${initData.eventname}`, 'session');

            if (!sliderOptions) {

                T.Utils.Store.set(`BasicCalculatorSlider${initData.eventname}`, initData, 'session');

                this._initSlider(
                    $ctx, parseInt(initData.min, 10),
                    parseInt(initData.max, 10),
                    parseInt(initData.start, 10),
                    parseInt(initData.step, 10),
                    initData.unit,
                    initData.eventname
                );
            }

            else {

                this._initSlider(
                    $ctx,
                    parseInt(sliderOptions.min, 10),
                    parseInt(sliderOptions.max, 10),
                    parseInt(sliderOptions.start, 10),
                    parseInt(sliderOptions.step, 10),
                    initData.unit,
                    sliderOptions.eventname
                );
            }
        },

        /**
         * Slider init function
         *
         * @param $ctx current context
         * @param min slider min value
         * @param max slider max value
         * @param start handle start value
         * @param step handle/slider step value
         * @param unit measurement
         * @param event decorator specific event name
         */
        _initSlider: function ($ctx, min, max, start, step, unit, event) {

            const slider = $ctx.find('.js-slider').get(0),
                io = $ctx.find('.js-result').get(0),
                that = this;
            let curVal = 0;

            noUiSlider.create(slider, { // eslint-disable-line
                range: { 'min': min, 'max': max },
                start: [start],
                step: step

            });

            slider.noUiSlider.on('start', function () {
                slider.noUiSlider.updateOptions({
                    range: {
                        'min': min,
                        'max': max
                    },
                    step: step
                });
            });

            slider.noUiSlider.on('update', function (values, handle) {
                io.value = `${parseInt(values[handle])} ${unit}`;
                that._events.emit(`BasicClusterBox.change${event}`, $ctx);
                that._events.emit(`FdlCalculator.change${event}`, $ctx);

                const sliderOptions = T.Utils.Store.get(`BasicCalculatorSlider${event}`);
                if (sliderOptions) {
                    sliderOptions.start = slider.noUiSlider.get();
                    T.Utils.Store.set(`BasicCalculatorSlider${event}`, sliderOptions, 'session');
                }
            });

            slider.noUiSlider.on('set', function () {
                that._events.emit(`FdlCalculator.set${event}`, $ctx);
            });

            io.addEventListener('change', function () {
                slider.noUiSlider.set(this.value);
            });

            $(io).on('focus', function () {

                slider.noUiSlider.updateOptions({
                    range: {
                        'min': min,
                        'max': max
                    },
                    step: 1
                });

                curVal = $(this).val();
                $(this).val('');
            });

            $(io).on('blur', function () {
                if (this.value !== '') {
                    const sliderOptions = T.Utils.Store.get(`BasicCalculatorSlider${event}`);
                    if (sliderOptions) {
                        sliderOptions.start = slider.noUiSlider.get();
                        T.Utils.Store.set(`BasicCalculatorSlider${event}`, sliderOptions, 'session');
                    }

                    slider.noUiSlider.set(this.value);

                    that._events.emit(`FdlCalculator.change${event}`, $ctx);
                    that._events.emit(`FdlCalculator.set${event}`, $ctx);
                } else {
                    slider.noUiSlider.set(curVal);
                }
            });
        }

    });

}(jQuery));

if (typeof module !== "undefined" && module.exports) {
    module.exports = T.Module.BasicCalculatorSlider;
}