/**
 * LoginTracking module implementation.
 *
 * @author  <marc.radziwill@namcis.com>
 * @class LoginTracking
 * @extends BasisComponent
 */

// eslint-disable-next-line no-undef
class LoginTracking extends BasisComponent {

	authEvents = ['msal:loginSuccess', 'msal:acquireTokenSuccess', 'msal:ssoSilentSuccess'];
	send = false;
	_connectedCallback() {
		this.$ctx = jQuery(this);

		this._updateDwhCookie();
		jQuery(document).ready(async () => {
			if (await T.Utils.Auth.wasEventTriggered(this.authEvents)) {
				await this.init();
			} else {
				T.Utils.Auth.addEventHandler(this.authEvents, this.init.bind(this));
			}
		});
	}

	async init() {
		T.Utils.Application.init();
		if (!this.send) {
			this.send = true;
			if (await T.Utils.Auth.isLoggedIn()) {
				this._transportLoginTrackingInformation();
			} else {
				jQuery(document).on('identityloaded', this._transportLoginTrackingInformation.bind(this));
			}
		}
	}

	_updateDwhCookie() {
		T.Utils.Helper.isConsentMatch("Data Warehouse", (consentStatus) => {
			if (this.$ctx.data('rawcookie') && consentStatus) {
				// older APIL cannot read encoded cookies
				let domain = location.hostname.replace(/^[^.]+\./, ".");
				domain = domain === location.hostname ? "" : `; domain=${domain}`;
				document.cookie = `Consents=dwh:${consentStatus}${domain};path=/`;
			} else if (consentStatus) {
				T.Utils.Store.set('Consents', `dwh:${consentStatus}`, T.Utils.Store.COOKIE, -1, true);
			} else {
				//remove the cookie if consent is false
				T.Utils.Store.set('Consents', `dwh:${consentStatus}`, T.Utils.Store.COOKIE, 0, true);
			}
		});
	}

	_transportLoginTrackingInformation() {
		T.Utils.Helper.isConsentMatch("Data Warehouse", async (consentStatus) => {

			if (await T.Utils.Auth.isLoggedIn()) {
				this.config = this.$ctx.data('config');

				if (this._isTpEnabled()) {
					if (consentStatus) {
						this._proceedWithConsent();
					} else {
						T.Utils.Auth.getResolvedIdentity(this._handleUserRejection.bind(this));
					}
				} else {
					if (consentStatus) {
						this._proceedWithConsent();
					}
				}
			}
		});
	}

	_proceedWithConsent() {
		const tpActive = this.$ctx.data('tp');
		const dwhSwitch = this.$ctx.data('dwh');
		const dwhActive = dwhSwitch && dwhSwitch !== '' ? dwhSwitch : false;
		if (dwhActive || tpActive) {
			T.Utils.Auth.getResolvedIdentity(this._handleIdentity.bind(this, dwhActive, tpActive));
		}
	}

	_handleUserRejection(identity) {
		const tpConfig = {
			ApiUrl: this.config.TpApiUrl
		};
		const tpConfigRejection = { ...this.config, ...tpConfig };
		T.Utils.PageInfo.callEndpoint(undefined, undefined, identity, tpConfigRejection);
	}

	_handleIdentity(dwhActive, tpActive, identity) {
		T.Utils.PageInfo.callEndpoint(dwhActive, tpActive, identity, this.config);
	}

	_isTpEnabled() {
		return this.config && this.config.TpApiUrl;
	}
}
window.customElements.define('login-tracking', LoginTracking);