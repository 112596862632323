(function ($) {
    'use strict';

    /**
     * BasicStatusNav module implementation.
     *
     * @author  <w.eberts@edelweiss72.de>
     * @namespace T.Module
     * @class BasicStatusNav
     * @extends T.Module
     */
    T.Module.BasicStatusNav = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $indicator: null,

        /** @type {jQuery} */
        $container: null,

        /** @type {jQuery} */
        $steps: null,

        /** @type {Number} */
        lastStep: null,

        /** @type {Number} */
        lastPercent: 0,

        /** @type {MediaQueryList} */
        tablet: null,

        /** @type {jQuery} */
        statusNav: null,

        /** @type {jQuery} **/
        $lForm: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start(resolve) {
            this.$ctx = $(this._ctx);

            if (this.$ctx.siblings('.l-form').length) {

                this.$statusNav = $('.m-basic-status-nav').eq(0);
                this.$indicator = this.$statusNav.find('.js-indicator');
                this.$container = this.$statusNav.find('.js-steps');
                this.$steps = this.$statusNav.find('.js-step');
                this.$lForm = this.$ctx.siblings('.l-form').eq(0);

            }
            else if (this.$ctx.next('.o-basic-form').length) {

                this.$statusNav = this.$ctx;
                this.$indicator = this.$ctx.find('.js-indicator');
                this.$container = this.$ctx.find('.js-steps');
                this.$steps = this.$ctx.find('.js-step');

            }

            // mediaquery
            this.tablet = S.Utils.Helper.mq('tablet', true);
            this.tablet.addListener(this.onMediaQuery.bind(this));

            // initialize
            const step = this.$statusNav.data('step'),
                percent = this.$statusNav.data('percent');

            if (step || percent) {
                this.setStatus(percent, step, 0);
            }

            this._events.on('BasicStatusNav.setStatus', this.setStatus.bind(this));

            resolve();
        },

        /**
         * This media query event is triggered when it switch from/to tablet.
         *
         * @param {MediaQueryList} mq
         */
        onMediaQuery(mq) // eslint-disable-line
        {
            // adjust status on resize smartphone <--> tablet
            this.setStatus(this.lastPercent, this.lastStep, 0);
        },

        /**
         *
         * @param {Number|String} [duration='slow']
         */
        calcStep(formContainer, duration = 'slow') {
            let activeStep = 0;
            const scrollTop = $(window).scrollTop(),
                $steps = formContainer.find('[data-step]');

            $steps.each((_, step) => {
                const $step = $(step);

                if ($step.offset().top <= scrollTop + 100) {
                    activeStep++;
                }
            });

            this.setStatus(1, Math.max(1, activeStep), duration);
        },

        /**
         * Animate or set the status indicator.
         *
         * Usage:
         * <pre>
         *   // 100% of step 1
         *   this.setStatus(1, 1);
         *
         *   // 50% of step 2
         *   this.setStatus(0.5, 2);
         *
         *   // 30% of all steps
         *   this.setStatus(1/3);
         *
         *   // 80% of all steps, no animation
         *   this.setStatus(0.8, null, 0);
         * </pre>
         *
         * @param {Number} percent
         * @param {Number} [step=null]
         * @param {Number|String} [duration='slow']
         */
        setStatus(percent, step, duration = 'slow') {
            let status = 0,
                $step = null;
            const totalWidth = this.$container.innerWidth(),
                that = this;

            if (this.lastPercent === percent && this.lastStep === step) {
                return;
            }

            // percentage is based on step
            if (typeof step === 'number') {
                if (step > this.$steps.length) {
                    $step = this.$steps.last();
                }
                else {
                    $step = this.$steps.eq(step - 1);
                }

                // special handling for smartphone
                if (!this.tablet.matches) {
                    // since steps are not proportial on smartphone, make sure to set active before calculation
                    $step.addClass('is-active');
                    this.$steps.not($step).removeClass('is-active');
                }

                const width = $step.outerWidth(),
                    position = $step.position();

                if (position) {
                    status = (position.left / totalWidth) + percent * (width / totalWidth);
                }
            }
            // total percentage
            else {
                const calcStep = Math.max(0, Math.ceil(percent * this.$steps.length) - 1);

                $step = this.$steps.eq(calcStep);
                status = percent;
            }

            // set active step
            $step.addClass('is-active');
            this.$steps.not($step).removeClass('is-active');

            // keep values
            this.lastStep = step;
            this.lastPercent = percent;

            this.$indicator.stop().animate(
                {
                    width: `${Math.min(100, status * 100)}%`
                },
                duration
            );

            $(window).on('scroll', function () {
                if (that.$lForm) {
                    that.calcStep(that.$lForm);
                }
            });

        }
    });
}(jQuery));