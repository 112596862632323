(function ($) {
	'use strict';
	/**
	 *
	 * @author  Lars Meyer <l.meyer@mgo360.de>
	 * @namespace T.Module
	 * @class BasicBtn.Toggle
	 * @extends T.Module
	 */
	T.Module.BasicBtn.Toggle = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$toggleLabelDefault: null,

		/** @type {jQuery} */
		$toggleLabelActive: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.$toggleLabelDefault = this.$ctx.attr('data-title-labeldefault');
			this.$toggleLabelActive = this.$ctx.attr('data-title-labelactive');

			this.addEventListener();

			this._parent.start(resolve);
		},

		/**
		 * add event listeners
		 */
		addEventListener() {
			this.$ctx.on('click.basicBtnToggleClick', (e) => {
				e.preventDefault();
				this.initToggle('default');
			});

			this._events.on('myAdadDashboardBox.allExpanded', () => {
				this.initToggle('labelactive');
			});

			this._events.on('myAdadDashboardBox.someCollapsed', () => {
				this.initToggle('labeldefault');
			});
		},

		/**
		 * init toggle logics:
		 *
		 * toggle active class and emit state for other comps in mod==='default'
		 *
		 * set activity depending on calls from other comps without retriggering the own emitters
		 *
		 * @param mod {String} - 'default' || 'labeldefault' || 'labelactive'
		 *
		 */
		initToggle(mod) {
			if (mod !== 'default') {

				if (mod === 'labeldefault' && this.$ctx.hasClass('is-active')) {
					this.$ctx
						.removeClass('is-active')
						.text(this.$toggleLabelDefault)
						.attr('title', this.$toggleLabelDefault);
				}

				if (mod === 'labelactive' && !this.$ctx.hasClass('is-active')) {
					this.$ctx
						.addClass('is-active')
						.text(this.$toggleLabelActive)
						.attr('title', this.$toggleLabelActive);
				}
			}

			else {

				this.$ctx.toggleClass('is-active');

				if (this.$ctx.hasClass('is-active')) {

					this.$ctx
						.text(this.$toggleLabelActive)
						.attr('title', this.$toggleLabelActive);

					this._events.emit('basicBtnToggle.toggleOpenAll');
				}

				else {
					this.$ctx
						.text(this.$toggleLabelDefault)
						.attr('title', this.$toggleLabelDefault);

					this._events.emit('basicBtnToggle.toggleCloseAll');
				}
			}
		}
	});
}(jQuery));
