(function ($) {
	'use strict';
	/**
	 * FormMembership module implementation.
	 *
	 * @author Marc Radziwill <marc.radzi@gmail.com>
	 * @namespace T.Module
	 * @class FormMembership
	 * @extends T.Module
	 */
	T.Module.FormMembership = T.createModule({
		start: function (resolve) {
			const $ctx = $(this._ctx);

			this._eventListener($ctx);
			this._initForm($ctx);

			resolve();
		},

		// eslint-disable-next-line no-unused-vars
		_initForm: function ($ctx) {
		},

		_eventListener: function ($ctx) {
			$ctx.on('click', '.a-basic-btn', this._handleFormSubmit);
		},

		_handleFormSubmit: function (e) {
			e.preventDefault();

			if ($('#dob').val() !== '') {
				const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
				const birthdate = new Date($('#dob').val().replace(pattern, '$3-$2-$1')).toISOString();
				$('#birthdate').val(birthdate);
			}

			const form = $('.o-form-membership');

			T.Utils.Ajax.fragment(
				{
					method: 'POST',
					url: `${form.attr('data-form-target')}/api/Mitgliedschaft`,
					data: form.serialize(),
					contentType: "application/x-www-form-urlencoded;charset=utf-8"
				},
				function () {
					form.html(`<div class='alert success-message'><span>${form.data('success-message')}</span></div>`);
				},
				function (jqxhr) {
					$('.alert').remove();
					$.each(jqxhr.responseJSON.Messages, function () {
						form.prepend(`<div class='alert error-message'><span>${this.Message}</span></div>`);
					});
				});
		}
	});
}(jQuery));
