/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint complexity: off */
(function ($) {
	'use strict';

	/**
	 * BasicInputText module implementation.
	 *
	 * @author  <w.eberts@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputText
	 * @extends T.Module
	 */
	T.Module.BasicInputText = T.createModule({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} $basicInputTextModule */
		$basicInputTextModule: null,

		/** @type {jQuery} $neededStickyFooter */
		$neededStickyFooter: null,

		/** @type {jQuery} $editBtn */
		$editBtn: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) { //NOSONAR
			const that = this;
			const inputTextRightBorder = 'a-basic-input-text--right-border';
			const inputContainer = '.mm-inputcontainer';

			this.$ctx = $(this._ctx);
			this.inputText = '.a-basic-input-text';
			this.inputTextPlain = '.a-basic-input-text--plain';
			this.colData = '.ll-col-data';
			this.formGeneral = '.l-form-general';
			this.$basicInputTextModule = this.$ctx.closest(this.inputText);
			this.$basicInputTextModuleBefore = window.getComputedStyle(document.querySelector(this.inputText), ':before');
			this.$neededStickyFooter = $('.m-basic-form-footer-sticky');

			// validate on modification
			this.$ctx.off('change.basicInputText keyup.basicInputText').on('change.basicInputText keyup.basicInputText', (e) => {
				that.validate(e.which);
			});

			this.$ctx.off('focus.basicInputText').on('focus.basicInputText', (e) => {
				// check if there is sticky footer first
				if (that.$neededStickyFooter.length) {
					that.handleInputOnClickForStickyElements(true, that.$neededStickyFooter);
				}

				this.$basicInputTextModule.addClass('js-focus');

				// case where both input fields need class js-focus (telephone number)
				if ($(e.currentTarget).parent().hasClass(inputTextRightBorder)) {
					$(e.currentTarget).parent().next().addClass('js-focus');
				}
				else if ($(e.currentTarget).parent().prev().hasClass(inputTextRightBorder)) {
					$(e.currentTarget).parent().prev().addClass('js-focus');
					$(e.currentTarget).parent().addClass('is-floating');
				}

				// if in a-basic-input-radio
				if ($(e.target).parents('.a-basic-input-radio').length) {
					const inputRadio = $(e.target).closest('.a-basic-input-radio').find('> input[type="radio"]');
					if (inputRadio.prop('checked') === false) {
						inputRadio.trigger("click");
						setTimeout(function () {
							that.$ctx.focus();
						}, 100);
					}
				}

				if ($(e.target).parents('.m-ves-number-plate').length) {

					const inputCity = $(e.target).closest('.mm-col-1 input');
					const inputLetters = $(e.target).closest('.mm-col-2 input');
					const inputNumbers = $(e.target).closest('.mm-col-3 input');
					const letters = /^[a-zäöüß]+$/i;
					const numbers = /^\d+$/;

					// allow only 'A', 'E' and 'H' for special license plates
					const specCharacters = /^[aeh]+$/i;
					const checkInput = 'keyup change';

					// check the input field of the city column
					inputCity.on(checkInput, () => {
						for (let i = 0; i < inputCity.val().length; i++) {
							if (!inputCity.val()[i].match(letters)) {
								const stringBefore = inputCity.val();
								const stringAfter = stringBefore.substr(0, stringBefore.length - 1);
								inputCity.val(stringAfter);
							}
						}
					});

					// check the input field of the letter column
					inputLetters.on(checkInput, () => {
						for (let i = 0; i < inputLetters.val().length; i++) {
							if (!inputLetters.val()[i].match(letters)) {
								const stringBefore = inputLetters.val();
								const stringAfter = stringBefore.substr(0, stringBefore.length - 1);
								inputLetters.val(stringAfter);
							}
						}
					});

					// check the input field of the number column
					inputNumbers.on('keyup select change', (e) => {
						for (let i = 0; i < inputNumbers.val().length; i++) {
							const stringBefore = inputNumbers.val();
							const stringAfter = stringBefore.substr(0, i);

							//check if input is a number
							if (inputNumbers.val()[i].match(numbers)) {
								// forbid 5 numbers in the license plate
								if (i === 4) {
									inputNumbers.val(stringAfter);
								}
							}
							// check if input is an 'A', 'E' or a 'H'
							else if ((e.which === 72 || e.which === 69 || e.which === 65 || this.taste === 72 || this.taste === 69 || this.taste === 65) && inputNumbers.val().length > 1 && inputNumbers.val()[inputNumbers.val().length - 1].match(specCharacters)) {
								this.taste = 72;

								// check if the letter is on first place of the string
								if (inputNumbers.val()[i] === 0) {
									inputNumbers.val('');
								}

								// check if the letter is on last place of the string
								if ((i + 1) !== inputNumbers.val().length) {
									inputNumbers.val(stringAfter);
								}
							}
							// if input is a special character or an unwanted letter
							else {
								this.taste = '';
								inputNumbers.val(stringAfter);
							}
						}
					});
				}

				// check if we are in .m-basic-calculator-content-box and show desc
				if ($(e.target).closest('.m-basic-calculator-content-box').length && $(e.target).closest(inputContainer).length) {
					$(e.target).closest(inputContainer).nextAll('.mm-description').addClass('is-visible');
				}
			});

			this.$ctx.off('focusout.basicInputText').on('focusout.basicInputText', (e) => {
				// check if there is sticky footer first
				if (that.$neededStickyFooter.length) {
					that.handleInputOnClickForStickyElements(false, that.$neededStickyFooter);
				}

				// case where both input fields need class js-focus (telephone number)
				if ($(e.currentTarget).parent().hasClass(inputTextRightBorder)) {
					$(e.currentTarget).parent().next().removeClass('js-focus');
				}
				else if ($(e.currentTarget).parent().prev().hasClass(inputTextRightBorder)) {
					$(e.currentTarget).parent().prev().removeClass('js-focus');
				}

				this.$basicInputTextModule.removeClass('js-focus');

				// check if we are in .m-basic-calculator-content-box and hide desc
				if ($(e.target).closest('.m-basic-calculator-content-box').length && $(e.target).closest(inputContainer).length) {
					$(e.target).closest(inputContainer).nextAll('.mm-description').removeClass('is-visible');
				}
			});

			if (this.$ctx.closest(this.inputTextPlain).length) {
				this.createTextFieldForInputPlain();
			}

			this._events.on('BasicInputText.eventSentFromButton', this.createTextFieldForInputPlain.bind(this));

			// data init
			if (this.$basicInputTextModule.data('init')) {
				// settings
				const settings = this.$basicInputTextModule.data('init');

				// calendar setting
				if (settings['calendarversion'] && that.$ctx.prop('disabled') === false) {

					// init calendar
					this.calendar(settings);
				}
			}

			if (this.$ctx.data('dateformat')) {
				this.formatDate();
			}

			if (this.$ctx.data('numberize')) {
				this.formatNumber();
			}

			// init geolocation
			if (typeof this.$ctx.data('location') !== 'undefined') {
				this.initGeoLocation();
			}

			// if we clone a calendar field we need to reinitialize it.
			this._events.on('BasicInputText.initializeDate', this.formatDate.bind(this));

			if (this.$ctx.hasClass('js-pw-check')) {
				this.checkPW(this.$ctx, this.$ctx.closest(this.colData).find('.m-basic-list--pw-check'));
			}

			this._events.on(`compReset.${this.$ctx.data('t-id')}`, () => {
				this.clearInput();
			});

			this.checkMultiRow();
			this.initEye();
			this.checkInitValue();
			this.copyValueToText();

			resolve();
		},

		/**
		 * if in mutirow, add class for flex controlling
		 */
		checkMultiRow() {
			if (this.$ctx.closest('.ll-multirow')) {
				const parent = this.$ctx.parent();

				if (parent.hasClass('a-basic-input-text--xs')) {
					this.$ctx.closest('.ll-row').addClass('is-size-xs');
				} else if (parent.hasClass('a-basic-input-text--s')) {
					this.$ctx.closest('.ll-row').addClass('is-size-s');
				} else if (parent.hasClass('a-basic-input-text--m')) {
					this.$ctx.closest('.ll-row').addClass('is-size-m');
				} else if (parent.hasClass('a-basic-input-text--l')) {
					this.$ctx.closest('.ll-row').addClass('is-size-l');
				} else if (parent.hasClass('a-basic-input-text--xl')) {
					this.$ctx.closest('.ll-row').addClass('is-size-xl');
				}
			}
		},

		checkInitValue() {
			this.$ctx.on('change', () => {
				if (this.$ctx.val() === '') {
					this.$ctx.addClass('is-empty');
				}
				else {
					this.$ctx.removeClass('is-empty');
				}
			});
		},

		/**
		 * handles the hiding process for the sticky navi with content
		 * @param {Boolean} hide -> true | show -> false
		 * @param {jQuery} $neededStickyFooter
		 *                      - its better to do the query in each inputModule and deliver it to the HideStickContent,
		 *                        because u need to check for a given sticky module to call this function - to prevent unecassery function calls
		 *                      - so u got the element here already, than just transfer it to the HideStickContent
		 */
		handleInputOnClickForStickyElements(hide = false, $neededStickyFooter) //NOSONAR
		{
			// hide footer
			if (hide) {
				S.Utils.HideStickyContent.handleStickyContent(this.$ctx, $neededStickyFooter);

				return;
			}

			// show footer
			S.Utils.HideStickyContent.showStickyFooter($neededStickyFooter);
		},

		copyValueToText() {
			const inputsToCopy = $('input[data-copy-content="sendmailcopy"]');

			inputsToCopy.each((index, element) => {
				setTimeout(() => {
					this.manageCopyInputContent($(element));
				}, 100);
			});

			inputsToCopy.on('change', (element) => {
				this.manageCopyInputContent($(element.currentTarget));
			});
		},

		manageCopyInputContent(element) {
			const target = element.data('copy-content');
			$(`*[data-copy-content-target="${target}"]`).html(element.val());

			if (element.val().length === 0) {
				$(`*[data-copy-content-parent="${target}"]`).hide();
			}
			else {
				$(`*[data-copy-content-parent="${target}"]`).show();
			}
		},

		/*
		 * Check for Input Plain Fields, create content span the first time, else rewrite the content
		 */
		createTextFieldForInputPlain(item) {  //NOSONAR
			let $item;
			let $counter;
			const newForm = $('.ll-fieldset.js-fieldset-edit').parent().parent().parent().parent();
			const plainContentClass = '.js-plain-content';


			if (newForm.find(this.formGeneral).length || newForm.hasClass('l-form-general')) {
				if (item && $counter === 1) {
					$item = item;
				}
				else {
					$item = newForm;
				}

				$counter = 1;
			}
			else {
				if (item && $counter === 0) {
					$item = item;
				}
				else {
					$item = $('.l-form');
				}

				$counter = 0;
			}

			$item.find('.js-row ').each((index, jsRow) => {
				if ($(jsRow).find('.a-basic-input-text--plain input').length) {
					if ($(jsRow).find(plainContentClass).length) {
						let contentText = '';

						if ($counter === 1) {
							if ($(jsRow).find(this.inputTextPlain).hasClass('is-disabled')) {
								$(jsRow).find(plainContentClass).addClass('is-data-only');
								$(jsRow).addClass('ll-row--data-only');
							}
							else {
								$(jsRow).find(plainContentClass).removeClass('is-data-only');
							}
						}

						$(jsRow).find('.a-basic-input-text--plain input').each((itemIndex, subitem) => {
							contentText += `${$(subitem).val()} `;
						});

						$(jsRow).find(plainContentClass).text(contentText.trim());
					}
					else {
						$(jsRow).find(this.colData).prepend('<span class="js-plain-content"></span>');

						$(jsRow).find(this.inputTextPlain).each((index, subItem) => {
							$(subItem).siblings(plainContentClass).text($(subItem).siblings(plainContentClass).text() + (index > 0 ? " " : "") + $(subItem).find('input').val());
						});
					}
				}

				$(jsRow).show();
			});
		},

		validate: function () {
			const value = this.$ctx.val();
			const clearButtonClass = '.js-clear-button';

			if (value !== '' && !this.$basicInputTextModule.hasClass('js-clear-disabled')) {
				this.$ctx.addClass('js-clear a-basic-input-text--clear');

				if (!this.$ctx.siblings(clearButtonClass).length) {
					// add clear btn
					this.$ctx.after('<div class="js-clear-button" aria-label="Schaltfläche Eingabe löschen" role="button" tabindex="0"><span></span></div>');

					this.$ctx.siblings(clearButtonClass).on('click touch', (event) => {
						event.preventDefault();
						this.clearInput(true);
					});

					this.$ctx.siblings(clearButtonClass).on('keydown', (event) => {
						// on ENTER
						if (event.which === 13) {
							this.clearInput(true);
						}
					});
				}
			}
			else {
				this.$ctx.removeClass('js-clear a-basic-input-text--clear');
				this.$ctx.siblings(clearButtonClass).remove();
			}
		},

		/**
		 * clearInput
		 *
		 * clear input
		 * @param {Boolean} focus
		 */
		clearInput(focus) {
			this.$ctx.removeClass('js-clear a-basic-input-text--clear is-hovered').val('').trigger('change');
			this.$ctx.closest('.ll-row').removeClass('is-valid');

			// emit reset
			if (this.$ctx.closest('.m-basic-keywordfilter').length) {
				this._events.emit('BasicKeywordfilter.reset');
			}

			if (focus) {
				this.$ctx.focus();
			}
		},


		/**
		 * init calendar
		 */
		calendar: function (settings) { //NOSONAR

			const that = this,
				calendarController = this.$ctx.siblings('.aa-calendar');
			let dateFormat = "dd.mm.yy",
				startingRange = '-100y',
				endingRange = "+1y",
				firstDay = 1;

			if (typeof this.$ctx.data('dateformat') !== 'undefined' && this.$ctx.data('dateformat') !== 'ddmmyyyy') {
				dateFormat = this.$ctx.data('dateformat');
			}

			if (settings["begin"]) {
				startingRange = settings['begin'];
			}

			if (settings["end"]) {
				endingRange = settings['end'];
			}

			// defines day-order (0 => start with sunday, 1 => start with monday)
			if (settings["firstday"]) {
				firstDay = settings['firstday'];
			}

			//Set Date as default Value in Input Field
			if (settings["defaultDate"]) {
				let date;

				if (settings["defaultDate"] === "tomorrow") {
					date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
				} else {
					date = new Date(new Date().getTime());
				}

				const day = date.getDate(),
					month = date.getMonth() + 1,
					value = `${(day < 10 ? '0' : '') + day}.${month < 10 ? '0' : ''}${month}.${date.getFullYear()}`;

				this.$ctx.val(value);
			}

			if (settings["calendarversion"] === 'standard') {

				that.$ctx.datepicker({
					showOn: "none",
					changeMonth: true,
					changeYear: true,
					dateFormat: dateFormat,
					prevText: 'Zurück', prevStatus: '',
					prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: '',
					nextText: 'Vor', nextStatus: '',
					nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
					currentText: 'Heute', currentStatus: '',
					todayText: 'Heute', todayStatus: '',
					clearText: '-', clearStatus: '',
					closeText: 'Schließen', closeStatus: '',
					monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
						'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
					monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun',
						'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
					dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
					dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
					dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
					yearRange: `1900:${endingRange}`,
					minDate: startingRange,
					maxDate: endingRange,
					firstDay: firstDay,
					"showMonthAfterYear": false,
					beforeShow: () => {
						if (S.Utils.Helper.isTouch()) {

							// disable input field to prevent focusing while dp is open
							that.$ctx.addClass('js-disabled');
							that.$ctx.prop('readonly', true);

							if (S.Utils.Helper.isIOS()) {

								// add listener for outside clicks
								$('.l-outer').on('click.outsideClick', (e) => {

									const item = document.querySelector('.ui-datepicker');

									// if clicked outside
									if (!$(item).is(e.target) && !$(item).find('*').is(e.target) && !calendarController.is(e.target)) {
										that.$ctx.datepicker('hide');
									}
								});
							}
						}
					},

					onChangeMonthYear: (year, month) => {
						let currDay = this.$ctx.val().split('.')[0];

						if (currDay === '__' || currDay === '') {
							currDay = 1;
						}

						const value = `${(currDay === 1 ? '0' : '') + currDay}.${month < 10 ? '0' : ''}${month}.${year}`;

						this.$ctx.val(value);
						this.$ctx.datepicker("setDate", value);
						that.$ctx.trigger('change');
					},

					onClose: () => {
						if (S.Utils.Helper.isTouch()) {
							that.$ctx.removeClass('js-disabled');
							that.$ctx.prop('readonly', false);

							$('.l-outer').off('click.outsideClick');

							that._events.emit('BasicInputTextCalendar.closed', that.$ctx);
						}

						if (that.$ctx.next('.ll-depend-change').length) {
							that.$ctx.focus();
						}
					},

					onSelect: () => {
						$(document).trigger('BasicInputText.datechanged');
						that.$ctx.trigger('change');
					}
				});

				$(window).on('resize', function () {
					that.$ctx.datepicker('hide');
				});

				calendarController.on('click', () => {
					if ($(this.formGeneral).length > 0) {
						that.$ctx.datepicker("option", "monthNamesShort", ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']);
						$('.ui-datepicker').addClass('is-new-form');
					}

					if (!calendarController.closest(this.inputText).find('input').prop('disabled')) {
						// delay to enable input-blur and -disabling before dp is shown ( see beforeShow() )
						if (S.Utils.Helper.isTouch()) {
							S.Utils.delayed('showPickerInMobile', 250, () => {
								that.$ctx.datepicker('show');
							});
						}

						else {
							that.$ctx.datepicker('show');
						}
					}
				});
			}

			// config elements for mobile Devices
			if (S.Utils.Helper.isTouch()) {
				// change type to tel on init
				that.$ctx.attr('type', 'tel');
				// add masking
				that.$ctx.off('.mask').mask("99.99.9999");
			}

		},

		/**
		 * Masks the date according to German convention
		 */
		formatDate: function () {  //NOSONAR
			const dataValueAttrStr = 'data-value';
			const inputField = this.$ctx;

			if (inputField.data('dateformat') === 'ddmmyyyy') {
				inputField.prop('placeholder', 'TT.MM.JJJJ');

				// if the used device is a desktop PC or not touch enabled
				if (!S.Utils.Helper.isTouch()) {

					// We need to save the value as data-value, so we can switch from type=text to type=date and vice versa
					if (inputField.val().length > 0) {
						inputField.attr(dataValueAttrStr, inputField.val()).attr('type', 'text').val(inputField.attr(dataValueAttrStr)); //NOSONAR Constant will not work with attributes
					} else {
						inputField.attr('type', 'text');
						if (typeof inputField.attr('value') !== 'undefined') {  //NOSONAR
							if (inputField.attr('value') !== '') {
								inputField.val(inputField.attr('value'));
							}
						}
					}

					// prevents IE11 input.focus on page load resulting in be validation on 1st blur even if user hasnt filled the input (since it was focused on init)
					setTimeout(() => {
						inputField.off('.mask').mask("99.99.9999");
					}, 1000);
				}

				else { // in Touch Device

					if (typeof inputField.attr('value') !== 'undefined') {  //NOSONAR
						if (inputField.attr('value') !== '') {
							inputField.val(inputField.attr('value'));
						}
					}

					// only happens in chrome
					if (!inputField.val() && inputField.attr(dataValueAttrStr)) {
						// if empty
						inputField.off('.mask').val(inputField.attr(dataValueAttrStr).split('.').reverse().join('-'));
					}

					else if (!inputField.val() && inputField.attr('value')) {
						inputField.off('.mask').val(inputField.attr('value').split('.').reverse().join('-'));
					}
				}
			}

			else if (inputField.data('dateformat') === 'mmyyyy' || inputField.data('dateformat') === 'mm.yy') {
				inputField.prop('placeholder', 'MM.JJJJ');

				// prevents IE11 input.focus on page load resulting in be validation on 1st blur even if user hasnt filled the input (since it was focused on init)
				setTimeout(() => {
					inputField.off('.mask').mask("99.9999");
				}, 1000);
			}
		},

		/**
		 * Formats numbers with decimal points
		 */
		formatNumber: function () {

			const inputField = this.$ctx;

			// If data-numberize is set, use jquery-number to format numbers
			if (inputField.data('numberize')) {

				// We only want to allow iOS users and PC users to use jquery-number
				if (S.Utils.Helper.isIOS() || S.Utils.Helper.mq('desktop').matches) {

					if (inputField.data('numberize') === 'float') {
						inputField.number(true, 2, ',', '.');
					} else if (inputField.data('numberize') === 'natural') {
						inputField.number(true, 0, ',', '.');
					}
				}

				S.Utils.Helper.mq('desktop').addListener(function (mq) {
					if (S.Utils.Helper.isIOS() || mq.matches) {
						if (inputField.data('numberize') === 'float') {
							inputField.number(true, 2, ',', '.');
						} else if (inputField.data('numberize') === 'natural') {
							inputField.number(true, 0, ',', '.');
						}
					} else {
						inputField.off();
					}
				});
			}
		},


		/**
		 * init geolocation function
		 */
		initGeoLocation: function () {

			const that = this;

			that.$ctx.prev('.aa-location').eq(0).on('click', () => {

				// check if geolocation is allowed and supported
				if (navigator.geolocation) {

					navigator.geolocation.getCurrentPosition(showPosition);

				}
				else {
					// eslint-disable-next-line no-console
					console.log("Geolocation is not supported by this browser or the access was denied.");  //NOSONAR
				}

				// call show function
				function showPosition(position) {
					that.showGeoPosition(position);
				}
			});
		},

		/**
		 * @Namics this is just a dummy function!
		 *
		 * e.g. parse position in correct format to input field
		 *
		 */
		showGeoPosition: function (position) {

			const that = this;

			const latitudeVal = position.coords.latitude,
				longitudeVal = position.coords.longitude;

			const dummyString = `Lat: ${latitudeVal.toFixed(3)} | Long: ${longitudeVal.toFixed(3)}`;

			that.$ctx.prop('value', dummyString);
		},

		checkPW(pwInput, checkList) {

			pwInput.on('keyup', () => {
				const lowecase = /^[a-z]+$/;
				const uppercase = /^[A-Z]+$/;
				const numbers = /^\d+$/;
				let numValid = false;
				let lowValid = false;
				let upperValid = false;
				let specialValid = false;

				for (let i = 0; i < pwInput.val().length; i++) {
					if (pwInput.val()[i].match(numbers)) {
						numValid = true;
					}
					else if (pwInput.val()[i].match(uppercase)) {
						upperValid = true;
					}
					else if (pwInput.val()[i].match(lowecase)) {
						lowValid = true;
					}
					else {
						specialValid = true;
					}
				}

				if (pwInput.val().length >= 8) {
					checkList.find('li').eq(0).addClass('is-valid');
				}
				else {
					checkList.find('li').eq(0).removeClass('is-valid');
				}

				if (numValid) {
					checkList.find('li').eq(3).addClass('is-valid');
				}
				else {
					checkList.find('li').eq(3).removeClass('is-valid');
				}

				if (lowValid) {
					checkList.find('li').eq(2).addClass('is-valid');
				}
				else {
					checkList.find('li').eq(2).removeClass('is-valid');
				}

				if (upperValid) {
					checkList.find('li').eq(1).addClass('is-valid');
				}
				else {
					checkList.find('li').eq(1).removeClass('is-valid');
				}

				if (specialValid) {
					checkList.find('li').eq(4).addClass('is-valid');
				}
				else {
					checkList.find('li').eq(4).removeClass('is-valid');
				}
			});
		},

		initEye() {
			const eyes = this.$ctx.closest(this.colData).find('.js-eye');

			eyes.on('click', (eye) => {
				$(eye.currentTarget).toggleClass('is-shown');
				if ($(eye.currentTarget).closest(this.colData).find('input').attr('type') === 'password') {
					$(eye.currentTarget).closest(this.colData).find('input').attr('type', 'text');
				}
				else {
					$(eye.currentTarget).closest(this.colData).find('input').attr('type', 'password');
				}
			});
		},

	});
}(jQuery));