(function ($) {
    'use strict';
    /**
     * BasicTop module implementation.
     *
     * @author  <n.bork@edelweiss72.de>
     * @namespace T.Module
     * @class BasicTop
     * @extends T.Module
     */
    T.Module.BasicTop = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        isAnimating: false,

        start: function (resolve) {
            this.$ctx = $(this._ctx);

            // for lexicons only show it on desktop
            this.setEvents($('.m-basic-keywordfilter--lexicon').length);

            resolve();
        },

        /**
         * sets the grey button to scroll to top back
         * @param {Boolean} lexicon -> if true only show this button on desktop, it disturbs the a-z letter swiper
         */
        setEvents: function (lexicon = false) {
            if (lexicon) {
                if (S.Utils.Helper.mq('desktop').matches) {
                    this.$ctx.on('click', () => {
                        this.scrollToTop();
                        return false;
                    });
                }
            }
            else {
                this.$ctx.on('click', () => {
                    this.scrollToTop();
                    return false;
                });

                this.$ctx.on('keyup', (event) => {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                    }
                    return false;
                });
            }

            const initScrollLogic = () => {

                if (lexicon) {
                    if (S.Utils.Helper.mq('desktop').matches) {
                        S.Utils.delayed('a-basic-top', 200, () => {
                            if ($(window).scrollTop() > 200) {
                                this.$ctx.addClass('is-active');
                            }
                            else {
                                this.$ctx.removeClass('is-active');
                            }
                        });
                    }
                }
                else {
                    S.Utils.delayed('a-basic-top', 200, () => {
                        if ($(window).scrollTop() > 200) {
                            this.$ctx.addClass('is-active');
                        }
                        else {
                            this.$ctx.removeClass('is-active');
                        }
                    });
                }
            };

            window.addEventListener('scroll', initScrollLogic, { passive: true });
        },

        scrollToTop() {
            $('body,html').animate({
                scrollTop: 0
            }, 850);
        }
    });
}(jQuery));