/* eslint-disable sonarjs/cognitive-complexity */
(function ($) {
	'use strict';

	/**
	 * BasicTable module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicTable
	 * @extends T.Module
	 */
	T.Module.BasicTable = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Boolean} */
		$isResizing: false,

		/** @type {Number} */
		$lastVisible: -1,

		/** @type {Object} */
		$swiper: null,

		/** @type {Number} */
		$swiperActiveIndex: 0,

		/** @type {Boolean} */
		reverseOrder: false,

		/** @type {Boolean} */
		hasImages: false,

		/** @type {Boolean} */
		$isImage: false,

		/** @type {Number} */
		$cellNum: 0,

		/** @type {Object} */
		$td: [],

		/** @type {jQuery} */
		$clonedTableHeader: null,

		/** @type {Boolean} */
		pageIsInEditMode: null,

		/** @type {Boolean} */
		keywordfilterResultMode: false,

		/** @type {Number} */
		actualStickyHeaderOffset: -1,

		/** @type {jQuery} */
		$oldClickableSortButton: null,

		/** @type {boolean} */
		hasSrc: false,

		/** @type {String} */
		srcText: null,

		/** @type {String} */
		srcImg: null,

		/** @type {String} */
		srcAlt: null,

		/** @type {Boolean} */
		hasAnchor: false,

		/** @type {jQuery} sideNavi */
		sideNavi: null,

		/** @type {jQuery} sideNavi */
		$resetIcon: null,

		/** @type {String} */
		mainBorder: '.js-main-border',

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) //NOSONAR
		{
			this.$ctx = $(this._ctx);
			this.pageIsInEditMode = this.$ctx.closest('body').hasClass('is-page-editor');

			this.setBorder();

			if (this.pageIsInEditMode) {
				this.$ctx.removeAttr('data-t-id');
				return;
			}

			const that = this;
			this.reverseOrder = false;
			this.hasImages = false;
			this.$cellNum = this.$ctx.find('> thead > tr > th').length;

			// classes as strings
			this.anchorNav = '.m-basic-anchornav';
			this.jsTableWrap = '.js-basic-table-wrap';
			this.isLoading = 'is-loading';
			this.stickyWrap = '.mm-sticky-wrap';
			this.adacMapsSticky = '.m-adac-maps--sticky';
			this.isClickable = 'is-clickable';
			this.toggletextHead = '.js-toggletext-head';
			this.tableBreak = 'm-basic-table--break';
			this.tableCompare = 'm-basic-table--compare';
			this.keywordfilterResult = '.js-basickeywordfilter-result';
			this.tableZoom = '.js-table-zoom';
			this.swiperContainerWrap = '.swiper-container-wrap';
			this.marginTop = 'margin-top';
			this.hasStopper = 'has-stopper';
			this.isHighlight = 'is-highlight';
			this.tableRowVisible = '> tbody > tr:visible';
			this.subHeading = 'mm-subheading';
			this.subHeadingClass = '.mm-subheading';
			this.tableRowIsHighlight = 'tr.is-highlight';
			this.bodyTrFirstChildTd = '> tbody > tr:first-child > td';
			this.ariaLabelAttribute = 'aria-label';

			this.$td = this.$ctx.find(this.bodyTrFirstChildTd);
			this.hasAnchor = this.$ctx.closest(this.anchorNav).length;

			// has sideNavi
			this.sideNavi = this.$ctx.closest('.ll-main-center').find('.m-layout-main-nav-left');

			// check if there is a sideNavi -> the setZoom table need other width/left values
			if (this.sideNavi.length) {
				// sticky header clone
				this.$ctx.wrap('<div class="js-basic-table-wrap mm-has-side-navi" />');
			}
			else {
				// sticky header clone
				this.$ctx.wrap('<div class="js-basic-table-wrap" />');
			}

			this._events.on('basicToggleOpen', () => {
				this.checkSize();
			});

			this._events.on('BasicKeywordfilter.filterLayerTrue', () => {
				this.updateAfterFilter();
			});

			this._events.on('BasicKeywordfilter.filterLayerFalse', () => {
				this.updateAfterFilter();
			});

			this._events.on('BasicKeywordfilter.filterDoubleSlider', () => {
				this.updateAfterFilter();
			});

			this._events.on('BasicKeywordfilter.filterContentBySearchFinished', () => {
				this.updateAfterFilter();
			});

			this._events.on('BasicKeywordfilter.reset', () => {
				this.updateAfterFilter('reset');
			});

			this._events.on('RufClimate.finishedHeightAnim', () => {
				if (this.$ctx.closest('.swiper-container-table').length) {

					this.$ctx.closest(this.jsTableWrap).addClass(this.isLoading);

					setTimeout(() => {
						this.resetSticky();
						this.checkSize();
						this.checkRowNumber('default');
					}, 200);
				}
			});

			this._events.on('BasicAnchornav.openLayer', () => {
				setTimeout(() => {
					this.checkSize();
				});
			});

			// listen to click events in m-adac-maps--sticky js-decorator
			if (this.$ctx.closest(this.stickyWrap).length && this.$ctx.closest(this.stickyWrap).find(this.adacMapsSticky).length) {
				this._events.on('AdacMapsSticky.mapTableSwitch', (mod) => {
					if (mod === 'hide') {
						this.$ctx.hide();
					}

					else {
						this.$ctx.show();
					}
				});
			}

			// count rows and set white modifier
			this.checkRowNumber('default');

			// init sort
			this.$ctx.find('.js-sort').parent().on('click', function (e) {
				const infolayer = $(e.target).closest('.m-basic-info-layer').length;

				if (infolayer !== 1 && S.Utils.Helper.mq('tablet').matches) {
					that.initSorting.apply(that, [this]);

					S.Utils.Lightbox.lightbox(that.$ctx);
				}
			});

			if (this.$ctx.find(this.bodyTrFirstChildTd).length > 1) {
				// check if table is bigger than content wrap
				this.checkSize();

				// init resize check
				this.resize();
			}

			// tablet
			if (S.Utils.Helper.mq('tablet').matches) {
				// begin sort functions
				this.$ctx.find('.js-sort').parent().addClass(this.isClickable);

				// init text toggle
				if (this.$ctx.find(this.toggletextHead).length) {
					this.initTextToggle();
				}
			}

			const callCheckHeader = () => {
				S.Utils.delayed(`basicTable-${that.$ctx.data('t-id')}.preventScrollOverflow`, 40, () => {
					this.checkRefreshHeader();
				});
			};

			setTimeout(() => {
				window.addEventListener('scroll', callCheckHeader, { passive: true });

				// add listeners to recheck sticky state on header anim events
				this._events.on('basicHeader.isStuck', () => {
					this.checkRefreshHeader();
				});

				// add listeners to recheck sticky state on header anim events
				this._events.on('basicHeader.isNotStuck', () => {
					this.checkRefreshHeader();
				});

				// listen to header height changes like if nav is opened in reduced mode
				this._events.on('basicHeader.reducedHeightChanged', () => {
					this.checkRefreshHeader();
				});
			}, 200);

			S.Utils.Helper.mq('tablet').addListener((mq) => {
				if (mq.matches) {
					// begin sort functions
					this.$ctx.find('.js-sort').parent().addClass(this.isClickable);

					// init text toggle
					if (this.$ctx.find(this.toggletextHead).length) {
						this.initTextToggle();
					}
				}
				else {
					// from mobile/tablet just reset the table
					if (this.$resetIcon !== null) {
						this.$resetIcon.trigger('click');
					}

					this.$ctx.find('.js-sort').parent().removeClass(this.isClickable);

					if (this.$ctx.hasClass(this.tableBreak)) {
						this.resetSticky();
					}

					// special case compare
					if (this.$ctx.hasClass(this.tableCompare)) {
						this.initStickyHeader(0);
					}

					this.updateStickyHeader();
				}
			});

			if (typeof this.$ctx.data('js-srctext') !== 'undefined') {
				this.srcText = this.$ctx.data('js-srctext');

				if (typeof this.$ctx.data('js-srcimg') !== 'undefined') {
					this.srcImg = this.$ctx.data('js-srcimg');
					this.srcAlt = this.$ctx.data('js-srcalt');
				}

				this.hasSrc = true;
				this.addSrcInfo();

			}

			// call sticky logic on init
			if (this.$ctx.closest(this.stickyWrap).find(this.adacMapsSticky).length) {
				// trigger scroll event to establish a clean sticky state
				setTimeout(() => {
					const lastScrollVal = $(window).scrollTop();

					$(window).scrollTop(lastScrollVal - 1);
				}, 1000);
			}

			this.checkRefreshHeader();

			this.checkSubline();

			resolve();
		},

		/**
		 * add src info and image beneath table
		 */
		addSrcInfo: function () {

			const that = this,
				// build src-wrapper and append src text as span to it
				$srcWrapper = $(`<div class="mm-tablesrc"><span class="mm-tablesrc--text">${that.srcText}</span></div>`);

			// override table margin-class and add it to srcwrapper
			$srcWrapper.addClass(this.getSeparatorClass());
			// not removed bc of swiper logic
			that.$ctx.addClass('has-src');

			if (that.srcImg.length) {
				// build img-elem and add it as last child to src-Wrapper
				const $imgElem = $(`<img src="${that.srcImg}" alt="${that.srcAlt}">`);
				$srcWrapper.append($imgElem);
			}

			// add src-wrapper as next sibling to table (easier than adding it inside of table-tag bc <table> does funky stuff with inline-elems)
			that.$ctx.after($srcWrapper);
		},

		/**
		 * get height values from the header elems to set the sticky offset
		 */
		checkRefreshHeader: function () {
			const that = this,
				$header = $('body > .l-outer > .m-basic-header').eq(0);
			let headerHeight,
				offset = 0;

			if ($($header).hasClass('is-stuck') || S.Utils.Helper.mq('desktop-l').matches) {
				headerHeight = $header.outerHeight();
				offset = headerHeight;
			}

			else {
				headerHeight = 0;
				offset = headerHeight;
			}

			const anchorIndicatorFix = 16;

			if (that.hasAnchor && S.Utils.Helper.mq('tablet').matches) {
				const anchorHeight = that.$ctx.closest(this.anchorNav).find('.mm-anchorlist').outerHeight() + anchorIndicatorFix;
				offset += anchorHeight;
			}

			// exception if table is used with sticky map
			if (that.$ctx.closest(this.stickyWrap).find(this.adacMapsSticky).length) {
				offset += that.$ctx.closest(this.stickyWrap).find(this.adacMapsSticky).outerHeight();
			}
			if (this.actualStickyHeaderOffset !== offset) {
				this.initStickyHeader(offset);
				this.actualStickyHeaderOffset = offset;
			}
		},

		updateAfterFilter: function () {
			if (this.$ctx.closest(this.jsTableWrap).length && this.$ctx.closest(this.keywordfilterResult).length) {
				this.$ctx.closest(this.jsTableWrap).addClass(this.isLoading);

				setTimeout(() => {
					this.resetSticky(true);
					this.checkSize();
				}, 200);
			}
		},

		/**
		 * initStickyHeader
		 *
		 * @param {Number} offsetTop
		 */
		initStickyHeader: function (offsetTop) {
			let stickyAllowed = true;

			setTimeout(() => {
				if (typeof this.$ctx.attr('data-sticky') !== 'undefined' && this.$ctx.data('sticky') === false) {
					stickyAllowed = false;
				}

				if (this.$ctx.closest(this.jsTableWrap).find('.is-clone').length) {
					stickyAllowed = false;
				}

				if (this.$ctx.hasClass(this.tableBreak) && !S.Utils.Helper.mq('tablet').matches) {
					stickyAllowed = false;
				}
				if (this.$ctx.find('> thead').length && stickyAllowed !== false && !this.$ctx.closest(this.jsTableWrap).find('.is-clone').length) {

					if (!this.$clonedTableHeader) {
						this.$clonedTableHeader = this.$ctx.clone(true);
						this.$clonedTableHeader.addClass('is-cloned-header').insertBefore(this.$ctx).wrap('<div class="js-basic-table-header-clone-wrap" />');

						this.$ctx.find('.js-sort').attr('tabindex', -1);
						this.$clonedTableHeader.find('.a-basic-link').attr('tabindex', -1);
					}

					// reassign last scroll pos to prevent scroll events
					// Hint: On detach(), the sticky elem is reinserted into the doc. flow.
					// That will cause a short scroll event, made by the browser, bc the doc is larger
					// than before and the browser tries to stay at the last (visible!) pos. That triggers
					// the header elem to pin
					// => freeze the current scroll pos
					// => Hint: delay name on purpose cause we use this trick in e.g. basic-anchor.js as well
					// => prevents double scroll call cause only the last delayed func is executed

					// store current scroll pos
					const lastScrollVal = $(window).scrollTop();

					this._events.emit('stickyDetachEvent.preventHeaderEvent');

					// detach
					this.$clonedTableHeader.parent().trigger('sticky_kit:detach');

					S.Utils.delayed('freezeWindowAtCurrentScrollPos', 5, () => {
						$(window).scrollTop(lastScrollVal);
					});

					this.$clonedTableHeader
						.parent()
						.show()
						.stick_in_parent({
							sticky_class: 'is-sticky-header',
							offset_top: offsetTop,
							parent: this.$clonedTableHeader.closest(this.jsTableWrap),
						})
						.on('sticky_kit:stick', () => {
							// if in zoom layer => set unset zoom icon sticky too
							this.$clonedTableHeader.closest(this.jsTableWrap).siblings(this.tableZoom).addClass('is-sticky');

						})
						.on('sticky_kit:unbottom', () => {
							// if in zoom layer => set unset zoom icon sticky too
							this.$clonedTableHeader.closest(this.jsTableWrap).siblings(this.tableZoom).addClass('is-sticky');

						})
						.on('sticky_kit:unstick', () => {
							this.$clonedTableHeader.closest(this.jsTableWrap).siblings(this.tableZoom).removeClass('is-sticky');

						})
						.on('sticky_kit:bottom', () => {
							this.$clonedTableHeader.closest(this.jsTableWrap).siblings(this.tableZoom).removeClass('is-sticky');
						});

					// check for checkboxes in cloned body and destroy id and name attributes
					this.$clonedTableHeader.find('tbody input[type="checkbox"]').each((index, item) => {
						if (!$(item).attr('name').match('_cloned')) {
							$(item).attr('name', `${$(item).attr('name')}_cloned`);
							$(item).attr('id', `${$(item).attr('id')}_cloned`);
						}
					});

					this.updateStickyHeader();
				}
			}, 0);
		},

		/**
		 * updateStickyHeader
		 *
		 */
		updateStickyHeader: function () {
			setTimeout(() => {
				if (this.$clonedTableHeader && this.$ctx.find('> thead').length && !this.$ctx.closest(this.jsTableWrap).find('.is-clone').length) {
					// hide js-compare-head for calculating
					this.$ctx.find('.js-compare-head').hide();

					const trHeight = this.$ctx.find('thead').eq(0).outerHeight();

					// show js-compare-head again
					this.$ctx.find('.js-compare-head').show();

					// set width + height
					this.$clonedTableHeader.parent().css({
						width: this.$ctx.outerWidth(),
						height: `${trHeight + 2}px`,
					});

					// set margin top
					if (this.$ctx.closest(this.swiperContainerWrap).length) {
						this.$ctx.css(this.marginTop, '0px');
						this.$ctx.closest(this.swiperContainerWrap).find('.swiper-container-sticky > table').css(this.marginTop, '0px');
					}
					else {
						this.$ctx.css({
							left: 'auto',
							marginTop: `${-trHeight - 2}px`,
						});

						this.$clonedTableHeader.parent().css({
							left: 'auto',
						});

						// hide/show :: if break mode
						if (this.$ctx.hasClass(this.tableBreak) && !S.Utils.Helper.mq('tablet').matches) {
							this.$clonedTableHeader.parent().hide();
							this.$ctx.css(this.marginTop, '0px');
						}
						else {
							setTimeout(() => {
								this.$clonedTableHeader.parent().show();
							}, 200);
						}
					}

					// set padding if stopper
					if (this.$ctx.find('> thead > tr > th .mm-stopper-wrap').length) {
						this.$ctx.closest(this.swiperContainerWrap).addClass(this.hasStopper);
					}

					// remove for basicanchornav fnc -> this fnc ist deprecated
					//that.$clonedTableHeader.parent().trigger('sticky_kit:recalc');
				}
			}, 200);
		},

		/**
		 * checkRowNumber
		 *
		 * @param mod {String} - optional / 'filterUpdate' or 'default'
		 */
		checkRowNumber: function (mod) //NOSONAR
		{
			const that = this,
				tableRow = '> tbody > tr';

			// standard
			if (this.$ctx.find('> tbody > tr:visible, .mm-toggletext-content > table > tbody > tr:visible').length <= 3) {
				if (!this.$ctx.hasClass('m-basic-table--colored')) {
					this.$ctx.addClass('m-basic-table--white');
				}

				if (this.$ctx.closest(this.keywordfilterResult).length) {
					this.$ctx.find(tableRow).removeClass('is-odd is-even');

					// remove hightlighting after filter was updated
					if (mod === 'filterUpdate') {
						this.$ctx.find(tableRow).removeClass(this.isHighlight);
					}
				}
			}

			else {
				if (!this.$ctx.hasClass('js-white')) {
					this.$ctx.removeClass('m-basic-table--white');
				}

				if (this.$ctx.closest(this.keywordfilterResult).length) {
					this.$ctx.find(tableRow).removeClass('is-odd is-even');

					// remove hightlighting after filter was updated
					if (mod === 'filterUpdate') {
						this.$ctx.find(tableRow).removeClass(this.isHighlight);
					}

					this.$ctx.find(this.tableRowVisible).each((index, indexItem) => {
						if (index % 2 === 0) {
							$(indexItem).addClass('is-odd');

							return;
						}
						$(indexItem).addClass('is-even');
					});
				}
			}

			// with subheading
			if (this.$ctx.find(this.subHeadingClass).length) {
				this.$ctx.find(this.subHeadingClass).each((index, item) => {
					let rowCount = 0;
					$(item).siblings().not('.mm-toggle-row').each((index, item) => {

						if (!$(item).hasClass(this.subHeading)) {
							rowCount++;
							if (rowCount > 3) {
								that.$ctx.find('.js-white').removeClass('js-white');
							}
						}
						else {
							rowCount = 0;
						}

					});
				});
			}
		},

		setBorder: function () {
			if (!this.$ctx.hasClass('m-basic-table--no-border') && !this.$ctx.closest('.m-basic-info-layer').length && !this.$ctx.closest(this.mainBorder).length) {
				if (this.$ctx.hasClass('m-basic-table--striped') || this.$ctx.hasClass(this.tableCompare)) {
					this.$ctx.wrap(`<div class="js-main-border is-nopadding${this.getSeparatorClass()}" />`);
				}
				else {
					this.$ctx.wrap(`<div class="js-main-border${this.getSeparatorClass()}" />`);
				}

				this.$ctx.removeClass(this.getSeparatorClass());
			}
		},

		/**
		 * checkSize
		 */
		checkSize: function () {
			const tableWidth = this.$ctx.outerWidth();

			let contentWidth = this.$ctx.closest('.l-main-content').width();

			// if in ll-main-full
			if (this.$ctx.closest('.js-basic-table-wrap.is-full').length) {
				contentWidth = this.$ctx.closest('.js-basic-table-wrap.is-full').outerWidth();
			}

			// if in mm-table-adress
			if (this.$ctx.closest('.mm-table-address').length) {
				contentWidth = this.$ctx.closest('.mm-table-address').outerWidth();
			}

			// if in m-basic-anchornav
			if (this.$ctx.closest(this.anchorNav).length) {
				contentWidth = this.$ctx.closest('.mm-content').width();
			}

			if (tableWidth > contentWidth) {
				// set sticky
				if (!this.$ctx.closest('.swiper').length) {
					this.setSticky();
				}
				else {
					this.$swiper = null;
				}

				if ((contentWidth + 100) < $(window).width() && !this.$ctx.closest('.ll-main-full').length) {
					// set zoom icon
					this.setZoom();
				}
				else {
					// unset zoom icon
					this.unsetZoom();
				}

				// set/update virtual slide grid
				this.setSlideGrid();
			}
			else {
				// reset sticky
				if (this.$ctx.closest('.swiper').length) {
					this.resetSticky();
				}
			}

			this.setBorder();
		},

		/**
		 * in basicanchornav is the "mm-content" wrapper set to overflow: hidden
		 * - it is not comprehensible why this was set - so i added an exceptionClass = 'mm-zoom-table-full' for tables full with zoom
		 *
		 * Ref:
		 * https://ticket.ew72.net/browse/AC-294
		 */
		handleContentVisibility(remove = false) {
			const exceptionClass = 'mm-zoom-table-full',
				$content = this.$ctx.closest('.mm-content');

			if (remove || $content.hasClass(exceptionClass)) {
				$content.removeClass(exceptionClass);

				return;
			}

			$content.addClass(exceptionClass);
		},

		/**
		 * setZoom
		 *
		 */
		setZoom: function () {
			const tableWrap = this.$ctx.closest(this.jsTableWrap),
				containerWrap = this.$ctx.closest(this.swiperContainerWrap);

			// add zoom controller icon
			if (!containerWrap.find(this.tableZoom).length) {
				containerWrap.prepend('<button class="js-table-zoom" />');
			}

			containerWrap.find(this.tableZoom).off().on('click', () => {
				tableWrap.addClass('is-full');

				this.handleContentVisibility();

				// check if table is in js-basickeywordfilter-result
				if (this.$ctx.closest(this.keywordfilterResult).length > 0) {
					this.keywordfilterResultMode = true;
					tableWrap.unwrap();
				}

				// check sticky_kit header
				if (this.$clonedTableHeader) {
					this.$clonedTableHeader.parent().hide();
				}

				// set stopper class
				if (this.$ctx.closest(this.swiperContainerWrap).hasClass(this.hasStopper)) {
					tableWrap.addClass(this.hasStopper);
				}

				// reset sticky
				this.resetSticky();

				setTimeout(() => {
					// check size
					this.checkSize();

					// update container
					this.$swiper?.updateSize();

					// if swiper, swipe to active index
					if (this.$ctx.closest(this.swiperContainerWrap).length) {
						setTimeout(() => {
							this.$swiper.slideTo(this.$swiperActiveIndex, 0);
						}, 400);
					}
					else if (this.$clonedTableHeader) {
						this.initStickyHeader();
					}
				}, 200);

				// add reset zoom icon
				const resetIcon = $('<button class="js-table-zoom" />');

				tableWrap.prepend(resetIcon);

				this.$resetIcon = tableWrap.find(this.tableZoom);

				// add click event to reset zoom icon
				this.$resetIcon.off().on('click', (e) => {
					tableWrap.removeClass('is-full');

					this.handleContentVisibility(true);

					$(e.currentTarget).remove();

					// reset sticky
					if (this.$ctx.closest(this.swiperContainerWrap).length) {
						this.resetSticky();
					}

					this.checkSize();

					setTimeout(() => {
						if (this.keywordfilterResultMode === true) {
							this.$ctx.closest(this.jsTableWrap).wrap('<div class="js-basickeywordfilter-result" />');
							this.resetSticky();
							this.setSticky();
							this.checkSize();
						}

						this.$swiper?.updateSize();

						// if swiper, swipe to active index
						if (this.$ctx.closest(this.swiperContainerWrap).length && this.$swiper !== null) {
							this.$swiper.slideTo(this.$swiperActiveIndex, 0);
						}
					}, 200);
				});

				// set event
				this._events.emit('BasicTable.zoom', this.$ctx);

				// set stopper class
				if (this.$ctx.closest(this.swiperContainerWrap).length) {
					setTimeout(() => {
						if (this.$ctx.closest(this.swiperContainerWrap).hasClass(this.hasStopper)) {
							containerWrap.find(this.tableZoom).addClass('js-table-zoom--stopper');
						}
					}, 300);
				}
			});
		},

		/**
		 * unsetZoom
		 *
		 */
		unsetZoom: function () {
			if (this.$ctx.closest(this.swiperContainerWrap).length) {
				this.$ctx.closest(this.swiperContainerWrap).find(this.tableZoom).remove();
			}
		},

		/**
		 * setSticky
		 *
		 */
		setSticky: function () {
			const that = this;
			let allowSticky = true;

			if (!S.Utils.Helper.mq('tablet').matches && (this.$ctx.hasClass(this.tableBreak) || this.$ctx.hasClass(this.tableCompare))) {
				allowSticky = false;
			}

			if (this.$ctx.find('tbody tr:first-child td').length < 4) {
				allowSticky = false;
			}

			if (!this.$ctx.closest('.mfp-hide, .l-lightbox').length && allowSticky) {

				this.$ctx.closest(this.mainBorder).addClass('is-full');
				// if src is true, add separator to src elem instead of swiper
				if (that.hasSrc) {
					// wrap the component :: swiper container wrap
					this.$ctx.wrap('<div class="swiper-container-wrap"></div>');
				}
				else {
					// wrap the component :: swiper container wrap
					this.$ctx.wrap(`<div class="swiper-container-wrap ${this.getSeparatorClass()}"></div>`);
				}

				// wrap the component :: swiper container
				this.$ctx.wrap('<div class="swiper swiper-container--table"><div class="swiper-wrapper cf"></div></div>');

				// wrap the component :: swiping table
				this.$ctx.wrap('<div class="swiper-container-table"></div>');

				// clone the orig table
				this.$ctx.clone(true).addClass('is-clone m-basic-table--clone')
					.prependTo(this.$ctx.closest(this.swiperContainerWrap))
					.wrap('<div class="swiper-container-sticky"></div>');

				// mark as orig table
				this.$ctx.addClass('is-orig');

				/* ###  go on with generated wrapper HTML ### */
				const swiperContainer = this.$ctx.closest('.swiper'),
					swiperStickyContainer = swiperContainer.prev();

				setTimeout(() => {
					const firstColWidth = that.$ctx.find(this.tableRowVisible).eq(0).find('> td:visible').eq(0).outerWidth();

					// save sticky area from swiping table
					swiperContainer.css('margin-left', `${firstColWidth - 1}px`);

					// set sticky area width
					swiperStickyContainer.css('width', `${firstColWidth}px`);

					// cut first col from swiping table
					this.$ctx.css('left', `${-(firstColWidth)}px`);

					// cut first col from swiping sticky header table
					this.$ctx.prev().css('left', `${-(firstColWidth)}px`);

					// set width of swiping table to wrap container for cutting
					//tableContainer.css('width', this.$ctx.outerWidth() + 'px');

					// set virtual slide grid
					this.setSlideGrid();

					// write swiper buttons
					swiperContainer.append('<div class=\'swiper-button-prev swiper-button-disabled\'  aria-label=\'Vorige Inhalte anzeigen\'></div><div class=\'swiper-button-next swiper-button-disabled\' aria-label=\'Nächste Inhalte anzeigen\'></div>');

					// init swipe
					this.initSwiper(swiperContainer);

					// save sticky header
					if (this.$ctx.find('> thead').length) {

						if (this.$clonedTableHeader !== null) {

							this.$clonedTableHeader.parent().hide().insertBefore(this.$ctx);
							this.$clonedTableHeader.parent().trigger('sticky_kit:detach');

							this.updateStickyHeader();
						}

						this.$ctx.closest(this.jsTableWrap).removeClass(this.isLoading);
					}

					this.$ctx.css(this.marginTop, '0');
					this.$ctx.closest(this.swiperContainerWrap).find('.m-basic-table--clone').css(this.marginTop, '0');
				}, 200);

				this.$ctx.closest(this.jsTableWrap).removeClass(this.isLoading);
			}
		},

		/**
		 * resetSticky
		 *
		 */
		resetSticky: function (update = false) {
			const that = this,
				swiperWrapper = this.$ctx.closest(this.swiperContainerWrap);

			if (swiperWrapper.length) {
				// move table component to outer wraper :: swiper-container-wrap
				this.$ctx.appendTo(swiperWrapper);

				// save sticky header
				// eslint-disable-next-line sonarjs/no-collapsible-if
				if (that.$ctx.find('> thead').length) //NOSONAR
				{

					if (that.$clonedTableHeader !== null) {

						that.$clonedTableHeader.parent().show().insertBefore(that.$ctx);
						that.initStickyHeader();
					}

				}

				// kill siblings :: swiper-container-sticky, swiper-container
				this.$ctx.siblings().not('.js-basic-table-header-clone-wrap').remove();

				// kill wrap container :: swiper-container-wrap
				this.$ctx.unwrap();
				this.$ctx.closest(this.mainBorder).removeClass('is-full');

				// remove classes and inline styling
				this.$ctx.attr('style', '').removeClass('is-orig');
			}

			that.$ctx.closest(this.jsTableWrap).removeClass(this.isLoading);

			if (update) {
				// if there is an issue -> vogt/grigoriadis
				that.checkRowNumber('filterUpdate');
			}
		},

		/**
		 * setSlideGrid
		 *
		 */
		setSlideGrid: function () {
			const swiperWrapper = this.$ctx.closest('.swiper-wrapper'),
				swiperHeight = this.$ctx.outerHeight(),
				initMode = swiperWrapper.find('.swiper-slide').length;

			// write/update virtual slider grid
			this.$ctx.find(this.tableRowVisible).eq(0).find('> td').each(function (index, item) {
				let sliderWidth = 0;

				if (index > 0) {
					index--;

					// only first table col will set to 0px
					sliderWidth = $(item).outerWidth();

					// append virtual slider box
					if (initMode === 0) {
						swiperWrapper.append(`<div class="swiper-slide ${index}"></div>`);
					}

					swiperWrapper.find('> .swiper-slide').eq(index).css({
						'width': sliderWidth,
						'height': `${swiperHeight}px`,
					});
				}
			});

			if (this.$swiper) {
				this.$swiper.updateSlides();
			}
		},

		/**
		 * initSwiper
		 *
		 * @param $swiperContainer - jQuery Object
		 */
		initSwiper: function ($swiperContainer) {
			if (this.$ctx.data('mode') === 'edit') {
				// edit mode class
				$('body').addClass('is-page-editor');
			}

			// remove lazyload class from picture/img and replace them with swiper-lazy class
			// S.Utils.SwiperHelper.addLazyClassToImg(this.$ctx.find('.swiper-slide'), true);

			const simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				keyboardControl = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				freeMode = this.$ctx.find(this.bodyTrFirstChildTd).length === 2,
				$swiperBtnPrev = $swiperContainer.find('.swiper-button-prev'),
				$swiperBtnNext = $swiperContainer.find('.swiper-button-next');

			this.$swiperBtnPrev = $swiperBtnPrev;
			this.$swiperBtnNext = $swiperBtnNext;

			this.swiperBtnLeftAriaLabelText = this.$swiperBtnPrev.attr(this.ariaLabelAttribute);
			this.swiperBtnRightAriaLabelText = this.$swiperBtnNext.attr(this.ariaLabelAttribute);

			// init swiper container
			this.$swiper = new Swiper($swiperContainer[0], {
				slidesPerView: 'auto',
				simulateTouch: simulateTouch,
				keyboardControl: keyboardControl,
				watchSlidesProgress: true,
				preloadImages: false,
				slidesPerGroupAuto: true,
				freeMode: {
					enable: freeMode,
					momentumRatio: 0.7,
					momentumVelocityRatio: 0.7,
				},
				navigation: {
					prevEl: $swiperBtnPrev[0],
					nextEl: $swiperBtnNext[0],
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						speed: 500,
						preventInteractionOnTransition: false,
					},
					768: {
						speed: 500,
						preventInteractionOnTransition: true,
					},
					1024: {
						speed: 1500,
						preventInteractionOnTransition: true,
					}
				},
				on: {
					init: () => {
						$swiperBtnNext.stick_in_parent({
							parent: $swiperContainer,
						});

						$swiperBtnPrev.stick_in_parent({
							parent: $swiperContainer,
						});
					},
					touchStart: (swiper) => {
						if (swiper.params.freeMode?.enabled) {
							// disable to wait for the transition to end, so the user can interact (this is for freemode)
							S.Utils.SwiperHelper.swiperPreventInteractionOnTransitionSwitch(swiper);
						}
					},
					touchMove: (swiper) => {
						if (swiper.params.freeMode?.enabled) {
							// disable to wait for the transition to end, so the user can interact (this is for freemode)
							S.Utils.SwiperHelper.swiperPreventInteractionOnTransitionSwitch(swiper);
						}
					},
					transitionEnd: (swiper) => {
						if (swiper.params.freeMode?.enabled) {
							// enable to wait for the transition to end, so the user can interact
							S.Utils.SwiperHelper.swiperPreventInteractionOnTransitionSwitch(swiper, true);
						}

						const swiperContainerWrap = this.$ctx.closest(this.swiperContainerWrap);

						let count = 0;

						// update swiper active index
						swiperContainerWrap.find('.swiper-slide').each((index, item) => {
							const $item = $(item);

							if ($item.hasClass('swiper-slide-visible')) {
								if (count === 0) {
									this.$swiperActiveIndex = index;
								}
								count++;
							}
						});
					},
				}
			});

			// bugfix: set aria-labels here again, because swiper sometimes overwrites it
			$swiperBtnPrev.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
			$swiperBtnNext.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);

			this.resize(this.$swiper);
		},

		/**
		 * setAreaLabelSwiper
		 */
		setAreaLabelSwiper: function () {
			this.$swiperBtnPrev.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
			this.$swiperBtnNext.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);
		},

		/**
		 * initTextToggle
		 */
		initTextToggle: function () {
			setTimeout(() => {
				// reset
				this.$ctx.find('> tbody > tr > td > .js-toggletext-head').each((index, item) => {
					const $item = $(item);

					$item.attr('style', '').removeClass('is-active');
					$item.next().attr('style', '');
				});

				if (this.$clonedTableHeader) {
					this.$clonedTableHeader.find('tbody > tr > td > .js-toggletext-head').each((index, item) => {
						const $item = $(item);

						$item.attr('style', '').removeClass('is-active');
						$item.next().attr('style', '');
					});
				}

				// set
				this.$ctx.find('> tbody > tr > td > .js-toggletext-head').each((index, item) => {
					this.setToogleHeadWidthAndClickEvent(item);
				});

				if (this.$clonedTableHeader) {
					this.$clonedTableHeader.find('tbody > tr > td > .js-toggletext-head').each((index, item) => {
						this.setToogleHeadWidthAndClickEvent(item);
					});
				}
			}, 300);
		},

		/**
		 * @param item
		 */
		setToogleHeadWidthAndClickEvent(item) {
			const $item = $(item),
				setWidth = `${$item.innerWidth()}px`;

			$item.css('width', setWidth);
			$item.next().css('width', setWidth).hide();

			$(item).off().on('click', () => {
				$item.toggleClass('is-active').next().slideToggle();
			});
		},

		/**
		 * resize
		 */
		resize: function () {
			$(window).resize(() => {
				// reset
				this.$isResizing++;

				// buffer
				this.bufferResize();
			});
		},

		/**
		 * buffer resize
		 */
		bufferResize: function () {
			let isResizingCheck = 0;

			// start buffer interval check
			if (this.$isResizing === 1) {
				const resizing = setInterval(() => {
					if (isResizingCheck < this.$isResizing) {
						isResizingCheck = this.$isResizing;
					}
					else {
						this.$isResizing = 0;
						isResizingCheck = 0;

						// tablet
						// eslint-disable-next-line sonarjs/no-collapsible-if
						if (S.Utils.Helper.mq('tablet').matches) {
							// init text toggle
							if (this.$ctx.find(this.toggletextHead).length) {
								this.initTextToggle();
							}
						}

						// check if table is bigger than content wrap
						this.checkSize();

						// if swiper is activated
						if (this.$swiper !== null) {
							this.$swiper.update();
							this.setAreaLabelSwiper();
						}

						// update sticky header
						if (this.$ctx.find('> thead').length) {
							this.updateStickyHeader();
						}

						this.checkSubline();

						clearInterval(resizing);
					}
				}, 500);
			}
		},

		/**
		 * Function to sort table Elements
		 */
		sortContentRows: function (a, b, targetIndex) {
			const first = (this.reverseOrder) ? a : b,
				second = (this.reverseOrder) ? b : a;

			if (this.hasImages) {
				return ($(first).children().eq(targetIndex).find('img.js-sortable').attr('title').trim() < $(second).children().eq(targetIndex).find('img').attr('title').trim()) ? 1 : -1;
			}
			else {
				const $firstItemToCheck = $(first).children().eq(targetIndex),
					$secondItemToCheck = $(second).children().eq(targetIndex),
					sortableText = '.js-sortable-text',
					ratingUser = '.m-basic-rating--user';

				if ($firstItemToCheck.find(sortableText).length) {
					// if you need to filter stars from m-basic-rating--user, which has the value in data-attr rating
					if ($firstItemToCheck.find(sortableText).closest(ratingUser).length) {
						const $firstItem = $firstItemToCheck.find(sortableText).closest(ratingUser).data('rating'),
							$secondItem = $secondItemToCheck.find(sortableText).closest(ratingUser).data('rating');

						return $firstItem < $secondItem ? 1 : -1;
					}
					else {
						// const checkForNumber = parseInt($firstItemToCheck.find(sortableText).text().trim()),
						const $firstItem = $firstItemToCheck.find(sortableText).text().trim(),
							$secondItem = $secondItemToCheck.find(sortableText).text().trim();

						return this.naturalCompare($firstItem, $secondItem);
					}
				}
				else {
					// if you need to filter and there is NO js-sortable-text class set
					return this.naturalCompare($firstItemToCheck.text().trim(), $secondItemToCheck.text().trim());
				}
			}
		},

		/**
		 * Natural Sorting
		 */
		naturalCompare: function (a, b) {
			return b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' });
		},

		/**
		 * Function to sort table Elements
		 */
		sortHeadings: function (a, b, targetIndex) {
			const first = (this.reverseOrder) ? a : b,
				second = (this.reverseOrder) ? b : a;

			return (first.headlineObject.find('> td').eq(targetIndex).text().trim() > second.headlineObject.find('> td').eq(targetIndex).text().trim()) ? 1 : -1;

		},

		/**
		 * sort Table Function
		 */
		initSorting: function (target) {

			const $target = $(target),
				targetIndex = $target.closest('th').index(),
				$tableBody = this.$ctx.find('tbody'),
				$allRows = $tableBody.find('tr'),
				sortable = [],
				sortActive = 'js-sort--active';

			let pos;

			this.hasImages = ($allRows.eq(0).find('> td').eq(targetIndex).find('img.js-sortable').length > 0);
			this.reverseOrder = (!this.reverseOrder);

			// check if the user clicks the same filter-button (small triangle) again, if not close all and open the clicked ones
			if ($target.get(0) === this.$oldClickableSortButton) {
				$target.find('.js-sort').toggleClass(sortActive);
			}
			else {
				this.$ctx.find('.js-sort').removeClass(sortActive);
				$target.find('.js-sort').addClass(sortActive);
			}

			this.$oldClickableSortButton = $target.get(0);

			$allRows.each((i, rows) => {
				const $currentRow = $(rows);

				if (i === 0 || $currentRow.hasClass(this.subHeading)) {
					pos = sortable.push({ headlineObject: '', contentTrs: [] }) - 1;
				}

				if ($currentRow.hasClass(this.subHeading)) {
					sortable[pos].headlineObject = $currentRow;
				}
				else {
					sortable[pos].contentTrs.push($currentRow);
				}

				if ($currentRow.hasClass(this.isHighlight)) {
					$currentRow.removeClass(this.isHighlight);
				}

				// remove highlighting
				// that.$ctx.find(this.tableRowIsHighlight).removeClass(this.isHighlight);
				// that.$clonedTableHeader.find(this.tableRowIsHighlight).removeClass(this.isHighlight);
			});

			this.$ctx.find('tbody').remove();

			sortable.sort((a, b) => {
				return this.sortHeadings.apply(this, [a, b, targetIndex]);
			});

			$.each(sortable, (i) => {
				const tmp = $('<tbody>');

				sortable[i].contentTrs.sort((a, b) => {
					return this.sortContentRows.apply(this, [a, b, targetIndex]);
				});

				$.each(sortable[i].contentTrs, (x) => {

					sortable[i].contentTrs[x].prependTo(tmp);

				});

				if (sortable[i].headlineObject) {

					sortable[i].headlineObject.prependTo(tmp);

				}

				this.$ctx.append(tmp);

			});

			this.checkRowNumber('default');

		},

		/**
		 * depending on scroll direction, trigger an additional scroll event to force header to be pinned / unpinned;
		 *
		 * => simplifies height calculation to scroll to highlighted tr;
		 */
		forceHeaderScrollState: function () {
			if (S.Utils.Helper.mq('tablet').matches && this.$ctx.find(this.tableRowIsHighlight).length) {
				const currentTargetBounds = this.$ctx.find(this.tableRowIsHighlight).eq(0).prev()[0].getBoundingClientRect(),
					currentHeaderBounds = this.$clonedTableHeader.parent()[0].getBoundingClientRect(),
					lastScrollVal = $(window).scrollTop(),
					// check if not already docked
					dockedVal = Math.abs(currentHeaderBounds.bottom - currentTargetBounds.top);

				let delayBufferScroll = 400,
					mod = 'unsticked';

				// target is not in pos
				if (dockedVal > 1) {
					// sticking
					if (this.$clonedTableHeader.parent().hasClass('is-sticky-header')) {
						mod = 'sticked';

						// call scroll to force correct header pin state
						if (currentHeaderBounds.bottom < currentTargetBounds.top) {
							$(window).scrollTop(lastScrollVal + 1);
						}
						else {
							$(window).scrollTop(lastScrollVal - 1);
							delayBufferScroll = 300;
						}
					}

					// not sticking
					else {
						// calc scroll-up distance (lastScrollVal + map-distance-to-top + ( distance between header-top and map-bottom );
						const mapBoundings = this.$ctx.closest(this.stickyWrap).find(this.adacMapsSticky)[0].getBoundingClientRect(),
							distanceToStick = lastScrollVal + mapBoundings.top + (currentHeaderBounds.top - mapBoundings.bottom);

						$(window).scrollTop(distanceToStick);
					}

					S.Utils.delayed(`basicTable-${this.$ctx.data('t-id')}.scrollDownToHighlight.buffer`, delayBufferScroll, () => {
						this.scrollToHighlight(mod);
					});
				}
			}
		},

		/**
		 * some templates got a highlighting logic, e.g. if table is used to display POIs next to a map
		 *
		 * scrolls highlighted tr to 2nd pos from top
		 *
		 * @param mod {String} - sticked || unsticked :: switches scroll logics especially for header pin states
		 */
		scrollToHighlight: function (mod) { //NOSONAR

			const that = this,
				$hightlightRow = that.$ctx.find(this.tableRowIsHighlight).eq(0),
				lastScrollVal = $(window).scrollTop();

			let $targetRow = $hightlightRow;

			if ($hightlightRow.prev().length) {
				$targetRow = $hightlightRow.prev();
			}

			let currentTargetBounds = $targetRow[0].getBoundingClientRect(),
				currentHeaderBounds = that.$clonedTableHeader.parent()[0].getBoundingClientRect(),
				calcHeights = 0,
				newScrollVal = lastScrollVal;

			if (mod === 'sticked') {
				if (currentHeaderBounds.bottom <= currentTargetBounds.top) {
					// check prev siblings
					$targetRow.prevAll().each((index, item) => {
						if (item.getBoundingClientRect().bottom > currentHeaderBounds.bottom) {
							// item is fully visible => take the height
							if (item.getBoundingClientRect().top > currentHeaderBounds.bottom) {
								calcHeights += item.getBoundingClientRect().height;
							}

							// only bottom part visible => take diff between item.bottom and head.bottom
							else {
								calcHeights += item.getBoundingClientRect().bottom - currentHeaderBounds.bottom;
							}
						}
					});

					newScrollVal += calcHeights;
				}

				// scroll down
				else {
					// include target row in logic (via .addBack() )
					$targetRow.nextAll().addBack().each((index, item) => {
						// top bound is smaller than header.bottom bound (item is scrolled upwards)
						if (item.getBoundingClientRect().top <= currentHeaderBounds.bottom) {

							// item is fully invisible (scrolled upwards beyond header) => take the height
							if (item.getBoundingClientRect().bottom <= currentHeaderBounds.bottom) {
								calcHeights += item.getBoundingClientRect().height;
								// the last child might generate false values since there could be a small bottom gap for sticky thead after we triggered the scroll down manually
								if ($(item).is(':last-child') && item.getBoundingClientRect().bottom !== currentHeaderBounds.bottom) {
									calcHeights += currentHeaderBounds.bottom - item.getBoundingClientRect().bottom;
								}
							}

							// only bottom part visible => take diff between item.bottom and head.bottom
							else {
								calcHeights += currentHeaderBounds.bottom - item.getBoundingClientRect().top;
							}
						}
					});

					newScrollVal -= calcHeights;
				}
			}

			else {
				// check prev siblings
				$targetRow.prevAll().each((index, item) => {
					const itemBounds = item.getBoundingClientRect();

					if (itemBounds.bottom > currentHeaderBounds.bottom) {
						// item is fully visible => take the height
						if (itemBounds.top > currentHeaderBounds.bottom) {
							calcHeights += itemBounds.height;
						}

						// only bottom part visible => take diff between item.bottom and head.bottom
						else {
							calcHeights += itemBounds.bottom - currentHeaderBounds.bottom;
						}
					}
				});
				newScrollVal += calcHeights;
			}

			S.Utils.delayed(`basicTable-${that.$ctx.data('t-id')}.scrollToHighlight`, 20, () => {
				$(window).scrollTop(newScrollVal);

				// recheck if everything is ok
				S.Utils.delayed(`basicTable-${that.$ctx.data('t-id')}.scrollToHighlight.recheck`, 40, () => {

					currentTargetBounds = $targetRow[0].getBoundingClientRect();
					currentHeaderBounds = that.$clonedTableHeader.parent()[0].getBoundingClientRect();

					// result should be ~0, if its more or less than |1| => recalc
					const dockedVal = Math.abs(currentTargetBounds.top - currentHeaderBounds.bottom);

					// if the targetRow is not 'docked' to the sticky thead, reupdate scroll val
					if (dockedVal > 1) {
						// scroll up a bit
						if ($targetRow[0].getBoundingClientRect().top < that.$clonedTableHeader.parent()[0].getBoundingClientRect().bottom) {
							newScrollVal -= that.$clonedTableHeader.parent()[0].getBoundingClientRect().bottom - $targetRow[0].getBoundingClientRect().top;
						}

						// scroll down a bit
						else {
							newScrollVal += $targetRow[0].getBoundingClientRect().top - that.$clonedTableHeader.parent()[0].getBoundingClientRect().bottom;
						}

						$(window).scrollTop(newScrollVal);
					}
				});
			});
		},

		/**
		 * check if table swipeable and contains sublines
		 *
		 * add / remove colspan and table-datas
		 *
		 */
		checkSubline: function () {
			const swiperContainerWrap = this.$ctx.closest(this.swiperContainerWrap),
				tableContainerWrap = this.$ctx,
				subline = swiperContainerWrap.find(this.subHeadingClass);

			if (swiperContainerWrap.length) {
				subline.each(() => {
					const sublineTd = subline.find('td');

					let $i,
						counter = sublineTd.prop('colspan');

					if (counter > '1') {
						counter = counter - 1;
						sublineTd.prop('colspan', '1');

						for ($i = 0; $i < counter; $i++) {
							subline.append('<td class="js-placeholder"></td>');
						}
					}
				});

			}
			else {
				subline.each((index, elem) => {
					const sublineTd = subline.find('td');

					if ($(elem).find('td.js-placeholder').length) {
						const colspan = $(elem).find('td').length;

						tableContainerWrap.find('.js-placeholder').remove();
						sublineTd.prop('colspan', colspan);
					}
				});
			}
		},

		/**
		 * @return {string}
		 */
		getSeparatorClass: function () {
			const separatorClass = this.$ctx.attr('class').match(/h-space-[s,m,l]+/);

			if (separatorClass) {
				return ` ${this.$ctx.attr('class').match(/h-space-[s,m,l]+/)[0]}`;
			}
			else {
				return '';
			}
		}

	});
}(jQuery));