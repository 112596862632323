'use strict';
(function ($) {
	/**
	 * CrashTestSearch module implementation.
	 *
	 * @author Marc Radziwill <marc.radziwill@namics.com>
	 * @namespace T.Module
	 * @class CrashTestSearch
	 * @extends T.Module
	 */
	T.Module.CrashTestSearch = T.createModule({
		SESSIONKEY_SEARCHPARAMS: 'Application.CrashTestSearch.Params',
		_manufactureFld: 'select[name="select_manufacturer"]',
		_modelFld: 'select[name="select_model"]',
		_classesFld: 'input[name="genre"]',
		_yearFromFld: 'select[name="select_construction_year_from"]',
		_yearToFld: 'select[name="select_construction_year_to"]',
		_counterBnt: '.js-counter',
		_resultTable: '.js-result-table',
		_resetSearchBtn: '.js-reset-search',
		_tableBody: 'table tbody',
		_dataPlaceholder: 'data-placeholder',
		_counterClass: '.m-counter',


		_loadConfig: {
			manufactures: false,
			models: false,
			classes: false,
			years: false,
		},

		start: function start(resolve) {
			T.Utils.Application.init();

			this.$ctx = $(this._ctx);

			this.$noResult = this.$ctx.find('.js-noresult');
			this.$noBackend = this.$ctx.find(".js-nobackend");
			this.$tileContainer = this.$ctx.find('.js-tile-container');
			this.$tileTemplate = $(this.$ctx.find('#js-tile-template').html());
			this.$rowTemplate = $(this.$ctx.find('#js-row-template').html());
			this.$resultContainer = this.$ctx.find('.js-resultcontainer');
			this.$table = this.$ctx.find(this._resultTable);
			this.$loadMore = this.$ctx.find('.js-load-more');
			this.$submitButton = this.$ctx.find('.js-submit-search');

			this._pageNumber = 0;
			this._resultAmountPerRequest = 10;

			this._addListeners();
			this._readConfig();

			this._requestManufactures();
			this._requestClasses(() => {
				if (T.Utils.Store.get(this.SESSIONKEY_SEARCHPARAMS, T.Utils.Store.SESSION)) {
					this._loadConfig = {
						manufactures: true,
						models: true,
						classes: true,
						years: true,
					};
					this.$ctx.trigger('loadConfig');
				} else {
					this._requestModelYears();
				}
			});



			resolve();
		},

		_addListeners: function _addListeners() {
			this.$ctx.on('loadConfig', this._handleLoadConfig.bind(this));

			this.$ctx.find(this._manufactureFld).on('change', this._handleManufactureChange.bind(this));
			this.$ctx.find(this._modelFld).on('change', this._handleModelChange.bind(this));
			this.$ctx.find(this._yearFromFld).on('change', this._handleYearFromChange.bind(this));
			this.$ctx.find(this._yearToFld).on('change', this._handleYearToChange.bind(this));
			this.$ctx.find(this._resetSearchBtn).on('click', this._resetSearch.bind(this));

			this.$loadMore.on('click', this._loadMoreResults.bind(this));
			this.$ctx.find('form.js-submit').submit(this._submitSearch.bind(this));
		},

		_handleLoadConfig: function _handleLoadConfig() {
			if (this._loadConfig.manufactures && this._loadConfig.classes && this._loadConfig.years) {
				this._loadStoredSearch();
				this._loadConfig = {
					manufactures: false,
					models: false,
					classes: false,
					years: false,
				};
			} else if (this._loadConfig.models) {
				this._loadConfig.models = false;
			}
			this._clearResultTable();
			this._requestSearchCount();
		},

		_loadStoredSearch: function _loadStoredSearch() {
			const storedSearch = T.Utils.Store.get(this.SESSIONKEY_SEARCHPARAMS, T.Utils.Store.SESSION);

			if (storedSearch) {
				const firstOptionSelector = "option:first";

				const $elementFrom = this.$ctx.find(this._yearFromFld),
					$elementTo = this.$ctx.find(this._yearToFld);
				if (storedSearch.HerstellerId) {
					this._setDropdownVal(this._manufactureFld, storedSearch.HerstellerId);
					this._requestModels();
				}
				if (storedSearch.BaureiheId) {
					this._setDropdownVal(this._modelFld, storedSearch.BaureiheId);
				}
				if (storedSearch.BaureiheModellstartjahr) {
					for (const key in storedSearch.BaureiheModellstartjahr) {
						$elementFrom.append($('<option/>').attr('value', storedSearch.BaureiheModellstartjahr[key]).text(storedSearch.BaureiheModellstartjahr[key]));

					}
					$elementFrom.find(firstOptionSelector).remove();
					this._setDropdownVal(this._yearFromFld, storedSearch.BaureiheModellstartjahr[0]);
				}
				if (storedSearch.BaureiheModellendjahr) {
					for (const key in storedSearch.BaureiheModellendjahr) {
						$elementTo.append($('<option/>').attr('value', storedSearch.BaureiheModellendjahr[key]).text(storedSearch.BaureiheModellendjahr[key]));
					}
					$elementTo.find(firstOptionSelector).remove();
					this._setDropdownVal(this._yearToFld, storedSearch.BaureiheModellendjahr[storedSearch.BaureiheModellendjahr.length - 1]);
				}
				if (storedSearch.FahrzeugklassenIds && storedSearch.FahrzeugklassenIds.length > 0) {
					this._setCheckArrayVals(this._classesFld, storedSearch.FahrzeugklassenIds);
				}
			}

			if (storedSearch && storedSearch.detailsPage) {
				this._scrollToElement = true;
				this._submitSearch();
				this._updateStorage('detailsPage', false);
			}
		},

		_clearResultTable: function _clearResultTable() {
			this.$table.find('tbody').empty();
			this.$resultContainer.toggleClass('h-hidden', true);
		},

		_resetSearch: function _resetSearch(evt) {
			if (evt) {
				evt.preventDefault();
			}

			this._pageNumber = 0;
			this.$resultContainer.find(this._tableBody).empty();
			this.$resultContainer.toggleClass('h-hidden', true);
			T.Utils.Store.set(this.SESSIONKEY_SEARCHPARAMS, null, T.Utils.Store.SESSION);
			window.location.reload();
		},


		_submitSearch: function _submitSearch(evt) {
			if (evt) {
				this._pageNumber = 0;
				this._updateStorage('detailsPage', false);
				evt.preventDefault();
			}
			this._clearResultTable();

			this.$resultContainer.find('.js-result-table-wrapper').toggleClass('h-hidden', false);
			this.$resultContainer.find(this._tableBody).empty();
			this._requestResults();
			return false;
		},

		_requestResults: function _requestResults() {
			T.Utils.View.startLoader();
			const storedSearch = T.Utils.Store.get(this.SESSIONKEY_SEARCHPARAMS, T.Utils.Store.SESSION);
			const payload = {
				Paging: {
					Anzahl: this._resultAmountPerRequest,
					Seite: this._pageNumber
				},
				HerstellerId: storedSearch && storedSearch.HerstellerId ? storedSearch.HerstellerId : this._getDropdownVal(this._manufactureFld),
				BaureiheId: storedSearch && storedSearch.BaureiheId ? storedSearch.BaureiheId : this._getDropdownVal(this._modelFld),

				BaureiheModellstartjahr: this._getDropdownVal(this._yearFromFld),
				BaureiheModellendjahr: this._getDropdownVal(this._yearToFld),
				FahrzeugklassenIds: storedSearch && storedSearch.FahrzeugklassenIds ? storedSearch.FahrzeugklassenIds : this._getCheckArrayVals(this._classesFld)
			};

			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apicrashtestsuche),
				data: payload
			};
			T.Utils.Ajax.fragment(request, this._handleSuccessResultRequest.bind(this), this._handleErrorResultRequest.bind(this));
		},

		_handleSuccessResultRequest: function _handleSuccessResultRequest(data) {
			try {
				this._clearErrors();
				if (data && data.Data) {
					this._addResultsToView(data);
				}
			} finally {
				T.Utils.View.stopLoader();
			}
		},

		_handleErrorResultRequest: function _handleErrorResultRequest(error) {
			this.$resultContainer.find(this._counterClass).text('0');
			this.$loadMore.toggleClass('h-hidden', true);
			this._classifyError(error);
			T.Utils.View.stopLoader();
		},


		_updateStorage: function _updateStorage(key, value) {
			let storage = T.Utils.Store.get(this.SESSIONKEY_SEARCHPARAMS, T.Utils.Store.SESSION);

			if (storage) {
				storage[key] = value;
			} else {
				storage = {};
				storage[key] = value;
			}
			T.Utils.Store.set(this.SESSIONKEY_SEARCHPARAMS, storage, T.Utils.Store.SESSION);
		},

		_getStorageByName: function _getStorageByName(name) {
			const storage = T.Utils.Store.get(this.SESSIONKEY_SEARCHPARAMS, T.Utils.Store.SESSION);
			if (storage) {
				return storage[name];
			}
			return null;
		},

		_addResultsToView: function _addResultsToView(data) {
			this._clearErrors();
			this.$resultContainer.find(this._counterClass).empty();

			if (data && data.Data && data.Data.Crashtests && data.Data.Crashtests.Data.length > 0) {
				if (this._pageNumber > 0) {
					this.$lastRow = this.$table.find('tbody tr:last-child');
				}

				data.Data.Crashtests.Data.forEach((item) => {
					this._createNewRow(item);
				});
				this.$resultContainer.find(this._counterClass).html(data.Data.Crashtests.TotalCount);
				T.Utils.Helper.initAdditionalModules(this.$ctx);
				this._toggleLoadMoreButton(data.Data.Crashtests.TotalPages);

				if (this.$lastRow && this.$lastRow.length > 0) {
					$([document.documentElement, document.body]).animate({
						scrollTop: this.$lastRow.offset().top - 300
					}, 500);
				}

				this.$resultContainer.toggleClass('h-hidden', false);

				if (this._scrollToElement) {
					const $elem = $(`[data-scroll-id="${this._getStorageByName('scrollId')}"]`);
					if ($elem && $elem.length > 0) {
						setTimeout(() => {
							$([document.documentElement, document.body]).animate({
								scrollTop: $elem.offset().top
							}, 2000);
						}, 500);
						this._scrollToElement = false;
					}
				}
			} else {
				T.Utils.View.stopLoader();
			}

			this._events.emit('BasicTable.ChangedContent');
		},


		_createNewRow: function _createNewRow(item) {
			const $rowclone = this.$rowTemplate.clone();
			const $img = $rowclone.find('img');

			const itemName = item.Bezeichnung;
			let imageUrl = this._config.imageBasePath.interpolate({
				Media: item.BaureiheBild,
				Extension: item.BaureiheBildDateiEndung || ".jpg"
			});

			$rowclone.attr('data-scroll-id', item.Id);



			const sourceSet = $img.attr('srcset');

			let interpolatedSourceSet;
			if (!this._config.useCloudinary) {
				interpolatedSourceSet = sourceSet.interpolate({

					PathS: imageUrl.interpolate({ Format: this._config.imageSuffixS }),
					PathM: imageUrl.interpolate({ Format: this._config.imageSuffixM }),
					PathL: imageUrl.interpolate({ Format: this._config.imageSuffixL }),
					PathXL: imageUrl.interpolate({ Format: this._config.imageSuffixXL }),
					PathXXL: imageUrl.interpolate({ Format: this._config.imageSuffixXXL }),
				});
				$img.attr('src', imageUrl.interpolate({ Format: this._config.imageSuffixS }));
			}
			else {
				imageUrl = imageUrl.replace('${Format}', ''); //NOSONAR
				interpolatedSourceSet = sourceSet.interpolate({

					PathS: imageUrl.interpolate({ Format: '' }),
					PathM: imageUrl.interpolate({ Format: '' }),
					PathL: imageUrl.interpolate({ Format: '' }),
					PathXL: imageUrl.interpolate({ Format: '' }),
					PathXXL: imageUrl.interpolate({ Format: '' }),
				});



			}

			$img.attr('srcset', interpolatedSourceSet);



			$img.attr('title', itemName);
			if (item.BaureiheBildAltText) {
				$img.attr('alt', item.BaureiheBildAltText);
			}

			$rowclone.find('.js-manufacturer').text(item.BaureiheBezeichnung);

			const $testRating = $rowclone.find('.js-single-rating');
			const $dualRating = $rowclone.find('.js-dual-rating');

			if (item.SterneGesamt) {
				$testRating.data('rating', item.SterneGesamt);
				$testRating.toggleClass('h-hidden', false);

			} else {
				$testRating.toggleClass('h-hidden', true);
				$rowclone.find('.js-rating-text').toggleClass('h-hidden', false);
			}

			const ratingPointsClass = '.js-rating-points';
			if (item.Gesamterfuellungsgrad) {
				const hitcount = $testRating.find(ratingPointsClass).text();
				$testRating.find(ratingPointsClass).empty();
				$testRating.find(ratingPointsClass).append(`(${item.Gesamterfuellungsgrad} ${hitcount})`);
			} else {
				$testRating.find(ratingPointsClass).toggleClass('h-hidden', true);
			}

			if (item.HatDualRating && item.SterneGesamtDualRating !== undefined) {

				$dualRating.data('rating', item.SterneGesamtDualRating);
				$dualRating.toggleClass('h-hidden', false);

			} else {
				$dualRating.toggleClass('h-hidden', true);
			}


			$rowclone.find('.js-modelyear').text(item.BaureiheModellzeitraum);

			if (item.PdfDateinameAlterTest) {
				const pdfPath = this._config.pdfBasePath.interpolate({
					Media: `${item.PdfDateinameAlterTest}.pdf`,
				});
				$rowclone.find('.js-pdf').attr('href', pdfPath);
			} else {
				$rowclone.find('.js-pdf').toggleClass('h-hidden', true);
			}

			const $tbody = this.$table.find('tbody');

			const $detailsLink = $rowclone.find(".js-details");
			if (item.IstAlterTest) {
				$rowclone.find(".js-details-text").toggleClass('h-hidden', false);
				$detailsLink.toggleClass('h-hidden', true);
			} else {
				const detailsPageUrl = this._config.detailsPageUrl.interpolate({
					id: item.Id,
					name: `${T.Utils.Helper.createSeoUrl(item.Bezeichnung)}/`
				});
				$detailsLink.attr("href", detailsPageUrl);
				$detailsLink.on('click', this._handleDetailsClick.bind(this));
			}

			if (item.Testdatum) {
				const $testDateSection = $rowclone.find('.js-testing-date');
				let testDateTemplateText = $testDateSection.text();
				const formattedTestDate = moment(item.Testdatum).format('MM/YYYY');
				testDateTemplateText = testDateTemplateText.replace("{date}", formattedTestDate);
				$testDateSection.text(testDateTemplateText);

			} else {
				$rowclone.find('.js-testing-date').toggleClass("h-hidden", true);
			}

			$tbody.append($rowclone);
		},

		_handleDetailsClick: function _handleDetailsClick(ev) {
			ev.preventDefault();
			const $link = $(ev.currentTarget);
			const storedSearch = T.Utils.Store.get(this.SESSIONKEY_SEARCHPARAMS, T.Utils.Store.SESSION);

			storedSearch.scrollId = $link.closest('tr.js-scroll-id').data('scroll-id');
			storedSearch.detailsPage = true;

			T.Utils.Store.set(this.SESSIONKEY_SEARCHPARAMS, storedSearch, T.Utils.Store.SESSION);

			T.Utils.Helper.routeToUrl($link.attr('href'));
		},

		_readConfig: function _readConfig() {
			this._config = this.$ctx.data('config');
			this._resultAmountPerRequest = this._config.resultAmountPerRequest;
		},

		_handleManufactureChange: function _handleManufactureChange() {
			this._updateStorage('HerstellerId', this._getDropdownVal(this._manufactureFld));
			this._updateStorage('BaureiheId', '');
			this._requestModels();
			this._requestModelYears();
		},

		_handleModelChange: function _handleModelChange() {
			this._updateStorage('BaureiheId', this._getDropdownVal(this._modelFld));
			this._loadConfig.models = true;
			this._requestModelYears();
		},

		_handleClassesChange: function _handleClassesChange() {
			this._updateStorage('FahrzeugklassenIds', this._getCheckArrayVals(this._classesFld));
			this._requestSearchCount();
			this._clearResultTable();
		},

		_handleYearFromChange: function _handleYearFromChange() {
			this._requestSearchCount();
			this._clearResultTable();
		},

		_handleYearToChange: function _handleYearToChange() {
			this._requestSearchCount();
			this._clearResultTable();
		},

		_requestManufactures: function _requestManufactures() {
			T.Utils.View.startLoader();
			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apicrashtesthersteller)
			};
			T.Utils.Ajax.fragment(request, this._handleSuccessManufacturesRequest.bind(this), this._handleErrorManufacturesRequest.bind(this));
		},

		_handleSuccessManufacturesRequest: function _handleSuccessManufacturesRequest(data) {
			try {
				this._clearErrors();
				if (data && data.Data) {
					this._addManufatureDropdown(data);
				}
			} finally {
				T.Utils.View.stopLoader();
			}
		},

		_handleErrorManufacturesRequest: function _handleErrorManufacturesRequest(error) {
			this._classifyError(error);
			T.Utils.View.stopLoader();
		},


		_addManufatureDropdown: function _addManufatureDropdown(data) {
			const $element = this.$ctx.find(this._manufactureFld);
			const placeholder = $element.attr(this._dataPlaceholder);

			for (const key in data.Data.HerstellerErgebnis) {
				$element.append($('<option/>').attr('value', data.Data.HerstellerErgebnis[key].Id).text("Alle" === key ? placeholder : data.Data.HerstellerErgebnis[key].Bezeichnung));
			}
			this._loadConfig.manufactures = true;
			this.$ctx.trigger('loadConfig');
		},

		_requestModels: function _requestModels() {
			T.Utils.View.startLoader();

			const HerstellerId = this._getDropdownVal(this._manufactureFld);
			if (HerstellerId) {
				const payload = {
					HerstellerId: HerstellerId
				};

				const request = {
					method: 'GET',
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apicrashtestbaureihen),
					data: payload
				};
				T.Utils.Ajax.fragment(request, this._handleSuccessModelsRequest.bind(this), this._handleErrorModelsRequest.bind(this));
			} else {
				this._setModelToAll();
			}
		},

		_handleSuccessModelsRequest: function _handleSuccessModelsRequest(data) {
			try {
				this._clearErrors();
				if (data && data.Data) {
					this._addModelDropdown(data);
				}
			} finally {
				T.Utils.View.stopLoader();
			}
		},

		_handleErrorModelsRequest: function _handleErrorModelsRequest(error) {
			this._classifyError(error);
			T.Utils.View.stopLoader();
		},


		_addModelDropdown: function _addModelDropdown(data) {
			this._clearDropdown(this._modelFld);
			const $element = this.$ctx.find(this._modelFld);
			const placeholder = $element.attr(this._dataPlaceholder);

			$element.append($('<option/>').attr('value', '').text(placeholder)).attr('selected', true);
			for (const key in data.Data.BaureihenErgebnis) {
				$element.append($('<option/>').attr('value', data.Data.BaureihenErgebnis[key].Id).text("Alle" === key ? placeholder : data.Data.BaureihenErgebnis[key].Bezeichnung));
			}

			$element.find(`option[value="${this._getStorageByName('BaureiheId')}"]`).prop('selected', true);

			this._loadConfig.models = true;
			this.$ctx.trigger('loadConfig');
		},

		_setModelToAll: function _setModelToAll() {
			this._clearDropdown(this._modelFld);
			const $element = this.$ctx.find(this._modelFld);
			const placeholder = $element.attr(this._dataPlaceholder);
			$element.append($('<option/>').attr('value', '').text(placeholder)).attr('selected', true);
			T.Utils.View.stopLoader();

			this._loadConfig.models = true;
			this.$ctx.trigger('loadConfig');
		},

		_requestClasses: function _requestClasses(callback) {
			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apicrashtestfahrzeugklassen)
			};
			T.Utils.Ajax.fragment(request, this._handleSuccessClassesRequest.bind(this, callback), this._handleErrorClassesRequest.bind(this));
		},

		_handleSuccessClassesRequest: function _handleSuccessClassesRequest(callback, data) {
			try {
				this._clearErrors();
				if (data && data.Data) {
					this._addClassesToTiles(data);
					callback();
				}
			} finally {
				T.Utils.View.stopLoader();
			}
		},

		_handleErrorClassesRequest: function _handleErrorClassesRequest(error) {
			this._classifyError(error);
			T.Utils.View.stopLoader();
		},

		_addClassesToTiles: function _addClassesToTiles(data) {
			if (data.Data) {
				data.Data.FahrzeugklassenErgebnis.forEach((classItem) => {
					const $tile = this.$tileTemplate.clone();

					$tile.find('.js-title-title').text(classItem.Bezeichnung);
					$tile.find('[name="genre"]').attr('id', classItem.Id);
					$tile.find('[name="genre"]').attr('value', classItem.Id);
					$tile.find('label').attr('for', classItem.Id);
					this.$tileContainer.append($tile);
				});
			}
			this.$ctx.find(this._classesFld).on('click', this._handleClassesChange.bind(this));
			T.Utils.Helper.initAdditionalModules(this.$ctx);

		},

		_requestModelYears: function _requestModelYears() {
			T.Utils.View.startLoader();
			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apicrashtestbaureihenmodelljahre)
			};
			const BaureiheId = this._getDropdownVal(this._modelFld);

			if (BaureiheId && BaureiheId.length > 0) {
				request.data = {
					BaureiheId: BaureiheId
				};
			}
			T.Utils.Ajax.fragment(request, this._handleSuccessModelYearsRequest.bind(this), this._handleErrorModelYearsRequest.bind(this));
		},

		_handleSuccessModelYearsRequest: function _handleSuccessModelYearsRequest(data) {
			try {
				this._clearErrors();
				if (data && data.Data) {
					this._addYearsToDropdown(data.Data.ModelljahreErgebnis);
				}
			} finally {
				T.Utils.View.stopLoader();
			}
		},

		_addYearsToDropdown: function _addYearsToDropdown(modelyears) {
			this._clearDropdown(this._yearFromFld);
			this._clearDropdown(this._yearToFld);

			const startArray = [...new Set(modelyears.map(item => {
				return item.Start || 'Heute';
			}))].sort();
			const endArray = [...new Set(modelyears.map(item => {
				return item.End || 'Heute';
			}))].sort();

			const min = Math.min.apply(null, startArray),
				max = endArray.includes('Heute') ? 'Heute' : Math.max.apply(null, endArray),
				$elementFrom = this.$ctx.find(this._yearFromFld),
				$elementTo = this.$ctx.find(this._yearToFld);

			for (const key in startArray) {
				$elementFrom.append($('<option/>').attr('value', startArray[key]).text(startArray[key]));
			}
			for (const key in endArray) {
				$elementTo.append($('<option/>').attr('value', endArray[key]).text(endArray[key]));
			}
			$elementFrom.find(`option[value="${min}"]`).prop('selected', true);
			$elementTo.find(`option[value="${max}"]`).prop('selected', true);

			this._updateStorage('BaureiheModellstartjahr', startArray);
			this._updateStorage('BaureiheModellendjahr', endArray);

			this._loadConfig.years = true;
			this.$ctx.trigger('loadConfig');
		},

		_handleErrorModelYearsRequest: function _handleErrorModelYearsRequest(error) {
			this._classifyError(error);
			T.Utils.View.stopLoader();
		},


		_requestSearchCount: function _requestSearchCount() {
			this._pageNumber = 0;
			T.Utils.View.startLoader();
			const payload = {
				HerstellerId: this._getDropdownVal(this._manufactureFld),
				BaureiheId: this._getDropdownVal(this._modelFld),
				BaureiheModellstartjahr: this._getDropdownVal(this._yearFromFld),
				BaureiheModellendjahr: this._getDropdownVal(this._yearToFld),
				FahrzeugklassenIds: this._getCheckArrayVals(this._classesFld)
			};

			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apicrashtestsucheanzahl),
				data: payload
			};
			T.Utils.Ajax.fragment(request, this._handleSuccessSearchCountRequest.bind(this), this._handleErrorSearchRequest.bind(this));
		},

		_handleSuccessSearchCountRequest: function _handleSuccessSearchCountRequest(data) {
			try {
				this._clearErrors();
				if (data && data.Data) {
					this._addSearchCountToView(data);
				}
			} finally {
				T.Utils.View.stopLoader();
			}
		},

		_handleErrorSearchRequest: function _handleErrorSearchRequest() {
			this.$ctx.find(this._counterBnt).toggleClass('h-hidden', true);
		},

		_addSearchCountToView: function _addSearchCountToView(data) {
			this.$ctx.find(this._counterBnt).text(data.Data.Anzahl);
		},

		_toggleLoadMoreButton: function _toggleLoadMoreButton(totalPages) {
			if ((totalPages - 1) === this._pageNumber) {
				this.$loadMore.toggleClass('h-hidden', true);
			}
			else {
				this.$loadMore.toggleClass('h-hidden', false);
			}
		},

		_clearErrors: function _clearErrors() {
			this.$noBackend.toggleClass('h-hidden', true);
			this.$noResult.toggleClass('h-hidden', true);
			this.$noBackend.toggleClass('h-hidden', true);
		},

		_getDropdownVal: function _getDropdownVal(selector) {
			const result = this.$ctx.find(selector).val();
			if ("0" !== `${result}`) {
				return result;
			}
			return undefined;
		},
		_setDropdownVal: function _setDropdownVal(selector, val) {
			const $elm = this.$ctx.find(selector);
			$elm.val(val);
		},
		_clearDropdown: function _clearDropdown(selector) {
			const $elm = this.$ctx.find(`${selector} option`);
			$elm.remove();
		},

		_loadMoreResults: function _loadMoreResults(evt) {
			if (evt) {
				evt.preventDefault();
			}
			this._pageNumber++;
			this._requestResults();
			return false;
		},

		_getCheckArrayVals: function _getCheckArrayVals(selector) {
			const resultList = [];
			const $items = this.$ctx.find(`${selector}:checked`);
			$items.each((index, item) => {
				resultList.push(item.value);
			});
			return resultList;
		},

		_setCheckArrayVals: function _setCheckArrayVals(selector, values) {
			const $checkboxes = this.$ctx.find(selector);
			$checkboxes.prop("checked", false);
			(Array.isArray(values) ? values : (`${values}`).split(",")).forEach((val) => {
				$checkboxes.filter(`[value="${val}"]`).prop("checked", true);
			});
		},

		_classifyError: function _classifyError(error) {

			//if pagenumber is 0, there's no results for search at all.
			if (this._pageNumber === 0) {
				//no result
				if (error && error.responseJSON) {
					this.$noResult.toggleClass('h-hidden', false);
					this.$resultContainer.toggleClass('h-hidden', false);
				}
				//no backend
				else {
					this.$noBackend.toggleClass('h-hidden', false);
				}
			}
			this.$resultContainer.find('.js-result-table-wrapper').toggleClass('h-hidden', true);
			this.$resultContainer.find(this._tableBody).empty([]);
		}
	});
}(jQuery));