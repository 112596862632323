'use strict';
(function ($) {
	/**
	 * AdacMaps module implementation.
	 *
	 * @author Dmitri Zoubkov <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class BasicAdacMaps
	 * @extends T.Module
	 */
	T.Module.TourMail = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		$form: null,

		TYPEAHEAD_PARAMS_TOURMAIL: { ListOfGeoArt: ["L", "R", "O"] },

		sendDaysBefore: '[name="SendeTageVorReise"]',

		travelStart: '[name="Reiseantritt"]',

		travelPlaces: '[name="Ziele"]',

		currentSelection: undefined,

		suggestionSelected: false,

		validationOptions: {
			rules: {
				Email: {
					required: true,
					email: true
				},
				Postleitzahl: {
					required: true,
					minlength: 5,
					maxlength: 5,
					pattern: "^[a-zA-Z\\d]+$"
				},
				Reiseantritt: {
					required: true,
					checkDate: true
				},
				Ziele: {
					required: true
				}
			},
			messages: {
				Email: {
					required: "Bitte wählen Sie eine Email aus",
					email: "Bitte wählen Sie eine valide Email aus"
				},
				Postleitzahl: {
					required: "Bitte geben Sie eine PLZ ein.",
					minlength: "Bitte mindestens {0} Ziffern / Buchstaben",
					maxlength: "Bitte maximal {0} Ziffern / Buchstaben.",
					pattern: "Bitte geben Sie eine PLZ ein."
				},
				Reiseantritt: {
					required: "Bitte wählen Sie ein Reiseziel aus",
					checkDate: "Bitte geben Sie ein valides Datum an."
				},
				Ziele: {
					required: "Bitte wählen Sie ein Reiseziel aus"
				}
			}
		},

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');
			this.confirmationUrl = this.$ctx.data('tourmail-confirmationpage-url');
			this.apigeocode = this.$ctx.data("apigeo-url");
			this.dateFormat = 'DD.MM.YYYY';
			this.$tourmailBackend = this.$ctx.find('.js-tourmail-backend');
			this.$toumailNoLocation = this.$ctx.find('.js-tourmail-no-location');
			this.$mapsBackend = this.$ctx.find('.js-maps-backend');

			T.Utils.Application.init();
			const searchUrl = T.Utils.Helper.updateUrlParameter(this.TYPEAHEAD_PARAMS_TOURMAIL, T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apigeocode));

			const $travelPlaces = this.$ctx.find(this.travelPlaces);
			T.Utils.View.setDataSafe($travelPlaces, "searchurl", searchUrl);
			T.Utils.View.setDataSafe($travelPlaces, "subscription-key", T.Utils.Application.getApiMSubscriptionKey());

			this.$input = this.$ctx.find('.js-submit-search');
			T.Utils.View.setDataSafe(this.$input, "searchurl", searchUrl);
			T.Utils.View.setDataSafe(this.$input, "subscription-key", T.Utils.Application.getApiMSubscriptionKey());

			this._addValidationMethod(this.dateFormat);
			this.validationOptions.messages = $.extend({}, this.validationOptions.messages, this.$form.data('validationoption'));
			T.Utils.Validation.init(this.$ctx, this.$form, this.validationOptions);

			this._registerListeners();
			this.getUserData(this._handleUserVerificationState.bind(this));
			if (T.Utils.Helper.isExperienceEditor()) {
				this._clearHtmlForExperienceEditor();
			}
			this.$ctx.find(this.travelStart).val(moment().format(this.dateFormat));
			resolve();

		},

		_addValidationMethod: function _addValidationMethod(dateFormat) {
			$.validator.addMethod('checkDate', function (value) {
				const givenDate = moment(value, dateFormat),
					now = moment().format(dateFormat),
					maxDate = moment().add(1, 'y').format(dateFormat),
					isBetween = (moment(givenDate, dateFormat).isBetween(moment(now, dateFormat), moment(maxDate, dateFormat), undefined, '[]'));
				return givenDate.isValid() && isBetween;
			}, this.validationOptions.messages.Reiseantritt.checkDate);
		},

		_clearHtmlForExperienceEditor: function _clearHtmlForExperienceEditor() {
			this.$ctx.find('*').removeClass('h-hidden');
		},

		_registerListeners: function _registerListeners() {
			this.$ctx.find('.js-login').on('click', function () {
				T.Utils.Auth.authorize();
			});
			this.$ctx.find(this.travelStart).on('change', this._changeTravelDate.bind(this)).change();
			this.$ctx.find('.js-submit').on('click', this._submitForm.bind(this));
			this.$ctx.on('automcompleteSelect', this._handleSelection.bind(this));
			this.$input.on("blur", this._handleBestMatch.bind(this));
			this.$input.keypress((e) => { //NOSONAR
				if (13 === e.keyCode) {
					if (this._autocompletePending) {
						delete this._autocompletePending;
						return false;
					}
					this._handleBestMatch(e);
				}
			});
			this.$ctx.on('mapsError', this._handleMapsError.bind(this));
		},
		_changeTravelDate: function _changeTravelDate() {
			const $givenDate = this.$ctx.find(this.travelStart),
				now = moment().format(this.dateFormat),
				daysDiff = moment($givenDate.val(), this.dateFormat).diff(moment(now, this.dateFormat), 'days');

			if (daysDiff >= 7) {
				this.$ctx.find(this.sendDaysBefore).prop('disabled', false);
			} else {
				this.$ctx.find(this.sendDaysBefore).map(function () {
					if ($(this).attr('value') > daysDiff) {
						$(this).prop('disabled', true);
						$(this).prop('checked', false);
					} else {
						$(this).prop('disabled', false);
					}
					return true;
				});
			}
		},

		_handleSelection: function _handleSelection(origEvt, term, item) {
			if (origEvt && 'keydown' === origEvt.type && 13 === origEvt.keyCode) {
				this._autocompletePending = true;
			}
			this.suggestionSelected = true;
			this._handleSuggestData(item);

		},
		_handleBestMatch: function _handleBestMatch(e) {
			if (this.suggestionSelected) {
				this.suggestionSelected = false;
				return;
			}

			const suggest = T.Utils.Autocomplete.getLastBestMatchSuggestion(jQuery(e.target));
			this._handleSuggestData(suggest);
		},

		_handleSuggestData: function _handleSuggestData(data) {
			if (data && data.data()) {
				const elemDataAttrs = data.data();
				const travelObj = {
					Bezeichnung: elemDataAttrs.jsVal,
					Position1X: elemDataAttrs.jsPoint1.X,
					Position1Y: elemDataAttrs.jsPoint1.Y,
					Countryiso2: elemDataAttrs.jsLaenderkuerzel
				};
				if (elemDataAttrs.jsPoint2) {
					travelObj.Position2X = elemDataAttrs.jsPoint2.X;
					travelObj.Position2Y = elemDataAttrs.jsPoint2.Y;
				}
				this.$input.val(travelObj.Bezeichnung);
				this.currentSelection = travelObj;
				this.$mapsBackend.addClass('h-hidden');
			}
		},

		_submitForm: function _submitForm(ev) {
			ev.preventDefault();
			if (undefined === this.currentSelection) {
				this._showNoLocationError();
				return;
			}

			if (this.$form.valid()) {
				const data = this.$form.serializeArray().reduce(function (a, x) { a[x.name] = x.value; return a; }, {});

				// date in user's timezone!!! assuming travel won't start at midnight
				data.Reiseantritt = moment(data.Reiseantritt, this.dateFormat).add(12, 'hours').toISOString();
				data.Ziele = [this.currentSelection];
				T.Utils.Auth.getBearerToken((token) => {
					const options = {
						type: 'POST',
						data: data,
						url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.$ctx.data('tourmailapi-url')),
						headers: {
							"Authorization": `Bearer ${token}`,
							"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey()
						},
						contentType: "application/x-www-form-urlencoded;charset=utf-8"
					};
					T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));

				});

			}
		},

		_successCallback: function _successCallback() {
			this.$tourmailBackend.addClass('h-hidden');
			this.$toumailNoLocation.addClass('h-hidden');
			this.$mapsBackend.addClass('h-hidden');

			T.Utils.Helper.routeToUrl(this.confirmationUrl);
		},

		_errorCallback: function _errorCallback(err) {
			if (err && err.Messages && err.Messages.length > 0 && 274003 === err.Messages[0].Id) {
				this._showNoLocationError();
			} else {
				this._showBackendError();
			}
		},

		_showNoLocationError: function _showNoLocationError() {
			this.$toumailNoLocation.removeClass('h-hidden');
			this.$tourmailBackend.addClass('h-hidden');
		},

		_showBackendError: function _showBackendError() {
			this.$tourmailBackend.removeClass('h-hidden');
			this.$toumailNoLocation.addClass('h-hidden');
		},

		_handleMapsError: function _handleMapsError(ctx, jqXHR) {
			if (jqXHR.status === 500) {
				this.$mapsBackend.removeClass('h-hidden');
			}
		},

		/**
		 * Gets the user data from the application identity. Currently this call may result in several redirects to get a token for reading the user data.
		 *
		 * @param {function} name callback: the callback to call with optional user data after processing finished.
		 */
		getUserData: function getUserData(callback) {
			T.Utils.Auth.getResolvedIdentity(callback);
		},

		/**
		 * Check if the current user is already verified
		 * @param {User} user
		 */
		_handleUserVerificationState: function _handleUserVerificationState(user) {
			this._addUserInfoToView(user);
			this._showAndHideFields(user);
		},

		_addUserInfoToView: function _addUserInfoToView(user) {
			if (user && user.UserData) {
				if (user.UserData.Email) {
					this.$ctx.find('[name="Email"]').val(user.UserData.Email);
				}
				if (user.UserData.Anschrift && user.UserData.Anschrift.Postleitzahl) {
					this.$ctx.find('[name="Postleitzahl"]').val(user.UserData.Anschrift.Postleitzahl);
				}
			}
		},

		_showAndHideFields: function _showAndHideFields(user) {
			if (user) {
				this.$ctx.find('.js-userisnotloggedin').addClass('h-hidden');
				this.$ctx.find('.js-userisloggedin').removeClass('h-hidden');
			} else {
				this.$ctx.find('.js-userisloggedin').addClass('h-hidden');
				this.$ctx.find('.js-userisnotloggedin').removeClass('h-hidden');
			}
		}
	});
}(jQuery));