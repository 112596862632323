(function ($)
{
    'use strict';
    /**
     * BasicCategories module implementation.
     *
     * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
     * @namespace T.Module
     * @class BasicCategories
     * @extends T.Module
     */
    T.Module.BasicCategories = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $swiper: null,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {jQuery} */
        $swiperBtnLeft: null,

        /** @type {jQuery} */
        $swiperBtnRight: null,

        /** @type {Object[]} */
        $swiperSlides: null,

        /** @type {Boolean} */
        isInitialized: false,

        /** @type {Boolean} */
        isDestroyed: false,

        /** @type {jQuery} */
        $mySwiper: null,

        /** @type {Boolean} */
        isLazy: false,

        /** @type {Boolean} */
        isLoaded: false,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$swiperContainer = this.$ctx.find('.swiper');
            this.$swiperSlides = this.$swiperContainer.find('.swiper-slide');
            this.$swiperBtnLeft = this.$swiperContainer.find('.swiper-button-prev');
            this.$swiperBtnRight = this.$swiperContainer.find('.swiper-button-next');

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides, true);
            resolve();
        },

        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate()
        {
            this.initSwiperTeaser();

            this.resizer();
        },

        initSwiperTeaser: function ()
        {
            // init swiper conatiner
            if (S.Utils.Helper.mq('tablet').matches && !this.isInitialized)
            {
                this.$mySwiper = new Swiper(this.$swiperContainer[0], {
                    slidesPerView: 'auto',
                    speed: 400,
                    simulateTouch: true,
                    keyboard: {
                        enabled: true,
                    },
                    watchSlidesProgress: true,
                    spaceBetween: 13,
                    navigation: {
                        prevEl: this.$swiperBtnLeft[0],
                        nextEl: this.$swiperBtnRight[0],
                    },
                    lazy: {
                        checkInView: true,
                        loadPrevNextAmount: 5,
                        loadPrevNext: true,
                    },
                    on: {
                        /**
                         * @params {swiper, slideEl, imageEl}
                         *
                         * @param {Object} swiper
                         * @param {HTMLElement} slideEl
                         */
                        lazyImageLoad: (swiper, slideEl) =>
                        {
                            const $slide = $(slideEl);

                            // kept the same old logic from loadVisibleImages() Function
                            $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                            $slide.find('.js-init').removeClass('js-init');
                        },
                        lazyImageReady: (swiper, slideEl) =>
                        {
                            const $slide = $(slideEl);

                            S.Lazy.handleUnveilClasses($slide.find('img'));
                        }
                    }
                });

                this.isInitialized = true;
                this.isDestroyed = false;
            }
        },

        resizer: function ()
        {
            window.addEventListener('resize', () =>
            {
                S.Utils.delayed(`basiccategorie-resizer-${this.$ctx.data('t-id')}`, 100, () =>
                {
                    this.handleSwiperSize();
                });
            }, {
                passive: false
            });
        },

        handleSwiperSize: function ()
        {
            if (S.Utils.Helper.mq('tablet').matches)
            {
                this.initSwiperTeaser();
            }
            else if (!this.isDestroyed)
            {
                this.isInitialized = false;
                this.isDestroyed = true;
                this.$mySwiper.destroy(true, true);
                this.$swiperBtnRight.addClass('swiper-button-disabled');
                this.$swiperBtnLeft.addClass('swiper-button-disabled');
            }
        }
    });
}(jQuery));
