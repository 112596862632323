(function ($) // eslint-disable-line
{
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.CssAnimation = {

		/**
		 * init
		 */
		init() { },

		/**
		 * Determine if the element has a CSS animation applied
		 * @param {HTMLBodyElement|jQuery} el - element to inspect
		 * @returns {Array} true if an animation/transition detected, otherwise false
		 */
		checkForSingleElAnimation(el) {
			let currentElement = el;
			// if given el is jQuery
			if (el instanceof jQuery) {
				currentElement = el.get(0);
			}

			// check the element and return true/false and transitionType
			return [this.checkElement(currentElement), this.whichTransitionEvent()];
		},

		/**
		 * check for each animation/transition duration
		 * @param {Object|HTMLBodyElement} currentElement
		 * @param {Boolean} returnElement if true, return element not true only
		 * @returns {boolean|jQuery}
		 */
		checkElement(currentElement, returnElement = false) {
			// default value of animation/transition-duration is always 0, also if its not set
			const style = window.getComputedStyle(currentElement, null),
				// read the animation/transition duration - defaults to 0
				animDuration = parseFloat(style.getPropertyValue('animation-duration') || '0'),
				transDuration = parseFloat(style.getPropertyValue('transition-duration') || '0');

			// if we have any duration greater than 0, an animation exists
			if (animDuration > 0 || transDuration > 0) {
				return returnElement ? returnElement : true;
			}

			return false;
		},

		/**
		 * Determine if any element in a collection have a CSS animation applied
		 * @param {HTMLBodyElement|jQuery} elCollection - elements to inspect
		 * @returns {Array} [jQuery, String] gives alle elements back which are having a transition, otherwise false
		 *
		 * only issue with this, its a bit expensive - dont use this too often
		 * only issue with this, its a bit expensive - dont use this too often
		 */
		checkForElCollectionAnimation(elCollection) {
			// go through each item and check for an animation/transition
			const animationElements = elCollection.filter((_, element) => {
				return this.checkElement(element, true);
			});

			// check the element and return elements or false and transitionType
			return [animationElements.length ? animationElements : false, this.whichTransitionEvent()];
		},

		/**
		 * This is only necassery for old browser, any browser today support transtion/webKitTransition -> 'transitionend'
		 *
		 * Get information from browser, which transition event is needed
		 * @return {string}
		 */
		// eslint-disable-next-line
		whichTransitionEvent() //NOSONAR return
		{
			const el = document.createElement('fakeelement'),
				transitions = {
					'transition': 'transitionend',
					'OTransition': 'oTransitionEnd',
					'MozTransition': 'transitionend',
					'WebkitTransition': 'webkitTransitionEnd',
				};

			for (const t in transitions) {
				if (el.style[t] !== undefined) {
					return transitions[t];
				}
			}
		},
	};
})(jQuery);
