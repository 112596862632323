(function ($) {
    'use strict';
    /**
     * BasicLink module implementation.
     *
     * @author  <n.bork@edelweiss72.de>
     * @namespace T.Module
     * @class BasicLink
     * @extends T.Module
     */
    T.Module.BasicLink = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery}*/
        infoIcon: null,

        /** @type {jQuery} mainLanguageWrapper */
        mainLanguageWrapper: null,

        start: function (resolve) {
            this.$ctx = $(this._ctx);
            this.infoIcon = this.$ctx.find('.aa-info-icon');

            this.initScroll();

            // check if an "i"-icon is in the list and make the label and icon clickable
            if (this.infoIcon.length) {
                // for clicking a button with the class - mm-layer
                // which contains a layer -> trigger the event
                this.infoIcon.on('click', (e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    this._events.emit('MyAdacDashboardBoxLayer.open-layer-by-link', $(e.target));
                });
            }

            resolve();
        },

        /**
         * jumpmark animation
         */
        initScroll: function () {
            $('.a-basic-link--anchor').on('click', function (e) {
                e.preventDefault();

                const target = this.hash;
                const $target = $(target);

                const scroll = ($target.offset().top) - 120;

                $('html, body').stop().animate({
                    'scrollTop': scroll
                }, 800, 'swing');
            });
        },
    });
}(jQuery));