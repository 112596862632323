'use strict';
(function ($) {
	/**
	 * GasStationHotlistControls module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>
	 * @namespace T.Module
	 * @class GasStationHotlistControls
	 * @extends T.Module
	 */
	T.Module.GasStationHotlistControls = T.createModule({
		_isLoggedIn: false,
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this._readConfig();

			T.Utils.Auth.getResolvedIdentity(async () => {
				this._isLoggedIn = await T.Utils.Auth.isLoggedIn();
				this._updateView();
			});
			this._addEventListener();
			resolve();
		},
		_addEventListener: async function _addEventListener() {
			this.$buttonAdd.on("click", this._onButtonAddClicked.bind(this));
			this.$ctx.find(".js-login").on('click', (ev) => {
				ev.preventDefault();
				T.Utils.Auth.authorize();
			});
			this.$ctx.find(".js-register").attr("href", await T.Utils.Auth.makeRegisterURL());
		},
		_updateView: function _updateView() {
			if (this._isLoggedIn) {
				this._checkGasStation();
			}
			this.$buttonAddNotLoggedIn.toggleClass("h-hidden", this._isLoggedIn);
		},
		_addGasStation: function _addGasStation(token) {
			if (!this._gastationId) {
				this._showError(true);
				return;
			}

			const options = {
				type: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey(),
					"Content-Type": 'application/x-www-form-urlencoded'
				},
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._config.apiUrlAdd),
				data: { 'Id': this._gastationId },
				contentType: "application/x-www-form-urlencoded;charset=utf-8"
			};

			T.Utils.Ajax.fragment(options,
				this._handleAddSuccess.bind(this),
				this._errorApiNotAvailableCallback.bind(this));
		},
		_checkGasStation: function _checkGasStation() {
			if (this._gastationId) {
				T.Utils.Auth.getBearerToken((token) => {
					const req = {
						type: "GET",
						url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._config.apiUrlHotlist),
						headers: {
							Authorization: `Bearer ${token}`,
							"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey()
						}
					};
					T.Utils.Ajax.fragment(req, (data) => {
						const found = data && data.Data.length && data.Data.some((element) => {
							return element.ID === Number(this._gastationId);
						});
						this.$buttonAdd.toggleClass("h-hidden", found);
						this.$ctx.find(".js-success").toggleClass("h-hidden", !found);
					}, () => {
						console.warn("Error requesting gastation hotlist");
						this.$buttonAdd.toggleClass("h-hidden", !this._isLoggedIn);
					});
				});
			}
		},
		_errorApiNotAvailableCallback: function _errorApiNotAvailableCallback() {
			this._showError(true);
		},
		_handleAddSuccess: function _handleAddSuccess(data) {
			if (data && data.Success) {
				this.$buttonAdd.toggleClass("h-hidden", true);
				this.$ctx.find(".js-success").toggleClass("h-hidden", false);
			}
		},
		_onButtonAddClicked: function _onButtonAddClicked() {
			if (this._isLoggedIn) {
				T.Utils.Auth.getBearerToken(this._addGasStation.bind(this));
			}

		},
		_readConfig: function _readConfig() {
			this._config = this.$ctx.data("config");
			this.$buttonAdd = this.$ctx.find(".js-add");
			this.$buttonAddNotLoggedIn = this.$ctx.find(".js-add-not-logged-in");
			this._gastationId = T.Utils.Helper.getUrlParameter("id");
			if (!this._gastationId && this._config.idPosInSeoUrl) {
				this._gastationId = T.Utils.Helper.getUrlPathPart(this._config.idPosInSeoUrl);
			}
		},
		_showError: function _showError(show) {
			this.$ctx.find(".js-error").toggleClass("h-hidden", !show);
		}

	});
}(jQuery));