(function ($) {
	'use strict';

	/**
	 * doublehandle decorator implementation for the BasicCalculatorSlider module.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module.BasicCalculatorSlider
	 * @class Doublehandle
	 * @extends T.Module
	 */
	T.Module.BasicCalculatorSlider.Doublehandle = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$slider: null,

		/** @type {Array} */
		$textInputs: null,

		/** @type {jQuery} */
		$firstInput: null,

		/** @type {jQuery} */
		$secondInput: null,

		/** @type {Number} */
		currentVal1: null,

		/** @type {Number} */
		currentVal2: null,

		/** @type {Boolean} */
		slideResetMode: false,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.$slider = this.$ctx.find('.js-slider').get(0);
			this.$textInputs = this.$ctx.find('.js-result');
			this.$firstInput = this.$textInputs.get(0);
			this.$secondInput = this.$textInputs.get(1);

			this.currentVal1 = 0;
			this.currentVal2 = 0;

			this._parent.start(resolve);
		},

		_setupSlider: function ($ctx, initData) {

			const storedValues = [];
			let starters;

			T.Utils.Store.set(`BasicCalculatorSlider${initData.eventname}`, initData, 'session');

			// look after stored values from prev session
			if (this.$ctx.data('no-session-saving') === undefined && sessionStorage.getItem('basickeywordfilter_doubleslider_min')) {
				storedValues.push(sessionStorage.getItem('basickeywordfilter_doubleslider_min'));
				storedValues.push(sessionStorage.getItem('basickeywordfilter_doubleslider_max'));
			}

			// nothing stored
			if (!storedValues.length) {

				// convert string with starting positions to array with integers
				starters = initData.start.split(',').map(function (item) {
					return parseInt(item, 10);
				});
			}

			// readd stored values
			else {
				// convert saved array values to integers
				starters = storedValues.map(function (item) {
					return parseInt(item, 10);
				});
			}

			this._initSlider(
				$ctx,
				parseInt(initData.min, 10),
				parseInt(initData.max, 10),
				starters,
				parseInt(initData.step, 10),
				initData.eventname
			);
		},

		/**
		 * Slider init function
		 *
		 * @param $ctx - current context
		 * @param min - slider min value
		 * @param max - slider max value
		 * @param start - handle start values for both handles
		 * @param step - handle/slider step value
		 * @param eventName
		 */
		_initSlider: function ($ctx, min, max, start, step, eventName) {

			const that = this;

			// create
			noUiSlider.create(that.$slider, { // eslint-disable-line
				range: { 'min': min, 'max': max },
				start: [start[0], start[1]],
				step: step,
				connect: true,
				// set delta between sliders to step
				margin: step
			});

			that.addTextInputEvents(eventName);
			that.addSliderEvents(min, max, start, eventName);
		},

		/**
		 * Listen slider changes
		 *
		 * @param min {Number} - Min slider value
		 *
		 * @param max {Number} - Max slider
		 *
		 * @param start {Array} - staring slider values
		 *
		 * @param eventName {String}
		 */
		addSliderEvents(min, max, start, eventName) {
			const that = this;

			// update
			that.$slider.noUiSlider.on('update', (values) => {

				that.$firstInput.value = parseInt(values[0]);
				that.$secondInput.value = parseInt(values[1]);

				const sliderOptions = T.Utils.Store.get(`BasicCalculatorSlider${eventName}`);
				if (sliderOptions) {
					sliderOptions.start = that.$slider.noUiSlider.get();
					T.Utils.Store.set(`BasicCalculatorSlider${eventName}`, sliderOptions, 'session');
				}
			});

			// set
			that.$slider.noUiSlider.on('set', (sliderVals) => {

				S.Utils.delayed('basicCalcSliderDoubleHandle.delayForSlideupdate', 50, () => {

					if (typeof sliderVals[0] === 'undefined' || sliderVals[0] === '') {
						sliderVals[0] = that.currentVal1;
					}

					if (typeof sliderVals[1] === 'undefined' || sliderVals[1] === '') {
						sliderVals[1] = that.currentVal2;
					}

					if (!that.slideResetMode) {
						that._events.emit('BasicCalculatorSliderDouble.change', sliderVals, that.$ctx);
					}

					that.slideResetMode = false;
				});
			});

			// reset to init
			that._events.on('BasicCalculatorSliderDouble.reset', function () {
				that.slideResetMode = true;
				that.$slider.noUiSlider.set([min, max]);
			});
		},

		/**
		 * Listen to according inputs and sync slider handles with input values
		 *
		 * * @param eventName {String}
		 */
		addTextInputEvents(eventName) {
			const that = this;

			that.$textInputs.each((inputIndex, inputElem) => {

				// focus event
				$(inputElem).on('focus', () => {

					if (inputIndex === 0) {
						that.currentVal1 = $(inputElem).val();
					}

					else {
						that.currentVal2 = $(inputElem).val();
					}

					$(inputElem).val('');
				});

				// blur event
				$(inputElem).on('blur', () => {

					if (inputIndex === 0) {

						if ($(inputElem).val() !== '' && $(inputElem).val() !== that.currentVal1) {
							const sliderOptions = T.Utils.Store.get(`BasicCalculatorSlider${eventName}`);

							if (sliderOptions) {
								sliderOptions.start = that.$slider.noUiSlider.get(0);
								T.Utils.Store.set(`BasicCalculatorSlider${eventName}`, sliderOptions, 'session');
							}

							that.$slider.noUiSlider.set([$(inputElem).val(), null]);
						}

						else {
							$(inputElem).val(that.currentVal1);
						}
					}

					else {
						if ($(inputElem).val() !== '' && $(inputElem).val() !== that.currentVal2) {

							const sliderOptions = T.Utils.Store.get(`BasicCalculatorSlider${eventName}`);

							if (sliderOptions) {
								sliderOptions.start = that.$slider.noUiSlider.get(1);
								T.Utils.Store.set(`BasicCalculatorSlider${eventName}`, sliderOptions, 'session');
							}

							that.$slider.noUiSlider.set([null, $(inputElem).val()]);
						}

						else {
							$(inputElem).val(that.currentVal2);
						}
					}

				});
			});
		}
	});
}(jQuery));
