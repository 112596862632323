'use strict';
(function ($) {
	/**
	 * CarInsuranceExternalPartner module implementation.
	 *
	 * @author  <stefan.pirkl@gks.adac.de>
	 * @namespace T.Module
	 * @class CarInsuranceExternalPartner
	 * @extends T.Module
	 */
	T.Module.CarInsuranceExternalPartner = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this._sendRequest();
			resolve();
		},
		_addEventListener() {
			this.$submit.on('click', this._sendRequest.bind(this));
		},
		_sendRequest: function _sendRequest() {
			const api = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.$ctx.data('api-token'));
			const advertisingKey = this.$ctx.data('wbs');
			const options = {
				type: 'GET',
				url: api,
				data: { "wbs": advertisingKey },
				xhrFields: {
					withCredentials: true
				}
			};
			T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
		},
		_successCallback: function _successCallback(data) {
			if (data.Data && data.Data.Token) {
				this.$ctx.find('#jwt').val(data.Data.Token);
				this._doRedirect();
			}
		},
		_errorCallback: function _errorCallback() {
			this.$ctx.find("#errortext").removeClass("h-hidden");
		},
		_doRedirect: function _doRedirect() {
			const $form = this.$ctx.find("form")[0];
			if ($form) {
				$form.submit();
			}
		},
		_showError: function _showError() {
			this.$errorId.toggleClass("h-hidden", false);
		},
		_resetError: function _resetError() {
			this.$errorId.toggleClass("h-hidden", true);
		}
	});
}(jQuery));
