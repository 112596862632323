(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.LMainContent = {

		/**
		 * l-main-content og 2016-04-11
		 *
		 *
		 */

		/**
		 * @type {String} layer
		 */
		$lastContentContainer: null,

		/**
		 * @type {String} layer
		 */
		$banderoleContainer: null,

		classMainContent: '.l-main-content',
		classSwitchbox: '.ll-main-content-switchbox',
		classSwitchboxActive: 'll-main-content-switchbox--active',
		classSwitch: '.ll-main-content-switch',
		classSwitchAside: 'll-main-content-switch--aside',
		selector: 'input, textarea, button, select, a',

		/**
		 * init
		 *
		 */
		init: function () {
			// delete last empty when empty
			this.deleteLastEmptyContent();

			// init switch content
			this.switchContentInit();
		},

		deleteLastEmptyContent: function () {
			if ($(this.classMainContent).length) {
				this.$lastContentContainer = $(this.classMainContent).last();

				if ($(".l-main-full").length) {
					this.$banderoleContainer = $(".ll-main-full").last();

					if (!this.$lastContentContainer.children().length && !this.$banderoleContainer.children().length) {
						this.$lastContentContainer.remove();
					}
				}
			}
		},

		/**
		 * switchContentInit
		 *
		 */
		switchContentInit: function () {
			const that = this;

			if ($(this.classSwitch).length) {
				$(this.classSwitch).each(function (index, item) {
					$(item).find('*[data-contentswitchcontroller]').each(function (index, item) {

						$(item).on('click', function (e) {
							e.preventDefault();
							S.Utils.LMainContent.switchContent($(this));
						});
					});

					// if aside
					if ($(item).hasClass(that.classSwitchAside)) {
						const tabFocusElements = that.selector;

						// hide form elements from sibling switchboxes to ignore tab focus there
						$(item).find('.ll-main-content-switchbox:not(:first-child)').find(tabFocusElements).hide();
					}
				});
			}
		},

		/**
		 * switchContent :: includes hiding old and showing new content
		 *
		 * @param {Object} elem :: clicked controller in active switch content
		 */
		switchContent: function (elem) {
			let indexToShow = 0;
			if (elem.data('contentswitchcontroller')) {
				indexToShow = parseInt(elem.data('contentswitchcontroller'));
			}
			const containerWrapper = elem.closest(this.classSwitch);
			const containerActive = elem.closest(this.classSwitchbox);

			// if there are more than one container to switch
			if (containerActive.siblings(this.classSwitchbox).length) {
				const containerNext = containerWrapper.find(this.classSwitchbox).eq(indexToShow);

				// hide actual content
				this.switchContentHide(containerActive, containerNext);
			} else {
				// hide actual content only :: for showing next, hou have to fire separately
				this.switchContentHide(containerActive);
			}
		},

		/**
		 * switchContentHide
		 *
		 * @param {Object} elemToHide :: container to hide
		 * @param {Object} elemToShow :: container to show
		 */
		switchContentHide: function (elemToHide, elemToShow) {
			const that = this,
				containerWrapper = elemToHide.closest(this.classSwitch);

			if (containerWrapper.hasClass(this.classSwitchAside)) {
				// if elemToShow is known, go on
				if (elemToShow) {
					that.switchContentShow(elemToShow);
				}
			} else {
				// set actual height
				containerWrapper.css({
					'height': `${elemToHide.outerHeight()}px`
				});

				// hide content
				elemToHide.fadeOut('fast', function () {

					// set hidden class, remove active class
					$(this).addClass('ll-main-content-switchbox--hidden')
						.removeClass(that.classSwitchboxActive);

					// if elemToShow is known, go on
					if (elemToShow) {
						that.switchContentShow(elemToShow);
					}
				});
			}
		},

		/**
		 * switchContentShow
		 *
		 * @param {Object} elemToShow :: container to show
		 */
		// eslint-disable-next-line
		switchContentShow: function (elemToShow) { //NOSONAR return
			const containerWrapper = elemToShow.closest(this.classSwitch);

			if (containerWrapper.hasClass(this.classSwitchAside)) {

				let actualIndex = 0,
					nextIndex = 0,
					asideSpeed = 500,
					newPos = 0;

				const containerSwitchWrapper = containerWrapper.find('> .ll-main-content-switchwrap').eq(0),
					slides = containerSwitchWrapper.find(this.classSwitchbox),
					actualPos = parseInt(containerSwitchWrapper.css('left').replace('px', '')),
					that = this;

				if (S.Utils.Helper.mq('tablet').matches) {
					// tablet + desktop
					asideSpeed = 1000;
				}

				// mark next slide
				elemToShow.data('next', true);

				// find actualIndex + nextIndex
				slides.each(function (index, item) {
					if ($(item).hasClass(that.classSwitchboxActive)) {
						actualIndex = index;
					}

					if ($(item).data('next')) {
						nextIndex = index;
						$(item).removeData('next');
					}
				});

				const containerActive = containerWrapper.find(this.classSwitchbox).eq(actualIndex);

				// check if elemToShow allready is the actual content
				if (elemToShow.attr('id') && containerActive.attr('id') && elemToShow.attr('id') === containerActive.attr('id')) {
					return false;
				}

				if (actualPos === 0) {
					containerWrapper.addClass('is-aside');
				} else {
					containerWrapper.removeClass('is-aside');

					// set last class for removing right shadow
					if (nextIndex < (slides.length - 1)) {
						containerWrapper.removeClass('is-aside-last');
					}
				}

				// if is last slide, set last class
				if (!elemToShow.next().length) {
					containerWrapper.addClass('is-aside-last');
				}

				const tabFocusElements = that.selector;

				// hide form elements from actual switchbox to ignore tab focus there
				elemToShow.find(tabFocusElements).show();

				// get next content height

				// remove active class
				containerSwitchWrapper.find('.ll-main-content-switchbox--active').removeClass(that.classSwitchboxActive);

				// add active class
				elemToShow.addClass(that.classSwitchboxActive);

				// get new pos for animation
				if (nextIndex > actualIndex) {
					newPos = -(actualIndex * 100) - 100;
				} else {
					newPos = -(actualIndex * 100) + 100;
				}

				// animate switch wrap
				containerSwitchWrapper.animate({
					left: `${newPos}%`
				}, asideSpeed, function () {
					const tabFocusElements = that.selector;

					// hide form elements from actual switchbox to ignore tab focus there
					elemToShow.siblings(that.classSwitchbox).not(elemToShow).find(tabFocusElements).hide();

					// focus elemToShow
					elemToShow.focus();
				});

				// slide up all data-contentswitchhide with number of actual content to hide
				$(this.classMainContent).find('div[data-contentswitchhide]').each(function (index, item) {
					if ($(item).data('contentswitchhide') === actualIndex) {
						$(item).slideUp(asideSpeed);
					} else if ($(item).data('contentswitchhide') === nextIndex) {
						$(item).slideDown(asideSpeed);
					}
				});

			} else {
				// get next content height
				elemToShow.removeClass('ll-main-content-switchbox--hidden').show();

				const elemToShowHeight = elemToShow.outerHeight();
				elemToShow.hide();

				// animate to next height, disable max-height
				containerWrapper.animate({
					height: `${elemToShowHeight}px`
				}, 500, () => {

					// fade in and set active class
					elemToShow.addClass(this.classSwitchboxActive).fadeIn('slow');

					// scroll to elemToShow top
					$('html, body').animate({
						scrollTop: elemToShow.offset().top - 80
					}, 500);
				});
			}
		}
	};


	$(document).ready(function () {
		S.Utils.LMainContent.init();
	});

})(jQuery);
