'use strict';
(function ($) {
	/**
	 * Award decorator implementation for the vescalculatorshort component.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class Rrv
	 * @extends T.Module
	 */
	T.Module.VesCalculatorShort.Api = T.createDecorator({
		/** @type {jQuery} */
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.api = this.api || $('meta[data-key="backend"]').attr('data-value');
			this.calculator = $(this._ctx).data('calculator');
			this.proposal = $(this._ctx).data('proposal');


			this._parent.start(resolve);
		},

		dummy: function () {

		},

		/**
		 * calculate
		 *
		 */
		calculate: function () {
			const that = this;
			let result = '';
			const membershipCheck = (that.membership === 'ADACMitglied'),
				familyCheck = that.singleRate !== 'true',
				ageCheck = (that.age <= 66);

			const data = {
				IstMitglied: membershipCheck,
				Familientarif: familyCheck,
				Unter66: ageCheck
			};

			T.Utils.Store.set('vesCalculatorShort', data, 'session');

			T.Utils.Ajax.fragment(
				{
					url: this.api + this.calculator,
					type: 'POST',
					data: JSON.stringify(data)
				},
				function (response) {
					result = response.Data.Beitrag;

					if (result.toString().match(/\.[0-9]{1}$/g)) { //NOSONAR
						result = `${result}0`;
					}

					// update sticky box
					that.$ctx.find('.mm-amount-value').html($.number(result, 2).replace('.', ','));

					that.$ctx.find('.mm-error').hide();
					that.$ctx.find('.js-calculator-proposal').prop('disabled', false).removeClass('is-disabled');
					that.$ctx.find('.mm-value-container').show();
				},
				function () {
					that.$ctx.find('.mm-error').show();
					that.$ctx.find('.js-calculator-proposal').prop('disabled', true).addClass('is-disabled');
					that.$ctx.find('.mm-value-container').hide();

					that.$ctx.find('.a-basic-btn').on('click', function (e) {
						e.preventDefault();
					});

				});

		}







	});
}(jQuery));
