(function ($) {
	'use strict';
	/**
	 * Lightboxclose decorator for the basicBtn module
	 *
	 * @author  <s.krause@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicBtn
	 * @extends T.Module
	 */
	T.Module.BasicBtn.Lightboxclose = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			// click events
			this.clickevents();

			this._parent.start(resolve);
		},
		clickevents: function () {

			this.$ctx.on('click', function (e) {
				e.stopPropagation();
				e.preventDefault();

				if ($.magnificPopup.instance.isOpen) {
					$.magnificPopup.close();
				}

			});
		}

	});
}(jQuery));
