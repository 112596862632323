(function ($) {
    'use strict';
    /**
     * BasicList module implementation.
     *
     * @author Lars Meyer<l.meyer@edelweiss72.de>
     * @namespace T.Module
     * @class BasicList
     * @extends T.Module
     */

    T.Module.BasicList = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        start: function (resolve) {

            this.$ctx = $(this._ctx);

            resolve();
        }
    });
}(jQuery));
