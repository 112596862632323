(function($) {
	'use strict';
	/**
	 * BasicCookie module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicCookie
	 * @extends T.Module
	 */
    T.Module.BasicCookie = T.createModule( {

        /** @type {jQuery} */
        $ctx: null,

		/** @type {jQuery} */
		$btnClose: null,

        start: function ( resolve )
        {
            this.$ctx = $( this._ctx );

            if ( Cookies.get( 'dataprotection' ) === undefined )
            {
				this.$ctx.addClass('is-sliding-down');
                this.addEvents();
            }

            resolve();
        },

		addEvents()
		{
			// closing
			this.$btnClose = this.$ctx.find( '.m-btn' );

			if ( this.$btnClose.length )
			{
				this.$btnClose.on( 'click', () => {

					// create cookie by value 'true' for 30 days
					Cookies.set( 'dataprotection', 'true', {path: '/'} );

					this.$ctx.removeClass('is-sliding-down');
					this.$ctx.parent().removeClass('is-cookielayered');
					this._events.emit( 'cookieLayerTop.close' );
				} );
			}
		}
    } );
}(jQuery));
