'use strict';
(function ($) {
	/**
	 * Insurance contract detail view module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class InsuranceContractDetails
	 * @extends T.Module
	 */
	T.Module.InsuranceContractDetails = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		$vesToken: null,
		$form: null,
		_personenKreisDictionary: [],
		insuraneHelptexts: '',
		jsLightbox: 'js-lightbox',
		sessionKey: "selector.InsuranceContractDetails",
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			const that = this;
			this.$form = this.$ctx.find('form');
			T.Utils.Application.init();
			this.returnUrl = this.$ctx.data('return-url');
			this.$vesToken = T.Utils.Helper.getUrlParameter('data');
			this.insuraneHelptexts = this.$ctx.data('helptexts');
			this._getUserData(that._checkTokenAndRequestVesData.bind(that));
			this._createPersonenkreisDictionary();

			resolve();
		},
		_checkTokenAndRequestVesData: function _checkTokenAndRequestVesData() {
			T.Utils.Auth.getBearerToken(this._requestVesData.bind(this));
		},

		_requestVesData: function _requestVesData(token) {

			if (token) {
				if (this.$vesToken) {
					const terminationApi = this.$ctx.data('insurance-api');
					T.Utils.Ajax.fragment(
						{
							url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), terminationApi),
							type: 'POST',
							data: JSON.stringify({
								'VesToken': this.$vesToken
							}),
							cache: false,
							headers: {
								Authorization: `Bearer ${token}`,
							},
						},
						(data) => {
							const _data = data.Data;
							if (_data) {
								this._setMyVesData(_data);
							}
							T.Utils.View.stopLoader();
						},
						(jqXHR) => {
							T.Utils.View.stopLoader();
							T.Utils.Logger.log(`Error calling ${terminationApi}`, jqXHR, window.location.href);
							T.Utils.View.showGeneralErrorLayer(() => {
								T.Utils.Helper.routeToUrl(window.location.origin + this.returnUrl);
							});
						});
				}
				else {
					T.Utils.View.stopLoader();
					T.Utils.Helper.routeToUrl(window.location.origin + this.returnUrl);
				}
			}
		},

		_setMyVesData: function (data) {
			this._setPersonData(data);
			this._setVesData(data);
			this._setDataFromInsuranceData();
			this._setContractData(data);
			this._setVorlaeufigeVorzeitigeDeckung(data);
			this._setBausteineRechtsschutz(data);
			this._setFahrzeugVersicherung(data);
			this._setZuhauseVersicherung(data);
			if (data.PersonenUnfallschutz) {
				this._setAndCreateTemplateForPersons(data.PersonenUnfallschutz, data.Zahlweise);
			}
			if (data.Fahrraeder && data.Fahrraeder.length > 0) {
				this._setAndCreateTemplateForBicycle(data.Fahrraeder);
			}

			if (data.Haustiere && data.Haustiere.length > 0) {
				this._setAndCreateTemplateForPets(data.Haustiere);
			}
			this._showAndUpdateLinks(data);
			this._showDataForInsuranceType(data.VertragsArt);
		},
		_setVesData: function _setVesData(data) {
			if (data.Produkt) {
				this.$ctx.find('.js-div-Produkt').removeClass('h-hidden');
				this.$ctx.find('.js-Produkt').text(data.Produkt);
				const pageHeader = this.$ctx.parents('.l-main-content').first().find('.js-my-adac-main-title').find('h1');
				if (pageHeader) {
					pageHeader.text(`${pageHeader.text()} ${data.Produkt}`);
				}
			}
			if (data.BruttoZahlbetrag && data.Zahlweise && data.VertragsArt !== 22 && data.VertragsArt !== 23) {
				this.$ctx.find('.js-div-BruttoZahlbetrag').removeClass('h-hidden');
				this.$ctx.find('.js-BruttoZahlbetrag').text(`${$.number(data.BruttoZahlbetrag, 2, ',', '')} € / ${data.Zahlweise}`);
			}
			if (data.Gesamtjahresbeitrag) {
				this.$ctx.find('.js-div-Gesamtjahresbeitrag').removeClass('h-hidden');
				this.$ctx.find('.js-Gesamtjahresbeitrag').text(`${$.number(data.Gesamtjahresbeitrag, 2, ',', '')} €`);
				this._setYearlyFeeHelpText(data);
			}
			if (data.Zahlart) {
				this.$ctx.find('.js-div-Zahlart').removeClass('h-hidden');
				this.$ctx.find('.js-Zahlart').text(data.Zahlart);
			}

			if (data.Selbstbeteiligung && data.Selbstbeteiligung.length > 0) {
				this.$ctx.find('.js-div-Selbstbeteiligung').removeClass('h-hidden');
				this.$ctx.find('.js-Selbstbeteiligung').text(data.Selbstbeteiligung);
				this._setDecductibleHelpText(data);
			}

			if (data.Rabatt) {
				this.$ctx.find('.js-div-Rabatt').removeClass('h-hidden');
				this.$ctx.find('.js-Rabatt').text(data.Rabatt);
			}

			if (data.Einreisedatum) {
				this.$ctx.find('.js-div-Einreisedatum').removeClass('h-hidden');
				this.$ctx.find('.js-Einreisedatum').text(moment(data.Einreisedatum).format("L"));
			}
			if (data.Produktlinie && data.VertragsArt !== 20) {
				this.$ctx.find('.js-div-Produktlinie').removeClass('h-hidden');
				this.$ctx.find('.js-Produktlinie').text(data.Produktlinie);
			}
			if (data.Produktlinie && data.VertragsArt === 20) {
				this.$ctx.find('.js-div-Baustein').removeClass('h-hidden');
				this.$ctx.find('.js-Baustein').text(data.Produktlinie);
			}
			if (data.Zusatzbaustein) {
				this.$ctx.find('.js-div-Zusatzbaustein').removeClass('h-hidden');
				this.$ctx.find('.js-Zusatzbaustein').text(data.Zusatzbaustein);
			}
		},
		_setContractData: function _setContractData(data) {
			if (data.Vertragsbeginn) {
				this.$ctx.find('.js-div-Vertragsbeginn').removeClass('h-hidden');
				this.$ctx.find('.js-Vertragsbeginn').text(moment(data.Vertragsbeginn).format("L"));
			}
			if (data.Vertragsende) {
				this.$ctx.find('.js-div-Vertragsende').removeClass('h-hidden');
				this.$ctx.find('.js-Vertragsende').text(moment(data.Vertragsende).format("L"));
			}
			if (data.Vertragstyp) {
				this.$ctx.find('.js-div-Vertragstyp').removeClass('h-hidden');
				this.$ctx.find('.js-Vertragstyp').text(data.Vertragstyp);
				this._setContractTypeHelpText(data);
			}
			if (data.Vertragsform) {
				this.$ctx.find('.js-div-Vertragsform').removeClass('h-hidden');
				this.$ctx.find('.js-Vertragsform').text(data.Vertragsform);
			}
			if (data.Versicherungsumme) {
				this.$ctx.find('.js-div-Versicherungsumme').removeClass('h-hidden');
				this.$ctx.find('.js-Versicherungsumme').text(`${$.number(data.Versicherungsumme)} €`);
			}
		},
		_setPersonData: function _setPersonData(data) {
			if (data.Person) {
				this.$ctx.find('.js-div-Person').removeClass('h-hidden');
				let person = `${data.Person.Vorname} ${data.Person.Nachname}`;
				if (data.Person.Geburtsdatum && data.Person.Geburtsdatum.length > 0) {
					person += `, ${moment(data.Person.Geburtsdatum).format("L")}`;
				}
				this.$ctx.find('.js-Person').text(person);
			}
			if (data.PersonenKreis) {
				this.$ctx.find('.js-div-PersonenKreis').removeClass('h-hidden');
				this.$ctx.find('.js-PersonenKreis').text(data.PersonenKreis);
			}
			if (data.PersonKnz > 0) {
				this._getAndFillPersonenKnz(data.PersonKnz);
			}
			if (data.Nutzungsart) {
				this.$ctx.find('.js-div-Nutzungsart').removeClass('h-hidden');
				this.$ctx.find('.js-Nutzungsart').text(data.Nutzungsart);
			}
		},
		_setVorlaeufigeVorzeitigeDeckung: function _setVorlaeufigeVorzeitigeDeckung(data) {
			if (data.VorlaeufigeDeckung && data.VorlaeufigeDeckung !== -1) {
				this.$ctx.find('.js-div-VorlaeufigeDeckung').removeClass('h-hidden');
				const zeit = !moment(data.VorlaeufigeDeckung).format("LT") || (moment(data.VorlaeufigeDeckung).format("LT") === '00:00') ? '' : moment(data.VorlaeufigeDeckung).format("LT");
				this.$ctx.find('.js-VorlaeufigeDeckung').text(`${moment(data.VorlaeufigeDeckung).format("L")} ${zeit}`);
			}
			if (data.VorzeitigeDeckung && data.VorzeitigeDeckung !== -1) {
				this.$ctx.find('.js-div-VorzeitigeDeckung').removeClass('h-hidden');
				this.$ctx.find('.js-VorzeitigeDeckung').text(moment(data.VorzeitigeDeckung).format("L"));
			}
		},
		_setFahrzeugVersicherung: function _setFahrzeugVersicherung(data) {
			if (data.Fahrzeugart) {
				this.$ctx.find('.js-div-Fahrzeugart').removeClass('h-hidden');
				this.$ctx.find('.js-Fahrzeugart').text(data.Fahrzeugart);
			}
			if (data.Kennzeichen) {
				this.$ctx.find('.js-div-Kennzeichen').removeClass('h-hidden');
				this.$ctx.find('.js-Kennzeichen').text(data.Kennzeichen);
			}
			if (data.Fahrzeugversicherung) {
				if (data.Fahrzeugversicherung.Fahrzeugart) {
					this.$ctx.find('.js-div-Fahrzeugart').removeClass('h-hidden');
					this.$ctx.find('.js-Fahrzeugart').text(data.Fahrzeugversicherung.Fahrzeugart);
				}
				if (data.Fahrzeugversicherung.Tarif) {
					this.$ctx.find('.js-div-Tarif').removeClass('h-hidden');
					this.$ctx.find('.js-Tarif').text(data.Fahrzeugversicherung.Tarif);
				}
				if (data.Fahrzeugversicherung.Sparte) {
					this.$ctx.find('.js-div-Sparte').removeClass('h-hidden');
					this.$ctx.find('.js-Sparte').text(data.Fahrzeugversicherung.Sparte);
				}
				if (data.Fahrzeugversicherung.SelbstbeteiligungTeilkaskoBetrag > 0) {
					this.$ctx.find('.js-div-SelbstbeteiligungTeilkasko').removeClass('h-hidden');
					this.$ctx.find('.js-SelbstbeteiligungTeilkasko').text(`${$.number(data.Fahrzeugversicherung.SelbstbeteiligungTeilkaskoBetrag, 2, ',', '')} €`);
				}
				if (data.Fahrzeugversicherung.SelbstbeteiligungVollkaskoBetrag > 0) {
					this.$ctx.find('.js-div-SelbstbeteiligungVollkasko').removeClass('h-hidden');
					this.$ctx.find('.js-SelbstbeteiligungVollkasko').text(`${$.number(data.Fahrzeugversicherung.SelbstbeteiligungVollkaskoBetrag, 2, ',', '')} €`);
				}
				if (data.Fahrzeugversicherung.Versicherungsnummer) {
					this.$ctx.find('.js-div-Versicherungsscheinnummer').removeClass('h-hidden');
					this.$ctx.find('.js-Versicherungsscheinnummer').text(data.Fahrzeugversicherung.Versicherungsnummer);
				}
			}
			this._setFahrzeug(data);

		},

		_setFahrzeug: function _setFahrzeug(data) {
			if (data.Fahrzeug) {
				const fahrzeug = data.Fahrzeug;
				if (fahrzeug.SFplus1) {
					this.$ctx.find('.js-div-SFplus1').removeClass('h-hidden');
					this.$ctx.find('.js-SFplus1').text('Ja');
				}
				let bausteine = '';
				if (fahrzeug.Werkstattbonus) {
					bausteine += `${'Werkstattbonus'} <br>`;
				}
				if (fahrzeug.Fahrsicherheitstraining) {
					bausteine += `${'Fahrsicherheitstraining'} <br>`;
				}
				if (fahrzeug.Auslandsschadenschutz) {
					bausteine += `${'Auslandsschadenschutz'} <br>`;
				}
				if (fahrzeug.Fahrerschutz) {
					bausteine += `${'Fahrerschutz'} <br>`;
				}
				if (fahrzeug.Rabattschutz) {
					bausteine += `${'Rabattschutz'} <br>`;
				}
				if (fahrzeug.FahrUndSpar) {
					bausteine += `${'FahrUndSpar'} <br>`;
				}
				if (fahrzeug.OrtsclubMitglied) {
					bausteine += `${'OrtsclubMitglied'} <br>`;
				}
				if (fahrzeug.Werterhaltgarantie) {
					bausteine += `${'Werterhaltgarantie'} <br>`;
				}
				if (bausteine && bausteine.length > 0) {
					this.$ctx.find('.js-Bausteine').html(bausteine);
					this.$ctx.find('.js-div-Bausteine').removeClass('h-hidden');
				}
				if (fahrzeug.JaehrlicheFahrleistung) {
					this.$ctx.find('.js-div-JaehrlicheFahrleistung').removeClass('h-hidden');
					this.$ctx.find('.js-JaehrlicheFahrleistung').text(`${fahrzeug.JaehrlicheFahrleistung.toLocaleString()} KM`);
				}
			}
		},

		_setZuhauseVersicherung: function _setZuhauseVersicherung(data) {
			if (data.Selbstbeteiligung) {
				this.$ctx.find('.js-div-Selbstbehalt').removeClass('h-hidden');
				this.$ctx.find('.js-Selbstbehalt').text(data.Selbstbeteiligung);
			}
			if (data.Zuhause && data.Zuhause.length > 0) {
				const bausteine = data.Zuhause[0];
				if (bausteine.Extremwetterschutz) {
					this.$ctx.find('.js-div-Zusatzbausteine-Extremwetterschutz').removeClass('h-hidden');
					this.$ctx.find('.js-div-Zusatzbausteine-Extremwetterschutz').append('<br />');
				}
				if (bausteine.Fahrradschutz) {
					this.$ctx.find('.js-div-Zusatzbausteine-Fahrradschutz').removeClass('h-hidden');
					this.$ctx.find('.js-div-Zusatzbausteine-Fahrradschutz').append('<br />');
				}
				if (bausteine.Glasschutz) {
					this.$ctx.find('.js-div-Zusatzbausteine-Glasschutz').removeClass('h-hidden');
				}
				this.$ctx.find('.js-div-Zusatzbausteine').removeClass('h-hidden');
			}
		},
		_setBausteineRechtsschutz: function _setBausteineRechtsschutz(data) {
			if (data.BausteineRechtsschutz) {
				this.$ctx.find('.js-div-Baustein').removeClass('h-hidden');
				const blocks = ['Verkehr', 'Privat', 'Beruf', 'Beruf 60+', 'Wohnen'].filter((value, index) => {
					return (0 === index && data.BausteineRechtsschutz.BausteinVerkehr)
						|| (1 === index && data.BausteineRechtsschutz.BausteinPrivat)
						|| (2 === index && data.BausteineRechtsschutz.BausteinBeruf)
						|| (3 === index && data.BausteineRechtsschutz.BerufSenior)
						|| (4 === index && data.BausteineRechtsschutz.BausteinWohnen);
				});
				this.$ctx.find('.js-Baustein').text(blocks.join(", "));
				if (data.BausteineRechtsschutz.Wohneinheit && data.BausteineRechtsschutz.Wohneinheit.length > 0) {
					this.$ctx.find('.js-Wohneinheit').text(data.BausteineRechtsschutz.Wohneinheit);
					this.$ctx.find('.js-div-Wohneinheit').removeClass('h-hidden');
				}
			}
		},

		_setDecductibleHelpText(data) {
			if (this.insuraneHelptexts && this.insuraneHelptexts.Deductible) {
				const helptext = this.insuraneHelptexts.Deductible[`HelpTextDeductible${data.VertragsArt}Field`];
				if (helptext !== '' && helptext !== undefined) {
					this.$ctx.find('#Selbstbeteiligung div p').text(helptext);
				}
			}
		},

		_setContractTypeHelpText(data) {
			if (this.insuraneHelptexts && this.insuraneHelptexts.ContractType) {
				const helptext = this.insuraneHelptexts.ContractType[`HelpTextContractType${data.VertragsArt}Field`];
				if (helptext !== '' && helptext !== undefined) {
					this.$ctx.find('#Vertragstyp div p').text(helptext);
				}
			}
		},

		_setYearlyFeeHelpText(data) {
			if (this.insuraneHelptexts && this.insuraneHelptexts.YearlyFee) {
				const helptext = this.insuraneHelptexts.YearlyFee[`HelpTextYearlyFee${data.VertragsArt}Field`];
				if (helptext !== '' && helptext !== undefined) {
					this.$ctx.find('#Gesamtjahresbeitrag div p').text(helptext);
				}
			}
		},

		_showAndUpdateLinks(data) {
			const _cancelLink = this.$ctx.find('.js-link-cancel-contract');
			if (data.Vertragstyp === 'Verlängerung') {
				_cancelLink.parent('li').toggleClass('h-hidden', false);
				_cancelLink.attr('href', `${_cancelLink.attr('href')}?data=${this.$vesToken}`);
			}
			else {
				_cancelLink.parent('li').toggleClass('h-hidden', true);
			}
			const _documentLink = this.$ctx.find('.js-link-documents');
			if (_documentLink) {
				_documentLink.attr('href', `${_documentLink.attr('href')}?data=${this.$vesToken}`);
			}
		},

		_showDataForInsuranceType(_insuranceType) {
			const insuranceTypeData = this.$ctx.data("insurancetype");

			if (insuranceTypeData && insuranceTypeData.insurances && insuranceTypeData.insurances.length > 0) {
				// eslint-disable-next-line eqeqeq
				const elem = insuranceTypeData.insurances.filter((element) => element.insurancetypeid == _insuranceType);  //NOSONAR Do not fix this line
				if (elem.length > 0) {
					if (elem[0].textchangecontract) {
						this.$ctx.find('.js-insurancetype-text').prepend(elem[0].textchangecontract);
					}
					const src = elem[0].imageurl;
					if (src) {
						const $image = this.$ctx.find(".js-insurancetype-image");
						$image.find("picture source").attr("data-srcset", src);
						$image.find("picture source").attr("srcset", src);
						$image.find('img').attr('src', src);
						$image.find('img').attr('data-src', src);
						$image.removeClass('h-hidden');
					}
				}
			}
		},

		_setAndCreateTemplateForBicycle: function (fahrradList) {
			const that = this;
			that._$template = that.$ctx.find('.js-bicycle-template');
			that.$ctx.find('.js-bicycle-headline').removeClass('h-hidden');

			that._$bicycleWrapper = that._$template.find('.js-bicycle-wrapper').clone();
			that.$ctx.find('.js-bicycle-wrapper').remove();

			$.each(fahrradList, function (index, elem) {
				const _wrapper = that._$bicycleWrapper.clone();
				_wrapper.find('.js-bicycle-headline-single').removeClass('h-hidden');
				_wrapper.find('.js-bicycle-count').text(` ${index + 1}`);
				if (elem.Marke) {
					_wrapper.find('.js-div-Marke').removeClass('h-hidden');
					_wrapper.find('.js-Marke').text(elem.Marke);
				}
				if (elem.Modell) {
					_wrapper.find('.js-div-Modell').removeClass('h-hidden');
					_wrapper.find('.js-Modell').text(elem.Modell);
				}
				if (elem.Kaufdatum) {
					_wrapper.find('.js-div-Kaufdatum').removeClass('h-hidden');
					_wrapper.find('.js-Kaufdatum').text(moment(elem.Kaufdatum).format("L"));
				}
				if (elem.Fahrradtyp) {
					_wrapper.find('.js-div-Fahrradtyp').removeClass('h-hidden');
					_wrapper.find('.js-Fahrradtyp').text(elem.Fahrradtyp);
				}
				if (elem.Rahmennummer) {
					_wrapper.find('.js-div-Rahmennummer').removeClass('h-hidden');
					_wrapper.find('.js-Rahmennummer').text(elem.Rahmennummer);
				}
				if (elem.Fahrradart) {
					_wrapper.find('.js-div-Fahrradart').removeClass('h-hidden');
					_wrapper.find('.js-Fahrradart').text(elem.Fahrradart);
				}
				if (elem.Kaufpreis) {
					_wrapper.find('.js-div-Kaufpreis').removeClass('h-hidden');
					_wrapper.find('.js-Kaufpreis').text(`${$.number(elem.Kaufpreis, 2, ',', '.')} €`);
				}
				that._$template.append(_wrapper);
			});
			if (that._$template) {
				that._$template.removeClass('h-hidden');
				S.Utils.Lightbox.init(that.jsLightbox, that._$template);
			}
		},
		_setAndCreateTemplateForPersons: function _setAndCreateTemplateForPersons(personsList, zahlweise) {
			const that = this;
			that._$template = that.$ctx.find('.js-insuredperson-template');
			that._$personWrapper = that._$template.find('.js-insuredperson-wrapper').clone();
			that.$ctx.find('.js-insuredperson-wrapper').remove();
			$.each(personsList, function (index, elem) {
				const _wrapper = that._$personWrapper.clone();
				if (index === 0) {
					const policyHolder = _wrapper.find('.js-policyholder').find('h2');
					policyHolder.text(`${policyHolder.text()} ${elem.Vorname} ${elem.Nachname}`);
					_wrapper.find('.js-policyholder').removeClass('h-hidden');
				}
				else {
					const insuredPerson = _wrapper.find('.js-insuredperson').find('h2');
					insuredPerson.text(`${insuredPerson.text()} ${elem.Vorname} ${elem.Nachname}`);
					_wrapper.find('.js-insuredperson').removeClass('h-hidden');
				}
				if (elem.ZahlBeitrag) {
					_wrapper.find('.js-div-ZahlBeitrag').removeClass('h-hidden');
					_wrapper.find('.js-ZahlBeitrag').text(`${$.number(elem.ZahlBeitrag, 2, ',', '')} € / ${zahlweise}`);
				}
				if (elem.Geburtsdatum) {
					_wrapper.find('.js-div-Geburtsdatum').removeClass('h-hidden');
					_wrapper.find('.js-Geburtsdatum').text(moment(elem.Geburtsdatum).format("L"));
				}
				if (elem.Versicherungsumme) {
					_wrapper.find('.js-div-Versicherungsumme').removeClass('h-hidden');
					_wrapper.find('.js-Versicherungsumme').text(`${$.number(elem.Versicherungsumme)} €`);
				}
				if (elem.Progression) {
					_wrapper.find('.js-div-Progression').removeClass('h-hidden');
					_wrapper.find('.js-Progression').text(`${elem.Progression} \u0025`);
				}
				if (elem.Altersgruppe) {
					_wrapper.find('.js-div-Altersgruppe').removeClass('h-hidden');
					_wrapper.find('.js-Altersgruppe').text(elem.Altersgruppe);
				}
				if (elem.Altersgruppe === 'Erwachsener') {
					if (elem.Berufsgruppe) {
						_wrapper.find('.js-div-Berufsgruppe').removeClass('h-hidden');
						_wrapper.find('.js-Berufsgruppe').text(elem.Berufsgruppe);
					}
					if (elem.Beruf) {
						_wrapper.find('.js-div-Beruf').removeClass('h-hidden');
						_wrapper.find('.js-Beruf').text(elem.Beruf);
					}
				}
				if (elem.Zusatzbaustein) {
					_wrapper.find('.js-div-Zusatzbaustein').removeClass('h-hidden');
					_wrapper.find('.js-Zusatzbaustein').text(elem.Zusatzbaustein ? 'ja' : 'nein');
				}
				that._$template.append(_wrapper);
			});
			if (that._$template) {
				that._$template.removeClass('h-hidden');
				S.Utils.Lightbox.init(that.jsLightbox, that._$template);
			}
		},
		_setAndCreateTemplateForPets: function _setAndCreateTemplateForPets(haustiereData) {
			const that = this;
			that._$template = that.$ctx.find('.js-pets-template');
			that.$ctx.find('.js-pets-headline').removeClass('h-hidden');

			that._$petsWrapper = that._$template.find('.js-pets-wrapper').clone();
			that.$ctx.find('.js-pets-wrapper').remove();

			$.each(haustiereData, function (index, elem) {
				const _wrapper = that._$petsWrapper.clone();
				_wrapper.find('.js-pets-headline-single').removeClass('h-hidden');
				_wrapper.find('.js-pets-count').text(` ${index + 1}`);
				if (elem.Tierart) {
					_wrapper.find('.js-div-Tierart').removeClass('h-hidden');
					_wrapper.find('.js-Tierart').text(elem.Tierart);
				}
				if (elem.Tiername) {
					_wrapper.find('.js-div-Tiername').removeClass('h-hidden');
					_wrapper.find('.js-Tiername').text(elem.Tiername);
				}
				if (elem.EUHeimtierausweis) {
					_wrapper.find('.js-div-EUHeimtierausweis').removeClass('h-hidden');
					_wrapper.find('.js-EUHeimtierausweis').text(elem.EUHeimtierausweis);
				}
				if (elem.Geburtsdatum) {
					_wrapper.find('.js-div-Geburtsdatum').removeClass('h-hidden');
					_wrapper.find('.js-Geburtsdatum').text(moment(elem.Geburtsdatum).format("L"));
				}
				if (elem.Chipnummer) {
					_wrapper.find('.js-div-Chipnummer').removeClass('h-hidden');
					_wrapper.find('.js-Chipnummer').text(elem.Chipnummer);
				}
				if (elem.Taetowierungsnummer) {
					_wrapper.find('.js-div-Taetowierungsnummer').removeClass('h-hidden');
					_wrapper.find('.js-Taetowierungsnummer').text(elem.Taetowierungsnummer);
				}
				that._$template.append(_wrapper);
			});
			if (that._$template) {
				that._$template.removeClass('h-hidden');
				S.Utils.Lightbox.init(that.jsLightbox, that._$template);
			}
		},
		_getUserData: function _getUserData(callback) {
			const that = this;
			T.Utils.View.startLoader();

			T.Utils.Auth.getResolvedIdentity(function (user) {
				if (user?.UserData) {
					callback(user);
				} else {
					T.Utils.View.stopLoader();
					T.Utils.Helper.routeToUrl(window.location.origin + that.returnUrl);
				}
			});
		},
		_createPersonenkreisDictionary: function _createPersonenkreisDictionary() {
			const personGroups = this.$ctx.data("persongroups");
			if (personGroups !== undefined) {
				const personGroupsArray = Object.entries(personGroups);
				if (personGroupsArray.length > 0) {
					for (const [key, value] of personGroupsArray) {
						this._personenKreisDictionary[key] = value;
					}
				}
			}
		},
		_getAndFillPersonenKnz: function _getAndFillPersonenKnz(personKnz) {
			const personenKreisValue = this._personenKreisDictionary[personKnz];
			if (personenKreisValue && personenKreisValue.length > 0) {
				this.$ctx.find('.js-div-Personenkreis').removeClass('h-hidden');
				this.$ctx.find('.js-Personenkreis').text(personenKreisValue);
			}
		},

		_setDataFromInsuranceData: function _setDataFromInsuranceData() {
			const insuranceData = T.Utils.Store.get('InsuranceData', 'session');
			if (insuranceData && this.$vesToken) {
				const insurance = insuranceData.find((i) => {
					return i.VesToken === this.$vesToken;
				});

				if (insurance && insurance.Sicht0.Risikoadresse !== null) {
					this.$ctx.find('.js-insurance-place').html(`${insurance.Sicht0.Risikoadresse.StrasseHausnummer}<br/>${insurance.Sicht0.Risikoadresse.Postleitzahl} ${insurance.Sicht0.Risikoadresse.Ort}`);
					this.$ctx.find('.js-div-insurance-place').removeClass('h-hidden');
				}
			}
		}
	});
}(jQuery));
