(function ($) {
	'use strict';
	/**
	 * ADACQuiz module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class AdacQuiz
	 * @extends T.Module
	 */
	T.Module.AdacQuiz = T.createModule({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$pagginationWrapper: null,

		/** @type {jQuery} */
		$headlineWrapper: null,

		/** @type {jQuery} */
		$headlinePag: null,

		/** @type {jQuery} */
		$subheadline: null,

		/** @type {jQuery} */
		$layer: null,

		/** @type {jQuery} */
		$image: null,

		/** @type {jQuery} */
		$answer: null,

		/** @type {number} */
		counter: 1,

		/** @type {number} */
		maxPage: 0,

		/** @type {number} */
		maxHeight: 0,

		/** @type {number} */
		maxAnswerHeight: 0,

		/** @type {number} */
		resizeTimeout: 0,


		///** @type {jQuery} */
		//$container: null,

		start(resolve) {
			this.$ctx = $(this._ctx);
			this.headline = '.mm-quiz-headline';
			this.pagginationWrapperString = '.mm-quiz-pagination-wrapper';

			this.$pagginationWrapper = this.$ctx.find(this.pagginationWrapperString);
			this.$headlineWrapper = this.$ctx.find('.mm-quiz-start');
			this.$headlinePag = this.$pagginationWrapper.find(this.headline);
			this.$subheadline = this.$headlineWrapper.find('.mm-quiz-subheadline');
			this.$date = this.$headlineWrapper.find('.mm-quiz-date');
			this.$description = this.$headlineWrapper.find('.mm-quiz-description');

			this.quizCounter = '.quiz-counter';
			this.imageBox = '.mm-question-img-box img';
			this.lightbox = '.js-lightbox';
			this.dataSlideId = 'data-slideid';
			this.quizQuestionString = '.m-adac-quiz-question';


			this.initPopUpQuiz();
			this.compileJson();
			this.resizeFunction();

			resolve();
		},

		initPopUpQuiz() {
			/**
			 * init Magnific Popup function in every single Question
			 *
			 */

			if ($('.my-adac-quiz-popup-btn').length) {

				$('.my-adac-quiz-popup-btn').magnificPopup({
					fixedContentPos: true,
					mainClass: 'mfp-adac-quiz',
					items: {
						src: $('.my-adac-quiz-popup'),
						type: 'inline'
					},
					callbacks: {
						open: () => {
							this.$ctx.find(this.lightbox).off('click').on('click', (e) => {
								const $explContainer = $(e.target).parents('.m-adac-quiz-question').find('.mm-explanation');
								const $btn = $('<a class="a-basic-link back-to-quiz">zurück zum Quiz</a>');

								e.preventDefault();

								$(e.target).closest('.mm-question-content-wrapper').hide();
								$explContainer.removeClass('mfp-hide');
								$explContainer.find('.mfp-close-link').hide();
								$btn.on('click', () => {
									$(e.target).closest('.mm-question-content-wrapper').show();
									$explContainer.addClass('mfp-hide');
									$explContainer.find('.mfp-close-link').show();
								});
								$explContainer.append($btn);
							});
						}
					}
				});
			}
		},

		/**
		 *
		 * include Json and compile
		*
		*/
		compileJson() {
			const neededJSON = this.$ctx.data('searchurl').length !== 0 ? this.$ctx.data('searchurl') : "/dev/api/quiz/adacquiz-image.json";

			$.getJSON(neededJSON, (data) => {
				this.handleJson(data);
			});
		},

		/**
		 *
		 * @param {Object} data
		 */
		handleJson(data) {
			this.$headlinePag.html(data.headline);
			this.$subheadline.html(data.title);
			this.$date.html(data.date);
			this.$description.html(data.startText);

			let i = 1;

			$.each(data.questions, (key, element) => {
				// get html markup of questions
				const $container = $(this.$ctx.find('#quiz-template').html());
				const $pagination = $(this.$ctx.find('#quiz-pagination').html());

				this.$layer = $('.my-adac-quiz-popup');

				if (element.img !== null) {
					if (element.img.imgUrl) {
						$container.find(this.imageBox).attr({
							'src': element.img.imgUrl,
							'alt': element.img.imgAlt
						});
					}
					else {
						$container.find('.mm-question-img-box').empty();
					}
				}
				else {
					$container.find('.mm-question-img-box').empty();
				}

				// overwrite html content with Json input
				$container.find('.mm-question').html(element.question);

				if (element.explanation !== null) {
					$container.find('.mm-ex-info-text').html(element.explanation.text);
					$container.find('.mm-ex-headline').html(element.explanation.headline);

					// set explanation attributes zu load magnific popup
					$container.find('.mm-explanation').attr('id', `explanation${i}`);
					$container.find('.js-lightbox').attr('href', `#explanation${i}`);
					$container.find('.mm-lightbox-link').attr('data-layer-href', `explanation${i}`);

					$container.find('.mm-lightbox-link').addClass('is-active');
				}

				// set dataslid-Id for queations
				$container.attr("data-slideId", i++); //NOSONAR


				// iterate over answers
				$.each(element.answers, (key, element2) => {
					// save html markup in variables
					const $answerContainer = $(this.$ctx.find('#answer-template').html());
					// check if answer is correct or false and set data attribute
					$answerContainer.attr("data-correct", ((element2.isCorrect) ? 'isCorrect' : 'isFalse')).find('.mm-answer-p').html(element2.answer);
					// insert answer content in answer-wrapper
					$container.find('.mm-answer-wrapper').append($answerContainer);
				});

				// insert questions content in question-wrapper
				this.$ctx.find('.mm-question-wrapper').append($container);

				// insert pagination content in quiz-pagination container
				this.$ctx.find('.mm-quiz-pagination').append($pagination);


			});

			if (this.$ctx.find(this.imageBox).length) {
				this.$ctx.find(this.imageBox).on('load', () => {
					this.calcHeight();
				});
			} else {
				this.calcHeight();
			}

			// check how many questions exist
			this.maxPage = this.$ctx.find(this.quizQuestionString).length;

			this.$ctx.find(this.quizCounter).text(`${this.counter}/${this.maxPage}`);

			this.initEvents();


		},

		/**
		 *
		 *
		 */
		initEvents() {
			/**
			 * Start Function to show first question and hide start page
			 */

			let quizStarted = false;
			const answer = '.mm-answer';

			this.$ctx.find('.js-start').on('click', () => {

				quizStarted = true;
				history.pushState(null, null, location.href);

				this.$ctx.find('.mm-quiz-page--start').css({ 'position': 'absolute', 'opacity': '0', 'visibility': 'hidden' });

				// make first question visible and set Class active
				this.$ctx.find(this.quizQuestionString).first().addClass('is-active');

				const slideId = this.$ctx.find('.m-adac-quiz-question.is-active').attr(this.dataSlideId);


				this.$ctx.find('.mm-rates').eq(slideId - 1).addClass("is-active");

				this.$ctx.find(this.headline).removeClass('is-hidden');

			});

			$(window).on('popstate', () => {
				if (quizStarted === true) {
					window.location.reload();
				}
			});

			/**
			 *
			 * click function for answers
			 * shows if correct or not
			 * set class for styles
			 *
			 */
			// eslint-disable-next-line
			this.$ctx.find(answer).on('click', (e) => { //NOSONAR

				const $clickedAnswer = $(e.currentTarget),
					$answerWrapper = $clickedAnswer.parents(this.quizQuestionString),
					$allAnswers = $clickedAnswer.siblings(answer),
					slideId = $answerWrapper.attr(this.dataSlideId);

				if (!$answerWrapper.hasClass('is-answered')) {
					$answerWrapper.addClass('is-answered');
					$allAnswers.addClass('is-grey');

					if (String($clickedAnswer.data("correct")) === String("isCorrect")) {
						$clickedAnswer.addClass('is-correct');
						this.$ctx.find('.mm-rates').eq(slideId - 1).addClass("is-right"); //NOSONAR
					}
					else {
						$clickedAnswer.addClass('is-false');
						$allAnswers.filter('.mm-answer[data-correct="isCorrect"]').addClass('is-correct');
						this.$ctx.find('.mm-rates').eq(slideId - 1).addClass("is-wrong"); //NOSONAR
					}

				} else {
					return false;
				}
			});

			/**
			 * function to swipe question
			 *
			 */
			this.$ctx.find('.js-next-btn').on('click', (e) => {
				const $nextBtn = $(e.currentTarget),
					$answerWrapper = $nextBtn.closest(this.quizQuestionString);

				const slideId = $answerWrapper.attr(this.dataSlideId);
				const rightAnswers = this.$ctx.find('.mm-rates.is-right').length;
				const maxAnswers = this.$ctx.find('.mm-rates').length;

				$answerWrapper.removeClass('is-active is-fadein').addClass('is-fadeout').next().addClass('is-fadein is-active');

				this.counter++;

				this.$ctx.find(this.quizCounter).text(`${this.counter}/${this.maxPage}`);

				this.$ctx.find('.mm-rates').eq(slideId).addClass("is-active");

				if ($answerWrapper.next().length === 0) {
					$('.mm-quiz-page--end').css({ 'position': 'relative', 'visibility': 'visible', 'opacity': '1' });

					this.$ctx.find('.mm-rates').clone().appendTo('.mm-quiz-rating');
					this.$ctx.find(this.pagginationWrapperString).addClass('is-hidden');

					this.$ctx.find('.mm-ex-rates').text(`${rightAnswers} von ${maxAnswers}`);

					const percent = rightAnswers / maxAnswers * 100;
					if (percent <= 24) {
						this.$ctx.find('.mm-quiz-subheadline[data-rate="4"]').addClass('is-active');
					} else if (percent <= 49) {
						this.$ctx.find('.mm-quiz-subheadline[data-rate="3"]').addClass('is-active');
					} else if (percent <= 74) {
						this.$ctx.find('.mm-quiz-subheadline[data-rate="2"]').addClass('is-active');
					} else if (percent <= 100) {
						this.$ctx.find('.mm-quiz-subheadline[data-rate="1"]').addClass('is-active');
					}

				}

			});

			/**
			 * init Magnific Popup function in every single Question
			 *
			 */
			this.$ctx.find(this.lightbox).each((index, element) => {
				const idSelector = $(element).attr('href');
				$(element).magnificPopup({
					fixedContentPos: true,
					items: {
						src: $(idSelector),
						type: 'inline'
					},
					mainClass: 'mfp-explanation'
				});
			});

			$('.a-basic-link.mfp-close-link').on('click', () => {
				$('button.mfp-close').trigger('click');
			});


			this.$ctx.find('.mm-quiz-btn--end').on('click', () => {
				this.$ctx.find('.mm-quiz-page--start').css({ 'position': 'relative', 'opacity': '1', 'visibility': 'visible' });
				this.$ctx.find('.mm-quiz-page--end').css({ 'position': 'absolute', 'opacity': '0', 'visibility': 'hidden' });
				this.$ctx.find(this.pagginationWrapperString).removeClass('is-hidden');

				this.clear();
			});

		},

		clear() {
			this.counter = 1;
			this.$ctx.find(this.quizCounter).text(`${this.counter}/${this.maxPage}`);

			this.$ctx.find(this.quizQuestionString).removeClass('is-fadeout is-answered');
			this.$ctx.find('.mm-answer').removeClass('is-grey is-correct is-false');

			// remove ratings (clones from pagination, used as summary)
			this.$ctx.find('.mm-quiz-rating').empty();
			// reset pagination ratings
			this.$ctx.find('.mm-quiz-pagination').children('.mm-rates').removeClass('is-right is-wrong is-active');
			// hide headline for startpage
			this.$ctx.find(this.headline).addClass('is-hidden');
			// reset explanation rating
			this.$ctx.find('.mm-ex-rates').empty();
			this.$ctx.find('.mm-quiz-subheadline').removeClass('is-active');

		},

		calcHeight() {
			let layer = false;
			this.maxHeight = 0;

			if (this.$layer.hasClass('mfp-hide')) {
				layer = true;
				this.$layer.css({ 'opacity': '0', 'visibility': 'hidden' }).removeClass('mfp-hide');
			}

			this.$ctx.find(this.quizQuestionString).css("height", "auto").each((index, element) => {
				if ($(element).height() > this.maxHeight) {
					this.maxHeight = $(element).outerHeight();
				}
			});

			if (layer) {
				this.$layer.css({ 'opacity': '1', 'visibility': 'visible' }).addClass('mfp-hide');
			}

			this.$ctx.find(this.quizQuestionString).css({ 'height': this.maxHeight });
		},

		resizeFunction() {
			$(window).on('resize', () => {
				clearTimeout(this.resizeTimeout);
				this.resizeTimeout = setTimeout(() => {
					this.calcHeight();
				}, 10);

			});
		}

	});
}(jQuery));
