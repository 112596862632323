(function($) {
	'use strict';
	/**
	 * LayoutMainStage module implementation.
	 *
	 * @author  <n.wewior@edelweiss72.de>
	 * @namespace T.Module
	 * @class LayoutMainStage
	 * @extends T.Module
	 */
	T.Module.LayoutVesStage = T.createModule({

		/** @type {jQuery} */
        $ctx: null,

		/** @type {Number} */
		$stopper: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
		start: function(resolve) {
			this.$ctx = $(this._ctx);
			this.$stopper = this.$ctx.find('.oo-stopper');

			//calculate the actual height of position absulute stopper element (size of its child) to position it correctly
			this.$stopper.css('height', `${this.$stopper.children().first().outerHeight()  }px`);

			resolve();
		},
	});
}(jQuery));
