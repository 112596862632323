document.updateArr = [];
(function ($) {
	'use strict';

	$.noConflict();
	// $(document).ready(function ()
	document.addEventListener('DOMContentLoaded', function () {
		const application = new T.Application();

		// for reference and global use of sandbox (includes all modules)
		// normally its only shared between all modules
		// - this needs to be saved before the start to get access in S.Utils.Helper which is called inside modules
		document.applicationInstance = application;

		$(document).bind('addModules', (event, context) => {
			application._sandbox.addModules(context);

			if (context && S.Lazy.wasInitialized) {
				S.Lazy.update(context);
			}
		});

		$(document).bind('removeModules', (event, context) => {
			application._sandbox.removeModules(context);

			if (context && S.Lazy.wasInitialized) {
				S.Lazy.remove(context);
			}
		});

		application.registerModules();
		application.start();

		// after application is started, init lazy self execution
		S.Lazy.init();
	});
})(jQuery);
