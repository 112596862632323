(function ($) {
	'use strict';

	/**
	 * BasicInputSelect module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputSelect
	 * @extends T.Module
	 */
	T.Module.BasicInputSelect = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$select: null,

		/** @type {jQuery} */
		$selectOverlay: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);

			if (typeof this.$ctx.data('js-use-value') !== 'undefined' && this.$ctx.data('js-use-value')) {
				this.$select = this.$ctx.find('select').eq(0);
				this.$selectOverlay = this.$select.siblings('.js-select-overlay').eq(0);
				this.useValueAttribute();
			}

			resolve();
		},

		/**
		 * takes the value attr. as selected string if an option is selected
		 *
		 * => e.g. on currency inputs, the user should see "EUR" as selected option but "Euro (€)" if he opens the dropdown:
		 * => <select><option val="EUR">Euro (€)</option></select>
		 */
		useValueAttribute() {
			// initial check
			const initialSelectVal = this.$select.find('option:selected').val();

			// add overlay elem if theres none
			if (!this.$selectOverlay.length) {

				$('<span class="js-select-overlay"></span>').insertBefore(this.$ctx.find('.aa-controller'));

				this.$selectOverlay = this.$select.siblings('.js-select-overlay').eq(0);
			}

			// add initial selected option val to overlay
			if (initialSelectVal.length) {
				this.$selectOverlay.text(initialSelectVal);
			}

			// on change
			this.$ctx.on('change', () => {
				const stringToAssign = this.$ctx.find('option:selected').val();
				this.$selectOverlay.text(stringToAssign);
			});
		},

		checkScrollPosition() {
			const lightboxClass = '.ll-lightbox-inner';
			if (this.$ctx.closest(lightboxClass).length > 0) {
				//timeout until options loaded
				setTimeout(() => {
					const heightLightbox = this.$ctx.closest(lightboxClass).outerHeight();
					const offsetSelect = S.Globals.CalculateOffset.topToParent(this.$ctx, this.$ctx.closest(lightboxClass));
					const heightSelectWithOptions = this.$ctx.find('.aa-btn-selection').outerHeight() + this.$ctx.find('.aa-options').outerHeight();

					if (heightSelectWithOptions > (heightLightbox - offsetSelect)) {
						let scrollTarget = heightSelectWithOptions - (heightLightbox - offsetSelect) + 20;

						if (scrollTarget > offsetSelect) {
							scrollTarget = offsetSelect;
						}

						this.$ctx.closest(lightboxClass).animate({
							scrollTop: this.$ctx.scrollTop() + scrollTarget
						}, 500);
					}

				}, 500);
			}
		},
	});
}(jQuery));