(function ($) {
	'use strict';

	S.Utils = S.Utils || {};


	S.Utils.Lightbox = {
		classAnimation: "ll-lightbox-inner mfp-with-anim",
		ariaLabelClose: 'Schaltfläche Detailinformationen schließen',
		ariaLabelAttr: 'aria-label',
		lightboxCloseSelector: '.l-lightbox .mfp-close',

		/**
		 * Lightbox 2016-02-03
		 *
		 * init
		 *
		 * @param mod {String} js-lightbox || js-image-lightbox || js-lightbox-gallery
		 * @param container {jQuery} :: only if mod, for extra init, e. g. after ajax calls
		**/
		init: function (mod, container) {
			if (typeof (mod) !== 'undefined') {
				if (mod === 'js-lightbox') {
					this.lightbox(container);
				} else if (mod === 'js-image-lightbox') {
					this.imageLightbox(container);
				} else if (mod === 'js-lightbox-gallery') {
					this.pagerLightbox(container);
				}
			} else {
				this.lightbox(undefined);
				this.imageLightbox(undefined);
				this.pagerLightbox(undefined);
			}
		},

		/**
		 * lightbox
		 * @param {jQuery} container
		 **/
		lightbox: function (container) {
			// default selector :: banderole component is exception
			let selector = $('.js-lightbox').not($('.o-basic-banderole .js-lightbox'));
			const that = this;

			if (typeof (container) !== 'undefined') {
				selector = container.find('.js-lightbox');
			}

			if (selector.length) {


				selector.each(function (index, item) {
					let idSelector;

					if ($(item).attr('href')) {
						idSelector = $(item).attr('href');
					} else if ($(item).attr('data-href')) {
						idSelector = $(item).attr('data-href');
					}

					if ($(item).length) {
						$(item).magnificPopup({
							fixedContentPos: true,
							callbacks: {
								beforeOpen: function () {
									// setup classes
									$(idSelector).addClass(that.classAnimation);
									if ($('.l-outer').hasClass('l-outer--my-adac-content')) {
										$(idSelector).addClass("my-adac");
									}
									// eslint-disable-next-line
									this.st.mainClass = `${this.st.el.attr('data-effect')} l-lightbox`; //NOSONAR Attributes

									// if there is an extra big image inside that has to be lazyloaded
									if ($(idSelector).find('.js-img-big').length) {
										const imageBig = $(idSelector).find('.js-img-big').eq(0);
										imageBig.attr('src', imageBig.data('src'));
									}
								},
								open: function () {
									// set aria-label for close button
									$(that.lightboxCloseSelector).html('').attr(that.ariaLabelAttr, that.ariaLabelClose).removeAttr('title');
								}
							},

							items: {
								src: $(idSelector),
								type: 'inline'
							}
						});
					}
				});
			}
		},

		/**
		 * image lightbox :: for direct image path in href
		 * @param {jQuery} container
		 **/
		imageLightbox: function (container) {
			// default selector :: banderole component is exception
			let selector = $('.js-image-lightbox').not($('.o-basic-banderole .js-image-lightbox'));
			const that = this;

			if (typeof (container) !== 'undefined') {
				selector = container.find('.js-image-lightbox');
			}
			if (selector.length) {
				selector.magnificPopup({
					fixedContentPos: true,
					type: 'image',
					callbacks: {
						beforeOpen: function () {
							$(this.st.el[0].hash).addClass(that.classAnimation);
							this.st.mainClass = `${this.st.el.attr('data-effect')} l-lightbox l-lightbox--image`;
						},
						open: function () {
							// set aria-label for close button
							$(that.lightboxCloseSelector).html('').attr(that.ariaLabelAttr, that.ariaLabelClose).removeAttr('title');
						}
					}
				});
			}
		},

		pagerLightbox: function (container) {

			let selector = $('.js-lightbox-gallery');
			const classHealthInsurance = '.ll-lightbox-inner--healthinsurance';
			const that = this;

			if (typeof (container) !== 'undefined') {
				selector = $('.js-lightbox-gallery');
			}
			if (selector.length) {
				selector.magnificPopup({
					fixedContentPos: true,
					callbacks: {
						beforeOpen: function () {
							$(classHealthInsurance).addClass(that.classAnimation);
							this.st.mainClass = `${this.st.el.attr('data-effect')} l-lightbox`;
						},
						open: function () {
							// re-appends controls inside the main container
							this.arrowLeft.appendTo(classHealthInsurance);
							this.arrowRight.appendTo(classHealthInsurance);

							// set aria-label for close button
							$(that.lightboxCloseSelector).html('').attr(that.ariaLabelAttr, that.ariaLabelClose).removeAttr('title');
						},
						buildControls: function () {

							const buttonRight = this.arrowRight,
								buttonLeft = this.arrowLeft;

							buttonRight.add(buttonLeft).on('click', () => {
								$('.l-lightbox').animate({
									scrollTop: 0
								}, 500, 'swing');
							});
						}
					},
					gallery: {
						// options for gallery
						enabled: true
					}
				});
			}
		}
	};

	$(document).ready(function () {
		S.Utils.Lightbox.init(undefined, undefined);
	});
})(jQuery);
