/* eslint-disable */
(function ($) {
	'use strict';
	/**
	 * VesRsContentbox module implementation.
	 *
	 * @author Lars Meyer <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class VesRsContentbox
	 * @extends T.Module
	 */

	T.Module.VesRsContentbox = T.createModule({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$tileContainer: null,

		/** @type {jQuery} */
		$inputBox: null,

		/** @type {jQuery} */
		$inputField: null,

		/** @type {jQuery} */
		$selectField: null,

		/** @type {jQuery} */
		$inputElements: null,

		/** @type {jQuery} */
		$inputDropDown: null,

		/** @type {jQuery} */
		$trafficBoxDummyBtn: null,

		/** @type {Object} */
		$btnCloseAll: null,

		/** @type {Object} */
		$stickyTarif: null,

		/** @type {Object} */
		$submitButton: null,

		_colDataClassSelector: '.ll-col-data',
		_isDisabledClass: 'is-disabled',
		_inputPrivatBoxIdSelector: 'input#privatBox',
		_insuranceScopeTileContainerSelector: '.mm-tile-container[data-js-condition=\"insurancescope\"]',
		_tileDescHeadClassSelector: '.mm-tile-desc-head',
		_beenFiredYesId: '#beenfiredYes',
		_isoDateFormat: 'YYYY-MM-DD',
		_dataJsLayerConditionAttr: 'data-js-layer-condition',
		_tileInputChildSelector: '.mm-tile > input',
		_jsEnabledClass: 'js-enabled',
		_basicInputLayerChangeEvt: 'BasicInputLayer.inputChange',
		_viewDateFormat: 'DD-MM-YYYY',

		start: function (resolve) {

			const that = this;

			this.$ctx = $(this._ctx);

			that.$tileContainer = this.$ctx.find('.mm-tile-container');
			that.$inputBox = this.$ctx.find(this._tileInputChildSelector);
			that.$inputField = this.$ctx.find('.l-form input').not('input[type=checkbox], input[type=radio]');
			that.$inputElements = this.$ctx.find('.l-form input[type=checkbox], .l-form input[type=radio]');
			that.$inputDropDown = this.$ctx.find('.l-form select');
			that.$btnCloseAll = $('.ll-main-center');

			that.$stickyTarif = $('.m-ves-rs-sticky');
			that.$submitButton = that.$stickyTarif.find('button[type="submit"]');

			// special inputs to emit click-events on disabled inputs
			that.$trafficBoxLabel = this.$ctx.find('.mm-tile-container > .mm-tile[data-js-id="verkehrBox"] > label');
			that.$jobBoxLabel = this.$ctx.find('.mm-tile-container > .mm-tile[data-js-id="berufBox"] > label');
			that.$livingBoxLabel = this.$ctx.find('.mm-tile-container > .mm-tile[data-js-id="livingBox"] > label');

			that.editValidationElems();
			that.fillTileContainer();
			that.addEventListeners('init');
			that.getInitialContext();


			resolve();
		},

		/**
		 * fill tilecontainer with empty tiles to maintain grid-layout
		 *
		 */
		fillTileContainer: function () {

			const that = this;

			// iterate through each container
			that.$tileContainer.each(function (index, element) {

				// check if there are already empty tiles
				if (!$(element).find('.mm-tile-empty').length) {

					// get tiles and length
					const tiles = $(element).find('.mm-tile');
					const tileSize = $(tiles).length;

					if (tileSize > 2) {

						const emptyTiles = [];
						for (let i = 0; i < tileSize; i++) {
							emptyTiles.push($('<div>', { class: 'mm-tile mm-tile-empty' }));
						}

						$(element).append(emptyTiles);
					}
				}
			});
		},


		/**
		 * position validation-icons next to calendar or info-layer icons
		 * limit error-msg width
		 */
		editValidationElems: function () {

			// Valid Icons
			this.$ctx.find('.l-form .ll-check').each(function (index, item) {
				// datepicker is displayed
				if ($(item).siblings('.a-basic-input-text--calendar').length) {

					// special setting for desktop
					if (S.Utils.Helper.mq('desktop').matches) {
						$(item).css('right', '-5px');
					}
				}

				// info-layer icon is displayed
				else if ($(item).siblings('.m-basic-info-layer').find('.mm-infolayer-icon').length) {
					// special setting for tablet
					if (S.Utils.Helper.mq('tablet').matches) {
						$(item).css('right', '0px');
					}
				}
			});

			// Valid Messages
			this.$ctx.find('.l-form .ll-error-msg').each(function (index, item) {

				// datepicker is displayed
				if ($(item).siblings('.a-basic-input-text--date').length) {

					if (S.Utils.Helper.mq('tablet').matches) {
						$(item).css('max-width', '140px');
					}
				}

			});

		},

		/**
		 * add events to special items
		 *
		 * @param mod - prevent unnecessary rebinding of events
		 */
		addEventListeners: function (mod) {

			const that = this;

			if (mod === 'init') {

				// add events to Box-Items
				that.$inputBox.each((index, element) => {
					// prevent tab-nav
					$(element).on('change', () => {
						that.getInputContext(element);
					});

					$(element).on('change', () => {
						that.getInputContext(element);
					});
				});

				// add events to form-items
				this.$inputField.each((index, element) => {

					let eventmod = 'change';

					if ($('.l-outer').hasClass('is-touch')) {
						eventmod = 'blur';
					}

					if (!this.$ctx.hasClass('m-ves-rs-contentbox--damagedeclaration')) {
						$(element).attr('tabindex', '-1');
					}

					// prevent tab-nav
					$(element).on(eventmod, (e) => {
						that.getFieldContext(element);
					});
				});

				// add events to form-items
				this.$inputElements.each((index, element) => {
					// prevent tab-nav
					$(element).attr('tabindex', '-1').on('change', () => {
						that.getFieldContext(element);
					});
				});

				// add events to items
				this.$inputDropDown.each((index, element) => {

					if (!this.$ctx.hasClass('m-ves-rs-contentbox--damagedeclaration')) {
						$(element).attr('tabindex', '-1');
					}

					// prevent tab-nav
					$(element).on('change', () => {
						that.getFieldContext(element);
					});
				});

				// exception for verkehr tile
				if (that.$trafficBoxLabel.length) {
					that.$trafficBoxLabel.addClass(this._jsEnabledClass).on('click', () => {
						that.getInputContext(that.$trafficBoxLabel.siblings('input#verkehrBox'));
					});
				}

				// exception for beruf tile
				if (that.$jobBoxLabel.length) {
					that.$jobBoxLabel.addClass(this._jsEnabledClass).on('click', () => {
						that.getInputContext(that.$jobBoxLabel.siblings('input#berufBox'));
					});
				}

				// exception for verkehr tile
				if (that.$livingBoxLabel.length) {
					that.$livingBoxLabel.addClass(this._jsEnabledClass).on('click', () => {
						that.getInputContext(that.$livingBoxLabel.siblings('input#wohnenBox'));
					});
				}

				$(document).on(`resetModule.${that.$ctx.data('t-id')}`, (e) => {

					that.$ctx.find('[data-t-id]').each((index, elem) => {
						// get all comps within this comp and call reset for them
						that._events.emit(`compReset.${$(elem).data('t-id')}`);
					});
				});

				// for debugging global reset call
				// setTimeout( () => {
				//     S.Utils.Reset.reset( that.$ctx.data('t-id') );
				// }, 10000);
			}

			else if (mod === 'closeAll') {

				S.Utils.delayed('closeAllBtnDelay', 200, () => {

					that.$btnCloseAll.on('click', (e) => {

						const item = document.querySelector('.mm-infolayer-content.visible');

						// if clicked outside
						if (!$(item).is(e.target) && !$(item).find('*').is(e.target)) {
							// close layers
							that.closeAllLayers();
						}
					});
				});
			}
		},

		/**
		 * find input-elements and -states on init
		 */
		getInitialContext: function () {

			const that = this;

			// get all input elements from page
			const boxInputs = document.querySelectorAll('.m-ves-rs-contentbox .mm-tile > input');
			const formInputs = document.querySelectorAll('.m-ves-rs-contentbox .l-form input');

			// loop through them and call infolayer on detection
			$(boxInputs).each((index, element) => {
				const condition = $(element).attr(this._dataJsLayerConditionAttr);
				if ($(element).is(':checked')) {
					that.getInputContext(element, 'init');
					if (condition) {
						this._events.emit(this._basicInputLayerChangeEvt, condition, true);
					}
				}
			});

			// loop through and call additional logic
			$(formInputs).each((index, element) => {
				that.getFieldContext(element);
			});

			if (that.$stickyTarif.length) {
				that.modifyStickyTarif('show');
			}

			that.$inputDropDown.each((index, element) => {
				that.getFieldContext(element);
			});

		},

		/**
		 * find input-elements and -states and call layers or show/hide other elements
		 *
		 * @param element
		 *
		 * @param info - optional string (e.g "init")
		 */
		getInputContext: function (element, info) {

			const that = this;
			const checkedState = $(element).is(':checked');

			// iterate through all inputs and emit layer-conditions to get current info-layer context
			if (info !== 'init') {
				that.$inputBox.each((index, box) => {
					const condition = $(box).attr(this._dataJsLayerConditionAttr);
					if (condition) {
						that._events.emit(this._basicInputLayerChangeEvt, condition, $(box).is(':checked'));
					}
				});
			}

			if ($(element).is('#verkehrBox')) {
				if (info !== 'init') {
					that.toggleSpecialLayers('alert', element);
				}
			}

			// toggle tiles for privat
			if ($(element).is('#privatBox')) {
				that.editElements('togglePrivatActive', checkedState);
				that.editElements('toggleTrafficOnly');
			}

			// toggle privat if job is changed
			if ($(element).is('#berufBox')) {

				// trigger special mode for disabled inputs
				if ($(element).is(':disabled')) {
					that.editElements('disabledJobOrLiving', element);
				}

				else {

					// delay to prevent firing twice ( -> event-emit via 'change', not 'click')
					S.Utils.delayed('delayForJobBox', 50, function () {
						that.editElements('togglePrivat60', checkedState);
					});

					that.editElements('editPrimeList', checkedState);
					that.editElements('toggleTrafficOnly');
				}
			}

			// toggle prime/traffic if living is changed
			if ($(element).is('#wohnenBox')) {

				// trigger special mode for disabled inputs
				if ($(element).is(':disabled')) {
					that.editElements('disabledJobOrLiving', element);
				}

				else {
					that.editElements('editPrimeList', checkedState);
					that.editElements('toggleTrafficOnly');
				}
			}

			// toggle primeBox-List if prime is clicked
			if ($(element).is('#primeselection')) {
				that.editElements('togglePrimeList', checkedState);
			}

			if ($(element).is('#oneVehicle')) {
				that.toggleSpecialLayers('alert', element);
				that.editElements('toggleTrafficOnly');
			}

			if ($(element).is('#manyVehicle')) {
				that.editElements('toggleTrafficOnly');
			}

			if ($(element).is('#alreadyMember')) {
				that.editElements('toggleMembershipOptions', 'alreadymember');
				that.modifyStickyTarif('hide');
			}

			if ($(element).is('#becomeMember')) {
				that.editElements('toggleMembershipOptions', 'becomemember');
				that.modifyStickyTarif('show');
			}

			if ($(element).is('#noMember')) {
				that.editElements('toggleMembershipOptions', 'nomember');
				that.modifyStickyTarif('hide');
			}

			if ($(element).is('#adacMember') || $(element).is('#adacMemberPlus')) {
				that.modifyStickyTarif('show');
			}
		},

		/**
		 * calc values of text-inputs to do stuff
		 **/
		// eslint-disable-next-line complexity
		getFieldContext: function (element) {//NOSONAR

			const that = this;

			// calc age for privatBox
			if ($(element).attr('data-js-condition') === 'age') {
				if ($(element).val()) {

					const age = that.calcAge($(element).val());

					if (age < 18) {
						that.toggleSpecialLayers('error', element, 'under18');
						$(element).val('');
					}

					else if (age >= 120) {
						that.toggleSpecialLayers('error', element, 'over120');
						$(element).val('');
					}

					else {
						that.editElements('togglePrivatBox', age)
					}
				}
			}

			// toggle insurance before / on
			if ($(element).is('#insurancestart')) {

				const mode = 'toggleInsuranceStart';

				if ($(element).val()) {

					const inputDate = moment($(element).val(), this._viewDateFormat);
					const today = moment();

					if (inputDate.isValid()) {

						// check if inputdate is more than 1 year in the future
						if (inputDate > today) {

							// calc difference
							let year = today.year() - inputDate.year();
							const month = today.month() - inputDate.month();

							// count year back because of carry-over of calculation ( month is <= 0 and day is bigger)
							if (month < 0 || (month === 0 && today.date() < inputDate.date())) {
								year--;
							}

							if (year >= -1) {

								$(element).attr('value', moment(inputDate).format(this._isoDateFormat));

								// touch but not iOS
								// if ( $(element).closest( '.l-outer' ).hasClass( 'is-touch' ) && !$(element).closest( '.l-outer' ).hasClass( 'is-ios' ) ) {

								// console.log('dateformat should be here');
								// $(element).val( moment().add( 1, 'd' ).format( 'YYYY-MM-DD' ) );
								// }
							}
						}
					}
				}

				that.editElements(mode);
			}

			// toggle insurance before / on
			if ($(element).is('#insurancebeforeYes')) {

				if ($(element).is(':checked')) {
					that.editElements('toggleInsuranceBefore', true);
				}

			}

			// toggle insurance before / off
			if ($(element).is('#insurancebeforeNo')) {

				if ($(element).is(':checked')) {
					that.editElements('toggleInsuranceBefore', false);
				}

			}

			// show layer and disable button if insurance termination is selected
			if ($(element).is(this._beenFiredYesId) || $(element).is('#stillmember') || $(element).is('#beenfiredNo')) {

				if ($(element).is(':checked')) {
					that.editElements('toggleInsuranceTermination', element);
				}
			}

			// check payment schedule and update sticky-tarif
			if ($(element).is('#paymentschedule')) {
				that.modifyStickyTarif('edit', $(element).children(':selected').text());
			}

		},

		/**
		 *
		 * @param value - base for calc
		 *
		 * @returns {number}
		 *
		 */
		calcAge: function (value) {

			const that = this;

			// check if insurance-start is set
			const insuranceBegin = that.$ctx.find('input#insurancestart');
			let today;

			if ($(insuranceBegin).val()) {
				// get today date
				today = moment($(insuranceBegin).val(), this._viewDateFormat);
			}

			else {
				today = moment();
			}

			// get birthday-value and format it
			const birthDate = moment(value, this._viewDateFormat);

			// calc age and month
			let age = today.year() - birthDate.year();

			const month = today.month() - birthDate.month();
			if (month < 0 || (month === 0 && today.date() < birthDate.date())) {
				age--;
			}

			return (age);

		},

		/**
		 *
		 * reset insurance begin to default-value
		 *
		 * CAUTION: is not called -> will be done via BE
		 *
		 */
		resetInsuranceDate: function (element) {
			// disabled -> will be done via BE
			$(element).val(moment().add(1, 'd').format('DD.MM.YYYY'));
			$(element).attr('value', moment().add(1, 'd').format(this._isoDateFormat));

			if ($(element).closest('.l-outer').hasClass('is-touch')) {
				// android
				if (!$(element).closest('.l-outer').hasClass('is-ios')) {
					$(element).val(moment().add(1, 'd').format(this._isoDateFormat));
				}
				// ios
				else {
					// console.log("is ios!");
				}
			}
		},

		/**
		 * change/show/hide elements if corresponding input changed
		 *
		 * @param mode - String to determine calls
		 *
		 * @param value - optional value (e.g. age or element)
		 *
		 */
		// eslint-disable-next-line complexity
		//NOSONAR
		editElements: function (mode, value) { //NOSONAR Complexity

			const that = this;

			// show/hide Privat 60+
			if (mode === 'togglePrivatBox') {

				const privatBox = document.querySelector(`.m-ves-rs-contentbox > .mm-tile-container > .mm-tile > ${this._inputPrivatBoxIdSelector}`);
				const jobBox = document.querySelector('.m-ves-rs-contentbox > .mm-tile-container > .mm-tile > input#berufBox');

				if (value >= 60) {

					if (!$(jobBox).is(':checked')) {

						$(privatBox).attr(this._dataJsLayerConditionAttr, 'privat60');
						$(privatBox).next().find(this._tileDescHeadClassSelector).text('Privat 60+');

						that._events.emit(this._basicInputLayerChangeEvt, 'privat60', true);
						that._events.emit(this._basicInputLayerChangeEvt, 'privat', false);

						that.toggleSpecialLayers('alert', $('input#birthday'));
					}
				}


				else {
					$(privatBox).attr(this._dataJsLayerConditionAttr, 'privat');
					$(privatBox).next().find(this._tileDescHeadClassSelector).text('Privat');

					that._events.emit(this._basicInputLayerChangeEvt, 'privat60', false);
					that._events.emit(this._basicInputLayerChangeEvt, 'privat', true);
				}
			}

			// show/hide car selection inputs & previous insurances
			else if (mode === 'toggleTrafficOnly') {

				// get containers
				const mainWrapper = document.querySelectorAll('.m-ves-rs-contentbox[data-js-condition=\"verkehr\"]'),
					mainContainer = $(mainWrapper).find(this._insuranceScopeTileContainerSelector),
					subContainer1 = $(mainWrapper).find('.mm-tile-container[data-js-condition=\"vehiclenumberselection\"]'),
					subContainer2 = $(mainWrapper).find('.mm-tile-container[data-js-condition=\"vehicletypeselection\"]');

				const prevInsuranceContainer = document.querySelectorAll('.m-ves-rs-contentbox[data-js-condition=\"prevInsurances\"]');
				const paymentScheduleInput = document.querySelectorAll('.m-ves-rs-contentbox > .l-form .a-basic-input-select > select#paymentschedule');

				// check if verkehr is the only checked input
				if ($(mainContainer).find('.mm-tile > input:checked').length === 1) {

					// show subcontainer
					$(subContainer1).removeClass('hidden');

					//hide previous insurance
					$(prevInsuranceContainer).hide();
				}

				// more than one is checked
				else {

					// hide subcontainers
					if (!$(subContainer1).hasClass('hidden')) {
						$(subContainer1).addClass('hidden');
					}

					if (!$(subContainer2).hasClass('hidden')) {
						$(subContainer2).addClass('hidden');
					}

					// set inputs back to init
					$(subContainer1).find(this._tileInputChildSelector).each((index, item) => {

						if ($(item).is('#manyVehicle')) {
							$(item).prop('checked', true);
						}
						else {
							$(item).prop('checked', false);
						}
					});

					// enable selection
					$(paymentScheduleInput).prop('disabled', false);
					$(prevInsuranceContainer).show();
				}

				// determine states of subcontainer1 => if the 1st rbtn is checked (oneVehicle), show subcontainer2
				if (!$(subContainer1).hasClass('hidden')) {

					const input = $(subContainer1).find('.mm-tile > input:checked');

					if ($(input).is('#oneVehicle')) {

						$(subContainer2).removeClass('hidden');
					}

					else {
						$(subContainer2).addClass('hidden');

						// enable selection
						$(paymentScheduleInput).prop('disabled', false);
					}
				}
			}

			// toggle wohnen & beruf (enable/disable) & show/hide primeSelection
			else if (mode === 'togglePrivatActive') {

				// get elements
				const jobBox = that.$ctx.find('input#berufBox');
				const livingBox = that.$ctx.find('input#wohnenBox');
				const privatBox = that.$ctx.find(this._inputPrivatBoxIdSelector);
				const primeBox = that.$ctx.find('.mm-tile-container[data-js-condition=\"primeselection\"]');
				const primeInput = that.$ctx.find('input#primeselection');
				const primeList = $(primeBox).find('.mm-tile > ul.m-basic-list--vesrs-premium > li');

				// set privatBox to checked if the other one is checked
				if ($(jobBox).is(':checked') && $(livingBox).is(':checked')) {

					$(privatBox).prop('checked', true);
					that.toggleSpecialLayers('alert', $(privatBox), 'livingAndJob');
				}

				// set privatBox to checked if the other one is checked
				else if ($(jobBox).is(':checked')) {
					$(privatBox).prop('checked', true);

					that.toggleSpecialLayers('alert', $(privatBox), 'jobOnly');
				}

				// set privatBox to checked if the other one is checked
				else if ($(livingBox).is(':checked')) {
					$(privatBox).prop('checked', true);

					that.toggleSpecialLayers('alert', $(privatBox), 'livingOnly');
				}


				// set privatBox to checked if the other one is checked
				else if ($(primeInput).is(':checked')) {
					$(privatBox).prop('checked', true);

					that.toggleSpecialLayers('alert', $(privatBox), 'premiumOnly');
				}


				else {

					// if checked, enable other inputs
					if (value) {
						$(jobBox).prop('disabled', false);
						$(livingBox).prop('disabled', false);

						//show prime-selection
						$(primeBox).show();
					}

					//disable
					else {
						$(jobBox).prop('disabled', true);
						$(livingBox).prop('disabled', true);

						//hide prime-selection and set all inputs & styles back to default
						$(primeList).parent().removeClass('active');
						$(primeList).each((index, item) => {
							if ($(item).hasClass('active')) {
								$(item).removeClass('active');
							}
						});

						$(primeBox).find(this._tileInputChildSelector).prop('checked', false);
						$(primeBox).hide();
					}
				}
			}

			// switch privat60 back to privat if jobBox is checked
			else if (mode === 'togglePrivat60') {

				const privatBox = that.$ctx.find(this._inputPrivatBoxIdSelector);

				if (value) {

					if ($(privatBox).attr(this._dataJsLayerConditionAttr) === 'privat60') {

						$(privatBox).attr(this._dataJsLayerConditionAttr, 'privat');
						$(privatBox).next().find(this._tileDescHeadClassSelector).text('Privat');

						that._events.emit(this._basicInputLayerChangeEvt, 'privat60', false);
						that._events.emit(this._basicInputLayerChangeEvt, 'privat', true);
					}
				}

				// switch back to 60+ if job-input is unchecked and birthday is > 60
				else {

					const birthdayInput = document.querySelectorAll('input[data-js-condition=\"age\"]'),
						age = that.calcAge($(birthdayInput).val());

					// call layer for privatbox and emit info-layer conditions
					if (age >= 60 && $(privatBox).attr(this._dataJsLayerConditionAttr) === 'privat') {

						$(privatBox).attr(this._dataJsLayerConditionAttr, 'privat60');
						$(privatBox).next().find(this._tileDescHeadClassSelector).text('Privat 60+');

						that._events.emit(this._basicInputLayerChangeEvt, 'privat60', true);
						that._events.emit(this._basicInputLayerChangeEvt, 'privat', false);

						that.toggleSpecialLayers('alert', $(this._inputPrivatBoxIdSelector), 'activate60');

					}
				}
			}

			// show prime-selections
			else if (mode === 'togglePrimeList') {

				const primeList = that.$ctx.find('.m-basic-list--vesrs-premium > li');

				// checked
				if (value) {

					$(primeList).parent().addClass('active');

					// get main container
					const mainContainer = that.$ctx.find(this._insuranceScopeTileContainerSelector),
						mainBoxes = $(mainContainer).find(this._tileInputChildSelector);

					// loop through inputs and set prime-pkg to active by index from main-inputs
					$(mainBoxes).each((index, item) => {

						if ($(item).is(':checked')) {
							$(primeList).eq(index).addClass('active');
						}
					});
				}

				else {

					$(primeList).parent().removeClass('active');

					$(primeList).each((index, item) => {
						if ($(item).hasClass('active')) {
							$(item).removeClass('active');
						}
					});
				}
			}

			else if (mode === 'editPrimeList') {

				const primeList = document.querySelectorAll('.m-basic-list--vesrs-premium.active > li');

				if (primeList.length) {
					// get main container
					const mainContainer = that.$ctx.find('.mm-tile-container[data-js-condition=\"insurancescope\"]'),
						mainBoxes = $(mainContainer).find(this._tileInputChildSelector);

					// loop through inputs and set prime-pkg to active by index from main-inputs
					$(mainBoxes).each((index, item) => {

						if ($(item).is(':checked')) {
							$(primeList).eq(index).addClass('active');
						}

						else {
							$(primeList).eq(index).removeClass('active');
						}
					});
				}
			}

			// toggle further inputs if yes is clicked
			else if (mode === 'toggleInsuranceBefore') {

				const subItems = that.$ctx.find('.ll-row[data-js-condition=\"insurancebefore\"]');

				// yes is checked
				if (value) {
					if ($(subItems).hasClass('hidden')) {
						$(subItems).removeClass('hidden');
					}
				}

				// no is checked
				else {
					if (!$(subItems).hasClass('hidden')) {
						$(subItems).addClass('hidden');
					}
				}
			}

			// show layer and disable button if insurance termination is selected
			else if (mode === 'toggleInsuranceTermination') {

				// yes is checked
				if ($(value).attr('id') === 'beenfiredYes') {

					that.toggleSpecialLayers('error', value);
					that.$submitButton.prop('disabled', true).addClass(this._isDisabledClass);
				}

				else {
					that.$submitButton.prop('disabled', false).removeClass(this._isDisabledClass);
				}

				// reset via layer-closing
				if (value === 'reset') {
					$(this._beenFiredYesId).prop('checked', false);
					that.$submitButton.prop('disabled', false).removeClass(this._isDisabledClass);
				}
			}

			// recalc birthday on insuBegin change
			else if (mode === 'toggleInsuranceStart') {
				that.getFieldContext(that.$ctx.find('input#birthday'))
			}

			// toggle membership / login options
			else if (mode === 'toggleMembershipOptions') {

				const subItemLogin = document.querySelectorAll('[data-js-condition=\"alreadymember\"]'),
					subItemMembership = document.querySelectorAll('[data-js-condition=\"becomemember\"]'),
					subItemNoMember = document.querySelectorAll('[data-js-condition=\"nomember\"]');

				if (value === 'alreadymember') {
					$(subItemLogin).removeClass('is-hidden');
					$(subItemMembership).addClass('is-hidden');
					$(subItemNoMember).addClass('is-hidden');
				}

				else if (value === 'becomemember') {
					$(subItemMembership).removeClass('is-hidden');
					$(subItemLogin).addClass('is-hidden');
					$(subItemNoMember).addClass('is-hidden');
				}

				else {
					$(subItemNoMember).removeClass('is-hidden');
					$(subItemLogin).addClass('is-hidden');
					$(subItemMembership).addClass('is-hidden');
				}
			}

			// show special layer
			else if (mode === 'disabledJobOrLiving') {

				const item = value;

				// find privat box and state (60+ or not)
				const privatBox = that.$ctx.find(this._inputPrivatBoxIdSelector);

				if ($(privatBox).attr(this._dataJsLayerConditionAttr) === 'privat60') {
					that.toggleSpecialLayers('alert', item, 'disabled60+');
				}

				else {
					that.toggleSpecialLayers('alert', item, 'disabled');
				}


			}
		},

		/**
		 * show alert and error layers
		 *
		 * @param mod - type of layer (alert / error)
		 *
		 * @param element - what needs to be layered
		 *
		 * @param condition - (optional) necessary if element has more than one special layer
		 */
		toggleSpecialLayers: function (mod, element, condition) {

			const that = this;

			if (!condition) {
				condition = 'default';
			}

			// close other layers
			const allClosed = that.closeAllLayers();

			if (allClosed) {

				const infoLayerModClassSelector = `.m-basic-info-layer--${mod}`;
				const infoLayerJsConditionSelector = `.mm-infolayer-content[data-js-layer-condition='${condition}']`
				// find layer
				let layerContainer = $(element).siblings(infoLayerModClassSelector).children(infoLayerJsConditionSelector);

				if (!layerContainer.length) {
					layerContainer = $(element).parent().siblings(infoLayerModClassSelector).children(infoLayerJsConditionSelector);
				}

				// show layer (as hidden) and calc offset
				$(layerContainer).addClass('hidden');

				// special setting for tablet+ to check if layer should be switched to pos-right
				const layerPos = that.calcLayerOffset(layerContainer);

				const visiblePosLayerPosClassName = `visible pos-${layerPos}`;
				$(layerContainer).addClass(visiblePosLayerPosClassName).removeClass('hidden').find('.mm-infolayer-close').on('click', () => {
					$(layerContainer).removeClass(visiblePosLayerPosClassName).parents(this._colDataClassSelector).css('z-index', '');

					// special setting for insurance termination
					if ($(element).is(this._beenFiredYesId)) {
						that.editElements('toggleInsuranceTermination', 'reset');
					}

				});

				if ($(layerContainer).parents(this._colDataClassSelector).length) {
					$(layerContainer).parents(this._colDataClassSelector).css('z-index', '99');
				}

				// add listener to main-container
				that.addEventListeners('closeAll');

				// auto close layer after 7s
				// S.Utils.delayed('autoCloseLayers', 7000, () => {
				//     that.closeAllLayers();
				// });
			}
		},

		/**
		 * close all alert and error layers
		 *
		 */
		closeAllLayers: function () {

			const that = this;

			that.$btnCloseAll.off();

			// find visible layers and close them
			const visiblelayers = document.querySelectorAll('.mm-infolayer-content.visible');

			if (visiblelayers.length) {

				const activeInfoIcons = document.querySelectorAll('.mm-infolayer-icon.active');

				$(visiblelayers).removeClass('visible pos-right pos-default');
				$(activeInfoIcons).removeClass('active');

				if ($(visiblelayers).parents(this._colDataClassSelector).length) {
					$(visiblelayers).parents(this._colDataClassSelector).css('z-index', '');
				}

				// special setting for insurance termination
				if ($(visiblelayers).parent().siblings('.a-basic-input-radio > input#beenfiredYes')) {
					that.editElements('toggleInsuranceTermination', 'reset');

				}
			}

			return (true);

		},

		/**
		 * calc distance between right edge of layer and right edge of viewport-window
		 *
		 * @param layer
		 */
		calcLayerOffset: function (layer) {

			if (layer.length) {

				const windowWidth = $(window).innerWidth();
				const domRect = layer[0].getBoundingClientRect();

				const spaceRight = windowWidth - domRect.left - domRect.width;

				if (spaceRight < 10) {

					return ('right');
				}

				else {

					return ('default');
				}
			}

			return 'default';
		},

		/**
		 * show/hide/edit elements in sticky tarif comp
		 *
		 * @param mod  -  show or hide
		 *
		 * @param selectVal - value of paymentschedule input
		 */
		modifyStickyTarif: function (mod, selectVal) {

			const that = this;

			const defaultMemberBox = document.querySelector('input#adacMember'),
				plusMemberBox = document.querySelector('input#adacMemberPlus'),
				memberElement = that.$stickyTarif.find('.mm-membership');

			// toggle info if called from inputbox
			if (mod === 'show') {

				if ($(defaultMemberBox).is(':checked') || $(plusMemberBox).is(':checked')) {
					$(memberElement).show();
				}

				else {
					$(memberElement).hide();
				}
			}

			else if (mod === 'hide') {
				$(memberElement).hide();
			}
		}


	});
}(jQuery));