(function ($) {
	'use strict';

	/**
	 * BasicInputSelect module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputSelect
	 * @extends T.Module
	 */
	T.Module.BasicInputSelect.Colorpick = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {Object} */
		$scrollPaneApi: null,

		/** @type {Object} */
		$hiddenSelect: null,

		/** @type {Object} */
		$dropDown: null,

		/** @type {Object} */
		$dropDownList: null,

		/** @type {Object} */
		$toggleBtn: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$dropDownContainer = this.$ctx.find('.aa-color-options');
			this.$hiddenSelect = this.$ctx.find('> select');
			this.$dropDownList = this.$ctx.find('.aa-color-options-list');
			this.$toggleBtn = this.$ctx.find('.aa-btn-color-selection');

			const that = this;

			// set tabIndex and enter event
			S.Globals.TabFocus.addPopup(that.$toggleBtn, this);
			S.Globals.TabFocus.addPopup(that.$dropDownList.find('li'), this);

			if (that.$dropDownContainer.length && that.$toggleBtn.length) {

				that.$toggleBtn.on('click', () => {
					that.toggleDropDown();
				});

				that.$dropDownList.find('li').on('keydown', (item) => {
					if ($(item.currentTarget).index() === (that.$dropDownList.find('li').length - 1) && that.$dropDownContainer.hasClass('is-opened') && item.which === 9) {
						that.toggleDropDown();
					}
				});

				// close dropDown if something else is clicked
				$(window).on('click', (e) => {

					if (that.$dropDownContainer.hasClass('is-opened') &&
						!that.$ctx.is(e.target) &&
						!that.$ctx.find('*').is(e.target)) {

						that.$toggleBtn.removeClass('is-opened');

						// hide dropdown
						that.$dropDownContainer.stop().slideUp(() => {
							that.$dropDownContainer.css('height', '').removeClass('is-opened');
						});
					}
				});
			}

			that.setColors();
			that.syncDropDown();

			this._parent.start(resolve);
		},

		/**
		 * This is triggered when the user press Enter on the element which where handed over via "S.Globals.TabFocus.addPopup(<element>, <instance>);"
		 * in the (maybe start()) function.
		 *
		 * @param {jQuery} $target - currently tab focused el
		 */
		// eslint-disable-next-line
		_handleFocusEnter($target) //NOSONAR Complexity
		{
			// only for info icons
			if ($target.is(this.$toggleBtn)) {
				S.Utils.delayed('enter-focus-select', 100, () => {
					if (!this.$toggleBtn.parent().hasClass('is-locked')) {
						this.toggleDropDown();


						this.$dropDownContainer.on('keydown', (e) => {
							if (e.which === 40 || e.which === 38) {
								e.preventDefault();
							}
						});

						this.$dropDownContainer.on('keyup', (e) => {
							if (e.which === 40) {
								if (this.$dropDownList.find('li:focus').index() === (this.$dropDownList.find('li').length - 1)) {
									this.toggleDropDown();
									this.$toggleBtn.focus();
								}
								else {
									this.$dropDownList.find('li:focus').next().focus();
								}
							}
							else if (e.which === 38) {
								if (this.$dropDownList.find('li:focus').index() === 0) {
									this.toggleDropDown();
									this.$toggleBtn.focus();
								}
								else {
									this.$dropDownList.find('li:focus').prev().focus();
								}
							}
						});
					}
				});
			}
			else if ($target.closest('.aa-color-options-list').length > 0) {
				$target.trigger('click');
			}
		},


		/**
		 * add colors to dropdown entries and sort them asc
		 *
		 */
		setColors: function () {

			const that = this;

			that.$dropDownList.find('> li').each(function (index, item) {
				const colorVal = $(item).attr('data-color');
				if (colorVal.length && colorVal !== 'none') {
					$(item).find('> .aa-colorbox').css('background', colorVal);
				}
			}).promise(that.sortColors());

		},

		/**
		 * sort colors alphabetically asc
		 *
		 */
		sortColors: function () {

			const that = this;

			that.$dropDownList.find('> li:not(.no-color)').sort(function (a, b) {

				const upA = $(a).text().toUpperCase(),
					upB = $(b).text().toUpperCase();
				let result = 0;

				if (upA < upB) {
					result = -1;
				}
				else if (upA > upB) {
					result = 1;
				}

				return result;
			}).appendTo(that.$dropDownList);
		},

		/**
		 * sync selection with hidden drop-down for easier validation
		 *
		 */
		syncDropDown: function () {

			const that = this;

			that.$dropDownList.find('> li').each(function (index, item) {

				// set selected item as default-selection on init
				if ($(item).hasClass('active')) {
					that.$hiddenSelect.val($(item).attr('data-value'));
					that.$hiddenSelect.trigger('change');
					that.showSelection(item);
				}

				$(item).on('click', function () {

					if (!$(item).hasClass('active')) {

						$(item).addClass('active').siblings().removeClass('active');

						// set dropdown-value to selected value from li
						that.$hiddenSelect.val($(item).attr('data-value'));
						that.$hiddenSelect.trigger('change');

						that.showSelection(item);
					}

					// close dropdown
					that.toggleDropDown();
				});
			});

		},

		/**
		 * copy selected content to toggle-button to show current selection
		 *
		 * @param (item)
		 */
		showSelection: function (item) {

			const that = this,
				itemText = $(item).text(),
				colorBox = $(item).find('.aa-colorbox').clone();

			if (colorBox.length) {
				that.$toggleBtn.text(itemText).append(colorBox).addClass('has-color');
			}

			else {
				that.$toggleBtn.text(itemText).removeClass('has-color');
			}
		},

		/**
		 * show/hide dropdown and init/destroy scrollbar
		 *
		 */
		toggleDropDown: function () {
			this.checkScrollPosition();
			const that = this;

			if (that.$dropDownContainer.hasClass('is-opened')) {

				that.$toggleBtn.removeClass('is-opened');

				// hide dropdown
				that.$dropDownContainer.stop().slideUp(300, () => {
					that.$dropDownContainer.css('height', '').removeClass('is-opened');
				});

				that.$dropDownContainer.off('keyup');
				that.$dropDownContainer.off('keydown');
			}

			else {

				that.$toggleBtn.addClass('is-opened');

				// show dropdown
				that.$dropDownContainer.stop().addClass('is-opened').slideDown(() => {
					that.$dropDownContainer.css('height', '');
				});

				that.$dropDownContainer.promise().done(() => {
					that.setScrollBar('init');
				});

				setTimeout(function () {
					that.$dropDownList.find('li').first().focus();
				}, 500);
			}
		},

		/**
		 * init custom scrollbar for dropDown
		 *
		 * @param mode
		 *
		 */
		setScrollBar: function (mode) {

			const that = this,
				heightBuffer = 20;

			if (mode === 'init') {

				that.$dropDownContainer.each(function (index, item) {

					const containerHeight = $(item).outerHeight(),
						listHeight = that.$dropDownList.outerHeight();

					if (!$(item).hasClass('jspScrollable')) {

						// check if item is bigger than container ( + buffer )
						if (listHeight >= (containerHeight + heightBuffer)) {
							$(item).jScrollPane({ showArrows: true });

							// prevent arrow-btn events
							that.$ctx.find('.jspArrow').off();

							that.$scrollPaneApi = $(item).data('jsp');
						}
					}

					// reinit for width calculation
					else {
						that.$scrollPaneApi.reinitialise();
					}
				});
			}
		}

	});
}(jQuery));
