'use strict';
(function ($) {

	/**
	 * BasicDynamicButton module implementation.
	 *
	 * @author  <adrian.kremer@namics.com>
	 * @namespace T.Module
	 * @class BasicDynamicButton
	 * @extends T.Module
	 */
	T.Module.BasicDynamicButton = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		Storage: {},

		/** @type {T.Module[]} */
		modules: [],

		/** @type{T.Module[][]} */
		rows: [],

		data: {},

		config: {},

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: async function start(resolve) {
			this.$ctx = $(this._ctx);
			this.api = this.$ctx.data('api');
			if (await T.Utils.Auth.isLoggedIn()) {
				T.Utils.Auth.getResolvedIdentity(this._requestCount.bind(this));
			}

			resolve();
		},
		_requestCount: function _requestCount(identity) {
			T.Utils.Ajax.fragment({
				url: `${this.api}?mglNo=${identity.MglNo}&firstname=${T.Utils.Auth.getLocalIdentity().Vorname}&lastname=${T.Utils.Auth.getLocalIdentity().Nachname}`
			}, this._onCheckSuccess.bind(this), () => { });
		},

		_onCheckSuccess: function _onCheckSuccess(count) {
			const contextBtn = this.$ctx.find('.m-context-btn');

			contextBtn.attr('href', `${contextBtn.attr('href')}?mode=0`);
			if (count > 0) {
				contextBtn.css({ 'display': 'inline-block' });
			}
		}
	});
}(jQuery));

