(function ( $ )
{
    'use strict';
    /**
     * LayoutHeaderNav module implementation.
     *
     * @author <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class LayoutHeaderNav
     * @extends T.Module
     */
    T.Module.LayoutHeaderNav = T.createModule( {
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} navLayer */
        $navLayer: null,

        /** @type {Object[]} linksLevel1 */
        linksLevel1: null,

        /** @type {Object[]} linksLevel2 */
        linksLevel2: null,

        /** @type {Object[]} linksLevel2 */
        linksLevel3: null,

        /** @type {Object[]} linksLevel2 */
        linksLevel4: null,

        /** @type {number} smartphonePos */
        smartphonePos: 0,

        /** @type {jQuery} l-main */
        $mainWrap: null,

        /** @type {jQuery} l-footer */
        $footerWrap: null,

        /** @type {Number} */
        offsetY: 0,

        /** @type {Number} */
        lastScrollY: 0,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
		// eslint-disable-next-line
        start: function ( resolve ) //NOSONAR complexity
        {
            this.$ctx = $( this._ctx );
            const that = this;

            this.$navLayer = this.$ctx.find( '.js-layer' ).eq( 0 );
            this.linksLevel1 = this.$navLayer.find( '> li > a' );
            this.linksLevel2 = this.$navLayer.find( '> li > ul > li > a' );
            this.linksLevel3 = this.$navLayer.find( '> li > ul > li > ul > li > a' );
            this.linksLevel4 = this.$navLayer.find( '> li > ul > li > ul > li > ul > li > a' );

            this.$mainWrap =  $('body > .l-outer > main.l-main').eq(0);
            this.$footerWrap = $('body > .l-outer > footer.l-footer').eq(0);

            // set global function close
            this._events.on( 'LayoutHeaderNav.close', this.close.bind( this ) );

            let mineOffset = 82;

            if ( Cookies.get( 'dataprotection' ) === undefined ){
                mineOffset = 220;
            }
            // headroom init
			// eslint-disable-next-line
            const header = new Headroom(document.querySelector('.l-header'), {
                offset : mineOffset,
                onPin : function()
                {
                    that._events.emit( 'LayoutHeaderNav.pinned' );
                    that.$ctx.animate({top: that.$ctx.css('top')}, 200);
                },

                onUnpin : function()
                {
                    // the header gets a class from basicSearch if the input is clicked
                    // function setSoftLayerNoScroll -> line 253
                    //  - only on mobile
                    // this class is especially for this to detect if the headroom need to be paused
                    if (!$('.l-header').hasClass('is-open')) {

                        that._events.emit( 'LayoutHeaderNav.unpinned' );
                        that.$ctx.attr('style', '');

                        if (!S.Utils.Helper.mq('tablet').matches && ($('.o-layout-header-metanav .js-searchbar').eq(0).is(':visible') && !$('.l-header .js-submit-search').is(":focus")))
                        {
                            // hide search
							$('.o-layout-header-metanav .js-search').eq(0).trigger('click');
                        }
                    }
                },
            });
            header.init();

            // init status classes
            this.linksLevel1.each( function ( index, item )
            {
                if ( $( item ).siblings( 'ul' ).length )
                {
                    $( item ).addClass( 'has-sub' );
                }
            } );

            if (this.linksLevel2.length) {
                this.linksLevel2.each(function (index, item) {
                    if ($(item).siblings('ul').length) {
                        $(item).addClass('has-sub');
                    }
                });
            }

            if (this.linksLevel3.length) {
                this.linksLevel3.each( function ( index, item )
                {
                    if ( $( item ).siblings( 'ul' ).length )
                    {
                        $( item ).addClass( 'has-sub' );
                    }
                } );
            }

            // init events
            if ( !S.Utils.Helper.mq( 'tablet' ).matches )
            {
                this.eventsSmartphone();
            }
            else
            {

                this.eventsTabletDesktop();
            }

            // click :: anchor items
            this.$ctx.find( '.is-anchor' ).off().on( 'click', function ()
            {
                // animation
                const pos = $( this.hash ).offset().top;
                that.animationAnchor( pos );

                // close smartphone navi
                that.close();
            } );

            // reset inline top.prop on change between desktop / tablet
            S.Utils.Helper.mq( 'desktop' ).addListener( function ( mq )
            {
                if ( mq.matches ) {
                    that.$ctx.attr('style', '');
                }
            });

            // on resize :: init + reset
            S.Utils.Helper.mq( 'tablet' ).addListener( function ( mq )
            {
                // tablet + desktop
                if ( mq.matches )
                {
                    // reset smartphone
                    that.linksLevel1.off();

                    if ( that.linksLevel2.length ) {
                        that.linksLevel2.off();
                    }

                    if ( that.linksLevel3.length ) {
                        that.linksLevel3.off();
                    }

                    that.smartphonePos = 0;

                    that.$ctx.attr('style', '');

                    that.close();

                    // remove active flyout state
                    that.$navLayer.find( '> ul.is-active' ).removeClass( 'is-active' );

                    // hide darkness if mainnavleft does not exist
                    if ( !$( '.m-layout-main-nav-left' ).length )
                    {
                        S.Utils.LayerBgDark.hideLayer();
                    }

                    that.toggleScrollability('close');

                    that.eventsTabletDesktop();
                }
                else
                {
                    that.$ctx.attr('style', '');

                    // reset tablet/desktop
                    that.$navLayer.find( '> li' ).off();
                    that.$ctx.find( 'ul' ).removeAttr( 'style' );

                    // smartphone
                    that.$navLayer.find( '> li' ).off();
                    that.eventsSmartphone();

                    // hide darkness
                    // hide darkness if nav and navleft are not open
                    let navLeft = 0;
                    if ( $( '.m-layout-main-nav-left' ).length && $( '.m-layout-main-nav-left .js-layer' ).is( ':visible' ))
                    {
						navLeft = 1;
                    }
                    if ( that.$navLayer.is( ':hidden' ) && navLeft !== 1 )
                    {
                        S.Utils.LayerBgDark.instantHideLayer();
                    }
                }
            } );

            this._events.on('LayoutHeaderMetaNav.close', () => {

                if ( S.Globals.MediaQuery.mqRightNow === 'smartphone') {
                    that.toggleScrollability('close');
                }
            });

            this._events.on('LayoutHeaderMetaNav.open', () => {
                that.toggleScrollability('open');
            });

            this._events.on('cookieLayerTop.close', () => {
                S.Utils.delayed('closeCookieLayerTop', 1000, () => {
                    if ( that.$ctx.hasClass('is-open') ) {
                        that.toggleScrollability('refresh');
                    }
                });
            });

            resolve();
        },

        /**
         * close navi
         */
        close: function ()
        {
            // if nav layer is on level 2
            if ( this.smartphonePos !== 0 )
            {
                this.$navLayer.hide().removeClass( 'is-active' ).css( 'left', '0' );
                this.smartphonePos = 0;
            }
            else
            {
                this.$navLayer.slideUp( 'fast' );
            }
        },

        /**
         * events :: init smartphone click events
         *
         */
        eventsSmartphone: function ()
        {
            const that = this,
				jspScrollable = '.jspScrollable',
				isScrollable = 'is-scrollable';

            // click :: smartphone first level items
			// eslint-disable-next-line
            this.linksLevel1.off().on( 'click', function ( event ) //NOSONAR return
            {
                if ( $( this ).hasClass( 'has-sub' ) )
                {
                    event.preventDefault();

                    // hide subitems
                    that.$navLayer.find( 'li > ul' ).hide();

                    //remove scrollbar
                    if (that.$navLayer.find(jspScrollable).length) {
                        that.$navLayer.find(jspScrollable).data('jsp').destroy();
                    }

                    // show next sub items and go to next level
                    $( this ).next().show();
                    that.animationSmartphone( 'next' );

                    that.$ctx.addClass(isScrollable);
                }

                else
                {
                    return true;
                }
            } );

            // click :: smartphone 2nd level items
			// eslint-disable-next-line
            this.linksLevel2.off().on( 'click', function ( event ) //NOSONAR return
            {
                if ( $( this ).hasClass( 'has-sub' ) )
                {
                    event.preventDefault();

                    // hide subitems
                    // that.$navLayer.find( 'li > ul' ).hide();

                    //remove scrollbar
                    if (that.$navLayer.find(jspScrollable).length) {
                        that.$navLayer.find(jspScrollable).data('jsp').destroy();
                    }

                    // show next sub items and go to next level
                    $( this ).next().show();
                    that.animationSmartphone( 'next' );

                    that.$ctx.addClass(isScrollable);
                }

                else
                {
                    return true;
                }
            } );

            if ( this.linksLevel3.length ) {

                // click :: smartphone 3rd level items

				// eslint-disable-next-line
                this.linksLevel3.off().on( 'click', function ( event ) //NOSONAR retutn
                {
                    if ( $( this ).hasClass( 'has-sub' ) )
                    {
                        event.preventDefault();

                        //remove scrollbar
                        if (that.$navLayer.find(jspScrollable).length) {
                            that.$navLayer.find(jspScrollable).data('jsp').destroy();
                        }

                        // show next sub items and go to next level
                        $( this ).next().show();
                        that.animationSmartphone( 'next' );

                        that.$ctx.addClass(isScrollable);
                    }

                    else
                    {
                        return true;
                    }
                } );
            }

            // click :: smartphone btn back :: animation to prev level
            this.$ctx.find( '.js-back' ).off().on( 'click', function ()
            {
                that.animationSmartphone( 'prev' );

                // fyi deactivated on purpose by EW72 / LM :
                // prevents animation back - if that's a problem, make a decision if you want the slide out anim
                // or the instant hide but both doesn't make sense

                // hide level2 elems
                // that.$navLayer.find('li > ul').css('display','');

            } );

            // close nav on click close-icon
            this.$ctx.find( 'button.mm-close' ).off().on( 'click', function ( event )
            {
                event.preventDefault();

                that.close();
                S.Utils.LayerBgDark.hideLayer();
                that.toggleScrollability('close');
            } );

        },

        /**
         * prevent scrolling in background while enabling nav-list scrolling
         *
         * calc nav-list height / freeze l-main / hides absolute elems like l-footer
         *
         * mod - (open | close | refresh )
         *
         */
        toggleScrollability: function (mod) {

            const that = this;

            if (mod !== 'close') {

                if ( S.Globals.MediaQuery.mqRightNow === 'smartphone') {

                    // main header (incl. cookie-layer top)
                    const headerHeight = $('.l-header').outerHeight(),

                        // cookie-layer bottom
                        $footerCookie = $('body > .m-basic-cookie');
                    let footerCookieHeight = 0;

                    if ( $footerCookie.length && $footerCookie.css('display') !== 'none') {
                        footerCookieHeight = $($footerCookie).outerHeight();
                    }

                    // calc max list height and set it
                    const headerCookieBuffer = window.innerHeight - headerHeight - footerCookieHeight;

                    that.$ctx.css('height', `${headerCookieBuffer}px`);

                    if (mod === 'open') {

                        // current scroll pos
                        that.lastScrollY = $(document).scrollTop();
                        that.offsetY = that.lastScrollY * -1;

                        // show lowest active nav-level at first
                        if ( that.$ctx.find( 'a.is-active' ).eq(0).length ) {

                            // last active a found
                            const $lowestActivePoint =  that.$ctx.find('a.is-active').last();
                            // count levels up to get a value for the css prop
                            const lvlCount = $lowestActivePoint.parents('ul').length;

                            // show each ul parent
                            $lowestActivePoint.parents('ul').show();

                            // build left.prop for the main wrapper ( -100% || -200% etc. )
                            const leftProp = `-${lvlCount - 1}00%`;
                            that.$navLayer.css('left', leftProp);

                            // update this.smartphonePos
                            // console.log('this.smartphonePos was', this.smartphonePos);

                            this.smartphonePos = ( lvlCount - 1 ) * -100;

                            that.$ctx.addClass('is-open');
                        }

                        else {
                            that.$ctx.addClass('is-open');
                        }

                        // fix position of l-main to prevent scrolling and add top-prop to prevent jumping
                        that.$mainWrap.css({
                            'position':'fixed',
                            'top': that.offsetY
                        });

                        // hide footer
                        that.$footerWrap.hide();

                        // add click event to close nav if no nav-elem is target
                        S.Utils.delayed('addOffsetNavClick', 100, () => {

                            $('body').on('click.navOffsetClick', (e) => {
                                if ( $( e.target ).is('div.m-layout-header-nav') || $( e.target ).is('ul')) {
                                    this.$ctx.find( 'button.mm-close' ).trigger('click');
                                    $( '.js-menu' ).removeClass('js-close-icon').find('img').attr('src', '/assets/img/icons/icon-menu.svg');
                                }
                            });
                        });
                    }
                }
            }

            else {

                // reset every inline style from above
                that.$ctx.css({'height': '', 'top': ''});
                that.$mainWrap.css({
                    'position':'',
                    'top': ''
                });
                that.$footerWrap.show();
                that.$ctx.removeClass('is-open');
                // scroll to last position
                $(document).scrollTop( that.lastScrollY);

                //remove eventlistener
                $('body').off('click.navOffsetClick');
            }

        },

        /**
         * events :: init tablet + desktop click/hover events
         *
         */
        eventsTabletDesktop: function ()
        {
            const that = this,
				isVisible = 'is-visible';

            function bodyClick ()
            {
                if ( S.Utils.Helper.mq( 'tablet' ).matches )
                {
                    that.$navLayer.find( 'ul' ).hide();
                }
            }

            // hover :: first level items
            this.$navLayer.find( '> li' ).off().on( 'mouseenter click touchstart', function ( e )
            {
                e.stopPropagation();
                that._events.emit( 'LayoutMainNavLeft.close' );

                // show elem as hidden to calc heights
                const activeList = $( this ).children( 'ul' );
                $(activeList).addClass('is-hidden').show();

                const listHeight = $(activeList).outerHeight(),
                    headerSpace = that.$navLayer.outerHeight() + $('.l-header').outerHeight(),
                    availableSpace = window.innerHeight - headerSpace - 10;

                if ( listHeight >= availableSpace ) {
                    $(activeList).removeClass('is-hidden').css('height',availableSpace)
                        .promise().done( () => {
                            $(activeList).jScrollPane({showArrows: false}).addClass(isVisible);
                            $(activeList).find('.jspPane').css('width', '100%');
                        });
                }
                else {
                    $(activeList).addClass(isVisible).removeClass('is-hidden');
                }

                $('.l-header').addClass('stay-visible');

                that.$navLayer.find( 'li > ul' ).not( $( this ).children( 'ul' ) ).hide();
                $( document ).on( 'click touchstart', bodyClick );
                if ( S.Utils.Helper.isTouch() )
                {
                    that.$navLayer.find( '> li > a' ).on( 'click', function ()
                    {
                        return false;
                    } );
                }
            } )
                .on( 'mouseleave', function ()
                {
                    const activeList = $( this ).children( 'ul' );

                    if ( $(activeList).hasClass('jspScrollable') ) {

                        // add selector to parent because of jsp madness
                        // => necessary to add a selector to elem cause class 'is-visibile'
                        // is added to jsp-object and therefore not selectable after destroy
                        $(activeList).parent('li').addClass('js-scrolled');

                        // destroy jsp scrollbar
                        $(activeList).eq(0).data('jsp').destroy();

                        // remove classes and styles set by jsp
                        that.$navLayer.find('li.js-scrolled > ul').removeAttr('style');
                        that.$navLayer.find('li.js-scrolled').removeClass('js-scrolled');
                    }

                    else {
                        $(activeList).stop().hide().removeClass(isVisible);
                    }

                    $('.l-header').removeClass('stay-visible');
                } );

            // click :: anchor items
            this.$ctx.find( '.js-anchor' ).off().on( 'click', function ()
            {
                // animation
                const pos = $( this.hash ).offset().top;
                that.animationAnchor( pos );

                // close tablet/desktop flyout navi layer
                that.$navLayer.blur();
            } );
        },

        /**
         * animation :: smartphone level animation
         *
         * @param {string} direction 'next' || 'prev'
         */
        animationSmartphone: function ( direction )
        {
            if ( direction === 'next' )
            {
                this.smartphonePos -= 100;
            }
            else
            {
                this.smartphonePos += 100;
            }

            // animation to next or prev level
            this.$navLayer.animate( {
                'left': `${this.smartphonePos}%`
            } );

        },

        /**
         * animation :: jump to content anchor
         *
         * @param {number} pos
         */
        animationAnchor: function ( pos )
        {
            $( 'html, body' ).animate( {
                scrollTop: pos
            }, 1000 );
            S.Utils.LayerBgDark.hideLayer();
        },

    } );
}( jQuery ));
