(function ($) {
    'use strict';

    /**
     * BasicInputRadio module implementation.
     *
     * @author  <w.eberts@edelweiss72.de>
     * @namespace T.Module
     * @class BasicInputRadio
     * @extends T.Module
     */
    T.Module.BasicInputRadio = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $input: null,

        /** @type {jQuery} */
        $labels: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve) {
            this.$ctx = $(this._ctx);
            this.$input = this.$ctx.children('input').eq(0);
            this.$labels = this.$ctx.find('label');

            // set/remove focus/mouseout class for separat highlight svg for focus by tab and click
            this.setFocus();

            // set first class radio for error
            this.setFirst();

            resolve();
        },

        /**
         * load background images onInit() into client to prevent bugs where ie11 would not display bg imgs due to loading problems
         *
         */
        loadAssetsForIE11() {

            // delayname on purpose => we'll do that only once, not for each elem!
            S.Utils.delayed('basicInputRadio.loadBgAssetsForIE11_delay', 10, () => {

                // in case elem has to be reinit, don't call logic again
                if (!$('body').find('> .js-asset-container').length) {

                    // add wrapper to body to store assets into
                    const $assetWrapper = $('<div class="js-asset-container h-visuallyhidden"></div>').appendTo('body');

                    // define necessary image paths as array
                    const imgArray = [];
                    imgArray[0] = '/assets/img/icons/icon-radio-button-checked.svg';
                    imgArray[1] = '/assets/img/icons/icon-radio-button-checked-focus.svg';
                    imgArray[2] = '/assets/img/icons/icon-radio-button-unchecked.svg';
                    imgArray[3] = '/assets/img/icons/icon-radio-button-unchecked-focus.svg';

                    // start preloading -> add each asset to imgContainer
                    for (let i = 0; i < imgArray.length; i++) {
                        $assetWrapper.append(`<img alt="assets" src="${imgArray[i]}"/>`);
                    }
                }
            });
        },

        /**
         *
         * setFocus class for separate styling from focus by tab
         */
        setFocus: function () {

            const that = this;
            let mouseClick = false;

            // set flag to detect if input was clicked (mouse) or pressed (keyboard)
            that.$labels.on('mouseup', () => {
                mouseClick = true;
            });

            // set flag to detect if input was clicked (mouse) or pressed (keyboard)
            that.$input.on('mouseup', () => {
                mouseClick = true;
            });

            that.$input.on('click', () => {
                if (mouseClick) {
                    that.$ctx.addClass('js-clicked');
                    //Reset flag
                    mouseClick = false;
                }
            });

            that.$input.on('blur', () => {
                that.$ctx.removeClass('js-clicked');
            });
        },

        /**
         *
         * setFirst
         */
        setFirst: function () {
            this.$ctx.closest('.ll-row').find('.a-basic-input-radio').first().find('> input').addClass('is-first-radio');
        }
    });
}(jQuery));