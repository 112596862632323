(function($) {
	'use strict';
	/**
	 * BasicUpload module implementation.
	 *
	 * @author s.vogt <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicUpload
	 * @extends T.Module
	 */
	T.Module.BasicUpload = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $container: null,

        /** @type {jQuery} */
        $input: null,

        /** @type {jQuery} */
        $btn: null,

        /** @type {jQuery} */
        $btnClear: null,

        /** @type {jQuery} */
        $data: null,

        /** @type {Number} */
        count: 1,

        /** @type {Number} */
        dataID: 0,

        /** @type {Boolean} */
        multiple: false,

		/** @type {Boolean} */
		showFilesize: false,

		/** @type {jQuery} */
		$fileSizeWrap: null,

        /** @type {String} */
        HTML: '',



        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function(resolve) {
            this.$ctx = $(this._ctx);
            this.$container = $(this._ctx).parent();

			// classes as strings
            this.btnClass = '.a-basic-btn';
            this.dataWrapFilesize = '> .m-data-wrap > .js-filesize';
            this.upload = '.m-basic-upload';
            this.fileOnly = 'is-file-only';

            this.$input = this.$ctx.find('input').eq(0);
            this.$btn = this.$ctx.find(this.btnClass).eq(0);
            this.$btnClear = this.$ctx.find('.js-clear-button').eq(0);
            this.$data = this.$ctx.find('.js-data').eq(0);
            this.dataID = this.$ctx.data('t-id');
            this.HTML = this.$ctx.prop('outerHTML');

            if ( this.$ctx.find(this.dataWrapFilesize).length ) {
				this.showFilesize = true;
				this.$fileSizeWrap =  this.$ctx.find(this.dataWrapFilesize).eq(0);
			}

            // init change
            this.change(this.$input);

            // init close
            this.close(this.$btnClear);

			resolve();
		},


        /**
         * change
         *
         * @param {jQuery} inputElem
         */
        change: function(inputElem) {
            const that = this;

            this.$btn.on('click', () => {
                inputElem.trigger('click');
            });

            inputElem.off().on('change', (e) =>
			{
                const fileName = $(e.currentTarget).val().split('\\').pop(),
                    container = inputElem.closest(this.upload);

                // multiple
                if (typeof container.attr('data-multiple') !== 'undefined' && container.attr('data-multiple') === 'true') {
                        that.multiple = true;

                        container.addClass(this.fileOnly);
                        let clone = null;

                        if (container.attr('data-add') === 'after') {
                            clone = $(that.HTML).insertAfter(container);
                        }
                        else {
                            clone = $(that.HTML).insertBefore(container);
                        }

						if(typeof that.$ctx.data('max-files') !== 'undefined') {
							const maxCountIdentifier = that.$ctx.data('multiupload-group-identifier');
							const currentUploads = $(`[data-multiupload-group-identifier="${maxCountIdentifier}"]`);
							const maxCountCurrent = currentUploads.length - 1;
							const maxCountFiles = parseInt(that.$ctx.data('max-files'));

							currentUploads.each((index, el) => {
								if(!$(el).hasClass(this.fileOnly) && (maxCountCurrent >= maxCountFiles)) {
									$(el).find(this.btnClass).addClass('h-hidden');
								}
							});
						}

                        that.clone(clone);
                }

                if (fileName) {
                    container.find('.js-data').addClass('is-active').html(`<span>${fileName}</span>`);

                    if(parseInt(container.find('.js-data span').height()) > parseInt(container.find('.js-data').css('line-height'))) {
                        container.find('.js-data').addClass('is-longer');
                    }

                    container.find('.m-description').hide();
                    container.find(this.btnClass).parent().addClass('is-no-show');
                    that._events.emit("BasicUpload.onFileSelected");

					if (that.showFilesize)
					{
						that.toggleFileSize('show', that.$container);
					}
                }
            });
        },


        /**
         * clone
         *
         * @param {jQuery} clone
         */
        clone: function(clone) {
            const that = this;

            that.count++;

            clone.addClass('is-clone');

            // update label
            clone.find('label').attr('for', `${clone.find('label').attr('for')}_${that.count}`);

            // update input
            clone.find('input').attr('id', `${clone.find('input').attr('id')}_${that.count}`).attr('name', `${clone.find('input').attr('name')}_${that.count}`);

            // update btn
            clone.find(this.btnClass).html(clone.data('text-add-next')).on('click', () => {
                clone.find('input').trigger('click');
            });

            const fileName =  clone.find('input').val().split('\\').pop();

            if (fileName){
                clone.find('js-data').addClass('is-active').html(fileName);
                clone.find(this.btnClass).hide();
            }

            // init close
            that.change(clone.find('input').eq(0));

            // init close
            that.close(clone.find('.js-clear-button').eq(0));
        },


        /**
         * close
         *
         * @param {jQuery} closeBtn
         */
        close: function(closeBtn) {
            const that = this,
                container = closeBtn.closest(this.upload);

            closeBtn.off().on('click', (e) =>
			{
                if (that.multiple === true) {
					if(typeof $(e.currentTarget).closest(this.upload).data('max-files') !== 'undefined') {
						const maxCountIdentifier = $(e.currentTarget).closest(this.upload).data('multiupload-group-identifier');
						const currentUploads = $(`[data-multiupload-group-identifier="${maxCountIdentifier}"]`);

						currentUploads.each((index, el) => {

							if(!$(el).hasClass(this.fileOnly)) {
								$(el).find(this.btnClass).removeClass('h-hidden');
							}
						});
					}

					$(e.currentTarget).closest(this.upload).remove();

                    // update btn
                    if ($(`[data-t-id="${that.dataID}"]`).length === 1){
                        $(`[data-t-id="${  that.dataID  }"]`).find(this.btnClass).html(container.data('text-add'));
                    }
                }else{
                    container.find('.js-data').removeClass('is-active').html('');
                    container.find('input').val('');
                    container.find('.m-description').show();
                    container.find(this.btnClass).parent().show();

					container.find('.js-data').removeClass('js-file-deletable');
					closeBtn.removeClass('js-delete-button').addClass('js-clear-button');
					container.removeClass('m-basic-upload--is-deletable');
                }

				if (that.showFilesize)
				{
					that.toggleFileSize('show', that.$container);
				}

                that._events.emit("BasicUpload.onFileDeleted");
            });
        },

		/**
		 * toggleFileSize
		 *
		 * @param mod {String} - show || hide
		 */
		toggleFileSize(mod, module)
		{
			// hide
			if ( mod === 'hide' ) {
                module.find('.m-basic-upload:not(:last-child)').each((index, item) =>
				{
                    const fileWrapTemp = $(item).find(this.dataWrapFilesize).eq(0);

                    fileWrapTemp.removeClass('is-visible');
                    fileWrapTemp.find('> .js-sizeVal').html();
                    fileWrapTemp.find('> .js-sizeUnit').html();
                });
			}

			// show
			else {
                module.find('.m-basic-upload:not(:last-child)').each((index, item) =>
				{
					const inputTemp = $(item).find('input').eq(0);

                    if(inputTemp[0].files[0] !== undefined) {
						const fileWrapTemp = $(item).find(this.dataWrapFilesize).eq(0);

						let fileUnit = 'KB';
						// size is stored in bytes => convert to KB
						// fileSize = this.$input[0].files[0].size / 1024,
						const fileSize = inputTemp[0].files[0].size / 1024;
						let formattedSize;

						// detect unit (KB / MB)
						if ( fileSize > '1023' ) {
							fileUnit = 'MB';

							// convert to MB and round to 2 decimals
							formattedSize = parseFloat( fileSize / 1024).toFixed(2);
						}

						else {
							// round KB to Int
							formattedSize = Math.round( fileSize);
						}

						fileWrapTemp.find('> .js-sizeVal').html(formattedSize);
						fileWrapTemp.find('> .js-sizeUnit').html(fileUnit);
						fileWrapTemp.addClass('is-visible');
					}
                });
			}
		}
	});
}(jQuery));
