'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) {
	const SELECTOR_HOMEPAGE = '.js-homepage-link';
	const SELECTOR_PHONE = '.js-telephone';
	const CLASS_CLEARLISTENER = 'js-clear-listener';

	/**
	 * MapboxSearchPoiResults.BikerHotels module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>, Dmitri Zoubkov <dmitri.zoubkov@mtc.berlin>
	 * @memberof T.Module.MapboxSearchPoiResults
	 * @class MapboxSearchPoiResults.BikerHotels
	 * @extends T.Module.MapboxSearchPoiResults
	 */
	T.Module.MapboxSearchPoiResults.BikerHotels = T.createDecorator({
		_initEvents: function _initEvents() {
			this._parent._initEvents();
			this.$ctx.find('.js-keyword-search').on('keyup', this._handleSearchKey.bind(this));
		},
		_handleSearchKey: function _handleSearchKey(event) {
			const $btn = this.$ctx.find('.js-clear-button');
			// eslint-disable-next-line eqeqeq
			if (13 != event.keyCode //NOSONAR keyCode has no defined type
				&& $btn.length && !$btn.hasClass(CLASS_CLEARLISTENER)) {
				$btn.css('z-index', 1000);
				$btn.on('click', () => {
					$(document).trigger('BasicKeywordfilter.reset');
					$btn.toggleClass(CLASS_CLEARLISTENER, false);
				});
				$btn.toggleClass(CLASS_CLEARLISTENER, true);
			}
		},
		_getConfigEndpoint: function _getConfigEndpoint() {
			return this.config.apiHotel;
		},
		_getItemTooltip: function _getItemTooltip(item) {
			return item ? T.Utils.Helper.htmlToText(item.title[0]) : null;
		},
		_getItemId: function _getItemId(item) {
			return item.guid;
		},
		_getItemCategory: function _getItemCategory(item) {
			return this._findRegion(item.zipcode);
		},
		_findRegion: function _findRegion(zipcode) {
			if (!this._regionCache) {
				this._regionCache = {};
			}
			let result = this._regionCache[zipcode];
			if (!result && this.config.Regions) {
				const zipn = Number(zipcode);
				// eslint-disable-next-line prefer-const
				for (const region in this.config.Regions) {
					if (this.config.Regions[region].some((range) => {
						return zipn >= Number(range[0]) && zipn <= Number(range[1]);
					})) {
						result = region;
						this._regionCache[zipcode] = region;
						break;
					}
				}
			}
			return result || '';
		},
		_getItemLocation: function _getItemLocation(item) {
			return item.city;
		},
		_getItemName: function _getItemName(item) {
			return T.Utils.Helper.htmlToText(item.title[0]);
		},
		_getItemPostCode: function _getItemPostCode(item) {
			return item.zipcode;
		},
		_getItemStreet: function _getItemStreet(item) {
			let result = item.street;
			if (item.imx_streetno) {
				result += ` ${item.imx_streetno}`;
			}
			return result;
		},
		_getItemHomepage: function _getItemHomepage(item) {
			return item.imxtools_homepage ? item.imxtools_homepage[0] : '';
		},
		_getItemPhone: function _getItemPhone(item) {
			return item.phone;
		},
		_getItemPointX: function _getItemPointX(item) {
			return item ? T.Utils.Geo.latlonToMercator({ x: item.coord_long, y: item.coord_lat }).x : 0;
		},
		_getItemPointY: function _getItemPointY(item) {
			return item ? T.Utils.Geo.latlonToMercator({ x: item.coord_long, y: item.coord_lat }).y : 0;
		},
		_getItemMapLocation: function _getItemMapLocation(item) {
			return item && item.coord_long && item.coord_lat ? T.Utils.Geo.coordToLocation({ x: item.coord_long, y: item.coord_lat }) : null;
		},
		_setImage: function _setImage($element, poiItem) {
			const $img = $element.find('img');
			let imgUrl;

			if (poiItem.imageUrl) {
				imgUrl = `${this.config.baseImageUrl}/w_161,h_121,c_fill/${poiItem.imageUrl}`;
				$img.attr('alt', T.Utils.Helper.htmlToText(poiItem.title[0]));
				$img.attr('title', T.Utils.Helper.htmlToText(poiItem.title[0]));
			} else {
				imgUrl = this._getConfigDefaultImagePath();
			}

			$img.attr('src', imgUrl);
		},
		_setText: function _setText($element, item) {
			this._parent._setText($element, item);
			let val = this._getItemHomepage(item);
			if (val) {
				$element.find(SELECTOR_HOMEPAGE).attr('href', val);
			} else {
				$element.find(SELECTOR_HOMEPAGE).remove();
			}
			val = this._getItemPhone(item);
			if (val) {
				$element.find(SELECTOR_PHONE).attr('href', `tel:${val.replace(/[ \-/]/g, "").replace(/^0+/, "+49")}`).text(val);
			} else {
				$element.find(SELECTOR_PHONE).remove();
			}
		},
		_hasCategory: function _hasCategory(item) {
			return item.zipcode && this._findRegion(item.zipcode);
		},
		_getConfigRequestParams: function _getConfigRequestParams() {
			return {
				channel: 3,
				outputclass: 0,
				rows: 1000,
				'filter.mandant': 15
			};
		},
		_handleGetDataSuccess: function _handleGetDataSuccess(data) {
			if (data && data.response && data.response.docs && data.response.docs.length) {
				this.items = data.response.docs;
				this._loadCategories();
				this._loadItems();
			}
			T.Utils.View.stopLoader();
		},
		_updateOtherMapLocations: function _updateOtherMapLocations() {
			if (this.items) {
				this.items.forEach((item) => {
					const id = this._getItemId(item);
					if (id !== this._getItemId(this.currentItem) && this._getVisibleResults(id).length) {
						item.mapsReference = T.Utils.Mapbox.createPoint(
							this.map,
							this._getItemMapLocation(item),
							this._getItemTooltip(item),
							this.$iconInActivePosition
						);
						const $elm = $(item.mapsReference.getElement());
						$elm.data('id', id);
						$elm.on('click', this._onItemClicked.bind(this));
					}
				});
			}
		}
	});
}(jQuery));