/**
 * RewardsModul module implementation.
 *
 * @author  <ibrahim.mahria@adac.de>
 * @class RewardsModul
 */
class RewardsModul extends BasisComponent {
	constructor() {
		super();
		this.productActive = '';
		this.productActiveTitle = '';
		this.productActiveImage = '';
		this.productActiveOrderNumber = '';
		this.buttonNext = '.js-next-Button';
		this.errorMsg = '.mm-error-msg';
		this.tileMain = '.mm-tile-main';
		this.orderNumber = 'order-number';
		this.isCashItem = 'iscashitem';
	}

	_connectedCallback() {
		this.$ctx = jQuery(this);
		T.Utils.Application.init();
		T.Utils.Auth.getResolvedIdentity(this.handleIdentityLoaded.bind(this));
		this.initClickActiveProduct();
		this.addEventListener();
		S.Utils.Form.init();
	}

	handleIdentityLoaded(user) {
		this._user = user;
	}

	initClickActiveProduct() {
		this.$ctx.find('.js-productactive').on('click', (e) => {
			const box = this.$ctx.find(e.target).closest(this.tileMain);
			this.productActive = box.find('.js-productactive').attr('id');
			this.productActiveTitle = box.find('.mm-headline').text();
			this.productActiveImage = box.find('.mm-icon img').eq(0).attr('src');
			this.productActiveOrderNumber = box.data(this.orderNumber);
			this.productActiveIsCashItem = box.data(this.isCashItem) || false;

			sessionStorage.setItem('productOverviewUrl', window.location.href);
			sessionStorage.setItem('productActive', this.productActive);
			sessionStorage.setItem('productActiveTitle', this.productActiveTitle);
			sessionStorage.setItem('productActiveImage', this.productActiveImage);
			sessionStorage.setItem('productActiveCopayment', '');
			sessionStorage.setItem('productActiveOrderNumber', this.productActiveOrderNumber);
			sessionStorage.setItem('productActiveModel', this.productActiveOrderNumber);
			sessionStorage.setItem('productIsCashItem', this.productActiveIsCashItem);
			this.setProductList();
			this.$ctx.find(this.errorMsg).hide();
			this.$ctx.find('.js-next-Button').data('productactive', this.productActiveOrderNumber);
		});
	}

	setProductList() {
		const productList = [];
		this.$ctx.find('.mm-tile-main:visible .js-productactive').each((index, item) => {
			const box = this.$ctx.find(item).closest(this.tileMain);
			productList[index] = {
				'url': item.id,
				'title': box.find('.mm-headline').text(),
				'image': box.find('.mm-icon img').eq(0).attr('src'),
				'copayment': '',
				'ordernumber': box.data(this.orderNumber),
				'model': box.data(this.orderNumber),
				'iscashitem': box.data(this.isCashItem) || false
			};
		});
		if (productList.length) {
			sessionStorage.removeItem('productlist');
			sessionStorage.setItem('productlist', JSON.stringify(productList));
		}
	}
	addEventListener() {
		this.$ctx.find(this.buttonNext).on('click', (e) => {
			const selectedItem = this.$ctx.find('.js-productactive:checked');
			if (selectedItem.length > 0) {
				this.$ctx.find(this.errorMsg).hide();
				window.location.href = T.Utils.Helper.updateUrlParameter(T.Utils.Helper.getQueryParams(window.location.search), this.$ctx.find(e.target).attr('data-t-url'));
			}
			else {
				this.$ctx.find(this.errorMsg).show();
			}
		});
	}
}
window.customElements.define('rewards-modul', RewardsModul);
