'use strict';
(function ($) {
	/**
	 * BasicContactForm module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class BasicContactForm
	 * @extends T.Module
	 */
	T.Module.BasicContactForm = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} **/
		$submitMailBtn: null,

		/** @type {jQuery} **/
		$successText: null,

		/** @type {jQuery} */
		$errorText: null,

		/** @type {jQuery} */
		$enableFormTracking: false,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);

			this.$successText = this.$ctx.find('.js-success-text');
			this.$errorText = this.$ctx.find('.js-error-text');
			this.$submitMailBtn = this.$ctx.find('button[type="submit"]');
			this.$contactForm = this.$ctx.find('form');
			if (this.$contactForm.attr('data-name')) {
				this.$enableFormTracking = true;
			}


			if (this.$contactForm) {
				this._readConfig();
				this._setupValidation();
				/**
				 * Adds click function for the mail icon
				 */
				this.addEvents();
				T.Utils.Auth.getResolvedIdentity(this._prefillForm.bind(this));
			}

			resolve();
		},

		_readConfig: function _readConfig() {
			this._apiregionalclub = this.$ctx.data("api-regionalclub");
		},

		_prefillForm: function _prefillForm(identity) {
			this._prefillField("MembershipNumber", identity ? identity.MglNo : "");
			const appIdent = T.Utils.Auth.getApplicationIdentity();
			if (appIdent && appIdent.UserData) {
				this._prefillField("Firstname", appIdent.UserData.Vorname);
				this._prefillField("Lastname", appIdent.UserData.Nachname);
				this._prefillField("Email", appIdent.UserData.Email);
				if (appIdent.UserData.Anrede === 2) {
					this.$ctx.find('input[name="gender"][value="1"]').attr('checked', true);
				} else if (appIdent.UserData.Anrede === 1) {
					this.$ctx.find('input[name="gender"][value="0"]').attr('checked', true);
				}
				if (appIdent.UserData.Anschrift) {
					this._prefillField("Zip", appIdent.UserData.Anschrift.Postleitzahl);
					this._prefillField("City", appIdent.UserData.Anschrift.Ort);
					this._prefillField("Regionalcode", appIdent.UserData.Anschrift.RegionalclubCode);
					this._prefillField("Regionalid", appIdent.UserData.Anschrift.RegionalclubKennung);
				}
			}
		},

		_prefillField: function _prefillField(fieldName, value) {
			if (value) {
				const field = this.$ctx.find(`input[name='${fieldName}']`);
				if (field) {
					$(field).val(value);
				}
			}
		},

		_setupValidation: function _setupValidation() {
			let validationmessages = this.$contactForm.data('validation-messages');

			if (!validationmessages) {
				validationmessages = {};
			}
			const rulesAndMessages = {
				rules: {
					'MembershipNumber': {
						maxlength: 9,
						digits: true
					},
					'Firstname': {
						required: true,
						maxlength: 100
					},
					'Lastname': {
						required: true,
						maxlength: 100
					},
					'Email': {
						required: true,
						email: true,
						maxlength: 100
					},
					'Message': {
						required: true,
						maxlength: 750
					},
					'Zip': {
						required: true,
						digits: true,
						maxlength: 5,
						minlength: 5
					}
				},
				messages: validationmessages
			};

			T.Utils.Validation.init(this.$ctx, [this.$contactForm], rulesAndMessages);

		},

		addEvents: function addEvents() {
			// E-Mail submit click
			this.$submitMailBtn.on('click', this._prepareFormSubmit.bind(this));
			$(document).on("identityloaded", this.onIdentityChanged.bind(this));
		},

		onIdentityChanged: function onIdentityChanged() {
			T.Utils.Auth.getResolvedIdentity(this._prefillForm.bind(this));
		},

		_prepareFormSubmit: function _prepareFormSubmit(ev) {
			ev.preventDefault();

			// We need to escape message field content due possibly inserted HTML, etc. code
			this.$messageField = this.$ctx.find('#Message');

			//thorougly check for existence
			if (this.$messageField && this.$messageField.length) {
				const msg = this.$messageField.val();
				if (!T.Utils.Helper.isEmptyString(msg)) {
					this.$messageField.val(T.Utils.Helper.htmlToText(msg.trim()));
				}
			}

			let getregcode = false;
			let zip;
			let field = this.$ctx.find("input[name=Regionalcode]");
			if (field && this._apiregionalclub) {
				getregcode = !$(field).val();
				if (getregcode) {
					field = this.$ctx.find("input[name=Zip]");
					if (field) {
						zip = field.val();
						if (!zip) {
							getregcode = false;
						}
					}
				}
			}
			if (getregcode) {
				T.Utils.Application.init();
				const url = T.Utils.Application.getApi() + this._apiregionalclub;

				T.Utils.Ajax.fragment(
					{
						url: url,
						method: 'POST',
						data: JSON.stringify({
							"Plz": zip
						})
					},
					(data) => {
						if (!data.Success) {
							field = this.$ctx.find("input[name=Regionalid]");
							field.val("00");
						} else {
							field = this.$ctx.find("input[name=Regionalid]");
							field.val(data.Data.RcNr);
							field = this.$ctx.find("input[name=Regionalcode]");
							field.val(data.Data.RcCode);
						}
						this._submitContactForm(ev);
					},
					this._errorCallback.bind(this)
				);
			} else {
				this._submitContactForm(ev);
			}
		},

		_submitContactForm: function _submitContactForm(ev) {
			ev.preventDefault();

			if (this.$contactForm.valid()) {
				T.Utils.Ajax.fragment(
					{
						url: this.$contactForm.attr('action'),
						method: 'POST',
						data: JSON.stringify(this.$contactForm.serialize())
					},
					(data) => {
						if (!data.success) {
							this._errorCallback();
						} else {
							this._successCallback();
						}
					},
					this._errorCallback.bind(this)
				);
			}
			return false;
		},

		_successCallback: function _successCallback() {
			this.$contactForm.addClass('h-hidden');
			this.$errorText.addClass('h-hidden');
			this.$successText.removeClass('h-hidden');
		},

		_errorCallback: function _errorCallback() {
			this.$contactForm.addClass('h-hidden');
			this.$successText.addClass('h-hidden');
			this.$errorText.removeClass('h-hidden');
		}
	});
}(jQuery));
