'use strict';
(function ($) {
	/**
	 * EcotestSearch module implementation.
	 *
	 * @author Alex Kröll <alexander.kroell@namics.com>
	 * @namespace T.Module
	 * @class EcotestSearch
	 * @extends T.Module
	 */
	T.Module.EcotestSearch = T.createModule({
		SESSIONKEY_SEARCHPARAMS: "Application.EcotestSearch.Params",
		_upToDateFld: 'input[name="models2"]',
		_modelFld: 'select[name="model"]',
		_brandFld: 'select[name="brand"]',
		_co2Fld: 'select[name="co2"]',
		_pollutantClassFld: 'select[name="schadstoff"]',
		_vehicleTypeFld: 'input[name="vehicles"]',
		_vehicleClassFld: 'input[name="vehiclesClass"]',
		_engineTypeFld: 'input[name="motorart"]',
		_ecostarFld: '.js-ecostars-input',
		_tableContainer: '.js-tableContainer',
		_mCounter: '.m-counter',
		_ratingPoints: '.js-rating-points',

		start: function start(resolve) {
			T.Utils.Application.init();

			this.$ctx = $(this._ctx);
			this.$tableTemplate = $(this.$ctx.find('#js-table-template').html());
			this.$rowTemplate = $(this.$ctx.find('#js-row-template').html());
			this.$filterContainer = this.$ctx.find('.js-filterContainer');
			this.$resultContainer = this.$ctx.find('.js-resultContainer');
			this.$resultForm = this.$ctx.find('.js-resultForm');
			this.$noResult = this.$ctx.find('.js-noresult');
			this.$noBackend = this.$ctx.find(".js-nobackend");
			this.$loadMore = this.$ctx.find('.js-load-more');

			this._checkboxIdCount = 0;
			this._pageNumber = 0;
			this._resultAmountPerRequest = 200;
			this._addListeners();
			this._readConfig();
			this._loadStoredSearch();
			resolve();
		},

		_addListeners: function _addListeners() {
			this.$ctx.find('form.js-submit').submit(this._submitSearch.bind(this));
			this.$ctx.find('.js-submit-search').on('click', this._submitSearch.bind(this));
			this.$ctx.find('.js-reset-search').on('click', this._resetSearch.bind(this));

			this.$loadMore.on('click', this._loadMoreResults.bind(this));
			this.$ctx.find(this._brandFld).on('change', this._handleBrandChange.bind(this));
			this.$ctx.find(this._upToDateFld).on('change', this._handleBrandChange.bind(this));
			this.$ctx.find(this._modelFld).on('change', this._clearResultContainer.bind(this));
		},

		_clearResultContainer: function _clearResultContainer() {
			this.$resultContainer.find(this._tableContainer).empty();
			this.$resultContainer.toggleClass('h-hidden', true);
		},
		_readConfig: function _readConfig() {
			this._config = this.$ctx.data('config');

		},
		// eslint-disable-next-line sonarjs/cognitive-complexity
		_loadStoredSearch: function _loadStoredSearch() {
			const storedSearch = T.Utils.Store.get(this.SESSIONKEY_SEARCHPARAMS, T.Utils.Store.SESSION);
			if (storedSearch) {
				const loadRemaining = () => {
					this._setDropdownVal(this._co2Fld, storedSearch.Co2Ausstoss || "0");
					this._setDropdownVal(this._pollutantClassFld, storedSearch.Steuerklasse);
					this._setStarBoxVal(this._ecostarFld, storedSearch.EcoTestSterne);

					this._setDropdownVal(this._modelFld, storedSearch.ModelReihe);
					this._setCheckArrayVals(this._vehicleTypeFld, storedSearch.FahrzeugAufbau);
					this._setCheckArrayVals(this._vehicleClassFld, storedSearch.FahrzeugKlasse);
					this._setCheckArrayVals(this._engineTypeFld, storedSearch.Motorart);
					if ((storedSearch.Hersteller && "Alle" !== storedSearch.Hersteller)
						|| (storedSearch.ModelReihe && "Alle" !== storedSearch.ModelReihe)
						|| (storedSearch.FahrzeugAufbau && storedSearch.FahrzeugAufbau.length)
						|| (storedSearch.FahrzeugKlasse && storedSearch.FahrzeugKlasse.length)
						|| (storedSearch.Motorart && storedSearch.Motorart.length)) {
						this.$ctx.find('.js-head-vehicledata').toggleClass('mm-opened is-opened', true);
					}
					if (storedSearch.SeitenSteuerung && storedSearch.SeitenSteuerung.Seite) {
						this._preloadPage = storedSearch.SeitenSteuerung.Seite;
					}
					this._submitSearch();
				};

				this.$ctx.find(this._upToDateFld).prop("checked", !storedSearch.AlleModelle);
				if (storedSearch.Hersteller && "Alle" !== storedSearch.Hersteller) {
					this._setDropdownVal(this._brandFld, storedSearch.Hersteller);
					this._requestModels((data) => {
						this._handleSuccessModelsRequest(data);
						loadRemaining();
					}, this._handleErrorModelsRequest.bind(this));
				} else {
					loadRemaining();
				}
			}
		},
		_requestModels: function _requestModels(successCallback, errorCallback) {
			T.Utils.View.startLoader();
			const payload = {
				Hersteller: this._getDropdownVal(this._brandFld),
				AlleModelle: !this.$ctx.find(this._upToDateFld).is(':checked')
			};
			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiModelsRequest),
				data: payload
			};
			T.Utils.Ajax.fragment(request, successCallback, errorCallback);
		},
		_handleBrandChange: function _handleBrandChange() {
			this._requestModels(this._handleSuccessModelsRequest.bind(this), this._handleErrorModelsRequest.bind(this));
		},
		_handleSuccessModelsRequest: function _handleSuccessModelsRequest(data) {
			try {
				this._clearErrors();
				if (data && data.Data) {
					this._loadModelDropdown(data);
				}
			} finally {
				T.Utils.View.stopLoader();
			}
		},

		_handleErrorModelsRequest: function _handleErrorModelsRequest(error) {
			this._classifyError(error);
			T.Utils.View.stopLoader();
		},

		_loadModelDropdown: function _loadModelDropdown(data) {
			this._clearDropdown(this._modelFld);

			const $element = this.$ctx.find(this._modelFld);

			//if "Alle Hersteller" is selected, display just a single option "Alle Modelle" in model field
			const placeholder = $element.attr('data-placeholder');
			if (!data.Data || "Alle" === this._getDropdownVal(this._brandFld)) {
				$element.append($('<option/>').attr('value', '').text(placeholder)).attr('selected', true);
			}
			//if not Alle Hersteller, "Alle" option from Apil will be displayed as "Alle Modelle"
			else {
				for (const key in data.Data) {
					$element.append($('<option/>').attr('value', key).text("Alle" === key ? placeholder : data.Data[key]));
				}
			}
		},

		_requestResults: function _requestResults() {
			T.Utils.View.startLoader();

			const payload = {
				AlleModelle: !this._isBoxChecked(this._upToDateFld),
				EcoTestSterne: this._getStarBoxVal(this._ecostarFld),
				Co2Ausstoss: this._getDropdownVal(this._co2Fld),
				Steuerklasse: this._getDropdownVal(this._pollutantClassFld),
				Hersteller: this._getDropdownVal(this._brandFld),
				ModelReihe: this._getDropdownVal(this._modelFld),

				FahrzeugAufbau: this._getCheckArrayVals(this._vehicleTypeFld),
				FahrzeugKlasse: this._getCheckArrayVals(this._vehicleClassFld),
				Motorart: this._getCheckArrayVals(this._engineTypeFld),
				"SortierSteuerung.Sortierung": 'EcotestVersion',
				"SortierSteuerung.SortierungAbsteigend": true,
				"SeitenSteuerung.Anzahl": this._resultAmountPerRequest,
				"SeitenSteuerung.Seite": this._pageNumber
			};
			T.Utils.Store.set(this.SESSIONKEY_SEARCHPARAMS, payload, T.Utils.Store.SESSION);

			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiSearchRequest),
				data: payload
			};
			T.Utils.Ajax.fragment(request, this._handleSuccessResultsRequest.bind(this), this._handleErrorResultsRequest.bind(this));
		},

		_handleSuccessResultsRequest: function _handleSuccessResultsRequest(data) {
			try {
				this._clearErrors();
				this.$resultContainer.find(this._mCounter).empty();

				if (data && data.Data) {

					let previousVersion = data.Data.Ergebnisse[0].TestObjekt.Datenblatt.EcotestVersion;
					let $table = this._getTable(this._pageNumber);


					data.Data.Ergebnisse.forEach((item) => {

						const version = item.TestObjekt.Datenblatt.EcotestVersion;
						if (version !== previousVersion) {
							this.$resultForm.find(this._tableContainer).append($table);

							$table = this.$tableTemplate.clone();
							previousVersion = item.TestObjekt.Datenblatt.EcotestVersion;
						}

						$table.find('h3').html(this._config.headlines[version - 1]);
						this._createNewRow(item, this._checkboxIdCount, $table);
						this._checkboxIdCount++;
					});
					this.$resultForm.find(this._tableContainer).append($table);
					this.$resultContainer.find(this._mCounter).html(`(${data.Data.SeitenDaten.AnzahlErgebnisse})`);
					T.Utils.Helper.initAdditionalModules(this.$ctx);
					this._toggleLoadMoreButton(data.Data.SeitenDaten.AnzahlSeiten);

				}

				this.$resultForm.toggleClass('h-hidden', false);

				this._pageNumber++;
				if (this._preloadPage && this._pageNumber <= this._preloadPage) {
					this._requestResults();
				}

			} finally {
				T.Utils.View.stopLoader();
			}

		},
		_handleErrorResultsRequest: function _handleErrorResultsRequest(error) {
			this._classifyError(error);
			this.$resultContainer.find(this._mCounter).empty();
			T.Utils.View.stopLoader();
		},
		_toggleLoadMoreButton: function _toggleLoadMoreButton(totalPages) {
			if ((totalPages - 1) === this._pageNumber) {
				this.$loadMore.toggleClass('h-hidden', true);
			}
			else {
				this.$loadMore.toggleClass('h-hidden', false);
			}
		},
		_getTable: function _getTable(page) {
			if (page > 0) {
				return this.$resultForm.find(this._tableContainer).last('table');
			} else {
				return this.$tableTemplate.clone();
			}
		},

		_createNewRow: function _createNewRow(item, checkboxIdCount, $table) {
			const $clone = this.$rowTemplate.clone();

			$clone.find('.a-basic-input-checkbox input').prop('id', `checkbox_${checkboxIdCount}`);

			const $img = $clone.find('img');
			const itemName = `${item.TestObjekt.Hersteller} ${item.TestObjekt.Modell}`;
			$img.attr('src', T.Utils.Helper.appendURIPath(this._config.imageBasePath, item.TestObjekt.Datenblatt.Bildname));

			$img.attr('title', itemName);
			$img.after(itemName);

			const $ecoTestRating = $clone.find('.m-basic-rating');
			$ecoTestRating.data('rating', item.TestObjekt.EcoRatingStar);

			const hitcount = $ecoTestRating.find(this._ratingPoints).text();
			$ecoTestRating.find(this._ratingPoints).empty();
			$ecoTestRating.find(this._ratingPoints).append(`(${item.TestObjekt.EcoRatingPoints} ${hitcount})`);


			const $tbody = $table.find('tbody');

			const detailsPageUrl = this._config.detailsPageUrl.interpolate({
				id: item.Id,
				name: T.Utils.Helper.createSeoUrl(item.TestObjekt.Fahrzeug)
			});
			$clone.find(".js-details").attr("href", detailsPageUrl);


			$tbody.append($clone);


		},
		_clearErrors: function _clearErrors() {
			this.$noBackend.toggleClass('h-hidden', true);
			this.$noResult.toggleClass('h-hidden', true);
			this.$noBackend.toggleClass('h-hidden', true);
		},

		_getDropdownVal: function _getDropdownVal(selector) {
			const result = this.$ctx.find(selector).val();
			if ("0" !== `${result}`) {
				return result;
			}
			return null;
		},
		_setDropdownVal: function _setDropdownVal(selector, val) {
			const $elm = this.$ctx.find(selector);
			$elm.val(val);
		},
		_clearDropdown: function _clearDropdown(selector) {
			const $elm = this.$ctx.find(`${selector} option`);
			$elm.remove();
		},
		_submitSearch: function _submitSearch(evt) {
			if (evt) {
				evt.preventDefault();
			}
			this._pageNumber = 0;
			this.$resultContainer.toggleClass('h-hidden', false);
			this.$ctx.find(this._tableContainer).empty();
			this._requestResults();
			return false;
		},
		_resetSearch: function _resetSearch(e) {
			if (e) {
				e.preventDefault();
			}
			T.Utils.Store.set(this.SESSIONKEY_SEARCHPARAMS, null, T.Utils.Store.SESSION);
			window.location.reload();
		},

		_loadMoreResults: function _loadMoreResults(evt) {
			if (evt) {
				evt.preventDefault();
			}
			this._requestResults();
			return false;
		},

		_getCheckArrayVals: function _getCheckArrayVals(selector) {
			const resultList = [];
			const $items = this.$ctx.find(`${selector}:checked`);
			$items.each((index, item) => {
				resultList.push(item.value);
			});
			return resultList;
		},
		_setCheckArrayVals: function _setCheckArrayVals(selector, values) {
			const $checkboxes = this.$ctx.find(selector);
			$checkboxes.prop("checked", false);
			(Array.isArray(values) ? values : (`${values}`).split(",")).forEach((val) => {
				$checkboxes.filter(`[value="${val}"]`).prop("checked", true);
			});
		},
		_isBoxChecked: function _isBoxChecked(selector) {
			return this.$ctx.find(selector).is(':checked');
		},
		_getStarBoxVal: function _getStarBoxVal(selector) {
			return this.$ctx.find(`${selector} .mm-star--full`).length;
		},
		_setStarBoxVal: function _setStarBoxVal(selector, val) {
			const $stars = this.$ctx.find(`${selector} .mm-star`);
			$stars.toggleClass('mm-star--full', false);
			for (let i = 0; i < val && i < $stars.length; i++) {
				$($stars[i]).toggleClass('mm-star--full', true);
			}
		},
		_classifyError: function _classifyError(error) {

			//if pagenumber is 0, there's no results for search at all.

			if (this._pageNumber === 0) {
				//no result
				if (error && error.responseJSON) {
					this.$noResult.toggleClass('h-hidden', false);
					this.$resultForm.toggleClass('h-hidden', true);
					this.$resultContainer.find(this._ratingPoints).empty();

				}
				//no backend
				else {

					this.$noBackend.toggleClass('h-hidden', false);
				}
			}
		}

	});
}(jQuery));