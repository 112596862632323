(function($) { // eslint-disable-line
	'use strict';
	/**
	 * BasicTeaserBox module implementation.
	 *
	 * @author Nicole Wewior <n.wewior@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicTeaserBox
	 * @extends T.Module
	 */
	T.Module.BasicTeaserBox = T.createModule({
		start: function(resolve) {
			resolve();
		}
	});
}(jQuery));
