(function($) {
	'use strict';

	/**
	 * VesRsSticky module implementation.
	 *
	 * @author Niclas Bork <n.bork@edelweiss72.de>
	 * @namespace T.Module
	 * @class VesRsSticky
	 * @extends T.Module
	 */
	T.Module.VesRsSticky = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $nav: null,

        /** @type {Object[]} */
        $llMainCenterLast: null,

        /** @type {Object[]} */
        $navList: null,

        /** @type {Object[]} */
        $navBtn: null,

        /** @type {jQuery} */
        $content: null,

        /** @type {Object[]} */
        $tabcontents: null,

        /** @type {Number} */
        stickyThresholdTop: 150,

        /** @type {Number} */
        stickyThresholdBottom: 120,

		start: function(resolve) {
            this.$ctx = $(this._ctx);
            this.$nav = this.$ctx.find('.m-nav').eq(0);
            this.$navList = this.$nav.find('> li');
            this.$navBtn = this.$nav.find('a.mm-nav-link');
            this.$tabcontentWrap = this.$ctx.find('.m-tabcontent');
            this.$tabcontents = this.$ctx.find('.m-tabcontent-inside');
            this.$llMainCenterLast = $('body > .l-outer > .l-main > .ll-main-center').eq(-1);

            this.btnDetails = '.a-basic-btn--details';
            this.btnContact = '.mm-btn-contact';

            this.setNavBtnEvents();

            this.showDetails();

            this.showContact();

            this.close();

            this.addScrollEvents();

            this.checkApproval();

            this.resize();

            // check for social sharing element
            if ( this.$llMainCenterLast.find('.m-basic-socialshare').length) {
                this.$ctx.addClass('is-social-sharing');
                this.$llMainCenterLast.addClass('is-social-sharing');
            }

            this.$llMainCenterLast.addClass('ll-main-center--vesrsgap');

            resolve();
		},

        /**
         * setNavBtnEvents
         */
        setNavBtnEvents: function () {
            const that = this;

            this.$navBtn.on('click touch', (e) => {
                e.preventDefault();

                const activeContentId = that.$ctx.find('li.is-active > a').attr('href'),
                    newTab = $(e.currentTarget),
                    newId = newTab.attr('href');

                if (newId !== activeContentId){

                    // reset active class
                    that.$navList.removeClass('is-active');

                    // set active
                    newTab.closest('li').addClass('is-active');

                    // hide actual content
                    that.$ctx.find(activeContentId).hide();

                    // show new content
                    that.$ctx.find(newId).removeClass('h-visuallyhidden').show();
                }

                return false;
            });
        },

        /**
         * show details
         */
        showDetails: function () {
            this.$ctx.find(this.btnDetails).on('click', (e) => {
                e.preventDefault();
                // doesnt work...
                // $('body').toggleClass('m-layer-open');
                this.$ctx.find(this.btnDetails).toggleClass('is-opened');
                this.$ctx.find('.mm-layer-details').toggleClass('is-open');

                this.setDistance();
            });
        },
        /**
         * show contact
         */
        showContact: function () {
            this.$ctx.find(this.btnContact).on('click', (e) => {
                e.preventDefault();
                this.$ctx.find(this.btnContact).toggleClass('is-opened');
                this.$ctx.find('.mm-layer-contact').toggleClass('is-open');

                this.setDistance();
            });
        },
        /**
         * close layer
         */
        close: function () {
            this.$ctx.find('.mm-close-icon').on('click', (e) => {
                e.preventDefault();
                this.$ctx.find(this.btnContact).removeClass('is-opened');
                this.$ctx.find('.mm-layer-contact').removeClass('is-open');
                this.$ctx.find(this.btnDetails).removeClass('is-opened');
                this.$ctx.find('.mm-layer-details').removeClass('is-open');
            });
        },

        /**
         * toggle sticky or fixed state depending on scroll pos
         */
        addScrollEvents: function () {

            const that = this;

            const callStickyLogic = () => {

                S.Utils.delayed('vesrssticky.scrollDelay', 20, () => {

                    // toggleStickyness
                    const footerRectClient = $('.l-footer')[0].getBoundingClientRect();

                    // footers top boundary is right at bottom viewport
                    if ( footerRectClient.top <= $(window).height() ) {
                        that.$ctx.addClass('is-stuck-bottom is-visible');
                    }

                    else {
                        that.$ctx.removeClass('is-stuck-bottom');
                    }

                    // toggle visibility
                    if ( $(document).scrollTop() > that.stickyThresholdTop ) {
                        that.$ctx.addClass('is-visible');
                    }

                    else if ( $(document).height() - that.stickyThresholdBottom > $(window).height() && !that.$ctx.hasClass('is-stuck-bottom') ) {
                        that.$ctx.removeClass('is-visible');
                    }

                });
            };

            S.Utils.delayed('vesrssticky.initDelay', 100, () => {

                document.removeEventListener('scroll',  callStickyLogic,  false);
                document.addEventListener('scroll',  callStickyLogic,  { passive: true });

                callStickyLogic();
            });
        },

        /**
		 * set dynamic position
         */
        setDistance: function () {
            const elementHeight = this.$ctx.outerHeight();

            this.$ctx.find('.mm-layer').css({
				'bottom': elementHeight,
			});
		},

        /**
         * check approval
         */
        checkApproval: function () {
            const input = $('.ll-approval'),
                formBtn = $('.l-form--emailoffer .a-basic-btn');

            $(input).on('change', function() {
                if(this.checked) {
                    $(formBtn).html('Absenden und Speichern');
                } else {
                    $(formBtn).html('Absenden');
                }
            });
        },

        /**
         * calculate position on resize
         */
        resize: function () {
            $(window).on('resize_vesrs-sticky', () => {
                S.Utils.delayed('vesrssticky.resizeDelay', 40, () => {
                    this.setDistance();
                    this.addScrollEvents();
                });
            });
        },
	});
}(jQuery));
