/* eslint-disable */
(function ($) {

	'use strict';
	/**
	 * Cardamage-JS
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 */

	S.Calculator = S.Calculator || {};

	S.Calculator.Cardamage = {

		/** @type {Object} */
		$inputBrand: null,

		/** @type {String} */
		inputBrandValue: '',

		/** @type {Object} */
		$inputConstDate: null,

		/** @type {String} */
		inputConstDateValue: '',

		/** @type {Object} */
		$inputVehicleModel: null,

		/** @type {Object} */
		$inputConstDateLabel: null,

		/** @type {Object} */
		$inputVehicleModelLabel: null,

		/** @type {Object} */
		$vehicleTypes: null,

		/** @type {Object} */
		$vehicleDetails: null,

		/** @type {Object} */
		$inputTypeMisc: null,

		/** @type {Object} */
		$timeBegin: null,

		/** @type {Object} */
		$timeEnd: null,

		/** @type {Number} */
		timeBeginDelta: null,

		/** @type {Number} */
		timeEndDelta: null,

		/**
		 * init
		 *
		 */
		init: function () {

			this.$ctx = $(this._ctx);

			// vehicle types
			this.$vehicleTypes = $('#typeCar, #typeBike, #typeMisc');
			this.$inputTypeMisc = this.$vehicleTypes.filter('#typeMisc');

			// vehicle details
			this.$vehicleDetails = $('#brand, #constDate, #vehicleModel');
			this.$inputBrand = this.$vehicleDetails.filter('#brand');
			this.$inputConstDate = this.$vehicleDetails.filter('#constDate');
			this.$inputVehicleModel = this.$vehicleDetails.filter('#vehicleModel');

			this.$inputConstDateLabel = this.$inputConstDate.parents('.ll-col-data').prev('.ll-col-label');
			this.$inputVehicleModelLabel = this.$inputVehicleModel.parents('.ll-col-data').prev('.ll-col-label');

			//timing stuff
			this.$timeBegin = $('#timeBegin');
			this.$timeEnd = $('#timeEnd');

			this.isDisabled = 'is-disabled';
			this.jsClearInputTextClear = 'js-clear a-basic-input-text--clear';
			this.jsClearButton = '.js-clear-button';

			this.addInputListeners();
			this.toggleInputs('init');

		},

		/**
		 * add listeners to inputs
		 *
		 */
		addInputListeners: function () {

			const that = this;

			that.$inputBrand.on('change mouseout', (e) => {
				that.toggleInputs('brand', e.target, e.type);
			});

			that.$inputConstDate.on('change mouseout', (e) => {
				that.toggleInputs('constDate', e.target, e.type);
			});

			that.$vehicleTypes.each((index, item) => {
				$(item).on('change', () => {
					that.toggleInputs('vehicleType', item, undefined);
				});
			});

			// cardamage timing
			if (this.$timeBegin.length && this.$timeEnd.length) {

				this.timeBeginDelta = 2;
				this.timeEndDelta = 6;

				if (this.$timeBegin.hasClass('js-foreignTiming')) {
					this.timeBeginDelta = 3;
					this.timeEndDelta = 8;
				}

				that.$timeBegin.on('change', (e) => {
					that.toggleInputs('timing', e.target, undefined);
				});

				that.$timeBegin.on('click', (e) => {
					that.toggleInputs('timing', e.target, undefined);
				});
			}
		},

		/**
		 * enable/disable dependent inputs
		 *
		 * @param mod - context of input change
		 *
		 * @param clickedItem (optional)
		 *
		 * @param eType(optional) - eventType
		 *
		 */
		// eslint-disable-next-line sonarjs/cognitive-complexity, complexity
		toggleInputs: function (mod, clickedItem, eType) { //NOSONAR

			const that = this;

			if (mod === 'init') {

				// vehicle details: disable if brand was not set onInit
				if (!that.$inputBrand.val() && !that.$inputTypeMisc.is(':checked')) {
					that.disableVehicleDetails();
				}

				// timing: disable timeEnd onInit until valid timeBegin is selected
				const timeValBegin = that.$timeBegin.find('option:selected').data('js-time');
				if (typeof timeValBegin === typeof 'undefined' || timeValBegin === false) {
					that.$timeEnd.prop('disabled', true);
				}
			}

			else {

				if (mod === 'brand') {

					// enable constDate
					if (that.$inputBrand.val()) {

						that.$inputConstDate.prop('disabled', false);
						that.$inputConstDateLabel.removeClass(this.isDisabled);

						// check if value changed and reset inputs
						if (eType === 'change' && that.inputBrandValue !== that.$inputBrand.val() && !that.$inputTypeMisc.is(':checked')) {

							// save current val
							that.inputBrandValue = that.$inputBrand.val();

							// reset input values
							that.$inputConstDate.val('').removeClass(this.jsClearInputTextClear);
							that.$inputVehicleModel.val('').removeClass(this.jsClearInputTextClear);

							that.$inputConstDate.siblings(this.jsClearButton).remove();
							that.$inputVehicleModel.siblings(this.jsClearButton).remove();

							that.$inputVehicleModel.prop('disabled', true);

						}
					}

					// disable only on change;
					// eslint-disable-next-line sonarjs/no-collapsible-if
					else if (eType !== 'mouseout') {

						if (!that.$inputTypeMisc.is(':checked')) {
							that.disableVehicleDetails();
						}
					}
				}

				else if (mod === 'constDate') {

					// enable model
					if (that.$inputConstDate.val()) {
						that.$inputVehicleModel.prop('disabled', false);
						that.$inputVehicleModelLabel.removeClass(this.isDisabled);

						// check if value changed and reset inputs
						if (eType === 'change' && that.inputConstDateValue !== that.$inputConstDate.val() && !that.$inputTypeMisc.is(':checked')) {

							// save current val
							that.inputConstDateValue = that.$inputConstDate.val();

							// reset input value
							that.$inputVehicleModel.val('').removeClass(this.jsClearInputTextClear);
							that.$inputVehicleModel.siblings(this.jsClearButton).remove();
						}
					}

					// disable model only on change;
					// eslint-disable-next-line sonarjs/no-collapsible-if
					else if (eType !== 'mouseout') {
						if (!that.$inputTypeMisc.is(':checked')) {

							that.$inputVehicleModel.val('').prop('disabled', true);
							that.$inputVehicleModel.removeClass(this.jsClearInputTextClear);
							that.$inputVehicleModel.siblings(this.jsClearButton).remove();

							if (!that.$inputVehicleModelLabel.hasClass(this.isDisabled)) {
								that.$inputVehicleModelLabel.addClass(this.isDisabled);
							}
						}
					}
				}

				else if (mod === 'vehicleType') {

					// Car or Bike is checked
					if ($(clickedItem).is('#typeCar') || $(clickedItem).is('#typeBike')) {

						// was Misc checked before?
						if (that.$inputTypeMisc.data('js-active') === true) {
							// remove attr and check if vehicleDetails are empty
							that.$inputTypeMisc.data('js-active', false);

							let checkCount = 0;

							that.$vehicleDetails.each((index, item) => {
								if ($(item).val() !== '') {
									checkCount++;
								}
							}).promise().done(() => {
								// clear and disable if every input is empty. otherwise do nothing;
								if (checkCount === 0) {
									that.disableVehicleDetails();
								}
							});
						}

						// no
						else {
							that.disableVehicleDetails();
						}
					}

					// add attr to misc to mark input as (currently) selected and enable all inputs
					else {
						$(clickedItem).data('js-active', true);

						// enable inputs
						that.$inputVehicleModel.prop('disabled', false);
						that.$inputVehicleModelLabel.removeClass(this.isDisabled);
						that.$inputConstDate.prop('disabled', false);
						that.$inputConstDateLabel.removeClass(this.isDisabled);
					}
				}

				else if (mod === 'timing') {

					let timeValBegin = that.$timeBegin.find('option:selected').data('js-time');
					let timeValBeginNew = that.$timeBegin.find('.is-active').eq(0).data('js-time');
					const timeEndValue = that.$timeEnd.parent().parent().parent().parent().find('.aa-btn-selection');


					if (typeof timeValBegin !== 'undefined' && timeValBegin !== false || typeof timeValBeginNew !== 'undefined' && timeValBeginNew !== false) {

						timeValBegin = parseFloat(timeValBegin);
						timeValBeginNew = parseFloat(timeValBeginNew);

						if (that.$timeEnd.prop('disabled')) {
							that.$timeEnd.prop('disabled', false);
						}

						that.$timeEnd.find('li').each((index, item) => {

							const itemTime = parseFloat($(item).data('js-time'));

							// disable timeEnd-options smaller than timeBegin +timeBeginDelta or bigger +timeEndDelta
							if (itemTime < (timeValBeginNew + that.timeBeginDelta) || itemTime > (timeValBeginNew + that.timeEndDelta)) {

								if (!$(item).hasClass(this.isDisabled)) {
									$(item).addClass(this.isDisabled);
								}

								if ($(item).hasClass('is-active')) {
									$(item).removeClass('is-active');
								}
							}
							else {
								if ($(item).hasClass(this.isDisabled)) {
									$(item).removeClass(this.isDisabled);
								}

								// set timeEnd to timeBegin + 2h
								if (itemTime === (timeValBeginNew + that.timeBeginDelta)) {
									timeEndValue.text($(item).text());
									$(item).closest('.ll-row').addClass('is-filled');
								}
							}

						});

						that.$timeEnd.find('option').each((index, item) => {

							const itemTime = parseFloat($(item).data('js-time'));

							// disable timeEnd-options smaller than timeBegin +timeBeginDelta or bigger +timeEndDelta
							if (itemTime < (timeValBegin + that.timeBeginDelta) || itemTime > (timeValBegin + that.timeEndDelta)) {

								if (!$(item).prop('disabled')) {
									$(item).prop('disabled', true);
								}
							}

							else {
								if ($(item).prop('disabled')) {
									$(item).prop('disabled', false);
								}

								// set timeEnd to timeBegin + 2h
								if (itemTime === (timeValBegin + that.timeBeginDelta)) {
									that.$timeEnd.val($(item).val());
								}
							}
						});
					}

					// reset to default and disable input
					else {

						// let to check if reset was already done
						let detectCount = 0;

						that.$timeEnd.prop('disabled', true).find('option').each((index, item) => {

							if ($(item).prop('selected')) {
								$(item).prop('selected', false);
							}

							// reset
							if (detectCount === 0) {
								const itemTime = $(item).data('js-time');

								if (typeof itemTime === typeof undefined || !itemTime) {
									that.$timeEnd.val($(item).val());
									detectCount++;
								}
							}
						});
					}
				}
			}
		},

		/**
		 * disabled vehicle details
		 */
		disableVehicleDetails: function () {

			const that = this;

			that.$vehicleDetails.each((index, item) => {

				$(item).val('');
				$(item).removeClass(this.jsClearInputTextClear);
				$(item).siblings(this.jsClearButton).remove();

			}).promise().done(() => {
				that.$inputConstDate.prop('disabled', true);
				that.$inputVehicleModel.prop('disabled', true);

				// add disabled class to corresponding labels
				if (!that.$inputConstDateLabel.hasClass(this.isDisabled)) {
					that.$inputConstDateLabel.addClass(this.isDisabled);
				}

				if (!that.$inputVehicleModelLabel.hasClass(this.isDisabled)) {
					that.$inputVehicleModelLabel.addClass(this.isDisabled);
				}
			});
		}
	};
	$(document).ready(function () {
		if ($('#calculatorCardamage').length) {
			S.Calculator.Cardamage.init();
		}
	});

})(jQuery);
