'use strict';
/* eslint-disable sonarjs/cognitive-complexity */
(function ($) {

	T.Utils = T.Utils || {};

	T.Utils.Validation = {

		emailPattern: /^(("[a-zA-Z0-9!"#$%&'*+\-./=?\\^_`{|}~]+?([^\\]")@)|([a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*?@))((\[(\d{1,3}\.){3}\d{1,3}\])|([a-zA-Z0-9-]([a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]([a-zA-Z]*[a-zA-Z])+)$/, //NOSONAR

		_messages: {
			"date": "Bitte geben Sie ein korrektes Datum ein",
			"digits": "Bitte nur Ziffern eingeben.",
			"email": "Bitte geben Sie eine valide E-Mail Adresse ein.",
			"maxlength": "Bitte maximal {0} Zeichen",
			"minlength": "Bitte mindestens {0} Zeichen",
			"pattern": "Dieses Feld muss korrekt gefüllt sein! Bitte prüfen Sie Ihre Eingabe!",
			"phonecross": "Bitte geben Sie eine Vorwahl und eine Durchwahl ein.",
			"customiban": "Bitte geben Sie eine gültige IBAN an.",
			"required": "Dieses Feld muss korrekt gefüllt sein! Bitte prüfen Sie Ihre Eingabe!"
		},

		_rules: {},

		init: function ($ctx, forms, validationOptions) {
			this._app = {
				$ctx: $ctx ? $ctx : $('body')
			};
			this._forms = forms ? forms : [];
			this._validationOptions = validationOptions ? validationOptions : {};

			this._setupFormValidation();
		},

		/**
		 * _validateForm.
		 *
		 * function to handle the client-side validation. The validation messages of the options
		 * are extended and the event handlers are initialized.
		 *
		 */

		_setupFormValidation: function () {
			this._removeErrorMessages();
			this._addCustomMethods();
			this._editValidationConfig();
			this._initValidation();
			this._addValidationMessages();

			if (this._cb) {
				this._cb();
			}
		},

		_removeErrorMessages: function () {
			const $ctx = $(this._app.$ctx);

			$ctx.find('.ll-error-msg').text('');
		},

		_editValidationConfig: function () {
			$.extend($.validator.rules, this._rules);
			$.extend($.validator.messages, this._messages);
		},

		_initValidation: function () {
			$(this._forms).each((index, element) => {
				element.validation = $(element).validate({
					errorClass: 'is-error',
					focusInvalid: false,
					invalidHandler: function (form, validator) {
						if (!validator.numberOfInvalids()) {
							return;
						}

						$('html, body').animate({
							scrollTop: $(validator.errorList[0].element).offset().top - 80
						}, 800);
					},
					errorPlacement: function (error, element) {
						const $element = $(element);

						$(element).parents('.js-row').removeClass('is-valid').addClass('is-error');

						const $parent = $element.parents('.ll-row').first();
						const errorElement = $parent.find('.ll-error-msg');

						if (!$element.is('[type=radio], [type=checkbox]')) {
							$parent.addClass('is-check');
						}

						if (errorElement.is(':visible')) {
							errorElement.text(error[0].innerHTML);
						} else {
							if (element.prev('input').is(':visible')) {
								$('<div class="ll-error-msg">').text(error[0].innerHTML).insertBefore(element.prev());
							} else {
								element = $(element).parent();
								$('<div class="ll-error-msg">').text(error[0].innerHTML).insertBefore(element);
							}
						}
					},
					onkeyup: false,
					onfocusout: this._decorateElement.bind(this),
					onfocusin: this._undecorateElement.bind(this),
					onclick: false
				});
			});
		},

		_undecorateElement: function _undecorateElement(element, event) {
			if (!event || 13 !== event.keyCode) {
				const $elm = $(element);
				if (this._isButton($elm)) {
					return;
				}
				$elm.removeClass('is-error');
				const $parent = $elm.parents('.ll-row');
				$parent.removeClass('is-error');
				this._addCheckClass(element);
			}
		},

		_decorateElement: function _decorateElement(element) {
			const $elm = $(element);
			if (this._isButton($elm)) {
				return;
			}
			const parent = $elm.parents('.ll-row');

			if ($elm.valid()) {
				parent.removeClass('is-error').addClass('is-valid');
				this._addCheckClass(element);
			} else {
				parent.removeClass('is-valid').addClass('is-error');
				this._addCheckClass(element);
			}
		},

		_addCheckClass: function _addCheckClass(element) {
			if (!$(element).is('[type=radio], [type=checkbox]')) {
				$(element).parents('.ll-row').addClass('is-check');
			}
		},

		_isButton: function _isButton(element) {
			const $elm = $(element);
			return 'BUTTON' === $elm.prop("tagName") || 'submit' === $elm.attr("type");
		},

		/**
		 * _addValidationMessages.
		 *
		 * set validation messages
		 *
		 */

		_addValidationMessages: function () {
			const self = this,
				$ctx = $(this._app.$ctx);

			$ctx.find('input,textarea,select').each(function (key, item) {
				if (self._validationOptions.rules && typeof self._validationOptions.rules[item.name] !== 'undefined') {
					$ctx.find(`[name="${item.name}"]`).each(function () {
						self._validationOptions.rules[item.name].messages = self._validationOptions.messages[item.name];
						$(this).rules('add', self._validationOptions.rules[item.name]);
					});
				}
			});
			// revalidate datepicker fields on change
			$ctx.find('.a-basic-input-text--calendar input').on("change", function () {
				$(self._forms)[0].validation.element($(this));
				self._decorateElement(this);
			});
		},

		addValidationForElement($element, rule) {
			$element.rules('add', rule);
		},

		removeValidationForElement($element) {
			$element.rules('remove');
		},

		/**
		 * _addCustomMethods.
		 *
		 * add custom validation methods
		 *
		 */
		_addCustomMethods: function () {
			$.validator.addMethod('phonecross', function (value, element) {
				if (value.length === 0) {
					return true;
				}

				const name = $(element).attr('name'),
					$prefix = $(`input[name="${name.replace('Suffix', 'Prefix')}"]`),
					$suffix = $(`input[name="${name.replace('Prefix', 'Suffix')}"]`);

				if (name.indexOf('Prefix') !== -1) {
					if ($suffix.val().length > 0) {
						$prefix.removeClass('is-error');
						$suffix.removeClass('is-error');
					}
					return $suffix.val().length > 0;
				}
				if (name.indexOf('Suffix') !== -1) {
					if ($prefix.val().length > 0) {
						$prefix.removeClass('is-error');
						$suffix.removeClass('is-error');
					}
					return $prefix.val().length > 0;
				}

				return true;
			});

			$.validator.addMethod('streetnumbercross', function (value, element) {
				const name = $(element).attr('name'),
					$streetNo = $(`input[name="${name.replace('Strasse', 'Hausnummer')}"]`),
					$street = $(`input[name="${name.replace('Hausnummer', 'Strasse')}"]`);

				return !($street.val().length === 0 && $streetNo.val().length === 0);
			});

			$.validator.addMethod('requiredstreet', function (value) {
				return value.length !== 0;
			});


			$.validator.addMethod('requiredstreetnumber', function (value) {
				return value.length !== 0;
			});
			$.validator.addMethod('customdob', function (value) {
				return (/(0[1-9]|1\d|2\d|3[01])\.(0[1-9]|1[0-2])\.\d{4}/.test(value) || /\d{4}-(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])/.test(value));});

			$.validator.addMethod('custombegin', function (value) {
				return (/(0[1-9]|1\d|2\d|3[01]).(0[1-9]|1[012]).[0-9]{4}/.test(value) || /[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1\d|2\d|3[01])/.test(value));
			});
			$.validator.addMethod('email', function (value, element) {
				if ($(element).prop('required') || value.length > 0) {
					return T.Utils.Validation.emailPattern.test($.trim(value));
				}
				return true;
			});
			// workaround for datepicker values
			$.validator.addMethod('requireddate', function (value, element, params) {
				const rex = params && params.test ? params : /^(([1-9]|[0-2]\d|[3][0-1])\.([1-9]|[0]\d|[1][0-2])\.[2][0]\d{2})$|^(([1-9]|[0-2]\d|[3][0-1])\.([1-9]|[0]\d|[1][0-2])\.[2][0]\d{2})$/; //NOSONAR
				return (value && rex.test(value));
			});

		}
	};
})(jQuery);