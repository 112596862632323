(function($) {
	'use strict';
	/**
	 * RufRating module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class RufRating
	 * @extends T.Module
	 */
	T.Module.RufRating = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $mainContent: null,

        /** @type {jQuery} */
        $firstContent: null,

        /** @type {jQuery} */
        $secondContent: null,

        /** @type {jQuery} */
        $contentItems: null,

        /** @type {jQuery} */
        $selectItem: null,

        /** @type {jQuery} */
        $btnShowRatings: null,

        /** @type {jQuery} */
        $btnShowMoreRatings: null,

        /** @type {String} */
        ordermod: null,

		start: function(resolve) {
            this.$ctx = $( this._ctx );

            this.$btnShowRatings = this.$ctx.find('.show-ratings');
            this.$btnShowMoreRatings = this.$ctx.find('.all-ratings');

            this.jsOrdermod = 'js-ordermod';
            this.optionSelected = 'option:selected';

            const itemContainer = '.oo-item-container';

            // get main wrapper (holding 3 items)
            if ( this.$ctx.find('.oo-content-wrapper').length) {
                this.$mainContent = this.$ctx.find('.oo-content-wrapper');
                this.$contentItems = this.$mainContent.find('.oo-rating-item');
            }

            // get additional wrapper (holding every other item)
            if ( this.$mainContent.find(itemContainer).length) {
                this.$firstContent = this.$ctx.find(itemContainer).first();
                this.$secondContent = this.$ctx.find(itemContainer).last();
			}

			// find select for filtering
			if ( this.$mainContent.find('.oo-sort-wrapper > .a-basic-input-select').length ) {
				this.$selectItem = this.$mainContent.find('.oo-sort-wrapper > .a-basic-input-select');

                if ( this.$selectItem.find(this.optionSelected).data(this.jsOrdermod).length) {
                    this.ordermod = this.$selectItem.find(this.optionSelected).data(this.jsOrdermod);

                    this.initEvents('order');
                    this.orderItems('init');
                }
			}

            this.initEvents('init');

			resolve();
		},

		/**
		 * initEvents
		 *
		 * @param mod - defines special mod if select is present
         */
        initEvents: function (mod) {
			const that = this;

			if (mod === 'init') {

                /**
                 * Show more Ratings -> second Content
                 */

                that.$btnShowMoreRatings.on('click', function() {

                    that.$secondContent.slideToggle(function () {
                        if (that.$btnShowMoreRatings.hasClass('rating-open')) {
                            that.$secondContent.addClass('is-hidden');
                            that.$btnShowMoreRatings.toggleClass('rating-open rating-close');
                        }
                        else {
                            that.$secondContent.removeClass('is-hidden');
                            that.$btnShowMoreRatings.toggleClass('rating-close rating-open');
                        }
                    });
                });

                /**
                 * Show first Ratings -> main content and in second if it was visible previously
                 */

                that.$btnShowRatings.on('click', function() {
                    that.$mainContent.slideToggle( function() {
                        if (that.$btnShowRatings.hasClass('rating-open')) {

                            that.$btnShowRatings.toggleClass('rating-open rating-close');
                        }
                        else {
                            that.$btnShowRatings.toggleClass('rating-close rating-open');
                        }
                    });
                });

			}

            /**
             * set order on change event
             */

            if (mod === 'order') {
                that.$selectItem.on('change', () => {
                    that.ordermod = that.$selectItem.find(this.optionSelected).data(this.jsOrdermod);
                    that.orderItems('update');
                });
            }
        },

        /**
		 * order content with ordermod
		 *
		 * @param mod
         */
        orderItems: function (mod) { // eslint-disable-line

            const that = this;

            /**
             * order items by rating
             */

            if (that.ordermod === 'rating') {

                that.$contentItems.sort(function(a, b) {

                    const valA = $(a).find('.m-basic-rating').data('rating'),
                        valB = $(b).find('.m-basic-rating').data('rating');

					// eslint-disable-next-line
                    return valB - valA; // NOSONAR
                }).appendTo(that.$secondContent);
            }

            /**
             * order items by date and time ascending and load in second container
             */

            if (that.ordermod === 'time-asc') {

                that.$contentItems.sort(function(a, b) {

                    const valA = moment( $(a).find('.oo-meta-item time').eq(0).attr('datetime')),
                        valB = moment( $(b).find('.oo-meta-item time').eq(0).attr('datetime'));

                    return valB - valA;
                }).appendTo(that.$secondContent);
            }

            /**
             * load first 3 elements in first container
             */

            that.$secondContent.find('.oo-rating-item').each( (index, item) => {

                if ( index < 3) {
                    $(item).appendTo(that.$firstContent);
                }

            });

        }

	});
}(jQuery));
