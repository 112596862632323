(function ($)
{
    'use strict';
    /**
     * MyAdacListToggleElement module implementation.
     *
     * @author Tobias <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class MyAdacToggleElements
     * @extends T.Module
     */
    T.Module.MyAdacListToggleElement = T.createModule({

        /** @type {Number} this.module*/
        $module: null,

        /** @type {Number} needed $headline*/
        $headlineWrapper: null,

        /** @type {Number} needed contentWrapper*/
        $contentWrapper: null,

        /** @type {Number} needed contentWrapper*/
        $toggleContent: null,


        start(resolve)
        {
            this.$module = $(this._ctx);

            this.toggleContentClass = '.mm-toggle-content';

            this.$headlineWrapper = this.$module.find('.mm-toggle-headline-wrapper');
            this.$toggleContent = this.$module.find(this.toggleContentClass);
            this.$allButtonsWithLayer = this.$module.find('.a-basic-btn--my-adac');
            this.$allLinksWithLayer = this.$module.find('a.a-basic-link--my-adac.open-layer');

            $(document).on('printSection.preparePrintModule', () =>
            {
                if (this.$module.closest('.mm-box.is-print-visible').length > 0) {

                    this.$module.addClass('is-bold');
                    this.$module.find(this.toggleContentClass).stop(true, false).slideDown('slow', ()=> {
                        this.$module.addClass('is-active');
                        return false;
                    });
                }
            });

            // register listener to automatically clean up after print (or on abort)
            $(window).on("afterprint", () =>
            {
                this.$module.removeClass('is-bold');
                this.$module.find(this.toggleContentClass).stop(true, false).slideUp('slow', ()=> {
                    this.$module.removeClass('is-active');
                    return false;
                });
            });

            // for clicken a button with the class - mm-layer
            // which contains a layer -> trigger the event
            this.$allButtonsWithLayer.on('click', (e) =>
            {
                e.stopPropagation();

                this._events.emit('MyAdacDashboardBoxLayer.open-layer-in-toggle', $(e.currentTarget).parent('.mm-my-adac-toggle'));
            });

            this.$allLinksWithLayer.on('click', (e) =>
            {
                e.stopPropagation();
                e.preventDefault();

                this._events.emit('MyAdacDashboardBoxLayer.open-layer-in-toggle', $(e.currentTarget).closest('.mm-my-adac-toggle'));
            });

            this.$headlineWrapper.on('click', (e) =>
            {
                e.stopPropagation();
                this.$contentWrapper = $(e.currentTarget).closest('.a-my-adac-list');

                this.toggleHeadline();
            });

            if (this.$module.find('.mm-toggle-content > .m-basic-image--my-adac').length > 0) {
                this.$module.find(this.toggleContentClass).addClass('mm-toggle-content--image');
            }

            resolve();
        },

        /**
         * handels the toggle animation
         */
        toggleHeadline()
        {
            this.$contentWrapper.find('.mm-my-adac-toggle-element.is-active .mm-toggle-content').slideUp();
			// eslint-disable-next-line
			this.$module.find('.mm-toggle-headline button').attr('aria-expanded', 'false'); //NOSONAR attribute

            if (this.$module.hasClass('is-active'))
            {
                this.$module.removeClass('is-bold');
                this.$module.find(this.toggleContentClass).stop(true, false).slideUp('slow', ()=> {
                    this.$module.removeClass('is-active');
					this.$module.find('.mm-toggle-headline button').attr('aria-expanded', 'false');
                    return false;
                });

                return;
            }

            this.$contentWrapper.find('.mm-my-adac-toggle-element').removeClass('is-bold');
            this.$module.addClass('is-bold');
            this.$module.find(this.toggleContentClass).stop(true, false).slideDown('slow', ()=> {
                this.$contentWrapper.find('.mm-my-adac-toggle-element').removeClass('is-active');
                this.$module.addClass('is-active');
				this.$module.find('.mm-toggle-headline button').attr('aria-expanded', 'true');
                return false;
            });
        }
    });
}(jQuery));
