(function ($)
{
    'use strict';
    /**
     * MyAdacDashboardBoxShowcase module implementation.
     *
     * @author Tobias <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class MyAdacDashboardBox
     * @extends T.Module
     */
    T.Module.MyAdacDashboardBoxShowcase = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {Boolean} */
        isOpen: null,

        /** @type {jQuery} $allBoxHeadline*/
        $allDashboardThemeHeadlines: null,

        /** @type {jQuery} $allBoxHeadline*/
        $allDashboardThemeBoxes: null,

        /** @type {jQuery} $allDashboardThemes*/
        $allDashboardThemes: null,

        /** @type {jQuery} $allDashBoardListElements*/
        $allDashBoardRightListWrapper: null,

        /** @type {jQuery} $allContentWithLayer*/
        $allContentWithLayer: null,

        /** @type {jQuery} $allButtonsWithLayer*/
        $allButtonsWithLayer: null,

        /** @type {jQuery} $allLayer*/
        $allLayer: null,

        /** @type {Boolean} onInitSearch*/
        onInitSearch: true,

        /** @type {Number} onInitSearch*/
        boxToPrintReadyCounter: 0,

        /** @type {jQuery} $printSectionElements*/
        $printSectionElements: null,

        /** @type {jQuery} $allDashboardThemeBoxWrapper*/
        $allDashboardThemeBoxWrapper: null,

        /** @type {Number} counter FOR TESTING*/
        counter: 0,

        /** @type {String} state class'*/
        clickBoxStateClass: 'mm-single-box',

        start: function (resolve)
        {
            this.$ctx = $(this._ctx);

			// classes/events/attr as strings
			this.dashboxTheme = '.mm-dashbox-theme';
			this.boxWrapper = '.mm-box-wrapper';
			this.dashboxThemeHeadline = '.mm-dashbox-theme-headline';
			this.eventContentChanged = 'MyAdacDashboardList.content-changed';
			this.boxIsExtended = '.mm-box.is-extended';
			this.isExtended = 'is-extended';
			this.sizeChanged = 'size-changed';
			this.isHovered = 'is-hovered';

            this.$allDashboardThemes = this.$ctx.find(this.dashboxTheme);
            this.$allDashboardThemeBoxWrapper = this.$allDashboardThemes.find(this.boxWrapper);
            this.$allDashboardThemeHeadlines = this.$ctx.find(this.dashboxThemeHeadline);
            this.$allDashboardThemeBoxes = this.$ctx.find('.mm-box');
            this.$allContentWithLayer = this.$ctx.find('.mm-content.mm-layer');
            this.$allButtonsWithLayer = this.$allContentWithLayer.find('.a-basic-btn--my-adac');
            this.$themeOptionLayer = this.$ctx.find('.mm-dashbox-theme-option-layer').find('.mm-option-wrapper');
            this.$allLayer = this.$allContentWithLayer.find('.m-my-adac-dashboard-box-layer');
            this.$allDashBoardRightListWrapper = this.$ctx.find('.mm-right-list-wrapper');

            // for testing with parameter
            this.testSingleBoxToggling();

            this.initLayerEvents();
            this.resize();
            this.checkForExpandAll();
            // set all box-groups together -> for opening the same line on click and more things
            this.setOffsetGroups();

            this.initEvents();

            resolve();
        },

        /**
         * Param :: singleBoxClick - <number> | <number, number, number> (comma separated)
         *
         *     Number indicates the theme/part you want to test
         */
        testSingleBoxToggling()
        {
            // for testing
            this.needToSwitchShowcase = S.Utils.GetUrlParameter.getUrlParams('singleBoxClick')[0];

            if (this.needToSwitchShowcase)
            {
                const split = typeof this.needToSwitchShowcase?.singleBoxClick === 'string' ? this.needToSwitchShowcase?.singleBoxClick.split(',') : [this.needToSwitchShowcase?.singleBoxClick];

                for (const num in split)
                {
                    if (S.Utils.Helper.checkForNumberFromString(num))
                    {
                        // Set the value of variable --blue to another value (in this case "lightblue")
                        this.$allDashboardThemes.eq(parseInt(num, 10)).addClass(this.clickBoxStateClass);
                    }
                }
            }
        },

        /**
         * init all events for this module
         */
		// eslint-disable-next-line
        initEvents() //NOSONAR complexity
        {
			const isPrintVisible = 'is-print-visible';

            // printing function
            $(document).on('printSection.preparePrintModule', () =>
            {
                this.$printSectionElements = this.$ctx.find('.is-print-section').find('.mm-box:not(.mm-box--promotion)');

                // be sure this this is the right
                if (this.$printSectionElements.length && typeof this.$printSectionElements !== 'undefined')
                {
                    this.$ctx.addClass(isPrintVisible);

                    // select module-specific elems and add the helper class to them
                    this.$ctx.find('.mm-wrapper').addClass(isPrintVisible);
                    this.$ctx.find('.mm-dashbox-theme-wrapper').addClass(isPrintVisible);
                }

                this.checkForElementsToPrint();
            });

            $(document).on('printSection.cleanUpPrintModule', () =>
            {
                this.$ctx.removeClass(isPrintVisible);
            });

            // printing listener
            this._events.on(this.eventContentChanged, () =>
            {
                if (this.$ctx.hasClass(isPrintVisible))
                {
                    this.boxToPrintReadyCounter++;

                    if (this.boxToPrintReadyCounter === this.$printSectionElements.length)
                    {
                        this.firstInit = true;

                        this.boxToPrintReadyCounter = 0;
                        // return;
                        $(document).trigger('printSection.preparePrintModuleFinished');
                    }
                }


                this.emailWordBreaker();

                this.initLayerEvents();
            });

            // printing listener
            this._events.on('MyAdacDashboardBox.content-added', () =>
            {
                this.$allDashboardThemeHeadlines = this.$ctx.find(this.dashboxThemeHeadline);
                this.$allDashboardThemeBoxes = this.$ctx.find('.mm-box');
                this.$allDashboardThemeBoxWrapper = this.$allDashboardThemes.find(this.boxWrapper);
                this.$allDashBoardRightListWrapper = this.$ctx.find('.mm-right-list-wrapper');

                this.setOffsetGroups(true);
                this.initLayerEvents();
                this.emailWordBreaker();
            });

            if (this.$allDashboardThemeBoxes.filter(this.boxIsExtended).length > 0)
            {
                this.deleteMeLoadingFunctionOnlyForTesting(this.$allDashboardThemeBoxes.filter(this.boxIsExtended).removeClass('is-loading'));
            }

            // Initial: start on mobile devices - close all windows
            if (!S.Utils.Helper.mq('tablet').matches)
            {
                this.$allDashboardThemeHeadlines.removeClass(this.isExtended);
                this.$allDashboardThemeBoxes.removeClass(this.isExtended);
            }

            // get global event clicking on X in the searching-field
            this._events.on('BasicKeywordfilter.afterRefreshFinished', () =>
            {
                this.deleteMeLoadingFunctionOnlyForTesting(this.$allDashboardThemeBoxes, true);

                this.setOffsetGroups(true);
            });

            // get global event toggle open all
            this._events.on('basicBtnToggle.toggleOpenAll', () =>
            {
                this.$allDashboardThemeHeadlines.addClass(this.isExtended);
                this.$allDashboardThemeBoxes.addClass(this.isExtended).trigger(this.sizeChanged);

                this.deleteMeLoadingFunctionOnlyForTesting(this.$allDashboardThemeBoxes, true);

                // switch to opposite (Alle ausklappen/zuklappen) if needed
                this.checkForExpandAll();
            });

            // get global event toggle close all
            this._events.on('basicBtnToggle.toggleCloseAll', () =>
            {
                this.$allDashboardThemeHeadlines.removeClass(this.isExtended);
                this.$allDashboardThemeBoxes.removeClass(this.isExtended);

                // switch to opposite (Alle ausklappen/zuklappen) if needed
                this.checkForExpandAll();
            });

            // get global event searchKeyup
            this._events.on('BasicKeywordfilter.searchKeyup', () =>
            {
                // screen
                S.Utils.delayed('searchKeyup', 100, () =>
                {
                    this.checkKeyup();
                });
            });

            // set a tabIndex to option layer to be focusable
            S.Globals.TabFocus.addPopup(this.$themeOptionLayer);

            // for clicking on theme-option-layer (insurance)
            this.$themeOptionLayer.on('click', (e) =>
            {
                this.handleOptionLayerLogic($(e.currentTarget), e);
            });

            // get clicked headline, and trigger the function
            this.$allDashboardThemeHeadlines.on('click', (e) =>
            {
                e.stopPropagation();

                // get clickedHeadline for theme-boxes and toggle the boxes
                this.headlineToggleBoxes($(e.currentTarget).closest(this.dashboxThemeHeadline));
                this.deleteMeLoadingFunctionOnlyForTesting($(e.currentTarget).closest(this.dashboxTheme).find('.mm-box'));
            });

            // click:   if we click on closed boxes, get the clicked theme-headline and find the
            //          included boxes to toggle only these ones
            //          check the headlines for mm-controler buttons "alle ein/ausklappen"
            // mouseover/mouseleave: to hover all theme-boxes for the hovered theme
            // this.$allDashboardThemeBoxes.on({
            this.$allDashboardThemeBoxWrapper.on({
				// eslint-disable-next-line
                click: (e) => //NOSONAR return
                {
                    e.stopPropagation();

                    const $clickedBox = $(e.currentTarget);

                    if ($(e.target).closest('.mm-box-headline').length)
                    {
                        this.deleteMeLoadingFunctionOnlyForTesting($clickedBox);

                        // // only on mobile devices
                        if (!S.Utils.Helper.mq('tablet').matches)
                        {
                            // check if the clickedBox is extended and the clicked element is the headline
                            // Only headline-click counts
                            if (($clickedBox.hasClass(this.isExtended) && $(e.target).closest('.mm-box-headline').length > 0) || !$clickedBox.closest('.mm-box').hasClass(this.isExtended))
                            {
                                // remove is-hovered Class, if not it will be yellow until you mouseleave the box
                                $clickedBox.removeClass(this.isHovered);

                                // only open the clicked Box
                                this.toggleSingleBox($clickedBox);

                                return;
                            }
                            else
                            {
                                return false;
                            }
                        }

                        // only for single box click (state via class: this.clickBoxStateClass)
                        if ($clickedBox.closest(this.dashboxTheme).hasClass(this.clickBoxStateClass))
                        {
                            if (!$clickedBox.closest('.mm-box').hasClass(this.isExtended))
                            {
                                // remove is-hovered Class, if not it will be yellow until you mouseleave the box
                                $clickedBox.removeClass(this.isHovered);
                            }

                            // only open the clicked Box
                            this.toggleSingleBox($clickedBox);

                            return;
                        }

                        this.$allDashboardThemeBoxes.removeClass(this.isHovered);
                        this.toggleGroupBoxes(this.getOffsetElements($clickedBox), false, false);
                    }
                },
                mouseenter: (e) =>
                {
                    const $hoveredBox = $(e.currentTarget);

                    // on mobile devices only hover one Box
                    if (!S.Utils.Helper.mq('tablet').matches)
                    {
                        $hoveredBox.removeClass(this.isHovered);

                        return;
                    }

                    e.stopPropagation();

                    const $neededGroup = $hoveredBox.closest('.mm-box').data('group'),
                        $hoveredGroupBox = $hoveredBox.closest(this.dashboxTheme).find('.mm-box').filter(`[data-group="${$neededGroup}"]`);

                    if ($hoveredBox.hasClass(this.isExtended))
                    {
                        return;
                    }

                    // only for single box click (state via class: this.clickBoxStateClass)
                    if ($hoveredBox.closest(this.dashboxTheme).hasClass(this.clickBoxStateClass))
                    {
                        $hoveredBox.addClass(this.isHovered);

                        return;
                    }

                    // if the hovered box has no extending class and its not mobile hover it
                    $hoveredGroupBox.addClass(this.isHovered);
                },

                mouseleave: () =>
                {
                    this.$allDashboardThemeBoxes.removeClass(this.isHovered);
                }
            }, '.mm-box');
        },

        /**
         * This is triggered when the user press Enter on the element which where handed over via "S.Globals.TabFocus.addPopup(<element>, <instance>);"
         *
         * @param {jQuery} $target - currently tab focused el
         * @param {Event} event - keyup (popupCtx) event
         * @private
         */
        _handleFocusEnter($target, event)
        {
            // only for this.$themeOptionLayer
            if ($target.is(this.$themeOptionLayer))
            {
                this.handleOptionLayerLogic($target, event);
            }
        },

        /**
         * This is triggered when the focus is tabbed to an element within
         * this module and the user presses the "ESC" key on this element.
         *
         * @param {jQuery} $target - currently tab focused el
         * @param {Event} event - keydown event
         *
         * @private
         */
        _handleFocusEsc($target, event)
        {
            if ($target.is(this.$ctx.find('.mm-layer-content')))
            {
                this.handleOptionLayerLogic(this.$themeOptionLayer, event);
            }
        },

        /**
         * @param {jQuery} $target
         * @param {Event} e
         */
        handleOptionLayerLogic($target, e)
        {
            e.stopPropagation();
            e.preventDefault();

            const $themeOption = $target.closest('.mm-theme-option');

            if ($themeOption.hasClass('is-open'))
            {
                this._events.emit('MyAdacDashboardBoxLayer.open-layer-theme-options-close', $themeOption);

                return;
            }

            // hand over the container with content, not only the layer
            S.Globals.TabFocus.setFocusToContext($themeOption.find('.mm-layer-content'), this, $themeOption.find('.mm-close'));

            this._events.emit('MyAdacDashboardBoxLayer.open-layer-theme-options', $themeOption);
        },

        /**
         *
         */
        initLayerEvents()
        {
            this.$allContentWithLayer = this.$ctx.find('.mm-content.mm-layer');
            this.$allButtonsWithLayer = this.$allContentWithLayer.find('.a-basic-btn--my-adac').not(this.$ctx.find('.mm-layer-content .a-basic-btn--my-adac'));

            this.$themeOptionLayer = this.$ctx.find('.mm-dashbox-theme-option-layer').find('.mm-option-wrapper');
            this.$allLayer = this.$allContentWithLayer.find('.m-my-adac-dashboard-box-layer');

            // for clicking a button with the class - mm-layer
            // which contains a layer -> trigger the event
            this.$allButtonsWithLayer.off().on('click', (e) =>
            {
                e.stopPropagation();

                this._events.emit('MyAdacDashboardBoxLayer.open-layer', $(e.currentTarget).parent('.mm-content'));
            });
        },

        deleteMeLoadingFunctionOnlyForTesting($clickedBox)
        {
            S.Utils.delayed(`loader-${  this.counter}`, 1000, () =>
            {
                $clickedBox.removeClass('is-loading');
                this._events.emit(this.eventContentChanged, $clickedBox);
                this.emailWordBreaker();
            });

            this.counter++;
        },

        /**
         *
         */
        setOffsetGroups(afterResize = false)
        {

            this.$allDashboardThemes.each((index, element) =>
            {
                const $themeDashbox = $(element),
                    $themeBoxes = $themeDashbox.find('.mm-box');

                if ($themeBoxes.length > 0)
                {

                    let benchmarkOffset = $themeBoxes.eq(0).offset().top,
                        counter = 0;

                    $themeBoxes.each((index, element) =>
                    {
                        const currentBoxOffset = $(element).offset();

                        if (currentBoxOffset.top !== benchmarkOffset)
                        {
                            counter++;
                            $(element).data('group', counter).attr('data-group', counter);

                            benchmarkOffset = currentBoxOffset.top;
                        }
                        else
                        {
                            $(element).data('group', counter).attr('data-group', counter);
                        }
                    });


                    if (afterResize)
                    {
                        for (let i = 0; i <= counter; i++)
                        {
                            const $boxGroup = $themeBoxes.filter(`.mm-box[data-group="${i}"]`);

                            if ($boxGroup.hasClass(this.isExtended))
                            {
                                $boxGroup.addClass(this.isExtended).trigger(this.sizeChanged);
                                this.deleteMeLoadingFunctionOnlyForTesting($boxGroup);
                            }
                            else
                            {
                                $boxGroup.removeClass(this.isExtended);
                            }
                        }
                    }
                }
            });
        },

        /**
         * only desktop/tablet,
         * @param {jQuery} $clickedBox
         * */
        getOffsetElements($clickedBox)
        {
            const neededOffset = $clickedBox.offset(),
                $neededBoxes = $clickedBox.closest(this.boxWrapper).find('.mm-box');

			// eslint-disable-next-line
            const $neededElements = $neededBoxes.filter((index, element) => //NOSONAR return
            {
                const offset = $(element).offset();

                return (neededOffset.top === offset.top);
            });

            return $neededElements;
        },

        /**
         * add all mail-adresses an (&shy;) none width empty space to word-break better
         */
        emailWordBreaker()
        {
            this.$allDashBoardRightListWrapper.each((index, element) =>
            {
                // &#8203; = Breitenloses Leerzeichen (there isn´t a "-" at word-break)
                const $content = $(element).find('.mm-list-point-text');

                let text;

                if ($content.length > 1)
                {

                    $content.each((index, value) =>
                    {
                        text = $(value).html().replace(/@/g, '@&#8203;');
                        $(value).html(text);
                    });
                }
                else if ($content.length === 1)
                {
                    text = $content.html().replace(/@/g, '@&#8203;');
                    $content.html(text);
                }
            });
        },

        /**
         * toggle the is-extended class if all boxes are extended / not extended
         * for mobile devices
         * @param {jQuery} $clickedElement
         */
        boxExtendedCheckForHeadlines($clickedElement)
        {
            const $clickedTheme = $clickedElement.closest(this.dashboxTheme),
                $neededThemeHeadline = $clickedTheme.find(this.dashboxThemeHeadline),
                allBoxesFromThemeAreExtended = ($clickedTheme.find(this.boxIsExtended).length === $clickedTheme.find('.mm-box').length);

            // for mobile devices: if all singleBoxes in a theme are clicked, add class to the theme-headline
            if (allBoxesFromThemeAreExtended)
            {
                $neededThemeHeadline.addClass(this.isExtended);
            }
            else
            {
                $neededThemeHeadline.removeClass(this.isExtended);
            }
        },

        /**
         * Only for mobile devices
         * Click on boxes - toggle single box and trigger the headline checker function
         * @param {jQuery} $clickedBox if true, extend all
         */
        toggleSingleBox($clickedBox)
        {
            if ($clickedBox.hasClass(this.isExtended))
            {
                $clickedBox.removeClass(this.isExtended).addClass(this.isHovered);
            }
            else
            {
                $clickedBox.addClass(this.isExtended).trigger(this.sizeChanged);
            }

            this.boxExtendedCheckForHeadlines($clickedBox);
        },

        /**
         * for expanding the boxes
         * click on headline - open only all matchin boxes, change arrow in the headline
         * click on open/close all button above - open/close all boxes
         * @param {*} $clickedElements if false take all (on top button click) - else take the clickedHeadline (on headline click)
         * @param {Boolean} toggleAll if true, extend all
         * @param {Boolean} close if true, close all extended themes (allBoxes)
         */
        toggleGroupBoxes($clickedElements = false, toggleAll = false, close = false)
        {
            // const $headline = $clickedElements ? $clickedElements : this.$allDashboardThemeHeadlines,
            const $contentBoxes = toggleAll ? this.$ctx.find('.mm-box') : $clickedElements,
                $allContentBoxesInTheme = $clickedElements.closest(this.boxWrapper).find('.mm-box'),
                $themeHeadline = $clickedElements ? $clickedElements.closest(this.dashboxTheme).find(this.dashboxThemeHeadline) : this.$allDashboardThemeHeadlines;

            // it´s for clicking the headline - only toggle the clicked headline´s boxes
            if (toggleAll && close)
            {
                $themeHeadline.removeClass(this.isExtended);
                $contentBoxes.removeClass(this.isExtended);

                this.checkForExpandTheHeadlines($themeHeadline, $allContentBoxesInTheme.filter(this.boxIsExtended), $allContentBoxesInTheme);
                return;
            }
            else if (toggleAll && !close)
            {
                $themeHeadline.addClass(this.isExtended);
                $contentBoxes.addClass(this.isExtended).trigger(this.sizeChanged);
                this.deleteMeLoadingFunctionOnlyForTesting($contentBoxes, true);

                this.checkForExpandTheHeadlines($themeHeadline, $allContentBoxesInTheme.filter(this.boxIsExtended), $allContentBoxesInTheme);
                return;
            }

            // if someone clicks on boxes, the box-jQueryCollection gives all elements which are in row
            if ($clickedElements)
            {
                if ($clickedElements.hasClass(this.isExtended))
                {
                    $clickedElements.removeClass(this.isExtended).addClass(this.isHovered);
                }
                else
                {
                    $clickedElements.addClass(this.isExtended).removeClass(this.isHovered).trigger(this.sizeChanged);
                    this.deleteMeLoadingFunctionOnlyForTesting($contentBoxes, true);
                }

                this.checkForExpandTheHeadlines($themeHeadline, $allContentBoxesInTheme.filter(this.boxIsExtended), $allContentBoxesInTheme);
            }
        },

        /**
         *
         * @param $clickedHeadline
         */
        headlineToggleBoxes($clickedHeadline)
        {
            const $contentBoxes = $clickedHeadline.closest(this.dashboxTheme).find('.mm-box'),
                $atLeastOneBoxIsOpened = $contentBoxes.filter(this.boxIsExtended);

            // click on a themeHeadline
            //
            // if at least on box is open, open all boxes
            if ($atLeastOneBoxIsOpened.length === $contentBoxes.length)
            {
                $contentBoxes.removeClass(this.isExtended);
                $clickedHeadline.removeClass(this.isExtended);
            }
            // if all boxs are closed, open all boxes
            else
            {
                $contentBoxes.addClass(this.isExtended).trigger(this.sizeChanged);

                // has to be removed later, will be trigger from backend
                this._events.emit(this.eventContentChanged, $contentBoxes);
                $clickedHeadline.addClass(this.isExtended);
            }

            this.checkForExpandAll();
        },

        /**
         * handles the headline arrow and class for a theme-headline
         * @param  {jQuery} $headline clicked theme-headline
         * @param  {jQuery} $atLeastOneBoxIsOpened filtered boxes which are open (hasClass .is-extended)
         * @param  {jQuery} $contentBoxes all boxes in the clicked headline theme
         */
        checkForExpandTheHeadlines($headline, $atLeastOneBoxIsOpened, $contentBoxes)
        {
            // click on a themeHeadline
            if ($atLeastOneBoxIsOpened.length === $contentBoxes.length)
            {
                $headline.addClass(this.isExtended);
            }
            else if ($atLeastOneBoxIsOpened.length > 0 && $atLeastOneBoxIsOpened.length !== $contentBoxes.length)
            {
                $headline.removeClass(this.isExtended);
            }
            else
            {
                $headline.removeClass(this.isExtended);
            }

            this.checkForExpandAll();
        },

        /**
         * for Alle zuklappen/ausklappen button/top
         * check if all elements are expanded change the button to 'Alle zuklappen'
         * if at least one element is expanded change the button to 'Alle ausklappen'
         */
        checkForExpandAll()
        {
            // Boolean: if true "alle zuklappen" / if false "alle aufklappen"
            let collapsedFound = false;

			// eslint-disable-next-line
            this.$allDashboardThemeHeadlines.each((index, element) => //NOSONAR Return
            {
                if (!$(element).hasClass(this.isExtended))
                {
                    collapsedFound = true;

                    return false;
                }
            });

            if ( collapsedFound ) {
                this._events.emit('myAdadDashboardBox.someCollapsed');
            }

            else {
                this._events.emit('myAdadDashboardBox.allExpanded');
            }
        },

        /**
         * extend the boxes with a searchresult
         */
        checkKeyup()
        {
            this.$allDashboardThemeBoxes.each((index, element) =>
            {
                if ($(element).find('.h-search').length || $(element).hasClass('js-search-conditions-matched'))
                {
                    $(element).addClass(this.isExtended)
                        .trigger(this.sizeChanged)
                        .closest(this.dashboxTheme)
                        .find(this.dashboxThemeHeadline)
                        .addClass(this.isExtended);

                    this.deleteMeLoadingFunctionOnlyForTesting($(element));
                }
                else
                {
                    $(element).removeClass(this.isExtended);
                }
            });
        },

        /**
         * resize function for changing size in the browser or from landscape to portrait
         * (with delay, to reduce the event-calls)
         */
        resize()
        {
            // Store the window width
            let windowWidth = $(window).width(),
                stopper = false;

            // Resize Event
			// eslint-disable-next-line
            $(window).on('resize', () => //NOSONAR Return
            {
                if ($(window).width() !== windowWidth)
                {
                    // Update the window width for next time
                    windowWidth = $(window).width();

                    S.Utils.delayed(`resize-boxes-${  this.moduleId}`, 200, () =>
                    {
                        this.setOffsetGroups(true);
                    });

                    // On resize, close all boxes and themeheadline just ones
                    // set the stopper on true if it changes from mobile to tablet
                    // to do it again if it changes again
                    if (this.$ctx.closest('.m-my-adac-dashboard-box--logged-out').length > 0)
                    {
                        return false;
                    }
                    else
                    {

                        if (!S.Utils.Helper.mq('tablet').matches)
                        {
                            if (stopper)
                            {
                                this.$allDashboardThemeHeadlines.removeClass(this.isExtended);
                                this.$allDashboardThemeBoxes.removeClass(this.isExtended);
                                this.$allLayer.addClass('is-hidden');
                                stopper = false;
                            }
                        }
                        else
                        {
                            stopper = true;
                        }
                    }
                }
            });
        },

        /**
         * checks if the someone puts something in the searchfield and pushed Browser-reload
         * on Browser-reload the searching-js saves the search word in sessionStorage
         * @return {boolean}
         */
        getSessionMatch()
        {
            for (let i = 0; i < sessionStorage.length; i++)
            {
                const key = sessionStorage.key(i);

                const keyStorage = key.split('/')[0];

                if (keyStorage === 'basickeywordfilter_searchtext_')
                {
                    return true;
                }
            }

            return false;
        },

        /**
         * extend the boxes with a searchresult
         */
        checkForElementsToPrint()
        {
            this.$printSectionElements.each((index, element) =>
            {
                $(element).addClass(this.isExtended)
                    .trigger(this.sizeChanged)
                    .closest(this.dashboxTheme)
                    .find(this.dashboxThemeHeadline)
                    .addClass(this.isExtended);
                this.deleteMeLoadingFunctionOnlyForTesting($(element));
            });
        }
    });
}(jQuery));
