'use strict';
(function ($) {

	/**
	 * BasicInputText module implementation.
	 *
	 * @author  <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputText - Linksearch
	 * @extends T.Module
	 */
	T.Module.BasicSearch.Linksearch = T.createDecorator({

		/**
		 * Initialize.
		 */
		start(resolve) {
			this.$ctx = $(this._ctx);
			this._registerListeners();
			resolve();
		},

		_registerListeners() {
			this.$ctx.find('form').on('submit', this._handleSearchSubmit.bind(this));
		},

		_handleSearchSubmit(ev) {
			ev.preventDefault();

			const $inputField = this.$ctx.find('.js-search-input'),
				searchUrl = $inputField.data('searchurl'),
				searchQuery = $inputField.val();

			if (searchQuery) {
				window.location.href = searchUrl + encodeURIComponent(searchQuery);
			}
		},
		setSoftLayerNoScroll() { },

		unsetSoftLayerNoScroll() { },

		checkSuggestionsLengthForSoftLayer() { },

		showBlackSoftLayer() { },

		hideBlackSoftLayer() { },

		clearButtonHandling() { },

		validate() { },

		setAutosuggestionsWrapperWidth() { },

		formatResult() { },

		handleLinkTemplating() { },

		handleAdTemplating() { },

		createHtmlOutput() { },

		handleShowMoreClick() { },

		handleOldResultDeleting() { },

		resize() { }
	});
}(jQuery));
