(function ($) {
	'use strict';
	/**
	 * BasicBreadcrumb module implementation.
	 *
	 * @author  <n.bork@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicBreadcrumb
	 * @extends T.Module
	 */
	T.Module.BasicBreadcrumb = T.createModule({

		/** @type {jQuery} **/
		$ctx: null,

		/** @type {jQuery} **/
		$liElements: null,

		/** @type {jQuery} **/
		$dropDownElement: null,

		/** @type {jQuery} */
		$swiperContainer: null,

		/** @type {jQuery} */
		$swiperSlidesWrapper: null,

		/** @type {Object[]} */
		$swiperSlides: null,

		/** @type {jQuery} */
		$swiperBtnLeft: null,

		/** @type {jQuery} */
		$swiperBtnRight: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.$liElements = this.$ctx.find('ul.mm-baselevel > li');
			this.$dropDownElement = this.$liElements.filter('.js-dropdown');
			this.$swiperContainer = this.$ctx.find('.swiper');
			this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper').eq(0);
			this.$swiperSlides = this.$ctx.find('.swiper-slide');
			this.$swiperBtnLeft = this.$swiperContainer.find('.swiper-button-prev');
			this.$swiperBtnRight = this.$swiperContainer.find('.swiper-button-next');
			this.ariaLabelAttribute = 'aria-label';
			this.swiperBtnLeftAriaLabelText = this.$swiperBtnLeft.attr(this.ariaLabelAttribute);
			this.swiperBtnRightAriaLabelText = this.$swiperBtnRight.attr(this.ariaLabelAttribute);

			this.firstLevel = '.mm-firstlevel';

			// set min width for dropdown element
			this.setMinWidth();

			// animate dropdown element
			this.setEvent();

			// close dropdown
			this.initClose();

			// init swiper
			this.initSwiper();

			resolve();
		},

		/**
		 * set min width for the dropdown to prevent false width values made by the swiper calc
		 */
		setMinWidth() {
			// add 4px for the borders
			const listWidth = this.$dropDownElement.find('> ul').outerWidth() + 4;
			this.$dropDownElement.css('min-width', listWidth);
		},

		/**
		 * animate dropdown element
		 */
		setEvent: function () {
			this.$ctx.find('.mm-baselevel .js-dropdown > a').on('click', (event) => {
				event.preventDefault();
				event.stopPropagation();

				this.$ctx.find(this.firstLevel).toggleClass('is-opened');
				this.$liElements.toggleClass('is-opened');

				if (this.$ctx.find(this.firstLevel).hasClass('is-opened')) {
					this.$dropDownElement.attr('aria-expanded', true);
				} else {
					this.$dropDownElement.attr('aria-expanded', false);
				}
			});
		},

		/**
		 * close element
		 */
		initClose: function () {
			$('html').on('click', () => {
				this.$ctx.find(this.firstLevel).removeClass('is-opened');
				this.$liElements.removeClass('is-opened');
				this.$ctx.find('.mm-firstlevel .js-dropdown > a').removeClass('is-active');
			});
		},

		/**
		 * init swiper
		 */
		initSwiper: function () {
			// init swiper container
			this.mySwiper = new Swiper(this.$swiperContainer[0], {
				slidesPerView: 'auto',
				speed: 1500,
				watchSlidesProgress: true,
				navigation: {
					prevEl: this.$swiperBtnLeft[0],
					nextEl: this.$swiperBtnRight[0],
				},
				keyboard: {
					enabled: true,
				},
				on: {
					init: (swiper) => {
						// enables/disables the swiper
						// - In the old logic, the slides were not centred
						S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
					},
					transitionStart: () => {
						//we do this because for the softening we need the logic the swiper provides for its buttons natively but the button should never focus
						$(this.$swiperBtnLeft[0]).attr('tabindex', '-1');
						$(this.$swiperBtnRight[0]).attr('tabindex', '-1');
					},
					resize: (swiper) => {
						S.Utils.delayed('basicBreadcrumbWindowResize', 40, () => {
							// enables/disables the swiper
							// - In the old logic, the slides were not centred
							S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);

							// clean aria-labels from swiper
							this.$liElements.removeAttr(this.ariaLabelAttribute);
						});
					}
				}
			});

			// clean aria-labels from swiper
			setTimeout(() => {
				this.$liElements.removeAttr(this.ariaLabelAttribute);
			}, 100);

			if (typeof this._sandbox.swiperInstance === 'undefined') {
				this._sandbox.swiperInstance = {};
			}
			this._sandbox.swiperInstance[this.$ctx.data('t-id')] = this.mySwiper;

			// set width of swiper-wrapper
			let slideWidth = 0;

			this.$swiperSlidesWrapper
				.children('li')
				.each((index, elem) => {
					slideWidth += $(elem).outerWidth();
				})
				.promise()
				.done(() => {
					this.$swiperSlidesWrapper.css('max-width', slideWidth);
				});

			// slider start with last slide element
			this.mySwiper.slideTo(this.$swiperSlides.length - 1, 0);

			// dropdown menu should be swipeable
			this.$dropDownElement.find(this.firstLevel).addClass('swiper-no-swiping');

			// bugfix: set aria-labels here again, because swiper sometimes overwrites it
			this.$swiperBtnLeft.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
			this.$swiperBtnRight.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);
		},
	});
}(jQuery));
