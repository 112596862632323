(function($) {
	'use strict';
	/**
	 * directory decorator implementation for the BasicToggle module.
	 *
	 * @author  <b.kowatsch@mgo360.de>
	 * @namespace T.Module.BasicToggle
	 * @class directory
	 * @extends T.Module
	 */
	T.Module.BasicToggle.Directory = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$toggleModuleContainer: null,

		start: function (resolve) {

			this.$ctx = $(this._ctx);
			this.opened = 'mm-opened is-opened';

			this.initEventsDirectory();
			this.initResize();

			if($(location).attr('hash').length > 1) {
				this.adjustTop($(location).attr('hash'));
			}
			this._parent.start(resolve);
		},

		initEventsDirectory: function(){
			// disable click-events on init when mobile-view is shown
			if(!S.Utils.Helper.mq('tablet').matches) {
				this.$ctx.find('.mm-head').removeClass(this.opened);
			}

			this.$ctx.find('a').on('click', (link) => {
				this.adjustTop($(link.currentTarget).attr('href'));
			});
		},

		adjustTop(link) {
			setTimeout(() => {
				// Alternativ: $(window).scrollTop($(window).scrollTop() - 70);
				$(window).scrollTop($(link).offset().top - 70);
			}, 100);
		},

		initResize()
		{
			let cachedWidth = $(window).width();

			$(window).resize(() =>
			{
				const newWidth = $(window).width();
				if(newWidth !== cachedWidth) {
					S.Utils.delayed('resize', 100, () => {
						if (S.Utils.Helper.mq('tablet').matches) {
							this.$ctx.find('.mm-head').addClass(this.opened);
						} else {
							this.$ctx.find('.mm-head').removeClass(this.opened);
						}
					});
					cachedWidth = newWidth;
				}
			});
		},
	});
}(jQuery));
