(function($) { // eslint-disable-line
	'use strict';
	/**
	 * BasicLinkList module implementation.
	 *
	 * @author Niclas Bork <n.bork@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicLinkList
	 * @extends T.Module
	 */
	T.Module.BasicLinkList = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
		start: function(resolve) {
			resolve();
		}
	});
}(jQuery));
