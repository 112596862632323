(function ($) {
	'use strict';
	/**
	 * Damage Declaration decorator for BasicUpload module.
	 *
	 * @author l.meyer <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicUpload
	 * @extends T.Module
	 */
	T.Module.BasicUpload.Damagedeclaration = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$input: null,

		/** @type {jQuery} */
		$btn: null,

		/** @type {jQuery} */
		$btnClear: null,

		/** @type {jQuery} */
		$data: null,

		/** @type {Number} */
		count: 1,

		/** @type {Number} */
		dataID: 0,

		/** @type {Boolean} */
		multiple: false,

		/** @type {String} */
		HTML: '',

		/** @type {Number} */
		fileSize: 0,

		/** @type {Number} */
		oldFileSize: 0,


		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {// eslint-disable-line
			this.$ctx = $(this._ctx);

			this.$input = this.$ctx.find('input').eq(0);
			this.$btn = this.$ctx.find('.a-basic-btn').eq(0);
			this.$btnClear = this.$ctx.find('.js-clear-button').eq(0);
			this.$data = this.$ctx.find('.js-data').eq(0);
			this.dataID = this.$ctx.data('t-id');
			this.HTML = this.$ctx.prop('outerHTML');

			// classes/events/attr as strings
			this.uploadClass = '.m-basic-upload';
			this.fileSizeAttr = 'data-js-filesize';
			this.cloneButtonRow = '.js-clone-button-row';
			this.cloneButton = '.js-clone-button';
			this.uploadButton = '.js-upload-button';
			this.eventBtnFadeAnimate = 'BasicBtn.fadeAnimate-';
			this.cloneRemove = '.js-clone-remove';
			this.cloneSourceTrue = '.js-clone-source-true';

			// init change
			this.change();

			// init close
			this.close();

			// listen to global reset event
			this._events.on(`compReset.${this.$ctx.data('t-id')}`, () => {
				this.reset();
			});

			this.$btnClear.on('click', (btn) => {
				$(btn.currentTarget).closest('.mm-row').trigger('UtilsForm.deleteClonedFileWithX');
			});
		},


		/**
		 * change
		 */
		// eslint-disable-next-line
		change: function () { //NOSONAR
			const that = this;

			let formatSize;

			that.$btn.off('click.changeUpload').on('click.changeUpload', () => {
				that.$input.trigger('click');
			});

			that.$input.off('change.changeUpload').on('change.changeUpload', (e) => {
				const fileName = $(e.currentTarget).val().split('\\').pop(),
					container = that.$input.closest(this.uploadClass);

				if (fileName) {

					let fileUnit = 'KB';

					that._events.emit('BasicUpload.reduceFileSize', that.oldFileSize);

					// inputElemSize
					that.fileSize = that.$input[0].files[0].size / 1024;
					that.oldFileSize = that.fileSize;
					formatSize = that.fileSize;

					container.find('.js-data').attr(this.fileSizeAttr, that.fileSize);

					that._events.emit('BasicUpload.addFileSize', that.fileSize);

					// detect unit (KB / MB)
					if (formatSize > '1023') {
						fileUnit = 'MB';

						// convert to MB and round to 2 decimals
						formatSize = parseFloat(formatSize / 1024).toFixed(2);
					}

					else {
						// round KB to Int
						formatSize = Math.round(formatSize);
					}

					const $rowWrap = $(container).closest('.mm-row').length ? $(container).closest('.mm-row') : $(container).closest('.ll-row');

					const $cloneBtn = $($rowWrap).find(this.cloneButton).length ? $($rowWrap).find(this.cloneButton) : $($rowWrap).nextAll(this.cloneButtonRow).find(this.cloneButton);

					const $uploadBtn = $($rowWrap).find(this.uploadButton).length ? $($rowWrap).find(this.uploadButton) : $($rowWrap).nextAll(this.cloneButtonRow).find(this.uploadButton);


					if ($cloneBtn.length) {

						S.Utils.delayed('BasicUploadDmgDecl.animBuffer', 100, () => {

							if (!$uploadBtn.hasClass('is-shown-animate')) {

								that._events.emit(`${this.eventBtnFadeAnimate + $uploadBtn.data('t-id')}`, 'show');
							}

							if (!$cloneBtn.hasClass('is-shown-animate')) {
								that._events.emit(`${this.eventBtnFadeAnimate + $cloneBtn.data('t-id')}`, 'show');
							}
						});
					}

					container.addClass('is-active');
					container.find('.js-data').addClass('is-active').html(fileName);
					container.find('.m-data-wrap .js-filesize').remove();
					container.find('.m-data-wrap').append(`<div class="js-filesize"><span>${formatSize}&nbsp;</span><span>${fileUnit}</span></div>`);

					if (container.closest('.ll-row').hasClass('js-clone')) {
						container.closest('.ll-col-data').siblings(this.cloneRemove).show();
					}

					$rowWrap.closest('.ll-fieldset').removeClass('js-all-files-stored');

					that._events.emit('BasicUpload.fileAdded', that.$input, fileName, formatSize, fileUnit);

					that.$btn.hide();
				}
			});
		},

		/**
		 * close
		 */
		close() {
			const eventClickClearInput = 'click.clearInput',
				jsFileStored = 'js-file-stored';

			if (this.$btnClear.closest(this.cloneSourceTrue).length) {
				this.$btnClear.closest(this.cloneSourceTrue).find(this.cloneRemove).off(eventClickClearInput).on(eventClickClearInput, () => {
					this.$btnClear.prev().removeClass(jsFileStored);
					this.$btnClear.closest('.mm-row-content').removeClass(jsFileStored);
					this.reset();
				});
			}

			this.$btnClear.off(eventClickClearInput).on(eventClickClearInput, () => {
				this.$btnClear.prev().removeClass(jsFileStored);
				this.$btnClear.closest('.mm-row-content').removeClass(jsFileStored);
				this.$btnClear.closest(this.uploadClass).find('.js-file-stored').removeClass(jsFileStored);
				this.reset();
			});
		},


		/**
		 * reset input
		 */
		// eslint-disable-next-line
		reset() //NOSONAR
		{
			this.$ctx.removeClass('is-active');
			this.$ctx.find('.js-data').removeClass('is-active').html('').removeAttr(this.fileSizeAttr);
			this.$ctx.find('.js-filesize').remove();
			this.$ctx.find('.a-basic-btn').show();
			this.$ctx.find('input').val('');

			this.$ctx.find('[data-js-guid]').removeAttr('data-js-guid');

			const fileSize = parseFloat(this.$ctx.find('.js-data').attr(this.fileSizeAttr));

			this._events.emit('BasicUpload.reduceFileSize', fileSize);

			// reset
			this.fileSize = 0;
			this.oldFileSize = this.fileSize;

			setTimeout(() => {

				if (this.$btnClear.closest(this.uploadClass).is(':visible')) {

					const $rowWrap = this.$ctx.closest('.mm-row').length ? this.$ctx.closest('.mm-row') : this.$ctx.closest('.ll-row');

					let $cloneBtn = $($rowWrap).find(this.cloneButton),
						$uploadBtn = $($rowWrap).find(this.uploadButton);

					// hide clone row btn
					if ($rowWrap.hasClass('mm-row')) {

						if ($cloneBtn.length) {
							this._events.emit(`${this.eventBtnFadeAnimate + $cloneBtn.data('t-id')}`, 'hide');
						}

						let unstoredUpload = false;

						// check if upload btn should be hidden
						$rowWrap.find('.m-basic-upload:visible').each((index, uploadElem) => { //NOSONAR
							if ($(uploadElem).find('.js-data.is-active:not(.js-file-stored)').length) {
								unstoredUpload = true;
								return false;
							}
						});

						if (!unstoredUpload && $uploadBtn.length) {
							this._events.emit(`${this.eventBtnFadeAnimate + $uploadBtn.data('t-id')}`, 'hide');
						}
					}

					// look for sibling rows in ll-row mark ups
					else if ($rowWrap.hasClass('ll-row')) {
						$cloneBtn = $rowWrap.nextAll(this.cloneButtonRow).find(this.cloneButton).eq(0);
						$uploadBtn = $rowWrap.nextAll(this.cloneButtonRow).find(this.uploadButton).eq(0);

						if ($cloneBtn.length) {
							this._events.emit(`${this.eventBtnFadeAnimate + $cloneBtn.data('t-id')}`, 'hide');
						}

						if ($uploadBtn.length) {
							this._events.emit(`${this.eventBtnFadeAnimate + $uploadBtn.data('t-id')}`, 'hide');
						}
					}
				}

			}, 200);

			this._events.emit('BasicUpload.fileRemoved', this.$ctx.find('input[type=file]'));

			// remove cloned row (call logic in utils.form func)
			if (this.$ctx.next('.js-clone-remove:visible').length && !this.$ctx.closest(this.cloneSourceTrue).find(this.cloneRemove).length) {
				S.Utils.Form.removeClonedRow(this.$ctx.next(this.cloneRemove));
			}
		}
	});
}(jQuery));
