'use strict';
(function ($) {
	/**
	 * BasicSearchResults module implementation.
	 *
	 * @author Igor Iric <igor.iric@namics.com>
	 * @namespace T.Module
	 * @class BasicSearchResults
	 * @extends T.Module
	 */
	T.Module.BasicSearchResults = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		results: null,

		apiSearchUrl: null,

		typeaheadSelection: null,
		page: 1,

		$moreBtn: null,

		//We need qeng and pages in order to triger paging
		qeng: undefined,
		pages: undefined,

		_serachRecommendationRequested: false,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this._queryInput = T.Utils.Helper.getUrlParameter("q");
			this.$moreBtn = this.$ctx.find('.js-more-btn');
			this._init();
			this._readConfig();
			this._registerListeners();
			this._submitSearchRequest(this.page);
			resolve();
		},

		_init: function _init() {
			this._showResults();
		},

		_readConfig: function _readConfig() {
			this.apiSearchUrl = this.$ctx.data("apisearchurl");
			this._apiSearchRecommendationUrl = this.$ctx.data("apisearchrecommendationurl");
			this._apiM = T.Utils.Application.getApiM();
			this._apiMsubscription = T.Utils.Application.getApiMSubscriptionKey();

			this.apisubscription = this.$ctx.data("apisubscription");
			if ('default' === this.apisubscription) {
				this.apisubscription = this._apiMsubscription;
			}

			this._$templatedetails = this.$ctx.find(".js-details-template").clone();
			this._$templatedetails.removeClass('js-details-template');
			this._$templatedetails.removeAttr('data-t-id');
			this._$templatedetails.removeClass('h-hidden');
			let contentFields = this.$ctx.data('contentfields');
			if (!contentFields || !contentFields.length) {
				contentFields = [{ query: "content", result: "content" }];
			}
			this._queryContentFields = [];
			this._resultContentFields = [];
			contentFields.forEach((item) => {
				this._queryContentFields.push(item.query);
				this._resultContentFields.push(item.result);
			});

			this._$articleSuggestionWrapper = this.$ctx.find('.js-article-suggestion-wrapper');
			this._$articleSuggestion = this.$ctx.find('.js-article-suggestion');
		},

		_registerListeners: function _registerListeners() {
			this.$moreBtn.on('click', this._loadNextPage.bind(this));
		},

		_errorCallback: function _errorCallback() {
			this.$moreBtn.toggleClass("h-hidden", true);
			this.$ctx.find(".js-error").toggleClass("h-hidden", false);
			T.Utils.View.stopLoader();
			this._setResultCount(0);
		},

		_setResultCount: function _setResultCount(count) {
			this.$ctx.find(".js-result-count").text(this._alternateSearch ? 0 : count);
			this.$ctx.find(".js-altresult-count").text(this._alternateSearch ? count : 0);
		},

		_setSearchTerm: function _setSearchTerm(queryString) {
			this.$ctx.find(".js-search-term").text(this._alternateSearch || queryString);
			if (this._alternateSearch) {
				this.$ctx.find(".js-search-origterm").text(queryString);
			}
			this.$ctx.find(".js-altsearch-title").toggleClass('h-hidden', !this._alternateSearch);
			this.$ctx.find(".js-altsearch-desc").toggleClass('h-hidden', !this._alternateSearch);
		},

		_setAltTerm: function _setAltTerm(alternative) {
			if (alternative) {
				this.$ctx.find(".js-altsearch").attr("href", T.Utils.Helper.updateUrlParameter({ q: alternative.query_expr.unparsed }, window.location.href));
				this.$ctx.find(".js-altsearch").removeClass("h-hidden");
				this.$ctx.find(".js-search-altterm").html(alternative.html);
			}
		},

		_showResults: function _showResults() {
			this.$ctx.find(".js-with-search").toggleClass("h-hidden", !this._queryInput);
			this.$ctx.find(".js-no-search").toggleClass("h-hidden", this._queryInput);

			const hasResults = this._queryInput && ("0" !== this.$ctx.find(".js-result-count").first().text() || "0" !== this.$ctx.find(".js-altresult-count").first().text());
			this.$ctx.find(".js-search-results").toggleClass("h-hidden", !hasResults);
			const $noresults = this.$ctx.find(".js-search-noresult");
			$noresults.toggleClass("h-hidden", hasResults);
			if (!hasResults) {
				// necessary for components rendered in hidden DOM, they will be reinitialized
				$noresults.find('[data-t-id]').attr('data-t-id', null);
				$noresults.find('[style]').attr('style', null);
			}
		},

		_submitSearchRequest: function _submitSearchRequest(page) {
			if (!this._queryInput || !this._queryInput.length) {
				return;
			}
			const count = 20;
			const searchWithPaging = {
				"max_page_count": 20,
				"query": {
					"unparsed": this._queryInput
				},
				"query_context_user_query": this._queryInput,
				"sort_date_facets_by_value": true,
				"content_sample_length": 230,
				"count": count,
				"alternatives_query_spelling_max_estimated_count": 50,
				"alternatives": true,
				"show_query_spelling_alternatives": true,
				"exclude_available_properties_and_facets": true,
				"result_pages": {
					"page_size": count
				},
				"properties": [{
					"name": "title",
					"formats": ["HTML"]
				}, {
					"name": "url",
					"formats": ["VALUE"]
				}, {
					"name": "publication-date",
					"formats": ["VALUE"]
				}]
			};
			this._queryContentFields.forEach((field) => {
				searchWithPaging.properties.push({
					name: field,
					formats: ["HTML"]
				});
			});

			if (this.pages) {
				searchWithPaging.result_pages.pages = this.pages[page - 1];
				searchWithPaging.result_pages.pages["current_page"] = true;
			}

			if (this.qeng) {
				searchWithPaging.result_pages["qeng_ids"] = this.qeng;
			}

			this._requestData(searchWithPaging);
		},

		_requestData: function _requestData(searchData) {
			T.Utils.View.startLoader();

			const options = {
				type: 'POST',
				url: this.apiSearchUrl,
				data: JSON.stringify(searchData)
			};
			if (this.apisubscription) {
				options.headers = { "Ocp-Apim-Subscription-Key": this.apisubscription };
			}
			T.Utils.Ajax.fragment(options, this._handleGlobalSearchResults.bind(this), this._errorCallback.bind(this));
		},

		_handleGlobalSearchResults: function _handleGlobalSearchResults(data) {
			if (data) {
				this._setResultCount(data.estimated_count);
				this._setSearchTerm(this._queryInput);
				delete this._alternateSearch;
				if (data.show_query_spelling_alternatives && data.alternatives) {
					this._handleAlternateSearch(data);
				}
				if (!this._alternateSearch) {
					this._showResults();
					this.$moreBtn.toggleClass("h-hidden", data.estimated_count <= 20);
					this.$ctx.find('.js-article-link').attr("data-t-name", "BasicLink");
					if (data.resultset) {
						if (data.resultset.result_pages) {
							this.qeng = data.resultset.result_pages.qeng_ids;
							this.pages = data.resultset.result_pages.pages;
						}
						if (data.resultset.results) {
							data.resultset.results.forEach((item) => {
								this._createDetails(item);
							});
						}
					}
				}

				if (!this._serachRecommendationRequested) {
					this._requestSearchRecommendation();
				}
				else {
					this._initModulesAndStopLoader();
				}
			}
			else {
				T.Utils.View.stopLoader();
			}
		},

		_handleAlternateSearch: function _handleAlternateSearch(data) {
			for (let i = 0; i < data.alternatives.length; i++) {
				const alt = data.alternatives[i];
				if (alt.id === "query_spelling") {
					if (data.estimated_count) {
						this._setAltTerm(alt.entries[0]);
					} else {
						this._performAlternateSearch(alt.entries[0]);
					}
					break;
				}
			}
		},

		_requestSearchRecommendation: function _requestSearchRecommendation() {
			const searchTerm = this._queryInput.trim();
			if (searchTerm && searchTerm.length > 0) {
				let url = T.Utils.Helper.appendURIPath(this._apiM, this._apiSearchRecommendationUrl);
				url = T.Utils.Helper.updateUrlParameter({ query: searchTerm }, url);

				const options = {
					type: 'GET',
					url: url,
					headers: {
						"Ocp-Apim-Subscription-Key": this._apiMsubscription
					},
					contentType: "application/json"
				};
				T.Utils.Ajax.fragment(options, this._handleSearchRecommendationSuccess.bind(this), this._handleSearchRecommendationError.bind(this));
			}
			else {
				this._initModulesAndStopLoader();
			}
		},

		_handleSearchRecommendationSuccess: function _handleSearchRecommendationSuccess(data) {
			if (data && data.Data) {
				const $title = this._$articleSuggestion.find('.js-article-suggestion-title');
				$title.text(data.Data.Title);
				T.Utils.View.setDataSafe($title, 'js-original-string', data.Data.Title);

				this._$articleSuggestion.find('.js-article-suggestion-link').attr("href", data.Data.Landingpage);
				this._$articleSuggestion.find('.js-article-suggestion-footer').text(data.Data.Landingpage);

				const $description = this._$articleSuggestion.find('.js-article-suggestion-description');
				T.Utils.View.setDataSafe($description, 'js-original-string', data.Data.Description);
				$description.text(data.Data.Description);

				this._$articleSuggestionWrapper.toggleClass('h-hidden', false);
				this.$ctx.find(".js-search-results").toggleClass("h-hidden", false);
				this._serachRecommendationRequested = true;
			}
			this._initModulesAndStopLoader();
		},

		_handleSearchRecommendationError: function _handleSearchRecommendationError() {
			this._$articleSuggestionWrapper.toggleClass('h-hidden', true);
			this._serachRecommendationRequested = true;
			this._initModulesAndStopLoader();
		},

		_initModulesAndStopLoader: function _initModulesAndStopLoader() {
			T.Utils.Helper.initAdditionalModules(this.$ctx);
			T.Utils.View.stopLoader();
		},

		_performAlternateSearch: function _performAlternateSearch(alternative) {
			if (alternative) {
				this._alternateSearch = this._queryInput;
				this._queryInput = alternative.query_expr.unparsed;
				this._submitSearchRequest(this.page);
			}
		},

		_loadNextPage: function _loadNextPage(e) {
			e.preventDefault();
			this.page++;
			this._submitSearchRequest(this.page);
		},

		_createDetails: function _createDetails(element) {
			if (!element || !element.properties || !element.properties.length) {
				return;
			}
			const $clone = this._$templatedetails.clone();
			const contentItems = {};
			element.properties.forEach((item) => {
				if (!item.data || !item.data.length) {
					return;
				}

				switch (item.name) {
					case "Title":
						$clone.find(".js-bs-Title").html(item.data[0].html.replace(/<\/?em>/g, ""));
						break;
					case "Address":
						if (item.data[0].value) {
							$clone.find(".js-bs-Url").text(item.data[0].value.str);
							$clone.find(".js-article-link").attr("href", item.data[0].value.str);
						}
						break;
					case "publication-date":
						if (item.data[0].value) {
							const publicationDateText = item.data[0].value.str;
							const date = moment(publicationDateText);
							if (date.isValid()) {
								$clone.find(".js-bs-PublicationDate").text(date.format("L"));
								$clone.find(".js-bs-PublicationDate").attr("datetime", publicationDateText);
							}
						}
						break;
					default:
						if (-1 < this._resultContentFields.indexOf(item.name)) {
							contentItems[item.name] = item;
						}
						break;
				}
			});
			this._makeResultContent(contentItems, $clone);
			this.$ctx.find(".js-result-details").append($clone);
		},

		_makeResultContent: function _makeResultContent(contentItems, $elm) {
			if (contentItems.content !== undefined) {
				delete contentItems.content;
			}

			this._resultContentFields.forEach((key) => {
				const item = contentItems[key];
				if (item && item.data[0].html) {
					const $last = $elm.find(".js-bs-Text").last();
					if ($last.html()) {
						$last.after(`<p class="js-bs-Text">${item.data[0].html}</p>`);
					} else {
						$last.html(item.data[0].html);
					}
				}
			});
		}
	});
}(jQuery));