// eslint-disable-next-line
(function ($) //NOSONAR Complexity
{
	'use strict';

	S.Utils = S.Utils || {};

	/**
	 * Holds a list of delayed calls.
	 *
	 * @type {{}}
	 * @private
	 */
	S.Utils._delayedCalls = {};

	/**
	 * Delays a function call and overrides existing calls in the queue.
	 *
	 * @param {string} name Queue name to allow multiple calls for different tasks
	 * @param {number} delay in milliseconds
	 * @param {function} callback Callback function
	 *
	 * @returns {number} Returns the "setTimeout()" id
	 */
	S.Utils.delayed = function (name, delay, callback) {
		if (!delay) {
			callback.call(this);

			return -1;
		}

		S.Utils.clearDelayed(name);

		S.Utils._delayedCalls[name] = setTimeout(callback.bind(this), delay);

		return S.Utils._delayedCalls[name];
	};

	/**
	 *
	 * @param {string} name Queue name to cancel
	 * @returns {boolean}
	 */
	S.Utils.clearDelayed = function (name) {
		if (S.Utils._delayedCalls[name]) {
			clearTimeout(S.Utils._delayedCalls[name]);

			S.Utils._delayedCalls[name] = 0;

			return true;
		}

		return false;
	};

	/**
	 * set global class is-ios
	 *
	 */
	if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
		$('.l-outer').addClass('is-ios');
	}

	/**
	 * set global class is-touch
	 *
	 */
	// eslint-disable-next-line
	if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
		$('.l-outer').addClass('is-touch');
	}

	S.Utils.Helper = {

		/**
		 * is touch device or not
		 *
		 */
		isTouch: function () {
			// eslint-disable-next-line
			if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) { //NOSONAR
				return true;
			} else {
				return false;
			}
		},

		/**
		 * is touch device or not
		 *
		 */
		isIOS: function () {
			return /iPad|iPhone|iPod/.test(navigator.userAgent);
		},

		/**
		 * if changed also update $breakpoints in /assets/css/partials/config.scss!
		 *
		 */
		breakpoints: {
			'mobile': 'only screen and (max-width: #{em(767px)})',
			'tablet': 'only screen and (min-width: #{em(768px)})',
			'desktop': 'print, only screen and (min-width: #{em(1025px)})',
			'desktop-l': 'print, only screen and (min-width: #{em(1220px)})',
			'desktop-xl': 'print, only screen and (min-width: #{em(1420px)})',
			'print': 'print'
		},

		/**
		 * Matches a given mediaquery type.
		 *
		 * Examples:
		 * <pre>if (S.Utils.Helper.mq('tablet').matches) { ... }</pre>
		 * <pre>S.Utils.Helper.mq('tablet').addListener(function (mq) {
		 *      if (mq.matches) {
		 *          alert('browser resize, tablet now matches!');
		 *      }
		 *      else {
		 *          alert('browser resize, tablet does not match!');
		 *      }
		 * });</pre>
		 *
		 * @param {string} type
		 * @param {boolean} [ifUnsupported=false]
		 * @returns {MediaQueryList}
		 */
		mq: function (type, ifUnsupported) {
			let result;

			if (S.Utils.Helper.breakpoints[type]) {
				if (window.matchMedia) {
					// turns "#{em(768px)}" into "48em"
					const media = S.Utils.Helper.breakpoints[type].replace(/#\{em\((\d+)px\)}/, function (match, p1) {
						return `${p1 / 16}em`;
					});

					result = window.matchMedia(media);
				}
				else {
					if (typeof ifUnsupported === 'undefined') {
						ifUnsupported = false;
					}

					// fallback for <= IE9 etc.
					result = {
						matches: ifUnsupported,
						addListener: function () { },
						removeListener: function () { }
					};
				}
			}

			return result;
		},

		/**
		 * count
		 */
		countNewPrice(newPrice, oldPrice, target) {
			let add = false;

			if (newPrice > oldPrice) {
				add = true;
			}

			//
			const interval = setInterval(function () {

				if (add) {
					if (oldPrice >= newPrice) {
						clearInterval(interval);
						target.textContent = `${newPrice} €`;
					}
					else {
						oldPrice += 0.5;
						target.textContent = `${oldPrice.toFixed(2)} €`;
					}
				}

				else {
					if (oldPrice <= newPrice) {
						clearInterval(interval);
						target.textContent = `${newPrice} €`;
					}
					else {
						oldPrice -= 0.5;
						target.textContent = `${oldPrice.toFixed(2)} €`;
					}
				}
			}, 50);

		},

		shortenTable(module, count) {

			const lessBtn = module.find('.js-less');
			const moreBtn = module.find('.js-more');
			const tableRows = module.find('.mm-table tbody tr');
			const filterSelector = '.is-filter';
			const filterHiddenSelector = 'is-filter-hidden';

			for (let i = count; i < (tableRows.length); i++) {
				tableRows.eq(i).addClass('is-filter');
			}

			lessBtn.hide();
			tableRows.filter(filterSelector).addClass(filterHiddenSelector);
			module.removeClass('is-open');

			lessBtn.on('click', () => {
				lessBtn.hide();
				moreBtn.show();
				tableRows.filter(filterSelector).addClass(filterHiddenSelector);
				module.removeClass('is-open');
			});

			moreBtn.on('click', () => {
				moreBtn.hide();
				lessBtn.show();
				tableRows.filter(filterSelector).removeClass(filterHiddenSelector);
				module.addClass('is-open');
			});
		},

		/**
		 * returns specific module instance
		 * (with context -> this.$ctx etc.)
		 *
		 * usage:
		 * const neededInstance = S.Utils.Helper.getModuleInstance(<jQuery|HTMLElement>);
		 *
		 * reference sandbox:
		 * https://github.com/brunschgi/terrificjs#sandbox
		 *
		 * @param {jQuery|HTMLElement|Number} input :: element with an T-ID or just T-ID
		 * @return {*}                              :: null when nothing is found
		 */
		getModuleInstance(input) {
			const context = input instanceof jQuery ? input.get(0) : input;

			if (context) {
				const context = input instanceof jQuery ? input.get(0) : input,
					sandbox = document.applicationInstance?._sandbox;

				let id;

				if (context instanceof Element) {
					id = parseInt(context?.getAttribute('data-t-id'), 10);
				}
				else {
					id = typeof context === 'number' ? context : parseInt(context, 10);
				}

				return id ? sandbox?.getModuleById(id) : null;
			}

			return null;
		},

		/**
		 * get the nearest module with an data-t-id
		 * - returns the input context if it has a data-t-id
		 * @param input
		 * @param onlyEl                            :: state to change, if you only want the jQuery-Element back, not the instance
		 */
		getTheNearestModuleInstance(input, onlyEl) {
			const $context = input instanceof jQuery ? input : $(input),
				$nearestModule = $context.data('t-id') ? $context : $context.closest('[data-t-id]');

			return onlyEl ? $nearestModule : this.getModuleInstance($nearestModule);
		},

		/**
		 * just check if string is a number
		 * @param entry
		 * @return {boolean}
		 */
		checkForNumberFromString(entry) {
			return !isNaN(entry) && /^\d+$/.test(entry);
		},

		/**
		 * checks for boolean values as param value
		 * @param value
		 * @return {boolean}
		 * @private
		 */
		_checkForBooleanValueFromAString(value) {
			let val;

			switch (value) {
				case 'true':
				case 'True':
				case 'TRUE':
					val = true;
					break;
				case 'false':
				case 'False':
				case 'FALSE':
					val = false;
					break;
				default:
					val = value;
			}

			return val;
		},
	};


})(jQuery);
