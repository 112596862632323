(function ($) {
	'use strict';
	/**
	 * BasicTableRestyle module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicTableRestyle
	 * @extends T.Module
	 */
	T.Module.BasicTableRestyle = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Boolean} */
		$isResizing: false,

		/** @type {Boolean} */
		reverseOrder: false,

		/** @type {Boolean} */
		hasImages: false,

		/** @type {Boolean} */
		$isImage: false,

		/** @type {Number} */
		$cellNum: 0,

		/** @type {Object} */
		$td: [],

		/** @type {Boolean} */
		keywordfilterResultMode: false,

		/** @type {jQuery} */
		$oldClickableSortButton: null,

		/** @type {boolean} */
		hasSrc: false,

		/** @type {String} */
		srcText: null,

		/** @type {String} */
		srcImg: null,

		/** @type {String} */
		srcAlt: null,

		classHighlight: 'is-highlight',



		start: function (resolve) {
			this.$ctx = $(this._ctx);

			const that = this;
			this.reverseOrder = false;
			this.hasImages = false;
			this.$td = this.$ctx.find('.mm-table > tbody > tr:first-child > td');
			this.$cellNum = this.$ctx.find('.mm-table > thead > tr > th').length;
			this.shortenTable();

			// init sort
			this.$ctx.find('.js-sort').closest('th').on('click', function () {
				that.initSorting.apply(that, [this]);
			});

			this.$ctx.find('.js-sort').closest('th').addClass('is-clickable');

			resolve();
		},

		/**
		 * checkRowNumber
		 *
		 * @param mod {String} - optional / 'filterUpdate' or 'default'
		 */
		checkRowNumber: function (mod) {
			const selectorTable = '.mm-table > tbody > tr';
			// standard
			if (this.$ctx.find('.mm-table > tbody > tr:visible, .mm-toggletext-content > table > tbody > tr:visible').length <= 3) {
				if (this.$ctx.closest('.js-basickeywordfilter-result').length) {
					this.$ctx.find(selectorTable).removeClass('is-odd is-even');

					// remove hightlighting after filter was updated
					if (mod === 'filterUpdate') {
						this.$ctx.find(selectorTable).removeClass(this.classHighlight);
					}
				}
			}

			else {

				if (this.$ctx.closest('.js-basickeywordfilter-result').length) {
					this.$ctx.find(selectorTable).removeClass('is-odd is-even');

					// remove hightlighting after filter was updated
					if (mod === 'filterUpdate') {
						this.$ctx.find(selectorTable).removeClass(this.classHighlight);
					}

					this.$ctx.find('.mm-table > tbody > tr:visible').each((index, indexItem) => {
						if (index % 2 === 0) {
							$(indexItem).addClass('is-odd');

							return;
						}
						$(indexItem).addClass('is-even');
					});
				}
			}
		},


		/**
		 * Function to sort table Elements
		 */
		sortContentRows: function (a, b, targetIndex) {
			const that = this;

			const first = (that.reverseOrder) ? a : b;
			const second = (that.reverseOrder) ? b : a;
			const sortTable = '.js-sortable-text';

			if (that.hasImages) {
				return ($(first).children().eq(targetIndex).find('img.js-sortable').attr('title').trim() < $(second).children().eq(targetIndex).find('img').attr('title').trim()) ? 1 : -1; //NOSONAR
			}
			else {
				const $firstItemToCheck = $(first).children().eq(targetIndex),
					$secondItemToCheck = $(second).children().eq(targetIndex);

				if ($firstItemToCheck.find(sortTable).length) {
					// const checkForNumber = parseInt($firstItemToCheck.find('.js-sortable-text').text().trim()),
					const $firstItem = $firstItemToCheck.find(sortTable).text().trim(),
						$secondItem = $secondItemToCheck.find(sortTable).text().trim();

					return that.naturalCompare($firstItem, $secondItem);
				}
				else {
					// if you need to filter and there is NO js-sortable-text class set
					return that.naturalCompare($firstItemToCheck.text().trim(), $secondItemToCheck.text().trim());
				}
			}
		},

		/**
		 * Natural Sorting
		 */
		naturalCompare: function (a, b) {
			return b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' });
		},

		/**
		 * Function to sort table Elements
		 */
		sortHeadings: function (a, b, targetIndex) {

			const that = this;

			const first = (that.reverseOrder) ? a : b;
			const second = (that.reverseOrder) ? b : a;

			return (first.headlineObject.find('> td').eq(targetIndex).text().trim() > second.headlineObject.find('> td').eq(targetIndex).text().trim()) ? 1 : -1;

		},

		/**
		 * sort Table Function
		 */
		initSorting: function (target) {

			const that = this,
				$target = $(target),
				targetIndex = $target.closest('th').index(),
				$tableBody = that.$ctx.find('tbody'),
				$allRows = $tableBody.find('tr'),
				classSortActive = 'js-sort--active',
				sortable = [];
			let pos;

			that.hasImages = ($allRows.eq(0).find('> td').eq(targetIndex).find('img.js-sortable').length > 0);
			that.reverseOrder = (!that.reverseOrder);

			// check if the user clicks the same filter-button (small triangle) again, if not close all and open the clicked ones
			if ($target.get(0) === this.$oldClickableSortButton) {
				$target.find('.js-sort').toggleClass(classSortActive);
			}
			else {
				$('.js-sort').removeClass(classSortActive);
				$target.find('.js-sort').addClass(classSortActive);
			}

			this.$oldClickableSortButton = $target.get(0);

			$allRows.each(function (i) {

				const $currentRow = $(this);

				if (i === 0 || $currentRow.hasClass('mm-subheading')) {

					pos = sortable.push({ headlineObject: '', contentTrs: [] }) - 1;

				}

				if ($currentRow.hasClass('mm-subheading')) {

					sortable[pos].headlineObject = $currentRow;

				}
				else {

					sortable[pos].contentTrs.push($currentRow);

				}

				if ($currentRow.hasClass(that.classHighlight)) {
					$currentRow.removeClass(that.classHighlight);
				}

			});

			that.$ctx.find('tbody').remove();

			sortable.sort(function (a, b) {
				return that.sortHeadings.apply(that, [a, b, targetIndex]);
			});

			$.each(sortable, function (i) {

				const tmp = $('<tbody>');

				sortable[i].contentTrs.sort(function (a, b) {
					return that.sortContentRows.apply(that, [a, b, targetIndex]);
				});

				$.each(sortable[i].contentTrs, function (x) {

					sortable[i].contentTrs[x].prependTo(tmp);

				});

				if (sortable[i].headlineObject) {

					sortable[i].headlineObject.prependTo(tmp);

				}

				that.$ctx.find('.mm-table').append(tmp);

			});

			this.checkRowNumber('default');

		},

		shortenTable: function () {
			const count = parseInt(this.$ctx.data('table-short-count')) * 2;
			S.Utils.Helper.shortenTable(this.$ctx, count);
		},

	});
}(jQuery));