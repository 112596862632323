/// <reference path="../../../../assets/typings/terrific-ext.d.ts" />

'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) {
	const SESSIONKEY_ERROR = "oauth.error";

	/**
	 * OAuthFeedbackHandler module implementation.
	 *
	 * @author  <dmitri.zoubkov@mtc.berlin>
	 * @memberof T.Module
	 * @class OAuthFeedbackHandler
	 * @extends T.Module
	 */
	T.Module.OAuthFeedbackHandler = T.createModule({
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this._readConfig();
			this._processStatus();
			resolve();
		},
		_readConfig: function _readConfig() {
			this._valuePlaceholder = this.$ctx.data('value-placeholder') || "";
			this._reqParams = this.$ctx.data('request-params') || ["error", "error_description"];
			this._reqPattern = new RegExp(`(\\?|#|&)${this._reqParams.join("|")}=`, 'g');

			this._errPattern = [];
			let conf = this.$ctx.data('corr-pattern') || "(Correlation ID):\\s+([^\\r\\n]+)";
			this._errPattern.push(new RegExp(conf));
			conf = this.$ctx.data('ts-pattern') || "(Timestamp):\\s+([^\\r\\n]+)";
			this._errPattern.push(new RegExp(conf));
			conf = this.$ctx.data('code-pattern') || "([\\w\\d]+):\\s+([^\\r\\n]+)";
			this._errPattern.push(new RegExp(conf));
		},
		_processStatus: async function _processStatus() {
			const isLoggedIn = await T.Utils.Auth.isLoggedIn();

			const needsIdentity = this.$ctx.data("reload-identity") && isLoggedIn;
			if (needsIdentity) {
				T.Utils.Store.set('Application.Identity', null, T.Utils.Store.SESSION);
			}

			if (this._isErrorRequest()) {
				if (this._isPwChangedError()) {
					T.Utils.Auth.logout(false);
					window.location.replace(window.location.pathname);
				}
				else {
					this._storeStatusAndReload();
				}
			} else {
				if (this._isIdTokenRequest()) {
					if (needsIdentity) {
						T.Utils.Auth.clearToken();
						T.Utils.Auth.authorize();
					} else {
						window.location.replace(window.location.href.replace(window.location.hash || window.location.search, ""));
					}
				}
				this._displayStatus();
			}
		},
		_isIdTokenRequest: function _isIdTokenRequest() {
			const matches = (window.location.hash || window.location.search).match(/(#|\?|&)(id_token)=/g); //NOSONAR
			return matches && 1 === matches.length;
		},
		_isErrorRequest: function _isErrorRequest() {
			let matches = window.location.search.match(this._reqPattern);
			if (matches && this._reqParams.length === matches.length) {
				return true;
			}
			matches = window.location.hash.match(this._reqPattern);
			return matches && this._reqParams.length === matches.length;
		},
		_isPwChangedError: function _isPwChangedError() {
			const err = this._parseRequest();
			const expectedError = {
				error: "access_denied",
				message: /^AAD_Custom_Error_PasswordChanged\b/
			};

			return err.error === expectedError.error && expectedError.message.test(err.error_description);
		},
		_parseRequest: function _parseRequest() {
			const req = window.location.hash || window.location.search;
			if (req && 1 < req.length) {
				const result = {};
				const urlArray = req.substr(1).split("&");
				urlArray.forEach(function (value) {
					const [key, val] = value.split("=");
					result[key] = decodeURIComponent(val.replace(/\+/g, "%20"));
				});
				this._parseErroDescription(result);
				// eslint-disable-next-line eqeqeq
				if (undefined != this._valuePlaceholder) { // NOSONAR
					['error_code', 'error_text', 'correlation_id', 'timestamp'].forEach((key) => {
						if (!result[key]) {
							result[key] = this._valuePlaceholder;
						}
					});
				}
				return result;
			}
			return null;
		},
		// eslint-disable-next-line sonarjs/cognitive-complexity
		_parseErroDescription: function _parseErroDescription(result) { //NOSONAR
			if (result.error_description) {
				const descArray = result.error_description.split("\r\n");
				let isSturct = false;
				descArray.forEach((line) => {
					let hasMatch = false;
					if (!result.correlation_id) {
						const matches = line.match(this._errPattern[0]);
						if (matches && 3 === matches.length) {
							result.correlation_id = matches[2];
							hasMatch = true;
						}
					}
					if (!hasMatch && !result.timestamp) {
						const matches = line.match(this._errPattern[1]);
						if (matches && 3 === matches.length) {
							try {
								result.timestamp = (new Date(matches[2])).toLocaleString();
							} catch (e) {
								result.timestamp = matches[2];
							}
							hasMatch = true;
						}
					}
					if (!hasMatch && !result.error_code) {
						const matches = line.match(this._errPattern[2]);
						if (matches && 3 === matches.length) {
							result.error_code = matches[1];
							result.error_text = matches[2];
							hasMatch = true;
						}
					}
					if (hasMatch) {
						isSturct = true;
					}
				});
				if (!isSturct && !result.error_text) {
					result.error_text = result.error_description;
				}
			}
		},
		_storeStatusAndReload: function _storeStatusAndReload() {
			T.Utils.Store.set(SESSIONKEY_ERROR, this._parseRequest(), T.Utils.Store.SESSION);
			window.location.replace(window.location.pathname);
		},
		_displayStatus: function _displayStatus() {
			const lastError = T.Utils.Store.get(SESSIONKEY_ERROR, T.Utils.Store.SESSION);
			$(document.body).data("oauthfeedback", "true");
			T.Utils.Store.set(SESSIONKEY_ERROR, null, T.Utils.Store.SESSION);
			this._showSuccess(!lastError);
			if (lastError) {
				this._showError(lastError);
			}
		},
		_showSuccess: function _showSuccess(show) {
			this.$ctx.find(".js-success").toggleClass('h-hidden', !show);
			this.$ctx.find(".js-initial").toggleClass('h-hidden', true);
			if (show) {
				this.$ctx.find(".js-errortext,.js-errorheadline,.js-errorsubheadline").toggleClass('h-hidden', true);
			}
		},
		_showError: function _showError(err) {
			this._renderErrorElement('errorheadline', err);
			this._renderErrorElement('errorsubheadline', err);
			this._renderErrorElement('errortext', err);
		},
		_renderErrorElement: function _renderErrorElement(type, err) {
			const code = err.error_code || err.error;
			let $elm = this.$ctx.find(`.js-${type}.js-error-${code}`);
			if (!$elm.length) {
				$elm = this.$ctx.find(`.js-${type}.js-error`);
			}
			$elm.html($elm.html().interpolate(err));
			$elm.toggleClass('h-hidden', false);
		}
	});
})(jQuery);