(function ($)
{
	class InputcopyComponent extends HTMLElement {
		constructor() {
			super();
			this.$ctx = null;
			this.$btns = null;
			this.$input = null;
			this.$message = null;
		}

		connectedCallback() {
			setTimeout(() => { // needed to delay the connect callback, else the dom inside is not accessible
				this.$ctx = $(this);
				this.$btns = this.$ctx.find('.js-copy-link');
				this.$input = this.$ctx.find('.js-copy-input');
				this.$message = this.$ctx.find('.aa-success-message');
				this.initCopyControls();
			});
		}

		//custom functions start here!

		initCopyControls() {
			this.$btns.on('click', (e) => {
				e.preventDefault();
				this.copyTextToClipboard();
			});

			this.$btns.on('keyup', (e) => {
				if(e.key === 'Enter') {
					e.preventDefault();
					this.copyTextToClipboard();
				}
			});
		}

		copyTextToClipboard() {
			navigator.clipboard.writeText(this.$input.val());
			this.$message.fadeIn();
		}
	}

	customElements.define( 'inputcopy-component',InputcopyComponent);
}(jQuery));
