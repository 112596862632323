/**
 * HeadlessLoginProcessor module implementation.
 *
 * @author  <dmitri.zoubkov@mtc.berlin>, <marc.radziwill@merkle.com>
 * @class HeadlessLoginProcessor
 * @extends BasisComponent
 */

class HeadlessLoginProcessor extends BasisComponent {

	async _connectedCallback() {
		T.Utils.Auth.getResolvedIdentity(await this.init.bind(this));
	}

	async init() {
		if (T.Utils.Helper.getUrlParameter('logoutState')) {
			this._processLogoutRequest();
		} else if (T.Utils.Helper.getUrlParameter('registerState')) {
			this._processRegistrationRequest();
		} else {
			await this._processRedirectRequest();
		}
	}

	async _processRedirectRequest() {
		const processUrl = this._getProcessUrl();
		const isLoggedIn = await T.Utils.Auth.isLoggedIn();
		if (processUrl) {
			if (isLoggedIn) {
				window.location.href = processUrl;
			} else {
				T.Utils.Auth.authorize();
			}
		} else {
			window.location.href = "/";
		}
	}

	async _processRegistrationRequest() {
		window.location.href = await T.Utils.Auth.makeRegisterURL();
	}

	async _processLogoutRequest() {
		const processUrl = this._getProcessUrl();
		if (processUrl) {
			await T.Utils.Auth.logoutFinally(processUrl);

		} else {
			window.location.href = "/";
		}
	}

	_getProcessUrl() {
		let result = T.Utils.Helper.getUrlParameter("processUrl");
		if (!this._isAcceptableUrl(result)) {
			T.Utils.Logger.info(`HeadlessLoginProcessor: parameter not acceptable processUrl=${result}, processing denied`);
			result = "";
		}
		return result;
	}
	_isAcceptableUrl(uri) {
		return window.location.hostname.toLowerCase() === (new URL(uri, window.location.href)).hostname.toLowerCase();
	}

}

window.customElements.define('headless-login-processor', HeadlessLoginProcessor);