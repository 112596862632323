(function ($)
{
    'use strict';

    /**
     * BasicForm module implementation.
     *
     * @author  <w.eberts@edelweiss72.de>
     * @namespace T.Module
     * @class BasicForm
     * @extends T.Module
     */
    T.Module.BasicForm = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        Storage: {},

        /** @type {T.Module[]} */
        modules: [],

        /** @type{T.Module[][]} */
        rows: [],

        params: {
            "apigateway": "/api/form/data/init.json"
        },

        data: {},

        config: {},

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);

            this.jsAsideOpen = '.js-aside-open';

            // würde nach attribut data-test suchen und bei if boolean zurückliefern
            if(this.$ctx.data('run-status')){
                const callCalc = () => {
                    this.calcStep();
                };

                window.addEventListener('scroll',  callCalc,  { passive: true });
            }

            this._events.on('t.sync', this.sync.bind(this));

            // init smartphone + tablet
            if (!S.Utils.Helper.mq('desktop').matches){
                const that = this;

                // show membership contribution layer
                this.$ctx.find(this.jsAsideOpen).off('click').on('click', (e) =>
				{
                    // layer bg dark
                    const callback = () =>
					{
                        // fade layer, show button
                        that.$ctx.find('.js-aside').fadeOut();
                        that.$ctx.find(this.jsAsideOpen).fadeIn();
                    };

                    S.Utils.LayerBgDark.init($(e.currentTarget).find(this.jsAsideOpen), callback);
                    $(e.currentTarget).fadeOut();
					$(e.currentTarget).siblings('.js-aside').fadeIn();
                });
            }

            // initDepend
            this.initDepends();

            // init listener click events
            this._addEventListener();

            // count characters in textarea
            this.countTextareaCharacters();

            resolve();
        },

        /**
         *
         *
         */
		// eslint-disable-next-line
        initDepends: function(){ //NOSONAR complexity
			const dataDependString = 'data-depend',
				basicInputRadio = '.a-basic-input-radio',
				basicInputCheckbox = '.a-basic-input-checkbox';

            // special case with only one radiobutton in a row and dependend content
            this.$ctx.find('.oo-depend').each(function(index, dependContent){
                // get data-depend
                const dependData = $(dependContent).attr(dataDependString);

                // if depend controler is a parent radiobutton
                if ($(dependContent).parents(basicInputRadio).length){
                    const dependRadio = $(dependContent).closest(basicInputRadio).find('input').eq(0),
                    dependRadioName = dependRadio.attr('name');

                    $(`input[name=${dependRadioName}]`).each(function(index, item){
                        $(item).on('change', function(){
                            if (dependRadio.prop('checked')){
                                $(dependContent).stop().slideDown(function (){
                                    $(dependContent).css('height', '');
                                });
                            }else{
                                $(dependContent).stop().slideUp(function (){
                                    $(dependContent).css('height', '');
                                });
                            }
                        });
                    });
                }

                // if depend controler is a parent checkbox
                else if ($(dependContent).parents(basicInputCheckbox).length){

                    const dependCheckbox = $(dependContent).closest(basicInputCheckbox).find('input').eq(0);
                    dependCheckbox.on('change', function(){
                        if (dependCheckbox.prop('checked')){
                            $(dependContent).stop().slideDown(function (){
                                $(dependContent).css('height', '');
                            });
                        }else{
                            $(dependContent).stop().slideUp(function (){
                                $(dependContent).css('height', '');
                            });
                        }
                    });

                }

                // if depend controler is a checkbox in a prev container
                else if (dependData === $(dependContent).prev().attr(dataDependString)){
                    if ($(dependContent).prev().find(basicInputCheckbox).length){

                        const dependCheckbox = $(dependContent).prev().find('.a-basic-input-checkbox input').eq(0);
                        dependCheckbox.on('change', function(){
                            if (dependCheckbox.prop('checked')){
                                $(dependContent).stop().slideDown(function (){
                                    $(dependContent).css('height', '');
                                });
                            }else{
                                $(dependContent).stop().slideUp(function (){
                                    $(dependContent).css('height', '');
                                });
                            }
                        });
                    }
                }
                // if depend controller is a radiobutton in a prev container
                else if ($(dependContent).prev().find(basicInputRadio).length){
                    const $radioButtons = $(dependContent).prev().find('.a-basic-input-radio input');

                    $radioButtons.on('change', function(){
                        if ($radioButtons.prop('checked') && $(this).attr(dataDependString) === dependData){
                            $(dependContent).stop().slideDown(function (){
                                $(dependContent).css('height', '');
                            });
                        }else{
                            $(dependContent).stop().slideUp(function (){
                                $(dependContent).css('height', '');
                            });
                        }
                    });
                }
            });
        },

        _changeSelection: function (ev) {
            this.$ctx
                .find(`.o-mgl-selector .oo-content > div[data-section="${jQuery(this).attr('id')}"]`)
                .addClass('js-mgl-selector-active')
                .siblings('.js-mgl-selector-active')
                .removeClass('js-mgl-selector-active');
            this.Storage.set(ev.currentTarget.name, $(ev.currentTarget).val());
        },

        /**
         * Animate or set the status indicator.
         *
         * Usage:
         * <pre>
         *   // 100% of step 1
         *   this.setStatus(1, 1);
         *
         *   // 50% of step 2
         *   this.setStatus(0.5, 2);
         *
         *   // 30% of all steps
         *   this.setStatus(1/3);
         *
         *   // 80% of all steps, no animation
         *   this.setStatus(0.8, null, 0);
         * </pre>
         *
         * @param {Number} percent
         * @param {Number} [step=null]
         * @param {Number|String} [duration='slow']
         */
        setStatus: function (percent, step, duration)
        {
            let status = 0,
                $step = null;
			const totalWidth = this.$container.innerWidth();

            if (this.lastPercent === percent && this.lastStep === step)
            {
                return;
            }

            // percentage is based on step
            if (typeof step === 'number')
            {
                $step = this.$steps.eq(step - 1);

                // special handling for smartphone
                if (!this.tablet.matches)
                {
                    // since steps are not proportial on smartphone, make sure to set active before calculation
                    $step.addClass('is-active');
                    this.$steps.not($step).removeClass('is-active');
                }

                const width = $step.outerWidth(),
                    position = $step.position();

                status = (position.left / totalWidth) + percent * (width / totalWidth);
            }
            // total percentage
            else
            {
                const calcStep = Math.max(0, Math.ceil(percent * this.$steps.length) - 1);

                $step = this.$steps.eq(calcStep);
                status = percent;
            }

            // set active step
            $step.addClass('is-active');
            this.$steps.not($step).removeClass('is-active');

            // keep values
            this.lastStep = step;
            this.lastPercent = percent;

            this.$indicator.stop().animate(
                {
                    width: `${Math.min(100, status * 100)  }%`
                },
                duration
            );
        },

        formToJSON: function (selector) {
            let form = {};
            $(selector).find(':input[name]:enabled').each(function () {
                const self = $(this);
                const name = self.attr('name');
                if (form[name]) {
                    const depth = name.split('.');

                    for (let i = 0; i < depth.length - 1; i++) {
                        form = form[depth[i]] = {};     // loop through each part of the path adding to obj
                    }
                    form[depth[index]] = self.val(); // eslint-disable-line
                }
                else {
                    form[name] = self.val();
                }
            });

            return form;
        },

        _addEventListener: function () {
            const that = this;

            // smartphone + tablet
            S.Utils.Helper.mq('desktop').addListener(function (mq) {
                if (!mq.matches) {
                    that._smartphoneTablet();
                }
            });

            // desktop
            S.Utils.Helper.mq('desktop').addListener(function (mq) {
                if (mq.matches) {
                    that._desktop();
                }
            });

        },

        _smartphoneTablet: function()
        {
            const that = this;

            // show membership contribution layer
            this.$ctx.find(this.jsAsideOpen).off('click').on('click', (e) =>
			{
                // layer bg dark
                const callback = () =>
				{
                    // fade layer, show button
                    that.$ctx.find('.js-aside').fadeOut();
                    that.$ctx.find(this.jsAsideOpen).fadeIn();
                };

                S.Utils.LayerBgDark.init($(e.currentTarget).find(this.jsAsideOpen), callback);
				$(e.currentTarget).fadeOut();
				$(e.currentTarget).siblings('.js-aside').fadeIn();
            });

            this.$ctx.find(this.jsAsideOpen).show();
            this.$ctx.find('.js-aside').hide();
        },

        _desktop: function()
        {
            S.Utils.LayerBgDark.instantHideLayer();
            this.$ctx.find(this.jsAsideOpen).off('click');
            this.$ctx.find('.js-aside').show();
            this.$ctx.find(this.jsAsideOpen).hide();
        },


        /**
         * Called once all modules are initialized.
         *
         */
        sync: function ()
        {
            this.calcStep(0);
        },

        /**
         *
         * @param {Number|String} [duration='slow']
         */
        calcStep: function (duration = 'slow')
        {
            let activeStep = 0;
            const scrollTop = $(window).scrollTop(),
                $steps = this.$ctx.find('[data-step]');

            $steps.each((_, step) =>
            {
                const $step = $(step);

                if ($step.offset().top <= scrollTop)
                {
                    activeStep++;
                }
            });

            this._events.emit('BasicStatusNav.setStatus', 1, Math.max(1, activeStep), duration);
        },

        /**
         * counts the characters in a Textarea and displays
         * the maximum amount left
         */
        countTextareaCharacters: function(){

            // Get maximum amount from .js-textarea-count
            $('textarea + div .js-textarea-count').each(function(index, item){
                const text_max = $(item).html(),// eslint-disable-line
                    textarea = $(item).closest('div').prev();

                // when a button is pressed in a textarea
                textarea.on('keydown', function() {

                    setTimeout(function(){

                        const text_length = $(textarea).val().length,// eslint-disable-line
							// eslint-disable-next-line
                            text_remaining = text_max - text_length;// NOSONAR

                        //output data to .js-textarea-count
                        $(item).html(text_remaining);

                    }, 0);

                });
            });
        }
    });
}(jQuery));

