(function ($)
{
    'use strict';

    /**
     * compare decorator implementation for the BasicTable module.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module.BasicTable
     * @class Compare
     * @extends T.Module
     */
    T.Module.BasicTable.Compare = T.createDecorator({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $ctxWrapper: null,

        /** @type {jQuery} */
        $clonedTable: null,

        /** @type {Object} */
        $tr: [],

        /** @type {Object} */
        $clonedTr: [],

        /** @type {Object} */
        $th: [],

        /** @type {Object} */
        $clonedTh: [],

        /** @type {Object} */
        $td: [],

        /** @type {Object} */
        $clonedTd: [],

        /** @type {Number} */
        $cellNum: 0,

		selectorTable: '> thead > tr > th',
		selectorTableWrap: '.js-basic-table-wrap',


        start: function (resolve)
        {
            const that = this;
            this.$ctx = $(this._ctx);

            this.$tr = this.$ctx.find('tr:not(.h-smartphone-only, .h-tablet)');
            this.$th = this.$ctx.find(this.selectorTable);
            this.$cellNum = this.$ctx.find(this.selectorTable).length;
            this.$td = this.$ctx.find('> tbody > tr > td');

            // init
            setTimeout(function(){
                that.init();
            }, 500);


            this._parent.start(resolve);
        },

        /**
         * init
         *
         */
        init: function (){
            const that = this;

            // clone table
            this.$clonedTable = this.$ctx.closest(this.selectorTableWrap).find('.is-cloned-header');
            this.$clonedTr = this.$clonedTable.find('tr:not(.h-smartphone-only, .h-tablet)');
            this.$clonedTh = this.$clonedTable.find(this.selectorTable);
            this.$clonedTd = this.$clonedTable.find('> tbody > tr > td');

            if (!S.Utils.Helper.mq('tablet').matches){

                // switchMode
                this.switchMode('smartphone');
            }else{

                // switchMode
                this.switchMode('tablet');
            }

            S.Utils.Helper.mq('tablet').addListener(function (mq) {
                if (!mq.matches) {

                    // switchMode
                    that.switchMode('smartphone');
                }else{

                    // switchMode
                    that.switchMode('tablet');
                }
            });
        },

        /**
         * initStickyHeader
         *
         * @param {Number} offsetTop
         */
        initStickyHeader: function (offsetTop){
            const that = this;
            let stickyAllowed = true;

            if (typeof this.$ctx.attr('data-sticky') !== 'undefined' && this.$ctx.data('sticky') === false){
				stickyAllowed = false;
            }

            if (this.$ctx.find('> thead').length && stickyAllowed !== false && !this.$ctx.closest(this.selectorTable).find('.is-clone').length){

                if (!this.$clonedTableHeader){
                    this.$clonedTableHeader = this.$ctx.clone(true);
                    this.$clonedTableHeader.addClass('is-cloned-header').insertBefore(this.$ctx).wrap('<div class="js-basic-table-header-clone-wrap" />');
                }

                this._events.emit('stickyDetachEvent.preventHeaderEvent');

                this.$clonedTableHeader.parent().trigger("sticky_kit:detach");

                this.$clonedTableHeader.parent().show().stick_in_parent({
                    sticky_class: 'is-sticky-header',
                    offset_top: offsetTop,
                    parent: that.$clonedTableHeader.closest(that.selectorTable)
                }).on("sticky_kit:stick sticky_kit:unbottom", function(e) {
                    // if in zoom layer => set unset zoom icon sticky too
                    that.$clonedTableHeader.closest(that.selectorTable).siblings('.js-table-zoom').addClass('is-sticky');

                    $(e.target).css({
                        'border-bottom': '1px solid #afafaf',
                    });
                }).on("sticky_kit:unstick sticky_kit:bottom", function(e) {
                    that.$clonedTableHeader.closest(that.selectorTable).siblings('.js-table-zoom').removeClass('is-sticky');

                    $(e.target).css({
                        'border-bottom': 'none',
                    });
                });

                // check for checkboxes in cloned body and destroy id and name attributes
                this.$clonedTableHeader.find('tbody input[type="checkbox"]').each((index, item) => {
                    if (!$(item).attr('name').match('_cloned'))
                    {
                        $(item).attr('name', `${$(item).attr('name')}_cloned`);
                        $(item).attr('id', `${$(item).attr('id')}_cloned`);
                    }
                });

                this.updateStickyHeader();
            }
        },

        /**
         * switchMode
         *
         * @param {String} mode
         */
        switchMode: function (mode){
            const that = this;

            this.$tr.each(function(index, item){

                if (mode === 'smartphone'){
                    // hide first cell and set cell width
                    if (index === 0){
                        that.$clonedTh.css('width', `${100 / (that.$cellNum - 1)}%`);
                        that.$ctx.find('> tbody > tr:first-child > td').css('width', `${100 / (that.$cellNum - 1)}%`);
                    }
                    $(item).children().eq(0).hide();
                }
                else {
                    // show first cell set cell width
                    if (index === 0){
                        that.$clonedTh.css('width', `${100 / (that.$cellNum)}%`);
                        that.$ctx.find('> tbody > tr:first-child > td').css('width', `${100 / (that.$cellNum)}%`);
                    }
                    $(item).children().eq(0).show();
                }
            });

            if (mode === 'smartphone'){
                // set state
                that.$ctx.data('phoneswitch', true);
            }
        }
    });
}(jQuery));
