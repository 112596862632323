'use strict';
(function ($) {
	/**
	 * BasicBoxRouting module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class BasicBoxRouting
	 * @extends T.Module
	 */
	T.Module.BasicBoxRouting = T.createModule({
		$ctx: null,
		routeContainer: '.a-basic-input',
		routeElement: 'input',
		routeState: ':selected',
		routeAttr: 'value',

		start(resolve) {
			this.$ctx = $(this._ctx);

			// interaction boxes
			this.$boxFormSelectButton = this.$ctx.find(".a-basic-btn");
			this.$boxFormSelectButton.on('click', this.boxOpenSelection.bind(this));

			resolve();
		},

		/**
		 * Opens the value of the currently selected option from the selection the provided button belongs to.
		 * If data-target of the option is set to "_blank", the link value is opened in a new window / tab.
		 */
		boxOpenSelection: function (e) {
			e.preventDefault();
			const $selectedOption = this.$ctx.find(`${this.routeContainer} ${this.routeElement}${this.routeState}`);
			const url = $selectedOption.attr(this.routeAttr);
			const target = $selectedOption.data('target');
			if (url) {
				window.open(url, target || "_self");
			}
		}
	});
}(jQuery));