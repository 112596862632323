(function ( $ )
{
    'use strict';
    /**
     * MglSedcard module implementation.
     *
     * @author Örsan Günay <oe.guenay@edelweiss72.de>
     * @namespace T.Module
     * @class MglSedcard
     * @extends T.Module
     */
    T.Module.MglSedcard = T.createModule( {
        /** @type {jQuery} */
        $ctx: null,

        start: function ( resolve )
        {
            this.$ctx = $( this._ctx );

            const isIE11 = !!navigator.userAgent.match(/Trident\/7\./),
				swiperSlide = '.swiper-slide',
				swiperWrapper = '.swiper-wrapper';

            if (isIE11)
            {
                this.doIE11();
            }
            else
            {

                this.$ctx.on('click.sedcard', () => {
                    S.Utils.delayed('sedTurn', 50, () => {
                        this.$ctx.toggleClass('hover').blur();
                    });

                });

                if (this.$ctx.data('mobile-first')) {
                    const positionInBanderole = this.$ctx.closest(swiperSlide).index(),
                        resetBanderole = () => {
                            const thisSwiperInstance = this._sandbox.swiperInstance[this.$ctx.closest('.o-basic-banderole').data('t-id')];

                            thisSwiperInstance.slideTo(0);
                            thisSwiperInstance.detachEvents();
                            thisSwiperInstance.attachEvents();
                            thisSwiperInstance.update();
                        };

                    if (S.Globals.MediaQuery.mqRightNow === 'smartphone') {
                        if (this.$ctx.closest(swiperSlide).index() !== 0) {
                            this.$ctx.closest(swiperSlide).prependTo(this.$ctx.closest(swiperWrapper));
                            resetBanderole();
                        }
                    }
                    else if (this.$ctx.closest(swiperSlide).index() === 0) {
                            this.$ctx.closest(swiperSlide).insertAfter(this.$ctx.closest(swiperWrapper).find(swiperSlide).eq(positionInBanderole));
                            resetBanderole();
                    }

                    S.Utils.Helper.mq('tablet').addListener((mq) => {  //eslint-disable-line
                        if (S.Globals.MediaQuery.mqRightNow === 'smartphone') {
                            if (this.$ctx.closest(swiperSlide).index() !== 0) {
                                this.$ctx.closest(swiperSlide).prependTo(this.$ctx.closest(swiperWrapper));
                                resetBanderole();
                            }
                        }
                        else {
                            if (this.$ctx.closest(swiperSlide).index() === 0) {
                                this.$ctx.closest(swiperSlide).insertAfter(this.$ctx.closest(swiperWrapper).find(swiperSlide).eq(positionInBanderole));
                                resetBanderole();
                            }
                        }
                    });
                }

            }

            resolve();
        },

        doIE11: function(){
            this.$ctx.on('click.sedcard', (e) => {
                $(e.currentTarget).find('.mm-card--front, .mm-card--back').addClass('is-ie11');
                $(e.currentTarget).find('.mm-card--front').toggleClass('is-ie11-hidden');
            });
        }
    } );
}( jQuery ));
