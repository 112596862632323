(function($) {
	'use strict';
	/**
	 * MyAdacPostbox module implementation.
	 *
	 * @author Stefan Vogt <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class MyAdacPostbox
	 * @extends T.Module
	 */
	T.Module.MyAdacPostbox = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Boolean} */
        mobileWasInit: false,

		/** @type {Boolean} */
		sortingWasInit: false,

		/** @type {Boolean} */
		reverseOrder: false,

		/** @type {Object} */
		groupContents: [],

		scrollPane: null,

		/** @type {Boolean} */
		scrollPaneWasInit: false,

		start: function(resolve) {
            this.$ctx= $(this._ctx);

            this.isScrollpane = 'is-scrollpane';

            // init mobile
			if (!S.Utils.Helper.mq('desktop-l').matches)
			{
				this.setMobile();
			}
			else
			{
				// init sorting
				this.initSorting();

				// init scrollpane
				this.initScrollPane();
			}

			// init resize
			this.initResize();

			// init toggle
			this.initToggle();

			// init checkbox sync
			this.initCheckboxSync();

			// init lazyload
			this.initLazyload();

			resolve();
		},

        /** init scrollpane  */
		initScrollPane: function(){
			if (this.$ctx.closest('.m-my-adac-dashboard-box-showcase').length)
			{
				const scrollpaneContainer = this.$ctx.find('.mm-scrollpane').eq(0);

				// scrollpane if in dashboard
				if (scrollpaneContainer.height() >= 590)
				{
					if (this.scrollPaneWasInit === false && S.Utils.Helper.mq('desktop-l').matches)
					{
						this.$ctx.addClass(this.isScrollpane);

						scrollpaneContainer.jScrollPane(
							{
								showArrows: false,
								autoReinitialise: true,
								height: 600
							});
						this.scrollPane = scrollpaneContainer.data('jsp');

						this.scrollPaneWasInit = true;
					}
				}
				else
                {
					if (this.scrollPane)
					{
						this.scrollPane.destroy();
                        this.scrollPaneWasInit = false;
                        this.$ctx.removeClass(this.isScrollpane);
                    }
                }
			}
		},

		/** generate mobile html  */
		setMobile: function(){
			// set state
			this.mobileWasInit = true;

			// generate html for mobile
			this.$ctx.find('tbody > tr.mm-head').each((index, item) =>
            {
				// set subject
				// $(`<div class="mm-from-mobile">${  $(item).find(' > td:nth-child(3)').text()  }:</div>`).prependTo($(item).find(' > td:nth-child(4)'));

				// check if date is today
				const ts = parseInt($(item).find(' > td:nth-child(2) > .js-sortable-text').text(), 10);
				const today = new Date().setHours(0, 0, 0, 0);
				const thatDay = new Date(ts).setHours(0, 0, 0, 0);
				const thatTime = new Date(ts);

				let insertValue = $(item).find(' > td:nth-child(2) > .mm-date').text().substring(0, 6);

				if(today <= thatDay)
				{
					insertValue = thatTime.toTimeString().substring(0, 5);
				}

				$(`<div class="mm-date-mobile">${insertValue}</div>`).insertBefore($(item).find('.mm-attachement').eq(0));
            });
		},

		/** check resize state */
        initResize: function(){
            $(window).resize( () =>
            {
                S.Utils.delayed(`myadacpostbox-${  this.$ctx.data('t-id')}`, 50, () =>
                {
					// reinit scrollpane
					if (this.scrollPane)
					{
						this.scrollPane.reinitialise();
					}

                    if (!S.Utils.Helper.mq('desktop-l').matches)
                    {
						if (this.mobileWasInit === false)
						{
							this.setMobile();
						}

                        // destroy scrollpane
						if (this.scrollPane) {
							this.scrollPane.destroy();
							this.scrollPane = null;
							this.$ctx.removeClass(this.isScrollpane);
							this.scrollPaneWasInit = false;
						}
                    }
                    else
					{
						if (this.sortingWasInit === false)
						{
							// init sorting
							this.initSorting();
						}

						if (this.scrollPaneWasInit === false)
						{
							// init scrollpane
							this.initScrollPane();
						}
					}

                    // close all toggle contents
                    this.closeAll();
                });
            });
        },

		/** init toggle clicks */
		initToggle: function(){
			this.$ctx.find('.mm-head').each((index, item) =>
			{
				$(item).find('td, .mm-toggle, .mm-read').not('td:first-child').on('click', (e) =>
				{
					const toggleContent = $(e.currentTarget).closest('tr').next().find('.mm-toggle-content').eq(0);

					if ($(e.currentTarget).closest('tr').hasClass('is-open'))
					{
						// remove state class
						$(e.currentTarget).closest('tr').removeClass('is-open').next().removeClass('is-open');

						// toggle
						toggleContent.css('height', '0').attr('style', '');
					}
					else
					{
						// get height
						toggleContent.addClass('is-not-overflow-hidden');

						const contentHeight = toggleContent.height();

						toggleContent.removeClass('is-not-overflow-hidden');

						// toggle
						setTimeout(() =>
						{
							toggleContent.css('height', `${contentHeight + 20  }px`);

							// set state was read
							$(e.currentTarget).closest('tr').addClass('is-open').next().addClass('is-open');
						}, 100);
					}

                    // check height => scrollpane if inside dashboard
                    setTimeout(() =>
                    {
                        // initScrollPane
                        this.initScrollPane();
                    }, 1000);
				});
			});
		},

		/** close all toggle contents */
		closeAll: function() {
			this.$ctx.find('tbody > tr.mm-head.is-open td:nth-child(2)').trigger('click');
		},

		/** init checkbox sync */
		initCheckboxSync: function() {
			const allCheckboxes = this.$ctx.find('tbody > tr > td input[type="checkbox"], .js-checkbox-all input[type="checkbox"]');

			this.$ctx.find('.js-checkbox-all input').on('click', (e) =>
			{
				if ($(e.currentTarget).is(':checked') === true)
				{
					allCheckboxes.prop('checked', true);
				}
				else
				{
					allCheckboxes.prop('checked', false);
				}
			});
		},

		/** init sorting table */
		initSorting: function ()
		{
			// set data-group
            this.$ctx.find('.mm-head').each((index, item) =>
            {
                $(item).attr('data-group', index).next().attr('data-group', index);
            });

			// save group contents
			this.$ctx.find('.mm-content').each((index, item) =>
			{
				this.groupContents.push($(item));
			});

			// click events
			this.$ctx.find('.js-sort').parent().on('click', (e) => {

				const $sortElem = $(e.currentTarget).find('.js-sort').eq(0);

				const targetIndex = $(e.currentTarget).index();

				const $tableBody = this.$ctx.find('tbody').eq(0);

				const $allRows = $tableBody.find('tr.mm-head');

				const sortable = [];

				let pos;



				// check if the user clicks the same filter-button (small triangle) again, if not close all and open the clicked ones
				if ($sortElem.hasClass('is-active'))
				{
					this.reverseOrder = (!this.reverseOrder);

					// update state classes
					$sortElem.toggleClass('is-up is-down');
				} else {
					// reset
					this.$ctx.find('.js-sort').removeClass('is-active is-up is-down');
					this.reverseOrder = true;

					// set active
					$sortElem.addClass('is-active is-up');
				}

				$allRows.each(function (i) {

					const $currentRow = $(this);

					if (i === 0)
					{

						pos = sortable.push({headlineObject: '', contentTrs: []}) - 1;

					}

					sortable[pos].contentTrs.push($currentRow);
				});

				// remove tbody
				$tableBody.remove();

				sortable.sort((a, b) => {
					return this.sortHeadings.apply(this, [a, b, targetIndex]);
				});

				$.each(sortable, (i) =>
				{

					const tmp = $('<tbody>');

					sortable[i].contentTrs.sort( (a, b) => {
						return this.sortContentRows.apply(this, [a, b, targetIndex]);
					});

					$.each(sortable[i].contentTrs, function (x) {

						sortable[i].contentTrs[x].prependTo(tmp);

					});

					if (sortable[i].headlineObject) {

						sortable[i].headlineObject.prependTo(tmp);

					}

					// insert sorted html
					tmp.insertAfter(this.$ctx.find('thead'));

					// add group contents to their toggle heads
					$.each(this.groupContents, (index, item) =>
					{
						item.insertAfter(this.$ctx.find(`tbody > tr.mm-head[data-group="${  index  }"]`));
					});

					// reinit toggle
					this.initToggle();
				});
			});

			// set state
			this.sortingWasInit = true;
		},

		/** sort table elements */
		sortHeadings: function (a, b, targetIndex)
		{
			const first = (this.reverseOrder) ? a : b;
			const second = (this.reverseOrder) ? b : a;

			return (first.headlineObject.find('> td').eq(targetIndex).text().trim() > second.headlineObject.find('> td').eq(targetIndex).text().trim()) ? 1 : -1;

		},

		/** sort table elements */
		sortContentRows: function (a, b, targetIndex)
		{
			const first = (this.reverseOrder) ? a : b;
			const second = (this.reverseOrder) ? b : a;

			const $firstItemToCheck = $(first).children().eq(targetIndex),
				$secondItemToCheck = $(second).children().eq(targetIndex),
				sortableText = '.js-sortable-text';

			if ($firstItemToCheck.find(sortableText).length)
			{
				// const checkForNumber = parseInt($firstItemToCheck.find(sortableText).text().trim()),
				const $firstItem = $firstItemToCheck.find(sortableText).text().trim(),
					$secondItem = $secondItemToCheck.find(sortableText).text().trim();

				return this.naturalCompare($firstItem, $secondItem);
			}
			else
			{
				// if you need to filter and there is NO js-sortable-text class set
				return this.naturalCompare($firstItemToCheck.text().trim(), $secondItemToCheck.text().trim());
			}
		},

		/** natural sorting */
		naturalCompare: function (a, b)
		{
			return b.localeCompare(a, undefined, {numeric: true, sensitivity: 'base'});
		},

        /** lazy loading */
        initLazyload: function ()
        {
			const btn = this.$ctx.find('.js-lazyload').eq(0);

            btn.on('click', (e) => // eslint-disable-line
			{
                this.$ctx.find('tr.h-none').each((index, item) =>
				{
					if (index < 24)
					{
						$(item).removeClass('h-none');
                    }
				});

                if (!this.$ctx.find('tr.h-none').length)
				{
                    btn.hide();
				}
            });
        },
	});
}(jQuery));
