'use strict';
(function ($) {
	/**
	 * HavaristCarDamageProofProblems module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class HavaristCarDamageProofProblems
	 * @extends T.Module
	 */
	T.Module.HavaristCarDamageProofProblems = T.createModule({
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');
			this._initValidation();
			this._addListeners();
			resolve();
		},
		_initValidation: function () {
			const validationOptions = {
				messages: this.$form.data('validation-messages') ? this.$form.data('validation-messages') : {},
				rules: {
					'name': {
						required: true,
						maxlength: 100
					},
					'kennzeichen': {
						required: true
					},
					'plz': {
						minlength: 4,
						maxlength: 8,
						pattern: "^[a-zA-Z\\d]+$",
						required: true
					},
					'tel': {
						digits: true
					},
					'email': {
						required: true,
						email: true,
						maxlength: 100
					},
					'checkDate': {
						checkDate: true
					}
				}
			};
			this._addValidationMethod(validationOptions);
			T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
		},
		/**
		 * Add events
		 */
		_addListeners: function _addListeners() {
			this.$ctx.find(".js-submit").click(this._handleSubmit.bind(this));
			this.$ctx.find(".js-back").click(this._handleBackButton.bind(this));
		},

		_handleBackButton: function _handleBackButton() {
			window.history.go(-1);
			return false;
		},

		_handleSubmit: function _handleSubmit() {
			const self = this;
			if (self.$form.valid()) {
				T.Utils.View.startLoader();
				this._sendDataToMail(successCallback, errorCallback);
			}
			function successCallback(data) {
				if (data.success) {
					self.$ctx.find("#content_demand").removeClass("h-hidden");
					self.$ctx.find(".form-cardamage-problems").addClass("h-hidden");
				}
				else {
					self.$ctx.find(".js-error").removeClass("h-hidden");
				}
				T.Utils.View.stopLoader();
			}
			function errorCallback() {
				self.$ctx.find(".js-error").removeClass("h-hidden");
				T.Utils.View.stopLoader();
			}

		},
		/**
		 * send Input Data to mail
		 */
		_sendDataToMail: function _sendDataToMail(successCallback, errorCallback) {
			const self = this;
			const options = {
				method: 'POST',
				xhrFields: { withCredentials: true },
				url: window.location.origin + self.$ctx.data('email-api'),
				data: {
					Name: self.$ctx.find('#cardamage_name').val(),
					PlateNumber: self.$ctx.find('#cardamage_attr').val(),
					Zip: self.$ctx.find('#cardamage_zip').val(),
					DemageDate: self.$ctx.find('#checkDate').val(),
					Phone: self.$ctx.find('#cardamage_nr').val(),
					Email: self.$ctx.find('#cardamage_mail').val()
				},
				contentType: "application/x-www-form-urlencoded;charset=utf-8"
			};
			T.Utils.Ajax.fragment(options, successCallback, errorCallback);
		},
		_addValidationMethod: function _addValidationMethod(validationOptions) {
			$.validator.addMethod('checkDate', function (value) {
				if ($("a.ui-state-default.ui-state-hover").length === 1) {
					return true;
				}
				const inputDate = moment(value, 'DD.MM.YYYY'),
					now = moment();
				return inputDate.isValid() && now >= inputDate;
			}, validationOptions.messages.checkDate.checkDate);
		}
	});
})(jQuery);