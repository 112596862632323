'use strict';
(function ($) {
	/**
	 * FineCalculatorV2 module implementation.
	 *
	 * @author Alex Kröll <alexander.kroell@namics.com>
	 * @namespace T.Module
	 * @class FineCalculatorV2
	 * @extends T.Module
	 */
	T.Module.FineCalculatorV2 = T.createModule({

		CHANGE_EVENT: 'FineCalculatorV2-change',
		SESSIONKEY_FINECALCULATOR_STATE: 'Application.FineCalculatorV2.State',
		//speed
		_speedingRadioButton: 'input[id="offence-velo"]',
		_tailgatingRadioButton: 'input[id="offence-dist"]',
		_trafficSignalsRadioButton: 'input[id="offence-red"]',
		_submitCalculationButton: '.js-submit-fine-calculation',
		_inTownOutOfTownRadio: 'input[name="off-velo1"]',
		_inTownOutOfTownRadioContainer: '.js-inTown-speeding-radio',
		_speedDropdown: '.js-speed-dropdown',
		_vehicleTypeSpeeding: 'input[name="off-dist2"]',
		_vehicleTypeSpeedingContainer: '.js-vehicleType-speeding-radio',

		//Tailgating
		_speedTailgatingDropdown: '.js-tailgating-speed-dropdown',
		_distanceTailgatingDropdown: '.js-distance-dropdown',
		_tailgatingVehicleType: 'input[name="off-type-distance"]',
		_tailgatingVehicleTypeContainer: '.js-vehicleType-distance-radio',

		// Redlight
		_redlightVehicleType: 'input[name="off-redlight3"]',
		_redlightVehicleTypeRadioContainer: '.js-redlight-vehicletype-radio',
		_signVehicleType: 'input[name="off-redlight2-3"]',
		_otherRoadUserRadioContainer: '.js-otherRoadUser-radio-container',
		_otherRoadUserRadio: 'input[name="off-redlight2-2"]',
		_signEndangeringRadioContainer: '.js-sign-endangering-radio-container',
		_signEndangeringRadio: 'input[name="off-redlight2-1"]',

		//Application
		_fineHiddenInput: 'input[name="Fine"]',
		_banHiddenInput: 'input[name="Ban"]',
		_scoreHiddenInput: 'input[name="Points"]',

		//Resultbox
		_fineResult: '.js-fine-result',
		_drivingBanResult: '.js-drivingban-result',
		_scoreResult: '.js-score-result',
		_hintLabelFine: '.js-hint-label-fine',
		_hintFine: '.js-hint-fine',
		_hintLabelDrivingBan: '.js-hint-label-drivingban',
		_hintDrivingBan: '.js-hint-drivingban',


		start: function start(resolve) {
			T.Utils.Application.init();

			this.$ctx = $(this._ctx);
			this._restoreState();
			this._addListeners();
			T.Utils.Helper.initAdditionalModules(this.$ctx);

			resolve();
		},

		_addListeners: function _addListeners() {

			this.$ctx.find(this._submitCalculationButton).on('click', this._submit.bind(this));
			this.$ctx.find('input,select').on('change', this._storeState.bind(this));
			this.$ctx.find(this._signEndangeringRadio).on('change', this._setPedestrianOrCyclistContainerVisibility.bind(this));
		},
		_storeState: function _storeState() {
			const state = T.Utils.Helper.getQueryParams(this.$ctx.find("input,select").serialize());
			T.Utils.Store.set(this.SESSIONKEY_FINECALCULATOR_STATE, {
				calculatorState: state,
				result: {
					fine: this.$ctx.find(this._fineResult).text(),
					fineText: this.$ctx.find('.js-fine-result-text').html(),
					ban: this.$ctx.find(this._drivingBanResult).text(),
					banText: this.$ctx.find('.js-drivingban-result-text').html(),
					score: this.$ctx.find(this._scoreResult).text(),
					scoreText: this.$ctx.find('.js-score-result-text').html(),
				}
			}, T.Utils.Store.SESSION);
		},
		_submit: function _submit() {


			if (this.$ctx.find(this._speedingRadioButton).is(':checked')) {
				this._setResultForSpeeding();
			} else if (this.$ctx.find(this._tailgatingRadioButton).is(':checked')) {
				this._setResultForTailgating();
			} else if (this.$ctx.find(this._trafficSignalsRadioButton).is(':checked')) {
				this._setResultForTrafficSignals();
			}

			this._storeState();

		},
		_restoreState: function _restoreState() {

			const state = T.Utils.Store.get(this.SESSIONKEY_FINECALCULATOR_STATE, T.Utils.Store.SESSION);

			if (state) {
				if (state.calculatorState) {
					const model = state.calculatorState;
					for (const name in model) {

						this.$ctx.find(`input[type="radio"][name="${name}"][value="${model[name]}"]`).prop("checked", true);
						this.$ctx.find(`select[name="${name}"] option[value="${model[name]}"]`).prop("selected", true);
					}
				}
				if (state.result) {

					this.$ctx.find(this._fineResult).text(state.result.fine || '');
					this.$ctx.find('.js-fine-result-text').html(state.result.fineText || '');
					this.$ctx.find(this._drivingBanResult).text(state.result.ban || '');
					this.$ctx.find('.js-drivingban-result-text').html(state.result.banText || '');
					this.$ctx.find(this._scoreResult).text(state.result.score || '');
					this.$ctx.find('.js-score-result-text').html(state.result.scoreText || '');

				}
				this._setPedestrianOrCyclistContainerVisibility();
			}
		},
		_setResultForSpeeding: function _setResultForSpeeding() {
			const req = {
				type: "GET",
				url: this.$ctx.data("json-speed")
			};
			T.Utils.Ajax.fragment(req, (data) => {
				if (data && data.FINESPEED) {
					const type = this._findCheckedRadioButton(this._vehicleTypeSpeeding, this._vehicleTypeSpeedingContainer).val();
					const location = this._findCheckedRadioButton(this._inTownOutOfTownRadio, this._inTownOutOfTownRadioContainer).val();
					const speed = this._getDropdownVal(this._speedDropdown);


					const match = data.FINESPEED.filter((x) => {
						return x.Fahrzeugart === type && x["Tempoüberschreitung in km/h"] === speed && x["Örtlichkeit"] === location;
					});

					if (match && match[0]) {
						this._fillResultBox(match[0], this);
					} else {
						this._clearResultBox(this);
					}
				}
			});
		},
		_setResultForTailgating: function _setResultForTailgating() {
			const req = {
				type: "GET",
				url: this.$ctx.data("json-tailgating")
			};
			T.Utils.Ajax.fragment(req, (data) => {
				if (data && data.FINECATCHUP) {
					const type = this._findCheckedRadioButton(this._tailgatingVehicleType, this._tailgatingVehicleTypeContainer).val();
					const speed = this._getDropdownVal(this._speedTailgatingDropdown);
					const distance = this._getDropdownVal(this._distanceTailgatingDropdown);

					const match = data.FINECATCHUP.filter((x) => {
						return x.Fahrzeugart === type && x.Geschwindigkeit === speed && x.Zehntelwert === distance;
					});

					if (match && match[0]) {
						this._fillResultBox(match[0], this);
					} else {
						this._clearResultBox(this);
					}
				}
			});
		},
		// eslint-disable-next-line sonarjs/cognitive-complexity
		_setResultForTrafficSignals: function _setResultForTrafficSignals() {

			const req = {
				type: "GET",
				url: this.$ctx.data("json-trafficsignals")
			};
			T.Utils.Ajax.fragment(req, (data) => {
				if (data && data.FINETRAFFICSIGNALS) {

					const model = T.Utils.Helper.getQueryParams(this.$ctx.find("input:visible").serialize());
					const modifiers = [];

					for (const x in model) {
						if (!isNaN(model[x])) {
							modifiers.push(model[x]);
						}
					}

					const redLightype = model["off-redlight3"];
					const signType = model["off-redlight3-sign"];

					const vehicleType = redLightype ? redLightype : signType;

					const matchModifiers = modifiers.filter((x => { return x > 0; })).sort();


					const match = data.FINETRAFFICSIGNALS.filter((x) => {
						return x.Fahrzeugart === vehicleType && (JSON.stringify(x.Modifikatoren.sort()) === JSON.stringify(matchModifiers));
					});

					if (match && match[0]) {
						this._fillResultBox(match[0]);
					} else {
						this._clearResultBox();
					}
				}
			});
		},
		_fillResultBox: function _fillResultBox(item) {



			const fine = item["Geldbuße in Euro"];
			this.$ctx.find(this._fineHiddenInput).val(fine.replace(/[^0-9.,]/g, "").replace(',', '.'));
			const germanizedfine = `${fine} Euro`;


			const drivingban = item["Fahrverbot in Monaten"] ? item["Fahrverbot in Monaten"] : item["Fahrverbot"];

			//if NaN - no driving ban will be applied - false
			this.$ctx.find(this._banHiddenInput).val(!isNaN(drivingban));

			let germanizedBan = `${drivingban} `;
			if (Number(drivingban) > 1) {
				germanizedBan += "Monate";
			} else if (!isNaN(drivingban)) {
				germanizedBan += "Monat";
			}

			const score = item.Punkte;
			this.$ctx.find(this._scoreHiddenInput).val(score);


			this.$ctx.find(this._fineResult).text(germanizedfine);
			this.$ctx.find(this._drivingBanResult).text(germanizedBan);
			this.$ctx.find(this._scoreResult).text(score);


			if (item["Bußgeldgebühren"]) {
				this.$ctx.find(this._hintLabelFine).toggleClass('h-hidden', false);
				this.$ctx.find(this._hintFine).toggleClass('h-hidden', false);
				this.$ctx.find(this._hintFine).text(item["Bußgeldgebühren"]);
			} else {
				this.$ctx.find(this._hintLabelFine).toggleClass('h-hidden', true);
				this.$ctx.find(this._hintFine).toggleClass('h-hidden', true);
				this.$ctx.find(this._hintFine).empty();
			}

			if (item.Hinweisfeld) {
				this.$ctx.find(this._hintLabelDrivingBan).toggleClass('h-hidden', false);
				this.$ctx.find(this._hintDrivingBan).toggleClass('h-hidden', false);
				this.$ctx.find(this._hintDrivingBan).text(item.Hinweisfeld);
			} else {
				this.$ctx.find(this._hintLabelDrivingBan).toggleClass('h-hidden', true);
				this.$ctx.find(this._hintDrivingBan).toggleClass('h-hidden', true);
				this.$ctx.find(this._hintDrivingBan).empty();
			}
			this.$ctx.trigger(this.CHANGE_EVENT);
			this._storeState();
		},
		_clearResultBox: function _clearResultBox() {

			this.$ctx.find(this._fineHiddenInput).val("");
			this.$ctx.find(this._banHiddenInput).val("");
			this.$ctx.find(this._scoreHiddenInput).val("");
			this.$ctx.find(this._fineResult).text("-");
			this.$ctx.find(this._drivingBanResult).text("-");
			this.$ctx.find(this._scoreResult).text("-");
			this.$ctx.find(this._hintLabelFine).toggleClass('h-hidden', true);
			this.$ctx.find(this._hintFine).toggleClass('h-hidden', true);
			this.$ctx.find(this._hintFine).empty();
			this.$ctx.find(this._hintLabelDrivingBan).toggleClass('h-hidden', true);
			this.$ctx.find(this._hintDrivingBan).toggleClass('h-hidden', true);
			this.$ctx.find(this._hintDrivingBan).empty();
			this.$ctx.trigger(this.CHANGE_EVENT);
			this._storeState();

		},
		_findCheckedRadioButton: function _findCheckedRadioButton(selector, selectorContainer) {
			return this.$ctx.find(`${selector}:checked`, selectorContainer);
		},
		_setPedestrianOrCyclistContainerVisibility: function _setPedestrianOrCyclistContainerVisibility() {

			const $container = this.$ctx.find(this._otherRoadUserRadioContainer);
			const $otherRoadUserRadio = this._findCheckedRadioButton(this._signEndangeringRadio, this._signEndangeringRadioContainer);

			$container.toggleClass('h-none', $otherRoadUserRadio.val() === "0");

		},
		_getDropdownVal: function _getDropdownVal(selector) {
			const result = this.$ctx.find(selector).val();
			if ("0" !== `${result}`) {
				return result;
			}
			return null;
		}


	});
}(jQuery));