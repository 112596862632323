(function($) {
	'use strict';
	/**
	 * BasicStickyServiceContact module implementation.
	 *
	 * @author m.haeusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicStickyServiceContact
	 * @extends T.Module
	 */
	T.Module.BasicStickyServiceContactRestyle = T.createModule({

		/** @type {number} */
		resizeTimeout: 0,

		start: function(resolve) {
			this.$ctx = $(this._ctx);
			this.$trigger = this.$ctx.find('.mm-trigger');
			this.$detailsContent = this.$ctx.find('.mm-content-layer');
			this.$triggerClose = this.$detailsContent.find('.mm-close');
			this.$tabBtn = this.$ctx.find('.mm-tab');
			this.$contentWrapper = this.$ctx.find('.mm-content-wrapper');
			this.$contentWrapperClose = this.$ctx.find('.mm-content-wrapper .mm-close-content');
			this.scrollPaneWasInit = false;

			this.isVisible = 'is-visible';
			this.eventClickServiceContact = 'click.servicecontact keyup.servicecontact';
			this.eventClickKeyup = 'click keyup';

			this.initTrigger();
			this.initTab();
			this.initCheckFormFooterSticky();

			resolve();
		},

		initCheckFormFooterSticky()
		{
			if($('.m-basic-form-footer-sticky').length)
			{
				this.$ctx.addClass('has-basic-form-footer-sticky');
			}
		},

		initTrigger()
		{
			this.$trigger.on(this.eventClickKeyup, (e) => {
				if(e.type === 'click' || e.key === 'Enter') {
					this.$detailsContent.fadeIn();
					$('body').on(this.eventClickServiceContact, (el) => {
						if(el.type === 'click') {
							if ($(el.target).closest('.m-basic-sticky-service-contact-restyle').length < 1) {
								this.$triggerClose.trigger('click');
								$('body').off(this.eventClickServiceContact);
							}
						}
						else if(el.key === 'Escape') {
							this.closeContact();
						}
					});
				}
			});

			this.$triggerClose.on(this.eventClickKeyup,(e) => {
				if(e.type === 'click' || e.key === 'Enter' || e.key === 'Escape') {
					this.closeContact();
				}
			});

			this.$triggerClose.on('focus',() => {
				if($('body').hasClass('is-keyboard-focus') && !this.$ctx.find('.js-hidden-focus-tab-handler').length) {
					const jsHiddenFocusTabHandler = $('<div class="js-hidden-focus-tab-handler" tabindex="0"></div>');
					this.$contentWrapper.append(jsHiddenFocusTabHandler);

					this.$ctx.find('.js-hidden-focus-tab-handler').on('focus', ()=> {
						this.$triggerClose.focus();
					});
				}
			});
		},

		closeContact()
		{
			$('body').off(this.eventClickServiceContact);
			this.$detailsContent.fadeOut();
			this.$contentWrapper.find('> div').removeClass(this.isVisible);
		},

		initTab()
		{
			this.$tabBtn.on(this.eventClickKeyup, (tab) => {
				if(tab.type === 'click' || tab.key === 'Enter') {
					const dataAttr = `div[data-anker-id="${$(tab.currentTarget).data('anker-id')}"]`;

					this.$contentWrapper.find(dataAttr).show();
					this.$contentWrapper.find(dataAttr).animate({
						left: 0
					});
					this.$tabBtn.hide();
					this.initScrollPane(this.$contentWrapper.find(dataAttr));
				}
			});

			this.$contentWrapperClose.on(this.eventClickKeyup, (btn) => {
				const mmcontentSelector = '.mm-content';
				if(btn.type === 'click' || btn.key === 'Enter') {
					$(btn.currentTarget).closest(mmcontentSelector).animate({
						left: $(btn.currentTarget).closest(mmcontentSelector).outerWidth()
					}, function () {
						$(btn.currentTarget).closest(mmcontentSelector).hide();
					});
					this.$tabBtn.show();
					this.$triggerClose.focus();
				}
			});

		},

		/** init scrollpane  */
		initScrollPane: function(elem)
		{
			if(elem.data('data-scrollPane') === undefined)
			{
				elem.data('data-scrollPane', true);

				// create jScrollPane
				elem.find('.mm-scrollpane').jScrollPane();
			}
		},

	});
}(jQuery));
