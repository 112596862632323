/**
 * EventTrackingManual implementation
 *
 * @author <marc.radziwill@merkle.com>
 * @class EventTrackingManual
*/
// eslint-disable-next-line no-undef
class EventTrackingManual extends EventTrackingBase {

	eventTrackingAttribute = 'data-clickevent';

	eventTrackingAttributeSelector = `[${this.eventTrackingAttribute}]`;

	_bindTrackingEvents($context) {
		const eventKey = 'event-click-bound';
		jQuery($context).find(this.eventTrackingAttributeSelector).each((index, element) => {
			const $element = jQuery(element);
			if (!$element.data(eventKey)) {
				$element.on('click', this.handleEventClick.bind(this));
				$element.data(eventKey, 'true');
			}
		});
	}


	handleEventClick(evt) {
		const $element = jQuery(evt.currentTarget);
		const eventDataString = $element.attr(this.eventTrackingAttribute);
		let eventData;

		try {
			eventData = JSON.parse(eventDataString);
		} catch (e) { //NOSONAR
			eventData = JSON.parse(eventDataString.replace(/'/g, '"'));
		}

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}
}
window.customElements.define('event-tracking-manual', EventTrackingManual);