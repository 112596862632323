(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.HideStickyContent = {

		/** @var{Object} Footer */
		$neededStickyFooter: null,
		$footerHeader: null,
		$footerHeaderLabel: null,
		$hiddenContent: null,

		$removedClasses: null,

		/** @var{Object} resize */
		stickyTimeout: null,
		stickyResizeTimeout: null,


		/** @var{Object} input or textField focuse */
		inputFocus: false,

		/**
		 * init
		 */
		init() {
			// clear out variables, if they were used in multiple instances
			this.$removedClasses = null;
		},

		/**
		 * handles the clickedElement and the given stickyFooter
		 *  - param stickyFooter: it´s better way to check if a sticky footer is given in input and textfield.js to prevent to call this S.UTILS if its not necassery
		 * @param {jQuery} $clickedElement
		 * @param {jQuery} $stickyFooter
		 */
		handleStickyContent($clickedElement, $stickyFooter) {
			// save variables
			this.$neededStickyFooter = this.$neededStickyFooter || $stickyFooter;
			// find direct child with mm-content = innerContent to hide if its open on tablet/mobile
			this.$footerHeader = this.$neededStickyFooter.find('> .mm-header');
			this.$footerHeaderLabel = this.$footerHeader.find('.mm-header-label ');
			this.$hiddenContent = this.$neededStickyFooter.find('> .mm-content');

			// check, if there is hidden content in the sticky-footer
			if (this.$hiddenContent.length) {
				// if its IOS or if the mediaQuery is not desktop, than its tablet/mobile
				// IOS is special with position: fixed (body) elements - it ignores the position: fixed and enforced scrolling the view
				if (S.Utils.Helper.isIOS() || !S.Utils.Helper.mq('desktop').matches) {
					// check if the footer has a hiddenContent with the label as triggerElemeent with the class "has-content" and if its open
					if (this.$footerHeaderLabel.hasClass('has-content is-open')) {
						this.$footerHeaderLabel.trigger('click');

						// check for transitino to end
						const [hasTransition, neededEvent] = S.Utils.CssAnimation.checkForSingleElAnimation(this.$hiddenContent);

						// if the element has a transition
						if (hasTransition) {
							// just execute one time, no need for more
							this.$hiddenContent.one(neededEvent, () => {

								// hide sticky footer
								this.hideStickyFooter();
							});
						}

						else {
							// wait for animation
							setTimeout(() => {
								// hide sticky footer
								this.hideStickyFooter();
							}, 550);
						}
					}
					else {
						// hide sticky footer
						this.hideStickyFooter();
					}
				}

				return;
			}

			this.hideStickyFooter();
		},

		/**
		 * hide sticky footer only on mobile
		 */
		hideStickyFooter() {
			// if the stickyFooter is visbile && check for media - only mobile
			if (this.$neededStickyFooter.hasClass('is-visible') && !S.Utils.Helper.mq('tablet').matches) {
				this.$neededStickyFooter.addClass('is-hidden');
				this.inputFocus = true;
			}
		},

		/**
		 * show sticky footer and add the class which were removed
		 */
		showStickyFooter($stickyFooter) {
			// clear timeout when needed
			clearTimeout(this.stickyTimeout);

			// set this false, on focusOut
			// if the next focus is an input, the inputFocus will be set on true in the 1sec delay, so it doesnt dissapear
			this.inputFocus = false;

			this.stickyTimeout = setTimeout(() => {
				// check if stickyFooter is given
				if ($stickyFooter.length && !this.inputFocus && this.$neededStickyFooter !== null) {
					// hide
					this.$neededStickyFooter.removeClass('is-hidden');
				}
			}, 1000);
		},

		/**
		 * resize function for changing size in the browser or from landscape to portrait
		 * (with delay, to reduce the event-calls)
		 */
		resize() {
			// Store the window width
			let windowWidth = $(window).width();

			// Resize Event
			$(window).on('resize', () => {
				// clear timeout when needed
				clearTimeout(this.stickyResizeTimeout);

				this.stickyResizeTimeout = setTimeout(() => {
					if ($(window).width() !== windowWidth) {
						// Update the window width for next time
						windowWidth = $(window).width();

						// hide
						this.$neededStickyFooter.removeClass('is-hidden');

						// if there is a hidden content
						if (this.$hiddenContent.length) {

							// just execute one time, no need for more
							this.$hiddenContent.off('transitionend');
						}
					}
				}, 50);
			});
		},
	};
})(jQuery);
