(function ($) {
	'use strict';

	S.Calculator = S.Calculator || {};

	S.Calculator.Fine = {

		/** @type {Object} Ihr Delikt */
		$offenseFilter: null,


		/** @type {Object} Kraftfahrzeugart */
		$speedVehicleFilter: null,

		/** @type {Object} Ort des Verstoßes */
		$speedPlaceFilter: null,

		/** @type {Object} Tempoüberschreitung */
		$speedTempoFilter: null,


		/** @type {Object} Geschwindigkeit */
		$catchupSpeedFilter: null,

		/** @type {Object} Abstand */
		$catchupDistanceFilter: null,


		/** @type {Object} Sonstige Zuwiderhandlungen */
		$infringementFilter: null,


		/** @type {Number} Ihr Delikt */
		activeOffenseFilter: 0,


		/** @type {String} Kraftfahrzeugart */
		activeSpeedVehicleFilter: '',

		/** @type {String} Ort des Verstoßes */
		activeSpeedPlaceFilter: '',

		/** @type {String} Tempoüberschreitung */
		activeSpeedTempoFilter: '',


		/** @type {String} Geschwindigkeit */
		activeCatchupSpeedFilter: '',

		/** @type {String} Abstand */
		activeCatchupDistanceFilter: '',

		/** @type {Number} Sonstige Zuwiderhandlungen */
		activeInfringementFilter: 0,


		/** @type {String} */
		filterId: 'offense_all',

		/** @type {String} */
		jsonSpeed: '',

		/** @type {String} */
		jsonCatchup: '',


		/**
		 * init form actions
		 *
		 */
		init: function () {

			const that = this;

			// classes/events/attr as strings
			this.formCalculation = '.l-form--calculation, .l-form-general--calculation';
			this.formOffense = '.l-form--offense, .l-form-general--offense';
			this.jsCalculate = '.js-calculate';
			this.contentSearchResultNone = '.l-main-content--searchresult > .h-none';
			this.visuallyHidden = 'h-visuallyhidden';
			this.colData = '.ll-col-data';
			this.colDataDivNotVisuallyHidden = '.ll-col-data > div:not(.h-visuallyhidden)';
			this.inputChecked = 'input:checked';

			// offence
			this.$offenseFilter = $('input[name="offense"]');

			// speed
			this.$speedVehicleFilter = $('input[name="speed_0"]');
			this.$speedPlaceFilter = $('input[name="speed_1"]');
			this.$speedTempoFilter = $(this.formCalculation).eq(0).find('fieldset').eq(3).find('input');

			// speed
			this.$catchupSpeedFilter = $('input[name="catchup_0"]');
			this.$catchupDistanceFilter = $(this.formCalculation).eq(1).find('fieldset').eq(2).find('input');

			// infringements
			this.$infringementFilter = $('input[name="infringements"]');

			// get json speed
			this.jsonSpeed = $(this.formOffense).data('json-speed');

			// get json catchup
			this.jsonCatchup = $(this.formOffense).data('json-catchup');

			$('.ll-main-triangle').closest('.l-main-content').addClass('l-main-content--searchresult');

			$('.ll-main-triangle').closest('.l-main-content').addClass('l-main-content--searchresult');

			// set offence filter
			this.setOffenseFilter();

			// set speed filter
			this.setSpeedFilter();

			// set catchup filter
			this.setCatchupFilter();

			// set infringements filter
			this.setInfringementsFilter();

			// calculate btn
			$(this.jsCalculate).on('click', function () {
				that.calculate();
			});
		},


		/**
		 * setOffenseFilter
		 *
		 */
		setOffenseFilter: function () {
			const that = this;

			this.activeOffenseFilter = parseInt($(this.formOffense).find('input[name="offense"]:checked').val());

			// show active calculation
			$(this.formCalculation).eq(this.activeOffenseFilter).show();

			this.$offenseFilter.on('change', (e) => {
				// reset searchresult
				$(this.contentSearchResultNone).hide();

				// reset calculation
				$(this.formCalculation).hide();

				// show active calculation
				$(this.formCalculation).eq(parseInt($(e.currentTarget).val())).show();

				// set
				that.activeOffenseFilter = parseInt($(e.currentTarget).val());

				if (that.activeOffenseFilter === 2) {
					// show infringement searchresult
					$(this.contentSearchResultNone).eq(that.activeOffenseFilter).show();

					// hide calculate btn
					$(this.jsCalculate).hide();
				} else {
					// hide all searchresult
					$(this.contentSearchResultNone).hide();

					// show calculate btn
					$(this.jsCalculate).show();
				}
			});
		},


		/**
		 * setSpeedFilter
		 *
		 */
		setSpeedFilter: function () {
			const that = this;

			// vehicle
			this.activeSpeedVehicleFilter = $('input[name="speed_0"]:checked').val();
			this.$speedVehicleFilter.each((index, item) => {
				$(item).on('click', () => {
					that.activeSpeedVehicleFilter = $('input[name="speed_0"]:checked').val();

					// select tempo set
					that.$speedTempoFilter.closest(this.colData).children().addClass(this.visuallyHidden);
					that.$speedTempoFilter.closest(this.colData).children().eq(index).removeClass(this.visuallyHidden);
				});
			});

			// place
			this.activeSpeedPlaceFilter = $('input[name="speed_1"]:checked').val();
			this.$speedPlaceFilter.on('click', function () {
				that.activeSpeedPlaceFilter = $('input[name="speed_1"]:checked').val();
			});

			// tempo
			this.activeSpeedTempoFilter = $(this.formCalculation).eq(0).find('fieldset').eq(3).find(this.colDataDivNotVisuallyHidden).find(this.inputChecked).val();
			this.$speedTempoFilter.on('click', () => {
				that.activeSpeedTempoFilter = $(this.formCalculation).eq(0).find('fieldset').eq(3).find(this.colDataDivNotVisuallyHidden).find(this.inputChecked).val();
			});
		},


		/**
		 * setCatchupFilter
		 *
		 */
		setCatchupFilter: function () {
			const that = this;

			// speed
			this.activeCatchupSpeedFilter = $('input[name="catchup_0"]:checked').val();

			this.$catchupSpeedFilter.each((index, item) => {
				$(item).on('click', () => {
					that.activeCatchupSpeedFilter = $('input[name="catchup_0"]:checked').val();

					// select distance set
					that.$catchupDistanceFilter.closest(this.colData).children().addClass(this.visuallyHidden);
					that.$catchupDistanceFilter.closest(this.colData).children().eq(index).removeClass(this.visuallyHidden);
				});
			});

			// distance
			this.activeCatchupDistanceFilter = $(this.formCalculation).eq(1).find('fieldset').eq(2).find(this.colDataDivNotVisuallyHidden).find(this.inputChecked).val();
			this.$catchupDistanceFilter.on('click', () => {
				that.activeCatchupDistanceFilter = $(this.formCalculation).eq(1).find('fieldset').eq(2).find(this.colDataDivNotVisuallyHidden).find(this.inputChecked).val();
			});
		},


		/**
		 * setInfringementsFilter
		 *
		 */
		setInfringementsFilter: function () {
			const that = this;

			this.activeInfrigementFilter = parseInt($('input[name="infrigements"]:checked').val());

			// show active
			$(this.contentSearchResultNone).eq(2).find('> .h-none').eq(this.activeInfringementFilter).show();

			this.$infringementFilter.on('click', (e) => {
				// set
				that.activeInfringementFilter = parseInt($(e.currentTarget).val());

				// reset infringement result
				$(this.contentSearchResultNone).eq(2).find('> .h-none').hide();

				// show active
				$(this.contentSearchResultNone).eq(2).find('> .h-none').eq(that.activeInfringementFilter).show();
			});
		},


		/**
		 * calculate
		 *
		 */
		// eslint-disable-next-line sonarjs/cognitive-complexity
		calculate: function () { //NOSONAR
			const that = this;
			const fineString = 'Geldbuße in Euro',
				toggleJsContent = '.m-basic-toggle .js-content',
				speedDescription = '#speed_description',
				speedForbiddenMonth = '#speed_forbidden_month',
				catchupDescription = '#catchup_description',
				catchupForbiddenMonth = '#catchup_forbidden_month';

			// reset searchresult
			$(this.contentSearchResultNone).hide();

			// show active searchresult
			$(this.contentSearchResultNone).eq(that.activeOffenseFilter).show();

			if (that.activeOffenseFilter === 0) {

				// speed
				$.getJSON(that.jsonSpeed, function () {

				}).done(function (data) {
					let resultFine, resultFineHint, resultFineDescription, resultFineMonth, resultFinePoints;
					let count = 0; // count result for testing
					const dummyArray = []; // for testing

					$.each(data.FINESPEED, function (index, item) {
						if (that.activeSpeedVehicleFilter === item['Fahrzeugart']
							&& that.activeSpeedPlaceFilter === item['Örtlichkeit']
							&& that.activeSpeedTempoFilter === item['Tempoüberschreitung in km/h']) {

							count++; // dummy
							dummyArray.push(item[fineString]); // dummy

							resultFine = item[fineString];
							resultFineHint = item['Bußgeldgebühren'];
							resultFineDescription = item['Hinweisfeld'];
							resultFineMonth = item['Fahrverbot in Monaten'];
							resultFinePoints = item['Punkte'];
						}
					});

					// begin dummy ###
					let restultsring = '';
					for (let x = 0; x < dummyArray.length; x++) {
						restultsring += `${dummyArray[x]}, `;
					}

					$(toggleJsContent).append(`<br><strong>Kriterien: </strong>Fahrzeugart: ${that.activeSpeedVehicleFilter}, Örtlichkeit: ${that.activeSpeedPlaceFilter}, Tempoüberschreitung: ${that.activeSpeedTempoFilter}`);

					$(toggleJsContent).append(`<br>${count} Ergebnisse(${restultsring}) => Bußgeld: ${resultFine}`);
					// end dummy ###

					// result fine
					$('#speed_fine').html(resultFine);

					// result hint
					if (resultFineHint === '') {
						$('#speed_hint').closest('p').hide();
					} else {
						$('#speed_hint').html(resultFineHint).closest('p').show();
					}

					// result description
					$(speedDescription).html(resultFineDescription);
					if (resultFineDescription === '') {
						$(speedDescription).closest('p').hide();
					} else {
						$(speedDescription).closest('p').show();
					}

					// result month
					$(speedForbiddenMonth).html(resultFineMonth);
					if (resultFineMonth === '-') {
						$(speedForbiddenMonth).next().hide();
					} else {
						$(speedForbiddenMonth).next().show();
					}

					// result points
					$('#speed_points').html(resultFinePoints);
				});

			} else if (that.activeOffenseFilter === 1) {

				// catchup
				$.getJSON(that.jsonCatchup, function (

				) {

				}).done(function (data) {
					let resultFine, resultFineHint, resultFineDescription, resultFineMonth, resultFinePoints;
					let count = 0; // count result for testing
					const dummyArray = []; // for testing

					$.each(data.FINECATCHUP, function (index, item) {
						if (that.activeCatchupSpeedFilter === item['Geschwindigkeit']
							&& that.activeCatchupDistanceFilter === item['Zehntelwert']) {

							count++; // dummy
							dummyArray.push(item[fineString]); // dummy

							resultFine = item[fineString];
							resultFineHint = item['Bußgeldgebühren'];
							resultFineDescription = item['Hinweisfeld'];
							resultFineMonth = item['Fahrverbot'];
							resultFinePoints = item['Punkte'];
						}
					});

					// begin dummy ###
					let restultsring = '';
					for (let x = 0; x < dummyArray.length; x++) {
						restultsring += `${dummyArray[x]}, `;
					}

					$(toggleJsContent).append(`<br><strong>Kriterien: </strong>Geschwindigkeit: ${that.activeCatchupSpeedFilter}, Abstand: ${that.activeCatchupDistanceFilter}`);

					$(toggleJsContent).append(`<br>${count} Ergebnisse(${restultsring}) => Bußgeld: ${resultFine}`);
					// end dummy ###

					// result fine
					$('#catchup_fine').html(resultFine);

					// result hint
					if (resultFineHint === '') {
						$('#catchup_hint').closest('p').hide();
					} else {
						$('#catchup_hint').html(resultFineHint).closest('p').show();
					}

					// result description
					$(catchupDescription).html(resultFineDescription);
					if (resultFineDescription === '') {
						$(catchupDescription).closest('p').hide();
					} else {
						$(catchupDescription).closest('p').show();
					}

					// result month
					$(catchupForbiddenMonth).html(resultFineMonth.toString());
					if (resultFineMonth === '-') {
						$(catchupForbiddenMonth).next().hide();
					} else {
						$(catchupForbiddenMonth).next().show();
					}

					// result points
					$('#catchup_points').html(resultFinePoints);
				});
			}
		}
	};
	$(document).ready(function () {
		if ($('.l-form.js-fine, .l-form-general.js-fine').length) {
			S.Calculator.Fine.init();
		}
	});

})(jQuery);
