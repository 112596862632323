'use strict';
(function ($) {
	/**
	 * QueryForwarding module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class QueryForwarding
	 * @extends T.Module
	 */
	T.Module.QueryForwarding = T.createModule({
		start(resolve) {
			this.updateAllAnchorWithQueryParam();
			this._registerObserver();
			resolve();
		},

		_registerObserver: function _registerObserver() {
			const config = { attributes: false, childList: true, subtree: true };
			try {
				const observer = new MutationObserver(this._initMutations.bind(this));
				observer.observe(document.body, config);
			} catch (err) {
				T.Utils.Logger("Failed to init MutationObserver", err);
			}
		},

		_initMutations: function _initMutations(mutationsList) {
			mutationsList.forEach(mutation => {
				if (mutation.addedNodes) {
					this.updateAllAnchorWithQueryParam($(mutation.addedNodes).parent());
				}
			});
		},

		/**
		 * Get query Parameter from URL and put it on all anchor links
		 */
		updateAllAnchorWithQueryParam: function updateAllAnchorWithQueryParam() {
			$("main .a-basic-btn").each(function () {
				const hrefOrig = $(this).attr('href');
				const queryResult = T.Utils.Helper.getQueryParams(window.location.search);
				if (hrefOrig && queryResult) {
					$(this).attr('href', T.Utils.Helper.updateUrlParameter(queryResult, hrefOrig));
				}
			});
		}
	});
}(jQuery));
