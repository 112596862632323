'use strict';
(function ($) {
	/**
	 * JoinCodeEnBW module implementation.
	 *
	 * @author Carsten Meiselbach <carsten.meiselbach@namics.com>
	 * @namespace T.Module
	 * @class JoinCodeEnBW
	 * @extends T.Module
	 */
	T.Module.JoinCodeEnBW = T.createModule({
		$loginBtn: null,
		$reqJoincodeBtn: null,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this.$loginBtn = this.$ctx.find('.js-login-btn');
			this.$reqJoincodeBtn = this.$ctx.find('.js-request-joincode-btn');
			this.readConfiguration();
			this.addEventListeners();
			T.Utils.View.startLoader();
			T.Utils.Auth.getResolvedIdentity(this.handleUserData.bind(this));
			resolve();
		},

		readConfiguration: function readConfiguration() {
			this._backend = this.$ctx.data("backend") || T.Utils.Application.getApi();
			this._apiJoincodeEnBW = this.$ctx.data("apijoincodeenbw");
			this._apiSendMail = this.$ctx.data("apisendmail");
			this._processid = this.$ctx.data("processid");
		},

		addEventListeners: function addEventListeners() {
			this.$loginBtn.on('click', this.authorizeOnClick.bind(this));
			this.$reqJoincodeBtn.on('click', this.checkTokenAndGetJoinCode.bind(this));
		},

		authorizeOnClick: async function authorizeOnClick(event) {
			event.preventDefault();

			if (!await T.Utils.Auth.isLoggedIn()) {
				T.Utils.Auth.authorize();
			}
		},

		handleUserData: function handleUserData(user) {
			if (user) {
				this._user = user;
				this.$loginBtn.toggleClass('h-hidden', true);

				if (user.UserData.IstMitglied) {
					this.$reqJoincodeBtn.toggleClass('h-hidden', false);
				} else {
					this.$ctx.find('.js-no-member').toggleClass('h-hidden', false);
				}
			} else {
				this.$loginBtn.toggleClass('h-hidden', false);
			}

			T.Utils.View.stopLoader();
		},

		checkTokenAndGetJoinCode: function checkTokenAndGetJoinCode(event) {
			event.preventDefault();

			T.Utils.Auth.getBearerToken(this.getJoinCodeEnBW.bind(this));
		},

		getJoinCodeEnBW: function getJoinCodeEnBW(token) {
			const url = T.Utils.Helper.appendURIPath(this._backend, this._apiJoincodeEnBW);
			const options = {
				url: url,
				type: 'POST',
				headers: {
					Authorization: `Bearer ${token}`
				}
			};

			T.Utils.Ajax.fragment(options, this.handleSuccess.bind(this), this.handleApilError.bind(this));
		},

		handleSuccess: function handleSuccess(result) {
			if (result && result.Data && result.Data.JoinCode !== "") {
				this.$reqJoincodeBtn.toggleClass('h-hidden', true);
				this.$ctx.find(".js-joincode").text(result.Data.JoinCode);
				this.$ctx.find(".js-valid").text(moment(result.Data.ValidUntil).format("DD.MM.YYYY HH:mm"));
				this.$ctx.find('.js-joincode-success').toggleClass('h-hidden', false);
				// Mail sending currently disabled until CR for email input field.
				//this.sendMail(result.Data.JoinCode, moment(result.Data.ValidUntil).format("DD.MM.YYYY HH:mm"));
			} else {
				this.$ctx.find(".js-no-code").toggleClass('h-hidden', false);
			}
			T.Utils.View.stopLoader();
		},

		handleApilError: function handleApilError(err) {
			if (err && err.Messages && err.Messages.length > 0 && err.Messages[0].Messsage && err.Messages[0].Messsage.includes("reference already exists")) {
				this.$ctx.find('.js-reference-exists-error').toggleClass('h-hidden', false);
			} else {
				this.$ctx.find('.js-technical-error').toggleClass('h-hidden', false);
			}

			T.Utils.View.stopLoader();
		},

		sendMail: function sendMail(joincode, joincodevaliduntil) {
			if (this._user.UserData.Email.length > 0) {
				const url = T.Utils.Helper.appendURIPath(window.location.origin, this._apiSendMail);

				const requestOptions = {
					method: 'POST',
					url: url,
					data: {
						"processid": this._processid,
						"joincode": joincode,
						"joincodevaliduntil": joincodevaliduntil,
						"targetemail": this._user.UserData.Email
					},
					contentType: "application/x-www-form-urlencoded;charset=utf-8"
				};

				T.Utils.Ajax.fragment(requestOptions, this.handleSendMailSuccess.bind(this), () => { });
			}
		},

		handleSendMailSuccess: function handleSendMailSuccess() {
			this.$ctx.find('.js-joincode-email').toggleClass('h-hidden', false);
		}
	});
}(jQuery));