(function($) {
	'use strict';
	/**
	 * MyAdacCheckboxImage module implementation.
	 *
	 * @author Tobias <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class MyAdacPromotionBox
	 * @extends T.Module
	 */
	T.Module.MyAdacCheckboxImage = T.createModule({

        /** @type {jQuery} */
        $module: null,

		start: function (resolve)
		{
			this.$module = $(this._ctx);
			resolve();
		},
	});
}(jQuery));
