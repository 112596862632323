(function ($) {
	'use strict';
	/**
	 * BasicListCompare module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicList
	 * @extends T.Module
	 */
	T.Module.BasicList.Compare = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$swiperContainer: null,

		/** @type {jQuery} */
		$swiperSlidesWrapper: null,

		/** @type {Object[]} */
		$swiperSlides: null,

		/** @type {jQuery} */
		$swiperBtnLeft: null,

		/** @type {jQuery} */
		$swiperBtnRight: null,

		/** @type {Number} */
		$spaceBetween: 0,

		/** @type {Boolean} */
		isLazy: true,

		/** @type {Boolean} */
		isLoaded: false,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$swiperContainer = this.$ctx.find('.swiper');
			this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper');
			this.$swiperSlides = this.$swiperSlidesWrapper.find('.swiper-slide');
			this.$swiperBtnLeft = this.$ctx.find('.swiper-button-prev');
			this.$swiperBtnRight = this.$ctx.find('.swiper-button-next');
			this.$spaceBetween = 11;

			// remove lazyload class from picture/img and replace them with swiper-lazy class
			S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides, true);

			resolve();
		},

		/**
		 * default cb function from observer when lazy loading
		 * is triggered or none lazy loading is needed/given
		 *
		 * @param target :: which initialized the lazy call
		 * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
		 * @private
		 */
		// eslint-disable-next-line
		_lazyUpdate(target, dataObj) {
			this.initSwiper();
		},

		/**
		 * init swiper
		 */
		initSwiper: function () {
			// init swiper
			if (!this.$swiperContainer.length) {
				return;
			}

			const simulateTouch = this.$ctx.data('mode') !== 'edit', // if sitecore edit mode
				keyboardControl = this.$ctx.data('mode') !== 'edit'; // if sitecore edit mode

			// init swiper container
			this.mySwiper = new Swiper(this.$swiperContainer[0], {
				slidesPerView: 'auto',
				spaceBetween: this.$spaceBetween,
				simulateTouch: simulateTouch,
				watchSlidesProgress: true,
				preloadImages: false,
				a11y: false,
				keyboard: {
					enabled: keyboardControl,
				},
				navigation: {
					prevEl: this.$swiperBtnLeft[0],
					nextEl: this.$swiperBtnRight[0],
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						speed: 500,
						preventInteractionOnTransition: false,
						slidesPerGroupAuto: false,
					},
					1024: {
						speed: 1500,
						preventInteractionOnTransition: true,
						slidesPerGroupAuto: true,
					}
				},
				lazy: {
					checkInView: true,
					loadPrevNextAmount: 3,
					loadPrevNext: true,
				},
				on: {
					init: (swiper) => {
						// enables/disables the swiper
						// - In the old logic, the slides were not centred
						S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
					},
					touchStart: (swiper) => {
						S.Utils.SwiperHelper.swiperPreventInteractionOnTransitionSwitch(swiper);
					},
					touchMove: (swiper) => {
						S.Utils.SwiperHelper.swiperPreventInteractionOnTransitionSwitch(swiper);
					},
					transitionEnd: (swiper) => {
						S.Utils.SwiperHelper.swiperPreventInteractionOnTransitionSwitch(swiper, true);
					},
					resize: (swiper) => {
						S.Utils.delayed(`basicList-compare-${this.$ctx.data('t-id')}`, 40, () => {
							// enables/disables the swiper
							// - In the old logic, the slides were not centred
							S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
						});
					},
				},
			});

			if (typeof this._sandbox.swiperInstance === 'undefined') {
				this._sandbox.swiperInstance = {};
			}
			this._sandbox.swiperInstance[this.$ctx.data('t-id')] = this.mySwiper;
		},
	});
}(jQuery));
