(function($) {
	'use strict';
	/**
	 * BasicNewsBox module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicNewsbox
	 * @extends T.Module
	 */
	T.Module.BasicNewsbox = T.createModule({
		/** @type {jQuery} */
        $ctx: null,

		/** @type {jQuery} */
		$contentContainer: null,

		/** ###### VES TAB STUFF ####### */

		/** @type {jQuery}  */
		$truncHead: null,

		/** @type {jQuery}  */
		$truncHeadPdfContent: null,

		/** @type {jQuery} */
		$truncPara: null,

		/** @type {Number} */
		headlineWordCount: 0,

		/** @type {Number} */
		paragraphWordCount: 0,

		/** @type {Boolean} */
		headIsPdfLink: false,

		/** @type {Boolean} */
		needOverflow: true,

        /** @type {Boolean} */
        needRestore: false,

        /** @type {Number} */
        siblingCount: 0,

		/**	###### VES TAB END ####### */

		/**
         * Initialize
         *
         * @param {function} resolve
         */
		start: function(resolve) {
			this.$ctx = $(this._ctx);

			// newsboxes in ves-tab get a special truncation (...)
			if ( this.$ctx.closest('.m-ves-tab').length ) {

				this.$contentContainer = this.$ctx.find('.mm-content').eq(0);

				// headline
				this.$truncHead = this.$contentContainer.find('h2, h3').eq(0);
				// paragraph
				this.$truncPara = this.$contentContainer.find('p').eq(0);

				this.siblingCount = this.$ctx.siblings('.m-basic-newsbox').length;

				// headline might be a pdf link which changes the calc logic cause we have to take care of the
				// pdf infos (icon & filesize)
				if ( this.$truncHead.find('.a-basic-icon-pdf').length ) {
                    this.headIsPdfLink = true;
                    this.$truncHeadPdfContent = this.$truncHead.find('span:not(.a-basic-icon-pdf)').eq(0);
                }

				this.countWords();
			}

			resolve();
		},

		/**
		 * Check the viewport (y)-position for the elem to prevent performance bugs for non visible boxes while manipulating strings
		 * in handleOverflowText();
		 *
		 */
		visibilityCheck: function () {

			const that = this;

            if ( !that.$ctx.hasClass('is-hidden') && that.needOverflow ) {

                const elementTop = that.$ctx.offset().top,
                    elementBottom = elementTop + that.$ctx.outerHeight(),
                    viewportTop = $(window).scrollTop(),
                    viewportBottom = viewportTop + $(window).height(),
                    isVisible = elementBottom > viewportTop && elementTop < viewportBottom;

                if ( isVisible ) {
                    that.handleOverflowText();
                }
            }
		},

		/**
		 * count words in headline and p elem to get a limit value for the truncation loops
		 *
		 */
		countWords: function () {

			const that = this,
				paraRawString = that.$truncPara[0].textContent;
			let headRawString = that.$truncHead[0].textContent;

			if ( that.headIsPdfLink ) {
				headRawString = that.$truncHeadPdfContent[0].textContent;
			}

			that.headlineWordCount = headRawString.trim().split(' ').length;
			that.paragraphWordCount = paraRawString.trim().split(' ').length;

			that.handleLinebreaks();
		},

		/**
		 * insert a whitespace ' ' before each br to prevent the truncation of the last word in a visible row followed by a linebreak (<br>)
		 *
		 * remove whitespaces ' ' after each br to enable truncation of the last word in a visible row preceded by a linebreak (<br>)
		 */
		handleLinebreaks: function () {

			const that = this;

			// selects br incl. all(!) whitespaces around the tag
			const regex = /\s*<br\s*[/]?>\s*/gi; //NOSONAR expression checked

			// headline
			if ( that.$truncHead.find('br').eq(0).length ) {
				const headStr = that.$truncHead.html().trim();
				that.$truncHead.html(headStr.replace(regex, " <br>"));
			}

			// paragraph
			if ( that.$truncPara.find('br').eq(0).length ) {
				const paraStr = that.$truncPara.html().trim();
				that.$truncPara.html(paraStr.replace(regex, " <br>"));
			}

			that.storeOriginalContent('store');
		},

		/**
		 * store the strings as data-attr. onInit() to cut / restore the original content if necessary
		 *
		 * @param mod (store / restore) - stores content in data-attr / restores content from data-attr
		 *
 		 */
		storeOriginalContent: function (mod) {

			const that = this,
				dataOriginalString = 'data-js-original-string';

			// store
			if ( mod === 'store' ) {

				// headline
				if ( that.headIsPdfLink ) {
					that.$truncHead.attr(dataOriginalString, that.$truncHeadPdfContent[0].innerHTML);
				}

				else {
					that.$truncHead.attr(dataOriginalString, that.$truncHead[0].innerHTML);
				}

				// paragraph
				that.$truncPara.attr(dataOriginalString, that.$truncPara[0].innerHTML);

				that.resizeAndScroll();
                that.visibilityCheck();
			}

			// restore
			else {

                that.needOverflow = true;
                that.needRestore = false;

				// headline
				if ( that.headIsPdfLink ) {
					that.$truncHeadPdfContent[0].innerHTML = that.$truncHead.attr(dataOriginalString);
				}

				else {
					that.$truncHead[0].innerHTML = that.$truncHead.attr(dataOriginalString);
				}

				// paragraph
				that.$truncPara[0].innerHTML = that.$truncPara.attr(dataOriginalString);
			}
		},

		/**
		 * add / remove truncation ('...') till text content fits wrapper
		 *
		 */
		handleOverflowText: function () {

			const that = this,
				heightBuffer = 6;
			let headLoopCount = 0,
				paraLoopCount = 0;

			const compareParaHeight = that.$truncPara.height(),
				compareHeadHeight = that.$truncHead.height() + heightBuffer;

			// headline
			if ( that.headIsPdfLink ) {

				// compare the overall.height of the head tag against the a tag inside, instead of comparing
				// scrollheights to elem.heights ( see markup structure to understand that :P )
				while ( that.$truncHead.find('> a').eq(0).height() > compareHeadHeight ) {

					that.$truncHeadPdfContent[0].innerHTML = that.$truncHeadPdfContent[0].innerHTML.replace(/\W*\s(\S)*$/, '...'); //NOSONAR expression checked
					headLoopCount++;

                    that.needRestore = true;

					/*
                        Saves the page from infinity loops causing the browser to crash:
                        Since we compare dynamic heights, we might get false height
                        values due to rounding and font-rendering differences etc.
                        Therefore we limit the loop to stop if it tries to cut the string
                        more often than there are words in the original string.
                    */
					if (headLoopCount >= that.headlineWordCount ) {
						break;
					}
				}
			}

			else {

				while ( that.$truncHead[0].scrollHeight > compareHeadHeight ) {

					that.$truncHead[0].innerHTML = that.$truncHead[0].innerHTML.replace(/\W*\s(\S)*$/, '...'); //NOSONAR expression checked
					headLoopCount++;

                    that.needRestore = true;

					if (headLoopCount >= that.headlineWordCount ) {
						break;
					}
				}
			}


			// paragraph
			while ( that.$truncPara[0].scrollHeight > compareParaHeight ) {

				that.$truncPara[0].innerHTML = that.$truncPara[0].innerHTML.replace(/\W*\s(\S)*$/, '...'); //NOSONAR expression checked
				paraLoopCount++;

                that.needRestore = true;

				if (paraLoopCount >= that.paragraphWordCount ) {
					break;
				}
			}

			that.needOverflow = false;
		},

		/**
		 *
		 */
		resizeAndScroll: function () {

			const that = this;
			let oldWidth = $(window).width();

			const tID = that.$ctx.data('t-id'),
				delayVal = 50,
				eventNewsBoxWindowResizeDelay = 'basicNewsBox.windowResizeDelayForTruncation_';

			const callVisibility = () => {
				that.visibilityCheck();
			};

			window.addEventListener('scroll',  callVisibility,  { passive: true });

			$(window).on('resize', () => {

				S.Utils.Helper.mq( 'tablet' ).addListener( function () {
					S.Utils.delayed(`${eventNewsBoxWindowResizeDelay + tID   }_tablet`, delayVal, () => {
						that.storeOriginalContent('restore');
                        that.visibilityCheck();
					});
				});

				S.Utils.Helper.mq( 'desktop' ).addListener( function () {
					S.Utils.delayed(`${eventNewsBoxWindowResizeDelay + tID   }_desktop`, delayVal, () => {
						that.storeOriginalContent('restore');
                        that.visibilityCheck();
					});
				});

				S.Utils.delayed(`${eventNewsBoxWindowResizeDelay + tID   }`, delayVal, () => {
					if ( oldWidth > $(window).width() ) {
						that.needOverflow = true;
					}

					else if (  oldWidth < $(window).width() ) {
						that.storeOriginalContent('restore');
					}

                    that.visibilityCheck();
					oldWidth = $(window).width();
				});
			});
		}

	});
}(jQuery));
