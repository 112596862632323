/**
 * SocialWall components
 *
 * @author  <marc.radziwill@merkle.com>
 * @class SocialWall
*/

// eslint-disable-next-line no-unused-vars
class SocialWall extends BasisComponent {
	posts = [];
	currentPage = 1;
	_connectedCallback() {
		T.Utils.Application.init();
		this._readConfig();
		this._addEventListeners();
		this._requestData();
	}

	_readConfig() {
		this.config = JSON.parse(this.dataset.config ? this.dataset.config : {});

		this.$ctx = jQuery(this);
		this._$resultItemTemplate = jQuery(this.$ctx.find(".js-item-template").html());
		this._$resultWrapper = this.$ctx.find('.js-items-wrapper');
		this.$moreButton = this.$ctx.find('.js-more-items');
	}

	_addEventListeners() {
		this.$moreButton.on('click', this._requestMoreDataEventHandler.bind(this));
	}

	_requestData() {
		T.Utils.View.startLoader();

		const request = {
			method: 'GET',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.config.firstposts),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this._handleDataSuccess.bind(this), this._handleDataError.bind(this));
	}

	_requestMoreData() {
		T.Utils.View.startLoader();
		const request = {
			method: 'GET',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), `${this.config.moreposts}${this.currentPage - 1}`),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this._handleDataSuccess.bind(this), this._handleDataError.bind(this));
	}



	_requestMoreDataEventHandler() {
		if (this.posts.length <= this.currentPage * 12) {
			if (this.hasMorePosts) {
				this._requestMoreData();
			}
		} else {
			const postsToShow = this.currentPage * 12 + (this.posts.length < 12 ? this.posts.length : 12);
			this._createPosts(postsToShow);
			this.currentPage += 1;
		}

		this.$moreButton.toggleClass('h-hidden', !this.hasMorePosts);
		T.Utils.View.stopLoader();
	}



	_handleDataSuccess(data) {
		if (data) {
			this.hasMorePosts = data.more ? data.more : false;
			this.$moreButton.toggleClass('h-hidden', !this.hasMorePosts);
			const newPosts = this._createPostsData(data.updates);

			const filteredNewPosts = newPosts.filter(newPost =>
				!this.posts.some(existingPost => existingPost.id === newPost.id)
			);
			this.posts = this.posts.concat(filteredNewPosts);

			if (this.currentPage === 1) {
				this._createPosts(12);
				if (data.updates.length > 12) {
					this.$moreButton.toggleClass('h-hidden', false);
				}
			} else {
				this._createPosts(this.posts.length);
			}
		}
		T.Utils.View.stopLoader();
	}

	_handleDataError() {
		T.Utils.View.stopLoader();
	}

	_createPostsData(updates) {
		return updates.map(update => {
			return {
				id: update.message.id,
				postLayout: update.message.layout,
				postType: update.message.media ? update.message.media.type : 'text',
				hasMedia: !!update.message.media,
				media: update.message.media ? update.message.media : {},
				user: this._getUserName(update.message),
				serviceOrg: update.message.service,
				service: this._getPostService(update.message.service),
				link: this._getPostLink(update.message),
				text: update.message.text,
				date: new Date(update.message.time).toLocaleDateString('de-DE', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric'
				})
			};
		});
	}

	_createPosts(count) {
		this._$resultWrapper.empty();
		const postsToShow = this.posts.slice(0, count);
		postsToShow.forEach((post) => {
			const $resultItem = this._$resultItemTemplate.clone();
			$resultItem.attr('data-id', post.id);
			$resultItem.find('.mm-name').text(post.user);
			this.setIcons($resultItem, post);
			this.setLink($resultItem, post);

			$resultItem.find('.mm-date').html(post.date);
			$resultItem.find('.mm-name').html(post.user);
			$resultItem.find('.mm-text').html(post.text);
			if (post.hasMedia) {
				this.setImageToPost($resultItem, post.media.image);
			}
			this._$resultWrapper.append($resultItem);
		});
		if (this.posts.length <= count) {
			this.$moreButton.addClass('h-hidden');
		}
		this.$ctx.toggleClass('h-hidden', false);
	}

	setLink($resultItem, post) {
		const postLink = post.link;
		if (postLink) {
			$resultItem.find('.mm-link').toggleClass('h-hidden');
			const $externalLink = $resultItem.find('.mm-link a').first();

			const templateText = $externalLink.text();
			$externalLink.text(templateText.interpolate({ platform: post.service }));
			$externalLink.attr('href', postLink);

			const $likeButton = $resultItem.find('.mm-like');
			const $shareButton = $resultItem.find('.mm-share');
			const $commentButton = $resultItem.find('.mm-comment');

			$resultItem.find('.mm-actions').toggleClass('h-hidden');
			$likeButton.attr('href', postLink);
			$shareButton.attr('href', postLink);
			$commentButton.attr('href', postLink);
		}
	}

	getPostIcon(post) {
		const lowerserviceorg = post.serviceOrg.toLowerCase();
		if (lowerserviceorg === 'twitter'
			|| lowerserviceorg === 'youtube'
			|| lowerserviceorg === 'instagram'
			|| lowerserviceorg === 'facebook'
			|| lowerserviceorg === 'tiktok'
			|| lowerserviceorg === 'linkedin') {
			return lowerserviceorg;
		}
		else if (lowerserviceorg === 'x') {
			return 'twitter';
		}
		return 'arrow';
	}

	setIcons($resultItem, post) {
		const iconList = ['twitter', 'youtube', 'instagram', 'facebook', 'x', 'linkedin', 'tiktok'];
		if (iconList.includes(post.service.toLowerCase())) {
			const $icon = $resultItem.find('.mm-icon');
			$icon.toggleClass('h-hidden', false);
			$icon.addClass(`mm-icon--${this.getPostIcon(post)}`);
		}
	}

	setImageToPost($resultItem, image) {
		if (image) {
			const $img = jQuery('<img>');
			$img.attr('src', image.url);
			$img.attr('loading', 'lazy');
			$img.on('error', function () {
				jQuery(this).hide();
			});
			const $picture = jQuery('<picture>').append($img);
			$resultItem.find('.mm-media').empty().append($picture);
		}
	}

	_getUserName(message) {
		if (message.service === 'instagram' && (!message.user || message.user.name === '(No User Info)')) {
			return message.srvinfo.replace('Recent ', '');
		}
		return message.user && message.user.name !== '(No User Info)' ? message.user.name : '';
	}

	_getPostLink(message) {
		return message.link || message.srvlink || '';
	}

	_getPostService(service) {
		if (service === 'www') {
			return 'adac.de';
		}
		if (service === 'twitter') {
			return 'X';
		}
		return service || '';
	}
}
window.customElements.define('social-wall', SocialWall);