'use strict';
(function ($) {
	/**
	 * QuickStart module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class QuickStart
	 * @extends T.Module
	 */
	T.Module.QuickStart = T.createModule({
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this._readAllAnchorAndBuildQuickstart();

			resolve();
		},
		/**
		 * find all anchor with class js-anchor and put it to view quickstart
		 */
		_readAllAnchorAndBuildQuickstart: function _readAllAnchorAndBuildQuickstart() {
			$('div.js-anchor').each(function () {
				const anchorShortdescription = $(this).attr("data-shortdescription");
				if (this.id && anchorShortdescription) {
					$(".js-ul-anchorlist").append(`<li><a href='#${this.id}' title='${anchorShortdescription}'>${anchorShortdescription}</a></li>`);
				}
			});
		}
	});
})(jQuery);