(function ($)
{
    'use strict';
    /**
     * CompareProductSticky module implementation.
     *
     * @author  <n.bork@edelweiss72.de>
     * @namespace T.Module
     * @class CompareProductSticky
     * @extends T.Module
     */
    T.Module.CompareProductSticky = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {Number} */
        $ctxTID: null,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {jQuery} */
        $swiperSlidesWrapper: null,

        /** @type {Object[]} */
        $swiperSlides: null,

        /** @type {jQuery} */
        $swiperBtnLeft: null,

        /** @type {jQuery} */
        $swiperBtnRight: null,

        /** @type {Number} */
        $spaceBetween: 0,

        /** @type {jQuery} */
        $swiper: null,

        /** @type {string} */
        mode: '',

        /** @type {Boolean} */
        isLazy: false,

        /** @type {Boolean} */
        isLoaded: false,

        /**
         * Initialize.
         *
         * @param {function} resolve
         * @param {Object} decoratorData
         */
        start: function (resolve, decoratorData)
        {
            this.$ctx = $(this._ctx);
            this.$ctxTID = this.$ctx.data('t-id');

			// classes/events/attr as strings
			this.swiperWrapper = '.swiper-wrapper';
			this.swiperSlide = '.swiper-slide';
			this.stuckBottom = 'is-stuck-bottom';
			this.compareContainer = '.mm-compare-container';
			this.compareSlide = '.mm-compare-slide';
			this.mainCenter = '.ll-main-center';
			this.compareProductId = 'compare-product-id';
			this.compareProductImg = 'compare-product-img';
			this.swiperWrapperSlide = '.swiper-wrapper .swiper-slide';

            this.$swiperContainer = this.$ctx.find('.swiper');
            this.$swiperSlidesWrapper = this.$swiperContainer.find(this.swiperWrapper);
            this.$swiperSlides = this.$swiperSlidesWrapper.find(this.swiperSlide);
            this.$swiperBtnLeft = this.$ctx.find('.swiper-button-prev');
            this.$swiperBtnRight = this.$ctx.find('.swiper-button-next');
            this.mode = this.$ctx.data('mode');
            this.$spaceBetween = decoratorData.spaceBetween || 0;

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides, true);

            resolve();
        },

        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate(target, dataObj)// eslint-disable-line
        {
            // init swipe
            this.initSwiper();

            // init event
            this.initEvent();

            if (sessionStorage.getItem('compareProduct'))
            {
                this.initFillProducts();
            }

            // init sortable
            this.initSortable();

            // init click preview
            this.initClickPreview();

            // init add checked product
            this.addCheckedProduct();

            this.initSticky();

            this.initPosition();
        },

        /**
         * init swipe
         */
        initSwiper: function ()
        {
            // if sitecore edit mode
            const keyboardControl = this.$ctx.data('mode') !== 'edit';

            // init swiper container
            this.$swiper = new Swiper(this.$swiperContainer[0], {
                spaceBetween: this.$spaceBetween,
                slidesPerView: 'auto',
                slidesPerGroupAuto: true,
                simulateTouch: false,
                watchSlidesProgress: true,
                preloadImages: false,
                preventInteractionOnTransition: true,
                keyboard: {
                    enabled: keyboardControl,
                },
                navigation: {
                    prevEl: this.$swiperBtnLeft[0],
                    nextEl: this.$swiperBtnRight[0],
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        speed: 500,
                    },
                    1024: {
                        speed: 1500,
                    }
                },
                on: {
                    init: (swiper) =>
                    {
                        // enables/disables the swiper
                        // - In the old logic, the slides were not centred
                        S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                    },
                    resize: (swiper) =>
                    {
                        S.Utils.delayed(`compareproductsticky-check-${this.$ctxTID}`, 50, () =>
                        {
                            // enables/disables the swiper
                            // - In the old logic, the slides were not centred
                            S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);

                            this.calcPosition('resize');

                            this.initPosition();
                        });
                    },
                    // for adding and removing the compare products we need to listen to update aswell
                    update: (swiper) =>
                    {
                        S.Utils.delayed(`compareproductsticky-update-check-${this.$ctxTID}`, 100, () =>
                        {
                            // enables/disables the swiper
                            // - In the old logic, the slides were not centred
                            S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                        });
                    },
                    /**
                     * @param {Object} swiper
                     * @param {HTMLElement} slideEl
                     * @param {HTMLElement} imageEl :: if needed
                     */
                    lazyImageLoad: (swiper, slideEl) =>
                    {
                        const $slide = $(slideEl);

                        // kept the same old logic from loadVisibleImages() Function
                        $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                        $slide.find('.js-init').removeClass('js-init');
                    },
                },
            });

            if (typeof this._sandbox.swiperInstance === 'undefined')
            {
                this._sandbox.swiperInstance = {};
            }
            this._sandbox.swiperInstance[this.$ctx.data('t-id')] = this.$swiper;
        },

        /**
         *  update swiper
         */
        updateSwiper: function ()
        {
            this.$swiperSlides = this.$ctx.find(this.swiperSlide);

            this.$swiper.update();
        },

        /**
         * init sticky
         */
        initSticky: function ()
        {
            document.addEventListener('scroll', () =>
            {
                S.Utils.delayed('BasicCompareProductSticky.delayScroll', 40, () =>
                {
                    this.calcPosition();
                });
            }, {passive: true});
        },

        /**
         * calculate the module position to be sticky or not
         */
        calcPosition()
        {
            const footerElem = document.querySelector('.l-footer');

            if (this._ctx.getBoundingClientRect().bottom >= footerElem.getBoundingClientRect().top - 10)
            {
                // calc distance to footer -> use parent bc its no changing pos. on calc
                const dist = this.$ctx.parent()[0].getBoundingClientRect().bottom - footerElem.getBoundingClientRect().top;

                this.$ctx.removeClass('is-stuck-unbottom');
                this.$ctx.addClass(this.stuckBottom);

                this.$ctx.css({'bottom': dist});
            }

            if (window.innerHeight < footerElem.getBoundingClientRect().top)
            {
                this.$ctx.addClass('is-stuck-unbottom');
                this.$ctx.removeClass(this.stuckBottom);
                this.$ctx.removeAttr('style');

                this.initPosition();
            }
        },

        /**
         * toggle
         */
        initEvent: function ()
        {
            this.$ctx.find(this.compareContainer).addClass('mm-compare-container--hidden');

            let compareHeight = 0;

            this.$ctx.find(this.compareContainer).css('display', 'block');

            setTimeout(() =>
            {
                compareHeight = this.$ctx.find(this.compareContainer).outerHeight() + 50;
            }, 10);

            this.$ctx.find(this.compareContainer).css('display', 'none');

            this.$ctx.find(this.compareSlide).on('click', () =>
            {
                this.$ctx.find(this.compareContainer).stop().slideToggle(() =>
                {
                    $(this.compareSlide).toggleClass('mm-opened');
                });

                if (this.$ctx.find(this.compareSlide).hasClass('mm-opened'))
                {
                    this.$ctx.closest(this.mainCenter).animate({'padding-bottom': 0});
                }
                else
                {
                    this.$ctx.closest(this.mainCenter).animate({'padding-bottom': compareHeight});
                }
            });
        },

        /**
         * add counter
         */
        addCounter: function ()
        {
            const $counters = this.$ctx.find('.m-counter'),
                num = parseInt($counters.first().text());

            $counters.text(num + 1);
        },

        /**
         * subtract counter
         */
        subtractCounter: function ()
        {
            const $counters = this.$ctx.find('.m-counter'),
                num = parseInt($counters.first().text());

            $counters.text(num - 1);
        },

        /**
         * delete slides
         */
        initDelete: function ()
        {
            this.$ctx.find('.mm-icon-cancel').off('click.compare-delete').one('click.compare-delete', (e) =>
            {
                const items = JSON.parse(sessionStorage.getItem('compareProduct'));

                items.splice($(e.currentTarget).closest(this.swiperSlide).index() - 1, 1);

                sessionStorage.setItem('compareProduct', JSON.stringify(items));

                $(e.target).closest(this.swiperSlide).remove();

                this.subtractCounter();
                this.updateSwiper(true);
                this.isEmpty();

                const productId = $(e.target).next('.mm-figure-product').find('.mm-product').data(this.compareProductId);

                $('.m-basic-table--compare-result input[type=checkbox]').each((index, item) =>
                {

                    const inputId = $(item).data(this.compareProductId);

                    if ($(item).is(':checked') === true && inputId === productId)
                    {
                        $(item).prop('checked', false);
                    }
                });
            });
        },

        /**
         * sortable products
         */
        initSortable: function ()
        {
            this.$ctx.find(this.swiperWrapper).sortable({
                items: this.swiperSlide,
                axis: 'x',
                opacity: 0.85,
                revert: 100
            });

            this.$ctx.find(this.swiperWrapper).disableSelection();
        },
        /**
         * check if empty
         */
        isEmpty: function ()
        {
            if (this.$ctx.find(this.swiperWrapperSlide).length > 0)
            {
                this.$ctx.find('.m-basic-text').addClass('m-basic-text--hidden');
            }
            else
            {
                this.$ctx.find('.m-basic-text').removeClass('m-basic-text--hidden');
            }
        },

        /**
         * init fill productlist
         */
        initFillProducts: function ()
        {
            const items = JSON.parse(sessionStorage.getItem('compareProduct'));

            $.each(items, (index, value) =>
            {
                if (value.mode === this.mode)
                {
                    this.addProduct(value.id, value.name, value.img);
                }
            });
        },
        /**
         * add product in sticky element
         */
        initClickPreview: function ()
        {
            const limit = this.$ctx.data('maximum');

            $('.js-add-to-compare-sticky').on('click', (e) =>
            {
                e.preventDefault();

                const id = $(e.target).data(this.compareProductId),
                    name = $(e.target).data('compare-product-name'),
                    img = $(e.target).data(this.compareProductImg);

                if (this.$ctx.find(this.swiperWrapperSlide).length < limit)
                {
                    if (sessionStorage.getItem('compareProduct'))
                    {
                        const items = JSON.parse(sessionStorage.getItem('compareProduct'));

                        let count = 0;

                        $.each(items, (index, value) =>
                        {

                            if (value.id === id)
                            {
                                count++;
                            }
                        });

                        if (count === 0)
                        {
                            this.addProduct(id, name, img);

                            items.push({
                                mode: this.mode,
                                id: id,
                                name: name,
                                img: img
                            });

                            sessionStorage.setItem('compareProduct', JSON.stringify(items));
                        }
                    }
                    else
                    {
                        const compareProduct = [];

                        compareProduct.push({
                            mode: this.mode,
                            id: id,
                            name: name,
                            img: img
                        });

                        sessionStorage.setItem('compareProduct', JSON.stringify(compareProduct));

                        // add product in sticky element
                        this.addProduct(id, name, img);

                        // swiper update
                        this.updateSwiper(true);
                    }
                }
                else
                {
                    this.$ctx.find('a.js-lightbox[href="#popup_placeholder_limit"]').trigger('click');
                }
            });
        },

        /**
         * append product
         */
        addProduct: function (id, name, img)
        {
            const $figureProducts = $('.swiper-slide .mm-figure-product'),
                $compareSticky = $('.js-add-to-compare-sticky');

            $(`<div class="swiper-slide"><button class="mm-icon-cancel"></button><figure class="mm-figure-product"><img src="${img}" data-src="${img}" data-compare-product-id="${id}" alt="" title="" class="mm-product lazyload" /></figure><div class="mm-description" data-compare-product-id="${id}">${name}</div></div>`).appendTo('.mm-compare-container .swiper .swiper-wrapper');

            if ($compareSticky.data(this.compareProductImg) === null || $compareSticky.data(this.compareProductImg) === '')
            {
                $figureProducts.css({
                    'display': 'none',
                });
            }
            else
            {
                $figureProducts.css({
                    'display': 'block',
                });
            }

            this.isEmpty();
            this.addCounter();
            this.initDelete();
            this.updateSwiper(true);

            this.$ctx.find(this.compareContainer).css('display', 'block');
            this.$ctx.find(this.compareSlide).addClass('mm-opened');

            if (this.$ctx.find(this.compareSlide).hasClass('mm-opened'))
            {
                if (this.$ctx.hasClass(this.stuckBottom))
                {
                    this.$ctx.animate({'bottom': '0'});
                }

                this.$ctx.closest(this.mainCenter).css({'padding-bottom': $('.m-compare-product-sticky').outerHeight()});
            }
        },
        /**
         * append checked product
         */
        addCheckedProduct: function ()
        {
            const limit = this.$ctx.data('maximum');

            $('.js-add-checked-to-compare-sticky').on('click', (e) =>
            {
                e.preventDefault();

                let count = 0;

                $('.m-basic-table--compare-result input[type=checkbox]').each((index, item) =>
                {

                    if ($(item).is(':checked') === true)
                    {

                        count++;

                        const id = $(item).data(this.compareProductId);

                        const name = $(item).data('compare-product-name');

                        const img = $(item).data(this.compareProductImg);

                        if (this.$ctx.find(this.swiperWrapperSlide).length < limit)
                        {

                            if (sessionStorage.getItem('compareProduct'))
                            {
                                const items = JSON.parse(sessionStorage.getItem('compareProduct'));

                                let count = 0;

                                $.each(items, (index, value) =>
                                {

                                    if (value.id === id)
                                    {
                                        count++;
                                        $('a.js-lightbox[href="#popup_placeholder_exists"]').trigger('click');
                                    }
                                });

                                if (count === 0)
                                {
                                    this.addProduct(id, name, img);

                                    items.push({
                                        mode: this.mode,
                                        id: id,
                                        name: name,
                                        img: img
                                    });

                                    sessionStorage.setItem('compareProduct', JSON.stringify(items));
                                }

                            }
                            else
                            {
                                const compareProduct = [];

                                compareProduct.push({
                                    mode: this.mode,
                                    id: id,
                                    name: name,
                                    img: img
                                });

                                sessionStorage.setItem('compareProduct', JSON.stringify(compareProduct));

                                // add product in sticky element
                                this.addProduct(id, name, img);

                                // swiper update
                                this.updateSwiper(true);
                            }
                        }
                        else
                        {
                            this.$ctx.find('a.js-lightbox[href="#popup_placeholder_limit"]').trigger('click');
                        }

                    }
                });

                if (count === 0)
                {
                    this.$ctx.find('a.js-lightbox[href="#popup_placeholder_unchecked"]').trigger('click');
                }
            });
        },

        /**
         * positioning
         */
        initPosition: function ()
        {
            const contentWidth = $('.l-main-content').outerWidth(),
                trueWidth = $(this.mainCenter).outerWidth() - contentWidth;

            if ($('.m-layout-main-nav-left').length)
            {
                if (!S.Utils.Helper.mq('desktop').matches)
                {
                    // mobile + tablet
                    this.$ctx.css({
                        'left': '0px',
                    });
                }
                else
                {
                    // desktop
                    this.$ctx.css({
                        'left': trueWidth,
                    });
                }
            }
        }
    });
}(jQuery));
