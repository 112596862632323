(function ($) {
    'use strict';
    /**
     * AdacMaps module implementation.
     *
     * @author Lars Meyer <l.meyer@edelweiss72.de>
     * @namespace T.Module
     * @class AdacMaps
     * @extends T.Module
     */
    T.Module.AdacMaps = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {Object} */
        $switch: null,

        /** @type {Object} */
        $poiBtns: null,

        /** @type {Object} */
        $resultList: null,

        start: function (resolve) {

            this.$ctx = $(this._ctx);
            this.$switch = this.$ctx.find('.js-switch');
            this.$poiBtns = this.$ctx.find('.mm-pin');
            this.$resultList = this.$ctx.find('.mm-result-list');

            this.addEventListeners();

            resolve();
        },

        /**
         * Listen to
         *
         * - window.resize()
         * - events emitted by other components (e.g. filter)
         * - buttons like switch map
         *
         */
        addEventListeners() {

            const that = this;

            // map / list switch
            that.$switch.on('click', function (e) {
                e.preventDefault();
                that.switchMap(this);
            });

            //mark active list Element
            that.$resultList.find('li').on('click', function (e) {
                that.$resultList.find('li').removeClass('is-highlight');
                that.$resultList.find('li .mm-pin').removeClass('is-active');
                $(e.currentTarget).addClass('is-highlight');
                $(e.currentTarget).find('.mm-pin').addClass('is-active');
            });

            // show map on click
            that.$poiBtns.each((index, btn) => {
                $(btn).on('click', () => {
                    that.switchMap(that.$switch.filter('[data-switch="map"]'));
                });
            });
        },

        /**
         * Switch between map and list in mobile
         *
         * @param (triggerBtn) {Object} - btn that fired
         */
        switchMap(triggerBtn) {

            const $wrapper = $(triggerBtn).closest('.m-adac-maps');

            let $showElement,
                $hideElement,
                mapIsVisible = false;

            if (String($(triggerBtn).data('switch')) === String('map')) {
                $showElement = $wrapper.find('.mm-map-wrapper');
                $hideElement = $wrapper.find('.mm-result-list-wrap');

                mapIsVisible = true;
            }

            else {
                $showElement = $wrapper.find('.mm-result-list-wrap');
                $hideElement = $wrapper.find('.mm-map-wrapper');
            }

            $(triggerBtn).addClass('is-active');
            $(triggerBtn).siblings('.js-switch').removeClass("is-active");

            $showElement.addClass("is-active");
            $hideElement.removeClass("is-active");

            if (mapIsVisible) {
                this._events.emit('AdacMaps.viewSwitchToMap');
            }
        }
    });
}(jQuery));