(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.Reset = {

		/**
		 * init reset call
		 *
		 */
		init: function () {

		},

		/**
		 *
		 * call doc.trigger with data-t-id as event namespace:
		 * the given components listen to this event and handle the resets by themselves
		 *
		 * moduleId {Number} - data-t-id of the component
		 *
		 */
		reset: function (moduleId) {
			if (typeof moduleId === 'number') {
				$(document).trigger(`resetModule.${moduleId}`);
			}
		},

	};

	$(document).ready(function () {
		S.Utils.Reset.init();
	});

})(jQuery);
