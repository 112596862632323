(function ($) {
	'use strict';

	/**
	 * Toggle decorator implementation for the BasicFormOverview component.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class Toggle
	 * @extends T.Module
	 */
	T.Module.BasicFormOverview.Toggle = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$legendTriggers: null,


		start: function (resolve) {
			this.$ctx = $(this._ctx);

			this.$legendTriggers = this.$ctx.find('.oo-fieldset > legend');
			this.ooFieldset = '.oo-fieldset';

			this.addToggleListeners();
			this.toggleContent('init', 'undefined');

			this._parent.start(resolve);
		},

		/**
		 *
		 *
		 */
		addToggleListeners: function () {

			this.$legendTriggers.each((index, legendElem) => {

				$(legendElem).on('click', () => {
					$(legendElem).closest(this.ooFieldset).toggleClass('is-open');
					this.toggleContent('update', $(legendElem).closest(this.ooFieldset));

				});


			});
		},

		/**
		 *
		 *
		 */
		toggleContent: function (mod, wrapper) {

			if (mod === 'init') {

				this.$ctx.find(this.ooFieldset).each((index, fieldWrap) => {

					if ($(fieldWrap).hasClass('is-open')) {
						$(fieldWrap).find('.oo-row:not(.hidden)').slideDown();
					}

					else {
						$(fieldWrap).find('.oo-row').slideUp();
					}
				});

			}

			else {

				if ($(wrapper).hasClass('is-open')) {
					$(wrapper).find('.oo-row:not(.hidden)').slideDown();
				}

				else {
					$(wrapper).find('.oo-row').slideUp();
				}
			}


		},


	});
}(jQuery));
