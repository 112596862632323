/**
 * EventTrackingGeneric implementation
 *
 * @author <marc.radziwill@merkle.com>
 * @class EventTrackingGeneric
*/

// eslint-disable-next-line no-undef
class EventTrackingGeneric extends EventTrackingBase {

	_bindTrackingEvents($context) {
		if ($context.length) {
			$context.find('input[type="submit"], input[type="button"], button, a, div.aa-slider.js-slider').not('[data-gtm-init]').attr("data-gtm-init", true).on('click', (evt) => {
				const $element = jQuery(evt.currentTarget);
				const eventData = {
					eventAction: this._getEventActionByElement($element),
					eventLabel: this._getLabelFromElement($element, evt),
					eventValue: this._getEventValue($element)
				};

				eventData.eventCategory = this._getEventCategory($element, eventData.eventAction);

				const gtmCustom = $element.data("gtm-custom");
				if (gtmCustom) {
					const customEventData = gtmCustom.split(':');
					eventData[customEventData[0]] = customEventData[1];
				}
				T.Utils.Tracking.pushData({
					eventData: eventData,
					event: 'click'
				});
			});
		}
	}

	_getEventValue($element) {
		let eventValue = '';
		if ($element.is('div.aa-slider.js-slider')) {
			const $inp = $element.prev('input');
			if ($inp.length) {
				eventValue = parseInt($inp.val());
			}
		}
		return eventValue;
	}

	_getEventCategory(element, action) {
		let result = `ADAC.de | ${this._getContentType()}`;
		if (action && action.startsWith('navigation_link_level')) {
			const topmenu = T.Utils.Format.normalizeSpace(element.parents('[data-gtm-eventaction="navigation_link_level1"] > li').find('> a').text());
			if (topmenu) {
				result = `${result} | ${topmenu}`;
			}
		}
		return result;
	}

	_getLabelFromElement($element, event) {
		if ($element.length) {
			const gtmEventLabel = $element.data("gtm-eventlabel");
			if (gtmEventLabel) {
				return gtmEventLabel;
			} else if ($element.is('div.aa-slider.js-slider')) {
				return "Slider";
			} else if (event.target && event.target.nodeName === 'IMG') {
				return event.target.alt;
			} else if ($element.is("input")) {
				return $element.val();
			}

			const href = $element.attr("href");
			if (href && href.startsWith("mailto:")) {
				return "Kontakt E-Mail";
			}
			return T.Utils.Format.normalizeSpace($element.text());
		}
		return '';
	}

	_getEventActionByElement($element) {
		let eventAction = '';
		if ($element.length) {
			eventAction = $element.data("gtm-eventaction");
			if (!eventAction) {
				if ($element.parents('[data-gtm-eventaction]').length > 0) {
					eventAction = $element.parents('[data-gtm-eventaction]').first().data("gtm-eventaction");
				} else if ($element.data("t-name") === "BasicBtn" || $element.attr('href') === '#') {
					eventAction = "BasicBtn";
				} else {
					eventAction = 'richtext_link';
				}
			}
			eventAction += ' | click';
		}
		return eventAction;
	}
}
window.customElements.define('event-tracking-generic', EventTrackingGeneric);


if (typeof module !== "undefined" && module.exports) {
	module.exports = EventTrackingGeneric;
}