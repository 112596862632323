"use strict";
/* eslint-disable sonarjs/cognitive-complexity */
(function ($) {

	/*istanbul ignore next*/
	T.Utils = T.Utils || {};
	T.Utils.Captcha = {

		initClass: '.js-captcha',

		hasCaptcha: false,

		init: function init() {
			this.start();
		},

		start: function start() {
			this.$captchaElem = $(this.initClass);
			if (this.$captchaElem && this.$captchaElem.length) {
				this.registerListeners();

				this.config = this.$captchaElem.data('config');
				if (!this.config) {
					console.warn('No captcha HTML element or config found.');
					return;
				}
				this.hasCaptcha = true;
				this.getCaptchaCode(this.setCaptchaCode.bind(this), this.clearCaptchaInput.bind(this));
			}
		},

		registerListeners: function registerListeners() {
			this.$captchaElem.find('.js-create-new-code').on('click', this.getCaptchaCode.bind(this));
		},

		getCaptchaCode: function getCaptchaCode(successCallback, errorCallback) {
			return T.Utils.Ajax.fragment({
				method: 'GET',
				xhrFields: { withCredentials: true },
				url: this.config.apigetcaptchacode,
			},
				'function' === typeof successCallback ? successCallback : this.setCaptchaCode.bind(this),
				'function' === typeof errorCallback ? errorCallback : () => { });
		},

		validateCaptchaCode: function validateCaptchaCode(code, successCallback, errorCallback) {
			return T.Utils.Ajax.fragment({
				method: 'GET',
				data: {
					code: code
				},
				xhrFields: { withCredentials: true },
				url: this.config.apicheckcaptchacode,
			}, successCallback, errorCallback);
		},

		setCaptchaCode: function setCaptchaCode(content) {
			this.$captchaElem.find(".js-captcha-img").attr("src", `data:image/jpeg;base64,${content}`);
		},

		getCaptchaInput: function getCaptchaInput() {
			return this.$captchaElem.find("#captcha").val();
		},

		clearCaptchaInput: function clearCaptchaInput() {
			this.$captchaElem.find("#captcha").val("");
		},
	};
	T.Utils.Captcha.init();
})(jQuery);