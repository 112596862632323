(function ($) {
    'use strict';
    /**
     * VesTab module implementation.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class Tab
     * @extends T.Module
     */
    T.Module.VesTab = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $nav: null,

        /** @type {Object[]} */
        $navList: null,

        /** @type {Object[]} */
        $navBtn: null,

        /** @type {jQuery} */
        $content: null,

        /** @type {Object[]} */
        $tabcontents: null,

        /** @type {String} */
        sessionTabId: null,

        /** @type {String} */
        sessionNewsBoxId: null,


        /**
         * Initialize
         *
         * @param {function} resolve
         */
        start: function (resolve) {
            const that = this;
            this.$ctx = $(this._ctx);

            // component stuff
            this.$nav = this.$ctx.find('.mm-nav').eq(0);
            this.$navList = this.$nav.find('> li');
            this.$navBtn = this.$nav.find('a');
            this.$tabcontentWrap = this.$ctx.find('.mm-tabcontent');
            this.$tabcontents = this.$ctx.find('.mm-tabcontent-inside');
            this.$selectBtn = this.$ctx.find('.a-basic-input-select option');
            this.$selectBox = this.$ctx.find('.m-select-box');

            this.liActiveLink = 'li.is-active > a';
            this.inputSelectBoxOptionSelected = '.a-basic-input-select > .m-select-box > option:selected';

            this.getSessionMatch();

            // set nav btn events
            if (this.$ctx.hasClass('m-ves-tab--filter')) {
                this.setNavBtnEventsFilter();
                this.fillTabContent();
                this.resizeTabContent();
                this.handleContentLinkEvent();
                this.scrollToActive();
            } else {
                this.setNavBtnEvents();
            }

            // dublicate tab headline for print into tabcontent
            this.$navBtn.each(function (index, item) {
                const headline = $(item).html();
                $(that.$tabcontents[index]).prepend(`<h2 class="mm-tabcontent-printheadline">${headline}</h2>`);
            });

            resolve();
        },

        /**
         * setNavBtnEvents
         *
         */
        setNavBtnEvents: function () {
            const that = this;

            this.$navBtn.on('click touch', (e) => {
                e.preventDefault();

                const activeContentId = that.$ctx.find(this.liActiveLink).attr('href').substring(1),
                    newTab = $(e.currentTarget),
                    newId = newTab.attr('href').substring(1);

                if (newId !== activeContentId) {

                    // reset active class
                    that.$navList.removeClass('is-active');

                    // set active
                    newTab.closest('li').addClass("is-active");

                    // hide actual content
                    that.$ctx.find(`#${activeContentId}`).hide();

                    // show new content
                    that.$ctx.find(`#${newId}`).removeClass('h-visuallyhidden').show();
                }

                return false;
            });

            // init tab controling
            if (document.activeElement) {
                $(window).on('keydown', function (e) {
                    const activeElem = $(document.activeElement);
                    const activeElemListParent = $(document.activeElement).closest('li');

                    // check if activeElem is inside basicTab
                    if (activeElem.closest('.m-basic-tab').length && activeElem.closest('.mm-nav').length) {

                        // enter
                        if (e.which === 13) {
                            activeElem.trigger('click');
                        }

                        // right arrow
                        if (e.which === 39 && activeElemListParent.next().length) {
                            activeElemListParent.next().find('> a').focus();
                        }

                        // left arrow
                        if (e.which === 37 && activeElemListParent.prev().length) {
                            activeElemListParent.prev().find('> a').focus();
                        }
                    }
                });
            }
        },


        /**
         * setNavBtnEventsFilter
         *
         */
        setNavBtnEventsFilter: function () {

            const that = this;

            this.$navBtn.on('click touch', (e) => {
                e.preventDefault();

                const activeContentId = that.$ctx.find(this.liActiveLink).attr('href').substring(1),
                    newTab = $(e.currentTarget),
                    newId = newTab.attr('href').substring(1);

                if (newId !== activeContentId) {

                    // reset active class
                    that.$navList.removeClass('is-active');

                    // set active
                    newTab.closest('li').addClass("is-active");

                }

                that.fillTabContent();

                return false;
            });

            this.$selectBox.change(() => {
                that.fillTabContent();
            });

            // init tab controling
            if (document.activeElement) {
                $(window).on('keydown', function (e) {
                    const activeElem = $(document.activeElement);
                    const activeElemListParent = $(document.activeElement).closest('li');

                    // check if activeElem is inside basicTab
                    if (activeElem.closest('.m-basic-tab').length && activeElem.closest('.mm-nav').length) {

                        // enter
                        if (e.which === 13) {
                            activeElem.trigger('click');
                        }

                        // right arrow
                        if (e.which === 39 && activeElemListParent.next().length) {
                            activeElemListParent.next().find('> a').focus();
                        }

                        // left arrow
                        if (e.which === 37 && activeElemListParent.prev().length) {
                            activeElemListParent.prev().find('> a').focus();
                        }
                    }
                });
            }
        },

        /**
         * fillTabContent
         *
         */
        fillTabContent: function () {
            const that = this,
                items = that.$tabcontentWrap.children();
            let activeContentId;

            if (S.Utils.Helper.mq('mobile').matches) {
                activeContentId = that.$ctx.find(this.inputSelectBoxOptionSelected).val();
            } else {
                if (that.$ctx.find(this.liActiveLink).length > 0) {
                    activeContentId = that.$ctx.find(this.liActiveLink).attr('href').substring(1);
                }
            }

            items.addClass('is-hidden');
            items.last().removeClass('is-hidden');

            items.each((i, el) => {
                if (($(el).data('tab-id') && $.inArray(activeContentId, $(el).data('tab-id')) !== -1) || typeof activeContentId === 'undefined') {
                    $(el).removeClass('is-hidden');
                }

            });
        },

        resizeTabContent: function () {

            S.Utils.Helper.mq('mobile').addListener(() => {
                if (S.Utils.Helper.mq('mobile').matches) {
                    const activeContentId = this.$ctx.find(this.liActiveLink).attr('href').substring(1);

                    this.$ctx.find(".a-basic-input-select > .m-select-box").val(activeContentId);
                } else {
                    const activeContentId = this.$ctx.find(this.inputSelectBoxOptionSelected).val();

                    // reset active class
                    this.$navList.removeClass('is-active');

                    // set active
                    this.$ctx.find(`a[href='#${activeContentId}']`).closest('li').addClass("is-active");
                }
            });
        },

        /**
         * handles the click event for mBasicNewsboxes for searchresults
         */
        handleContentLinkEvent() {

            this.$tabcontentWrap.on({
                click: (e) => {
                    const $currentClickedBox = $(e.currentTarget),
                        currentTabId = S.Utils.Helper.mq('mobile').matches ? this.$ctx.find(this.inputSelectBoxOptionSelected).val() : this.$ctx.find('.mm-nav > li.is-active > a').attr('href').substring(1),
                        clickedBoxId = $currentClickedBox.closest('.m-basic-newsbox').data('t-id');

                    this.setSession(currentTabId, clickedBoxId);
                }
            }, '.m-basic-newsbox .mm-content-link');
        },

        /**
         * set data in sessionStorage for active Tab and clicked Newsbox
         *
         * @param {String} clickedTabId
         * @param {Number} clickedNewsBoxId
         */
        setSession(clickedTabId, clickedNewsBoxId) {
            const basicResultData = { "tabId": clickedTabId, "newsBoxId": clickedNewsBoxId };

            if (window.sessionStorage) {
                sessionStorage.setItem('basicsearch_result', JSON.stringify(basicResultData));
            }
        },

        /**
         * checks if the someone puts something in the searchfield and pushed Browser-reload
         * on Browser-reload the searching-js saves the search word in sessionStorage
         * @return {boolean}
         */
        getSessionMatch() {
            for (let i = 0; i < sessionStorage.length; i++) {
                const key = sessionStorage.key(i);

                if (key === 'basicsearch_result') {
                    const sessionData = JSON.parse(sessionStorage.getItem(key));

                    this.sessionTabId = sessionData.tabId;
                    this.sessionNewsBoxId = sessionData.newsBoxId;

                    this.handleSessionData();
                    return true;
                }
            }

            return false;
        },

        /**
         *
         */
        handleSessionData() {
            if (S.Utils.Helper.mq('mobile').matches) {
                this.$ctx.find('.a-basic-input-select > .m-select-box').val(this.sessionTabId);
            } else {
                this.$navList.removeClass('is-active');
                this.$ctx.find(`a[href='#${this.sessionTabId}']`).closest('li').addClass("is-active");
            }
        },

        /**
         *
         */
        scrollToActive() {
            const that = this;
            setTimeout(function () {

                const puffer = $(window).height() / 5;
                if (that.$tabcontentWrap.find(`[data-t-id="${that.sessionNewsBoxId}"]`).length !== 0) {
                    const newsBoxPos = that.$tabcontentWrap.find(`[data-t-id="${that.sessionNewsBoxId}"]`).offset().top - puffer;
                    $(window).scrollTop(newsBoxPos);
                }

            }, 1000);

        }

    });
}(jQuery));