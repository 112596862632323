(function ($) {
	'use strict';
	/**
	 * FdlCreditcardconfig-Aktionen module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class FdlCreditcardconfig-Aktionen
	 * @extends T.Module.FdlCreditcardconfig
	 */
	T.Module.FdlCreditcardconfig.Aktionen = T.createDecorator({
		/**
		 * start function
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);

			this.$specialbox = this.$ctx.find('.oo-specialbox .a-basic-input-checkbox > input ');
			this.$specialboxPrice = this.$specialbox.parents('.oo-input').next('.oo-pricing-box').find('.oo-price-value');

			this.$pkgBox = this.$ctx.find('.oo-packagebox');
			this.$stickyFooter = this.$ctx.find('.oo-fdl-stickyfooter');
			this.$stickyFooterMonth = this.$stickyFooter.find('.oo-stickyfooter-price > .oo-monthly > .oo-monthly-val');
			this._clearError();
			this._addListeners();
			const checkCountLocal = this._retrieveControlState(true);
			this._parent.start(resolve);
			this.$checkCount = checkCountLocal;

			T.Utils.Application.init();
			this._requestActionPrices();
		},

		_addListeners: function () {
			this.$ctx.find('.oo-stickyfooter-button *[data-t-name="BasicBtn"]').click(this._storeControlState.bind(this));
			if (this.$ctx.find('#confirm_submit')) {
				this.$ctx.find('#confirm_submit').click(this._confirmSubmit.bind(this));
			}
		},

		/**
		 * set info-layer data via prices from other elemens
		 *
		 */
		setLayerData: function () {
			this.$tippLayerTrigger.each((index, item) => {
				if ($(item).next('div.tipplayer-container').length) {
					const layerContainer = $(item).next('div.tipplayer-container');
					$(layerContainer).find('.diff-price').empty();
					$(layerContainer).find('.platinum-price').empty();
				}
			});
			this._parent.setLayerData();
		},

		calcFooterInitial: function (checkCount) {

			const that = this;
			if (checkCount === 0) {
				if (that.$specialbox.prop('checked')) {
					that.$stickyFooterMonth.text(that.$specialboxPrice.text());
				} else {
					that.$stickyFooterMonth.text('0,00 €');
				}
			}

			else {

				let boxValSum = 0.0;
				that.$pkgBox.each(function (index, element) {
					const checkBox = $(element).find('.oo-checkbox >> input[type=checkbox]');
					if (checkBox.prop('checked')) {
						const boxVal = that.convertToNumber($(element).find('.oo-price').text());
						boxValSum = boxValSum + boxVal;
					}
				});
				that.$stickyFooterMonth.text(that.convertToString(boxValSum));
			}
		},

		_requestActionPrices: function () {
			const $self = this;
			const modulkreditkarteapi = this._getApiKreditkarte(),
				advertKey = this._getAdvertisingKey();
			const url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), modulkreditkarteapi);

			T.Utils.View.startLoader();
			$.ajax({
				url: url,
				type: 'GET',
				xhrFields: { withCredentials: true },
				data: { Werbeschluessel: advertKey }
			}).then(
				function (data) {
					T.Utils.View.stopLoader();
					let prices = null;
					if (data.Data) {
						prices = $self.extractPrices(data.Data);
					}
					if (prices === null || prices?.pricesReise.price < 0.0 || prices.pricesSparen.price < 0.0 || prices.pricesSchutz.price < 0.0 || prices.pricesPlatin.price < 0.0) {
						$self._showError('Technischer Fehler im ADAC: Daten nicht vollständig verfügbar');
					} else {
						this._setActionPrices(data, $self, prices);
					}
				},
				function (jqxhr, textStatus) {
					T.Utils.View.stopLoader();
					let errorText = '';
					if (textStatus !== '') {
						errorText = textStatus;
					}
					if (jqxhr?.responseJSON?.Messages) {
						$.each(jqxhr.responseJSON.Messages, function (index, message) {
							errorText = `<br>${jQuery('<div/>').text(message).html()}`;
						});
					}
					$self._showError(errorText);
				}
			);
		},
		_setActionPrices: function _setActionPrices(data, $self, prices) {
			if (data.Data.InfoTextAktion && data.Data.InfoTextAktion !== 'adac.de') {
				const actionPriceElement = $self.$ctx.parent().find('#creditCardActionText');
				actionPriceElement.removeClass('h-hidden');
				const actionPriceText = actionPriceElement.find('p');
				actionPriceText.text(data.Data.InfoTextAktion);
			}
			if (data?.Data?.Hauptkarte?.PreisPeriode1 !== undefined && data?.Data?.Hauptkarte?.PreisPeriode1 !== '') {
				if (data.Data.Hauptkarte.PreisPeriode1 > 0) {
					const annualfee = $self.$ctx.find('.oo-annualfee');
					annualfee.removeClass('h-hidden');
					annualfee.text(`Jahreskartengebühr: ${$self.convertToString(data.Data.Hauptkarte.PreisPeriode1)}`);
				} else {
					const noannualfee = $self.$ctx.find('.oo-noannualfee');
					noannualfee.removeClass('h-hidden');
				}
			}
			$self.$specialboxPrice.text($self.convertToString(prices.pricesPlatin.price));
			const specialBoxOldPrice = $self.$specialbox.parents('.oo-input').next('.oo-pricing-box').find('.oo-price-old');
			$self._setOldPriceElement(prices.pricesPlatin, specialBoxOldPrice);

			$self.$pkgBox.each(function (index, element) {
				const priceElement = $(element).find('.oo-price');
				const oldPriceElement = $(element).find('.oo-price-old');
				const checkBox = $(element).find('.oo-checkbox >> input[type=checkbox]');
				switch (index) {
					case 0:
						priceElement.text($self.convertToString(prices.pricesReise.price));
						$self._setOldPriceElement(prices.pricesReise, oldPriceElement);
						$self.calcFooter(checkBox, $self.$checkCount);
						break;
					case 1:
						priceElement.text($self.convertToString(prices.pricesSparen.price));
						$self._setOldPriceElement(prices.pricesSparen, oldPriceElement);
						$self.calcFooter(checkBox, $self.$checkCount);
						break;
					case 2:
						priceElement.text($self.convertToString(prices.pricesSchutz.price));
						$self._setOldPriceElement(prices.pricesSchutz, oldPriceElement);
						$self.calcFooter(checkBox, $self.$checkCount);
						break;
				}
			});
			if ($self.$specialbox.prop('checked')) {
				$self.calcFooter($self.$specialbox, 0);
			}
			$self._updateGUI();
		},
		_updateGUI: function () {
			if (this.$tippLayerTrigger.length) {
				this.setLayerData();
			}

			this.calcFooterInitial(this.$checkCount);

			this.calcFooterPos();
			this.setArrows();
			this.windowResize();
			this.calcHeights();
			this.setScrollBar();
		},

		_getApiKreditkarte: function () {
			return this.$ctx.attr('data-modulkreditkarteapi');
		},

		_getAdvertisingKey: function () {
			return this.$ctx.attr('data-modulkreditkartewbs');
		},

		_showError: function (errorMsgTxt) {
			const errorMsgElement = this.$ctx.parent().find('.m-basic-hint--error');
			errorMsgElement.removeClass('h-hidden');
			const errorMsgRow = errorMsgElement.find(' div > h2');
			errorMsgRow.html(errorMsgTxt);
		},

		_clearError: function () {
			const errorMsgRow = this.$ctx.find('.l-form > .ll-row');
			errorMsgRow.removeClass('is-error');
			errorMsgRow.find('.ll-error-msg').html("");
		},

		_storeControlState: function _storeControlState() {
			const checkBoxes = this.$pkgBox.find('.oo-checkbox > div > input[type=checkbox]');
			const cardRadios = this.$ctx.find('.oo-stickyfooter-input > div > input[type=radio]');
			if (checkBoxes.length === 3) {
				const configParam = {
					checkStateSpecial: this.$specialbox.prop('checked'),
					checkState0: $(checkBoxes[0]).prop('checked'),
					checkState1: $(checkBoxes[1]).prop('checked'),
					checkState2: $(checkBoxes[2]).prop('checked'),
					checkStateCardColorBlack: false,
					checkStateCardColorPetrol: false
				};
				if (cardRadios.length === 2) {
					configParam.checkStateCardColorBlack = $(cardRadios[0]).prop('checked');
					configParam.checkStateCardColorPetrol = $(cardRadios[1]).prop('checked');
				}
				T.Utils.Store.set('fdlcreditcardconfigparam', configParam, "session");
				if (!configParam.checkState0 && !configParam.checkState1 && !configParam.checkState2 && !configParam.checkStateSpecial) {
					$.magnificPopup.open({
						mainClass: 'l-lightbox',
						items: {
							src: $('#popup_placeholder_selection'),
							type: 'inline'
						}
					});
				}
				else {
					const submitButtonLink = this.$ctx.find('#submitButtonLink');
					if (submitButtonLink) {

						window.location = submitButtonLink.val();
					}
				}
			}
		},

		_confirmSubmit: function _confirmSubmit() {
			const submitButtonLink = this.$ctx.find('#submitButtonLink');
			if (submitButtonLink) {
				window.location = submitButtonLink.val();
			}
		},

		_retrieveControlState: function _retrieveControlState(readUrlParams) {

			let configParam = T.Utils.Store.get('fdlcreditcardconfigparam', "session");


			const checkBoxes = this.$pkgBox.find('.oo-checkbox > div > input[type=checkbox]');
			const cardRadios = this.$ctx.find('.oo-stickyfooter-input > div > input[type=radio]');
			if (!configParam && readUrlParams) {
				configParam = this._parseConfigFromUrlParams();
			}


			let result = 0;
			if (configParam) {
				if (configParam['checkStateCardColorBlack']) {
					$(cardRadios[0]).prop('checked', true);
				} else if (configParam['checkStateCardColorPetrol']) {
					$(cardRadios[1]).prop('checked', true);
				}
				for (let i = 0; i < checkBoxes.length; i++) {
					const c = configParam[`checkState${i}`];
					$(checkBoxes[i]).prop('checked', c);
					if (c) {
						result++;
					}
					this.calcFooter(checkBoxes[i], result);
				}
				this.$specialbox.prop('checked', configParam.checkStateSpecial);
				if (configParam.checkStateSpecial) {
					result = 0;
					this.calcFooter(this.$specialbox, result);
				}
			}
			return result;
		},

		_parseConfigFromUrlParams: function _parseConfigFromUrlParams() {
			const result = {
				checkStateSpecial: 0 < this.getUrlParam('platin').length,
				checkState0: 0 < this.getUrlParam('reise').length,
				checkState1: 0 < this.getUrlParam('sparen').length,
				checkState2: 0 < this.getUrlParam('schutz').length,
				checkStateCardColorBlack: true,
				checkStateCardColorPetrol: false
			};
			if (result.checkState0 && result.checkState1 && result.checkState2) {
				result.checkStateSpecial = true;
			}
			if (result.checkStateSpecial) {
				for (let i = 0; i < 3; i++) {
					result[`checkState${i}`] = false;
				}
			}
			return result;
		},

		/**
		 * ensure that the api rout starts with a /
		 * @param {string} api
		 */
		ensureApiPrefix: function ensureApiPrefix(api) {
			if (!api.startsWith("/")) {
				return `/${api}`;
			}
			return api;
		},

		getUrlParam: function (key) {
			const result = new RegExp(`${key}=([^&]*)`, "i").exec(window.location.search);
			return result && unescape(result[1]) || "";
		},

		extractPrices: function (moduleParent) {

			const $self = this;
			const module = moduleParent.Module;
			const result = {
				"pricesReise": { price: -1.0, oldPrice: -1.0 },
				"pricesSparen": { price: -1.0, oldPrice: -1.0 },
				"pricesSchutz": { price: -1.0, oldPrice: -1.0 },
				"pricesPlatin": { price: -1.0, oldPrice: -1.0 }
			};
			if (module) {
				$.each(module, function () {
					if (this.Bezeichnung === 'Reise') {
						result.pricesReise = $self.extractModulePrices(this);
					} else if (this.Bezeichnung === 'Sparen') {
						result.pricesSparen = $self.extractModulePrices(this);
					} else if (this.Bezeichnung === 'Schutz') {
						result.pricesSchutz = $self.extractModulePrices(this);
					} else if (this.Bezeichnung === 'Platin') {
						result.pricesPlatin = $self.extractModulePrices(this);
					}
				});
			}
			return result;
		},

		extractModulePrices: function (moduleApiData) {
			const result = { price: -1.0, oldPrice: -1.0 };
			if (moduleApiData.DauerPeriode > 0) {
				result.price = moduleApiData.PreisPeriode2;
				result.oldPrice = moduleApiData.PreisPeriode1;
			}
			else {
				result.price = moduleApiData.PreisPeriode1;
			}
			return result;
		},

		_setOldPriceElement: function (prices, oldPriceElement) {
			if (prices !== null && prices.oldPrice >= 0.0) {
				oldPriceElement.text(this.convertToString(prices.oldPrice));
			} else {
				oldPriceElement.addClass('h-hidden');
			}
		}

	});
})(jQuery);
