/// <reference path="../../../../assets/typings/terrific-ext.d.ts" />

'use strict';
(function ($) {
	/**
	 * LocalCounselingAppointment module implementation.
	 *
	 * @author  <dmitri.zoubkov@mtc.berlin>
	 * @memberof T.Module
	 * @class LocalCounselingAppointment
	 * @extends T.Module
	 */
	T.Module.LocalCounselingAppointment = T.createModule({
		optionsSelector: '.js-appointment-options',

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');
			this._readConfiguration();
			this._addListeners();
			this._setupValidation();
			resolve();
		},
		_readConfiguration: function _readConfiguration() {
			T.Utils.Application.init();
			this.links = this.$ctx.data('link-config');
			this.apiAppointment = this.$ctx.data('api-appointment');
			this.apiRegionalclub = this.$ctx.data('api-regionalclub');
			this.apiGeolocation = this.$ctx.data('api-geolocation');
		},
		_addListeners: function _addListeners() {
			this._getFormField("Plz").on('change', this._clearErrors.bind(this));
			this.$ctx.find('.js-submit-search').on('click', this._searchAppointment.bind(this));
			this.$form.on('submit', this._searchAppointment.bind(this));
			this.$ctx.find('.js-locate-me').on('click', this._locateUser.bind(this));

			this.$ctx.find('.js-back-to-previous').on('click', this._switchPages.bind(this, false));
			this.$ctx.find('.js-submit-parameters').on('click', this._handleConselingParams.bind(this));
		},
		_setupValidation: function _setupValidation() {
			const validationmessages = this.$ctx.data('validation-messages') || {};
			const rulesAndMessages = {
				rules: {
					Plz: {
						required: true,
						digits: true,
						maxlength: 5,
						minlength: 5
					}
				},
				messages: validationmessages
			};

			T.Utils.Validation.init(this.$ctx, this.$form, rulesAndMessages);
		},
		_locateUser: function _locateUser() {
			if (!navigator.geolocation) {
				return;
			}
			T.Utils.View.startLoader();
			try {
				navigator.geolocation.getCurrentPosition(this._handleGeolocationSuccess.bind(this), this._handleGeolocationError.bind(this), { timeout: 10000, maximumAge: 300000 });
			} catch (e) {
				T.Utils.View.stopLoader();
			}
		},
		_handleGeolocationSuccess: function _handleGeolocationSuccess(position) {
			const point = T.Utils.Geo.latlonToMercator({
				x: position.coords.longitude,
				y: position.coords.latitude
			});
			this._requestLocationDetails(point);
		},
		_handleGeolocationError: function _handleGeolocationError(e) {
			T.Utils.View.stopLoader();
			if (e) {
				console.error(e);
			}
		},
		_requestLocationDetails: function _requestLocationDetails(mercator) {
			const req = {
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() },
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiGeolocation),
				data: {
					X: mercator.x,
					Y: mercator.y
				}
			};
			T.Utils.Ajax.fragment(req, this._handleLocationDetailsSuccess.bind(this), this._handleGeolocationError.bind(this));
		},
		_handleLocationDetailsSuccess: function _handleLocationDetailsSuccess(data) {
			if (data && data.Success && data.Data && data.Data.length) {
				let postal = null;
				if (data.Data.some((item) => {
					if (item.Postleitzahl) {
						postal = item.Postleitzahl;
						return true;
					}
					return false;
				})) {
					this._getFormField("Plz").val(postal);
					this.$form.find('input').each(function () { T.Utils.Validation._decorateElement(this); });
				}
			}
			T.Utils.View.stopLoader();
		},
		_searchAppointment: function _searchAppointment(e) {
			if (e) {
				e.preventDefault();
			}
			this._showNotFound(false);
			this._showError(false);
			if (this.$form.valid()) {
				T.Utils.View.startLoader();
				const req = {
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.apiRegionalclub),
					data: {
						Plz: this._getFormField("Plz").val()
					},
					method: "POST",
					contentType: "application/x-www-form-urlencoded;charset=utf-8"
				};
				T.Utils.Ajax.fragment(req, (data) => {
					try {
						if (data && data.Success && data.Data) {
							this._requestCounselingUrl(data.Data.RcCode);
						} else {
							this._showNotFound();
							T.Utils.View.stopLoader();
						}
					} catch (err) {
						this._handleCodeError(err);
					}
				}, this._handleApiError.bind(this));
			}
		},
		_requestCounselingUrl: function _requestCounselingUrl(rcCode) {
			if (this.links) {
				if (this.links[rcCode]) {
					this._handleCounselingData(this.links[rcCode]);
				} else {
					this._showNotFound();
				}
				T.Utils.View.stopLoader();
			} else {
				// local API (same host)
				const req = {
					url: T.Utils.Helper.appendURIPath("/", this.apiAppointment.interpolate({ rccode: rcCode }))
				};
				T.Utils.Ajax.fragment(req, (data) => {
					try {
						if (data && data.Success && data.Data) {
							this._handleCounselingData(data.Data);
						} else if (data.Messages && data.Messages.length && -1 < String(data.Messages[0].Message).toLowerCase().indexOf("server error")) {
							this._showError();
						} else {
							this._showNotFound();
						}
						T.Utils.View.stopLoader();
					} catch (err) {
						this._handleCodeError(err);
					}
				}, this._handleApiError.bind(this));
			}
		},
		_handleCounselingData: function _handleCounselingData(data) {
			this.$form.attr('action', data.link);
			if (data.hasparameters) {
				this._switchPages(true);
			} else {
				this._loadCounselingUrl(data.link);
			}
		},
		_handleConselingParams: function _handleConselingParams() {
			const fields = this.$form.find(this.optionsSelector).find('input, select, textarea').serializeArray();
			const params = {};
			fields.forEach(element => {
				if (element.value) {
					params[element.name] = element.value;
				}
			});
			this._loadCounselingUrl(this.$form.attr('action'), params);
		},
		_loadCounselingUrl: function _loadCounselingUrl(link, params) {
			let url = link;
			if (params) {
				url = T.Utils.Helper.updateUrlParameter(params, url);
			}
			try {
				const w = window.open(url, "adaccounseling");
				w.focus();
			} catch (e) {
				this._showPopupHint(url);
			}
		},
		_handleApiError: function _handleApiError(jqxhr) {
			T.Utils.View.stopLoader();
			const errorCode = String(jqxhr.status).substr(0, 3);
			if ('404' === errorCode) {
				this._showNotFound();
			} else {
				console.error(jqxhr);
				this._showError();
			}
		},
		_handleCodeError: function _handleCodeError(err) {
			T.Utils.View.stopLoader();
			T.Utils.Logger.log("Unexpected error", err);
			this._showError();
		},
		_switchPages: function _switchPages(showParams, evt) {
			if (evt) {
				evt.preventDefault();
			}
			this.$ctx.find(this.optionsSelector).toggleClass('h-hidden', !showParams);
			this.$ctx.find('.js-appointment-search').toggleClass('h-hidden', showParams);
		},
		_showNotFound: function _showNotFound(show = true) {
			this.$ctx.find('.js-message-notfound').toggleClass('h-hidden', !show);
		},
		_showError: function _showError(show = true) {
			this.$ctx.find('.js-error').toggleClass('h-hidden', !show);
		},
		_showPopupHint: function _showPopupHint(url) {
			this.$ctx.find('.js-popup-error').toggleClass('h-hidden', !url);
			if (url) {
				this.$ctx.find('.js-popup-link').attr('href', url);
			}
		},
		_clearErrors: function _clearErrors() {
			this._showError(false);
			this._showNotFound(false);
			this._showPopupHint();
		},
		_getFormField: function _getFormField(name) {
			return this.$form.find(`[name="${name}"]`);
		}
	});
})(jQuery);