(function ($) {
	'use strict';
	/**
	 * Damage Declaration decorator for the vesrscontentbox module
	 *
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class VesRsContentBox
	 * @extends T.Module
	 */
	T.Module.VesRsContentbox.Damagedeclaration = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$triggerCollection: null,

		/** @type {jQuery} */
		$hiddenContentCollection: null,

		/** @type {jQuery} */
		$triggerSpecialCollection: null,

		/** @type {jQuery} */
		$specialContentCollection: null,

		/** @type {jQuery} */
		$cloneButton: null,

		/** @type {jQuery} */
		$costInputs: null,

		/** @type {jQuery} */
		$currencyInputs: null,

		/** @type {jQuery} */
		$euroElem: null,

		/** @type {jQuery} */
		$chfElem: null,

		/** @type {jQuery} */
		$costTileContainer: null,

		/** @type {jQuery} */
		$costUploadContainer: null,

		/** @type {Number} */
		costCheckCount: 0,

		/** @type {jQuery} */
		$policeInputs: null,

		/** @type {jQuery} */
		$accOffenderInputs: null,

		/** @type {Number} */
		highCostSum: 0,

		start(resolve) {
			this.$ctx = $(this._ctx);

			this.$triggerCollection = this.$ctx.find('div[data-js-trigger-content] input, div[data-js-trigger-content] select');
			this.$hiddenContentCollection = $(document.querySelectorAll('div[data-js-hidden-content]'));

			// special stuff
			this.$triggerSpecialCollection = $(document.querySelectorAll('input[data-js-trigger-special], select[data-js-trigger-special]'));
			this.$specialContentCollection = $(document.querySelectorAll('div[data-js-special-content], input[data-js-special-content]'));

			// cost stuff
			this.$costInputs = this.$ctx.find('div[data-js-hidden-context="cost-type-upload"] .a-basic-input-text > input');
			this.$currencyInputs = this.$ctx.find('div[data-js-hidden-context="cost-type-upload"] .a-basic-input-select > select');
			this.$euroElem = this.$ctx.find('div[data-js-hidden-content="cost-type-upload"] span.js-value-eur');
			this.$chfElem = this.$ctx.find('div[data-js-hidden-content="cost-type-upload"] span.js-value-chf');

			this.$costTileContainer = $(document.querySelectorAll('.mm-tile-container[data-js-trigger-content="cost-type"]'));
			this.$costUploadContainer = $(document.querySelectorAll('.mm-upload-container[data-js-hidden-content="cost-type-upload"]'));

			// police stuff
			this.$policeInputs = this.$ctx.find('div[data-js-hidden-context="calledpolice"] .m-basic-upload input');

			// accident offender stuff
			this.$accOffenderInputs = this.$ctx.find('div[data-js-hidden-context="acc-offender-info"] .m-basic-upload input');

			// classes/events/attr as strings
			this.cloneRemove = '.js-clone-remove';
			this.basicUpload = '.m-basic-upload';
			this.jsClearButton = '.js-clear-button';
			this.rowContent = '.mm-row-content';
			this.inputTextInput = '.a-basic-input-text > input';
			this.inputTypeText = 'input[type=text]';
			this.divDataJsTriggerContent = 'div[data-js-trigger-content]';
			this.attrDataJSTriggerContent = 'data-js-trigger-content';
			this.isChecked = 'is-checked';
			this.optionSelected = 'option:selected';
			this.dataJsHiddenContext = 'data-js-hidden-context';
			this.dataJsHiddenContent = 'data-js-hidden-content';
			this.jsCloneSourceAccidentOffender = '.js-clone-source-accidentoffender';
			this.jsCloneAccidentOffender = '.js-clone-accidentoffender';
			this.dataJsSpecialContentHighCost = '[data-js-special-content=high-cost]';
			this.dataJsSpecialContentLowCost = '[data-js-special-content=low-cost]';

			//in case init of S.Globals.MediaQuery not happend yet
			if (S.Globals.MediaQuery.mqRightNow === '') {
				S.Globals.MediaQuery.checkMediaQuery();
			}

			if (this.$costTileContainer.length && this.$costUploadContainer.length && S.Globals.MediaQuery.mqRightNow !== 'desktop') {
				this.moveCostElems('mobile');
			}

			this.editValidationElems();
			this.addMoreEventListeners();

			this.calcCosts();

			// Is done in backend!
			// this.calcFileUploadSize();

			this.windowResize();

			this._parent.start(resolve);
		},

		/**
		 * add helper class if no error-msg is present in mm-upload-container
		 *
		 * in some cases theres no input distinct error msg, the whole mm-row has one error text elem for all inputs
		 *
		 */
		editValidationElems() {
			this.$ctx.find('.mm-upload-container > .mm-row > .mm-row-content--left').each((index, rowContent) => {

				if (!$(rowContent).find('> .ll-error-msg').length) {
					$(rowContent).addClass('is-no-error');
				}
			});
		},

		/**
		 * add events to elems to show/hide dependent contents
		 *
		 */
		// eslint-disable-next-line
		addMoreEventListeners: function () { //NOSONAR complexity

			const that = this,
				jsCloneCostDeclaration = '.js-clone-costdeclaration',
				rowContent = '.mm-row-content--',
				jsFileStored = 'js-file-stored',
				jsFileStoredClass = '.js-file-stored';

			if (that.$costInputs.length || that.$policeInputs.length || that.$accOffenderInputs.length) {
				// clear cost row / either clear or copy infos from first clone
				that.$ctx.find(this.cloneRemove).off('click.clearCostRow').on('click.clearCostRow', (e) => {
					// recheck if the source remove btn was triggered and not a clone elem which inherited the listener
					if (!$(e.currentTarget).parent(jsCloneCostDeclaration).length) {
						let leftRowContentSelector = 'left',
							$sourceLeftWrapper = $(e.currentTarget).closest('.mm-row').find(`${rowContent + leftRowContentSelector}.js-clone-source-costdeclaration`).eq(0);
						const $sourceRightWrapper = $(e.currentTarget).closest('.mm-row').find('.mm-row-content--right.js-clone-source-costdeclaration').eq(0);

						// check if there is the half-row instead
						if (!$sourceLeftWrapper.length) {
							leftRowContentSelector = 'half';
							$sourceLeftWrapper = $(e.currentTarget).closest('.mm-row').find(`${rowContent + leftRowContentSelector}.js-clone-source-costdeclaration`).eq(0);
						}

						// if there is a clone row left, copy all values and remove that cloned row instead of cleaning the source
						if ($sourceLeftWrapper.siblings(jsCloneCostDeclaration).length) {

							// trigger changes to fire the calc logics
							$sourceRightWrapper.find('.a-basic-input-select option:first').attr('selected', true).parent('select').trigger('change');

							// cleaning the input will trigger the upload remove click as well
							$sourceRightWrapper.find('.a-basic-input-text input').val('').trigger('change');

							// store all elems (except for the label) to move them from 1st clone to src wrappers
							const $cloneLeftWrapper = $sourceLeftWrapper.closest('.mm-row').find(rowContent + leftRowContentSelector + jsCloneCostDeclaration).eq(0),
								$cloneRightWrapper = $sourceLeftWrapper.closest('.mm-row').find('.mm-row-content--right.js-clone-costdeclaration').eq(0),
								$costElems = $cloneRightWrapper.find('.ll-col-data');
							let $uploadWrapper = $cloneLeftWrapper.find(this.basicUpload);

							// check if there is the half-row instead
							if (leftRowContentSelector === 'half') {
								$uploadWrapper = $cloneRightWrapper.find(this.basicUpload);
							}

							S.Utils.delayed('vesrs.damagedecl.waitForCostRowCleaning', 100, () => {

								// clear src row from old elems
								$sourceLeftWrapper.find('> div:not(.ll-col-label):not(.ll-error-msg):not(.ll-check)').remove();
								$sourceRightWrapper.find('> div:not(.ll-error-msg):not(.ll-check)').remove();

								// add new elems from the clone
								$uploadWrapper.detach();

								if (leftRowContentSelector === 'half') {
									$sourceRightWrapper.prepend($uploadWrapper);
								}

								else {
									$costElems.detach();
									$sourceLeftWrapper.append($uploadWrapper);
									$sourceRightWrapper.prepend($costElems);
								}

								// remove the empty clone row
								$cloneLeftWrapper.remove();
								$cloneRightWrapper.remove();
							});
						}

						// just clear
						else {

							if ($sourceLeftWrapper.length) {
								$sourceLeftWrapper.find(jsFileStoredClass).removeClass(jsFileStored);
								$sourceLeftWrapper.removeClass(jsFileStored);

								if ($sourceLeftWrapper.find('.js-data.is-active').length) {
									$sourceLeftWrapper.find(this.jsClearButton).trigger('click');
								}

								// reset cost val
								// change and blur trigger Namics validation and result in error styling here
								//$sourceRightWrapper.find(this.inputTextInput).val('').trigger('change').blur();
								$sourceRightWrapper.find(this.inputTextInput).val('').closest(this.rowContent).removeClass('is-check is-valid is-error');
								$sourceRightWrapper.find('.a-basic-input-select option:first').attr('selected', true);

								// clean validation styling classes

							}

							else if ($(e.currentTarget).prev(this.basicUpload).length) {
								$(e.currentTarget).prev(this.basicUpload).find('.m-data-wrap > .js-clear-button').trigger('click');
							}

							else {
								const $uploadWrapper = $(e.currentTarget).closest('.ll-row').find(this.basicUpload);

								if ($uploadWrapper.hasClass('js-upload')) {

									$uploadWrapper.closest('.ll-col-data').siblings(this.cloneRemove).hide();
									$uploadWrapper.find(this.jsClearButton).trigger('click');
									$uploadWrapper.find(jsFileStoredClass).removeClass(jsFileStored);
									$uploadWrapper.find('.a-basic-btn').show();
								}
							}
						}
					}
					else {
						S.Utils.Form.removeClonedRow($(e.currentTarget));
					}
				});
			}

			if (that.$triggerSpecialCollection.length) {

				that.$triggerSpecialCollection.each((index, item) => {
					if (!$(item).is('select') && !$(item).is(this.inputTypeText)) {
						$(item).on('click.doSpecialStuff', () => {
							that.doSpecialStuff(item);
						});
					}

					else {
						$(item).on('change.doSpecialStuff', () => {
							that.doSpecialStuff(item);
						});
					}
				});
			}

			// force validation by trigger focus
			that.$ctx.find('.js-clone-button--costdeclaration').on('click', (e) => {
				$(e.currentTarget).prevAll('.mm-row-content--left').eq(0).find(this.inputTypeText).trigger('focus');
			});

			// remove clones (done here cause elem might be loaded from cache, not triggering the Utils.form logic
			that.$ctx.find(this.cloneRemove).off('click.removeClone').on('click.removeClone', (e) => {
				setTimeout(() => {
					if (!$(e.currentTarget).prevAll(this.basicUpload).length) {
						$(e.currentTarget).closest('.js-clone').remove();
					}

					else {
						const $uploadWrapper = $(e.currentTarget).prevAll(this.basicUpload);
						$uploadWrapper.find(this.jsClearButton).trigger('click');
						$uploadWrapper.find(jsFileStoredClass).removeClass(jsFileStored);
						$uploadWrapper.find('.a-basic-btn').show();
					}
				}, 400);
			});
		},

		/**
		 * find input-elements and -states
		 *
		 * @param element
		 */
		getInputContext: function (element) {

			const that = this;

			if (that.$triggerCollection.filter($(element)).length) {
				S.Utils.delayed(`vesrs.damagedecl.getInputContextDelay_${$(element).attr('id')}`, 5, () => {
					that.toggleElements(element);
				});
			}
		},

		getFieldContext: function (element) {

			const that = this;

			if (that.$triggerCollection.filter($(element)).length) {
				S.Utils.delayed(`vesrs.damagedecl.getFieldContextDelay${$(element).attr('id')}`, 5, () => {
					that.toggleUploadButtons(element);
				});
			}
		},

		/**
		 * change/show/hide elements if corresponding input changed
		 *
		 * @param element - String to determine calls
		 *
		 */
		// eslint-disable-next-line
		toggleElements: function (element) { //NOSONAR complexity

			const that = this;
			let isCbx = false,
				isChecked = false,
				closeID = false,
				openID = false;
			const $currentTriggerWrapper = $(element).closest(this.divDataJsTriggerContent),
				currentTriggerCtx = $currentTriggerWrapper.attr(this.attrDataJSTriggerContent),
				dataJsTriggerClose = 'data-js-trigger-close',
				dataJsTriggerOpen = 'data-js-trigger-open';

			// check for whole context
			if (typeof currentTriggerCtx !== 'undefined') {

				// check for checkboxes
				if ($(element).is(':checkbox')) {
					isCbx = true;

					if ($(element).is(':checked')) {
						isChecked = true;
						$(element).closest('.mm-tile').addClass(this.isChecked);
					}

					else {
						$(element).closest('.mm-tile').removeClass(this.isChecked);
					}
				}

				// check for select inputs
				if ($(element).is('select')) {

					if (typeof $(element).find(this.optionSelected).attr(dataJsTriggerClose) !== 'undefined') {
						closeID = $(element).find(this.optionSelected).attr(dataJsTriggerClose);
					}

					if (typeof $(element).find(this.optionSelected).attr(dataJsTriggerOpen) !== 'undefined') {
						openID = $(element).find(this.optionSelected).attr(dataJsTriggerOpen);
					}
				}

				else {
					// save open and close ids (mainly for cbx)
					if (typeof $(element).attr(dataJsTriggerClose) !== 'undefined') {
						closeID = $(element).attr(dataJsTriggerClose);
					}

					if (typeof $(element).attr(dataJsTriggerOpen) !== 'undefined') {
						openID = $(element).attr(dataJsTriggerOpen);
					}
				}

				// iterate through each hidden elem
				that.$hiddenContentCollection.each((index, item) => {

					// get each hidden elem with same trigger ctx
					if ($(item).attr(this.dataJsHiddenContext) === currentTriggerCtx) {

						// CBX logic
						if (isCbx) {

							if (isChecked) {

								// show target content wrapper
								$(item).removeClass('is-hidden');

								if (openID) {

									that.$hiddenContentCollection.each((index, subItem) => {

										let dataContentSubItem = $(subItem).attr(this.dataJsHiddenContent);

										if (dataContentSubItem.indexOf(",") > 0) {
											dataContentSubItem = JSON.parse(dataContentSubItem);
										}

										// data attr is an array
										if (typeof dataContentSubItem !== 'string') {

											for (let i = 0; i < dataContentSubItem.length; i++) {

												// get hidden elem with same trigger ctx
												if (dataContentSubItem[i] === openID) {
													$(subItem).removeClass('is-hidden');
												}
											}
										}

										else {
											// get hidden elem with same trigger ctx
											if (dataContentSubItem === openID) {
												$(subItem).removeClass('is-hidden');
											}
										}
									});
								}
							}

							else {

								// look for other checked inputs
								if (!$currentTriggerWrapper.find('input[type=checkbox]:checked').length) {

									// nothing found => hide item
									$(item).addClass('is-hidden');
									that.resetInputs($(item));
									that.hideChildElems($(item));
								}

								if (openID) {

									that.$hiddenContentCollection.each((index, subItem) => {

										let dataContentSubItem = $(subItem).attr(this.dataJsHiddenContent);

										if (dataContentSubItem.indexOf(",") > 0) {
											dataContentSubItem = JSON.parse(dataContentSubItem);
										}

										// data attr is an array
										if (typeof dataContentSubItem !== 'string') {

											let foundItem = false,
												foundSibling = false;
											const siblingTriggerCollection = [];

											$currentTriggerWrapper.find('input[data-js-trigger-open]').each((index, siblingInput) => {
												if ($(siblingInput).prop('checked')) {
													siblingTriggerCollection.push($(siblingInput).attr(dataJsTriggerOpen));
												}
											});

											for (let i = 0; i < dataContentSubItem.length; i++) {

												// get hidden elem with same trigger ctx
												if (dataContentSubItem[i] === openID) {
													foundItem = true;
												}

												$(siblingTriggerCollection).each((index, triggerID) => {
													if (dataContentSubItem[i] === triggerID) {
														foundSibling = true;
													}

												});
											}

											if (foundItem && !foundSibling) {
												$(subItem).addClass('is-hidden');
												that.resetInputs($(subItem));
												that.hideChildElems($(subItem));
											}
										}

										else {
											// get each hidden elem with same trigger ctx
											if (dataContentSubItem === openID) {
												$(subItem).addClass('is-hidden');
												that.resetInputs($(subItem));
												that.hideChildElems($(subItem));
											}
										}
									});
								}
							}
						}

						// if open or close where set
						else if (openID || closeID) {

							let dataContentItem = $(item).attr(this.dataJsHiddenContent);

							if (dataContentItem.indexOf(",") > 0) {
								dataContentItem = JSON.parse(dataContentItem);
							}

							// data attr is an array
							if (typeof dataContentItem !== 'string') {

								let openedItem = false;

								for (let i = 0; i < dataContentItem.length; i++) {

									// get hidden elem with same trigger ctx
									if (dataContentItem[i] === openID) {
										openedItem = true;
										$(item).removeClass('is-hidden');
									}
								}

								// if nothing in array matched, hide item
								if (!openedItem) {
									$(item).addClass('is-hidden');
								}
							}

							else {

								// open each elem with the same openID
								if (dataContentItem === openID) {
									$(item).removeClass('is-hidden');
								}

								// close every other in this trigger ctx
								else {
									$(item).addClass('is-hidden');
									that.resetInputs($(item));
									that.hideChildElems($(item));
								}
							}
						}

						// input has no open or close id, close content opened by another input sibling (mainly rbtns)
						else {
							$(item).addClass('is-hidden');
							that.resetInputs($(item));
							that.hideChildElems($(item));
						}
					}
				});
			}
		},
		// eslint-disable-next-line
		hideChildElems: function ($item) { //NOSONAR complexity

			const that = this;

			// find each "trigger child elem" within currentTriggerContext and hide it
			const dependentChildId = $item.find(this.divDataJsTriggerContent).attr(this.attrDataJSTriggerContent);

			// find each "hidden content child elem" within currentTriggerContext and hide it
			$item.find('> div[data-js-hidden-content]').each((index, child) => {
				$(child).addClass('is-hidden');
				that.resetInputs($(child));
			});

			if (typeof dependentChildId !== 'undefined') {

				// iterate through each hidden elem
				that.$hiddenContentCollection.each((index, elem) => {

					// get each hidden elem with same trigger ctx
					if ($(elem).attr(this.dataJsHiddenContext) === dependentChildId) {

						$(elem).addClass('is-hidden');
						that.resetInputs($(elem));

						// find trigger inputs: reset each and hide dependent content
						const dependentGrandChildTriggerId = $(elem).find(this.divDataJsTriggerContent).attr(this.attrDataJSTriggerContent);

						if (typeof dependentGrandChildTriggerId !== 'undefined') {

							// iterate through each hidden elem
							that.$hiddenContentCollection.each((index, subElem) => {

								// get each hidden elem with same trigger ctx
								if ($(subElem).attr(this.dataJsHiddenContext) === dependentGrandChildTriggerId) {

									$(subElem).addClass('is-hidden');
									that.resetInputs($(subElem));

								}
							});
						}

						// find hidden contents: hide each dependent content
						const dependentGrandChildContentID = $(elem).attr(this.dataJsHiddenContent);

						if (typeof dependentGrandChildContentID !== 'undefined') {

							// iterate through each hidden elem
							that.$hiddenContentCollection.each((index, subElem) => {

								// get each hidden elem with same trigger ctx
								if ($(subElem).attr(this.dataJsHiddenContext) === dependentGrandChildContentID) {

									$(subElem).addClass('is-hidden');
									that.resetInputs($(subElem));

									// find trigger inputs: reset each and hide dependent content
									const dependentGrandGrandChildTriggerId = $(subElem).find(this.divDataJsTriggerContent).attr(this.attrDataJSTriggerContent);

									if (typeof dependentGrandGrandChildTriggerId !== 'undefined') {

										// iterate through each hidden elem
										that.$hiddenContentCollection.each((index, subSubElem) => {

											// get each hidden elem with same trigger ctx
											if ($(subSubElem).attr(this.dataJsHiddenContext) === dependentGrandGrandChildTriggerId) {

												$(subSubElem).addClass('is-hidden');
												that.resetInputs($(subSubElem));

											}
										});
									}

								}
							});
						}
					}
				});
			}
		},

		/**
		 * reset input elems (like uncheck) if trigger was closed again etc.
		 *
		 * @param $elemCollection
		 */
		resetInputs: function ($elemCollection) {

			$elemCollection.find('input[type=radio]').each((index, item) => {
				$(item).prop('checked', false);
			});

			$elemCollection.find('input[type=checkbox]').each((index, item) => {
				$(item).prop('checked', false).closest('.mm-tile').removeClass(this.isChecked);
			});

			$elemCollection.find(this.inputTypeText).each((index, item) => {
				if ($(item).val().length) {
					$(item).val('').trigger('change');

					if ($(item).closest('.mm-row').length) {
						setTimeout(() => {
							$(item).closest('.mm-row').removeClass('is-check is-error is-valid');
						}, 100);
					}
				}
			});

			$elemCollection.find('select').each((index, item) => {
				$(item).find('option').eq(0).prop('selected', true);
			});

			$elemCollection.find(this.basicUpload).each((index, item) => {

				if ($(item).find('input').val().length) {
					$(item).find(this.jsClearButton).trigger('click');
				}

			});

			$elemCollection.find('.m-basic-upload .js-clone-remove').each((index, item) => {
				$(item).trigger('click');
			});

			// remove acc offender clones
			if ($elemCollection.parent(this.jsCloneSourceAccidentOffender).length) {
				$(document.querySelectorAll(this.jsCloneAccidentOffender)).each((index, item) => {
					$(item).find(this.cloneRemove).trigger('click');
				});
			}

			// clear valid classes
			if ($elemCollection.closest('.m-ves-rs-contentbox').hasClass('is-valid')) {
				$elemCollection.closest('.m-ves-rs-contentbox').removeClass('is-valid').addClass('is-invalid');
			}
		},

		/**
		 * collect sizes by listening to upload elem and display them as a summary
		 */
		// eslint-disable-next-line
		calcFileUploadSize: function () { //NOSONAR complexity

			const that = this;
			let currentRawSize = 0,
				currentFormatSize = 0;

			const wrapperElem = this.$ctx.find('.mm-fileSize'),
				sizeElem = this.$ctx.find('.mm-fileSize > .js-fileSizeSum'),
				unitElem = this.$ctx.find('.mm-fileSize > .js-fileSizeSumUnit');


			// prevent calc errors by checking if size was calced previously
			// => while cloning we have to reinit the modules, so we'll lose every information about the past

			if ($(sizeElem).text().length) {
				// caution: size elem is still a number! thats why we dont have to convert it back
				// if necessary, convert it to a string first in on('BasicUpload.addFileSize') function to get rid of the dot
				// otherwise, you'll get a false value after convertToNumber cause that helper will remove the dot
				// as it expects a string!!!
				currentRawSize = $(sizeElem).text();
				currentFormatSize = currentRawSize;

				if ($(unitElem).text() === 'MB') {
					currentRawSize = currentRawSize * 1024;
				}
			}

			// file is added
			that._events.on('BasicUpload.addFileSize', (fileSize) => {

				if (fileSize !== 0 && !isNaN(currentRawSize)) {

					let unit = "KB";

					currentRawSize += fileSize;

					if (currentRawSize > 1023) {
						currentFormatSize = parseFloat(currentRawSize / 1024).toFixed(2);
						unit = "MB";
					}

					else {
						currentFormatSize = Math.round(currentRawSize);
					}

					$(wrapperElem).removeClass('is-hidden');
					$(unitElem).text(unit);
					$(sizeElem).text(currentFormatSize).attr('data-js-rawSize', currentRawSize);

					if ($(unitElem).eq(0).text() === 'MB' && currentFormatSize > 7) {
						$(wrapperElem).addClass('is-error');
					}
					else {
						$(wrapperElem).removeClass('is-error');
					}
				}


			});

			// file is removed
			that._events.on('BasicUpload.reduceFileSize', (fileSize) => {

				let unit = "KB";

				if (fileSize !== 0 && !isNaN(currentRawSize)) {

					currentRawSize -= fileSize;

					if (currentRawSize > 0) {

						if (currentRawSize > 1023) {
							currentFormatSize = parseFloat(currentRawSize / 1024).toFixed(2);
							unit = "MB";
						}

						else {
							currentFormatSize = Math.round(currentRawSize);
						}

						$(wrapperElem).removeClass('is-hidden');
						$(unitElem).text(unit);
						$(sizeElem).text(currentFormatSize);

						if ($(unitElem).eq(0).text() === 'MB' && currentFormatSize > 7) {
							$(wrapperElem).addClass('is-error');
						}
						else {
							$(wrapperElem).removeClass('is-error');
						}
					}

					// remove values if no file is attached
					else {
						$(wrapperElem).addClass('is-hidden');
						$(unitElem).text('');
						$(sizeElem).text('');
					}
				}
			});
		},

		/**
		 * calc costs for cost form
		 */
		// eslint-disable-next-line
		calcCosts: function () { //NOSONAR complexity
			const that = this;

			let newEuroSum = 0,
				chfEuroSum = 0;

			that.$costInputs.each((index, item) => {

				$(item).on('change', (e) => {

					newEuroSum = 0;
					chfEuroSum = 0;

					if ($(e.currentTarget).closest('.mm-upload-container').length) {

						$(e.currentTarget).closest('.mm-upload-container').find(this.inputTextInput).each((index, input) => {

							if ($(input).val().length) {

								const rawVal = $(input).val();

								if ($(input).closest(this.rowContent).find(this.optionSelected).val() === 'chf') {
									chfEuroSum += that.convertToNumber(rawVal);
								}

								else {
									newEuroSum += that.convertToNumber(rawVal);
								}
							}
						});
					}

					else {

						$(e.currentTarget).closest('.mm-tile-container').find(this.inputTextInput).each((index, input) => {

							if ($(input).val().length) {

								const rawVal = $(input).val();

								if ($(input).closest(this.rowContent).find(this.optionSelected).val() === 'chf') {
									chfEuroSum += that.convertToNumber(rawVal);
								}

								else {
									newEuroSum += that.convertToNumber(rawVal);
								}
							}
						});
					}


					if (newEuroSum !== 0 || chfEuroSum !== 0) {

						if (newEuroSum) {
							that.$euroElem.closest(this.rowContent).removeClass('h-none');
							that.$euroElem.text(that.convertToString(newEuroSum));
						}

						else {
							that.$euroElem.closest(this.rowContent).addClass('h-none');
						}

						if (chfEuroSum) {
							that.$chfElem.closest(this.rowContent).removeClass('h-none');
							that.$chfElem.text(that.convertToString(chfEuroSum));
						}

						else {
							that.$chfElem.closest(this.rowContent).addClass('h-none');
						}

						// show row wrapper
						that.$chfElem.closest(this.rowContent).parent().removeClass('is-hidden');

						if (that.$specialContentCollection.filter(this.dataJsSpecialContentHighCost).length) {

							that.toggleHighCostElems('show', newEuroSum + chfEuroSum);
						}

						if (that.$specialContentCollection.filter(this.dataJsSpecialContentLowCost).length) {
							that.toggleLowCostElems('show', newEuroSum + chfEuroSum);
						}
					}

					else {
						// reset classes
						that.$euroElem.closest(this.rowContent).removeClass('h-none');
						that.$chfElem.closest(this.rowContent).addClass('h-none');

						// hide row wrapper
						that.$chfElem.closest(this.rowContent).parent().addClass('is-hidden');

						if (that.$specialContentCollection.filter(this.dataJsSpecialContentHighCost).length) {

							that.toggleHighCostElems('hide', 0);

						}

						if (that.$specialContentCollection.filter(this.dataJsSpecialContentLowCost).length) {

							that.toggleLowCostElems('hide', 0);

						}
					}
				});

			});
		},

		/**
		 * toggle high-cost elems
		 *
		 * @param mod {String} - show || hide
		 *
		 * @param costSum {Number} (optional)
		 */
		toggleHighCostElems(mod, costSum) {
			const highCostTarget = this.$specialContentCollection.filter(this.dataJsSpecialContentHighCost);

			if (mod === 'hide') {
				$(highCostTarget).addClass('is-hidden');
			}

			else {
				$(highCostTarget).each((index, elem) => {

					const costCondition = $(elem).data('js-special-content-condition');

					if (costSum >= costCondition) { //NOSONAR
						$(elem).removeClass('is-hidden');
					}

					else {
						$(elem).addClass('is-hidden');
					}
				});
			}
		},

		/**
		 * toggle low-cost elems
		 *
		 * @param mod {String} - show || hide
		 *
		 * @param costSum {Number} (optional)
		 */
		toggleLowCostElems(mod, costSum) {
			const highCostTarget = this.$specialContentCollection.filter(this.dataJsSpecialContentLowCost);

			if (mod === 'hide') {
				$(highCostTarget).addClass('is-hidden');
			}

			else {
				$(highCostTarget).each((index, elem) => {

					const costCondition = $(elem).data('js-special-content-condition');
					if (costSum <= costCondition) { //NOSONAR
						$(elem).removeClass('is-hidden');
					}

					else {
						$(elem).addClass('is-hidden');
					}
				});
			}
		},

		/**
		 * convert string to (float) number for further calculation
		 *
		 * @param {String} price
		 */
		convertToNumber: function (price) {

			let convertedVal = parseFloat(price
				// remove dots
				.replace(/\./g, '')
				// replace commas with dots
				.replace(/,/g, '.')
				// remove every char excepts numbers and dots
				// eslint-disable-next-line
				.replace(/[^\d\.]/g, '')
			);

			// check if convertedVal is a number (to get rid of booleans) and if it is NaN (to get rid of strings)
			if (typeof convertedVal !== "number" || isNaN(convertedVal)) {
				convertedVal = 0;
			}

			return (convertedVal);
		},

		/**
		 * convert number to string for text injections
		 *
		 * @param {Number} value
		 */
		convertToString: function (value) {

			return (
				// round number on 2nd decimal-char, convert it to string
				(value).toFixed(2))
				// replace dots with commas
				.replace(/\./g, ',');
		},

		/**
		 * enable / disable upload-button if file-uploads were changed
		 *
		 * @param {String} mod - add / remove
		 *
		 * @param {Object} element - file input trigger
		 */
		// eslint-disable-next-line
		toggleUploadButtons: function (mod, element) { //NOSONAR complexity
			const rowContentButtonWrap = '.mm-row-content--button-wrap',
				isDisabled = 'is-disabled',
				jsUploadButton = '.js-upload-button',
				uploadInputNotFileStored = '.m-basic-upload input:not(.js-file-stored)';

			// cost inputs
			if ($(element).closest(this.rowContent).nextAll(rowContentButtonWrap).length) {

				// enable cause mod is 'add'
				if (mod !== 'remove') {

					$(element).closest(this.rowContent).nextAll(rowContentButtonWrap).find(jsUploadButton).removeClass(isDisabled);
				}

				else {

					let disable = true;

					// look for other filled file inputs
					// eslint-disable-next-line
					$(element).closest('.mm-row').find(uploadInputNotFileStored).each((index, inputElem) => { //NOSONAR return

						if (typeof $(inputElem).val() !== 'undefined' && $(inputElem).val() !== '') {
							disable = false;
							return false;
						}
					});

					if (disable) {
						$(element).closest(this.rowContent).nextAll(rowContentButtonWrap).find(jsUploadButton).addClass(isDisabled);
					}

					else {
						$(element).closest(this.rowContent).nextAll(rowContentButtonWrap).find(jsUploadButton).removeClass(isDisabled);
					}
				}
			}

			// police file inputs
			if ($(element).closest('.ll-row').nextAll('.js-clone-button-row').find(jsUploadButton).length) {

				const uploadBtn = $(element).closest('.ll-row').nextAll('.js-clone-button-row').find(jsUploadButton).eq(0);

				// enable cause mod is 'add'
				if (mod !== 'remove') {
					$(uploadBtn).removeClass(isDisabled);
				}

				else {

					let disable = true;
					// eslint-disable-next-line
					$(element).closest('.ll-fieldset').find(uploadInputNotFileStored).each((index, inputElem) => { //NOSONAR return

						if (typeof $(inputElem).val() !== 'undefined' && $(inputElem).val() !== '') {
							disable = false;
							return false;
						}
					});

					// look for other filled file inputs
					if (disable) {
						$(uploadBtn).addClass(isDisabled);
					}

					else {
						$(uploadBtn).removeClass(isDisabled);
					}
				}
			}

			// accident offender inputs
			if ($(element).closest(this.jsCloneSourceAccidentOffender).length || $(element).closest(this.jsCloneAccidentOffender).length) {

				const fileInputWrapper = $(element).closest(this.jsCloneSourceAccidentOffender).length ? $(element).closest(this.jsCloneSourceAccidentOffender).eq(0) : $(element).closest(this.jsCloneAccidentOffender).eq(0);

				const uploadBtn = $(fileInputWrapper).nextAll('div[data-js-hidden-context="acc-offender-info"]').find(jsUploadButton).eq(0);

				// enable cause mod is 'add'
				if (mod !== 'remove') {
					$(uploadBtn).removeClass(isDisabled);
				}

				else {

					let disable = true;

					// eslint-disable-next-line
					$(fileInputWrapper).siblings('.js-clone-source-accidentoffender, .js-clone-accidentoffender').find(uploadInputNotFileStored).each((index, inputElem) => { //NOSONAR return

						if (typeof $(inputElem).val() !== 'undefined' && $(inputElem).val() !== '') {
							disable = false;
							return false;
						}
					});

					if (disable) {
						$(uploadBtn).addClass(isDisabled);
					}

					else {
						$(uploadBtn).removeClass(isDisabled);
					}
				}
			}
		},

		/**
		 * do special things, like disabling or enabling fields across the whole form-app
		 *
		 * or force hide elems even though they are triggered by other elems as well
		 *
		 * @param element
		 *
		 */
		// eslint-disable-next-line
		doSpecialStuff: function (element) { //NOSONAR complexity

			const that = this,
				specialID = $(element).attr('data-js-trigger-special'),
				dataJsSpecialContent = 'data-js-special-content';
			let triggerID,
				mod,
				isChecked = false;

			if (specialID === 'same-insurance') {

				mod = 'custom-insurance';

				if ($(element).is(':checked')) {
					isChecked = true;
				}
			}

			// listen for input change to (re)set additional insurance
			if (specialID === 'same-insurance-src') {

				mod = 'custom-insurance-change';

				that.$triggerSpecialCollection.each((index, elem) => {

					if ($(elem).attr('data-js-trigger-special') === 'same-insurance' && $(elem).is('input:checked')) {
						isChecked = true;
					}

				});
			}

			if (mod === 'custom-insurance-change' && isChecked) {

				if ($(element).attr(dataJsSpecialContent) === 'same-insurance-src1') {

					that.$specialContentCollection.each((index, elem) => {

						if ($(elem).attr(dataJsSpecialContent) === 'same-insurance1') {

							$(elem).val($(element).val());

							if ($(elem).parent().find(this.jsClearButton).length) {
								$(elem).parent().find(this.jsClearButton).trigger('click');
							}
						}
					});
				}

				if ($(element).attr(dataJsSpecialContent) === 'same-insurance-src2') {

					that.$specialContentCollection.each((index, elem) => {

						if ($(elem).attr(dataJsSpecialContent) === 'same-insurance2') {
							$(elem).val($(element).val());

							if ($(elem).parent().find(this.jsClearButton).length) {
								$(elem).parent().find(this.jsClearButton).trigger('click');
							}
						}
					});
				}
			}

			if (mod === 'custom-insurance') {

				let inputString1,
					inputString2;

				that.$specialContentCollection.each((index, elem) => {

					if (isChecked) {

						// get src vals
						if ($(elem).attr(dataJsSpecialContent) === 'same-insurance-src1') {
							inputString1 = $(elem).val();
						}

						if ($(elem).attr(dataJsSpecialContent) === 'same-insurance-src2') {
							inputString2 = $(elem).val();
						}

					}
					// disable inputs
					if ($(elem).attr(dataJsSpecialContent) === 'same-insurance1') {

						if (isChecked) {

							if ($(elem).parent().find(this.jsClearButton).length) {
								$(elem).parent().find(this.jsClearButton).trigger('click');
							}

							$(elem).val(inputString1);
							$(elem).attr('disabled', true);

						}

						else {
							$(elem).attr('disabled', false);

							if ($(elem).val().length) {
								$(elem).trigger('change');
							}
						}
					}

					if ($(elem).attr(dataJsSpecialContent) === 'same-insurance2') {

						if (isChecked) {

							if ($(elem).parent().find(this.jsClearButton).length) {
								$(elem).parent().find(this.jsClearButton).trigger('click');
							}

							$(elem).val(inputString2);
							$(elem).attr('disabled', true);
						}

						else {
							$(elem).attr('disabled', false);

							if ($(elem).val().length) {
								$(elem).trigger('change');
							}
						}
					}
				});
			}

			else {

				that.$specialContentCollection.each((index, elem) => {

					if ($(elem).attr(dataJsSpecialContent) === triggerID) {

						if (mod === 'hide') {
							$(elem).addClass('is-hidden');
						}

						else {
							$(elem).removeClass('is-hidden');
						}
					}
				});
			}

		},

		/**
		 * windowResize - add listeners to window resize event to change markup structure for cost uploads
		 * in mobile / tablet
		 *
		 */
		windowResize: function () {

			$(window).on('resize.damagedeclaration', () => {

				S.Utils.delayed('damagedeclaration.moveCostElems', 40, () => {

					if (S.Globals.MediaQuery.mqRightNow !== 'desktop') {
						this.moveCostElems('mobile');
						// hide btns for cost V2
						this.$costTileContainer.find('.mm-tile > .a-basic-btn.js-clone-button--costdeclaration').addClass('force-hidden');
					}

					else {
						this.moveCostElems('desktop');
						// show (or better reset) btns for cost V2
						this.$costTileContainer.find('.mm-tile > .a-basic-btn.js-clone-button--costdeclaration').removeClass('force-hidden');
					}
				});
			});
		},

		/**
		 * change markup structure for cost elems in mobile / desktop
		 *
		 * @param mod (desktop / mobile) to decide, which markup version has to be build
		 *
		 */
		moveCostElems: function (mod) {

			const that = this,
				jsMobileMode = 'js-mobile-mode';

			if (mod !== 'desktop' && !that.$costTileContainer.hasClass(jsMobileMode)) {

				S.Utils.delayed('damagedeclaration.moveCostElemsDelay', 20, () => {

					// get context info from each tile input to know, which row belongs to which tile
					that.$costTileContainer.addClass(jsMobileMode).find('input[data-js-trigger-open]').each((index, item) => {

						const contentID = $(item).data('js-trigger-open');

						// iterate through each row and find matches with above info
						that.$costUploadContainer.find('.mm-row[data-js-hidden-content]').each((subIndex, rowItem) => {

							if ($(rowItem).data('js-hidden-content') === contentID) {
								// insert row as next sibling to the trigger tile
								$(rowItem).addClass('js-moved').insertAfter($(item).closest('.mm-tile'));
							}
						});
					});

				});
			}

			else if (S.Globals.MediaQuery.mqRightNow === 'desktop' && that.$costTileContainer.hasClass(jsMobileMode)) {

				S.Utils.delayed('damagedeclaration.moveCostElemsDelay', 20, () => {

					const $sumRow = that.$costUploadContainer.find('.mm-row--summary');

					that.$costTileContainer.removeClass(jsMobileMode).find('.mm-row.js-moved').each((index, rowItem) => {
						$(rowItem).removeClass('js-moved');
						$(rowItem).insertBefore($sumRow, null);
					});
				});
			}
		}

	});
}(jQuery));
