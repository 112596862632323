(function ($) {
	'use strict';
	/**
	 * Award decorator implementation for the basickeywordfilter component.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class Lexicon
	 * @extends T.Module
	 */
	T.Module.BasicKeywordfilter.LexiconMyAdac = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		ctxTID: null,

		/** @type {jQuery} */
		$swiper: null,

		/** @type {jQuery} */
		$letterboxsticky: null,

		/** @type {jQuery} */
		$swiperContainer: null,

		/** @type {jQuery} */
		$swiperSlidesWrapper: null,

		/** @type {jQuery} */
		$swiperBtnLeft: null,

		/** @type {jQuery} */
		$swiperBtnRight: null,

		/** @type {Object[]} */
		$swiperSlides: null,

		/** @type {jQuery} */
		$resultWrapper: null,

		/** @type {jQuery} */
		$result: null,

		/** @type {Object[]} */
		$categories: null,

		/** @type {Object[]} */
		$categoryOffsets: [],

		/** @type {Number} */
		$activeLetter: 0,

		/** @type {jQuery} */
		$header: null,

		/** @type {Number} */
		headerHeight: 0,

		/** @type {Number} */
		oldHeaderHeight: 0,

		/** @type {jQuery} */
		transitionEnd: null,

		/** @type {Number} */
		heightToScrollFromTop: 0,

		/** @type {jQuery} */
		landscape: null,

		/** @type {Number} */
		headerState: 1,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.ctxTID = this.$ctx.data('t-id');

			this.$letterboxsticky = this.$ctx.find('.mm-lexicon-letterbox-sticky');
			this.$swiperSlidesWrapper = this.$ctx.find('.swiper-wrapper');
			this.$swiperContainer = this.$ctx.find('.swiper');
			this.$swiperSlides = this.$ctx.find('.swiper-slide');
			this.$categories = this.$ctx.find('.mm-lexicon-category');
			this.$resultWrapper = this.$ctx.find('.mm-lexicon-result-wrap');
			this.$result = this.$resultWrapper.find('> .mm-lexicon-result');
			this.$swiperBtnLeft = this.$ctx.find('.mm-lexicon-prev');
			this.$swiperBtnRight = this.$ctx.find('.mm-lexicon-next');
			this.ariaLabelAttribute = 'aria-label';
			this.swiperBtnLeftAriaLabelText = this.$swiperBtnLeft.attr(this.ariaLabelAttribute);
			this.swiperBtnRightAriaLabelText = this.$swiperBtnRight.attr(this.ariaLabelAttribute);

			this.result = '.mm-result';
			this.noResult = '.mm-noresult';
			this.htmlBody = 'html, body';
			this.isActive = '.is-active';

			// new variables
			this.$header = $('.m-basic-header');
			// set the headerHeight
			this.headerHeight = this.getTheHeaderHeight();

			// initial header height
			this.heightToScrollFromTop = this.$ctx.offset().top - this.headerHeight;

			// for letterScrollbar/swiper
			this.landscape = $(window).width() > $(window).height();

			// get the TransitionEvent for listing
			this.transitionEnd = this.whichTransitionEvent();

			//*** get global event search - BasicKeywordfilter.searchKeyup
			this._events.on('BasicKeywordfilter.searchKeyup', this.highlight.bind(this.searchValue));

			//*** get global event search - BasicKeywordfilter.filterContentBySearchFinished
			this._events.on('BasicKeywordfilter.filterContentBySearchFinished', () => {
				this.filterAfterSearch();
			});

			//*** get global event search BasicKeywordfilter.reset
			this._events.on('BasicKeywordfilter.reset', () => {
				this.reset();
			});

			//*** get global event search - BasicKeywordfilter.checkKeywordContentLinks
			this._events.on('BasicKeywordfilter.checkKeywordContentLinks', () => {
				this.checkKeywordContentLinks();
			});

			// layer is unpinned -> slides up to top
			this._events.on('basicHeader.isStuck', () => {
				this.headerHeight = this.getTheHeaderHeight();
				this.setSticky();
			});

			// layer is pinned -> slides down
			this._events.on('basicHeader.isNotStuck', () => {
				this.headerHeight = this.getTheHeaderHeight();
				this.setSticky();
			});

			// for mobile devices, the site/browser has to load all
			// before javascript can handle and calculate position
			// if not, it will calculate the wrong numbers
			$(window).on('load', () => {
				this.setCategoryOffsets();
			});

			// deactivated above due to jQuery inconsistency: we load all these scripts in a document.ready() call
			// -> if the load event is fast enough (e.g. the page has not much content), the load is done before doc.ready is done
			// -> load() won't fire
			setTimeout(() => {
				this.setCategoryOffsets();
				this.initSwipe();
			}, 1000);

			this._parent.start(resolve);
		},

		/**
		 * get the header height
		 * @return {number} neededHeight
		 */
		getTheHeaderHeight: function () {
			return this.$header.height();
		},

		/**
		 * my own sticky function, gets the header height and set the param´s for the sticky letter swiper
		 *
		 */
		setSticky: function () {
			this._events.emit('stickyDetachEvent.preventHeaderEvent');

			this.$letterboxsticky.trigger('sticky_kit:detach');

			this.$letterboxsticky.stick_in_parent(
				{
					offset_top: this.headerHeight,
					parent: this.$resultWrapper,
					sticky_class: 'is-sticky'
				}
			);
		},

		/**
		 * checkKeywordContentLinks
		 *
		 */
		checkKeywordContentLinks: function () {
			$('.js-keywordfilter-content').off().on('click', (e) => {
				e.preventDefault();

				// global reset
				this._events.emit('BasicKeywordfilter.reset');

				this.$searchfield.val($(e.target).html());

				$(this.htmlBody).animate({
					scrollTop: this.$ctx.offset().top - 30,
				}, 250);

				let anchor = null;

				this.$ctx.find('h3').each((index, item) => {

					if ($(item).html() === $(e.target).html()) {
						anchor = $(item);
					}
				});

				if (anchor) {
					const scrollPos = -(anchor.offset().top - this.$ctx.find('.mm-lexicon-result').offset().top);

					this.$result.animate({
						marginTop: scrollPos,
					}, 1000);
				}

				const activeCategoryIndex = anchor.closest('.mm-lexicon-category').index();

				// set active letter
				this.$swiperSlidesWrapper.find(this.isActive).removeClass('is-active');
				this.$swiperSlidesWrapper.children().eq(activeCategoryIndex).addClass('is-active');
				this.$activeLetter = activeCategoryIndex;
			});
		},

		/**
		 * init letterEvent
		 */
		initLetterEvent: function () {
			const $slidesWithContent = this.$swiperSlides.filter((index) => {
				return !this.$swiperSlides.eq(index).find('.mm-lexicon-letter').hasClass('is-empty');
			});

			//check if touch is usefull
			$slidesWithContent.on('click', (e) => {
				e.preventDefault();

				const $clickedLetter = $(e.currentTarget);

				// reset old active
				$clickedLetter.siblings(this.isActive).removeClass('is-active');

				// set new active
				$clickedLetter.addClass('is-active');

				this.$activeLetter = this.$swiperSlides.index($clickedLetter);

				// get the actual height/offset to scroll to
				this.heightToScrollFromTop = this.$ctx.offset().top - this.headerHeight;

				this.$result.css({ marginTop: `-${this.$categoryOffsets[this.$activeLetter]}px` });
				this.animateToTop();
			});

			this.$result.on(this.transitionEnd, () => {
				this.animateToTop();
				this.$swiper.update();
				this.$swiper.slideTo(this.$activeLetter - 1);
			});
		},

		/**
		 * init initActiveLetter
		 */
		initActiveLetter: function () {
			const $activeSlide = this.$swiperSlides.find('.mm-lexicon-letter:not(.is-empty)').eq(0).closest('.swiper-slide').addClass('is-active').index();

			this.$swiper.slideTo($activeSlide - 1);
		},

		/**
		 * animate to top, after clicking a letter
		 * on mobile a little bit faster
		 */
		animateToTop() {
			this.toggleScrollEvent('disable');

			if (!S.Utils.Helper.mq('tablet').matches) {
				// scroll window
				$(this.htmlBody)
					.stop(false, true)
					.animate({
						scrollTop: this.heightToScrollFromTop,
					}, 400, () => {
						S.Utils.delayed('BasicKeywordFilterLexiconMyAdac.enableScroll', 100, () => {
							this.toggleScrollEvent('enable');
						});
					});
			}
			else {
				// scroll window
				$(this.htmlBody)
					.stop(false, true)
					.animate({
						scrollTop: this.heightToScrollFromTop,
					}, 1000, () => {
						S.Utils.delayed('BasicKeywordFilterLexiconMyAdac.enableScroll', 100, () => {
							this.toggleScrollEvent('enable');
						});
					});
			}

			S.Utils.delayed(`basicKeywordfilter-letter-${this.ctxTID}`, 1000, () => {
				this._events.emit('basicKeywordfilter.letter', this.$ctx);
			});
		},

		/**
		 * Get information from browser, which event is transition
		 * @return {string}
		 */
		whichTransitionEvent: function ()//NOSONAR
		{
			let t;

			const el = document.createElement('fakeelement'),
				transitions = {
					'transition': 'transitionend',
					'OTransition': 'oTransitionEnd',
					'MozTransition': 'transitionend',
					'WebkitTransition': 'webkitTransitionEnd',
				};

			for (t in transitions) {
				if (el.style[t] !== undefined) {
					return transitions[t];
				}
			}
		},

		/**
		 * preventScrollEvent
		 */
		preventScrollEvent: function (ev) {
			ev.preventDefault();
		},

		/**
		 * disableScrolls before scrollTop starts
		 * $(html,body) will be scrolledTop if you chlick an letter
		 *
		 * @param mod {String} - 'enable' || 'disable'
		 */
		toggleScrollEvent(mod) {
			if (mod === 'disable') {
				window.addEventListener('wheel', this.preventScrollEvent, { capture: true, passive: false });
				window.addEventListener('touchmove', this.preventScrollEvent, { capture: true, passive: false });
			}

			else {
				window.removeEventListener('wheel', this.preventScrollEvent, true);
				window.removeEventListener('touchmove', this.preventScrollEvent, true);
			}
		},

		/**
		 * setCategoryOffsets [A][B][C] etc.
		 */
		setCategoryOffsets: function () {
			this.$categories.each((index, item) => {
				if ($(item).is(':visible')) {
					this.$categoryOffsets[index] = $(item).position().top;
				}
			});
		},

		/**
		 * reset
		 */
		reset: function () {
			// reset result pos
			this.$result.css('margin-top', '0px');

			// reset hidden categories + articles
			this.$result.find(':hidden').show().removeClass('is-hidden');

			// reset hidden letters
			this.$swiperSlidesWrapper.find(':hidden').show();

			// reset active letter
			this.$swiperSlidesWrapper.find(this.isActive).removeClass('is-active');
			this.$swiperSlidesWrapper.children().eq(0).addClass('is-active');
			this.$activeLetter = 0;

			// hide search info
			this.$ctx.find(this.result).hide();
			this.$ctx.find(this.noResult).hide();

			// update category offsets
			this.setCategoryOffsets();

			// update orig snapGrid width the actual really offset of the slide
			this.$swiperSlides.each((index, _) => // eslint-disable-line
			{
				this.$swiper.snapGrid[index] = this.$swiper.slides[index].swiperSlideOffset;
			});

			// slide to first letter
			this.$swiper.slideTo(0);

			// update swiper
			this.$swiper.update();
		},

		/**
		 * filter category items
		 * @param {string} searchValue
		 */
		highlight: function (searchValue) // eslint-disable-line
		{
			// reset result pos
			$('.m-basic-keywordfilter .mm-lexicon-result').css('margin-top', '0px');

			// reset hidden categories + articles
			$('.m-basic-keywordfilter .mm-lexicon-result :hidden').show().removeClass('is-hidden');

			// reset hidden letters
			$('.m-basic-keywordfilter').find('.swiper-wrapper :hidden').show();
		},

		/**
		 * filter categories + articles
		 *
		 */
		filterAfterSearch: function () {
			let countHighlightCategory = 0;

			if (this.$ctx.find('.mm-searchbar input').eq(0).val().length > 2) {
				// filter categories + articles
				this.$categories.each((index, category) => {
					const $category = $(category);

					let countHighlightArticle = 0;

					$category.children('.m-basic-newsbox').each((index, item) => {
						if (!$(item).find('.h-search').length) {
							$(item).hide().addClass('is-hidden');
						}
						else {
							countHighlightArticle++;
						}
					});

					if (countHighlightArticle === 0) {
						// hide category
						$category.hide();

						// hide letter in swiper
						this.$swiperSlidesWrapper.children().eq(index).hide();
					}
					else {
						countHighlightCategory++;

						// set active letter
						if (countHighlightCategory === 1) {
							this.$swiperSlidesWrapper.find(this.isActive).removeClass('is-active');
							this.$swiperSlidesWrapper.children().eq(index).addClass('is-active');
							this.$activeLetter = index;
						}
					}
				});

				// set search result info text
				if (countHighlightCategory === 0) {
					this.$ctx.find(this.noResult).show();
					this.$ctx.find(this.result).hide();
				}
				else {
					this.$ctx.find('.js-num').html(this.$ctx.find('.h-search').length);
					this.$ctx.find(this.noResult).hide();
					this.$ctx.find(this.result).show();
				}
			}

			// update swiper
			this.$swiper?.update();

			// update category offsets
			this.setCategoryOffsets();
		},

		/**
		 * init swipe
		 */
		initSwipe: function () {
			// init swiper conatiner
			this.$swiper = new Swiper(this.$swiperContainer[0], {
				spaceBetween: 0,
				slidesPerView: 'auto',
				direction: 'vertical',
				slidesPerGroupAuto: true,
				watchSlidesProgress: true,
				preventInteractionOnTransition: true,
				observer: true,
				preloadImages: false,
				keyboard: {
					enabled: true,
				},
				navigation: {
					prevEl: this.$swiperBtnLeft[0],
					nextEl: this.$swiperBtnRight[0],
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						speed: 300,
					},
					1024: {
						speed: 700,
					}
				},
			});

			// bugfix: set aria-labels here again, because swiper sometimes overwrites it
			this.$swiperBtnLeft.attr(this.ariaLabelAttribute, this.swiperBtnLeftAriaLabelText);
			this.$swiperBtnRight.attr(this.ariaLabelAttribute, this.swiperBtnRightAriaLabelText);

			this.resize();

			// set it to sticky
			this.setSticky();

			// init letter event
			this.initLetterEvent();

			// set active letter
			this.initActiveLetter();
		},

		/**
		 * resize
		 */
		resize: function () {
			let oldWidth = $(window).width();

			// resizing
			window.addEventListener('resize', () => {
				S.Utils.delayed(`resizeFunction-${this.ctxTID}`, 100, () => {
					// if you dont check for this, mobile devices will trigger if you swipe over the end of the site
					if ($(window).width() !== oldWidth) {
						// update all necassery variables
						this.headerHeight = this.getTheHeaderHeight();

						// update category offsets
						this.setCategoryOffsets();

						this.$letterboxsticky.trigger('sticky_kit:recalc');

						// this overwrites it self - same delay name (but thats what we want)
						// - if user has finished scrolling, adjust the new letter position and scroll to it
						S.Utils.delayed(`offset-recalculation-fix-${this.ctxTID}`, 200, () => {
							// on resize the categoryOffset changes and so the window A-Z and its offsets
							// to slide to the active letter, just click on the active slide (this will automatically slide to the correct letter position)
							if (this.$swiperSlidesWrapper.find('.swiper-slide.is-active').length) {
								this.$swiperSlidesWrapper.find('.swiper-slide.is-active').trigger('click');
							}
						});

						oldWidth = $(window).width();
					}
				});
			}, { passive: false });
		},
	});
}(jQuery));
