'use strict';
(function ($) {
	/**
	 * CheckMglNumber module implementation.
	 *
	 * @author  <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class CheckMglNumber
	 * @extends T.Module
	 */
	T.Module.CheckMglNumber = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this.readConfiguration();
			this.addEventListener();
			resolve();
		},

		readConfiguration: function readConfiguration() {
			this.api = this.$ctx.data("api");
			this.targetUrl = this.$ctx.data("targeturl");
		},

		addEventListener: function addEventListener() {
			this.$ctx.find("form").on("submit", this.onSubmit.bind(this));
			this.$ctx.find("#coupon").on("change", this.setIsError.bind(this, false));
		},

		onSubmit: function onSubmit(event) {
			event.preventDefault();
			const coupon = this.$ctx.find("#coupon").val();
			if (coupon.length === 0) {
				this.setIsError(true);
			} else {
				this.submitForm(coupon);
			}
		},

		submitForm: function submitForm(value) {
			const url = T.Utils.Helper.updateUrlParameter({
				MglNo: value
			}, T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.api));

			const options = {
				type: 'GET',
				url: url,
				headers: { 'Ocp-Apim-Subscription-Key': T.Utils.Application.getApiMSubscriptionKey() }
			};
			T.Utils.Ajax.fragment(options, this.successCallback.bind(this), this.errorCallback.bind(this));
		},

		successCallback: function successCallback() {
			T.Utils.Helper.routeToUrl(this.targetUrl);
		},

		errorCallback: function errorCallback() {
			this.setIsError(true);
		},

		setIsError: function setIsError(isInvalid) {
			this.$ctx.find(".js-error").toggleClass("is-error", isInvalid);
		}

	});
}(jQuery));