/**
 * EventTrackingBase implementation
 *
 * @author <marc.radziwill@merkle.com>
 * @class EventTrackingBase
*/

// eslint-disable-next-line no-unused-vars
class EventTrackingBase extends BasisComponent {

	_connectedCallback() {
		this._readConfiguration();
		T.Utils.Tracking.hasAnalyticsConsent(this._handleConsent.bind(this));
	}

	_readConfiguration() {
		this.$ctx = jQuery(this);
		this._businessunit = this.$ctx.data("businessunit") || '';
		this._product = this.$ctx.data("product") || '';
		this._contentID = this.$ctx.data("contentid") || '';
		this._pageTitle = this.$ctx.data("page-title") || document.title || '';
		this._layoutTyp = this.$ctx.data("contenttype") || '';
		this._pageID = this.$ctx.data("pageid") || '';
		this._createdDate = this.$ctx.data("created-date") || '';
		if (this._createdDate) {
			this._createdDate = moment(this._createdDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
		}
		this._updateDate = this.$ctx.data("update-date") || '';
		if (this._updateDate) {
			this._updateDate = moment(this._updateDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
		}
		this._versionCounter = this.$ctx.data("version-counter");
		this._metaRobots = jQuery('meta[name=robots]').attr('content') || "noindex, nofollow";
		this.$main = jQuery('.l-main-content').first();
		if (!this.$main.length) {
			this.$main = jQuery('#l_main_content').first();
		}
	}

	_handleConsent(consentStatus) {
		if (consentStatus) {
			this._checkProductBrandAndProcced();
			this._addEventListeners();
			this._bindTrackingEvents(jQuery(document.body));
			this._pushTeaserPackage();
			this._registerObserver(this._initMutations.bind(this));
		}
	}

	_addEventListeners() {
		jQuery(window).on("scroll", this._pushTeaserPackage.bind(this));
	}

	_pushTeaserPackage() {
		const teaserList = [];
		const $teasers = jQuery('[data-teaser-tracking-container] [data-teaser-tracking]').not('[data-gtm-tracked]');
		$teasers.each((index, teaser) => {
			if (this._isTeaserVisible(teaser)) {
				const $teaser = jQuery(teaser);
				$teaser.attr("data-gtm-tracked", true);
				const eventData = this._getTeaserInformations(teaser, index);
				if (eventData) {
					teaserList.push(eventData);
					$teaser.on('click', () => {
						T.Utils.Tracking.pushData({ teaser: eventData, event: "teaser_click" });
					});
				}
			}
		});

		if (teaserList.length) {
			T.Utils.Tracking.pushData({ teaser: teaserList, event: "teaser_impression" });
		}
	}

	_getTeaserInformations(teaser, index) {
		const $teaser = jQuery(teaser);
		const teaserConfig = $teaser.data("teaser-tracking");
		if (teaserConfig) {
			const headline = $teaser.find('.js-teaser-headline').text() || "";
			return {
				teaserId: teaserConfig.id,
				teaserName: `${teaserConfig.componentType} ${this._pageTitle}`,
				teaserPosition: index + 1,
				teaserCreative: `${teaserConfig.type === "Product Teaser" ? "(Ad) " : ""}${headline}`,
				teaserProduct: teaserConfig.teaserProduct,
				teaserProductCategory: teaserConfig.teaserProductCategory
			};
		}
		return undefined;
	}

	_pushPagePackage() {
		const metaDescription = jQuery('meta[name=description]').attr('content') || '';
		const breadCrumbs = this._getBreadcrumb();
		const eventData = {
			event: "page_load",
			page: {
				domain: document.location.hostname,
				organization: `${this._businessunit} | ${this._product}`,
				breadCrumbs: breadCrumbs.length || '',
				pageID: this._pageID,
				CMS: "sitecore",
				layoutType: this._layoutTyp,
				contentType: this._getContentType(),
				focusKeyword: "",
				department: this._businessunit,
				publicationDate: this._createdDate,
				lastUpdate: this._updateDate,
				isNews: this._checkIfNews(),
				versionCounter: this._versionCounter,
				tags: this._contentID,
				teaserHeadline: this._getTeaserHeadlines(),
				title: this._pageTitle,
				title_length: this._pageTitle.length,
				description: metaDescription,
				description_length: metaDescription.length,
				structured_data: this.$main.find('img').length,
				count_ads: "",
				count_teasers: this._getTeaserCount(),
				count_links: this._getLinksCount(),
				count_words: this._getCountWords(),
				robots: this._metaRobots,
				brand: this.productBrand,
				hreflang: '',
				canonicalUrl: ''
			}
		};

		const $canonical = jQuery("link[rel='canonical']");
		if ($canonical.length) {
			eventData.page.hreflang = $canonical.attr("hreflang") || '';
			eventData.page.canonicalUrl = $canonical.attr("href") || '';
		}
		T.Utils.Tracking.pushData(eventData);
	}

	_checkProductBrandAndProcced() {
		const $brandElm = jQuery('[data-productbrand]');
		if ($brandElm.length) {
			this.productBrand = $brandElm.first().data('productbrand') || '';
			this._pushPagePackage();
		}
	}

	_registerObserver(callback) {
		const config = { attributes: false, childList: true, subtree: true };
		try {
			const observer = new MutationObserver(callback);
			observer.observe(document.body, config);
		} catch (err) {
			T.Utils.Logger("Failed to init MutationObserver", err);
		}
	}

	_initMutations(mutationsList) {
		mutationsList.forEach(mutation => {
			if (mutation.addedNodes) {
				this._bindTrackingEvents(jQuery(mutation.addedNodes).parent());
			}
		});
	}


	_isTeaserVisible(element) {
		const $element = jQuery(element);
		return $element.is(':visible') || T.Utils.View.isInViewport($element);
	}

	_getTeaserHeadlines() {
		return Array.prototype.reduce.call(this.$main.find('[data-teaser-tracking] .js-teaser-headline'), (accumulator, currentValue) => {
			const t = T.Utils.Format.normalizeSpace(currentValue.textContent);
			if (t) {
				return accumulator ? `${accumulator} | ${t}` : t;
			}
			return accumulator;
		}, '');
	}

	_getTeaserCount() {
		if (this.$main.length) {
			return this.$main.find('[data-teaser-tracking="true"]').length;
		}
		return 0;
	}

	_checkIfNews() {
		return this.$main.find('[data-t-name="BasicNewsbox"]').length ? "isNews" : "isNotNews";
	}

	_getContentType() {
		return jQuery('meta[property="og:title"]').attr('content') === 'Antrag' ? 'Antrag' : 'Artikel';
	}

	_getLinksCount() {
		return this.$main.length ? this.$main.find('a[href][href!="/"]').not('[href^="#"]').length : 0;
	}

	_getBreadcrumb() {
		const listItems = jQuery('div.m-basic-breadcrumb ul.mm-baselevel > li > a');
		return Array.prototype.map.call(listItems, function (element) {
			return element.title;
		});
	}

	_getCountWords() {
		let countWords = '';
		if (this.$main.length) {
			const words = this.$main.text().match(/[\p{Letter}\p{Mark}\p{Number}]+/gu);
			if (words) {
				countWords = words.length;
			}
		}
		return countWords;
	}
}

if (typeof module !== "undefined" && module.exports) {
	module.exports = EventTrackingBase;
}