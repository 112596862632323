"use strict";
/* eslint-disable sonarjs/cognitive-complexity */
(function () {


	T.Utils = T.Utils || {};
	T.Utils.Adaptive = {
		isFast: true,
		saveData: false,
		type: '',
		init: function init() {
			this.connection = navigator.connection ||
				navigator.mozConnection ||
				navigator.webkitConnection;

			this.checkIfFastOrSlow();
			if (this.connection) {
				this.type = this.connection.effectiveType;
				this.connection.addEventListener("change", this.updateConnectionStatus.bind(this));

				if (this.connection.saveData === true) {
					this.saveData = true;
				}
			}
		},

		checkIfFastOrSlow: function checkIfFastOrSlow() {
			if (this.connection && (
				this.connection.effectiveType === "slow-2g"
				|| this.connection.effectiveType === "2g"
				|| this.connection && this.connection.effectiveType === "3g"
			)) {
				this.isFast = false;
			}
		},

		updateConnectionStatus: function updateConnectionStatus() {
			if (this.connection) {
				this.type = this.connection.effectiveType;
			}
			this.checkIfFastOrSlow();

		},
		isFastNetwork: function isFastNetwork() {
			return this.isFast;
		},

		isSlowNetwork: function isSlowNetwork() {
			return !this.isFast;
		},

		isSaveData: function isSaveData() {
			return this.saveData;
		},

		listenToUpdateConnectionStatus: function listenToUpdateConnectionStatus(callback) {
			if (this.connection) {
				this.connection.addEventListener('change', callback);
			}
		}
	};
	T.Utils.Adaptive.init();
})();