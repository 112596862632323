(function ( $ )
{
    'use strict';
    /**
     * BasicLegend module implementation.
     *
     * @author Lars Meyer <l.meyer@mgo360.de>
     * @namespace T.Module
     * @class BasicLegend
     * @extends T.Module
     */
    T.Module.BasicLegend = T.createModule( {

        /** @type {jQuery} */
        $ctx: null,

		/** @type {jQuery} */
		$header: null,

		/** @type {Number} */
		stickyThreshold: -1,

		start: function ( resolve )
        {
            this.$ctx = $( this._ctx );

            this.$header = $('body > .l-outer > .m-basic-header').eq(0);

            // add helper class to content wrapper
            this.$ctx.closest('.l-main-content').addClass('js-legend-open');

            this.addEventListeners();
            this.setSticky('init');

            resolve();
        },

        /**
         * add event listeners
         */
        addEventListeners ()
        {
            // close legend
            this.$ctx.find('.js-close').on('click', () =>
            {
				this.closeLegend();
            });

            // unnecessary cause the header events fire onScroll too
			// if this has to be readded, take care of passive listeners!
            // $( document ).off('scroll.basicLegend_' + this.$ctx.data('t-id')).on('scroll.basicLegend_' + this.$ctx.data('t-id'), () => {
            //     S.Utils.delayed('basicLegend.scrollDelayBuffer_' + this.$ctx.data('t-id'), 40 , () => {
			// 		this.setSticky('reinit');
            //     });
            // });

			// listen to header events and recalc stickyness
			this._events.on('basicHeader.isStuck', () => {
				this.setSticky('reinit');
			});

			this._events.on('basicHeader.isNotStuck', () => {
				this.setSticky('reinit');
			});

			this._events.on('basicHeader.reducedHeightChanged', () => {
				this.setSticky('reinit');
			});
        },

		/**
		 * closes legend and emits event
		 */
		closeLegend ()
		{
			this.$ctx.closest('.l-main-content').removeClass('js-legend-open');
			this.$ctx.slideUp( () => {
				this._events.emit('BasicLegend.close');
			});
		},

		/**
         * sets legend sticky
         *
         *
         * @param mod {String} - 'init' || 'reinit'
         */
        setSticky (mod)
		{
			const lastThresh = this.stickyThreshold;

			// header is sticky
			if ( this.$header.hasClass('is-stuck') ) {
				this.stickyThreshold = this.$header.outerHeight();
			}

			// not stuck
			else {

				this.stickyThreshold = 0;

				// header is always visible in desktop-l, even if it doesnt stick
				if  ( S.Utils.Helper.mq( 'desktop-l' ).matches ) {
					this.stickyThreshold = this.$header.outerHeight();
				}
			}

			this.stickyThreshold = Math.round(this.stickyThreshold);

			// only detach if stickyThreshold has changed
			if (mod !== 'init' && lastThresh !== this.stickyThreshold ) {

				this._events.emit('stickyDetachEvent.preventHeaderEvent');

				this.$ctx.trigger('sticky_kit:detach');
			}

			this.$ctx.stick_in_parent({
				sticky_class: 'is-stuck',
				offset_top: this.stickyThreshold,
			});
        }

    } );
}( jQuery ));
