(function ($) {
	class GalleryComponent extends BasisComponent {

		constructor() {
			super();
			this.$ctx = null;
			this.$swiper = null;
			this.$swiperObject = null;
			this.$thumbnailSwiper = null;
			this.$thumbnailSwiperObject = null;
			this.basicMovie = null;
			this.cloudinaryContainer = null;
			this.youtubeContainer = null;
			this.has3dVideo = null;
			this.swiperNoSwiping = null;
			this.$captionBox = null;
			this.$swiperSlideClass = null;
			this.$movieContainerClass = null;
			this.$isEnlargedClass = null;
		}

		_connectedCallback() {
			//init delayed due to chrome not having swiperplugin ready immediatly
			setTimeout(() => {
				this.init();
			}, 100);

		}

		init() {
			this.$ctx = $(this);
			this.$thumbnailSwiper = this.$ctx.find('.js-thumbnail-swiper');
			this.$swiper = this.$ctx.find('.js-swiper');
			this.basicMovie = '.m-basic-movie';
			this.cloudinaryContainer = '.mm-cloudinary-container';
			this.youtubeContainer = '.mm-youtube-container';
			this.has3dVideo = 'has-immersive-3d-video';
			this.swiperNoSwiping = 'swiper-no-swiping';
			this.$captionBox = this.$ctx.find('.js-caption-box').length ? this.$ctx.find('.js-caption-box') : null;
			this.$swiperSlideClass = '.swiper-slide';
			this.$movieContainerClass = '.mm-movie-container';
			this.$isEnlargedClass = 'is-enlarged';
			this.tabFocusClass = 'is-keyboard-focus';
			this.helperTabfocusClass = 'js-helper-tabfocus-to-close';
			this.tabSlideCounter = 0;
			this.tabSlideCounterActive = true;
			this.tabElementsSelector = '[tabindex], a, button';
			this.imgSelector = '.js-img';

			// init slider
			this.initSlider();
		}

		//custom functions start here!
		initSlider() {
			const thumbnail = new Swiper(this.$thumbnailSwiper[0], {
				spaceBetween: 10,
				slidesPerView: 'auto',
				slidesPerGroupAuto: true,
				keyboard: {
					enabled: true,
				},
				a11y: false,
				freeMode: true,
				watchSlidesProgress: true,
				navigation: {
					nextEl: this.$thumbnailSwiper.find('.swiper-button-next')[0],
					prevEl: this.$thumbnailSwiper.find('.swiper-button-prev')[0],
				},
				on: {
					init: (swiper) => {
						this.$thumbnailSwiperObject = swiper;
						this.initPosters(swiper);
						this.initMovies(S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper));
					},
					transitionEnd: (swiper) => {
						this.initPosters(swiper);
						this.initMovies(S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper));
					}
				}
			});

			//eslint-disable-next-line
			const swiper = new Swiper(this.$swiper[0], { //NOSONAR
				slidesPerView: 'auto',
				watchSlidesProgress: true,
				slideToClickedSlide: true,
				observer: true,
				keyboard: {
					enabled: true,
				},
				a11y: false,
				navigation: {
					nextEl: this.$swiper.find('.swiper-button-next')[0],
					prevEl: this.$swiper.find('.swiper-button-prev')[0],
				},
				thumbs: {
					swiper: thumbnail
				},
				breakpoints: {
					// when window width is >= 320px
					320: {
						speed: 300,
						spaceBetween: 5,
						preventInteractionOnTransition: false,
					},
					1024: {
						speed: 600,
						spaceBetween: 10,
						preventInteractionOnTransition: true,
					}
				},
				on: {
					init: (swiper) => {
						this.$swiperObject = swiper;

						this.initMovies(S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper));
						this.initPosters(swiper);
						this.updateSlideInfo(swiper);

						this.$swiper.find(this.$swiperSlideClass).each((index, element) => {
							$(element).data('initialwidth', $(element).width());

							if (parseInt($(element).width()) > 0) {
								$(element).css('width', `${$(element).width()}px`);
							}
						});
					},
					/**
					 * custom event from basicmovie.js
					 * (for now only for 3d movies)
					 *
					 * @param swiper
					 * @param $container
					 * @param type
					 */
					//eslint-disable-next-line
					movieIsPlaying: (swiper, { $container, type }) => {
						// disable simulateTouch if 3d movie
						if (type === '3d') {
							this.set3DSlideMovieSettings(swiper);
						}
					},
					resize: () => {
						this.pauseMovies();
					},
					slideChangeTransitionEnd: (swiper) => {
						this.updateSlideInfo(swiper);
						this.initMovies(S.Utils.SwiperHelper.filterVisbSlidesFromIndexes(swiper));

						// pause movies
						this.pauseMovies();

						if (this.$captionBox !== null) {
							this.setCurrentSubtitle(swiper, swiper.activeIndex);
						}
					},
				}
			});

			this.initSlideEvents();

			this.initTabFocus();
		}

		initSlideEvents() {
			this.$swiper.find(this.$swiperSlideClass).on('keyup', (el) => {
				if (el.which === 13) {
					$(el.currentTarget).trigger('click');
				}
			});

			this.$thumbnailSwiper.find(this.$swiperSlideClass).on('click', (el) => {
				if (!this.$ctx.hasClass(this.$isEnlargedClass)) {
					this.$swiperObject.slideTo($(el.currentTarget).index());
					this.enlargeComponent();
				}
			});

			this.$swiper.find(this.imgSelector).on('keyup', (el) => {
				if (el.which === 13) {
					$(el.currentTarget).trigger('click');
				}
			});

			this.$swiper.find(this.imgSelector).on('click', () => {
				if (!this.$ctx.hasClass(this.$isEnlargedClass)) {
					this.enlargeComponent();
				}
			});
		}

		initTabFocus() {
			this.$swiper.find(this.tabElementsSelector).on('focus', (el) => {
				if ($('body').eq(0).hasClass(this.tabFocusClass)) {
					// pause movies
					this.pauseMovies();

					const slideIndex = $(el.currentTarget).closest(this.$swiperSlideClass).index();
					$(el.currentTarget).closest(this.$swiperSlideClass).focus();

					// update tab slide counter
					if (this.tabSlideCounterActive === true) {
						this.tabSlideCounter = slideIndex;
					}

					// just outside layer
					if (!this.$ctx.hasClass(this.$isEnlargedClass)) {
						const activeSlide = this.$swiper.find(this.$swiperSlideClass).eq(slideIndex);
						if (!activeSlide.hasClass('swiper-slide-fully-visible')) {
							this.$swiperObject.slideTo(slideIndex);
						}
					}

					// thumbnail
					this.$thumbnailSwiper.find('.swiper-slide-thumb-active').removeClass('swiper-slide-thumb-active');
					const activeThumbSlide = this.$thumbnailSwiper.find(this.$swiperSlideClass).eq(slideIndex);
					activeThumbSlide.addClass('swiper-slide-thumb-active');

					if (!activeThumbSlide.hasClass('swiper-slide-fully-visible')) {
						this.$thumbnailSwiperObject.slideTo(slideIndex);
					}

					// update slide counter
					this.$ctx.find('.js-slide-count-current').text(slideIndex + 1);
				}
			});

			this.$thumbnailSwiper.find(this.tabElementsSelector).on('focus', () => {
				if ($('body').eq(0).hasClass(this.tabFocusClass) && this.$ctx.hasClass(this.$isEnlargedClass)) {
					// just inside layer
					this.$ctx.find(`.${this.helperTabfocusClass}`).focus();
				}
			});
		}

		enlargeComponent() {
			const jsCloseEnlargeSelector = '.js-close-enlarge';
			const isInvisibleClass = 'is-invisible';

			if ($('body').eq(0).hasClass(this.tabFocusClass)) {
				this.tabSlideCounterActive = false;
				this.$swiper.find(this.$swiperSlideClass).eq(0).find(this.tabElementsSelector).eq(0).focus();
				this.tabSlideCounterActive = true;
			}

			this.$ctx.addClass('is-enlarged is-invisible');

			if (!S.Utils.Helper.mq('mobile').matches) {
				this.$swiperObject.params.slidesPerView = 1;
			}

			setTimeout(() => {
				// if in layer and tab controling
				if ($('body').eq(0).hasClass(this.tabFocusClass)) {
					this.initMovies(this.$swiper.find(this.$swiperSlideClass));
				}

				this.$swiperObject.update();
				if ($('body').eq(0).hasClass(this.tabFocusClass) && this.tabSlideCounter > 0) {
					this.$swiper.find(this.$swiperSlideClass).eq(this.tabSlideCounter).find(this.tabElementsSelector).eq(0).focus();
				}
				this.$ctx.removeClass(isInvisibleClass);
			}, 1000);

			this.$ctx.off().on('keyup', (el) => {
				//close enlarged with escape key
				if (el.which === 27) {
					this.$ctx.find(jsCloseEnlargeSelector).trigger('click');
				}
			});

			// add focus helper container after slider
			const $closeTriggerHelper = $(`<div tabindex="0" class="${this.helperTabfocusClass}"></div>`);
			this.$ctx.append($closeTriggerHelper);
			$closeTriggerHelper.on('focus', () => {
				this.$ctx.find(jsCloseEnlargeSelector).focus();
			});

			this.$ctx.find(jsCloseEnlargeSelector).off().on('click', () => {
				this.$swiper.find('.swiper-wrapper').eq(0).attr('style', '');

				this.$ctx.find(`.${this.helperTabfocusClass}`).remove();
				this.$ctx.removeClass(this.$isEnlargedClass);
				this.$ctx.addClass(isInvisibleClass);
				if (!S.Utils.Helper.mq('mobile').matches) {
					this.$swiperObject.params.slidesPerView = 'auto';
				}
				this.$swiper.find(this.$swiperSlideClass).each((index, element) => {
					if (parseInt($(element).data('initialwidth')) > 0) {
						$(element).css('width', `${$(element).data('initialwidth')}px`);
					}
					else {
						$(element).css('width', 'auto');
					}
				});

				this.$swiperObject.update();

				setTimeout(() => {
					this.$ctx.removeClass(isInvisibleClass);
					this.$swiper.find(this.$swiperSlideClass).eq(0).find(this.tabElementsSelector).eq(0).focus();
					this.$swiperObject.update();
				}, 200);
			});

			// soft layer
			this.$ctx.find('.js-soft-layer').off().on('click', () => {
				this.$ctx.find(jsCloseEnlargeSelector).trigger('click');
			});
		}

		updateSlideInfo(swiper) {
			this.$ctx.find('.js-slide-count-current').text(swiper.activeIndex + 1);
			this.$ctx.find('.js-slide-count').text(swiper.slides.length);
		}

		initPosters(swiper) {
			const $slides = $(swiper.el).find(this.$swiperSlideClass);

			for (const slide of $slides) {
				const $slide = $(slide);
				const movieWasPlayed = $slide.find('.is-init').length;
				const $movieCont = $slide.find(this.$movieContainerClass);
				const $YTCont = $slide.find(this.youtubeContainer);
				const $cloudCont = $slide.find(this.cloudinaryContainer);

				if ($movieCont.length && !movieWasPlayed) {
					const classToAdd = $movieCont.data('mode') === '3d' ? this.has3dVideo : 'has-immersive-video';
					$slide.addClass(classToAdd);
				}

				if ($YTCont.length && !movieWasPlayed) {
					$slide.addClass('has-youtube-video');
				}

				if ($cloudCont.length) {
					$slide.addClass('has-cloudinary-video');
				}
			}
		}

		/**
		 * init Movies
		 */
		initMovies($visibleSlides) {
			for (const slide of $visibleSlides) {
				const $slide = $(slide);
				const movieWasPlayed = $slide.find('.is-init').length;
				const $movieCont = $slide.find(this.$movieContainerClass);
				const $YTCont = $slide.find(this.youtubeContainer);
				const $cloudCont = $slide.find(this.cloudinaryContainer);

				if ($movieCont.length && !movieWasPlayed) {
					this.initMovieImmersive($slide);
				}

				if ($YTCont.length && !movieWasPlayed) {
					this.initMovieYoutube($slide);
				}

				if ($cloudCont.length) {
					this.initMovieCloudinary($slide);
				}
			}
		}

		initMovieImmersive(el) {
			const $el = el instanceof jQuery ? el : $(el),
				$movieContainer = $el.hasClass('mm-movie-container') ? $el : $el.find(this.$movieContainerClass);

			for (const movie of $movieContainer) {
				const $movie = $(movie),
					basicMovieInstance = S.Utils.Helper.getModuleInstance($movie.closest(this.basicMovie));

				// just for safety
				if (typeof basicMovieInstance.initMovie === 'function') {
					basicMovieInstance.initMovie($movie);
				}
			}
		}

		initMovieCloudinary(el) {
			const $el = el instanceof jQuery ? el : $(el),
				$cloudinaryContainer = $el.hasClass('mm-cloudinary-container') ? $el : $el.find(this.cloudinaryContainer);

			for (const movie of $cloudinaryContainer) {
				const $movie = $(movie),
					basicMovieInstance = S.Utils.Helper.getModuleInstance($movie.closest(this.basicMovie));

				// just for safety
				if (typeof basicMovieInstance.initCloudinary === 'function') {
					basicMovieInstance.initCloudinary($movie);
				}
			}
		}

		initMovieYoutube(el) {
			const $el = el instanceof jQuery ? el : $(el),
				$youtubeContainer = $el.hasClass('mm-youtube-container') ? $el : $el.find(this.youtubeContainer);

			for (const movie of $youtubeContainer) {
				const $movie = $(movie),
					basicMovieInstance = S.Utils.Helper.getModuleInstance($movie.closest(this.basicMovie));

				// just for safety
				if (typeof basicMovieInstance.startVideo === 'function') {
					basicMovieInstance.startVideo(basicMovieInstance.$ctx.data('src'));
				}
			}
		}

		/**
		 * pausing movies
		 */

		pauseMovies() {
			const $slides = document.body;

			// pause videos in all slides
			this.pauseImmersiveVideo($slides);
			this.pauseCloudinaryVideo($slides);
			this.pauseYoutubeVideos($slides);
		}

		pauseYoutubeVideos(element) {
			const $el = element instanceof jQuery ? element : $(element),
				$YTCont = $el.find(this.youtubeContainer),
				// User clicked on video for the first time - was-started
				// On slide(next/prev) videos get paused and poster is shown - is-paused
				$YTvideo = $YTCont.find('iframe.was-started:not(.is-paused)');

			for (const iframe of $YTvideo) {
				S.Utils.delayed(`pause-youtube-movie-${this.$ctxTID}-${this.index}`, 100, () => {
					const $iframe = $(iframe),
						$basicMovieEl = $iframe.closest(this.basicMovie),
						basicMovieTID = $basicMovieEl.attr('data-t-id'),
						basicMovieInstance = S.Utils.Helper.getModuleInstance(parseInt(basicMovieTID, 10)),
						src = iframe.getAttribute('src').split('?autoplay=1')[0];

					this.index += 1;

					basicMovieInstance.resetVideoSrc(false, src);
				});
			}
		}


		pauseImmersiveVideo(element = this.$ctx) {
			const $el = element instanceof jQuery ? element : $(element);

			$el.find('.mm-movie-container.is-playing').each((_, player) => {
				$(player).immersive('pause');
			});
		}

		pauseCloudinaryVideo(element) {
			const $el = element instanceof jQuery ? element : $(element),
				$cloudinaryPlayers = $el.find('.mm-cloudinary-player');

			/**
			 * if there are cloudinary players, pause them via browser video-api
			 */
			for (const player of $cloudinaryPlayers) {
				const cloudPlayer = player.getElementsByTagName('video');

				for (const video of cloudPlayer) {
					if (!video.paused) {
						video.pause();
					}
				}
			}
		}

		/**
		 * 3D Movie Settings
		 */
		set3DSlideMovieSettings(swiper) {
			// get active slide
			const slide = swiper.slides[swiper.activeIndex];

			// disable simulateTouch if 3d movie
			if (S.Utils.Helper.mq('desktop').matches) {
				this.add3DSlideMovieSettings(false, slide);

			}
			else {
				this.remove3DSlideMovieSettings(false, slide);
			}
		}

		add3DSlideMovieSettings(swiper, specificSlide) {
			const movies3D = specificSlide ? [specificSlide] : swiper.slides.filter((slide) => {
				return slide.classList.contains(this.has3dVideo);
			});

			for (const slide of movies3D) {
				slide?.classList.add(this.swiperNoSwiping);
				slide.style.cursor = 'pointer';
			}
		}

		remove3DSlideMovieSettings(swiper, specificSlide) {
			const movies3D = specificSlide ? [specificSlide] : swiper.slides.filter((slide) => {
				return slide.classList.contains(this.has3dVideo);
			});

			for (const slide of movies3D) {
				slide?.classList.remove(this.swiperNoSwiping);
				slide.style.cursor = 'initial';
			}
		}


		setCurrentSubtitle(el, slideIndex) {
			// in case el is null or undefined - get out (for example, if no slides are maintained)
			if (!el) {
				return;
			}

			// u can set a
			const context = el instanceof jQuery ? el.get(0) : el,
				$neededSlide = context instanceof Element ? $(context) : $(context.slides[slideIndex]);

			let subtitleText = '&nbsp;';

			// from img
			if ($neededSlide.find(this.imgSelector).length) {
				const $img = $neededSlide.find(this.imgSelector);

				subtitleText = $img.attr('title') || subtitleText;
			}
			// from ew72 movie
			else if ($neededSlide.find(this.$movieContainerClass).length) {
				const $movieContainer = $neededSlide.find(this.$movieContainerClass);

				subtitleText = $movieContainer.data('title') || subtitleText;
			}
			// from youtube
			else if ($neededSlide.find(this.youtubeContainer).length) {
				const $YTContainer = $neededSlide.find(this.youtubeContainer);

				subtitleText = $YTContainer.data('title') || subtitleText;
			}

			if (this.$captionBox !== null) {
				this.$captionBox.html(subtitleText);
			}
		}
	}

	customElements.define('gallery-component', GalleryComponent);

}(jQuery));
