/* eslint-disable eqeqeq */
(function ($) {
	'use strict';

	S.Calculator = S.Calculator || {};

	S.Calculator.Aks = {

		/** @type {jQuery} */
		$form: null,

		/** @type {jQuery} */
		$radioAks: null,

		/** @type {jQuery} */
		$radioExclusive: null,

		/** @type {jQuery} */
		$radioPremium: null,

		/** @type {jQuery} */
		$appendContent: null,

		/** @type {String} */
		radioGetRateChecked: '',

		/** @type {String} */
		radioTypeChecked: '',

		/** @type {String} */
		membershipChecked: '',

		/** @type {String} */
		membership: '',

		/** @type {String} */
		type: 'AKS',

		/** @type {Number} */
		age: 18,

		/** @type {String} */
		singleRate: 'true',

		/** @type {Date} */
		currentDate: new Date(),

		/** @type {Object} */
		urlvars: [],

		/** @type {Boolean} */
		loggedin: false,

		/** @type {String} */
		produktKey: '',

		/** @type {String} */
		$calcAks: $('#calculator_aks'),


		/**
		 * init form actions
		 *
		 */
		init: function () {
			this.$form = this.$calcAks.eq(0);
			this.$radioAks = this.$form.find('#radio_type_AKS').eq(0);
			this.$radioExclusive = this.$form.find('#radio_type_EXKLUSIV').eq(0);
			this.$radioPremium = this.$form.find('#radio_type_PREMIUM').eq(0);
			this.$appendContent = $('.m-basic-toggle .js-content');

			this.radioGetRateChecked = 'input[name="radio_get_rate"]:checked';
			this.radioTypeChecked = 'input[name="radio_type"]:checked';
			this.membershipChecked = 'input[name="membership"]:checked';

			// get url
			let hash = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			const hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

			for (let i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				this.urlvars.push(hash[0]);
				this.urlvars[hash[0]] = hash[1];
			}

			// ####### begin: Dummy ######
			this.$appendContent.append(`<br>Eingeloggt: ${this.loggedin}`);

			// simulate login
			if (typeof this.urlvars['loggedin'] != 'undefined') { //NOSONAR
				this.loggedin = true;
			}
			// simulate ADACMitglied
			if (typeof this.urlvars['ADACMitglied'] != 'undefined') { //NOSONAR
				this.membership = 'ADACMitglied';
			}
			// simulate ADACPlusMitglied
			if (typeof this.urlvars['ADACPlusMitglied'] != 'undefined') { //NOSONAR
				this.membership = 'ADACPlusMitglied';
			}
			// simulate EXKLUSIV
			if (typeof this.urlvars['EXKLUSIV'] != 'undefined') { //NOSONAR
				this.type = 'EXKLUSIV';
			}
			// simulate PREMIUM
			if (typeof this.urlvars['PREMIUM'] != 'undefined') { //NOSONAR
				this.type = 'PREMIUM';
			}
			// ####### end: Dummy ######



			// check date of birth
			this.checkDateOfBirth();

			// check date of beginning
			// this.checkDateOfBeginning();

			// check rate :: single || family
			this.checkRate();

			// check membership :: Kunde || ADACMitglied || ADACPlusMitglied
			this.checkMembership();

			// check type :: AKS || EXKLUSIV || PREMIUM
			this.checkType();
		},


		/**
		 * check date of birth
		 *
		 * @param {jQuery} elem
		 * @param {Boolean} valid
		 */
		setChecked: function (elem, valid) {
			if (valid) {
				elem.closest('.ll-row').addClass('is-check is-valid').removeClass('is-error');
			} else {
				elem.closest('.ll-row').addClass('is-check is-error').removeClass('is-valid');
			}
		},


		/**
		 * check date of birth
		 *
		 */
		checkDateOfBirth: function () {
			const that = this;

			// init blur
			this.$form.find('#date_of_birth').on('change', function () {
				const valArray = $(this).val().split('.');
				let dateFormat = valArray[0];

				if (valArray.length >= 2) {
					dateFormat = `${valArray[2]}-${valArray[1]}-${valArray[0]}`;
				}
				const date = new Date(dateFormat);
				const day = that.currentDate.getDate();
				let month = that.currentDate.getMonth() + 1;
				const year = that.currentDate.getFullYear();

				if (day < 10) {
					month = `0${day}`;
				}
				if (month < 10) {
					month = `0${month}`;
				}

				const minDate = new Date(`${year - 18}-${month}-${day}`),
					maxDate = new Date(`${year - 120}-${month}-${day}`);

				if ($(this).val() === '') {
					this.$appendContent.append('<br>Fehler: Geburt leer');
					that.setChecked($(this), false);
				} else {
					// if date is valid
					if (!isNaN(date.getTime())) {
						const dif = that.currentDate.getFullYear() - date.getFullYear();
						if (date > minDate || date < maxDate) {
							// check row
							that.setChecked($(this), false);
							this.$appendContent.append('<br>Fehler: Alter außerhalb zul. Bereich');
						} else if (this.currentDate < date) {
							this.$appendContent.append('<br>Fehler: Geburt in der Zukunft');
							that.setChecked($(this), false);
						} else {
							// check row
							that.setChecked($(this), true);

							// update age
							that.age = dif;

							// calculate
						}
					} else {
						// check row
						that.setChecked($(this), false);
						this.$appendContent.append('<br>Fehler: Datum nicht valide');
					}
				}
			});
		},


		/**
		 * check date of beginning
		 *
		 */
		checkDateOfBeginning: function () {
			const that = this,
				dateFormatMax = moment().add(1, 'y').format('YYYY-MM-DD'),
				dateFormatMaxDay = moment().add(1, 'd').format('YYYY-MM-DD'),
				maxDate = new Date(dateFormatMax),
				insuranceStart = '#insurancestart';

			setTimeout(function () {
				$(insuranceStart).val(moment().add(1, 'd').format('DD.MM.YYYY'));

				// check if value is empty :: chrome needs format yyyy-mm-dd
				if ($(insuranceStart).val() === '') {
					$(insuranceStart).val(moment().add(1, 'd').format('DD-MM-YYYY'));
					$(insuranceStart).attr('value', dateFormatMaxDay);

					if ($(insuranceStart).closest('.l-outer').hasClass('is-touch')) {
						if (!$(insuranceStart).closest('.l-outer').hasClass('is-ios')) {
							$(insuranceStart).val(dateFormatMaxDay);
							$(insuranceStart).attr('value', dateFormatMaxDay);
						}
						else {
							$(insuranceStart).val(moment().add(1, 'd').format('DD-MM-YYYY'));
							$(insuranceStart).attr('value', dateFormatMaxDay);
						}
					}
				}
			}, 200);

			// init change
			$('#insurancestart').on('change', function () {
				const valArray = $(this).val().split('.');
				let dateFormat = valArray[0];

				if (valArray.length >= 2) {
					dateFormat = `${valArray[2]}-${valArray[1]}-${valArray[0]}`;
				}
				const date = new Date(dateFormat);

				if ($(this).val() === '') {
					this.$appendContent.append('<br>Fehler: Begin leer');
					that.setChecked($(this), false);
				} else {
					// if date is valid
					if (!isNaN(date.getTime())) {
						if (date <= that.currentDate) {
							this.$appendContent.append('<br>Fehler: Begin Vergangenheit/Heute');
							that.setChecked($(this), false);
						} else if (date > maxDate) {
							this.$appendContent.append('<br>Fehler: Begin zu weit in der Zukunft');
							that.setChecked($(this), false);
						} else {
							// check row
							that.setChecked($(this), true);
						}
					} else {
						// check row
						that.setChecked($(this), false);
						this.$appendContent.append('<br>Fehler: Datum nicht valide');
					}
				}
			});
		},


		/**
		 * check rate :: single || family
		 *
		 */
		checkRate: function () {
			const that = this;
			const inputRate = 'input[name="rate"]';

			// init change
			this.$form.find(inputRate).on('click change', function () {
				if (that.$form.find('input[name="rate"]:checked').val() === 'single') {
					that.singleRate = 'true';

					// show text
					$('#rate_text_1').show();
					$('#rate_text_2').hide();
				} else {
					that.singleRate = 'false';

					// show text
					$('#rate_text_2').show();
					$('#rate_text_1').hide();
				}

				// calculate
			});

			// initial setting via HTML
			if (that.$form.find('#rate_single').prop('checked') === false) {
				that.$form.find(inputRate).eq(1).trigger('click').blur();
			}

			// initial setting via URL
			if (typeof this.urlvars['singlerate'] != 'undefined') {
				if (this.urlvars['singlerate'] === 'true') {
					that.$form.find(inputRate).eq(0).trigger('click').blur();
				} else if (this.urlvars['singlerate'] === 'false') {
					that.$form.find(inputRate).eq(1).trigger('click').blur();
				}
			}
		},


		/**
		 * check membership :: Kunde || ADACMitglied || ADACPlusMitglied
		 *
		 */
		checkMembership: function () {
			const that = this;
			const membershipPriceStandard = $('#membership_price_standard');
			const membershipPricePlus = $('#membership_price_plus');
			const membershipPriceStandardPlus = $('#membership_price_standard, #membership_price_plus');
			const typeTextInvalid = $('#type_text_invalid');

			// initial setting
			this.membership = this.$form.find(this.membershipChecked).val();

			if (that.membership === 'ADACMitglied' || that.membership === 'ADACPlusMitglied') {
				membershipPriceStandardPlus.hide();

				that.$radioExclusive.prop('disabled', false);
				that.$radioPremium.prop('disabled', false);

				// hide invalid text
				typeTextInvalid.hide();
			}

			// init change
			this.$form.find('input[name="membership"]').on('change', () => {
				that.membership = that.$form.find(this.membershipChecked).val();

				// hide extra membership price
				if (that.membership === 'ADACMitglied' || that.membership === 'ADACPlusMitglied') {
					membershipPriceStandardPlus.hide();

					that.$radioExclusive.prop('disabled', false);
					that.$radioPremium.prop('disabled', false);

					// hide invalid text
					typeTextInvalid.hide();
				} else {
					if (that.$form.find(this.radioGetRateChecked).val() === '1') {
						membershipPriceStandard.show();
						membershipPricePlus.hide();

						that.membership = 'ADACMitglied';
					} else if (that.$form.find(this.radioGetRateChecked).val() === '2') {
						membershipPricePlus.show();
						membershipPriceStandard.hide();

						that.membership = 'ADACPlusMitglied';
					} else {
						that.$radioExclusive.prop('disabled', true);
						that.$radioPremium.prop('disabled', true);

						// switch to AKS
						that.$radioAks.trigger('click').blur();

						// show invalid text
						typeTextInvalid.show();

						that.membership = 'Kunde';
					}
				}
			});

			// extra init change not member :: width membership || width membershipPlus || without membership
			this.$form.find('input[name="radio_get_rate"]').on('change', () => {
				if (that.$form.find(this.radioGetRateChecked).val() === '3') {
					that.$radioExclusive.prop('disabled', true);
					that.$radioPremium.prop('disabled', true);

					// hide extra membership price
					membershipPriceStandardPlus.hide();

					// show invalid text
					typeTextInvalid.show();

					that.membership = 'Kunde';
				} else {
					that.$radioExclusive.prop('disabled', false);
					that.$radioPremium.prop('disabled', false);

					// hide invalid text
					typeTextInvalid.hide();

					// reset membership to calculate
					if (that.$form.find(this.radioGetRateChecked).val() === '1') {

						// hide/show extra membership price
						membershipPriceStandard.show();
						membershipPricePlus.hide();

						that.membership = 'ADACMitglied';
					} else if (that.$form.find(this.radioGetRateChecked).val() === '2') {

						// hide/show extra membership price
						membershipPriceStandard.hide();
						membershipPricePlus.show();

						that.membership = 'ADACPlusMitglied';
					}
				}
			});

			// if logged in
			if (this.loggedin) {
				const radioMembership2 = $('#radio_membership_2');
				const radioMembership3 = $('#radio_membership_3');

				if (this.membership === 'Kunde') {
					// if not member
					radioMembership2.prop('disabled', true);
					radioMembership3.prop('disabled', true);

					if (that.$form.find(this.radioTypeChecked).val() === 'EXKLUSIV' || that.$form.find(this.radioTypeChecked).val() === 'PREMIUM') {
						// reset membership to calculate
						that.membership = 'ADACMitglied';
					}
				} else {
					// hide extra membershiop prices
					membershipPricePlus.hide();
					membershipPriceStandard.hide();

					if (this.membership === 'ADACMitglied') {
						// if member
						$('#radio_membership_1').prop('disabled', true);
						radioMembership3.prop('disabled', true);
						radioMembership2.trigger('click').blur();
					} else if (this.membership === 'ADACPlusMitglied') {
						// if member plus
						$('#radio_membership_1').prop('disabled', true);
						radioMembership2.prop('disabled', true);
						radioMembership3.trigger('click').blur();
					}
				}
			}
		},


		/**
		 * check membership :: AKS || EXKLUSIV || PREMIUM
		 *
		 */
		checkType: function () {
			const that = this;
			const radioGetRate3 = $('#radio_get_rate_3').closest('.a-basic-input-radio');

			// init change
			this.$form.find('input[name="radio_type"]').on('click change', () => {
				that.type = that.$form.find(this.radioTypeChecked).val();

				// if logged in
				if (that.type === 'EXKLUSIV') {
					// hide option get rate without membership
					radioGetRate3.hide();

					// show text
					$('#type_text_2').show();
					$('#type_text_1, #type_text_3').hide();

					// reset membership for calucate
					if (that.$form.find(this.membershipChecked).val() === 'Kunde') {
						if (that.$form.find(this.radioGetRateChecked).val() === '1') {
							that.membership = 'ADACMitglied';
						} else if (that.$form.find(this.radioGetRateChecked).val() === '2') {
							that.membership = 'ADACPlusMitglied';
						} else {
							that.membership = 'Kunde';
						}
					}
				} else if (that.type === 'PREMIUM') {
					// hide option get rate without membership
					radioGetRate3.hide();

					// show text
					$('#type_text_3').show();
					$('#type_text_1, #type_text_2').hide();

					// reset membership for calucate
					if (that.$form.find(this.membershipChecked).val() === 'Kunde') {
						if (that.$form.find(this.radioGetRateChecked).val() === '1') {
							that.membership = 'ADACMitglied';
						} else if (that.$form.find(this.radioGetRateChecked).val() === '2') {
							that.membership = 'ADACPlusMitglied';
						} else {
							that.membership = 'Kunde';
						}
					}
				} else {
					// show option get rate without membership
					radioGetRate3.show();

					// show text
					$('#type_text_1').show();
					$('#type_text_2, #type_text_3').hide();

					// hide invalid text
					$('#type_text_invalid').hide();
				}
			});

			// initial setting
			this.type = this.$form.find(this.radioTypeChecked).val();

			if (this.type === 'EXKLUSIV') {
				this.$radioExclusive.trigger('click').blur();

				// hide option get rate without membership
				radioGetRate3.hide();
			} else if (this.type === 'PREMIUM') {
				this.$radioPremium.trigger('click').blur();

				// hide option get rate without membership
				radioGetRate3.hide();
			}
		},


		/**
		 * calculate
		 *
		 */
		calculate: function () {
			const that = this;
			let result = '';

			$.getJSON(that.$calcAks.data('json'), function (data) {
				let count = 0; // count result for testing
				const dummyArray = []; // for testing

				$.each(data.AKS, function (index, item) {
					if (item.Mitglied === that.membership
						&& item.Typ === that.type
						&& parseInt(item.MinimalAlter) <= that.age
						&& parseInt(item.MaximalAlter) >= that.age
						&& item.Einzeltarif === that.singleRate) {
						count++; // dummy
						dummyArray.push(item.ProduktKey); // dummy

						that.produktKey = item.ProduktKey;
						result = item.Beitrag;

						// if result value is for example 13.9, add 0
						if (result.match(/\.\d$/g)) {
							result = `${result}0`;
						}
					}
				});

				let restultsring = '';
				for (let x = 0; x < dummyArray.length; x++) {
					restultsring += `${dummyArray[x]}, `;
				}

				this.$appendContent.append(`<br><strong>Kriterien: </strong>Mitgliedschaft: ${that.membership}, Tarifart: ${that.type}, Alter: ${that.age}, Einzeltarif: ${that.singleRate}`);
				this.$appendContent.append(`<br>${count} Ergebnisse(${restultsring}) => Tarif: ${$.number(result, 2, ',')}`);

				// update sticky box
				$('.ll-rate-value').html(result.replace('.', ','));
			}).done(function () {
				$('.js-result-error').hide();
				$('.js-calculator-proposal').prop('disabled', false).removeClass('is-disabled');
				$('.js-result-valid').show();
			}).fail(function () {
				$('.js-result-error').show();
				$('.js-calculator-proposal').prop('disabled', true).addClass('is-disabled');
				$('.js-result-valid').hide();
			});
		}
	};

	$(document).ready(function () {
		if ($('#calculator_aks').length) {
			S.Calculator.Aks.init();
		}
	});

	window.S.Calculator.Aks = S.Calculator.Aks;

})(jQuery);
