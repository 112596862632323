(function ($) {
	'use strict';

	/**
	 * BasicInputText module implementation.
	 *
	 * @author  <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputText
	 * @extends T.Module
	 */
	T.Module.BasicInputText.Iban = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {Number} */
		lastCaretPosition: 0,

		/** @type {Boolean} */
		shiftPressed: false,

		/** @type {Boolean} */
		ctrlPressed: false,

		/** @type {Boolean} */
		delPressed: false,

		/** @type {Boolean} */
		backSpacePressed: false,

		/** @type {Boolean} */
		arrowLeftPressed: false,

		/** @type {Boolean} */
		arrowRightPressed: false,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.addKeyEventListener();

			// in case input is pre filled
			if (this.$ctx.val().length > 5) {
				this.addWhiteSpaces();
			}

			this._parent.start(resolve);
		},

		/**
		 * listen to key events and toggle helper vars to detect cursor position problems
		 *
		 * shift & ctrl since user might select content just with keyboard inputs (ctrl + shift + arrow-key)
		 *
		 * => 'keyup/-down' on purpose cause 'change' or 'input' events will crash the namics validation
		 *
		 */
		// eslint-disable-next-line
		addKeyEventListener() //NOSONAR Complexity
		{
			// ### KEY DOWN ###

			const inputTextKeyEvent = 'basicInputText.keyEvent.';

			this.$ctx.off('keydown.basicInputTextIban').on('keydown.basicInputTextIban', (e) => {
				S.Utils.delayed(`${inputTextKeyEvent + e.which}`, 5, () => {

					// backspace (8)
					if (e.which === 8) {
						this.backSpacePressed = true;
					}

					// shift (16)
					if (e.which === 16) {
						this.shiftPressed = true;
					}

					// ctrl (17)
					if (e.which === 17) {
						this.ctrlPressed = true;
					}

					// arrow cursor left (37)
					if (e.which === 37) {
						this.arrowLeftPressed = true;
					}

					// arrow cursor right (39)
					if (e.which === 39) {
						this.arrowRightPressed = true;
					}

					// del (46)
					if (e.which === 46) {
						this.delPressed = true;
					}
				});
			});

			// ### KEY UP ###
			// => Resets values from 'keydown' if key is not pressed again within 20ms
			this.$ctx.off('keyup.basicInputTextIban').on('keyup.basicInputTextIban', (e) => {
				// store caret pos to reset it later after string manipulation
				this.lastCaretPosition = this.$ctx[0].selectionStart;

				// backspace (8)
				if (e.which === 8) {
					// reset
					S.Utils.delayed(`${inputTextKeyEvent + e.which}`, 20, () => {
						this.backSpacePressed = false;
					});
				}

				// shift (16)
				if (e.which === 16) {
					// reset
					S.Utils.delayed(`${inputTextKeyEvent + e.which}`, 20, () => {
						this.shiftPressed = false;
					});
				}

				// ctrl (17)
				if (e.which === 17) {
					// reset
					S.Utils.delayed(`${inputTextKeyEvent + e.which}`, 20, () => {
						this.ctrlPressed = false;
					});
				}

				// cursor left (37)
				if (e.which === 37) {
					// reset
					S.Utils.delayed(`${inputTextKeyEvent + e.which}`, 20, () => {
						this.arrowLeftPressed = false;
					});
				}

				// cursor right (39)
				if (e.which === 39) {
					// reset
					S.Utils.delayed(`${inputTextKeyEvent + e.which}`, 20, () => {
						this.arrowRightPressed = false;
					});
				}

				// del (46)
				if (e.which === 46) {
					// reset
					S.Utils.delayed(`${inputTextKeyEvent + e.which}`, 20, () => {
						this.delPressed = false;
					});
				}

				if (this.$ctx.val().length && this.$ctx.val().length > 4) {
					this.addWhiteSpaces();
				}
			});
		},

		/**
		 * mask iban inputs in format: 1111 [blank space] 2222 [blank space] 3333 [blank space] 44..
		 */
		addWhiteSpaces() {
			// remove whitespaces from string and split it in array every 4th char
			const matchedString = this.$ctx.val().replace(/ /g, '').match(/.{1,4}/g);

			// readd with join() (replace ',' with ' ')
			this.$ctx.val(matchedString.join(' '));

			let correctCaretPos = this.lastCaretPosition;

			if (correctCaretPos !== 0) {

				// if modulo 5 = 0, the caret was next to the added white space (on the right) resulting in a false value for the next time we read it out, since we count the added spaces as well.
				if (correctCaretPos % 5 === 0) {

					// jump to left side cause we want to delete the next char too
					if (this.backSpacePressed || this.arrowLeftPressed) {
						correctCaretPos--;
					}

					// increase the value manually
					else if (!this.delPressed) {
						correctCaretPos++;
					}
				}

				// if modulo 5 = 4, the caret was right next to the added white space (on the left) and if delete was pressed, the caret should jump to the right side of white space
				if (correctCaretPos % 5 === 4 && this.delPressed) {
					correctCaretPos++;
				}
			}

			if (!this.shiftPressed && !this.ctrlPressed && !this.arrowRightPressed) {
				this.setCaretPosition(correctCaretPos);
			}

			// trigger change to call namics validation
			S.Utils.delayed(`basicInputTextIban.TriggerChange_${this.$ctx.data('t-id')}`, 10, () => {
				this.$ctx.trigger('change');
			});
		},

		/**
		 * sets caret position
		 */
		setCaretPosition: function (pos) {

			const inputElem = this.$ctx[0];

			// browser fix if setSelectionRange is not supported
			if (inputElem.setSelectionRange) {
				inputElem.setSelectionRange(pos, pos);
			}

			// like IE (you guessed it didnt you?)
			else if (inputElem.createTextRange) {
				const range = inputElem.createTextRange();
				range.collapse(true);
				range.moveEnd('character', pos);
				range.moveStart('character', pos);
				range.select();
			}
		},
	});
}(jQuery));
