'use strict';
(function ($) {
	/**
	 * Utility for authorization of APIL clients, requires CMS API like /api/clientauth
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Utils
	 * @class ClientAuth
	 * @extends T.Utils
	 */
	T.Utils = T.Utils || {};

	T.Utils.ClientAuth = {
		defaultTTL: 86400,
		dataKey: "clientauth",
		/**
		 * Checks current client authorization status for clientKey (calling CMS API if necessary)
		 * @param {String} clientKey - client identifier
		 * @param {Function} successCallback - called when client succefully has been authorized
		 * @param {Function} errorCallback - called when authorization fails
		 */
		authorize: function authorize(clientKey, successCallback, errorCallback) {
			if (this.checkAuth(clientKey)) {
				successCallback();
				return;
			}
			this._requestAuthorization(clientKey, successCallback, errorCallback);
		},
		/**
		 * Checks current client authorization status for clientKey locally (wihout calling API)
		 * @param {String} clientKey - client identifier
		 * @returns {Boolean} true if authorization exists and still valid
		 */
		checkAuth: function checkAuth(clientKey) {
			const prevData = T.Utils.Store.get(this.buildDataKey(clientKey), T.Utils.Store.COOKIE);
			if (prevData) {
				const result = moment().diff(moment(prevData)) < 0;
				if (!result) {
					T.Utils.Store.set(this.buildDataKey(clientKey), null, T.Utils.Store.COOKIE);
				}
				return result;
			}
			return false;
		},
		/**
		 * Stores client authorization expiry timestamp in cookie readable by clients
		 * @param {String} clientKey - client identifier
		 * @param {Number} ttlSeconds - (optional) duration in seconds authorization should remain valid
		 */
		storeAuth: function storeAuth(clientKey, ttlSeconds) {
			if (!ttlSeconds) {
				ttlSeconds = this.defaultTTL;
			}
			T.Utils.Store.set(this.buildDataKey(clientKey), moment().add(ttlSeconds, 'seconds').toISOString(), T.Utils.Store.COOKIE, 1);
		},

		removeAuthStore: function removeAuthStore(clientKey) {
			T.Utils.Store.set(this.buildDataKey(clientKey), null, T.Utils.Store.COOKIE, 1);
		},

		revalidateAuth: function revalidateAuth(clientKey, err, successCallback, errorCallback) {
			if (err.status === 401) {
				T.Utils.ClientAuth.removeAuthStore(clientKey);
				T.Utils.ClientAuth.authorize(clientKey, () => {
					successCallback();
				}, errorCallback);
			} else {
				errorCallback(err);
			}
		},

		_requestAuthorization: function (clientKey, successCallback, errorCallback) {
			T.Utils.Application.init();
			const url = T.Utils.Application.getApiClientAuth();
			const req = {
				// .json case is only for mocking
				method: url.lastIndexOf('.json') === url.length - 5 ? 'GET' : 'POST',
				url: url,
				data: {
					Client: clientKey,
					Token: $('input[name="__RequestVerificationToken"]').val()
				}
			};
			if (req.method === "POST") {
				req.data = JSON.stringify(req.data);
			}
			T.Utils.Ajax.fragment(req, (data) => {
				if (data && data.Success) {
					this.storeAuth(clientKey, data.Data && 0 < data.Data.TTL ? data.Data.TTL : this.defaultTTL);
					successCallback();
				} else {
					errorCallback();
				}
			}, errorCallback);
		},
		buildDataKey: function buildDataKey(clientKey) {
			return `${this.dataKey}_${clientKey}`;
		}
	};
})(jQuery);