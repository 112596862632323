'use strict';
(function ($) {

	/**
	 * ApplicationIntegrationAdapter module implementation.
	 *
	 * @author Marc Radziwill <marc.radzi@googlemail.com>
	 * @namespace T.Module
	 * @class ApplicationIntegrationAdapter
	 * @extends T.Module
	 */
	T.Module.ApplicationIntegrationAdapter = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		applicationParams: {},

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this._errorUrl = this.$ctx.data('errorurl');
			this._checkPrerequisite(this.$ctx);
			window.addEventListener('beforeunload', () => {
				this.renderingCanceled = true;
			});

			resolve();
		},

		/**
		 * Checks prerequesites (i.e., if user has cookies disabled in browser)
		 */
		_checkPrerequisite: function () {
			if (T.Utils.CookiesDisabledHelper._checkCookiesDisabled()) {
				T.Utils.CookiesDisabledHelper._displayCookiesDisabledMessage();
			} else {
				this._initListener(this.$ctx);
				this._getApplication(this.$ctx);
			}
		},

		/**
		 * _initListener.
		 *
		 * Deprecated (delete after all applications are using function initAdditionalModules)
		 *
		 * @param {Object} $ctx
		 */

		_initListener: function () {
			const self = this;

			$(document).bind('initAdditionalModules', function (event, context) {
				if (context) {
					self._sandbox.addModules(context);
				} else {
					self._sandbox.addModules(self.$ctx.children().first()[0]);
				}
			});
		},

		/**
		 * initAdditionalModules.
		 *
		 * @param {Object} $context
		 */

		initAdditionalModules: function (context) {
			const self = this;

			if (context && context[0]) {
				self._sandbox.addModules(context[0]);
			} else {
				self._sandbox.addModules(self.$ctx.children().first()[0]);
			}
		},

		collectInitializeLinks: function collectInitializeLinks(apps) {
			const links = new Set();
			for (const app of apps) {
				if (app.initialize) {
					links.add(app.initialize);
				}
				if (app.subapps && Array.isArray(app.subapps)) {
					const subappLinks = collectInitializeLinks(app.subapps);
					subappLinks.forEach(link => links.add(link));
				}
			}
			return Array.from(links);
		},

		_preloadSubapps: function _preloadSubapps(subapps) {
			const initializeLinks = this.collectInitializeLinks(subapps) ?? [];
			for (const link of initializeLinks) {
				T.Utils.PerfPrefetch.simplePreload(link, 'script');
			}
		},

		/**
		 * _getApplication.
		 *
		 * @param {Object} $ctx
		 */
		_getApplication: function _getApplication($ctx) {
			this.renderingCanceled = false;
			if (!T.Utils.Perf.getMark('alc_start')) {
				T.Utils.Perf.mark('alc_start');
			}

			const configFunc = (applicationConfig) => {
				if (typeof applicationConfig === 'object' && applicationConfig !== null) {
					this.applicationParams = applicationConfig;
					$(this.$ctx).addClass(applicationConfig.selector);
					if (T.Utils.Perf.getMark('alc_start')) {
						T.Utils.Perf.mark('alc_end');
						T.Utils.Perf.setMeasure('alc', 'alc_start', 'alc_end');
					}
					this._initApplication(applicationConfig);
					if (applicationConfig.subapps && applicationConfig.subapps.length > 0) {
						this._preloadSubapps(applicationConfig.subapps);
					}
				} else if (!this.renderingCanceled) {
					T.Utils.Logger.log({}, 'No valid application configuration provided.', window.location.href, '', '');
				}
			};

			let config = $ctx.data('config');
			if ("inline" === config) {
				config = JSON.parse($ctx.find('#config-ApplicationIntegrationAdapter').text());
			}
			if (config && config.initialize) {
				configFunc(config);
			} else {
				T.Utils.Ajax.fragment(
					{
						method: 'GET',
						url: $ctx.data('apigateway')
					},
					configFunc,
					(jqxhr) => {
						window.setTimeout(() => {
							if (!this.renderingCanceled) {
								T.Utils.Logger.log(
									jqxhr,
									'data-apigateway url could not be loaded. Please see HTML data attribue.',
									window.location.href,
									'108',
									''
								);
								window.location.href = this._errorUrl;
							}
						}, 300);
					});
			}
		},

		onloadHandler: function (params) {
			if (T.Utils.Perf.getMark('aljs_start')) {
				T.Utils.Perf.mark('aljs_end');
				T.Utils.Perf.setMeasure('alc', 'aljs_start', 'aljs_end');
			}

			if (T.Utils.Perf.getMark('ai_start')) {
				T.Utils.Perf.mark('ai_end');
				T.Utils.Perf.setMeasure('ai', 'ai_start', 'ai_end');
			}
			if (typeof this.$ctx[`ADAC_${params.name}`] === 'function') {
				this.$ctx[`ADAC_${params.name}`]($.extend(
					true,
					{
						$ctx: this.$ctx,
						tid: this.$ctx.data('t-id')
					},
					params), this);
			} else if (!this.renderingCanceled) {
				T.Utils.Logger.log({}, `Application "${params.name}" cannot be started. Please check if the configured application name matches the implementation.`, window.location.href, '139', '');
			}
		},

		onMainScriptError: function () {
			window.setTimeout(() => {
				if (!this.renderingCanceled) {
					T.Utils.Logger.log({}, 'JavaScript bundle could not be loaded. Please see NLog.', window.location.href, '141', '');
					window.location.href = this._errorUrl;
				}
			}, 300);
		},


		/**
		 * _initApplication.
		 *
		 * @param {Object} params
		 */

		// eslint-disable-next-line sonarjs/cognitive-complexity
		_initApplication: function (params) {
			if (params.initialize && params.initialize.length > 0) {
				if (!T.Utils.Perf.getMark('aljs_start')) {
					T.Utils.Perf.mark('aljs_start');
				}
				this._appendScript(params, { async: true, fetchPriority: 'high' }, this.onloadHandler, this.onMainScriptError);
				T.Utils.Perf.mark('ai_start');
			} else {
				T.Utils.Logger.log({}, 'JavaScript bundle could not be loaded. Initialize (js bundle) url is null', window.location.href, '141', '');
			}
		},

		_appendScript: function (params, scriptOptions, onloadHandler, onError) {
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = params.initialize;
			if (scriptOptions.async) {
				script.async = true;
			}
			if (scriptOptions.fetchPriority) {
				script.fetchPriority = scriptOptions.fetchPriority;
			}

			script.onload = onloadHandler.bind(this, params);

			script.onerror = onError;
			document.head.appendChild(script);
		},

		/**
		 * _loadDependencies.
		 *
		 * checks the dependencies deployed with the plugin constructor
		 * loads and initializes the scripts.
		 */

		_loadDependencies: function (applicationCallback) {
			applicationCallback();
		},

		_initSubApps: function (item, loadCallback) {
			this._appendScript(item, { fetchPriority: 'high' }, this.onLoadSubappHandler.bind(this, item, loadCallback), this.onErrorSubappHandler.bind(this));
		},

		onLoadSubappHandler: function (item, loadCallback) {
			if (typeof this.$ctx[`ADAC_${item.name}`] === 'function') {
				this.$ctx[`ADAC_${item.name}`]($.extend(
					true,
					{
						$ctx: this.$ctx,
						tid: this.$ctx.data('t-id')
					},
					item), this);
				loadCallback();
			}
		},

		onErrorSubappHandler: function (jqxhr) {
			T.Utils.Logger.log('SubApplication could not be initialized', jqxhr, window.location.href);
		},

		loadSubApps: function (app, applicationCallback) {
			const self = this;
			let subAppsToLoad;

			if (app.options.subapps && app.options.subapps.length > 0) {
				subAppsToLoad = app.options.subapps.length;
				$.each(app.options.subapps, function (idx, item) {
					item.app.parentapp = app.options.app;
					self._initSubApps(item, loadCallback);
				});

			} else {
				applicationCallback();
			}

			function loadCallback() {
				subAppsToLoad -= 1;
				if (subAppsToLoad === 0) {
					applicationCallback();
				}
			}
		},

		/**
		 * All applications should call this method after loading (including javascript) has been completed.
		 */
		complete: function () {
			// adds a css class to the markup to help the e2e tests identify when an application is ready
			this.$ctx.children('div').addClass('e2e-app-complete');
		}
	});
}(jQuery));
