/// <reference path="../../../assets/typings/mapbox-gl.d.ts" />

'use strict';
// eslint-disable-next-line no-unused-vars
(function ($) {

	T.Utils = T.Utils || {};

	/**
	 * Geo utilitiy functions.
	 *
	 * @author  <marc.radziwill@>
	 * @namespace T.Utils
	 * @class Geo
	 */
	T.Utils.Geo = {
		MERC_2_GEO_X_HELPER: 18000 / (6371 * Math.PI),
		MERC_2_GEO_Y_HELPER: 36000000 / Math.PI,
		SMART_OFFSET: 20015087,
		SMART_UNIT: 4.809543,
		GEO_2_MERC_X_HELPER: 63.71 * (Math.PI / 180),
		GEO_2_MERC_Y_HELPER: Math.PI * (0.5 / 18000000),

		coordToLocation: function coordToLocation(coordXy) {
			if (coordXy) {
				return Array.isArray(coordXy) ? coordXy : [coordXy.x, coordXy.y];
			}
			return [];
		},
		geoDecimal2SmartUnit: function geoDecimal2SmartUnit(geoPoint) {
			return this.mercator2SmartUnit(this.geoDecimal2Mercator(geoPoint));
		},
		mercator2SmartUnit: function mercator2SmartUnit(mercPoint, precision) {
			const result = {
				x: ((mercPoint.x + this.SMART_OFFSET) / this.SMART_UNIT),
				y: ((mercPoint.y + this.SMART_OFFSET) / this.SMART_UNIT)
			};
			if (undefined !== precision) {
				result.x = Number(result.x.toFixed(precision));
				result.y = Number(result.y.toFixed(precision));
			}
			return result;
		},
		smartUnit2Mercator: function smartUnit2Mercator(suPoint) {
			return {
				x: suPoint.x * this.SMART_UNIT - this.SMART_OFFSET,
				y: suPoint.y * this.SMART_UNIT - this.SMART_OFFSET
			};
		},
		geoDecimal2Mercator: function geoDecimal2Mercator(geoPoint) {
			return {
				x: this.GEO_2_MERC_X_HELPER * geoPoint.x,
				y: 6371000.0 * Math.log(Math.tan((Math.PI / 4.0) + this.GEO_2_MERC_Y_HELPER * geoPoint.y))
			};
		},
		latlonToMercator: function latlonToMercator(latlon, precision) {
			const smart = this.geoDecimal2SmartUnit(this.latlonToGeoDecimal(latlon, precision));
			const result = this.smartUnit2Mercator(smart);
			result.x = Math.round(result.x);
			result.y = Math.round(result.y);
			return result;
		},
		latlonToGeoDecimal: function latlonToGeoDecimal(latlon, precision) {
			return {
				x: (undefined === precision ? latlon.x : Number(latlon.x.toFixed(precision))) * 100000,
				y: (undefined === precision ? latlon.y : Number(latlon.y.toFixed(precision))) * 100000
			};
		},
		mercatorToLonLat: function mercatorToLonLat(mercPoint, precision) {
			const geoPoint = {
				x: T.Utils.Map.MERC_2_GEO_X_HELPER * mercPoint.x,
				y: (Math.atan(Math.exp(mercPoint.y / 6371000.0)) - (Math.PI / 4.0)) * T.Utils.Map.MERC_2_GEO_Y_HELPER
			};
			geoPoint.x /= 100000;
			geoPoint.y /= 100000;
			if (undefined !== precision) {
				geoPoint.x = Number(geoPoint.x.toFixed(precision));
				geoPoint.y = Number(geoPoint.y.toFixed(precision));
			}
			return geoPoint;
		},
		isMercatorPoint: function isMercatorPoint(point) {
			return Number(point.x) === point.x && point.x % 1 === 0 &&
				Number(point.y) === point.y && point.y % 1 === 0 &&
				(point.x > 180 || point.x < -180) && (point.y > 180 || point.y < -180);
		}
	};
})(jQuery);