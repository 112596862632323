(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.Clone = {

		asideMode: {},

		mediaQueryRightNow: '',


		/**
		 * init form actions
		 *
		 */
		init: function () {
			this.initClone();
		},


		/**
		 * Triggers need/ can have the following datas:
		 *      data-clone: unique id
		 *      data-clone-trigger-source: where can we find the HTML to be cloned
		 *      data-clone-trigger-target: in which container should the clone be stored
		 *      data-clone-max: optional, max number of possible clones
		 *      data-clone-parent: optional, what element needs to be hidden if max is reached
		 *      data-clone-self="0": set, if Namics need to do something before and calls the cloning function themself
		 *      data-clone-remove-child: clone id - parent trigger that shows the clone dialogs and sets them back to inital when changed. E.g. a tile that opened a "add clone" btn and is now changed -> clone should be deleted
		 *
		 * Element, where the 'to be cloned code' can be found: data-clone-source [data-clone-trigger-source]; inside
		 * there has to be a wrapper which wraps the code
		 *      every one of these wrappers has data-clone-heritage [data-clone-trigger-source] specified so we can
		 *      have an target container for multiple sources and can still distinguish the single sources (e.g. to be count
		 *      for max  Value of clones)
		 *
		 * Element which will contain cloned Elements:
		 *      data-clone-target [data-clone-trigger-target]
		 *      data-clone-count="0" -> this will incremented for each added cloneblock so we can modify given ids to be unique
		 *
		 * Overlay for delete Hint:
		 *      data-clone-delete-self="0" set, if Namics need to do the deleting and calls the deleting function themself
		 */
		initClone() {
			// classes/data as strings
			this.dataCloneTarget = '*[data-clone-target="';
			this.cloneSelectDepend = 'clone-select-depend';
			this.dataCloneSelect = '[data-clone-select="';
			this.jsDeleteSelectClone = '.js-delete-select-clone';
			this.cloneSelectString = 'clone-select';
			this.jsCloneCount = '.js-clone-count';
			this.cloneParent = 'clone-parent';

			const cloneTrigger = $('[data-clone]');
			const cloneSelectTrigger = $('[data-clone-select]');
			const that = this;
			const $removeChild = $('[data-clone-remove-child]');
			const formContainer = '.mm-form-container';

			// set tabIndex and enter event
			S.Globals.TabFocus.addPopup(cloneTrigger, this);

			cloneTrigger.on('click', (e) => {
				if (parseInt($(e.currentTarget).data('clone-self')) !== 0) {
					this.cloneBlock(e.currentTarget);
				}
			});

			cloneTrigger.each((index, elem) => {
				const target = $(document).find(`${this.dataCloneTarget + $(elem).data('clone-trigger-target')}"]`);
				if ((typeof $(elem).data('clone-inittrigger') !== 'undefined') && (target.children().length === 0)) {
					$(elem).trigger('click');
				}
			});

			cloneSelectTrigger.on('change', (e) => {
				if (parseInt($(e.currentTarget).data('clone-self')) !== 0) {
					this.cloneSelect(e.currentTarget);
				}

				if (typeof $(e.currentTarget).data(this.cloneSelectDepend) !== 'undefined') {
					$(`${this.dataCloneSelect + $(e.currentTarget).data(this.cloneSelectDepend)}"]`).val($(e.currentTarget).val()).trigger('change');
				}
			});

			cloneSelectTrigger.each((index, elem) => {
				if ($(elem).val() > 1 && typeof ($(elem).data('clone-not-on-init')) === 'undefined') {
					that.cloneSelect($(elem));
				}
				else {
					const trigger = $(elem);
					const target = $(`div[data-clone-select-target="${trigger.data('clone-select-t')}"]`);
					target.children().each((i, element) => {
						$(element).find(this.jsDeleteSelectClone).on('click', (element) => {
							that.recountSelectClone(element, trigger, target);
						});
					});
				}

				if (typeof $(elem).data(this.cloneSelectDepend) !== 'undefined') {

					const dependentSelect = $(`${this.dataCloneSelect + $(elem).data(this.cloneSelectDepend)}"]`);
					$(elem).find('option').each((index, el) => {
						$(el).clone().appendTo(dependentSelect);
					});

					if ($(elem).val() > 1 && typeof ($(elem).data('clone-not-on-init')) === 'undefined') {
						dependentSelect.val($(elem).val());
						that.cloneSelect(dependentSelect);
					}
				}
			});

			$removeChild.on('change', (e) => {
				const dataId = $(e.currentTarget).data('cloneRemoveChild'),
					$cloneTarget = $(`[data-clone-target="${dataId}"]`);

				if ($cloneTarget.length) {
					$cloneTarget.find('> .ll-clone-wrapper').remove();
					$('[data-clone-trigger-target="reserve"]').show();
				}
			});

			this.initEvents(formContainer);
		},

		initEvents(formContainer) {
			$('.js-clone-person').on('click', function (item) {
				const nextPerson = $(item.currentTarget).closest('.m-ves-rs-contentbox').find('.is-clone-person.is-hidden').first();
				nextPerson.removeClass('is-hidden');
				$('.is-summary').find(`.${nextPerson.data('number')}`).removeClass('is-hidden');
			});

			$('.js-clone-death').on('click', function (trigger) {

				const $nextPerson = $(trigger.currentTarget).closest('.l-form').find('.is-clone-death.is-hidden');

				// show
				if ($nextPerson.length) {

					$nextPerson.eq(0).removeClass('is-hidden');

					$nextPerson.eq(0).find('.ll-delete-clone').off('click').on('click', function (item) {
						const wrapper = $(item.currentTarget).closest('.is-clone-death');
						wrapper.addClass('is-hidden').find('input').val('');
						$(item.currentTarget).closest('.l-form').find('.js-clone-death').css('opacity', 1.0);
					});

					if (!$(trigger.currentTarget).closest('.l-form').find('.is-clone-death.is-hidden').length) {
						$(trigger.currentTarget).css('opacity', 0.5);
					}
				}
			});

			$('.js-toggle-row-btn').off('click.showRow').on('click.showRow', function () {

				const $rowTarget = $(this).closest(formContainer).find('.js-toggle-row.h-hidden');

				// show
				if ($rowTarget.length) {

					$rowTarget.eq(0).removeClass('h-hidden');

					if (!$(this).closest(formContainer).find('.js-toggle-row.h-hidden').length) {
						$(this).css('opacity', 0.5);
					}
				}
			});

			$('.js-clone-familymember').on('click', function (trigger) {

				const $nextPerson = $(trigger.currentTarget).closest('.l-form').find('.is-clone-familymember.is-hidden');

				// show
				if ($nextPerson.length) {

					$nextPerson.eq(0).removeClass('is-hidden');

					$nextPerson.eq(0).find('.ll-delete-clone').off('click').on('click', function (item) {
						const wrapper = $(item.currentTarget).closest('.is-clone-familymember');
						wrapper.addClass('is-hidden').find('input').val('');
						$(item.currentTarget).closest('.l-form').find('.js-clone-familymember').css('opacity', 1.0);
					});

					if (!$(trigger.currentTarget).closest('.l-form').find('.is-clone-familymember.is-hidden').length) {
						$(trigger.currentTarget).css('opacity', 0.5);
					}
				}
			});

			$('.js-clone-friends').on('click', function (trigger) {

				const $nextPerson = $(trigger.currentTarget).closest(formContainer).find('.is-clone-friends.h-hidden');

				// show
				if ($nextPerson.length) {

					$nextPerson.eq(0).removeClass('h-hidden');

					$nextPerson.eq(0).find('.js-clone-remove').off('click').on('click', function (item) {
						const wrapper = $(item.currentTarget).closest('.is-clone-friends');
						wrapper.addClass('h-hidden').find('input').val('');
						$(item.currentTarget).closest(formContainer).find('.js-clone-friends').css('opacity', 1.0);
					});

					if (!$(trigger.currentTarget).closest(formContainer).find('.is-clone-friends.h-hidden').length) {
						$(trigger.currentTarget).css('opacity', 0.5);
					}
				}
			});

			$('.js-hide-row').off('click.hideRow').on('click.hideRow', function (item) {

				const $target = $(item.currentTarget).closest('.js-hide-target');

				if ($target.length) {
					$target.addClass('h-hidden').find('input').val('');
					$($target).closest(formContainer).find('.js-toggle-row-btn').css('opacity', 1.0);
				}
			});

			$('.js-delete-clone').off('click').on('click', function (item) {
				const nextPerson = $(item.currentTarget).closest('.is-clone-person, .is-clone-child');
				nextPerson.addClass('is-hidden').find('input').val('');
				if (nextPerson.hasClass('is-clone-person')) {
					$('.is-summary').find(`.${nextPerson.data('number')}`).addClass('is-hidden');
				}
			});
		},

		_handleFocusEnter($target) {
			$target.trigger('click');
		},

		/**
		 * reinit js logic for elems like input fields after cloning
		 * datepicker needs to have class hasDatepicker removed in order to work
		 * init Trigger for delete cloned Element and show Trigger again if it was hidden due to reached maximum
		 */
		reinitModules: function ($elem, handle) {
			const that = this;
			// clear data-t-id
			$elem.find('[data-t-name]').each((index, item) => {
				$(item).removeAttr('data-t-id');

				if ($(item).hasClass('hasDatepicker')) {
					$(item).removeClass('hasDatepicker');
				}
				$(document).trigger('addModules', $(item));
			});

			// reinit depends
			$elem.find('[data-depend]').each(() => {
				S.Utils.Form.initDepends();
			});

			// reinit magnific popup
			$elem.find('.js-lightbox').each(() => {
				S.Utils.Lightbox.init('js-lightbox', $elem);
			});

			// delete data-clone
			if (typeof $elem.data('clone-source') !== 'undefined' && $elem.data('clone-source').length) {
				$elem.removeAttr('data-clone-source');
			}

			//dateconditions
			S.Utils.Form.initDateConditions();

			// init delete
			$elem.find('.ll-delete').each((index, cloneblock) => {
				const itemToMagnify = $(cloneblock);
				S.Globals.TabFocus.addPopup($elem.find('.ll-delete'), this);

				itemToMagnify.magnificPopup({
					fixedContentPos: true,
					callbacks: {
						beforeOpen: function () {
							if ($('.l-outer').hasClass('l-outer--my-adac-content')) {
								$elem.find('.ll-info-delete').addClass("my-adac");
							}
							this.st.mainClass = `${this.st.el.attr('data-effect')} l-lightbox`;
						},
						elementParse: function (thisitem) {
							const tmp = $(thisitem.src);
							tmp.find('[data-clone-delete="yes"]').on('click', function () {
								if (parseInt(tmp.data('clone-delete-self')) !== 0) {
									that.deleteBlock(itemToMagnify, handle);
									$.magnificPopup.close();
								}
							});

							tmp.find('[data-clone-delete="no"]').on('click', function () {
								$.magnificPopup.close();
							});
						},
					},
					items: {
						src: $elem.find('.ll-info-delete'),
						type: 'inline'
					},
					type: 'inline'
				});
			});

			$(document).trigger('utilsClone.reinit.finished', $elem);

			if ($elem.hasClass('ll-clone-wrapper')) {
				setTimeout(function () {
					$elem.addClass('is-visible');
				}, 1);
			}
		},

		/**
		 * clone Selectfunction
		 * @param trigger: clicked element
		 */
		cloneSelect: function (trigger) {
			trigger = $(trigger);
			const source = $(`div[data-clone-select-source="${trigger.data(this.cloneSelectString)}"]`),
				target = $(`div[data-clone-select-target="${trigger.data('clone-select-t')}"]`),
				cloneCount = target.find(' > .js-is-clone').length,
				clonesToAdd = (trigger.val() - 1) - cloneCount,
				cloneSelectCount = 'clone-select-count';
			let cloneDataCount = 0;
			if (target.data(cloneSelectCount)) {
				cloneDataCount = target.data(cloneSelectCount);
			}

			if (clonesToAdd > 0) {
				for (let i = 0; i < clonesToAdd; i++) {
					const cloneEl = source.clone(true),
						cloneNumber = cloneCount + i + 2,
						consecutiveNumber = cloneDataCount,
						validationClasses = 'is-valid is-error';

					cloneEl.find(this.jsCloneCount).text(cloneNumber);
					cloneEl.addClass('js-is-clone');
					cloneEl.find('input').each(function () {
						if ($(this).attr('type') !== 'radio' && $(this).attr('type') !== 'checkbox') {
							$(this).val('');
							$(this).attr('value', '');
						}
						else {
							$(this).attr('checked', false);
						}
					});

					cloneEl.find('select').each(function () {
						$(this).find('option').removeAttr('selected');
					});
					cloneEl.find('input').removeClass(validationClasses);
					cloneEl.find('.is-check').removeClass(validationClasses);
					cloneEl.find(this.jsDeleteSelectClone).removeClass('h-hidden');

					cloneEl.find('[data-t-id]').each((index, item) => {
						$(item).removeAttr('data-t-id');

						if ($(item).hasClass('hasDatepicker')) {
							$(item).removeClass('hasDatepicker');
						}
					});

					if (cloneEl.data('clone-select-sep')) {
						cloneEl.addClass(cloneEl.data('clone-select-sep'));
					}

					let sourceHtml = cloneEl[0].outerHTML;
					const regEx = / (id|for|name|data-depend|data-js-date-condition-source|aria-controls)="([^"]*)"/ig; // alles was an names, ids etc hochgezählt werden muss

					let res = regEx.exec(sourceHtml);

					while (res !== null) {
						const newId = res[0].replace(res[2], `${res[2]}_${consecutiveNumber}`);
						sourceHtml = sourceHtml.replace(res[0], newId);
						res = regEx.exec(sourceHtml);
					}

					target.append(sourceHtml);
					target.children().removeClass('h-hidden');
					cloneDataCount++;

				}
				this.reinitModules(target, target.children());
				$(document).trigger('UtilsCloneSelect.finish');
				target.data(cloneSelectCount, cloneDataCount);
			}

			else if (clonesToAdd < 0) {
				target.find(' > .js-is-clone').slice(clonesToAdd).remove();
				$(document).trigger('UtilsCloneSelect.delete');
			}

			if (target.children().length === 0) {
				source.find(this.jsCloneCount).empty();
			}
			else {
				source.first().find(this.jsCloneCount).text('1');
				target.find(`div[data-clone-select-source="${trigger.data(this.cloneSelectString)}"]`).removeAttr('data-clone-select-source');
			}

			target.find(this.jsDeleteSelectClone).on('click', (element) => {
				this.recountSelectClone(element, trigger, target);
			});
		},

		recountSelectClone: function (element, trigger, target) {
			const parent = $(element.currentTarget).closest('.m-basic-calculator-content-box--is-clone-parent, .is-clone-parent');
			const cloneIndex = parent.index();
			const source = $(`div[data-clone-select-source="${trigger.data(this.cloneSelectString)}"]`).first();

			trigger.val(trigger.val() - 1);

			if (typeof trigger.data(this.cloneSelectDepend) !== 'undefined') {
				$(`${this.dataCloneSelect + trigger.data(this.cloneSelectDepend)}"]`).val(trigger.val());
				$(`[data-clone-select-target="${trigger.data(this.cloneSelectDepend)}"]`).children().eq(parent.index()).remove();
			}

			parent.fadeOut();

			setTimeout(() => {
				parent.remove();

				if (target.children().length >= 1) {
					target.children().each((index, elem) => {
						if (index >= cloneIndex) {
							const newIndex = index + 2; //not starting with 0 or 1
							$(elem).find(this.jsCloneCount).text(newIndex);
						}
					});
					source.find(this.jsCloneCount).text('1');
				}
				else {
					source.find(this.jsCloneCount).empty();
				}
				$(document).trigger('UtilsCloneSelect.delete');
			}, 500);
		},

		/**
		 * clone block function.
		 * @param trigger: clicked element
		 */
		cloneBlock: function (trigger) {
			trigger = $(trigger);
			const source = trigger.data('clone-trigger-source');
			const target = trigger.data('clone-trigger-target');
			const handle = trigger.data('clone');
			const maxClone = parseInt(trigger.data('clone-max'));
			const noCloneWrap = typeof trigger.data('clone-no-wrap') !== 'undefined';

			const $cloneTarget = $(document).find(`${this.dataCloneTarget + target}"]`);

			let sourceHtml;

			// we want the parent too! not just its content
			if (noCloneWrap) {

				// store the parent object of the src by cloning it
				const sourceObj = $(document).find(`*[data-clone-source="${source}"]`).parent().clone(false, false);

				// remove siblings of the src
				$(sourceObj).find(`> :not([data-clone-source="${source}"])`).remove();
				sourceHtml = sourceObj.html();
			}

			else {
				sourceHtml = $(document).find(`*[data-clone-source="${source}"]`).html();
			}

			// cleaning html from validate states
			sourceHtml = sourceHtml.replace(/is-valid|is-error/g, '');

			let newSource = sourceHtml;
			const counter = parseInt($cloneTarget.data('clone-count'));
			const regEx = / (id|for|name|data-depend|data-js-condition|data-js-condition-context|data-js-reset-condition|aria-controls)="([^"]*)"/ig; // alles was an names, ids etc hochgezählt werden muss
			// const regEx = / id="([^"]*)"/ig;

			let res = regEx.exec(sourceHtml);

			while (res !== null) {
				const newId = res[0].replace(res[2], `${res[2]}_${counter}`);
				newSource = newSource.replace(res[0], newId);
				res = regEx.exec(sourceHtml);
			}

			$cloneTarget.data('clone-count', (counter + 1));
			$cloneTarget.append(newSource);

			const heritageChildren = $cloneTarget.children().filter(`*[data-clone-heritage="${source}"]`).length;

			$cloneTarget.children().last().find('.ll-clone-count').text(heritageChildren);

			$cloneTarget.children().last().find('input').first().focus();

			if (maxClone <= heritageChildren) {
				if (typeof trigger.data(this.cloneParent) !== 'undefined') {
					trigger.closest(`.${trigger.data(this.cloneParent)}`).hide();
				}
				else {
					trigger.hide();
				}
			}

			if (noCloneWrap) {

				const $closestComp = $cloneTarget.closest('[data-t-name]');
				$closestComp.removeAttr('data-t-id');

				$($cloneTarget).find('*[data-clone-source]').each((index, elem) => {
					if (index > 0) {
						$(elem).removeAttr('data-clone-source');
					}
				});

				$(document).trigger('addModules', $closestComp);

				this.reinitModules($closestComp, handle);
			}

			else {
				this.reinitModules($(document).find(`${this.dataCloneTarget + target}"]`).children().last(), handle);
			}
		},

		/**
		 * delete clone block function.
		 * @param itemToMagnify: delete Trigger (e.g. legend ll-delete in Forms)
		 * @param handle: clone trigger (element with data-clone)
		 */
		deleteBlock: function (itemToMagnify, handle) {
			const cloneWrapper = '.ll-clone-wrapper',
				heritage = itemToMagnify.closest(cloneWrapper).data('clone-heritage'),
				triggerElement = $(`[data-clone="${handle}"]`),
				parent = itemToMagnify.closest(cloneWrapper).parent();

			itemToMagnify.closest(cloneWrapper).removeClass('is-visible');
			itemToMagnify.closest(cloneWrapper).remove();

			parent.children().filter(`*[data-clone-heritage="${heritage}"]`).each(function (index) {
				$(this).find('.ll-clone-count').text(index + 1);
			});

			if (typeof triggerElement.data(this.cloneParent) !== 'undefined') {
				triggerElement.closest(`.${triggerElement.data(this.cloneParent)}`).show();
			}
			else {
				triggerElement.show();
			}

			/**
			 * trigger event when cloned block is deleted
			 * - usage for now: mgl
			 *
			 * emit(<Event>, <EventData>)
			 */
			$(document).trigger("CloneBlockDeleted", triggerElement);
		},
	};

	$(document).ready(function () {
		S.Utils.Clone.init();
	});

})(jQuery);
