(function($) {
	'use strict';
	/**
	 * BasicToggleTabBox module implementation.
	 *
	 * @author m.haeusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicToggleTabBox
	 * @extends T.Module
	 */
	T.Module.BasicToggleTabBox = T.createModule({

		selectorHeaderList: '.mm-desk-header-list',

		start: function(resolve) {
			this.$ctx = $( this._ctx );

			// classes as strings
			this.toggleTabHeadline = '.mm-toggle-tab-headline';
			this.toggleTabContent = '.mm-toggle-tab-content';
			this.tabToggleHeadline = '.mm-tab-toggle-headline';
			this.toggleTabContainer = '.mm-toggle-tab-container';
			this.tabToggleHeadlineOnlyChild = '.mm-tab-toggle-headline:only-child';
			this.isCurrent = 'is-current';
			this.isCurrentClass = '.is-current';
			this.tIndex = '.mm-t-index';
			this.deletedContainerToggleTabContent = '.mm-toggle-tab-container[data-js-delete-id="1"] .mm-toggle-tab-content';

			this.$toggleBtn = this.$ctx.find(this.toggleTabHeadline);
			this.$toggleContent = this.$ctx.find(this.toggleTabContent);
			this.$tabBtn = this.$ctx.find(this.tabToggleHeadline);
			this.$tabContainer = this.$ctx.find(this.toggleTabContainer);
			this.$validateCheckbox = this.$ctx.find('.js-checkbox-validate');
			this.$deleteBoxtrigger = this.$ctx.find('.mm-tab-close');
			this.$deleteBtn = $('.js-delete-elem');
			this.$addTabs = $('.js-add-scracitie');
			this.$modHeight = this.$ctx.height();

			this.initToggle();
			this.initTab();
			this.initTabToggleId();
			this.resizeModule();
			this.checkboxValidation('initial');
			this.addTabs();
			this.deleteTabs();


			this.$validateCheckbox.on('change', (e) => {
				this.checkboxValidation('change', e);
			});

			if(this.$ctx.find(this.tabToggleHeadlineOnlyChild).length){
				this.$ctx.find(this.tabToggleHeadlineOnlyChild).closest(this.selectorHeaderList).addClass('is-hidden');
			}

			resolve();
		},

		initToggle()
		{
			this.$toggleBtn.off().on('click', (e) =>
			{
				const clickedElement = $(e.currentTarget);

				const content = clickedElement.closest(this.toggleTabContainer).find(this.toggleTabContent);

				if(!content.hasClass(this.isCurrent))
				{
					content.addClass(this.isCurrent).slideDown(400, function() {
						$('html, body').animate({scrollTop:clickedElement.position().top});
					});
					clickedElement.addClass(this.isCurrent);
				}
				else
				{
					content.filter(this.isCurrentClass).slideUp().removeClass(this.isCurrent);
					clickedElement.filter(this.isCurrentClass).removeClass(this.isCurrent);
				}
			});
		},

		initTab()
		{
			const toggleTabBox = `${this.$ctx.attr("data-t-name")}_${this.$ctx.attr("data-t-id")}`;

			this.$ctx.on(`keydown.${  toggleTabBox}`, (e) => {
				if(e.which === 13 && $('body').hasClass('is-keyboard-focus')) {
					this.$ctx.find('.mm-tab-toggle-headline:focus-visible').trigger('click');
				}
			});

			this.$tabBtn.off().on('click', (e) =>
			{
				const position = $(e.currentTarget).index();
				const content = this.$tabContainer.eq(position).find(this.toggleTabContent);
				this.$tabBtn.removeClass(this.isCurrent);
				$(e.currentTarget).addClass(this.isCurrent);

				if(!content.hasClass(this.isCurrent))
				{
					this.$toggleContent.filter(this.isCurrentClass).fadeOut(250).removeClass(this.isCurrent);
					content.fadeIn(400).addClass(this.isCurrent);
				}
			});
		},

		checkboxValidation(mod, e) {

			if(mod === 'change') {
				const $changedBox = $(e.target);
				const boxAttr = $changedBox.data('check');

				const $equalElement = this.$validateCheckbox.filter(`[data-check='${  boxAttr  }']`);


				if ($changedBox.is(':checked')) {
					$equalElement.attr('disabled', 'disabled');
					$changedBox.removeAttr('disabled');
				} else {
					$equalElement.removeAttr('disabled');
				}
			}
			else {

				this.$validateCheckbox.filter(":checked").each((index, item) => {

					const $checkedElement = $(item);
					const boxAttr = $checkedElement.data('check');

					const $equalElement = this.$validateCheckbox.filter(`[data-check='${boxAttr}']`);


					if ($checkedElement.is(':checked')) {
						$equalElement.attr('disabled', 'disabled');
						$checkedElement.removeAttr('disabled');
					} else {
						$equalElement.removeAttr('disabled');
					}
				});
			}

		},

		initTabToggleId()
		{
			const that = this;
			let countTabs = 1,
				countToggles = 1;

			that.$ctx.find('.mm-desk-header-list .mm-tab-toggle-headline').each( (index, tabEl) => {
				$(tabEl).attr('data-js-delete-id', countTabs);
				$(tabEl).find(this.tIndex).text(countTabs);
				countTabs++;
			});

			that.$ctx.find('.mm-toggle-tab-container .mm-toggle-tab-headline').each( (index, toggleEl) => {
				$(toggleEl).closest(this.toggleTabContainer).attr('data-js-delete-id', countToggles);
				$(toggleEl).find(this.tIndex).text(countToggles);
				countToggles++;
			});
		},

		resizeModule()
		{

			S.Utils.Helper.mq( 'mobile' ).addListener(() => {
				if (S.Utils.Helper.mq('mobile').matches) {
					const active = this.$tabBtn.filter(this.isCurrentClass).index();
					this.$toggleBtn.removeClass(this.isCurrent).closest(this.toggleTabContainer).eq(active).find(this.toggleTabHeadline).addClass(this.isCurrent);
				}
				else
				{
                    this.$ctx.find(this.tabToggleHeadline).removeClass(this.isCurrent);
                    this.$ctx.find('.mm-toggle-tab-container .mm-toggle-tab-headline').removeClass(this.isCurrent);
                    this.$ctx.find('.mm-toggle-tab-container .mm-toggle-tab-content').fadeOut(400).removeClass(this.isCurrent);
					this.$ctx.find('.mm-tab-toggle-headline[data-js-delete-id="1"]').addClass(this.isCurrent);
					this.$ctx.find('.mm-toggle-tab-container[data-js-delete-id="1"] .mm-toggle-tab-headline').addClass(this.isCurrent);
					this.$ctx.find(this.deletedContainerToggleTabContent).fadeIn(400).addClass(this.isCurrent);
				}
			});

		},

		addTabs()
		{
			this.$addTabs.on('click', (e)=> {
				e.preventDefault();

				const newCloneContainer = this.$tabContainer.first().clone().appendTo(this.$ctx.find('.mm-formular')),
					newCloneHeadline = this.$tabBtn.first().clone().appendTo(this.$ctx.find(this.selectorHeaderList)),
					currentCount = parseInt(this.$tabContainer.last().find(this.tIndex).text() + 1),
					scrollPos = this.$ctx.offset().top - 150,
					dataDepend = 'data-depend';

				newCloneContainer.find(this.toggleTabHeadline).removeClass(this.isCurrent);
				newCloneContainer.find(this.toggleTabContent).removeClass(this.isCurrent).removeAttr('style');
				newCloneHeadline.removeClass(this.isCurrent);

				newCloneContainer.find('.mm-toggle-tab-content label').each(function () {
					$(this).attr('for', `${$(this).attr('for')}-${currentCount}`);
				});

				newCloneContainer.find(this.toggleTabContent).find('input, select, textarea').each(function () {
					$(this).removeClass('js-clear a-basic-input-text--clear hasDatepicker');
					$(this).attr('name', `${$(this).attr('name')}-${currentCount}`);
					$(this).attr('id', `${$(this).attr('id')}-${currentCount}`);
					$(this).siblings('.js-clear-button').remove();

					if(typeof $(this).attr(dataDepend) !== 'undefined') {
						$(this).attr(dataDepend, `${$(this).attr(dataDepend)}-${currentCount}`);
					}

					if ( $(this).is('input') ) {
						$(this).val('');
					}

				});

				this.$ctx.find(this.tabToggleHeadline).closest(this.selectorHeaderList).removeClass('is-hidden');

				newCloneContainer.find(this.toggleTabContent).find('.ll-depend').each(function () {
					$(this).attr(dataDepend, `${$(this).attr(dataDepend)}-${currentCount}`);
				});

				newCloneContainer.find('[data-t-id]').each(function (index, item) {
					$(item).removeAttr('data-t-id');
					$(document).trigger('addModules', $(item));
				});

				S.Utils.Lightbox.lightbox(this.$ctx);
				S.Utils.Form.init();

				this.$toggleBtn = this.$ctx.find(this.toggleTabHeadline);
				this.$toggleContent = this.$ctx.find(this.toggleTabContent);
				this.$tabBtn = this.$ctx.find(this.tabToggleHeadline);
				this.$tabContainer = this.$ctx.find(this.toggleTabContainer);
				this.$deleteBoxtrigger = this.$ctx.find('.mm-tab-close');
				this.$validateCheckbox = this.$ctx.find('.js-checkbox-validate');

				this.initTabToggleId();
				this.initTab();
				this.initToggle();
				this.deleteTabs();

				this._events.emit('BasicToggleTabBox.update');

				this.$tabBtn.last().trigger('click');

				$(window).scrollTop(scrollPos);
			});
		},

		deleteTabs()
		{
			let deleteId = 0;

			this.$deleteBoxtrigger.on('click', (e)=> {

				if($(e.target).closest('div').hasClass('mm-tab-toggle-headline')){
					deleteId = $(e.target).closest(this.tabToggleHeadline).data('js-delete-id');
				} else if ($(e.target).parents('mm-toggle-tab-container')) {
					deleteId = $(e.target).closest(this.toggleTabContainer).data('js-delete-id');
				}

				$('.mm-delete-index').text(deleteId);

				this.$deleteBtn.on('click', () => {

					this.initTabToggleId();

					this.$ctx.find('.mm-tab-toggle-headline[data-js-delete-id="1"]').addClass(this.isCurrent);
					this.$ctx.find('.mm-toggle-tab-container[data-js-delete-id="1"] .mm-toggle-tab-headline').addClass(this.isCurrent);

					if (S.Utils.Helper.mq('mobile').matches) {
						this.$ctx.find(this.deletedContainerToggleTabContent).slideDown(400).addClass(this.isCurrent);
					}
					else
					{
						this.$ctx.find(this.deletedContainerToggleTabContent).fadeIn(400).addClass(this.isCurrent);
					}

					this._events.emit('BasicToggleTabBox.update');

					if ( $.magnificPopup.instance.isOpen )
					{
						$.magnificPopup.close();
					}

					if(this.$ctx.find(this.tabToggleHeadlineOnlyChild).length){
						this.$ctx.find(this.tabToggleHeadlineOnlyChild).closest(this.selectorHeaderList).addClass('is-hidden');
						this.$ctx.find(`[data-js-delete-id="${deleteId}"]`).remove();
					}
					else {
						this.$ctx.find(`[data-js-delete-id="${deleteId}"]`).remove();
						this.$ctx.find('.mm-tab-toggle-headline:first-child').addClass(this.isCurrent);
						this.$ctx.find('.mm-toggle-tab-container:first-child').children().addClass(this.isCurrent);
					}
				});

			});
		}


	});
}(jQuery));
