(function ($)
{
    'use strict';
    /**
     * basicInfoLayer module implementation.
     *
     * @author Lars Meyer <l.meyer@edelweiss72.de>
     * @namespace T.Module
     * @class BasicInfoLayer
     * @extends T.Module
     */
    T.Module.BasicInfoLayer = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $body: null,

        /** @type {jQuery} */
        $infoIcon: null,

        /** @type {Array} */
        infoConditions: null,

        start: function (resolve)
        {

            const that = this;

            this.$ctx = $(this._ctx);
            this.$infoIcon = this.$ctx.find('.mm-infolayer-icon, .mm-infolayer-link');
            this.infoConditions = [];
            this.lightbox = '.l-lightbox';
			this.infolayerIconActive = '.mm-infolayer-icon.active';

            this.$body = $('body').eq(0);

            // set tabIndex and esc/enter event
            S.Globals.TabFocus.addPopup(this.$infoIcon, this);

            // add events to items
            this.$infoIcon.off('click.BasicInfoLayer').on('click.BasicInfoLayer', (e) =>
            {
                this.openLayer(e.target);
            });

            that._events.on('BasicInputLayer.inputChange', (condition, toggleState) =>
            {
                that.setConditions(condition, toggleState);
            });

            if (typeof this.$body.data('init-basicinfolayer') === 'undefined')
            {

                this.$body.data('init-basicinfolayer', true);

                this.$body.off('click.BasicInfoLayerOffsetClick').on('click.BasicInfoLayerOffsetClick', (e) =>
                {
                    if (!$(e.target).hasClass('mm-infolayer-icon') && !$(e.target).closest('.ll-row').find('label .mm-infolayer-icon').length && !$(e.target).closest(this.lightbox))
                    {
                        // close layers
                        that.closeLayer();
                    }
                });
            }

            resolve();
        },

        /**
         * This is triggered when the user press Enter on the element which where handed over via "S.Globals.TabFocus.addPopup(<element>, <instance>);"
         * in the (maybe start()) function.
         *
         * @param {jQuery} $target - currently tab focused el
         */
        _handleFocusEnter($target)
        {
            // only for info icons
            if ($target.is(this.$infoIcon))
            {
                const activeInfoIcons = document.querySelectorAll(this.infolayerIconActive);

                if (!activeInfoIcons.length)
                {
                    // the delay can be overwritten (no need for differentiation) because if the user keeps pressing enter
                    // this would lead to some issues - openLayer function is not designed for (there was no need)
                    S.Utils.delayed('enter-focus-popup', 100, () => {
                        this.openLayer(this.$infoIcon);
                    });
                }
            }
        },

        /**
         * This is triggered when the focus is tabbed to an element within
         * this module and the user presses the "ESC" key on this element.
         *
         * @param $target
         * @private
         */
        _handleFocusEsc()
        {
            // only for info layer
            const activeInfoIcons = document.querySelectorAll(this.infolayerIconActive);

            if (activeInfoIcons.length)
            {
                this.closeLayer();
            }
        },

        /**
         * handle conditions to toggle info-layers.
         *
         * @param condition - string with context-info
         * @param toggleState - boolean: element checked or not
         */
        setConditions: function (condition, toggleState)
        {

            const that = this;
            const index = that.infoConditions.indexOf(condition);

            // condition not found in array
            if (index === -1)
            {
                // input is checked, add condition
                if (toggleState)
                {
                    that.infoConditions.push(condition);
                }
            }

            // condition found in array
            else
            {
                // input is not checked, remove condition
                if (!toggleState)
                {
                    that.infoConditions.splice(index, 1);
                }
            }
        },

        /**
         * modify info-conditions for better layerhandling in specialcases
         *
         */
        modifyConditions: function ()
        {

            // Exception for VES-RS-Rechner:
            // single   +   privat60    =>   single60
            // familie  +   privat60    =>   familie60

            const that = this;

            const condition1 = 'single';
            const condition2 = 'familie';
            const condition3 = 'privat60';

            const specialcondition1 = 'single60';
            const specialcondition2 = 'familie60';

            const polishedArray = that.infoConditions;

            // 60+ found
            if (polishedArray.indexOf(condition3) !== -1)
            {

                // single found
                if (polishedArray.indexOf(condition1) !== -1)
                {
                    // remove familie60
                    if (polishedArray.indexOf(specialcondition2) !== -1)
                    {
                        polishedArray.splice(polishedArray.indexOf(specialcondition2), 1);
                    }
                    // add single60
                    polishedArray.push(specialcondition1);
                }

                // familie found
                else if (polishedArray.indexOf(condition2) !== -1)
                {

                    // remove single60
                    if (polishedArray.indexOf(specialcondition1) !== -1)
                    {
                        polishedArray.splice(polishedArray.indexOf(specialcondition1), 1);
                    }

                    // add familie60
                    polishedArray.push(specialcondition2);
                }
            }

            // 60+ not found
            else if (polishedArray.indexOf(condition3) === -1)
            {

                // remove familie60
                if (polishedArray.indexOf(specialcondition2) !== -1)
                {
                    polishedArray.splice(polishedArray.indexOf(specialcondition2), 1);
                }

                // remove single60
                if (polishedArray.indexOf(specialcondition1) !== -1)
                {
                    polishedArray.splice(polishedArray.indexOf(specialcondition1), 1);
                }
            }

            return (polishedArray);
        },

        /**
         * Get Info-Layer by current context
         *
         */
        getContentLayer: function ()
        {
            const that = this,
				infolayerContent = '.mm-infolayer-content';
            let layer = '';

            //that.$ctx.find(infolayerContent).addClass('mm-scrollpane');
            // find layer if there is more than one
            if (that.$ctx.find(infolayerContent).length > 1)
            {
				//old version
				if(typeof that.$ctx.data('has-conditions') === 'undefined') {
					const modifiedConditions = that.modifyConditions();
					that.$ctx.find(infolayerContent).each(function (index, element) {
						const layerCondition = $(element).attr('data-js-layer-condition');

						// find condition in array
						const listIndex = modifiedConditions.indexOf(layerCondition);

						if (listIndex !== -1) {
							layer = $(element);
						}

					});
				}
				else {
					that.$ctx.find(infolayerContent).each(function (index, element) {
						const layerCondition = $(element).attr('data-js-layer-condition');
						const inputConds = $(`input[data-infolayer-content="${layerCondition}"]`);

						inputConds.each(function (index, el) {
							if($(el).prop('checked')) {
								layer = $(element);
							}
						});
					});
				}
            }

            // if length is < 1, find the default layer
            else
            {
                layer = that.$ctx.find('.mm-infolayer-content[data-js-layer-condition=default]');
            }

            // fallback if no default layer exists or something went wrong -> take the first one
            if (!layer.length)
            {
                layer = that.$ctx.find(infolayerContent).eq(0);
            }

            return (layer);
        },

        /**
         *
         * close all layers
         */
        closeLayer: function ()
        {
            const activeInfoIcons = document.querySelectorAll(this.infolayerIconActive);
            $(activeInfoIcons).removeClass('active');
			$(activeInfoIcons).parent().removeClass('active');
			$(activeInfoIcons).attr('aria-expanded', 'false');

            $('.a-layout-darkness').eq(0).removeClass('a-layout-darkness--info-icon');
            S.Utils.LayerBgDark.hideLayer();

            $(this.lightbox).remove();
        },

        /**
         *
         * open layer and append it to main-wrapper
         *
         */
        openLayer: function (item)
        {

            const that = this,
				lightboxInner = '.ll-lightbox-inner';

            that.closeLayer();

            if (that.$ctx.data('layer-type') === 'outside' && that.$ctx.data('layer-id'))
            {
                $(`div[data-layer-content-id="${  that.$ctx.data('layer-id')  }"]`).removeClass('is-hidden');
            }
            else
            {
                // do the default stuff
                // close others (alert / error)
                const visibleLayers = document.querySelectorAll('.mm-infolayer-content.visible');
				this.$infoIcon.attr('aria-expanded', 'true');

                if (visibleLayers.length)
                {
                    $(visibleLayers).removeClass('visible');
                }

                // get layer
                const layer = that.getContentLayer();

                // define lightbox elements
                const lightBoxContent = $('<div class="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-zoom-in l-lightbox mfp-ready" tabindex="-1" style="overflow-x: hidden; overflow-y: auto;"><div class="mfp-container mfp-s-ready mfp-inline-holder"><div class="mfp-content"><div class="ll-lightbox-inner mfp-with-anim"><button aria-label="Schaltfläche Detailinformationen schließen" class="mfp-close"></button></div></div><div class="mfp-preloader">Loading...</div></div></div>');

                // inject layer as clone to keep it in (DOM-)place
                $(lightBoxContent).find(lightboxInner).append(layer.clone());

                setTimeout(() =>
                {
                    $(lightBoxContent).find('.m-basic-table-toggle').removeAttr('data-t-id');
                    $(lightBoxContent).find('.m-basic-toggle').removeAttr('data-t-id');
                    $(lightBoxContent).find(lightboxInner).trigger('addModules', $(lightBoxContent).find('.m-basic-table-toggle'));
                    $(lightBoxContent).find(lightboxInner).trigger('addModules', $(lightBoxContent).find('.m-basic-toggle'));
                }, 1000);

                this.$body.prepend(lightBoxContent);

                // make this focusable and keep the tabFocus inside this container
                S.Globals.TabFocus.setFocusToContext(lightBoxContent, this, lightBoxContent.find('.mfp-close'));

                // set icon to active
                $(item).addClass('active');
				$(item).parent().addClass('active');

                $('.a-layout-darkness').eq(0).addClass('a-layout-darkness--info-icon');
                S.Utils.LayerBgDark.showLayer();

                if (that.$ctx.closest('.m-basic-calculator-content-box'))
                {
                    $('.mfp-wrap').addClass('is-small');
                }

                $(lightBoxContent).find('.mfp-close').on('click', () =>
                {
                    that.closeLayer();

                    // cant be in closeLayer function, because closeLayer gets called EVERY time openLayer gets called
                    S.Globals.TabFocus.focusLastElBeforePopupOpen();
                });

                this.initScrollPane();

                this.$body.off('keyup.BasicInfoLayerOffsetClick').on('keyup.BasicInfoLayerOffsetClick', (e) =>
                {
                    if (e.key === 'Escape')
                    {
                        that.closeLayer();

                        // cant be in close layer, because closeLayer gets called EVERY time openLayer gets called
                        S.Globals.TabFocus.focusLastElBeforePopupOpen();
                    }
                });

                S.Utils.delayed('closeAllLayers', 200, () =>
                {
                    $(this.lightbox).on('click', (e) =>
                    {
                        const item = document.querySelector(lightboxInner);
                        // if clicked outside
                        if (!$(item).is(e.target) && !$(item).find('*').is(e.target))
                        {
                            // close layers
                            that.closeLayer();

                            // cant be in close layer, because closeLayer gets called EVERY time openLayer gets called
                            S.Globals.TabFocus.focusLastElBeforePopupOpen();
                        }
                    });

                    $('body').on('click.infolayerclose', (el) =>
                    {
                        if ($(el.target).closest(this.lightbox).length < 1 && $(el.target).closest('.m-basic-info-layer').length < 1 && $(el.target).closest('.m-basic-info-layer-tab').length < 1)
                        {
                            that.closeLayer();
                            $('body').off('click.infolayerclose');

                            // cant be in close layer, because closeLayer gets called EVERY time openLayer gets called
                            S.Globals.TabFocus.focusLastElBeforePopupOpen();
                        }
                    });
                });
            }
        },
        /** init scrollpane  */
        initScrollPane: function ()
        {
			const scrollpaneContainer = $('.l-lightbox .ll-lightbox-inner'),
				infolayerContentScrollpane = '.mm-infolayer-content.mm-scrollpane';
            let height = 0;

			scrollpaneContainer.find(infolayerContentScrollpane).children().each(function ()
            {
                height = height + $(this).outerHeight();
            });

            if (height > scrollpaneContainer.find(infolayerContentScrollpane).outerHeight())
            {
				scrollpaneContainer.find(infolayerContentScrollpane).jScrollPane(
                    {
                        showArrows: false,
                        autoReinitialise: false,
                        height: 600
                    });
            }
        },
    });
}(jQuery));
