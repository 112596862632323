'use strict';
// https://stackoverflow.com/questions/72720387/cant-find-variable-autofillcallbackhandler
// ignore log entries like
// FATAL {"msg":"Exception!","errorMsg":"ReferenceError: Can\u0027t find variable: _AutofillCallbackHandler","url":"...","line number":1,"column":25} | SessionId-RequestId: ... | PageUrl: ... | UserAgent: Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 [FBAN/FBIOS;FBAV/468.0.0.39.104;FBBV/609862394;FBDV/iPhone13,1;FBMD/iPhone;FBSN/iOS;FBSV/17.5.1;FBSS/3;FBID/phone;FBLC/de_DE;FBOP/5;FBRV/612925389]
// Exception: JSNLog.Exceptions.ClientException
// Message: Can't find variable: _AutofillCallbackHandler

(function () {

	window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () { };

})();
