(function ($) {
	'use strict';

	S.Calculator = S.Calculator || {};

	S.Calculator.FineForeignCountries = {

		/** @type {Object} */
		offenseFilter: [],

		/** @type {String} */
		filterId: "",

		/**
		 * init form actions
		 *
		 */
		init: function init() {

			this.offenseFilter = $('.l-form--offense').find('input[name="offense"]');

			$('.ll-main-triangle').closest('.l-main-content').addClass('l-main-content--searchresult');

			// set country filter
			this.setCountryFilter();

			// set offence filter
			this.setOffenseFilter();
		},

		/**
		 * setCountryFilter
		 *
		 */
		setCountryFilter: function setCountryFilter() {
			$('#finecalculatorCountries').on('change', function () {
				const countryBox = $(this),
					country = $(this).val();

				$('.l-main-content--searchresult .m-basic-toggle .js-head').each(function (index, item) {
					// reset
					$(item).removeClass('mm-opened js-reset');

					if ($(item).find('> p > strong').text() === country) {
						$(item).show().addClass('js-reset');

						// set country to headline
						$('.js-country').html(`: ${country}`);
					} else if (countryBox.children().eq(0).is(':selected')) {
						if (index === 0) {
							$(item).show().trigger('click').next().show();
						} else {
							$(item).show().next().hide();
						}

						// clean headline
						$('.js-country').html('');
					} else {
						$(item).hide().next().hide();
					}
				});
			});
		},

		/**
		 * setOffenseFilter
		 *
		 */
		setOffenseFilter: function setOffenseFilter() {
			const that = this;

			this.offenseFilter.on('click', function () {
				that.filterId = $(this).siblings('div').children().html();

				$('.l-main-content--searchresult .js-basic-table-wrap:first-child .m-basic-table > tbody > tr').each(function (index, item) {
					if ($(item).text().indexOf(that.filterId) > -1 || that.filterId === that.offenseFilter.eq(0).siblings('div').children().html()) {
						$(item).show();
					} else {
						$(item).hide();
					}
				});

				that.checkRowNumber();
			});

			this.offenseFilter.each(function (index, item) {
				if ($(item).is(':checked')) {
					setTimeout(function () {
						$(item).trigger('click').blur();
					}, 50);
				}
			});
		},

		/**
		 * checkRowNumber
		 *
		 */
		checkRowNumber: function checkRowNumber() {
			$('.l-main-content--searchresult .m-basic-table').each(function (index, table) {

				// standard
				if ($(table).find('> tbody > tr:visible, .mm-toggletext-content > table > tbody > tr:visible').length <= 3) {
					$(table).addClass('m-basic-table--white');
				} else {
					$(table).removeClass('m-basic-table--white');
				}
			});
		}

	};
	$(document).ready(function () {
		if ($('.l-form--country-selection').length) {
			S.Calculator.FineForeignCountries.init();
		}
	});

})(jQuery);
