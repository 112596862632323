(function ($)
{
    'use strict';
    /**
     * Award decorator implementation for the basicbanderole component.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class Award
     * @extends T.Module
     */
    T.Module.BasicBanderole.Award = T.createDecorator({

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);

            // init lightbox
            // S.Utils.Lightbox.init( 'js-lightbox', that.$ctx );

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            S.Utils.SwiperHelper.addLazyClassToImg(this.$ctx.find('.swiper-slide'), true);

            // spaceBetween old value 50px was never be used (20px was set fix in this initSwiper)
            this._parent.start(resolve, {spaceBetween: 20});
        },

        /**
         * init swipe
         *
         * lazyLoading:
         * this decorator inherits the "isLazy = true" from the parent. When this module gets in view
         * the _lazyUpdate function is triggered and executed. The function also inherits from his parent (this decorator).
         * So if you need to overwrite the initialisation on init/comesIntoView - write a separat _lazyUpdate function here
         *
         * If you dont want this module to be lazy loaded and immediately called, just set "isLazy = false" or at the beginning of this object as variable declaration "isLazy: fasle"
         * in this decorator. If the "is-lazy" class is given remove this also.
         *
         * This will overwrite the parent isLazy variable.
         */
        initSwiper: function ()
        {
            // this.$swiperSlides comes from _parent/orig file
            const slidesPerViewDesktop = this.$swiperSlides.length > 2 ? 3 : this.$swiperSlides.length,
                slidesPerViewTablet = this.$swiperSlides.length > 1 ? 2 : 1,
                slidesPerViewMobile = 1,
                lazy = {
                    checkInView: true,
                    loadPrevNextAmount: 2,
                    loadPrevNext: true,
                    loadOnTransitionStart: true,
                };

            // init swiper conatiner
            let swiperOptions = {
                spaceBetween: this.spaceBetween,
                watchSlidesProgress: true,
                preloadImages: false,
				a11y: false,
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    prevEl: this.$swiperBtnLeft[0],
                    nextEl: this.$swiperBtnRight[0],
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: slidesPerViewMobile,
                        speed: 500,
                    },
                    767: {
                        slidesPerView: slidesPerViewTablet,
                        speed: 500,
                    },
                    1024: {
                        slidesPerView: slidesPerViewDesktop,
                        speed: 1500,
                    },
                },
                on: {
                    init: (swiper) =>
                    {
                        // enables/disables the swiper
                        // - In the old logic, the slides were not "centred" / logic wasnt given
                        S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                    },
                    resize: (swiper) =>
                    {
                        S.Utils.delayed(`basicbanderole-check-award-${this.$ctxTID}`, 100, () =>
                        {
                            // enables/disables the swiper
                            // - In the old logic, the slides were not "centred" / logic wasnt given
                            S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                        });
                    },
                    /**
                     * @param {Object} swiper
                     * @param {HTMLElement} slideEl
                     * @param {HTMLElement} imageEl :: if needed
                     */
                    lazyImageLoad: (swiper, slideEl) =>
                    {
                        const $slide = $(slideEl);

                        // kept the same old logic from loadVisibleImages() Function
                        $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                        $slide.find('.js-init').removeClass('js-init');
                    },
                }
            };

            // for develop testing purpose only - disable/enable lazy loading via param
            if (!S.Utils.GetUrlParameter.checkForUrlParameter('swiperlazy', 'false'))
            {
                swiperOptions = {...swiperOptions, lazy};
            }

            new Swiper(this.$swiperContainer[0], swiperOptions);
        },
    });
}(jQuery));
