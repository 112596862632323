'use strict';
(function ($) {
	/**
	 * MapboxSearchPoiResults module implementation.
	 *
	 * @author Tri Nguyen <tri.nguyen@namics.com>, Dmitri Zoubkov <dmitri.zoubkov@mtc.berlin>
	 * @memberof T.Module
	 * @class MapboxSearchPoiResults
	 * @extends T.Module
	 */
	T.Module.MapboxSearchPoiResults = T.createModule({
		config: null,
		categories: null,
		currentItem: null,
		items: null,
		map: null,

		start: function start(resolve) {
			this._initApplication();
			this._initEvents();
			this._readConfig();
			resolve();
			this.getData();
		},
		_initApplication: function _initApplication() {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
		},
		_initEvents: function _initEvents() {
			this._events.on("BasicKeywordfilter.filterChange", () => {
				this._setFirstItemPoiIconActive();
				this._showFirstItemOnMap();
			});

			// For mobile
			this.$ctx.find('.js-switch').on("click", this._toggleMaps.bind(this));
			this.$ctx.find('.js-switch[data-switch="map"]').on('click', () => {
				this._showCurrentItemOnMap();
			});
		},
		_getCategoryFilterValue: function _getCategoryFilterValue(category) {
			return `category-${this.categories.indexOf(category)}`;
		},
		_getConfig: function _getConfig() {
			return this.$ctx.data('config');
		},
		_getConfigBaseImageUrl: function _getConfigBaseImageUrl() {
			return this.config.baseImageUrl;
		},
		_getConfigBaseMapUrl: function _getConfigBaseMapUrl() {
			return this.config.baseMapUrl;
		},
		_getConfigDefaultImagePath: function _getConfigDefaultImagePath() {
			return this.config.defaultImagePath;
		},
		_getConfigEndpoint: function _getConfigEndpoint() {
			return null;
		},
		_getConfigMapZoom: function _getConfigMapZoom() {
			return (this.config.Map || {}).Zoom || 12;
		},
		_getConfigMapStyle: function _getConfigMapStyle() {
			return (this.config.Map || {}).Style;
		},
		_getConfigRequestParams: function _getConfigRequestParams() {
			return this.config.Poi;
		},
		_getCurrentTooltip: function _getCurrentTooltip() {
			return this._getItemTooltip(this.currentItem);
		},
		getData: function getData() {
			this._showError(false);
			T.Utils.View.startLoader();
			const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._getConfigEndpoint());
			const options = {
				type: "GET",
				data: this._getConfigRequestParams(),
				url: apiUrl,
				headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
			};
			T.Utils.Ajax.fragment(options,
				this._handleGetDataSuccess.bind(this),
				this._errorApilNotAvailableCallback.bind(this));
		},
		// eslint-disable-next-line no-unused-vars
		_getItemCategory: function _getItemCategory(item) {
			return null;
		},
		// eslint-disable-next-line no-unused-vars
		_getItemCountry: function _getItemCountry(item) {
			return null;
		},
		_getItemDistanceInKm: function _getItemDistanceInKm(item) {
			return item.EntfernungInKm;
		},
		_getItemId: function _getItemId(item) {
			return item.Id;
		},
		// eslint-disable-next-line no-unused-vars
		_getItemLocation: function _getItemLocation(item) {
			return null;
		},
		// eslint-disable-next-line no-unused-vars
		_getItemName: function _getItemName(item) {
			return null;
		},
		_getItemPointX: function _getItemPointX(item) {
			return item.Point.X;
		},
		_getItemPointY: function _getItemPointY(item) {
			return item.Point.Y;
		},
		_getItemMapLocation: function _getItemMapLocation(item) {
			return item && item.Point && item.Point.X && item.Point.Y ? this._mercatorToLocation(item.Point) : null;
		},
		// eslint-disable-next-line no-unused-vars
		_getItemPostCode: function _getItemPostCode(item) {
			return null;
		},
		// eslint-disable-next-line no-unused-vars
		_getItemStreet: function _getItemStreet(item) {
			return null;
		},
		// eslint-disable-next-line no-unused-vars
		_getItemTooltip: function _getItemTooltip(item) {
			return null;
		},
		_handleGetDataSuccess: function _handleGetDataSuccess(data) {
			if (data && data.Success && data.Data && data.Data.length > 0) {
				this.items = data.Data;
				this._loadCategories();
				this._loadItems();
			}
			T.Utils.View.stopLoader();
			this._handleGetDataSuccessOnCompleted();
		},
		_handleGetDataSuccessOnCompleted: function _handleGetDataSuccessOnCompleted() {
			// Decorator can use this function to post-process the data, for example show hint if no result
		},
		// eslint-disable-next-line no-unused-vars
		_hasCategory: function _hasCategory(item) {
			return false;
		},
		_isMapComponentVisible: function _isMapComponentVisible() {
			return !T.Utils.Helper.isSmartphone() || this.$ctx.find('.js-switch[data-switch="map"]').hasClass("is-active");
		},
		// eslint-disable-next-line no-unused-vars
		_isDistanceVisible: function _isDistanceVisible(item) {
			const poi = this._getConfigRequestParams();
			if (poi && ((poi.position))) {
				const position = poi.position;
				return (position.x && position.y) || (position.Longitude && position.Latitude);
			}
			else {
				return false;
			}

		},
		_hideMap: function _hideMap() {
			this.$mapWrapper.toggleClass('h-hidden', true);
		},
		_errorApilNotAvailableCallback: function _errorApilNotAvailableCallback() {
			T.Utils.View.stopLoader();
			if (!T.Utils.Helper.isExperienceEditor()) {
				this._showError(true);
			}
		},

		_showError: function _showError(show) {
			this.$ctx.find(".ll-error-msg").toggleClass('h-hidden', !show);
			this.$ctx.find(".js-basicKeywordFilter").toggleClass('h-hidden', show);
			this.$ctx.find(".js-basickeywordfilter-result").toggleClass('h-hidden', show);
		},

		_onItemClicked: function _onItemClicked(e) {
			let $item = $(e.currentTarget);
			const itemId = $item.data("id");
			const isInList = $item.parent().hasClass('mm-result-list');
			if (!isInList) {
				$item = this.$ctx.find(`.js-result-list [data-id="${itemId}"]`);
			}
			this._setPoiIconActive($item);
			this.currentItem = null;
			for (let index = 0; index < this.items.length; index++) {
				// eslint-disable-next-line eqeqeq
				if (this._getItemId(this.items[index]) == itemId) {  //NOSONAR, DO NOT fix "==" here
					this.currentItem = this.items[index];
					break;
				}
			}
			if (this.currentItem && this._isMapComponentVisible()) {
				this._showCurrentItemOnMap();
			}
			if (!isInList) {
				T.Utils.View.scrollToElement($item);
			}
		},

		_loadCategories: function _loadCategories() {

			const distinct = (value, index, self) => {
				return self.indexOf(value) === index;
			};

			this.categories = this.items.map((item) => {
				return this._getItemCategory(item);
			}).filter((category) => {
				return (category !== "");
			}).filter(distinct);

			for (let index = 0; index < this.categories.length; index++) {
				const newItem = this.$categoryTemplate.clone();

				const filter = this._getCategoryFilterValue(this.categories[index]);
				T.Utils.View.setDataSafe(newItem.find('.js-category-container'), 'filter', filter);

				newItem.find(".js-category-input").attr("id", filter).attr("name", filter);
				newItem.find(".js-category-label-1").attr("for", filter);
				newItem.find(".js-category-label-2").attr("for", filter).text(this.categories[index]);
				this.$ctx.find('.js-categories').append(newItem);
			}

			this.$basicKeywordFilter.attr('data-t-name', this.$basicKeywordFilter.data('tx-name'));
			this.$basicKeywordFilter.removeAttr('data-tx-name');
			T.Utils.Helper.initAdditionalModules(this.$basicKeywordFilter);
		},
		_loadItems: function _loadItems() {
			this._loadItemsPaging(0, this.items.length);
		},
		_loadItemsPaging: function _loadItemsPaging(startIndex, endIndex) {
			const itemsInPage = this.items.slice(startIndex, endIndex);
			for (let index = 0; index < itemsInPage.length; index++) {
				const item = itemsInPage[index];
				const newItem = this.$itemTemplate.clone();
				newItem.on('click', this._onItemClicked.bind(this));

				this._setFilter(newItem, item);
				this._setImage(newItem, item);
				this._setText(newItem, item);
				this._setMapUrl(newItem, item);
				this._setDetailUrl(newItem, item);
				this.$ctx.find('.mm-result-list').append(newItem);

			}

			if (this.items && this.items.length && startIndex === 0) {
				this._setFirstItemPoiIconActive();
				this.currentItem = this.items[0];
				if (this._isMapComponentVisible()) {
					this._showCurrentItemOnMap();
				}

			}
		},
		_readConfig: function _readConfig() {
			this.$itemTemplate = $(this.$ctx.find("#js-item-template").html());
			this.$categoryTemplate = $(this.$ctx.find("#js-category-template").html());
			this.$basicKeywordFilter = $(this.$ctx.find(".js-basicKeywordFilter"));
			this.$iconActivePosition = this.$ctx.find("#mapsIconActivePoi");
			this.$iconInActivePosition = this.$ctx.find("#mapsIconInActivePoi");
			this.$mapWrapper = this.$ctx.find(".mm-map-wrapper");
			this.config = this._getConfig();
		},
		_setDetailUrl: function _setDetailUrl() {

		},
		_setFilter: function _setFilter($element, item) {
			let filter = { filter: "nocategory" };
			if (this._hasCategory(item)) {
				const category = this._getItemCategory(item);
				filter = { filter: this._getCategoryFilterValue(category) };
			}
			T.Utils.View.setDataSafe($element, 'filter', filter);
		},
		_setImage: function _setImage($element, item) {
			const imageList = item.ListOfBildVersionen;
			if (!imageList) {
				return;
			}
			let imagePath = '';

			if (imageList.length > 0) {
				imagePath = T.Utils.Helper.appendURIPath(
					this._getConfigBaseImageUrl(), imageList[0].ProdPfad);
				imagePath = T.Utils.Helper.appendURIPath(imagePath, imageList[0].BildDatName);
			} else {
				imagePath = this._getConfigDefaultImagePath();
			}
			const $img = $element.find('img');

			$img.attr('src', imagePath);
			$img.attr('alt', item.Hotelname);
			$img.attr('title', item.Hotelname);
		},
		_setText: function _setText($element, item) {
			T.Utils.View.setDataSafe($element, 'id', this._getItemId(item));
			$element.find(".js-attribute").text(this._getItemCategory(item));
			$element.find(".js-name").text(this._getItemName(item));
			if (this._isDistanceVisible(item)) {
				$element.find(".js-distance").text(this._getItemDistanceInKm(item));
			}
			else {
				$element.find(".js-distance-container").toggleClass('h-hidden', true);
			}
			$element.find(".js-street").text(this._getItemStreet(item));
			$element.find(".js-postcode").text(this._getItemPostCode(item));
			$element.find(".js-position").text(this._getItemLocation(item));

		},
		_showCurrentItemOnMap: function _showCurrentItemOnMap() {
			try {
				const loc = this._getItemMapLocation(this.currentItem);
				if (this.currentItem && loc) {
					this._createMapIfNeeded(loc, this._updateMap.bind(this));
				}
			} catch (e) {
				T.Utils.Logger.log("Failed to initialize map", e, window.location.href);
				this._hideMap();
			}
		},
		_createMapIfNeeded: function _createMapIfNeeded(center, whenReady) {
			if (!this.map) {
				this._loadingMap = true;
				this.map = T.Utils.Mapbox.renderMap(this._getConfigMapStyle(), center, {
					apimKey: T.Utils.Application.getApiMSubscriptionKey()
				}, this._getConfigMapZoom());
				if (this.map) {
					this.map.on("load", this._hanldeMapLoad.bind(this, whenReady));
				}
			} else if ('function' === typeof whenReady) {
				if (this._loadingMap) {
					// when filter is set on page load and wants to select a location, map is not yet ready
					const mapsCheck = setInterval(() => {
						if (!this._loadingMap) {
							clearInterval(mapsCheck);
							whenReady();
						}
					}, 300);
				}
				else {
					whenReady();
				}
			}
		},
		_hanldeMapLoad: function _hanldeMapLoad(whenReady) {
			delete this._loadingMap;
			this._adjustMapControls();
			if ('function' === typeof whenReady) {
				whenReady(true);
			}
		},
		_adjustMapControls: function _adjustMapControls() {
			T.Utils.Mapbox.showNavigationControl(this.map);
		},
		_mercatorToLocation: function _mercatorToLocation(point) {
			return T.Utils.Geo.coordToLocation(T.Utils.Geo.mercatorToLonLat({ x: point.X, y: point.Y }));
		},
		_showFirstItemOnMap: function _showFirstItemOnMap() {
			const $firstItem = this._getVisibleResults().first();

			if ($firstItem.length) {
				// eslint-disable-next-line eqeqeq
				const firstItem = this.items.find((item) => this._getItemId(item) == $firstItem.data("id")); //NOSONAR, DO NOT fix "==" here
				if (firstItem) {
					this.currentItem = firstItem;
					if (this._isMapComponentVisible()) {
						this._showCurrentItemOnMap();
					}
				}
			}
		},
		_updateMap: function _updateMap(initialLoad, location) {
			if (!initialLoad) {
				T.Utils.Mapbox.removeAllPoints(this.map);
			}
			this._updateOtherMapLocations();
			const loc = location || this._getItemMapLocation(this.currentItem);
			this.currentItem.mapsReference = T.Utils.Mapbox.createPoint(this.map, loc, this._getCurrentTooltip() || null, this.$iconActivePosition);
			if (!initialLoad) {
				T.Utils.Mapbox.setCenter(this.map, loc, !T.Utils.Helper.isSmartphone() && !T.Utils.Adaptive.isSlowNetwork());
			}
		},
		_updateOtherMapLocations: function _updateOtherMapLocations() {
			// override
		},
		_setFirstItemPoiIconActive: function _setFirstItemPoiIconActive() {
			const $visibleItems = this._getVisibleResults();
			if ($visibleItems.length) {
				this._setPoiIconActive($visibleItems.first());
			}
		},
		_setPoiIconActive: function _setPoiIconActive($el) {
			if (this.$currentPointActive) {
				this.$currentPointActive.removeClass("is-active");
			}

			this.$currentPointActive = $el.find(".js-icon");
			this.$currentPointActive.addClass("is-active");
		},
		_setMapUrl: function _setMapUrl($element, item) {
			const mapUrl = T.Utils.Map.createRouteUrl(this._getConfigBaseMapUrl(), {
				postCode: this._getItemPostCode(item),
				location: this._getItemLocation(item),
				street: this._getItemStreet(item),
				x: this._getItemPointX(item),
				y: this._getItemPointY(item)
			});
			$element.find('.js-map-link').attr('href', mapUrl);
		},
		_toggleMaps: function _toggleMaps(e) {
			e.preventDefault();
			const $currentClickedElement = $(e.currentTarget);
			const $wrapper = $currentClickedElement.closest(".m-adac-maps");
			let $showElement = $wrapper.find('.mm-result-list-wrap'),
				$hideElement = this.$mapWrapper;

			$currentClickedElement.addClass('is-active');
			$currentClickedElement.siblings('.js-switch').removeClass("is-active");

			if ($currentClickedElement.data("switch") === "map") {
				$showElement = this.$mapWrapper;
				$hideElement = $wrapper.find('.mm-result-list-wrap');
			}

			$showElement.addClass("is-active");
			$hideElement.removeClass("is-active");
		},
		_getVisibleResults: function _getVisibleResults(dataId) {
			const dataAttr = dataId ? `[data-id="${dataId}"]` : '';
			return this.$ctx.find(`.js-result-list li${dataAttr}:visible`);
		}
	});
}(jQuery));