'use strict';
(function ($) {

	/**
	 * LoginBtn module implementation.
	 * simple call the login page
	 *
	 * @author Markus Hiller <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class EnsureUserAuth
	 * @extends T.Module
	 */
	T.Module.LoginBtn = T.createModule({

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start(resolve) {
			this.$ctx = $(this._ctx);

			this.$ctx.on('click', this._doLoginCall.bind(this));

			resolve();
		},

		_doLoginCall: function _doLoginCall(e) {
			e.preventDefault();
			T.Utils.Auth.authorize();
		}

	});
}(jQuery));

