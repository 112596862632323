(function($) {
	'use strict';
	/**
	 * BasicHeader module implementation.
	 *
	 * @author Lars Meyer <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicHeader
	 * @extends T.Module
	 */
    T.Module.BasicHeader = T.createModule( {

        /** @type {jQuery} */
        $ctx: null,

        /**  @type {jQuery} */
        $body: null,

        /**  @type {jQuery} */
        $mainWrap: null,

        /**  @type {jQuery} */
        $menuBtnWrap: null,

        /** @type {jQuery} */
        $menuBtns: null,

        /** @type {jQuery} */
        $navBurgerBtn: null,

        /** @type {jQuery} */
        $navWrapper: null,

        /** @type {jQuery} */
        $navLinkElems: null,

        /** @type {jQuery} */
        $navLinkButtons: null,

        /** @type {jQuery} */
        $navListLevel1: null,

        /** @type {jQuery} */
        $backBtns: null,

        /** @type {jQuery} */
        $searchBar: null,

        /** @type {jQuery} */
        $searchBarClose: null,

        /** @type {jQuery} */
        $layerWrappers: null,

        /** @type {jQuery} */
        $layerClose: null,

        /** @type {Number} // 300ms + 10ms as buffer */
        cssAnimDelay: 310,

        /** @type {jQuery} */
        $darkLayer: null,

        /** @type {Number} */
        lastScrollY: null,

        /** @type {jQuery} */
        $headerClone: null,

        /** @type {Number} // just a reasonable value without matching css-prop or smth */
        unstickThreshold: -50,

        /** @type {jQuery} */
        $breadcrumbWrap: null,

        /** @type {Boolean} */
        breadCrumbMode: false,

        /** @type {Boolean} */
        darkLayerCoolDown: false,

        /** @type {Boolean} */
        reducedHead: false,

        /** @type {jQuery} */
        topMenu: null,

        /** @type {jQuery} */
        listMenuWrapper: null,

		/** @type {Boolean} */
		disableScrollEvents: false,

		/** @type {String} */
		burgerBtnAriaLabelDefault: '',

		/** @type {String} */
		burgerBtnAriaLabelActive: '',

		/** @type {String} */
		attributeAriaLabel: 'aria-label',

		/** @type {String} */
		attributeAriaExpanded: 'aria-expanded',

		classNoScroll: 'is-noscroll',
		classPaddingTop: 'padding-top',
		eventsBasicHeaderIsStuck: 'basicHeader.isStuck',
		eventsBasicHeaderNotStuck: 'basicHeader.isNotStuck',
		classBtnSubmit:'.mm-btn-submit',
		classIsVisible:'is-visible',
		classHeaderShifted: 'is-header-shifted',
        visbileActiveClass: 'is-visible is-active',
		listWrapperClass: '.mm-list-wrapper',
		classIsScrollable: 'is-scrollable',

        start: function ( resolve )
        {
            const that = this;

            this.$ctx = $( this._ctx );

            that.$body = $(document.querySelector('body'));
            that.$mainWrap = $(document.querySelector('body > .l-outer > .l-main'));

            // nav layer
            that.$navWrapper = that.$ctx.find('.mm-navi-wrap > .mm-nav');
            that.$navListLevel1 = that.$navWrapper.find('> .mm-list-wrapper > .mm-nav-list');
			that.$navListLevel1Links = that.$navListLevel1.find('> a');
            that.$navLinkElems = that.$navWrapper.find('a');
			that.$navLinkButtons = that.$navLinkElems.filter('[area-expanded], [role=button]');
            that.$backBtns = that.$navWrapper.find('.mm-back');

            // btns // menu / burger
            that.$menuBtnWrap = that.$ctx.find('.mm-navi-wrap > .mm-main');
            that.$menuBtns =  that.$menuBtnWrap.find('.mm-main-btn > .mm-section > .mm-btn');
            that.$navBurgerBtn = that.$menuBtnWrap.find('.mm-main-nav > .mm-menu');

            // search
            that.$searchBar = that.$ctx.find('.mm-navi-wrap > .mm-search');
            that.$searchBarClose = that.$searchBar.find('.mm-close');

            // layers
            that.$layerWrappers = that.$menuBtns.next('.mm-layer');
            that.$layerClose = that.$layerWrappers.find('.mm-close');

            // other // bg layer
            that.$darkLayer = that.$ctx.prev('.mm-header-dark-layer');
            that.$headerClone = that.$ctx.next('.is-clone');
            that.topMenu = that.$ctx.find('.mm-top-topics');
            that.listMenuWrapper = that.$ctx.find('.mm-nav > .mm-list-wrapper');

            // strings
			this.isShifted = 'is-shifted';
			this.suggestionVisible = 'js-suggestion-visible';
			this.isNoAnim = 'is-no-anim';
			this.eventHeaderEnableOffsetClick = 'basicHeader.enableOffsetClick';

			that.lastScrollY = $(window).scrollTop();

			this.$ctx.on('keyup.escape', (el) => {
				// close enlarged with escape key
				if (el.which === 27 && this.$navBurgerBtn.hasClass('is-open')) {
					this.$navBurgerBtn.trigger('click');
				}
			});

            if ( that.$ctx.siblings('.m-basic-breadcrumb').length ) {
                that.breadCrumbMode = true;
                that.$breadcrumbWrap = that.$ctx.siblings('.m-basic-breadcrumb');
                that.$ctx.addClass('has-breadcrumb');
            }

            if (that.$ctx.hasClass('m-basic-header--reduced')) {
                that.reducedHead = true;
                that.unstickThreshold = 0;
            }

			if(!S.Utils.Helper.mq('tablet').matches) {
				this.$ctx.find(this.classBtnSubmit).prop('disabled', false);
				this.$ctx.find('.mm-search-cancel').prop('disabled', false);
			}

			// get burger menu btn aria-labels
			if (this.$navBurgerBtn.attr(this.attributeAriaLabel)) {
				this.burgerBtnAriaLabelDefault = this.$navBurgerBtn.attr(this.attributeAriaLabel);
			}
			if (this.$navBurgerBtn.attr('data-aria-label-active')) {
				this.burgerBtnAriaLabelActive = this.$navBurgerBtn.attr('data-aria-label-active');
			}

            this.initEvents();
            this.initAccessibilityFlyout();

            resolve();
        },

        /**
         * init events and click logics for the dark layer, a-tags, layers etc.
         *
         * adds transitions
         */
        initEvents ()
        {
            const that = this;

            // ***
            // toggle visibility for the dark layer
            // ***
            this.$darkLayer
                .on( 'transitionend', () => {
                    S.Utils.delayed('basicHeader.transitionBufferDarkLayer', 10, () => {
                        if (!this.$darkLayer.hasClass('is-active')) {
                            this.$darkLayer.removeClass(this.classIsVisible);
                        }
                    });
                });

            // ***
            // kills href call if role button is set for toggle elems in 'mobile'
            // ***
            this.$navLinkButtons.on('click', (e) => {
                if ( !S.Utils.Helper.mq( 'desktop-l' ).matches ) {
                    e.preventDefault();
                }
            });

            // ***
            // layer events
            // ***
            this.$menuBtns.off('click.basicHeaderLayerOpen').on('click.basicHeaderLayerOpen', function ()  {
                // layer open
                that.openLayer(this);
            });

            this.$layerClose.off('click.basicHeaderLayerClose').on('click.basicHeaderLayerClose',  function ()  {
                // layer close
                that.closeLayer('single', $(this).closest('.mm-layer') );
            });

            // ***
            // add other events
            // ***
			if ( S.Utils.Helper.mq( 'desktop-l' ).matches ) {

				if (!this.reducedHead) {
					this.checkStickyHeightsDesk('init');
				}

				else {
					this.forceSticky();
				}
			}

			else {
				this.checkStickyHeightsMob('init');
			}

			// ***
			// listen to other components
			// ***

			// -> basictable is reinserted into the document flow
			// -> triggering a scroll up event
			// -> forces the header to be sticky again
			// -> causing the table to update its sticky position
			this._events.on('stickyDetachEvent.preventHeaderEvent', () => {

				this.disableScrollEvents = true;

				S.Utils.delayed('basicHeader.enableScrollEvents', 500, () => {
					this.disableScrollEvents = false;
				});
			});

			this.addScrollEvent();
            this.addMQEvents();
            this.addSearchEvents();
            this.initTopMenu();

            if ( this.breadCrumbMode ) {
                this.checkBoxShadow();
            }

            this.windowResize();
        },

        /**
         * manage topMenu
         */
        initTopMenu()
        {
            this.listMenuWrapper.append(`<li class="is-top-menu">${this.topMenu.html()}</li>`);
        },

		/**
		 * initAccessibilityFlyout
		 *
		 */
		initAccessibilityFlyout ()
		{
			this.$navListLevel1Links.on('focus', (e) =>
			{
				this.$ctx.find('.mm-nav-list.has-focus').removeClass('has-focus');
				$(e.target).parent().addClass('has-focus');
			});

			this.$navListLevel1Links.on('focusout', (e) =>
			{
				$(e.target).parent().removeClass('has-focus');
			});
		},

        /**
         * adds scroll magic
         */
        addScrollEvent ()
        {
            const callHeightCalc = () => {
                if ( !this.disableScrollEvents ) {
                    if ( S.Utils.Helper.mq( 'desktop-l' ).matches ) {
                        if ( !this.$body.hasClass(this.classNoScroll) && !this.reducedHead ) {
                            this.checkStickyHeightsDesk('scroll');
                        }
                    }

                    else {
                        this.checkStickyHeightsMob('scroll');
                    }
                }
            };

            document.removeEventListener('scroll',  callHeightCalc,  false);
            document.addEventListener('scroll',  callHeightCalc,  { passive: true });
        },

        /**
         * set or remove boxShadow of header and breadcrumb depending on breadcrumb position
         */
        checkBoxShadow ()
        {
			const classDropShadow = 'is-dropshadow';
            // off
            if ( S.Utils.Helper.mq( 'desktop-l' ).matches && !this.reducedHead ) {
                this.$ctx.removeClass(classDropShadow);
            }

            // toggle
            else {
                if ( this.$ctx.hasClass('is-stuck') ) {
					this.$ctx.addClass(classDropShadow);
				}

                else {
					this.$ctx.removeClass(classDropShadow);
				}
            }
        },

		/**
		 * detect if sticky should be set or not in mobile
		 *
		 * @param mod {String} - 'init' || 'resize' || 'scroll' || 'recheck'
		 */
		// eslint-disable-next-line
		checkStickyHeightsMob(mod) //NOSONAR COmplexity
		{
			// get current scroll position
			const currentScrollPos = $(window).scrollTop();

            // check if page was scrolled on page load
			if ( mod === 'init' && currentScrollPos > 0 ) {

				// store padding.top val of the main wrapper
				const mainWrapPaddingTop= parseInt(this.$mainWrap.css(this.classPaddingTop));

				// update padding.top with stored val + header height
				this.$mainWrap.css(this.classPaddingTop, this.$ctx[0].getBoundingClientRect().height + mainWrapPaddingTop );
				this.$ctx.addClass('is-stuck');
				this.handlebarButtons('enable');
				this._events.emit(this.eventsBasicHeaderIsStuck);
			}

			if ( mod === 'scroll' || mod === 'resize' || mod === 'recheck' ) {

				// prevent "unsticking" if a layer is open (necessary for tablet and desktop (<1220px))
				const layersClosed = !this.$layerWrappers.filter('.is-visible').length,
					scrolledDistance = currentScrollPos - this.lastScrollY;

				// scroll down (scrolledDistance is positive)
				if ( scrolledDistance > 10 ) {

					// if there is an opened layer and the viewport is not at window top boundary, force stickyness
					if ( currentScrollPos !== 0 && !layersClosed || this.$body.hasClass(this.classNoScroll) ) {
						if ( !this.$ctx.hasClass('is-stuck') ) {
							this.forceSticky();
						}
					}

					// default logic -> remove stickyness
					else if ( this.$ctx.hasClass('is-stuck') && !this.disableScrollEvents ) {

						this.$ctx.addClass('is-anim-up');

                        // this gets triggered 5 times from calling this function multiple times
                        // on start - this.$ctx.off('transitionend.animUp')
                        // is only triggered when transition is fired
                        // - quick solution off()
						this.$ctx.off().on('transitionend.animUp', () => {
							this.$ctx.removeClass('is-anim-up is-stuck');
							this.$mainWrap.css(this.classPaddingTop,'');
							this.$ctx.off('transitionend.animUp');
							this._events.emit(this.eventsBasicHeaderNotStuck);
						});
					}

					// update scroll val
					this.lastScrollY = currentScrollPos;
				}

				// scroll up ( -1 for iOS rubber band check )
				else if ( scrolledDistance < 0 && -1 < currentScrollPos ) {

					// we are back at top => clean up
					if ( currentScrollPos === 0  && !this.disableScrollEvents ) {
						this.$mainWrap.css(this.classPaddingTop,'');
						this.$ctx.removeClass('is-stuck');
						this.handlebarButtons();
						this._events.emit(this.eventsBasicHeaderNotStuck);
					}

					// if not already stuck, stick it
					else if ( !this.$ctx.hasClass('is-stuck') && !this.disableScrollEvents ) {

						const mainWrapPaddingTopDesk = parseInt(this.$mainWrap.css(this.classPaddingTop));

						this.$mainWrap.css(this.classPaddingTop, this.$ctx[0].getBoundingClientRect().height + mainWrapPaddingTopDesk );
						this.$ctx.addClass('is-stuck');
						this.handlebarButtons('enable');
						this._events.emit(this.eventsBasicHeaderIsStuck);

						// prevent infinit loop
						if ( mod !== 'recheck' ) {
                            this.recheckScrollState();
                        }
                    }

                    // update scroll val
                    this.lastScrollY = currentScrollPos;
                }

                else if ( mod !== 'recheck' ) {
                    this.recheckScrollState();
				}
			}

			if ( this.breadCrumbMode ) {
				this.checkBoxShadow();
			}
		},

		/**
		 * sets header sticky in edge cases that are not easy to implement in the given logic like
		 * if a layer is open and we trigger the default sticky scroll animations
		 */
		forceSticky()
		{
			let mainWrapPaddingFix = false;

			// check if padding fix was already applied (if stuck, this should have been done previously)
			if (!this.$ctx.hasClass('is-stuck') ) {
				mainWrapPaddingFix = true;
			}

			// set sticky no matter what!
            if (!this.reducedHead) {
                this.$ctx.addClass('is-stuck');
				this.handlebarButtons('enable');
            }

			this._events.emit(this.eventsBasicHeaderIsStuck);

			// in desktop-l, theres always a header visible. Therefore we can define the spacings in the css and don't need the js magic
			if ( !S.Utils.Helper.mq( 'desktop-l' ).matches && mainWrapPaddingFix ) {
				const mainWrapPaddingTopDesk = parseInt(this.$mainWrap.css(this.classPaddingTop));
				this.$mainWrap.css(this.classPaddingTop, this.$ctx[0].getBoundingClientRect().height + mainWrapPaddingTopDesk );
			}

			if ( this.breadCrumbMode ) {
				this.checkBoxShadow();
			}
		},

		/**
		 * used mainly for iOS devices because the touchend event fires multiple times in some cases
		 */
		recheckScrollState()
		{
			S.Utils.delayed('basicHeader.recheckScrollStateBuffer', 300, () => {

                if ( !S.Utils.Helper.mq( 'desktop-l' ).matches ) {
                    this.checkStickyHeightsMob('recheck');
                }

                else if ( !this.$body.hasClass(this.classNoScroll) && !this.reducedHead ) {
                    this.checkStickyHeightsDesk('scroll');
                }
			});
		},

        /**
         * detect if sticky should be set or not in desktop-l
         *
         * @param mod {String} - 'init' || 'resize' || 'scroll'
         */
        checkStickyHeightsDesk()
        {
            // not stuck -> should stick?
            if ( !this.$ctx.hasClass('is-stuck') ) {

                // header is scrolled upwards beyond viewport
                if ( this.$mainWrap[0].getBoundingClientRect().top < this.unstickThreshold ) {

                    this.$ctx.addClass('is-stuck');
					this.handlebarButtons('enable');

                    if ( this.breadCrumbMode ) {
                        // move them up to anim start
                        this.$breadcrumbWrap.addClass(this.classHeaderShifted);
                    }

                    this._events.emit(this.eventsBasicHeaderIsStuck);
                }
            }

            else {

                // 1: unstick original
                if ( this.$mainWrap[0].getBoundingClientRect().top >= this.unstickThreshold ) {

                    this.$ctx.addClass('is-anim').removeClass('is-stuck');
					this.handlebarButtons();

                    // add transition class
                    if (  this.breadCrumbMode && this.$breadcrumbWrap.hasClass(this.classHeaderShifted) ) {
                        this.$breadcrumbWrap.addClass('is-header-anim').on('transitionend', () => {
                            // clean up
                            this.$breadcrumbWrap.removeClass('is-header-shifted is-header-anim');
                        });
                    }

                    this.$ctx.on('transitionend.animDesk', () => {
                        this.$ctx.removeClass('is-anim');
                        this._events.emit(this.eventsBasicHeaderNotStuck);
                        this.recheckScrollState();
                    });
                }
            }

			if ( this.breadCrumbMode ) {
				this.checkBoxShadow();
			}
        },

        /**
         *
         * adds special events to nav btns
         */
        // eslint-disable-next-line
        addMQEvents () //NOSONAR
        {
            const that = this;
			const eventClick = 'click.basicHeaderClickNaviMob';

            // detect mq state
            const mod = S.Utils.Helper.mq( 'desktop-l' ).matches ? 'desktop-l' : 'mobile';

			S.Globals.TabFocus.addPopup(this.$navBurgerBtn, this);

            // mobile
            if (mod !== 'desktop-l') {

                this.toggleNavHover('off');

                // adds toggle logics
                this.$navLinkButtons.off(eventClick).on(eventClick, (e) => {

                    if ( $(e.currentTarget).next('ul.mm-list-wrapper').length ) {

                        const $subListWrapper =  $(e.currentTarget).next('ul.mm-list-wrapper');

                        // lvl 1 & 2
                        if ( !$(e.currentTarget).hasClass('mm-list-toggle') ) {
                            $(e.currentTarget).closest(this.listWrapperClass).addClass(this.isShifted);
                            $subListWrapper.addClass('is-active');
                        }

                        // lvl 3 // toggle elem
                        else {
                            // close
                            if ( $(e.currentTarget).hasClass('is-open') ) {
                                $(e.currentTarget).removeClass('is-open');
                                $subListWrapper.slideUp(300, 'swing', () => {
                                    $subListWrapper.removeClass('is-active').removeAttr('style');
                                });
                            }

                            // open
                            else {
                                $(e.currentTarget).addClass('is-open');
                                $subListWrapper.slideDown(300, 'swing', () => {
                                    $subListWrapper.addClass('is-active');
                                });
                            }
                        }
                    }
                });

                // nav btn
                this.$navBurgerBtn.off('click').on('click.basicHeaderMobile', function () {

                    if ( $(this).hasClass('is-open') ) {
                        that.closeNavMenu('default');
                    }

                    else {
                        that.openNavMenu();
                    }
                });

                // back btn: closes current nav lvl
                this.$backBtns.off('click').on('click.basicHeaderMobile', (e) => {
                    const elemWrapper = $(e.currentTarget).closest('.mm-list-wrapper.is-active');
                    $(elemWrapper).removeClass('is-active');
                    $(elemWrapper).closest('.mm-list-wrapper.is-shifted').removeClass(this.isShifted);
                });

                this.$navListLevel1.off('keydown');
            }

            // desktop-l
            else {

                // add menu click
                if (this.reducedHead) {

                    this.$navBurgerBtn.off('click').on('click.basicHeaderReducedDesk', function ()  {

                        // close
                        if ( $(this).hasClass('is-open') ) {
                            that.closeNavMenu('default');
                        }

                        // open
                        else {
                            that.openNavMenu();
                        }
                    });
                }

                // remove click event listeners
                this.$navLinkElems.off(eventClick);
                this.toggleNavHover('on');

                this.$navListLevel1Links.off('keydown').on('keydown', (element) => {
                    if(element.which === 13) {
                        if($(element.currentTarget).parent().hasClass('is-active'))
                        {
                            this.closeNavMenuDesktop($(element.currentTarget).parent(), $(element.currentTarget).parent().index());
                        }
                        else
                        {
                            this.openNavMenuDesktop($(element.currentTarget).parent(), $(element.currentTarget).parent().index());
                        }
                        element.preventDefault();
                    }
                });
            }
        },

		_handleFocusEnter($target)
		{
			$target.trigger('click');
		},

        /**
         * toggleNavHover
         *
         * @param mod {String} - on || off
         */
        toggleNavHover (mod)
        {
            // off
            if (mod !== 'on' || !S.Utils.Helper.mq( 'desktop-l' ).matches ) {
                // remove hover events
                this.$navListLevel1.off("mouseenter mouseleave");
            }

            // on
            else {
				// reset state
				this.activeNavElems = 0;
                this.$navListLevel1.each( (index, elem) => {

                    $(elem).hover(

                        // mouse enter
                        () => {
							// prevent hover if search is open
							if ( !this.$ctx.hasClass(this.suggestionVisible) ) {

								// kill close(!) delay for elem to prevent closing while (re-)opening
								S.Utils.delayed(`basicHeader.closeNavOnHoverDelay-${index}`, 5, () => {
									return true;
								});
								this.openNavMenuDesktop(elem, index);
							}
                        },

                        // mouse leave
                        () => {
							// kill open(!) delay for elem to prevent opening while (re-)closing
							S.Utils.delayed(`basicHeader.openNavOnHoverDelay-${index}`, 5, () => {
								return true;
							});
							this.closeNavMenuDesktop(elem, index, 'default');
                        }
                    );
                });
            }
        },

		/**
		 * opens nav menu in desktop+
		 *
		 * @param elem {Object} - nav li elem that is hovered
		 * @param index {Number} - nav li elem index
		 */
		openNavMenuDesktop ( elem, index)
		{
			let openDelay = 300;

			const $listWrapper = $(elem).find('> .mm-list-wrapper'),
				activeSibs = this.$navListLevel1.filter('.is-active').length;

			$(elem).children(':first').attr(this.attributeAriaExpanded, true);

			if ( activeSibs > 0 ) {
				openDelay = 5;

				// close others immediately
				this.$navListLevel1.each( (indexOpened, elemOpened) => {
					if ( $(elemOpened).hasClass('is-active') ) {
						this.closeNavMenuDesktop(elemOpened, indexOpened, 'fast');
					}
				});

				$($listWrapper).addClass(this.isNoAnim);
			}

            $(elem).off('keydown').on('keydown', (element) => {
                if(element.key === 'Escape') {
                    this.closeNavMenuDesktop(elem, index);
                    $(elem).addClass('has-focus is-active');
                }
            });

			S.Utils.delayed(`basicHeader.openNavOnHoverDelay-${index}`, openDelay, () => {
				// hide layers
				this.resetChildren($(this.$menuBtnWrap.find('> .mm-main-btn')));
				this.$searchBar.removeClass('is-open');

				// set active styles
                $(elem).addClass('is-active');
                $(elem).find('> .mm-list').addClass('js-hover');

                $($listWrapper).addClass(this.classIsVisible);

				// wait for 'is-visible' class to call height anim after elem is displayed
                setTimeout( () => {
                    $($listWrapper).addClass('is-active');
                }, 10);

                this.toggleDarkLayer('on');

				// check if flyout should be scrollable
				if (S.Utils.Helper.mq('desktop-l').matches) {
					const headerHeight = this.$ctx.outerHeight();
					const checkAvailableHeight = $(window).height() - headerHeight + 10;

					$listWrapper.css('height', 'auto');
					if ($listWrapper.outerHeight() > checkAvailableHeight) {
						$listWrapper.css('height', `calc(100vh - ${headerHeight}px`);
					}
				}
			});
		},

		/**
		 * closes nav menu in desktop+
		 *
		 * @param elem {Object} - nav li elem that is hovered
		 *
		 * @param index {Number} - nav li elem index
		 *
		 * @param mod {String} - default || fast : to trigger close without delay
		 */
		closeNavMenuDesktop ( elem, index, mod)
		{
			let closeDelay = 300;

			if ( mod === 'fast' ) {
				closeDelay = 40;
			}

			$(elem).children(':first').attr(this.attributeAriaExpanded, false);

			// reset calculated heights
			$(elem).closest(this.listWrapperClass).find(this.listWrapperClass).removeAttr('style');

			S.Utils.delayed(`basicHeader.closeNavOnHoverDelay-${  index  }`, closeDelay, () => {

				// prevents the NavMenuDesktop from closing prematurely while moving on navListLevel1
				if(!(mod === 'fast' && this.$navListLevel1.filter('.is-active').length === 1))
				{
					$(elem).removeClass('is-active');
					$(elem).find('> .mm-list-wrapper').removeClass('is-active is-visible is-no-anim');

					this.darkLayerCoolDown = true;

					if ( mod !== 'fast' ) {

						// hard reset cause hover states fire too fast
						this.darkLayerCoolDown = false;
						this.toggleDarkLayer('off');
					}

					else {
						this.toggleDarkLayer('on');
					}
				}
			});
		},

        /**
         * opens nav menu in mobile / tablet
         */
        openNavMenu ()
        {
            // delay to kill closeNavChildren() call in closeNavMenu
            S.Utils.delayed('basicHeader.closeNavChildren', 5, () => {

                this.$navBurgerBtn.addClass('is-open');
                this.$navBurgerBtn.attr(this.attributeAriaExpanded, 'true');

				// update aria-label
				if (this.burgerBtnAriaLabelActive !== '') {
					this.$navBurgerBtn.attr(this.attributeAriaLabel, this.burgerBtnAriaLabelActive);
				}

                if ( this.reducedHead ) {

                    // set to display block
                    this.$navWrapper.addClass(this.classIsVisible);
                    this.$searchBarClose.trigger('click');

                    // wait till display.prop is set to anim opacity
                    setTimeout( () => {
                        this.$navWrapper.addClass('is-active');
                        this.$navWrapper.parent().addClass('is-active');

                        if ( !S.Utils.Helper.mq( 'desktop-l' ).matches ) {

                            this.toggleDarkLayer('on');

                            S.Utils.delayed(this.eventHeaderEnableOffsetClick, 100, () => {
                                this.bodyOffsetClick('menu', 'on');
                            });
                        }
                    }, 20);

                    if ( this.breadCrumbMode ) {
                        S.Utils.delayed('basicHeaderReduced.checkBoxShadowDelay', this.cssAnimDelay, () => {
                            this.checkBoxShadow();
                        });
                    }

                    S.Utils.delayed('basicHeader.waitForHeaderAnimTillEmittingHeight', this.cssAnimDelay,() => {
                        if ( S.Utils.Helper.mq( 'desktop-l' ).matches) {
                            this._events.emit('basicHeader.reducedHeightChanged');
                        }
                    });
                } else {
                    this.$navWrapper.find('.is-no-anim').removeClass(this.isNoAnim);

                    this.$navWrapper.addClass(this.classIsVisible);

                    // wait till display.prop is set to anim opacity
                    setTimeout( () => {
                        this.$navWrapper.addClass('is-active');
                        this.$mainWrap.addClass('js-nav-opened');
                        this.toggleDarkLayer('on');

                        S.Utils.delayed(this.eventHeaderEnableOffsetClick, 100, () => {
                            this.bodyOffsetClick('menu', 'on');
                        });
                    }, 20);
                }

				// check if navi main level should be scrollable
				if (!S.Utils.Helper.mq('desktop-l').matches) {
					const headerHeight = this.$ctx.outerHeight();
					const checkAvailableHeight = $(window).height() - headerHeight + 10;

					this.$navWrapper.css('height', 'auto');
					if (this.$navWrapper.outerHeight() > checkAvailableHeight) {
						this.$navWrapper.addClass(this.classIsScrollable);
						this.$navWrapper.css('height', `calc(100vh - ${headerHeight}px`);
					} else {
						this.$navWrapper.removeClass(this.classIsScrollable);
					}
				}

                this._events.emit('basicHeader.navMenuOpened');
            });
        },

        /**
         * closes nav menu
         *
         * @param mod {String} - 'default' || 'resize' : set no-anim-classes when resize event triggered the close()
         */
        closeNavMenu (mod)
        {
            if ( this.$navBurgerBtn.hasClass('is-open') ) {

				// reset scrollable class
				this.$navWrapper.removeClass(this.classIsScrollable);

                // reset icon
                this.$navBurgerBtn.removeClass('is-open');
				this.$navBurgerBtn.attr(this.attributeAriaExpanded, 'false');

				// update aria-label
				if (this.burgerBtnAriaLabelDefault !== '') {
					this.$navBurgerBtn.attr(this.attributeAriaLabel, this.burgerBtnAriaLabelDefault);
				}

                if ( mod === 'resize' ) {
                    // prevent closing anim
                    this.$navWrapper.addClass(this.isNoAnim).removeAttr('style');
                }

                // reset main nav wrapper
                this.$navWrapper.removeClass('is-active');

                // set height of back to reduced default
                if ( this.reducedHead ) {
                    this.$navWrapper.parent().removeClass('is-active');

                    if ( this.breadCrumbMode) {
                        // readd drop shadow to breadcrumb immediately
                        this.$breadcrumbWrap.removeClass('is-no-dropshadow');
                    }
                }

                else {

                    // reset first nav lvl
                    this.$navWrapper.find('> .is-shifted').addClass(this.isNoAnim).removeClass(this.isShifted);

                    // reset hover styles of nav items (used in desktop-l)
                    this.$navLinkElems.filter('.js-hover').removeClass('js-hover');

                    // reset (.l-main) content wrapper
                    this.$mainWrap.removeClass('js-nav-opened');
                }

                if ( !S.Utils.Helper.mq( 'desktop-l' ).matches || mod === 'resize' ) {
                    this.toggleDarkLayer('off');
                    this.bodyOffsetClick('menu', 'off');
                }

                // reset child list elems after css anim is finished
                S.Utils.delayed('basicHeader.closeNavChildren', this.cssAnimDelay, () => {
                    this.resetChildren(this.$navWrapper);

                    // reset js helpers classes for the anim
                    this.$navWrapper.removeClass('is-no-anim is-visible');

                    if ( this.reducedHead ) {

                        if ( S.Utils.Helper.mq( 'desktop-l' ).matches) {
                            this._events.emit('basicHeader.reducedHeightChanged');
                        }

                        if ( this.breadCrumbMode ) {
                            this.checkBoxShadow();
                        }
                    }
                });
            }
        },


        /**
         * opens layer
         *
         * @param {Object} trigger - btn that fired
         *
         */
        openLayer (trigger)
        {
            // find corresponding layer (should be sibling of trigger)
            const $layer = $(trigger).next('.mm-layer');

			// is not already open?
            if ( $($layer).hasClass(this.classIsVisible) ) {
				this.closeLayer('single', $layer );
			}

			// layer exists?
            else if ( $($layer).length ) {

                // close other layers
                this.closeLayer('all', $layer);

                // close nav
                this.closeNavMenu('default');

                // close search
                if ( this.reducedHead && this.$searchBar.hasClass('is-open') ) {
                    this.$searchBarClose.trigger('click');
                }

                // set display.prop
                $($layer).addClass(this.classIsVisible);

				// set sticky
				if ( !S.Utils.Helper.mq( 'desktop-l' ).matches ) {
					this.checkStickyHeightsMob('scroll');
				}

                // trigger anim class afterwards
                setTimeout( () => {
                    $($layer).addClass('is-active');
                }, 10);

                S.Utils.delayed(this.eventHeaderEnableOffsetClick, 100, () => {
                    this.bodyOffsetClick('layer', 'on');
                });
            }
        },

        /**
         * closes open layer
         *
         *  * @param {String} mod - 'single' || 'all' || 'force'
         *
         *  * @param {Object} layerElem - layer that needs to be closed in mod=single // layer that is ignored in mod=all/force
         */
        closeLayer (mod, layerElem)
        {
            // closes one layer
            if ( mod === 'single' ) {

                if ( $(layerElem).hasClass(this.classIsVisible) ) {

                    // remove anim class
                    $(layerElem).removeClass('is-active');

                    // set display to none after anim is finished
                    S.Utils.delayed('basicHeader.hideLayerAfterAnim', this.cssAnimDelay, () => {
                        $(layerElem).removeClass(this.classIsVisible);
                    });

                    this.bodyOffsetClick('layer', 'off');
                }
            }

            // closes all opened layers ('all' || 'force')
            else {

                let i = 0;

                this.$layerWrappers.each( (index, $layer) => {

                    i++;

                    // is open and not the one we are trying to open?
                    if ( $($layer).hasClass(this.classIsVisible) && !$(layerElem).is($($layer)) ) {

                        if (mod === 'force') {
                            $($layer).removeClass(this.visbileActiveClass);
                        }

                        else {
                            // remove anim class
                            $($layer).removeClass('is-active');

                            // set display to none after anim is finished
                            S.Utils.delayed(`basicHeader.hideLayerAfterAnim_${  i  }`, this.cssAnimDelay, () => {
                                $($layer).removeClass(this.classIsVisible);
                            });
                        }
                    }
                });
            }
        },

        /**
         * reset child elems of elemWrapper to their default state
         *
         * @param elemWrapper {Object} - elem to search for elems
         */
        resetChildren (elemWrapper)
        {
            $(elemWrapper[0].querySelectorAll('.is-active, .is-open:not(.mm-btn-toggle), .is-shifted, .is-no-anim, .is-visible, .js-hover'))
                .each( (index, element) => {
                    $(element).removeAttr('style').removeClass('is-active is-open is-shifted is-no-anim is-visible js-hover');
                });
        },

        /**
         * search events - configs search events for smartphone // tablet+
         *
         */
        addSearchEvents ()
        {
            // ***
            // clicks
            // ***

			const jsSearchFilled = 'js-search-filled',
				that = this,
				layerSearch = '.mm-layer.mm-search';

            // open
            this.$menuBtns.filter(this.classBtnSubmit).on('click.basicHeaderSearchOpen', () => {

                if ( S.Globals.MediaQuery.mqRightNow === 'smartphone' || S.Utils.Helper.mq( 'desktop-l' ).matches || this.reducedHead ) {
                    this.$searchBar.addClass('is-open');
                }

                this._events.emit('basicHeader.searchOpened');
            });

            // close
            this.$searchBarClose.on('click.basicHeaderSearchClose', () => {
                this.$searchBar.removeClass('is-open');
            });

            // ***

            // ***
            // listen to input events of searchbar
            // ***

            // focus in
            this._events.on('BasicSearch.ModNaviFocus', () => {

                S.Utils.delayed('basicHeader.SearchInputFocusDelay', 5, () => {
                    this.$ctx.addClass('js-search-focus');

                    // close other layers
                    this.closeLayer('all', this.$searchBar);

                    // close nav
                    this.closeNavMenu('default');
                });
            });

            // keyup
            this._events.on('BasicSearch.ModNaviKeyUp', (input) => {

                if ( $(input).val().length ) {
                    this.$ctx.addClass(jsSearchFilled);
					this.handlebarButtons('enable');
                }

                else {
                    this.$ctx.removeClass(jsSearchFilled);
					this.handlebarButtons();
                }
            });

            // blur
            this._events.on('BasicSearch.ModNaviBlur', (input) => {

                S.Utils.delayed('basicHeader.SearchInputFocusDelay', 100, () => {
                    this.$ctx.removeClass('js-search-focus');
                    if ( !$(input).val().length ) {
                        this.$ctx.removeClass(jsSearchFilled);

						if(this.$ctx.find(layerSearch).hasClass('is-open') ||  S.Utils.Helper.mq('tablet') && !this.reducedHead) {
							this.handlebarButtons();
						}
						else {
							this.handlebarButtons('enable');
						}
                    }
                });
            });

            // clear
            this._events.on('BasicSearch.InputCleared', () => {
                this.$ctx.removeClass(jsSearchFilled);

				if(this.$ctx.find(layerSearch).hasClass('is-open') || S.Utils.Helper.mq('tablet')  && !this.reducedHead) {
					this.handlebarButtons();
				}
				else {
					this.handlebarButtons('enable');
				}
            });

            this.$searchBarClose.on('click', () =>
			{
				if(!S.Utils.Helper.mq('tablet').matches || this.reducedHead || this.$ctx.hasClass('is-stuck'))
				{
					this.handlebarButtons('enable');
				}
			});

            // resizing to mobile
			$(window).resize(function ()
			{
				setTimeout(() =>
				{
					if(!S.Utils.Helper.mq('tablet').matches || this.reducedHead)
					{
						$(that.classBtnSubmit).eq(0).prop('tabindex', '0');
					}
				}, 20);
			});


            // ***
            // suggestions
            // ***

            // show
            this._events.on('BasicSearch.SuggestionsShown', () => {

                this.$ctx.addClass(this.suggestionVisible);
                this.toggleDarkLayer('on');

                S.Utils.delayed(this.eventHeaderEnableOffsetClick, 100, () => {
                    this.bodyOffsetClick('search', 'on');
                });
            });

            // hide
            this._events.on('BasicSearch.SuggestionsHidden', () => {

                if (  this.$ctx.hasClass(this.suggestionVisible) ) {

                    this.$ctx.removeClass(this.suggestionVisible);
                    this.toggleDarkLayer('off');
                    this.bodyOffsetClick('search', 'off');
                }
            });

            // ***
        },

		/**
		 * controlling prop disable of buttons for accessibility
		 *
		 * @param {String} mod - 'disable' || 'enable'
		 */

		handlebarButtons (mod) {

			const state = mod ? '0' : '-1';

			this.$ctx.find(this.classBtnSubmit).prop('tabindex', state);
			this.$ctx.find('.mm-search-cancel').prop('tabindex', state);
		},

        /**
         * toggles visibility of the dark layer
         *
         * @param {String} mod - 'on' || 'off'
         */
        toggleDarkLayer (mod)
        {
            if (mod === 'on') {

                // prevent removing layer when e.g. search is open and menu is triggered -> double call;
                this.darkLayerCoolDown = true;
                this.$darkLayer.addClass(this.visbileActiveClass);

                // reset darklayer
                setTimeout( () => {
                    this.darkLayerCoolDown = false;
                }, 50);

                // recheck if darklayer should be visible (some fast mouse hover events trigger the visibility)
                setTimeout( () => {
                    if (!this.$darkLayer.hasClass('is-active')) {
                        this.$darkLayer.removeClass(this.classIsVisible);
                    }
                }, 500);
            }

            else {

                if (!this.darkLayerCoolDown) {
                    this.$darkLayer.removeClass('is-active');
                }
            }
        },

        /**
         * adds click logic to body to close nav or layers
         *
         * @param mod {String} - menu  || layer || search
         *
         * @param state {String} - on || off
         */
		// eslint-disable-next-line
        bodyOffsetClick (mod, state) //NOSONAR Complexity
        {

            // close on click of body and darklayer (body is not firing in iOS mobile)
            const closingElems = [this.$body, this.$darkLayer],
				eventBasicHeaderBodyClick = 'click.basicHeaderBodyClick';

            // add events
            if (state === 'on') {

                // menu
                if ( mod === 'menu' ) {

                    this.toggleLastScrollPosition('on');

                    $.each(closingElems, (index, elem) => {
                        $(elem).off(eventBasicHeaderBodyClick).on(eventBasicHeaderBodyClick, (e) => {
                            // outside
                            if ( !$( e.target ).closest('.m-basic-header').length && this.$navBurgerBtn.hasClass('is-open') ) {
                                this.$navBurgerBtn.trigger('click');
                            }
                        });
                    });
                }

                // layer || search
                else {

                    if ( mod === 'layer') {

                        if ( S.Globals.MediaQuery.mqRightNow === 'smartphone' ) {
                            this.toggleLastScrollPosition('on');
                        }

                        $.each(closingElems, (index, elem) => {
                            $(elem).off(eventBasicHeaderBodyClick).on(eventBasicHeaderBodyClick, (e) => {
                                // outside
                                if ( !$( e.target ).closest('.mm-section').length ) {
                                    this.$layerClose.trigger('click');
                                }
                            });
                        });
                    }

                    if ( mod === 'search' ) {

                        this.toggleLastScrollPosition('on');

                        $.each(closingElems, (index, elem) => {
                            $(elem).off(eventBasicHeaderBodyClick).on(eventBasicHeaderBodyClick, (e) => {
                                // outside
                                if ( !$( e.target ).closest('.mm-layer.mm-search').length ) {
                                    this.$searchBarClose.trigger('click');
                                }
                            });
                        });
                    }
                }
            }

            // remove events
            else {
                S.Utils.delayed('Basicheader.toggleLastScrollDelay', 10, () => {
                    this.toggleLastScrollPosition('off');
                });

                $.each(closingElems, (index, elem) => {
                    $(elem).off(eventBasicHeaderBodyClick);
                });
            }
        },

        /**
         * saves last scroll position and sets it back
         *
         * @param mod {String} - 'on' || 'off'
         */
        toggleLastScrollPosition (mod)
        {
            // toggle no scroll
            if (mod === 'on') {

				// kill scroll listener
				// $(document).off('scroll.basicHeader');
                this.disableScrollEvents = true;

                // store current scroll pos
                this.lastScrollY = $(window).scrollTop();

                // add class and scroll body
                S.Utils.delayed('freezeWindowAtCurrentScrollPos', 5, () =>
                {
                    this.$body.addClass(this.classNoScroll).scrollTop(this.lastScrollY);
                });
            }

            else if ( this.$body.hasClass(this.classNoScroll) && this.lastScrollY !== null ) {

                // remove class and scroll window
                this.$body.removeClass(this.classNoScroll);
                $(window).scrollTop(this.lastScrollY);

                S.Utils.delayed('basicHeader.scrollEventBindingBuffer', 200, () => {
                    this.disableScrollEvents = false;
                });
            }
        },

        /**
         * close nav on mq change
         */
        windowResize ()
        {
            const that = this;

            // change from desktop-l to tablet (both directions)
            S.Utils.Helper.mq('desktop-l').addListener( () => {

                // close searchbar
                that.$searchBar.removeClass('is-open');

                // close nav
                that.closeNavMenu('resize');
                that.closeLayer('force', null);
                that.bodyOffsetClick('layer', 'off');

                // reset main padding
                that.$mainWrap.css(that.classPaddingTop,'');

				// clear event listener
				this.$ctx.off('transitionend');

                if ( that.breadCrumbMode ) {
                    that.checkBoxShadow();

                    if ( that.$breadcrumbWrap.hasClass(this.classHeaderShifted) ) {
                        // clean up
                        this.$breadcrumbWrap.removeClass('is-header-shifted is-header-anim');
                    }
                }

                // >= desktop-l
				if ( S.Utils.Helper.mq( 'desktop-l' ).matches ) {

					if ( !that.reducedHead ) {
						that.checkStickyHeightsDesk('resize');
					}

					else {
						this.forceSticky();
					}

				}

				// < desktop-l
				else {
					that.checkStickyHeightsMob('resize');
				}

                // reinit btnEvents
                that.addMQEvents();
            });
        }
    } );
}(jQuery));
