'use strict';
(function ($) {
	/**
	 * MyAdacDashboard module implementation.
	 *
	 * @author Marc Radziwill <marc.radziwill@merkle.com>
	 * @namespace T.Module
	 * @class MyAdacDashboard
	 * @extends T.Module
	 */
	T.Module.MyAdacDashboard = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);

			this.previewUrl = T.Utils.Helper.updateUrlParameter(T.Utils.Helper.getQueryParams(window.location.search), this.$ctx.data('preview-url'));
			T.Utils.Auth.getResolvedIdentity(this._handleUserCallback.bind(this));
			$(document).on('identityloaded', this._handleUserCallback.bind(this));
			$(document).on('identitylogout', this._redirectUser.bind(this));
			resolve();
		},

		_handleUserCallback: function _handleUserCallback(user) {
			if (!user) {
				this._redirectUser();
			} else {
				this.$ctx.removeClass('h-hidden');
			}
		},

		_redirectUser: function _redirectUser() {
			window.location.href = this.previewUrl;
		}

	});
}(jQuery));
