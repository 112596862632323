(function($) {
    'use strict';
    /**
     * MglProductAside module implementation.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class MglProductAside
     * @extends T.Module
     */
    T.Module.MglProductAside = T.createModule({
        /** @type {jQuery} */
        $ctx: null,



        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function(resolve) {
            this.$ctx = $(this._ctx);

            // check if active product exists
            if (sessionStorage.getItem('productActive'))
            {
                // set product image
                this.$ctx.find('img').eq(0).attr('src', sessionStorage.getItem('productActiveImage'));

                // set product title
                this.$ctx.find('.m-productname').eq(0).text(sessionStorage.getItem('productActiveTitle'));

                // check copayment
                if (sessionStorage.getItem('productActiveCopayment'))
                {
                    this.$ctx.find('.m-price').eq(0).text(sessionStorage.getItem('productActiveCopayment'));
                }else{
                    this.$ctx.find('.m-price').eq(0).hide();
                }
            }

            resolve();
        }
    });
}(jQuery));
/**
 * Created by s.vogt on 02.05.2017.
 */
