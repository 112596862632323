(function ($) {
	'use strict';
	/**
	 * BasicLayerSurvey module implementation.
	 *
	 * @namespace T.Module
	 * @class BasicLayerSurvey
	 * @extends T.Module
	 */
	T.Module.BasicLayerSurvey.Contact = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,
		cookieId: null,
		activeLayer: false,
		timeout: 5000,
		frequency: 2,
		hideClass: 'is-already-shown',
		sessionLayerCountName: 'basiclayersurvey-contact_layer-count',

		start: function (resolve) {
			this.$ctx = $(this._ctx);

			// timeout
			if (typeof this.$ctx.data('timeout') !== 'undefined') {
				this.timeout = this.$ctx.data('timeout');
			}

			// frequency
			if (typeof this.$ctx.data('frequency') !== 'undefined') {
				this.frequency = this.$ctx.data('frequency');
			}

			this.$ctx.find('.mm-close').click(() => {
				this.$ctx.hide();
				this.manageHideClass();
			});

			setTimeout(() => {
				if (this.$ctx.hasClass('is-on-leave')) {
					$(document).mouseleave(() => {
						if (!this.$ctx.hasClass(this.hideClass) && S.Utils.Helper.mq('tablet').matches) {
							//show Layer like in BasicLayerSurvey
							this.showLayer();
						}
					});
				}
				else {
					this.showLayer();
				}
			}, this.timeout);

			this.resize();
			resolve();
		},

		resize() {
			$(window).on('resize', () => {
				S.Utils.delayed('hideContactLayerOnResize', 100, () => {
					if (!S.Utils.Helper.mq('tablet').matches) {
						this.$ctx.addClass(this.hideClass);
					}
					else if (this.getSessionLayerCount() < this.frequency) {
						this.$ctx.removeClass(this.hideClass);
					}
				});
			});
		},

		manageHideClass() {
			this.activeLayer = false;

			if (this.getSessionLayerCount() >= this.frequency) {
				this.$ctx.addClass(this.hideClass);
			}
		},

		getSessionLayerCount() {
			if (sessionStorage.getItem(this.sessionLayerCountName)) {
				return parseInt(sessionStorage.getItem(this.sessionLayerCountName));
			}
			else {
				return 0;
			}
		},

		showLayer: function () {
			if (!this.activeLayer && this.getSessionLayerCount() < this.frequency) {
				this.activeLayer = true;

				sessionStorage.setItem(this.sessionLayerCountName, this.getSessionLayerCount() + 1);

				this.$ctx.css({ "display": "block" });

				// make this focusable and keep the tabFocus inside this container
				S.Globals.TabFocus.setFocusToContext(this.$ctx);

				$('body').on('click.surveylayer', (el) => {
					if ($(el.target).closest('.mm-content').length < 1) {
						this.$ctx.hide();
						this.manageHideClass();
						$('body').off('click.surveylayer');
					}
				});
			}
		}
	});
}(jQuery));
