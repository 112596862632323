(function($) {

	'use strict';

	/**
	 * RufClimate module implementation.
	 *
	 * @author l.meyer <l.meyer@edelweiss72.de>
	 * @namespace T.Module
	 * @class RufClimate
	 * @extends T.Module
	 */
	T.Module.RufClimate = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $scaleElem: null,

        /** @type {jQuery} */
        $elemCousins: null,

        /** @type {jQuery} */
        $tableWrap: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function(resolve) {

            const that = this;

			this.$ctx = $(this._ctx);

            this.$scaleElem = this.$ctx.find('.aa-top');

            if (this.$ctx.closest('table.m-basic-table').length) {
                this.$tableWrap = this.$ctx.closest('table.m-basic-table');
            }

            this.$elemCousins = this.$tableWrap.find('.a-ruf-climate');

            // do stuff after last elem was init
            if (this.$elemCousins.last().is(that.$ctx)) {
                this.$ctx.closest('.m-basic-table').addClass('m-basic-table--white js-white');
            }

            this.calcHeights();

			resolve();
		},


        /**
		 * calc bar height
		 *
         */
        calcHeights: function () {

            const that = this;

            // get data values from each elem
            const elemValue = this.$ctx.data('value'),
                elemFactor = this.$ctx.data('factor');

			const realValue = Math.round(elemValue / elemFactor);

            // add animation
            that.$scaleElem.animate({height: realValue}, 600);

            // if last elem in this table is reached, fire to reinit swiper for the table
            if (that.$elemCousins.last().is(that.$ctx)) {
                setTimeout( () => {
                    that._events.emit('RufClimate.finishedHeightAnim');
                }, 600);
            }
        }
	});
}(jQuery));
