'use strict';
(function ($) {
	/**
	 * GoogleTagEventPackage module implementation.
	 * Push information for Events into the dataLayer for Google Tag Manager.
	 * Google Tag Manager will event Listeners attached to trigger based actions.
	 * Google Tag Manager = GTM
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class GoogleTagEventPackage
	 * @extends T.Module
	 */
	T.Module.GoogleTagEventPackage = T.createModule({
		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);

			this._readConfiguration();
			T.Utils.Tracking.hasAnalyticsConsent(this._handleConsent.bind(this));
			resolve();
		},

		_readConfiguration: function _readConfiguration() {
			this._businessunit = this.$ctx.data("businessunit") || '';
			this._product = this.$ctx.data("product") || '';
			this._contentID = this.$ctx.data("contentid") || '';
			this._pageTitle = this.$ctx.data("page-title") || document.title || '';
			this._layoutTyp = this.$ctx.data("contenttype") || '';
			this._pageID = this.$ctx.data("pageid") || '';
			this._createdDate = this.$ctx.data("created-date") || '';
			if (this._createdDate) {
				this._createdDate = moment(this._createdDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
			}
			this._updateDate = this.$ctx.data("update-date") || '';
			if (this._updateDate) {
				this._updateDate = moment(this._updateDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
			}
			this._versionCounter = this.$ctx.data("version-counter");
			this._metaRobots = $('meta[name=robots]').attr('content') || "noindex, nofollow";
			this.$main = $('.l-main-content').first();
			if (!this.$main.length) {
				this.$main = $('#l_main_content').first();
			}
		},

		_handleConsent: function _handleConsent(consentStatus) {
			if (consentStatus) {
				this._checkProductBrandAndProcced();
				this._addEventListeners();
				this._pushGtmGenericEvents($(document.body));
				this._pushGtmTeaserPackage();
				this._registerObserver();
			}
		},

		_checkProductBrandAndProcced: function _checkProductBrandAndProcced() {
			const $brandElm = $('[data-productbrand]');
			if ($brandElm.length) {
				this.productBrand = $brandElm.first().data('productbrand') || '';
				this._pushGtmPagePackage();
			}
		},

		_addEventListeners: function _addEventListeners() {
			$(window).on("scroll", this._pushGtmTeaserPackage.bind(this));
		},

		_registerObserver: function _registerObserver() {
			const config = { attributes: false, childList: true, subtree: true };
			try {
				const observer = new MutationObserver(this._initMutations.bind(this));
				observer.observe(document.body, config);
			} catch (err) {
				T.Utils.Logger("Failed to init MutationObserver", err);
			}
		},
		_pushGtmPagePackage: function _pushGtmPagePackage() {
			const metaDescription = $('meta[name=description]').attr('content') || '';
			const breadCrumbs = this._getBreadcrumb();
			const eventData = {
				event: "page_load",
				page: {
					domain: document.location.hostname,
					organization: `${this._businessunit} | ${this._product}`,
					breadCrumbs: breadCrumbs.length || '',
					pageID: this._pageID,
					CMS: "sitecore",
					layoutType: this._layoutTyp,
					contentType: this._getContentType(),
					focusKeyword: "",
					department: this._businessunit,
					publicationDate: this._createdDate,
					lastUpdate: this._updateDate,
					isNews: this._checkIfNews(),
					versionCounter: this._versionCounter,
					tags: this._contentID,
					teaserHeadline: this._getTeaserHeadlines(),
					title: this._pageTitle,
					title_length: this._pageTitle.length,
					description: metaDescription,
					description_length: metaDescription.length,
					structured_data: this.$main.find('img').length,
					count_ads: "",
					count_teasers: this._getTeaserCount(),
					count_links: this._getLinksCount(),
					count_words: this._getCountWords(),
					robots: this._metaRobots,
					brand: this.productBrand,
					hreflang: '',
					canonicalUrl: ''
				}
			};
			const $canonical = $("link[rel='canonical']");
			if ($canonical.length) {
				eventData.page.hreflang = $canonical.attr("hreflang") || '';
				eventData.page.canonicalUrl = $canonical.attr("href") || '';
			}
			T.Utils.Tracking.pushData(eventData);
		},

		_pushGtmTeaserPackage: function _pushGtmTeaserPackage() {
			const teaserList = [];
			const $teasers = $('[data-teaser-tracking-container] [data-teaser-tracking]').not('[data-gtm-tracked]');
			$teasers.each((index, teaser) => {
				if (this._isTeaserVisible(teaser)) {
					const $teaser = $(teaser);
					$teaser.attr("data-gtm-tracked", true);
					const eventData = this._getTeaserInformations(teaser, index);
					if (eventData) {
						teaserList.push(eventData);
						$teaser.on('click', () => {
							T.Utils.Tracking.pushData({ teaser: eventData, event: "teaser_click" });
						});
					}
				}
			});

			if (teaserList.length) {
				T.Utils.Tracking.pushData({ teaser: teaserList, event: "teaser_impression" });
			}
		},

		_getTeaserInformations: function _getTeaserInformations(teaser, index) {
			const $teaser = $(teaser);
			const teaserConfig = $teaser.data("teaser-tracking");
			if (teaserConfig) {
				const headline = $teaser.find('.js-teaser-headline').text() || "";
				return {
					teaserId: teaserConfig.id,
					teaserName: `${teaserConfig.componentType} ${this._pageTitle}`,
					teaserPosition: index + 1,
					teaserCreative: `${teaserConfig.type === "Product Teaser" ? "(Ad) " : ""}${headline}`,
					teaserProduct: teaserConfig.teaserProduct,
					teaserProductCategory: teaserConfig.teaserProductCategory
				};
			}
			return undefined;
		},

		_getContentType: function _getContentType() {
			return $('meta[property="og:title"]').attr('content') === 'Antrag' ? 'Antrag' : 'Artikel';
		},

		_getLinksCount: function () {
			return this.$main.length ? this.$main.find('a[href][href!="/"]').not('[href^="#"]').length : 0;
		},
		_getBreadcrumb: function () {
			const listItems = $('div.m-basic-breadcrumb ul.mm-baselevel > li > a');
			return Array.prototype.map.call(listItems, function (element) {
				return element.title;
			});
		},
		_getCountWords: function () {
			if (this.$main.length) {
				const words = this.$main.text().match(/[\p{Letter}\p{Mark}\p{Number}]+/gu);
				if (words) {
					return words.length;
				}
			}
			return '';
		},
		_getTeaserHeadlines: function () {
			return Array.prototype.reduce.call(this.$main.find('[data-teaser-tracking] .js-teaser-headline'), (accumulator, currentValue) => {
				const t = T.Utils.Format.normalizeSpace(currentValue.textContent);
				if (t) {
					return accumulator ? `${accumulator} | ${t}` : t;
				}
				return accumulator;
			}, '');
		},
		_getTeaserCount: function _getTeaserCount() {
			if (this.$main.length) {
				return this.$main.find('[data-teaser-tracking="true"]').length;
			}
			return 0;
		},
		_checkIfNews: function _checkIfNews() {
			return this.$main.find('[data-t-name="BasicNewsbox"]').length ? "isNews" : "isNotNews";
		},
		_initMutations: function _initMutations(mutationsList) {
			mutationsList.forEach(mutation => {
				if (mutation.addedNodes) {
					this._pushGtmGenericEvents($(mutation.addedNodes).parent());
				}
			});
		},
		_pushGtmGenericEvents: function _pushGtmGenericEvents($context) {
			if ($context.length) {
				$context.find('input[type="submit"], input[type="button"], button, a, div.aa-slider.js-slider').not('[data-gtm-init]').attr("data-gtm-init", true).on('click', (evt) => {
					const $element = $(evt.currentTarget);
					const eventData = {
						eventAction: this._getEventActionByElement($element),
						eventLabel: this._getLabelFromElement($element, evt),
						eventValue: this._getEventValue($element)
					};
					eventData.eventCategory = this._getEventCategory($element, eventData.eventAction);

					const gtmCustom = $element.data("gtm-custom");
					if (gtmCustom) {
						const customEventData = gtmCustom.split(':');
						eventData[customEventData[0]] = customEventData[1];
					}
					T.Utils.Tracking.pushData({
						eventData: eventData,
						event: 'click'
					});
				});
			}
		},
		_getEventValue: function _getEventValue($element) {
			let eventValue = '';
			if ($element.is('div.aa-slider.js-slider')) {
				const $inp = $element.prev('input');
				if ($inp.length) {
					eventValue = parseInt($inp.val());
				}
			}
			return eventValue;
		},

		_getEventCategory: function _getEventCategory(element, action) {
			let result = `ADAC.de | ${this._getContentType()}`;
			if (action && action.startsWith('navigation_link_level')) {
				const topmenu = T.Utils.Format.normalizeSpace(element.parents('[data-gtm-eventaction="navigation_link_level1"] > li').find('> a').text());
				if (topmenu) {
					result = `${result} | ${topmenu}`;
				}
			}
			return result;
		},

		_getLabelFromElement: function _getLabelFromElement($element, event) {
			if ($element.length) {
				const gtmEventLabel = $element.data("gtm-eventlabel");
				if (gtmEventLabel) {
					return gtmEventLabel;
				} else if ($element.is('div.aa-slider.js-slider')) {
					return "Slider";
				} else if (event.target && event.target.nodeName === 'IMG') {
					return event.target.alt;
				} else if ($element.is("input")) {
					return $element.val();
				}

				const href = $element.attr("href");
				if (href && href.startsWith("mailto:")) {
					return "Kontakt E-Mail";
				}
				return T.Utils.Format.normalizeSpace($element.text());
			}
			return '';
		},

		_getEventActionByElement: function _getEventActionByElement($element) {
			let eventAction = '';
			if ($element.length) {
				eventAction = $element.data("gtm-eventaction");
				if (!eventAction) {
					if ($element.parents('[data-gtm-eventaction]').length > 0) {
						eventAction = $element.parents('[data-gtm-eventaction]').first().data("gtm-eventaction");
					} else if ($element.data("t-name") === "BasicBtn" || $element.attr('href') === '#') {
						eventAction = "BasicBtn";
					} else {
						eventAction = 'richtext_link';
					}
				}
				eventAction += ' | click';
			}
			return eventAction;
		},

		_isTeaserVisible: function _isTeaserVisible(element) {
			const $element = $(element);
			return $element.is(':visible') || T.Utils.View.isInViewport($element);
		}
	});
}(jQuery));