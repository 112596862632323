(function ($) {
	'use strict';
	/**
	 * BasicMovie module implementation.
	 *
	 * @author  <s.vogt@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicMovie
	 * @extends T.Module
	 */
	T.Module.BasicMovie = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Object} */
		cld: null,

		/** @type {HTMLElement} */
		$swiperPreloader: null,

		/** @type {jQuery} */
		$default: null,

		/** @type {jQuery} */
		$cloudinaryContainer: null,

		/** @type {jQuery} */
		$movieContainer: null,

		/** @type {jQuery} */
		$previewImage: null,

		/** @type {Boolean} */
		isLoaded: false,

		/** @type {jQuery} */
		$cloudinaryDefaultImage: null,
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$ctxID = this.$ctx.data('t-id');
			this.isInSwiper = this.$ctx.closest('.swiper').length;
			this.$default = this.$ctx.find('.mm-default');
			this.$cloudinaryDefaultImage = this.$default.length ? this.$default : null;
			this.$cloudinaryContainer = this.$ctx.find('.mm-cloudinary-container');
			this.$movieContainer = this.$ctx.find('.mm-movie-container');
			this.$previewImage = this.$ctx.find('.mm-poster-img');

			// movies should be handelt via swiper
			if (this.isInSwiper) {
				if (this.$cloudinaryContainer.length) {
					// add missing swiper preloader
					this.$swiperPreloader = S.Utils.SwiperHelper.appendSwiperPreloader(this.$cloudinaryContainer);
				}
			}
			else {
				// if image is not loaded and has a data-src attribute (indicates lazyloading)
				// add class to be considered in the lazyloading process
				if (this.$previewImage?.data('src') && !this.$previewImage?.hasClass(S.Lazy.lazyloadClass.loadedClass)) {
					this.$previewImage.addClass(S.Lazy.lazyloadClass.lazysizes);
				}
			}

			resolve();
		},

		/**
		 * default cb function from observer when lazy loading
		 * is triggered or none lazy loading is needed/given
		 *
		 * @param target :: which initialized the lazy call
		 * @param {{instance, $target, tId, $nearestModule}} dataObj
		 * @private
		 */
		_lazyUpdate(target, dataObj)// eslint-disable-line
		{
			// swiper handles the initialisation by itself for its children in basicgallery.js
			if (!this.isInSwiper) {
				// ew72 player
				if (this.$movieContainer.length) {
					this.initMovie(this.$movieContainer);
				}
				// cloudinary
				if (this.$cloudinaryContainer.length) {
					this.initCloudinary(this.$cloudinaryContainer);
				}
				this.isLoaded = true;
			}
		},

		/**
		 * initMovie
		 * @param {jQuery} $container
		 */
		initMovie: function ($container) {
			const poster = $container.siblings('.mm-poster-container').eq(0),
				posterImg = poster.find('.mm-poster-img').eq(0),
				movieSrc = $container.data('src') || '',
				posterSrc = $container.data('poster') || '';

			if (!$container.hasClass('is-init')) {
				if ($container.data('mode') === '3d') {
					// add immersive 3d class for scss
					this.$ctx.addClass('has-immersive-3d-video');

					poster.off().on('click', () => {
						// reset
						this.resetAll3DMovies();

						// set status class
						$container.addClass('is-init');

						// init immersive player
						$container.immersive({
							//debug: true, // ?debug=1 works

							// poster - optional
							poster: posterSrc,
							video: {
								// mindestens ein video, unterschiedliche auflösungen möglich
								src: [
									{
										src: movieSrc
									}
								]
							}
						});

						// play directly
						$container.immersive('play');

						const $swiper = $container.closest('.swiper');

						if ($swiper.length) {
							// just a custom event for 3d movies
							$swiper[0].swiper.emit('movieIsPlaying', { $container: $container, type: '3d' });
						}
					});
				}
				else {
					// add immersive class for scss
					this.$ctx.addClass('has-immersive-video');

					// init immersive player
					$container.immersive({
						//debug: true, // ?debug=1 works

						// poster - optional
						poster: posterSrc,
						video: {
							// mindestens ein video, unterschiedliche auflösungen möglich
							src: [
								{
									src: movieSrc
								}
							]
						}
					});

					// set status class
					$container.addClass('is-init');
				}

				posterImg.on('keyup', (el) => {
					if (el.which === 13) {
						poster.trigger('click');
					}
				});
			}
		},

		/**
		 * handle cloudinary movies initialisation
		 * @param {jQuery} $container
		 */
		initCloudinary: function ($container) {
			const { hostname, publicId, cloudname, title } = $container.data(),
				$video = $container.find('video'),
				isLoaded = $video.attr('id'), // check if video has already been initialized,
				options = {};

			if (hostname && publicId && !isLoaded)
			// if (hostname && publicId && !isLoaded)
			{
				this.$cloudinaryDefaultImage?.remove();

				if (title) {
					options.info = {
						title: title,
					};
				}

				const moviePlayerId = `_${Math.random().toString(36).substr(2, 9)}`, //NOSONAR usage checked
					cld = cloudinary.Cloudinary.new({ cloud_name: cloudname, private_cdn: true, secure_distribution: hostname });

				$video.attr('id', moviePlayerId);

				/**
				 * create video player instance cloudinary
				 *
				 * Reference:
				 * ##########
				 * https://cloudinary.com/documentation/video_player_api_reference
				 */
				const vPlayer = cld.videoPlayer(`#${moviePlayerId}`, {
					colors: {
						'accent': '#ffcc00'
					},
					controls: true,
					autoplay: false,
					preload: 'metadata', // metadata: Only load the video metadata.
					cloudinaryAnalytics: false,
					//sourceTypes,
					//fluid: true
				});

				vPlayer.videojs.log.level('error');
				vPlayer.source(publicId, options);
				$container.addClass('mm-cloudinary-container--loaded');

				// if its not null, remove it
				this.$swiperPreloader?.remove();

				const $swiper = $container.closest('.swiper');

				if ($swiper.length) {
					/**
					 * add more events if needed
					 *
					 * ref:
					 * https://cloudinary.com/documentation/video_player_api_reference#events
					 */
					vPlayer.on('play', () => {
						// just a custom event for 3d movies
						$swiper[0].swiper.emit('cloudinaryMovie', { $container: $container, event: 'play' });
					});

					vPlayer.on('pause', () => {
						// just a custom event for 3d movies
						$swiper[0].swiper.emit('cloudinaryMovie', { $container: $container, event: 'pause' });
					});
				}
			}
		},

		/**
		 * just reset all movies with a mm-movie-container and the is-init (class is added in the 3d function)
		 */
		resetAll3DMovies() {
			const $alreadyInitializedMovie = $('.m-basic-movie .mm-movie-container.is-init');

			// reset
			if ($alreadyInitializedMovie.length) {
				$alreadyInitializedMovie.immersive('pause')
					.removeClass('is-init')
					.html('');
			}
		},
	});
}(jQuery));