'use strict';
(function () {

	T.Utils = T.Utils || {};

	T.Utils.ApiQueue = {
		items: null,
		currentItemIndex: null,
		lastUrl: null,
		currentData: null,
		errorCallback: null,
		successCallback: null,
		clear: function clear() {
			this.items = [];
		},
		execute: function execute(data, errorCallback, successCallback) {
			this.currentData = data;
			this.errorCallback = errorCallback;
			this.successCallback = successCallback;

			if (!this.items || this.items.length === 0) {
				return;
			}
			this.currentItemIndex = -1;
			this._executeNext();
		},
		_executeItem: function _executeItem(item) {
			let data = item.getData();
			if (!data) {
				data = this.currentData;
			}
			item.execute(data, this._executeNext.bind(this), this._onExcuteItemError.bind(this));
		},
		isQueued: function isQueued() {
			return this.items && this.items.length > 0;
		},
		_executeNext: function _executeNext() {
			this.currentItemIndex += 1;
			if (this.currentItemIndex < this.items.length) {
				this._executeItem(this.items[this.currentItemIndex]);
			}
			else {
				if (this.successCallback) {
					this.successCallback();
				}
			}
		},
		_onExcuteItemError: function _onExcuteItemError(response) {
			const item = this.items[this.currentItemIndex];
			if (item.onError) {
				item.onError(response, this.errorCallback);
			}
			else {
				this.errorCallback(response);
			}
		},
		_queueOrUpdate: function _queueOrUpdate(item, isFirstPosition) {
			let foundIndex = -1;
			for (let index = 0; index < this.items.length; index++) {
				if (this.items[index].key === item.key) {
					foundIndex = index;
					break;
				}
			}

			if (foundIndex > 0) {
				this.items[foundIndex] = item;
			}
			else {
				if (!isFirstPosition) {
					this.items.push(item);
				}
				else {
					this.items.unshift(item);
				}
			}
		},
		queue: function queue(item, isFirstPosition) {
			if (!this.items) {
				this.items = [];
			}
			else {
				if (window.location.href !== this.lastUrl) {
					this.items = [];
				}
			}
			this.lastUrl = window.location.href;

			this._queueOrUpdate(item, isFirstPosition);
		},
		queueFirst: function queueFirst(item) {
			this.queue(item, true);
		}

	};
})();
