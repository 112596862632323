(function ($)
{
    'use strict';

    /**
     * BasicInputFile module implementation.
     *
     * @author  <w.eberts@edelweiss72.de>
     * @namespace T.Module
     * @class BasicInputFile
     * @extends T.Module
     */
    T.Module.BasicInputFile = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $input: null,

        /** @type {string} */
        error: '',

        /** @type {boolean} */
        valid: false,

        /** @type {T.Module.BasicForm} BasicForm: we can be validated */
        BasicForm: null,

        /** @type {boolean} BasicForm: do we show a valid/error symbol? */
        useCheck: false,

        /** @type {jQuery} BasicForm: just a reference */
        $row: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$input = this.$ctx;

            resolve();
        },

        /**
         *
         * @returns {string}
         */
        getType: function ()
        {
            return this.$input.attr('type');
        },

        /**
         *
         * @returns {boolean}
         */
        isVisible: function ()
        {
            return this.$input.is(':visible');
        },

        /**
         *
         * @returns {boolean}
         */
        isChecked: function ()
        {
            return this.$input.is(':checked');
        },

        /**
         *
         * @returns {boolean}
         */
        isRequired: function ()
        {
            return this.$input.is('[required]');
        },

        /**
         *
         * @returns {boolean}
         */
        isValid: function ()
        {
            return this.valid;
        },

        /**
         *
         * @param status
         * @param showStatus
         */
        setValid: function (status, showStatus = true)// eslint-disable-line
        {
            this.valid = status;

            if (status)
            {
                this.setError('', false);
            }

            return status;
        },

        /**
         *
         * @returns {boolean}
         */
        isError: function ()
        {
            return this.error !== '';
        },

        /**
         *
         * @param {string} [type='']
         * @param {boolean} [showStatus=true]
         * @returns {boolean}
         */
        setError: function (type = '', showStatus = true)// eslint-disable-line
        {
            this.error = type;

            if (type)
            {
                this.setValid(false, false);
            }

            return !type;
        },

        /**
         *
         * @returns {string}
         */
        getError: function ()
        {
            return this.error;
        },

        /**
         *
         * @param {boolean} [showStatus=true]
         * @returns {*}
         */
        validate: function (showStatus = true) // eslint-disable-line
        {
        }
    });
}(jQuery));
