(function ($) {
	'use strict';
	/**
	 * Membership decorator implementation for the BasicClusterBox component.
	 *
	 * @author  <carsten.meiselbach@namics.com>
	 * @namespace T.Module
	 * @class Rrv
	 * @extends T.Module
	 */
	T.Module.BasicClusterBox.Membership = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			const that = this;
			this.$ctx = $(this._ctx);
			this.has_loggedin_content = this.$ctx.find('.has-loggedin');
			this.no_loggedin_content = this.$ctx.find('.no-loggedin');
			this.has_membership_content = this.$ctx.find('.has-membership');
			this.no_membership_content = this.$ctx.find('.no-membership');
			this.has_loggedin_content.hide();
			this.getUserData(this._displayContent.bind(that));
			this._parent.start(resolve);
			this.$ctx.find('.js-login').on('click', this._authorizeOnClick.bind(this));
		},

		_authorizeOnClick(event) {
			event.preventDefault();
			T.Utils.Auth.authorize();
		},
		/**
		 * Displays the user data for the Cluster box dialogue using css classes for table items.
		 *
		 * @param { function } user data: from session storage
		 */
		_displayContent(user) {
			if (user) {
				this.no_loggedin_content.addClass('h-hidden');
				this.has_loggedin_content.removeClass('h-hidden');

				if (user.UserData.IstMitglied) {
					this.$ctx.find('.hasmembership-rate').text(user.UserData.Mitgliedschaft.TarifInformation.Beschreibung);

					// Set yearly rate with currency.
					let currentTarifBeitrag = user.UserData.Mitgliedschaft.TarifInformation.Beitrag.toString();

					if (currentTarifBeitrag.match(/\.[0-9]{1}$/g)) { //NOSONAR
						currentTarifBeitrag = `${currentTarifBeitrag}0`;
					}
					currentTarifBeitrag = currentTarifBeitrag.replace('.', ',');
					this.$ctx.find('.hasmembership-rateprice').text(`${currentTarifBeitrag} € / Jahr`);

					this.$ctx.find('.hasmembership-street').text(user.UserData.Anschrift.Strasse);
					this.$ctx.find('.hasmembership-streetno').text(user.UserData.Anschrift.Hausnummer);
					this.$ctx.find('.hasmembership-zip').text(user.UserData.Anschrift.Postleitzahl);
					this.$ctx.find('.hasmembership-city').text(user.UserData.Anschrift.Ort);

					this.has_membership_content.removeClass('h-hidden');
					this.no_membership_content.addClass('h-hidden');
					this.has_membership_content.show();
					this.has_loggedin_content.show();
				} else {
					this.has_membership_content.addClass('h-hidden');
					this.no_membership_content.removeClass('h-hidden');
					this.no_membership_content.show();
					this.has_loggedin_content.show();
				}
			} else {
				this.has_loggedin_content.addClass('h-hidden');
				this.no_loggedin_content.removeClass('h-hidden');
			}
		},

		/**
		 * Gets the user data from the application identity. Currently this call may result in several redirects to get a token for reading the user data.
		 *
		 * @param {function} name callback: the callback to call with optional user data after processing finished.
		 */
		getUserData(callback) {
			T.Utils.Auth.getResolvedIdentity(callback);
		}
	});
}(jQuery));
