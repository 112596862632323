'use strict';
(function ($) {
	// fix for num pad decimal key
	const _origNumPlugin = $.fn.number;
	if ('function' === typeof (_origNumPlugin)) {
		$.fn.number = function (number) {
			if (number === true && this.is('input:text')) {
				this.on('keydown.format', function (e) {
					const code = (e.keyCode ? e.keyCode : e.which);
					if (110 === code) {
						const e = $.Event("keydown");
						e.keyCode = 188;
						e.which = 188;
						$(this).trigger(e);
					}
				});
			}
			_origNumPlugin.apply(this, arguments);
		};
	}
})(jQuery);
