(function($) {
    'use strict';
    /**
     * BasicText module implementation.
     *
     * @author m.haeusgen <m.haeusgen@edelweiss72.de>
     * @namespace T.Module
     * @class BasicText
     * @extends T.Module
     */
    T.Module.BasicText = T.createModule({

        start: function(resolve) {
            this.$ctx = $( this._ctx );

            resolve();
        }

    });
}(jQuery));
