(function ($) {
	'use strict';

	/**
	 * compare decorator implementation for the BasicTableToggle module.
	 *
	 * @author  <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module.BasicTableToggle
	 * @class RowFilter
	 * @extends T.Module
	 */
	T.Module.BasicTableToggle.RowFilter = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {Object} */
		$tr: [],

		selectorCustomizeText: '.mm-customize-text',


		start: function (resolve) {

			const that = this;

			this.$ctx = $(this._ctx);

			this.$toggleBtn = this.$ctx.find('.js-toggle-btn');
			this.$toggleRow = this.$ctx.find('.js-toggle-row');
			this.$FilterIconBtn = this.$ctx.find('.mm-customize-view');
			this.$FilterTextBtn = this.$ctx.find(this.selectorCustomizeText);
			this.$FilterTextBtnDefaultText = this.$ctx.find(this.selectorCustomizeText).text();
			this.$filter = this.$ctx.find('.mm-filter-layer');

			this.filterGroupId = this.$ctx.data('js-filtergroup-id');

			this.activefilters = [];

			// collect each tableToggle elem with same filter id to sync filter states
			if (typeof this.filterGroupId !== 'undefined' && this.filterGroupId.length) {
				this.$filterGroup = [];
				// eslint-disable-next-line
				this.$filterGroup.push(document.querySelectorAll(`.m-basic-table-toggle[data-js-filtergroup-id=\"${this.filterGroupId}\"]`));
			}


			this.initFiltering();

			this._events.on('BasicTableToggle.syncFilter', (context, activeFilters) => {

				if (context !== that.$ctx) {
					that.activefilters = activeFilters;
					that.filterTables();
					that.resetInfoMessage();

				}
			});

			this.checkFilterBtnText();

			this._parent.start(resolve);
		},

		syncFilter() {
			const that = this;
			const filterData = this.$filter.find('.mm-layer-content');

			that.activefilters = [];

			filterData.find('ul input').each((i, el) => {

				let inputName = $(el).attr('id');
				const divider = inputName.lastIndexOf('_');

				inputName = inputName.substring(0, divider);

				if ($(el).is(':checked')) {
					that.activefilters.push(inputName);
				}

				$.each(this.$filterGroup, (index, elem) => {
					$(elem).find('.mm-filter-layer').each((index2, elem2) => {
						$(elem2).find(`[id^="${inputName}"]`).prop('checked', $(el).is(':checked'));
					});
				});
			});

			this._events.emit('BasicTableToggle.syncFilter', this.$ctx, that.activefilters);
		},

		/**
		 * resets info message about least amount of active cbx in all grouped filters (except the one, that fired => see call() )
		 */
		resetInfoMessage() {
			this.$ctx.find('.js-msg-checkbox').removeClass('is-active');
		},

		filterTables() {
			const that = this,
				$target = this.$filter.find('.mm-layer-content ul input');

			$target.each((i, el) => {

				const uncheckedInputsData = $(el).data('filter-product'),
					hideTableHead = this.$ctx.find(`th[data-filter-product="${uncheckedInputsData}"]`),
					hideTableHeadIndex = hideTableHead.index(),
					hideTable = this.$ctx.find(`.mm-table-content[data-filter-product="${uncheckedInputsData}"]`);

				if ($(el).attr('type') === 'checkbox') {
					if (!$(el).prop('checked')) {

						if (hideTableHead.length) {
							hideTableHead.addClass('is-hidden');

							if (that.$filterGroup.length) {
								$.each(this.$filterGroup, (index, groupElem) => {
									$(groupElem).find('tr').each((i, elem) => {
										$(elem).find('td').eq(hideTableHeadIndex).addClass('is-hidden');
									});
								});
							}
						}
						if (hideTable.length) {
							hideTable.addClass('is-hidden');
						}

					} else {

						hideTableHead.removeClass('is-hidden');
						hideTable.removeClass('is-hidden');

						if (that.$filterGroup.length) {

							$.each(this.$filterGroup, (index, groupElem) => {
								$(groupElem).find('tr').each((i, elem) => {
									$(elem).find('td').eq(hideTableHeadIndex).removeClass('is-hidden');
								});
							});
						}
					}
				}
			});

			this.checkFilterBtnText();
		},

		checkFilterBtnText() {
			let count = 0;

			this.$filter.find('.mm-layer-content ul input').each((index, item) => {
				if (!$(item).prop('checked')) {
					count++;
				}
			});

			if (count > 0) {
				this.$ctx.find(this.selectorCustomizeText).text(this.$FilterTextBtn.data('filter-reset-text'));
			} else {
				this.$ctx.find(this.selectorCustomizeText).text(this.$FilterTextBtnDefaultText);
			}
		}

	});
}(jQuery));
