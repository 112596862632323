(function ($)
{
    'use strict';
    /**
     * CompareProduct module implementation.
     *
     * @author  <n.bork@edelweiss72.de>
     * @namespace T.Module
     * @class CompareProduct
     * @extends T.Module
     */
    T.Module.CompareProduct = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {Number} */
        ctxTID: null,

        /** @type {Boolean} */
        allowClick: true,

        /** @type {Boolean} */
        allowClickTrigger: false,

        /** @type {jQuery} */
        $swiperContainer: null,

        /** @type {jQuery} */
        $swiperSlidesWrapper: null,

        /** @type {Object[]} */
        $swiperSlides: null,

        /** @type {jQuery} */
        $swiperBtnLeft: null,

        /** @type {jQuery} */
        $swiperBtnRight: null,

        /** @type {Number} */
        $spaceBetween: 0,

        /** @type {Boolean} */
        firstInit: true,

        /** @type {jQuery} */
        $swiper: null,

        /** @type (Number) */
        initialOffset: 0,

        /** @type {jQuery[]} */
        $deleteSlide: null,

        /** @type {jQuery} */
        $header: null,

        /** @type {Boolean} */
        isLazy: true,

        /** @type {Boolean} */
        isLoaded: false,

        /**
         * Initialize.
         *
         * @param {function} resolve
         * @param {Object} decoratorData
         */
        start: function (resolve, decoratorData)
        {
            this.$ctx = $(this._ctx);

			// classes as strings
            this.swiperWrapperString = '.swiper-wrapper';
            this.swiperSlideString = '.swiper-slide';
            this.result = '.oo-result';
            this.compareProductBox = '.oo-compare-product-box';

            this.ctxTID = this.$ctx.data('t-id');
            this.$swiperContainer = this.$ctx.find('.swiper');
            this.$swiperSlidesWrapper = this.$swiperContainer.find(this.swiperWrapperString);
            this.$swiperSlides = this.$swiperSlidesWrapper.find(this.swiperSlideString);
            this.$swiperBtnLeft = this.$ctx.find('.swiper-button-prev');
            this.$swiperBtnRight = this.$ctx.find('.swiper-button-next');
            this.$spaceBetween = decoratorData.spaceBetween || 0;

            this.$header = $(document.querySelector('body > .l-outer > .m-basic-header'));

            // remove lazyload class from picture/img and replace them with swiper-lazy class
            S.Utils.SwiperHelper.addLazyClassToImg(this.$swiperSlides, true);

            resolve();
        },

        /**
         * default cb function from observer when lazy loading
         * is triggered or none lazy loading is needed/given
         *
         * @param target :: which initialized the lazy call
         * @param {{instance, $target, tId, $nearestModule}} dataObj :: data about the triggering element if needed
         * @private
         */
        _lazyUpdate(target, dataObj)// eslint-disable-line
        {
            // init swipe
            this.initSwiper();

            // init event
            this.initEvent();

            // init cell height
            this.initHeight();

            // init delete
            this.initDelete();

            // init sortable
            this.initSortable();

            // sticky
            this.initFixed();
        },

        /**
         * init swipe
         */
        initSwiper: function ()
        {
            // if sitecore edit mode
            const simulateTouch = this.$ctx.data('mode') !== 'edit',
                keyboardControl = this.$ctx.data('mode') !== 'edit';

            // init swiper container
            this.$swiper = new Swiper(this.$swiperContainer[0], {
                spaceBetween: this.$spaceBetween,
                slidesPerView: 'auto',
                slidesPerGroupAuto: true,
                simulateTouch: simulateTouch,
                watchSlidesProgress: true,
                preloadImages: false,
                allowTouchMove: false,
                preventInteractionOnTransition: true,
                keyboard: {
                    enabled: keyboardControl,
                },
                navigation: {
                    prevEl: this.$swiperBtnLeft[0],
                    nextEl: this.$swiperBtnRight[0],
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        speed: 500,
                    },
                    1024: {
                        speed: 1500,
                    }
                },
                lazy: {
                    checkInView: true,
                    loadPrevNextAmount: 2,
                    loadPrevNext: true,
                    loadOnTransitionStart: true,
                },
                on: {
                    init: (swiper) =>
                    {
                        // enables/disables the swiper
                        // - In the old logic, the slides were centred
                        S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                    },
                    resize: (swiper) =>
                    {
                        S.Utils.delayed(`compareproduct-check-${this.ctxTID}`, 100, () =>
                        {
                            // enables/disables the swiper
                            // - In the old logic, the slides were centred
                            S.Utils.SwiperHelper.checkIfSlidesFitAndLockSwiper(swiper, this.$ctx);
                        });
                    },
                    /**
                     * @param {Object} swiper
                     * @param {HTMLElement} slideEl
                     * @param {HTMLElement} imageEl :: if needed
                     */
                    lazyImageLoad: (swiper, slideEl) =>
                    {
                        const $slide = $(slideEl);

                        // kept the same old logic from loadVisibleImages() Function
                        $slide.removeClass('is-init').find('.swiper-lazy-preloader').fadeOut();
                        $slide.find('.js-init').removeClass('js-init');
                    },
                },
            });

            if (typeof this._sandbox.swiperInstance === 'undefined')
            {
                this._sandbox.swiperInstance = {};
            }
            this._sandbox.swiperInstance[this.$ctx.data('t-id')] = this.$swiper;
        },

        /**
         * toggle
         */
        initEvent: function ()
        {
            this.$ctx.find('.js-compare-head').on('click', (e) =>
            {
                const $clickedHead = $(e.target),
                    myIndex = $clickedHead.parent().index() + 1;

                this.$ctx.find(`.swiper-slide > :nth-child(${myIndex}) > .js-compare-content`).stop().slideToggle(() =>
                {
                    $clickedHead.first().prev().toggleClass('oo-opened');
                });
            });
        },

        /**
         * calculate height
         */
        initHeight: function ()
        {
            // dynamic cells height
            const cells = this.$ctx.find(this.swiperSlideString).eq(0).find(this.result).length;

            this.$ctx.find(this.swiperSlideString).each((index) =>
            {
                for (let i = 0; i < cells; i++)
                {
                    this.$ctx.find(this.swiperSlideString).eq(index).find(this.result).eq(i).addClass(`row-${i}`);
                }
            });

            for (let i = 0; i < cells; i++)
            {
                let height = 0;

                this.$ctx.find(`.row-${i}`).each(function (index, item)
                {
                    if ($(item).height() > height)
                    {
                        height = $(item).height();
                    }
                });

                this.$ctx.find(`.row-${i}`).height(height);
            }

            // dynamic header height
            let headerHeight = 0;

            this.$ctx.find('.oo-header-box').each(function ()
            {
                if ($(this).height() > headerHeight)
                {
                    headerHeight = $(this).height();
                }
            });

            this.$ctx.find('.oo-header-box').height(headerHeight);

            // dynamic headline height
            const headline = this.$ctx.find(this.swiperSlideString).eq(0).find('.oo-head').length;

            this.$ctx.find(this.swiperSlideString).each((index) =>
            {
                for (let i = 0; i < headline; i++)
                {
                    this.$ctx.find(this.swiperSlideString).eq(index).find('.oo-head').eq(i).addClass(`rowHead-${i}`);
                }
            });

            for (let i = 0; i < headline; i++)
            {
                let headlineHeight = 0;

                this.$ctx.find(`.rowHead-${i}`).each(function (index, item)
                {
                    if ($(item).height() > headlineHeight)
                    {
                        headlineHeight = $(item).height();
                    }
                });

                this.$ctx.find(`.rowHead-${i}`).height(headlineHeight);
            }

            // last cell height
            let lastCell = 0;

            this.$ctx.find('.js-compare-content--last').each(function ()
            {
                if ($(this).height() > lastCell)
                {
                    lastCell = $(this).height();
                }
            });

            this.$ctx.find('.js-compare-content--last').height(lastCell);

            // preloading sequence
            this.$ctx.addClass('is-init');
        },

        /**
         * delete slides
         */
        initDelete: function ()
        {
            this.$ctx.find('.oo-figure-icon').on('click', (e) =>
            {
                this.$deleteSlide = $(e.target).closest(this.swiperSlideString);
            });

            this.$ctx.find('.js-remove-btn .a-basic-btn').on('click', () =>
            {
                this.$deleteSlide.remove();

                // swiper update
                this.$swiper.update(true);
            });
        },

        /**
         * sortable products
         */
        initSortable: function ()
        {
			const borderSettings = '1px solid #afafaf',
				borderLeft = 'border-left';

            this.$ctx.find(this.swiperWrapperString).sortable({
                items: this.swiperSlideString,
                axis: 'x',
                opacity: 0.85,
                revert: 100,
                start: () =>
                {
                    this.$ctx.find(this.compareProductBox).css(borderLeft, borderSettings);
                    this.$ctx.find(this.result).css('border-right', borderSettings);
                    this.$ctx.find('.oo-test-information').css(borderLeft, borderSettings);
                },
                stop: () =>
                {
                    this.$ctx.find(this.compareProductBox).css(borderLeft, 'none');
                    this.$ctx.find(this.result).css('border-right', 'none');
                    this.$ctx.find('.oo-test-information').css(borderLeft, 'none');
                }
            });

            this.$ctx.find(this.swiperWrapperString).disableSelection();
        },

        /**
         * set fixed position
         */
        setFixed: function ()
        {
            const $stickyProdBox = $(this.$ctx.find(this.compareProductBox)),
                $fixedButtons = $(this.$ctx.find('.swiper-button-next, .swiper-button-prev')),
                tableBoundings = this.$ctx[0].getBoundingClientRect(),
                headerBounds = this.$header[0].getBoundingClientRect(),
				paddingTop = 'padding-top';

            let headerThreshold = 0,
                maxBoxHeight = $stickyProdBox.eq(0).height();

            // fix height of the whole product row by setting the largest height val to each box of the set
            $stickyProdBox.each((index, prodBox) =>
            {
                if ($(prodBox).height() > maxBoxHeight)
                {
                    maxBoxHeight = $(prodBox).height();
                }
            });

            $stickyProdBox.height(maxBoxHeight);

            // update header threshold if header is visible (otherwise its above the visible viewport area)
            if (headerBounds.top > -1)
            {
                headerThreshold = headerBounds.height;
            }

            // reset if table is scrolled upwards beyond viewport.top (calc distance of table.bottom to header.bottom)
            if (Math.abs(tableBoundings.top) + maxBoxHeight + headerThreshold > tableBoundings.height)
            {
                $stickyProdBox.removeAttr('style').removeClass('is-sticky');
                $fixedButtons.removeAttr('style');
                this.$ctx.find(this.swiperSlideString).css(paddingTop, 0);
            }
            else
            {
                // stick on scroll down
                if (tableBoundings.top + maxBoxHeight < headerThreshold)
                {
                    const boxTopOffset = Math.abs(tableBoundings.top) + headerThreshold;

                    $stickyProdBox.css({
                        'position': 'absolute',
                        'top': boxTopOffset
                    }).addClass('is-sticky');

                    $fixedButtons.css({
                        'position': 'absolute',
                        'top': boxTopOffset + 125
                    });

                    // If the product box is sticky, the swiper does not know that it is in the visible area, as it is only moved with position absolute.
                    // Therefore we have to do this manually, but only if the product box is not in the original place when it is initially loaded.
                    if (this.firstInit)
                    {
                        // only once
                        this.firstInit = false;

                        S.Utils.SwiperHelper.forceLazyLoadingOnVisbSlides(this.$swiper);
                    }

                    this.$ctx.find(this.swiperSlideString).css(paddingTop, maxBoxHeight);
                }

                // unstick on scroll up
                else
                {
                    $stickyProdBox.removeAttr('style').removeClass('is-sticky');
                    $fixedButtons.removeAttr('style');

                    this.$ctx.find(this.swiperSlideString).css(paddingTop, 0);
                }
            }
        },

        /**
         * init fixed position
         */
        initFixed: function ()
        {
            // call on init
            this._callFunc();

            window.addEventListener('scroll', this._callFunc.bind(this), {passive: false});
        },

        _callFunc: function ()
        {
            // this delay overwrites itself, and will be executed once when the user stop scrolling (same S.Utils.delayed name)
            // and the execution delay (old value 200ms) is too high for this function to be executed.
            // Before it could be executed, it is already overwritten by the new delay with the same name. And multiple instances
            // of the compareProduct would overwrite each other here (same name) - changed that and added a module-id to string
            //
            // lowered the value to 10ms - now the function get called on scroll
            S.Utils.delayed(`compareproduct-${this.$ctx.data('t-id')}`, 10, () =>
            {
                this.setFixed();
            });
        }
    });
}(jQuery));
