'use strict';
(function ($) {
	/**
	 * MeinAdacDashboardBonusesList module implementation.
	 *
	 * @author Roland Dollansky <roland.dollansky@adac.de>
	 * @namespace T.Module
	 * @class MeinAdacDashboardBonusesList
	 * @extends T.Module
	 */
	T.Module.MeinAdacDashboardBonusesListV1 = T.createModule({
		_identityAware: true,
		$ctx: null,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);

			this._readConfiguration();
			this._requestBonusesListData();

			resolve();
		},

		_readConfiguration: function _readConfiguration() {
			T.Utils.Application.init();

			this._api = this.$ctx.data("backend") || T.Utils.Application.getApi();
			this._apibonuseslist = this.$ctx.data("apimybonusestracking");
			this._apimybonusesimage = this.$ctx.data("apimybonusesimage");
			this._apidhltrackingurl = this.$ctx.data("apidhltrackingurl");
			this._labelpraemie = this.$ctx.data("label-praemie");
			this._errorList = this.$ctx.data("label-errorlist");
			this.$baseContainer = $(this.$ctx.find('.bonuses-container'));
			this.$baseBonus = $(this.$baseContainer.find('#bonuses-list-template').html());
		},

		_requestBonusesListData: function _requestBonusesListData() {
			T.Utils.Auth.getBearerToken((token) => {
				const url = T.Utils.Helper.updateUrlParameter({
					'scope': T.Utils.Auth.scope,
				}, T.Utils.Helper.appendURIPath(this._api, this._apibonuseslist));

				const options = {
					url: url,
					type: 'GET',
					headers: {
						Authorization: `Bearer ${token}`
					}
				};

				T.Utils.Ajax.fragment(options, this._handleBonusesListResponse.bind(this), this._handleBonusesListError.bind(this));
			});
		},

		_handleBonusesListResponse: function _handleBonusesListResponse(data) {
			const bonusesData = data?.Data;
			if (data?.Success && bonusesData && bonusesData.Sendungen.length > 0) {
				for (const element of bonusesData.Sendungen) {
					const sendung = element;
					const $clone = this.$baseBonus.clone();
					const headline = `${this._labelpraemie} : ${sendung.Praemienname}`;
					$clone.find('.js-bonus-headline').html(headline);
					$clone.find('.js-bonus-title').html(sendung.Praemienname);
					$clone.find('.js-bonus-creation-date').text(moment(sendung.Anlagedatum).format("L"));
					this._handleSendungssnummer(sendung, this._errorList);
					this._requestBonusesListImageData(sendung, $clone.find('.js-bonus-image'));
					this.$baseContainer.append($clone);
				}
				T.Utils.Helper.initAdditionalModules(this.$baseContainer);
			}
		},
		_handleSendungssnummer: function _handleSendungssnummer(sendung, errorList, $clone) {
			if ("000000000000" !== sendung.Sendungsnummer && sendung.Sendungsnummer.length > 0) {
				$clone.find('.js-bonus-tracking-nr').text(sendung.Sendungsnummer);
				const trackingUrl = this._apidhltrackingurl + sendung.Sendungsnummer;
				$clone.find('.js-tracking-link').attr('href', trackingUrl);
			}
			else if ("000000000000" === sendung.Sendungsnummer && errorList) {
				let sendungsNrText = errorList['default'] ? errorList['default'] : '';
				if (errorList[sendung.Praemiennummer]) {
					sendungsNrText = errorList[sendung.Praemiennummer];
				}
				$clone.find('.js-bonus-tracking-nr').text(sendungsNrText);
				$clone.find('.js-tracking-link').remove();
			}
		},
		_handleBonusesListError: function _handleBonusesListError() {
			if (!T.Utils.Helper.isExperienceEditor()) {
				this.$ctx.hide();
			}
		},
		_requestBonusesListImageData: function _requestBonusesListImageData(sendung, $element) {
			const url = T.Utils.Helper.updateUrlParameter({
				'bonusid': sendung.Praemiennummer
			}, this._apimybonusesimage);

			const options = {
				url: url,
				type: 'GET'
			};

			T.Utils.Ajax.fragment(options, this._handleBonusesListImageResponse.bind(this, $element), () => {
				// do nothing
			});
		},
		_handleBonusesListImageResponse: function _handleBonusesListImageResponse($element, imageUrl) {
			if (imageUrl) {
				$element.attr('src', imageUrl);
			}
		}

	});
}(jQuery));
