'use strict';
(function ($) {
	/**
	 * MoinAiTrigger module implementation.
	 *
	 * @author  <krystian.sadowski@namics.com>
	 * @namespace T.Module
	 * @class MoinAiTrigger
	 * @extends T.Module
	 */
	T.Module.MoinAiTrigger = T.createModule({
		$ctx: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this.readConfiguration();
			this._addListeners();
			resolve();
		},
		readConfiguration: function readConfiguration() {
			this._config = this.$ctx.data("config");
		},
		_addListeners: function _addListeners() {
			this.$ctx.on('click', this._initChatbot.bind(this, this.swapContent.bind(this)));
		},
		_initChatbot: function _initChatbot(swapContentCb) {
			const moinAiFrame = $($.find('.moin-ai-button'));
			if (moinAiFrame && moinAiFrame.length === 0) {
				const script = document.createElement('script');
				if (swapContentCb) {
					script.onload = () => {
						swapContentCb();
					};
				}
				script.type = "text/javascript";
				script.src = this._config.src;
				script.id = this._config.id;
				document.head.appendChild(script);
			}
			else {
				const moinAiFrameTrigger = moinAiFrame.contents().find('.knw_widget_button');
				if (moinAiFrameTrigger && moinAiFrameTrigger.length > 0) {
					moinAiFrameTrigger.click();
				}
			}
		}
	});
}(jQuery));