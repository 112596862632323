'use strict';
(function ($) {
	/**
	 * BassApproval module implementation.
	 *
	 * @author  <ibrahim.mahria@adac.de>
	 * @namespace T.Module
	 * @class BassApproval
	 * @extends T.Module
	 */
	T.Module.BassApproval = T.createModule({
		/** @type {jQuery} */
		$ctx: null,
		_user: null,

		sessionKey: "selector.bassapproval",

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			T.Utils.Application.init();
			this._sendRequest();
			resolve();
		},
		_sendRequest() {
			this._api = this.$ctx.data("backend") || T.Utils.Application.getApi();
			const url = T.Utils.Helper.appendURIPath(this._api, this.$ctx.attr('data-api'));

			const token = this.getUrlParameter('token');
			if (token) {
				T.Utils.Ajax.fragment(
					{
						url: url,
						type: 'POST',
						data: JSON.stringify({
							"Id": 1,
							"Token": token
						})
					});
			}
		},
		getUrlParameter(name) {
			name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); //NOSONAR
			const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
			const results = regex.exec(location.search);
			return results === null ? '' : decodeURIComponent(results[1]);
		}
	});
}(jQuery));
