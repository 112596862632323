(function ($) {
	'use strict';
	/**
	 * Chatbot decorator for the basicBtn module
	 *
	 * @author  <markus.hiller@mtc.berlin>
	 * @namespace T.Module
	 * @class BasicBtn
	 * @extends T.Module
	 */
	T.Module.BasicBtn.ChatbotTrigger = T.createDecorator({
		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$iframeChatbot: null,
		$chatbotContent: null,

		start: function (resolve) {
			this.$ctx = $(this._ctx);
			// click events
			this.clickevents();
			this.$iframeChatbot = $('#chatbotframe');
			this.$chatbotContent = $('#ChatbotContent');

			this._parent.start(resolve);
		},

		clickevents: function () {
			this.$ctx.on('click', () => {
				this.$iframeChatbot.attr('src', `data:text/html;charset=utf-8,${encodeURI(this.$chatbotContent.html())}`);
				this.$ctx.closest('.m-basic-text').addClass('h-hidden');
				this.$iframeChatbot.closest('.ll-main-shadowbox').removeClass('h-hidden');
			});
		}

	});
}(jQuery));
