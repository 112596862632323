'use strict';
(function ($) {
	/**
	 * SecondhandVehicleSearch module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class SecondhandVehicleSearch
	 * @extends T.Module
	 */
	T.Module.SecondhandVehicleSearch = T.createModule({
		_vehicleArtDefault: "PKW",
		_registrationMonthFld: 'select[name="ErstzulassungMonat"]',
		_registrationYearFld: 'select[name="ErstzulassungJahr"]',
		_vehicleArtFld: 'input[name="FahrzeugartId"]',
		_brandFld: 'select[name="MarkeId"]',
		_modelFld: 'select[name="Modell"]',
		_kmCoutnFld: 'input[name="Kilometerstand"]',
		_hsnFld: 'input[name="Hsn"]',
		_tsnFld: 'input[name="Tsn"]',

		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);

			this.$searchHeadline = this.$ctx.find('.js-pricesearch-head h2');
			this._readConfiguration();
			this._addListeners();
			this._setupValidation();
			this._requestSearchFacets({ registration: true });
			resolve();
		},
		_readConfiguration: function _readConfiguration() {
			this._config = this.$ctx.data("config");
			if (!this._config.currencyFormat) {
				this._config.currencyFormat = {};
			}
			this._resultHeadText = this.$searchHeadline.text();
		},
		_addListeners: function _addListeners() {
			this.$ctx.find(".js-form").submit(this.submitSearch.bind(this));
			this.$ctx.find(".js-sumit-search").on('click', this.submitSearch.bind(this));
			this.$ctx.find(this._vehicleArtFld).on('change', this._requestSearchFacets.bind(this, { brandsAndModels: true }));
			this.$ctx.find(this._vehicleArtFld).on('change', this._requestSearchFacets.bind(this, { registration: true }));
			this.$ctx.find(this._registrationYearFld).on('change', this._requestSearchFacets.bind(this, { brandsAndModels: true }));
			this.$ctx.find(this._brandFld).on('change', this._requestSearchFacets.bind(this, { modelsOnly: true }));
		},
		_setupValidation: function _setupValidation() {
			const valconf = {
				rules: {
					ErstzulassungMonat: {
						required: true
					},
					ErstzulassungJahr: {
						required: true
					},
					Kilometerstand: {
						digits: true,
						range: [5000, 300000],
						required: true
					},
					Hsn: {
						maxlength: 4,
						required: true
					},
					Tsn: {
						maxlength: 3,
						required: true
					},
					MarkeId: {
						required: true
					},
					Modell: {
						required: true
					}
				},
				messages: this._config.validationMessages || {}
			};
			T.Utils.Validation.init(this.$ctx, [this.$ctx.find("form")], valconf);
		},
		_requestSearchFacets: function _requestSearchFacets(reloadFlags) {
			T.Utils.View.startLoader();
			this._showError(false);
			this._clearSearchResuls();
			const url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiSearchFacets);
			const payload = {
				FahrzeugartId: this.$ctx.find(`${this._vehicleArtFld}:checked`).val() || this._vehicleArtDefault
			};

			if (reloadFlags.modelsOnly) {
				const val = this._getDropdownVal(this._brandFld);
				if (val) {
					payload.MarkeId = val;
				}
			}

			if (reloadFlags.modelsOnly || reloadFlags.brandsAndModels) {
				const val = this._getDropdownVal(this._registrationYearFld);
				if (val) {
					payload.ErstzulassungJahr = val;
				}

			}
			const request = {
				method: 'GET',
				url: url,
				data: payload
			};
			T.Utils.Ajax.fragment(request, this._handleSearchFacetsSuccess.bind(this, reloadFlags), this._handleSearchFacetsError.bind(this));
		},
		_handleSearchFacetsSuccess: function _handleSearchFacetsSuccess(reloadFlags, data) {

			if (data && data.Data) {

				this.$ctx.find(this._vehicleArtFld).parent(".mm-tile").toggleClass("h-hidden", true);
				this._updateVehicleTypes(data);


				if (reloadFlags.modelsOnly) {
					this._reloadModelDropdown(data);
				} else {
					if (reloadFlags.registration) {
						this._reloadRegistrationMonthDropdown(data);
						this._reloadRegistrationYearDropdown(data);
					}

					if (reloadFlags.brandsAndModels) {
						this._reloadModelAndBrandDropdowns(data);
					}
				}
			}

			T.Utils.View.stopLoader();
		},

		_updateVehicleTypes: function _updateVehicleTypes(data) {
			if (data.Data.Fahrzeugarten && data.Data.Fahrzeugarten.Facets) {
				data.Data.Fahrzeugarten.Facets.forEach((item) => {
					const $elm = this.$ctx.find(`${this._vehicleArtFld}[value="${item.Id}"]`);
					const $tile = $elm.parent(".mm-tile");
					$tile.toggleClass("h-hidden", false);
					if (!$tile.find(".mm-tile-desc-head").text()) {
						$tile.find(".mm-tile-desc-head").text(item.Name);
					}
				});
			}
		},

		_reloadRegistrationMonthDropdown: function _reloadRegistrationMonthDropdown(data) {

			const prevVal = this._getDropdownVal(this._registrationMonthFld);
			this._clearDropdown(this._registrationMonthFld);

			const $elm = this.$ctx.find(this._registrationMonthFld);
			(data.Data.ErstzulassungMonate || []).forEach((item) => {
				this._appendItems($elm, item, prevVal);
			});
		},
		_reloadRegistrationYearDropdown: function _reloadRegistrationYearDropdown(data) {

			const prevVal = this._getDropdownVal(this._registrationYearFld);
			this._clearDropdown(this._registrationYearFld);

			const $elm = this.$ctx.find(this._registrationYearFld);
			(data.Data.ErstzulassungJahre || []).forEach((item) => {
				this._appendItems($elm, item, prevVal);
			});
		},

		_appendItems: function _appendItems($elm, item, prevVal) {
			const $option = $("<option/>").attr('value', item).text(item);
			$elm.append($option);

			if (prevVal === item) {
				$option.prop("selected", true);
			}
		},

		_reloadModelAndBrandDropdowns: function _reloadModelAndBrandDropdowns(data) {
			this._clearDropdown(this._brandFld);

			if (data.Data.Marken && data.Data.Marken.Facets) {
				const $elm = this.$ctx.find(this._brandFld);
				data.Data.Marken.Facets.forEach((item) => {
					$elm.append($("<option/>").attr('value', item.Id).text(item.Name));
				});

				this._reloadModelDropdown(data);

			}
		},
		_reloadModelDropdown: function _reloadModelDropdown(data) {
			this._clearDropdown(this._modelFld);

			const $elm = this.$ctx.find(this._modelFld);
			data.Data.Modelle.Facets.forEach((item) => {
				$elm.append($("<option/>").attr('value', item).text(item));
			});

		},
		_handleSearchFacetsError: function _handleSearchFacetsError(error) {
			this._handleError(error);
		},
		_clearDropdown: function _clearDropdown(selector) {
			const $elm = this.$ctx.find(`${selector} option[value!=""]`);
			$elm.remove();
		},
		_getDropdownVal: function _getDropdownVal(selector) {
			const result = this.$ctx.find(selector).val();

			if ("0" !== String(result)) {
				return result;
			}
			return null;
		},
		_getSearchMode: function _getSearchMode() {
			if (this.$ctx.find(".js-search-type-lic.is-current").length) {
				return 2;
			}
			return 1;
		},
		_clearSearchResuls: function _clearSearchResuls() {
			this.$ctx.find(".js-pricesearch-head").toggleClass('h-hidden', true);
			this.$ctx.find(".js-pricesearch-results").toggleClass('h-hidden', true);
			this.$ctx.find(".js-result-image").toggleClass('h-hidden', true);
			this.$ctx.find(".js-pricesearch-table tbody").empty();
		},
		_showError: function _showError(show) {
			this.$ctx.find(".js-pricesearch-error").toggleClass('h-hidden', !show);
		},
		_requestPriceData: function _requestPriceData() {
			T.Utils.View.startLoader();
			this._clearSearchResuls();
			this._showError(false);
			let url = "";
			if (this._getSearchMode() === 2) {
				url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiSearchForHsnTsn);
			}
			else {
				url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this._config.apiPriceData);
			}

			const payload = 2 === this._getSearchMode()
				? {
					Hsn: this.$ctx.find(this._hsnFld).val(),
					Tsn: this.$ctx.find(this._tsnFld).val(),
					ErstzulassungJahr: this._getDropdownVal(this._registrationYearFld),
					ErstzulassungMonat: this._getDropdownVal(this._registrationMonthFld),
					Kilometerstand: this.$ctx.find(this._kmCoutnFld).val()
				}
				: {
					FahrzeugartId: this.$ctx.find(`${this._vehicleArtFld}:checked`).val() || this._vehicleArtDefault,
					MarkeId: this._getDropdownVal(this._brandFld),
					Modell: this._getDropdownVal(this._modelFld),
					ErstzulassungJahr: this._getDropdownVal(this._registrationYearFld),
					ErstzulassungMonat: this._getDropdownVal(this._registrationMonthFld),
					Kilometerstand: this.$ctx.find(this._kmCoutnFld).val()
				};
			const request = {
				method: 'GET',
				url: url,
				data: payload
			};
			T.Utils.Ajax.fragment(request, this._handlePriceDataSuccess.bind(this), this._handlePriceDataError.bind(this));
		},
		_handlePriceDataSuccess: function _handlePriceDataSuccess(data) {
			if (data && data.Data && data.Data.Gebrauchtfahrzeuge && data.Data.Count) {
				this.$searchHeadline.text(this._resultHeadText);
				this.$ctx.find(".js-pricesearch-title").text(data.Data.Gebrauchtfahrzeuge[0].Modell);
				const $image = this.$ctx.find(".js-result-image");

				let hasImage = false;
				const $tbody = this.$ctx.find(".js-pricesearch-table tbody");
				data.Data.Gebrauchtfahrzeuge.forEach((item) => {

					const vehicleName = `${item.Herstellername}-${item.Modell}`;

					const detailsPageUrl = T.Utils.Helper.appendURIPath(
						this._config.detailPageUrl,
						this._config.detailPageUrlSfx.interpolate({
							id: item.Id,
							name: T.Utils.Helper.createSeoUrl(vehicleName),
							year: this._getDropdownVal(this._registrationYearFld),
							month: this._getDropdownVal(this._registrationMonthFld),
							mileage: this.$ctx.find(this._kmCoutnFld).val()

						}));

					const price = item.BerechneterVerkaufsPreis
						? `${$.number(item.BerechneterVerkaufsPreis, this._config.currencyFormat.round,
							this._config.currencyFormat.decimalSep, this._config.currencyFormat.thousandSep)} ${this._config.currencyFormat.unit}`
						: this._config.textNoPriceYet;
					const $tr = $("<tr/>");

					if (this._config.detailPageUrl !== "/") {
						$tbody.append($tr.append($("<td/>").append($("<a/>").addClass(["a-basic-link", "h-space-s"]).attr("href", detailsPageUrl).text(item.Typ)),
							$("<td/>").text(price)));
					} else {
						$tbody.append($tr.append($("<td/>").text(item.Typ), $("<td/>").text(price)));
					}

					if (!hasImage && item.Bildname) {
						hasImage = true;
						const imgsrc = T.Utils.Helper.appendURIPath(this._config.baseImageUrl, item.Bildname);
						$image.find("picture source").attr("data-srcset", imgsrc);
						$image.find("picture source").attr("srcset", imgsrc);
						$image.find("img").attr("data-src", imgsrc);
						$image.find("img").attr("src", imgsrc);
					}
				});
				$tbody.parent().toggleClass("m-basic-table--white", false);
				$image.toggleClass("h-hidden", !hasImage);
				this.$ctx.find(".js-pricesearch-results").toggleClass('h-hidden', false);
			} else {
				this.$searchHeadline.text(this._config.hintNoResult);
			}
			this.$ctx.find(".js-pricesearch-head").toggleClass('h-hidden', false);
			T.Utils.View.stopLoader();
		},
		_handlePriceDataError: function _handlePriceDataError(error) {
			this._handleError(error);
		},

		_handleError: function _handleError(error) {
			T.Utils.View.stopLoader();
			console.error(error);
			this._showError(true);
		},

		submitSearch: function submitSearch(evt) {
			if (evt) {
				evt.preventDefault();
			}
			if (this.$ctx.find("form").valid()) {
				this._requestPriceData();
			}
			return false;
		}
	});
})(jQuery);