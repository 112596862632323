'use strict';
(function ($) {
	const SESSIONKEY_CURRENTPAGE = 'currentVisitedPage';
	const SESSIONKEY_LASTPAGE = 'lastVisitedPage';
	const DATA_CONDITION = 'condition';
	const DATA_COMPONENTNAME = 'componentname';
	const DATA_CONDITION_REDIRECT = `${DATA_CONDITION}-redirect`;
	const DATA_SESSION_REQUIRED = 'session-required';

	/**
	 * ConditionHandler module implementation.
	 * On content pages a condition can be set and / or checked.
	 * This mechanism can be used to ensure that a page has been visited before another page is shown.
	 *
	 * @author  <markus.hiller@namics.com>
	 * @namespace T.Module
	 * @class ConditionHandler
	 * @extends T.Module
	 */
	T.Module.ConditionHandler = T.createModule({

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);

			this._events.on('t.sync', () => {
				if (!this._run) {
					this._run = true;
					const redirect = this._checkCondition();
					if (redirect) {
						this._performRedirect(redirect);
					} else {
						this._storeComponentModules();
					}
				}
			});
			resolve();
		},

		_checkCondition: function _checkCondition() {
			let conditionData = '';
			if (this._isEvaluationNeeded()) {
				const $conditions = $(document).find(`[data-${DATA_CONDITION}]`);
				if (!$conditions.length) {
					return conditionData;
				}

				const components = this._getComponents();
				for (let i = 0; i < $conditions.length; i++) {
					const $cond = $conditions.eq(i);
					const condition = $cond.data(DATA_CONDITION);
					if (condition && ((!components && 0 === i) || components.indexOf(condition) < 0)) {
						const result = $cond.data(DATA_CONDITION_REDIRECT);
						conditionData = !$cond.data(DATA_SESSION_REQUIRED) && this._isReferredBy(result) ? '' : result;
					}
				}
			}
			return conditionData;
		},

		_storeComponentModules: function _storeComponentModules() {
			if (!this._isIntermediateRequest()) {
				if (!this._isPageBeingReloaded()) {
					T.Utils.Store.set(SESSIONKEY_LASTPAGE, T.Utils.Store.get(SESSIONKEY_CURRENTPAGE, T.Utils.Store.SESSION) || {}, T.Utils.Store.SESSION);
				}
				const componentNames = [];

				$(document).find(`[data-${DATA_COMPONENTNAME}]`).each((index, elem) => {
					$(elem).data(DATA_COMPONENTNAME).split(";").forEach((value) => {
						componentNames.push(value.trim());
					});
				});

				const currentPage = {
					href: window.location.href,
					componentNames: componentNames
				};
				T.Utils.Store.set(SESSIONKEY_CURRENTPAGE, currentPage, T.Utils.Store.SESSION);
			}
		},

		_getComponents: function _getComponents() {
			const sessionData = T.Utils.Store.get(SESSIONKEY_CURRENTPAGE, T.Utils.Store.SESSION);
			if (sessionData && sessionData.componentNames && sessionData.componentNames.length) {
				return sessionData.componentNames;
			}
			return null;
		},

		_isEvaluationNeeded: function _isEvaluationNeeded() {
			return !this._isIntermediateRequest() && !this._isPageBeingReloaded();
		},

		_isPageBeingReloaded: function _isPageBeingReloaded() {
			const currentVisitedPage = T.Utils.Store.get(SESSIONKEY_CURRENTPAGE, T.Utils.Store.SESSION);
			return currentVisitedPage ? this._compareURL(new URL(currentVisitedPage.href, window.location.href), window.location) : false;
		},

		_isIntermediateRequest: function _isIntermediateRequest() {
			//handle login redirect
			return T.Utils.Auth.isHelperFrame() || T.Utils.Auth.isRedirectURI() || document.getElementsByTagName("exclusive-content").length > 0;
		},

		_performRedirect: function _performRedirect(redirectUrl) {
			if (redirectUrl) {
				T.Utils.Logger.info(`Conditional redirect from ${window.location.href} to ${redirectUrl}`);
				T.Utils.Helper.routeToUrl(T.Utils.Helper.isExternalUrl(redirectUrl) ? "/global-content/400" : redirectUrl);
			}
		},

		_isReferredBy: function _isReferredBy(url) {
			if (!url || !document.referrer) {
				return false;
			}
			return this._compareURL(new URL(url, window.location.href), new URL(document.referrer, window.location.href));
		},

		_compareURL: function _compareURL(url1, url2, compareParams) {
			const u1 = url1 || {}, u2 = url2 || {};
			let result = u1.host === u2.host && T.Utils.Helper.appendURIPath(u1.pathname) === T.Utils.Helper.appendURIPath(u2.pathname);
			if (result && compareParams) {
				result = u1.search === u2.search;
			}
			return result;
		}
	});
}(jQuery));

