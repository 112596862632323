'use strict';
(function ($) {
	/**
	 * Membership Insurance Documents
	 *
	 * @author Barbara Ascher <barbara.ascher@gks.adac.de>
	 * @namespace T.Module
	 * @class MyAdacMembershipContent-Insruancedocuments
	 * @extends T.Module.MyAdacMembershipContent
	 */
	T.Module.MyAdacMembershipContent.InsuranceDocuments = T.createDecorator({
		/**
		 * start function
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.$insuranceDocumentsForm = this.$ctx.find('form');
			this._parent.start(resolve);
			T.Utils.Application.init();
			this.readConfiguration();
			this.addEventListeners();
			const emitter = new T.EventEmitter(this._sandbox);
			emitter.on('t.sync', () => {
				if (this.needsUpdating()) {
					this.updateContent();
				}
			});
			this._policyVorgangsnummer = "";
		},

		readConfiguration: function readConfiguration() {
			this._apipolicy = this.$ctx.data("api-policy");
			this._backend = this.$ctx.data("backend") || T.Utils.Application.getApi();
			this._errorPageUrl = this.$ctx.data("error-page");
			this._noInsuranceDataUrl = this.$ctx.data("no-insurance-data-url");
			this._labels = this.$ctx.data("labels") || {};
		},

		addEventListeners: function addEventListeners() {
			$(document).on("identityloaded", this.onIdentityChanged.bind(this));
			$(document).on("identitylogout", this.onIdentityChanged.bind(this));
			this.$ctx.find('.js-submit').on('click', this.submitForm.bind(this));
		},

		startLoader: function startLoader() {
			T.Utils.View.startLoader();
		},

		stopLoader: function stopLoader() {
			T.Utils.View.stopLoader();
			this._events.emit('MyAdacDashboardList.content-changed', this.$ctx);
		},

		onIdentityChanged: function onIdentityChanged() {
			if (this.needsUpdating()) {
				this.updateContent();
			}
		},

		needsUpdating: function needsUpdating() {
			return !this._dataLoaded && !this._dataLoading;
		},

		updateContent: function updateContent() {
			this._dataLoading = true;
			this.startLoader();

			T.Utils.Auth.getResolvedIdentity(this.handleUserCallback.bind(this));
		},

		handleUserCallback: function handleUserCallback(user) {
			if (!user || !user.UserData) {
				this.errorHandling(false);
			}
			this._user = user;
			this.getInsuranceData();
		},

		getInsuranceData: function getInsuranceData() {
			this._dataLoading = true;
			const token = this.getUrlParam('data');
			if (token) {
				const insuranceData = T.Utils.Store.get('InsuranceData', 'session');
				for (const entry in insuranceData) {
					if (insuranceData[entry].VesToken === token) {
						this.insurance = insuranceData[entry];
						break;
					}
				}
				if (this.insurance) {
					this.updateView(this._user);
				} else {
					this.errorHandling(false);
				}
			} else {
				this.errorHandling(true);
			}
		},

		errorHandling: function errorHandling(showErrorPage) {
			if (showErrorPage) {
				T.Utils.Helper.routeToUrl(this._errorPageUrl);
			} else {
				T.Utils.Helper.routeToUrl(this._noInsuranceDataUrl);
			}
		},

		updateView: function updateView(user) {
			delete this._user;

			const $parent = this.$ctx.parents('.l-main-content').first();
			const $mainTitle = $parent.find('.js-my-adac-main-title').find('h1');

			$mainTitle.text(`${$mainTitle.text()} ${this.insurance.Sicht1.Bezeichnung}`);

			if (user && user.Success) {
				const company = user.UserData.Firma;
				if (company) {
					this.$ctx.find(".js-company").html(company);
					this.$ctx.find(".js-company-user").html(user.UserData.Beguenstigter);
				} else {
					const salutationLabelMapping = this._labels['salutation'];
					const salutationLabel = salutationLabelMapping[user.UserData.Anrede];
					this.$ctx.find(".js-salutation").text(salutationLabel);
					this.$ctx.find(".js-firstname").text(user.UserData.Vorname);
					this.$ctx.find(".js-lastname").text(user.UserData.Nachname);
				}
				this.showListItems(['.js-company', '.js-company-user'], company);
				this.showListItems(['.js-salutation', '.js-firstname', '.js-lastname'], !company);

				if (user.UserData.Anschrift.Ergaenzungszeile) {
					this.$ctx.find('.js-additional-address').text(user.UserData.Anschrift.Ergaenzungszeile);

					this.showListItems(['.js-additional-address'], true);
				}

				this.$ctx.find('.js-street').text(user.UserData.Anschrift.StrasseHausnummer);
				this.$ctx.find('.js-zip-location').text(`${user.UserData.Anschrift.Postleitzahl} ${user.UserData.Anschrift.Ort}`);

				this.showListItems(['.js-street', '.js-zip-location'], true);
			}

			this._dataLoading = false;
			this._dataLoaded = true;
			this.stopLoader();
		},

		submitForm: function submitForm() {
			this.startLoader();
			this.checkTokenAndRequestDocuments();
		},

		checkTokenAndRequestDocuments: function checkTokenAndRequestDocuments() {
			T.Utils.Auth.getBearerToken(this.requestPolicy.bind(this));
		},

		requestPolicy: function requestPolicy(token) {
			if (!token) {
				return;
			}

			const url = T.Utils.Helper.appendURIPath(this._backend, this._apipolicy);
			const requestOptions = {
				method: 'POST',
				cache: false,
				headers: {
					Authorization: `Bearer ${token}`
				},
				url: url,
				data: JSON.stringify({
					"VesToken": this.insurance.VesToken
				})
			};
			T.Utils.Ajax.fragment(requestOptions, this.submitSuccess.bind(this), this.submitError.bind(this));
		},

		submitSuccess: function submitSuccess(data) {
			T.Utils.Store.set('InsuranceData', null, "session");

			const $processNumber = this.$ctx.find('.js-processnumber');
			const $timestamp = this.$ctx.find('.js-timestamp');
			$processNumber.text(`${$processNumber.text()} ${data.Data.Vorgangsnummer} ${this._policyVorgangsnummer}`);
			$timestamp.text(`${$timestamp.text()} ${moment(data.Data.Timestamp).format("LLL")}`);

			this.stopLoader();

			this.$ctx.find(".js-form-content").toggleClass("h-hidden", true);
			this.$ctx.find(".js-success").toggleClass("h-hidden", false);
		},

		submitError: function () {
			this.errorHandling(true);
			this.stopLoader();
		},

		showListItems: function showListItems(selectors, show, parents) {
			parents = parents ? parents : '.mm-list-wrapper';
			for (let i = 0; i < selectors.length; i++) {
				this.$ctx.find(selectors[i]).parents(parents).toggleClass('h-hidden', !show);
			}
		},

		getUrlParam: function getUrlParam(key) {
			const result = new RegExp(`${key}=([^&]*)`, "i").exec(window.location.search);
			return result && unescape(result[1]) || "";
		},
	});
}(jQuery));
