'use strict';
(function ($) {
	/**
	 * MyAdacDashboardPreview module implementation.
	 *
	 * @author Marc Radziwill <marc.radziwill@merkle.com>
	 * @namespace T.Module
	 * @class MyAdacDashboardPreview
	 * @extends T.Module
	 */
	T.Module.MyAdacDashboardPreview = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.dashboardUrl = T.Utils.Helper.updateUrlParameter(T.Utils.Helper.getQueryParams(window.location.search), this.$ctx.data('dashboard-url'));

			this._registerListeners();
			T.Utils.Auth.getResolvedIdentity(this._handleUserCallback.bind(this));
			$(document).on('identityloaded', this._handleUserCallback.bind(this));

			resolve();
		},

		_registerListeners: function _registerListeners() {
			this.$ctx.find(".js-login").on('click', (ev) => {
				ev.preventDefault();
				T.Utils.Auth.authorize();
			});
		},

		_handleUserCallback: function _handleUserCallback(user) {
			if (user) {
				const reqUrl = new URL(this.dashboardUrl);
				const membershipSelectionUrl = reqUrl.searchParams.get('membershipselectionurl');

				const redirectUrl = membershipSelectionUrl
					? membershipSelectionUrl
					: this.dashboardUrl;

				window.location.href = redirectUrl;
			}
		}
	});
}(jQuery));
