
(function ($) {
    class SkiplinkComponent extends BasisComponent {

        constructor() {
            super();
            this.$ctx = null;
            this.$header = null;
            this.$searchlink = null;
            this.$searchOpenLayer = null;
        }

        _connectedCallback() {
            this.init();
        }

        init() {
            this._isConnected = true;
            this.$ctx = $(this);
            this.$header = this.$ctx.closest('.m-basic-header');
            this.$searchlink = this.$ctx.find('.js-skipto-search');
            this.$searchOpenLayer = this.$header.find('.js-submit-btn');

            this.initEvents();
            this.resize();
        }

        removeEvents() {
            if (this._isConnected) {
                this.$searchlink.off('click.searchSkipLink');
            }
        }

        _disconnectedCallback() {
            this.removeEvents();
        }

        initEvents() {
            S.Globals.TabFocus.addPopup(this.$searchlink, this);

            this.$searchlink.on('click.searchSkipLink', (link) => {
                if ((!S.Utils.Helper.mq('tablet').matches) || (S.Utils.Helper.mq('desktop-l').matches && this.$header.hasClass('is-stuck'))) {
                    link.preventDefault();
                    this.$searchOpenLayer.trigger('click');
                }
            });
        }

        resize() {
            $(window).resize(() => {
                S.Utils.delayed('resizedelayskiplinks', 200, () => {
                    this.initEvents();
                });
            });
        }
    }

    customElements.define('skiplink-component', SkiplinkComponent);

}(jQuery));
