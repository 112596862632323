(function ($) {
	'use strict';

	/**
	 * BasicInputText module implementation.
	 *
	 * @author  <t.grigoriadis@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicInputText
	 * @extends T.Module
	 */
	T.Module.BasicSearch = T.createModule({

		/** @type {jQuery} */
		$module: null,

		/** @type {String} */
		dataType: null,

		/** @type {String} autoCompleteInstance  */
		autocompleteInstance: null,

		/** @type {jQuery} $resultWrapper */
		$resultWrapper: null,

		/** @type {jQuery} $showMore */
		$showMoreButton: null,

		/** @type {jQuery} $showMore */
		$searchButton: null,

		/** @type {jQuery} $buttonTemplate */
		$buttonTemplate: null,

		/** @type {jQuery} $linkTemplate */
		$linkTemplate: null,

		/** @type {jQuery} $adTemplate */
		$adTemplate: null,

		/** @type {jQuery} contentWrapperHTML */
		contentWrapperHTML: null,

		/** @type {jQuery} clearButton */
		$inputClearButton: null,

		/** @type {jQuery} $searchWrapper */
		$searchForm: null,

		/** @type {String} mod */
		mod: null,

		/** @type {String} resultType */
		resultType: null,

		/** @type {String} headline */
		headline: null,

		/** @type {Boolean} firstInit */
		firstInit: true,

		/** @type {Boolean} withAdvertising */
		withAdvertising: false,

		/** @type {Number} showAllCounter */
		showAllCounter: 0,

		/** @type {Boolean} userClickedTheDeleteInputValue */
		userClickedTheDeleteInputValue: false,

		/** @type {jQuery} moduleConfig */
		moduleConfig: null,

		/** @type {jQuery} needToSwitchShowcase */
		needToSwitchShowcase: null,

		/** @type {Boolean} isOldHeader */
		isOldHeader: true,

		/** @type {jQuery} $mobileSubmitBtn */
		$mobileSubmitBtn: null,

		lastKeyPressed: '0',

		/**
		 * Initialize.
		 */

		// eslint-disable-next-line
		start(resolve) //NOSONAR Complexity
		{
			this.$module = $(this._ctx);
			this.moduleId = this.$module.data('tId');
			this.moduleConfig = JSON.parse(this.$module.attr('data-config'));
			this.dataType = this.$module.data('jsonformat');
			this.$resultWrapper = this.$module.find('.mm-search-result');
			this.$searchForm = this.$module.find('.mm-search-form');
			this.$searchButton = this.$resultWrapper.find('.js-search-btn');
			this.$searchInput = this.$module.find('.mm-submit-search');
			this.$searchInputHeader = this.$module.closest('.m-basic-header').find('.mm-submit-search');
			this.$inputClearButton = this.$module.find('.mm-search-cancel');

			this.$mobileSubmitBtn = this.$module.find('.js-submit-btn');
			this.$SubmitBtnHeader = this.$module.closest('.m-basic-header').find('.js-submit-btn');

			this.$darknessLayer = $('.a-layout-darkness.a-layout-darkness--search');
			this.mod = this.$module.data('mod');
			this.$header = $('.l-header');

			// templates for search 2.0 - adding commercial etc.
			this.$buttonTemplate = $('.js-button-template');
			this.$linkTemplate = $('.js-link-template');
			this.$adTemplate = $('.js-ad-template');

			this.isFocused = 'is-focused';
			this.autocompleteSuggestions = '.autocomplete-suggestions';
			this.contentHeadline = '.mm-content-headline';

			// as long as not fully loaded, disable input
			$(document).ready(() => {
				//everything should be loaded so enable search input
				this.$searchInput.prop('disabled', false);
			});

			// create a switch to check if old or new header is present
			if ($('body > .l-outer > header').hasClass('m-basic-header') && this.mod === 'navi') {
				this.isOldHeader = false;
			}

			this.listener();
			this.resize();
			this.localSwitcherToCheck();

			// listen to header events
			this._events.on('basicHeader.searchOpened', () => {
				// just focus header if focused searchbar is also in header
				if (this.$module.closest('.mm-search').hasClass('mm-layer')) {
					this.$searchInputHeader.focus();
				}

				this.checkSuggestionsLength(0);
			});

			// uncomment this if showcase can be removed
			// this.validate();

			this.$searchButton.on('click', () => {
				this.$searchForm.trigger('submit');
			});

			this.$searchButton.on('keyup', (key) => {

				if (key.which === 13) {
					this.$searchForm.trigger('submit');
				}
			});

			/**
			 * form handler
			 *
			 * differs between search request and given search suggestions
			 * if the user clicks/select a suggestions the this.autcomplete.selection isnt null
			 */
			this.$searchForm.on('submit', (e) => {
				e.preventDefault();

				// input value
				const query = this.autocompleteInstance.currentValue;

				// if the user clicks/select a suggestions the this.autcomplete.selection isnt null
				if (this.autocompleteInstance.selection === null) {
					// normal search
					T.Utils.Autocomplete.normalSearchValue(query);
				}
				//if the user submit a search request that isn´t a suggestions
				else {
					// selected search
					T.Utils.Autocomplete.selectSearchValue(query);
				}
			});

			/**
			 * searchInput listener
			 */
			this.$searchInput.on(
				{
					click: () => {
						// // set a class, if the inputField is focusesd- scss
						this.$searchForm.addClass(this.isFocused);

						this.clearButtonHandling();

						// // set state for the headroom plugin so you can scroll on mobile
						if (this.mod === 'navi' && this.isOldHeader) {
							// set the header-input class for headroom-plugin
							$('header').addClass('header-input-is-focused');

							this.setSoftLayerNoScroll();
						}

						// check if suggestions are visible
						this.checkSuggestionsLength(0);

						// only for mobile, check if the user´s phone is on landscape
						// - on landscape there is a small context space, and the background isnt swipeable throughout the
						//   position fixed body -> so scroll to the suggestions to gurantee the user can scroll through suggestions
						if (!S.Utils.Helper.mq('tablet').matches && this.isOldHeader) {
							// only for mobile
							this.checkForLandscapeToScrollToSuggestions();
						}
					},
					/**
					 * event for deleting OldSearchResults
					 * for deleting the suggestions on clicking the X button
					 */
					keyup: (key) => {
						if (key.which === 13) {
							this.$searchForm.trigger('submit');
						}

						this.clearButtonHandling();

						// check if suggestions are visible
						this.checkSuggestionsLength(0);

						if (this.mod === 'navi') {
							this._events.emit('BasicSearch.ModNaviKeyUp', this.$searchInput);
						}
					},
					/**
					 * searchInput focus listener
					 */
					focus: () => {
						// // set a class, if the inputField is focusesd- scss
						this.$searchForm.addClass(this.isFocused);

						if (this.mod === 'navi') {
							this._events.emit('BasicSearch.ModNaviFocus', this.$searchInput);
						}
					},

					/**
					 * searchInput blur listener
					 */
					blur: () => {
						const blurDelay = 200;

						// the plugin need a moment to process the suggestions
						S.Utils.delayed(`$searchInputBlur-${this.moduleId}`, blurDelay, () => {
							// removes a class, if the inputField is focused - scss
							this.$searchForm.removeClass(this.isFocused);
							//
							// // if the user click out of the input/suggestionbox remove the class
							this.$module.find(this.autocompleteSuggestions).removeClass('is-shown');
							this._events.emit('BasicSearch.SuggestionsHidden');
							//
							// // only for the header search-navi
							// // if you click in the inputfield, a darklayer appears
							if (this.mod === 'navi' && !this.userClickedTheDeleteInputValue && this.isOldHeader) {
								//remove the mobile wrapper for noScroll and blackBackground
								this.unsetSoftLayerNoScroll();
							}

							if (this.mod === 'navi') {
								this._events.emit('BasicSearch.ModNaviBlur', this.$searchInput);
							}
						});
					},
				});

			/**
			 * if the user clicks on the X in the inputfield
			 * to clear the input value
			 */
			this.$inputClearButton.on('click', (e) => {
				e.preventDefault();
				// hide the x button
				this.$inputClearButton.removeClass('is-shown');
				// clear input val
				this.$searchInput.val('');
				this.$searchInput.focus();

				this._events.emit('BasicSearch.InputCleared');

				// readd focus for the input
				if (!this.isOldHeader) {

					this._events.emit('BasicSearch.ModNaviFocus');

					// delay required to clean up the suggestions before reassigning the focus
					// deactivated cause looks stupid with drop-shadow (less is more ^^)
					// setTimeout( () => {
					//     this.$searchInput.focus();
					// }, 200);
				}
			});

			this.$mobileSubmitBtn.on('click', () => {
				this.$searchForm.trigger('submit');
			});

			// Bei Click auf Lupe + gefülltem Suchfeld löse die Suche aus
			this.$SubmitBtnHeader.on('click', () => {
				if (this.$searchInput.val().length > 3) {
					this.$searchForm.trigger('submit');
				}
			});

			resolve();
		},

		/**
		 * landscape function to set needed values and classes so its guaranteed
		 * the user can scroll throug suggestions and sees the inputfield
		 */
		checkForLandscapeToScrollToSuggestions() {
			// get width and height window values
			const width = $(window).width(),
				height = $(window).height();

			// check if its landscape
			if (height < width) {
				const neededTopValueToHide = $('.o-layout-header-metanav > nav').height();

				// set a value to increase the height of the suggestions container,
				// so the user can see all the suggestions if the keyboard is open
				// set landscape class
				this.$resultWrapper.addClass('is-landscape');

				// hide the header, so you have more free space on landscape
				this.$header.css({ top: `${-neededTopValueToHide}px` });

				S.Utils.delayed(`landscapeScroll-${this.moduleId}`, 50, () => {
					// scrollTop for IOS mobile devices - they scroll under the input if you dont this
					$('html, body').scrollTop(0);
				});
			}
		},

		/**
		 * set css inline styles to header/l-header/headroom
		 *
		 * if we dont set the top position and the header to absolute, it will push the
		 * l-main down and has to much space
		 */
		setSoftLayerNoScroll() {
			// only set this options for moible
			if (!S.Utils.Helper.mq('tablet').matches && this.isOldHeader) {
				// add class to check
				this.$header.addClass('is-open');

				// the plugin need a moment to process the suggestions
				$('body').addClass('is-noscroll');
			}
		},

		/**
		 * unsets css inline styles to header/l-header/headroom
		 */
		unsetSoftLayerNoScroll() {
			// check if the mobile class for header is set
			if (this.$header.hasClass('is-open') && this.isOldHeader) {
				// removes the header-input class for headroom-plugin and checking class for layoutheadernav.js -> onUnpin
				this.$header.removeAttr('style').removeClass('header-input-is-focused, is-open');

				// remove the needed padding for mobile
				this.$resultWrapper.removeClass('is-landscape');

				// remove no scroll class
				$('body').removeClass('is-noscroll');
			}
		},

		/**
		 * checks if there are suggestions or the autocomplete-suggestions are visible
		 *
		 * for handling the softLayer
		 * for emitting events
		 *
		 * @param delayValue {number} optional - specifies delay val, if non-default is required
		 */
		checkSuggestionsLength(delayValue) {
			let delay = 200;

			// set delay for new header
			if (!this.isOldHeader && typeof delayValue !== 'undefined' && typeof delayValue === 'number') {
				delay = delayValue;
			}

			// the plugin need a moment to process the suggestions
			S.Utils.delayed(`checkSuggestionsLength-${this.moduleId}`, delay, () => {
				// only show if suggestions-wrapper is visible and suggestions are given
				if (!this.$module.find(this.autocompleteSuggestions).is(':visible')) {
					this._events.emit('BasicSearch.SuggestionsHidden');
				}
			});
		},

		/**
		 * for oldResultSuggestions:
		 *
		 * handles the logic of the oldResultSuggestions (X - deleteBtn)
		 * @param {Boolean} hide
		 */
		clearButtonHandling(hide = false) {
			const $autosuggestionWrapper = this.$module.find(this.autocompleteSuggestions),
				$results = $autosuggestionWrapper.find('.mm-result-suggestion');

			// if there aren´t old results, hide the wrapper
			if ($results.length === 0) {
				// hide suggestion wrapper
				$autosuggestionWrapper.removeClass('is-shown');
			}
			// if there are old results, show the wrapper
			else if ($results.length > 0) {
				// show suggestion wrapper
				$autosuggestionWrapper.addClass('is-shown');
			}

			// handles the X button for inputSearch (deleteBtn)
			if (this.$searchInput.val().length === 0 || hide) {
				// hide deleteBtn X
				this.$inputClearButton.removeClass('is-shown');

				return;
			}

			// show deleteBtn X
			this.$inputClearButton.addClass('is-shown');
		},

		/**
		 * showcase function
		 * if showcase can be removed - uncomment this.validate() on top of the init and delete this
		 */
		localSwitcherToCheck() {
			this.needToSwitchShowcase = S.Utils.GetUrlParameter.checkForUrlParameter('vogt', 'true');

			// after checking the pararmeter, init instances for devbridge
			this.validate();
		},

		/**
		 * creates the autocopmleteInstance and validate the input
		 *
		 * ForINFO: if searchStageTwo starts the oldResults and advertising are added
		 *          minChars 4 to 0 (for instant searchRequest)
		 */
		// eslint-disable-next-line
		validate() //NOSONAR Complexity
		{
			let config = {
				serviceUrl: '/dev/api/search/search-terms.json',
				dataType: 'json',
				minChars: 4,
				width: '100%',
				maxHeight: '100%',
				noSuggestionNotice: 'Keine Einträge gefunden',
				appendTo: this.$resultWrapper,
				triggerSelectOnValidInput: false,
				showNoSuggestionNotice: false,
				preventBadQueries: false,
				isAjax: false,
				mode: 'normal',
				params: {
					// for differ the mod in param
					// param which is send with the request
					type: this.mod,
				},
				// noCache: true,
				delay: 50,
				onSelect: () => {
					//trigger submit if clicked
					this.$searchForm.submit();

					setTimeout(() => {
						if (this.lastKeyPressed === 13) {
							// trigger submit if enter pressed
							this.$searchForm.submit();
						}
					}, 500);
				},
				// eslint-disable-next-line
				onSearchStart: (queryObj) => //NOSONAR Return
				{
					// ipnut length
					const inputLength = queryObj.query.length;

					// if the input length is under/equal 3 handle the oldResult (plus advertising in stage2)
					if (inputLength <= 3) {
						// set the headline for oldResults
						this.headline = this.moduleConfig.headline;

						// set the type for the oldResults, to use it in other functions
						this.resultType = 'oldResults';

						// on firstInit, check if autcomplete is loaded
						if (this.autocompleteInstance !== null) {
							// only show if suggestinos are given
							// show the suggestionsWrapper
							if (this.autocompleteInstance.suggestions.length) {
								// show the suggestionsWrapper
								this.$module.find(this.autocompleteSuggestions).show();
							}

							// change the needed headline
							this.$module.find(this.contentHeadline).text(this.headline);

							// after firstInit
							if (!this.firstInit) {
								// prevent devbridge to request an query from server
								return false;
							}
						}

						// this variable is for the oldResults to request an query from server (return false to stop requesting/ true to send request the query)
						// set the variable for firstInit to true
						// this is needed to tell devbridgeAutocomplete to send the request again
						this.firstInit = false;

						// set the wrapper to 100%
						// this overrides the wrapper for advertisings
						if (this.withAdvertising && this.mod === 'navi') {
							this.setAutosuggestionsWrapperWidth();
						}
					}
					else {
						// this variable is for the oldResults to request an query from server (return false to stop requesting/ true to send request)
						// set the variable for firstInit to true
						// this is needed to tell devbridgeAutocomplete to send the request again
						this.firstInit = true;

						this.$module.find(this.contentHeadline).text(this.headline);

						this.headline = this.moduleConfig.headline;

						this.resultType = 'searchResults';

						return true;
					}
				},
				transformResult: (response, query) => {
					// return the empty response if nothing is given or the response has no length
					if (typeof response === 'undefined' || !response.results || response.results.length === 0) {
						return { suggestions: [] };
					}

					// if showcase can be removed - uncomment this and delete comment "showcase switch for adac"
					// results and options
					// const resultObj = T.Utils.Autocomplete.transformResult(response, config.isAjax);
					// console.log('Line 499|| ', this.needToSwitchShowcase);

					// showcase switch for adac
					let resultObj;
					// showcase switch for adac
					if (this.needToSwitchShowcase) {
						resultObj = response;

					}
					else {
						resultObj = T.Utils.Autocomplete.transformResult(response, config.isAjax);
					}

					// if the input length is under/equal 3 handle the oldResult (plus advertising in stage2)
					if (query.length <= 3) {
						// set the wrapper to 100%
						// this overrides the wrapper for advertisings
						if (this.withAdvertising && this.mod === 'navi' && S.Utils.Helper.mq('desktop-l').matches) {
							this.setAutosuggestionsWrapperWidth();
						}

						// return data
						const suggestions = $.map(resultObj.oldResults, (dataItem) => {
							return { value: dataItem.value, data: dataItem.data };
						});

						// return the cretaed Object
						return {
							suggestions: suggestions,
						};
					}
					else {

						// if there isnt a total hit count
						this.showAllCounter = (typeof resultObj.searchResults === 'undefined' || typeof resultObj.searchResults.searchMatches === 'undefined') ? false : resultObj.searchResults.searchMatches;

						let suggestions;

						if (typeof resultObj.searchResults !== 'undefined') {

							// return data
							suggestions = $.map(resultObj.searchResults.results, (dataItem) => {
								return { value: dataItem.value, data: dataItem.data };
							});
						}
						else if (typeof resultObj.results !== 'undefined') {
							// return data
							suggestions = $.map(resultObj.results, (dataItem) => {
								return { value: dataItem.value, data: dataItem.data };
							});

						}
						// if there are no results at searchResults or at results, give an empty array back
						// and not an error
						else {
							return { suggestions: [] };
						}

						// only do this, if advertising is in array and its minimum desktop-l mq
						if (resultObj.advertising && S.Utils.Helper.mq('desktop-l').matches) {
							// rewrite the advertising results and push them in the result array
							$.each(resultObj.advertising, (_, categoryItem) => {
								// rewrite the advertising results and push them in the result array
								$.each(categoryItem.results, (_, dataItem) => {
									suggestions.push({
										type: 'advertising',
										title: categoryItem.title,
										image: categoryItem.image,
										link: categoryItem.link,
										count: categoryItem.count,
										value: dataItem.title,
										data: {
											link: dataItem.link,
											searchResultCount: 0,
										},
									});
								});
							});
						}

						// return the created Object
						return {
							suggestions: suggestions,
						};
					}
				},
				beforeRender: ($container, suggestions) => {
					if (suggestions.length !== 0) {
						// set the wrapper width only once to 720 when needed and the advertising is appended
						// if i put it under the for/each it will be executed for the oldsearches too
						// (after the user delete characters in the input <= 3.length)
						let setBiggerWrapperForAdvert = true;

						$container.addClass('is-shown');

						// for navi input field
						if (this.mod === 'navi') {
							this.contentWrapperHTML = `<div class="mm-content-headline">${this.headline}</div><div class="mm-content-wrapper"><div class="mm-content"></div><div class="mm-content-advertising"></div></div>`;
						}
						// for content input fields
						else {
							this.contentWrapperHTML = `<div class="mm-content-headline">${this.headline}</div><div class="mm-content-wrapper"><div class="mm-content"></div></div>`;
						}

						// empty the container for individual markup, add left and right wrapper
						$container.empty().append(this.contentWrapperHTML);

						suggestions.forEach((suggestion) => {
							// if there are advertisings and you are in the navi input field
							if (suggestion.type === 'advertising' && this.mod === 'navi') {
								// get the html dependent on the category
								const html = this.createHtmlOutput(suggestion, suggestions.indexOf(suggestion));

								// append suggestion
								$container.find('.mm-content-advertising').append(html);

								// set the wrapper width only once to 720 when needed and the advertising is appended
								// if i put it under the for/each it will be executed for the oldsearches too
								// (after the user delete characters in the input <= 3.length)
								if (setBiggerWrapperForAdvert) {
									// set the advertising mode to true
									this.withAdvertising = true;

									// handle advertising wrapper
									this.setAutosuggestionsWrapperWidth(true);

									// set firstInit variable for biggerWrapper to false
									setBiggerWrapperForAdvert = false;
								}

							}
							else {
								// get the html dependent on the category
								const html = this.createHtmlOutput(suggestion, suggestions.indexOf(suggestion));

								// append suggestion
								$container.find('.mm-content').append(html);
							}
						});

						// if you have normal searchResults (no oldSearchResults) append the button and init the event for it
						if (this.resultType === 'searchResults') {
							const showAllButtonText = !this.showAllCounter ? this.moduleConfig.labelAllResults : `${this.moduleConfig.labelAllResults} (${this.showAllCounter})`;

							// get show more button
							// add the button
							$container
								.find('.mm-content')
								.append(this.$buttonTemplate.html())
								.find('.mm-show-more')
								.text(showAllButtonText);

							this.$showMoreButton = $container.find('.mm-show-more');

							// set the event on the button to trigger the submit on click
							this.handleShowMoreClick();
						}

						// only if oldResults is set, set the event
						if (this.resultType === 'oldResults') {
							// set the event for the delete button on oldResults
							this.handleOldResultDeleting();
						}

						this.$module.find(this.autocompleteSuggestions).addClass('is-shown');
					}
				},
				onSearchComplete: () => {
					// looks like a valid state to know if suggestions are there even when they are slowly loaded
					// ( compare: visible check in checkSuggestionsLength() )
					if (!this.isOldHeader) {
						this._events.emit('BasicSearch.SuggestionsShown');
					}
				}
			};

			// if showcase can be removed - uncomment this and delete comment "showcase switch for adac"
			// const ajaxConfig = T.Utils.Autocomplete.getAjaxConfig(this.$module);
			// config = $.extend(config, ajaxConfig);

			// showcase switch for adac
			if (!this.needToSwitchShowcase) {
				const ajaxConfig = T.Utils.Autocomplete.getAjaxConfig(this.$module);
				config = $.extend(config, ajaxConfig);
			}
			this.autocompleteInstance = this.$searchInput.devbridgeAutocomplete(config)  // to get/save the instance
				.data('autocomplete');

			// sets the classes for suggestions/suggestions-selected
			this.autocompleteInstance.classes.selected = 'mm-result-selected';
			this.autocompleteInstance.classes.suggestion = 'mm-result-suggestion';
		},

		/**
		 * NOT FINISHED width is not correct -> stage 2
		 *
		 * sets the wrapper width for autosuggestions (for advert you need more width)
		 *      # the issue is, that the plugin sets auto/100% to the wrapper and calculate the width of the input field for it
		 *      # but for the advertising we need a more width (cant HANDLE THIS it in css)
		 * @param {Boolean} setFixWidth
		 */
		setAutosuggestionsWrapperWidth(setFixWidth = false) {
			// only set this if desktop-l matches and the set the width
			if (setFixWidth && S.Utils.Helper.mq('desktop-l').matches) {
				this.autocompleteInstance.setOptions({ width: '720' });
				this.$resultWrapper.addClass('mm-has-advert');
				return;
			}

			const neededWidth = $('.mm-search-input-wrapper').outerWidth();

			this.autocompleteInstance.setOptions({ width: neededWidth });
			this.$resultWrapper.removeClass('mm-has-advert');
		},

		/**
		 * reformat the highlighted value if it is in the search suggestion
		 * @param {suggestion} suggestion single
		 * @return {*}
		 */
		formatResult(suggestion) {
			// eslint-disable-next-line
			const currentValue = this.autocompleteInstance.currentValue.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

			const re = new RegExp(`(${currentValue.split(' ').join('|')})`, 'gi'),
				title = suggestion.replace(re, '<strong class="mm-segment--inner">$1</strong>');

			return `<span class="mm-segment">${title}</span>`;
		},

		/**
		 * handles the link-template and fills it
		 * @param {Object} suggestion
		 * @param {number} index -> suggestion index
		 * @return {*|jQuery|*|*|*|*}
		 */
		handleLinkTemplating(suggestion, index) {
			const $linkHtml = $(this.$linkTemplate.text()),
				$resultCancel = '<div class="mm-old-result-cancel"></div>',
				$resultCountHtml = (typeof suggestion.data.searchResultCount !== 'undefined') ? `<span class="mm-result-count">&nbsp;(${suggestion.data.searchResultCount})</span>` : false;

			// set the index and unique resultId for deleting resultSuggestions
			$linkHtml.attr({
				"data-index": index,
				"data-result-id": suggestion.data.resultId,
			});

			// if link isn´t given
			if (typeof suggestion.data.link !== "undefined" && suggestion.data.link.length > 0) {
				$linkHtml
					.find('.mm-link-wrapper')
					.empty()
					.append('<a class="mm-link"></a>')
					.find('.mm-link')
					.attr({
						href: suggestion.data.link,
					});
			}

			// if there isnt a resultCount
			if (!$resultCountHtml) {
				$linkHtml
					.find('.mm-link')
					.html(this.formatResult(suggestion.value));
			}
			else {
				$linkHtml
					.find('.mm-link')
					.after($resultCountHtml)
					.html(this.formatResult(suggestion.value));
			}

			// for old results
			if (this.resultType === 'oldResults') {
				$linkHtml.append($resultCancel).addClass('mm-old-results');
			}

			return $linkHtml.get(0).outerHTML;
		},

		/**
		 * for stage 2 - change the link like the linkTemplate
		 * handles the advertising templates and fills it
		 * @param {Object} suggestion
		 * @param {number} index -> suggestion index
		 * @return {*|jQuery|*|*|*|*}
		 */
		handleAdTemplating(suggestion, index) {
			const $adHtml = $(this.$adTemplate.text()),
				$adImgHtml = suggestion.image ? `<img src="${suggestion.image.src}" alt="${suggestion.image.alt}" class="mm-img">` : '',
				$adLinkShowAllHtml = suggestion.link ? `<a href="${suggestion.link}" class="mm-link">Alle anzeigen<span class="mm-result-count">&nbsp;${suggestion.count}</span></a>` : '';

			$adHtml.attr('data-index', index);
			$adHtml.find('.mm-ad-img').append($adImgHtml);

			for (const result in suggestion.results) {
				const $neededLinkHtml = `<a href="${result.link}" class="mm-result-link">${result.title}</a>`;

				$adHtml.find('.mm-ad-link-wrapper').append($neededLinkHtml);
			}

			$adHtml.find('.mm-ad-link-show-all').append($adLinkShowAllHtml);

			return $adHtml.get(0).outerHTML;
		},

		/**
		 * Create different html output based on category|mod
		 * -> for different groups or themes (later)
		 * @param {array} suggestion
		 * @param {Number} suggestionIndex
		 * @return {string}html
		 */
		createHtmlOutput(suggestion, suggestionIndex) {
			if (suggestion.type === 'advertising' && this.mod === 'navi') {
				return this.handleAdTemplating(suggestion, suggestionIndex);
			}

			return this.handleLinkTemplating(suggestion, suggestionIndex);
		},

		/**
		 * handle show more click to trigger form submit
		 * if needed
		 */
		handleShowMoreClick() {
			this.$showMoreButton.on('click', (e) => {
				e.preventDefault();

				T.Utils.Autocomplete.normalSearchValue(this.autocompleteInstance.currentValue);
			});
		},

		/**
		 * handle cancel event for oldResults, so the user can delete old searchings
		 */
		handleOldResultDeleting() {
			this.$module.find('.mm-old-result-cancel').on('click mousedown', (e) => {

				const $clickedSuggestion = $(e.currentTarget).closest('.mm-result-suggestion');

				// only for testing
				// only for testing
				// only for testing
				$clickedSuggestion.remove();

				// if no results are left, delete the headline
				if (this.$module.find('.mm-content > .mm-result-suggestion').length > 0) {
					this.$module.find(this.autocompleteSuggestions).show().addClass('is-shown');
				}
				else {
					this.$module.find(this.contentHeadline).remove();
					this.$module.find(this.autocompleteSuggestions).removeClass('is-shown');
				}

				// set the trigger variable for the focusout
				this.userClickedTheDeleteInputValue = true;

				// focus in
				S.Utils.delayed(`handle-old-result-deleting-${this.moduleId}`, 500, () => {
					this.$searchInput.focus();

					this.userClickedTheDeleteInputValue = false;
				});

			});
		},

		/**
		 * reisze function
		 */
		resize() {
			let oldWidth = $(window).width();

			$(window).on('resize', () => {
				S.Utils.delayed(`search-box-${this.moduleId}`, 200, () => {
					// if you dont check for this, mobile devices will trigger if you swipe over the end of the site
					if ($(window).width() !== oldWidth) {

						if (S.Utils.Helper.mq('desktop-l').matches) {
							this.withAdvertising = this.showAdvertisingMediaQuerie ? true : false;
						}
						else if (S.Utils.Helper.mq('desktop').matches) {
							// set the wrapper size on
							this.withAdvertising = false;
						}
						else if (S.Utils.Helper.mq('tablet').matches) {
							// set state for the headroom plugin so you can scroll on mobile
							// $('.l-header').removeClass('header-input-is-focused');

							if ($(window).width() < 1024) {
								this.showAdvertisingMediaQuerie = false;
							}
						}
						else {
							// default
						}

						// set state for the headroom plugin so you can scroll on mobile
						this.$header.removeClass('header-input-is-focused');

						// unset the softLayer
						this.unsetSoftLayerNoScroll();

						oldWidth = $(window).width();
					}
				});
			});
		},

		listener() {
			document.addEventListener("keyup", (event) => {
				this.lastKeyPressed = event.which;
			});
		},
	});
}(jQuery));