(function ($)
{
    'use strict';
    /**
     * BasicProgressNav module implementation.
     *
     * @author  <oe.guenay@edelweiss72.de>
     * @namespace T.Module
     * @class BasicProgressNav
     * @extends T.Module
     */
    T.Module.BasicProgressNav = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $listContainer: null,

        /** @type {jQuery} */
        $activeListItemBackground: null,

        /** @type {Boolean} */
        $isResizing: 0,

        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$activeListItemBackground = this.$ctx.find('li.mm-active .mm-progressnav-container');
            this.$listContainer = this.$ctx.find('ul').eq(0);
			this.$listPoint = this.$ctx.find('li');
			this.$textInner = this.$listPoint.find('.mm-text-inner');
			this.$text = this.$listPoint.find('.mm-text');
			this.$textNotActive = this.$listPoint.not('.mm-active').find('.mm-text');
			this.mobileStyling = 'mm-mobile-styling';
			this.noPadding = 'mm-no-padding';

            const that = this;


			if(that.$listPoint.length > 3 && S.Utils.Helper.mq('desktop').matches) {
				that.$textInner.addClass(this.mobileStyling);
				that.$text.addClass(this.noPadding);
				that.$textNotActive.addClass(this.mobileStyling);
			}

            // Add and delete classes
            if (that.$listContainer.outerHeight() <= 40)
            {
                that.$listContainer.removeClass('is-big');
            }
            else
            {
                if (!that.$listContainer.hasClass('is-big'))
                {
                    that.$listContainer.addClass('is-big');
                }
            }

            if(this.$ctx.hasClass('m-basic-progress-nav-form--piechart')){
                this.initPieChart();
            }

            // init resize check
            this.resize();
            resolve();
        },

        initPieChart: function() {
            const circle =  this.$ctx.find('.mm-mobile');
            const count =  this.$ctx.find('.mm-nav-wrapper').children().filter(':not(.mm-mobile)').length;
            const active =  this.$ctx.find('.mm-nav-wrapper .mm-active').index() - 1;

            for(let i = 0; i < count; i++) {
                const angle = 90 + ((360/5) * i);
                let activeClass='';

                if(i < active) {
                    activeClass = ' is-active';
                }
                circle.append(`<span class="mm-segment${activeClass}" style="transform: rotate(${  angle  }deg);"><span></span></span>`);
            }
        },

        /**
         * resize
         */
        resize: function ()
        {
            const that = this;

            // resizing
            $(window).resize(() =>
            {

                // reset
                that.$isResizing++;

                // buffer
                that.bufferResize();

				if(that.$listPoint.length > 3 && S.Utils.Helper.mq('desktop').matches) {
					that.$textInner.addClass(this.mobileStyling);
					that.$text.addClass(this.noPadding);
					that.$textNotActive.addClass(this.mobileStyling);
				} else {
					that.$textInner.removeClass(this.mobileStyling);
					that.$text.removeClass(this.noPadding);
					that.$textNotActive.removeClass(this.mobileStyling);
				}

            });
        },

        /**
         * buffer resize
         */
        bufferResize: function ()
        {
            const that = this;
            let isResizingCheck = 0;

            // start buffer interval check
            if (that.$isResizing === 1)
            {
                const resizing = setInterval(function ()
                {
                    if (isResizingCheck < that.$isResizing)
                    {
                        isResizingCheck = that.$isResizing;
                    }
                    else
                    {
                        that.$isResizing = 0;
                        isResizingCheck = 0;

                        // Add and delete classes
                        if (!that.$listContainer.hasClass('mm-nav-wrapper')) {
                            that.$listContainer.removeClass();
                        }
                        if (that.$listContainer.outerHeight() >= 40)
                        {
                            that.$listContainer.addClass('is-big');
                        }

                        clearInterval(resizing);
                    }
                }, 200);
            }
        }
    });
}(jQuery));
