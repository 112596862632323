(function ($)
{
    'use strict';
    /**
     * BasicFormFooterSticky module implementation.
     *
     * @author m.haeusgen <m.haeusgen@edelweiss72.de>
     * @namespace T.Module
     * @class BasicFormFooterSticky
     * @extends T.Module
     */
    T.Module.BasicFormFooterSticky = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $toggleBtn: null,

        /** @type {jQuery} */
        $content: null,

        /** @type {jQuery} */
        $additionalInfo: null,

        /** @type {Object[]} */
        lFooter: null,

        /** @type {Object[]} */
        $llMainCenterLast: null,

        /** @type {Number} */
        stickyThresholdTop: 150,

        /** @type {Object[]} */
        $lMain: null,

		/** @type {Boolean} */
		contentInAnimation: false,

        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$toggleBtn = this.$ctx.find('.mm-price-box-big');
            this.$headerLabel = this.$ctx.find('.mm-header-label');
            this.$content = this.$ctx.find('> .mm-content');
            this.$lMain = $('body > .l-outer > .l-main').eq(0);
            this.$llMainCenterLast = $(this.$lMain).find(' > .ll-main-center').eq(-1);
            this.lFooter = $('body > .l-outer > .l-footer').eq(0);
            this.$additionalInfo = this.$ctx.find('.mm-price-box-add').eq(0);
            this.$layerBtn = this.$ctx.find('.js-open-layer');
            this.$layer = this.$ctx.find('.mm-btnbox-layer');
            this.stuckBottom = 'is-stuck-bottom';
            this.jsStickyFormFooter = 'js-sticky-form-footer';

            this.addEventListener();
            this.resizeModule();

            if (!this.$ctx.hasClass('m-basic-form-footer-sticky--whole-footer'))
            {
                this.lFooter.addClass('is-conversion');
            }

			if (!this.$ctx.hasClass('is-initial-closed'))
			{
				S.Utils.delayed('basicFormStickyFooter.initDelay', 400, () => {
					this.initVisibility();
				});
			}
			this.initSticky();

            resolve();
        },

		/**
		 * add event listener like layer btns or global comp events
		 */
		addEventListener()
		{
			// add open close toggle
			this.$toggleBtn.off('click.showFooterContent').on('click.showFooterContent', () =>
			{
				this.toggleContent();
			});

			// layer events
			this.$layerBtn.off('click.footerStickyToggleLayer').on('click.footerStickyToggleLayer', () => {
				this.toggleLayer();
			});

			this.$layer.find('.mm-layer-close').off('click.footerStickyToggleLayerClose').on('click.footerStickyToggleLayerClose', () => {
				this.toggleLayer();
			});

			const callSticky = () => {

				S.Utils.delayed('basicFormStickyFooter.scrollDelay', 40, () => {

					// prevent scroll events at page bottom triggered by the height anim of the content animation
					if ( !this.contentInAnimation ) {
						this.initSticky();
					}
				});
			};

			document.removeEventListener('scroll',  callSticky,  false);
			document.addEventListener('scroll',  callSticky,  { passive: true });

			this._events.off('BasicCalculatorContentBox.memberShipChange').on('BasicCalculatorContentBox.memberShipChange', (mod) => {
				this.showPriceInfo(mod);
			});
		},

		/**
         * hide Footer
         */
        initSticky()
        {
			const windowHeight = $(window).height(),
				footerRectClient = this.lFooter[0].getBoundingClientRect(),
				$footerBtnShow = $('*[data-footerbutton="sticky-footer-button-show"]'),
				showInvisibleButtonInFooter = $($footerBtnShow).length > 0 ? $($footerBtnShow).offset().top : 0;

			//some buttons in sticky footer should not be visible unless user scrolled to bottom/ footer is visible
			if ( ($(document).scrollTop() + windowHeight ) >= showInvisibleButtonInFooter) {
				this.$ctx.addClass('is-button-visible');
			}
			else {
				this.$ctx.removeClass('is-button-visible');
			}

			const stickyRectClient = this.$ctx[0].getBoundingClientRect();

			// stick to footer
			if (footerRectClient.top <= windowHeight )
			{
				this.stickToFooter(stickyRectClient, footerRectClient);
			}

			// stay fixed at bottom
			else
			{
				// reset all
				this.$ctx.removeClass(this.stuckBottom).css('bottom', '');
				this.$lMain.removeClass(this.jsStickyFormFooter).css('padding-bottom', '');

				// backToTopButton - move back to top anchor above sticky elem
				$(this.$lMain).find('> .a-basic-top').eq(0).css('bottom', stickyRectClient.height + 30);
				$('.m-basic-sticky-service-contact-restyle').eq(0).css('bottom', stickyRectClient.height + 100);
			}
        },

		stickToFooter(stickyRectClient, footerRectClient)
		{
			const windowHeight = $(window).height();

			// recalc only if elem is not stuck bottom or beneath footer
			if ( !this.$ctx.hasClass(this.stuckBottom) ) {
				this.$ctx.addClass(this.stuckBottom);

				// remove main padding to simplify fix docking calc of footer and sticky elem
				this.$lMain.addClass(this.jsStickyFormFooter).css({
					'padding-bottom': '0',
				});

				// set top offset (move elem right beneath $llMainCenterLast)
				this.$ctx.css({
					"bottom": -Math.abs(stickyRectClient.height + this.$llMainCenterLast.height()),
				});

				// add padding on init
				this.$llMainCenterLast.addClass(this.jsStickyFormFooter).css({
					// '+1' sets the small gap between footer and sticky elem
					'padding-bottom': (this.$ctx.height() + 1),
				});

				// move back to top anchor above sticky elem
				// $(this.$lMain).find('> .a-basic-top').css('bottom', stickyRectClient.height + footerRectClient.height + 60);
				//
				// if(S.Utils.Helper.mq('tablet').matches)
				// {
				// 	$('.m-basic-sticky-service-contact-restyle').css('bottom', stickyRectClient.height + footerRectClient.height + 140);
				// }
				// else
				// {
				// 	$('.m-basic-sticky-service-contact-restyle').css('bottom', stickyRectClient.height + footerRectClient.height);
				// }
			}

			else {

				if ( stickyRectClient.top >= footerRectClient.top || stickyRectClient.top < windowHeight / 2 ) {
					// set top offset (move elem right beneath $llMainCenterLast)
					this.$ctx.css({
						"bottom": -Math.abs(stickyRectClient.height + this.$llMainCenterLast.height()),
					});

					// add padding on init
					this.$llMainCenterLast.addClass(this.jsStickyFormFooter).css({
						// '+1' sets the small gap between footer and sticky elem
						'padding-bottom': (this.$ctx.height() + 1),
					});
				}
			}
		},

		/**
		 * init visibility after page load
		 */
		initVisibility()
		{
			//visible after loading
			this.$ctx.addClass('is-visible');

			// add padding on init
			this.$llMainCenterLast.addClass(this.jsStickyFormFooter).css({
				// '+1' sets the small gap between footer and sticky elem
				'padding-bottom': (this.$ctx.height() + 1),
			});

			if ( S.Utils.Helper.mq('desktop').matches ) {
				S.Utils.delayed('basicFormStickyFooter.showContentAfterInit', 200, () => {
					this.toggleContent();
					// hide content again after delay
					if ( !this.$ctx.hasClass('is-initial-open') ) {
						S.Utils.delayed('basicFormStickyFooter.hideContentAfterInit', 1200, () => {
							this.toggleContent();
						});
					}
				});
			}

			this.initSticky();
		},

		/**
         * show / hide Sticky Content
         */
        toggleContent()
        {
			if (this.$content.length)
			{
				// set global flag the content anim is called
				this.contentInAnimation = true;

				const isClosed = !this.$ctx.hasClass('is-open'),
					maxHeight = isClosed ? this.$content.find('.mm-inner-content').outerHeight() : 0;

				if (isClosed) {
					$('body').on('click.footerstickylayerclose', (el) => {
						if($(el.target).closest('.m-basic-form-footer-sticky').length < 1) {
							this.toggleContent();
						}
					});
				}
				else {
					$('body').off('click.footerstickylayerclose');
				}

				this.$headerLabel.toggleClass('is-open');
				this.$ctx.toggleClass('is-open');

				this.$content.animate({
					height: maxHeight
				}, 500, () => {

					if ( isClosed ) {
						this.$content.removeClass('is-close');
					}

					else {
						this.$content.addClass('is-close');
					}

					// add padding on init
					this.$llMainCenterLast.addClass(this.jsStickyFormFooter).css({
						// '+1' sets the small gap between footer and sticky elem
						'padding-bottom': (this.$ctx.height() + 1),
					});

					// reset global flag on anim.complete
					S.Utils.delayed('basicFormStickyFooter.recalcStickyness', 100, () => {

						this.contentInAnimation = false;
						this.initSticky();
					});
				});
			}
        },

        /**
         * shows additional line under pricing, e.g. membership fee
         *
         * @param mod {String} - show || hide
         *
         */
        showPriceInfo(mod) {
            if ( mod === 'show' ) {
                this.$additionalInfo.removeClass('h-visuallyhidden');
            }

            else {
                this.$additionalInfo.addClass('h-visuallyhidden');
            }
        },

        resizeModule()
        {
            $(window).on('resize', () =>
            {
                S.Utils.delayed('basicFormStickyFooter.resizeDelay', 40, () =>
                {
                    this.initSticky();
                });
            });
        },

		toggleLayer()
        {
			this.$layer.toggleClass('is-open');
        }
    });
}(jQuery));
