(function ($) {
	'use strict';
	/**
	 * Swipeable decorator for the toggletabbox module
	 *
	 * @author  <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class ToggleTabBox
	 * @extends T.Module
	 */
	T.Module.BasicToggleTabBox.Swipeable = T.createDecorator({

		/** @type {jQuery} */
		$ctx: null,

		/** @type {jQuery} */
		$swiperContainer: null,

		/** @type {jQuery} */
		$swiperSlidesWrapper: null,

		/** @type {Object[]} */
		$swiperSlides: null,

		/** @type {jQuery} */
		$swiperBtnLeft: null,

		/** @type {jQuery} */
		$swiperBtnRight: null,

		/** @type {Object[]} */
		mySwiper: null,

		/**
		 * Initialize
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this.$swiperContainer = this.$ctx.find('.swiper');
			this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper').eq(0);
			this.$swiperSlides = this.$ctx.find('.swiper-slide');
			this.$swiperBtnLeft = this.$swiperContainer.find('.swiper-button-prev');
			this.$swiperBtnRight = this.$swiperContainer.find('.swiper-button-next');

			this.initSwiper();

			this._events.on('BasicToggleTabBox.update', () => {
				this.mySwiper?.update();
			});

			this._parent.start(resolve);
		},

		/**
		 * init swiper
		 */
		initSwiper: function () {
			// if sitecore edit mode
			const keyboardControl = this.$ctx.data('mode') !== 'edit';

			// if the component is not set up in default structure (with swiper containers etc.),
			// e.g. because handlebar usage was not possible, we can build the necessary structure manually
			// by wrapping the elements and adding swiper-classes
			if (!this.$swiperContainer.length) {
				// containers
				// find first direct child of ctx, make it a swiper-wrapper and wrap it as a swiper-container
				this.$ctx.children().eq(0).addClass('swiper-wrapper').wrap('<div class="swiper"></div>');
				this.$swiperContainer = this.$ctx.children().closest('.swiper');
				this.$swiperSlidesWrapper = this.$swiperContainer.find('.swiper-wrapper').eq(0);

				// slides
				// find direct li-children of slides-wrapper and make each of them a swiper-slide
				this.$swiperSlidesWrapper.children('div').addClass('swiper-slide');
				this.$swiperSlides = this.$ctx.find('.swiper-slide');

				// buttons
				// append them to the container
				this.$swiperContainer.append('<div class="swiper-button-prev"></div><div class="swiper-button-next"></div>');
				this.$swiperBtnLeft = this.$swiperContainer.find('.swiper-button-prev');
				this.$swiperBtnRight = this.$swiperContainer.find('.swiper-button-next');
			}

			// init swiper container
			this.mySwiper = new Swiper(this.$swiperContainer[0], {
				slidesPerView: 'auto',
				speed: 1500,
				keyboard: {
					enabled: keyboardControl,
				},
				watchSlidesProgress: true,
				navigation: {
					prevEl: this.$swiperBtnLeft[0],
					nextEl: this.$swiperBtnRight[0],
				}
			});

			if (typeof this._sandbox.swiperInstance === 'undefined') {
				this._sandbox.swiperInstance = {};
			}
			this._sandbox.swiperInstance[this.$ctx.data('t-id')] = this.mySwiper;

			// set width of swiper-wrapper
			let slideWidth = 0;

			this.$swiperSlidesWrapper
				.children('div')
				.each((index, elem) => {
					slideWidth += $(elem).outerWidth();
				})
				.promise()
				.done(() => {
					this.$swiperSlidesWrapper.css('max-width', slideWidth);
				});

		},
	});
}(jQuery));
