'use strict';
(function ($) {
	/**
	 * MoinAiTrigger button decorator implementation.
	 *
	 * @author  <krystian.sadowski@namics.com>
	 * @namespace T.Module
	 * @class MoinAiTrigger
	 * @extends T.Module
	 */
	T.Module.MoinAiTrigger.Button = T.createDecorator({
		swapContent() {
			const check = setInterval(() => {
				const $botBtn = $($.find('.moin-ai-button')).contents().find('.knw_widget_button');
				if ($botBtn && $botBtn.length > 0) {
					$botBtn.trigger('click');
					clearInterval(check);
				}
			}, 100);
		}
	});
}(jQuery));