'use strict';
(function ($) {
	/**
	 * Keyservice Search module implementation.
	 *
	 * @author Krystian Sadowski <krystian.sadowski@mtc.berlin>
	 * @namespace T.Module
	 * @class KeyServiceSearch
	 * @extends T.Module
	 */
	T.Module.KeyServiceSearch = T.createModule({
		start: function start(resolve) {
			this._initApplication();
			this._readConfiguration();
			this._registerListeners();
			this._setupValidation();
			resolve();
		},
		_initApplication: function _initApplication() {
			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');
			this.$plzInput = this.$ctx.find('.js-plz-input');
			this.$plzContainer = this.$ctx.find('.js-plz-container');
			this.$keyserviceExistWrapper = this.$ctx.find('.js-keyservice-exist');
			this.$keyserviceNotExistWrapper = this.$ctx.find('.js-keyservice-not-exist');

			T.Utils.Application.init();
		},
		_readConfiguration: function _readConfiguration() {
			this._config = typeof this.$ctx.data('config') === 'object' ? this.$ctx.data('config') : JSON.parse(this.$ctx.data('config'));
			this.apiKeyService = this._config.apiKeyService;
			this.apiReverseGeocoding = this._config.apiReverseGeocoding;
		},
		_registerListeners: function _registerListeners() {
			this.$ctx.find('.js-localizeme').on('click', this._getClientGeoLocation.bind(this));
			this.$ctx.find('.js-form').submit(this._submitSerach.bind(this));
			this.$ctx.find('.js-submit').on('click', this._submitSerach.bind(this));
			this.$ctx.find('.js-request-door-opening').on('click', this._requestDoorOpeningForm.bind(this));
			this.$plzInput.on('focusout', this._checkValidationAndResetResults.bind(this));
		},
		_setupValidation: function _setupValidation() {
			const validationmessages = this._config.validationMessages || {};
			const rulesAndMessages = {
				rules: {
					"plz": {
						required: true,
						digits: true,
						maxlength: 5,
						minlength: 5
					}
				},
				messages: validationmessages
			};

			T.Utils.Validation.init(this.$ctx, this.$form, rulesAndMessages);
		},
		_submitSerach: function _submitSerach(ev) {
			ev.preventDefault();
			if (this.$form.valid()) {
				this._resetResultSection();
				this._requestKeyserviceExist();
				this._showAutolocationError(false);
			}
			else {
				this._resetResultSection();
				this._showError(false);
			}
		},
		_requestDoorOpeningForm: function _requestDoorOpeningForm(ev) {
			ev.preventDefault();
			const $link = $(ev.currentTarget);
			const url = T.Utils.Helper.updateUrlParameter({ 'plz': this.$plzInput.val() }, $link.attr('href'));

			setTimeout(() => {
				// For Firefox it is necessary to delay window
				window.open(url, "_blank");
			}, 100);
		},
		_checkValidationAndResetResults: function _checkValidationAndResetResults() {
			if (!this.$form.valid()) {
				this._resetResultSection();
				this._showError(false);
			}
		},
		_resetResultSection: function _resetResultSection() {
			this.$keyserviceExistWrapper.toggleClass('h-hidden', true);
			this.$keyserviceNotExistWrapper.toggleClass('h-hidden', true);
		},
		_getClientGeoLocation: function _getClientGeoLocation() {
			this._showAutolocationError(false);
			this._resetResultSection();
			this._resetPlzValidation();

			const error = (() => {
				this._showAutolocationError(true);
			});

			if (!navigator.geolocation) {
				console.warn("This browser doesn't support geolocation");
				error();
				return;
			}
			const success = ((position) => {
				const p = T.Utils.Geo.latlonToMercator({ x: position.coords.longitude, y: position.coords.latitude });

				p.x = Math.round(p.x);
				p.y = Math.round(p.y);

				const renderFunc = ((foundAddress) => {
					this.$plzInput.val(foundAddress.Postleitzahl);
					if (this.$form && this.$form.validation) {
						this.$form.validation.element(this.$queryInput);
						this.$queryInput.focus();
					}

					this._resetResultSection();
					this._requestKeyserviceExist(foundAddress.Postleitzahl);
				});

				T.Utils.Map.getGeolocationFromApiByCoords(p, renderFunc, error, this.apiReverseGeocoding);
			});
			navigator.geolocation.getCurrentPosition(success, error);
		},
		_showAutolocationError: function _showAutolocationError(show) {
			this.$ctx.find('.js-auto-location-err').toggleClass('h-hidden', !show);
		},
		_requestKeyserviceExist: function _requestKeyserviceExist(location) {
			this._showError(false);
			T.Utils.View.startLoader();

			let url = T.Utils.Helper.appendURIPath(window.location.origin, this.apiKeyService);
			if (location) {
				url = T.Utils.Helper.updateUrlParameter({ 'plz': location }, url);
			}
			else {
				url = T.Utils.Helper.updateUrlParameter({ 'plz': this.$plzInput.val() }, url);
			}

			const options = {
				method: 'POST',
				url: url
			};

			T.Utils.Ajax.fragment(options, this._handleKeyServiceApiSuccess.bind(this), this._handleKeyServiceApiFailure.bind(this));
		},
		_handleKeyServiceApiSuccess: function _handleKeyServiceApiSuccess(data) {
			if (data) {
				if (data.success && data.haskeyservice) {
					this.$keyserviceExistWrapper.toggleClass('h-hidden', false);
				}
				else if (!data.success) {
					this._showError(true);
				}
				else {
					this.$keyserviceNotExistWrapper.toggleClass('h-hidden', false);
				}
			}
			T.Utils.View.stopLoader();
		},
		_handleKeyServiceApiFailure: function _handleKeyServiceApiFailure() {
			T.Utils.View.stopLoader();
			this._showError(true);
		},
		_showError: function _showError(show) {
			this.$ctx.find(".js-search-error").toggleClass('h-hidden', !show);
		},
		_resetPlzValidation: function _resetPlzValidation() {
			this.$plzContainer.removeClass('is-error is-check');
			this.$plzInput.removeClass('is-error');
		}
	});
}(jQuery));