(function($)
{
	'use strict';
	/**
	 * BasicDropdownLayer module implementation.
	 *
	 * @author  <s.vogt@mgo360.de>
	 * @namespace T.Module
	 * @class BasicDropdownLayer
	 * @extends T.Module
	 */
	T.Module.BasicDropdownLayer = T.createModule({
		/** @type {jQuery} */
		$ctx: null,

		start: function(resolve)
		{
			this.$ctx = $(this._ctx);

			this.toggleHead = '.js-toggle-head';
			this.dataFilter = '[data-filter]';

			// init toggle
			this.initToggle();

			// init click input
			this.initClickInput();

			// init click ouside
			this.initClickOutside();

			resolve();
		},

		initToggle: function()
		{
			this.$ctx.find(this.toggleHead).on('click', (e) =>
			{
				$(e.currentTarget).toggleClass('is-open').next().slideToggle('fast');
			});
		},

		initClickInput: function()
		{
			this.$ctx.find('[data-filter] > input').on('click', (e) =>
			{
				if($(e.currentTarget).prop('checked') === true)
				{
					sessionStorage.setItem(`basickeywordfilter_${  $(e.currentTarget).closest(this.dataFilter).data('filter')  }_${  location.pathname}`, true);
				}
				else
				{
					sessionStorage.removeItem(`basickeywordfilter_${  $(e.currentTarget).closest(this.dataFilter).data('filter')  }_${  location.pathname}`);
				}
			});
		},

		initClickOutside: function()
		{
			// add listener for outside clicks
			$('.l-outer').on('click.outsideClickBasicDropdownLayer', (e) => {

				// if clicked outside
				if (!this.$ctx.is(e.target) && !this.$ctx.find('*').is(e.target) && this.$ctx.find(this.toggleHead).hasClass('is-open')) {
					this.$ctx.find(this.toggleHead).toggleClass('is-open').next().slideToggle('fast');
				}
			});
		},
	});
}(jQuery));
