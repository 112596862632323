(function($) {
	'use strict';
	/**
	 * BasicRangeSlide module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicRangeSlide
	 * @extends T.Module
	 */
	T.Module.BasicRangeSlide = T.createModule({

		/** @type {Object} */
		initData: null,

		/** @type {Object} */
		initDataMb: null,

		/** @type {Array} */
		stepValues: [],

		/** @type {Number} */
		val1: null,

		/** @type {Number} */
		val2: null,

		/** @type {Boolean} */
		singleSelection: false,

		/** @type {String} */
		valueFormat: "",

		/** @type {String} */
		valueSuffix: "",

		start: function(resolve) {

			this.$ctx = $(this._ctx);

			this.initData = this.$ctx.data('init');
			this.initDataMb = this.$ctx.data('mobile');
			this.initDataSelect = this.$ctx.data('select');
			this.range = this.$ctx.find('.js-range').get(0);

			this.rangeSliderStart = '.js-range-slider-start';
			this.rangeSliderEnd = '.js-range-slider-end';
			this.noUiMarker = '.noUi-marker';
			this.start = [];

			if ( typeof this.initData.valueFormat !== 'undefined' && this.initData.valueFormat.length ) {
				this.valueFormat = this.initData.valueFormat;
			}

			if ( typeof this.initData.valueSuffix !== 'undefined' && this.initData.valueSuffix.length ) {
				this.valueSuffix = this.initData.valueSuffix;
			}

			if ( this.$ctx.hasClass('m-basic-range-slide--single-selection') ) {
				this.singleSelection = true;
			}

			// convert string with starting positions to array with integers
			let min,
				max,
				valueCount;

			if ( !this.singleSelection ) {

				if (!S.Utils.Helper.mq( 'tablet' ).matches && typeof this.initDataMb !== 'undefined')
				{
					this.stepValues = this.initData.valuesMb.split(',').map(function (item) {
						return parseInt(item, 10);
					});

					min = parseInt(this.initData.minMb, 10);
					max = parseInt(this.initData.maxMb, 10);
					valueCount = this.stepValues.length;

					this.start = this.initData.startMb.split(',').map(function (item) {
						return parseInt(item, 10);
					});
				}

				else
				{
					this.stepValues = this.initData.values.split(',').map(function (item) {
						return parseInt(item, 10);
					});

					min = parseInt(this.initData.min, 10);
					max = parseInt(this.initData.max, 10);
					valueCount = this.stepValues.length;

					this.start = this.initData.start.split(',').map(function (item) {
						return parseInt(item, 10);
					});
				}

				if(typeof this.initDataMb !== 'undefined' || typeof this.initDataSelect !== 'undefined') {
					this.resizeSlider(this.range, min, max, valueCount);
				}

				if(S.Utils.Helper.mq( 'tablet' ).matches || typeof this.initDataSelect === 'undefined') {
					this.createSlide(this.range, min, max, valueCount);

					setTimeout(()=> {
						this.setActiveBullets(this.range, this.start, 'init');
					}, 100);
				}

				this.setSliderWidth(this.range, valueCount);

				if(!S.Utils.Helper.mq( 'tablet' ).matches && typeof this.initDataSelect !== 'undefined') {
					this.createSelect();
				}
			}

			else {

				this.stepValues = this.initData.values.split(',').map(function (item) {
					return parseInt(item, 10);
				});

				min = parseInt(this.initData.min, 10);
				max = parseInt(this.initData.max, 10);
				valueCount = this.stepValues.length;

				this.start = this.initData.start.split(',').map(function (item) {
					return parseInt(item, 10);
				});

				this.createSingleSlide(this.range, min, max, valueCount);
			}

			resolve();
		},

		createSlide(slider, min, max, valueCount)
		{
			const percent = 100/(valueCount-1),
				range = {};
			let start,
				end;

			$.each(this.stepValues, (index, step) => {
				const rangeIndex = parseFloat(percent) * parseInt(index);
				if(rangeIndex === 0) {
					range['min'] = min;
				}  else if (rangeIndex === 100) {
					range['max'] = max;
				} else {
					range[`${rangeIndex  }%`] = step;
				}
			});

			// check init start / end
			if (this.$ctx.find(this.rangeSliderStart).val() === '')
			{
				start = this.start[0];
				end = this.start[1];
			}
			else
			{
				start = this.$ctx.find(this.rangeSliderStart).val();
				end = this.$ctx.find(this.rangeSliderEnd).val();
			}

			noUiSlider.create(slider, { // eslint-disable-line
				start: [ start, end ],
				snap: true,
				connect: true,
				//behaviour: 'drag',
				range: range,
				pips: {
					mode: 'values',
					density: this.stepValues,
					stepped: true,
					values: this.stepValues
				}
			});

			slider.noUiSlider.on('update.createSlider', (values, handle) => { // eslint-disable-line
				this.setActiveBullets(slider, values, 'slider');
				this.start[0] = parseInt(values[0]);
				this.start[1] = parseInt(values[1]);
				this.$ctx.find(this.rangeSliderStart).val(parseInt(values[0]));
				this.$ctx.find(this.rangeSliderEnd).val(parseInt(values[1]));
			});
		},

		createSingleSlide(slider, min, max, valueCount)
		{
			const that = this;

			const percent = 100/(valueCount-1),
				range = {};

			$.each(this.stepValues, (index, step) => {
				const rangeIndex = parseFloat(percent) * parseInt(index);
				if(rangeIndex === 0) {
					range['min'] = min;
				}  else if (rangeIndex === 100) {
					range['max'] = max;
				} else {
					range[`${rangeIndex  }%`] = step;
				}
			});

			if(slider && slider.noUiSlider) {
				slider.noUiSlider.destroy();
			}

			noUiSlider.create(slider, { // eslint-disable-line
				start: [this.initData.start],
				snap: true,
				connect: false,
				//behaviour: 'drag',
				range: range,
				pips: {
					mode: 'values',
					density: this.stepValues,
					stepped: true,
					values: this.stepValues,
					format: {
						// 'to' the formatted value. Receives a number.
						to: function (value) {

							// format value as german number format: '50.000' || '100.000'
							if (that.valueFormat === '3dig') {
								value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); //NOSONAR expression checked
							}

							// append value suffix to the value: '50.000 €'
							if (that.valueSuffix.length) {
								value = `${value}&nbsp;${that.valueSuffix}`;
							}

							return value;
						}
					}
				}
			});

			slider.noUiSlider.on('update.createSlider', (values, handle) => { // eslint-disable-line

				this.setActiveBullets(slider, values, 'slider');
				this.start[0] = parseInt(values[0]);
				this.start[1] = parseInt(values[1]);
				this.$ctx.find(this.rangeSliderStart).val(parseInt(values[0]));
				this.$ctx.find(this.rangeSliderEnd).val(parseInt(values[1]));
			});

			// US-Uselab Dummy updater
			this._events.on('updateRangeSlider', (val) => {
				slider.noUiSlider.set( val );
			});

			that.addJSHelperClass();
		},

		createSelect(){

			$(this.range).closest('.mm-range-container').css({'display': 'none'});

			const selectBoxStart = $('<select></select>').prependTo(this.$ctx.find('.a-basic-input-select').first()),
				selectBoxEnd = $('<select></select>').prependTo(this.$ctx.find('.a-basic-input-select').last()),
				optionValue = 'option[value=';
			let activeIndex1 = 0,
				activeIndex2 = 0;

			$(this.stepValues).each((index, elem) => {

				if (typeof this.val1 !== 'number') {
					this.val1 = parseInt(this.start[0]);
				}

				if (typeof this.val2 !== 'number') {
					this.val2 = parseInt(this.start[1]);
				}

				if(this.val1 === elem){
					activeIndex1 = index;
				}

				if(this.val2 === elem){
					activeIndex2 = index;
				}


				selectBoxStart.append($("<option>").attr('value',index).text(elem));
				$(selectBoxStart).find('option').removeAttr('selected', 'selected');
				$(selectBoxStart).find(`${optionValue + activeIndex1}]`).attr('selected', 'selected');
				this.$ctx.find(this.rangeSliderStart).val($(selectBoxStart).find(`${optionValue + activeIndex1}]`).text());

				selectBoxEnd.append($("<option>").attr('value',index).text(elem));
				$(selectBoxEnd).find('option').removeAttr('selected', 'selected');
				$(selectBoxEnd).find(`${optionValue + activeIndex2}]`).attr('selected', 'selected');
				this.$ctx.find(this.rangeSliderEnd).val($(selectBoxStart).find(`${optionValue + activeIndex2}]`).text());
			});

			selectBoxStart.on('change', (e) => { // eslint-disable-line
				this.start[0] = selectBoxStart.find(' > option:selected').eq(0).text();
				this.$ctx.find(this.rangeSliderStart).val(this.start[0]);
			});

			selectBoxEnd.on('change', () => {
				this.start[1] = selectBoxEnd.find(' > option:selected').eq(0).text();
				this.$ctx.find(this.rangeSliderEnd).val(this.start[1]);
			});
		},

		resizeSlider(slider, min, max, valueCount) {
			S.Utils.Helper.mq( 'tablet' ).addListener( (mq) => {

				if (mq.matches) {

					if (typeof this.initDataSelect !== 'undefined') {

						$(this.range).closest('.mm-range-container').css({'display': 'block'});

						this.start[0] = this.$ctx.find('.ll-inline-element:first option:selected').text();
						this.start[1] = this.$ctx.find('.ll-inline-element:last option:selected').text();

						this.$ctx.find('select').remove();

					} else if(typeof this.initDataMb !== 'undefined') {

						slider.noUiSlider.destroy();
					}

					this.stepValues = this.initData.values.split(',').map(function (item) {
						return parseInt(item, 10);
					});

					min = parseInt(this.initData.min, 10);
					max = parseInt(this.initData.max, 10);
					valueCount = this.stepValues.length;

					const percent = 100 / (valueCount - 1),
						range   = {};

					$.each(this.stepValues, (index, step) => {
						const rangeIndex = parseFloat(percent) * parseInt(index);

						if (rangeIndex === 0) {
							range['min'] = min;
						} else if (rangeIndex === 100) {
							range['max'] = max;
						} else {
							range[`${rangeIndex  }%`] = step;
						}
					});

					noUiSlider.create(slider, { // eslint-disable-line
						start: [this.start[0], this.start[1]],
						snap: true,
						connect: true,
						//behaviour: 'drag',
						range: range,
						pips: {
							mode: 'values',
							density: this.stepValues,
							stepped: true,
							values: this.stepValues
						}
					});

					slider.noUiSlider.on('update.resize', (values, handle) => { // eslint-disable-line
						this.setActiveBullets(slider, values);

						this.$ctx.find(this.rangeSliderStart).val(parseInt(values[0]));
						this.$ctx.find(this.rangeSliderEnd).val(parseInt(values[1]));

						this.val1 = parseInt(values[0]);
						this.val2 = parseInt(values[1]);
					});

					this.setSliderWidth(slider, valueCount);

				} else {

					if (typeof this.initDataMb !== 'undefined' && typeof this.initDataSelect === 'undefined') {

						slider.noUiSlider.destroy();

						this.start = this.initData.startMb.split(',').map(function (item) {
							return parseInt(item, 10);
						});

						this.stepValues = this.initData.valuesMb.split(',').map(function (item) {
							return parseInt(item, 10);
						});

						min = parseInt(this.initData.minMb, 10);
						max = parseInt(this.initData.maxMb, 10);
						valueCount = this.stepValues.length;

						const percent = 100 / (valueCount - 1),
							range   = {};

						$.each(this.stepValues, (index, step) => {
							const rangeIndex = parseFloat(percent) * parseInt(index);
							if (rangeIndex === 0) {
								range['min'] = min;
							} else if (rangeIndex === 100) {
								range['max'] = max;
							} else {
								range[`${rangeIndex  }%`] = step;
							}
						});

						noUiSlider.create(slider, { // eslint-disable-line
							start: [this.start[0], this.start[1]],
							snap: true,
							connect: true,
							//behaviour: 'drag',
							range: range,
							pips: {
								mode: 'values',
								density: this.stepValues,
								stepped: true,
								values: this.stepValues
							}
						});

						slider.noUiSlider.on('update.resizeSmall', (values) => {
							this.setActiveBullets(slider, values);
							this.$ctx.find(this.rangeSliderStart).val(parseInt(values[0]));
							this.$ctx.find(this.rangeSliderEnd).val(parseInt(values[1]));
						});

						this.setSliderWidth(slider, valueCount);

					} else if (typeof this.initDataSelect !== 'undefined') {

						this.createSelect();

						slider.noUiSlider.destroy();

					}
				}
			});
		},

		setActiveBullets(slider, values) {

			const startHandleVal = parseInt(values[0]),
				endHandleVal = parseInt(values[1]);
			let startHandleIndex = 0,
				endHandleIndex = 0;

			$.each(this.stepValues, (index, value) => {
				if(value === startHandleVal){
					startHandleIndex = index;
				} else if(value === endHandleVal) {
					endHandleIndex = index;
				}
			});

			// single handle
			if ( this.singleSelection ) {

				this.$ctx.find(this.noUiMarker).each( (index, elem)=> {
					if ( index === startHandleIndex ) {
						$(elem).addClass('is-active');
						$(elem).next('.noUi-value').addClass('is-active');
					} else {
						$(elem).removeClass('is-active');
						$(elem).next('.noUi-value').removeClass('is-active');
					}
				});
			}

			else {
				this.$ctx.find(this.noUiMarker).each( (index, elem)=> {
					if(index >= startHandleIndex && index <= endHandleIndex){
						$(elem).addClass('is-active');
					} else {
						$(elem).removeClass('is-active');
					}
				});
			}
		},

		setSliderWidth(slider, valueCount) {
			$(slider).css({'max-width': `${70 * valueCount}px`});
		},

		addJSHelperClass() {

			const values = this.initData.values.split(',');

			this.$ctx.find(this.noUiMarker).each( (index, elem)=> {
				$(elem).addClass(`js-value-${values[index]}`);
				this.clickHelper(elem, values[index]);
			});

			// set tabIndex and esc/enter event
			S.Globals.TabFocus.addPopup(this.$ctx.find(this.noUiMarker), this);
		},

		_handleFocusEnter($target)
		{
			$target.trigger('updateSlider');
		},

		/**
		 * clickHelper is used to trigger clicks in be-/testing-environments:
		 *
		 * we define click events for each ui-marker to update the slider with the attached value
		 *
		 * @param elem {Object}
		 * @param val {Number}
		 */
		clickHelper(elem, val)
		{
			$(elem).on('updateSlider', () => {
				this._events.emit('updateRangeSlider', (val) );
			});
		}
	});
}(jQuery));
