(function ($)
{
    'use strict';

    /**
     * BasicInputCheckbox module implementation.
     *
     * @author  <w.eberts@edelweiss72.de>
     * @namespace T.Module
     * @class BasicInputCheckbox
     * @extends T.Module
     */
    T.Module.BasicInputCheckbox = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {jQuery} */
        $input: null,

        /** @type {string} */
        error: '',

        /** @type {boolean} */
        valid: false,

        /** @type {T.Module.BasicForm} BasicForm: we can be validated */
        BasicForm: null,

        /** @type {boolean} BasicForm: do we show a valid/error symbol? */
        useCheck: false,

        /** @type {jQuery} BasicForm: just a reference */
        $row: null,

        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function (resolve)
        {
            this.$ctx = $(this._ctx);
            this.$input = this.$ctx.children('input');

            // set/remove focus/mouseout class for separat highlight svg for focus by tab and click
            this.setFocus();

            // validate on modification
            this.$input.on('change.basic blur.basic input.basic', this.validate.bind(this));

            // show password
            this.showPassword();

            resolve();
        },
        /**
         *
         * setFocus class for separat styling from focus by tab
         */
        setFocus: function () {
            const that = this;
            const mouseover = 'js-mouseover';

            this.$input.focus(function(){
                if (that.$ctx.hasClass(mouseover)){
                    that.$ctx.addClass('js-clicked');
                }else{
                    that.$ctx.removeClass('js-clicked');
                }
            });

            this.$ctx.on('mouseover', function(){
                that.$ctx.addClass(mouseover);
            });

            this.$ctx.on('mouseout', function(){
                that.$ctx.removeClass(mouseover);
            });
        },

        /**
         *
         * @returns {string}
         */
        getType: function ()
        {
            return this.$input.attr('type');
        },

        /**
         *
         * @returns {boolean}
         */
        isVisible: function ()
        {
            return this.$input.is(':visible');
        },

        /**
         *
         * @returns {boolean}
         */
        isChecked: function ()
        {
            return this.$input.is(':checked');
        },

        /**
         *
         * @returns {boolean}
         */
        isRequired: function ()
        {
            return this.$input.is('[required]');
        },

        /**
         *
         * @returns {boolean}
         */
        isValid: function ()
        {
            return this.valid;
        },

        /**
         *
         * @param status
         * @param showStatus
         */
        setValid: function (status, showStatus = true)// eslint-disable-line
        {
            this.valid = status;

            if (status)
            {
                this.setError('', false);
            }

            return status;
        },

        /**
         *
         * @returns {boolean}
         */
        isError: function ()
        {
            return this.error !== '';
        },

        /**
         *
         * @param {string} [type='']
         * @param {boolean} [showStatus=true]
         * @returns {boolean}
         */
        setError: function (type = '', showStatus = true)// eslint-disable-line
        {
            this.error = type;

            if (type)
            {
                this.setValid(false, false);
            }

            return !type;
        },

        /**
         *
         * @returns {string}
         */
        getError: function ()
        {
            return this.error;
        },

        /**
         *
         * @param {boolean} [showStatus=true]
         * @returns {*}
         */
        validate: function (showStatus = true)
        {
            // require at least one character, no whitespace
            if (this.isRequired() && !this.isChecked())
            {
                return this.setError('required', showStatus);
            }

            this.setValid(this.isChecked(), false);

            // no validation
            return this.setError('', showStatus);
        },

        /**
         * show password
         */
        showPassword: function () {
			const fieldset =  '.ll-fieldset';

            this.$input.change(function() {
                if ($(this).is(':checked') && $(this).is("[name='passwordcbx']")){
					$(fieldset).find('#password').attr('type', 'text');
					$(fieldset).find('#repeatPassword').attr('type', 'text');
                }
                else if($(this).is("[name='passwordcbx']")) {
					$(fieldset).find('#password').attr('type', 'password');
					$(fieldset).find('#repeatPassword').attr('type', 'password');
                }
            });
        }
    });
}(jQuery));
