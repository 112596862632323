/**
 * MglCompetition module implementation.
 *
 * @author  <ibrahim.mahria@adac.de>
 * @class MglCompetition - Gewinnspiel für Mitglieder
 */
class MglCompetition extends BasisComponent {

	constructor() {
		super();
		this.jsConfirmationDiv = ".js-confirmation";
		this.jsConfirmationText = ".js-confirmation-text";
		this.jsConfirmationAPIFehler = ".js-confirmation-Error-text";
		this.jsMglLoggedDiv = ".js-mgl-logged";
		this.jsIsShared = ".js-isshared";
		this.hiddenClass = "h-hidden";
	}

	_connectedCallback() {
		this.$ctx = jQuery(this);
		T.Utils.Application.init();
		this.readConfiguration();
		this.addEventListener();
		T.Utils.View.startLoader();
		T.Utils.Auth.getResolvedIdentity(this.checkCompetitionAndhandleUser.bind(this));
	}

	readConfiguration() {
		this._backend = this.$ctx.data('backend') || T.Utils.Application.getApi();
		this.config = JSON.parse(this.dataset.config ? this.dataset.config : {});
	}

	addEventListener() {
		this.$ctx.find(".js-submit-form").on("click", this.sendParticipation.bind(this));
		this.$ctx.find(".js-login-btn").on('click', (ev) => {
			ev.preventDefault();
			T.Utils.Auth.authorize();
		});
	}

	async checkCompetitionAndhandleUser(user) {
		this._user = user;
		const isLoggedIn = await T.Utils.Auth.isLoggedIn();
		if (this._user?.UserData?.IstMitglied) {
			T.Utils.Auth.getBearerToken(this.checkCompetition.bind(this));
		} else if (!isLoggedIn) {
			this.$ctx.find(".js-isnotlogged").removeClass(this.hiddenClass);
		} else {
			this.$ctx.find(".js-user-logged").removeClass(this.hiddenClass);
		}
		T.Utils.View.stopLoader();
	}

	checkCompetition(token) {
		const url = T.Utils.Helper.appendURIPath(this._backend, this.config.gewinnspielpruefen);
		const options = {
			type: 'GET',
			url: url,
			headers: {
				Authorization: `Bearer ${token}`
			},
			data: { "mitgliedsnummer": this._user.MglNo }
		};
		T.Utils.Ajax.fragment(options, this.canCompetitionSuccess.bind(this), this.canCompetitionError.bind(this));
	}

	canCompetitionSuccess(data) {
		const canParticipate = data?.Data && data?.Success;
		if (canParticipate) {
			this.$ctx.find(this.jsMglLoggedDiv).removeClass(this.hiddenClass);
			const mgl = this._user.UserData;
			if (mgl) {
				this.$ctx.find(".js-name").text(`${mgl.Vorname} ${mgl.Nachname}`);
				if (mgl.Email) {
					this.$ctx.find(".js-email").text(mgl.Email);
				}
			}
		}
	}

	canCompetitionError(err) {
		this.$ctx.find(this.jsMglLoggedDiv).addClass(this.hiddenClass);
		if (err && err.status === 400 && err.responseJSON && err.responseJSON.Messages) {
			if (err.responseJSON.Messages[0].Id === 337001) {
				this.$ctx.find(this.jsIsShared).removeClass(this.hiddenClass);
				this.$ctx.find(this.jsConfirmationDiv).removeClass(this.hiddenClass);
			} else {
				this.$ctx.find(this.jsConfirmationAPIFehler).removeClass(this.hiddenClass);
				this.$ctx.find(this.jsConfirmationDiv).removeClass(this.hiddenClass);
			}
		} else {
			this.$ctx.find(this.jsConfirmationAPIFehler).removeClass(this.hiddenClass);
			this.$ctx.find(this.jsConfirmationDiv).removeClass(this.hiddenClass);
		}
		T.Utils.View.stopLoader();
	}

	sendParticipation(token) {
		T.Utils.View.startLoader();
		const userData = this._user?.UserData;
		if (userData && userData.Email) {
			const url = T.Utils.Helper.appendURIPath(this._backend, this.config.gewinnspielspeichern);
			const options = {
				type: 'POST',
				url: url,
				headers: {
					Authorization: `Bearer ${token}`
				},
				data: {
					"Mitgliedsnummer": this._user.MglNo,
					"EmailAdresse": userData.Email,
					"Name": userData.Nachname,
					"Vorname": userData.Vorname
				},
				contentType: "application/x-www-form-urlencoded;charset=utf-8"
			};
			T.Utils.Ajax.fragment(options, this.successCallback.bind(this), this.errorCallback.bind(this));
		}
		else {
			this.$ctx.find(".js-error").removeClass(this.hiddenClass);
			T.Utils.View.stopLoader();
		}
	}

	successCallback() {
		this.$ctx.find(this.jsMglLoggedDiv).addClass(this.hiddenClass);
		this.$ctx.find(this.jsConfirmationDiv).removeClass(this.hiddenClass);
		this.$ctx.find(this.jsConfirmationText).removeClass(this.hiddenClass);
		T.Utils.View.stopLoader();
	}

	errorCallback(err) {
		this.$ctx.find(this.jsMglLoggedDiv).addClass(this.hiddenClass);
		if (err && err.status === 400 && err.responseJSON && err.responseJSON.Messages) {
			if (err.responseJSON.Messages[0].Id === 337001) {
				this.$ctx.find(this.jsIsShared).removeClass(this.hiddenClass);
				this.$ctx.find(this.jsConfirmationDiv).removeClass(this.hiddenClass);
			} else {
				this.$ctx.find(this.jsConfirmationDiv).removeClass(this.hiddenClass);
				this.$ctx.find(this.jsConfirmationAPIFehler).removeClass(this.hiddenClass);
			}
		} else {
			this.$ctx.find(this.jsConfirmationDiv).removeClass(this.hiddenClass);
			this.$ctx.find(this.jsConfirmationAPIFehler).removeClass(this.hiddenClass);
		}
		T.Utils.View.stopLoader();
	}
}
window.customElements.define('mgl-competition', MglCompetition);