(function ($)
{
    'use strict';
    /**
     * MyAdacListToggleElement module implementation.
     *
     * @author Tobias <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class MyAdacToggleElements
     * @extends T.Module
     */
    T.Module.MyAdacContentHint = T.createModule({

        /** @type {Number} this.module*/
        $module: null,


        start(resolve)
        {
            this.$module = $(this._ctx);

            resolve();
        },
    });
}(jQuery));
