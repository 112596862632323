(function($) {
	'use strict';
	/**
	 * FdlCreditCardApplication module implementation.
	 *
	 * @author Niclas Bork <n.bork@edelweiss72.de>
	 * @namespace T.Module
	 * @class FdlCreditCardApplication
	 * @extends T.Module
	 */
	T.Module.FdlCreditCardApplication = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        start: function (resolve)
		{
            this.$ctx = $(this._ctx);

            // credit card select
            this.select();

            // calculate credit card packages
			this.calculate();

			// confirm application
            this.confirm();

            resolve();
        },
        /**
		 * credit card select
         */
		select: function ()
		{
            this.$ctx
                .find(`.oo-content > div[data-section="${this.$ctx.find('.oo-switch input[type="radio"]:checked').attr('id')}"]`)
                .addClass('js-fdl-creditcardselect-active')
                .siblings('.js-fdl-creditcardselect-active')
                .removeClass('js-fdl-creditcardselect-active');
		},
        /**
		 * calculate credit card packages
         */
        calculate: function ()
		{
            const that = this;

            this.$ctx.find('input[type=checkbox]').on('change', (event) => {

                const $parent = $(event.currentTarget).parent();

                function recalculate() {
                    let sum = 0;

                    that.$ctx.find('input[type=checkbox]:checked').each(function() {
                        sum += parseFloat($(this).val().replace(',', '.'));
                    });

                    that.$ctx.find('.oo-content-result .oo-total').html(`${sum.toFixed(2).replace('.', ',')} ` + `€`);
                }

                if ($parent.is('.js-checkbox-deal')) {
                    // uncheck all others
                    that.$ctx.find('.oo-content-inner div:not(.js-checkbox-deal) input[type=checkbox]').prop('checked', false);
                } else {
                    // if all 3 selected
                    if (that.$ctx.find('.oo-content-inner div:not(.js-checkbox-deal) input[type=checkbox]:checked').length === 3) {
                        that.$ctx.find('.oo-content-inner div:not(.js-checkbox-deal) input[type=checkbox]').prop('checked', false);
                        that.$ctx.find('.oo-content-inner div.js-checkbox-deal input[type=checkbox]').prop('checked', true);
                    } else {
                        // uncheck js-checkbox-deal
                        that.$ctx.find('.oo-content-inner div.js-checkbox-deal input[type=checkbox]').prop('checked', false);
                    }
                }

                recalculate();

            });
        },
        /**
         * confirm application
         */
        confirm: function ()
        {
            const btnconfirm = $('.btn-confirm');

            btnconfirm.on('click', (e) => {
                e.preventDefault();

                if (!$('input[type=checkbox]:checked').length) {
                    $('a.js-lightbox--trigger[href="#popup_placeholder_selection"]').trigger('click');
                }
            });
        }
	});
}(jQuery));
