(function ($)
{
    'use strict';
    /**
     * MyAdacDashboardList module implementation.
     *
     * @author Tobias <t.grigoriadis@edelweiss72.de>
     * @namespace T.Module
     * @class MyAdacDashboardList
     * @extends T.Module
     */
    T.Module.MyAdacDashboardList = T.createModule({

        /** @type {jQuery} this.module ID */
        $module: null,

        /** @type {Number} this.module ID */
        moduleId: null,

        /** @type {jQuery}*/
        $listWrapper: null,

        /** @type {jQuery}*/
        $leftListPointWrapper: null,

        /** @type {jQuery}*/
        $rightListPointWrapper: null,

        /** @type {number}*/
        neededLeftListWidth: 0,

        /** @type {number}*/
        neededRightListWidth: 0,

        /** @type {number}*/
        givenLeftListWidth: 0,

        /** @type {number}*/
        givenRightListWidth: 0,

        /** @type {Boolean}*/
        firstInit: true,

        /** @type {Boolean}*/
        stopper: false,

        /** @type {jQuery}*/
        infoIcon: null,


        start(resolve)
        {
            this.$module = $(this._ctx);

			const listWrapperClass = '.mm-list-wrapper',
				outerMyAdacContent = '.l-outer--my-adac-content';

            this.moduleId = this.$module.data('tId');
            this.$listWrapper = this.$module.find(listWrapperClass);
            this.$leftListPointWrapper = this.$listWrapper.find('.mm-left-list-wrapper');
            this.$leftListPoints = this.$leftListPointWrapper.find('.mm-list-point-headline');
            this.$rightListPointWrapper = this.$listWrapper.find('.mm-right-list-wrapper');
            this.$rightListPoints = this.$rightListPointWrapper.find('.mm-list-point-text');
            this.givenLeftListWidth = this.$leftListPointWrapper.width();
            this.givenRightListWidth = this.$rightListPointWrapper.width();
            this.infoIcon = this.$module.find('.mm-info-icon') || null;
            this.infoIconLabel = this.infoIcon.closest('.mm-left-list-wrapper, .mm-right-list-wrapper') || null;

			// check if an "i"-icon is in the list and make the label and icon clickable
			if (this.infoIcon.length && this.infoIcon !== null)
			{
				// for clicking a button with the class - mm-layer
				// which contains a layer -> trigger the event
				this.infoIconLabel.on('click', (e) =>
				{
					const amount = $(e.currentTarget).closest(listWrapperClass).find('.m-my-adac-dashboard-box-layer').length;

					$(e.currentTarget).closest(listWrapperClass).removeClass('open-left open-right');

					if($(e.currentTarget).hasClass('mm-left-list-wrapper') && amount > 1) {
						$(e.currentTarget).closest(listWrapperClass).addClass('open-left');
					}
					else if($(e.currentTarget).hasClass('mm-right-list-wrapper') && amount > 1) {
						$(e.currentTarget).closest(listWrapperClass).addClass('open-right');
					}

					e.stopPropagation();

					this._events.emit('MyAdacDashboardBoxLayer.open-layer-info-icon', $(e.currentTarget).closest(listWrapperClass));
				});
			}

            this._events.on('MyAdacDashboardList.content-changed', (e) =>
            {
                if (typeof e !== 'undefined' && e.is(this.$module.closest('.mm-box')) && this.firstInit)
                {
                    this.init();
                    this.firstInit = false;
                }

                if (this.$module.closest(outerMyAdacContent).length && this.firstInit)
                {
                    this.init();
                    this.firstInit = false;
                }
            });

            if (this.$module.closest('.m-my-adac-dashboard-box--logged-out').length)
            {
                this.init();
                this.firstInit = false;
            }

            if (this.$module.closest(outerMyAdacContent).length && !this.$module.hasClass('mm-equal-columns'))
            {
                this.resize();
                this.checkForMyAdacContent();
            }

            if (this.$module.closest(outerMyAdacContent).length && this.$module.hasClass('mm-equal-columns'))
            {
                this.$listWrapper.removeClass('is-hidden');
            }

            resolve();
        },

        /**
         * init only on trigger
         */
        init()
        {
            this.checkLeftListWrapper();
        },

        /**
         * check before calculating and initializing if it necessary for this list to be calculated
         * or if the words fits in the 40%/60% split
         * CHECK LEFT FIRST, if this fits the split check the right sight
         */
        checkLeftListWrapper()
        {
            let checkTheRightListToo = true;

            // eslint-disable-next-line
            this.$leftListPoints.each((index, element) => //NOSONAR
            {
                if (this.givenLeftListWidth <= ($(element).outerWidth(true)))
                {
                    this.setHighestWidthToAllListpoints();
                    checkTheRightListToo = false;

                    return false;
                }
            });

            if (checkTheRightListToo)
            {
                this.checkRightListWrapper();
            }
        },

        /**
         * check before calculating and initializing if it necessary for this list to be calculated
         * or if the words fits in the 40%/60% split
         * CHECK RIGHT SIGHT, if this fits the split all is fine
         */
        checkRightListWrapper()
        {
            // eslint-disable-next-line
            this.$rightListPoints.each((index, element) => //NOSONAR
            {

                if (this.givenRightListWidth <= ($(element).outerWidth(true)))
                {
                    this.setHighestWidthToAllListpoints();

                    return false;
                }
            });

            this.$listWrapper.removeClass('is-hidden');
        },

        /**
         * only for l-outer--my-adac-content sites
         */
        checkForMyAdacContent()
        {
            this.$listWrapper.removeClass('is-hidden');
            this.stopper = true;
        },

        /**
         * get the width of longest content
         * @param {jQuery} $elementCollection Object
         * @return {number} width number
         */
        getContentWidth($elementCollection)
        {
            const contentWidths = [];

            $elementCollection.each((index, element) =>
            {
                $(element).css({width: 'initial'});
                contentWidths.push($(element).outerWidth());
            });

            return Math.max.apply(null, contentWidths);
        },

        /**
         * sets the width of longest content
         * @param {jQuery} $elementCollection Object
         * @param {number} neededWidth number
         */
        setContentWidth($elementCollection, neededWidth)
        {
            $elementCollection.children().each((value, element) =>
            {
                $(element).css({maxWidth: neededWidth});
            });
        },

        /**
         * get the width of longest content
         * set it first to the left list-wrapper and then for each element in the right
         */
        setHighestWidthToAllListpoints()
        {
            this.$listWrapper.removeClass('is-hidden');
        },

        /**
         * only resize for my-adac-content site´s
         * tablet/desktop: listelements 50/50 mobile: calculate listelements
         */
        resize()
        {
            // Store the window width
            let windowWidth = $(window).width();

            // Resize Event
            $(window).on('resize', () =>
            {
                if ($(window).width() !== windowWidth)
                {
                    // Update the window width for next time
                    windowWidth = $(window).width();

                    S.Utils.delayed(`resize-list-elements-content-${  this.moduleId}`, 200, () =>
                    {
                        // On resize, close all boxes and themeheadline just ones
                        // set the stopper on true if it changes from mobile to tablet
                        // to do it again if it changes again
                        if (!S.Utils.Helper.mq('tablet').matches)
                        {
                            if (this.stopper)
                            {
                                this.setHighestWidthToAllListpoints();
                            }
                        }
                        else
                        {
                            if (!this.stopper)
                            {
                                this.$leftListPointWrapper.removeAttr("style");
                                this.$rightListPointWrapper.removeAttr("style");
                                this.$listWrapper.removeAttr("style");
                                this.stopper = true;
                            }
                        }
                    });
                }
            });
        },
    });
}(jQuery));
