/// <reference path="../../../../assets/typings/terrific-ext.d.ts" />
'use strict';
(function ($) {
	/**
	 * GasStationHotlist module implementation.
	 *
	 * @author  <dmitri.zoubkov@namics.com>
	 * @namespace T.Module
	 * @class GasStationHotlist
	 * @extends T.Module
	 */
	T.Module.GasStationHotlist = T.createModule({
		SESSIONKEY_FUELTYPE: "Application.GasStationHotlist.FuelType",
		start: function start(resolve) {
			T.Utils.Application.init();
			this.$ctx = $(this._ctx);
			this._readConfiguration();
			this._addListeners();
			T.Utils.Auth.getResolvedIdentity(async () => {
				const isLoggedIn = await T.Utils.Auth.isLoggedIn();
				if (isLoggedIn) {
					this._initModule();
				} else {
					T.Utils.Auth.authorize();
				}
			});
			resolve();
		},
		_readConfiguration: function _readConfiguration() {
			this.apihotlist = this.$ctx.data('apihotlist');
			this.apihotlistdeleteitem = this.$ctx.data('apihotlistdeleteitem');
			this._detailPageUrl = this.$ctx.data('detailpageurl');
			this.operatorBenefitIdAssignment = this.$ctx.data('operatorbenefitidassignment');
			this.apiMembershipBenefitUrl = this.$ctx.data('apimembershipbenefiturl');
			this._detailPageUrlSfx = this.$ctx.data('detailpageurlsfx');
			this.$gasStationHotlistFuelType = this.$ctx.find("#gasStationHotlistFuelType");
			this._fuelTypes = [];
			this.$ctx.find('#gasStationHotlistFuelType option[value!="-"]').each((index, element) => {
				this._fuelTypes.push(element.value);
			});
			this.$itemTemplate = $(this.$ctx.find(".js-result-row-template").html());
			this._selectedFuelType = T.Utils.Store.get(this.SESSIONKEY_FUELTYPE, T.Utils.Store.SESSION);
		},
		_addListeners: function _addListeners() {
			this.$gasStationHotlistFuelType.on("change", this._handleFuelTypeChange.bind(this));
		},
		_initModule: function _initModule() {
			this._requestHotlist(this._requestHotlistSuccess.bind(this), this._requestHotlistError.bind(this));
		},
		_showError: function _showError(show, keepResults) {
			this.$ctx.find('.js-hotlist-error').toggleClass('h-hidden', !show);
			this.$ctx.find('.js-hotlist-table').toggleClass('h-hidden', show && !keepResults);
		},
		_selectFuelType: function _selectFuelType(val) {
			this.$gasStationHotlistFuelType.find(`option[value="${val}"]`).prop("selected", true);
			this.$gasStationHotlistFuelType.trigger('change');
		},
		_buildDetailUrl: function _buildDetailUrl(item) {
			return T.Utils.Helper.appendURIPath(
				this._detailPageUrl,
				this._detailPageUrlSfx.interpolate({
					id: item.ID,
					name: T.Utils.Helper.createSeoUrl(`${item.PLZ}-${item.Ort}-${item.Name || "unknown"}`)
				}));
		},
		// eslint-disable-next-line sonarjs/cognitive-complexity
		_createResultRow: function _createResultRow(container, fuelType, item) {
			const $row = this.$itemTemplate.clone();
			let filter = $row.data("filter");
			if (!filter) {
				filter = { filter: "" };
			}
			filter.filter = fuelType;
			$row.data("filter", filter);
			// necessary since the table sorter doesn't copy data only attrs
			$row.attr("data-filter", JSON.stringify(filter));
			$row.attr("data-stationid", item.ID);
			$row.attr("data-fueltype", fuelType);

			const $gasstation = $row.find('.js-gasstation');
			$gasstation.find('a').attr('href', this._buildDetailUrl(item));
			if (item.Name) {
				$gasstation.find('strong').text(item.Name);
			}
			if (item.Strasse) {
				$gasstation.append($("<br />"));
				$gasstation.append($("<span />").text(`${item.Strasse} ${item.Hausnummer}`));
			}
			if (item.Ort || item.PLZ) {
				$gasstation.append($("<br />"));
				const val = item.PLZ ? `${item.PLZ} ` : "";
				$gasstation.append($("<span />").text(val + item.Ort));
			}

			const customerBenefits = Object.keys(this.operatorBenefitIdAssignment);

			let hasCustomerBenefit = false;
			let customerBenefitId = '';

			customerBenefits.forEach((elem) => {
				if (item.Name.toLowerCase().includes(elem.toLowerCase()) || (item.Betreiber && item.Betreiber.toLowerCase().includes(elem.toLowerCase()))) {
					hasCustomerBenefit = true;
					customerBenefitId = this.operatorBenefitIdAssignment[elem];
				}
			});

			if (hasCustomerBenefit) {
				$row.attr('data-benefit-id', customerBenefitId);
				const apiUrl = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.apiMembershipBenefitUrl);
				const options = {
					type: "GET",
					data: {
						angebotId: customerBenefitId
					},
					url: apiUrl
				};
				T.Utils.Ajax.fragment(options, (dataBenefit) => {
					if (dataBenefit && dataBenefit.Data) {
						this.$ctx.find(`[data-benefit-id="${dataBenefit.Data.AngebotId}"] .js-mgl-benefit`).toggleClass('h-hidden', false);
					}
				});
			}

			const $price = $row.find('.js-price');
			$price.find('.mm-digits').number(item.Preis, 3, ",", ".");
			if (item.Zeitpunkt) {
				const ts = moment(item.Zeitpunkt);
				$price.find('.mm-date').text(ts.format('L'));
				$price.find('.mm-time').text(ts.format('LT'));
			}
			// necessary since the table sorter deletes all DOM bindings
			$price.find('.js-provider-icon').not(`.js-item-${item.Provider.toLowerCase()}`).remove();
			$row.find('.js-item-delete').on('click', this._handleDeleteItem.bind(this));
			container.append($row);
		},
		_requestHotlistSuccess: function _requestHotlistSuccess(data) {
			try {
				if (data && data.Data) {
					const $container = this.$ctx.find('.js-hotlist-table table.mm-table tbody');
					$container.empty();
					// eslint-disable-next-line prefer-const
					for (const type in data.Data) {
						data.Data[type].forEach((item) => {
							if (item.Provider && undefined !== item.Preis) {
								this._createResultRow($container, type, item);
							}
						});
					}
				}

				this._showError(false);
				if (this._selectedFuelType) {
					this._selectFuelType(this._selectedFuelType);
				}
			} catch (e) {
				this._showError(true);
				T.Utils.Logger.log("Error rendering results", e);
			} finally {
				T.Utils.View.stopLoader();
			}
		},
		_requestHotlistError: function _requestHotlistError() {
			this._showError(true);
			T.Utils.View.stopLoader();
		},
		_requestHotlist: function _requestHotlist(successCallback, errorCallback) {
			T.Utils.View.startLoader();
			T.Utils.Auth.getBearerToken((token) => {
				const requests = [];
				const response = { Data: {} };
				this._fuelTypes.forEach(element => {
					const def = $.Deferred();
					requests.push(def);

					const req = {
						type: "GET",
						url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apihotlist),
						headers: {
							Authorization: `Bearer ${token}`,
							"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey()
						},
						data: {
							spritsorte: element
						}
					};

					T.Utils.Ajax.fragment(req, (data) => {
						if (data) {
							response.Success = data.Success;
							if (data.Success && data.Data) {
								response.Data[element] = data.Data;
							}
						}
						def.resolve(data);
					}, (jqxhr) => {
						// eslint-disable-next-line sonarjs/no-collapsible-if
						if (jqxhr && jqxhr.responseJSON && jqxhr.responseJSON.Messages && jqxhr.responseJSON.Messages.length > 0) {
							// User has no hot list.
							if (jqxhr.responseJSON.Messages[0].Id === 276000) {
								this.$ctx.find('.js-hotlist-error').addClass('h-hidden');
							}
						}
						def.reject(jqxhr);
					}
					);

				});
				$.when.apply($, requests).then(() => successCallback(response), errorCallback);
			});
		},
		_handleDeleteItem: function _handleDeleteItem(e, elm) {
			if (e) {
				e.preventDefault();
			}
			const $elm = $(elm || e.target);
			const id = $elm.parents('.js-result-row').data("stationid");
			const type = $elm.parents('.js-result-row').data("fueltype");

			const countRows = $elm.parents('table').find(`.js-result-row[data-fueltype="${type}"]`).length;

			this._showError(false);
			this._requestDeleteItem(id, () => {
				$elm.parents('table').find(`.js-result-row[data-stationid="${id}"]`).remove();
				if (1 >= countRows) {
					this._selectFuelType("-");
				}
				T.Utils.View.stopLoader();
			}, () => {
				this._showError(true, true);
				$(window).scrollTop(0);
				T.Utils.View.stopLoader();
			});

		},
		_requestDeleteItem: function _requestDeleteItem(id, successCallback, errorCallback) {
			T.Utils.View.startLoader();
			T.Utils.Auth.getBearerToken((token) => {
				const req = {
					type: "POST",
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apihotlistdeleteitem),
					headers: {
						Authorization: `Bearer ${token}`,
						"Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey()
					},
					data: JSON.stringify({ 'ID': id })
				};

				T.Utils.Ajax.fragment(req, successCallback, errorCallback);
			});
		},
		_handleFuelTypeChange: function _handleFuelTypeChange() {
			const val = this.$gasStationHotlistFuelType.val();
			T.Utils.Store.set(this.SESSIONKEY_FUELTYPE, val && "-" !== val ? val : null, T.Utils.Store.SESSION);
		}
	});
})(jQuery);