(function ($) {
	'use strict';
	/**
	 * BasicTeaserProduct module implementation.
	 *
	 * @author  <markus.hiller@mtc.berlin>
	 * @namespace T.Module
	 * @class BasicTeaserProduct
	 * @extends T.Module
	 */
	T.Module.BasicTeaserProduct.Extention = T.createDecorator({

		/**
		 * Initialize.
		 *
		 * @param {function} resolve
		 */
		start: function (resolve) {
			this.$ctx = $(this._ctx);
			this._parent.start(resolve);
		},

		/*
		 * initClickPreview
		 */
		initClickPreview: function () {
			this._parent.initClickPreview();
			this.$ctx.find('a').on('click', (e) => {
				e.preventDefault();
				const box = $(e.target).closest('.mm-box');
				let isCashItem = false;

				if (box.data('isCash') !== undefined) {
					isCashItem = box.data('isCash');
				}

				sessionStorage.setItem('productIsCashItem', isCashItem);
			});
		}
	});
}(jQuery));
