'use strict';
(function ($) {
	/**
	 * PreInsuranceTerminationService module implementation.
	 *
	 * @author  <barbara.ascher@gks.adac.de>
	 * @namespace T.Module
	 * @class PreInsuranceTerminationService
	 * @extends T.Module
	 */

	T.Module.PreInsuranceTerminationService = T.createModule({

		validationOptions: {
			rules: {
				Zustimmung: {
					required: true
				}
			},
			messages: {
				Zustimmung: {
					required: "Um diesen Service nutzen zu können, müssen Sie der Datenweitergabe zustimmen"
				}
			}
		},

		/**
		 * start function
		 * @param {function} resolve
		 */
		start: function start(resolve) {
			this.$ctx = $(this._ctx);
			this.$form = this.$ctx.find('form');
			this.$errorMessage = this.$ctx.find('.js-error-backend');
			this.$successMessage = this.$ctx.find('.js-success-message');
			this.$submitButton = this.$ctx.find('.js-submit');
			this.$formDiv = this.$ctx.find('.js-form');
			T.Utils.Application.init();
			this._readConfiguration();
			this._registerListeners();
			this.validationOptions.messages = $.extend({}, this.validationOptions.messages, this.$form.data('validationoption'));
			T.Utils.Validation.init(this.$ctx, this.$form, this.validationOptions);
			resolve();
		},

		_readConfiguration: function _readConfiguration() {
			this.terminationApi = this.$ctx.data("api");
			this.transactionKey = T.Utils.Helper.getUrlParameter(this.$ctx.data('urlparam'));
		},

		_registerListeners: function _registerListeners() {
			this.$submitButton.on('click', this._submitForm.bind(this));
		},

		_submitForm: function _submitForm(ev) {
			ev.preventDefault();
			if (this.$form.valid()) {

				const options = {
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), this.terminationApi),
					type: 'POST',
					data: JSON.stringify({ HergangId: this.transactionKey }),
				};
				T.Utils.Ajax.fragment(options, this._successCallback.bind(this), this._errorCallback.bind(this));
			}
		},

		_successCallback: function _successCallback() {
			this.$errorMessage.toggleClass("is-error", true);
			this.$successMessage.toggleClass("h-hidden", false);

			this.$submitButton.toggleClass("h-hidden", true);
			this.$formDiv.toggleClass("h-hidden", true);
		},

		_errorCallback: function _errorCallback() {
			this.$errorMessage.toggleClass("h-hidden", false);
			this.$successMessage.toggleClass("h-hidden", true);

			this.$submitButton.toggleClass("h-hidden", true);
			this.$formDiv.toggleClass("h-hidden", true);
		}
	});
})(jQuery);