'use strict';
(function () {

	T.Utils = T.Utils || {};

	/**
	 * Utitlity methods for handling SelfServiceLogin API.
	 * Use examples s. selfServiceLogin-status or selfServiceLogin-anmeldung teriffic components
	 */
	T.Utils.SelfServiceLogin = {

		/**
		 *
		 * @param {User} user object from session or Identity API
		 * @returns true if specified user has Verified role
		 */
		isVerifiedUser: function isVerifiedUser(user) {
			return this.hasRequiredRole(user, "Verified");
		},

		/**
		 *
		 * @param {User} user object from session or Identity API
		 * @returns true if specified user has IdentityChecked role
		 */
		isIdentityCheckedUser: function isIdentityCheckedUser(user) {
			return this.hasRequiredRole(user, "IdentityChecked");
		},

		hasRequiredRole: function hasRequiredRole(user, requiredRole) {
			if (user && user.Roles) {
				return user.Roles.some(value => value.toLowerCase() === requiredRole.toLowerCase());
			}
			return false;
		},


		/**
		 * Filter the verified role from all states
		 * @param {Array} states
		 */
		getVerifiedStateResponse: function getVerifiedStateResponse(states) {
			if (states === undefined) {
				return undefined;
			}
			let result;
			for (let i = 0; !result && i < states.Data.length; i++) {
				if ((`${states.Data[i].Role}`).toLowerCase() === "verified") {
					result = states.Data[i];
				}
			}
			return result;
		},


		/**
		 * Check if the current user has pending role requests
		 */
		getRoleStates: function getRoleStates(apicheckstate, callback, mergeRequestsFor) {
			T.Utils.Application.init();
			T.Utils.Auth.getBearerToken((token) => {
				const url = T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), apicheckstate);

				const requestOptions = {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					url: url
				};

				const errorCallback = function (data) {
					if (data.status === 404) {
						callback();
					}
				};
				if (mergeRequestsFor) {
					T.Utils.Ajax.multifragment(`${mergeRequestsFor}:${requestOptions.method}:${requestOptions.url}`, requestOptions, callback, errorCallback);
				} else {
					T.Utils.Ajax.fragment(requestOptions, callback, errorCallback);
				}
			});
		},

		/**
		 * Checks if the current user is a pure online customer
		 */
		checkPureOnlineCustomer: function checkPureOnlineCustomer(apipureonlinecustomer, callback) {
			T.Utils.Auth.getBearerToken((token) => {
				const requestOptions = {
					method: 'GET',
					headers: {
						Authorization: `Bearer ${token}`,
					},
					url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApi(), apipureonlinecustomer)
				};

				T.Utils.Ajax.fragment(requestOptions, (data) => {
					const isPureOnlineCustomer = data.Data.istReinerOnlineKunde;
					callback(isPureOnlineCustomer);
				});
			});
		},

		/**
		 * Check if the current User with the current rate can use the self service login
		 */
		canUseSelfService: function canUseSelfService(apicanuse, tarif, callback, mockIt, mergeRequestsFor) {
			const url = T.Utils.Helper.appendURIPath(window.location.origin, apicanuse);

			const requestOptions = {
				method: 'GET',
				url: url,
				data: { "tarif": tarif }
			};

			if (mockIt) {
				//Mock
				callback({ allow: true });
			} else {
				//Real
				if (mergeRequestsFor) {
					T.Utils.Ajax.multifragment(`${mergeRequestsFor}:${requestOptions.method}:${requestOptions.url}`, requestOptions, callback, errorCallback);
				} else {
					T.Utils.Ajax.fragment(requestOptions, callback, errorCallback);
				}
			}

			function errorCallback() {
				callback({ allow: false });
			}
		}
	};
})();
