(function($) { //eslint-disable-line

	'use strict';
	/**
	 * BasicRatingEkomi module implementation.
	 *
	 * @author Marie Häusgen <m.haeusgen@edelweiss72.de>
	 * @namespace T.Module
	 * @class BasicRatingEkomi
	 * @extends T.Module
	 */
	T.Module.BasicRatingEkomi = T.createModule({
		start: function(resolve) {
			resolve();
		}
	});
}(jQuery));
