/* eslint-disable */
(function ($) {
	'use strict';

	S.Utils = S.Utils || {};

	S.Utils.Form = {
		asideMode: {},
		mediaQueryRightNow: '',


		/**
		 * init form actions
		 *
		 */
		init: function () {
			// input focusout helper
			this.initializeFormattingForInputFields();

			// textarea count
			this.countTextareaCharacters();

			//check for depend boxes
			this.initDepends();

			//check for depend-change boxes
			this.initDependsOnChange();

			this.initClone();

			this.initInputListner();

			this.initDateConditions();

			this.observeMultirows();
		},

		initializeFormattingForInputFields: function () {
			$('input, textarea').each(function (index, itemOuter) {
				// If data-numberize is set, use jquery-number to format numbers
				if ($(itemOuter).data('numberize')) {

					const that = this;

					// We only want to allow iOS users and PC users to use jquery-number
					if (S.Utils.Helper.isIOS() || S.Utils.Helper.mq('desktop').matches) {
						if (String($(itemOuter).data('numberize')) === String('float')) {
							$(this).number(true, 2, ',', '.');
						}
						else if (String($(itemOuter).data('numberize')) === String('natural')) {
							$(this).number(true, 0, ',', '.');
						}
					}

					S.Utils.Helper.mq('desktop').addListener(function (mq) {
						if (S.Utils.Helper.isIOS() || mq.matches) {
							if (String($(itemOuter).data('numberize')) === String('float')) {
								$(that).number(true, 2, ',', '.');
							}
							else if (String($(itemOuter).data('numberize')) === String('natural')) {
								$(that).number(true, 0, ',', '.');
							}
						}
						else {
							$(itemOuter).off();
						}
					});
				}

				// max length
				if ($(itemOuter).data('max')) {
					const maxNum = parseInt($(itemOuter).data('max'));

					$(itemOuter).bind('keyup', function () {
						const value = $(itemOuter).val();
						if (value.length > maxNum) {
							$(itemOuter).val(value.substring(0, value.length - 1));
						}
					});
				}

				// only number
				if ($(itemOuter).attr('type') === 'number') {
					$(itemOuter).bind('keyup', function () {
						const value = $(itemOuter).val();
						const reg = '^\\d+$';

						if (!value.match(reg)) {
							$(itemOuter).val(value.substring(0, value.length - 1));
						}
					});
				}
			});
		},

		observeMultirows: function() {
			const elementToObserve = $('.l-form-general').find('.ll-multirow .ll-row');

			const observer = new MutationObserver((mutationRecords) =>
			{
				elementToObserve.each((index, elem) => {
					if($(elem).hasClass('is-error')) {
						$(elem).closest('.ll-multirow').addClass('has-error');
					}
					else {
						$(elem).closest('.ll-multirow').removeClass('has-error');
					}
				});

			});

			elementToObserve.each(function ()
			{
				observer.observe(this, {attributes: true});
			});
		},

		/**
		 * get age
		 * check for format - either '.' (german) or '-' (american)
		 * will accept parameter for future date to check age at that date
		 * expects full date with DD.MM.YYYY --OR-- yyyy-mm-dd
		 * example 1: this.getAge('22.07.1992') will calculate the age of the person NOW
		 * example 2: this.getAge('22.07.1992', '01.01.2080') will calculate the age of the Person on 01.01.2080
		 */
		getAge(birthday, birthdayAtDate) {
			let formattedBirthday,
				formattedBirthdayAtDate;

			if (birthday.match(/\./g)) {
				formattedBirthday = moment(birthday, 'DD.MM.YYYY');
			}
			else if (birthday.match(/-/g)) {
				formattedBirthday = moment(birthday, 'YYYY-MM-DD');
			}

			if (birthdayAtDate) {
				if (birthdayAtDate.match(/\./g)) {
					formattedBirthdayAtDate = moment(birthdayAtDate, 'DD.MM.YYYY');
				}
				else if (birthdayAtDate.match(/-/g)) {
					formattedBirthdayAtDate = moment(birthdayAtDate, 'YYYY-MM-DD');
				}

				return moment(formattedBirthdayAtDate).diff(formattedBirthday, 'year');
			}

			return moment().diff(formattedBirthday, 'year');
		},

		/**
		 * counts the characters in a Textarea and displays
		 * the maximum amount left
		 */
		countTextareaCharacters: function () {
			$('textarea + div .js-textarea-count').each(function (index, item) {
				const textMax = $(item).html(),
					textarea = $(item).closest('div').prev();

				// when a button is pressed in a textarea
				textarea.on('keydown', function () {

					setTimeout(function () {
						const value = $(textarea).val();
						const textLength = value.length;

						if (textLength > textMax) {
							const dif = textLength - textMax;
							$(textarea).val(value.substring(0, value.length - dif));
							$(item).html(0);
						}
						else {
							$(item).html(textMax - value.length);
						}

					}, 0);

				});
			});
		},

		initInputListner: function () {
			//give empty placeholders to inputs and textareas to use :show-placeholder in css
			$('.l-form-general').find('.ll-row .a-basic-input-text input[type="text"], .ll-row .a-basic-input-text input[type="search"], .ll-row .a-basic-input-text input[type="number"], .ll-row .a-basic-input-text input[type="email"], .ll-row textarea').each(function (index, item) {
				if (typeof $(item).attr('placeholder') === 'undefined' || $(item).attr('placeholder') === '') {
					$(item).attr('placeholder', ' ');
				}
			});

			$('.l-form-general').find('.ll-row textarea, .ll-row .a-basic-input-text input, .ll-row .a-basic-input-select li, .ll-row a:not(.a-basic-link):not(.a-basic-btn)').each(function (index, item) {
				if ($(item).next().hasClass('aa-col-label') || $(item).next().hasClass('aa-label')) {
					// class is needed to do some stylings depending on whether the label is floating or not (basic-info-layer)
					$(item).closest('.ll-row').addClass('is-floating');

					if ($(item).val() === '') {
						$(item).addClass('is-empty');
					}
					else {
						$(item).removeClass('is-empty');
					}

					$(item).on('blur', function (e) {
						$(e.currentTarget).closest('.ll-row').removeClass('is-focused');

						// class "is-empty" is needed for input fields with value, after value got removed --> field empty
						// is not controllable via css
						if ($(e.currentTarget).val() === '') {
							$(e.currentTarget).addClass('is-empty');
						}
						else {
							$(e.currentTarget).removeClass('is-empty');
						}
					});


					$(item).not('.aa-btn-selection').on('focus', function (e) {
						$(e.currentTarget).closest('.ll-row').addClass('is-focused');
					});


					$(item).not('.aa-btn-selection').on('click', function (e) {
						$(e.currentTarget).closest('.ll-row').addClass('is-focused');
					});
				}
			});

			//if selects are preset match divs
			$('.l-form-general').find('.ll-row .a-basic-input-select').each(function (index, item) {
				const val = $(this).find('select').val();
				$(this).find('.aa-btn-selection').text($(this).find('select option:selected').text());
				$(this).find('.aa-options li[data-value="' + val + '"]').addClass('is-active');
			});
		},

		/**
		 * initDepends
		 */
		initDepends: function () {

			/**
			 * => If the depend elem has "display: flex", this js overwrites the default property when the height anim is called, since jQuery.slideDown() sets "display:block"
			 *
			 * => Fix: Animate with a custom function, e.g. using max-height and dont alter the css property or reset the original one by storing them before running the logic
			 */

			const $givenSelects = $('.l-form, .l-form-general').find('.a-basic-input-select select[data-depend]');
			const $givenOptions = $('.l-form, .l-form-general').find('.a-basic-input-select option[data-depend]');

			// special case with only one radiobutton in a row and dependent content
			$('.ll-depend:not(.ll-depend-group)').each(function (index, dependContent) {
				// get data-depend
				const dependData = $(dependContent).attr('data-depend'),
					// get toggle-data
					dependToggle = $(dependContent).attr('data-depend-toggle');
				let $fittingSelect = false;

				// check for select depends
				if (dependToggle !== undefined && $givenSelects.length) {
					$fittingSelect = $givenSelects.filter((index, element) => String($(element).data('depend')) === String(dependToggle));
				}

				// if depend controller is a parent radiobutton
				if ($(dependContent).parents('.a-basic-input-radio').length && $(dependContent).siblings('.a-basic-input-radio').find(`[data-depend="${$(dependContent).attr('data-depend')}"]`).length === 0) {
					const dependRadio = $(dependContent).closest('.a-basic-input-radio').find('input').eq(0),
						dependRadioName = dependRadio.attr('name');

					// init check
					if (dependRadio.prop('checked')) {
						$(dependContent).stop().addClass('is-open').slideDown();

						// show content-dependencies, if input is "checked"
						// hide default content
						$('div[data-depend-toggle=' + 'is-default' + ']').hide();
						// show dependent-content
						$(`div[data-depend-toggle=${dependToggle}]`).show();
					}

					else {
						// hide every dependent-content, if nothing is checked on init
						$(`div[data-depend-toggle=${dependToggle}]`).hide();
					}

					$(`input[name=${dependRadioName}]`).each(function (index, item) {
						$(item).on('click', function () {
							if (dependRadio.prop('checked')) {
								$(dependContent).stop().addClass('is-open').slideDown();

								// show dependent-content, if input is now "checked"
								$(`div[data-depend-toggle=${dependToggle}]`).show();
								// hide default content
								$('div[data-depend-toggle=' + 'is-default' + ']').hide();
							}
							else {
								$(dependContent).stop().slideUp();

								// hide last dependent-content, if input is not "checked" anymore
								$(`div[data-depend-toggle=${dependToggle}]`).hide();
							}
						});
					});
				}

				// if depend controller is a parent checkbox
				else if ($(dependContent).parents('.a-basic-input-checkbox').length) {
					const dependCheckbox = $(dependContent).closest('.a-basic-input-checkbox').find('input').eq(0);

					// init check
					if (dependCheckbox.prop('checked')) {
						$(dependContent).stop().slideDown(function () {
							$(dependContent).css('height', '');
						});
					}

					dependCheckbox.on('change', function () {
						if (dependCheckbox.prop('checked')) {
							$(dependContent).stop().slideDown(function () {
								$(dependContent).css('height', '');
							});
						}
						else {
							$(dependContent).stop().toggleClass('is-open').slideUp(function () {
								$(dependContent).css('height', '');
							});
						}
					});

				}

				// if depend controller is a parent selectbox
				else if ($fittingSelect) {
					$fittingSelect.on('change', function () {
						if ($fittingSelect.val() >= '1') {
							$(dependContent).stop().slideDown(function () {
								$(dependContent).css('height', '');
							});
						}
						else {
							$(dependContent).stop().slideUp(function () {
								$(dependContent).css('height', '');
								$(dependContent).find('.js-select-validate').val('0');
							});
						}
					});
				}

				// if depend controller is a checkbox in a prev container
				else if (dependData === $(dependContent).prev().attr('data-depend')) {
					if ($(dependContent).prev('.a-basic-input-checkbox').length) {
						const dependCheckbox = $(dependContent).prev('.a-basic-input-checkbox ').find('input').eq(0);
						dependCheckbox.on('change', function () {
							if (dependCheckbox.prop('checked')) {
								$(dependContent).stop().slideDown('slow');
							}
							else {
								$(dependContent).stop().slideUp('slow');
							}
						});
					}
				}

				// if depend controller is a radiobutton or checkbox in a prev container
				else if ($(dependContent).prev().find('.a-basic-input-radio input[data-depend], .a-basic-input-checkbox input[data-depend]').length) {
					const $radioButtons = $(dependContent).prev().find('.a-basic-input-radio input, .a-basic-input-checkbox input'),
						$radioButtonDepend = $(dependContent).prev().find('.a-basic-input-radio input[data-depend], .a-basic-input-checkbox input[data-depend]');

					const isFlexElem = $(dependContent).hasClass('mm-row') && $(dependContent).closest('.mm-form-container').length ? true : false;
					// set inline style for height anim
					if (isFlexElem) {
						$(dependContent).css('display', 'none');
					}

					if ($radioButtonDepend.is(':checked')) {
						if (!$radioButtons.filter(':checked').closest('.a-basic-input-radio').hasClass('js-depend-close')) {
							if (isFlexElem) {
								$(dependContent).stop().slideDown({
									start: function () {
										$(this).css({
											display: "flex"
										});
									}
								});
							}

							else {
								$(dependContent).stop().slideDown(function () {
									$(dependContent).css('height', '');
								});
							}
						}
					}

					$radioButtons.on('change', function () {
						if ($radioButtons.filter(':checked').attr("data-depend") === dependData) {
							if (isFlexElem) {
								$(dependContent).stop().slideDown({
									start: function () {
										$(this).css({
											display: "flex"
										});
									}
								});
							}

							else {
								$(dependContent).stop().slideDown(function () {
									$(dependContent).css('height', '');
								});
							}
						}
						else {
							if (isFlexElem) {
								$(dependContent).stop().slideUp({
									start: function () {
										$(this).css({
											display: "flex"
										});
									}
								});
							}

							else {
								$(dependContent).stop().slideUp(function () {
									$(dependContent).css('height', '');
								});
							}
						}
					});
				}
				else if ($(dependContent).siblings('.a-basic-input-radio, .a-basic-input-checkbox').length) {
					const $radioButtons = $(dependContent).siblings('.a-basic-input-radio, .a-basic-input-checkbox').find('input');
					const $dependRadio = $(dependContent).siblings('.a-basic-input-radio, .a-basic-input-checkbox').find(`input[data-depend="${dependData}"]`).eq(0);

					$radioButtons.on('click change', function () {
						if ($dependRadio.prop('checked')) {
							$(dependContent).stop().slideDown('slow', () => {
								$(dependContent).css('height', '');
							});

							if ($(dependContent).hasClass('ll-depend-default')) {
								const dependDefault = $(`[data-depend-default="${dependData}"]`);

								dependDefault.stop().slideUp('slow', () => {
									dependDefault.css('height', '');
								});
							}
						}
						else {
							$(dependContent).stop().slideUp('slow', () => {
								$(dependContent).css('height', '');
							});

							const dependDefault = $(`[data-depend-default="${dependData}"]`);

							dependDefault.stop().slideDown('slow', () => {
								dependDefault.css('height', '');
							});
						}
					});

					if ($dependRadio.prop('checked')) {

						// killed stuff at Namics, so let's try like this
						// $dependRadio.trigger('click');

						$(dependContent).stop().slideDown('slow', () => {
							$(dependContent).css('height', '');
						});
					}
				}
			});

			// special case with grouped dependent content somewhere in the markup (not only in l-form!)
			$('.ll-depend.ll-depend-group').each(function (index, dependContent) {
				// get data-depend
				const dependToggle = $(dependContent).attr('data-depend-toggle'),
					dependGroupID = $(dependContent).attr('data-depend-group-id'),
					$inputTrigger = $(`[data-depend=${dependToggle}]`);

				// if input is a direct child of .a-basic-input-radio
				if ($inputTrigger.parent('.a-basic-input-radio').length) {

					if ($inputTrigger.is(':checked')) {
						if (typeof $(dependContent).data('depend-no-animation') !== 'undefined') {
							$(dependContent).css('display', 'flex');
						} else {
							$(dependContent).stop().slideDown(function () {
								$(dependContent).css('height', '');
							});
						}

						// hide others if they have the same groupId
						$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
							if ($(item).attr('data-depend-toggle') !== dependToggle) {
								$(item).stop().slideUp('fast', () => {
									$(item).css('height', '');
								});
							}
						});
					}

					$inputTrigger.on('change', () => {

						if ($inputTrigger.is(':checked')) {

							// hide others if they have the same groupId
							$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
								let toggleBy = $(item).attr('data-depend-toggle');

								// if toggled by multiple elements choose toggle-by instead
								if (typeof $(item).attr('data-depend-toggle-by') !== 'undefined') {
									toggleBy = $(item).attr('data-depend-toggle-by');
								}
								if (toggleBy.indexOf(dependToggle) === -1) {
									$(item).hide();
								}
								else {
									if (typeof $(item).data('depend-no-animation') !== 'undefined') {
										$(item).css('display', 'flex');
									} else {
										$(item).show();
									}
								}
							});
						}
					});
				}

				// if input is a direct child of .a-basic-input-checkbox
				else if ($inputTrigger.hasClass('a-basic-input-checkbox')) {

					const dependCheckbox = $inputTrigger.find('input[type="checkbox"]').eq(0);

					if (dependCheckbox.is(':checked')) {
						//show depend content on init if cbx is checked
						$(dependContent).stop().slideDown(function () {
							$(dependContent).css('height', '');
						});

						// hide others if they have the same groupId
						$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
							if ($(item).attr('data-depend-toggle') !== dependToggle) {
								$(item).stop().slideUp('fast', () => {
									$(item).css('height', '');
								});
							}
						});
					}

					else {
						//show is-default content on init
						$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
							if ($(item).attr('data-depend-toggle') === 'is-default') {
								$(item).stop().slideDown(() => {
									$(item).css('height', '');
								});
							}
						});
					}

					dependCheckbox.on('change', function () {

						if (dependCheckbox.prop('checked')) {

							// show dependent content
							$(dependContent).stop().slideDown(() => {
								$(dependContent).css('height', '');
							});

							// hide others if they have the same groupId
							$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
								if ($(item).attr('data-depend-toggle') !== dependToggle) {
									$(item).stop().slideUp('fast', () => {
										$(item).css('height', '');
									});
								}
							});
						}

						else {
							// hide others if they have the same groupId
							$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
								if ($(item).attr('data-depend-toggle') === 'is-default') {
									$(item).stop().slideDown(() => {
										$(item).css('height', '');
									});
								}
							});

							$(dependContent).stop().slideUp('fast');
						}
					});

				}

				// if input is a direct child of .mm-tile--checkbox
				else if ($inputTrigger.closest('.mm-tile--checkbox')) {

					const dependCheckbox = $inputTrigger.closest('.mm-tile--checkbox').find('input[type="checkbox"]').eq(0);

					if (dependCheckbox.is(':checked')) {
						//show depend content on init if cbx is checked
						$(dependContent).stop().slideDown(function () {
							$(dependContent).css('height', '');
						});

						// hide others if they have the same groupId
						$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
							if ($(item).attr('data-depend-toggle') !== dependToggle) {
								$(item).stop().slideUp('fast', () => {
									$(item).css('height', '');
								});
							}
						});
					}

					else {
						//show is-default content on init
						$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
							if ($(item).attr('data-depend-toggle') === 'is-default') {
								$(item).stop().slideDown(() => {
									$(item).css('height', '');
								});
							}
						});
					}

					dependCheckbox.on('change', function () {

						if (dependCheckbox.prop('checked')) {

							// show dependent content
							$(dependContent).stop().slideDown(() => {
								$(dependContent).css('height', '');
							});

							// hide others if they have the same groupId
							$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
								if ($(item).attr('data-depend-toggle') !== dependToggle) {
									$(item).stop().slideUp('fast', () => {
										$(item).css('height', '');
									});
								}
							});
						}

						else {
							// hide others if they have the same groupId
							$(`.ll-depend-group[data-depend-group-id=${dependGroupID}]`).each(function (index, item) {
								if ($(item).attr('data-depend-toggle') === 'is-default') {
									$(item).stop().slideDown(() => {
										$(item).css('height', '');
									});
								}
							});

							$(dependContent).stop().slideUp('fast');
						}
					});

				}

			});

			if ($givenOptions.length > 0) {
				$($givenOptions).each((index, element) => {
					$(element).parent().off().on('change', (select) => {
						const option = $(select.currentTarget).find('option:selected').data('depend-option');
						const depend = $(select.currentTarget).find('option:selected').data('depend');
						$('.ll-depend[data-depend="' + depend + '"]').hide();
						$('.ll-depend[data-depend="' + depend + '"]').filter('[data-depend-option="' + option + '"]').show();

					});
				});

				$givenOptions.filter(':selected').parent().trigger('change');
			}
		},

		/**
		 * initDependsOnChange
		 */
		initDependsOnChange: function () {
			$('.l-form .ll-depend-change').each(function (index, dependContent) {
				const inputChange = $(dependContent).prev();

				$(dependContent).attr('data-depend-change', inputChange.val());


				inputChange.blur(function () {

					const newInputVal = $(dependContent).closest('.a-basic-input-text').find('input').val();

					if ($(dependContent).attr('data-depend-change') !== newInputVal) {
						$(dependContent).addClass('is-visible');
					}
					else {
						$(dependContent).removeClass('is-visible');
					}

				});
			});
		},


		/**
		 * initClone
		 */
		initClone: function () {

			const that = this;

			let i = 1,
				count = 1;

			$('.js-clone-button--input').on('click', function (e) {
				e.preventDefault();

				const maxClones = $(e.target).parent().children('.js-clone-input.is-cloned').length;

				if (maxClones < 3) {

					const $inputElement = $(e.target).prevAll('.js-clone-input').not('.is-cloned');

					const $clone = $inputElement.clone(true, true).insertBefore($inputElement).addClass('is-cloned');

					$clone.find('.js-clone-input.js-cloned').remove();

					$clone.find('.js-clone-headline').html('Zwischenziel');

					$clone.find('.a-basic-input-text input[type=text]').each(function () {
						$(this).attr('name', `${$(this).attr('name')}-${i}`);
						$(this).attr('id', `${$(this).attr('id')}-${i}`);
						$(this).val('');

						i++;
					});

					that.reinitModules($clone);

					if (!$clone.find('.mm-remove').length) {

						$clone.append('<span class="mm-remove"></span>');

						$clone.find('.mm-remove').on('click', function (e) {
							$(e.target).parent().remove();
						});
					}

				}
			});

			$('.js-clone-button--tour').on('click', function (e) {
				e.preventDefault();

				const maxClones = $(e.target).parent().children('.js-clone-tour.is-cloned').length;

				if (maxClones < 3) {

					const $tourElement = $(e.target).prevAll('.js-clone-tour').not('.is-cloned');

					const $clone = $tourElement.clone(true, true).insertBefore(e.target).addClass('is-cloned').find('.js-clone-input.is-cloned').remove().end();

					i++;

					count++;

					$clone.find('.aa-basic-input-radio-depend, .ll-depend.ll-depend-group').each(function () {
						if ($(this).is('.aa-basic-input-radio-depend')) {
							$(this).attr('data-depend', `${$(this).attr('data-depend')}-${i}`);
						} else {
							$(this).attr('data-depend-toggle', `${$(this).attr('data-depend-toggle')}-${i}`);
						}
					});

					$clone.find('input, select, textarea').each(function () {
						$(this).attr('name', `${$(this).attr('name')}-${i}`);
						$(this).attr('id', `${$(this).attr('id')}-${i}`);
						$(this).val('');
						$clone.find('.a-basic-input-text input').removeClass('js-clear a-basic-input-text--clear');
						$clone.find('.a-basic-input-select select').val($('.a-basic-input-select select option:first').val());
					});

					$clone.find('label').each(function () {
						$(this).attr('for', `${$(this).attr('for')}-${i}`);
					});

					that.reinitModules($clone);

					$clone.find('.js-counter').text(count);

					if (!$clone.find('.mm-remove').not('.js-clone-input .mm-remove').length) {

						$clone.prepend('<span class="mm-remove"></span>');

						$clone.find('.mm-remove').on('click', function (e) {
							let tour = 2;

							count--;

							$(e.target).parent().siblings('.js-clone-tour.is-cloned').each(function () {
								$(this).find('.js-counter').text(tour);

								tour++;
							});

							$(e.target).parent().remove();
						});
					}

					that.initDepends();

				}
			});

			$('.js-clone-button--accidentoffender').on('click', function (e) {

				e.preventDefault();

				const maxClones = 100,
					currentClones = $(e.currentTarget).parent().prevAll('.js-clone-accidentoffender').length;

				if (currentClones < maxClones) {

					const $vesrsElement = $(e.currentTarget).parent().prevAll('.js-clone-source-accidentoffender');

					const $clone = $vesrsElement.clone(true, true).insertBefore($(e.currentTarget).parent());

					$clone.removeClass('js-clone-source-accidentoffender  is-valid is-error is-check').addClass('js-clone js-clone-accidentoffender');

					i++;

					count++;

					$clone.find('.aa-basic-input-radio-depend, .ll-depend.ll-depend-group').each(function () {
						if ($(this).is('.aa-basic-input-radio-depend')) {
							$(this).attr('data-depend', `${$(this).attr('data-depend')}-${i}`);
						} else {
							$(this).attr('data-depend-toggle', `${$(this).attr('data-depend-toggle')}-${i}`);
						}
					});

					$clone.find('input, select, textarea').each(function () {
						$(this).attr('name', `${$(this).attr('name')}-${i}`);
						$(this).attr('id', `${$(this).attr('id')}-${i}`);

						$(this).val('');
						$(this).removeClass('js-clear a-basic-input-text--clear');
						$(this).siblings('.js-clear-button').trigger('click');

						if ($(this).is('input[type=file]')) {
							$(this).val('');
							$(this).removeClass('js-file-stored');
						}

					});

					$clone.find('.js-has-listener').removeClass('js-has-listener');

					$clone.find('.m-basic-info-layer').remove();

					$clone.find('label').each(function () {
						$(this).attr('for', `${$(this).attr('for')}-${i}`);
					});

					setTimeout(function () {
						$clone.find('.m-basic-upload .js-clear-button').trigger('click');
					}, 100);

					$clone.find('.m-basic-upload .m-data-wrap > .js-data').removeClass('js-file-stored');

					if ($clone.find('.js-clone-head').length) {

						const $cloneHeadline = $clone.find('.js-clone-head').eq(0);

						$cloneHeadline.append('<span class="js-counter"></span>');
						$cloneHeadline.append('<span class="js-clone-remove"></span>');

						$cloneHeadline.find('.js-clone-remove').on('click', () => {

							count--;

							$clone.remove();
						});
					}

					that.updateFormSummary('add-offender', $clone, count, i);

					that.initDepends();

					that.reinitModules($clone);

				}
			});

			$('.js-clone-button--policefiles').on('click', function (e) {

				e.preventDefault();

				const maxClones = 100,
					currentClones = $(e.currentTarget).closest('.ll-row').prevAll('.js-clone-policefiles').length,
					$triggerBtn = $(e.currentTarget);

				if (currentClones < maxClones && !$triggerBtn.hasClass('is-disabled')) {

					let reinitMainWrap = false;

					const $sourceElements = $(e.currentTarget).closest('.ll-row').prevAll('.js-clone-source-policefiles');

					const $clones = $sourceElements.clone(true, true).insertBefore($(e.currentTarget).closest('.ll-row'));

					$clones.removeClass('js-clone-source-policefiles is-valid is-error is-check').addClass('js-clone js-clone-policefiles');

					i++;

					$clones.find('.aa-basic-input-radio-depend, .ll-depend.ll-depend-group').each(function () {
						if ($(this).is('.aa-basic-input-radio-depend')) {
							$(this).attr('data-depend', `${$(this).attr('data-depend')}-${i}`);
						} else {
							$(this).attr('data-depend-toggle', `${$(this).attr('data-depend-toggle')}-${i}`);
						}
					});

					$clones.find('input, select').each(function () {
						$(this).attr('name', `${$(this).attr('name')}-${i}`);
						$(this).attr('id', `${$(this).attr('id')}-${i}`);
						$(this).removeClass('js-clear a-basic-input-text--clear');
						$(this).siblings('.js-clear-button').remove();

						if ($(this).is('input[type=text]')) {
							$(this).val('');
						}

						if ($(this).is('input[type=file]')) {
							$(this).val('');
							$(this).removeClass('js-file-stored');
							// data attr (only needed in the clickdummy)
							//$(this).attr('data-js-form-input-child',  $(this).data('js-form-input-child') + '-' + i );
							reinitMainWrap = true;
						}
					});

					// hide button
					$triggerBtn.removeClass('is-shown-animate');
					$triggerBtn.closest('.ll-fieldset').removeClass('js-all-files-stored');
					$triggerBtn.prevAll('.js-upload-button').removeClass('is-shown-animate');

					// upload elem
					$clones.removeClass('js-file-stored');
					$clones.find('.m-basic-upload .m-data-wrap > .js-data').removeClass('is-active js-file-stored').html('');
					$clones.find('.m-basic-upload .m-data-wrap > .js-filesize').remove();
					$clones.find('.js-has-listener').removeClass('js-has-listener');
					$clones.find('.js-clone-remove').removeClass('hidden');
					$clones.find('.ll-col-label > .m-basic-info-layer').remove();

					if (reinitMainWrap) {
						that.reinitModules($clones.closest('.m-ves-rs-contentbox'));
					}
					else {
						that.reinitModules($clones);
					}

					$clones.find('label').each(function () {
						$(this).attr('for', `${$(this).attr('for')}-${i}`);
					});

					// trigger click to show file window immediately
					$clones.find('.a-basic-btn:not(.a-basic-btn--clone)').show().trigger('click');

					$clones.find('.js-clone-remove').off('click.removePoliceRow').on('click.removePoliceRow', (e) => {

						// emit info for file size calculation
						if ($(e.currentTarget).closest('.ll-row').find('.m-basic-upload .js-data.is-active').length) {
							$(e.currentTarget).closest('.ll-row').find('.m-basic-upload .js-clear-button').trigger('click');
						}

						that.updateFormSummary('remove-cost', $clones, count, i);

						setTimeout(function () {
							$clones.remove();

							// show button if all uploads are filled
							let foundEmptyUpload = false;

							$triggerBtn.closest('.ll-fieldset').children('.ll-row').each((index, rowContainer) => {
								if ($(rowContainer).find('.m-basic-upload').length) {
									if (!$(rowContainer).find('.m-basic-upload .js-data.is-active').length) {
										foundEmptyUpload = true;
										return false;
									}
								}
							});

							if (!foundEmptyUpload) {
								// show clone trigger btn
								$triggerBtn.addClass('is-shown-animate');
							}

							const $uploadBtn = $triggerBtn.prevAll('.js-upload-button').eq(0);

							if ($uploadBtn.length) {

								let unstoredUpload = false;

								// check if file upload btn should be hidden
								$triggerBtn.closest('.ll-fieldset').find('.m-basic-upload:visible').each((index, uploadElem) => {
									if ($(uploadElem).find('.js-data.is-active:not(.js-file-stored)').length) {
										unstoredUpload = true;
										return false;
									}
								});

								if (!unstoredUpload) {
									$triggerBtn.closest('.ll-fieldset').removeClass('js-all-files-stored');
									$(document).trigger(`BasicBtn.fadeAnimate-${$uploadBtn.data('t-id')}`, 'hide');
								}

								else if (!foundEmptyUpload) {
									$(document).trigger(`BasicBtn.fadeAnimate-${$uploadBtn.data('t-id')}`, 'show');
								}
							}
						}, 50);
					});

					that.initDepends();

				}
			});

			$('.js-clone-button--costdeclaration').on('click', function (e) {

				e.preventDefault();

				const maxClones = 100,
					currentClones = $(e.currentTarget).parent().siblings('.mm-row-content--right.js-clone-costdeclaration').length,
					$triggerBtn = $(e.currentTarget);

				if (currentClones < maxClones && !$triggerBtn.hasClass('is-disabled')) {

					let reinitMainWrap = false;

					const $sourceElements = $(e.currentTarget).parent().siblings('.js-clone-source-costdeclaration');

					const $clones = $sourceElements.clone(true, true).insertBefore($(e.currentTarget).parent());

					$clones.removeClass('js-clone-source-costdeclaration is-valid is-error is-check').addClass('js-clone js-clone-costdeclaration');

					i++;

					$clones.find('.aa-basic-input-radio-depend, .ll-depend.ll-depend-group').each(function () {
						if ($(this).is('.aa-basic-input-radio-depend')) {
							$(this).attr('data-depend', `${$(this).attr('data-depend')}-${i}`);
						} else {
							$(this).attr('data-depend-toggle', `${$(this).attr('data-depend-toggle')}-${i}`);
						}
					});

					$clones.find('input, select').each(function () {
						$(this).attr('name', `${$(this).attr('name')}-${i}`);
						$(this).attr('id', `${$(this).attr('id')}-${i}`);
						$(this).removeClass('js-clear a-basic-input-text--clear');
						$(this).siblings('.js-clear-button').remove();

						if ($(this).is('input[type=text]')) {
							$(this).val('');
						}

						if ($(this).is('input[type=file]')) {
							$(this).val('');
							$(this).removeClass('js-file-stored');
							reinitMainWrap = true;
						}
					});

					$clones.find('.js-has-listener').removeClass('js-has-listener');

					if ($clones.find('.ll-col-label > label.ll-label > img').length) {
						$clones.find('.ll-col-label > label.ll-label > img').remove();
					}

					else {
						$clones.find('.ll-col-label > label.ll-label').text('');
					}

					// hide button
					$triggerBtn.removeClass('is-shown-animate');

					// upload elem
					$clones.removeClass('js-file-stored');
					$clones.find('.m-basic-upload .m-data-wrap > .js-data').removeClass('is-active js-file-stored').html('');
					$clones.find('.m-basic-upload .m-data-wrap > .js-filesize').remove();
					$clones.find('.ll-col-label > .m-basic-info-layer').remove();

					if (reinitMainWrap) {
						that.reinitModules($clones.closest('.m-ves-rs-contentbox'));
					}
					else {
						that.reinitModules($clones);
					}

					$clones.find('label').each(function () {
						$(this).attr('for', `${$(this).attr('for')}-${i}`);
					});

					// trigger click to show file window immediately
					$clones.find('.a-basic-btn:not(.a-basic-btn--clone)').show().trigger('click');

					$clones.find('.js-clone-remove').off('click.removeCostRow').on('click.removeCostRow', (e) => {
						that.removeClonedRow(e.currentTarget);
					});

					that.initDepends();
				}
			});

			$('.aa-basic-input-radio-trip').on('change', function () {
				if ($(this).is(':checked')) {
					$('.ll-depend.ll-depend-group').stop().slideUp('slow', () => {
						$(this).css('height', '');
					});
				}
			});

			// for stored states where clones are rebuild from storage
			$('.js-clone-costdeclaration > .js-clone-remove').off('click.removeCostRow').on('click.removeCostRow', (e) => {
				that.removeClonedRow(e.currentTarget);
			});

			$('.m-ves-rs-contentbox--damagedeclaration .mm-row-content--right .js-clone-remove').off('click.removeCostRow').on('click.removeCostRow', (e) => {
				if ($(e.currentTarget).closest('.js-clone-source-costdeclaration')) {
					$(e.currentTarget).parent().find('.js-clear-button').trigger('click');
				}
				else {
					that.removeClonedRow(e.currentTarget);
				}
			});
		},

		/** conditions for DateElements
		 *
		 */
		initDateConditions: function () {
			const allCondElements = $('[data-js-date-condition-source]');

			allCondElements.each((index, el) => {
				const condElement = $(el);
				const handler = $('#' + condElement.data('js-date-condition-source'));

				handler.off().on('change', (target) => {
					const conditionElements = $('[data-js-date-condition-source="' + $(target.currentTarget).attr('id') + '"]');

					conditionElements.each((index, conel) => {
						const condition = $(conel).data('js-date-condition-list');
						const condType = $(conel).data('js-date-condition');
						const compareTo = new Date(); //at the moment this compares the value of datefield with today. If somewhen needed more abstract extend it
						let showInfo = true;
						let condConst = false;

						$(conel).children().addClass('h-hidden');
						if ($(target.currentTarget).val().length > 1) {
							let contentToDisplay = 'default';
							const enteredVal = $(target.currentTarget).val().split('.');
							const enteredDate = new Date(enteredVal[2], (enteredVal[1] - 1), enteredVal[0]);
							const diffTime = Math.abs(compareTo - enteredDate);

							if (condType === 'y') {
								const conditionYear = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));

								for (let i = 0; i < condition.length; i++) {
									if (conditionYear >= condition[i]) {
										contentToDisplay = condition[i];
									}
								}
							}
							//extend for others types like days or month if neccessary

							const elementoDisplay = $(conel).find($('[data-js-date-condition-content="' + contentToDisplay + '"]'));

							if (typeof elementoDisplay.data('js-date-additional-constraint') !== 'undefined') {
								condConst = elementoDisplay.data('js-date-additional-constraint');
								showInfo = false;
							}

							if (condConst) {
								//watch for changes on additional constraints element
								const addConstElement = $('#' + condConst[0]);
								const regex = new RegExp('\\b\\w*' + condConst[1] + '\\w*\\b');
								addConstElement.off().on('change', () => {
									$(target.currentTarget).trigger('change');
								});

								if (addConstElement.prop("tagName") === 'SELECT' && regex.test(addConstElement.find('option:selected').data('js-value'))) {
									showInfo = true;
								}
							}

							if (showInfo) {
								elementoDisplay.removeClass('h-hidden');
								if (elementoDisplay.find('.is-no-trigger').length > 0) {
									elementoDisplay.find('.is-no-trigger .mm-infolayer-content').addClass('visible pos-default');
								}
							}
						}
					});
				});
			});
		},

		/**
		 * reinit js logic for elems like input fields after cloning
		 *
		 */
		reinitModules: function ($elem) {
			// clear data-t-id
			$elem.find('[data-t-id]').each((index, item) => {
				$(item).removeAttr('data-t-id');
				$(document).trigger('addModules', $(item));
			});
		},

		/**
		 * update dependent elements if clone is added
		 */
		updateFormSummary: function (mod, $clone, count, i) {

			const that = this;

			if (mod === 'remove-cost') {

				const cloneFormInputID = $clone.find('input').eq(0).attr('data-js-form-input-id'),
					childInputID = $clone.find('input[type=file]').eq(0).attr('data-js-form-input-child');

				$(document.querySelectorAll('.js-form-summary > .mm-wrapper')).find('.mm-list-wrapper').each((index, row) => {

					if ($(row).attr('data-js-form-summary') === cloneFormInputID) {

						const liTarget = $(row).find('li');

						$(liTarget).each((index, liElem) => {
							if ($(liElem).attr('data-js-form-summary') === childInputID) {

								if ($(liElem).is(':only-child')) {
									$(liElem).closest('span').remove();
								}

								else {
									$(liElem).remove();
								}
							}
						});
					}
				});
			}

			if (mod === 'remove-offender') {

				const cloneFormInputID = $clone.find('input').eq(0).attr('data-js-form-input-id');
				let cloneRowFound = false,
					$cloneRow = null;

				$(document.querySelectorAll('.js-form-summary > .mm-wrapper')).find('.mm-list-wrapper').each((index, row) => {

					if ($(row).attr('data-js-form-summary') === cloneFormInputID) {
						$cloneRow = $(row);
						cloneRowFound = true;

						return false;
					}

				});

				if (cloneRowFound) {

					if ($cloneRow.next('.is-acc-offender-clone').length) {

						$cloneRow.nextAll('.is-acc-offender-clone').each((index, clonedRow) => {

							const rowIndex = $(clonedRow).index('.is-acc-offender-clone') + 1;

							$(clonedRow).find('.oo-label').text(`Angaben zum Unfallgegner ${rowIndex}`);
						});
					}


					$cloneRow.remove();

				}

			}

			else {

				let $srcRow = null,
					srcRowFound = false,
					cloneRowFound = false,
					cloneFormInputID = '';
				const srcFormInputID = $clone.find('input').eq(0).attr('data-js-form-input-id');

				$clone.find('input, select, textarea').each(function (index, inputElem) {
					// update input-ids
					$(inputElem).attr('data-js-form-input-id', `${$(inputElem).attr('data-js-form-input-id')}-clone-${i}`);
					$(inputElem).attr('data-js-form-input-child', `${$(inputElem).attr('data-js-form-input-child')}-clone-${i}`);

				}).promise().done(() => {
					cloneFormInputID = $clone.find('input').eq(0).attr('data-js-form-input-id');
				});

				$(document.querySelector('.js-form-summary > .mm-wrapper')).find('.mm-list-wrapper').each((index, row) => {

					if ($(row).attr('data-js-form-summary') === srcFormInputID) {
						srcRowFound = true;
						$srcRow = $(row);
					}

					if ($(row).attr('data-js-form-summary') === cloneFormInputID) {
						cloneRowFound = true;
					}
				});

				if (srcRowFound && !cloneRowFound) {

					const $dataClone = $srcRow.clone(true, true).insertAfter($srcRow.parent().children().last());

					$dataClone.attr('data-js-form-summary', cloneFormInputID);
					$dataClone.addClass('is-acc-offender-clone');

					// remove the double dot
					const srcLabelString = $dataClone.find('.mm-list-point-headline').text();
					const cloneLabelString = srcLabelString.substring(0, srcLabelString.length - 1);

					$dataClone.find('.mm-list-point-headline').text(`${cloneLabelString} ${count}:`);

					$dataClone.find('.mm-right-list-wrapper > .mm-list-point-text span').each(function (index, spanElem) {

						const newID = `${$(spanElem).attr('data-js-form-summary')}-clone-${i}`;
						$(spanElem).text('');

						// update form-ids
						$(spanElem).attr('data-js-form-summary', newID);

					});
				}

				that.reinitModules($clone.closest('.m-ves-rs-contentbox'));

			}
		},

		/**
		 * remove cloned rows
		 *
		 * @param btn {Object}
		 */
		removeClonedRow(btn) {
			const $targetRow = $(btn).closest('.mm-row-content--right'),
				$halfRow = $targetRow.closest('.mm-row-content--right').prev('.mm-row-content--half'),
				$mainRow = $targetRow.closest('.mm-row');

			let $cloneBtn = $targetRow.closest('.mm-row').find('.js-clone-button');
			const $uploadBtn = $targetRow.closest('.mm-row').find('.js-upload-button');

			if (!$cloneBtn.length) {
				$cloneBtn = $targetRow.closest('.mm-upload-container').find('.js-clone-button');
			}

			if ($halfRow.length) {
				$cloneBtn = $(btn).closest('.mm-row').find('.mm-row-content--button-wrap .js-clone-button');
			}

			// reset cost val and emit event to reinit calc in dmg-decla decorator
			$targetRow.find('.a-basic-input-text > input').val('').trigger('change');
			$targetRow.prev('.mm-row-content').find('.a-basic-input-text > input').val('').trigger('change');

			// show button if source is filled
			if ($(btn).closest('.mm-row').find('.mm-row-content--left').eq(0).find('.m-basic-upload .js-data.is-active').length) {
				$(btn).closest('.mm-row').find('.mm-row-content--button-wrap .js-clone-button').removeClass('is-disabled');
			}

			// delete upload
			if ($($targetRow).find('.m-basic-upload .js-data.is-active').length) {
				$($targetRow).find('.m-basic-upload .js-clear-button').trigger('click');
				$($targetRow).find('.js-file-stored').removeClass('js-file-stored');
			}

			setTimeout(function () {
				$targetRow.prev('.mm-row-content').remove();
				$targetRow.remove();

				if ($halfRow.length) {
					$halfRow.remove();
				}

				// show clone btn if all uploads are filled
				if (!$mainRow.find('.m-basic-upload .js-data:not(.is-active)').eq(0).length) {
					// show clone trigger btn
					$cloneBtn.addClass('is-shown-animate').removeClass('is-disabled');
				}

				// show upload btn if one upload is filled but not file stored
				if ($mainRow.find('.m-basic-upload .js-data.is-active:not(.js-file-stored)').eq(0).length) {
					$(document).trigger(`BasicBtn.fadeAnimate-${$uploadBtn.data('t-id')}`, 'show');
				}

				// hide upload btn
				else {
					$(document).trigger(`BasicBtn.fadeAnimate-${$uploadBtn.data('t-id')}`, 'hide');
				}
				$mainRow.trigger('UtilsForm.deleteClonedRow');
			}, 200);
		}
	};

	$(document).ready(function () {
		S.Utils.Form.init();
	});

})(jQuery);
