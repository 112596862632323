(function ($) {
    'use strict';
    /**
     * BasicRating module implementation.
     *
     * @author Örsan Günay <oe.guenay@edelweiss72.de>
     * @namespace T.Module
     * @class BasicRating
     * @extends T.Module
     */
    T.Module.BasicRating = T.createModule({

        /** @type {jQuery} */
        $ctx: null,

        /** @type {Number} */
        thisRating: 0,

        start: function (resolve) {
            this.$ctx = $(this._ctx);
            this.thisRating = this.$ctx.data('rating');

            const that = this;

            this._events.on('basicTable.rebuildedPhone', function () {
                T.Utils.Helper.initAdditionalModules(that.$ctx.parent());
            });

            // comp is used without js logics as well => call them, if needed
            if (that.thisRating && typeof that.thisRating !== 'undefined') {
                that.initListeners();
                that.setUpHref();
                that.getRatingData();
                this.setStars();
            }
            resolve();
        },

        initListeners: function () {
            this.$ctx.find('.js-rating-showall').bind('click', this._handleOpenLink.bind(this));
        },

        setUpHref: function () {
            const $link = $(this.$ctx.find('.js-rating-showall'));

            $link.attr('data-openurl', $link.attr('href'));
            $link.attr('href', '#');

        },

        getRatingData: function () {
            const that = this;
            let options;
            const backend = $('meta[data-key="backend"]').attr('data-value');

            if (typeof backend !== 'undefined' && typeof that.$ctx.data('apigateway') !== 'undefined') {
                options = {
                    url: backend + that.$ctx.data('apigateway'),
                    type: 'GET'
                };
                T.Utils.Ajax.fragment(options,
                    function (data) {
                        that.thisRating = data.Data.AnzahlSterne;
                        that.$ctx.find('.js-rating-recommendation div').text(`${data.Data.Empfehlungsquote} %`);
                        that.setStars();
                    },
                    function () {
                        that.$ctx.find('.js-start-container').hide();
                        that.$ctx.find('.js-quota-container').hide();
                    });
            } else {
                that.$ctx.find('.js-start-container').hide();
                that.$ctx.find('.js-quota-container').hide();
            }
        },

        setStars() {

            const that = this,
                $stars = this.$ctx.find('.mm-star-container div').children(),
                starClass = $stars.attr('class');

            $stars.each(function () {
                if (that.thisRating >= 1) {
                    $(this).addClass(`${starClass}--full`);
                } else if (that.thisRating > 0) {
                    $(this).addClass(`${starClass}--half`);
                }
                that.thisRating--;
            });
        },

        _handleOpenLink: function (e) {
            e.preventDefault();

            window.open($(e.currentTarget).data('openurl'), 'Check24 Kundenbewertung', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${700}, height=${700}`);
            return false;
        }
        //setCircle() {
        //    let that = this,
        //        $stars = this.$ctx.find('.mm-star-container .mm-circle');
        //
        //    $stars.each(function () {
        //        if (that.thisRating >= 1) {
        //            $(this).addClass('mm-circle--full');
        //        }else if(that.thisRating > 0) {
        //            $(this).addClass('mm-circle--half');
        //        }
        //        that.thisRating--;
        //    });
        //},
    });
}(jQuery));