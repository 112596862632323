(function($) {
    'use strict';
    /**
     * BasicTeaserProduct module implementation.
     *
     * @author  <s.vogt@edelweiss72.de>
     * @namespace T.Module
     * @class BasicTeaserProduct
     * @extends T.Module
     */
    T.Module.BasicTeaserProduct = T.createModule({
        /** @type {jQuery} */
        $ctx: null,

        /** @type {String} */
        productlist: '',

        /** @type {String} */
        productActive: '',

        /** @type {String} */
        productActiveTitle: '',

        /** @type {String} */
        productActiveImage: '',

        /** @type {String} */
        productActiveOrderNumber: '',

        /** @type {String} */
        productActiveCopayment: '',

		selectorCoPayment: '.mm-copayment',


        /**
         * Initialize.
         *
         * @param {function} resolve
         */
        start: function(resolve) {
            this.$ctx = $(this._ctx);
            const that = this;

            // add click
            that.initClickPreview();

            // check if active product exists
            if (sessionStorage.getItem('productActive') && this.$ctx.closest('.o-basic-banderole').length === 0 && sessionStorage.getItem('productDetailPage'))
            {
                let savedUrl;

                // get currentVisitedPage from session storage to compare with real current url
                if (sessionStorage.getItem('currentVisitedPage'))
                {
                    savedUrl = JSON.parse(sessionStorage.getItem('currentVisitedPage')).href;
                }
                else
                {
                    // dummy || always true => scrolling
                    savedUrl = sessionStorage.getItem('productDetailPage');
                }

                if ($(`a[href="${sessionStorage.getItem('productActive')}"]`).length && this.cleanUrlToCompare(sessionStorage.getItem('productDetailPage')) === this.cleanUrlToCompare(savedUrl))
                {
                    setTimeout( () =>
                    {
                        $('html, body').animate({
                            scrollTop: $(`a[href="${  sessionStorage.getItem('productActive')  }"]`).eq(0).offset().top - 20
                        }, 1000);
                    }, 300);
                }
            }

            resolve();
        },


        /*
         * cleanUrlToCompare
         *
         * @param:
         */
        cleanUrlToCompare: function(url){
            // clean ? and #
            let urlCleaned = url.split(/[?#]/)[0];

            // clean slash at the end
            if (urlCleaned.slice(-1) === '/')
            {
                urlCleaned = urlCleaned.slice(0, -1);
            }

           return urlCleaned;
        },


        /*
         * initClickPreview
         */
        initClickPreview: function(){
            const that = this;

            this.$ctx.find('a').on('click', (e) =>
            {
                e.preventDefault();

                const box = $(e.target).closest('.mm-box');

                // set active product
                that.productActive = box.find('.mm-img-box a').attr('href');
                that.productActiveTitle = box.find('h2').eq(0).text();
                that.productActiveImage = box.find('figure img').eq(0).attr('src');
                that.productActiveOrderNumber = box.data('order-number');

                if (box.find(that.selectorCoPayment).length > 0){
                    that.productActiveCopayment = box.find(that.selectorCoPayment).text();
                }

                if (box.data('model') !== 'undefined'){
                    that.productActiveModel = box.data('model');
                }

                sessionStorage.setItem('productOverviewUrl', window.location.href);
                sessionStorage.setItem('productActive', that.productActive);
                sessionStorage.setItem('productActiveTitle', that.productActiveTitle);
                sessionStorage.setItem('productActiveImage', that.productActiveImage);
                sessionStorage.setItem('productActiveCopayment', that.productActiveCopayment);
                sessionStorage.setItem('productActiveOrderNumber', that.productActiveOrderNumber);
                sessionStorage.setItem('productActiveModel', that.productActiveModel);

                // set product list
                that.setProductList($(e.currentTarget).attr('href'));
            });
        },


        /*
         * initClickPreview
         */
        setProductList: function(targetLocation){

            if (this.$ctx.closest('.o-basic-banderole').length === 0) {

                const productList = [];

                this.$ctx.find('.mm-box:visible .mm-img-box a').each((index, item) =>
                {
                    const box = $(item).closest('.mm-box');

                    productList[index] = {
                        'url': $(item).attr('href'),
                        'title': box.find('h2').eq(0).text(),
                        'image': box.find('figure img').eq(0).attr('src'),
                        'copayment': box.find(this.selectorCoPayment).text(),
                        'ordernumber': box.data('order-number'),
                        'model': box.data('model'),
                    };
                });

                if ( productList.length ) {
                    // reset
                    sessionStorage.removeItem('productlist');
                    sessionStorage.setItem('productlist', JSON.stringify(productList));
                }
            }

            window.location.href = targetLocation;
        }
    });
}(jQuery));
